import React, { useCallback, useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import { isAfter, endOfDay } from 'date-fns';
import { isSome } from 'fp-ts/Option';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';

import {
  CourseSearchType,
  useGetCoursesForInstructorCreateLessonListQuery,
  useCreateLessonForInstructorMutation,
  LessonInput,
  CourseNewLessonFragment as Course,
} from '../../gen/graphql';
import { getApiErrorMessage } from '../../utils/graphqlError';
import { LessonDateTimeValidation, LessonMinutesValidation } from '../../utils/FormValidation';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../context/ToastsProvider';
import { CourseStatus } from '../../const/Course';

export interface AddParam {
  startAt?: Date;
  endAt?: Date;
  courseID: number;
  title?: string;
}

interface AddLessonModalWindowProps {
  isOpen: boolean;
  loading?: boolean;
  refetch: () => Promise<void>;
  onClose: () => void;
}

export const AddLessonModalWindow: React.FC<AddLessonModalWindowProps> = ({
  isOpen,
  refetch,
  onClose,
  ...props
}) => {
  const [addData, setAddData] = useState<AddParam>({
    startAt: new Date(),
    endAt: new Date(),
    courseID: 0,
    title: '',
  });
  const [dateError, setDateError] = useState('');
  const [studentError, setStudentError] = useState('');
  const [nextLessonRegisterable, setNextLessonRegisterable] = useState(false);
  const [isOverLessonCount, setIsOverLessonCount] = useState(false);
  const [isOverLessonDate, setIsOverLessonDate] = useState(false);

  // トースト
  const { showToast } = useToastsContext();

  const [createLesson] = useCreateLessonForInstructorMutation();

  // コース取得処理
  const { data: coursesData } = useGetCoursesForInstructorCreateLessonListQuery({
    variables: {
      input: {
        type: CourseSearchType.All,
        status: [
          CourseStatus.ACTIVE,
          CourseStatus.BEFORE_FIRST_LESSON,
          CourseStatus.BEFORE_ASSIGN,
          CourseStatus.INSTRUCTOR_CHANGING,
          CourseStatus.ABSENCE,
          CourseStatus.GRADUATED,
          CourseStatus.CANCELED,
          CourseStatus.ABSENCE_INSTRUCTOR_CHANGING,
          CourseStatus.CANCELING,
        ],
      },
    },
  });
  const courses = useMemo((): Course[] => {
    return coursesData?.coursesForInstructor ?? [];
  }, [coursesData]);

  const handleChangeLessonTitle: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setAddData({
      ...addData,
      title: event.target.value,
    });
  };

  const checkNextLessonRegistable = (courseID: number) => {
    setNextLessonRegisterable(false);
    const currentCourse = courses.find((element: Course) => {
      if (element.id === courseID) {
        return element;
      }
    });

    const lessonCount = currentCourse?.lessons?.length;
    const plannedLessonCount = currentCourse?.plannedLessonCount ?? 0;

    if (
      lessonCount !== undefined &&
      plannedLessonCount !== undefined &&
      lessonCount < plannedLessonCount
    ) {
      setNextLessonRegisterable(false);
      setIsOverLessonCount(false);
      setIsOverLessonDate(false);
    } else if (lessonCount === undefined && plannedLessonCount === undefined) {
      setNextLessonRegisterable(false);
      setIsOverLessonCount(false);
      setIsOverLessonDate(false);
    } else if (lessonCount === 0 && plannedLessonCount === 0) {
      setNextLessonRegisterable(false);
      setIsOverLessonCount(false);
      setIsOverLessonDate(false);
    } else if (lessonCount === undefined && plannedLessonCount !== 0) {
      setNextLessonRegisterable(false);
      setIsOverLessonCount(false);
      setIsOverLessonDate(false);
    } else {
      setNextLessonRegisterable(true);
      setIsOverLessonCount(true);
    }

    const courseEndDate = currentCourse?.endDate;

    if (courseEndDate && addData.startAt) {
      const currentCourseEndDate = endOfDay(Date.parse(courseEndDate));
      if (isAfter(addData.startAt, currentCourseEndDate)) {
        setNextLessonRegisterable(true);
        setIsOverLessonDate(true);
      }
    }

    if (courseID === 0) {
      setNextLessonRegisterable(false);
      setIsOverLessonCount(false);
      setIsOverLessonDate(false);
    }
  };

  const handleChangeCourseId: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const courseID = parseInt(event.target.value);

    checkNextLessonRegistable(courseID);

    setAddData({
      ...addData,
      courseID: courseID,
    });
  };

  function handleChangeLessonStartDate(date: Date | null) {
    if (!addData.startAt || !date) return;

    const startAt = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      addData.startAt.getHours(),
      addData.startAt.getMinutes(),
      addData.startAt.getSeconds(),
    );
    const endAt = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      addData.startAt.getHours() + 1,
      addData.startAt.getMinutes(),
      addData.startAt.getSeconds(),
    );
    setAddData({
      ...addData,
      startAt: startAt,
      endAt: endAt,
    });
  }

  function handleChangeLessonEndDate(date: Date | null) {
    if (!addData.endAt || !date) return;

    const endAt = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      addData.endAt.getHours(),
      addData.endAt.getMinutes(),
      addData.endAt.getSeconds(),
    );
    setAddData({
      ...addData,
      endAt: endAt,
    });
  }

  function handleChangeStartTime(date: Date | null) {
    if (!addData.startAt || !date) return;

    const startAt = new Date(
      addData.startAt.getFullYear(),
      addData.startAt.getMonth(),
      addData.startAt.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    );
    const endAt = new Date(
      addData.startAt.getFullYear(),
      addData.startAt.getMonth(),
      addData.startAt.getDate(),
      date.getHours() + 1,
      date.getMinutes(),
      date.getSeconds(),
    );
    setAddData({
      ...addData,
      startAt: startAt,
      endAt: endAt,
    });
  }

  function handleChangeEndTime(date: Date | null) {
    if (!addData.endAt || !date) return;

    const endAt = new Date(
      addData.endAt.getFullYear(),
      addData.endAt.getMonth(),
      addData.endAt.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    );
    setAddData({
      ...addData,
      endAt: endAt,
    });
  }

  const validate = useCallback((): boolean => {
    if (!addData.startAt || !addData.endAt) return false;

    const startAt = new Date(addData.startAt);
    const endAt = new Date(addData.endAt);
    if (isSome(LessonDateTimeValidation(startAt, endAt))) {
      setDateError('レッスン終了日時は、開始日時より後を指定してください');
      return false;
    }

    if (addData.courseID < 1) {
      setStudentError('専属レッスンを受ける受講生が選択されていません。');
      return false;
    }

    const selectedCourse = courses.find((d) => d.id === addData.courseID);
    if (
      selectedCourse?.plan?.lessonMinutes &&
      isSome(LessonMinutesValidation(startAt, endAt, selectedCourse?.plan?.lessonMinutes))
    ) {
      setDateError(`該当コースのレッスンは${selectedCourse.plan.lessonMinutes / 60}時間以内です`);
      return false;
    }

    setDateError('');
    return true;
  }, [addData, courses]);

  // レッスン更新用のデータをセット
  const setLessonInput = useCallback(() => {
    const input: LessonInput = {
      title: addData.title ?? '',
      startAt: addData.startAt ? addData.startAt.toISOString() : '',
      endAt: addData.endAt ? addData.endAt.toISOString() : '',
    };

    return input;
  }, [addData]);

  // レッスン更新処理
  const submit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!validate()) return;

      const input = setLessonInput();
      try {
        await createLesson({
          variables: {
            courseID: addData.courseID,
            input: input,
          },
          onCompleted: () => {
            setAddData({
              title: '',
              startAt: new Date(),
              endAt: new Date(),
              courseID: 0,
            });
          },
        });
        await refetch();
      } catch (e) {
        showToast(1, getApiErrorMessage(e));
        return;
      }
      showToast(0, '専属レッスンが登録されました。');
      onClose();
      setNextLessonRegisterable(false);
    }, [createLesson, setLessonInput, showToast, onClose, addData, refetch, validate]),
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      loading={props.loading}
      width={'60vw'}
      header={<span>専属レッスン日程を登録</span>}
      footer={
        <Buttons>
          <Button onClick={submit} disabled={nextLessonRegisterable}>
            登録
          </Button>
        </Buttons>
      }
    >
      <ModalContainer>
        {nextLessonRegisterable && (
          <LessonError>
            {isOverLessonCount && <div>規定のレッスン回数以上レッスンを登録できません</div>}
            {isOverLessonDate && <div>卒業日以降にレッスンは登録できません</div>}
          </LessonError>
        )}
        <ModalContent>
          <LessonDateForm>
            <Label>開始日時</Label>
            <div className="lesson-date-input">
              <DatePicker
                selected={addData.startAt}
                onChange={handleChangeLessonStartDate}
                // minDate={new Date()} // 登録忘れのパターンを考慮
                dateFormat="yyyy年MM月dd日"
                disabledKeyboardNavigation
                placeholderText="日程を選択"
                className="input datepicker-date"
              />
              <DatePicker
                selected={addData.startAt}
                onChange={handleChangeStartTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="開始時間"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                disabledKeyboardNavigation
                placeholderText="00:00"
                className="input datepicker-start-time"
              />
            </div>
          </LessonDateForm>
          <LessonDateForm>
            <Label>終了日時</Label>
            <div className="lesson-date-input">
              <DatePicker
                selected={addData.endAt}
                onChange={handleChangeLessonEndDate}
                // minDate={new Date()} // 登録忘れのパターンを考慮
                dateFormat="yyyy年MM月dd日"
                disabledKeyboardNavigation
                placeholderText="日程を選択"
                className="input datepicker-date"
              />
              <DatePicker
                selected={addData.endAt}
                onChange={handleChangeEndTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="終了時間"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                disabledKeyboardNavigation
                placeholderText="00:00"
                className="input datepocker-end-time"
              />
            </div>
          </LessonDateForm>
          <ErrorText>{dateError}</ErrorText>
          <LessonForm>
            <Label>受講生</Label>
            <SelectDiv>
              <select required onChange={handleChangeCourseId}>
                <option value="0" selected>
                  受講生を選択
                </option>
                {courses.map((course) => {
                  return (
                    <option
                      key={course.id}
                      value={course.id}
                      selected={addData.courseID == course.id}
                    >
                      {course.student?.user?.maskedPersonalInfo?.name}：{course.plan?.name}
                    </option>
                  );
                })}
              </select>
            </SelectDiv>
          </LessonForm>
          <ErrorText>{studentError}</ErrorText>
          <LessonForm>
            <Label>タイトル</Label>
            <LessonTitle
              value={addData.title}
              onChange={handleChangeLessonTitle}
              placeholder="レッスンの概要が具体的にわかるタイトルを記入してください"
            />
            <Notice>
              ※レッスンを登録すると受講生に「メール」と「お知らせ」で通知されます。登録前に必ず内容をご確認ください。
            </Notice>
          </LessonForm>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  padding: 2rem;

  input {
    font-size: 16px;
    padding: 0;
    border-radius: 0;
    outline: none;
    background: none;
  }

  input,
  textarea {
    margin: 0;
    padding: 0;
    background: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;
const ModalContent = styled.div`
  margin: 0 auto;
`;
const SelectDiv = styled.div`
  min-width: 200px;

  select {
    display: block;
    width: 100%;
    max-width: 320px;
    height: 50px;
    margin: 5px 0px;
    padding: 0px 24px;
    font-size: 15px;
    line-height: 1.75;
    background-color: white;
    background-image: none;
    border: 1px solid lightgray;
    word-break: normal;
  }
`;
const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0.5rem 0;
`;
const LessonDateForm = styled.div`
  margin-top: 0.625rem;
  margin-right: 1.5rem;

  .lesson-date-input {
    display: flex;
  }
  .react-datepicker-wrapper {
    width: auto;

    .input {
      border: 1px solid lightgray;
    }
  }
`;
const LessonForm = styled.div`
  margin-top: 2rem;
`;
const LessonTitle = styled.input`
  display: block;
  font-size: 15px;
  width: 99%;
  height: 40px;
  border: 1px solid lightgray;
  background-color: white;
  margin: 0 auto;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const LessonError = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fff0f0;
  color: #eb0000;

  div {
    font-size: 0.875rem;
    line-height: 1.5;
  }
`;
const Notice = styled.p`
  margin-top: 0.75rem;
  color: #eb0000;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
`;
const ErrorText = styled.p`
  color: #e2001b;
  font-size: 0.75rem;
`;
