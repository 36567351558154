import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Loader } from '../molecules/Loader';
import { useToastsContext } from '../../context/ToastsProvider';
import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';
import { useReleaseSubscriptionScheduleMutation } from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { defaultErrorMessage } from '../../const/ErrorMessage';

interface Props {
  isOpen: boolean;
  toggle: (nextState: boolean) => void;
  reFetch: () => Promise<void>;
}

export const SubscriptionUpdateScheduleCancelModalsArea: React.FC<Props> = ({
  isOpen,
  toggle,
  reFetch,
}): JSX.Element => {
  const { showToast } = useToastsContext();
  const [releaseSubscriptionScheduleMutation] = useReleaseSubscriptionScheduleMutation();

  const [showLoader, setShowLoader] = useState<boolean>(false);

  const closeModal = useCallback((): void => {
    toggle(false);
  }, [toggle]);

  // 解約のキャンセル
  const releaseSubscriptionSchedule = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        await releaseSubscriptionScheduleMutation();
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, defaultErrorMessage);
        return;
      } finally {
        setShowLoader(false);
      }

      reFetch();
      showToast(0, '処理に成功しました');
      closeModal();
    }, [releaseSubscriptionScheduleMutation, showToast, reFetch, closeModal]),
  );

  return (
    <React.Fragment>
      <Loader display={showLoader} />
      <Modal
        underlayer
        isOpen={isOpen}
        onClose={closeModal}
        width={'576px'}
        header={<Title>プラン変更予約解約</Title>}
        footer={
          <ButtonWrapper>
            <Button onClick={closeModal} gray>
              取り消す
            </Button>
            <Button onClick={releaseSubscriptionSchedule}>予約のキャンセル</Button>
          </ButtonWrapper>
        }
      >
        <Container>
          <Description>
            <Text>
              プラン変更予約を中止します。中止した後も引き続き現在のプラン内容でサービスを受けることができます。
            </Text>
            <br />
            <Text>プラン変更予約をキャンセルしますか？</Text>
            <br />
          </Description>
        </Container>
      </Modal>
    </React.Fragment>
  );
};

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 auto;
`;
const Container = styled.div`
  display: flex;
  flex-flow: column;
  padding: 1.25rem 3.75rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 1rem;
    align-items: center;
  `}
`;

const Description = styled.div`
  line-height: 1.35;
  text-align: center;
`;

const Text = styled.p`
  font-size: 1rem;
  font-weight: bold;
  margin: 0.5rem auto;
  line-height: 1.35;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
