import React, { useCallback, useReducer } from 'react';

interface MenuContext {
  isOpen: boolean;
  isHoverOpen: boolean;
  toggle: () => void;
  toggleClose: () => void;
  toggleHoverOpen: () => void;
  toggleHoverClose: () => void;
}

const initialContext: MenuContext = {
  isOpen: false,
  isHoverOpen: false,
  toggle: () => undefined,
  toggleClose: () => undefined,
  toggleHoverOpen: () => undefined,
  toggleHoverClose: () => undefined,
};

export const MenuContext = React.createContext(initialContext);

const useMenu = (): MenuContext => {
  /**
   * オープンフラグ変更
   */
  const [isOpen, dispatch] = useReducer((state: boolean, action: 'toggle' | 'close' | 'open') => {
    switch (action) {
      case 'toggle':
        return !state;
      case 'close':
        return false;
      case 'open':
        return true;
    }
  }, window.innerWidth > 950);

  /**
   * ホバーオープンフラグ変更
   */
  const [isHoverOpen, dispatchHover] = useReducer(
    (state: boolean, action: 'toggle' | 'close' | 'open') => {
      switch (action) {
        case 'toggle':
          return !state;
        case 'close':
          return false;
        case 'open':
          return true;
      }
    },
    true,
  );

  /**
   * オープンフラグチェンジ
   */
  const toggle = useCallback(() => {
    dispatch('toggle');
  }, []);

  /**
   * オープンフラグクローズ
   */
  const toggleClose = useCallback(() => {
    dispatch('close');
  }, []);

  /**
   * ホバーオープンフラグオープン
   */
  const toggleHoverOpen = useCallback(() => {
    dispatchHover('open');
  }, []);

  /**
   * ホバーオープンフラグクローズ
   */
  const toggleHoverClose = useCallback(() => {
    dispatchHover('close');
  }, []);

  return {
    isOpen,
    isHoverOpen,
    toggle,
    toggleClose,
    toggleHoverOpen,
    toggleHoverClose,
  };
};

interface MenuProviderProps {
  children: React.ReactNode;
}

export const MenuProvider: React.FC<MenuProviderProps> = (props) => {
  const value = useMenu();

  return <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>;
};
