import {
  SessionPhase as Phase,
  IsOnScheduleType,
  ResponseDeadlineType,
  SessionReportPreparationType,
  SessionReportAnnouncementType,
  HasAnnouncementsType,
} from '../gen/graphql';

// セッションの所要時間デフォルト値
export const SESSION_DEFAULT_MINUTES = 60;

// セッション内容のデフォルト値
export const DefaultContent = `
【概要】
①ライフプランと目標設定について
・どういった状態が理想なのかの言語化と、それを達成するために必要な目標の具体化
・3年後にはフリーランスエンジニアとして活躍したい
・卒業時には案件を10件以上獲得する

②転職について
・これから転職を開始していきたいと相談あり、転職サポートについて説明
・転職サポート申込フォームをお渡し済み

【課題】
・
・

【次回までの受講生の宿題】
・
・
`;

// 運営への連携での詳細欄デフォルト値
export const HasAnnouncementDetailfaultContent = `
記入例 ※運営が把握するための項目です。箇条書きで記載してください。
①どのようなことがいつ頃から起こっていますか？その理由は？（※具体的に事実ベースで記入をお願いします）
②この事象から今後想定される懸念はなにかありますか？
③いつまでに、どのようなフォローを希望しますか？
`;

// セッションの終了状況
export const SessionPahse = [
  { label: '実施済み', value: Phase.Completed },
  { label: '受講生都合によるキャンセル', value: Phase.CanceledByStudent },
];

// セッション前の準備項目
export const Preparations = [
  {
    label: '受講生情報確認（Q&A、セッション履歴、カリキュラムなど）',
    value: SessionReportPreparationType.CheckStudentInfoTask,
  },
  { label: '受講生のメッセージ対応', value: SessionReportPreparationType.MessageTask },
  { label: '運営からの依頼対応', value: SessionReportPreparationType.AdminRequestTask },
  { label: 'その他', value: SessionReportPreparationType.OtherTask },
];

// 学習スケジュール
export const Schedules = [
  { label: '遅れている', value: IsOnScheduleType.TooLate },
  { label: 'やや遅れている', value: IsOnScheduleType.BitLate },
  { label: '進捗通り', value: IsOnScheduleType.OnSchedule },
  { label: 'やや進んでいる', value: IsOnScheduleType.BitForward },
  { label: '進んでいる', value: IsOnScheduleType.TooForward },
];

// 運営への連携事項有無
export const HasAnnouncements = [
  { label: 'はい', value: HasAnnouncementsType.Yes },
  { label: 'いいえ', value: HasAnnouncementsType.No },
];

// 運営への対応期限
export const ResponseDeadline = [
  { label: '急ぎ対応希望', value: ResponseDeadlineType.Asap },
  { label: '1~3営業日以内の対応希望', value: ResponseDeadlineType.FewDays },
  { label: '7営業日以内の対応希望', value: ResponseDeadlineType.SevenDays },
];

// 運営への連携項目
export const Announcements = [
  {
    label: '受講生のメンタル面',
    value: SessionReportAnnouncementType.StudentMental,
  },
  {
    label: '目標・カリキュラム内容',
    value: SessionReportAnnouncementType.ReconsiderCurricurum,
  },
  {
    label: 'インストラクターの指導方法',
    value: SessionReportAnnouncementType.InstructToInstructor,
  },
  {
    label: 'インストラクターに情報共有',
    value: SessionReportAnnouncementType.ShareInfoToInstructor,
  },
  {
    label: 'インストラクター交代',
    value: SessionReportAnnouncementType.ChangeToInstructor,
  },
  {
    label: '転職サポート',
    value: SessionReportAnnouncementType.TalkToJobchamgeSupport,
  },
  {
    label: '延長コース',
    value: SessionReportAnnouncementType.TalkToExtensionCourse,
  },
  {
    label: 'コース変更',
    value: SessionReportAnnouncementType.TalkToChangeCourse,
  },
  { label: '支払い', value: SessionReportAnnouncementType.TalkToPayment },
  { label: '解約', value: SessionReportAnnouncementType.TalkToCancel },
  { label: 'サービス', value: SessionReportAnnouncementType.TalkToServiceDiscontent },
  { label: 'その他', value: SessionReportAnnouncementType.Other },
];
