import { IconUserFragment, ChatMemberUserFragment } from '../gen/graphql';
import { User as TsUser } from '../swagger/gen/ts_front';
import UserType from '../const/UserType';
import { SnsType } from '../store';

export const getUserType = (
  user: IconUserFragment | TsUser | ChatMemberUserFragment | undefined,
  isInstructor: boolean,
): string => {
  // TODO isAdmin, isInstructorとisCoarchで型が違うためswitchで分岐できない
  if (user?.isAdmin) {
    return UserType.Admin;
  } else if (user?.instructor?.isCoach) {
    return UserType.Coach;
  } else if (user?.isInstructor) {
    return UserType.Instructor;
    // TODO AB#11729 swagger除去してgraphqlのUserに型を統一したらinでの分岐を削除
  } else if (user && 'isAI' in user && user.isAI) {
    return UserType.AI;
  }

  if (isInstructor) {
    if (user?.isStudent) {
      return UserType.Student;
    } else {
      switch (user?.snsType) {
        case SnsType.TeamSubscription:
          return UserType.Team;
        case SnsType.PersonalSubscription:
        case SnsType.PremiumSubscription:
          return UserType.SnsUser;
        default:
          return '';
      }
    }
  }

  return '';
};
