const UserType = {
  Instructor: 'instructor',
  Student: 'student',
  SnsUser: 'sns',
  Coach: 'coach',
  Admin: 'admin',
  Team: 'team',
  AI: 'ai',
};

export const UserTypeLabels: { [key: string]: string } = {
  [UserType.Instructor]: '講師',
  [UserType.Student]: 'マンツー',
  [UserType.SnsUser]: 'サブスク',
  [UserType.Coach]: 'コーチ',
  [UserType.Admin]: '管理者',
  [UserType.Team]: '法人',
  [UserType.AI]: '自動',
};

export default UserType;
