import {
  ChatMemberListFragment,
  ChatRoomListFragment,
  CurrentUserFragment as User,
} from '../gen/graphql';
import defaultIcon from '../static/image/icon_default.svg';

export const getChatRoomIcon = (room: ChatRoomListFragment, lmsUser: User | null): string => {
  return room.chatMembers.reduce((acc, cur) => {
    if (!cur.user) return acc;
    if (cur.user.id === lmsUser?.id) return acc;
    if (!cur.user.image) return acc;
    return cur.user.image;
  }, defaultIcon);
};

export const getChatRoomName = (
  chatMembers: ChatMemberListFragment[],
  lmsUser: User | null,
): string => {
  return chatMembers
    .filter((chatMember) => !chatMember.user || lmsUser?.id !== chatMember.user?.id)
    .reduce(
      (acc, cur) =>
        cur.user ? acc + cur.user.maskedPersonalInfo?.name + ', ' : '退会済みユーザー, ',
      '',
    )
    .slice(0, -2);
};
