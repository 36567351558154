import React from 'react';

import styled from 'styled-components';
import { H3 } from '../../atoms/Typography';

import { ProgramListContainer } from '../../atoms/ProgramListContainer';
import { CourseProgramArticle } from '../../organisms/CourseProgramArticle';
import { EmptyBlock } from '../../molecules/EmptyBlock';

import { useGetCourseProgramsQuery } from '../../../gen/graphql';
import { useUser } from '../../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../../const/UserPermission';

export const CourseProgramCompletedStudys: React.FC = () => {
  const { permissionCheck } = useUser();

  const { data, loading } = useGetCourseProgramsQuery({
    variables: {
      input: {
        registeredCoursePrograms: false,
        isCompleted: true,
      },
    },
  });

  return (
    <ProgramSection data-e2e="courseProgramConpletedStudyList">
      <StyledH3 color="DARK">完了したコース教材</StyledH3>
      {!loading &&
        (data?.coursePrograms && data?.coursePrograms?.length > 0 ? (
          <ProgramListContainer>
            {data.coursePrograms.map((p) => {
              return (
                <CourseProgramArticle
                  id={p?.id ? p.id : ''}
                  key={p.id}
                  image={p?.image ? p.image : ''}
                  title={p?.title ? p.title : ''}
                  description={p.description}
                  totalChapterCount={p.totalChapterCount}
                  totalRequireTime={p.totalRequireTime}
                  totalCompletedTime={p.totalCompletedTime}
                  showProgress={permissionCheck(FunctionType.StudyLog, PermissionType.Read)}
                />
              );
            })}
          </ProgramListContainer>
        ) : (
          <EmptyBlock title="完了したコース教材はありません">
            コース教材で学習を完了した後こちらで
            <br />
            どのコース教材を学習したか確認しましよう。
          </EmptyBlock>
        ))}
    </ProgramSection>
  );
};

const ProgramSection = styled.section`
  margin-bottom: 2.625rem;
`;

const StyledH3 = styled(H3)`
  font-size: 1.25rem;
`;
