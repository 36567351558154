import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';

import Image001 from '../../static/image/question_create_modal_001.png';
import Image002 from '../../static/image/question_create_modal_002.png';
import Image003 from '../../static/image/question_create_modal_003.png';
import Image004 from '../../static/image/question_create_modal_004.png';
import Image005 from '../../static/image/question_create_modal_005.png';
import Image006 from '../../static/image/question_create_modal_006.png';
import { Alert } from '../molecules/Alert';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  handleLink: () => void;
}

export const QuestionCreateModal: React.FC<Props> = (props) => {
  return (
    <Modal
      underlayer
      isOpen={props.isOpen}
      onClose={props.onClose}
      loading={props.loading}
      header={<Title>質問の投稿に際して</Title>}
      width="1100px"
    >
      <Inner>
        <H3>いつもテラコヤのQ&A掲示板をご利用いただきありがとうございます</H3>
        <Description>
          テラコヤのQ&A掲示板をご利用いただきありがとうございます。大変ありがたいことに毎月2000件近い質問を頂いています。
          <br />
          素早い返信、品質の高い回答を目指していますが、皆様にご協力いただきたいことがあります。
        </Description>

        <H3>良質な回答を得る6つのコツ</H3>
        <Flex>
          <Item>
            <Image src={Image001} />
            <ItemContent>
              <H4>タイトルにこだわる</H4>
              <Text>タイトルだけでどんな内容なのかが判断できるように記載しましょう。</Text>
            </ItemContent>
          </Item>
          <Item>
            <Image src={Image002} />
            <ItemContent>
              <H4>具体的に書く</H4>
              <Text>
                質問文は5W1Hを意識したフォーマットに沿って記載し、エラー文やプログラムも含めましょう。
              </Text>
            </ItemContent>
          </Item>
          <Item>
            <Image src={Image003} />
            <ItemContent>
              <H4>試したことを詳細に</H4>
              <Text>
                試したこと、すでに閲覧した記事など具体的に書きましょう。既に試したことの回答を防げます。
              </Text>
            </ItemContent>
          </Item>
          <Item>
            <Image src={Image004} />
            <ItemContent>
              <H4>マークダウンで書く</H4>
              <Text>他の人も読みやすいようマークダウンを活用しましょう。</Text>
            </ItemContent>
          </Item>
          <Item>
            <Image src={Image005} />
            <ItemContent>
              <H4>講師の立場で質問する</H4>
              <Text>講師もわからなければ調べます。講師の気持ちになって質問文を考えましょう。</Text>
            </ItemContent>
          </Item>
          <Item>
            <Image src={Image006} />
            <ItemContent>
              <H4>事前に検索する</H4>
              <Text>同様の質問がないか事前にQ&A掲示板とGoogleで検索しましょう。</Text>
            </ItemContent>
          </Item>
        </Flex>

        <H3>投稿いただいても回答できない質問</H3>
        <ul>
          <ListItem>プログラミング学習に関係のない質問</ListItem>
          <ListItem>コードをください。デバッグしてください等の丸投げの質問</ListItem>
          <ListItem>
            サービスに関する質問
            <span>
              ※
              <a
                href="https://intercom.help/plus---samurai/ja/collections/3420590-%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B-%E3%81%94%E6%84%8F%E8%A6%8B"
                target="_blank"
              >
                こちら
              </a>
              よりご連絡ください
            </span>
          </ListItem>
        </ul>

        <StyledAlert severity="warning" title="投稿内容は他のユーザーにも公開されます">
          <AlertText>以下の情報を含めないよう十分ご注意ください。</AlertText>
          <ul>
            <AlertListItem>本名、メールアドレスなどの個人情報</AlertListItem>
            <AlertListItem>会社名や特定のプロジェクト名など、機密性の高い情報</AlertListItem>
            <AlertListItem>パスワード、APIキー、トークンなどの機密情報</AlertListItem>
          </ul>
        </StyledAlert>

        <StyledButton onClick={props.handleLink}>質問入力画面に進む</StyledButton>
      </Inner>
    </Modal>
  );
};

const Title = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
`;
const Inner = styled.div`
  max-width: 976px;
  margin: 0 auto;
  padding: 0 2rem 2rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 0 1rem 2rem;
  `}
`;
const H3 = styled.h3`
  margin: 2rem auto 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;

  ${media.lessThan('medium')`
    font-size: 1.375rem;
  `}
`;
const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.5;

  ${media.lessThan('medium')`
    color: rgba(0,0,0,.87);
    font-size: 1rem;
  `}
`;
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap 1.5rem 2rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 1.5rem 0;
  `}
`;
const Item = styled.div`
  display: flex;
  align-items: flex-start;
  width: calc((100% - 4rem) / 3);

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0 auto;
  `}
`;
const Image = styled.img`
  display: block;
  width: 40%;
  max-width: 6.25rem;
  margin-right: 0.25rem;
`;
const ItemContent = styled.div`
  flex: 1;
`;
const H4 = styled.h4`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
`;
const Text = styled.p`
  margin-top: 0.25rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  line-height: 1.5;
`;
const ListItem = styled.li`
  padding: 1.125rem 2rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;

  &:nth-of-type(2n - 1) {
    background: #f5f5f5;
  }

  span {
    margin-left: 0.75rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;

    a {
      color: #0029ff;
      font-size: 1em;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  ${media.lessThan('medium')`
    padding: 1rem;
    line-height: 1.375rem;

    span {
      display: block;
      margin: .5rem auto 0 0;
    }
  `}
`;
const StyledAlert = styled(Alert)`
  margin-top: 2rem;

  ${media.lessThan('medium')`
    margin-top: 1.25rem;
  `}
`;
const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 240px;
  margin: 2.5rem auto 0;
  background: #fff;
  border: 1px solid #eb0000;
  color: #eb0000;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    background: #eb0000;
    color: #fff;
  }
`;

const AlertText = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
`;
const AlertListItem = styled.li`
  padding-left: 1em;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-indent: -1em;

  &:before {
    content: '・';
  }
`;
