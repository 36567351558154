import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import stripeJs, { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { Either } from 'fp-ts/Either';
import { isNonEmpty } from 'fp-ts/Array';

import {
  CardCvcError,
  CardExpirationError,
  CardNumberError,
  LmsStripeValidationError,
  Payment,
  StripePaymentService,
  StripePaymentServiceImpl,
} from '../../infrastructure/externalService/StripePaymentService';

import { Button } from '../atoms/Button';
import { CardBrand } from '../atoms/CardBrand';
import { Modal } from './Modal';
import { RegisterPayment } from '../organisms/RegisterPayment';

import { useToastsContext } from '../../context/ToastsProvider';
import { defaultErrorMessage } from '../../const/ErrorMessage';
import { Payment as PaymentConst } from '../../const/Payment';
import { StripePaymentMethodFragment } from '../../gen/graphql';

type PaymentMethodItem = StripePaymentMethodFragment;

interface AddPaymentMethodModalProps<T extends PaymentMethodItem> {
  isOpen: boolean;
  toggle: (nextState: boolean) => void;
  loading?: boolean;
  cards: Array<T>;
  currentDefaultPaymentId: string;
  addNewPayment: (
    result: Either<LmsStripeValidationError, Payment>,
    clearElements: () => void,
    initValidateMsg: () => void,
    validateError: (error: LmsStripeValidationError) => void,
    setSubmitButtonClicked: (clicked: boolean) => void,
  ) => void;
  changeSelectPayment: (
    selectedPaymentMethodId: string,
    clearElements: () => void,
    initValidateMsg: () => void,
    setSubmitButtonClicked: (clicked: boolean) => void,
  ) => void;
}

export const AddPaymentMethodModal = <T extends PaymentMethodItem = StripePaymentMethodFragment>(
  props: AddPaymentMethodModalProps<T>,
): JSX.Element => {
  const [stripe, setStripe] = React.useState<stripeJs.Stripe | null>(null);
  const [elements, setElements] = React.useState<stripeJs.StripeElements | null>(null);
  const stripeService: StripePaymentService = new StripePaymentServiceImpl(stripe);

  const [stripePromise] = React.useState(() =>
    loadStripe(
      pipe(
        O.fromNullable(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
        O.fold(
          () => '',
          (env) => env,
        ),
      ),
    ),
  );

  const [cardNumberError, setCardNumberError] = React.useState('');
  const [expirationError, setExpirationError] = React.useState('');
  const [cvcError, setCvcError] = React.useState('');
  const [submitButtonClicked, setSubmitButtonClicked] = React.useState(false);
  const [paymentID, setPaymentID] = React.useState<T['id'] | null>(props.currentDefaultPaymentId);
  const now = new Date();
  const [addOpen] = useState(true);

  const { showToast } = useToastsContext();

  const closeModal = () => {
    props.toggle(false);
  };

  const setCardValidationStripeError = (e: LmsStripeValidationError) => {
    if (e instanceof CardNumberError) {
      setCardNumberError(e.message);
    } else if (e instanceof CardExpirationError) {
      setExpirationError(e.message);
    } else if (e instanceof CardCvcError) {
      setCvcError(e.message);
    } else {
      setCvcError(defaultErrorMessage);
    }
  };

  const initCreditInfoValidationMsg = () => {
    setCardNumberError('');
    setExpirationError('');
    setCvcError('');
  };

  const selectedPayment = (item: PaymentMethodItem) => {
    return paymentID === item.id;
  };
  const checkExpire = (item: PaymentMethodItem, now: Date) =>
    now.getFullYear() < item.expireYear ||
    (now.getFullYear() === item.expireYear && now.getMonth() + 1 <= item.expireMonth);
  const displayNewPayment = () => !paymentID;

  const changePaymentMethod = async () => {
    setSubmitButtonClicked(true);

    initCreditInfoValidationMsg();

    if (displayNewPayment()) {
      //新規カードでの支払い変更
      await stripeService
        .checkCreditCard(O.fromNullable(elements))
        .then((result: Either<LmsStripeValidationError, Payment>) => {
          props.addNewPayment(
            result,
            () => stripeService.clearElements(O.fromNullable(elements)),
            () => initCreditInfoValidationMsg(),
            (e) => setCardValidationStripeError(e),
            (clicked) => setSubmitButtonClicked(clicked),
          );
        });
    } else {
      //既存カードでの支払い変更
      if (!paymentID || paymentID === props.currentDefaultPaymentId) {
        //元々選択していたデフォルトの支払い先を選択
        setSubmitButtonClicked(false);
        showToast(1, '現在のデフォルトの支払い方法です');
        return;
      }
      props.changeSelectPayment(
        paymentID,
        () => initCreditInfoValidationMsg(),
        () => initCreditInfoValidationMsg(),
        (clicked) => setSubmitButtonClicked(clicked),
      );
    }
  };

  useEffect(() => {
    setPaymentID(props.currentDefaultPaymentId);
  }, [props.currentDefaultPaymentId]);

  return (
    <Modal
      underlayer
      isOpen={props.isOpen}
      onClose={closeModal}
      loading={props.loading}
      width={'694px'}
      header={<Title>支払い方法を変更</Title>}
      footer={
        <ButtonWrapper>
          <Button onClick={changePaymentMethod} disabled={submitButtonClicked}>
            変更する
          </Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <Ul>
          {isNonEmpty(props.cards)
            ? props.cards.map((item) => {
                return (
                  <Li key={item.id}>
                    <Label active={selectedPayment(item)}>
                      <input
                        type="radio"
                        value={item.id}
                        checked={selectedPayment(item)}
                        disabled={!checkExpire(item, now)}
                        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                          setPaymentID(value);
                        }}
                      />
                      <Dl>
                        <Brand>
                          <CardBrand brand={item.cardBrand} />
                        </Brand>
                        <NumberTitle>カード番号</NumberTitle>
                        <Number>**** **** **** {item.cardNumber}</Number>
                        <Limit active={checkExpire(item, now)}>
                          {checkExpire(item, now) ? (
                            <span>有効期限</span>
                          ) : (
                            <span className="disabled">有効期限切れ</span>
                          )}
                          {item.expireMonth}/{item.expireYear}
                        </Limit>
                        {/* isAvailableはPaymentMethodFragment(toC用)にしかない */}
                        {'isAvailable' in item && !item?.isAvailable && <Notice>決済エラー</Notice>}
                      </Dl>
                    </Label>
                  </Li>
                );
              })
            : ''}
          {props.cards.length < PaymentConst.MAX_ITEM_NUMBER && (
            <Li>
              <Label active={displayNewPayment()}>
                <input
                  type="radio"
                  checked={displayNewPayment()}
                  onChange={() => {
                    setPaymentID(null);
                  }}
                />
                <AddText>支払い方法の追加</AddText>
                {displayNewPayment() ? (
                  <AddContainer active={addOpen}>
                    <Elements stripe={stripePromise}>
                      <RegisterPayment
                        numberError={cardNumberError}
                        expirationError={expirationError}
                        cvcError={cvcError}
                        setStripe={setStripe}
                        setElements={setElements}
                      />
                    </Elements>
                  </AddContainer>
                ) : (
                  ''
                )}
              </Label>
            </Li>
          )}
        </Ul>
      </Container>
    </Modal>
  );
};

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
`;
const ButtonWrapper = styled.div`
  text-align: right;
`;

const Container = styled.div`
  padding: 2rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 2rem 1.4rem;
  `}
`;
const Li = styled.li`
  input {
    display: none;
  }

  ${media.lessThan('medium')`
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0,0,0,0.1);
  `}
`;
const Ul = styled.ul`
  ${Li} + ${Li} {
    margin-top: 0.875rem;
  }

  ${media.lessThan('medium')`
    ${Li} + ${Li} {
      margin-top: 1rem;
    }
  `}
`;
const Label = styled.label<{ active: boolean }>`
  display: block;
  padding-left: 4rem;
  background: #fff;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    margin: auto;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.36);
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: 0.5rem;
  }

  &:after {
    content: '';
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    margin: auto;
    background: #${(props) => (props.active ? 'e73248' : 'fff')};
    border-radius: 50%;
    position: absolute;
    top: 11px;
    left: 0.75rem;
  }

  ${media.lessThan('large')`
    padding-left: 2.625rem;
  `}

  ${media.lessThan('medium')`
    padding-left: 2rem;
    background: #fff;

    &:before {
      left: 0.25rem;
    }

    &:after {
      left: 0.5rem;
    }
  `}
`;
const Dl = styled.dl`
  display: flex;
  align-items: center;

  ${media.lessThan('medium')`
    flex-wrap: wrap;
  `}
`;
const Brand = styled.dd`
  width: 3.25rem;
  height: 2rem;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const NumberTitle = styled.dd`
  margin: 0 1.5rem 0 2rem;

  ${media.lessThan('large')`
    margin: 0 0.5rem 0 1rem;
    font-size: 0.875rem;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const Number = styled.dd`
  margin-right: 2rem;

  ${media.lessThan('large')`
    margin-right: 1rem;
    font-size: 0.875rem;
  `}

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0 0 0.25rem;
    line-height: 1rem;
  `}
`;
const Limit = styled.dd<{ active: boolean }>`
  display: flex;
  align-items: center;

  span {
    display: block;
    margin-right: 0.5rem;

    &.disabled {
      color: #e73248;
    }
  }

  ${media.lessThan('large')`
    font-size: 0.875rem;

    span {
      font-size: 0.875rem;
    }
  `}

  ${media.lessThan('medium')`
    color: rgba(0,0,0,0.36);
    font-size: 0.6875rem;

    span {
      font-size: 0.6875rem;
    }
  `}

  ${(props) =>
    !props.active
      ? media.lessThan('medium')`
      color: #e73248;
    `
      : ''}
`;
const Notice = styled.dd`
  color: #e73248;
  margin-left: 0.4rem;

  ${media.lessThan('large')`
    font-size: 0.875rem;
  `}

  ${media.lessThan('medium')`
    font-size: 0.6875rem;
  `}
`;

const AddText = styled.p`
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 1rem;

  ${media.lessThan('medium')`
    font-size: 0.875rem;
  `}
`;
const AddContainer = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  margin-top: 0.875rem;
  padding: 1.5rem 2rem;
  background: #f5f5f5;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    margin-left: -2rem;
    padding: 1rem;
  `}
`;
