import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import ja from 'date-fns/locale/ja';

if (ja.options) {
  // 月曜はじまり
  ja.options.weekStartsOn = 1;
}

type Props = Omit<ReactDatePickerProps, 'local' | 'renderCustomHeader'>;

// 日本語環境用にカスタマイズしたDatepicker
export const CustomDatePicker = (props: Props): JSX.Element => (
  <DatePicker
    locale={ja}
    renderCustomHeader={({
      date,
      increaseMonth,
      decreaseMonth,
      nextMonthButtonDisabled,
      prevMonthButtonDisabled,
    }) => (
      <>
        {!prevMonthButtonDisabled && (
          <button
            type="button"
            className="react-datepicker__navigation react-datepicker__navigation--previous"
            aria-label="Previous Month"
            onClick={decreaseMonth}
          >
            <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
              Previous Month
            </span>
          </button>
        )}
        <div className="react-datepicker__current-month">
          {date.getFullYear()}年 {date.getMonth() + 1}月
        </div>
        {!nextMonthButtonDisabled && (
          <button
            type="button"
            className="react-datepicker__navigation react-datepicker__navigation--next"
            aria-label="Next Month"
            onClick={increaseMonth}
          >
            <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
              Next Month
            </span>
          </button>
        )}
        <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--scroll" />
      </>
    )}
    {...props}
  />
);
