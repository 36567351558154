// 利用可能ユーザー数の上限
export const MAXIMUM_NUMBER_OF_USERS = 100;

// 利用可能ユーザー数の下限
export const MINIMUM_NUMBER_OF_USERS = 1;

// 利用可能ユーザー数の下限(クレジットカード利用時)
export const MINIMUM_NUMBER_OF_USERS_FOR_CREDIT = 1;

// 利用可能ユーザー数の下限(銀行振り込み時)
export const MINIMUM_NUMBER_OF_USERS_FOR_BANK_TRANSFER = 5;

// 招待メール送信数の上限
export const MAXIMUM_NUMBER_OF_INVITATION_MAIL = 100;
