import React, { useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ApolloQueryResult } from '@apollo/client';

import { useUpdateCourseMutation, GetCourseForInstructorQuery } from '../../gen/graphql';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';

import { useToastsContext } from '../../context/ToastsProvider';
import { TextArea, TextAreaHandler } from '../atoms/TextArea';
import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';
import { defaultErrorMessage } from '../../const/ErrorMessage';

interface Props {
  isOpen: boolean;
  courseID: number;
  memo: string;
  loading?: boolean;
  onRefetch: () => Promise<ApolloQueryResult<GetCourseForInstructorQuery>>;
  onClose: () => void;
}

export const CourseUpdateModal: React.FC<Props> = ({
  isOpen,
  courseID,
  memo,
  onRefetch,
  onClose,
  ...props
}): JSX.Element => {
  const instructorMemoRef = useRef<TextAreaHandler>(null);
  const { showToast } = useToastsContext();

  const [updateCourse] = useUpdateCourseMutation();

  const submit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      const instructorMemo = instructorMemoRef?.current?.getValue() ?? '';
      try {
        await updateCourse({
          variables: {
            id: courseID,
            input: {
              instructorMemo: instructorMemo,
            },
          },
        });
        instructorMemoRef.current?.setValue('');
        await onRefetch();
      } catch (e) {
        showToast(1, defaultErrorMessage);
      }
      showToast(0, 'メモの更新が完了しました');
      onClose();
    }, [courseID, onClose, onRefetch, updateCourse, showToast]),
  );

  useEffect(() => {
    instructorMemoRef.current?.setValue(memo);
  }, [memo]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      loading={props.loading}
      width={'60vw'}
      header={'受講生のメモを編集'}
      footer={<Button onClick={submit}>保存</Button>}
    >
      <ModalContainer>
        <ModalContent>
          <TextArea height="60vh" name="instructorMemo" ref={instructorMemoRef} />
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  padding: 2rem;

  input {
    font-size: 1rem;
    padding: 0;
    border-radius: 0;
    outline: none;
    background: none;
  }

  input,
  button {
    margin: 0;
    padding: 0;
    background: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
`;

const ModalContent = styled.div`
  margin: 0 auto;
`;
