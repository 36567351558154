import React from 'react';
import styled from 'styled-components';
import lodash from 'lodash';

export interface Option {
  value: string | number;
  name: string;
}

interface MultipleInputWithSearchProps {
  options: Option[];
  values: Option[];
  placeholder?: string;
  onSelect: (option: Option) => void;
  onRemove: (option: Option) => void;
  fetchOptions: () => void;
  onFocus?: () => void;
  className?: string;
}

export const MultipleInputWithSearch = React.forwardRef(
  (props: MultipleInputWithSearchProps, ref: React.Ref<HTMLInputElement>): JSX.Element => (
    <Container className={props.className}>
      <InputContainer>
        {props.values.map(
          (value, i): JSX.Element => (
            <Value key={i}>
              <span>{value.name}</span>
              <RemoveButton onClick={(): void => props.onRemove(value)}>×</RemoveButton>
            </Value>
          ),
        )}
        <Input
          type="text"
          name="tags"
          onKeyUp={lodash.debounce(props.fetchOptions, 500)}
          onFocus={props.onFocus}
          placeholder={props.placeholder}
          ref={ref}
        />
      </InputContainer>
      <Options>
        {props.options.map(
          (option, i): JSX.Element => (
            <li key={i} onClick={(): void => props.onSelect(option)}>
              {option.name}
            </li>
          ),
        )}
      </Options>
    </Container>
  ),
);

const Container = styled.div`
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
  width: 100%;
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.125rem;
  box-sizing: border-box;
  font-size: 1rem;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0.5rem;
  appearance: none;
  border: none;
  box-shadow: none;
  color: rgba(0, 0, 0.87);
  font-size: 1rem;

  &::placeholder {
    color: rgba(0, 0, 0, 0.36);
  }

  &:focus {
    background: #fff;
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

const Value = styled.p`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: rgba(241, 196, 78, 0.6);
  border: 1px solid #f1c44e;
  border-radius: 1.25rem;
  color: rgba(51, 51, 51, 0.87);
  line-height: 1rem;

  span {
    font-size: 0.75rem;
  }
`;

const RemoveButton = styled.span`
  margin-left: 0.25rem;
  cursor: pointer;
`;

const Options = styled.ul`
  position: absolute;
  left: 2rem;
  bottom: 0;
  width: 248px;
  transform: translateY(100%);
  z-index: 2;
  box-shadow: 0.125rem 0.25rem 0.625rem rgba(0, 0, 0, 0.3);

  li {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #fff;

    & + li {
      border-top: 1px solid #eee;
    }

    &:hover {
      color: #e2001b;
      background-color: #fce5e8;
    }
  }
`;
