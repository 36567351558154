import React from 'react';
import TabNavigationLayout from './TabNavigationLayout';

type TabType = 'notification' | 'admin';

interface Props {
  activeTab: TabType;
  notificationUnreadCount: number;
  noticeUnreadCount: number;
  hideFooter?: boolean;
  metaTitle: string;
  metaDescription?: string;
}

export const NotificationLayout: React.FC<Props> = ({
  activeTab,
  notificationUnreadCount,
  noticeUnreadCount,
  hideFooter,
  metaTitle,
  metaDescription,
  children,
}): JSX.Element => (
  <TabNavigationLayout
    pageTitle="通知"
    tabs={[
      {
        label: 'お知らせ',
        to: '/notification/notification',
        active: activeTab === 'notification',
        count: notificationUnreadCount,
      },
      {
        label: 'アナウンス',
        to: '/notification/admin',
        active: activeTab === 'admin',
        count: noticeUnreadCount,
      },
    ]}
    hasIntercom
    hideFooter={hideFooter}
    metaTitle={metaTitle}
    metaDescription={metaDescription}
  >
    {children}
  </TabNavigationLayout>
);
