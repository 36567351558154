import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';

import Arrow from '../../../static/image/lp/flow_arrow.svg';
import Thumb001 from '../../../static/image/lp/flow001.jpg';
import Thumb002 from '../../../static/image/lp/flow002.jpg';
import Thumb003 from '../../../static/image/lp/flow003.jpg';

export const LpFlow = (): JSX.Element => {
  return (
    <Flow id="flow" data-gtm="flow">
      <StyledInner>
        <Ttl>
          <h2>学習の流れ</h2>
          <p>Flow</p>
        </Ttl>

        <Description>
          利用方法は様々。
          <br />
          例えばこんな学習スタイル。
        </Description>

        <Flex>
          <Column>
            <Thumb src={Thumb001} />
            <Case>
              <span>CASE.1</span>
            </Case>
            <ColumnTitle>何をしたらいいか全くわからないけど学習し始めたい</ColumnTitle>
            <List>
              <Item>
                講師とレッスンをして学習
                <br />
                の方向性を決める
              </Item>
              <Item>教材を使って学習</Item>
              <Item>つまづいたらQ&Aで質問</Item>
              <Item>毎日の学習を記録</Item>
            </List>
          </Column>
          <Column>
            <Thumb src={Thumb002} />
            <Case>
              <span>CASE.2</span>
            </Case>
            <ColumnTitle>学習中のPHPの書籍で詰まっているところを質問したい</ColumnTitle>
            <List>
              <Item>つまづいている箇所の質問</Item>
              <Item>
                さらに掘り下げて聞きたい
                <br />
                ところを講師とレッスン
              </Item>
              <Item>毎日の学習を記録</Item>
            </List>
          </Column>
          <Column>
            <Thumb src={Thumb003} />
            <Case>
              <span>CASE.3</span>
            </Case>
            <ColumnTitle>簡単なサイトは作れるが動的なサービスをLaravelで作りたい</ColumnTitle>
            <List>
              <Item>
                PHPやLaravelの教材を
                <br />
                使って学習
              </Item>
              <Item>つまづいたらQ&A掲示板で質問</Item>
              <Item>
                月1回好きなタイミングで
                <br />
                講師とレッスン
              </Item>
              <Item>毎日の学習を記録</Item>
            </List>
          </Column>
        </Flex>
      </StyledInner>
    </Flow>
  );
};

const Flow = styled.section`
  padding: 6.25rem 0 0;
  scroll-margin-top: 49px;

  ${media.lessThan('medium')`
    padding: 4rem 0 0;
    scroll-margin-top: 57px;
  `}
`;
const StyledInner = styled(Inner)`
  max-width: 1280px;
`;
const Description = styled.p`
  margin: 3rem auto;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;

  br {
    display: none;
  }

  ${media.lessThan('medium')`
    margin: 2rem auto;
    font-size: 1rem;
    line-height: 1.375;

    br {
      display: block;
    }
  `}
`;
const Flex = styled.div`
  display: flex;
  padding: 1.75rem 1.25rem 2.125rem;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: calc(100% - 4rem);
    height: calc(100% - 0.75rem);
    margin: auto;
    border-radius: 1rem;
    box-shadow: 1.25rem 1.5rem 5rem rgba(111, 35, 35, 0.15);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 1rem;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    padding: 1rem .5rem;

    &:before {
      width: calc(100% - 1.25rem);
      box-shadow: .875rem .75rem 1.875rem rgba(111, 35, 35, .15);
    }
  `}
`;
const Column = styled.div`
  flex: 1;
  padding-bottom: 2.5rem;
  background: #fffafa;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  z-index: 2;

  &:nth-of-type(n + 2) {
    margin-left: 1.5rem;
  }

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0 auto;

    &:nth-of-type(n + 2) {
      margin: 3rem auto 0;
    }
  `}
`;

const Thumb = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto;
`;
const Case = styled.p`
  margin: 1rem auto 0;
  text-align: center;

  span {
    display: inline-block;
    padding: 0 1rem;
    color: #eb0000;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1.625rem;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 0.8125rem;
      background: #ed2020;
      opacity: 0.25;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  ${media.lessThan('medium')`
    span {
      padding: 0 .625rem;
      font-size: 1.125rem;
      line-height: 1.25rem;

      &:before {
        height: .625rem;
      }
    }
  `}
`;
const ColumnTitle = styled.h3`
  margin: 0.875rem auto 0;
  padding: 0 0.5rem;
  box-sizing: border-box;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.002em;
  text-align: center;

  ${media.lessThan('medium')`
    margin-top: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0;
  `}
`;
const List = styled.ul`
  margin-top: 2.5rem;
  padding: 0 0.5rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
const Item = styled.li`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.008em;
  text-align: center;

  &:nth-of-type(n + 2):before {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 1rem auto;
    background: url(${Arrow}) center / contain no-repeat;
  }

  ${media.lessThan('medium')`
    font-size: 1rem;
    font-weight: 400;
  `}
`;
