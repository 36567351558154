import React from 'react';

import { Breadcrumb } from './Breadcrumb';

interface PracticeSubmitBreadcrumbProps {
  permission: boolean;
  programTitle?: string;
  programID?: number;
  practiceTitle?: string;
  practiceID?: string;
}

export const PracticeSubmitBreadcrumb: React.FC<PracticeSubmitBreadcrumbProps> = (
  props,
): JSX.Element => {
  const breadcrumbs = [
    {
      label: 'ホーム',
      to: '/home',
    },
    {
      label: '教材',
      to: props.permission ? '/programs?type=1' : '/programs',
    },
    {
      label: props.programTitle ?? '教材詳細',
      to: `/programs/${props.programID}/chapters`,
    },
    {
      label: props.practiceTitle ?? '課題詳細',
      to: `/practices/${props.practiceID}/body`,
    },
    {
      label: '課題提出フォーム',
    },
  ];

  return <Breadcrumb links={breadcrumbs} />;
};
