import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  image: string;
  name: string;
  comment: string;
}

export const ResultSlide: React.FC<Props> = (props) => {
  return (
    <Container>
      <Thumb src={props.image} />
      <Info>
        <Name>{props.name}</Name>
        <Comment>{props.comment}</Comment>
      </Info>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  overflow: hidden;
`;
const Thumb = styled.img`
  width: 100%;
  height: auto;
`;
const Info = styled.div`
  padding: 1.5rem 2rem;

  ${media.lessThan('medium')`
    padding: 1.5rem 1rem;
  `}
`;
const Name = styled.h3`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
`;
const Comment = styled.p`
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.375;
`;
