import { useUser } from '../../../redux/user/useUser';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { BasicLayout } from '../../templates/BasicLayout';
import { Button } from '../../atoms/Button';

import {
  useCreateProgramFeedbackMutation,
  useGetProgramQuery,
  FeedbackCategoryType,
} from '../../../gen/graphql';
import { useToastsContext } from '../../../context/ToastsProvider';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { NotFoundPage } from '../public/NotFound';
import { LOWER_META_TITLE } from '../../../const/Service';
import { FormProvider, useForm } from 'react-hook-form';
import { FeedbackForm, FeedbackFormValue } from '../../organisms/FeedbackForm';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { yupResolver } from '@hookform/resolvers/yup';
import { feedbackSchema } from '../../../common/formSchema/feedback';

export const ProgramFeedbackContainer = (): JSX.Element => {
  const params = useParams<{ program_id: string; chapter_id: string }>();
  const pathProgramID = Number(params.program_id);
  const pathChapterID = Number(params.chapter_id);
  if (Number.isNaN(pathProgramID) || Number.isNaN(pathChapterID)) return NotFoundPage;

  return <ProgramFeedback programID={pathProgramID} chapterID={pathChapterID} />;
};

interface ProgramFeedbackProps {
  programID: number;
  chapterID: number;
}

const ProgramFeedback = (props: ProgramFeedbackProps): JSX.Element => {
  const metaTitle = `ご意見・ご要望 | ${LOWER_META_TITLE}`;

  const { showToast } = useToastsContext();

  const [createProgramFeedback] = useCreateProgramFeedbackMutation();
  const navigate = useNavigate();

  const { user, permissionCheck } = useUser();
  const { data: programData } = useGetProgramQuery({
    variables: {
      programID: props.programID,
      userID: user.lmsUser?.id,
    },
    onError: () => {
      // 不正なidの場合教材トップにリダイレクト
      return <Navigate to="/programs" replace />;
    },
  });
  const program = programData?.program;

  const methods = useForm<FeedbackFormValue>({
    defaultValues: {
      category: '0',
      content: '',
    },
    resolver: yupResolver(feedbackSchema),
  });

  const submit = async (data: FeedbackFormValue) => {
    try {
      await createProgramFeedback({
        variables: {
          input: {
            chapterID: props.chapterID,
            feedbackCategoryID: parseInt(data.category),
            content: data.content,
          },
        },
      });
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
      showToast(1, defaultErrorMessage);
    }
  };

  const breadcrumbs = [
    {
      label: '教材TOP',
      to: permissionCheck(FunctionType.ProgramForInstructorAndCoach, PermissionType.Read)
        ? '/programs?type=1'
        : '/programs',
    },
    {
      label: program?.title ?? '',
      to: program?.id ? `/programs/${program.id}/chapters` : '',
    },
    {
      label: 'ご意見・ご要望',
    },
  ];

  return (
    <>
      <BasicLayout pageTitle="教材" metaTitle={metaTitle}>
        <Container>
          <StyledBreadcrumb links={breadcrumbs} />
          <FormProvider {...methods}>
            <FeedbackForm
              title="この章へのご意見・ご要望をお聞かせください"
              onSubmit={submit}
              type={FeedbackCategoryType.Program}
              backButton={
                <Submit
                  onClick={() =>
                    navigate(`/programs/${props.programID}/chapters/${props.chapterID}`)
                  }
                  border
                >
                  <p>教材に戻る</p>
                </Submit>
              }
            />
          </FormProvider>
        </Container>
      </BasicLayout>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 892px;
  margin: 2rem auto;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    flex-direction: column;
    padding: 2rem 0;
  `}
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 1rem;
`;

const Submit = styled(Button)`
  display: block;
  width: 100%;
  max-width: 320px;
  margin: 2rem auto 0;
`;
