import React from 'react';
import { HeadTagProvider } from './HeadTagProvider';
import { MenuProvider } from './MenuProvider';
import { ModalProvider } from './ModalProvider';
import { ApolloClientProvider } from './ApolloClientProvider';
import { ToastsContainer } from './ToastsProvider';
import SimpleReactLightbox from 'simple-react-lightbox';
interface ProviderProps {
  children: React.ReactNode;
}

export const Provider: React.FC<ProviderProps> = ({ children }) => {
  return (
    <SimpleReactLightbox>
      <ApolloClientProvider>
        <HeadTagProvider>
          <MenuProvider>
            <ModalProvider>
              <ToastsContainer>{children}</ToastsContainer>
            </ModalProvider>
          </MenuProvider>
        </HeadTagProvider>
      </ApolloClientProvider>
    </SimpleReactLightbox>
  );
};
