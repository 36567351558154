import stripeJs, { PaymentMethod, StripeError } from '@stripe/stripe-js';
import { Option } from 'fp-ts/Option';
import { Either } from 'fp-ts/Either';
import { TaskEither } from 'fp-ts/lib/TaskEither';

import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import * as O from 'fp-ts/lib/Option';
import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import { defaultErrorMessage } from '../../const/ErrorMessage';

export class LmsStripeValidationError extends Error {}

export class CardNumberError extends LmsStripeValidationError {}

export class CardExpirationError extends LmsStripeValidationError {}

export class CardCvcError extends LmsStripeValidationError {}

export class StripeSystemError extends LmsStripeValidationError {}

export interface Payment {
  paymentId: string;
  cardNumberLast4: string;
  expiration: string;
}

export interface StripePaymentService {
  checkCreditCard(
    elements: Option<stripeJs.StripeElements>,
  ): Promise<Either<LmsStripeValidationError, Payment>>;

  clearElements(elements: Option<stripeJs.StripeElements>): void;
}

export class StripePaymentServiceImpl implements StripePaymentService {
  private readonly stripe: stripeJs.Stripe | null;

  public constructor(stripe: stripeJs.Stripe | null) {
    this.stripe = stripe;
  }

  public clearElements(elements: Option<stripeJs.StripeElements>): void {
    pipe(
      elements,
      O.chainNullableK((element: stripeJs.StripeElements) => {
        O.chainNullableK((e: stripeJs.StripeCardNumberElement) => e.clear())(
          O.fromNullable(element.getElement(CardNumberElement)),
        );
        O.chainNullableK((e: stripeJs.StripeCardExpiryElement) => e.clear())(
          O.fromNullable(element.getElement(CardExpiryElement)),
        );
        O.chainNullableK((e: stripeJs.StripeCardCvcElement) => e.clear())(
          O.fromNullable(element.getElement(CardCvcElement)),
        );
      }),
    );
  }

  public checkCreditCard(
    elements: Option<stripeJs.StripeElements>,
  ): Promise<Either<LmsStripeValidationError, Payment>> {
    return pipe(
      this.checkStripe(this.stripe, elements),
      TE.chain(this.checkElements),
      TE.chain(this.checkCardNumberElement),
      TE.chain(this.checkCreatePaymentMethod),
      TE.chain(this.checkPaymentMethod),
      TE.chain(this.checkCard),
    )(); //FIXME 本来はTaskEitherのまま引き渡したいが、なぜかrunしなかったりしてハマったので、一旦Promise<Either>に変換して返している。
  }

  private checkStripe(
    stripe: stripeJs.Stripe | null,
    elements: Option<stripeJs.StripeElements>,
  ): TaskEither<
    StripeSystemError,
    { stripe: stripeJs.Stripe; elements: Option<stripeJs.StripeElements> }
  > {
    return pipe(
      TE.fromOption(() => new StripeSystemError())(O.fromNullable(stripe)),
      TE.map((result) => {
        return {
          stripe: result,
          elements: elements,
        };
      }),
    );
  }

  private checkElements(args: {
    stripe: stripeJs.Stripe;
    elements: Option<stripeJs.StripeElements>;
  }): TaskEither<
    StripeSystemError,
    { stripe: stripeJs.Stripe; elements: stripeJs.StripeElements }
  > {
    return pipe(
      TE.fromOption(() => new StripeSystemError())(args.elements),
      TE.map((elem: stripeJs.StripeElements) => {
        return {
          stripe: args.stripe,
          elements: elem,
        };
      }),
    );
  }

  private checkCardNumberElement(args: {
    stripe: stripeJs.Stripe;
    elements: stripeJs.StripeElements;
  }): TaskEither<
    StripeSystemError,
    { stripe: stripeJs.Stripe; cardNumberElement: stripeJs.StripeCardNumberElement }
  > {
    return pipe(
      TE.fromOption(() => new StripeSystemError())(
        O.fromNullable(args.elements.getElement(CardNumberElement)),
      ),
      TE.map((cardNumberElement: stripeJs.StripeCardNumberElement) => {
        return {
          stripe: args.stripe,
          cardNumberElement: cardNumberElement,
        };
      }),
    );
  }

  private checkCreatePaymentMethod(args: {
    stripe: stripeJs.Stripe;
    cardNumberElement: stripeJs.StripeCardNumberElement;
  }): TaskEither<LmsStripeValidationError, Option<PaymentMethod>> {
    return TE.tryCatch<LmsStripeValidationError, Option<PaymentMethod>>(
      () =>
        args.stripe
          .createPaymentMethod({
            type: 'card',
            card: args.cardNumberElement,
            //子要素のカード番号エレメントのみを渡すと有効期限/CVCの情報も子要素から探して送信するというトリッキーな動き
          })
          .then((result: { paymentMethod?: PaymentMethod; error?: StripeError }) =>
            pipe(
              O.fromNullable(result.error),
              O.fold(
                () => Promise.resolve(O.fromNullable(result.paymentMethod)),
                (error: StripeError) => Promise.reject(this.replaceLmsError(error)),
              ),
            ),
          ),
      (error) => {
        if (error instanceof LmsStripeValidationError) {
          return error;
        } else {
          return new StripeSystemError();
        }
      },
    );
  }

  private checkPaymentMethod(
    paymentMethod: Option<PaymentMethod>,
  ): TaskEither<StripeSystemError, stripeJs.PaymentMethod> {
    return pipe(TE.fromOption(() => new StripeSystemError())(paymentMethod));
  }

  private checkCard(method: stripeJs.PaymentMethod): TaskEither<StripeSystemError, Payment> {
    return pipe(
      TE.fromOption(() => new StripeSystemError())(O.fromNullable(method.card)),
      TE.map((card: stripeJs.PaymentMethod.Card) => {
        return {
          paymentId: method.id,
          cardNumberLast4: card.last4,
          expiration: card.exp_month + '/' + card.exp_year,
        };
      }),
    );
  }

  private replaceLmsError(error: stripeJs.StripeError): LmsStripeValidationError {
    //https://stripe.com/docs/api/errors#errors-validation_error
    // ざっと、エラーの種類を記載
    // api_connection_error    Failure to connect to Stripe's API.
    // api_error   API errors cover any other type of problem (e.g., a temporary problem with Stripe's servers), and are extremely uncommon.
    // authentication_error    Failure to properly authenticate yourself in the request.
    // card_error  Card errors are the most common type of error you should expect to handle. They result when the user enters a card that can't be charged for some reason.
    // idempotency_error   Idempotency errors occur when an Idempotency-Key is re-used on a request that does not match the first request's API endpoint and parameters.
    // invalid_request_error   Invalid request errors arise when your request has invalid parameters.
    // rate_limit_error    Too many requests hit the API too quickly.
    // validation_error    Errors triggered by our client-side libraries when failing to validate fields (e.g., when a card number or expiration date is invalid or incomplete).

    switch (error.code) {
      case 'incomplete_number': //カード番号に不備があります。
      case 'invalid_number': //カード番号が無効です。
        return new CardNumberError(error.message ? error.message : '');
      case 'incomplete_expiry': //カードの有効期限の日付に不備があります。
      case 'invalid_expiry_year_past': //カードの有効期限が過ぎています。
      case 'invalid_expiry_year': //カードの有効期限の日付が無効です。72年以降を指定すると発生
        return new CardExpirationError(error.message ? error.message : '');
      case 'incomplete_cvc': //カードのセキュリティコードに不備があります。
        return new CardCvcError(error.message ? error.message : '');
      default:
        return new CardCvcError(defaultErrorMessage);
    }
  }
}
