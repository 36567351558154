import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { HomeSummaryElement } from '../molecules/HomeSummaryElement';
import { VerticalUserInfo } from '../molecules/VerticalUserInfo';
import CalendarIcon from '../../static/image/icon_calendar.svg';
import BookIcon from '../../static/image/icon_book_orange.svg';
import HeadsetIcon from '../../static/image/icon_headset.svg';
import TicketIcon from '../../static/image/icon_ticket.svg';
import { Tickets } from '../molecules/Tickets';
import { CurrentUserFragment as User, TicketFragment as Ticket } from '../../gen/graphql';

interface HomeSummaryProps {
  user: User;
  studyReportCurrentRunningLayDays: number;
  studyReportRunningLayDays: number;
  studyReportCount: number;
  completedProgramCount: number;
  totalLessonCount: number;
  tickets: Ticket[];
}

export const GeneralHomeSummary: React.FC<HomeSummaryProps> = (props) => {
  return (
    <Container>
      <VerticalUserInfo
        user={props.user}
        currentRunningLayDays={props.studyReportCurrentRunningLayDays}
        runningLayDays={props.studyReportRunningLayDays}
      />
      <HomeSummaryElement icon={CalendarIcon} name="総学習回数" value={props.studyReportCount} />
      <HomeSummaryElement icon={BookIcon} name="完了教材数" value={props.completedProgramCount} />
      <HomeSummaryElement icon={HeadsetIcon} name="総レッスン数" value={props.totalLessonCount} />
      <HomeSummaryElement
        icon={TicketIcon}
        name="レッスンチケット"
        value={props.tickets.length}
        noteNode={
          <TicketDue>
            <StyledTickets tickets={props.tickets} />
            <TicketDueLabel>(有効期限)</TicketDueLabel>
          </TicketDue>
        }
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  align-items: center;

  & > * {
    box-sizing: border-box;
    width: 25%;
  }

  & > * + * {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('medium')`
    flex-wrap: wrap;
    padding: 8px;
    border-radius: 2px;
    align-items: stretch;

    & > *:first-child {
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 16px;
      margin-bottom: 6px;
    }
    
    & > *:not(:first-child) {
      width: 50%;
      border: none;
      padding: 16px;
    }

    & > *:not(:first-child):nth-of-type(odd) {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    & > *:nth-of-type(n + 4) {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  `}
`;

const StyledTickets = styled(Tickets)`
  display: none;
  bottom: 1.5rem;
  right: 0;
`;

const TicketDue = styled.p`
  cursor: pointer;
  &:hover ${StyledTickets} {
    display: block;
  }
`;

const TicketDueLabel = styled.span`
  font-size: 0.5625rem;
  color: #fd3c2f;
  opacity: 0.8;
  text-decoration: underline;
  text-align: center;
  vertical-align: top;
`;
