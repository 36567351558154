import React from 'react';
import styled from 'styled-components';

import { Tab } from '../atoms/Tab';

export interface TabProps {
  label: string;
  to: string;
  onClick?: () => void;
  active: boolean;
  count?: number;
}

interface TabNavigationProps {
  tabs: TabProps[];
}

export const TabNavigation: React.FC<TabNavigationProps> = (props): JSX.Element => {
  return (
    <Container id="TabNavigation">
      {props.tabs.map((tab, i): JSX.Element => {
        return (
          <Tab
            key={i}
            to={tab.to}
            onClick={tab.onClick}
            label={tab.label}
            active={tab.active}
            count={tab.count}
          />
        );
      })}
    </Container>
  );
};

export default TabNavigation;

const Container = styled.nav`
  flex: 1;
  width: auto;
  white-space: nowrap;
  height: 3rem;
  box-sizing: border-box;
  padding: 0 1rem 2px;
  overflow: auto hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * + * {
    margin-left: 20px;
  }
`;
