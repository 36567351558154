import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { useUser } from '../../redux/user/useUser';
import { MenuContext } from '../../context/MenuProvider';
import { HeadTagContext } from '../../context/HeadTagProvider';

import { Navigation } from '../organisms/Navigation';
import { AppBar } from '../organisms/AppBar';
import { CommonModals } from '../organisms/CommonModals';
import { MvFull } from '../organisms/MvFull';
import { Footer } from '../organisms/Footer';

import { Z_INDEX_DRAWER } from '../../const/ZIndex';
import { useMetaData } from '../../common/customHooks/MetaReplace';
import { CampaignSnackbar } from '../organisms/CampaignSnackbar';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface BasicLayoutProps {
  pageTitle: string;

  /**
   * Intercom表示対象画面か。
   * (表示対象画面の場合は、表示対象ロールの場合にIntercomを表示。)
   */
  hasIntercom?: boolean;

  hideFooter?: boolean;
  metaTitle: string;
  metaDescription?: string;
}

export const BasicLayout: React.FC<BasicLayoutProps> = (props) => {
  const { isOpen } = useContext(MenuContext);
  const { logout, user, permissionCheck } = useUser();
  const headTagContext = React.useContext(HeadTagContext);
  const hamburgerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useMetaData(props.metaTitle, props.metaDescription);

  return (
    <Wrapper>
      <Helmet title={headTagContext.title} meta={headTagContext.meta} />
      <Container isOpen={isOpen}>
        <Navigation pageTitle={props.pageTitle} naviRef={hamburgerRef} />
        <Div isOpen={isOpen}>
          <AppBar
            pageTitle={props.pageTitle}
            handleLogout={logout}
            logoutTransition={'/'}
            appBarRef={hamburgerRef}
            hasIntercom={props.hasIntercom}
          />
          {permissionCheck(FunctionType.AdsForFree, PermissionType.Read) &&
            user.lmsUser?.createdAt && (
              <CampaignSnackbar userCreatedAt={new Date(user.lmsUser.createdAt)} />
            )}
          {location.pathname === '/home' && !user.lmsUser && (
            <MvFull>
              月定額2,980円〜
              <br />
              現役エンジニアに質問し放題の
              <br />
              プログラミングスクール
            </MvFull>
          )}
          <Main>{props.children}</Main>
          {props.hideFooter ?? <Footer />}
        </Div>
      </Container>
      <CommonModals />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;

  ${media.lessThan('medium')`
    transition: transform 0.2s ease-out;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
      position: fixed;
      top: 0;
      left: 0;
      pointer-events: none;
      opacity: 0;
      transition: all .1s;
      z-index: ${Z_INDEX_DRAWER - 1};
    }
  `}
  ${(props) => {
    return props.isOpen
      ? media.lessThan('medium')`
        &:before {
          pointer-events: all;
          opacity: 1;
        }
      `
      : '';
  }}
`;

const Div = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  padding-left: ${(props) => (props.isOpen ? '168' : '68')}px;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding-left: 0;
  `}
`;

const Main = styled.main`
  transition: transform 0.1s ease-out 0.1s;
`;
