import React, { useContext, useEffect, useMemo } from 'react';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import TabNavigationLayout from './TabNavigationLayout';
import { HeadTagContext } from '../../context/HeadTagProvider';

type TabType =
  | 'profile'
  | 'course'
  | 'subscription'
  | 'invoice'
  | 'payment_intent'
  | 'payment'
  | 'mail'
  | 'password'
  | 'alignment'
  | 'cancel';

interface Props {
  activeTab: TabType;
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  isSocialLoginUser: boolean;
  hasIntercom?: boolean;
  metaTitle: string;
  metaDescription?: string;
}

export const AccountTabLayout: React.FC<Props> = ({
  activeTab,
  permissionCheck,
  isSocialLoginUser,
  hasIntercom,
  metaTitle,
  metaDescription,
  children,
}): JSX.Element => {
  const tabs = useMemo(() => {
    const tabs = [];

    if (permissionCheck(FunctionType.AccountProfile, PermissionType.Read)) {
      const profile = {
        label: 'プロフィール',
        to: '/account/profile',
        active: activeTab === 'profile',
      };
      tabs.push(profile);
    }

    if (permissionCheck(FunctionType.AccountCourse, PermissionType.Read)) {
      const course = {
        label: 'マンツーマン契約',
        to: '/account/course',
        active: activeTab === 'course',
      };
      tabs.push(course);
    }

    if (permissionCheck(FunctionType.AccountSubscription, PermissionType.Read)) {
      const subscription = {
        label: '加入プラン',
        to: '/account/subscription',
        active: activeTab === 'subscription',
      };
      tabs.push(subscription);
    }

    if (permissionCheck(FunctionType.AccountInvoice, PermissionType.Read)) {
      const invoice = {
        label: '請求情報',
        to: '/account/invoice',
        active: activeTab === 'invoice',
      };
      tabs.push(invoice);
    }

    if (permissionCheck(FunctionType.AccountPaymentIntent, PermissionType.Read)) {
      const paymentIntent = {
        label: '購入履歴',
        to: '/account/payment_intent',
        active: activeTab === 'payment_intent',
      };
      tabs.push(paymentIntent);
    }

    if (permissionCheck(FunctionType.AccountPayment, PermissionType.Read)) {
      const payment = {
        label: '支払方法',
        to: '/account/payment',
        active: activeTab === 'payment',
      };
      tabs.push(payment);
    }

    if (permissionCheck(FunctionType.AccountMail, PermissionType.Update)) {
      const mail = { label: 'メール設定', to: '/account/mail', active: activeTab === 'mail' };
      tabs.push(mail);
    }

    if (
      permissionCheck(FunctionType.AccountPassword, PermissionType.Update) &&
      !isSocialLoginUser
    ) {
      const password = {
        label: 'パスワード設定',
        to: '/account/password',
        active: activeTab === 'password',
      };
      tabs.push(password);
    }

    if (permissionCheck(FunctionType.AccountAlignment, PermissionType.Read)) {
      const alignment = {
        label: '連携',
        to: '/account/alignment',
        active: activeTab === 'alignment',
      };
      tabs.push(alignment);
    }

    return tabs;
  }, [permissionCheck, activeTab, isSocialLoginUser]);

  const { changeTitle, changeMeta } = useContext(HeadTagContext);

  useEffect(() => {
    const meta = [];
    if (metaTitle) {
      changeTitle(metaTitle);
      meta.push({
        name: 'og:title',
        content: metaTitle,
      });
    }
    if (metaDescription) {
      meta.push({
        name: 'description',
        content: metaDescription,
      });
      meta.push({
        name: 'og:description',
        content: metaDescription,
      });
    }

    if (meta.length) {
      changeMeta(meta);
    }
  }, [changeTitle, changeMeta, metaTitle, metaDescription]);

  return (
    <TabNavigationLayout
      pageTitle="アカウント"
      tabs={tabs}
      hasIntercom={hasIntercom}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {children}
    </TabNavigationLayout>
  );
};
