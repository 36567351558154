import React, { useCallback } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';

import { Button } from '../atoms/Button';
import { H1 } from '../atoms/Typography';

import { useUser } from '../../redux/user/useUser';
import LessonImg from '../../static/image/spot_lesson_img.png';
import { SPOT_LESSON_TIME } from '../../const/SpotLesson';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface Props {
  onClickBuyTicket: () => void;
}

export const SpotLessonBanner: React.FC<Props> = ({ onClickBuyTicket }): JSX.Element => {
  const navigate = useNavigate();
  const { permissionCheck } = useUser();
  const { openModal } = useCommonModal();

  const handleClickReserveButton = useCallback(() => {
    if (openModal(FunctionType.SpotLessonReserve, PermissionType.Create)) return;
    navigate('/spot_lessons/reserve');
  }, [openModal, navigate]);

  return (
    <Container>
      <H1 color={'DARK'}>現役エンジニアとのオンラインマンツーマンレッスン</H1>
      <p>
        「レッスン」は、現役エンジニアと{SPOT_LESSON_TIME}
        分間、マンツーマンでレッスンできるサービスです。
        <br />
        Q&Aで解決できない悩みや言語化できない疑問をインストラクターに相談してみましょう。
      </p>
      <HelpLink
        href="https://intercom.help/plus---samurai/ja/collections/2980849-%E3%83%AC%E3%83%83%E3%82%B9%E3%83%B3%E3%81%AE%E6%A6%82%E8%A6%81-%E4%BA%88%E7%B4%84-%E3%83%81%E3%82%B1%E3%83%83%E3%83%88-%E3%82%AD%E3%83%A3%E3%83%B3%E3%82%BB%E3%83%AB"
        target="_blank"
      >
        レッスンとは？
      </HelpLink>
      <HelpLink
        href="https://intercom.help/plus---samurai/ja/articles/8339547-%E3%83%AC%E3%83%83%E3%82%B9%E3%83%B3%E3%81%94%E4%BA%88%E7%B4%84%E6%99%82%E3%81%AE%E3%81%8A%E9%A1%98%E3%81%84"
        target="_blank"
      >
        レッスンご予約時のお願い
      </HelpLink>
      <ButtonContainer>
        <Button onClick={handleClickReserveButton}>レッスンを予約する</Button>
        {(permissionCheck(FunctionType.SpotLessonBuyTicket, PermissionType.Create) ||
          permissionCheck(FunctionType.RecommendFree, PermissionType.Read) ||
          permissionCheck(FunctionType.RecommendLight, PermissionType.Read)) && (
          <Button onClick={onClickBuyTicket} border>
            チケットを購入する
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 1.6rem 15.875rem 3rem 2rem;
  box-sizing: border-box;
  background-color: #e5ecfd;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.8;
  position: relative;

  span {
    font-size: 1rem;
    font-weight: 400;
  }

  &:after {
    content: '';
    display: block;
    max-width: 440px;
    ${media.lessThan('medium')`
      max-width: 100%; 
    `}
    width: 30%;
    height: 0;
    padding-top: calc(300% / 2);
    background: url(${LessonImg}) bottom center / contain no-repeat;
    position: absolute;
    bottom: 1rem;
    right: 2.25rem;
    pointer-events: none;
  }

  ${media.lessThan('medium')`
    margin-bottom: 1.5rem;
    padding: 2rem 1rem;
    box-sizing: border-box;
    font-size: 0.75rem;
    line-height: 1.5;

    span {
      font-size: 0.875rem;
    }

    &:after {
      bottom: 8.5rem;
      height: 180px;
      width: 300px;
      padding-top: 0;
      transform: translateX(-50%);
      left: 50%;
    }
  `}

  ${media.lessThan('small')`
    br {
      display: none;
    }
  `}
`;

const HelpLink = styled.a`
  padding-right: 1rem;
  color: #eb0000;
  font-size: 0.8rem;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    justify-content: center;
    gap: .625rem;
    margin-top: 11.25rem;
  `}
`;
