import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { isNonEmpty } from 'fp-ts/es6/Array';
import {
  SessionPhase as Phase,
  SessionListForStudentFragment as Session,
  useGetSessionsForStudentQuery,
} from '../../../gen/graphql';
import { BasicLayout } from '../../templates/BasicLayout';
import { Chip } from '../../atoms/Chip';
import { Loader } from '../../molecules/Loader';

import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import { useCategorizedSessionList } from '../../../common/customHooks/CategorizedSessionList';
import defaultIcon from '../../../static/image/icon_default.svg';
import LessonCircleSvg from '../../../static/image/icon_lesson_circle.svg';

export const StudentSession: React.FC = (): JSX.Element => {
  const metaTitle = `セッション | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のセッションページです。セッション日の確認やこれまでのセッション履歴を確認できます。`;

  const { data, loading } = useGetSessionsForStudentQuery();
  const { pastSessions, featureSessions, nextSession } = useCategorizedSessionList(
    data?.sessionsForStudent ?? [],
  );

  return (
    <BasicLayout
      pageTitle="セッション"
      hasIntercom
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      <Loader display={loading} />
      <Container>
        <Info>
          <NextLesson>
            {nextSession ? (
              <>
                <h4>次回セッション</h4>
                <section>
                  <SessionDate>
                    <SessionDateContent
                      startAt={new Date(nextSession.startAt)}
                      endAt={new Date(nextSession.endAt)}
                    />
                  </SessionDate>
                  <div>
                    <h5>{nextSession.title}</h5>

                    <Instructor>
                      <span>
                        <IconImage
                          src={nextSession.coaching.coach?.user.image || defaultIcon}
                          alt="icon"
                        />
                      </span>
                      <span>{nextSession.coaching.coach?.user.maskedPersonalInfo?.name}</span>
                    </Instructor>
                  </div>
                </section>
              </>
            ) : (
              <>
                <h4>次回以降のセッションが未登録の状態です。</h4>
                <div>
                  <p>担当コーチがセッション日程を登録すると表示されます。</p>
                  <p>
                    まだセッション日程を決めていない場合は、メッセージ機能を使って相談しましょう。
                  </p>
                </div>
              </>
            )}
          </NextLesson>
          <Inquiry>
            <H3>お困りですか？</H3>
            <ul>
              <li>・コーチと連絡が取れない</li>
              <li>・レッスン開始時間になってもコーチがこない・連絡が取れない</li>
              <li>・レッスン直前にコーチに授業をキャンセルされた</li>
            </ul>
            <p>
              などの場合は
              <a href="https://tayori.com/faq/2afb58b87ebf5105ff984f92aad98eb81be77847/detail/2508beebff951fb788306a8ee320372a2aab6c0d">
                こちら
              </a>
              から運営にご連絡ください。
            </p>
          </Inquiry>
        </Info>

        <Lessons>
          <H3>今後のセッション予定</H3>
          {featureSessions.map((session) => (
            <SessionContent key={session.id} session={session} />
          ))}
        </Lessons>

        <Lessons>
          <H3>これまでのセッション履歴（計 {pastSessions.length} 回）</H3>
          {isNonEmpty(pastSessions) ? (
            pastSessions.map((session) => <SessionContent key={session.id} session={session} />)
          ) : (
            <Unregistered>
              <img src={LessonCircleSvg} alt="レッスン未登録" />
              <h4>まだセッション履歴がありません。</h4>
              <p>過去のセッションの一覧がここに表示されます。</p>
            </Unregistered>
          )}
        </Lessons>
      </Container>
    </BasicLayout>
  );
};

const SessionDateContent = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => (
  <>
    <time>
      {format(startAt, 'MM/dd')}
      <span>({format(startAt, 'E', { locale: ja })})</span>
    </time>
    <time>
      {format(startAt, 'HH:mm')}〜{format(endAt, 'HH:mm')}
    </time>
  </>
);

const SessionContent = ({ session }: { session: Session }) => (
  <StyledSessionContent>
    <SessionDate>
      <SessionDateContent startAt={new Date(session.startAt)} endAt={new Date(session.endAt)} />
      {session.phase === Phase.CanceledByStudent && (
        <CancelLabel>
          キャンセル
          <br />
          （レッスン消化）
        </CancelLabel>
      )}
    </SessionDate>
    <div>
      <Chip>{session.coaching.course.plan.name}</Chip>
      <h5>{session.title}</h5>
      <Instructor>
        <span>
          <IconImage src={session.coaching.coach?.user.image || defaultIcon} alt="icon" />
        </span>
        <span>{session.coaching.coach?.user.maskedPersonalInfo?.name}</span>
      </Instructor>
    </div>
  </StyledSessionContent>
);

const Container = styled.div`
  padding: 2rem;
`;

const H3 = styled.h3`
  margin-bottom: 1rem;
  font-weight: 600;
`;

const Info = styled.div`
  display: flex;
  max-width: 52.25rem;
  margin-bottom: 2rem;

  ${media.lessThan('large')`
    flex-direction: column;
  `}
`;
const NextLesson = styled.div`
  flex: 1;
  padding: 1.25rem;
  background-color: #ffe86d;
  box-sizing: border-box;
  line-height: 1.3;

  h4 {
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  section {
    display: flex;
  }

  h5 {
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.75rem;
  }

  ${media.lessThan('small')`
    section {
      flex-direction: column;
    }
  `}
`;

const Inquiry = styled.div`
  flex: 1;
  margin-left: 1rem;
  padding: 1.25rem;
  border: 1px solid #ddd;
  box-sizing: border-box;
  line-height: 1.3;

  p,
  li,
  a {
    font-size: 0.75rem;
  }

  p {
    margin-top: 0.75rem;
  }

  a {
    color: #e2001b;
  }

  ${media.lessThan('large')`
    margin: 1rem 0 0;
  `}
`;

const Lessons = styled.section`
  margin-bottom: 2rem;
`;

const StyledSessionContent = styled.section`
  display: flex;
  padding: 1rem;
  width: 100%;
  max-width: 52.5rem;
  box-sizing: border-box;
  line-height: 1.3;

  h5 {
    margin: 0.625rem 0.375rem;
    font-weight: 600;
  }

  ${media.lessThan('small')`
    flex-direction: column;
    width: auto;
    margin: 1rem -2rem 0;
    padding: 1rem 2rem 0;
    border-top: 1px dotted #ddd;
  `}
`;

const SessionDate = styled.div`
  margin-right: 2rem;
  text-align: left;

  time {
    display: block;
    font-size: 0.75rem;

    &:nth-of-type(1) {
      font-size: 1.5rem;
      font-weight: 600;

      span {
        font-size: 0.875rem;
        font-weight: 300;
      }
    }
  }

  ${media.lessThan('small')`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 1rem;
    position: relative;

    time {
      &:nth-of-type(1) {
        margin-right: .25rem;
        font-size: 1.25rem;

        span {
          font-size: .75rem;
        }
      }
    }
  `}
`;

const IconImage = styled.img`
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
`;

const Instructor = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  span {
    font-size: 0.75rem;

    + span {
      margin-left: 0.5rem;
    }
  }
`;

const Unregistered = styled.div`
  width: 100%;
  margin-top: 4rem;
  text-align: center;

  h4 {
    margin-top: 2rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  p {
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const CancelLabel = styled.p`
  margin: 0.2rem;
  padding: 0.25rem 0;
  border: 1px solid #e2001b;
  border-radius: 0.25rem;
  color: #e2001b;
  font-size: 0.625rem;
  font-weight: 700;
  text-align: center;

  ${media.lessThan('small')`
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
  `}
`;
