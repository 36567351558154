import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  excludeTeamMember: () => void;
}

export const TeamExcludeModal: React.FC<Props> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      loading={props.loading}
      width={'576px'}
      header={<Title>メンバーを外す</Title>}
      footer={
        <ButtonWrapper>
          <CancelButton onClick={props.onClose}>取り消す</CancelButton>
          <ExcludeButton onClick={props.excludeTeamMember}>メンバーを外す</ExcludeButton>
        </ButtonWrapper>
      }
    >
      <Container>
        <Description>
          選択したメンバーを外す場合は、
          <SpBr />
          問題にならないよう
          <PcBr />
          事前に連絡をし、
          <SpBr />
          チームから外す理由を明確に説明した後に行ってください。
        </Description>
        <Note>
          チームメンバーの除外について、詳しくはこちらの
          <a
            href="https://intercom.help/plus---samurai/ja/articles/6622179-q-%E6%B3%95%E4%BA%BA%E3%83%97%E3%83%A9%E3%83%B3%E3%81%AE%E3%83%81%E3%83%BC%E3%83%A0%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%AE%E5%89%8A%E9%99%A4%E3%81%A8%E9%99%A4%E5%A4%96%E3%81%AE%E9%81%95%E3%81%84%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
            target="_blank"
          >
            ヘルプページ
          </a>
          をご覧ください。
        </Note>
      </Container>
    </Modal>
  );
};

const Title = styled.h3`
  width: 100%;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  border-radius: 0.125rem;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition: all 0.2s;

  &:hover {
    background: #efefef;
  }
`;
const ExcludeButton = styled(Button)`
  display: block;
  width: 10rem;
  padding: 0.5rem;
  margin-left: 1rem;
  background: #eb0000;
  border: 1px solid #eb0000;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    color: #eb0000;
  }
`;

const Container = styled.div`
  max-width: 480px;
  margin: 0 auto;
  padding: 1.25rem 1.5rem 3rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 1rem 1rem 2.25rem;
  `}
`;
const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: .875rem;
  `}
`;
const Note = styled.p`
  margin-top: 1rem;
  font-size: 0.75rem;
  line-height: 1.25rem;

  a {
    color: #1a0dab;
    font-size: 1em;
    text-decoration: underline;
  }

  ${media.lessThan('medium')`
    margin-top: 2.375rem;
  `}
`;
const PcBr = styled.br`
  ${media.lessThan('small')`
    display: none;
  `}
`;
const SpBr = styled.br`
  display: none;

  ${media.lessThan('small')`
    display: block;
  `}
`;
