import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Auth } from 'aws-amplify';
import { isSome } from 'fp-ts/Option';

import { LabeledLayout } from '../../molecules/LabeledLayout';
import { Button } from '../../atoms/Button';
import { Spacer } from '../../atoms/Spacer';
import { H3 } from '../../atoms/Typography';
import { Input } from '../../atoms/Input';

import { useToastsContext } from '../../../context/ToastsProvider';

import { UserPasswordValidation } from '../../../utils/FormValidation';

import { LOWER_META_TITLE } from '../../../const/Service';
import { AccountTabLayout } from '../../templates/AccountTabLayout';
import { useUser } from '../../../redux/user/useUser';

type PassKeys = 'passOld' | 'passNew' | 'passNewRetype';

export const AccountPassword: React.FC = (): JSX.Element => {
  const metaTitle = `パスワード設定 | ${LOWER_META_TITLE}`;

  const { permissionCheck, isSocialLoginUser } = useUser();
  const { showToast } = useToastsContext();

  const [password, setPassword] = useState<{ [key in PassKeys]: string }>({
    passOld: '',
    passNew: '',
    passNewRetype: '',
  });
  const [errors, setErrors] = useState<{ [key in PassKeys]: boolean }>({
    passOld: false,
    passNew: false,
    passNewRetype: false,
  });

  const [error, setError] = useState('');

  const checkPassword = useCallback(
    (restrict?: boolean): boolean => {
      const result = Object.keys(password).filter(
        (key): boolean =>
          isSome(UserPasswordValidation(password[key as PassKeys])) &&
          (restrict || password[key as PassKeys] !== ''),
      );
      if (result.length > 0) {
        setError(
          '大文字、小文字、数字それぞれを最低1字含む8文字以上のパスワードを入力してください',
        );
        setErrors(
          Object.keys(password).reduce(
            (acc, k): { [key in PassKeys]: boolean } => ({
              ...acc,
              [k as PassKeys]: result.indexOf(k) >= 0,
            }),
            errors,
          ),
        );
      } else if (
        password.passNew !== password.passNewRetype &&
        password.passNew !== '' &&
        password.passNewRetype !== ''
      ) {
        setError('新しいパスワードと新しいパスワード(再入力)が一致しません');
        setErrors({ ...errors, passNew: true, passNewRetype: true });
      } else {
        setError('');
        setErrors({
          passOld: false,
          passNew: false,
          passNewRetype: false,
        });
        return true;
      }
      return false;
    },
    [errors, password],
  );

  const handleInputPassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword({ ...password, [e.target.name]: e.target.value.trim() });
  };

  const handleSubmit = async (): Promise<void> => {
    if (!checkPassword(true)) return;

    await Auth.changePassword(
      await Auth.currentAuthenticatedUser(),
      password.passOld,
      password.passNew,
    )
      .then((): void => {
        showToast(0, 'パスワードの変更に成功しました');
      })
      .catch((): void => {
        showToast(1, 'パスワードの変更に失敗しました');
        setPassword({
          passOld: '',
          passNew: '',
          passNewRetype: '',
        });
      });
  };

  useEffect((): void => {
    checkPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <AccountTabLayout
      activeTab="password"
      permissionCheck={permissionCheck}
      isSocialLoginUser={isSocialLoginUser()}
      metaTitle={metaTitle}
    >
      <Container>
        <Content>
          <H3 color={'DARK'}>
            パスワード変更<Note>(大文字,小文字,数字を組み合わせた8文字以上)</Note>
          </H3>
          <LabeledLayout labelWidth={'300px'} label={<span>現在のパスワード</span>} required>
            <Input
              name="passOld"
              width="100%"
              value={password.passOld}
              onChange={handleInputPassword}
              type="password"
              error={errors.passOld}
            />
          </LabeledLayout>
          <LabeledLayout labelWidth={'300px'} label={<span>新しいパスワード</span>} required>
            <Input
              name="passNew"
              width="100%"
              value={password.passNew}
              onChange={handleInputPassword}
              type="password"
              error={errors.passNew}
            />
          </LabeledLayout>
          <LabeledLayout
            labelWidth={'300px'}
            label={<span>新しいパスワード(再入力)</span>}
            required
          >
            <Input
              name="passNewRetype"
              width="100%"
              value={password.passNewRetype}
              onChange={handleInputPassword}
              type="password"
              error={errors.passNewRetype}
            />
          </LabeledLayout>
          <Error>{error}</Error>
          <Spacer height={'1rem'} />
          <Button onClick={handleSubmit}>変更する</Button>
        </Content>
      </Container>
    </AccountTabLayout>
  );
};

export default AccountPassword;

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  padding: 2rem;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  border: solid 1px rgba(0, 0, 0, 0.1);
`;

const Error = styled.p`
  margin-top: 1rem;
  color: red;
`;

const Note = styled.span`
  margin-left: 0.25rem;
  color: rgba(0, 0, 0, 0.36);
  font-size: 0.75rem;
  line-height: 1rem;

  ${media.lessThan('medium')`
    display: block;
    margin: .5rem auto 0;
  `}
`;
