import React from 'react';
import styled from 'styled-components';

import Party from '../../static/image/party.png';

import { H3, Normal } from '../atoms/Typography';
import { ShareButton } from '../molecules/ShareButton';

interface StudyReportCreateModalProps {
  tweetContent: string;
}

export const StudyReportCreatedModal: React.FC<StudyReportCreateModalProps> = (props) => {
  return (
    <Container>
      <img src={Party} alt="パーティークラッカー" />
      <ContentArea>
        <H3 color="#222222">学習ログが記録されました！</H3>
        <Normal color="#222222">ツイッターで学習状況をシェアしましょう！</Normal>
        <ShareButtonArea>
          <ShareButton content={props.tweetContent} hashTags={['侍エンジニア']} />
        </ShareButtonArea>
      </ContentArea>
    </Container>
  );
};

const Container = styled.div`
  width: 428px;
  background-color: #ffffff;
  text-align: center;
  box-sizing: border-box;
  padding: 2rem;
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShareButtonArea = styled.div`
  margin-top: 2rem;
`;
