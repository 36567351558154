import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { getQuestionLabelType } from '../../utils/Question';
import { QuestionLabels } from '../../const/Question';
import { AnswerRequestType } from '../../gen/graphql';

interface QuestionBadgeProps {
  answersCount: number;
  solved: boolean;
  isDraft: boolean;
  answerRequestType: AnswerRequestType;
  className?: string;
}

export const QuestionBadge: React.FC<QuestionBadgeProps> = (props) => {
  return (
    <Container className={props.className}>
      <Label solved={props.solved}>
        {QuestionLabels[getQuestionLabelType(props.solved, props.isDraft, props.answerRequestType)]}
      </Label>
      <Count>
        <Num>{props.answersCount}</Num>
        回答
      </Count>
    </Container>
  );
};

const Container = styled.div`
  width: 5rem;

  ${media.lessThan('medium')`
    width: 2.875rem;
  `}
`;
const Label = styled.p<{ solved: boolean }>`
  padding: 0.25rem;
  font-size: 0.625rem;
  line-height: 0.75rem;
  text-align: center;

  ${(props) =>
    props.solved
      ? `
    background: #fce5e8;
    color: #fd3c2f;
  `
      : `
    background: #f5f5f5;
    color: rgba(0,0,0,.6);
  `}

  ${media.lessThan('medium')`
    padding: .375rem 0;
    line-height: 1;
  `}
`;
const Count = styled.p`
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.36);
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;

  ${media.lessThan('medium')`
    margin-top: .375rem;
  `}
`;
const Num = styled.span`
  display: block;
  margin-bottom: 0.375rem;
  font-size: 1.5rem;
  line-height: 1;
`;
