import { ErrorCode } from '../gen/graphql';

export const defaultErrorMessage =
  'システムエラーで処理に失敗しました。お困りの場合はユーザーサポートまでお問い合わせお願いたします。';

export const errorMessages = new Map<ErrorCode, string>([
  [
    ErrorCode.UserAlreadyExists,
    '会員の登録に失敗しました。すでに登録済のユーザーは登録いただけません。',
  ],
  [
    ErrorCode.UserAlreadyDeleted,
    '会員の登録に失敗しました。以前削除されたアカウントと同じメールアドレスを使用して、新たなユーザー登録を行うことはできません。',
  ],
  [ErrorCode.CommonUserIsDenied, 'アカウント情報が更新されたため、ログインし直してください。'],
  [
    ErrorCode.CommonInvalidImage,
    'プロフィール画像にはGIF、PNG、JPEG、WebP、SVG形式のファイルを指定して下さい',
  ],
  [ErrorCode.StudyReportMaxTimeError, '1日の合計学習時間が24時間を越えています。'],
  [ErrorCode.UserEmailInvalid, 'メールアドレスは正しい形式で入力してください。'],
  [ErrorCode.TeamInvitationNoValidEmail, 'すでにチームに所属済みのユーザーには送信できません。'],
  [ErrorCode.TeamMemberIsLastAdmin, 'チーム最後の管理者なのでアカウントを削除できません。'],
  [
    ErrorCode.StripeDoNotHonorErr,
    'クレジットカードによる支払いが拒否されました。時間をおいてお試しいただくか、カード発行会社にお問い合わせください。',
  ],
  [ErrorCode.SubscriptionPaymentMethodIsEmpty, '支払い方法が設定されていません'],
  [ErrorCode.TeamInvitationReachedMaximumLimit, 'チームメンバーよりも契約申し込み人数が少ないです'],
  [
    ErrorCode.NotPermittedDownNumberOfTeamMembers,
    '銀行振込の場合、利用人数を減らすことはできません。',
  ],
  [
    ErrorCode.ProgramSatisfactionNotAllCompletedYetErr,
    '全章修了していないため、評価を投稿できませんでした。',
  ],
  [
    ErrorCode.PracticeSubmitDetailReviewStatusIsInvalid,
    '提出物のステータスが更新されているため、保存できません。最新の提出物を確認してください。',
  ],
  [
    ErrorCode.StripePromotionCodeAlreadyUsedErr,
    'ご利用いただいたクーポンは初回契約のみ有効なクーポンです。',
  ],
  [
    ErrorCode.PromotionCodeIsInvalid,
    'このクーポンコードは利用できません。大文字と小文字は区別されるのでご注意ください。',
  ],
  [ErrorCode.PromotionCodeIsUsed, 'ご利用いただいたクーポンは使用済みです。'],
  [ErrorCode.SubscriptionIsExisted, 'すでにサブスクリプション申し込み済みです'],
  [
    ErrorCode.SubscriptionIsMaintained,
    '決済サービスのメンテナンス中です。時間を空けて再度お試しください',
  ],
  [ErrorCode.StripeCardDeclined, '選択したクレジットカードは使用できません。'],
  [ErrorCode.StripeCardExpired, 'カードの有効期限が切れています。'],
  [
    ErrorCode.StripeProcessingError,
    '処理に失敗しました。時間をおいてもう一度クレジットカードの登録を行ってください。',
  ],
  [
    ErrorCode.ChatMessageAlreadyDeletedError,
    'メッセージはすでに削除済みの可能性があります。リロード後に再度お試しください。',
  ],
  [
    ErrorCode.SessionAlreadyReserved,
    '指定された日時は既にセッションの予定があるため登録できません。',
  ],
  [
    ErrorCode.SessionIsDuringNewYearHolidayPeriod,
    '年末年始の休業期間中(12/30〜1/3)はセッションの登録はできません。',
  ],
  [ErrorCode.SessionIsOverCount, 'セッション数の上限を超えています。'],
  [ErrorCode.LessonAlreadyReserved, '指定された日時は既にレッスンの予定があるため登録できません。'],
  [
    ErrorCode.LessonIsDuringNewYearHolidayPeriod,
    '年末年始の休業期間中(12/30〜1/3)はレッスンの登録はできません。',
  ],
  [ErrorCode.LessonIsOverDate, '指定された日時はコース終了日時以降であるため登録できません。'],
  [ErrorCode.LessonIsOverCount, '規定のレッスン回数を超えるため登録できません。'],
  [
    ErrorCode.CourseInstructorIsNotAssigned,
    '指定されたインストラクターは該当のコースにアサインされていないため、レッスンを登録/更新できません。',
  ],
  [ErrorCode.InstructorScheduleAcrossDays, '日をまたぐ日程は登録できません。'],
  [ErrorCode.InstructorScheduleOutsideBusinessHours, '日をまたぐ日程は登録できません。'],
  [
    ErrorCode.InstructorScheduleIsDuringNewYearHolidayPeriod,
    '年末年始の休業期間中(12/30〜1/3)は登録はできません。',
  ],
  [ErrorCode.InstructorScheduleDuplicated, 'その時間帯は既に登録済みです。'],
  [
    ErrorCode.UserNotSetGoogleMeetUrl,
    'アカウントのMeet URL連携がされていません。運営までお問合せください。',
  ],
  [ErrorCode.NoAvailableTickets, '利用可能なチケットがありませんでした。'],
  [ErrorCode.SpotLessonAlreadyReserved, '予約が入っている為、削除できません。'],
  [
    ErrorCode.SpotLessonIsFullOfReservations,
    '選択した希望日時の予約がいっぱいの為、予約を完了できませんでした。',
  ],
  [ErrorCode.SpotLessonIsDuplicated, '重複する予約があるため、予約を完了できませんでした。'],
  [
    ErrorCode.SpotLessonTooCloseToStartTime,
    'レッスン開始時刻まで3時間を切っているため、別の時間を指定してください。',
  ],
  [
    ErrorCode.SpotLessonIsNotCovered,
    '更新前のスケジュールに予約が入っている時間帯があります。予約が入っている時間帯は変更できません。',
  ],
  [ErrorCode.SpotLessonAlreadyDoneOrCanceled, '既に実施済みもしくはキャンセル済みです。'],
  [ErrorCode.SpotLessonIsStarted, '開始時刻を過ぎているため、キャンセルできません。'],
  [ErrorCode.StripeCardIncorrectCvc, 'カードのセキュリティーコードが間違っています。'],
  [ErrorCode.ProgramSatisfactionAlreadyExistsErr, 'この教材の評価を投稿済みです。'],
  [
    ErrorCode.ClaimDetailAlreadyExistsSpotLesson,
    '指定された単発レッスンの請求申請は登録済みです。',
  ],
  [ErrorCode.ClaimDetailAlreadyExistsLesson, '指定された専属レッスンの請求申請は登録済みです。'],
  [
    ErrorCode.ClaimDetailAlreadyExistsCurriculum,
    '指定されたカリキュラム作成の請求申請は登録済みです。',
  ],
  [
    ErrorCode.ClaimDetailNotAllowedFlexibleCurriculum,
    'このコースではカリキュラム作成の請求申請はできません。',
  ],
  [
    ErrorCode.ClaimDetailNotAllowedLessonPreparation,
    'このコースでは専属レッスンの前後対応は請求申請できません。',
  ],
  [ErrorCode.ClaimDetailNotAllowedQuestion, 'このコースでは質問対応は請求申請できません。'],
  [
    ErrorCode.QuestionCommentIsNotPermitted,
    '講師に回答を依頼済みのため、AI先生には返信できません。',
  ],
  [
    ErrorCode.QuestionCommentIsPendingAnswer,
    'AI先生が回答作成中のため、AI先生には返信できません。',
  ],
  [ErrorCode.InvalidTag, '無効なタグが指定されています。'],
]);
