import { forwardRef, TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  width?: string;
  height?: string;
  error?: boolean;
}

export const ControlledTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  return <StyledTextArea ref={ref} {...props} />;
});

const StyledTextArea = styled.textarea<{ width?: string; height?: string; error?: boolean }>`
  appearance: none;
  padding: 0.5rem;
  border: 1px solid ${(props): string => (props.error ? '#fd2f92' : '#dddddd')};
  border-radius: 2px;
  background-color: ${(props): string => (props.error ? '#fce5e8' : '#ffffff')};
  box-sizing: border-box;
  width: 100%;
  ${(props): string => (props.width ? `max-width: ${props.width};` : '')}
  ${(props): string => (props.height ? `height: ${props.height};` : '')}
`;
