import React from 'react';
import styled from 'styled-components';

interface FilterInputLabelProps {
  label: string;
  deselection?: {
    active: boolean;
    handleReset: () => void;
  };
}

export const FilterInputLabel: React.FC<FilterInputLabelProps> = (props) => {
  return (
    <Label>
      {props.label}
      {props.deselection && (
        <ConditionReset active={props.deselection.active} onClick={props.deselection.handleReset}>
          選択解除
        </ConditionReset>
      )}
    </Label>
  );
};

const Label = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.09px;
  position: relative;
`;

const ConditionReset = styled.span<{ active: boolean | undefined }>`
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1em;
  cursor: pointer;
  ${(props): string => (props.active ? '' : 'display: none;')}
`;
