import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import MaskedInput from 'react-text-mask';

interface Props {
  onChange: (value: string) => void;
  placeholder?: string;
}

export const DateInput: React.FC<Props> = (props) => {
  const changeText = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value ?? '');
  };
  return (
    <StyledInput
      mask={[/\d/, /\d/, /\d/, /\d/, '/', /[0-1]/, /\d/, '/', /[0-3]/, /\d/]}
      onChange={changeText}
      placeholder={props.placeholder}
    />
  );
};

const StyledInput = styled(MaskedInput)`
  width: 11.25rem;
  padding: 0 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.36);
  box-sizing: border-box;
  outline: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.625rem;

  &::placeholder {
    color: rgba(0, 0, 0, 0.46);
  }

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
