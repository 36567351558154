import React from 'react';
import styled from 'styled-components';
import { NotificationQuestionFragment } from '../../gen/graphql';

interface NotificationQuestionProps {
  question: NotificationQuestionFragment;
}

export const NotificationQuestion: React.FC<NotificationQuestionProps> = (props) => {
  return (
    <Container>
      <Title>{props.question.title}</Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-sizing: border-box;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 1rem;
  box-sizing: border-box;
  font-size: 1rem;
  justify-content: center;
  font-weight: 600;
  word-break: break-all;
`;
