import { UAParser } from 'ua-parser-js';
import { UserDeviceType } from '../const/UserDeviceType';

export const strToBoolean = (str: string): boolean => str.toLowerCase() === 'true';

// 第一引数で渡された文字列を第二引数で渡されたenumの型に変換する(文字列的に一致する場合のみ)
export const strToEnum = <T>(str: string, enumType: T): T[Extract<keyof T, string>] | undefined => {
  for (const e in enumType) {
    if (enumType[e] === str) {
      return enumType[e];
    }
  }
  return undefined;
};

// 金額にカンマ
export const insertCommaDelimiter = (numberWithoutComma: string): string =>
  numberWithoutComma.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

// 金額からカンマを取り除く
export const removeCommaDelimiter = (numberWithComma: string): string =>
  numberWithComma.replace(/,/g, '');

export const sleep = (msec: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, msec));

export const minutesToHours = (minute: number | undefined | null): number => {
  if (!minute) {
    return 0;
  }
  return Math.ceil(minute / 60);
};

export const minutesToHoursSecondDecimalPlace = (minute: number | undefined | null): number => {
  return minutesToHoursWithScale(minute, 2);
};

// 桁数指定して、特定桁数での切り上げ表示を行う
const minutesToHoursWithScale = (minute: number | undefined | null, scale: number): number => {
  if (!minute) {
    return 0;
  }
  return Math.ceil((minute / 60) * 10 ** scale) / 10 ** scale;
};

export const mergeRefs =
  (
    ...refs: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  ) =>
  (
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    incomingRef: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  ): any => // eslint-disable-line @typescript-eslint/no-explicit-any
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(incomingRef);
      } else {
        ref.current = incomingRef;
      }
    });

// min以上max未満の数値を返す
export function generateRandomNumber(min: number, max: number): number {
  if (max <= min) {
    throw new Error('最小値が最大値以上になっています。');
  }
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getUserDeviceType(userAgent: string): UserDeviceType {
  const type = new UAParser().setUA(userAgent).getDevice().type;
  switch (type) {
    case 'mobile':
      return UserDeviceType.SP;
    case 'tablet':
      return UserDeviceType.TB;
    default:
      return UserDeviceType.PC;
  }
}
