import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import { Either, isLeft, tryCatch } from 'fp-ts/Either';

import { QuestionRequest, Tag } from '../../domain/model/question/QuestionRequest';
import {
  ProgramSearchType,
  ProgramType,
  QuestionInput,
  QuestionStatus,
  useGetTagsLazyQuery,
  useSearchProgramsQuery,
} from '../../gen/graphql';
import {
  chapterOrPracticeOptionValue,
  getChapterFilterOptionValue,
  getChapterFilterOptionLabel,
  getPracticeFilterOptionValue,
  getPracticeFilterOptionLabel,
  selectChapterOrPracticeOptionValue,
} from '../../utils/Filter';
import { getChapters, getPractices } from '../../utils/Program';
import { ParseError } from '../../utils/FPSupport';

import {
  MAX_QUESTION_TITLE_LENGTH,
  MAX_QUESTION_CONTENT_LENGTH,
  TECH_CONTENT_PRACE_HOLDER,
} from '../../const/Question';
import { FIRST_PAGE, MAX_LIMIT, MIN_LIMIT } from '../../const/Page';
import { INITIAL_ULID } from '../../const/Filter';

import { Button } from '../atoms/Button';
import { ErrorText } from '../atoms/ErrorText';
import { InputLabel } from '../atoms/InputLabel';
import { UploadImageRefHandler, RichInput, RichInputHandler } from '../molecules/RichInput';
import { MultipleInputWithSearch } from '../molecules/MultipleInputWithSearch';
import { QuestionHintModal } from './QuestionHintModal';
import { QuestionPreviewModal } from './QuestionPreviewModal';

import { useOutsideClick } from '../../common/customHooks/OutsideClick';
import { useToastsContext } from '../../context/ToastsProvider';
import { Markdown } from '../../common/Markdown';

import IconMarkdown from '../../static/image/icon_question_markdown.svg';
import IconHint from '../../static/image/icon_question_hint.svg';

interface QuestionFormState {
  referer: string;
}

interface QuestionFormProps {
  sending: boolean;
  sendingDraft: boolean;
  onSubmit: (input: QuestionInput) => void;
  onDraft: (input: QuestionInput) => void;
  previousValues?: QuestionInput;
  shouldShowImageAttentionModal?: boolean;
}

export interface QuestionFormHandler {
  setTitle: (title: string) => void;
  setContent: (content: string) => void;
  setTags: (tags: Tag[]) => void;
  setProgramID: (programID: number) => void;
  setChapterID: (chapterID: number) => void;
  setPracticeID: (practiceID: string) => void;
  setDraftValues: (input: QuestionInput) => void;
}

export const QuestionFormDefaultInputValues: QuestionInput = {
  title: '',
  programID: 0,
  chapterID: 0,
  practiceID: INITIAL_ULID,
  tagIDs: [],
  content: '',
  status: QuestionStatus.Valid,
};

export const QuestionForm = React.forwardRef<QuestionFormHandler, QuestionFormProps>(
  (props, ref) => {
    const { showToast } = useToastsContext();
    const location = useLocation();
    const query = queryString.parse(location.search);

    const locationState = location.state as QuestionFormState;
    const backUrl = locationState?.referer ?? `/questions/all`;

    const [viewForm, setViewForm] = React.useState(true);

    const [titleError, setTitleError] = React.useState('');
    const [contentError, setContentError] = React.useState('');
    const [chapterOrPracticeError, setChapterOrPracticeError] = React.useState('');

    const [title, setTitle] = React.useState('');
    const [programID, setProgramID] = React.useState('0');
    const [chapterID, setChapterID] = React.useState('0');
    const [practiceID, setPracticeID] = React.useState(INITIAL_ULID);

    const [tagOptions, setTagOptions] = React.useState<Tag[]>([]);

    const [tags, setTags] = React.useState<Tag[]>([]);

    const [openHintModal, setOpenHintModal] = React.useState(false);
    const [openPreviewModal, setOpenPreviewModal] = React.useState(false);

    const [draftValues, setDraftValues] = React.useState<QuestionInput>(
      QuestionFormDefaultInputValues,
    );

    const tagInputRef = React.useRef(document.createElement('input'));
    const richInputRef = React.useRef<RichInputHandler>(null);
    const uploadImageRef = React.useRef<UploadImageRefHandler>(null);

    const previousValues = props.previousValues ?? QuestionFormDefaultInputValues;
    const isNewRegister = props.previousValues === undefined;

    const objectSort = useCallback((obj): [string, unknown][] => {
      return Object.entries(obj)
        .sort()
        .map(([key, val]) => {
          if (Array.isArray(val)) {
            return [key, val.sort()];
          } else if (val && typeof val === 'object') {
            return [key, objectSort(val)];
          }
          return [key, val];
        });
    }, []);

    const isDraftVisible = useMemo((): boolean => {
      if (isNewRegister) return true;

      return JSON.stringify(objectSort(draftValues)) !== JSON.stringify(objectSort(previousValues));
    }, [draftValues, isNewRegister, objectSort, previousValues]);

    const { data: programsData } = useSearchProgramsQuery({
      variables: {
        input: {
          programSearchType: ProgramSearchType.All,
          type: ProgramType.Normal,
          page: FIRST_PAGE,
          limit: MAX_LIMIT,
        },
      },
    });
    const programs = programsData?.programs.items ?? [];
    const chapters =
      getChapters(programsData?.programs.items.find((item) => item.id === Number(programID))) ?? [];
    const practices =
      getPractices(programsData?.programs.items.find((item) => item.id === Number(programID))) ??
      [];

    const [getTags] = useGetTagsLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setTagOptions(
          data.getTags.items
            ? data.getTags.items.map((t) => {
                return { ...t, value: t.id };
              })
            : [],
        );
      },
    });

    const fetchTags = () => {
      getTags({
        variables: {
          input: {
            name: tagInputRef.current.value,
            page: FIRST_PAGE,
            limit: MIN_LIMIT,
          },
        },
      });
    };

    const handleInputTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value.length > MAX_QUESTION_TITLE_LENGTH) {
        setTitleError(`タイトルは${MAX_QUESTION_TITLE_LENGTH}文字以下にしてください。`);
      } else if (value === '') {
        setTitleError('タイトルを入力してください。');
      } else {
        setTitleError('');
      }
      setTitle(value);
      setDraftValues({ ...draftValues, title: value });
    };

    const handleProgramChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      setProgramID(value);
      setChapterID('0');
      setPracticeID(INITIAL_ULID);

      setDraftValues({
        ...draftValues,
        programID: parseInt(value),
        chapterID: 0,
        practiceID: INITIAL_ULID,
      });
    };

    const choiceTag = (tag: Tag) => {
      if (tags.filter((t) => t.value === tag.value).length === 0) {
        const newTags = [...tags, tag];
        setTags(newTags);

        setDraftValues({ ...draftValues, tagIDs: newTags.map((tag) => Number(tag.value)) });
      }
      tagInputRef.current.value = '';
      setTagOptions([]);
    };

    const removeTag = (tag: Tag) => {
      const updateTags = tags.filter((t) => t.value !== tag.value);
      setTags(updateTags);

      setDraftValues({ ...draftValues, tagIDs: updateTags.map((tag) => Number(tag.value)) });
    };

    const handleChapterOrPracticeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;

      const [chapterID, practiceID] = chapterOrPracticeOptionValue(value);
      setChapterID(chapterID);
      setPracticeID(practiceID);
      setDraftValues({
        ...draftValues,
        chapterID: parseInt(chapterID),
        practiceID: practiceID,
      });
    };

    const handleContentChange = () => {
      const content = richInputRef.current?.getValue() ?? '';
      if (content.length > MAX_QUESTION_CONTENT_LENGTH) {
        setContentError(`本文は${MAX_QUESTION_CONTENT_LENGTH}文字以下にしてください。`);
      } else if (richInputRef.current?.getValue() === '') {
        setContentError('本文を入力してください。');
      } else {
        setContentError('');
      }

      setDraftValues({ ...draftValues, content });
    };

    useOutsideClick(
      React.useRef(document.createElement('p')),
      React.useRef(document.createElement('div')),
      (): void => {
        if (tagInputRef && tagInputRef.current) tagInputRef.current.value = '';
        setTagOptions([]);
      },
    );

    const initialValidationMessage = (
      setTitleError: (value: ((prevState: string) => string) | string) => void,
      setChapterOrPracticeError: (value: ((prevState: string) => string) | string) => void,
      setContentError: (value: ((prevState: string) => string) | string) => void,
    ) => {
      setTitleError('');
      setChapterOrPracticeError('');
      setContentError('');
    };

    const submit = () => {
      initialValidationMessage(setTitleError, setChapterOrPracticeError, setContentError);

      const request: Either<ParseError, QuestionRequest> = tryCatch(
        () =>
          new QuestionRequest({
            title: title,
            programID: programID,
            chapterID: chapterID,
            practiceID: practiceID,
            tags: tags.map((tag) => Number(tag.value)), //元がunionTypeでむりくりやっているせいで歪んでいる
            content: richInputRef.current?.getValue(),
          }),
        (e) => {
          if (e instanceof ParseError) {
            return e;
          } else {
            throw new Error('網羅外のエラー');
          }
        },
      );

      if (isLeft(request)) {
        showToast(1, '投稿に失敗しました。システム不整合が発生しています');
        return;
      }

      const content = richInputRef.current?.getValue();
      const parsedProgramId = parseInt(programID);
      const parsedchapterID = parseInt(chapterID);

      if (!content) return;

      const input: QuestionInput = {
        title: title,
        programID: parsedProgramId ? parsedProgramId : null,
        chapterID: parsedchapterID ? parsedchapterID : null,
        practiceID: practiceID ? practiceID : null,
        tagIDs: tags.map((tag) => Number(tag.value)),
        content: content,
        status: QuestionStatus.Valid,
      };

      props.onSubmit(input);
    };

    const draft = () => {
      initialValidationMessage(setTitleError, setChapterOrPracticeError, setContentError);

      const request: Either<ParseError, QuestionRequest> = tryCatch(
        () =>
          new QuestionRequest({
            title: title,
            programID: programID,
            chapterID: chapterID,
            practiceID: practiceID,
            tags: tags.map((tag) => Number(tag.value)), //元がunionTypeでむりくりやっているせいで歪んでいる
            content: richInputRef.current?.getValue(),
          }),
        (e) => {
          if (e instanceof ParseError) {
            return e;
          } else {
            throw new Error('網羅外のエラー');
          }
        },
      );

      if (isLeft(request)) {
        showToast(1, '投稿に失敗しました。システム不整合が発生しています');
        return;
      }

      const content = richInputRef.current?.getValue();
      const parsedProgramId = parseInt(programID);
      const parsedchapterID = parseInt(chapterID);

      if (!content) return;

      const input: QuestionInput = {
        title: title,
        programID: parsedProgramId ? parsedProgramId : null,
        chapterID: parsedchapterID ? parsedchapterID : null,
        practiceID: practiceID ? practiceID : null,
        tagIDs: tags.map((tag) => Number(tag.value)),
        content: content,
        status: QuestionStatus.Draft,
      };

      props.onDraft(input);
    };

    React.useImperativeHandle(ref, () => {
      return {
        setTitle: (title: string) => setTitle(title),
        setContent: (content: string) => richInputRef.current?.setValue(content),
        setTags: (tags: Tag[]) => setTags(tags),
        setProgramID: (programID: number) => setProgramID(programID.toString()),
        setChapterID: (chapterID: number) => setChapterID(chapterID.toString()),
        setPracticeID: (practiceID: string) => setPracticeID(practiceID),
        setDraftValues: (input: QuestionInput) => setDraftValues(input),
      };
    });

    const getWindowDimensions = () => {
      const { innerHeight: height } = window;
      return height;
    };
    const [windowHeight, setWindowHeight] = React.useState(getWindowDimensions());
    const resizeHeight = React.useCallback((): void => {
      setWindowHeight(getWindowDimensions());
    }, [setWindowHeight]);

    const clickMarkDownLink = () => {
      window.open('https://www.sejuku.net/blog/78156', '_blank');
    };

    React.useEffect(() => {
      const content = richInputRef.current?.getValue();
      if (content === '') {
        richInputRef.current?.setValue(TECH_CONTENT_PRACE_HOLDER);
      }
    }, []);

    React.useEffect(() => {
      window.addEventListener('resize', resizeHeight);
      return () => window.removeEventListener('resize', resizeHeight);
    }, [resizeHeight]);

    React.useEffect(() => {
      if (typeof query.program_id === 'string') setProgramID(query.program_id);
    }, [query.program_id]);

    React.useEffect(() => {
      if (typeof query.chapter_id === 'string') setChapterID(query.chapter_id);
    }, [query.chapter_id]);

    React.useEffect(() => {
      if (typeof query.practice_id === 'string') setPracticeID(query.practice_id);
    }, [query.practice_id]);

    const handleClose = React.useCallback((): void => {
      if (openHintModal) {
        return;
      }
      setOpenPreviewModal(false);
    }, [openHintModal, setOpenPreviewModal]);

    return (
      <>
        <Container windowHeight={windowHeight}>
          <InformationArea>
            <FlexGroup active={true}>
              <FlexContent>
                <InputGroup isError={!!titleError}>
                  <StyledLabel for="title">タイトル</StyledLabel>
                  <TitleInput
                    type="text"
                    name="title"
                    value={title}
                    onChange={handleInputTitle}
                    placeholder={'例) JavaScriptでxxの実装中にyyのエラーが出た時の対処法'}
                    data-e2e="questionTitle"
                    maxLength={MAX_QUESTION_TITLE_LENGTH}
                  />
                  {!!titleError && <StyledErrorText>{titleError}</StyledErrorText>}
                </InputGroup>
              </FlexContent>
              <ToggleView active={viewForm} onClick={() => setViewForm(!viewForm)}>
                <svg
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6 0L0.803846 9H11.1962L6 0Z" fill="black" />
                </svg>
              </ToggleView>
            </FlexGroup>

            <FlexGroup active={viewForm}>
              <FlexContent>
                <SelectLeftInputGroup>
                  <StyledLabel for="program">教材</StyledLabel>
                  <ProgramSelect
                    name="program"
                    onChange={handleProgramChange}
                    value={programID}
                    active={programID !== '0'}
                  >
                    <option value="0">
                      選択なし
                      {'　'.repeat(
                        programs.reduce(
                          (acc, cur) => (acc < cur.title.length ? cur.title.length : acc),
                          5,
                        ),
                      )}
                    </option>
                    {programs.map((program) => {
                      return (
                        <option value={program.id.toString()} key={program.id}>
                          {program.title}
                        </option>
                      );
                    })}
                  </ProgramSelect>
                </SelectLeftInputGroup>
              </FlexContent>
              <FlexContent>
                <SelectRightInputGroup isError={chapterOrPracticeError !== ''}>
                  <StyledLabel for="chapter_or_practice">章・課題</StyledLabel>
                  <ProgramSelect
                    name="chapter_or_practice"
                    onChange={handleChapterOrPracticeChange}
                    value={selectChapterOrPracticeOptionValue(chapterID, practiceID)}
                    active={chapterID !== '0' || practiceID !== ''}
                  >
                    <option value="0">
                      選択なし
                      {'　'.repeat(
                        [...chapters, ...practices].reduce(
                          (acc, cur) => (acc < cur.title.length ? cur.title.length : acc),
                          5,
                        ),
                      )}
                    </option>
                    {chapters.map((chapter) => {
                      return (
                        <option
                          value={getChapterFilterOptionValue(chapter.id.toString())}
                          key={chapter.id}
                        >
                          {getChapterFilterOptionLabel(chapter.title)}
                        </option>
                      );
                    })}
                    {practices.map((practice) => {
                      return (
                        <option value={getPracticeFilterOptionValue(practice.id)} key={practice.id}>
                          {getPracticeFilterOptionLabel(practice.title)}
                        </option>
                      );
                    })}
                  </ProgramSelect>
                  {!!chapterOrPracticeError && (
                    <StyledErrorText>{chapterOrPracticeError}</StyledErrorText>
                  )}
                </SelectRightInputGroup>
              </FlexContent>
            </FlexGroup>

            <FlexGroup active={viewForm}>
              <FlexContent>
                <InputGroup>
                  <StyledLabel for="title">タグ（複数指定可能）</StyledLabel>
                  <StyledMultipleInputWithSearch
                    values={tags}
                    options={tagOptions}
                    onRemove={removeTag}
                    onSelect={choiceTag}
                    fetchOptions={fetchTags}
                    ref={tagInputRef}
                    placeholder={
                      '+ タグを入力して候補から選択（”JavaScript”、”PHP”、”Laravel”など）'
                    }
                  />
                </InputGroup>
              </FlexContent>
            </FlexGroup>
          </InformationArea>

          <TextArea>
            <ContentEditor isError={contentError !== ''}>
              <TextAreaInputTitle>本文を編集</TextAreaInputTitle>
              <StyledRichInput
                name="question_content"
                fileUpload={false}
                imageUpload
                placeholder={TECH_CONTENT_PRACE_HOLDER}
                isAutoHeight={false}
                onKeyUp={handleContentChange}
                ref={richInputRef}
                uploadImageRef={uploadImageRef}
                hiddenPreview
                maxLength={MAX_QUESTION_CONTENT_LENGTH}
              />
              {!!contentError && <StyledErrorText>{contentError}</StyledErrorText>}
            </ContentEditor>
            <Preview>
              <PreviewTitleArea>
                <TextAreaTitle>プレビュー</TextAreaTitle>
                <PreviewCommand>
                  <Command onClick={clickMarkDownLink}>
                    <img src={IconMarkdown} alt="" />
                    マークダウンとは
                  </Command>
                  <Command onClick={() => setOpenHintModal(true)}>
                    <img src={IconHint} alt="" />
                    ヒント
                  </Command>
                </PreviewCommand>
              </PreviewTitleArea>
              <MarkdownWrapper>
                <Markdown content={richInputRef.current?.getValue()} />
              </MarkdownWrapper>
            </Preview>
          </TextArea>
          <CommandArea>
            <PageBack to={backUrl}>戻る</PageBack>
            {(isNewRegister || draftValues.status === QuestionStatus.Draft) && (
              <DraftButton
                border
                disabled={
                  !isDraftVisible || title === '' || titleError !== '' || contentError !== ''
                }
                onClick={draft}
              >
                {props.sendingDraft ? '送信中' : isDraftVisible ? '下書きを保存' : '下書き保存済'}
              </DraftButton>
            )}
            <SubmitButton
              onClick={submit}
              disabled={title === '' || titleError !== '' || contentError !== ''}
            >
              {props.sending ? '送信中' : '投稿する'}
            </SubmitButton>
            <PreviewButton gray onClick={() => setOpenPreviewModal(true)}>
              プレビュー
            </PreviewButton>
          </CommandArea>
        </Container>

        <QuestionPreviewModal
          isOpen={openPreviewModal}
          onClose={handleClose}
          onOpenHint={() => setOpenHintModal(true)}
        >
          <Markdown content={richInputRef.current?.getValue()} />
        </QuestionPreviewModal>

        <QuestionHintModal
          isOpen={openHintModal}
          onClose={() => setOpenHintModal(false)}
          isWorry={true}
        />
      </>
    );
  },
);

const Container = styled.div<{ windowHeight: number }>`
  display: grid;
  height: ${(props) =>
    props.windowHeight ? props.windowHeight - 65 + 'px' : 'calc(100vh - 65px)'};
  grid-template-rows: auto 1fr auto;

  ${media.lessThan('medium')`
    display: block;
    height: auto;
    padding: 2rem 1rem;
  `}
`;
const InformationArea = styled.div`
  padding: 1.25rem 1.25rem 1rem;
  background: #fff;
  border-bottom: 1px solid #eee;

  ${media.lessThan('medium')`
    padding: 0;
    border-bottom: none;
  `}
`;
const FlexGroup = styled.div<{ active: boolean }>`
  width: 100%;
  margin-top: 0.5rem;
  display: ${(props) => (props.active ? 'flex' : 'none')};

  ${media.lessThan('medium')`
    display: block;
    margin-top: 0;

    &:nth-of-type(n + 2) {
      margin-top: 1rem;
    }
  `}
`;
const FlexContent = styled.div`
  flex: 1;

  ${media.lessThan('medium')`
    &:nth-of-type(n + 2) {
      margin-top: 1rem;
    }
  `}
`;
const ToggleView = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  margin-left: 0.5rem;
  background: #${(props) => (props.active ? 'fff' : 'f8f8f8')};
  border: 1px solid #b6b6b6;
  border-radius: 0.125rem;
  cursor: pointer;
  transition: all 0.2s;

  ${(props) =>
    !props.active
      ? `
    svg {
      transform: rotate(180deg);
    }
  `
      : ''}

  &:hover {
    background: #${(props) => (props.active ? 'f8f8f8' : 'fff')};
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const InputGroup = styled.div<{ isError?: boolean }>`
  padding: 0.375rem 0.75rem 0.75rem;
  background: #fff;
  border: 1px solid #${(props) => (props.isError ? 'eb0000' : 'dbdbdb')};
  border-radius: 0.125rem;
`;
const SelectLeftInputGroup = styled(InputGroup)`
  border-radius: 0.125rem 0 0 0.125rem;
`;
const SelectRightInputGroup = styled(InputGroup)`
  border-radius: 0 0.125rem 0.125rem 0;
`;
const StyledLabel = styled(InputLabel)`
  display: block;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1em;
`;
const TitleInput = styled.input`
  display: block;
  width: 100%;
  height: 1.25rem;
  margin-top: 0.375rem;
  padding: 0;
  border: none;
  box-shadow: none;
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;

  &::placeholder {
    color: rgba(0, 0, 0, 0.36);
  }

  &:focus {
    background: #fff;
    border: none;
    box-shadow: none;
    outline: none;
  }
`;
const ProgramSelect = styled.select<{ active: boolean }>`
  display: block;
  width: 100%;
  height: 1.25rem;
  margin-top: 0.375rem;
  padding: 0 1.5rem 0 0;
  background: #fff center right / auto no-repeat;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2217%22%20height%3D%2216%22%20viewBox%3D%220%200%2017%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.49707%2013L3.30092%204H13.6932L8.49707%2013Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E');
  border: none;
  outline: none;
  color: rgba(0, 0, 0, ${(props) => (props.active ? '.87' : '.36')});
  font-size: 1rem;
  appearance: none;
  position: relative;
`;
const StyledMultipleInputWithSearch = styled(MultipleInputWithSearch)`
  margin-top: 0.375rem;

  > div {
    padding: 0;
    border: none;
  }

  input {
    padding: 0;
    line-height: 1.25rem;
  }
`;
const StyledErrorText = styled(ErrorText)`
  margin-top: 0.25rem;
  margin-bottom: 0.25em;
  margin-left: 0.25rem;
`;

const TextArea = styled.div`
  display: flex;
  overflow-y: hidden;

  > div {
    width: 50%;
    display: grid;
    grid-template-rows: auto 1fr;
  }

  ${media.lessThan('medium')`
    margin-top: 1rem;
    display: block;

    > div {
      display: block;
      width: 100%;
    }
  `}
`;

const TextAreaTitle = styled.h4`
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
`;
const TextAreaInputTitle = styled(TextAreaTitle)`
  width: 100%;
  margin: 0.5rem auto 0.375rem;
  padding: 0 1rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    margin: 0 auto .375rem;
    padding: 0;
  `}
`;

const ContentEditor = styled.div<{ isError?: boolean }>`
  border: ${(props) => (props.isError ? '1px solid #eb0000' : '1px solid #eee')};
`;

const StyledRichInput = styled(RichInput)`
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;

  .tools {
    padding: 0 1rem;
    border: none;
  }
  .textarea {
    height: auto;
    padding: 1rem;
    border: none;
    border-top: 1px solid #eee;
  }

  ${media.lessThan('medium')`
    .tools {
      padding: 0;
    }

    .textarea {
      height: 20rem;
      border: 1px solid #eee;
    }
  `}
`;

const Preview = styled.div`
  padding: 0.5rem 1rem 0.75rem;
  background: #fcfcfc;
  border-left: 1px solid #eee;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    display: none !important;
  `}
`;
const PreviewTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.375rem;
`;
const PreviewCommand = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
const Command = styled.p`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #eb0000;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;

  img {
    display: block;
  }
`;
const MarkdownWrapper = styled.div`
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 0.125rem;
  overflow-y: auto;
`;

const CommandArea = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-top: 1px solid #eee;

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
    margin-top: 2rem;
    padding: 0;
    border-top: none;
  `}
`;
const PageBack = styled(Link)`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: auto;

  &:before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.625rem;
    background: transparent center / contain no-repeat;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10.6523%201.7832C10.3857%201.7832%2010.1738%201.87891%209.98926%202.05664L4.74609%207.17676C4.51367%207.40918%204.4043%207.64844%204.39746%207.94238C4.39746%208.23633%204.50684%208.48242%204.74609%208.70801L9.98926%2013.835C10.1738%2014.0127%2010.3926%2014.1084%2010.6523%2014.1084C11.1855%2014.1084%2011.6094%2013.6846%2011.6094%2013.1582C11.6094%2012.8984%2011.5%2012.6523%2011.3086%2012.4609L6.66016%207.94238L11.3086%203.43066C11.5%203.23926%2011.6094%202.99316%2011.6094%202.7334C11.6094%202.20703%2011.1855%201.7832%2010.6523%201.7832Z%22%20fill%3D%22black%22%20fillOpacity%3D%220.87%22%2F%3E%3C%2Fsvg%3E');
  }

  ${media.lessThan('medium')`
    margin-right: 0;
  `}
`;
const StyledButton = styled(Button)`
  display: block;
  width: 40%;
  max-width: 336px;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 300px;
  `}
`;
const SubmitButton = styled(StyledButton)`
  order: 2;

  ${media.lessThan('medium')`
    order: 1;
  `}
`;
const DraftButton = styled(StyledButton)`
  order: 1;

  ${media.lessThan('medium')`
    order: 2;
  `}
`;
const PreviewButton = styled(StyledButton)`
  display: none;
  order: 3;

  ${media.lessThan('medium')`
    display: block;
  `}
`;
