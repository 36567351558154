import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useParams } from 'react-router-dom';

import { NotFoundPage } from '../public/NotFound';
import { BasicLayout } from '../../templates/BasicLayout';
import { ExamHeader } from '../../organisms/ExamHeader';
import { ExamQuestion } from '../../organisms/ExamQuestion';
import { Loader } from '../../molecules/Loader';
import { useToastsContext } from '../../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

import * as Sentry from '@sentry/react';
import { checkProgramElementAuthType } from '../../../utils/Program';
import { useUser } from '../../../redux/user/useUser';

import {
  useGetProgramExamQuery,
  useGetProgramQuery,
  AnswerInput,
  useAnswerExamMutation,
  ExamScoreFragment,
} from '../../../gen/graphql';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { LOWER_META_TITLE } from '../../../const/Service';
import ForbiddenPage from '../public/Forbidden';
import {
  ProgramElementInProgramDetail,
  ProgramElementsInProgramDetail,
} from '../../../types/Program';
import { PageWrapper } from '../../atoms/PageWrapper';

export const ProgramExamContainer = (): JSX.Element => {
  const params = useParams<{ program_id: string; chapter_id: string }>();
  const pathProgramID = Number(params.program_id);
  const pathChapterID = Number(params.chapter_id);
  if (Number.isNaN(pathProgramID) || Number.isNaN(pathChapterID)) return NotFoundPage;

  return <ProgramExam programID={pathProgramID} chapterID={pathChapterID} />;
};

interface ProgramExamProps {
  programID: number;
  chapterID: number;
}

const ProgramExam = (props: ProgramExamProps): JSX.Element => {
  const [answers, setAnswers] = useState<AnswerInput[]>([]);
  const [examScore, setExamScore] = useState<ExamScoreFragment>();
  const { showToast } = useToastsContext();
  const { permissionCheck } = useUser();
  const [answered, setAnswered] = useState(
    permissionCheck(FunctionType.ExamForInstructorAndCoach, PermissionType.Read),
  ); // インストラクターかコーチの場合デフォルトで解答と解説を表示

  const programData = useGetProgramQuery({
    variables: {
      programID: props.programID,
    },
  });
  const program = programData.data?.program;

  const examData = useGetProgramExamQuery({
    variables: {
      chapterID: props.chapterID,
    },
    onError(e) {
      Sentry.captureException(e);
    },
  });

  const nextProgramElement = useMemo(() => {
    return getNextProgramElement(program?.programElements ?? [], props.chapterID, permissionCheck);
  }, [permissionCheck, program?.programElements, props.chapterID]);

  const bestScore = examData.data?.chapter?.examScores?.reduce((acc, cur) =>
    acc.score > cur.score ? acc : cur,
  );

  const onSelectAnswer = useCallback(
    (answer: AnswerInput) => {
      const newAnswers = answers.filter(
        (a) => a.questionID !== answer.questionID && a.answerID !== answer.answerID,
      );

      setAnswers([...newAnswers, answer]);
    },
    [answers],
  );

  const [answerExam, { loading: sending }] = useAnswerExamMutation();
  const disableSubmitButton = answers.length !== examData.data?.programExam?.length;

  const submit = useSafeAsyncCallback(
    useCallback(async () => {
      if (disableSubmitButton) return;

      try {
        const answerResponse = await answerExam({
          variables: {
            input: {
              chapterID: props.chapterID,
              answerRequests: answers,
            },
          },
        });

        setExamScore(answerResponse.data?.answerExam?.ExamScore);
        setAnswered(true);
        window.scrollTo(0, 0);
      } catch (e) {
        Sentry.captureException(e);
        showToast(1, '解答に失敗しました。');
        return;
      }
    }, [answers, answerExam, props.chapterID, showToast, disableSubmitButton]),
  );

  const retryExam = () => {
    setAnswers([]);
    setExamScore(undefined);
    setAnswered(false);
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const metaTitle = useMemo(
    () => `${examData?.data?.chapter?.title} | ${LOWER_META_TITLE}`,
    [examData?.data?.chapter?.title],
  );
  const metaDescription = useMemo(
    () => `侍テラコヤの章ページです。この章では、${examData?.data?.chapter?.description}`,
    [examData?.data?.chapter?.description],
  );

  if (examData.error) {
    return NotFoundPage;
  }

  if (!program && !programData.loading) {
    return ForbiddenPage;
  }

  return (
    <>
      <BasicLayout pageTitle="教材" metaTitle={metaTitle} metaDescription={metaDescription}>
        <Loader display={examData.loading || sending} />
        <Breadcrumb>
          <StyledLink to="/">ホーム</StyledLink>
          <StyledLink to="/programs">教材</StyledLink>
          <StyledLink to={`/programs/${programData?.data?.program?.id}/chapters`}>
            {programData?.data?.program?.title}
          </StyledLink>
          <Current>{examData.data?.chapter?.title}</Current>
        </Breadcrumb>
        <PageWrapper size="narrow">
          <ExamArea data-e2e="examTitle">
            <ExamHeader
              chapterTitle={examData.data?.chapter?.title}
              bestScore={bestScore?.score}
              bestMedal={bestScore?.medalType}
              answered={answered}
              examScore={examScore}
              disableScore={!permissionCheck(FunctionType.Exam, PermissionType.Read)}
            />

            <Exams>
              {examData.data?.programExam?.map((e, i) => (
                <ExamQuestion
                  questionNumber={i + 1}
                  key={e.id}
                  question={e}
                  onSelectAnswer={onSelectAnswer}
                  answered={answered}
                />
              ))}
            </Exams>

            <Buttons>
              {answered ? (
                <>
                  {permissionCheck(FunctionType.Exam, PermissionType.Create) && (
                    <RetryButton onClick={retryExam}>
                      <span>もう一度テストをする</span>
                    </RetryButton>
                  )}
                  <CloseButton to={`/programs/${props.programID}/chapters/${props.chapterID}`}>
                    <span>教材に戻る</span>
                  </CloseButton>
                  {nextProgramElement && (
                    <CloseButton2
                      to={getProgramElementLink(nextProgramElement, props.programID)}
                      disabled={!checkProgramElementAuthType(nextProgramElement, permissionCheck)}
                    >
                      <span data-e2e="toNext">次へ進む</span>
                    </CloseButton2>
                  )}
                </>
              ) : (
                <>
                  <CloseButton to={`/programs/${props.programID}/chapters/${props.chapterID}`}>
                    <span>閉じる</span>
                  </CloseButton>
                  <AnswerButton onClick={submit} $disable={disableSubmitButton}>
                    <span data-e2e="answer">解答する</span>
                  </AnswerButton>
                </>
              )}
            </Buttons>
          </ExamArea>
        </PageWrapper>
      </BasicLayout>
    </>
  );
};

// 現在の章より後で権限があるProgramElementを取得する
export const getNextProgramElement = (
  programElements: ProgramElementsInProgramDetail,
  chapterID: number,
  permissionCheck: (functionType: string, permissionType: string) => boolean,
): ProgramElementInProgramDetail | undefined => {
  const currentProgramElementIndex = programElements.findIndex(
    (programElement) => programElement.chapter?.id === chapterID,
  );

  if (currentProgramElementIndex === -1) return undefined;

  return programElements.slice(currentProgramElementIndex + 1).find((programElement) => {
    return checkProgramElementAuthType(programElement, permissionCheck);
  });
};

export const getProgramElementLink = (
  programElement: ProgramElementInProgramDetail,
  programID: number,
): string => {
  const { chapter, practice } = programElement;

  if (chapter) {
    return `/programs/${programID}/chapters/${chapter.id}`;
  }

  if (practice) {
    return `/practices/${practice.id}/body`;
  }

  return '';
};

const Breadcrumb = styled.div`
  width: calc(100% - 2rem * 2);
  margin: 2rem 2rem 0;
  line-height: 0.9375rem;

  & > * + * {
    &:before {
      content: '>';
      margin: 0 0.3rem;
    }
  }

  ${media.lessThan('medium')`
    width: calc(100% - 1rem * 2);
    margin: 1rem 1rem 0;
  `}
`;

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8125rem;
  transition: all 0.2s;

  &:hover {
    color: #e2001b;
    text-decoration: underline;
  }
`;

const Current = styled.span`
  color: rgba(0, 0, 0, 0.36);
  font-size: 0.8125rem;
`;

const ExamArea = styled.section`
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1.125rem;
  padding: 2rem;

  ${media.lessThan('medium')`
    border: none;
    margin-top: 0;
    padding: 0;
    min-width: 100%;
  `}
`;

const Exams = styled.div`
  & > * + * {
    margin-top: 32px;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: center;

  & > * + * {
    margin-left: 82px;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-left: 0;
      margin-top: 16px;
    }
  `}
`;

const RetryButton = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;
  min-width: 216px;
  padding: 0 40px;
  height: 40px;
  border: 1px solid #eb0000;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;

  span {
    color: #eb0000;
    font-size: 1rem;
    white-space: nowrap;
  }

  ${media.lessThan('medium')`
    width: calc(100% - 32px);
    height: 44px;
  `}
`;

const CloseButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;
  min-width: 216px;
  padding: 0 40px;
  height: 40px;
  border: 1px solid #eb0000;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;

  span {
    color: #eb0000;
    font-size: 1rem;
  }

  ${media.lessThan('medium')`
  width: calc(100% - 32px);
    height: 44px;
  `}
`;

const CloseButton2 = styled(Link)<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;
  min-width: 216px;
  padding: 0 40px;
  height: 40px;
  background-color: #eb0000;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;

  span {
    color: #ffffff;
    font-size: 1rem;
  }

  ${media.lessThan('medium')`
    width: calc(100% - 32px);
    height: 44px;
  `}

  ${(props) =>
    props.disabled &&
    `
    background: #e5e5e5;
    border-color: #e5e5e5;
    cursor: default;
    pointer-events: none;
    color: rgba(0,0,0,.87);
  `}
`;

const AnswerButton = styled.div<{ $disable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
  padding: 0 40px;
  height: 40px;
  background-color: ${(props) => (props.$disable ? 'rgba(0,0,0,0.36)' : '#eb0000')};
  border-radius: 3px;
  box-sizing: border-box;
  cursor: ${(props) => (props.$disable ? 'default' : 'pointer')};

  span {
    color: ${(props) => (props.$disable ? 'rgba(0,0,0,0.36)' : '#ffffff')};
    font-size: 1rem;
  }

  ${media.lessThan('medium')`
    margin-top: 16px;
    margin-left: 0;
    width: calc(100% - 32px);
    height: 44px;
  `}
`;
