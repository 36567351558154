import { useState } from 'react';
import { useParams } from 'react-router-dom';
import media from 'styled-media-query';
import styled from 'styled-components';
import { UserPageLayout } from '../../templates/UserPageLayout';
import { NotFoundPage } from '../public/NotFound';
import { useGetUserWithProgramsQuery } from '../../../gen/graphql';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Loader } from '../../molecules/Loader';
import { ProgramArticle } from '../../organisms/ProgramArticle';
import { Button } from '../../atoms/Button';
import { ProgramListContainer } from '../../atoms/ProgramListContainer';
import { LOWER_META_TITLE } from '../../../const/Service';

export const UserProgramsContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathUserID = Number(paramID);
  if (Number.isNaN(pathUserID)) return NotFoundPage;

  return <UserPrograms userID={pathUserID} />;
};

interface UserProgramsProps {
  userID: number;
}

const UserPrograms = (props: UserProgramsProps): JSX.Element => {
  const metaTitle = `教材 | ${LOWER_META_TITLE}`;

  const { data, loading } = useGetUserWithProgramsQuery({
    variables: { id: props.userID },
  });

  // 学習中の教材を表示するか、完了した教材を表示するか
  const [type, setType] = useState<'whileStudying' | 'completed'>('whileStudying');

  // ユーザー情報取得前は画面レイアウトを表示できないためローディングだけを返す
  if (loading) {
    return <Loader display />;
  }

  // インストラクターは教材ページなし
  if (!data || !data.user || data.user.isInstructor) {
    return NotFoundPage;
  }

  const programs =
    type === 'completed' ? data.user.completedPrograms : data.user.whileStudyingPrograms;

  return (
    <UserPageLayout activeTab="programs" user={data.user} metaTitle={metaTitle}>
      <PageWrapper>
        <ToggleButtonContainer>
          <ToggleButton onClick={() => setType('whileStudying')} $active={type === 'whileStudying'}>
            学習中の教材
          </ToggleButton>
          <ToggleButton onClick={() => setType('completed')} $active={type === 'completed'}>
            完了した教材
          </ToggleButton>
        </ToggleButtonContainer>
        <Count>
          <span>教材数：</span>
          {programs?.length ?? 0}
        </Count>
        <ListContainer>
          <ProgramListContainer>
            {programs?.map((program) => (
              <ProgramArticle
                id={program.id}
                key={program.id}
                image={program.icon}
                thumb={program.thumbnail}
                title={program.title}
                description={program.description}
                questionCount={program.questionCount}
                answerCount={program.answerCount}
                chapterCount={program.chapterCount ?? 0}
                requireTime={program.requireTime ?? 0}
                completedTime={program.completedTime ? program.completedTime : 0}
                tags={program.tags ?? []}
                studyLogCount={program.studyLogCount}
                isUserPage
              />
            ))}
          </ProgramListContainer>
        </ListContainer>
      </PageWrapper>
    </UserPageLayout>
  );
};

const ToggleButtonContainer = styled.div`
  display: flex;

  & > :not(:first-child) {
    margin-left: 0.625rem;
  }

  ${media.lessThan('small')`
    justify-content: space-between;
    padding: 0 0.5rem 0 0.5625rem;
    
    & > :not(:first-child) {
      margin-left: 0;
    }
  `}
`;

const ToggleButton = styled(Button)<{ $active: boolean }>`
  padding: 0.4375rem 0.75rem;
  background: ${({ $active }) => ($active ? '#EB0000' : '#FFFFFF')};
  font-size: 0.875rem;
  font-weight: ${({ $active }) => ($active ? 700 : 400)};
  color: ${({ $active }) => ($active ? '#FFFFFF' : 'rgba(0, 0, 0, 0.6)')};
  line-height: 1.2;
  border-radius: 1.875rem;

  ${media.lessThan('small')`
    padding: 0.4375rem 1em;
    font-size: 0.9375rem;
    
  `}
`;

const Count = styled.p`
  margin: 1rem 0 0 0.25rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;

  span {
    margin-left: 0.625rem;
    font-size: 0.9375rem;
    font-weight: 400;
  }

  ${media.lessThan('small')`
    display: none;
  `}
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 100%;

  ${media.lessThan('small')`
    margin-top: 3rem;
  `}
`;
