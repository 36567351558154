import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import {
  PracticeFragment,
  PracticeSubmitDetailFragment,
  PracticeSubmitDetailReviewStatus,
  UserFragment,
} from '../../gen/graphql';
import { isDraft, isFailed, isReviewed, isWaiting } from '../../utils/PracticeSubmit';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { PracticeSubmitCard } from './PracticeSubmitCard';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';

interface PracticeSubmitDetailListProps {
  practiceSubmitID: string;
  submitter: UserFragment;
  practice: PracticeFragment;
  practiceSubmitDetails: PracticeSubmitDetailFragment[];
  fetchPracticeSubmitForInstructor: () => Promise<void>;
}

export const PracticeSubmitDetailList = ({
  practiceSubmitID,
  submitter,
  practice,
  practiceSubmitDetails,
  fetchPracticeSubmitForInstructor,
}: PracticeSubmitDetailListProps): JSX.Element => {
  const navigate = useNavigate();
  const { openModal } = useCommonModal();

  const { permissionCheck, user } = useUser();
  const permissionPracticeForInstructorAndCoachCreate = useMemo(
    () => permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Create),
    [permissionCheck],
  );
  const permissionPracticeForInstructorAndCoachUpdate = useMemo(
    () => permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Update),
    [permissionCheck],
  );

  const handleClickResubmit = useCallback(() => {
    if (openModal(FunctionType.PracticeSubmit, PermissionType.Create)) return;
    navigate(`/practices/submits/${practiceSubmitID}/details/new`);
  }, [openModal, navigate, practiceSubmitID]);

  const setSubmitterControlMenu = useCallback(
    (value: string) => [
      {
        text: '編集',
        onClick: () => {
          if (openModal(FunctionType.PracticeSubmit, PermissionType.Update)) return;
          navigate(`/practices/submits/${practiceSubmitID}/details/${value}/edit`);
        },
      },
    ],
    [openModal, navigate, practiceSubmitID],
  );

  // インストラクター以外
  // 最新の提出物
  // レビュー待ちか下書き
  // 自身の提出物のとき
  const isDisplaySubmitterEditButton = useCallback(
    (index: number, reviewStatus: PracticeSubmitDetailReviewStatus) =>
      !permissionPracticeForInstructorAndCoachUpdate &&
      index === 0 &&
      (isWaiting(reviewStatus) || isDraft(reviewStatus)) &&
      submitter.id === user.lmsUser?.id,
    [permissionPracticeForInstructorAndCoachUpdate, submitter.id, user.lmsUser?.id],
  );

  // インストラクター以外
  // 最新の提出物
  // 再提出
  // 自身の提出物のとき
  const isDisplayResubmitButton = useCallback(
    (index: number, reviewStatus: PracticeSubmitDetailReviewStatus) =>
      !permissionPracticeForInstructorAndCoachCreate &&
      index === 0 &&
      isFailed(reviewStatus) &&
      submitter.id === user.lmsUser?.id,
    [permissionPracticeForInstructorAndCoachCreate, submitter.id, user.lmsUser?.id],
  );

  // インストラクター
  // レビュー待ち
  const isDisplayReviewCreateArea = useCallback(
    (reviewStatus: PracticeSubmitDetailReviewStatus) =>
      permissionPracticeForInstructorAndCoachCreate && isWaiting(reviewStatus),
    [permissionPracticeForInstructorAndCoachCreate],
  );

  // インストラクター
  // 自身のレビューのとき
  const isDisplayReviewerEditButton = useCallback(
    (reviewerUserID: number) =>
      permissionPracticeForInstructorAndCoachUpdate && reviewerUserID === user.lmsUser?.id,
    [permissionPracticeForInstructorAndCoachUpdate, user.lmsUser?.id],
  );

  return (
    <List>
      {practiceSubmitDetails?.map((practiceSubmitDetail, index) => (
        <PracticeSubmitCard
          key={practiceSubmitDetail.id}
          reviewStatus={practiceSubmitDetail.reviewStatus}
          reviewedCount={isReviewed(practiceSubmitDetail.reviewStatus) ? 1 : 0}
          practiceTitle={practice.title ?? ''}
          programTitle={practice.program.title}
          submitter={submitter}
          submitCreatedAt={practiceSubmitDetail.createdAt}
          submitEditedAt={practiceSubmitDetail.editedAt ?? undefined}
          practiceID={practice.id}
          programID={practice.program.id}
          practiceSubmitDetail={practiceSubmitDetail}
          index={index}
          clickReSubmit={handleClickResubmit}
          setSubmitterControlMenu={setSubmitterControlMenu}
          isDisplaySubmitterEditButton={isDisplaySubmitterEditButton}
          isDisplayResubmitButton={isDisplayResubmitButton}
          isDisplayReviewCreateArea={isDisplayReviewCreateArea}
          isDisplayReviewerEditButton={isDisplayReviewerEditButton}
          fetchPracticeSubmitForInstructor={fetchPracticeSubmitForInstructor}
        />
      ))}
    </List>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
