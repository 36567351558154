import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Z_INDEX_TOOLTIP } from '../../const/ZIndex';

interface Props {
  name: string;
  className?: string;
}

export const ToolTip: React.FC<Props> = (props) => {
  return <Container className={props.className}>{props.name}</Container>;
};

const Container = styled.p`
  margin: auto;
  padding: 0.25rem 0.625rem;
  background: #333;
  border-radius: 0.1875rem;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1rem;
  white-space: nowrap;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: ${Z_INDEX_TOOLTIP};

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
    border-width: 0 0.25rem 0.25rem 0.25rem;
    position: absolute;
    top: -0.25rem;
    left: 0.5rem;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;
