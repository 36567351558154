import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import ProgramImg from '../../static/image/img_program.svg';
import AlertIcon from '../../static/image/icon_alert.svg';

export const TopProgramEmptyCard: React.FC = (): JSX.Element => {
  return (
    <StyledSection>
      <Header>
        <StyledH3>教材を見てみましょう</StyledH3>
        <StyledLink to="/programs">教材一覧へ ＞</StyledLink>
      </Header>
      <Container>
        <BgImage />
        <Description>教材で学習を開始するとこちらに前回学習した教材が表示されます</Description>
      </Container>
      <Footer>
        <StyledLink to="/programs">教材一覧へ ＞</StyledLink>
      </Footer>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 1.25rem;

  ${media.lessThan('medium')`
    padding: 1rem;
  `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: -0.5rem;

  & > * {
    margin-top: 0.5rem;
  }

  a {
    margin-left: auto;
    ${media.lessThan('medium')`
      display: none;
    `}
  }
`;

const StyledH3 = styled.h3`
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.33;
  color: #000000;

  &:before {
    content: '';
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.375rem;
    background: url(${AlertIcon}) center / contain no-repeat;
    vertical-align: bottom;
  }

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.375;
  `}
`;

const StyledLink = styled(Link)`
  color: #e2001b;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1;
`;

const Container = styled.div`
  margin-top: 1.75rem;
  display: flex;
  gap: 0.625rem;

  ${media.lessThan('large')`
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

const BgImage = styled.div`
  background: center / cover no-repeat url(${ProgramImg});
  width: 7.5rem;
  height: 4rem;

  ${media.lessThan('medium')`
    width: 9.75rem;
    height: 8.125rem;
  `}
`;

const Description = styled.p`
  flex: 1;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

const Footer = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
    justify-content: flex-end;
    margin-top: 1.125rem;
  `}
`;
