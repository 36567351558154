import React, { useMemo } from 'react';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { useUser } from '../../redux/user/useUser';
import TabNavigationLayout from './TabNavigationLayout';
import { useURL } from '../../common/customHooks/URL';

interface Props {
  activeTab: string;
  metaTitle: string;
  metaDescription?: string;
}

export const QuestionTabLayout: React.FC<Props> = ({
  activeTab,
  metaTitle,
  metaDescription,
  children,
}): JSX.Element => {
  const { permissionCheck } = useUser();
  const { excludePageQuery } = useURL();

  const tabs = useMemo(() => {
    const tabs = [
      {
        label: 'すべての質問',
        to: excludePageQuery('/questions/all'),
        active: activeTab === 'all',
      },
    ];

    if (
      permissionCheck(FunctionType.QuestionForInstructorAndCoach, PermissionType.Read) ||
      permissionCheck(FunctionType.Question, PermissionType.Read)
    ) {
      const my = {
        label: permissionCheck(FunctionType.QuestionForInstructorAndCoach, PermissionType.Read)
          ? '担当受講生の質問'
          : 'あなたの質問',
        to: excludePageQuery('/questions/my'),
        active: activeTab === 'my',
      };
      tabs.push(my);

      const answered = {
        label: '回答した質問',
        to: excludePageQuery('/questions/answered'),
        active: activeTab === 'answered',
      };
      tabs.push(answered);
    }

    if (permissionCheck(FunctionType.Question, PermissionType.Update)) {
      const draft = {
        label: '下書き',
        to: excludePageQuery('/questions/draft'),
        active: activeTab === 'draft',
      };
      tabs.push(draft);
    }

    return tabs;
  }, [permissionCheck, excludePageQuery, activeTab]);

  return (
    <TabNavigationLayout
      pageTitle="Q&A"
      tabs={tabs}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {children}
    </TabNavigationLayout>
  );
};
