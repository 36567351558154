import { RegisterUser as TRegisterUser } from '../../../swagger/gen/ts_front/model';
import { flatten, isSome, Option, toNullable } from 'fp-ts/Option';
import { findFirst } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import {
  UserNameValidation,
  UserNickNameValidation,
  UserEmailValidation,
  UserPasswordValidation,
} from '../../../utils/FormValidation';

export class RegisterUser implements TRegisterUser {
  public readonly email!: string;
  public readonly name!: string;
  public readonly nickName!: string;
  public readonly password!: string;
  public readonly smrc!: string;

  //本来はこんなやり方ではなく、JSONの型を継承していなかったら、それぞれを型定義する。
  //そうすると、同じ型(例えばstring型)で違ったフィールドを間違えて渡すことはなく、それを型レベルで保証できる。
  public constructor(props: {
    email: string;
    name?: string;
    nickName: string;
    password: string;
    smrc: string;
  }) {
    const error = pipe(this.precondition(props), toNullable);
    //preconditionとして検査して、validationを満たさなかった場合は例外を投げる
    if (error != null) {
      throw error;
    }
    Object.assign(this, props);
  }

  public precondition(props: {
    email: string;
    name?: string;
    nickName: string;
    password: string;
  }): Option<Error> {
    return flatten(
      findFirst((x: Option<Error>) => isSome(x))([
        ...(props.name ? [UserNameValidation(props.name)] : []),
        UserNickNameValidation(props.nickName),
        UserEmailValidation(props.email),
        UserPasswordValidation(props.password),
      ]),
    );
  }
}
