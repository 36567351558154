// カレンダーのブロックの数
// 営業時間の幅などを変更する場合はここも変更すること
const timeRowsCount = 13;

// 営業開始時間
// 営業時間の幅を変更する場合はここも変更すること
const openAt = 10;

// timeRowsCountをもとにカレンダーの行を自動生成
const TIME_ROWS = Array.from(Array(timeRowsCount)).map((_, i) => {
  return `${i + openAt}:00`;
});

// カレンダーの時間の始点
const SLOT_MIN_TIME = TIME_ROWS[0];

// カレンダーの時間の終点
const SLOT_MAX_TIME = '23:00';

const AVAILABLE_START_HOURS = Array.from(Array(timeRowsCount)).map((_, i) => {
  return {
    name: `${i + openAt}:00`,
    value: `${i + openAt}`,
  };
}); // [{name: 10:00, value: 10}, {name: 11:00, value: 11}...]

const BUSINESS_HOURS = Array.from(Array(timeRowsCount)).map((_, i) => {
  return {
    name: `${i + openAt + 1}:00`,
    value: `${i + openAt + 1}`,
  };
}); // [{name: 11:00, value: 11}, {name: 12:00, value: 12}...]

const SPOT_LESSON_TIME = 60;

const SPOT_LESSON_EVALUATION_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSdL2MxBQ-k4gPXiVmIhkWXjsJx5pTDBuGuzhgWtxFFwySU31w/viewform?usp=sf_link';

export {
  TIME_ROWS,
  SLOT_MIN_TIME,
  SLOT_MAX_TIME,
  AVAILABLE_START_HOURS,
  BUSINESS_HOURS,
  SPOT_LESSON_TIME,
  SPOT_LESSON_EVALUATION_FORM_URL,
};
