import React from 'react';
import styled from 'styled-components';

interface TypographyProps {
  color: string;
  className?: string;
}
interface Color {
  [key: string]: string;
  LIGHT: string;
  DARK: string;
  WildStrawberry: string;
}

//ここを元に命名をするようにする
// https://chir.ag/projects/name-that-color/#FD2F92
const FONT_COLOR: Color = {
  LIGHT: 'rgba(0,0,0,.6)',
  DARK: 'rgba(0,0,0,.87)',
  WildStrawberry: 'rgba(253,47,146)',
};

export const H1: React.FC<TypographyProps> = (props) => {
  return (
    <StyledH1 className={props.className} color={props.color}>
      {props.children}
    </StyledH1>
  );
};

const StyledH1 = styled.h1<{ color: string }>`
  color: ${(props) => FONT_COLOR[props.color || 'DARK']};
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const H2: React.FC<TypographyProps> = (props) => {
  return (
    <StyledH2 className={props.className} color={props.color}>
      {props.children}
    </StyledH2>
  );
};

const StyledH2 = styled.h2<{ color: string }>`
  color: ${(props) => FONT_COLOR[props.color || 'DARK']};
  font-size: 1.25rem;
  font-weight: 600;
`;

export const H3: React.FC<TypographyProps> = (props) => {
  return (
    <StyledH3 className={props.className} color={props.color}>
      {props.children}
    </StyledH3>
  );
};

export const StyledH3 = styled.h3<{ color: string }>`
  color: ${(props) => FONT_COLOR[props.color || 'DARK']};
  font-size: 1.15rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const H4: React.FC<TypographyProps> = (props) => {
  return (
    <StyledH4 className={props.className} color={props.color}>
      {props.children}
    </StyledH4>
  );
};

const StyledH4 = styled.h4<{ color: string }>`
  color: ${(props) => FONT_COLOR[props.color || 'DARK']};
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const H5: React.FC<TypographyProps> = (props) => {
  return (
    <StyledH5 className={props.className} color={props.color}>
      {props.children}
    </StyledH5>
  );
};

const StyledH5 = styled.h5<{ color: string }>`
  color: ${(props) => FONT_COLOR[props.color || 'DARK']};
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const H6: React.FC<TypographyProps> = (props) => {
  return (
    <StyledH6 className={props.className} color={props.color}>
      {props.children}
    </StyledH6>
  );
};

const StyledH6 = styled.h6<{ color: string }>`
  color: ${(props) => FONT_COLOR[props.color || 'DARK']};
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const Large: React.FC<TypographyProps> = (props) => {
  return (
    <LargeP className={props.className} color={props.color}>
      {props.children}
    </LargeP>
  );
};

const LargeP = styled.p<{ color: string }>`
  color: ${(props) => FONT_COLOR[props.color || 'DARK']};
  font-size: 1.125rem;
  font-weight: 400;
`;

export const Normal: React.FC<TypographyProps> = (props) => {
  return (
    <NormalP className={props.className} color={props.color}>
      {props.children}
    </NormalP>
  );
};

const NormalP = styled.p<{ color: string }>`
  color: ${(props) => FONT_COLOR[props.color || 'DARK']};
  font-size: 1rem;
  font-weight: 400;
`;

export const Small: React.FC<TypographyProps> = (props) => {
  return (
    <SmallP className={props.className} color={props.color}>
      {props.children}
    </SmallP>
  );
};

const SmallP = styled.p<{ color: string }>`
  color: ${(props) => FONT_COLOR[props.color || 'DARK']};
  font-size: 0.875rem;
  font-weight: 400;
`;

export const Tiny: React.FC<TypographyProps> = (props) => {
  return (
    <TinyP className={props.className} color={props.color}>
      {props.children}
    </TinyP>
  );
};

const TinyP = styled.p<{ color: string }>`
  color: ${(props) => FONT_COLOR[props.color || 'DARK']};
  font-size: 0.75rem;
  font-weight: 400;
`;
