import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';
import { TextArea, TextAreaHandler } from '../atoms/TextArea';
import { UserEmailValidation } from '../../utils/FormValidation';
import { isNone } from 'fp-ts/Option';
import { MAXIMUM_NUMBER_OF_INVITATION_MAIL } from '../../const/team';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  onSubmit: (value: string[]) => void;
}

export const TeamInviteModal: React.FC<Props> = (props) => {
  const emailsRef = useRef<TextAreaHandler>(null);
  const [error, setError] = useState<string>('');

  // 改行が入力されるとテキストエリアの行を1つ増やす
  const textarea = document.querySelector('textarea');
  const textareaEvent = () => {
    const textareaValue = textarea?.value;
    if (!textareaValue) return;

    const line = textareaValue.match(/\r?\n/g)?.length ?? 0;
    textarea.rows = line + 1;
  };
  textarea && textarea.addEventListener('input', textareaEvent);

  const handleSubmit = useCallback((): void => {
    setError('');

    // 空白と改行を除去
    const emailString = emailsRef.current?.getValue().trim().replace(/\r?\n/g, '');
    if (!emailString) {
      setError('入力内容にエラーがあります。');
      return;
    }
    const emails = emailString.split(',');
    // 重複と空文字を除去
    const extractEmails = emails.filter(
      (email, index) => emails.indexOf(email) === index && !!email,
    );
    // 形式チェック
    for (let i = 0; i < extractEmails.length; i++) {
      if (!isNone(UserEmailValidation(extractEmails[i]))) {
        setError('メールアドレスは正しい形式で入力してください。');
        return;
      }
    }
    // 招待メール数上限チェック
    if (extractEmails.length > MAXIMUM_NUMBER_OF_INVITATION_MAIL) {
      setError('1度に送ることができるメール数は100通までです。');
      return;
    }

    props.onSubmit(extractEmails);

    props.onClose();
  }, [props]);

  useEffect(() => {
    if (!props.isOpen) {
      emailsRef.current?.setValue('');
      setError('');
      if (!textarea) return;
      textarea.rows = 1;
    }
  }, [props.isOpen, textarea]);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      loading={props.loading}
      width={'576px'}
      header={<Title>新規メンバーを招待</Title>}
      footer={
        <ButtonWrapper>
          <CancelButton onClick={props.onClose}>取り消す</CancelButton>
          <InviteButton onClick={handleSubmit}>招待メールを送信</InviteButton>
        </ButtonWrapper>
      }
    >
      <Container>
        <InputArea>
          <Label>招待メール</Label>
          <TextArea
            name="emails"
            placeholder="samurai@sejuku.net"
            ref={emailsRef}
            width="504px"
            error={!!error}
          />
        </InputArea>
        <Note>複数人を招待するときは、コンマ「,」で区切ってください。</Note>
        {error && <Error>{error}</Error>}
      </Container>
    </Modal>
  );
};

const Title = styled.h3`
  width: 100%;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  border-radius: 0.125rem;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition: all 0.2s;

  &:hover {
    background: #efefef;
  }
`;
const InviteButton = styled(Button)`
  display: block;
  width: 10rem;
  padding: 0.5rem;
  margin-left: 1rem;
  background: #eb0000;
  border: 1px solid #eb0000;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    color: #eb0000;
  }
`;

const Container = styled.div`
  max-width: 480px;
  margin: 0 auto;
  padding: 1.25rem 1.5rem 3rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 1rem 1rem 2.25rem;
  `}
`;
const InputArea = styled.div``;
const Label = styled.div`
  margin-bottom: 0.75rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375px;
`;
const Error = styled.p`
  margin-top: 0.75rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #eb0000;
`;
const Note = styled.p`
  margin-top: 0.75rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.1875rem;
`;
