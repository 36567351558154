import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  className?: string;
}

export const ProgramListContainer: React.FC<Props> = (props) => {
  return <Container className={props.className}>{props.children}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 1.25rem;
  width: 100%;
  position: relative;

  ${media.lessThan('medium')`
    gap: 1rem;
  `}
`;
