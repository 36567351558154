import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Ttl } from '../../atoms/LpTtl';
import { LpSlider } from '../../molecules/LpSlider';
import { ResultSlide } from '../../molecules/LpResultSlide';

import SliderImg001 from '../../../static/image/lp/voice_slider001.png';
import SliderImg002 from '../../../static/image/lp/voice_slider002.png';
import SliderImg003 from '../../../static/image/lp/voice_slider003.png';
import SliderImg004 from '../../../static/image/lp/voice_slider004.png';

import { SERVICE_NAME } from '../../../const/Service';

export const LpResult = (): JSX.Element => {
  return (
    <Container data-gtm="result">
      <StyledTtl>
        <h2>未経験から続々とエンジニアに！</h2>
      </StyledTtl>
      <LpSlider>
        <ResultSlide
          image={SliderImg001}
          name={'羽多野遼佑'}
          comment={'作ったアプリがコンテストで特別賞を受賞し、サイト制作案件を受注できました。'}
        />
        <ResultSlide
          image={SliderImg002}
          name={'伊藤走'}
          comment={
            '自分が欲しかったアプリを作れる技術がつき、iOSエンジニアとして転職成功できました。'
          }
        />
        <ResultSlide
          image={SliderImg003}
          name={'南條伶央'}
          comment={'オリジナルアプリでポテンシャルをアピールし、XRエンジニアとして転職できました。'}
        />
        <ResultSlide
          image={SliderImg004}
          name={'樋口大輔'}
          comment={
            'スクールで学んだことを応用し、インターン先で実践的な開発ができるようになりました。'
          }
        />
      </LpSlider>
      <Sup>
        ※こちらの受講生は{SERVICE_NAME}に加えてマンツーマンレッスンもご利用いただいた方々です。
      </Sup>
    </Container>
  );
};

const Container = styled.section`
  margin: 3rem auto 0;
`;
const StyledTtl = styled(Ttl)`
  margin-bottom: 4rem;

  ${media.lessThan('medium')`
    padding: 0 1rem;
    margin-bottom: 2rem;

    h2 {
      line-height: 1.5;
    }
  `}
`;
const Sup = styled.p`
  max-width: 1186px;
  margin: -0.625rem auto 0;
  padding: 0 3.5rem;
  color: rgba(0, 0, 0, 0.36);
  font-size: 0.625rem;
  text-align: right;

  ${media.lessThan('large')`
    margin-top: 0.5rem;
  `}

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}

  ${media.lessThan('small')`
    line-height: 1.2;
    text-align: left;
  `}
`;
