import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { useUser } from '../../../redux/user/useUser';

import Logo from '../../../static/image/logo.svg';

import { Button } from '../../atoms/Button';
import { Loader } from '../../molecules/Loader';
import { RegisterForm } from '../../organisms/RegisterForm';
import { LoginModal } from '../../organisms/LoginModal';

import { TOAST_TYPE_ERROR, useToastsContext } from '../../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useRegisterMutation } from '../../../gen/graphql';
import { NoHeaderLayout } from '../../templates/NoHeaderLayout';
import { LOWER_META_TITLE } from '../../../const/Service';
import { getApiErrorMessage } from '../../../utils/graphqlError';

export const TeamMemberRegister = (): JSX.Element => {
  const metaTitle = `アカウント作成 | ${LOWER_META_TITLE}`;

  const { user, login } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToastsContext();
  const [cookies] = useCookies();

  const [clickableRegisterUserButton, setClickableRegisterUserButton] = useState(false);

  const [registerMutation] = useRegisterMutation();

  const [name, setName] = useState('');
  const [nickName, setNickName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showLoader, setShowLoader] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const invitationID = new URLSearchParams(location.search).get('id');
  const invitationURL = `/team_member/register/join?id=${invitationID ?? ''}`;

  const submitUser = useSafeAsyncCallback(async (): Promise<void> => {
    setShowLoader(true);

    try {
      await registerMutation({
        variables: {
          input: {
            name: name,
            nickName: nickName,
            email: email,
            password: password,
            smrc: cookies['smrc_'],
          },
        },
      });
      await login(email, password);
    } catch (e) {
      showToast(TOAST_TYPE_ERROR, getApiErrorMessage(e));
      return;
    } finally {
      setShowLoader(false);
    }

    showToast(0, 'アカウントの登録に成功しました。');
    navigate(invitationURL);
  });

  const openLoginModal = useCallback(() => setLoginModalOpen(true), []);
  const closeLoginModal = useCallback(() => setLoginModalOpen(false), []);

  useEffect(() => {
    if (user.lmsUser) {
      // ユーザー登録済みの一般ユーザーは紐付け画面へ
      navigate(invitationURL, { replace: true });
    }
  }, [user.lmsUser, navigate, invitationURL]);

  return (
    <NoHeaderLayout metaTitle={metaTitle}>
      <Loader display={showLoader} />
      <Header>
        <Link to="/">
          <img src={Logo} alt="Samurai" />
        </Link>
        <LoginLink onClick={openLoginModal}>ログイン</LoginLink>
      </Header>

      <Main>
        <Container>
          <Title>アカウント作成</Title>
          <Description>
            アカウントを作成します。既に侍テラコヤの個人アカウントを作成してある場合は、既存のアカウントを法人で利用するアカウントに設定できます。
          </Description>
          <Agreement>
            会員登録により、
            <a href="https://www.sejuku.net/corp/personal-information-signup" target="_blank">
              個人情報の取り扱い
            </a>
            および
            <a href="https://www.sejuku.net/corp/terakoya-agreement" target="_blank">
              利用規約
            </a>
            に同意するものとします。
          </Agreement>
          <RegisterForm
            name={name}
            setName={setName}
            nickName={nickName}
            setNickName={setNickName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            clickableRegisterButton={setClickableRegisterUserButton}
            socialLoginCallbackURL={invitationURL}
          />
          <LoginMessage>
            既にアカウントをお持ちの方は
            <a href="javascript:void(0)" onClick={openLoginModal}>
              ログイン
            </a>
            へ
          </LoginMessage>
          <RegisterButton onClick={submitUser} disabled={!clickableRegisterUserButton}>
            アカウントを登録する
          </RegisterButton>
        </Container>
      </Main>
      <LoginModal isOpen={loginModalOpen} onClose={closeLoginModal} redirectURL={invitationURL} />
    </NoHeaderLayout>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 67px;
  padding: 0 2rem;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  img {
    height: 1.5rem;
  }

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;
const LoginLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.3125rem;
  height: 2.375rem;
  border: 1px solid #e2001b;
  border-radius: 0.125rem;
  box-sizing: border-box;
  cursor: pointer;
  color: #e2001b;
  font-size: 0.875rem;
  font-weight: 700;
  transition: all 0.2s;

  &:after {
    content: 'する';
  }

  &:hover {
    background: #e2001b;
    color: #fff;
  }

  ${media.lessThan('medium')`
    width: 6.8125rem;
    height: 2rem;

    &:after {
      content: none;
    }
  `}
`;

const Main = styled.main`
  background-color: #fcfcfc;
  box-sizing: border-box;
  padding-top: 2rem;
`;
const Container = styled.section`
  margin: 0 auto;
  padding: 2rem 4rem;
  box-sizing: border-box;
  max-width: 39.5rem;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    padding: 2rem;
  `}
`;
const Title = styled.h2`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 700;
`;

const Description = styled.p`
  margin-top: 2.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;

  ${media.lessThan('medium')`
    margin-top: 2.5rem;
    font-size: 0.75rem;
    line-height: 1.3;
  `}
`;
const Agreement = styled.p`
  margin-top: 1rem;
  font-size: 0.75rem;
  line-height: 1.3;

  a {
    color: #fd6258;
    font-size: inherit;
  }
`;

const LoginMessage = styled.p`
  margin-top: 2rem;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.5;

  a {
    color: #fd6258;
    font-size: inherit;
  }

  ${media.lessThan('medium')`
    font-size: 0.75rem;
    line-height: 1.3;
  `}
`;

const RegisterButton = styled(Button)`
  width: 17.5rem;
  margin-top: 2rem;
  padding-left: 0;
  padding-right: 0;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 360px;
    margin: 2rem auto 0;
  `}
`;
