import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import TagManager from 'react-gtm-module';
import { Link, useParams } from 'react-router-dom';
import { needsModalForEditing } from '../../../utils/Question';

import { useUser } from '../../../redux/user/useUser';

import { ErrorText } from '../../atoms/ErrorText';
import { Button } from '../../atoms/Button';
import { PageWrapper } from '../../atoms/PageWrapper';
import { RichInput, RichInputHandler } from '../../molecules/RichInput';
import { QuestionBody } from '../../molecules/QuestionBody';
import { QuestionAnswer } from '../../organisms/QuestionAnswer';
import { QuestionAnswerWaiting } from '../../organisms/QuestionAnswerPending';
import { BasicLayout } from '../../templates/BasicLayout';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { QuestionReviewModal } from '../../organisms/QuestionReviewModal';
import { QuestionEditingModal } from '../../organisms/QuestionEditingModal';

import { ANSWER_PLACE_HOLDER, AI_ANSWER_PLACE_HOLDER } from '../../../const/Question';
import { defaultErrorMessage } from '../../../const/ErrorMessage';

import {
  useResolveQuestionMutation,
  useAddQuestionAnswerMutation,
  useChangeQuestionFavoriteStatusMutation,
  useGetQuestionQuery,
  useChangeQuestionAnswerFavoriteStatusMutation,
  useGetAiUserQuery,
  BannerCidType,
  AnswerRequestType,
  AnswerType,
} from '../../../gen/graphql';
import {
  isWaitingAIAnswer,
  latestAnswerRequestedDate,
  isEscalated,
  getAnswerInputType,
  AnswerInputType,
} from '../../../utils/Question';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../../context/ToastsProvider';
import {
  QuestionAnswerContentError,
  validateQuestionAnswerContent,
} from '../../../utils/FormValidation';
import { useBannerCids } from '../../../common/customHooks/BannerCids';
import { MIDDLE_LIMIT } from '../../../const/Page';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { NotFoundPage } from '../public/NotFound';
import { useCommonModal } from '../../../redux/common_modal/useCommonModal';
import { LOWER_META_TITLE } from '../../../const/Service';

export const QuestionDetailContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathQuestionID = Number(paramID);
  if (Number.isNaN(pathQuestionID)) return NotFoundPage;

  return <QuestionDetail questionID={pathQuestionID} />;
};

interface QuestionDetailProps {
  questionID: number;
}

const QuestionDetail = (props: QuestionDetailProps): JSX.Element => {
  const { openModal } = useCommonModal();
  const navigate = useNavigate();
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);

  const [metaTitle, setMetaTitle] = useState('');

  const { user, permissionCheck } = useUser();
  const { showToast } = useToastsContext();

  const [pollingInterval, setPollingInterval] = useState<number>(0);

  const { data, loading, refetch } = useGetQuestionQuery({
    variables: { questionID: props.questionID },
    // AIの回答を完了次第表示できるようにPollingする
    // 通常の質問はポーリングしないため0を設定する
    // AIの回答が完了した場合も0を設定し、ポーリングは行わない
    // see:https://www.apollographql.com/docs/react/data/queries/#polling
    pollInterval: pollingInterval,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMetaTitle(`${data?.getQuestion?.title} | ${LOWER_META_TITLE}`);
    },
  });
  const question = data?.getQuestion ?? undefined;
  const isWaitingAIAnswerResult = isWaitingAIAnswer(question);
  const isAnswerPending =
    question?.answerRequestType === AnswerRequestType.Ai && isWaitingAIAnswerResult;
  const requestedDate = latestAnswerRequestedDate(question);
  const isOwnQuestion = question?.user.id === user.lmsUser?.id;
  const openModalForEditing = needsModalForEditing(question);

  // AIIcon表示用のユーザーデータ
  const { data: aiUserData } = useGetAiUserQuery();
  const aiUser = aiUserData?.aiUser ?? undefined;

  const { pcBanners, spBanners } = useBannerCids([BannerCidType.Qa], MIDDLE_LIMIT);

  const refetchQuestion = () => refetch();

  const [resolveQuestion] = useResolveQuestionMutation({
    onCompleted: refetchQuestion,
  });

  const [addQuestionAnswer] = useAddQuestionAnswerMutation({
    onCompleted: refetchQuestion,
  });

  const [changeFavoriteStatusMutation] = useChangeQuestionFavoriteStatusMutation();

  const [changeAnswerFavoriteStatusMutation] = useChangeQuestionAnswerFavoriteStatusMutation();

  const answerContentRef = useRef<RichInputHandler>(null);
  const [contentError, setContentError] = useState<QuestionAnswerContentError | null>(null);

  const submitAnswer = useSafeAsyncCallback(
    useCallback(
      async (isEscalation: boolean) => {
        const answerContent = answerContentRef.current?.getValue() ?? '';
        const error = validateQuestionAnswerContent(answerContent);
        setContentError(error);
        if (error) return;

        try {
          await addQuestionAnswer({
            variables: {
              questionID: props.questionID,
              input: {
                content: answerContent,
              },
              isEscalation: isEscalation,
            },
          });
          answerContentRef.current?.setValue('');
        } catch {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          showToast(1, defaultErrorMessage);
        }
      },
      [addQuestionAnswer, props.questionID, showToast],
    ),
  );

  const openReviewModal = useCallback(() => {
    answerContentRef.current?.setValue('');
    setIsReviewModalOpen(true);
  }, [setIsReviewModalOpen]);

  const handleSolved = useSafeAsyncCallback(
    useCallback(async () => {
      try {
        await resolveQuestion({ variables: { questionID: props.questionID } });

        // 2度押し対策のため、この関数内で更新後データの再取得を完了させる
        await refetch();
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, defaultErrorMessage);
      }

      TagManager.dataLayer({
        dataLayer: {
          userId: user.lmsUser?.id,
          user: user.lmsUser,
          event: 'gtm-qa-que-solved',
          eventData: { questionID: props.questionID },
        },
        dataLayerName: 'LMSDataLayer',
      });
    }, [props.questionID, user.lmsUser, refetch, resolveQuestion, showToast]),
  );

  const changeFavoriteStatus = useSafeAsyncCallback(
    useCallback(async () => {
      if (openModal(FunctionType.QuestionFavoriteAndAnswerFavorite, PermissionType.Update)) return;

      try {
        await changeFavoriteStatusMutation({ variables: { questionID: props.questionID } });
        // HACK refetchに時間かかるのでキャッシュを使うようにした方がいいかもしれない
        await refetch();
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, defaultErrorMessage);
      }
    }, [changeFavoriteStatusMutation, props.questionID, refetch, showToast, openModal]),
  );

  const changeAnswerFavoriteStatus = useSafeAsyncCallback(
    useCallback(
      async (questionAnswerID: number) => {
        if (openModal(FunctionType.QuestionFavoriteAndAnswerFavorite, PermissionType.Update))
          return;

        try {
          await changeAnswerFavoriteStatusMutation({ variables: { questionAnswerID } });
          // HACK refetchに時間かかるのでキャッシュを使うようにした方がいいかもしれない
          await refetch();
        } catch {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          showToast(1, defaultErrorMessage);
        }
      },
      [changeAnswerFavoriteStatusMutation, refetch, showToast, openModal],
    ),
  );

  const handleClickEdit = () => {
    TagManager.dataLayer({
      dataLayer: {
        userId: user.lmsUser?.id,
        user: user.lmsUser,
        event: 'gtm-qa-que-edit',
        eventData: { questionID: props.questionID },
      },
      dataLayerName: 'LMSDataLayer',
    });

    if (openModalForEditing) {
      setIsEditingModalOpen(true);
      return;
    }

    navigate(`/question/edit/${props.questionID}`);
  };

  const handleCancelEditModal = () => {
    setIsEditingModalOpen(false);
  };

  const handleConfirmEditModal = () => {
    setIsEditingModalOpen(false);
    navigate(`/question/edit/${props.questionID}`);
  };

  const breadcrumbs = [
    {
      label: 'ホーム',
      to: '/home',
    },
    {
      label: 'Q&A一覧',
      to: `/questions/${
        permissionCheck(FunctionType.QuestionForInstructorAndCoach, PermissionType.Read)
          ? 'my'
          : 'all'
      }`,
    },
    {
      label: 'Q&A詳細',
    },
  ];

  const answerInputType = getAnswerInputType(
    question?.answerRequestType,
    isAnswerPending,
    isOwnQuestion,
  );

  useEffect((): void => {
    if (!isAnswerPending) {
      setPollingInterval(0);
    } else {
      setPollingInterval(3000);
    }
  }, [isAnswerPending, setPollingInterval]);

  return (
    <>
      <BasicLayout pageTitle="Q&A" metaTitle={metaTitle}>
        <PageWrapper>
          <BreadcrumbWrapper>
            <StyledBreadcrumb links={breadcrumbs} />
            {question?.tags && (
              <Tags>
                {question.tags.map((tag) => (
                  <TagLink to={`/questions/all}&tag_id=${tag.id}`}>{tag.name}</TagLink>
                ))}
              </Tags>
            )}
          </BreadcrumbWrapper>
          <ContentsWrapper>
            <Left>
              {loading && <Loading>Loading...</Loading>}
              {question && (
                <>
                  <QuestionBody
                    question={question}
                    answersCount={question.answerCount}
                    handleClickEdit={
                      permissionCheck(FunctionType.Question, PermissionType.Update)
                        ? handleClickEdit
                        : undefined
                    }
                    openReviewModal={
                      permissionCheck(FunctionType.QuestionResolve, PermissionType.Update)
                        ? openReviewModal
                        : undefined
                    }
                    onChangeFavoriteStatus={changeFavoriteStatus}
                  />
                </>
              )}

              {question?.answerCount ? (
                <Section>
                  <AnswerCount>全{question.answerCount}件の回答</AnswerCount>
                  {question?.questionAnswers?.map((answer) => {
                    return (
                      <QuestionAnswer
                        key={answer.id}
                        answer={answer}
                        isAnswerPending={isAnswerPending}
                        answerPendingUser={aiUser}
                        answerRequestedDate={requestedDate}
                        isOwnQuestion={isOwnQuestion}
                        answerRequestType={question.answerRequestType}
                        isEscalated={isEscalated(question)}
                        isQuestionAnswerTypeAI={answer.answerType === AnswerType.Ai}
                        questionID={props.questionID}
                        refetchQuestion={refetchQuestion}
                        onChangeFavoriteStatus={changeAnswerFavoriteStatus}
                        openModalForEditing={openModalForEditing}
                      />
                    );
                  })}
                </Section>
              ) : (
                isAnswerPending && (
                  <Section>
                    <AnswerCount>全0件の回答</AnswerCount>
                    <QuestionAnswerWaiting
                      answerPendingUser={aiUser}
                      answerRequestedDate={requestedDate}
                    />
                  </Section>
                )
              )}

              {!(
                !permissionCheck(FunctionType.QuestionAnswerAndComment, PermissionType.Create) &&
                (permissionCheck(FunctionType.RecommendLight, PermissionType.Read) ||
                  permissionCheck(FunctionType.RecommendTeamSubscription, PermissionType.Read) ||
                  permissionCheck(
                    FunctionType.RecommendTeamSubscriptionToAdmin,
                    PermissionType.Read,
                  ))
              ) &&
                (answerInputType === AnswerInputType.Escalation ||
                answerInputType === AnswerInputType.EscalationDisable ? (
                  <PostSection>
                    <PostTitle>
                      AI先生で解決しないため、講師からの回答を依頼する
                      {question?.user?.id === user.lmsUser?.id ? (
                        <span>※講師に回答依頼した場合、AI先生には返信できなくなります</span>
                      ) : (
                        ''
                      )}
                    </PostTitle>
                    <RichInput
                      fileUpload={false}
                      imageUpload
                      initialHeight={10}
                      ref={answerContentRef}
                      name="question_answer"
                      placeholder={AI_ANSWER_PLACE_HOLDER}
                      e2e="escalationInput"
                    />
                    {contentError && <StyledErrorText>{contentError.message}</StyledErrorText>}
                    <SubmitButton
                      onClick={() => submitAnswer(true)}
                      disabled={answerInputType === AnswerInputType.EscalationDisable}
                      border
                      e2e="escalationSubmit"
                    >
                      回答を依頼する
                    </SubmitButton>
                  </PostSection>
                ) : answerInputType === AnswerInputType.Normal ? (
                  <PostSection>
                    <PostTitle>
                      回答する
                      {question?.user?.id === user.lmsUser?.id ? (
                        <span>※自己解決の場合のみ記入してください</span>
                      ) : (
                        ''
                      )}
                    </PostTitle>
                    <RichInput
                      fileUpload={false}
                      imageUpload
                      initialHeight={10}
                      ref={answerContentRef}
                      name="question_answer"
                      placeholder={ANSWER_PLACE_HOLDER}
                      e2e="escalatedQuestionInput"
                    />
                    {contentError && <StyledErrorText>{contentError.message}</StyledErrorText>}
                    <SubmitButton onClick={() => submitAnswer(false)} e2e="submitAnswer" border>
                      回答する
                    </SubmitButton>
                  </PostSection>
                ) : (
                  ''
                ))}
            </Left>

            <RightPcBannerArea>
              {pcBanners.map((pcBanner) => (
                <a href={pcBanner.banner.link + pcBanner.cid} target="_blank" key={pcBanner.id}>
                  <img src={pcBanner.banner.imageURL} alt={pcBanner.banner.title} />
                </a>
              ))}
            </RightPcBannerArea>
          </ContentsWrapper>

          <SpBannerArea>
            {spBanners.map((spBanner) => (
              <a href={spBanner.banner.link + spBanner.cid} target="_blank" key={spBanner.id}>
                <img src={spBanner.banner.imageURL} alt={spBanner.banner.title} />
              </a>
            ))}
          </SpBannerArea>
        </PageWrapper>
      </BasicLayout>
      {/*親要素にtransformがあり位置をfixできないので外に出している*/}
      {question?.user?.id === user.lmsUser?.id && (
        <QuestionReviewModal
          isOpen={isReviewModalOpen}
          onClose={() => setIsReviewModalOpen(false)}
          handleSolved={
            permissionCheck(FunctionType.QuestionResolve, PermissionType.Update)
              ? handleSolved
              : undefined
          }
          questionID={props.questionID}
        />
      )}
      <QuestionEditingModal
        isOpen={isEditingModalOpen}
        onClose={() => setIsEditingModalOpen(false)}
        handleCancel={handleCancelEditModal}
        handleConfirm={handleConfirmEditModal}
      />
    </>
  );
};

const BreadcrumbWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    margin-bottom: .75rem;
    padding: 0 1rem;
  `}
`;
const StyledBreadcrumb = styled(Breadcrumb)`
  width: auto;
`;
const Tags = styled.div`
  flex: 1;
  display: flex;
  margin-left: 1rem;

  ${media.lessThan('medium')`
    width: 100%;
    margin: .5rem auto 0;
  `}
`;
const TagLink = styled(Link)`
  margin-right: 0.5rem;
  padding: 0.25rem 0.75rem;
  background: rgba(241, 196, 78, 0.6);
  border: 1px solid #f1c44e;
  border-radius: 1.25rem;
  color: rgba(51, 51, 51, 0.87);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
`;

const Loading = styled.p`
  margin-bottom: 2rem;

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;

const Section = styled.section`
  max-width: 956px;

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;

const AnswerCount = styled.p`
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  font-weight: 400;

  ${media.lessThan('medium')`
    margin-bottom: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  `}
`;

const PostSection = styled(Section)`
  margin-top: 2rem;
  position: relative;
`;
const PostTitle = styled.h2`
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;

  span {
    margin-left: 1rem;
    color: #000;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  ${media.lessThan('medium')`
    font-weight: 700;
    line-height: 1.5rem;

    span {
      display: block;
      margin: 1rem auto 0;
      font-weight: 400;
      line-height: 1.4;
    }
  `}
`;

const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 240px;
  margin: 1rem auto 0;

  ${media.lessThan('small')`
    max-width: none;
  `}
`;

const ContentsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Left = styled.section`
  flex: 1;
  min-width: 0;
`;

const RightPcBannerArea = styled.div`
  position: relative;
  width: 284px;
  margin-left: 1.25rem;

  & > a {
    display: block;
    width: 100%;

    img {
      display: block;
      width: 100%;
    }

    &:not(:first-child) {
      margin-top: 1rem;
    }

    &:last-of-type {
      position: sticky;
      top: 80px;
    }
  }

  ${media.lessThan('large')`
    width: 230px;
    margin-left: 1rem;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;

const SpBannerArea = styled.section`
  display: none;

  ${media.lessThan('medium')`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;

    > a {
      display: block;
      margin-top: 1rem;
  
      img {
        display: block;
        width: 100%;
      }
    }
  `}
`;

const StyledErrorText = styled(ErrorText)`
  margin-top: 0.25rem;
`;
