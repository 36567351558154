import React, { memo, useCallback, useEffect, useRef } from 'react';
import { areEqual } from 'react-window';
import Measure, { ContentRect } from 'react-measure';
import memoize from 'memoize-one';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format, isSameDay, isToday } from 'date-fns';

import { Bool, ChatMessageListFragment } from '../../gen/graphql';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useUser } from '../../redux/user/useUser';
import { ContributorIcon } from '../atoms/ContributorIcon';
import { Balloon } from '../molecules/Balloon';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { ConvertLocalToUTCDate } from '../../utils/DateUTC';

const PADDING_SIZE = 30;

interface ChatMessageListRowData {
  chatRoomId: number;
  chatMessages: ChatMessageListFragment[];
  editingMessage: Map<number, string>;
  handleItemResize: (index: number, content: ContentRect) => void;
  onConfirmEditing: () => Promise<unknown>;
  onEditing: (isEdit: boolean) => void;
  onEditingMessage: (message: ChatMessageListFragment | null) => void;
  isChatMessageLoaded: (index: number) => boolean;
  readCalled: boolean;
  setReadCalled: React.Dispatch<React.SetStateAction<boolean>>;
  readMessages: (chatRoomId: number) => void;
  setIncoming: React.Dispatch<React.SetStateAction<boolean>>;
}

export const createChatMessageListRowData = memoize(
  (
    chatRoomId,
    chatMessages,
    editingMessage,
    handleItemResize,
    onConfirmEditing,
    onEditing,
    onEditingMessage,
    isChatMessageLoaded,
    readCalled,
    setReadCalled,
    readMessages,
    setIncoming,
  ) => ({
    chatRoomId,
    chatMessages,
    editingMessage,
    handleItemResize,
    onConfirmEditing,
    onEditing,
    onEditingMessage,
    isChatMessageLoaded,
    readCalled,
    setReadCalled,
    readMessages,
    setIncoming,
  }),
);

export const ChatMessageListRow = memo(
  ({
    data,
    index,
    style,
  }: {
    data: ChatMessageListRowData;
    index: number;
    style: React.CSSProperties;
  }) => {
    const {
      chatRoomId,
      chatMessages,
      editingMessage,
      handleItemResize,
      onConfirmEditing,
      onEditing,
      onEditingMessage,
      isChatMessageLoaded,
      readCalled,
      setReadCalled,
      readMessages,
      setIncoming,
    } = data;

    const { user: loginUser, permissionCheck } = useUser();

    const newLineRef = useRef(null);

    const confirmEditing = useCallback(
      async (message: ChatMessageListFragment) => {
        if (editingMessage.size > 0 && !editingMessage.has(message.id)) {
          return await onConfirmEditing();
        }
        return true;
      },
      [editingMessage, onConfirmEditing],
    );

    const isNewLine = useCallback(
      (index: number): boolean => {
        const unreadMessages = chatMessages.filter((v) => v.userChatMessage?.isRead === Bool.False);
        const lastUnreadMessage = unreadMessages.slice(-1)[0];

        if (chatMessages[index] === undefined || lastUnreadMessage === undefined) return false;

        return chatMessages[index].id === lastUnreadMessage.id;
      },
      [chatMessages],
    );

    useEffect(() => {
      const element = newLineRef.current;
      const observer = new IntersectionObserver(async ([entry]) => {
        if (entry.isIntersecting) {
          // 既に1度実行されている場合は、スキップ
          if (readCalled) {
            return;
          }

          await readMessages(chatRoomId);

          setReadCalled(true);
          setIncoming(false);
        }
      });

      if (element) observer.observe(element);

      return () => {
        if (element) observer.unobserve(element);
      };
    }, [chatRoomId, readCalled, readMessages, setIncoming, setReadCalled]);

    // notice
    // 素早くスクロールした際に、間に合わない場合があり
    // エラーになってしまうのを防いでいます。
    if (!chatMessages[index]) {
      return <></>;
    }

    const { id, user, createdAt } = chatMessages[index];

    const self = user?.id === loginUser.lmsUser?.id;

    const isSameDate =
      chatMessages[index + 1] &&
      isSameDay(new Date(chatMessages[index + 1].createdAt), new Date(createdAt));

    const item = editingMessage.has(id)
      ? Object.assign({} as ChatMessageListFragment, {
          ...chatMessages[index],
          content: editingMessage.get(id),
        })
      : chatMessages[index];

    return (
      <Wrapper
        style={{
          ...style,
          top: `${parseFloat(style.top as string) + PADDING_SIZE}px`,
        }}
      >
        <Container>
          <Measure bounds margin onResize={(resizeData) => handleItemResize(index, resizeData)}>
            {({ measureRef }) => {
              if (!isChatMessageLoaded(index)) {
                return (
                  <Loader ref={measureRef}>
                    <CircularProgress />
                  </Loader>
                );
              } else {
                return (
                  <Message ref={measureRef} key={index} data-e2e={`chat-message-${index}`}>
                    {(chatMessages.length === index + 1 || !isSameDate) && (
                      <DateArea>
                        <DateElement>
                          {isToday(new Date(createdAt))
                            ? '今日'
                            : format(ConvertLocalToUTCDate(new Date(createdAt)), 'yyyy年MM月dd日')}
                        </DateElement>
                      </DateArea>
                    )}
                    {!self && isNewLine(index) && (
                      <NewLine>
                        <NewHr />
                        <NewLabel>New</NewLabel>
                      </NewLine>
                    )}
                    <MessageContents ref={isNewLine(index) ? newLineRef : null}>
                      <ContributorIcon user={user} />
                      <Balloon
                        openEdit={editingMessage.has(id)}
                        message={item}
                        onEditing={onEditing}
                        onEditingMessage={onEditingMessage}
                        confirmEditing={confirmEditing}
                        updatePermission={
                          self && permissionCheck(FunctionType.Chat, PermissionType.Update)
                        }
                        deletePermission={
                          self && permissionCheck(FunctionType.Chat, PermissionType.Delete)
                        }
                        e2e={`chat-ballon-${index}`}
                      />
                    </MessageContents>
                  </Message>
                );
              }
            }}
          </Measure>
        </Container>
      </Wrapper>
    );
  },
  areEqual,
);

// HACK: transformについて
// メッセージリストを反転しているため、アイテムまで反転してしまう。
// それを元に戻すために入ってます。
const Wrapper = styled.div`
  transform: scaleY(-1);
`;

const Container = styled.div`
  padding-top: 1rem;
  box-sizing: border-box;
`;

const Loader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.section`
  padding: 0 2rem;
  box-sizing: border-box;
  word-break: break-word;
  margin-bottom: 0.75rem;

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;

const MessageContents = styled.li`
  display: flex;
`;

const DateArea = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
  &:before,
  &:after {
    content: '';
    flex-grow: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  &:before {
    margin-right: 10px;
    padding-left: 15px;
  }
  &:after {
    margin-left: 10px;
    padding-right: 15px;
  }
`;

const DateElement = styled.span`
  height: 20px;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: rgba(253, 60, 47, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 3px 14px;
`;

const NewLine = styled.div`
  display: flex;
  height: 0;
  pointer-events: none;
  margin-bottom: 5px;
`;

const NewHr = styled.hr`
  background-image: linear-gradient(
    to right,
    rgba(var(--sk_raspberry_red, 224, 30, 90), 1) 0,
    rgba(var(--sk_raspberry_red, 224, 30, 90), 1) 100%
  );
  background-repeat: repeat-x;
  background-size: 10px 1px;
  border-top: 0;
  flex: 1;
  height: 1px;
  margin: 0 0 -1px;
  position: relative;
  z-index: 1;
`;

const NewLabel = styled.span`
  color: #de4e2b;
  cursor: default;
  font-size: 12px;
  font-size: 13px;
  font-weight: 400;
  font-weight: 700;
  height: 19px;
  line-height: 1.50001;
  margin: -10px 15px 0 0;
  padding: 0 4px;
  position: relative;
  z-index: 1;
`;
