import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Markdown } from '../../common/Markdown';

interface RichInputPreviewModalProps {
  content: string;
}

export const RichInputPreviewModal: React.FC<RichInputPreviewModalProps> = (props) => {
  return (
    <Container>
      <Content>
        <Markdown content={props.content} />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 768px;
  ${media.lessThan('medium')`
    width: 100vw;
  `}
  background-color: #ffffff;
  text-align: center;
  box-sizing: border-box;
  padding: 1.6rem;
`;

const Content = styled.div`
  position: relative;
  background-color: #fafafa;
  flex-grow: 0.6;
  ${media.lessThan('medium')`
    margin: 0 0.8rem;
    flex-grow: 0.8;
  `}
  padding: 1rem;
  box-sizing: border-box;
  line-height: 1.3;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;
