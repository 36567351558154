import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Intercom } from '../../../organisms/Intercom';

import LogoImage from '../../../../static/image/lp/logo.svg';
import LogoImageSmall from '../../../../static/image/logo.svg';

import { LoginModal } from '../../LoginModal';
import { scrollToID } from '../../../../utils/ui/Anchor';
import { useUser } from '../../../../redux/user/useUser';
import { Z_INDEX_APPBAR } from '../../../../const/ZIndex';

interface Props {
  onClickCV: () => void;
}

export const Header: React.FC<Props> = (props) => {
  const [navOpen, setNavOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const { user } = useUser();

  const teamLink = React.useMemo(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      return 'https://lp.sejuku.net/terakoya/biz/?cid=ss_header_to_terakoyabiz';
    } else {
      return `${process.env.REACT_APP_DOMAIN}/team/register/creation`;
    }
  }, []);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const openModal = () => {
    setLoginModalOpen(true);
  };

  const closeModal = () => {
    setLoginModalOpen(false);
  };

  const clickRegisterLink = () => {
    closeModal();
    scrollToID('plan');
  };

  useEffect(() => {
    if (user.loginError) {
      openModal();
    }
  }, [user.loginError]);

  return (
    <React.Fragment>
      <HeaderSection data-gtm="header">
        <Content>
          <Inner>
            <Switch active={navOpen} onClick={toggleNav}>
              <span></span>
            </Switch>
            <Logo src={LogoImage} alt="株式会社SAMURAI" />
            <SpLogo src={LogoImageSmall} alt="株式会社SAMURAI" />
            <Links active={navOpen}>
              <ContactLink href={teamLink}>法人のお客様</ContactLink>
              <LoginLink as="div" onClick={openModal}>
                ログイン
              </LoginLink>
              <AnchorLink as="div" onClick={props.onClickCV}>
                無料会員登録
              </AnchorLink>
            </Links>
          </Inner>
        </Content>

        <Nav>
          <a href="#feature">サービスの特徴</a>
          <a href="#flow">学習の流れ</a>
          <a href="#plan">料金</a>
          <a href="#voice">利用者の声</a>
          <a href="#faq">よくある質問</a>
        </Nav>
        <SpNav active={navOpen}>
          <LinkList>
            <LinkItem>
              <a href="#feature" onClick={() => setNavOpen(false)}>
                サービスの特徴
              </a>
            </LinkItem>
            <LinkItem>
              <a href="#flow" onClick={() => setNavOpen(false)}>
                学習の流れ
              </a>
            </LinkItem>
            <LinkItem>
              <a href="#plan" onClick={() => setNavOpen(false)}>
                料金
              </a>
            </LinkItem>
            <LinkItem>
              <a href="#voice" onClick={() => setNavOpen(false)}>
                利用者の声
              </a>
            </LinkItem>
            <LinkItem>
              <a href="#faq" onClick={() => setNavOpen(false)}>
                よくある質問
              </a>
            </LinkItem>
          </LinkList>
          <ButtonArea>
            <AnchorButton onClick={props.onClickCV}>無料で侍テラコヤを始める</AnchorButton>
            <LoginButton onClick={openModal}>ログイン</LoginButton>
            <ContactButton href={teamLink}>法人のお客様</ContactButton>
          </ButtonArea>
        </SpNav>
      </HeaderSection>
      <Intercom />
      <LoginModal
        isOpen={loginModalOpen}
        onClose={closeModal}
        clickRegisterLink={clickRegisterLink}
      />
    </React.Fragment>
  );
};

const HeaderSection = styled.header`
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: -75px;
  z-index: ${Z_INDEX_APPBAR};

  ${media.lessThan('medium')`
    width: 100%;
    position: fixed;
    top: 0;
  `}
`;
const Content = styled.div`
  padding: 1rem 0 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    padding: 0.75rem 0;
    border-bottom: none;
  `}
`;
const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;

  ${media.lessThan('large')`
    padding: 0 2rem;
  `}
  ${media.lessThan('medium')`
    flex-direction: row-reverse;
    padding: 0 1rem;
    position: relative;
  `}
`;
const Switch = styled.div<{ active: boolean }>`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    position: relative;

    span {
      display: block;
      width: 1.125rem;
      height: 0.125rem;
      margin: auto;
      background: #000;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all .2s;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: #000;
        position: absolute;
        left: 0;
        transition: all .2s;
      }

      &:before {
        top: -0.3125rem;
      }

      &:after {
        bottom: -0.3125rem;
      }
    }
  `}

  ${(props) =>
    props.active
      ? media.lessThan('medium')`
    span {
      background: rgba(0,0,0,0);

      &:before {
        top: 0;
        transform: rotate(45deg);
      }

      &:after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  `
      : ''}

  ${media.lessThan('small')`
    margin-left: 0.5rem;
  `}
`;
const Logo = styled.img`
  max-height: 24px;

  ${media.lessThan('large')`
    width: 30%;
  `}
  ${media.lessThan('medium')`
    display: none;
    width: 165px;
    max-height: 2rem;
    max-width: 15rem;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1rem;
  `}
  ${media.lessThan('small')`
    width: 7.5rem;
  `}
`;
const SpLogo = styled(Logo)`
  display: none;

  ${media.lessThan('medium')`
    display: block;
  `}
`;
const Links = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex: 1;

  ${media.lessThan('large')`
    margin-left: 2rem;
  `}

  opacity: ${(props) => (props.active ? '0' : '1')};
  pointer-events: ${(props) => (props.active ? 'none' : 'all')};
`;
const StyledLink = styled.a`
  display: block;
  width: 100%;
  max-width: 160px;
  padding: 0.875rem;
  border: 1px solid #e2001b;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  color: #e2001b;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  text-align: center;

  ${media.lessThan('large')`
    flex: 1;
  `}
`;
const ContactLink = styled(StyledLink)`
  ${media.lessThan('medium')`
    display: none;
  `}
`;
const LoginLink = styled(StyledLink)`
  margin-left: 1.5rem;

  &:after {
    content: 'する';
  }

  ${media.lessThan('large')`
    margin-left: 0.75rem;
  `}
  ${media.lessThan('medium')`
    flex: none;
    width: auto;
    padding: 0.5rem;
    border: none;
    font-size: 0.75rem;
    line-height: 1rem;

    &:after {
      content: none;
    }
  `}
`;
const AnchorLink = styled(StyledLink)`
  max-width: 172px;
  margin-left: 1.5rem;
  background: #e2001b;
  color: #fff;
  cursor: pointer;

  ${media.lessThan('large')`
    margin-left: 0.75rem;
  `}
  ${media.lessThan('medium')`
    display: none;
  `}
`;
const Nav = styled.nav`
  display: flex;
  justify-content: center;
  padding: 1rem 0 1.1875rem;

  a {
    display: block;
    font-size: 0.875rem;
    font-weight: 700;

    + a {
      margin-left: 5rem;
    }
  }

  ${media.lessThan('large')`
    a {
      + a {
        margin-left: 2rem;
      }
    }
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;

const SpNav = styled.nav<{ active: boolean }>`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    width: 100%;
    height: 0;
    padding: 2.75rem 2rem 2.5rem;
    background: rgba(255,255,255,0.96);
    box-sizing: border-box;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    transition: all .2s;
    opacity: 0;
    pointer-events: none;
    overflow-y: scroll;
  `}

  ${(props) =>
    props.active
      ? media.lessThan('medium')`
    height: calc(100vh - 57px);
    opacity: 1;
    pointer-events: all;
  `
      : ''}
`;
const LinkItem = styled.li`
  a {
    font-size: 1rem;
    font-weight: 700;
  }
`;
const LinkList = styled.ul`
  text-align: center;

  ${LinkItem} + ${LinkItem} {
    margin-top: 2.75rem;
  }
`;
const ButtonArea = styled.div`
  margin-top: 2.75rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
const Button = styled.div`
  display: block;
  padding: 0.75rem 0;
  border: 1px solid #e2001b;
  border-radius: 0.25rem;
  cursor: pointer;
  color: #e2001b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;
`;
const AnchorButton = styled(Button)`
  background: #e2001b;
  color: #fff;
`;
const LoginButton = styled(Button)`
  margin-top: 1.5rem;
`;
const ContactButton = styled.a`
  display: block;
  margin-top: 1.5rem;
  padding: 0.75rem 0;
  border: 1px solid #e2001b;
  border-radius: 0.25rem;
  color: #e2001b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;
`;
