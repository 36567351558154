import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { PlanType } from '../../../const/Prices';

interface Props {
  currentPlan: PlanType;
  setCurrentPlan: (val: PlanType) => void;
}

export const PlanTab: React.FC<Props> = (props) => {
  return (
    <Tabs>
      <TabItem
        active={props.currentPlan === 'PREMIUM'}
        onClick={() => props.setCurrentPlan('PREMIUM')}
      >
        <p>プレミアム</p>
      </TabItem>
      <TabItem active={props.currentPlan === 'BASIC'} onClick={() => props.setCurrentPlan('BASIC')}>
        <Recommend>おすすめ</Recommend>
        <p>ベーシック</p>
      </TabItem>
      <TabItem active={props.currentPlan === 'LIGHT'} onClick={() => props.setCurrentPlan('LIGHT')}>
        <p>ライト</p>
      </TabItem>
    </Tabs>
  );
};

const Tabs = styled.ul`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 0.625rem;
  padding: 1.25rem 2rem 0;
  background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 14.58%,
      rgba(255, 255, 255, 0) 82.29%,
      #ffffff 100%
    ),
    linear-gradient(180deg, #ffffff 0%, #ffffff 89.58%, #efefef 100%);
  border-bottom: 1px solid #b6b6b6;

  ${media.lessThan('medium')`
    gap: .375rem;
    margin: 0 -1rem;
    padding: 1.25rem .625rem 0;
  `}
`;
const TabItem = styled.li<{ active: boolean }>`
  width: 15rem;
  border-radius: 0.375rem 0.375rem 0 0;
  cursor: pointer;
  position: relative;

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.125rem;
    font-weight: ${(props) => (props.active ? '700' : '400')};
    line-height: 1.75rem;
    text-align: center;

    &:after {
      content: 'プラン';
    }
  }

  ${(props) =>
    props.active
      ? `
    margin-bottom: -1px;
    padding: 1.625rem 0 1rem;
    background: #fff;
    border: 1px solid #b6b6b6;
    border-bottom-color: #fff;
  `
      : `
    padding: 1rem 0;
    background: linear-gradient(180deg, #F6F6F6 0%, #F6F6F6 81.25%, #EDEDED 100%);
  `}

  ${media.lessThan('medium')`
    width: 6.25rem;

    p {
      font-size: .875rem;
      line-height: 1.25rem;

      &:after {
        content: none;
      }
    }
  `}

  ${(props) =>
    props.active
      ? media.lessThan('medium')`
    padding: 1rem 0 .75rem;
  `
      : media.lessThan('medium')`
    padding: .75rem 0;
  `}
`;
const Recommend = styled.span`
  padding: 0.25rem 0.75rem;
  background: #eb0000;
  border-radius: 2px 2px 2px 0;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  position: absolute;
  left: -0.5rem;
  bottom: 3.5rem;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.125rem 0.25rem;
    border-color: #be000a #be000a transparent transparent;
    position: absolute;
    top: 100%;
    left: 0;
  }

  ${media.lessThan('medium')`
    padding: .25rem .625rem;
    font-size: .75rem;
    line-height: 1rem;
    left: -.25rem;
    bottom: 2.25rem;

    &:after {
      border-width: .125rem .125rem;
    }
  `}
`;
