import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Auth } from 'aws-amplify';
import { isSome, isNone, none, Option } from 'fp-ts/Option';

import { useUser } from '../../../redux/user/useUser';
import { Input } from '../../../components/atoms/Input';
import { LoginModal } from '../../../components/organisms/LoginModal';

import { Layout } from './Layout';
import * as Sentry from '@sentry/react';
import {
  UserEmailValidation,
  UserPasswordValidation,
  EmailError,
  PasswordError,
} from '../../../utils/FormValidation';
import { useSafeAsyncCallback } from '../../customHooks/SafeAsyncCallback';
import { TranslateCognitoError } from '../../../utils/CognitoErrorDictionary';
import { useNavigate } from 'react-router-dom';

export const ChangePasswordVerification = (): JSX.Element => {
  const navigate = useNavigate();
  const { user, login } = useUser();

  const [error, setError] = useState<string>('');

  const [errorEmail, setErrorEmail] = useState<Option<EmailError>>(none);
  const [errorPassword, setErrorPassword] = useState<Option<PasswordError>>(none);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [loginModalOpen, setLoginModalOpen] = useState<boolean>(false);

  const buttonRef = useRef(document.createElement('button'));

  const inputEmail = (value: string) => {
    setEmail(value);
    setErrorEmail(UserEmailValidation(value));
  };
  const inputPassword = (value: string) => {
    setPassword(value);
    setErrorPassword(UserPasswordValidation(value));
  };
  const inputCode = (value: string) => {
    setCode(value);
  };

  const openModal = () => {
    setLoginModalOpen(true);
  };

  const closeModal = () => {
    setLoginModalOpen(false);
  };

  const sendLogin = useSafeAsyncCallback(async (): Promise<void> => {
    await login(email.trim(), password.trim())
      .then((user) => {
        // NOTE: パスワードの更新が必要な場合でもエラーにはしていないが、
        // storeに保存されるlmsUserはnullになる実装になっている
        if (user === null) {
          navigate('/changeTemporaryPassword');
          return;
        }

        navigate('/home');
      })
      .catch((error) => {
        if (error == 'ユーザーとして登録されていません。') {
          setError('ユーザーとして登録されていません');
        } else {
          setError(TranslateCognitoError(error.message));
        }
      });
  });

  const send = useSafeAsyncCallback(async (): Promise<void> => {
    if (isSome(errorEmail) || isSome(errorPassword) || !code.trim()) return;
    await Auth.forgotPasswordSubmit(email.trim(), code.trim(), password.trim())
      .then(() => sendLogin())
      .catch((err) => {
        setError(TranslateCognitoError(err.message));
        Sentry.captureException(error);
      });
  });

  const checkAuthState = () => {
    if (!user.isChangePassword) {
      // 正常な画面遷移でない場合はloginに戻す
      navigate('/');
    }
  };

  useEffect(() => {
    checkAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (email && isNone(errorEmail) && password && isNone(errorPassword) && code.trim()) {
      buttonRef.current.classList.add('active');
    } else {
      buttonRef.current.classList.remove('active');
    }
  }, [email, errorPassword, password, errorEmail, code]);

  return (
    <>
      <Layout title="パスワード変更">
        <Container>
          <h4>パスワード変更</h4>
          <p className="input">
            <StyledInput
              name="email"
              type="email"
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => inputEmail(e.target.value)}
              placeholder="メールアドレス"
              error={isSome(errorEmail)}
            />
            <p className="err">{isSome(errorEmail) && errorEmail.value.message}</p>
          </p>
          <p className="input">
            <StyledInput
              name="code"
              type="text"
              value={code}
              onChange={(e: ChangeEvent<HTMLInputElement>) => inputCode(e.target.value)}
              placeholder="確認用コード"
            />
          </p>
          <p className="input">
            <StyledInput
              name="password"
              type="password"
              value={password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => inputPassword(e.target.value)}
              placeholder="パスワード"
              error={isSome(errorPassword)}
            />
            <p className="err">{isSome(errorPassword) && errorPassword.value.message}</p>
          </p>
          <p className="err">{error}</p>
          <button ref={buttonRef} onClick={send}>
            設定
          </button>
          <Suggestion>
            ログイン画面は
            <span onClick={openModal}>こちら</span>
          </Suggestion>
        </Container>
        <style jsx>{`
          .container {
            text-align: center;
          }

          h4 {
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 2rem;
          }

          .input {
            margin-bottom: 1rem;
          }

          .err {
            color: #f00;
            margin: 0.25rem 0;
            max-width: 300px;
            font-size: 0.85rem;
          }

          button {
            margin: 1rem 0;
            cursor: pointer;
            padding: 0.75rem 0;
            width: 300px;
            background: rgba(0, 0, 0, 0.36);
            color: #fff;
            font-size: 1rem;
            border: none;
            border-radius: 0;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            cursor: default;
          }

          button.active {
            background-color: #e2001b;
            cursor: pointer;
          }
        `}</style>
      </Layout>
      <LoginModal isOpen={loginModalOpen} onClose={closeModal} />
    </>
  );
};

const Container = styled.div`
  ${media.lessThan('medium')`
    display: inline-block;
    margin-top: 50px;
    background: #fff;
    padding: 30px 20px;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.16);
  `}
  ${media.lessThan('small')`
    display: block;
  `}
  ${media.greaterThan('medium')`
    text-align: center;
  `}
`;

const StyledInput = styled(Input)`
  width: 300px;
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
  border: solid 1px rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  background: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  input {
    margin: 0;
  }

  ${media.lessThan('small')`
    width: 100%;
  `}
`;

const Suggestion = styled.p`
  margin-top: 0.4rem;
  font-size: 1rem;

  span {
    cursor: pointer;
    color: #fd6258;
    font-size: 1rem;
    text-decoration: underline;
    font-weight: bold;
  }
`;
