import React, { SyntheticEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  isVisible: boolean;
  onDelete: () => void;
}

export const TimelineControl: React.FC<Props> = ({ isVisible, onDelete }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickControlIcon = (e: SyntheticEvent): void => {
    // 利用側のタイムラインカード全体がリンクになっているので遷移をキャンセルさせる
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const handleClickDeleteButton = useCallback(
    (e: SyntheticEvent): void => {
      // 利用側のタイムラインカード全体がリンクになっているので遷移をキャンセルさせる
      e.preventDefault();
      onDelete();
    },
    [onDelete],
  );

  return (
    <>
      <ControlIcon isVisible={isVisible} onClick={handleClickControlIcon}>
        <span />
      </ControlIcon>
      <Control isOpen={isOpen} onMouseLeave={() => setIsOpen(false)}>
        <li>
          <Delete>
            <ControlButton onClick={handleClickDeleteButton}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.3861 5.26566C10.4281 5.22362 10.4851 5.2 10.5446 5.2H13.8413C13.9008 5.2 13.9578 5.22362 13.9998 5.26566C14.0419 5.30771 14.0655 5.36473 14.0655 5.42419V7.29629H10.3204V5.42419C10.3204 5.36473 10.344 5.30771 10.3861 5.26566ZM9.69685 8.49629C9.70466 8.49659 9.71251 8.49674 9.72039 8.49674C9.72827 8.49674 9.73612 8.49659 9.74393 8.49629H14.642C14.6498 8.49659 14.6576 8.49674 14.6655 8.49674C14.6734 8.49674 14.6812 8.49659 14.689 8.49629H17.31L16.54 17.7367C16.5386 17.7533 16.5379 17.7699 16.5379 17.7865C16.5379 18.0646 16.4275 18.3312 16.2309 18.5278C16.0342 18.7244 15.7676 18.8349 15.4895 18.8349H8.89606C8.61802 18.8349 8.35136 18.7244 8.15476 18.5278C7.95815 18.3312 7.84769 18.0646 7.84769 17.7865C7.84769 17.7699 7.847 17.7533 7.84562 17.7367L7.07559 8.49629H9.69685ZM9.12039 7.29629V5.42419C9.12039 5.04647 9.27044 4.68422 9.53752 4.41713C9.80461 4.15005 10.1669 4 10.5446 4H13.8413C14.219 4 14.5813 4.15005 14.8484 4.41713C15.1155 4.68422 15.2655 5.04647 15.2655 5.42419V7.29629H17.9551H17.9678H18.787C19.1183 7.29629 19.387 7.56492 19.387 7.89629C19.387 8.22766 19.1183 8.49629 18.787 8.49629H18.5142L17.7378 17.8134C17.7308 18.4 17.4947 18.961 17.0794 19.3763C16.6577 19.798 16.0859 20.0349 15.4895 20.0349H8.89606C8.29976 20.0349 7.72788 19.798 7.30623 19.3763C6.89092 18.961 6.65486 18.4 6.64785 17.8134L5.87143 8.49629H5.6C5.26863 8.49629 5 8.22766 5 7.89629C5 7.56492 5.26863 7.29629 5.6 7.29629H6.41786H6.4305H9.12039ZM10.5448 10.5933C10.8762 10.5933 11.1448 10.8619 11.1448 11.1933V16.1384C11.1448 16.4698 10.8762 16.7384 10.5448 16.7384C10.2135 16.7384 9.94485 16.4698 9.94485 16.1384V11.1933C9.94485 10.8619 10.2135 10.5933 10.5448 10.5933ZM14.4417 11.1933C14.4417 10.8619 14.1731 10.5933 13.8417 10.5933C13.5104 10.5933 13.2417 10.8619 13.2417 11.1933V16.1384C13.2417 16.4698 13.5104 16.7384 13.8417 16.7384C14.1731 16.7384 14.4417 16.4698 14.4417 16.1384V11.1933Z"
                  fill="#e2001b"
                />
              </svg>
              削除する
            </ControlButton>
          </Delete>
        </li>
      </Control>
    </>
  );
};

const ControlIcon = styled.div<{ isVisible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: inherit;
  border-radius: 50%;
  cursor: pointer;
  /* HACK: 配置をどうするかは呼び出し側で指定できるようにしたい */
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
  transition: all 0.2s;

  &:hover {
    background: #f5f5f5;
  }

  span {
    display: block;
    width: 4px;
    height: 4px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      position: absolute;
      top: 0;
    }

    &:before {
      left: -7px;
    }

    &:after {
      right: -7px;
    }
  }

  ${media.lessThan('medium')`
    opacity: 1;
    pointer-events: all;
  `}
`;

const Control = styled.ul<{ isOpen: boolean }>`
  padding: 0.5rem 0;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* HACK: 配置をどうするかは呼び出し側で指定できるようにしたい */
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  transition: all 0.2s;
`;

const Delete = styled.div`
  color: #e2001b;
`;

const ControlButton = styled.p`
  display: flex;
  align-items: center;
  width: 10rem;
  padding: 0.625rem 1rem;
  cursor: pointer;
  font-size: 1rem;

  svg {
    margin-right: 1rem;
  }
`;
