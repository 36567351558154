import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import media from 'styled-media-query';
import {
  addHours,
  isAfter,
  isBefore,
  isEqual,
  set as setDate,
  startOfHour,
  startOfDay,
} from 'date-fns';

import { ModelError } from '../../swagger/gen/ts_front';
import { Modal } from '../molecules/Modal';
import { Select } from '../atoms/Select';
import { Button } from '../atoms/Button';
import { isSome, none, some } from 'fp-ts/lib/Option';
import { AVAILABLE_START_HOURS, BUSINESS_HOURS } from '../../const/SpotLesson';
import { jstToUtc } from '../../utils/DateFnsSupport';

interface InstructorScheduleAddModalProps {
  isOpen: boolean;
  loading?: boolean;
  toggle: (nextState: boolean) => void;
  onInstructorScheduleCreate: (startAt: string, endAt: string) => Promise<void>;
  isLoadingInstructorSchedule: boolean;
}

export interface InstructorScheduleAddModalHandler {
  setDefaultValue: (startAt: Date, endAt: Date) => void;
}

export const InstructorScheduleAddModal = React.forwardRef<
  InstructorScheduleAddModalHandler,
  InstructorScheduleAddModalProps
>((props, ref) => {
  const [startAt, setStartAt] = React.useState(addHours(startOfHour(startOfDay(new Date())), 10));
  const [endAt, setEndAt] = React.useState(addHours(startOfHour(startOfDay(new Date())), 11));
  const [error, setError] = React.useState<string[]>([]);

  const changeDate = (newDate: Date | null) => {
    if (!newDate) {
      return;
    }
    setStartAt((prevDate) => {
      return setDate(prevDate, {
        year: newDate.getFullYear(),
        month: newDate.getMonth(),
        date: newDate.getDate(),
        minutes: 0,
      });
    });

    setEndAt((prevDate) => {
      return setDate(prevDate, {
        year: newDate.getFullYear(),
        month: newDate.getMonth(),
        date: newDate.getDate(),
        minutes: 0,
      });
    });
  };

  const changeStartAt = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.persist();

      const newDate = setDate(startAt, {
        hours: parseInt(e.target.value),
      });

      setStartAt((prevDate) => {
        return setDate(prevDate, {
          hours: parseInt(e.target.value),
          minutes: 0,
        });
      });

      if (isBefore(endAt, newDate)) {
        setEndAt(() => addHours(newDate, 1));
      }
    },
    [startAt, endAt],
  );

  const changeEndAt = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.persist();

      setEndAt((prevDate) => {
        return setDate(prevDate, {
          hours: parseInt(e.target.value),
          minutes: 0,
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startAt, endAt],
  );

  const clear = React.useCallback(() => {
    setStartAt(addHours(startOfHour(startOfDay(new Date())), 10));
    setEndAt(addHours(startOfHour(startOfDay(new Date())), 11));
    setError([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAt, endAt]);

  const validate = React.useCallback(() => {
    if (isEqual(startAt, endAt) || isAfter(startAt, endAt)) {
      return some(new Error('開始時刻には終了時刻より前の値を入力してください'));
    }

    return none;
  }, [startAt, endAt]);

  const send = React.useCallback(async () => {
    if (props.isLoadingInstructorSchedule) return;
    setError([]);

    const errors = validate();
    if (isSome(errors)) {
      setError((p) => [...p, errors.value.message]);
      return;
    }

    props
      .onInstructorScheduleCreate(jstToUtc(startAt).toISOString(), jstToUtc(endAt).toISOString())
      .catch((err) => {
        setError((p) => [...p, err.response.data.errors.map((e: ModelError) => e.message)]);
      });
  }, [props, validate, startAt, endAt]);

  const setDefaultValue = (s: Date, e: Date) => {
    setStartAt(s);
    setEndAt(e);
  };

  React.useImperativeHandle(ref, () => {
    return {
      setDefaultValue,
    };
  });

  React.useEffect(() => {
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={() => {
        props.toggle(false);
        clear();
      }}
      loading={props.loading}
      header={'シフトを登録'}
      footer={
        <Buttons>
          <CancelButton
            onClick={() => {
              clear();
              props.toggle(false);
            }}
          >
            キャンセル
          </CancelButton>
          <Button onClick={send} disabled={props.isLoadingInstructorSchedule}>
            保存する
          </Button>
        </Buttons>
      }
      width={'550px'}
    >
      <Contents>
        <Form>
          <DatePicker
            selected={startAt}
            onChange={changeDate}
            minDate={new Date()}
            dateFormat="yyyy年MM月dd日"
            disabledKeyboardNavigation
            placeholderText="日程を選択"
            className="input datepicker-date"
          />
          <HoursContainer>
            <h4>時間帯</h4>
            <Hours>
              <Select
                name="startAt"
                options={AVAILABLE_START_HOURS}
                value={startAt.getHours().toString()}
                onChange={changeStartAt}
              />
              <p>～</p>
              <Select
                name="endAt"
                options={BUSINESS_HOURS}
                value={endAt.getHours().toString()}
                onChange={changeEndAt}
              />
            </Hours>
          </HoursContainer>
        </Form>
        <ErrorText>
          {error.map((e) => (
            <p>{e}</p>
          ))}
        </ErrorText>
      </Contents>
    </Modal>
  );
});

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;
const CancelButton = styled.p`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const Contents = styled.div`
  padding: 24px 24px 200px 24px;
`;

const Form = styled.div`
  display: flex;
  align-items: flex-end;

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;
  `}
`;

const HoursContainer = styled.div`
  margin-left: 40px;

  h4 {
    font-size: 0.9rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
  }

  ${media.lessThan('medium')`
    margin-left: 0;
    margin-top: 16px;
  `}
`;

const Hours = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  & > * + * {
    margin-left: 15px;
  }
`;

const ErrorText = styled.div`
  p {
    color: #f00;
    font-weight: bold;
    font-size: 0.9rem;
  }
`;
