import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { Link } from 'react-router-dom';
import { ReactNode } from 'react';

export interface WalkThroughModalSlideProps {
  catchImage?: string;
  title?: ReactNode;
  smallText?: string;
  leadLinkedButtonHref?: string;
  leadLinkedButtonText?: string;
  leadButtonTo?: string;
  leadButtonText?: string;
  children?: ReactNode;
}

export const WalkThroughModalSlide: React.FC<WalkThroughModalSlideProps> = ({
  catchImage,
  title,
  smallText,
  leadLinkedButtonHref,
  leadLinkedButtonText,
  leadButtonTo,
  leadButtonText,
  children,
}) => {
  return (
    <Slide>
      {catchImage && <Catch src={catchImage} />}
      {title && <Title>{title}</Title>}
      {children && <Text>{children}</Text>}
      {smallText && <SmallText>{smallText}</SmallText>}
      {leadLinkedButtonHref && (
        <LeadBlankButton href={leadLinkedButtonHref} target="_blank" rel="noopener noreferrer">
          {leadLinkedButtonText}
        </LeadBlankButton>
      )}
      {leadButtonTo && (
        <LeadButton to={leadButtonTo} target="_blank" rel="noopener noreferrer">
          {leadButtonText}
        </LeadButton>
      )}
    </Slide>
  );
};

const Slide = styled.div`
  position: relative;
  outline: none;

  ${media.lessThan('small')`
    padding-bottom: 2rem;
  `}
`;
const Catch = styled.img`
  display: block;
  width: 100%;
`;
const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  padding: 0 2rem;
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  .sp {
    display: none;
  }

  ${media.lessThan('small')`
    margin: 1.75rem auto 0;

    .sp {
      display: block;
    }
  `}
`;
const Text = styled.p`
  margin: 1.125rem auto 0;
  padding: 0 2rem;
  color: #000;
  font-size: 1rem;
  letter-spacing: 0.003em;
  line-height: 1.8em;
  text-align: center;

  ${media.lessThan('small')`
    margin-top: 1.5rem;
    font-size: .875rem;
    letter-spacing: .042px;
  `}
`;
const SmallText = styled.p`
  margin-top: 0.5rem;
  padding: 0 2rem;
  font-size: 0.75rem;
  text-align: center;

  ${media.lessThan('small')`
    margin-top: 1.5rem;
  `}
`;
const sharedButtonStyle = css`
  display: block;
  width: 320px;
  margin: 1rem auto 0;
  padding: 0.5rem 0;
  border-radius: 4px;
  background: var(--gradation-theme, linear-gradient(132deg, #fd8258 0%, #fd3c2f 100%));
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 1.098px;
  text-align: center;

  ${media.lessThan('small')`
    max-width: calc(100% - 4rem);
    font-size: .875rem;
    letter-spacing: .854px;
  `}
`;
const LeadButton = styled(Link)`
  ${sharedButtonStyle}
`;
const LeadBlankButton = styled.a`
  ${sharedButtonStyle}
`;
