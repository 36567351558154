import React, { useEffect } from 'react';
import { useUser } from '../../../redux/user/useUser';
import { useNavigate } from 'react-router-dom';

export const LoginOauth: React.FC = () => {
  const {
    callbackOauthLogin,
    user: { socialLoginCallbackURL },
  } = useUser();
  const navigate = useNavigate();

  const transitionScreen = () => {
    if (socialLoginCallbackURL) {
      navigate(socialLoginCallbackURL);
      return;
    }

    navigate('/home');
  };

  useEffect(() => {
    const f = async () => {
      await callbackOauthLogin();
      transitionScreen();
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
