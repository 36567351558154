import React from 'react';
import styled from 'styled-components';

interface ListItemProps {
  id: number;
  label: string;
  count: number;
  selected: boolean;
  onClick: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const ListItem: React.FC<ListItemProps> = (props): JSX.Element => {
  return (
    <Item onClick={props.onClick} className={props.selected ? 'selected' : ''}>
      <Label>{props.label}</Label>
      <CountLabel>{`${props.count}件`}</CountLabel>
    </Item>
  );
};

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0.75rem;
  line-height: 130%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &.selected {
    background: #fce5e8;
    color: #e2001b;
  }
`;

const Label = styled.p`
  font-size: 0.75rem;
`;

const CountLabel = styled(Label)`
  margin-left: 0.5rem;
  white-space: nowrap;
`;
