import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Notification as NotificationContent } from '../../organisms/Notification';
import { LOWER_META_TITLE } from '../../../const/Service';
import { NotificationLayout } from '../../templates/NotificationLayout';
import { useNotificationUnreadCount } from '../../../common/customHooks/NotificationUnreadCount';

export const NotificationIndex: React.FC = () => {
  const metaTitle = `お知らせ | ${LOWER_META_TITLE}`;

  const { notificationUnreadCount, noticeUnreadCount, refetch } = useNotificationUnreadCount();

  return (
    <NotificationLayout
      activeTab="notification"
      notificationUnreadCount={notificationUnreadCount}
      noticeUnreadCount={noticeUnreadCount}
      metaTitle={metaTitle}
    >
      <Container>
        <NotificationContent refetchNotificationUnreadCount={refetch} />
      </Container>
    </NotificationLayout>
  );
};

const Container = styled.div`
  max-width: 1120px;
  padding: 24px;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    max-width: 100%;
    padding: 0;
  `}
`;
