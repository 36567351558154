import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import media from 'styled-media-query';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SERVICE_NAME } from '../../const/Service';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';

import Catch001 from '../../static/image/walkThrough001.png';
import Catch002 from '../../static/image/walkThrough002.png';
import Catch003 from '../../static/image/walkThrough003.png';
import Catch004 from '../../static/image/walkThrough004.png';
import Catch005 from '../../static/image/walkThrough005.png';
import Catch006 from '../../static/image/walkThrough006.png';
import { Z_INDEX_MODAL } from '../../const/ZIndex';
import { PROGRAM_COUNT } from '../../const/Program';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { WalkThroughModalSlide, WalkThroughModalSlideProps } from './WalkThroughModalSlide';
import { WalkThroughModalStartContent } from './WalkThroughModalStartContent';

interface ModalProps {
  isOpen: boolean;
  loading?: boolean;
  toggle: (nextState: boolean) => void;
}

export const WalkThrough: React.FC<ModalProps> = (props) => {
  const { slides, settings, displayCloseButton, clearModal } = useData();

  return (
    <>
      {props.isOpen && <GlobalStyle />}
      <Modal
        isOpen={props.isOpen}
        onClose={() => void 0}
        loading={props.loading}
        width={'500px'}
        tbWidth={'500px'}
        spFullScreen
      >
        {clearModal ? (
          <WalkThroughModalStartContent toggle={props.toggle} />
        ) : (
          <>
            <StyledSlider {...settings}>
              {slides.map((slide, index) => {
                return (
                  <WalkThroughModalSlide
                    key={index}
                    catchImage={slide.catchImage}
                    title={slide.title}
                    children={slide.children}
                    smallText={slide.smallText}
                    leadLinkedButtonHref={slide.leadLinkedButtonHref}
                    leadLinkedButtonText={slide.leadLinkedButtonText}
                    leadButtonTo={slide.leadButtonTo}
                    leadButtonText={slide.leadButtonText}
                  />
                );
              })}
            </StyledSlider>
            {displayCloseButton && (
              <CloseButton onClick={() => props.toggle(false)}>学習を始める</CloseButton>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

const useData = () => {
  const { permissionCheck } = useUser();
  const isTeam = permissionCheck(FunctionType.DocumentForTeam, PermissionType.Read);

  const slides: WalkThroughModalSlideProps[] = [
    {
      catchImage: Catch001,
      title: `${SERVICE_NAME}へようこそ`,
      children: `${SERVICE_NAME}は、ゼロから始められる定額学び放題のプログラミング学習サービスです。`,
    },
    {
      catchImage: Catch002,
      title: `${PROGRAM_COUNT}種類以上の教材と実務レベルのスキルを身につける課題機能でプログラミングスキルを習得`,
      children: `サービス開発をしながら学べるプログラミング学習教材を利用できます。つまづいても質問ができるので、安心して学習を進められます。`,
    },
    {
      catchImage: Catch003,
      title: (
        <>
          いつでもつまづいたときに <br />
          現役エンジニアに
          <br className="sp" />
          相談できる
        </>
      ),
      children: isTeam
        ? '現役エンジニアが回答してくれるQ&A掲示板やオンラインレッスンを利用できます。学習中のエラー相談はもちろん、効率的な学習方法に関する相談もOK。'
        : '現役エンジニアが回答してくれるQ&A掲示板やオンラインレッスンを利用できます。案件獲得の方法や効率的な学習方法、転職やフリーランスとしてのキャリア設計に対する悩み相談もOK。',
    },
    {
      catchImage: Catch004,
      title: '学習を楽しく習慣にできる',
      children: `自分がどれだけ努力したかを可視化する、学習の記録できます。他のユーザーの学習ログも見られるのでモチベーションを高く維持できます。`,
    },
  ];
  if (!isTeam) {
    slides.push({
      catchImage: Catch005,
      title: (
        <>
          フリープランでは機能が <br />
          制限されてしまいます
        </>
      ),
      children: `すべての教材やQ&A掲示板、レッスン機能を使いたい場合はライトプラン以上、課題機能も使って着実にスキルを身につけたい方はベーシックプラン以上にご登録ください。`,
      leadButtonTo: '/account/subscription/lp?cid=post-registration_modal1',
      leadButtonText: '有料プランの詳細はこちら',
    });
    slides.push({
      catchImage: Catch006,
      title: (
        <>
          まずは無料相談を
          <br className="sp" />
          ご予約ください
        </>
      ),
      smallText: '※技術的な相談はお答えいたしかねます。',
      leadLinkedButtonHref:
        'https://docs.google.com/forms/d/e/1FAIpQLSeELqJrRenIEUAzsQfc6q1Sk3eIspM4n0Ax9raJGgyT00wgog/viewform?cid=terakoya_counseling',
      leadLinkedButtonText: '無料サポートのご予約はこちら',
      children: `テラコヤの使い方は？副業やフリーランス、転職を目標にしているけど何から勉強したらいい？というようなご相談を無料で受けております。`,
    });
    slides.push({});
  }

  const [displayCloseButton, setDisplayCloseButton] = useState(false);
  const [clearModal, setClearModal] = useState(false);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    variableWidth: false,
    beforeChange: function (current: number, next: number) {
      if (isTeam) {
        //最後のページには閉じるボタンを表示する。
        setDisplayCloseButton(next === slides.length - 1);
      } else {
        //最後はダミーページ。そこを表示しようとしたら画面を切り替える。
        if (next === slides.length - 1) {
          setClearModal(true);
        }
      }
    },
  };

  return { slides, settings, displayCloseButton, clearModal };
};

const GlobalStyle = createGlobalStyle`
  .intercom-lightweight-app,
  .intercom-lightweight-app-launcher {
    z-index: ${Z_INDEX_MODAL - 1} !important;
  }
`;

const StyledSlider = styled(Slider)`
  padding-bottom: 5.5rem;

  .slick-dots {
    bottom: 2.875rem;
    font-size: 0;

    li {
      width: 0.5rem;
      height: 0.5rem;
      margin: 0 0.25rem;

      button {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);

        &:before {
          content: none;
        }
      }

      &.slick-active button {
        background: #e2001b;
      }
    }
  }

  .slick-prev,
  .slick-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.5rem;
    height: 2.25rem;
    border-radius: 0.25rem;
    top: auto;
    bottom: 2rem;
    z-index: 2;
    transform: none;

    &:before {
      content: none;
    }

    &:after {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
    }

    &.slick-disabled {
      display: none !important;
    }
  }

  .slick-next {
    right: 2rem;
    background: #e2001b;

    &:after {
      content: '次へ';
      color: #fff;
    }
  }

  .slick-prev {
    left: 2rem;

    &:after {
      content: '戻る';
      color: rgba(0, 0, 0, 0.6);
    }
  }

  ${media.lessThan('small')`
    width: 100%;
    height: 100%;
    padding: 0 0 3.75rem;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: rgba(0,0,0,.1);
      position: absolute;
      bottom: 3.75rem;
      left: 0;
    }

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-slide {
      overflow-y: auto;
    }

    .slick-dots {
      bottom: 1.625rem;

      li {
        margin: 0 .375rem;
      }
    }

    .slick-prev,
    .slick-next {
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;
      bottom: .75rem;

      &:after {
        content: '';
        width: .5rem;
        height: .5rem;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
    .slick-prev {
      background: #e2001b;
      left: 1rem;

      &:after {
        margin-left: .875rem;
        transform: rotate(225deg);
      }
    }
    .slick-next {
      right: 1rem;

      &:after {
        margin-left: .75rem;
        transform: rotate(45deg);
      }
    }
  `}
`;
const CloseButton = styled(Button)`
  width: 7.5rem;
  padding: 0.5rem 0;
  border: none;
  line-height: 1.25rem;
  position: absolute;
  right: 2rem;
  bottom: 2rem;

  ${media.lessThan('small')`
    width: auto;
    padding: 0;
    background: none;
    color: #e2001b;
    font-weight: 400;
    right: 1rem;
    bottom: 1.25rem;
  `}
`;
