import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from '../../store';
import { CommonModalActionTypes } from './actions';
import { useUser } from '../user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface ModalInterface {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

interface UseCommonModal {
  // 登録を促すモーダル
  registerFreeUserModal: ModalInterface;
  // ライト契約以上を促すモーダル
  registerLightUserModal: ModalInterface;
  // ベーシック契約以上を促すモーダル
  registerBasicUserModal: ModalInterface;
  // プレミアム契約以上を促すモーダル
  registerPremiumUserModal: ModalInterface;
  // 法人管理者未課金ユーザーに課金を促すモーダル
  registerTeamSubscriptionUserModal: ModalInterface;
  // 法人メンバー未課金ユーザーに管理者へ課金を促すモーダル
  registerTeamSubscriptionToTeamAdminModal: ModalInterface;
  // モーダル表示
  openModal: (functionType: string, permissionType: string) => boolean;
  // 全部閉じる
  closeAllModals: () => void;
}

export const useCommonModal = (): UseCommonModal => {
  const commonModal = useSelector((state: StoreState) => state.commonModal);
  const dispatch = useDispatch();

  const { permissionCheck } = useUser();

  const openRegisterFreeUserModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.openRegisterFreeUserModal });
  }, [dispatch]);

  const closeRegisterFreeUserModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.closeRegisterFreeUserModal });
  }, [dispatch]);

  const openRegisterLightUserModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.openRegisterLightUserModal });
  }, [dispatch]);

  const closeRegisterLightUserModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.closeRegisterLightUserModal });
  }, [dispatch]);

  const openRegisterBasicUserModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.openRegisterBasicUserModal });
  }, [dispatch]);

  const closeRegisterBasicUserModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.closeRegisterBasicUserModal });
  }, [dispatch]);

  const openRegisterPremiumUserModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.openRegisterPremiumUserModal });
  }, [dispatch]);

  const closeRegisterPremiumUserModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.closeRegisterPremiumUserModal });
  }, [dispatch]);

  const openRegisterTeamSubscriptionUserModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.openRegisterTeamSubscriptionUserModal });
  }, [dispatch]);

  const closeRegisterTeamSubscriptionUserModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.closeRegisterTeamSubscriptionUserModal });
  }, [dispatch]);

  const openRegisterTeamSubscriptionToTeamAdminModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.openRegisterTeamSubscriptionToTeamAdminModal });
  }, [dispatch]);

  const closeRegisterTeamSubscriptionToTeamAdminModal = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.closeRegisterTeamSubscriptionToTeamAdminModal });
  }, [dispatch]);

  const setOpenRegisterFreeUserModal = useCallback((): boolean => {
    if (permissionCheck(FunctionType.RecommendFree, PermissionType.Read)) {
      openRegisterFreeUserModal();
      return true;
    }
    return false;
  }, [openRegisterFreeUserModal, permissionCheck]);

  const setOpenRegisterLightUserModal = useCallback((): boolean => {
    if (permissionCheck(FunctionType.RecommendLight, PermissionType.Read)) {
      openRegisterLightUserModal();
      return true;
    }
    return false;
  }, [openRegisterLightUserModal, permissionCheck]);

  const setOpenRegisterBasicUserModal = useCallback((): boolean => {
    if (permissionCheck(FunctionType.RecommendBasic, PermissionType.Read)) {
      openRegisterBasicUserModal();
      return true;
    }
    return false;
  }, [openRegisterBasicUserModal, permissionCheck]);

  const setOpenRegisterPremiumUserModal = useCallback((): boolean => {
    if (permissionCheck(FunctionType.RecommendPremium, PermissionType.Read)) {
      openRegisterPremiumUserModal();
      return true;
    }
    return false;
  }, [openRegisterPremiumUserModal, permissionCheck]);

  const setOpenRegisterTeamSubscriptionUserModal = useCallback((): boolean => {
    if (permissionCheck(FunctionType.RecommendTeamSubscription, PermissionType.Read)) {
      openRegisterTeamSubscriptionUserModal();
      return true;
    }
    return false;
  }, [openRegisterTeamSubscriptionUserModal, permissionCheck]);

  const setOpenRegisterTeamSubscriptionToTeamAdminModal = useCallback((): boolean => {
    if (permissionCheck(FunctionType.RecommendTeamSubscriptionToAdmin, PermissionType.Read)) {
      openRegisterTeamSubscriptionToTeamAdminModal();
      return true;
    }
    return false;
  }, [openRegisterTeamSubscriptionToTeamAdminModal, permissionCheck]);

  const setOpenRegisterLightSubscriptionModal = useCallback((): boolean => {
    if (setOpenRegisterLightUserModal()) return true;
    if (setOpenRegisterTeamSubscriptionUserModal()) return true;
    if (setOpenRegisterTeamSubscriptionToTeamAdminModal()) return true;
    return false;
  }, [
    setOpenRegisterLightUserModal,
    setOpenRegisterTeamSubscriptionToTeamAdminModal,
    setOpenRegisterTeamSubscriptionUserModal,
  ]);

  const setOpenRegisterBasicSubscriptionModal = useCallback((): boolean => {
    if (setOpenRegisterBasicUserModal()) return true;
    if (setOpenRegisterTeamSubscriptionUserModal()) return true;
    if (setOpenRegisterTeamSubscriptionToTeamAdminModal()) return true;
    return false;
  }, [
    setOpenRegisterBasicUserModal,
    setOpenRegisterTeamSubscriptionToTeamAdminModal,
    setOpenRegisterTeamSubscriptionUserModal,
  ]);

  const setOpenRegisterPremiumSubscriptionModal = useCallback((): boolean => {
    if (setOpenRegisterPremiumUserModal()) return true;
    if (setOpenRegisterTeamSubscriptionUserModal()) return true;
    if (setOpenRegisterTeamSubscriptionToTeamAdminModal()) return true;
    return false;
  }, [
    setOpenRegisterPremiumUserModal,
    setOpenRegisterTeamSubscriptionToTeamAdminModal,
    setOpenRegisterTeamSubscriptionUserModal,
  ]);

  const openModal = useCallback(
    (functionType: string, permissionType: string): boolean => {
      const permission = permissionCheck(functionType, permissionType);

      switch (functionType) {
        case FunctionType.ChapterAuthTypeRegistered:
          switch (permissionType) {
            case PermissionType.Read:
              if (!permission) {
                if (setOpenRegisterFreeUserModal()) return true;
              }
              return false;
            default:
              return false;
          }

        case FunctionType.ChapterAuthTypeLight:
          switch (permissionType) {
            case PermissionType.Read:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.ChapterAuthTypeBasic:
          switch (permissionType) {
            case PermissionType.Read:
              if (!permission) return setOpenRegisterBasicSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.ChapterAuthTypePremium:
          switch (permissionType) {
            case PermissionType.Read:
              if (!permission) return setOpenRegisterPremiumSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.PracticeAuthTypeRegistered:
          switch (permissionType) {
            case PermissionType.Read:
              if (!permission) {
                if (setOpenRegisterFreeUserModal()) return true;
              }
              return false;
            default:
              return false;
          }

        case FunctionType.PracticeAuthTypeLight:
          switch (permissionType) {
            case PermissionType.Read:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.PracticeAuthTypeBasic:
          switch (permissionType) {
            case PermissionType.Read:
              if (!permission) return setOpenRegisterBasicSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.PracticeAuthTypePremium:
          switch (permissionType) {
            case PermissionType.Read:
              if (!permission) return setOpenRegisterPremiumSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.PracticeSubmit:
          switch (permissionType) {
            case PermissionType.Create:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            case PermissionType.Update:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.Question:
          switch (permissionType) {
            case PermissionType.Create:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.QuestionAnswerAndComment:
          switch (permissionType) {
            case PermissionType.Create:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.QuestionFavoriteAndAnswerFavorite:
          switch (permissionType) {
            case PermissionType.Update:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.SpotLessonBuyTicket:
          switch (permissionType) {
            case PermissionType.Create:
              if (!permission) {
                if (setOpenRegisterFreeUserModal()) return true;
                if (setOpenRegisterLightUserModal()) return true;
              }
              return false;
            default:
              return false;
          }

        case FunctionType.SpotLessonReserve:
          switch (permissionType) {
            case PermissionType.Create:
              if (!permission) {
                if (setOpenRegisterFreeUserModal()) return true;
                return setOpenRegisterLightSubscriptionModal();
              }
              return false;
            default:
              return false;
          }

        case FunctionType.StudyReport:
          switch (permissionType) {
            case PermissionType.Create:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.StudyReportComment:
          switch (permissionType) {
            case PermissionType.Create:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.StudyReportFavorite:
          switch (permissionType) {
            case PermissionType.Update:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.Timeline:
          switch (permissionType) {
            case PermissionType.Create:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.TimelineComment:
          switch (permissionType) {
            case PermissionType.Create:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.TimelineFavorite:
          switch (permissionType) {
            case PermissionType.Update:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        case FunctionType.TimeLineOfInstructorAndAdmin:
          switch (permissionType) {
            case PermissionType.Read:
              if (!permission) return setOpenRegisterLightSubscriptionModal();
              return false;
            default:
              return false;
          }

        default:
          return false;
      }
    },
    [
      permissionCheck,
      setOpenRegisterFreeUserModal,
      setOpenRegisterLightUserModal,
      setOpenRegisterBasicSubscriptionModal,
      setOpenRegisterLightSubscriptionModal,
      setOpenRegisterPremiumSubscriptionModal,
    ],
  );

  const closeAllModals = useCallback((): void => {
    dispatch({ type: CommonModalActionTypes.closeAllModals });
  }, [dispatch]);

  return {
    registerFreeUserModal: {
      isOpen: commonModal?.isFreeUserModalOpen,
      open: openRegisterFreeUserModal,
      close: closeRegisterFreeUserModal,
    },
    registerLightUserModal: {
      isOpen: commonModal?.isLightUserModalOpen,
      open: openRegisterLightUserModal,
      close: closeRegisterLightUserModal,
    },
    registerBasicUserModal: {
      isOpen: commonModal?.isBasicUserModalOpen,
      open: openRegisterBasicUserModal,
      close: closeRegisterBasicUserModal,
    },
    registerPremiumUserModal: {
      isOpen: commonModal?.isPremiumUserModalOpen,
      open: openRegisterPremiumUserModal,
      close: closeRegisterPremiumUserModal,
    },
    registerTeamSubscriptionUserModal: {
      isOpen: commonModal?.isTeamSubscriptionUserModalOpen,
      open: openRegisterTeamSubscriptionUserModal,
      close: closeRegisterTeamSubscriptionUserModal,
    },
    registerTeamSubscriptionToTeamAdminModal: {
      isOpen: commonModal?.isTeamSubscriptionToTeamAdminModalOpen,
      open: openRegisterTeamSubscriptionToTeamAdminModal,
      close: closeRegisterTeamSubscriptionToTeamAdminModal,
    },
    openModal,
    closeAllModals,
  };
};
