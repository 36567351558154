import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

import { H3, H4 } from '../../atoms/Typography';

import { LabeledLayout } from '../../molecules/LabeledLayout';

import { UseUser, useUser } from '../../../redux/user/useUser';
import { LOWER_META_TITLE } from '../../../const/Service';
import {
  GetMailSettingDocument,
  MailSettingInput,
  useGetMailSettingQuery,
  useUpdateMailSettingMutation,
  Valid,
} from '../../../gen/graphql';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { AccountTabLayout } from '../../templates/AccountTabLayout';

const allMailSettings: { [key: string]: string } = {
  notice: '運営からのお知らせ通知',
  messageReceived: 'メッセージ受信時',
  lessonRemind: '専属レッスンリマインド',
  lessonUnregistered: '専属レッスン登録リマインド',
  lessonRegistered: '専属レッスン予定登録時',
  lessonScheduleUpdated: '専属レッスン日程変更時',
  lessonLogUnregistered: '',
  practiceSubmitRegistered: '課題の提出時',
  practiceSubmitReRegistered: '課題の再提出時',
  practiceSubmitDetailReviewCommentRegistered: '課題へのレビュー投稿時',
  questionRegistered: '質問の投稿時',
  questionAnswerRegistered: '質問への回答投稿時',
  questionCommentRegistered: '質問へのコメント投稿時',
  claimApplied: '請求申請時',
  attendanceStatusChanged: '受講生ステータス変更時',
  backSchool: '受講生復学前通知',
  studyReportCommentRegistered: '学習ログへのコメント投稿時',
  studyReportAchieved: '学習ログ達成通知(週)',
  spotLessonRegistered: 'レッスンの予約時',
  spotLessonRemind: 'レッスンリマインド',
  spotLessonCancel: 'レッスンのキャンセル時',
  spotLessonLogUnregistered: 'レッスン未完了時',
  ticketRemind: 'チケット利用期限のリマインド',
  sessionRemind: 'セッションのリマインド',
};

const mailSettingsForStudent = [
  'notice',
  'messageReceived',
  'lessonRemind',
  'lessonUnregistered',
  'lessonRegistered',
  'lessonScheduleUpdated',
  'practiceSubmitDetailReviewCommentRegistered',
  'questionRegistered',
  'questionAnswerRegistered',
  'questionCommentRegistered',
  'studyReportCommentRegistered',
  'studyReportAchieved',
  'ticketRemind',
  'sessionRemind',
] as const;
const mailSettingsForInstructor = [
  'notice',
  'messageReceived',
  'lessonRemind',
  'lessonUnregistered',
  'lessonRegistered',
  'lessonScheduleUpdated',
  'practiceSubmitRegistered',
  'practiceSubmitReRegistered',
  'questionAnswerRegistered',
  'questionCommentRegistered',
  'attendanceStatusChanged',
  'backSchool',
  'spotLessonRegistered',
  'spotLessonRemind',
  'spotLessonCancel',
] as const;
const mailSettingsForCoach = [
  'notice',
  'messageReceived',
  'practiceSubmitRegistered',
  'practiceSubmitReRegistered',
  'questionAnswerRegistered',
  'questionCommentRegistered',
  'sessionRemind',
] as const;
const mailSettingsForSubscription = [
  'notice',
  'practiceSubmitDetailReviewCommentRegistered',
  'questionAnswerRegistered',
  'questionCommentRegistered',
  'studyReportCommentRegistered',
  'studyReportAchieved',
  'spotLessonRegistered',
  'spotLessonRemind',
  'spotLessonCancel',
  'ticketRemind',
] as const;
const mailSettingsForFree = [
  'notice',
  'questionAnswerRegistered',
  'questionCommentRegistered',
  'studyReportCommentRegistered',
  'studyReportAchieved',
  'spotLessonRegistered',
  'spotLessonRemind',
  'spotLessonCancel',
  'ticketRemind',
] as const;

export const AccountMail: React.FC = (): JSX.Element => {
  const metaTitle = `メール設定 | ${LOWER_META_TITLE}`;

  const { permissionCheck, isSocialLoginUser } = useUser();
  const { mailSettings, currentMailSettings, handleChangeSettings } =
    useMailSetting(permissionCheck);

  return (
    <AccountTabLayout
      activeTab="mail"
      permissionCheck={permissionCheck}
      isSocialLoginUser={isSocialLoginUser()}
      metaTitle={metaTitle}
    >
      <Container>
        <Content>
          <H3 color="DARK">メール受信設定</H3>
          <H4 color="LIGHT">設定は変更時に自動で保存されます</H4>
          {currentMailSettings &&
            mailSettings.map((name, i): JSX.Element => {
              return (
                <LabeledLayout
                  key={i}
                  labelWidth="300px"
                  label={<span>{allMailSettings[name]}</span>}
                >
                  <Toggle>
                    <StyledInput
                      type="checkbox"
                      id={name}
                      checked={currentMailSettings[name] === Valid.Valid}
                      onChange={handleChangeSettings}
                    />
                    <StyledLabel htmlFor={name}></StyledLabel>
                  </Toggle>
                </LabeledLayout>
              );
            })}
        </Content>
      </Container>
    </AccountTabLayout>
  );
};

export default AccountMail;

const useMailSetting = (permissionCheck: UseUser['permissionCheck']) => {
  const [currentMailSettings, setCurrentMailSettings] = useState<MailSettingInput>();

  const mailSettings = useMemo(() => {
    switch (true) {
      case permissionCheck(FunctionType.MailSettingsForStudent, PermissionType.Read):
        return mailSettingsForStudent;
      case permissionCheck(FunctionType.MailSettingsForInstructor, PermissionType.Read):
        return mailSettingsForInstructor;
      case permissionCheck(FunctionType.MailSettingsForCoach, PermissionType.Read):
        return mailSettingsForCoach;
      case permissionCheck(FunctionType.MailSettingsForSubscription, PermissionType.Read):
        return mailSettingsForSubscription;
      case permissionCheck(FunctionType.MailSettingsForFree, PermissionType.Read):
        return mailSettingsForFree;
      default:
        return [];
    }
  }, [permissionCheck]);

  useGetMailSettingQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCurrentMailSettings(data.getMailSetting);
    },
  });

  const [updateMailSetting] = useUpdateMailSettingMutation({
    refetchQueries: [{ query: GetMailSettingDocument }],
  });

  const handleChangeSettings = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!currentMailSettings) return;

    const updatedSettings: MailSettingInput = {
      ...currentMailSettings,
      [e.target.id]: e.target.checked ? Valid.Valid : Valid.Invalid,
    };

    setCurrentMailSettings(updatedSettings);

    updateMailSetting({
      variables: {
        input: updatedSettings,
      },
    });
  };

  return {
    mailSettings,
    currentMailSettings,
    handleChangeSettings,
  };
};

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 2rem;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0.1);
`;

const Toggle = styled.div`
  text-align: right;
`;

const StyledInput = styled.input`
  display: none;

  &:checked + label {
    border: 1px solid #e2001b;
    background-color: #e2001b;
    color: #fff;
  }

  &:checked + label:before {
    left: calc(100% - 22px);
    background-color: #fff;
  }
`;

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  border-radius: 24px;
  box-sizing: border-box;
  cursor: pointer;
  background: #f3f3f3;
  border: 1px solid rgba(0, 0, 0, 0.3);
  transition: all 0.2s linear;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 50%;
    transition: all 0.2s ease-out;
  }
`;
