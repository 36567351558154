import React from 'react';
import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import { progress } from '../../utils/Progress';

interface Props {
  programId: number;
  title?: string;
  thumbnail?: string;
  studyLogCount?: number;
  chapterCount?: number;
}

export const TopProgramCard: React.FC<Props> = ({
  programId,
  title,
  thumbnail,
  studyLogCount = 0,
  chapterCount = 0,
}): JSX.Element => {
  return (
    <StyledSection>
      <Header>
        <StyledH3>学習中の教材</StyledH3>
        <StyledLink to="/programs_while_studying">学習中の教材一覧 ＞</StyledLink>
      </Header>
      <Container>
        <ContainerLink to={`/programs/${programId}/chapters`}>
          <div>
            <Thumb src={thumbnail} alt={title} />
          </div>
          <DetailInfo>
            <Title>{title}</Title>
            <Progress>
              <ProgressBar start={studyLogCount} end={chapterCount}>
                <div />
              </ProgressBar>
              <ProgressInfo>
                {studyLogCount}/{chapterCount}({progress(studyLogCount, chapterCount)}%)
              </ProgressInfo>
            </Progress>
          </DetailInfo>
        </ContainerLink>
      </Container>
      <Footer>
        <StyledLink to="/programs_while_studying">学習中の教材一覧 ＞</StyledLink>
      </Footer>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 1.25rem;
  ${media.lessThan('medium')`
    padding: 1.5rem 1rem 1.25rem;;
  `}
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: -0.5rem;

  & > * {
    margin-top: 0.5rem;
  }

  a {
    margin-left: auto;
    ${media.lessThan('medium')`
      display: none;
    `}
  }
`;
const StyledH3 = styled.h3`
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.33;
  color: #000000;

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.375;
  `}
`;

const ContainerLink = styled(Link)`
  display: flex;
  ${media.lessThan('medium')`
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
  `}
`;

const StyledLink = styled(Link)`
  color: #e2001b;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.33;
`;
const Container = styled.div`
  margin-top: 1.75rem;
`;
const Thumb = styled.img`
  width: 7.5rem;
  height: auto;
  border-radius: 0.25rem;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const DetailInfo = styled.div`
  width: 100%;
  margin-left: 0.75rem;
  ${media.lessThan('medium')`
    margin: 0.5rem 0 0;
  `}
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.6;
`;
const Progress = styled.div`
  margin-top: 0.5625rem;
  display: flex;
  align-items: center;
`;

const barAnimation = (start: number, end: number) => {
  return keyframes`
    0% {
      width: 0;
    }

    100% {
      width: ${progress(start, end)}%;
    }
  `;
};
const ProgressBar = styled.div<{ start: number; end: number }>`
  width: 100%;
  height: 0.3rem;
  border-radius: 1rem;
  background-color: #f0f0f0;
  div {
    height: 100%;
    width: 0;
    background: linear-gradient(to right, #fe5e27, #e2001b);
    animation: ${(props) => barAnimation(props.start, props.end)} 0.5s ease-out 1 forwards;
  }
`;
const ProgressInfo = styled.span`
  margin-left: 0.75rem;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
`;

const Footer = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
    justify-content: flex-end;
    margin-top: 1.125rem;
  `}
`;
