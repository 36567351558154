import { StudyReport } from '../common/Const';

export const formatReportTime = (time: number | null | undefined): string => {
  return time ? `${Math.floor(time / 60)}時間${time % 60}分` : '0 時間 0 分';
};

export const getStudyReportContentError = (value: string | undefined): string => {
  const contentFilled = !!value?.trim();
  const contentChanged = value?.trim() !== StudyReport.DEFAULT_VALUE;

  if (!contentFilled) {
    return '「ひとこと」が入力されていません。';
  }
  if (!contentChanged) {
    return '「ひとこと」の内容が変更されていません。';
  }

  return '';
};
