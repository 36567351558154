import React, { forwardRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import IconImage from '../../static/image/icon_team_popup.svg';

interface TeamPopupLabelProps {
  isOpen: boolean;
}

export const TeamPopupLabel = forwardRef(
  ({ isOpen }: TeamPopupLabelProps, ref: React.Ref<HTMLLabelElement>) => (
    <Container>
      <Label ref={ref} active={isOpen}>
        <LabelText>所属チーム</LabelText>
        <LabelIcon src={IconImage} />
      </Label>
    </Container>
  ),
);

const Container = styled.div`
  position: relative;
`;
const Label = styled.label<{ active: boolean }>`
  display: block;
  padding-right: 1.5rem;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    margin: auto;
    border-style: solid;
    border-color: ${(props) =>
      props.active
        ? 'transparent transparent #000 transparent'
        : '#000 transparent transparent transparent'};
    border-width: ${(props) => (props.active ? '0 5px 9px 5px' : '9px 5px 0 5px')};
    position: absolute;
    top: 0;
    bottom: 0;
    right: 3px;
  }

  ${media.lessThan('medium')`
    padding-right: 1rem;
  `}
`;
const LabelText = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  user-select: none;

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const LabelIcon = styled.img`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    width: 2rem;
  `}
`;
