import React, { useMemo } from 'react';
import TabNavigationLayout from './TabNavigationLayout';

type TabType = 'profile' | 'subscription' | 'invoice' | 'payment';

interface Props {
  activeTab: TabType;
  metaTitle: string;
  metaDescription?: string;
}

export const TeamSettingTabLayout: React.FC<Props> = ({
  activeTab,
  metaTitle,
  metaDescription,
  children,
}): JSX.Element => {
  const tabs = useMemo(() => {
    const profileTab = {
      label: 'チーム情報',
      to: `/team/profile`,
      active: activeTab === 'profile',
    };
    const tabs = [profileTab];

    const subscriptionTab = {
      label: '加入プラン',
      to: `/team/subscription`,
      active: activeTab === 'subscription',
    };
    tabs.push(subscriptionTab);

    const invoiceTab = {
      label: '請求情報',
      to: `/team/invoice`,
      active: activeTab === 'invoice',
    };
    tabs.push(invoiceTab);

    const paymentTab = {
      label: '支払方法',
      to: `/team/payment`,
      active: activeTab === 'payment',
    };
    tabs.push(paymentTab);

    return tabs;
  }, [activeTab]);

  return (
    <TabNavigationLayout
      pageTitle="チーム設定"
      tabs={tabs}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {children}
    </TabNavigationLayout>
  );
};
