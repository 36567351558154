import React from 'react';

import Amex from '../../static/image/brand_amex.png';
import Diners from '../../static/image/brand_diners.png';
import Jcb from '../../static/image/brand_jcb.png';
import Master from '../../static/image/brand_master.png';
import Visa from '../../static/image/brand_visa.png';
import Discover from '../../static/image/brand_discover.png';

interface Props {
  brand: string;
  className?: string;
}

export const CardBrand: React.FC<Props> = (props) => {
  let card = '';
  switch (props.brand) {
    case 'amex':
      card = Amex;
      break;
    case 'diners':
      card = Diners;
      break;
    case 'jcb':
      card = Jcb;
      break;
    case 'mastercard':
      card = Master;
      break;
    case 'visa':
      card = Visa;
      break;
    case 'discover':
      card = Discover;
      break;
  }

  return <img className={props.className} src={card} />;
};
