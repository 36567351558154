import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { CurrentUserFragment as User } from '../../gen/graphql';
import { HomeSummaryElement } from '../molecules/HomeSummaryElement';
import { VerticalUserInfo } from '../molecules/VerticalUserInfo';
import CalendarIcon from '../../static/image/icon_calendar.svg';
import BookIcon from '../../static/image/icon_book_orange.svg';
import HeadsetIcon from '../../static/image/icon_headset.svg';

interface HomeSummaryProps {
  user?: User;
  studyReportCount: number;
  studyReportCurrentRunningLayDays: number;
  studyReportRunningLayDays: number;
  completedProgramCount: number;
  lessonCount: number;
  totalLessonCount: number;
  sessionCountInfo?: {
    count: number;
    totalCount: number;
  };
}

export const StudentHomeSummary: React.FC<HomeSummaryProps> = ({ sessionCountInfo, ...props }) => {
  return (
    <Container $hasSession={!!sessionCountInfo}>
      <VerticalUserInfo
        user={props.user}
        currentRunningLayDays={props.studyReportCurrentRunningLayDays}
        runningLayDays={props.studyReportRunningLayDays}
      />
      <HomeSummaryElement icon={CalendarIcon} name="総学習回数" value={props.studyReportCount} />
      <HomeSummaryElement icon={BookIcon} name="完了教材数" value={props.completedProgramCount} />
      <HomeSummaryElement
        icon={HeadsetIcon}
        name="レッスン数"
        value={props.lessonCount}
        total={props.totalLessonCount}
      />
      {sessionCountInfo && (
        <HomeSummaryElement
          icon={HeadsetIcon}
          name="セッション数"
          value={sessionCountInfo.count}
          total={sessionCountInfo.totalCount}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{ $hasSession: boolean }>`
  display: flex;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  align-items: center;

  & > * {
    box-sizing: border-box;
    width: ${(props) => (props.$hasSession ? '20' : '25')}%;
  }

  & > * + * {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('medium')`
    flex-wrap: wrap;
    padding: 8px;
    border-radius: 2px;
    align-items: stretch;

    & > *:first-child {
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 16px;
      margin-bottom: 6px;
    }
    
    & > *:not(:first-child) {
      width: calc(100% / 2);
      border: none;
      padding: 16px;
    }

    & > *:not(:first-child):nth-of-type(even) {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    
    & > *:not(:first-child):nth-of-type(-n + 3) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  `}
`;
