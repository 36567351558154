import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import IconSearch from '../../static/image/icon_search.svg';
import { useMediaQuery } from '../organisms/SearchHeader';
import { SP_WIDTH } from '../../const/Layout';
import { PLACEHOLDER_FOR_PC, PLACEHOLDER_FOR_SP } from '../../const/Search';

interface Props {
  pc?: boolean;
  className?: string;
}

export const DummySearchForm: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { windowWidth } = useMediaQuery();
  const moveSearch = () => {
    navigate('/search', { state: { pathname: location.pathname } });
  };

  return (
    <Container className={props.className} onClick={moveSearch} $pc={props.pc}>
      <Img src={IconSearch} alt="search" />
      <Text>{windowWidth > SP_WIDTH ? PLACEHOLDER_FOR_PC : PLACEHOLDER_FOR_SP}</Text>
    </Container>
  );
};

const Container = styled.div<{ $pc?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.5rem 0.625rem;
  background: #fff;
  border: 1px solid #524e4d;
  border-radius: 2rem;
  cursor: text;

  ${media.lessThan('medium')`
    gap: .5rem;
    padding: .5rem;
  `}

  ${(props) =>
    props.$pc &&
    media.lessThan('medium')`
    display: none;
  `}
`;
const Img = styled.img`
  display: block;
  width: 1rem;
  height: auto;
`;
const Text = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
