import React, { useCallback } from 'react';
import * as E from 'fp-ts/lib/Either';
import { Either } from 'fp-ts/Either';
import {
  LmsStripeValidationError,
  Payment,
  StripeSystemError,
} from '../../infrastructure/externalService/StripePaymentService';
import { useToastsContext } from '../../context/ToastsProvider';
import { pipe } from 'fp-ts/function';
import { AddPaymentMethodModal } from '../molecules/AddPaymentMethodModal';
import { StripePaymentMethodFragment, useTeamSelectPaymentMethodMutation } from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { getApiErrorMessage } from '../../utils/graphqlError';

interface Props {
  teamID: string;
  isOpen: boolean;
  loading?: boolean;
  toggle: (nextState: boolean) => void;
  onCompleted: (id: string) => Promise<void>;
  cards: StripePaymentMethodFragment[];
  currentDefaultPaymentId: string;
  setLoading: (isLoading: boolean) => void;
}

export const TeamDefaultPaymentAddModal: React.FC<Props> = ({
  teamID,
  isOpen,
  loading,
  toggle,
  onCompleted,
  cards,
  currentDefaultPaymentId,
  setLoading,
}) => {
  const [selectPaymentMethod] = useTeamSelectPaymentMethodMutation();

  const { showToast } = useToastsContext();

  const changePaymentMethod = useSafeAsyncCallback(
    useCallback(
      async (
        id: string,
        clearElements: () => void,
        initValidateMsg: () => void,
        setSubmitButtonClicked: (clicked: boolean) => void,
      ): Promise<void> => {
        setLoading(true);
        setSubmitButtonClicked(true);
        initValidateMsg();

        try {
          await selectPaymentMethod({
            variables: {
              teamID,
              providerID: id,
            },
          });
          await onCompleted(id);
        } catch (e) {
          showToast(1, getApiErrorMessage(e));
          return;
        } finally {
          setSubmitButtonClicked(false);
          setLoading(false);
        }
        showToast(0, '支払い方法の追加及びデフォルトの支払い方法が変更完了しました。');
        toggle(false);
        clearElements();
      },
      [selectPaymentMethod, teamID, onCompleted, showToast, toggle, setLoading],
    ),
  );

  const addNewPayment = (
    inputNewPaymentResult: Either<LmsStripeValidationError, Payment>,
    clearElements: () => void,
    initValidateMsg: () => void,
    validateError: (error: LmsStripeValidationError) => void,
    setSubmitButtonClicked: (clicked: boolean) => void,
  ) => {
    setLoading(true);
    setSubmitButtonClicked(true);
    initValidateMsg();

    pipe(
      inputNewPaymentResult,
      E.fold(
        (e: LmsStripeValidationError) => {
          if (!(e instanceof StripeSystemError)) {
            validateError(e);
          }
          showToast(1, getApiErrorMessage(e));
          setSubmitButtonClicked(false);
          setLoading(false);
        },
        async (payment: Payment) => {
          try {
            await selectPaymentMethod({
              variables: {
                teamID,
                providerID: payment.paymentId,
              },
            });
            await onCompleted(payment.paymentId);
          } catch (e) {
            showToast(1, getApiErrorMessage(e));
            return;
          } finally {
            setLoading(false);
            setSubmitButtonClicked(false);
          }
          showToast(0, '支払い方法の追加及びデフォルトの支払い方法が変更完了しました。');
          toggle(false);
          clearElements();
        },
      ),
    );
  };

  return (
    <>
      <AddPaymentMethodModal
        isOpen={isOpen}
        toggle={toggle}
        loading={loading}
        cards={cards}
        currentDefaultPaymentId={currentDefaultPaymentId}
        addNewPayment={addNewPayment}
        changeSelectPayment={changePaymentMethod}
      />
    </>
  );
};
