import React from 'react';
import styled from 'styled-components';

import { PracticeSearchFragment, ProgramSearchFragment } from '../../gen/graphql';
import {
  ReviewedOptions,
  PracticeSortByOptions,
  ReviewStatusOptions,
} from '../../const/PracticeSubmit';

import { FilterInputSelect } from '../atoms/FilterInputSelect';
import { FilterRadio } from '../molecules/FilterRadio';
import { FilterSelect } from '../molecules/FilterSelect';
import { INITIAL } from '../../const/Filter';

interface Props {
  title?: string;
  className?: string;
  displaySortKey: {
    sortKey: string;
    handleSortKeySelect: (value: string) => void;
  };
  displayReviewed: {
    reviewed: number;
    handleReviewedSelect: (value: string) => void;
  };
  displayReviewStatus: {
    reviewStatus: string;
    handleReviewStatusSelect: (value: string) => void;
  };
  displayProgram?: {
    programs: ProgramSearchFragment[];
    programID: number;
    handleProgramIDSelect: (value: string) => void;
  };
  displayPractice?: {
    practices: PracticeSearchFragment[];
    practiceID: string;
    handlePracticeIDSelect: (value: string) => void;
  };
}

export const PracticeSubmitFilter: React.FC<Props> = (props) => {
  const handleSortKeySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.displaySortKey?.handleSortKeySelect(e.target.value);
  };

  const handleReviewedSelect = (value: string) => {
    props.displayReviewed.handleReviewedSelect(value);
  };

  const handleReviewStatusSelect = (value: string) => {
    props.displayReviewStatus.handleReviewStatusSelect(value);
  };

  const handleProgramIDSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!props.displayProgram) return;
    props.displayProgram.handleProgramIDSelect(e.target.value);
  };

  const handlePracticeIDSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!props.displayPractice) return;
    props.displayPractice.handlePracticeIDSelect(e.target.value);
  };

  return (
    <Container className={props.className}>
      {!!props.title && <Title>{props.title}</Title>}
      <ItemList>
        <div>
          <FilterSelect
            label="並び替え"
            name="sortKey"
            options={PracticeSortByOptions}
            currentValue={props.displaySortKey.sortKey}
            handleSelect={handleSortKeySelect}
          />
        </div>
        <div>
          <FilterRadio
            label="回答有無"
            name="reviewed"
            options={ReviewedOptions}
            currentValue={props.displayReviewed.reviewed.toString()}
            handleSelect={handleReviewedSelect}
          />
        </div>
        <div>
          <FilterRadio
            label="ステータス"
            name="reviewStatus"
            options={ReviewStatusOptions}
            currentValue={props.displayReviewStatus.reviewStatus}
            handleSelect={handleReviewStatusSelect}
            column={2}
          />
        </div>
        <div>
          {props.displayProgram && (
            <>
              <ItemTitle>教材</ItemTitle>
              <FilterInputSelect
                name="program"
                options={[
                  { name: '教材を選択', value: INITIAL.toString() },
                  ...props.displayProgram.programs.map((program) => {
                    return { name: program.title, value: program.id.toString() };
                  }),
                ]}
                currentValue={props.displayProgram.programID.toString()}
                handleSelect={handleProgramIDSelect}
                active={props.displayProgram.programID !== INITIAL}
              />
            </>
          )}
          {props.displayPractice && (
            <>
              {!props.displayProgram && <ItemTitle>課題</ItemTitle>}
              <StyledFilterInputSelect
                name="practice"
                options={[
                  { name: '課題を選択', value: INITIAL.toString() },
                  ...props.displayPractice.practices.map((practice) => {
                    return { name: practice.title ?? '', value: practice.id.toString() };
                  }),
                ]}
                currentValue={props.displayPractice.practiceID.toString()}
                handleSelect={handlePracticeIDSelect}
                active={props.displayPractice.practiceID !== INITIAL.toString()}
                disabled={props.displayProgram && props.displayProgram.programID === 0}
              />
            </>
          )}
        </div>
      </ItemList>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  padding: 1rem;
  overflow-y: auto;
`;
const Title = styled.h3`
  margin-bottom: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.09px;
`;
const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
`;
const ItemTitle = styled.h4`
  margin-bottom: 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.09px;
`;
const StyledFilterInputSelect = styled(FilterInputSelect)`
  margin-top: 0.625rem;
`;
