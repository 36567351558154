import React from 'react';
import styled from 'styled-components';

import { BasicLayout } from '../../templates/BasicLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Timeline as TimelineContent } from '../../organisms/Timeline';

import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';

export const Timeline: React.FC = (): JSX.Element => {
  const metaTitle = `タイムライン | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のタイムラインページです。他のユーザーの学習進捗や動向を確認できます。日頃の学習の成果や悩みを投稿してユーザー同士で交流できます。`;

  return (
    <BasicLayout pageTitle="タイムライン" metaTitle={metaTitle} metaDescription={metaDescription}>
      <PageWrapper>
        <Container>
          <TimelineContent withPostForm />
        </Container>
      </PageWrapper>
    </BasicLayout>
  );
};

const Container = styled.div`
  max-width: 892px;
  margin: 0 auto;
`;
