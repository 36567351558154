import React, { forwardRef, SyntheticEvent, useCallback } from 'react';

import styled from 'styled-components';
import { defaultBreakpoints } from 'styled-media-query';

import UserType from '../../const/UserType';

import defaultIcon from '../../static/image/icon_default.svg';

interface CircularIconProps {
  src?: string | null;
  size: number;
  mediumSize?: number;
  userType?: string;
  onClick?: () => void;
  className?: string;
  e2e?: string;
}

export const CircularIcon = forwardRef(
  (
    { src, size, mediumSize, userType, onClick, e2e, className }: CircularIconProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const handleClick = useCallback(
      (e: SyntheticEvent) => {
        if (!onClick) {
          return;
        }
        e.preventDefault();
        onClick();
      },
      [onClick],
    );

    return (
      <Container
        className={className}
        size={size}
        src={src ? src : defaultIcon}
        $mediumSize={mediumSize}
        userType={userType}
        onClick={handleClick}
        ref={ref}
        isCursor={(!!onClick || !!ref) && userType !== UserType.AI}
        data-e2e={e2e}
      />
    );
  },
);

const Container = styled.div<{
  size: number;
  src: string;
  $mediumSize?: number;
  userType?: string;
  isCursor: boolean;
}>`
  position: relative;
  flex: 0 0 auto;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: url(${(props) => props.src}) center / cover no-repeat;
  border-radius: 50%;
  ${(props) => (props.isCursor ? 'cursor: pointer;' : '')}

  @media (max-width: ${defaultBreakpoints.medium}) {
    margin-right: 5px;
    width: ${(props) => props.$mediumSize ?? props.size}px;
    height: ${(props) => props.$mediumSize ?? props.size}px;
  }

  ${(props) => {
    if (props.userType === UserType.AI) {
      return 'box-shadow: 0 0 0 2px #3a8369 inset;';
    }
    if (
      props.userType === UserType.Instructor ||
      props.userType === UserType.Admin ||
      props.userType === UserType.Coach
    ) {
      return 'box-shadow: 0 0 0 2px #ff5e5e inset;';
    }
    return 'box-shadow: 0 0 0 2px #ececec inset;';
  }}
`;
