import React from 'react';
import styled from 'styled-components';

import { useUser } from '../../redux/user/useUser';
import { SERVICE_NAME } from '../../const/Service';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { Link } from 'react-router-dom';

export const HelpMenu: React.FC = () => {
  const { permissionCheck } = useUser();
  const questionLink = React.useMemo(() => {
    switch (true) {
      case permissionCheck(FunctionType.DocumentForInstructor, PermissionType.Read):
        return 'https://tayori.com/faq/8921b734331f31134b24218f9de5fc4d5ad32b21';
      case permissionCheck(FunctionType.DocumentForCoach, PermissionType.Read):
        return 'https://tayori.com/q/samurai-coach/';
      case permissionCheck(FunctionType.DocumentForStudent, PermissionType.Read):
        return 'https://tayori.com/faq/2afb58b87ebf5105ff984f92aad98eb81be77847';
      case permissionCheck(FunctionType.DocumentForSubscription, PermissionType.Read) ||
        permissionCheck(FunctionType.DocumentForNotLogin, PermissionType.Read):
        return 'https://intercom.help/plus---samurai/ja/';
      default:
        return '';
    }
  }, [permissionCheck]);

  const howtoLink = React.useMemo(() => {
    switch (true) {
      case permissionCheck(FunctionType.DocumentForStudent, PermissionType.Read):
        return 'https://tayori.com/faq/2afb58b87ebf5105ff984f92aad98eb81be77847/category/0649a87959221766c0c5bcc791ce9d910e7e5e60';
      case permissionCheck(FunctionType.DocumentForSubscription, PermissionType.Read) ||
        permissionCheck(FunctionType.DocumentForNotLogin, PermissionType.Read):
        return 'https://intercom.help/plus---samurai/ja/collections/2991873-%E3%81%AF%E3%81%98%E3%82%81%E3%81%A6%E3%81%AEplus';
      default:
        return '';
    }
  }, [permissionCheck]);

  const feedbackLink = permissionCheck(FunctionType.ServiceFeedback, PermissionType.Create)
    ? '/feedback'
    : '';

  return (
    <>
      {questionLink && (
        <Li>
          <a href={questionLink} target="_blank">
            よくある質問
          </a>
        </Li>
      )}
      {feedbackLink && (
        <Li>
          <Link to="/feedback">ご意見・ご要望</Link>
        </Li>
      )}
      {howtoLink && (
        <Li>
          <a href={howtoLink} target="_blank">
            {SERVICE_NAME}の使い方
          </a>
        </Li>
      )}
      <Li>
        <a href="https://www.sejuku.net/blog/" target="_blank">
          侍エンジニアブログ
        </a>
      </Li>
    </>
  );
};

const Li = styled.li`
  &:nth-of-type(n + 2) {
    margin-top: 1rem;
  }

  a {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    font-weight: 500;
  }
`;
