import React from 'react';

import styled from 'styled-components';

import { Button } from '../../atoms/Button';
import { H3 } from '../../atoms/Typography';

import { useToastsContext } from '../../../context/ToastsProvider';

import { useGetCalendarForInstructorQuery } from '../../../gen/graphql';
import { AccountTabLayout } from '../../templates/AccountTabLayout';
import { useUser } from '../../../redux/user/useUser';
import { LOWER_META_TITLE } from '../../../const/Service';

const AccountAlignment: React.FC = (): JSX.Element => {
  const metaTitle = `アカウント連携 | ${LOWER_META_TITLE}`;

  const { permissionCheck, isSocialLoginUser } = useUser();
  const { showToast } = useToastsContext();

  const { data } = useGetCalendarForInstructorQuery();

  const calendar = data?.calendarForInstructor.id ?? '';

  const handleClip = () => {
    if (calendar === '') {
      showToast(1, 'URLのコピーに失敗しました');
      return;
    }
    navigator.clipboard
      .writeText(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v1/calendar/instructor_calendars/${calendar}`,
      )
      .then(
        () => {
          showToast(0, 'URLをコピーしました');
        },
        () => {
          showToast(1, 'URLのコピーに失敗しました');
        },
      );
  };

  return (
    <AccountTabLayout
      activeTab="alignment"
      permissionCheck={permissionCheck}
      isSocialLoginUser={isSocialLoginUser()}
      metaTitle={metaTitle}
    >
      <Container>
        <Content>
          <H3 color="DARK">アカウント連携</H3>
          <StyledButton onClick={handleClip} border>
            Googleカレンダーとの連携用URLをコピー
          </StyledButton>
          <Notice>
            ※Googleカレンダーとの連携でできることと連携方法の詳細は
            <Link href="https://terakoya.sejuku.net/programs/55/chapters/637#Todo%E2%91%A3%EF%BC%9Agoogle%E3%82%AB%E3%83%AC%E3%83%B3%E3%83%80%E3%83%BC%E9%80%A3%E6%90%BA%E3%81%AE%E3%82%BB%E3%83%83%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0">
              こちら
            </Link>
          </Notice>
        </Content>
      </Container>
    </AccountTabLayout>
  );
};

export default AccountAlignment;

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 2rem;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled(Button)`
  display: block;
  max-width: 400px;
  margin-top: 0.5rem;
`;

const Notice = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 1rem;
`;

const Link = styled.a`
  color: #e2001b;
  font-size: 1em;
`;
