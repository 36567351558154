import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';

import GiftImage from '../../../static/image/lp/gift001.png';

export const LpGift = (): JSX.Element => {
  return (
    <Gift data-gtm="gift">
      <Inner>
        <Ttl>
          <h2>登録特典</h2>
          <p>Gift</p>
        </Ttl>
        <img
          src={GiftImage}
          alt="登録者限定！「SAMURAI式プログラミング学習メソッド」をプレゼント！"
        />
      </Inner>
    </Gift>
  );
};

const Gift = styled.section`
  padding: 6.25rem 0;
  background: #f5f0ed;

  img {
    display: block;
    width: 100%;
    max-width: 802px;
    margin: 4rem auto 0;
  }

  ${media.lessThan('medium')`
    padding: 4rem 0;

    img {
      margin-top: 2rem;
    }
  `}
`;
