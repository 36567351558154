import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../atoms/Button';
import { TextArea, TextAreaHandler } from '../atoms/TextArea';
import { Modal } from '../molecules/Modal';
import {
  useAddQuestionAnswerEvaluationMutation,
  QuestionAnswerEvaluationInput,
  QuestionAnswerEvaluationType as EvaluationType,
} from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../context/ToastsProvider';
import { defaultErrorMessage } from '../../const/ErrorMessage';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleSolved?: () => void;
  questionID: number;
}

type FormValues = {
  questionID: number;
  evaluationType: EvaluationType.Good | EvaluationType.Bad | '';
  evaluationComment: string;
};

export const QuestionReviewModal: React.FC<Props> = (props) => {
  const [isCompletedAnswer, setIsCompletedAnswer] = useState(false);
  const [addQuestionAnswerEvaluation] = useAddQuestionAnswerEvaluationMutation();
  const { showToast } = useToastsContext();
  const evaluationCommentRef = useRef<TextAreaHandler | null>(null);
  // hook form default value
  const { handleSubmit, register, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      questionID: 0,
      evaluationType: '',
      evaluationComment: '',
    },
  });

  const evaluIconActiveWatch = watch('evaluationType', EvaluationType.Good);

  const onSubmit: SubmitHandler<FormValues> = useSafeAsyncCallback(async (data) => {
    if (!data.evaluationType) return;
    const input: QuestionAnswerEvaluationInput = {
      questionID: props.questionID,
      evaluationType: data.evaluationType,
      evaluationComment: evaluationCommentRef.current?.getValue() ?? '',
    };

    try {
      // 評価を登録する
      await addQuestionAnswerEvaluation({
        variables: {
          input,
        },
      });
      props.handleSolved && (await props.handleSolved());
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
      showToast(1, defaultErrorMessage);
    } finally {
      setIsCompletedAnswer(true);
    }
  });

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} loading={!isCompletedAnswer} width="800px">
      <Header>
        <HeaderTitle>{isCompletedAnswer ? '評価完了' : '回答者の評価'}</HeaderTitle>
      </Header>
      <Container>
        {isCompletedAnswer ? (
          <>
            <ContentTitle>
              評価の投稿
              <br />
              ありがとうございました
            </ContentTitle>
            <Text>
              評価いただきありがとうございます。QAの回答は誰でも行えますので自分が知っている知識で分かるようでしたら他のユーザーにも教えてあげましょう。
            </Text>
            <StyledButton border onClick={props.onClose}>
              閉じる
            </StyledButton>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ContentTitle>
                今回の質問に対する講師の
                <br />
                回答はいかがでしたか？
              </ContentTitle>
              <RadioContainer>
                <RadioLabel
                  active={evaluIconActiveWatch === EvaluationType.Good}
                  {...register('evaluationType', {
                    required: true,
                  })}
                  onClick={() => {
                    setValue('evaluationType', EvaluationType.Good);
                  }}
                >
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="32" cy="32" r="32" fill="#DA696C" />
                    <circle cx="20.245" cy="23.5102" r="3.91837" fill="white" />
                    <circle cx="43.7553" cy="23.5102" r="3.91837" fill="white" />
                    <path
                      d="M43.9671 39.1837C41.7087 43.0877 37.4877 45.7143 32.6532 45.7143C27.8187 45.7143 23.5977 43.0877 21.3394 39.1837"
                      stroke="white"
                      strokeWidth="3.91837"
                      strokeLinecap="round"
                    />
                  </svg>
                  <LabelText>よかった</LabelText>
                </RadioLabel>
                <RadioLabel
                  active={evaluIconActiveWatch === EvaluationType.Bad}
                  {...register('evaluationType', {
                    required: true,
                  })}
                  onClick={() => {
                    setValue('evaluationType', EvaluationType.Bad);
                  }}
                >
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="32" cy="32" r="32" fill="#7CB3D6" />
                    <circle cx="20.245" cy="23.5102" r="3.91837" fill="white" />
                    <circle cx="43.7553" cy="23.5102" r="3.91837" fill="white" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M41.5967 47.3035C41.8965 47.628 42.4487 47.4622 42.4487 47.0204C42.4487 42.5557 36.5712 37.2245 31.9998 37.2245C27.4283 37.2245 21.5508 42.5557 21.5508 47.0204C21.5508 47.4622 22.103 47.628 22.4028 47.3035C24.7891 44.7199 28.2055 43.102 31.9998 43.102C35.794 43.102 39.2104 44.7199 41.5967 47.3035Z"
                      fill="white"
                    />
                  </svg>
                  <LabelText>よくなかった</LabelText>
                </RadioLabel>
              </RadioContainer>
              <CommentLabel>評価のコメントを記入しましょう</CommentLabel>
              <StyledTextArea
                placeholder="例) ご丁寧に回答いただきありがとうございました。"
                height="160px"
                name={'evaluationComment'}
                ref={evaluationCommentRef}
              />
              <CommentNote>
                ※コメントは無くても構いませんが、御礼のメッセージなどを書くと喜ばれます。
              </CommentNote>
              <StyledButton type={'submit'} disabled={!evaluIconActiveWatch}>
                評価を投稿する
              </StyledButton>
            </form>
          </>
        )}
      </Container>
    </Modal>
  );
};

const Header = styled.div`
  padding: 1rem 3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;
const HeaderTitle = styled.h2`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
`;
const Container = styled.div`
  padding: 2rem;

  ${media.lessThan('medium')`
    padding: 1rem 1rem 2rem;
  `}
`;
const ContentTitle = styled.h3`
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: center;

  br {
    display: none;
  }

  ${media.lessThan('medium')`
    font-size: 1.125rem;
    line-height: 1.625rem;

    br {
      display: block;
    }
  `}
`;
const Text = styled.p`
  max-width: 700px;
  margin: 1rem auto 0;
  color: #000;
  font-size: 1rem;
  line-height: 1.5;

  ${media.lessThan('medium')`
    margin-top: 2rem;
    font-size: .875rem;
  `}
`;
const StyledButton = styled(Button)`
  display: block;
  width: 240px;
  margin: 2rem auto 0;
  padding-left: 0;
  padding-right: 0;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 320px;
  `}
`;
const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 2rem;

  ${media.lessThan('medium')`
    gap: 1rem;
    margin-top: 1rem;
  `}
`;
const RadioLabel = styled.label<{ active: boolean }>`
  width: 10rem;
  padding: 2rem 0 0.75rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;

  svg {
    display: block;
    margin: 0 auto;
  }

  &:hover {
    border-color: #eb0000;
  }

  ${(props) =>
    props.active &&
    `
    border-color: #eb0000;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      background: #eb0000;
      border-radius: .5rem;
      position: absolute;
      top: .5rem;
      right: .5rem;
    }

    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 4.5px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      box-sizing: border-box;
      position: absolute;
      top: .78rem;
      right: .75rem;
      transform: rotate(-45deg);
    }
  `}

  ${media.lessThan('medium')`
    flex: 1;
    width: auto;
    max-width: 12rem;
  `}
`;
const LabelText = styled.p`
  margin-top: 1.125rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
`;
const CommentLabel = styled.p`
  margin-top: 2rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;

  ${media.lessThan('medium')`
    margin-top: 1.5rem;
    font-size: 1rem;
  `}
`;
const StyledTextArea = styled(TextArea)`
  margin-top: 0.5rem;
`;
const CommentNote = styled.p`
  margin-top: 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem;
  line-height: 1rem;
`;
