import React, { useCallback } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Modal } from '../molecules/Modal';

import Icon001 from '../../static/image/subscription_modify_flow_001.png';
import Icon002 from '../../static/image/subscription_modify_flow_002.png';
import Icon003 from '../../static/image/subscription_modify_flow_003.png';
import Icon004 from '../../static/image/subscription_modify_flow_004.png';
import Icon005 from '../../static/image/subscription_modify_flow_005.png';
import { Button } from '../atoms/Button';

interface Props {
  toggle: (nextState: boolean) => void;
  isOpen: boolean;
}

export const SubscriptionModifyFlowModal: React.FC<Props> = ({ toggle, isOpen }) => {
  const linkHelpPage = () => {
    window.open(
      'https://intercom.help/plus---samurai/ja/articles/6360651-q-6%E3%83%B6%E6%9C%88-12%E3%83%B6%E6%9C%88%E3%83%97%E3%83%A9%E3%83%B3%E3%82%92%E5%A5%91%E7%B4%84%E4%B8%AD%E3%81%A7%E3%81%99-%E6%9C%9F%E9%96%93%E6%BA%80%E4%BA%86%E3%82%92%E5%BE%85%E3%81%9F%E3%81%9A%E3%81%97%E3%81%A6%E3%83%97%E3%83%A9%E3%83%B3%E3%82%92%E5%A4%89%E6%9B%B4%E3%81%97%E3%81%9F%E3%81%84%E5%A0%B4%E5%90%88-%E3%81%A9%E3%81%86%E3%81%99%E3%82%8C%E3%81%B0%E3%81%84%E3%81%84%E3%81%A7%E3%81%99%E3%81%8B',
    );
  };

  const closeModal = useCallback((): void => {
    toggle(false);
  }, [toggle]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      width="960px"
      header={<HeaderTitle>プラン変更について</HeaderTitle>}
    >
      <Container>
        <Title>プラン変更の流れ</Title>
        <Description>
          複数月プランを契約中のお客様は、以下の流れに沿って、
          <br className="pc" />
          ご自身でプラン変更手続きを行なっていただく必要がございます。
          <br />
          <br />
          <span>アップグレードの場合は、解約手数料を返金いたします。</span>
        </Description>
        <Sup>ダウングレードの場合は、解約手数料は返金されませんので、ご注意ください。</Sup>
        <Content>
          <ContentTitle>ダウングレードの場合</ContentTitle>
          <Table>
            <Card>
              <Image src={Icon001} />
              <div>
                <CardTitle>
                  <span>1</span>解約完了
                </CardTitle>
                <CardDescription>現在のプランを一度解約し次回更新日まで待ちます</CardDescription>
              </div>
            </Card>
            <Card>
              <Image src={Icon002} />
              <div>
                <CardTitle>
                  <span>2</span>支払い
                </CardTitle>
                <CardDescription>
                  更新日に解約が完了となり、残期間の50%分が解約手数料として発生。一旦お支払いいただきます。
                </CardDescription>
              </div>
            </Card>
            <Card>
              <Image src={Icon003} />
              <div>
                <CardTitle>
                  <span>3</span>再登録
                </CardTitle>
                <CardDescription>改めて、希望するプランに再登録します</CardDescription>
              </div>
            </Card>
          </Table>
          <PlusIcon />
          <ContentTitle>アップグレードの場合</ContentTitle>
          <UpgradeDescription>
            アップグレードの場合は上記①〜③の手続きに加えて下記の手続きを行うことで返金の手続きが行われます。
          </UpgradeDescription>
          <UpgradeTable>
            <Card>
              <Image src={Icon004} />
              <div>
                <CardTitle>
                  <span>4</span>連絡
                </CardTitle>
                <CardDescription>
                  再登録後、運営に登録した旨をご連絡していただきます。
                </CardDescription>
              </div>
            </Card>
            <Card>
              <Image src={Icon005} />
              <div>
                <CardTitle>
                  <span>5</span>返金
                </CardTitle>
                <CardDescription>
                  運営が「新プラン」への登録を確認した後、②で発生した解約手数料を返金いたします。
                </CardDescription>
              </div>
            </Card>
          </UpgradeTable>
        </Content>
        <StyledButton onClick={linkHelpPage} border>
          詳細の手順はこちらのヘルプページへ
        </StyledButton>
      </Container>
    </Modal>
  );
};

const HeaderTitle = styled.h2`
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;
`;
const Container = styled.div`
  padding: 2rem 1.5rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 1.5rem 1rem;
  `}
`;
const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1em;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1.375rem;
    line-height: 1.875rem;
  `}
`;
const Description = styled.p`
  margin: 1rem auto 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;

  span {
    font-size: 1em;
    font-weight: 700;
  }

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.375rem;
  `}

  ${media.lessThan('small')`
    .pc {
      display: none;
    }
  `}
`;
const Sup = styled.p`
  margin-top: 0.625rem;
  color: #eb0000;
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
`;
const Content = styled.div`
  margin-top: 2rem;
`;
const ContentTitle = styled.h4`
  color: #333;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
`;
const Table = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1.5rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    margin-top: 1rem;
  `}
`;
const UpgradeTable = styled(Table)`
  margin-top: 1rem;
`;
const Card = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  width: calc((100% - 1rem) / 3);
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  box-shadow: 0.125rem 0.125rem 0.375rem rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const Image = styled.img`
  display: block;
  width: 42%;
  max-width: 108px;
`;
const CardTitle = styled.h5`
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.375rem;
    height: 1.375rem;
    background: #ff3e3e;
    border-radius: 1rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
  }
`;
const CardDescription = styled.p`
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.125rem;
`;
const PlusIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  margin: 1rem auto;
  background: #ff3e3e;
  border-radius: 2rem;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.1875rem;
    margin: auto;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:after {
    transform: rotate(90deg);
  }
`;
const UpgradeDescription = styled.p`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
  text-align: center;
`;

const StyledButton = styled(Button)`
  display: block;
  margin: 2rem auto 0;
`;
