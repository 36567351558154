export const IsValidBirthday = (
  value: string,
): {
  result: boolean;
  error?: string;
} => {
  // 日付の形式がxxxx/xx/xxになっているか
  if (value && value.match(/\d{4}\/[0-1]\d\/[0-3]\d/)) {
    const userDate = new Date(value);
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 6);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 150);

    // 6歳以上150歳未満
    if (userDate.getTime() <= minDate.getTime() && userDate.getTime() > maxDate.getTime()) {
      return {
        result: true,
      };
    } else {
      return {
        result: false,
        error: '有効な生年月日を入力してください。年齢制限は6歳から150歳までです。',
      };
    }
  }

  return {
    result: false,
  };
};
