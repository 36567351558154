import React from 'react';
import styled from 'styled-components';

interface Props {
  active?: boolean;
  name: string;
  value: string;
  icon: string;
  activeIcon: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

export const ToggleButton: React.FC<Props> = (props) => {
  return (
    <Button>
      <input
        id={`${props.name}_${props.value}`}
        type="checkbox"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        checked={props.checked}
      />
      <label htmlFor={`${props.name}_${props.value}`}>
        <img src={props.icon} className="inactive" />
        <img src={props.activeIcon} className="active" />
        {props.children}
      </label>
    </Button>
  );
};

const Button = styled.div`
  width: 180px;

  .active {
    display: none;
  }

  .inactive {
    display: block;
  }

  input {
    display: none;

    &:checked + label {
      border-color: #eb0000;
      color: #eb0000;

      .active {
        display: block;
      }

      .inactive {
        display: none;
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: 100%;
    padding: 0.625rem 2rem;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    box-sizing: border-box;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;
