import { useMemo } from 'react';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import {
  BannerCidDeviceType,
  BannerCidFragment,
  BannerCidType,
  BannerTargetType,
  useGetBannerCidsQuery,
} from '../../gen/graphql';
import { useUser } from '../../redux/user/useUser';

export const useBannerCids = (
  cidTypes: BannerCidType[],
  limit: number,
): {
  pcBanners: BannerCidFragment[];
  spBanners: BannerCidFragment[];
  loading: boolean;
} => {
  const { permissionCheck } = useUser();

  // ログインしているロールから表示対象種別を判別
  const targetType = useMemo((): BannerTargetType => {
    switch (true) {
      case permissionCheck(FunctionType.BannerForInstructor, PermissionType.Read):
        return BannerTargetType.Instructor;
      case permissionCheck(FunctionType.BannerForStudent, PermissionType.Read):
        return BannerTargetType.Student;
      case permissionCheck(FunctionType.BannerForGraduate, PermissionType.Read):
        return BannerTargetType.Graduate;
      case permissionCheck(FunctionType.BannerForSubscription, PermissionType.Read):
        return BannerTargetType.Subscription;
      case permissionCheck(FunctionType.BannerForTeam, PermissionType.Read):
        return BannerTargetType.Team;
      case permissionCheck(FunctionType.BannerForFree, PermissionType.Read):
        return BannerTargetType.Free;
      default:
        return BannerTargetType.NotLoggedIn;
    }
  }, [permissionCheck]);

  const { data, loading } = useGetBannerCidsQuery({
    variables: { targetType, cidTypes, limit },
  });
  const bannerCids = data?.bannerCids ?? [];
  const pcBanners = bannerCids.filter(
    (bannerCid) =>
      bannerCid.deviceType === BannerCidDeviceType.Pc ||
      bannerCid.deviceType === BannerCidDeviceType.PcAndSp,
  );
  const spBanners = bannerCids.filter(
    (bannerCid) =>
      bannerCid.deviceType === BannerCidDeviceType.Sp ||
      bannerCid.deviceType === BannerCidDeviceType.PcAndSp,
  );

  return {
    pcBanners,
    spBanners,
    loading,
  };
};
