import React, { useMemo } from 'react';

import styled from 'styled-components';
import media from 'styled-media-query';

import { useUser } from '../../redux/user/useUser';

import { Link } from 'react-router-dom';
import { Markdown } from '../../common/Markdown';
import { QuestionFragment, QuestionStatus } from '../../gen/graphql';

import { Button } from '../atoms/Button';
import { QuestionBadge } from './QuestionBadge';
import { Control } from './Control';
import { UserIcon } from '../organisms/UserIcon';

import IconSolvedRed from '../../static/image/icon_solved_red.svg';
import { Box } from '../atoms/Box';

interface QuestionBodyProps {
  question: QuestionFragment;
  answersCount: number;
  handleClickEdit?: () => void;
  openReviewModal?: () => void;
  onChangeFavoriteStatus: () => void;
}

export const QuestionBody: React.FC<QuestionBodyProps> = (props) => {
  const { user } = useUser();

  const controlMenu = [
    {
      text: '編集',
      onClick: props.handleClickEdit,
    },
  ];

  const favorites = props.question.questionFavorites;
  const isActiveFavorite = useMemo(
    (): boolean => favorites.some(({ userID }) => userID === user.lmsUser?.id),
    [favorites, user.lmsUser?.id],
  );

  return (
    <Container>
      <Box padding={[5, 8, 8]} spPadding={[11, 4, 8]}>
        <InfoWrapper>
          <QuestionBadge
            answersCount={props.answersCount}
            solved={props.question.solved}
            isDraft={props.question.status === QuestionStatus.Draft}
            answerRequestType={props.question.answerRequestType}
          />
          <Info>
            <Title>{props.question.title}</Title>
            <StyledUserIcon
              user={props.question.user}
              date={props.question.editedAt ?? props.question.createdAt}
              note={props.question.editedAt ? '(編集済み)' : ''}
              huge
            />
            {props.question.program && props.question.chapter && (
              <ProgramList>
                <Program>
                  <ProgramLink to={`/programs/${props.question.program.id}/chapters`}>
                    教材：{props.question.program.title}
                  </ProgramLink>
                </Program>
                <Program>
                  <ProgramLink
                    to={`/programs/${props.question.program.id}/chapters/${props.question.chapter.id}`}
                  >
                    章：{props.question.chapter.title}
                  </ProgramLink>
                </Program>
              </ProgramList>
            )}
            {props.question.program && props.question.practice && (
              <ProgramList>
                <Program>
                  <ProgramLink to={`/programs/${props.question.program.id}/chapters`}>
                    教材：{props.question.program.title}
                  </ProgramLink>
                </Program>
                <Program>
                  <ProgramLink to={`/practices/${props.question.practice.id}/body`}>
                    課題：{props.question.practice.title}
                  </ProgramLink>
                </Program>
              </ProgramList>
            )}

            {props.question.user?.id === user.lmsUser?.id && props.handleClickEdit && (
              <StyledControl menu={controlMenu} />
            )}

            <Action>
              {props.question.user?.id === user.lmsUser?.id && !props.question.solved && (
                <SolveButton className="pointer" onClick={props.openReviewModal}>
                  <img src={IconSolvedRed} alt="解決済みにする" />
                  解決済みにする
                </SolveButton>
              )}
              <FavoriteButton $active={isActiveFavorite} onClick={props.onChangeFavoriteStatus}>
                同じく {favorites.length}
              </FavoriteButton>
            </Action>
          </Info>
        </InfoWrapper>
        <div className="content">
          <Markdown content={props.question.content} />
        </div>
        <FooterFavoriteButtonWrapper>
          <FavoriteButton $active={isActiveFavorite} onClick={props.onChangeFavoriteStatus}>
            同じく {favorites.length}
          </FavoriteButton>
        </FooterFavoriteButtonWrapper>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 956px;
  margin-bottom: 2rem;
  ${media.lessThan('medium')`
    width: 100%;
  `}

  .content p {
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 1rem;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 3rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
    padding-bottom: 1rem;
  `}
`;
const Info = styled.div`
  flex: 1;
  margin-left: 1.25rem;
  padding-right: 2.75rem;
  position: relative;

  ${media.lessThan('medium')`
    margin-left: .625rem;
    padding-right: 0;
  `}
`;

const Title = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4em;

  ${media.lessThan('medium')`
    font-size: 1.125rem;
    line-height: 1.48em;
  `}
`;

const StyledUserIcon = styled(UserIcon)`
  margin-top: 0.25rem;

  ${media.lessThan('medium')`
    margin-top: .625rem;
  `}
`;

const ProgramList = styled.div`
  margin-top: 0.75rem;
`;
const Program = styled.p`
  margin: 0;
  line-height: 1rem;

  &:nth-of-type(n + 2) {
    margin-top: 0.25rem;
  }

  ${media.lessThan('medium')`
    line-height: .875rem;
  `}
`;
const ProgramLink = styled(Link)`
  color: #4183c4;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.1px;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledControl = styled(Control)`
  position: absolute;
  top: 0;
  right: 0;

  ${media.lessThan('medium')`
    top: -1.25rem;
  `}
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -2.75rem;

  .pointer {
    cursor: pointer;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: flex-end;
    margin-right: 0;
  `}
`;

const SolveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  margin: 0 1rem 0;
  padding: 0.5rem;
  border: 1px solid #eb0000;
  border-radius: 0.1875rem;
  color: #eb0000;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;

  img {
    display: block;
    margin-right: 0.25rem;
  }

  ${media.lessThan('medium')`
    margin: 1rem 0 0.5rem;
  `}
`;

const FavoriteButton = styled(Button)<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.125rem;
  height: 2.25rem;
  box-sizing: border-box;
  background: ${({ $active }) => ($active ? '#eb0000' : '#ffffff')};
  border: 1px solid #eb0000;
  border-radius: 0.1875rem;
  color: ${({ $active }) => ($active ? '#ffffff' : '#eb0000')};
  font-weight: 700;
  font-size: 0.875rem;
`;

const FooterFavoriteButtonWrapper = styled.div`
  margin-top: 1.5rem;
`;
