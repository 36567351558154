import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useOnboardingQuery } from '../../gen/graphql';
import Checked from '../../static/image/onboarding_checked.svg';
import UnChecked from '../../static/image/onboarding_unchecked.svg';
import Close from '../../static/image/onboarding_close.svg';
import { SERVICE_NAME } from '../../const/Service';
import {
  getOnboardingClosedFromLocalStorage,
  setOnboardingClosedToLocalStorage,
} from '../../utils/localStorageSupport';
import { PROBLEM_QUESTION_CATEGORY } from '../../const/Question';
import { useUser } from '../../redux/user/useUser';

export const Onboarding: React.FC = () => {
  const { data } = useOnboardingQuery({
    fetchPolicy: 'no-cache', // Mutationすることがなく、refetchされるタイミングがないのでCacheしないで毎回取得する
  });
  const { user } = useUser();
  const userID = user.lmsUser?.id ?? 0;

  const [closeOnboarding, setCloseOnboarding] = useState(
    getOnboardingClosedFromLocalStorage(userID),
  );

  const close = () => {
    setOnboardingClosedToLocalStorage(userID);
    setCloseOnboarding(true);
  };

  return (
    <>
      {!closeOnboarding && data?.onboarding.isShowOnboarding ? (
        <Container>
          <StyledSection>
            <Header>
              <StyledH3>基本機能を習得しよう</StyledH3>
              <CloseBtn src={Close} onClick={close} />
            </Header>
            <Description>まずは{SERVICE_NAME}の基本的な機能を利用してみましょう</Description>
            {data?.onboarding.isShowProfile ? (
              <Item>
                {data?.onboarding.isCompletedProfile ? (
                  <ImageItem src={Checked} />
                ) : (
                  <ImageItem src={UnChecked} />
                )}
                <Text>
                  <StyledLink to={`/account/profile`}>プロフィール画像を変更</StyledLink>
                  <LinkInfo>仲間との交流の第一歩</LinkInfo>
                </Text>
              </Item>
            ) : (
              ''
            )}
            {data?.onboarding.isShowProgram ? (
              <Item>
                {data?.onboarding.isCompletedProgram ? (
                  <ImageItem src={Checked} />
                ) : (
                  <ImageItem src={UnChecked} />
                )}
                <Text>
                  <StyledLink to={`/programs`}>教材を使って学習する</StyledLink>
                  <LinkInfo>何事も初めの一歩から</LinkInfo>
                </Text>
              </Item>
            ) : (
              ''
            )}
            {data?.onboarding.isShowStudyReport ? (
              <Item>
                {data?.onboarding.isCompletedStudyReport ? (
                  <ImageItem src={Checked} />
                ) : (
                  <ImageItem src={UnChecked} />
                )}
                <Text>
                  <StyledLink to={`/study_report/my`}>学習ログを書く</StyledLink>
                  <LinkInfo>記録を残して習慣に</LinkInfo>
                </Text>
              </Item>
            ) : (
              ''
            )}
            {data?.onboarding.isShowQuestion ? (
              <Item>
                {data?.onboarding.isCompletedQuestion ? (
                  <ImageItem src={Checked} />
                ) : (
                  <ImageItem src={UnChecked} />
                )}
                <Text>
                  <StyledLink to={`/questions/all?category=${PROBLEM_QUESTION_CATEGORY}`}>
                    エンジニアに質問する
                  </StyledLink>
                  <LinkInfo>どんな悩みも気軽に質問</LinkInfo>
                </Text>
              </Item>
            ) : (
              ''
            )}
            {data?.onboarding.isShowSpotLesson ? (
              <Item>
                {data?.onboarding.isCompletedSpotLesson ? (
                  <ImageItem src={Checked} />
                ) : (
                  <ImageItem src={UnChecked} />
                )}
                <Text>
                  <StyledLink to={`/spot_lessons`}>レッスンを予約しよう</StyledLink>
                  <LinkInfo>1on1の個人レッスン</LinkInfo>
                </Text>
              </Item>
            ) : (
              ''
            )}
          </StyledSection>
        </Container>
      ) : (
        ''
      )}
    </>
  );
};

const Container = styled.section`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const StyledSection = styled.section`
  background: rgba(253, 60, 47, 0.03);
  border: 1px solid #eb0000;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0.8rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
`;

const StyledH3 = styled.h3`
  color: #eb0000;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.8rem;
`;

const Description = styled.p`
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0.8rem;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
`;

const CloseBtn = styled.img`
  position: absolute;
  top: 0.5rem; /* 上からの位置を指定 */
  right: 0.5rem; /* 右からの位置を指定 */
  cursor: pointer;
`;

const ImageItem = styled.img`
  display: block;
`;

const Text = styled.div`
  margin-left: 0.3rem;
`;

const StyledLink = styled(Link)`
  font-size: 0.8rem;
  font-weight: 600;
  color: #eb0000;
  display: block;
  margin-bottom: 0.3rem;
`;

const LinkInfo = styled.p`
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.87);
`;
