import React from 'react';
import styled from 'styled-components';

import { LEAD_TO_PRO_LINK } from '../../const/Link';

interface Props {
  className?: string;
}

export const LeadButtonToPro: React.FC<Props> = (props) => {
  return (
    <Button
      href={`${LEAD_TO_PRO_LINK}?cid=terakoya_home_snackbar&utm_source=terakoya_lms&utm_medium=terakoya&utm_campaign=terakoya_home`}
      target="_blank"
      className={props.className}
    >
      <span>【無料】学習目標設定</span>
    </Button>
  );
};

const Button = styled.a`
  display: block;
  padding: 0.5rem;
  background: linear-gradient(95deg, #fd8258 11.86%, #fd3c2f 82.5%);
  border-radius: 0.125rem;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: #fff;
    border-radius: 0.0625rem;
    position: absolute;
    top: 1px;
    left: 1px;
  }

  span {
    display: block;
    background: linear-gradient(95deg, #fd8258 11.86%, #fd3c2f 82.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
    text-align: center;
    text-indent: -0.5em;
    position: relative;
    z-index: 2;
  }
`;
