import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { PaymentAddModal } from '../../organisms/PaymentAddModal';
import { PaymentDeleteModal } from '../../organisms/PaymentDeleteModal';

import { Button } from '../../atoms/Button';
import { CardBrand } from '../../atoms/CardBrand';
import { useToastsContext } from '../../../context/ToastsProvider';
import { Loader } from '../../molecules/Loader';
import { Spacer } from '../../atoms/Spacer';
import { Payment } from '../../../const/Payment';
import {
  StripePaymentMethodFragment,
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useSelectPaymentMethodMutation,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { useUser } from '../../../redux/user/useUser';
import { AccountTabLayout } from '../../templates/AccountTabLayout';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { LOWER_META_TITLE } from '../../../const/Service';

export const AccountPayment: React.FC = () => {
  const metaTitle = `支払い方法 | ${LOWER_META_TITLE}`;
  const now = new Date();

  const { permissionCheck, isSocialLoginUser } = useUser();

  const [addIsOpen, setAddIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);

  const [deleteCard, setDeleteCard] = useState<StripePaymentMethodFragment>();

  const [radioButtonClicked, setRadioButtonClicked] = useState(false);

  const [selectPaymentMethodsLoading, setSelectPaymentMethodsLoading] = useState<boolean>(false);

  const { showToast } = useToastsContext();

  const {
    data: getPaymentMethodsData,
    loading: getPaymentMethodsLoading,
    refetch: getPaymentMethodsRefetch,
  } = useGetPaymentMethodsQuery({
    variables: {
      limit: Payment.MAX_ITEM_NUMBER,
    },
    onError: (e) => {
      showToast(1, getApiErrorMessage(e));
    },
    notifyOnNetworkStatusChange: true,
  });
  const paymentMethods = useMemo(
    () => getPaymentMethodsData?.paymentMethods.items ?? [],
    [getPaymentMethodsData],
  );

  const [selectPaymentMethod] = useSelectPaymentMethodMutation();
  const [deletePaymentMethodMutation] = useDeletePaymentMethodMutation();

  const fetchPayments = useCallback(async () => {
    getPaymentMethodsRefetch();
  }, [getPaymentMethodsRefetch]);

  const changeDefaultPayment = useSafeAsyncCallback(
    useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const providerID = event.target.value;
        setSelectPaymentMethodsLoading(true);
        setRadioButtonClicked(true);

        try {
          await selectPaymentMethod({
            variables: {
              providerID: providerID,
            },
          });
          fetchPayments();
          setSelectPaymentMethodsLoading(false);
          showToast(0, 'デフォルトの支払い方法を変更しました。');
        } catch (e) {
          setSelectPaymentMethodsLoading(false);
          showToast(1, getApiErrorMessage(e));
        } finally {
          setRadioButtonClicked(false);
        }
      },
      [fetchPayments, selectPaymentMethod, showToast],
    ),
  );

  const createPaymentMethod = useCallback(
    async (providerID: string): Promise<void> => {
      // ローディング表示や、例外処理はモーダル(PaymentAddModal)コンポーネント内で実施されている
      await selectPaymentMethod({
        variables: {
          providerID,
        },
      });
    },
    [selectPaymentMethod],
  );

  const deletePaymentMethod = useCallback(
    async (providerID: string): Promise<void> => {
      // ローディング表示や、例外処理はモーダル(PaymentDeleteModal)コンポーネント内で実施されている
      await deletePaymentMethodMutation({
        variables: {
          providerID: providerID,
        },
      });
    },
    [deletePaymentMethodMutation],
  );

  const existsDefaultPayment = useMemo((): boolean => {
    return paymentMethods.filter((paymentMethod) => paymentMethod.isDefault).length > 0;
  }, [paymentMethods]);

  const checkExpire = (item: StripePaymentMethodFragment, now: Date) =>
    now.getFullYear() < item.expireYear ||
    (now.getFullYear() === item.expireYear && now.getMonth() + 1 <= item.expireMonth);

  const showLoader = selectPaymentMethodsLoading || getPaymentMethodsLoading;

  return (
    <React.Fragment>
      <Loader display={showLoader}></Loader>
      <PaymentAddModal
        isOpen={addIsOpen}
        toggle={setAddIsOpen}
        onSubmit={createPaymentMethod}
        fetchPayments={fetchPayments}
      />
      {deleteCard && (
        <PaymentDeleteModal
          isOpen={deleteIsOpen}
          toggle={setDeleteIsOpen}
          cardData={deleteCard}
          onSubmit={deletePaymentMethod}
          fetchPayments={fetchPayments}
        />
      )}
      <AccountTabLayout
        activeTab="payment"
        permissionCheck={permissionCheck}
        isSocialLoginUser={isSocialLoginUser()}
        metaTitle={metaTitle}
      >
        {!showLoader ? (
          <Wrapper>
            <Container>
              <h2>クレジットカード情報</h2>
              {paymentMethods.length > 0 ? (
                <Ul>
                  {paymentMethods.map((item) => {
                    return (
                      <Li key={item.id}>
                        <Label active={item.isDefault}>
                          <input
                            type="radio"
                            value={item.id}
                            checked={item.isDefault}
                            onChange={changeDefaultPayment}
                            disabled={
                              radioButtonClicked ||
                              item.isDefault ||
                              !existsDefaultPayment ||
                              !permissionCheck(FunctionType.AccountPayment, PermissionType.Update)
                            }
                          />
                          <dl>
                            <Brand>
                              <CardBrand brand={item.cardBrand} />
                            </Brand>
                            <NumberTitle>カード番号</NumberTitle>
                            <Number>**** **** **** {item.cardNumber}</Number>
                            <Limit active={checkExpire(item, now)}>
                              {checkExpire(item, now) ? (
                                <span>有効期限</span>
                              ) : (
                                <span className="disabled">有効期限切れ</span>
                              )}
                              {item.expireMonth}/{item.expireYear}
                            </Limit>
                            {!item.isDefault &&
                              permissionCheck(
                                FunctionType.AccountPayment,
                                PermissionType.Delete,
                              ) && (
                                <Command
                                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    setDeleteIsOpen(true);
                                    setDeleteCard(item);
                                  }}
                                >
                                  削除
                                </Command>
                              )}
                          </dl>
                        </Label>
                      </Li>
                    );
                  })}
                </Ul>
              ) : (
                <EmptyMessage>ご登録されているクレジットカード情報がありません</EmptyMessage>
              )}
              {permissionCheck(FunctionType.AccountPayment, PermissionType.Create) && (
                <StyledButton onClick={() => setAddIsOpen(true)}>支払い方法を追加する</StyledButton>
              )}
              <Spacer height={'1rem'} />
              <Info>
                ※未払いの請求情報が存在する場合、有効なクレジットカードを指定すると自動で再決済が行われます。
              </Info>
            </Container>
          </Wrapper>
        ) : null}
      </AccountTabLayout>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 54rem;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
`;
const Container = styled.section`
  padding: 2rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;

  h2 {
    margin-bottom: 2.625rem;
    font-size: 1.25rem;
    font-weight: 700;
  }

  ${media.lessThan('medium')`
    margin: 0 -2rem;
  `}
`;

const Li = styled.li`
  input {
    display: none;
  }

  ${media.lessThan('medium')`
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0,0,0,0.1);
  `}
`;
const Ul = styled.ul`
  ${Li} + ${Li} {
    margin-top: 0.875rem;
  }

  ${media.lessThan('medium')`
    ${Li} + ${Li} {
      margin-top: 1rem;
    }
  `}
`;
const Label = styled.label<{ active: boolean }>`
  display: block;
  padding: 0.5rem 4.25rem 0.5rem 4rem;
  background: ${(props) => (props.active ? 'rgba(226,0,0,0.1)' : '#fff')};
  border-radius: 0.25rem;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    margin: auto;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.36);
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.5rem;
  }

  &:after {
    content: '';
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    margin: auto;
    background: #${(props) => (props.active ? 'e73248' : 'fff')};
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.75rem;
  }

  dl {
    display: flex;
    align-items: center;
  }

  ${media.lessThan('large')`
    padding: 0.5rem 3.75rem 0.5rem 2.625rem;
  `}

  ${media.lessThan('medium')`
    padding: 0 4.25rem 0 2rem;
    background: #fff;

    &:before {
      left: 0.25rem;
    }

    &:after {
      left: 0.5rem;
    }

    dl {
      flex-wrap: wrap;
    }
  `}
`;
const Brand = styled.dd`
  width: 3.25rem;
  height: 2.125rem;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const NumberTitle = styled.dd`
  margin: 0 1.5rem 0 2rem;

  ${media.lessThan('large')`
    margin: 0 0.5rem 0 1rem;
    font-size: 0.875rem;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const Number = styled.dd`
  margin-right: 2rem;

  ${media.lessThan('large')`
    margin-right: 1rem;
    font-size: 0.875rem;
  `}

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0 0 0.25rem;
    line-height: 1rem;
  `}
`;
const Limit = styled.dd<{ active: boolean }>`
  display: flex;
  align-items: center;

  span {
    display: block;
    margin-right: 0.5rem;

    &.disabled {
      color: #e73248;
    }
  }

  ${media.lessThan('large')`
    font-size: 0.875rem;

    span {
      font-size: 0.875rem;
    }
  `}

  ${media.lessThan('medium')`
    color: rgba(0,0,0,0.36);
    font-size: 0.6875rem;

    span {
      font-size: 0.6875rem;
    }
  `}

  ${(props) =>
    !props.active
      ? media.lessThan('medium')`
      color: #e73248;
    `
      : ''}
`;

const Command = styled.dd`
  height: 0.875rem;
  margin: auto;
  cursor: pointer;
  color: #e73248;
  font-size: 0.875rem;
  line-height: 0.875rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;

  ${media.lessThan('medium')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.75rem;
    height: 2.25rem;
    background: #fff;
    border: 1px solid #e73248;
    border-radius: 0.1875rem;
    box-sizing: border-box;
  `}
`;

const EmptyMessage = styled.p`
  font-size: 1rem;
  text-align: center;
`;
const StyledButton = styled(Button)`
  display: block;
  margin: 3rem auto 0;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 300px;
  `}
`;

const Info = styled.p`
  margin-top: 0.6rem;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.36);

  a {
    font-size: 0.8rem;
    color: #e2001b;
  }
`;
