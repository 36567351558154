import { useCallback } from 'react';
import { useUser } from '../../redux/user/useUser';
import TagManager from 'react-gtm-module';

// NOTE: dataLayerの型はobject
// eslint-disable-next-line @typescript-eslint/ban-types
type EventData = object;

export const useTagManager = (): {
  pushEvent: (event: string, eventData?: EventData) => void;
} => {
  const {
    user: { lmsUser },
  } = useUser();

  // イベント送信
  const pushEvent = useCallback(
    (event: string, eventData: EventData = {}): void => {
      TagManager.dataLayer({
        dataLayer: {
          userId: lmsUser?.id,
          user: lmsUser,
          event,
          eventData,
        },
        dataLayerName: 'LMSDataLayer',
      });
    },
    [lmsUser],
  );

  return { pushEvent };
};
