import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../atoms/Button';
import { CardBrand } from '../atoms/CardBrand';
import { Loader } from '../molecules/Loader';
import { Modal } from '../molecules/Modal';
import { DefaultPaymentAddModal } from './DefaultPaymentAddModal';

import {
  StripePaymentMethodFragment,
  useBuyTicketMutation,
  useGetPaymentMethodsQuery,
} from '../../gen/graphql';
import { useToastsContext } from '../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { Payment } from '../../const/Payment';

import {
  SPOT_LESSON_TAX_EXCLUDED_LOCALED_PRICE,
  SPOT_LESSON_TAX_INCLUDED_LOCALED_PRICE,
} from '../../const';
import { getApiErrorMessage } from '../../utils/graphqlError';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void; // 購入完了時に親コンポーネントのデータ再取得などをするためのコールバック
  loading?: boolean;
}

export const BuyTicketModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onComplete,
  ...props
}): JSX.Element => {
  const [buyTicketMutation] = useBuyTicketMutation();
  const { showToast } = useToastsContext();

  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data, refetch } = useGetPaymentMethodsQuery({
    variables: {
      limit: Payment.MAX_ITEM_NUMBER,
    },
  });
  const paymentMethods = useMemo(() => data?.paymentMethods.items ?? [], [data]);

  // 支払い方法取得
  const fetchPaymentMethods = useCallback(async (): Promise<void> => {
    refetch();
  }, [refetch]);

  const defaultPaymentMethod = useMemo((): StripePaymentMethodFragment | null => {
    if (paymentMethods.length <= 0) return null;
    return paymentMethods.find((paymentMethod) => paymentMethod.isDefault) ?? null;
  }, [paymentMethods]);

  const handleClose = useCallback((): void => {
    // 支払い方法選択モーダルが開いている状態、ローディング状態でモーダル外をクリックした時に閉じないようにする
    if (isOpenPaymentModal || loading) {
      return;
    }
    onClose();
  }, [isOpenPaymentModal, loading, onClose]);

  const handleClickBuy = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setLoading(true);
      try {
        await buyTicketMutation();
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, getApiErrorMessage(e));
        return;
      } finally {
        setLoading(false);
      }

      showToast(0, 'チケットの購入が完了しました');
      onComplete();
      onClose();
    }, [buyTicketMutation, onClose, onComplete, showToast]),
  );

  return (
    <>
      <Loader display={loading} />
      <Modal
        underlayer
        isOpen={isOpen}
        onClose={handleClose}
        loading={props.loading}
        width={'37.5rem'}
        header={<Title>レッスンチケットの購入</Title>}
      >
        <Container>
          <Label>金額</Label>
          <Amount>
            {SPOT_LESSON_TAX_EXCLUDED_LOCALED_PRICE}
            <span className="unit">円 / 枚</span>
            <span className="tax">(税込 {SPOT_LESSON_TAX_INCLUDED_LOCALED_PRICE}円)</span>
          </Amount>
          <Label>ご請求先</Label>
          <PaymentMethod>
            <PaymentMethodText>クレジットカード決済</PaymentMethodText>
            <Card>
              {defaultPaymentMethod && (
                <>
                  <CardBrandWrapper>
                    <CardBrand brand={defaultPaymentMethod.cardBrand} />
                  </CardBrandWrapper>
                  <CardNumber>下4桁 {defaultPaymentMethod.cardNumber} 一括払い</CardNumber>
                </>
              )}
              <PaymentMethodButton onClick={() => setIsOpenPaymentModal(true)}>
                {defaultPaymentMethod ? '変更' : '追加'}
              </PaymentMethodButton>
            </Card>
          </PaymentMethod>
          <BuyButton onClick={handleClickBuy} disabled={!defaultPaymentMethod}>
            チケットを購入する
          </BuyButton>
          <Notion>
            ※「特定商取引法に基づく表記」は
            <a href="https://www.sejuku.net/corp/legal" target="_blank">
              こちら
            </a>
            をご覧ください。
          </Notion>
        </Container>
      </Modal>
      <DefaultPaymentAddModal
        isOpen={isOpenPaymentModal}
        toggle={setIsOpenPaymentModal}
        fetchPayments={fetchPaymentMethods}
        cards={paymentMethods}
        currentDefaultPaymentId={defaultPaymentMethod?.id ?? ''}
        setLoading={setLoading}
      />
    </>
  );
};

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 auto;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  margin: 0 auto;
  padding: 1.5rem 1rem 2rem;

  ${media.lessThan('small')`
    padding: 2rem 1rem 2.5rem;
  `}
`;

const Label = styled.p`
  padding: 0.375rem 1rem;
  width: 100%;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;
  background: #f5f5f5;
  box-sizing: border-box;

  ${media.lessThan('small')`
    padding: 0.5rem 1rem;
  `}
`;

const Amount = styled.p`
  padding: 0.625rem 0 1rem;
  color: #eb0000;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.47;

  .unit {
    font-size: 1rem;
  }

  .tax {
    margin-left: 0.625rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: #000000;
  }

  ${media.lessThan('small')`
    padding: 1.5rem 0 2rem;
  `}
`;

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1.5rem 1.125rem;
  width: 100%;
  box-sizing: border-box;

  ${media.lessThan('small')`
    padding: 1.5rem 0 2.125rem 0.875rem;
  `}
`;

const PaymentMethodText = styled.p`
  display: none;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;

  ${media.lessThan('small')`
    display: block;
  `}
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const CardBrandWrapper = styled.div`
  width: 2.5rem;
  height: 2rem;
  margin-right: 1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.lessThan('small')`
    display: none;
  `}
`;

const CardNumber = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
`;

const PaymentMethodButton = styled(Button)`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  padding: 0.25rem 1rem;
  background: #ffffff;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #eb0000;
`;

const BuyButton = styled(Button)<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 2.75rem;
  background: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.36)' : '#eb0000')};
  font-weight: 700;
  font-size: 0.75rem;
  color: #ffffff;
  border-radius: 0.1875rem;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const Notion = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 0.5rem;

  ${media.lessThan('medium')`
    font-size: 0.625rem;
    margin-top: 0.75rem;
  `}

  a {
    font-size: inherit;
    color: #e2001b;
  }
`;
