import React from 'react';
import styled from 'styled-components';
import { RadioOption } from './Radio';

interface FilterInputRadioProps {
  name: string;
  options: readonly RadioOption[];
  currentValue: string;
  handleSelect: (value: string) => void;
  column?: number;
}

export const FilterInputRadio: React.FC<FilterInputRadioProps> = (props) => {
  return (
    <Radio column={props.column}>
      {props.options.map((option) => {
        return (
          <RadioLabel
            key={option.value}
            active={props.currentValue === option.value}
            column={props.column}
          >
            <RadioInput
              type="radio"
              name={props.name}
              value={option.value}
              onChange={() => props.handleSelect(option.value)}
              checked={props.currentValue === option.value}
            />
            {option.label}
          </RadioLabel>
        );
      })}
    </Radio>
  );
};

const Radio = styled.div<{ column?: number }>`
  display: flex;
  width: 100%;

  ${(props) =>
    !!props.column &&
    `
    flex-wrap: wrap;
  `}
`;

const RadioLabel = styled.label<{ active: boolean; column?: number }>`
  display: flex;
  justify-content: center;
  padding: 0.625rem 0.75rem;
  background: #fff;
  border: 1px solid #dbdbdb;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  width: 100%;

  &:before {
    content: '';
    display: block;
  }

  ${(props) =>
    !!props.column &&
    `
    width: calc(100% / ${props.column});
    box-sizing: border-box;
  `}

  ${(props) =>
    props.active
      ? `
    background: #fff5f5;
    border-color: #eb0000;
    color: #eb0000;

    &:before {
      content: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2211%22%20height%3D%229%22%20viewBox%3D%220%200%2011%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1.5%204.09194L4.06932%207L9.5%201%22%20stroke%3D%22%23EB0000%22%20strokeWidth%3D%222%22%2F%3E%3C%2Fsvg%3E');
      margin-right: 0.5rem;
    }
    `
      : ''}
`;
const RadioInput = styled.input`
  display: none;
`;
