import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Z_INDEX_FAB } from '../../../../const/ZIndex';

interface Props {
  onClickCV: () => void;
}

export const FloatBanner: React.FC<Props> = (props) => {
  const [isActive, setIsActive] = useState(false);
  const isRunning = useRef(false);

  const isScrollToggle = useCallback(() => {
    if (isRunning.current) return;
    isRunning.current = true;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    requestAnimationFrame(() => {
      if (scrollTop > 100) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
      isRunning.current = false;
    });
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', isScrollToggle);
  });

  return (
    <Container active={isActive} data-gtm="floatBanner">
      <RegisterLink onClick={props.onClickCV}>
        <span className="small">最短10秒で登録完了</span>
        <span className="large">無料で侍テラコヤを始める</span>
      </RegisterLink>
    </Container>
  );
};

const Container = styled.div<{ active: boolean }>`
  width: 100%;
  padding: 0.625rem 1rem 0.875rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 -0.125rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.25rem);
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${Z_INDEX_FAB};
  transition: all 0.2s;

  ${media.lessThan('medium')`
    padding: 0.625rem 1rem;
  `}

  ${(props) =>
    props.active
      ? media.lessThan('medium')`
      transform: translateY(0%);
    `
      : media.lessThan('medium')`
      transform: translateY(100%);
    `}
`;
const RegisterLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 440px;
  margin: 0 auto;
  padding: 0.8125rem 0;
  background: linear-gradient(95.07deg, #fd8258 11.86%, #fd3c2f 82.5%);
  border-radius: 0.25rem;
  cursor: pointer;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;

  .small {
    margin-right: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
  }

  .large {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
  }

  ${media.lessThan('medium')`
    max-width: 360px;
    padding: .625rem 0;
    box-shadow: none;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;

    .small {
      display: none;
    }

    .large {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  `}
`;
