import React from 'react';
import { format, parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';
import media from 'styled-media-query';
import styled from 'styled-components';
import { NotificationSessionFragment } from '../../gen/graphql';

interface NotificationSessionProps {
  session: NotificationSessionFragment;
}

export const NotificationSession: React.FC<NotificationSessionProps> = (props) => {
  return (
    <Container>
      <DateTime>
        <Date>
          <span>{format(parseISO(props.session.startAt), 'MM月dd日')}</span>(
          {format(parseISO(props.session.startAt), 'E', { locale: ja })})
        </Date>
        <Time>
          {format(parseISO(props.session.startAt), 'kk:mm')} ~{' '}
          {format(parseISO(props.session.endAt), 'kk:mm')}
        </Time>
      </DateTime>
      <Title>{props.session.title}</Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

const DateTime = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: fit-content;
  min-width: 144px;
  padding: 1rem;
  box-sizing: border-box;
  align-item: center;
  justify-content: center;
  background-color: #fae9e2;
  word-break: break-all;

  ${media.lessThan('medium')`
    flex-direction: row;
    justify-content: space-between;
    align-item: center;
    width: 100%;
  `}
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ee4646;

  span {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

const Time = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ee4646;
  font-size: 0.8rem;
  font-weight: 500;

  ${media.lessThan('medium')`
    font-size: 1.0rem;
    font-weight: 400;
  `}
`;

const Title = styled.div`
  display: flex;
  padding: 1rem;
  box-sizing: border-box;
  font-size: 1.2rem;
  align-items: center;
  font-weight: 600;
  word-break: break-all;
`;
