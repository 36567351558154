import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Ttl } from '../../atoms/LpTtl';
import { Campaign } from './Campaign';
import { AdFvContent } from '../../molecules/lp/AdFvContent';
import { SubscriptionFvContent } from '../../molecules/lp/SubscriptionFvContent';

import AboutBefore from '../../../static/image/lp/sv_before_pc.png';
import AboutAfter from '../../../static/image/lp/sv_after_pc.png';
import AboutAfterSp from '../../../static/image/lp/sv_after_sp.png';
import AboutBg from '../../../static/image/lp/sv_bg_pc.png';
import AboutBgSp from '../../../static/image/lp/sv_bg_sp.png';
import AboutIcon001 from '../../../static/image/lp/sv001_pc.png';
import AboutIcon002 from '../../../static/image/lp/sv002_pc.png';
import BannerPc from '../../../static/image/lp/fv_banner_pc.jpg';
import BannerSp from '../../../static/image/lp/fv_banner_sp.jpg';

import { LIGHT_TAX_INCLUDED_LOCALED_PRICE } from '../../../const';
import { SERVICE_NAME } from '../../../const/Service';

interface Props {
  isResigning?: boolean;
  subscription?: boolean;
  onClickCV: () => void;
}

export const LpFv: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {props.subscription ? (
        <SubscriptionFvContent onClickCV={props.onClickCV} />
      ) : (
        <AdFvContent onClickCV={props.onClickCV} />
      )}

      <Campaign onClickCV={props.onClickCV} subscription={props.subscription} />

      {!props.isResigning && (
        <>
          <Banner>
            <img className="pc" src={BannerPc} alt="" />
            <img className="sp" src={BannerSp} alt="" />
            <p>
              ※全額返金保証の条件は
              <a
                href="https://intercom.help/plus---samurai/ja/articles/6254049-q-%E5%85%A8%E9%A1%8D%E8%BF%94%E9%87%91%E4%BF%9D%E8%A8%BC%E3%81%AE%E5%AF%BE%E8%B1%A1%E6%9D%A1%E4%BB%B6%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
                target="_blank"
              >
                こちら
              </a>
            </p>
          </Banner>
          <About>
            <AboutInner>
              <StyledTtl>
                <h2>{SERVICE_NAME}とは？</h2>
                <p>About</p>
              </StyledTtl>
              <AboutText>質問も、教材も、レッスンも、仲間も。</AboutText>
              <AboutText>オールインワンで、月定額{LIGHT_TAX_INCLUDED_LOCALED_PRICE}円~。</AboutText>
            </AboutInner>
            <Icon001 src={AboutIcon001} />
            <Icon002 src={AboutIcon002} />
          </About>
        </>
      )}
    </React.Fragment>
  );
};

const Banner = styled.div`
  padding: 0 1.5rem 3rem;
  background: #fff6f6;

  img {
    display: block;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    &.sp {
      display: none;
    }
  }

  p {
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.75rem;
    text-align: center;

    a {
      color: #eb0000;
      font-size: 1em;
    }
  }

  ${media.lessThan('medium')`
    padding: 0 1rem 2rem;
  `}

  ${media.lessThan('small')`
    img {
      &.pc {
        display: none;
      }

      &.sp {
        display: block;
      }
    }
  `}
`;

const About = styled.div`
  padding: 10rem 0;
  background: #fff0f0;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 98px;
    background: url(${AboutBefore}) bottom center / 100% 100% no-repeat;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 97px;
    background: url(${AboutAfter}) bottom center / 100% 100% no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  ${media.lessThan('large')`
    &:before,
    &:after {
      width: 1440px;
      left: calc(50% - 770px);
    }
  `}

  ${media.lessThan('medium')`
    padding: calc(11vw + 2rem) 0 30vw;

    &:before {
      width: 200%;
      height: 11vw;
      left: -50%;
    }

    &:after {
      height: 6vw;
    }
  `}

  ${media.lessThan('small')`
    padding: calc(6.5625vw + 2rem) 0 calc(5.75rem + 6.5625vw);

    &:after {
      width: 100%;
      height: 6.5625vw;
      background-image: url(${AboutAfterSp});
      left: 0;
    }
  `}
`;
const StyledTtl = styled(Ttl)`
  position: relative;

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
  `}
`;
const AboutText = styled.p`
  margin-top: 1.5rem;
  font-size: 1.375rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  z-index: 2;

  ${media.lessThan('medium')`
    margin-top: 0.5rem;
    font-size: 1rem;
  `}
`;
const AboutInner = styled.div`
  &:before {
    content: '';
    display: block;
    width: 100%;
    max-width: 1098px;
    height: 100%;
    margin: auto;
    background: url(${AboutBg}) center / contain no-repeat;
    position: absolute;
    top: -1.5rem;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  ${AboutText} + ${AboutText} {
    margin-top: 1.5rem;
  }

  ${media.lessThan('medium')`
    &:before {
      height: 42.1875vw;
      background: url(${AboutBgSp}) center / cover no-repeat;
      top: auto;
      bottom: 0;
    }

    ${AboutText} + ${AboutText} {
      margin-top: 1.5rem;
    }
  `}
`;
const AboutIcon = styled.img`
  display: block;
  position: absolute;
  bottom: 0;
`;
const Icon001 = styled(AboutIcon)`
  width: 240px;
  bottom: 35px;
  left: calc(50% - 560px);

  ${media.lessThan('large')`
    bottom: 7px;
    left: calc(50% - 440px);
  `}

  ${media.lessThan('medium')`
    width: 33vw;
    left: -5px;
    bottom: -25px;
  `}

  ${media.lessThan('small')`
    width: 120px;
    left: 0;
    bottom: -18px;
  `}
`;
const Icon002 = styled(AboutIcon)`
  width: 153px;
  bottom: 58px;
  right: calc(50% - 480px);

  ${media.lessThan('large')`
    bottom: 70px;
    right: calc(50% - 400px);
  `}

  ${media.lessThan('medium')`
    width: 23vw;
    right: 24px;
    bottom: 0;
  `}

  ${media.lessThan('small')`
    width: 92px;
    bottom: -8px;
  `}
`;
