import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RegisterTeamSubscriptionToTeamAdminModal: React.FC<ModalProps> = (props) => {
  const contentRef = React.useRef<HTMLDivElement>(null);

  return props.isOpen ? (
    <Modal
      onClose={props.onClose}
      isOpen={props.isOpen}
      width="576px"
      header={<Title>現在ご利用いただけません。</Title>}
      footer={
        <Buttons>
          <Button onClick={props.onClose}>閉じる</Button>
        </Buttons>
      }
    >
      <Container ref={contentRef}>
        <p>
          この機能は、法人管理者による決済が完了していないため、現在ご利用いただけません。
          <br />
          <br />
          法人管理者による決済が完了するまでお待ちいただくか、
          <br className="pc" />
          法人管理者に決済状況の確認をお願いいたします。
        </p>
      </Container>
    </Modal>
  ) : (
    <React.Fragment />
  );
};

const Container = styled.div`
  max-width: 464px;
  margin: 0 auto;
  padding: 2.5rem 1rem 3rem;
  box-sizing: border-box;

  p {
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
  }

  ${media.lessThan('medium')`
    padding: 1rem 1rem 1.5rem;

    p {
      font-size: .875rem;
      line-height: 1.7
    }

    .pc {
      display: none;
    }
  `}
`;

const Title = styled.h3`
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
