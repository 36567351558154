import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { pipe } from 'fp-ts/pipeable';
import { fromNullable, getOrElse } from 'fp-ts/Option';

import { RankingUserCard } from '../molecules/RankingUserCard';

import {
  StudySummaryListFragment,
  StudyFavoriteSummaryFragment,
  StudyTagSummaryListFragment,
} from '../../gen/graphql';

interface Props {
  title: string;
  type?: string;
  switch: (range: string) => void;
  studySummary: StudySummaryListFragment[];
  studyFavoriteSummary: StudyFavoriteSummaryFragment[];
  studyTagSummaryList: StudyTagSummaryListFragment[];
}

export const RankingCard: React.FC<Props> = (props) => {
  const getOrElse0 = (value: number | undefined | null) => {
    return pipe(
      fromNullable(value),
      getOrElse<number>(() => {
        return 0;
      }),
    );
  };

  const [switchState, setSwitchState] = useState('week');

  const setState = (range: string) => {
    setSwitchState(range);
    props.switch(range);
  };

  return (
    <RankCard>
      <Ttl>
        <H3>{props.title}</H3>
        <Switch>
          <SwitchItem active={switchState === 'week'} onClick={() => setState('week')}>
            週間
          </SwitchItem>
          <SwitchItem active={switchState === 'month'} onClick={() => setState('month')}>
            月間
          </SwitchItem>
          <SwitchItem active={switchState === 'sum'} onClick={() => setState('sum')}>
            合計
          </SwitchItem>
        </Switch>
      </Ttl>
      <Ranking>
        {
          // 学習時間
          props.type === 'time' && props.studySummary
            ? props.studySummary.map((time, i) => {
                return (
                  <RankingUserCard
                    key={i}
                    name={time?.user?.maskedPersonalInfo?.name}
                    id={time?.user?.id}
                    nickName={time?.user?.nickName}
                    image={time?.user?.image}
                    isInstructor={time?.user?.isInstructor}
                    isStudent={time?.user?.isStudent}
                    num={
                      switchState === 'week'
                        ? Math.floor(getOrElse0(time?.studyReportTimeWeek) / 60)
                        : switchState === 'month'
                          ? Math.floor(getOrElse0(time?.studyReportTimeMonth) / 60)
                          : Math.floor(getOrElse0(time?.studyReportTime) / 60)
                    }
                    type={props.type}
                  />
                );
              })
            : // いいね数
              props.type === 'like' && props.studyFavoriteSummary
              ? props.studyFavoriteSummary.map((like, i) => {
                  return (
                    <RankingUserCard
                      key={i}
                      id={like?.user?.id}
                      name={like?.user?.maskedPersonalInfo?.name}
                      nickName={like?.user?.nickName}
                      image={like?.user?.image}
                      isInstructor={like?.user?.isInstructor}
                      isStudent={like?.user?.isStudent}
                      num={
                        switchState === 'week'
                          ? like?.studyFavoriteCountWeek
                          : switchState === 'month'
                            ? like?.studyFavoriteCountMonth
                            : like?.studyFavoriteCount
                      }
                      type={props.type}
                    />
                  );
                })
              : // 人気言語
                props.type === 'language' && props.studyTagSummaryList
                ? props.studyTagSummaryList.map((language, i) => {
                    return (
                      <RankingUserCard
                        key={i}
                        name={language?.tag?.name}
                        nickName={''}
                        image={language?.tag?.iconImage}
                        isInstructor={null}
                        isStudent={null}
                        num={null}
                        type={props.type}
                      />
                    );
                  })
                : ''
        }
      </Ranking>
    </RankCard>
  );
};

const RankCard = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem 1rem 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const Ttl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const H3 = styled.h3`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: bold;
`;
const Switch = styled.ul`
  display: flex;
`;
const SwitchItem = styled.li<{ active?: boolean }>`
  font-size: 0.75rem;
  line-height: 1.25;

  ${(props) =>
    props.active
      ? `
    color: rgba(0,0,0,0.87);
    font-weight: 700;
    cursor: default;
  `
      : `
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    cursor: pointer;
  `}

  &:nth-of-type(n + 2) {
    margin-left: 0.75rem;
  }
`;
const Ranking = styled.ul`
  counter-reset: rankingNum;
  margin-top: 1rem;
`;
