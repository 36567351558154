import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { AnsweredOptions, SearchQuestionStatusOptions, SortKeyOptions } from '../../const/Question';
import {
  ChapterSearchFragment,
  CountSearchInput,
  PracticeSearchFragment,
  ProgramSearchFragment,
  QuestionSortKey,
  TagFragment,
  useGetCountByTagsLazyQuery,
  SearchQuestionStatus,
} from '../../gen/graphql';
import { Input } from '../atoms/Input';
import SearchIcon from '../../static/image/icon_search.svg';
import { ListItem } from './ListItem';
import { FilterRadio } from '../molecules/FilterRadio';
import { FilterSelect } from '../molecules/FilterSelect';
import { FilterInputLabel } from '../atoms/FilterInputLabel';
import { FilterInputSelect } from '../atoms/FilterInputSelect';
import {
  selectChapterOrPracticeOptionValue,
  getChapterFilterOptionLabel,
  getChapterFilterOptionValue,
  getPracticeFilterOptionLabel,
  getPracticeFilterOptionValue,
} from '../../utils/Filter';

export interface QuestionFilterProps {
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  title?: string;
  displaySortKey?: {
    sortKey: string;
    handleSortKeySelect: (value: string) => void;
  };
  displayAnswered: {
    answered: number;
    handleAnsweredSelect: (value: string) => void;
  };
  displaySolved: {
    solved: number;
    handleSolvedSelect: (value: string) => void;
  };
  displaySearchQuestionStatus: {
    searchQuestionStatus: SearchQuestionStatus;
    handleSearchQuestionStatusSelect: (value: string) => void;
  };
  displayProgram?: {
    programs: ProgramSearchFragment[];
    programID: number;
    handleProgramIDSelect: (value: string) => void;
  };
  displayChapterOrPractice?: {
    chapters: ChapterSearchFragment[];
    chapterID: number;
    practices: PracticeSearchFragment[];
    practiceID: string;
    handleChapterIDOrPracticeIDSelect: (value: string) => void;
  };
  displayTag: {
    searchTag: string;
    handleSearchTagSet: (value: string) => void;
    tags: TagFragment[];
    tagID: number;
    handleTagReset: () => void;
    handleTagIDSelect: (value: number) => void;
  };
  /* 検索用 */
  search?: boolean;
  hideQuestion?: boolean;
  className?: string;
  searchQuery?: CountSearchInput;
}

export const QuestionFilter: React.FC<QuestionFilterProps> = (props) => {
  const handleSortKeySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.displaySortKey?.handleSortKeySelect(e.target.value);
  };

  const handleAnsweredSelect = (value: string) => {
    props.displayAnswered.handleAnsweredSelect(value);
  };

  const handleSearchQuestionStatusSelect = (value: string) => {
    props.displaySearchQuestionStatus.handleSearchQuestionStatusSelect(value);
  };

  const handleProgramIDSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!props.displayProgram) return;
    props.displayProgram.handleProgramIDSelect(e.target.value);
  };

  const handleChapterIDOrPracticeIDSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!props.displayChapterOrPractice) return;
    props.displayChapterOrPractice.handleChapterIDOrPracticeIDSelect(e.target.value);
  };

  const handleSearchTagSet = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.displayTag.handleSearchTagSet(e.target.value);
  };

  const handleTagReset = () => {
    props.displayTag.handleTagReset();
  };

  const handleTagIDSelect = (value: number) => {
    props.displayTag.handleTagIDSelect(value);
  };

  const [getCountByTags, { data: searchTagsData }] = useGetCountByTagsLazyQuery();
  const fetchCountByTags = useCallback(
    (input: CountSearchInput, tagIds: number[]) => {
      getCountByTags({
        variables: {
          input: input,
          tagIDs: tagIds,
        },
      });
    },
    [getCountByTags],
  );

  useEffect(() => {
    if (!props.searchQuery) return;

    const tagIds = props.displayTag.tags.map((item) => item.id);
    fetchCountByTags(props.searchQuery, tagIds);
  }, [props.displayTag.tags, fetchCountByTags, props.searchQuery]);

  return (
    <FilterContainer className={props.className}>
      {!props.search && (
        <>
          <FilterTitle>ソート</FilterTitle>
          <FilterInputContainer>
            <FilterSelect
              name="sortKey"
              options={SortKeyOptions}
              currentValue={props.displaySortKey?.sortKey ?? QuestionSortKey.CreatedAt}
              handleSelect={handleSortKeySelect}
              active={true}
            />
          </FilterInputContainer>
        </>
      )}
      <FilterTitle>{props.title}</FilterTitle>
      {!props.hideQuestion && (
        <>
          {
            <FilterInputContainer>
              <FilterRadio
                label="ステータス"
                name="searchQuestionStatus"
                options={SearchQuestionStatusOptions}
                currentValue={props.displaySearchQuestionStatus.searchQuestionStatus.toString()}
                handleSelect={handleSearchQuestionStatusSelect}
                column={2}
              />
            </FilterInputContainer>
          }
          <FilterInputContainer>
            <FilterRadio
              label="回答有無"
              name="answered"
              options={AnsweredOptions}
              currentValue={props.displayAnswered.answered.toString()}
              handleSelect={handleAnsweredSelect}
            />
          </FilterInputContainer>
        </>
      )}
      {(props.displayProgram || props.displayChapterOrPractice) && (
        <FilterInputContainer>
          <SelectContainer>
            <FilterInputLabel label={props.displayProgram ? '教材' : '章'} />
            {props.displayProgram && (
              <FilterInputSelect
                name="program"
                options={[
                  { name: '教材を選択', value: '0' },
                  ...props.displayProgram.programs.map((program) => {
                    return { name: program.title, value: program.id.toString() };
                  }),
                ]}
                currentValue={props.displayProgram.programID.toString()}
                handleSelect={handleProgramIDSelect}
                active={props.displayProgram.programID !== 0}
              />
            )}
            {props.displayChapterOrPractice && (
              <FilterInputSelect
                name="chapter_or_practice"
                options={[
                  { name: '章または課題を選択', value: '0' },
                  ...props.displayChapterOrPractice.chapters.map((chapter) => {
                    return {
                      name: getChapterFilterOptionLabel(chapter.title),
                      value: getChapterFilterOptionValue(chapter.id.toString()),
                    };
                  }),
                  ...props.displayChapterOrPractice.practices.map((practice) => {
                    return {
                      name: getPracticeFilterOptionLabel(practice.title),
                      value: getPracticeFilterOptionValue(practice.id),
                    };
                  }),
                ]}
                currentValue={selectChapterOrPracticeOptionValue(
                  props.displayChapterOrPractice.chapterID.toString(),
                  props.displayChapterOrPractice.practiceID,
                )}
                handleSelect={handleChapterIDOrPracticeIDSelect}
                active={
                  props.displayChapterOrPractice.chapterID !== 0 ||
                  props.displayChapterOrPractice.practiceID !== ''
                }
                disabled={props.displayProgram && props.displayProgram.programID === 0}
              />
            )}
          </SelectContainer>
        </FilterInputContainer>
      )}
      <FilterInputContainer>
        <FilterInputLabel
          label={'タグ'}
          deselection={{
            active: props.displayTag.tagID !== 0,
            handleReset: handleTagReset,
          }}
        />
        <SearchTag
          name="searchTag"
          value={props.displayTag.searchTag}
          type="text"
          placeholder="キーワードを検索"
          leftImg={SearchIcon}
          onChange={handleSearchTagSet}
        />
        <ListContainer>
          {props.search
            ? searchTagsData?.countByTags.map((tag) => {
                return (
                  <ListItem
                    key={tag.tagID}
                    id={tag.tagID}
                    label={tag.tag?.name ?? ''}
                    count={tag.count}
                    selected={tag.tagID === props.displayTag.tagID}
                    onClick={() => handleTagIDSelect(tag.tagID)}
                  />
                );
              })
            : props.displayTag.tags.map((tag): JSX.Element => {
                return (
                  <ListItem
                    key={tag.id}
                    id={tag.id}
                    label={tag.name}
                    count={tag.questionCount ? tag.questionCount : 0}
                    selected={tag.id === props.displayTag.tagID}
                    onClick={() => handleTagIDSelect(tag.id)}
                  />
                );
              })}
        </ListContainer>
      </FilterInputContainer>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  box-sizing: border-box;
  padding: 1rem;
  overflow-y: auto;
`;

const FilterTitle = styled.h3`
  margin-top: 0.875rem;
  position: relative;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.089px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const SearchTag = styled(Input)`
  margin-bottom: 0.5rem !important;

  input {
    font-size: 0.8125rem;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  .label {
    margin-right: 0.625rem;
    font-size: 0.75rem;
  }

  select {
    font-size: 0.8125rem;
  }

  & > select:nth-of-type(n + 2) {
    margin-top: 0.625rem;
  }
`;

const FilterInputContainer = styled.div`
  margin-top: 0.875rem;
`;

const ListContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin: 0.5rem 0 1rem;

  & > * {
    width: 49%;
  }
`;
