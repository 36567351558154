import React from 'react';
import { Helmet } from 'react-helmet';
import { SERVICE_NAME_FULL, DEFAULT_META_DESCRIPTION, DEFAULT_META_TITLE } from '../const/Service';
import { HeadTagContext } from '../context/HeadTagProvider';

export const JSONLD: React.FC = () => {
  const headTagContext = React.useContext(HeadTagContext);
  const jsonLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: '株式会社SAMURAI',
      founder: '木内 翔大',
      foundingDate: 'March,2015',
      description:
        '株式会社SAMURAIは、IT教育で世界平和を実現する会社です。 私たちの使命は、SDGsの5つの問題を解決するために事業を運営し、貧困や不平等を無くして社会をすこしずつ優しくしていくことです。  生産的で働きがいのある雇用を生み出すためには、私たちの組織も生産的で働きがいのある企業となる必要があると考えています。',
      url: 'https://www.sejuku.net/corp/',
      logo: 'https://www.sejuku.net/corp/wp-content/themes/corp/assets/images/logo.svg',
      telephone: '03-5790-9039',
      address: {
        '@type': 'PostalAddress',
        addressLocality: '港区',
        addressRegion: '東京都',
        postalCode: '105-0001',
        streetAddress: '虎ノ門一丁目3番1号　東京虎ノ門グローバルスクエア17階',
        addressCountry: 'JP',
      },
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '03-5790-9039',
          contactType: 'CustomerSupport',
        },
      ],
      sameAs: 'https://www.sejuku.net/',
    },
    {
      '@context': 'http://schema.org',
      '@type': 'website',
      name: `${DEFAULT_META_TITLE}`,
      inLanguage: 'jp',
      publisher: {
        '@type': 'Organization',
        name: '株式会社SAMURAI',
        logo: {
          '@type': 'ImageObject',
          url: 'https://terakoya.sejuku.net/static/media/logo.efe3ff53.svg',
        },
      },
      headline: SERVICE_NAME_FULL,
      description: DEFAULT_META_DESCRIPTION,
      url: 'https://terakoya.sejuku.net/',
    },
    {
      '@context': 'https://schema.org',
      '@type': 'SiteNavigationElement',
      hasPart: [
        {
          '@type': 'WebPage',
          name: 'トップ',
          url: 'https://terakoya.sejuku.net/',
        },
        {
          '@type': 'CollectionPage',
          name: 'サービスの特徴',
          url: 'https://terakoya.sejuku.net/#feature',
        },
        {
          '@type': 'CollectionPage',
          name: 'ご利用の流れ',
          url: 'https://terakoya.sejuku.net/#flow',
        },
        {
          '@type': 'CollectionPage',
          name: '料金プラン',
          url: 'https://terakoya.sejuku.net/#plan',
        },
        {
          '@type': 'ContactPage',
          name: 'お客様の声',
          url: 'https://terakoya.sejuku.net/#voice',
        },
      ],
    },
    {
      '@context': 'http://schema.org',
      '@type': 'FAQPage',
      description: 'サービスに関する質問をまとめています。',
      mainEntity: [
        {
          '@type': 'Question',
          name: 'マンツーマンのレッスンはありますか？',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'レッスンチケットを利用し、1回60分のマンツーマンのレッスンを受講することができます。',
            url: 'https://terakoya.sejuku.net/#faq',
          },
        },
        {
          '@type': 'Question',
          name: '質問の受付時間は何時から何時ですか？',
          acceptedAnswer: {
            '@type': 'Answer',
            text: '24時間いつでもお受けしております。 営業時間（10時〜22時）外の質問は、翌営業日に回答させていただきます。',
            url: 'https://terakoya.sejuku.net/#faq',
          },
        },
        {
          '@type': 'Question',
          name: 'Q&A掲示板では技術的な質問以外も質問できますか？',
          acceptedAnswer: {
            '@type': 'Answer',
            text: 'はい。質問可能です。 例えば、「学習を効率的に進めるにはどうしたらいいですか？」といった、抽象的な質問にも、現役エンジニアが回答致します。',
            url: 'https://terakoya.sejuku.net/#faq',
          },
        },
      ],
    },
  ];

  return (
    <Helmet title={headTagContext.title} meta={headTagContext.meta}>
      <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
    </Helmet>
  );
};
