import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format } from 'date-fns';
import * as E from 'fp-ts/lib/Either';
import * as O from 'fp-ts/Option';
import stripeJs from '@stripe/stripe-js';

import { useToastsContext } from '../../../context/ToastsProvider';

import { SubscriptionLayout } from '../../templates/SubscriptionLayout';
import { NotFoundPage } from '../public/NotFound';
import { Button } from '../../atoms/Button';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Loader } from '../../molecules/Loader';
import { PromotionCodeInput } from '../../molecules/PromotionCodeInput';
import { AddPaymentMethod } from '../../molecules/AddPaymentMethod';
import { DefaultPaymentAddModal } from '../../organisms/DefaultPaymentAddModal';

import IconLock from '../../../static/image/icon_lock_gray.svg';

import {
  StripePaymentMethodFragment,
  useApplyPromotionCodeLazyQuery,
  useSubscribeMutation,
  useGetPaymentMethodsQuery,
  useGetPriceQuery,
  useMySubscriptionContractsQuery,
  useGetCampaignQuery,
  useSelectPaymentMethodMutation,
  PaymentMethodType,
  SpCouponDuration,
  PromotionCodeApplyResultFragment,
} from '../../../gen/graphql';
import {
  CardCvcError,
  CardExpirationError,
  CardNumberError,
  LmsStripeValidationError,
  StripePaymentService,
  StripePaymentServiceImpl,
  StripeSystemError,
} from '../../../infrastructure/externalService/StripePaymentService';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { Payment } from '../../../const/Payment';
import { PRICE_ID_BASIC_PLAN } from '../../../const/Product';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { LOWER_META_TITLE } from '../../../const/Service';
import { CANCELLATION_FAQ_LINK } from '../../../const/Link';
import { sessionStorageSupport } from '../../../utils/sessionStorageSupport';
import { Box } from '../../atoms/Box';

type PaymentMethodItem = StripePaymentMethodFragment;

export const AccountSubscriptionPayment: React.FC = (): JSX.Element => {
  const metaTitle = `加入プラン | ${LOWER_META_TITLE}`;

  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = useToastsContext();

  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [promotionCode, setPromotionCode] = useState('');
  const [applyPromotionCode, setApplyPromotionCode] = useState('');

  const [isCheckedFrontValidation, setIsCheckedFrontValidation] = useState(false);
  const [cardNumberError, setCardNumberError] = useState('');
  const [expirationError, setExpirationError] = useState('');
  const [cvcError, setCvcError] = useState('');
  const [paymentID, setPaymentID] = useState<PaymentMethodItem['id'] | null>(null);
  const [currentPaymentID, setCurrentPaymentID] = useState<PaymentMethodItem['id'] | null>(null);
  const [elements, setElements] = useState<stripeJs.StripeElements | null>(null);
  const [stripe, setStripe] = useState<stripeJs.Stripe | null>(null);
  const [selectPaymentMethod] = useSelectPaymentMethodMutation();

  const stripeService: StripePaymentService = useMemo(() => {
    return new StripePaymentServiceImpl(stripe);
  }, [stripe]);

  const displayNewPayment = useCallback(() => {
    return !paymentID;
  }, [paymentID]);

  const { data: campaignData } = useGetCampaignQuery();

  const {
    data: getPaymentMethodsData,
    loading: getPaymentMethodsLoading,
    refetch: getPaymentMethodsRefetch,
  } = useGetPaymentMethodsQuery({
    variables: {
      limit: Payment.MAX_ITEM_NUMBER,
    },
    notifyOnNetworkStatusChange: true,
  });
  const paymentMethods = useMemo(
    () => getPaymentMethodsData?.paymentMethods.items ?? [],
    [getPaymentMethodsData],
  );

  const currentPaymentMethod = useMemo((): StripePaymentMethodFragment | null => {
    if (paymentMethods.length <= 0) return null;
    return paymentMethods.find((paymentMethod) => paymentMethod.isDefault) ?? null;
  }, [paymentMethods]);

  // 支払い方法が選択されてたら送信可能
  const canSubmit = () => {
    if (displayNewPayment()) {
      // 新規カード登録の場合はバリデーションクリアしてたら送信可能
      return isCheckedFrontValidation;
    } else {
      return currentPaymentMethod !== null;
    }
  };

  // queryパラメータからpriceIDを取得(ないor無効な場合は1:ベーシック)
  const priceID = useMemo((): number => {
    const val = new URLSearchParams(location.search).get('priceID');
    return val && !Number.isNaN(val) ? parseInt(val) : PRICE_ID_BASIC_PLAN;
  }, [location.search]);

  const { data: priceData, loading: priceLoading } = useGetPriceQuery({
    variables: {
      // 指定がなければBasicプラン
      id: priceID,
    },
  });
  const price = priceData?.getPrice;

  const [
    applyPromotionCodeQuery,
    { data: applyPromotionCodeData, error: applyPromotionCodeError },
  ] = useApplyPromotionCodeLazyQuery();
  const applyPromotionCodeInfo = applyPromotionCodeData?.applyPromotionCode;
  const promotionCodeInfo = applyPromotionCodeInfo ? 'クーポンコードが適用されました' : '';
  const promotionCodeError = applyPromotionCodeError
    ? getApiErrorMessage(
        applyPromotionCodeError,
        'このクーポンコードは利用できません。大文字と小文字は区別されるのでご注意ください。',
      )
    : '';

  // 登録後のアンケート送付用に初回契約かどうかの判定処理
  // 契約履歴が遅延更新のため登録前に契約有無のチェックを入れる
  const { loading: contractLoading, ...contractData } = useMySubscriptionContractsQuery({
    variables: {
      // 以前の契約履歴有無チェックのため最小ページで取得する
      input: {
        page: 1,
        limit: 1,
      },
    },
  });
  const isReContract = contractData.data?.mySubscriptionContracts?.total > 0;

  const [subscribe] = useSubscribeMutation();

  // 支払い方法取得
  const fetchPaymentMethods = useCallback(async (): Promise<void> => {
    getPaymentMethodsRefetch();
  }, [getPaymentMethodsRefetch]);

  // クレジットカードバリデーション初期化
  const initCreditInfoValidationMsg = () => {
    setCardNumberError('');
    setExpirationError('');
    setCvcError('');
  };

  // クレジットカードバリデーション
  const setCardValidationStripeError = (e: LmsStripeValidationError) => {
    if (e instanceof CardNumberError) {
      setCardNumberError(e.message);
    } else if (e instanceof CardExpirationError) {
      setExpirationError(e.message);
    } else if (e instanceof CardCvcError) {
      setCvcError(e.message);
    } else {
      setCvcError(defaultErrorMessage);
    }
  };

  const currentDefaultPaymentId = currentPaymentMethod?.id ?? '';

  useEffect(() => {
    setPaymentID(currentDefaultPaymentId);
  }, [currentDefaultPaymentId]);

  const selectedPayment = (item: PaymentMethodItem) => {
    return paymentID === item.id;
  };

  const changePaymentMethod = useSafeAsyncCallback(
    useCallback(
      async (providerID: string, clearElements: () => void): Promise<void> => {
        try {
          await selectPaymentMethod({
            variables: {
              providerID: providerID,
            },
          });
          setCurrentPaymentID(providerID);
          fetchPaymentMethods();
          showToast(0, '支払い方法の追加及びデフォルトの支払い方法が変更完了しました。');
          clearElements();
        } catch (e) {
          showToast(1, getApiErrorMessage(e));
        }
      },
      [selectPaymentMethod, fetchPaymentMethods, showToast],
    ),
  );

  const changeSelectPayment = useSafeAsyncCallback(
    useCallback(
      async (providerID: string): Promise<void> => {
        initCreditInfoValidationMsg();
        changePaymentMethod(providerID, initCreditInfoValidationMsg);
      },
      [changePaymentMethod],
    ),
  );

  const submitPaymentMethod = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      initCreditInfoValidationMsg();

      if (displayNewPayment()) {
        // 新規カードでの支払い変更
        const result = await stripeService.checkCreditCard(O.fromNullable(elements));

        if (E.isLeft(result)) {
          const e = result.left;
          if (!(e instanceof StripeSystemError)) {
            setCardValidationStripeError(e);
          }
          showToast(1, getApiErrorMessage(e));
        } else if (E.isRight(result)) {
          try {
            const paymentMethodID = result.right.paymentId;
            await selectPaymentMethod({
              variables: {
                providerID: paymentMethodID,
              },
            });
            setCurrentPaymentID(paymentMethodID);
          } catch (e) {
            showToast(1, getApiErrorMessage(e));
            return;
          } finally {
            initCreditInfoValidationMsg();
            fetchPaymentMethods();
          }
        }
      } else {
        //既存カードでの支払い変更
        if (!paymentID || paymentID === currentDefaultPaymentId) {
          return;
        }
        changeSelectPayment(paymentID);
      }
    }, [
      changeSelectPayment,
      currentDefaultPaymentId,
      displayNewPayment,
      elements,
      paymentID,
      stripeService,
      selectPaymentMethod,
      fetchPaymentMethods,
      showToast,
    ]),
  );

  // 送信
  const submit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!price) {
        return;
      }

      setShowLoader(true);

      // カード登録
      await submitPaymentMethod();

      // 決済処理
      try {
        await subscribe({
          variables: {
            input: {
              priceID: price.id,
              promotionCode: applyPromotionCode ? applyPromotionCode : undefined,
              providerPaymentMethodID: currentPaymentID,
              paymentMethodType: PaymentMethodType.CreditCard,
              timezoneOffset: new Date().getTimezoneOffset(),
            },
          },
        });
      } catch (e) {
        showToast(1, getApiErrorMessage(e));
        return;
      } finally {
        setShowLoader(false);
      }

      sessionStorageSupport.setItem('REGISTERED_TYPE', 'recharge');
      sessionStorageSupport.setItem('QUESTIONNAIRE', 'true');
      sessionStorageSupport.setItem('IS_RE_CONTRACT', isReContract.toString());
      navigate('/account/subscription/registered');
    }, [
      price,
      navigate,
      isReContract,
      submitPaymentMethod,
      subscribe,
      applyPromotionCode,
      currentPaymentID,
      showToast,
    ]),
  );

  // productが取得できないのはqueryパラメータがおかしい
  if (!price && !priceLoading) {
    return NotFoundPage;
  }

  const handleClickApply = async () => {
    if (!price) {
      return;
    }

    if (applyPromotionCode) setApplyPromotionCode('');

    await applyPromotionCodeQuery({
      variables: {
        promotionCode: promotionCode,
        priceID: price.id,
      },
    }).then((data) => {
      // 前回query実行時とvariablesの値が同じだとonCompletedの処理が反映されないためここで設定
      if (data.data) {
        setApplyPromotionCode(promotionCode);
      }
    });
  };

  const loading = showLoader || getPaymentMethodsLoading || priceLoading || contractLoading;

  return (
    <>
      <Loader display={loading} />
      <SubscriptionLayout metaTitle={metaTitle}>
        <Wrapper>
          <StyledPageWrapper>
            <Side>
              <SideTitle>お申し込みプラン</SideTitle>
              <PlanCard>
                <PlanCardName>{price?.product?.name}</PlanCardName>
                <PlanCardInner>
                  {applyPromotionCodeInfo ? (
                    <PlanCardDiscountPrice>
                      <p className="cost">
                        ¥{applyPromotionCodeInfo.price?.toLocaleString()}(税込)/
                        {price?.product?.productExtension.contractPeriodTypeLabel}
                      </p>
                      <PlanCardPrice>
                        <span>{getPvdDuration(applyPromotionCodeInfo)}</span>¥
                        {applyPromotionCodeInfo.appliedPrice?.toLocaleString()}
                        <span>(税込)</span>
                      </PlanCardPrice>
                    </PlanCardDiscountPrice>
                  ) : (
                    <PlanCardPrice>
                      ¥{price?.unitAmountIncludingTax.toLocaleString()}
                      <span>
                        (税込) / {price?.product?.productExtension.contractPeriodTypeLabel}
                      </span>
                    </PlanCardPrice>
                  )}
                  <PlanCardNote>
                    登録料{price?.product?.productExtension.contractPeriod === 1 && '・解約金'}
                    は一切かかりません
                  </PlanCardNote>
                </PlanCardInner>
              </PlanCard>
              {!!campaignData?.getCampaign && (
                <CampaignImage
                  src={campaignData.getCampaign.imageURL}
                  alt={campaignData.getCampaign.title}
                />
              )}
            </Side>
            <Container padding={[8, 15]} spPadding={[6, 4, 8]}>
              <Title>ご請求情報の入力</Title>
              <Description>入力いただいた情報は暗号化されて送信されます。</Description>

              <ContentTitle>支払い方法の変更</ContentTitle>
              <AddPaymentMethod
                cards={paymentMethods}
                currentDefaultPaymentId={currentPaymentMethod?.id ?? ''}
                setLoading={setShowLoader}
                fetchPayments={fetchPaymentMethods}
                setStripe={setStripe}
                setElements={setElements}
                setPaymentID={setPaymentID}
                selectedPayment={selectedPayment}
                displayNewPayment={displayNewPayment}
                setIsCheckedFrontValidation={setIsCheckedFrontValidation}
                cardNumberError={cardNumberError}
                expirationError={expirationError}
                cvcError={cvcError}
              />

              <ContentTitle>クーポンコード</ContentTitle>
              <PromotionCodeInput
                promotionCode={promotionCode}
                setPromotionCode={setPromotionCode}
                promotionCodeInfo={promotionCodeInfo}
                promotionCodeError={promotionCodeError}
                handleClickApply={handleClickApply}
                hiddenLabel
              />

              <SubscriptionNote>
                サブスクリプションは{format(new Date(), 'yyyy/M/d')}に開始され
                {price?.product?.productExtension.contractPeriod}ヶ
                {price?.product?.productExtension.contractPeriodTypeLabel}後に自動で更新されます。
                <br />
                自動更新は更新{price?.product?.productExtension.contractPeriodTypeLabel}
                にアカウント設定より無料でキャンセル可能です。
                {price?.product?.productExtension.isMultiMonthContract && (
                  <>
                    <br />
                    更新期間外のキャンセルでは解約手数料が発生しますのでご注意ください。
                  </>
                )}
              </SubscriptionNote>
              <LegalNote>
                ※「特定商取引法に基づく表記」は
                <a href="https://www.sejuku.net/corp/legal" target="_blank">
                  こちら
                </a>
                をご覧ください。
                <br />※ 支払時期については
                <a href="https://www.sejuku.net/corp/terakoya-agreement" target="_blank">
                  利用規約
                </a>
                をご覧ください。
                <br />※ 解約方法については
                <a href={CANCELLATION_FAQ_LINK} target="_blank">
                  こちら
                </a>
                をご覧ください。
              </LegalNote>

              <StyledSubmitButton onClick={submit} disabled={!canSubmit()}>
                決済を完了する
              </StyledSubmitButton>
            </Container>
          </StyledPageWrapper>
        </Wrapper>
      </SubscriptionLayout>
      <DefaultPaymentAddModal
        isOpen={paymentModalIsOpen}
        toggle={setPaymentModalIsOpen}
        fetchPayments={fetchPaymentMethods}
        cards={paymentMethods}
        currentDefaultPaymentId={currentPaymentMethod?.id ?? ''}
        setLoading={setShowLoader}
      />
    </>
  );
};

const getPvdDuration = (applyPromotionCodeInfo: PromotionCodeApplyResultFragment) => {
  switch (applyPromotionCodeInfo.pvdDuration) {
    case SpCouponDuration.Once:
      return '初回のみ！';
    case SpCouponDuration.Forever:
      return '永年！';
    case SpCouponDuration.Repeating:
      return applyPromotionCodeInfo.pvdDurationInMonths + 'ヶ月間！';
    default:
      return '';
  }
};

const Wrapper = styled.div`
  padding: 1.5rem 0;
  background-color: #fafafa;
`;
const StyledPageWrapper = styled(PageWrapper)`
  display: flex;
  gap: 1.25rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 2rem;
  `}
`;

const Side = styled.div`
  width: 19.125rem;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const SideTitle = styled.h2`
  color: #000;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25rem;

  ${media.lessThan('medium')`
    text-align: center;
  `}
`;
const PlanCard = styled.div`
  margin-top: 1rem;
  background: #fff;
  border: 0.125rem solid #eb0000;
  border-radius: 0.25rem;
  text-align: center;
`;
const PlanCardName = styled.h3`
  padding: 0.5rem 0.5rem 0.625rem;
  background: #eb0000;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.125rem;

  ${media.lessThan('medium')`
    padding: .5rem;
    line-height: 1.25rem;
  `}
`;
const PlanCardInner = styled.div`
  padding: 0.75rem 0.5rem;
`;
const PlanCardPrice = styled.p`
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.375rem;

  span {
    font-size: 1rem;
  }
`;
const PlanCardDiscountPrice = styled.div`
  .cost {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;
    text-decoration: line-through;
  }

  ${PlanCardPrice} {
    color: #eb0000;
    line-height: 2rem;

    &:before {
      content: '↓';
      display: block;
      color: #000;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.125rem;
    }
  }
`;
const PlanCardNote = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

const CampaignImage = styled.img`
  display: block;
  width: 100%;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`;

const Container = styled(Box)`
  flex: 1;

  ${media.lessThan('medium')`
    margin: 0 -1rem;
  `}
`;
const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
`;
const Description = styled.p`
  margin-top: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.25;

  &:after {
    content: '';
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url(${IconLock}) center / auto no-repeat;
    vertical-align: top;
  }
`;
const ContentTitle = styled.h3`
  margin: 1.5rem auto 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
`;
const SubscriptionNote = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.6;
`;
const LegalNote = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem;
  line-height: 1.5;

  a {
    color: #fd3c2f;
    font-size: 1em;
  }
`;
const StyledSubmitButton = styled(Button)`
  margin-top: 2rem;
`;
