import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { LpButton } from '../../atoms/LpButton';

import { LIGHT_TAX_INCLUDED_PRICE } from '../../../const';

import LpMovie from '../../../static/movie/lptop.mp4';
import FvAfter from '../../../static/image/lp/ad/fv_after_pc.png';

interface Props {
  onClickCV: () => void;
}

export const SubscriptionFvContent: React.FC<Props> = (props) => {
  return (
    <Section>
      <Wrapper>
        <Inner>
          <SubTitle>日本最安級！サブスク型オンラインITスクール</SubTitle>
          <Title>
            <span>教材・課題・Q&A・レッスン</span>など
            <br />
            すべての機能を使って挫折せずに
            <br />
            プログラミングスキルを習得！
          </Title>
          <Sup>累計45,000名以上の指導実績！</Sup>
          <Reason>
            <ReasonColumn>
              <h2>月額料金</h2>
              <p>
                {LIGHT_TAX_INCLUDED_PRICE}
                <span>円~</span>
                <small>(税込み)</small>
              </p>
            </ReasonColumn>
            <ReasonColumn>
              <h2>
                講師満足度<span>※1</span>
              </h2>
              <p>
                95<span>%</span>
              </p>
            </ReasonColumn>
            <ReasonColumn>
              <h2>
                挫折率<span>※2</span>
              </h2>
              <p>
                8<span>%</span>
              </p>
            </ReasonColumn>
          </Reason>
          <ButtonArea>
            <LpButton onClick={props.onClickCV} subscription />
            <Description>
              <span>(※1) 弊社提供のレッスン、セミナーオンラインコースなどのサービスを含む</span>
              <span>(※2) 弊社提供のマンツーマンレッスンの実績に基づくデータ</span>
            </Description>
          </ButtonArea>
        </Inner>
        <Inner>
          <Video src={LpMovie} loop autoPlay muted />
        </Inner>
      </Wrapper>
    </Section>
  );
};

const Section = styled.section`
  background-color: #fff9f9;
  padding: 1.75rem 2rem;
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 200px;
    background: url(${FvAfter}) center bottom / 100% 100% no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  ${media.lessThan('large')`
    &:after {
      width: 1440px;
      left: calc(50% - 770px);
    }
  `}

  ${media.lessThan('medium')`
    padding: 1rem;

    &:after {
      width: 300%;
      height: 18vw;
      left: -80%;
    }
  `}
`;
const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  ${media.lessThan('large')`
    flex-direction: column-reverse;
  `}

  ${media.lessThan('medium')`
    text-align: center;
  `}
`;

const Video = styled.video`
  width: 100%;
  ${media.lessThan('medium')`
    max-width: 340px;
`}
`;

const Inner = styled.div`
  padding: 1rem 0;
  max-width: 600px;
  display: inline-block;
  position: relative;
  z-index: 2;

  ${media.lessThan('medium')`
    text-align: left;
  `}
`;
const SubTitle = styled.p`
  color: #111;
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.75rem;

  ${media.lessThan('medium')`
    font-size: .75rem;
    line-height: 1.125rem;
  `}
`;
const Title = styled.h1`
  margin-top: 0.375rem;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 0.02em;

  span {
    display: inline-block;
    padding: 0 0.375rem;
    background: #eb0000;
    color: #fff;
    font-size: 1em;
    font-weight: 800;
  }

  ${media.lessThan('medium')`
    margin-top: .125rem;
    font-size: 1.375rem;
    line-height: 2rem;

    span {
      padding: 0 .25rem;
    }
  `}

  ${media.lessThan('small')`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 1.25rem;
    line-height: 1.75rem;

    br {
      display: none;
    }
  `}
`;
const Sup = styled.p`
  margin-top: 1.125rem;
  color: #111;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0.05em;
  text-align: center;

  span {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1;
  }

  ${media.lessThan('medium')`
    margin-top: 1.25rem;
    font-size: .75rem;
    line-height: .875rem;
  `}
`;
const Reason = styled.div`
  display: flex;
  gap: 1rem;
  max-width: 488px;
  margin: 0.875rem auto 0;

  ${media.lessThan('medium')`
    margin-top: .625rem;
  `}
`;
const ReasonColumn = styled.div`
  flex: 1;
  padding: 0.875rem 0 0.5rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.25rem 1rem rgba(111, 35, 35, 0.125);
  text-align: center;

  h2 {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.125rem;

    span {
      color: rgba(0, 0, 0, 0.36);
      font-size: 0.625rem;
      font-weight: 400;
      line-height: 1;
    }
  }

  p {
    margin-top: 0.375rem;
    color: #eb0000;
    font-size: 1.875rem;
    font-weight: 800;
    line-height: 1em;
    letter-spacing: -0.03em;

    span {
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.25rem;
    }

    small {
      display: block;
      margin-top: 0.125rem;
      color: rgba(0, 0, 0, 0.36);
      font-size: 0.625rem;
      font-weight: 700;
      line-height: 1;
    }
  }

  ${media.lessThan('medium')`
    h2 {
      font-size: .75rem;
      line-height: 1rem;
    }

    p {
      font-size: 1.25rem;

      span {
        font-size: .625rem;
        line-height: .875rem;
      }
    }
  `}
`;
const ButtonArea = styled.div`
  max-width: 392px;
  margin: 1.125rem auto 0;

  ${media.lessThan('medium')`
    margin-top: 1.25rem;
  `}
`;
const Description = styled.p`
  margin-top: 0.5rem;

  span {
    display: block;
    color: #111;
    font-size: 0.625rem;
    line-height: 0.875rem;
  }

  ${media.lessThan('medium')`
    margin-top: 1.125rem;
  `}
`;
