// 技術Q&AのカテゴリーID
import { RadioOption } from '../components/atoms/Radio';
import { SelectOption } from '../components/atoms/Select';
import { QuestionSortKey, SearchQuestionStatus } from '../gen/graphql';
import { ALL } from './Filter';
import { QuestionLabelType } from '../utils/Question';

export const ANSWERED = 1;
export const NO_ANSWER = 2;

export const SOLVED = 1;
export const UNSOLVED = 2;

export const TECHNICAL_QUESTION_CATEGORY = 1;
export const PROBLEM_QUESTION_CATEGORY = 2;

export const MAX_QUESTION_TITLE_LENGTH = 100;
export const MAX_QUESTION_CONTENT_LENGTH = 16383;

export const TECH_CONTENT_PRACE_HOLDER = `## 実現したいこと

ここに実現したいことを書いてください。 
以下の**例文は必ず削除** してください。

※例1 JavaScriptで〇〇の機能を実装中に以下に記載のエラーメッセージが発生しました。このエラーを解消したいです。
※例2 WordPress制作で副業の範囲で5万円を稼ぎたいのですが、どのようなロードマップで学習するのが良いか知りたい。
※例3 会員登録フォームで登録ボタンを押したときに認証メールが届くようにしたい。

## 試したことや書いたソースコード

ここに試したことやソースコードを書いてください。 

※例1. [ xx yyy ] とGoogleで検索したが全く答えに辿り付けなかった  
※例2. https://** を参考に zzz してみた
※例3. 以下のソースコードを書きました

\`\`\`ここに言語名を入力してください
ここに書いたソースコードを記載してください
\`\`\`

上記記載しない場合、**見出しごと必ず削除** してください。

## 起きている問題やエラーメッセージ 

\`\`\`
エラーメッセージをここに書いてください。
\`\`\`

上記記載しない場合、**見出しごと必ず削除** してください。


## その他の関連情報

使用言語・ライブラリのバージョン、関連のスクリーンショット、GitHubのリポジトリリンクなど、その他、解決に役立つと思われる関連情報を記載してください。

上記記載しない場合、**見出しごと必ず削除** してください。

`;

export const WORRY_CONTENT_PLACE_HOLDER = `
## 1. やりたいことや目的、概要

## 2. 何についての悩みや起きている問題か

## 3. 試したこと、または試そうと考えていること
`;

export const ANSWER_PLACE_HOLDER = `質問に対して別の回答をする場合、こちらに入力してください。
回答に対する返信の場合は、スレッドに対して「返信」をしてください。
Markdown方式で入力ができます。
`;

export const AI_ANSWER_PLACE_HOLDER = `例1）AI先生の回答ではerror- xxxxが出てしまい、前に進めません。
      今詰まっている情報を、以下に画像とコードを貼ったので、こちらを元にご回答をよろしくお願いします。

例2）AI先生の回答のCSSを試しましたが、本当は以下の参考画像のように表示させたいです。
     この場合、どのようにコーディングするのが適切でしょうか？
`;

export const COMMENT_PLACE_HOLDER = `コメントを入力してください
Markdown入力ができます
`;

export const SortKeyOptions: readonly SelectOption[] = [
  { value: QuestionSortKey.CreatedAt, name: '質問が新しい順' },
  { value: QuestionSortKey.ActionedAt, name: '更新日時が新しい順' },
  { value: QuestionSortKey.QuestionFavoriteCount, name: '同じくが多い順' },
  { value: QuestionSortKey.AnswerCount, name: '回答数が多い順' },
  { value: QuestionSortKey.AnswerFavoriteCount, name: '参考になったが多い順' },
];

export const AnsweredOptions: readonly RadioOption[] = [
  { value: ALL.toString(), label: '全て' },
  { value: ANSWERED.toString(), label: '回答あり' },
  { value: NO_ANSWER.toString(), label: '回答なし' },
];

export const SolvedOptions: readonly RadioOption[] = [
  { value: ALL.toString(), label: '全て' },
  { value: SOLVED.toString(), label: '解決済み' },
  { value: UNSOLVED.toString(), label: '未解決' },
];

export const SearchQuestionStatusOptions: readonly RadioOption[] = [
  { value: SearchQuestionStatus.All.toString(), label: '全て' },
  { value: SearchQuestionStatus.Ai.toString(), label: 'AI回答中' },
  { value: SearchQuestionStatus.Escalation.toString(), label: '回答受付中' },
  { value: SearchQuestionStatus.Solved.toString(), label: '解決済み' },
];

export const QuestionLabels: { [key: string]: string } = {
  [QuestionLabelType.AI]: 'AI回答中',
  [QuestionLabelType.Normal]: '回答受付中',
  [QuestionLabelType.Solved]: '解決済み',
  [QuestionLabelType.Draft]: '下書き',
} as const;
