import React, { useEffect, useRef } from 'react';
import i18n from '@emoji-mart/data/i18n/ja.json';
import { Emoji, Picker } from 'emoji-mart';

interface EmojiPickerProps {
  selectEmoji: (emoji: typeof Emoji.Props) => void;
  style?: React.CSSProperties;
}

export const EmojiPicker: React.FC<EmojiPickerProps> = ({ selectEmoji, style }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    new Picker({
      onEmojiSelect: (emoji: typeof Emoji.Props) => selectEmoji(emoji),
      previewPosition: 'none',
      skinTonePosition: 'none',
      native: true,
      i18n,
      style: style ?? undefined,
      ref,
    });
  }, [selectEmoji, style]);

  return <div ref={ref} />;
};
