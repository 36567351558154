import { useMemo } from 'react';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { useGetTotalMedalCountsQuery } from '../../gen/graphql';
import { useUser } from '../../redux/user/useUser';
import { MedalCount } from '../../types/MedalCount';

export const useGetExamMedal = (): Map<number, MedalCount> => {
  const { permissionCheck } = useUser();
  const skipQuery = !(
    permissionCheck(FunctionType.Exam, PermissionType.Read) ||
    permissionCheck(FunctionType.ExamForInstructorAndCoach, PermissionType.Read)
  );

  const { data } = useGetTotalMedalCountsQuery({
    skip: skipQuery,
  });

  const medalCounts = useMemo(() => {
    const medalCountMap = new Map();

    data?.totalMedalCounts?.forEach((mc) => {
      if (mc.programID) {
        medalCountMap.set(mc.programID, {
          gold: mc.medalCounts.GOLD,
          silver: mc.medalCounts.SHILVER,
          bronze: mc.medalCounts.BRONZE,
        });
      }
    });

    return medalCountMap;
  }, [data]);

  return medalCounts;
};
