import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';

import FinishImage from '../../static/image/course_program_detail_finish.png';

interface Props {
  isFinished: boolean;
}

export const CourseProgramDetailFinish: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const linkArchive = () => {
    navigate('/programs');
  };
  const linkLog = () => {
    navigate('/study_report/new');
  };

  return (
    <Container $isFinished={props.isFinished}>
      <Step>Finish</Step>
      {!!props.isFinished && (
        <>
          <StepText>
            <StepTitle>コース修了</StepTitle>
            <StepDescription>
              おめでとうございます。全てのStepを完了しました。一度終えたStepは改めて確認することができます。
              <br />
              実践でコードを記述している際にもし忘れていいるような点がある時にはコース内の教材を読み返してみましょう
            </StepDescription>
          </StepText>
          <ImageArea>
            <Image src={FinishImage} alt="" />
            <SpTitle>コース修了</SpTitle>
            <SpText>
              おめでとうございます。全てのStepを完了しました。一度終えたStepは改めて確認することができます。
              <br />
              実践でコードを記述している際にもし忘れていいるような点がある時にはコース内の教材を読み返してみましょう
            </SpText>
            <ButtonArea>
              <ArchiveButton onClick={linkArchive}>コース一覧に戻る</ArchiveButton>
              <LogButton onClick={linkLog}>学習ログを記入</LogButton>
            </ButtonArea>
          </ImageArea>
        </>
      )}
    </Container>
  );
};

const Container = styled.div<{ $isFinished: boolean }>`
  display: flex;
  flex-wrap: wrap;
  padding-left: 1.5rem;

  ${(props) =>
    props.$isFinished
      ? media.lessThan('medium')`
    padding-left: 0;
  `
      : media.lessThan('medium')`
    display: none;
  `}
`;
const Step = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  background-color: #f5f5f5;
  border-radius: 50%;
  color: #575e71;
  font-size: 0.9375rem;
  font-weight: 700;

  ${media.lessThan('medium')`
    width: 2.5rem;
    height: 2.5rem;
    font-size: .75rem;
  `}
`;
const StepText = styled.div`
  flex: 1;
  margin-left: 0.875rem;
  color: rgba(0, 0, 0, 0.87);

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const StepTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
`;
const StepDescription = styled.p`
  margin-top: 0.125rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
`;
const ImageArea = styled.div`
  width: 100%;
  margin-top: 1rem;
  padding: 2.5rem 1.5rem 1.25rem;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: hidden;

  ${media.lessThan('medium')`
    margin-top: .75rem;
    padding: 0 0 2rem;
  `}
`;
const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 634px;
  margin: 0 auto;
  border-radius: 1rem;

  ${media.lessThan('medium')`
    max-width: none;
    border-radius: 0;
  `}
`;
const SpTitle = styled.h3`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    margin-top: 1.25rem;
    padding: 0 1rem;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
  `}
`;
const SpText = styled.p`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    margin-top: .5rem;
    padding: 0 1rem;
    color: rgba(0,0,0,.6);
    font-size: .75rem;
    line-height: 1rem;
  `}
`;
const ButtonArea = styled.div`
  display: flex;
  gap: 2.5rem;
  width: 100%;
  max-width: 540px;
  margin: 1.5rem auto 0;

  ${media.lessThan('medium')`
    gap: 1rem;
    margin-top: 1rem;
    padding: 0 1rem;
    box-sizing: border-box;
  `}
`;
const Button = styled.div`
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #eb0000;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  transition: all 0.2s;

  ${media.lessThan('medium')`
    line-height: 1.25rem;
  `}
`;
const ArchiveButton = styled(Button)`
  background: #fff;
  color: #eb0000;

  &:hover {
    background: #eb0000;
    color: #fff;
  }

  ${media.lessThan('medium')`
    font-size: .75rem;
  `}
`;
const LogButton = styled(Button)`
  background: #eb0000;
  color: #fff;

  &:after {
    content: 'する';
  }

  &:hover {
    background: #fff;
    color: #eb0000;
  }

  ${media.lessThan('medium')`
    font-size: .875rem;
    font-weight: 700;

    &:after {
      content: none;
    }
  `}
`;
