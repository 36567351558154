import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  StripePaymentMethodFragment,
  CurrentUserFragment as User,
  CurrentPermissionListFragment as PermissionList,
  MyTeamMemberFragment,
  ContractOfStripeSubscriptionForAnalyticsFragment as ContractOfStripeSubscriptionForAnalytics,
  UserRole,
} from './gen/graphql';
import { Prefecture, MaskedTicket, Category, Calendar, Course } from './swagger/gen/ts_front';

export enum SnsType {
  None,
  Free,
  PersonalSubscription,
  PremiumSubscription,
  TeamSubscription,
}

export enum StudentStatus {
  Graduate = 6,
}

export interface Pagination {
  page: number;
  total: number;
  limit: number;
}

export interface StoreState {
  user: {
    lmsUser: User | null;
    cognitoUser: CognitoUser | null;
    permissionList: PermissionList | null;
    contractsOfStripeSubscriptionForAnalytics: ContractOfStripeSubscriptionForAnalytics | null;
    role: UserRole;
    teamID: string | null;
    teams: MyTeamMemberFragment[];
    isLogged: boolean;
    loginError: string;
    isChangePassword: boolean;
    socialLoginCallbackURL: string | null;
  };
  prefecture: {
    prefectures: Prefecture[];
    pagination: Pagination;
  };
  commonTicket: {
    tickets: MaskedTicket[];
    pagination: Pagination;
  };
  commonPaymentMethod: {
    paymentMethods: StripePaymentMethodFragment[];
    pagination: Pagination;
  };
  spotLessonCategory: {
    categories: Category[];
    pagination: Pagination;
  };
  spotLessonCalendar: {
    calendars: Calendar[];
  };
  commonModal: {
    isFreeUserModalOpen: boolean;
    isLightUserModalOpen: boolean;
    isBasicUserModalOpen: boolean;
    isPremiumUserModalOpen: boolean;
    isTeamSubscriptionUserModalOpen: boolean;
    isTeamSubscriptionToTeamAdminModalOpen: boolean;
  };
  instructorCourse: {
    courses: Course[];
    pagination: Pagination;
  };
}
