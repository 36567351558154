import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BasicLayout } from '../../templates/BasicLayout';

import styled from 'styled-components';
import media from 'styled-media-query';
import moment from 'moment';

import { LOWER_META_TITLE } from '../../../const/Service';
import { useGetCourseForInstructorQuery } from '../../../gen/graphql';

import differenceInDaysGreaterThanZero from '../../../utils/DateCalc';

import { PageWrapper } from '../../atoms/PageWrapper';
import { CourseUpdateModal } from '../../organisms/CourseUpdateModal';
import { NotFoundPage } from '../public/NotFound';

import { lessonTypeLabels } from '../../../types/Course';

const contractType = [
  '-',
  '~2020年5月契約書(期間)',
  '2020年5月~契約書(レッスン回数)',
  '2021年1月~契約書(休学廃止)',
];
const courseStatus = [
  '削除',
  '受講中',
  '初回レッスン日待ち',
  'アサイン待ち',
  'インスト交代中',
  '休学中',
  '卒業済み',
  '解約',
  '休学/インスト交代中',
  '解約中',
];

export const InstructorCourseDetailContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathCourseID = Number(paramID);
  if (Number.isNaN(pathCourseID)) return NotFoundPage;

  return <InstructorCourseDetail courseID={pathCourseID} />;
};

interface InstructorCourseDetailProps {
  courseID: number;
}

const InstructorCourseDetail = (props: InstructorCourseDetailProps): JSX.Element => {
  const metaTitle = `受講生詳細 | ${LOWER_META_TITLE}`;

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const { data, refetch } = useGetCourseForInstructorQuery({
    variables: {
      id: props.courseID,
    },
  });

  return (
    <BasicLayout pageTitle="受講生詳細" metaTitle={metaTitle}>
      <PageWrapper>
        <Notice>
          受講生情報に誤りや変更がある場合は、お手数ですが
          <a
            target="_blank"
            href="https://docs.google.com/forms/u/1/d/e/1FAIpQLSfGduU7QymZzbheUbLmUXL3QPZvvOXjXhkCkJO8e52WGGW-3Q/viewform"
          >
            こちら
          </a>
          のお問い合わせフォームよりご連絡ください。
        </Notice>
        <Section>
          <h2>受講生情報</h2>
          <img src={data?.courseForInstructor.student.user.image}></img>
          <Dl>
            <Dt>受講生ID</Dt>
            <Dd>{data?.courseForInstructor.student.id}</Dd>
          </Dl>
          <Dl>
            <Dt>名前</Dt>
            <Dd>{data?.courseForInstructor.student.user.maskedPersonalInfo?.name}</Dd>
          </Dl>
          <Dl>
            <Dt>ニックネーム</Dt>
            <Dd>{data?.courseForInstructor.student.user.nickName}</Dd>
          </Dl>
        </Section>
        <Section>
          <h2>受講情報</h2>
          <Dl>
            <Dt>コースID</Dt>
            <Dd>{data?.courseForInstructor.id}</Dd>
          </Dl>
          <Dl>
            <Dt>プラン名</Dt>
            <Dd>{data?.courseForInstructor.plan.name}</Dd>
          </Dl>
          <Dl>
            <Dt>契約書種別</Dt>
            <Dd>
              {data?.courseForInstructor.contractType
                ? contractType[data?.courseForInstructor.contractType]
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>受講開始日</Dt>
            <Dd>
              {data?.courseForInstructor.startDate
                ? moment(data?.courseForInstructor.startDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>受講終了日</Dt>
            <Dd>
              {data?.courseForInstructor.endDate
                ? moment(data?.courseForInstructor.endDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>受講終了日</Dt>
            <Dd>
              {data?.courseForInstructor.endDate
                ? moment(data?.courseForInstructor.endDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>卒業までの日数</Dt>
            <Dd>
              {data?.courseForInstructor.endDate
                ? differenceInDaysGreaterThanZero(new Date(data?.courseForInstructor.endDate))
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>休学終了日（１）</Dt>
            <Dd>
              {data?.courseForInstructor.absentEndDate
                ? moment(data?.courseForInstructor.absentEndDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>休学開始日（２）</Dt>
            <Dd>
              {data?.courseForInstructor.absent2StartDate
                ? moment(data?.courseForInstructor.absent2StartDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>休学終了日（２）</Dt>
            <Dd>
              {data?.courseForInstructor.absent2EndDate
                ? moment(data?.courseForInstructor.absent2EndDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>休学開始日（３）</Dt>
            <Dd>
              {data?.courseForInstructor.absent3StartDate
                ? moment(data?.courseForInstructor.absent3StartDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>休学終了日（３）</Dt>
            <Dd>
              {data?.courseForInstructor.absent3EndDate
                ? moment(data?.courseForInstructor.absent3EndDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>インスト交代開始日（１）</Dt>
            <Dd>
              {data?.courseForInstructor.instructorChangeStartDate
                ? moment(data?.courseForInstructor.instructorChangeStartDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>インスト交代終了日（１）</Dt>
            <Dd>
              {data?.courseForInstructor.instructorChangeEndDate
                ? moment(data?.courseForInstructor.instructorChangeEndDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>インスト交代開始日（２）</Dt>
            <Dd>
              {data?.courseForInstructor.instructorChange2StartDate
                ? moment(data?.courseForInstructor.instructorChange2StartDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>インスト交代終了日（２）</Dt>
            <Dd>
              {data?.courseForInstructor.instructorChange2EndDate
                ? moment(data?.courseForInstructor.instructorChange2EndDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>インスト交代開始日（３）</Dt>
            <Dd>
              {data?.courseForInstructor.instructorChange3StartDate
                ? moment(data?.courseForInstructor.instructorChange3StartDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>インスト交代終了日（３）</Dt>
            <Dd>
              {data?.courseForInstructor.instructorChange3EndDate
                ? moment(data?.courseForInstructor.instructorChange3EndDate).format('YYYY/MM/DD')
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>コンサルタント</Dt>
            <Dd>
              {data?.courseForInstructor.consultant ? data?.courseForInstructor.consultant : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>受講ステータス</Dt>
            <Dd>
              {data?.courseForInstructor.status
                ? courseStatus[data?.courseForInstructor.status]
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>レッスンタイプ</Dt>
            <Dd>
              {data?.courseForInstructor.lessonType
                ? lessonTypeLabels[data?.courseForInstructor.lessonType]
                : '-'}
            </Dd>
          </Dl>
          <Dl>
            <Dt>レッスン場所</Dt>
            <Dd>{data?.courseForInstructor.lessonPlace}</Dd>
          </Dl>
          <Dl>
            <Dt>総レッスン回数</Dt>
            <Dd>{data?.courseForInstructor.plannedLessonCount}</Dd>
          </Dl>
          <Dl>
            <Dt>実施済みレッスン回数</Dt>
            <Dd>{data?.courseForInstructor.finishedLessonCount}</Dd>
          </Dl>
          <Dl>
            <Dt>総セッション回数</Dt>
            <Dd>{data?.courseForInstructor.coaching?.plannedSessionCount ?? 0}</Dd>
          </Dl>
          <Dl>
            <Dt>実施済みセッション回数</Dt>
            <Dd>{data?.courseForInstructor.coaching?.finishedSessionCount ?? 0}</Dd>
          </Dl>
        </Section>
        <Section>
          <h2>その他</h2>
          <Dl>
            <Dt>カリキュラムURL</Dt>
            <Dd className={data?.courseForInstructor.curriculum ? 'ellipsis' : ''}>
              {data?.courseForInstructor.curriculum ? (
                <a target="_blank" href={data?.courseForInstructor.curriculum}>
                  {data?.courseForInstructor.curriculum}
                </a>
              ) : (
                <span>カリキュラム未登録</span>
              )}
            </Dd>
          </Dl>
          <Dl>
            <Dt>転職</Dt>
            <Dd>{data?.courseForInstructor.jobChange ? '希望する' : '希望しない'}</Dd>
          </Dl>
          <Dl>
            <Dt>キャリアサポート</Dt>
            <Dd>{data?.courseForInstructor.careerSupport ? '希望する' : '希望しない'}</Dd>
          </Dl>
          <Dl>
            <Dt>案件獲得サポート</Dt>
            <Dd>{data?.courseForInstructor.customerAcquisition ? '希望する' : '希望しない'}</Dd>
          </Dl>
          <Dl>
            <Dt>支払い遅滞</Dt>
            <Dd>{data?.courseForInstructor.delayInPayment ? 'あり' : 'なし'}</Dd>
          </Dl>
          <Dl>
            <Dt>音信不通</Dt>
            <Dd>{data?.courseForInstructor.breakInContact ? 'あり' : 'なし'}</Dd>
          </Dl>
          <Dl>
            <Dt>教材費</Dt>
            <Dd>{data?.courseForInstructor.teachingMaterialCost ? 'あり' : 'なし'}</Dd>
          </Dl>
          <Dl>
            <Dt>レッスン時間上限</Dt>
            <Dd>{data?.courseForInstructor.lessonMinutesUpperLimit}</Dd>
          </Dl>
          {data?.courseForInstructor.studentInformation && (
            <>
              <Dl>
                <Dt>顧客情報</Dt>
                <Dd>
                  <StudentInformationContent>
                    {data?.courseForInstructor.studentInformation.customerInformation}
                  </StudentInformationContent>
                </Dd>
              </Dl>
              <Dl>
                <Dt>コンサルタント引継ぎ情報</Dt>
                <Dd>
                  <StudentInformationContent>
                    {data?.courseForInstructor.studentInformation.transferInformation}
                  </StudentInformationContent>
                </Dd>
              </Dl>
            </>
          )}
        </Section>
        <CourseUpdateModal
          isOpen={isOpenModal}
          courseID={props.courseID}
          memo={data?.courseForInstructor.instructorMemo ?? ''}
          onRefetch={refetch}
          onClose={() => {
            setIsOpenModal(false);
          }}
        />
        <MemoSection>
          <MemoHeader>
            <h2>メモ</h2>
          </MemoHeader>
          <MemoEditButton
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            編集
          </MemoEditButton>
          <MemoContent>{data?.courseForInstructor.instructorMemo ?? ''}</MemoContent>
        </MemoSection>
      </PageWrapper>
    </BasicLayout>
  );
};

const Notice = styled.div`
  margin: 1rem auto;
  max-width: 892px;
  padding: 1.2rem 2rem;
  background-color: rgba(253, 60, 47, 0.05);

  a {
    color: #e2001b;
  }

  ${media.lessThan('small')`
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1.4;

    a {
      font-size: 0.875rem;
    }
  `}
`;
const Section = styled.section`
  margin: 1rem auto;
  max-width: 892px;
  padding: 1.2rem 2rem;
  background-color: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.1);

  h2 {
    margin: 1rem 0 2rem;
    font-size: 1.2rem;
    font-weight: bold;
  }

  img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }

  ${media.lessThan('small')`
    padding: 1rem;

    h2 {
      margin: 1rem 0;
    }
  `}
`;
const Dl = styled.dl`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;

  ${media.lessThan('small')`
    display: block;
    padding-top: 1rem;
    border-top: 1px solid rgba(0,0,0,.1);
  `}
`;
const Dt = styled.dt`
  width: 25%;
  min-width: 170px;
  max-width: 280px;

  ${media.lessThan('small')`
    width: 100%;
    max-width: none;
    margin-bottom: .6rem;
    font-size: .875rem;
    font-weight: bold;
  `}
`;
const Dd = styled.dd`
  flex: 1;

  &.ellipsis {
    color: #e2001b;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    color: #e2001b;
  }

  span {
    color: lightgray;
  }
`;

const MemoSection = styled.section`
  position: relative;
  margin: 1rem auto;
  max-width: 892px;
  padding: 1.2rem 2rem;
  background-color: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.1);

  h2 {
    margin: 1rem 0 2rem;
    font-size: 1.2rem;
    font-weight: bold;
  }

  img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }

  ${media.lessThan('small')`
    padding: 1rem;

    h2 {
      margin: 1rem 0;
    }
  `}
`;

const MemoHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MemoEditButton = styled.p`
  position: absolute;
  top: 2rem;
  right: 2rem;
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0.4rem 0.8rem;
  color: #e2001b;

  ${media.lessThan('small')`
    top: 1rem;
    right: 1rem;
    padding: .375rem .75rem;
    font-size: .875rem;
  `}
`;

const MemoContent = styled.div`
  white-space: pre-wrap;
`;

const StudentInformationContent = styled.div`
  white-space: pre-wrap;
`;
