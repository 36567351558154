import { QuestionFragment, AnswerRequestType } from '../gen/graphql';
import { getUnixTime, parseISO } from 'date-fns';

export enum QuestionLabelType {
  // AI回答中
  AI,
  // インストラクター回答中
  Normal,
  // 解決済み
  Solved,
  // 下書き
  Draft,
}

export enum AnswerInputType {
  // 通常
  Normal,
  // 表示しない
  Hidden,
  // エスカレーション
  Escalation,
  // ボタンをDisable
  EscalationDisable,
}

export enum CommentInputType {
  // コメント可能
  OpenComment,
  // 「返信する」は表示するがDisable
  DisableComment,
  // コメント非表示
  HiddenComment,
  // 質問者本人のみがAI先生に返信できます。
  AICommentMessage,
  // AI先生にエラーが発生しました。最下部のフォームより講師からの回答を依頼してください。
  WaitingToEscalationMessage,
}

// 編集時に注意モーダルを出すかどうか判定する
// AI回答があり、未エスカレーションの場合はモーダルを出す
export function needsModalForEditing(question?: QuestionFragment): boolean {
  if (!question) {
    return false;
  }

  // AIからの回答がない場合はfalse
  const aiAnswerCount = question.questionAnswers?.filter((e) => e.user.isAI).length ?? 0;
  if (aiAnswerCount <= 0) {
    return false;
  }

  // エスカレーション済みの場合はfalse
  if (isEscalated(question)) {
    return false;
  }

  return true;
}

// ラベルタイプ取得
export function getQuestionLabelType(
  solved: boolean,
  isDraft: boolean,
  answerRequestType: AnswerRequestType,
): QuestionLabelType {
  if (solved) {
    return QuestionLabelType.Solved;
  }

  if (isDraft) {
    return QuestionLabelType.Draft;
  }

  if (
    answerRequestType === AnswerRequestType.Ai ||
    answerRequestType === AnswerRequestType.WaitingToEscalation
  ) {
    return QuestionLabelType.AI;
  }

  return QuestionLabelType.Normal;
}

// 解答欄の表示タイプを取得する
export function getAnswerInputType(
  answerRequestType: AnswerRequestType | undefined,
  isAnswerPending: boolean,
  isOwnQuestion: boolean,
): AnswerInputType {
  if (!answerRequestType) {
    return AnswerInputType.Normal;
  }

  if (isAnswerPending) {
    return AnswerInputType.EscalationDisable;
  }

  if (
    answerRequestType === AnswerRequestType.Ai ||
    answerRequestType === AnswerRequestType.WaitingToEscalation
  ) {
    if (isOwnQuestion) {
      return AnswerInputType.Escalation;
    } else {
      return AnswerInputType.Hidden;
    }
  } else {
    return AnswerInputType.Normal;
  }
}

// 質問の回答の中にエスカレーションしたものがある場合はエスカレート済みを設定する
export function isEscalated(question?: QuestionFragment): boolean {
  if (!question) {
    return false;
  }

  return question.answerRequestType === AnswerRequestType.Escalation;
}

// AIの回答状況に応じてコメントの表示形式を設定する
export function getCommentInputType(
  answerRequestType: AnswerRequestType,
  isAnswerPending: boolean,
  isOwnQuestion: boolean,
  isEscalated: boolean,
  isQuestionAnswerTypeAI: boolean,
): CommentInputType {
  if (answerRequestType === AnswerRequestType.Ai) {
    if (isAnswerPending) {
      if (isOwnQuestion) {
        return CommentInputType.DisableComment;
      } else {
        return CommentInputType.HiddenComment;
      }
    } else {
      if (isOwnQuestion) {
        return CommentInputType.OpenComment;
      } else {
        return CommentInputType.AICommentMessage;
      }
    }
  }
  if (answerRequestType === AnswerRequestType.WaitingToEscalation) {
    if (isOwnQuestion) {
      return CommentInputType.WaitingToEscalationMessage;
    } else {
      return CommentInputType.HiddenComment;
    }
  }
  if (isEscalated) {
    if (isQuestionAnswerTypeAI) {
      return CommentInputType.HiddenComment;
    } else {
      return CommentInputType.OpenComment;
    }
  }
  return CommentInputType.OpenComment;
}

// 質問がAIからの回答待ちか判定する
// 条件
// - AnswerRequestType == AI
// - 回答がない or 最新のコメントがAIではない
export function isWaitingAIAnswer(question?: QuestionFragment): boolean {
  if (!question) {
    return false;
  }

  if (question.answerRequestType === AnswerRequestType.Escalation) {
    return false;
  }

  if (!question.questionAnswers || question.questionAnswers.length <= 0) {
    return true;
  }
  const latestQuestionAnswer = question.questionAnswers
    .slice(0)
    .sort((a, b) => {
      return getUnixTime(parseISO(b.createdAt)) - getUnixTime(parseISO(a.createdAt));
    })
    .find(() => true);
  if (!latestQuestionAnswer) {
    return true;
  }

  const latestQuestionAnswerComment = latestQuestionAnswer?.questionComments
    ?.slice(0)
    .sort((a, b) => {
      return getUnixTime(parseISO(b.createdAt)) - getUnixTime(parseISO(a.createdAt));
    })
    .find(() => true);
  if (!latestQuestionAnswerComment) {
    return false;
  }

  return latestQuestionAnswerComment.user.isAI === false;
}

// 最新の問い合わせを行った日付を取得する
// 質問者と同一IDのコメント&質問の更新日から最新ものを取得する
export function latestAnswerRequestedDate(question?: QuestionFragment): string {
  if (!question) {
    return '';
  }

  const requestedUserID = question.id;

  const commentUpdatedAtList =
    question.questionAnswers?.map(
      (e) =>
        e.questionComments?.filter((e) => e.user.id === requestedUserID).map((e) => e.updatedAt) ??
        [],
    ) ?? [];

  const updatedAtList = commentUpdatedAtList.flat().concat(question.updatedAt);
  const latestUpdatedAt =
    updatedAtList
      .slice(0)
      .sort((a, b) => {
        return getUnixTime(parseISO(b)) - getUnixTime(parseISO(a));
      })
      .find(() => true) ?? '';

  return latestUpdatedAt;
}
