import { FC, useState, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../atoms/Button';
import { Input } from '../atoms/Input';
import { Select } from '../atoms/Select';
import { CustomDatePicker } from '../atoms/CustomDatePicker';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { RegisterPayment } from './RegisterPayment';

import { PaymentMethodType, useGetTeamPricesQuery } from '../../gen/graphql';
import {
  CardCvcError,
  CardExpirationError,
  CardNumberError,
  LmsStripeValidationError,
} from '../../infrastructure/externalService/StripePaymentService';
import { teamAndTeamSubscriptionSchema } from '../../common/formSchema/teamSubscription';
import { TeamRegisterInput } from '../pages/public/TeamRegister';
import { MAXIMUM_NUMBER_OF_USERS } from '../../const/team';
import { CANCELLATION_FAQ_LINK } from '../../const/Link';

interface Props {
  onSubmit: (input: TeamRegisterInput) => void;
  setStripe: (stripe: Stripe | null) => void;
  setElements: (elements: StripeElements | null) => void;
  isCompleted: boolean;
  stripeValidationError: LmsStripeValidationError | null;
}

export const TeamRegisterCompanyAndSubscription: FC<Props> = ({
  onSubmit,
  setStripe,
  setElements,
  isCompleted,
  stripeValidationError,
}): JSX.Element => {
  // メールアドレスの入力欄にフォーカスがあたっているか
  const [focusedEmail, setFocusedEmail] = useState(false);

  // 課金系のフォーム関連情報
  const stripePromise = useMemo(
    () => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ''),
    [],
  );

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { isValid, errors, dirtyFields },
  } = useForm<TeamRegisterInput>({
    resolver: yupResolver(teamAndTeamSubscriptionSchema),
    defaultValues: {
      paymentMethodType: PaymentMethodType.CreditCard,
      timezoneOffset: new Date().getTimezoneOffset(),
    },
    mode: 'all',
  });

  const quantity = watch('quantity');
  const paymentMethodType = watch('paymentMethodType');
  const priceID = watch('priceID');

  const { data: pricesData } = useGetTeamPricesQuery({
    variables: {
      paymentMethodType: paymentMethodType,
    },
    fetchPolicy: 'cache-first',
    onCompleted: ({ teamPrices }) => {
      // paymentMethodに連動してPriceも変わる
      setValue('priceID', teamPrices[0]?.id);
      // 銀行振込用の日付を初期化
      setValue('billingCycleAnchor', null);
    },
  });
  const prices = useMemo(() => pricesData?.teamPrices ?? [], [pricesData]);
  const price = useMemo(() => prices.find((p) => p.id === priceID), [prices, priceID]);

  const cardNumberError =
    stripeValidationError instanceof CardNumberError ? stripeValidationError.message : '';
  const expirationError =
    stripeValidationError instanceof CardExpirationError ? stripeValidationError.message : '';
  const cvcError =
    stripeValidationError instanceof CardCvcError ? stripeValidationError.message : '';

  // 遷移するまでにSleepが入るため完了フラグもチェックする
  const isDisabledSubmit = !isValid || isCompleted;

  return (
    <>
      <Description>
        会社情報の登録をします。必要事項を記入の上、登録ボタンをクリックしてくだい。
      </Description>
      <InputArea>
        <FormRow>
          <Label>
            <span className="required">必須</span>
            <label>会社名</label>
          </Label>
          <Controller
            name="companyName"
            control={control}
            render={({ field, fieldState: { error, isDirty } }) => (
              <>
                <StyledInput
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  type="text"
                  placeholder="株式会社テラコヤ"
                  error={!!error}
                  validation={isDirty && !error}
                  onBlur={field.onBlur}
                />
                <ErrorText>{error && error.message}</ErrorText>
              </>
            )}
          />
        </FormRow>
        <FormRow>
          <Label>
            <span className="required">必須</span>
            <label>請求先メールアドレス</label>
          </Label>
          <Controller
            name="adminEmail"
            control={control}
            render={({ field, fieldState: { error, isDirty } }) => (
              <>
                <StyledInput
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  type="text"
                  placeholder="yourmail@domain.com"
                  error={!!error}
                  validation={isDirty && !error}
                  onBlur={() => {
                    setFocusedEmail(false);
                    field.onBlur();
                  }}
                  onFocus={() => setFocusedEmail(true)}
                />
                {/* 入力中の場合にメールアドレスの形式エラーを出したくないので、フォーカスがあたっていて入力中の値がある場合はエラーを出さない */}
                <ErrorText>{(!focusedEmail || !field.value) && error && error.message}</ErrorText>
              </>
            )}
          />
        </FormRow>

        <FormRow>
          <Label>
            <span className="required">必須</span>
            <label>利用人数</label>
            <span className="note">
              {MAXIMUM_NUMBER_OF_USERS}人を超える場合は
              <a href="https://lp.sejuku.net/terakoya/biz/#form_contact" target="_blank">
                こちら
              </a>
              からお問い合わせ下さい。
            </span>
          </Label>
          <Controller
            name="quantity"
            control={control}
            render={({ field }) => (
              <>
                <QuantityInput
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  type="number"
                  placeholder="利用人数を入力"
                  width="10rem"
                  validation={dirtyFields.quantity && !errors.quantity}
                  onBlur={field.onBlur}
                />
                <QuantityUnit>人</QuantityUnit>
                <ErrorText>{errors.quantity?.message}</ErrorText>
              </>
            )}
          />
        </FormRow>
        <PriceArea>
          <PriceLabel>利用金額</PriceLabel>
          <TotalPrice>
            ¥
            {price && dirtyFields.quantity && !errors.quantity
              ? (price.unitAmount * quantity).toLocaleString()
              : '-'}
            <span>(税込){paymentMethodType === PaymentMethodType.CreditCard && ' / 月'}</span>
          </TotalPrice>
          <PriceNote>
            1人あたりの料金 ¥{price ? price.unitAmount.toLocaleString() : '-'}(税込)
            {paymentMethodType === PaymentMethodType.CreditCard && ' / 月'}
          </PriceNote>
        </PriceArea>
        <FormRow>
          <Label>
            <span className="required">必須</span>
            <label>お支払い方法</label>
          </Label>

          <Controller
            name="paymentMethodType"
            control={control}
            render={({ field }) => (
              <>
                <FormControl component="fieldset">
                  <RadioGroup value={field.value} onChange={field.onChange}>
                    <FormControlLabel
                      value={PaymentMethodType.CreditCard}
                      control={<Radio />}
                      label="クレジットカード"
                    />
                    <FormControlLabel
                      value={PaymentMethodType.Bank}
                      control={<Radio />}
                      label="銀行振込 (請求書払い)"
                    />
                  </RadioGroup>
                </FormControl>
              </>
            )}
          />
        </FormRow>
        {paymentMethodType === PaymentMethodType.CreditCard && (
          <>
            <PaymentNotice>
              入力いただいた情報は暗号化されて送信されます。
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="3" y="7" width="14" height="12" rx="1" fill="#555555" />
                <rect x="6" y="2" width="8" height="13" rx="4" stroke="#555555" strokeWidth="2" />
              </svg>
            </PaymentNotice>
            <Elements stripe={stripePromise}>
              <RegisterPayment
                numberError={cardNumberError}
                expirationError={expirationError}
                cvcError={cvcError}
                setStripe={setStripe}
                setElements={setElements}
              />
            </Elements>
            <PlanDescription>
              サブスクリプションは{format(new Date(), 'yyyy/M/d')}
              に開始され1ヶ月後に自動で更新されます。
            </PlanDescription>
          </>
        )}
        {paymentMethodType === PaymentMethodType.Bank && (
          <>
            <BankTransferInputArea>
              <FormRow>
                <Label>
                  <span className="required">必須</span>
                  <label>利用期間</label>
                </Label>
                <Controller
                  name="priceID"
                  control={control}
                  render={({ field }) => (
                    <>
                      <RecurringIntervalSelect
                        name="priceID"
                        value={String(field.value)}
                        onChange={(e) => setValue('priceID', parseInt(e.target.value))}
                        options={prices.map((p) => ({
                          name: String(p.recurringIntervalCount),
                          value: String(p.id),
                        }))}
                      />
                      <RecurringIntervalUnit>ヶ月</RecurringIntervalUnit>
                    </>
                  )}
                />
              </FormRow>
              <FormRow>
                <Label>
                  <span className="required">必須</span>
                  <label>利用開始日</label>
                </Label>
                <Controller
                  name="billingCycleAnchor"
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledDatePicker
                        selected={field.value ? new Date(field.value) : null}
                        onChange={(date) =>
                          setValue('billingCycleAnchor', date?.toISOString(), {
                            shouldValidate: true,
                          })
                        }
                        onBlur={() => trigger('billingCycleAnchor')}
                        minDate={new Date()}
                        dateFormat="yyyy年MM月dd日"
                        disabledKeyboardNavigation
                        placeholderText="開始日を選択"
                      />
                      <ErrorText>{errors.billingCycleAnchor?.message}</ErrorText>
                    </>
                  )}
                />
              </FormRow>
            </BankTransferInputArea>
            <PlanDescription>
              サブスクリプションは利用開始日を起点に開始され、解約申請がない場合、契約は自動更新されます。
            </PlanDescription>
          </>
        )}
        <Info>
          ※「特定商取引法に基づく表記」は
          <a href="https://www.sejuku.net/corp/legal" target="_blank">
            こちら
          </a>
          をご覧ください。
          <br />※ 解約方法については
          <a href={CANCELLATION_FAQ_LINK} target="_blank">
            こちら
          </a>
          をご覧ください。
        </Info>
        <SubmitButton onClick={handleSubmit(onSubmit)} disabled={isDisabledSubmit}>
          契約する
        </SubmitButton>
      </InputArea>
    </>
  );
};

const Description = styled.p`
  font-size: 0.75rem;
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.87);
`;

const InputArea = styled.div`
  margin-top: 0.875rem;

  ${media.lessThan('medium')`
    margin-top: 0;
  `}
`;

const FormRow = styled.div`
  margin-top: 2rem;

  ${media.lessThan('medium')`
    position: relative;
    flex-direction: column;

    & > * + * {
      margin-top: 0.5rem;
    }

    input {
      display: block;
      width: 100% !important;
    }
  `}
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 1rem;

  label {
    font-size: 1rem;
    font-weight: 700;
  }

  .required {
    display: block;
    margin-right: 0.5rem;
    padding: 0.125rem 0.5rem;
    background-color: #fd6258;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.2;
  }

  small {
    display: block;
    margin-left: 0.5rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.75rem;
  }

  ${media.lessThan('medium')`
    flex-wrap: wrap;

    small {
      width: 100%;
      margin: 0.375rem auto 0;
    }
  `}
`;

const StyledInput = styled(Input)`
  height: 2.75rem;
  border-color: rgba(0, 0, 0, 0.36);
  border-radius: 0;

  input {
    padding: 0.75rem;
    outline: none;

    &:autofill {
      box-shadow: 0 0 0 1000px #fff inset;
    }
  }

  ${media.lessThan('medium')`
    height: 2.5rem;

    input {
      padding: .625rem .75rem;
    }
  `}
`;

const ErrorText = styled.p`
  margin: 0.25rem auto 0;
  color: #eb0000;
  align-self: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

const SubmitButton = styled(Button)`
  width: 13.75rem;
  margin-top: 2rem;
  padding-left: 0;
  padding-right: 0;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 22.5rem;
  `}
`;

const QuantityInput = styled(Input)`
  display: inline-block;
  height: 2.75rem;
  border-color: rgba(0, 0, 0, 0.36);
  border-radius: 0;

  input {
    padding: 0.75rem;
    outline: none;

    &[type='number'] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    &:autofill {
      box-shadow: 0 0 0 1000px #fff inset;
    }
  }

  ${media.lessThan('medium')`
    height: 2.5rem;
    width: 6.75rem;

    input {
      padding: .625rem .75rem;
    }

    &::placeholder {
      color: transparent;
    }
  `}
`;

const QuantityUnit = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
  line-height: 1.38;
  color: rgba(0, 0, 0, 0.6);
`;

const PriceArea = styled.div`
  margin-top: 1rem;
`;

const PriceLabel = styled.p`
  font-weight: 700;
  line-height: 1.5;
`;

const TotalPrice = styled.p`
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.35;

  span {
    font-size: 1rem;
  }
`;

const PriceNote = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
`;

const PaymentNotice = styled.p`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.25rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
    font-size: .75rem;
    line-height: 1rem;
  `}
`;

const PlanDescription = styled.p`
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.6;

  ${media.lessThan('medium')`
    margin-top: 1rem;
    font-size: 0.75rem;
    line-height: 1.3;
  `}
`;

const BankTransferInputArea = styled.div`
  margin: calc(2rem - 0.5625rem) auto 0;
  padding: 1rem 1rem 2rem;
  background: #ecf6fc;

  ${FormRow} {
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }
  }

  ${media.lessThan('medium')`
    margin: calc(1.5rem - 0.5625rem) -0.75rem 0;
    padding: 0.75rem;
  `}
`;

const RecurringIntervalSelect = styled(Select)`
  width: 9.375rem;
  height: 2.75rem;
  padding: 0.75rem;
  background: #fff top 0.75rem right 0.75rem / auto no-repeat
    url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2217%22%20height%3D%2216%22%20viewBox%3D%220%200%2017%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.49707%2013L3.30092%204H13.6932L8.49707%2013Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E');
  font-size: 1rem;
  appearance: none;
  outline: none;

  ${media.lessThan('medium')`
    padding: 0.625rem 0.75rem;
    width: 5.75rem;
  `}
`;

const RecurringIntervalUnit = styled.span`
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.35;
  color: rgba(0, 0, 0, 0.6);
`;

const Info = styled.p`
  margin-top: 1.6rem;
  font-size: 0.75rem;
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.87);

  a {
    font-size: inherit;
    color: #e2001b;
  }

  ${media.lessThan('medium')`
    br {
      display: none;
    }
  `}
`;

const StyledDatePicker = styled(CustomDatePicker)`
  padding: 0.75rem;
  width: 11.625rem;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.36);
  outline: none;

  ${media.lessThan('medium')`
    padding: 0.625rem 1rem;
    width: 10rem;
  `}
`;
