import React, { useEffect } from 'react';

interface CvMoshimoProps {
  userID: number;
}

export const CvMoshimo: React.FC<CvMoshimoProps> = (props) => {
  // こちらのタスクで依頼 https://samurai-7s.backlog.com/view/LMS-1326
  // タグ一覧(https://docs.google.com/spreadsheets/d/1zE7MSsf4DhM8NpvbQBElisoON9-Sm5Ldw5CVkprGGUY/edit#gid=2139083905)

  useEffect(() => {
    const h = document.getElementsByTagName('head')[0] as HTMLElement;

    const s1 = document.createElement('script');
    s1.id = 'msmaf_1';
    s1.src = `https://r.moshimo.com/af/r/result.js?p_id=3614&pc_id=8760&m_v=${props.userID}`;
    s1.async = true;
    h.appendChild(s1);

    const s2 = document.createElement('script');
    s2.id = 'msmaf_2';
    s2.src = `https://r.moshimo.com/af/r/result.js?p_id=3614&pc_id=8762&m_v=${props.userID}`;
    s2.async = true;
    h.appendChild(s2);

    return () => {
      document.getElementById('msmaf_1')?.remove();
      document.getElementById('msmaf_2')?.remove();
    };
  }, [props.userID]);

  return null;
};
