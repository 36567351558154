import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../../atoms/LpInner';

import BgImage from '../../../../static/image/lp/price_bg_pc.png';
import BgImageSp from '../../../../static/image/lp/price_bg_sp.png';

import { LIGHT_TAX_INCLUDED_LOCALED_PRICE } from '../../../../const';

export const Price: React.FC = () => {
  return (
    <PriceSection id="price" data-gtm="price">
      <Inner>
        <Title>
          <span>フリープラン</span>で侍テラコヤ
          <br className="sp" />
          を気に入ったら
          <span>
            入会金不要、
            <br className="sp" />
            月額定額
          </span>
          で<br className="pc" />
          気軽に学習を続けることができます
        </Title>
        <PriceText>
          <span className="month">月額</span>
          <span className="number">{LIGHT_TAX_INCLUDED_LOCALED_PRICE}</span>
          <span className="unit">円〜</span>
          <span className="tax">(税込)</span>
        </PriceText>
      </Inner>
    </PriceSection>
  );
};

const PriceSection = styled.section`
  padding: 3rem 0 4rem;
  scroll-margin-top: 49px;
  background: #f5f0ed url(${BgImage}) center / auto 100% no-repeat;

  ${media.lessThan('medium')`
    scroll-margin-top: 57px;
  `}

  ${media.lessThan('small')`
    padding: 2.75rem 0;
    background-image: url(${BgImageSp});
    background-size: cover;
  `}
`;
const Title = styled.h2`
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 2.375rem;
  text-align: center;

  span {
    color: #eb0000;
    font-size: 2rem;
  }

  .sp {
    display: none;
  }

  ${media.lessThan('medium')`
    font-size: 1.125rem;
    line-height: 2rem;

    span {
      font-size: 1.375rem;
    }

    .pc {
      display: none;
    }

    .sp {
      display: block;
    }
  `}
`;
const PriceText = styled.p`
  margin-top: 0.75rem;
  text-align: center;

  .month {
    font-size: 2rem;
    font-weight: 700;
    line-heihgt: 1;
  }
  .number {
    color: #eb0000;
    font-size: 4.5rem;
    font-weight: 700;
  }
  .unit {
    color: #eb0000;
    font-size: 2.25rem;
    font-weight: 700;
  }
  .tax {
    font-size: 1.125rem;
    font-weight: 700;
  }

  ${media.lessThan('medium')`
    .month {
      font-size: .875rem;
    }
    .number {
      font-size: 2.125rem;
    }
    .unit {
      font-size: 1.25rem;
    }
    .tax {
      display: block;
      font-size: .75rem;
    }
  `}
`;
