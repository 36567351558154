import { ClaimCategory } from '../common/Const';

// カテゴリーによって清算の合計単位を表示し分ける
export const showTotalUnit = (id: number): string => {
  if (
    id === ClaimCategory.TEACHING_MATERIAL ||
    id === ClaimCategory.REFERRAL_INCENTIVE ||
    id === ClaimCategory.POSTED_TIMELINE ||
    id === ClaimCategory.OTHER
  ) {
    return '合計:';
  }

  if (id === ClaimCategory.UNIT_PRICE_SYSTEM_OF_PRACTICE_REVIEW) {
    return '合計件数:';
  }

  return '合計時間:';
};

// カテゴリーによって清算の単位を表示し分ける
export const showUnit = (id: number): string => {
  if (
    id === ClaimCategory.TEACHING_MATERIAL ||
    id === ClaimCategory.REFERRAL_INCENTIVE ||
    id === ClaimCategory.POSTED_TIMELINE ||
    id === ClaimCategory.OTHER
  ) {
    return '回';
  }

  if (id === ClaimCategory.UNIT_PRICE_SYSTEM_OF_PRACTICE_REVIEW) {
    return '件';
  }

  return '分';
};
