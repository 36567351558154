import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import ResetCSS from '../../atoms/ResetCSS';

import Logo from '../../../static/image/logo.svg';
import { Timeline } from 'react-twitter-widgets';

const Header = styled.header`
  display: flex;
  width: 100%;
  height: 67px;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 2rem;
  box-sizing: border-box;

  img {
    height: 1.5rem;
  }

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;

const Main = styled.main`
  box-sizing: border-box;
  padding: 32px 180px;

  ${media.lessThan('medium')`
    padding: 32px;
  `}

  h2 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
  }
`;

const Content = styled.section`
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;

  p {
    margin-top: 1rem;
    line-height: 1.3;
  }
`;

const Text = styled.h3`
  display: block;
  font-size: 8rem;
  font-weight: bold;
  color: #e2001b;
  text-align: center;
`;

const SubText = styled.h4`
  display: block;
  font-size: 2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.36);
  text-align: center;
`;

const TimelineContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

const TimelineBox = styled.div`
  width: 600px;
  align-text: center;
`;

export const MaintenancePage = (
  <React.Fragment>
    <ResetCSS />

    <Header>
      <img src={Logo} alt="Samurai" />
    </Header>

    <Main>
      <Content>
        <Text>503</Text>
        <SubText>Maintenance</SubText>
        <p>
          申し訳ございませんが、現在対象ページはメンテナンス中のためアクセスできません。
          <br />
          時間を置いたのち、再度アクセスお願いします。
          <br />
        </p>
        <TimelineContent>
          <TimelineBox>
            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: 'samuraijuku', // アカウント名
              }}
              options={{
                height: '400',
              }}
            />
          </TimelineBox>
        </TimelineContent>
      </Content>
    </Main>
  </React.Fragment>
);

export const Maintenance: React.FC = (): JSX.Element => MaintenancePage;

export default MaintenancePage;
