import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { BasicLayout } from '../../templates/BasicLayout';
import { CourseList } from '../../organisms/CourseList';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import { CourseStatusLabels } from '../../../const/Course';
import {
  CourseSearchType,
  useCreateSessionMutation,
  useGetCoursesForInstructorCourseListQuery,
  useGetSessionsForInstructorQuery,
  useUpdateSessionMutation,
} from '../../../gen/graphql';
import { SessionList } from '../../organisms/SessionList';
import { Loader } from '../../molecules/Loader';
import { Button } from '../../atoms/Button';
import { SessionInput, SessionInputModal } from '../../organisms/SessionInputModal';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../../context/ToastsProvider';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { useUser } from '../../../redux/user/useUser';
import { NotFoundPage } from '../public/NotFound';

export const InstructorSessionContainer = (): JSX.Element => {
  const paramCourseID = useParams<{ course_id: string }>().course_id;
  // undefined許容
  const pathCourseID = paramCourseID ? Number(paramCourseID) : undefined;
  if (Number.isNaN(pathCourseID)) return NotFoundPage;

  return <InstructorSession courseID={pathCourseID} />;
};

interface InstructorSessionProps {
  courseID: number | undefined;
}

const InstructorSession = (props: InstructorSessionProps): JSX.Element => {
  const metaTitle = `セッション | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のセッションページです。セッション日の確認やこれまでのセッション履歴を確認できます。`;

  const { permissionCheck } = useUser();
  const navigate = useNavigate();
  const { showToast } = useToastsContext();

  const [isOpenInputModal, setIsOpenInputModal] = useState(false);
  const [courseStatus, setCourseStatus] = useState<number | undefined>();
  const [courseId, setCourseId] = useState<number | undefined>(props.courseID);

  // 入力モーダル開閉
  const openInputModal = useCallback((): void => setIsOpenInputModal(true), []);
  const closeInputModal = useCallback((): void => setIsOpenInputModal(false), []);

  // 絞り込み用のコース一覧取得
  const { data: coursesData } = useGetCoursesForInstructorCourseListQuery({
    variables: {
      input: {
        type: CourseSearchType.Coaching,
        status: courseStatus ? [courseStatus] : undefined,
      },
    },
  });

  const coachingID = useMemo(() => {
    const course = coursesData?.coursesForInstructor?.find(({ id }) => id === courseId);
    return course?.coaching?.id;
  }, [coursesData?.coursesForInstructor, courseId]);

  // セッション一覧取得
  const {
    data: sessionsData,
    loading: sessionLoading,
    refetch: refetchSession,
  } = useGetSessionsForInstructorQuery({
    variables: {
      input: { coachingID, courseStatus },
    },
  });

  const [createSessionMutation, { loading: creating }] = useCreateSessionMutation();
  const [updateSessionMutation, { loading: updating }] = useUpdateSessionMutation();

  // セッション登録
  const createSession = useSafeAsyncCallback(
    useCallback(
      async (input: SessionInput): Promise<void> => {
        // 基本的にはありえないが、updateと型を使い回すためにoptionalにしているのでチェック
        if (!input.coachingID) {
          return;
        }
        try {
          await createSessionMutation({
            variables: {
              coachingID: input.coachingID,
              input: {
                title: input.title,
                startAt: input.startAt,
                endAt: input.endAt,
              },
            },
          });
          await refetchSession();
        } catch (e) {
          // HACK: APIのエラーについてはInputのモーダルまで引き回すと手間がかかるので一旦Toastで表示するが、
          // どうしてもモーダル上に表示したい要望や、簡単に対応する方法があれば改修する
          showToast(1, getApiErrorMessage(e));
          return;
        }

        closeInputModal();
        showToast(0, 'セッションの登録が完了しました。');
      },
      [createSessionMutation, closeInputModal, refetchSession, showToast],
    ),
  );

  // セッション更新
  const updateSession = useSafeAsyncCallback(
    useCallback(
      async (id: string, input: SessionInput): Promise<void> => {
        try {
          await updateSessionMutation({
            variables: {
              id,
              input: {
                title: input.title,
                startAt: input.startAt,
                endAt: input.endAt,
              },
            },
          });
          await refetchSession();
        } catch (e) {
          // HACK: APIのエラーについてはInputのモーダルまで引き回すと手間がかかるので一旦Toastで表示するが、
          // どうしてもモーダル上に表示したい要望や、簡単に対応する方法があれば改修する
          showToast(1, getApiErrorMessage(e));
          return;
        }
        showToast(0, 'セッションの更新が完了しました。');
      },
      [updateSessionMutation, refetchSession, showToast],
    ),
  );

  // セッション受講履歴登録後のrefetch処理
  const onRefetchSession = useSafeAsyncCallback(
    useCallback(async () => {
      await refetchSession();
    }, [refetchSession]),
  );

  // 予定のセッション数、完了済みセッション数を取得(複数コースの合算値)
  const [plannedSessionCount, finishedSessionCount] = useMemo(
    () =>
      coursesData?.coursesForInstructor?.reduce(
        ([planned, finished], { coaching }) => [
          planned + (coaching?.plannedSessionCount ?? 0),
          finished + (coaching?.finishedSessionCount ?? 0),
        ],
        [0, 0],
      ) ?? [0, 0],
    [coursesData?.coursesForInstructor],
  );

  // 絞り込みのコースステータスを変更
  const handleChangeCourseStatus = useCallback((e: { target: { value: string } }): void => {
    const courseStatus = parseInt(e.target.value);
    setCourseStatus(!isNaN(courseStatus) ? courseStatus : undefined);
    setCourseId(undefined);
  }, []);

  // すべての予定をクリック
  const handleClickAllSession = useCallback((): void => {
    if (courseId !== undefined) {
      setCourseId(undefined);
      navigate('/sessions/');
    }
  }, [courseId, navigate]);

  // 絞り込みのコース変更
  const changeCourse = useCallback(
    (nextCourseId: number): void => {
      if (courseId !== nextCourseId) {
        setCourseId(nextCourseId);
        navigate(`/sessions/${nextCourseId}`);
      }
    },
    [courseId, navigate],
  );

  const isSelectedCourse = !!courseId;

  return (
    <BasicLayout pageTitle="セッション" metaTitle={metaTitle} metaDescription={metaDescription}>
      <Loader display={sessionLoading || creating || updating} />
      <Container>
        <Left $hideWhenLessThanMedium={isSelectedCourse}>
          <ButtonContainer>
            <AddSessionButton onClick={openInputModal}>＋セッションを登録</AddSessionButton>
          </ButtonContainer>
          <AllCourse $active={!isSelectedCourse} onClick={handleClickAllSession}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.6 1.6V4.2H18.4V1.6H1.6ZM1.6 18.4V5.8H18.4V18.4H1.6ZM1 0C0.447715 0 0 0.447715 0 1V19C0 19.5523 0.447715 20 1 20H19C19.5523 20 20 19.5523 20 19V1C20 0.447715 19.5523 0 19 0H1ZM12.38 13.53C12.38 14.89 11.19 15.68 9.78005 15.68C8.61005 15.68 7.82005 15.24 7.30005 14.65L7.99005 13.72C8.43005 14.15 8.97005 14.48 9.64005 14.48C10.39 14.48 10.9 14.11 10.9 13.45C10.9 12.72 10.47 12.27 8.86005 12.27V11.21C10.22 11.21 10.66 10.74 10.66 10.08C10.66 9.5 10.3 9.16 9.67005 9.15C9.14005 9.16 8.71005 9.41 8.27005 9.81L7.52005 8.91C8.16005 8.35 8.87005 8 9.74005 8C11.17 8 12.14 8.7 12.14 9.97C12.14 10.77 11.69 11.35 10.9 11.66V11.71C11.74 11.94 12.38 12.56 12.38 13.53Z"
                fill="black"
              />
            </svg>
            <p>すべての予定</p>
          </AllCourse>
          <div>
            <StatusList name="status" onChange={handleChangeCourseStatus}>
              <option value="">すべて</option>
              {Object.entries(CourseStatusLabels).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </StatusList>
          </div>
          <CourseList
            changeCourse={changeCourse}
            coursesData={coursesData?.coursesForInstructor ?? []}
            currentCourseID={courseId ?? 0}
          />
        </Left>
        <Right $hideWhenLessThanMedium={!isSelectedCourse}>
          {/* ローディング時に未登録のメッセージがチラつくと気になるので、ローディング時はコンポーネントをアンマウントする */}
          {!sessionLoading && (
            <SessionList
              permissionCheck={permissionCheck}
              sessions={sessionsData?.sessionsForInstructor ?? []}
              plannedSessionCount={plannedSessionCount}
              finishedSessionCount={finishedSessionCount}
              onUpdateSession={updateSession}
              onRefetchSession={onRefetchSession}
            />
          )}
        </Right>
      </Container>
      {permissionCheck(FunctionType.SessionForCoach, PermissionType.Create) && (
        <SessionInputModal
          isOpen={isOpenInputModal}
          type="add"
          defaultInput={{
            coachingID,
          }}
          onSubmit={createSession}
          onClose={closeInputModal}
        />
      )}
    </BasicLayout>
  );
};

const Container = styled.div<{ isOpen?: boolean }>`
  height: 100%;
  display: flex;
`;

const AllCourse = styled.div<{ $active: boolean }>`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  padding: 1rem 1.4rem;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  ${({ $active }) => $active && `background-color: rgba(253, 60, 47, 0.05);`}

  p {
    margin-left: 0.6rem;
    font-weight: 600;
  }

  svg {
    stroke: #000;
    stroke-opacity: 0.87;
    fill-opacity: 0.87;
    stroke-width: 1;
    stroke-linejoin: round;
    cursor: pointer;
  }

  svg.withfill {
    fill: #000;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
const AddSessionButton = styled(Button)`
  padding: 0.9375rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
`;

const Left = styled.section<{ $hideWhenLessThanMedium: boolean }>`
  position: sticky;
  top: 66px;
  width: 25%;
  min-width: 230px;
  max-width: 360px;
  height: calc(100vh - 66px);
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: none;
    background: #fff;
  `}

  ${(props) => props.$hideWhenLessThanMedium && media.lessThan('medium')`display: none;`}
`;

const StatusList = styled.select`
  height: 2rem;
  width: 100%;
`;

const Right = styled.section<{ $hideWhenLessThanMedium: boolean }>`
  flex: 1;
  flex-grow: 1;

  ${media.lessThan('medium')`
    width: 100%;
    flex: none;
    transition: all 0.2s;
    background-color: #ffffff;
  `}

  ${(props) => props.$hideWhenLessThanMedium && media.lessThan('medium')`display: none;`}
`;
