import React, { useEffect } from 'react';

interface CvFelmatProps {
  userID: number;
}

export const CvFelmat: React.FC<CvFelmatProps> = (props) => {
  // こちらのタスクで依頼 https://samurai-7s.backlog.com/view/LMS-1326
  // タグ一覧(https://docs.google.com/spreadsheets/d/1zE7MSsf4DhM8NpvbQBElisoON9-Sm5Ldw5CVkprGGUY/edit#gid=2139083905)

  useEffect(() => {
    const h = document.getElementsByTagName('head')[0] as HTMLElement;

    const s1 = document.createElement('script');
    s1.id = 'felmat_1';
    s1.src = `https://js.felmat.net/fmcv.js?adid=W58858&uqid=${props.userID}&item=kakin.1.0`;
    s1.async = true;
    h.appendChild(s1);

    return () => {
      document.getElementById('felmat_1')?.remove();
    };
  }, [props.userID]);

  return null;
};
