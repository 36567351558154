export const progress: (numerator: number, denominator: number) => number = (
  numerator,
  denominator,
) => {
  return numerator <= 0
    ? 0
    : numerator > denominator
      ? 100
      : Math.round((numerator / denominator) * 100);
};
