import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import styled from 'styled-components';
import media from 'styled-media-query';
import { pipe } from 'fp-ts/function';
import { fromNullable } from 'fp-ts/Option';
import { map } from 'fp-ts/es6/Option';
import { useGetTagsQuery, TagFragment, TagType } from '../../gen/graphql';

export interface SpotLessonStep1CheckBoxProps {
  onChange: (inputTags: TagFragment[], activeNoChoose: boolean) => void;
  setLoading: (isLoading: boolean) => void;
}

export const SpotLessonReserveStep1CheckBox: React.FC<SpotLessonStep1CheckBoxProps> = (props) => {
  interface CheckBoxValue {
    active: boolean;
    tag: TagFragment;
  }

  const [checkboxState, setCheckboxState] = React.useState<Map<number, CheckBoxValue>>(new Map());

  const createTagIdToTag = (list: TagFragment[]) => {
    const tagIdToTag = new Map<number, CheckBoxValue>(
      list.map((e) => [
        e.id ? e.id : 0,
        {
          active: false,
          tag: e,
        },
      ]),
    );
    return tagIdToTag;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    pipe(
      fromNullable(checkboxState.get(parseInt(event.target.value))?.tag),
      //ここでnew MapしないとReact側のdiffアルゴリズム的に更新がトリガーされず、checkboxのcheckなどが反映されない。
      //https://ichi.pro/reactstate-fukku-de-es-6-mappu-o-shiyosuru-140787181346143
      map((t) => {
        setCheckboxState(
          new Map(
            checkboxState.set(parseInt(event.target.value), {
              active: event.target.checked,
              tag: t,
            }),
          ),
        );

        props.onChange(
          activeNoChoose() ? [] : getActiveChecks().map((e) => e.tag),
          activeNoChoose(),
        );
      }),
    );
  };

  const getActiveChecks = () => Array.from(checkboxState.values()).filter((x) => x.active);

  const disableCheckbox = (selected: number) =>
    getActiveChecks().length >= 2 &&
    !(checkboxState.get(selected)?.active ? checkboxState.get(selected)?.active : false);

  const activeNoChoose = () => checkboxState.get(0)?.active ?? false;

  const disableStep1Checkbox = (selected: number) => {
    if (selected != 0 && activeNoChoose()) {
      return true;
    }
    return disableCheckbox(selected);
  };

  const activeFlag = (active: boolean) => !activeNoChoose || active;

  const getStyledCheckbox = () =>
    Array.from(checkboxState).map((e: [number, CheckBoxValue]) => (
      <StyledCheckbox key={e[0]} active={activeFlag(e[1].active)}>
        <FormControlLabel
          //https://material-ui.com/components/checkboxes/
          key={e[0]}
          control={<Checkbox />}
          disabled={disableStep1Checkbox(e[0])}
          label={e[1].tag?.name ? e[1].tag?.name : '選択できません'}
          value={e[0]}
          checked={activeFlag(e[1].active)}
          onChange={handleChange}
        />
      </StyledCheckbox>
    ));

  useGetTagsQuery({
    variables: {
      input: {
        type: TagType.SpotLesson,
      },
    },
    onCompleted: (data) => {
      setCheckboxState(createTagIdToTag(data.getTags.items));
    },
  });

  return (
    <React.Fragment>
      <InputLanguage>
        <StyledFormGroup row>{getStyledCheckbox()}</StyledFormGroup>
      </InputLanguage>
    </React.Fragment>
  );
};

const StyledCheckbox = styled.div<{ active: boolean }>`
  width: calc((100% - 3rem) / 4);
  border: 1px solid ${(props) => (props.active ? '#e2001b' : 'rgba(0,0,0,0.1)')};
  border-radius: 150px;
  box-sizing: border-box;

  label {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.375rem 1rem 0.375rem 0.625rem;

    span {
      font-size: 0.875rem;
    }
  }

  input {
    display: none;
  }

  ${media.lessThan('large')`
    width: calc((100% - 2rem) / 3);
  `}

  ${media.lessThan('medium')`
    width: calc((100% - .75rem) / 2);

    label {
      padding: 0 1rem 0 .625rem;
    }
  `}

  ${media.lessThan('small')`
    label {
      padding: 0 .5rem 0 0;
    }
  `}
`;

const InputLanguage = styled.div`
  margin-top: 1rem;
  flex-direction: column;

  ${media.lessThan('medium')`
    margin-top: 1.5rem;
  `}
`;

const StyledFormGroup = styled(FormGroup)`
  gap: 1rem;

  ${media.lessThan('medium')`
    gap: .75rem;
  `}

  ${media.lessThan('small')`
    gap: .75rem .5rem;
  `}
`;
