import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { ToolTip } from '../atoms/ToolTip';

import IconInformation from '../../static/image/icon_information.svg';

interface Props {
  description: string;
}

export const InformationPopupIcon: React.FC<Props> = (props) => {
  const [isHover, setIsHover] = React.useState(false);

  return (
    <Container
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={() => {
        setIsHover(!isHover);
      }}
    >
      <Image src={IconInformation} alt="" />
      <StyledToolTip isActive={isHover} name={props.description} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;
const Image = styled.img`
  display: block;
  width: 1.25rem;
  height: auto;
`;
const StyledToolTip = styled(ToolTip)<{ isActive: boolean }>`
  display: none;
  width: 373px;
  white-space: normal;
  left: 50%;
  top: auto;
  bottom: calc(100% + 10px);
  transform: translateX(-50%);

  &:before {
    margin: auto;
    left: 0;
    right: 0;
    top: 100%;
    transform: rotate(180deg);
  }

  ${media.lessThan('medium')`
    width: 267px;
  `}

  ${(props) =>
    props.isActive &&
    `
    display: block;
  `}
`;
