import React, { useContext } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { MenuContext } from '../../context/MenuProvider';

interface HamburgerProps {
  hamburgerRef: React.RefObject<HTMLDivElement>;
}

export const Hamburger: React.FC<HamburgerProps> = (props) => {
  const { toggle } = useContext(MenuContext);

  return (
    <Box onClick={() => toggle()} ref={props.hamburgerRef}>
      <Line />
    </Box>
  );
};

const Box = styled.div`
  display: none;
  position: relative;
  width: 28px;
  height: 18px;
  margin-right: 1rem;

  ${media.lessThan('medium')`
    display: block;
  `}

  ${media.lessThan('small')`
    margin-right: 0.875rem;
  `}
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
  }
`;

const Line = styled.div`
  position: absolute;
  width: 100%;
  top: calc(50% - 1px);
  height: 2px;
  background-color: #000;
`;
