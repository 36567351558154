import { FormEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useGetFeedbackCategoriesQuery, FeedbackCategoryType } from '../../gen/graphql';
import { Loader } from '../molecules/Loader';
import { Select } from '../atoms/Select';
import { Button } from '../atoms/Button';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { ControlledTextArea } from '../atoms/ControlledTextArea';
import { useToastsContext } from '../../context/ToastsProvider';

type Props = {
  title: string;
  onSubmit: (data: FeedbackFormValue) => void;
  type: FeedbackCategoryType;
  backButton?: JSX.Element;
};

export type FeedbackFormValue = {
  category: string;
  content: string;
};

/**
 * ご意見・ご要望フォーム
 *
 * @description 使用する際は FormProvider でラップしてください
 */
export const FeedbackForm = (props: Props): JSX.Element => {
  const { title, onSubmit, backButton } = props;
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sending = useRef(false);

  const { showToast } = useToastsContext();

  const { data: feedbackCategoriesData } = useGetFeedbackCategoriesQuery({
    variables: {
      input: {
        first: 100, // 実際には100件以上のカテゴリーが存在する可能性は限りなく低いため、100を指定して、全件取得する
        after: 0,
        type: props.type,
      },
    },
  });

  const categoryOptions = [
    { name: '選択してください', value: '0' },
    ...(feedbackCategoriesData?.feedbackCategories.items ?? []).map((item) => {
      return {
        name: item.name,
        value: item.id.toString(),
      };
    }),
  ];

  const {
    handleSubmit: handleSubmitForm,
    watch,
    register,
    control,
  } = useFormContext<FeedbackFormValue>();
  const category = watch('category');
  const categoryName = categoryOptions.find((item) => item.value === category)?.name;
  const content = watch('content');

  const onError = (errors: FieldErrors<FeedbackFormValue>) => {
    if (errors.category) {
      showToast(1, 'カテゴリーを選択してください');
      throw new Error('カテゴリーを選択してください');
    }
    showToast(1, '本文を入力してください');
    throw new Error('本文を入力してください');
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (sending.current) {
      return;
    }
    sending.current = true;
    setIsSubmitting(true);

    try {
      await handleSubmitForm(onSubmit, onError)();
      setIsSubmitting(false);
      setSubmitted(true);
    } catch (e) {
      setIsSubmitting(false);
      sending.current = false;
    } finally {
      setIsSubmitting(false);
      sending.current = false;
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h3>
          {submitted ? 'ありがとうございます。下記の内容でご意見・ご要望を承りました' : title}
        </h3>
        {submitted && (
          <Thanks>
            入力した内容でご意見・ご要望を承りました。頂いたご要望を元に改善させていただきます。
          </Thanks>
        )}
        <WithTitle>
          <div className="title">
            <p>カテゴリー</p>
          </div>
          <div className="content">
            {submitted ? (
              <p>{categoryName}</p>
            ) : (
              <Controller
                control={control}
                name="category"
                render={({ field }) => {
                  return (
                    <Select
                      name="category"
                      options={categoryOptions}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  );
                }}
              />
            )}
          </div>
        </WithTitle>
        <WithTitle>
          <div className="title">
            <p>ご意見・ご要望</p>
          </div>
          <div className="content">
            {submitted ? <p>{content}</p> : <StyledTextArea {...register('content')} />}
          </div>
        </WithTitle>
        {submitted ? (
          backButton
        ) : (
          <>
            <Submit type="submit">
              <p>送信</p>
            </Submit>
            <AttentionNote>
              <p>
                ※個別の返信はしておりません。返信が必要な場合は、カスタマーサポートにご連絡ください。
              </p>
            </AttentionNote>
          </>
        )}
      </Form>
      <Loader display={isSubmitting} />
    </>
  );
};

const Form = styled.form`
  width: 788px;
  padding: 32px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  h3 {
    font-size: 1.25rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 48px;
  }

  ${media.lessThan('medium')`
    width: 100%;
    padding: 32px 16px;
    box-sizing: border-box;

    h3 {
      font-size: 1.25rem;
      line-height: 1.5;
      margin-bottom: 24px;
    }
  `}
`;

const WithTitle = styled.div`
  display: flex;
  align-items: flex-start;

  & + * {
    margin-top: 24px;
  }

  .title {
    width: 192px;
    min-width: 192px;
    padding-top: 0.5rem;

    p {
      font-size: 1rem;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .content {
    width: 100%;

    p {
      padding: 0.5rem;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  ${media.lessThan('medium')`
    flex-direction: column;

    .content {
      margin-top: 8px;
    }
  `}
`;

const Submit = styled(Button)`
  display: block;
  width: 100%;
  max-width: 320px;
  margin: 2rem auto 0;
`;

const StyledTextArea = styled(ControlledTextArea)`
  width: 520px;
  height: 200px;

  ${media.lessThan('medium')`
    width: 100%;
    height: 140px;
  `}
`;

const Thanks = styled.p`
  font-size: 0.8rem;
  margin: 48px 0 24px 0;
  line-height: 1.6;

  ${media.lessThan('medium')`
    margin: 12px 0;
  `}
`;

const AttentionNote = styled.span`
  color: rgba(0, 0, 0, 0.87);

  p {
    margin-top: 2rem;
    font-size: 0.75rem;
    line-height: 1.2em;
  }
`;
