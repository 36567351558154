import React, { useCallback } from 'react';
import { useUser } from '../../../redux/user/useUser';

import { InvoiceList } from '../../organisms/InvoiceList';

import { useGetTeamInvoicesQuery } from '../../../gen/graphql';
import { Loader } from '../../molecules/Loader';
import { MIN_LIMIT } from '../../../const/Page';
import { TeamSettingTabLayout } from '../../templates/TeamSettingTabLayout';
import { LOWER_META_TITLE } from '../../../const/Service';

export const TeamInvoice: React.FC = () => {
  const metaTitle = `請求情報 | ${LOWER_META_TITLE}`;

  const { user } = useUser();

  const { data, loading, refetch } = useGetTeamInvoicesQuery({
    variables: {
      input: {
        teamID: user.teamID ?? '',
        limit: MIN_LIMIT,
      },
    },
    skip: !user.teamID,
    notifyOnNetworkStatusChange: true,
  });
  const invoices = data?.teamInvoices.items ?? [];
  const nextCursor = data?.teamInvoices.nextCursor ?? '';
  const previousCursor = data?.teamInvoices.previousCursor ?? '';
  const hasPrevious = data?.teamInvoices.hasPrevious ?? false;
  const hasNext = data?.teamInvoices.hasNext ?? false;
  const fetchNextTeamInvoices = useCallback(async () => {
    refetch({
      input: {
        teamID: user.teamID ?? '',
        nextCursor: nextCursor,
        limit: MIN_LIMIT,
      },
    });
  }, [refetch, user.teamID, nextCursor]);
  const fetchPreviousTeamInvoices = useCallback(async () => {
    refetch({
      input: {
        teamID: user.teamID ?? '',
        previousCursor: previousCursor,
        limit: MIN_LIMIT,
      },
    });
  }, [refetch, user.teamID, previousCursor]);

  return (
    <>
      <Loader display={loading} />
      <TeamSettingTabLayout activeTab="invoice" metaTitle={metaTitle}>
        {!loading && (
          <InvoiceList
            invoices={invoices}
            hasPrevious={hasPrevious}
            hasNext={hasNext}
            fetchNextInvoices={fetchNextTeamInvoices}
            fetchPreviousInvoices={fetchPreviousTeamInvoices}
          />
        )}
      </TeamSettingTabLayout>
    </>
  );
};
