import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';
import { QuestionFilter } from './QuestionFilter';

import {
  ChapterSearchFragment,
  PracticeSearchFragment,
  ProgramSearchType,
  ProgramType,
  useGetTagsLazyQuery,
  SearchQuestionStatus,
  useSearchProgramsQuery,
  TagOrder,
} from '../../gen/graphql';
import { chapterOrPracticeOptionValue } from '../../utils/Filter';
import { getChapters, getPractices } from '../../utils/Program';
import { FIRST_PAGE, MAX_LIMIT, MIN_LIMIT } from '../../const/Page';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { INITIAL_ULID } from '../../const/Filter';
import { setParamsValue, useURL } from '../../common/customHooks/URL';
import { strToEnum } from '../../utils/common';

interface QuestionFilterModalProps {
  isOpen: boolean;
  loading?: boolean;
  toggle: (nextState: boolean) => void;
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  sortKey: string;
  answered: number;
  solved: number;
  searchQuestionStatus: SearchQuestionStatus;
  isStudent?: number;
  programID?: number;
  chapterID?: number;
  practiceID?: string;
  searchTag: string;
  setSearchTag: (searchTag: string) => void;
  tagID: number;
  // 教材＆章・課題QA用プロパティ
  forProgramAndChapterOrPractice?: {
    chapters: ChapterSearchFragment[];
    practices: PracticeSearchFragment[];
  };
}

export const QuestionFilterModal: React.FC<QuestionFilterModalProps> = (props) => {
  const { setParams } = useURL();

  const [sortKey, setSortKey] = useState<string>(props.sortKey);
  const [answered, setAnswered] = useState<number>(props.answered);
  const [solved, setSolved] = useState<number>(props.solved);
  const [searchQuestionStatus, setSearchQuestionStatus] = useState<SearchQuestionStatus>(
    props.searchQuestionStatus,
  );
  const [programID, setProgramID] = useState<number | undefined>(props.programID);
  const [chapterID, setChapterID] = useState<number | undefined>(props.chapterID);
  const [practiceID, setPracticeID] = useState<string | undefined>(props.practiceID);
  const [searchTag, setSearchTag] = useState<string>(props.searchTag);
  const [tagID, setTagID] = useState<number>(props.tagID);

  const isProgramTypeNormal = props.permissionCheck(
    FunctionType.ProgramForInstructorAndCoach,
    PermissionType.Read,
  );

  const { data: programsData } = useSearchProgramsQuery({
    variables: {
      input: {
        programSearchType: ProgramSearchType.All,
        type: isProgramTypeNormal ? ProgramType.Normal : undefined,
        page: FIRST_PAGE,
        limit: MAX_LIMIT,
      },
    },
  });
  const programs = programsData?.programs;

  const [getTags, { data: tagData }] = useGetTagsLazyQuery();
  const tagsData = tagData?.getTags;
  const fetchTags = useCallback(
    (name: string) => {
      getTags({
        variables: {
          input: {
            name,
            limit: MIN_LIMIT,
            page: FIRST_PAGE,
            order: TagOrder.QuestionCount,
          },
        },
      });
    },
    [getTags],
  );

  useEffect((): void => {
    fetchTags(searchTag);
  }, [searchTag, fetchTags]);

  const closeModal = () => {
    props.toggle(false);
  };

  const handleSortKeySelect = (value: string) => {
    setSortKey(value);
  };

  const handleAnsweredSelect = (value: string) => {
    setAnswered(parseInt(value));
  };

  const handleSolvedSelect = (value: string) => {
    setSolved(parseInt(value));
  };

  const handleSearchQuestionStatusSelect = (value: string) => {
    setSearchQuestionStatus(strToEnum(value, SearchQuestionStatus) ?? SearchQuestionStatus.All);
  };

  const handleProgramIDSelect = (value: string) => {
    const selectProgramID = parseInt(value);
    setProgramID(selectProgramID);
    setChapterID(0);
    setPracticeID(INITIAL_ULID);
  };

  const handleChapterIDOrPracticeIDSelect = (value: string) => {
    const [chapterId, practiceId] = chapterOrPracticeOptionValue(value);
    setChapterID(parseInt(chapterId));
    setPracticeID(practiceId);
  };

  const handleSearchTagSet = (value: string) => {
    setSearchTag(value);
  };

  const handleTagReset = () => {
    setTagID(0);
  };

  const handleTagIDSelect = (value: number) => {
    setTagID(value !== tagID ? value : 0);
  };

  const submit = () => {
    const params = [];

    params.push(setParamsValue('sort_key', sortKey));
    params.push(setParamsValue('answered', answered));
    params.push(setParamsValue('solved', solved));
    params.push(setParamsValue('search_question_status', searchQuestionStatus));
    params.push(setParamsValue('program_id', programID));
    params.push(setParamsValue('chapter_id', chapterID));
    params.push(setParamsValue('practice_id', practiceID));
    params.push(setParamsValue('tag_id', tagID));
    // 検索条件変更時はpageをリセット
    params.push(setParamsValue('page'));

    props.setSearchTag(searchTag);
    setParams(params);
    closeModal();
  };

  const questionFilterProps = {
    permissionCheck: props.permissionCheck,
    title: 'フィルター',
    displaySortKey: {
      sortKey,
      handleSortKeySelect,
    },
    displayAnswered: {
      answered,
      handleAnsweredSelect,
    },
    displaySolved: {
      solved,
      handleSolvedSelect,
    },
    displaySearchQuestionStatus: {
      searchQuestionStatus,
      handleSearchQuestionStatusSelect,
    },
    displayProgram:
      programID !== undefined
        ? { programs: programs?.items ?? [], programID, handleProgramIDSelect }
        : undefined,
    displayChapterOrPractice:
      chapterID !== undefined || practiceID !== undefined
        ? {
            chapters:
              // 教材＆章・課題QA画面用chaptersが設定されていなければ、本コンポーネントで取得したprogramsからprogramIDのchaptersを設定
              props.forProgramAndChapterOrPractice?.chapters ??
              getChapters(programs?.items.find((i) => i.id === programID)) ??
              [],
            chapterID: chapterID !== undefined ? chapterID : 0,
            practices:
              // 教材＆章・課題QA画面用practicesが設定されていなければ、本コンポーネントで取得したprogramsからprogramIDのpracticesを設定
              props.forProgramAndChapterOrPractice?.practices ??
              getPractices(programs?.items.find((i) => i.id === programID)) ??
              [],
            practiceID: practiceID !== undefined ? practiceID : '',
            handleChapterIDOrPracticeIDSelect,
          }
        : undefined,
    displayTag: {
      searchTag,
      handleSearchTagSet,
      tags: tagsData?.items ?? [],
      tagID,
      handleTagReset,
      handleTagIDSelect,
    },
  };

  return (
    <Modal
      underlayer
      isOpen={props.isOpen}
      onClose={closeModal}
      loading={props.loading}
      header={<Title>表示項目の変更</Title>}
      footer={<StyledButton onClick={() => submit()}>絞り込み</StyledButton>}
    >
      <QuestionFilter {...questionFilterProps} />
    </Modal>
  );
};

const Title = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 240px;
`;
