import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import { Stripe, StripeElements } from '@stripe/stripe-js';

import { useUser } from '../../../redux/user/useUser';

import Logo from '../../../static/image/logo.svg';

import { TOAST_TYPE_ERROR_PERSISTENT, useToastsContext } from '../../../context/ToastsProvider';
import { Loader } from '../../molecules/Loader';
import {
  PaymentMethodType,
  useCreateTeamMutation,
  useRegisterMutation,
  useTeamSubscribeMutation,
  TeamSubscribeFormInput,
} from '../../../gen/graphql';
import { TOAST_TYPE_ERROR } from '../../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { TeamRegisterCreation } from '../../organisms/TeamRegisterCreation';
import { TeamRegisterCompanyAndSubscription } from '../../organisms/TeamRegisterCompanyAndSubscription';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { sleep } from '../../../utils/common';
import {
  LmsStripeValidationError,
  StripePaymentServiceImpl,
  StripeSystemError,
} from '../../../infrastructure/externalService/StripePaymentService';
import { LoginModal } from '../../organisms/LoginModal';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { NoHeaderLayout } from '../../templates/NoHeaderLayout';
import { LOWER_META_TITLE } from '../../../const/Service';
import { sessionStorageSupport } from '../../../utils/sessionStorageSupport';

// try句で条件分岐するためのError
class SubscribeError extends Error {
  constructor(e: Error) {
    super(e.message);
  }
}

type UserTagInput = {
  tagID: number;
  term: number;
};

export type UserRegisterInput = {
  email: string;
  name: string;
  nickName: string;
  objective?: string | null;
  password: string;
  profile?: string | null;
  smrc?: string | null;
  tags?: Array<number | null> | null;
  userTags?: Array<UserTagInput | null> | null;
};

export type TeamRegisterInput = {
  adminEmail: string;
  companyName: string;
  billingCycleAnchor?: string | null;
  paymentMethodType: PaymentMethodType;
  priceID: number;
  quantity: number;
  teamID?: string | null;
  timezoneOffset: number;
};

type TeamRegisterSubmitInput = TeamRegisterInput & {
  providerPaymentMethodID?: string | null;
};

export const TeamRegister = (): JSX.Element => {
  const metaTitle = `チーム作成 | ${LOWER_META_TITLE}`;

  const location = useLocation();
  const step = location.pathname.split('/').slice(-1)[0];

  const navigate = useNavigate();
  const { showToast } = useToastsContext();
  const { user, login, changeTeamID, permissionCheck, refresh } = useUser();

  const [showLoader, setShowLoader] = useState(false);
  // 遷移するまでにタイムラグがあるため完了フラグを設定し、登録ボタンを押せないようにする。
  const [isCompleted, setIsCompleted] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  //Stripeでクレジットカード入力時に活用する項目
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [elements, setElements] = useState<StripeElements | null>(null);
  const [stripeValidationError, setStripeValidationError] =
    useState<LmsStripeValidationError | null>(null);
  const stripeService = useMemo(() => new StripePaymentServiceImpl(stripe), [stripe]);

  const [registerMutation] = useRegisterMutation();

  const [createTeam, teamResult] = useCreateTeamMutation({
    onCompleted: (data) => {
      changeTeamID(data.createTeam.id);
    },
    onError: (e) => {
      showToast(TOAST_TYPE_ERROR, getApiErrorMessage(e));
    },
  });
  const team = teamResult.data?.createTeam;

  const [subscribe] = useTeamSubscribeMutation();

  const submitUser = useSafeAsyncCallback(async (user: UserRegisterInput): Promise<void> => {
    setShowLoader(true);

    try {
      await registerMutation({ variables: { input: user } });
      await login(user.email, user.password);
    } catch (e) {
      showToast(TOAST_TYPE_ERROR, getApiErrorMessage(e));
      return;
    } finally {
      setShowLoader(false);
    }

    showToast(0, '管理者アカウントの登録に成功しました。');
    navigate('/team/register/company');
  });

  const submit = useSafeAsyncCallback(async (input: TeamRegisterSubmitInput): Promise<void> => {
    setStripeValidationError(null);
    setShowLoader(true);

    // TODO 法人のデモをデモ環境で実施できるようになり次第削除
    if (user.lmsUser?.id === 18348) {
      showToast(TOAST_TYPE_ERROR, 'デモアカウントのためチームを追加できません。');
      return;
    }

    try {
      // チーム情報作成
      const result = await createTeam({
        variables: {
          input: {
            adminEmail: input.adminEmail,
            companyName: input.companyName,
          },
        },
      });
      if (!result.data?.createTeam.id) {
        // 本来は戻り値でIDがNilになることはないが万が一のことを考慮してエラーを出す
        showToast(
          TOAST_TYPE_ERROR,
          '法人登録に失敗しました。すでに登録済みのユーザーにログインしなおしてから、再度法人登録をおこなってください。',
        );
        return;
      }

      // チーム作成後にRoleの再読込をする
      await refresh();

      // 決済情報登録
      const teamSubscribeFormInput: TeamSubscribeFormInput = {
        billingCycleAnchor: input.billingCycleAnchor,
        paymentMethodType: input.paymentMethodType,
        priceID: input.priceID,
        quantity: input.quantity,
        timezoneOffset: input.timezoneOffset,
        // コンポーネントに渡すと更新されないので、ここでTeamIDを設定する
        // TeamIDはチーム登録後処理に取得する
        teamID: result.data?.createTeam.id,
      };

      // クレカ登録の場合はStripeに決済情報を追加する
      if (input.paymentMethodType === PaymentMethodType.CreditCard) {
        const creditCardResult = await stripeService.checkCreditCard(O.fromNullable(elements));
        if (E.isLeft(creditCardResult)) {
          if (!(creditCardResult.left instanceof StripeSystemError)) {
            setStripeValidationError(creditCardResult.left);
          }
          throw new SubscribeError(creditCardResult.left);
        }
        teamSubscribeFormInput.providerPaymentMethodID = creditCardResult.right.paymentId;
      }

      await subscribe({
        variables: {
          input: teamSubscribeFormInput,
        },
      });
    } catch (e) {
      if (e instanceof SubscribeError) {
        showToast(
          TOAST_TYPE_ERROR_PERSISTENT,
          'お支払い方法の登録に失敗しました。後ほど登録してください。',
        );
        // すぐに遷移すると403が出るため待機する
        setIsCompleted(true);
        await sleep(1000);
        navigate('/team/subscription/payment');
        return;
      }

      showToast(TOAST_TYPE_ERROR, getApiErrorMessage(e));
      return;
    } finally {
      setShowLoader(false);
    }

    const paymentMethodTypeText =
      input.paymentMethodType === PaymentMethodType.CreditCard ? '決済' : '手続き';
    showToast(0, `${paymentMethodTypeText}が進行中です。3秒後に遷移します。`);
    setIsCompleted(true);
    await sleep(3000);

    sessionStorageSupport.setItem('REGISTERED_TYPE', 'new');
    navigate('/team/registered'); // 分析のためtoCとtoBでパスだけ変更している
  });

  useEffect(() => {
    if (permissionCheck(FunctionType.TeamRegisterRedirectHome, PermissionType.Read)) {
      navigate('/home');
      return;
    }

    if (permissionCheck(FunctionType.TeamRegisterCreation, PermissionType.Create)) {
      // 未ログインユーザーはアカウント作成から
      if (step !== 'creation') {
        navigate('/team/register/creation', { replace: true });
      }
    } else {
      // ログインユーザーは会社情報入力以降から
      if (step === 'creation') {
        navigate('/team/register/company', { replace: true });
      }
    }
  }, [navigate, step, team, permissionCheck]);

  const openLoginModal = useCallback(() => setLoginModalOpen(true), []);
  const closeLoginModal = useCallback(() => setLoginModalOpen(false), []);

  return (
    <NoHeaderLayout metaTitle={metaTitle}>
      <Loader display={showLoader || teamResult.loading} />
      <Header>
        <Link to="/">
          <img src={Logo} alt="Samurai" />
        </Link>
        {!user.lmsUser && <LoginLink onClick={openLoginModal}>ログイン</LoginLink>}
      </Header>

      <Main>
        <Container>
          <Step active={step === 'creation'}>
            <StepTitle>管理者アカウント作成</StepTitle>
            {step === 'creation' && (
              <RegisterStepContainer>
                <TeamRegisterCreation onSubmit={submitUser} onClickLogin={openLoginModal} />
              </RegisterStepContainer>
            )}
          </Step>
          <Step active={step === 'company'}>
            <StepTitle>会社情報の入力</StepTitle>
            {step === 'company' && (
              <CompanyStepContainer>
                <TeamRegisterCompanyAndSubscription
                  onSubmit={submit}
                  setStripe={setStripe}
                  setElements={setElements}
                  stripeValidationError={stripeValidationError}
                  isCompleted={isCompleted}
                />
              </CompanyStepContainer>
            )}
          </Step>
        </Container>
      </Main>
      <LoginModal
        isOpen={loginModalOpen}
        onClose={closeLoginModal}
        redirectURL="/team/register/company"
      />
    </NoHeaderLayout>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 67px;
  padding: 0 2rem;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  img {
    height: 1.5rem;
  }

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;
const LoginLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.3125rem;
  height: 2.375rem;
  border: 1px solid #e2001b;
  border-radius: 0.125rem;
  box-sizing: border-box;
  cursor: pointer;
  color: #e2001b;
  font-size: 0.875rem;
  font-weight: 700;
  transition: all 0.2s;

  &:after {
    content: 'する';
  }

  &:hover {
    background: #e2001b;
    color: #fff;
  }

  ${media.lessThan('medium')`
    width: 6.8125rem;
    height: 2rem;

    &:after {
      content: none;
    }
  `}
`;

const Main = styled.main`
  background-color: #fcfcfc;
  box-sizing: border-box;
  padding: 2rem;

  ${media.lessThan('medium')`
    padding: 2rem 0;
  `}
`;

const Container = styled.section`
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 39.5rem;
  counter-reset: step;
`;

const Step = styled.section<{ active: boolean }>`
  padding: 2rem 4rem;
  background: ${(props) => (props.active ? '#fff' : '#f5f5f5')};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;

  &:nth-child(n + 2) {
    margin-top: 2rem;
  }

  ${media.lessThan('medium')`
    padding: 2rem;
  `}
`;
const StepTitle = styled.h2`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 700;

  &:before {
    counter-increment: step;
    content: 'Step.' counter(step);
    display: block;
    width: 4rem;
    margin-right: 1.375rem;
  }

  ${media.lessThan('medium')`
    &:before {
      margin-right: .625rem;
    }
  `}
`;

const RegisterStepContainer = styled.div`
  margin-top: 1.375rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

const CompanyStepContainer = styled.div`
  margin-top: 0.875rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
