// フローティングアクションボタン、短縮ダイヤル（画面右下の質問するボタンなど）
export const Z_INDEX_FAB = 1050;

// ヘッダー
export const Z_INDEX_APPBAR = 1100;

// ドロワーメニュー
export const Z_INDEX_DRAWER = 1200;

// モーダル
export const Z_INDEX_MODAL = 1300;

// トースト、スナックバー
export const Z_INDEX_TOAST = 1400;

// ツールチップ
export const Z_INDEX_TOOLTIP = 1500;

// ローダー
export const Z_INDEX_LOADER = 1600;
