import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { isNonEmpty } from 'fp-ts/es6/Array';
import { useGetNoticesQuery, useReadAllNoticesMutation } from '../../../gen/graphql';
import { NotificationLayout } from '../../templates/NotificationLayout';
import { useNotificationUnreadCount } from '../../../common/customHooks/NotificationUnreadCount';
import { Loader } from '../../molecules/Loader';
import { Spinner } from '../../atoms/Spinner';
import { LOWER_META_TITLE } from '../../../const/Service';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../../context/ToastsProvider';
import { defaultErrorMessage } from '../../../const/ErrorMessage';

export const NoticePage: React.FC = (): JSX.Element => {
  const metaTitle = `アナウンス | ${LOWER_META_TITLE}`;

  const {
    notificationUnreadCount,
    noticeUnreadCount,
    refetch: refetchNotificationUnreadCount,
  } = useNotificationUnreadCount();
  const intersectionRef = useRef<HTMLDivElement>(null);

  const {
    data,
    loading,
    fetchMore,
    refetch: refetchNotices,
  } = useGetNoticesQuery({
    variables: {
      first: 20, // 一度に取得する件数
    },
  });
  const pageInfo = data?.notices.pageInfo;
  const notices = data?.notices.items ?? [];

  const [readAllNoticesMutation] = useReadAllNoticesMutation();
  const { showToast } = useToastsContext();

  const handleClick = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      try {
        await readAllNoticesMutation();
        refetchNotificationUnreadCount();
        refetchNotices();
      } catch (e) {
        showToast(1, defaultErrorMessage);
      }
    }, [readAllNoticesMutation, showToast, refetchNotificationUnreadCount, refetchNotices]),
  );

  const fetchMoreNotices = useCallback((): void => {
    if (!pageInfo?.hasNextPage) {
      return;
    }
    fetchMore({ variables: { after: pageInfo?.endCursor } });
  }, [pageInfo, fetchMore]);

  useEffect(() => {
    // NOTE: useEffectのcleanupでrefを直参照するとlintで怒られるので変数に入れている
    const currentIntersectionRef = intersectionRef.current;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        fetchMoreNotices();
      }
    });

    if (currentIntersectionRef) {
      observer.observe(currentIntersectionRef);
    }
    return () => {
      if (currentIntersectionRef) {
        observer.unobserve(currentIntersectionRef);
      }
    };
  }, [fetchMoreNotices]);

  return (
    <NotificationLayout
      activeTab="admin"
      notificationUnreadCount={notificationUnreadCount}
      noticeUnreadCount={noticeUnreadCount}
      metaTitle={metaTitle}
    >
      <Loader display={loading} />
      <Container>
        {isNonEmpty(notices) && (
          <>
            <ReadAllBlock>
              <ReadAllTypography onClick={() => handleClick()}>すべて既読にする</ReadAllTypography>
            </ReadAllBlock>
            <NoticeList>
              {notices.map((notice) => (
                <StyledLink
                  key={notice.id}
                  to={`/notification/admin/${notice.id}`}
                  $unread={!notice.noticeRead}
                >
                  <NoticeListItem>
                    <Title $unread={!notice.noticeRead}>{notice.title}</Title>
                    <DateText>{format(new Date(notice.createdAt), 'yyyy/MM/dd HH:mm')}</DateText>
                  </NoticeListItem>
                </StyledLink>
              ))}
              {pageInfo?.hasNextPage && (
                <NoticeListItem ref={intersectionRef}>
                  <Spinner size="small" />
                </NoticeListItem>
              )}
            </NoticeList>
          </>
        )}
      </Container>
    </NotificationLayout>
  );
};

const Container = styled.div`
  max-width: 70rem;
  padding: 2rem 2.875rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    max-width: 100%;
    padding: 3.1875rem 1rem;
  `}
`;

const NoticeList = styled.section`
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;

  ${media.lessThan('medium')`
    padding: 0;
    border: none;
  `}
`;

const NoticeListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 0 1.1875rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    flex-direction: column;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
  `}
`;

const StyledLink = styled(Link)<{ $unread: boolean }>`
  display: block;
  padding: 0 1.5rem;
  background: #${({ $unread }) => ($unread ? 'fff' : 'f8f8f8')};

  ${media.lessThan('medium')`
    padding: 0;
    border-radius: .25rem;
  `}

  &:not(:first-child) > ${NoticeListItem} {
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    ${media.lessThan('medium')`
      margin-top: 0.5rem;
    `}
  }
`;

const Title = styled.p<{ $unread: boolean }>`
  display: flex;
  align-items: center;
  width: 77%;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: ${({ $unread }) => ($unread ? '700' : '400')};
  color: ${({ $unread }) => ($unread ? 'rgba(0, 0, 0, 0.87)' : '#555')};

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

const DateText = styled.p`
  display: flex;
  align-items: center;
  font-size: 0.6875rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.36);
  line-height: 1.2;
  letter-spacing: 0.1px;

  ${media.lessThan('medium')`
    align-self: end;
    margin-top: 0.25rem;
  `}
`;

const ReadAllBlock = styled.div`
  text-align: right;
  margin-bottom: 1.2rem;
`;

const ReadAllTypography = styled.span`
  cursor: pointer;
  color: #e2001b;
  font-size: 0.875rem;
  font-weight: 700;
`;
