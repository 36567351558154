import React, { useContext } from 'react';
import styled from 'styled-components';
import { MenuContext } from '../../context/MenuProvider';

interface NavigationIconProps {
  children: React.ReactNode;
  active: boolean;
  withFill?: boolean;
}

export const NavigationIcon: React.FC<NavigationIconProps> = (props) => {
  const menuContext = useContext(MenuContext);

  return (
    <Svg
      active={props.active}
      withFill={props.withFill}
      isOpen={menuContext.isOpen}
      isHoverOpen={menuContext.isHoverOpen}
    >
      {props.children}
    </Svg>
  );
};

const Svg = styled.svg<{
  active: boolean;
  withFill?: boolean;
  isOpen: boolean;
  isHoverOpen: boolean;
}>`
  width: 20px;
  height: 20px;
  stroke-opacity: 0.87;
  fill-opacity: 0.87;
  stroke-width: 1.4;
  stroke-linejoin: round;
  stroke: ${(props) => (props.active ? '#e2001b' : '#000')};
  ${(props) => (props.withFill ? `fill: ${props.active ? '#e2001b' : '#000'}` : 'fill: none')};
  ${(props) => {
    if (props.isOpen || props.isHoverOpen) {
      return '';
    }
    return 'margin: auto 0.5rem auto auto;';
  }};
`;
