import React from 'react';
import styled from 'styled-components';

interface PagerProps {
  hasPrevious: boolean;
  fetchPrevious: () => void;
  hasNext: boolean;
  fetchNext: () => void;
}

const Pager: React.FC<PagerProps> = (props): JSX.Element => {
  return (
    <Container>
      <ItemLink active={props.hasPrevious} onClick={props.fetchPrevious}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9999 7.24389C14.9975 7.30617 14.9627 7.36566 14.9018 7.40935L8.76756 12.0003L14.9018 16.5913C14.9639 16.6359 14.9975 16.6972 14.9987 16.7614C14.9975 16.859 14.9192 16.9454 14.8 16.9826C14.6795 17.0188 14.5429 16.9975 14.4522 16.9278L8.09315 12.1686C7.96895 12.0756 7.96895 11.925 8.09315 11.8321L14.4522 7.07286C14.5429 7.00221 14.6832 6.98083 14.8037 7.01801C14.9242 7.05613 15.0024 7.14536 14.9999 7.24389Z"
            fill="#BE000A"
            stroke="#BE000A"
            strokeWidth="0.5"
          />
        </svg>
      </ItemLink>
      <ItemLink active={props.hasNext} onClick={props.fetchNext}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.00006 7.24389C9.00254 7.30617 9.03732 7.36566 9.09818 7.40935L15.2324 12.0003L9.09818 16.5913C9.03608 16.6359 9.00254 16.6972 9.0013 16.7614C9.00254 16.859 9.08079 16.9454 9.20002 16.9826C9.3205 17.0188 9.45712 16.9975 9.54778 16.9278L15.9068 12.1686C16.0311 12.0756 16.0311 11.925 15.9068 11.8321L9.54778 7.07286C9.45712 7.00221 9.31677 6.98083 9.19629 7.01801C9.07582 7.05613 8.99757 7.14536 9.00006 7.24389Z"
            fill="#BE000A"
            stroke="#BE000A"
            strokeWidth="0.5"
          />
        </svg>
      </ItemLink>
    </Container>
  );
};

export default Pager;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  margin-top: 2rem;
`;
const ItemLink = styled.div<{ active: boolean }>`
  width: 1.5rem;

  ${(props) => (props.active ? 'cursor: pointer;' : 'display: none;')}
`;
