import { Plan } from './Plan';
import { Student } from './Student';
import { Instructor } from './Instructor';
import { Lesson } from './Lesson';
import { CourseLessonType } from '../gen/graphql';

export interface Course {
  id?: number;
  plan?: Plan;
  student?: Student;
  instructor?: Instructor;
  dayOfWeek?: number;
  startDate?: string;
  endDate?: string;
  lessons?: Lesson[];
  absentStartDate?: Date;
  absentEndDate?: Date;
  curriculum?: string;
  plannedLessonCount?: number;
  status?: number;
  createdAt?: string;
  updatedAt?: string;
}

export const lessonTypeLabels: { [index: string]: string } = {
  [CourseLessonType.Online]: 'オンライン',
  [CourseLessonType.FaceToFace]: '対面希望',
} as const;
