// Q.解約手数料は発生しますか？
const CANCELLATION_FEE_LINK =
  'https://intercom.help/plus---samurai/ja/articles/6251120-q-%E8%A7%A3%E7%B4%84%E6%89%8B%E6%95%B0%E6%96%99%E3%81%AF%E7%99%BA%E7%94%9F%E3%81%97%E3%81%BE%E3%81%99%E3%81%8B';

// マンツー導線
const LEAD_TO_PRO_LINK = 'https://lp.sejuku.net/lp1';

// 解約FAQ
const CANCELLATION_FAQ_LINK =
  'https://intercom.help/plus---samurai/ja/collections/3073572-%E8%A7%A3%E7%B4%84-%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E5%89%8A%E9%99%A4-%E5%86%8D%E8%AA%B2%E9%87%91';

export { CANCELLATION_FEE_LINK, LEAD_TO_PRO_LINK, CANCELLATION_FAQ_LINK };
