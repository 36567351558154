import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { useNavigate } from 'react-router-dom';
import { Box } from '../atoms/Box';
import { ToolTip } from '../atoms/ToolTip';

interface Props {
  nextPriceID: number;
  period: number;
  price: number;
  recommend?: string;
  disable?: boolean;
}

export const AccountSubscriptionPlanCard: React.FC<Props> = ({
  nextPriceID,
  period,
  price,
  recommend,
  disable,
}) => {
  const navigate = useNavigate();

  const nextPage = () => {
    navigate(`/account/subscription/select/submit/${nextPriceID}`);
  };

  return (
    <React.Fragment>
      <Container padding={[5, 8, 4.5]} spPadding={[6, 4, 4]} radius="large" article>
        {!!recommend && <Recommend>{recommend}</Recommend>}
        <Period gray={period === 1}>{period}</Period>
        <PriceArea>
          <div>
            <Price gray={period === 1}>{price.toLocaleString()}</Price>
            <Tax gray={period === 1}>税込</Tax>
          </div>
        </PriceArea>
        <ButtonWrapper>
          {!disable ? (
            <Button onClick={nextPage}>プランを選択する</Button>
          ) : (
            <Button disable={true}>
              <StyledToolTip name="現在のプランからの変更がないため選択できません" />
              現在契約中のプラン
            </Button>
          )}
        </ButtonWrapper>
      </Container>
    </React.Fragment>
  );
};

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  position: relative;

  &:nth-of-type(n + 2) {
    margin-top: 2rem;
  }

  ${media.lessThan('medium')`
    flex-wrap: wrap;
    gap: .75rem;

    &:nth-of-type(n + 2) {
      margin-top: 1rem;
    }
  `}
`;

const Recommend = styled.p`
  padding: 0.5rem;
  background: #ff3e3e;
  border-radius: 0.125rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  position: absolute;
  top: -0.5rem;
  left: 2rem;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.25rem 0.25rem 0 0.25rem;
    border-color: #ff3e3e transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 0.625rem;
  }

  ${media.lessThan('medium')`
    font-size: .875rem;
    left: .75rem;
  `}
`;

const Period = styled.p<{ gray: boolean }>`
  flex: 1;
  margin-left: 2rem;
  color: ${(props) => (props.gray ? 'rgba(0,0,0,.5)' : 'rgba(0,0,0,.87)')};
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;

  &:after {
    content: 'ヶ月';
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1;
  }

  ${media.lessThan('medium')`
    margin-left: 1rem;
    font-size: 2rem;
    line-height: 1em;

    &:after {
      font-size: .75rem;
    }
  `}
`;

const PriceArea = styled.div`
  flex: 2;
  display: flex;

  ${media.lessThan('medium')`
    flex: none;
  `}
`;

const Price = styled.p<{ gray: boolean }>`
  color: ${(props) => (props.gray ? 'rgba(0,0,0,.5)' : '#eb0000')};
  font-size: 3.75rem;
  font-weight: 500;
  line-height: 1em;

  &:after {
    content: '円/月';
    color: ${(props) => (props.gray ? 'rgba(0,0,0,.5)' : 'rgba(0,0,0,.87)')};
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1em;
  }

  ${media.lessThan('medium')`
    font-size: 2.25rem;

    &:after {
      font-size: .75rem;
    }
  `}
`;

const Tax = styled.p<{ gray: boolean }>`
  color: ${(props) => (props.gray ? 'rgba(0,0,0,.5)' : 'rgba(0,0,0,.87)')};
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: right;

  ${media.lessThan('medium')`
    font-size: .625rem;
    line-height: .875rem;
  `}
`;

const ButtonWrapper = styled.div`
  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

const StyledToolTip = styled(ToolTip)`
  display: none;
  width: 200px;
  white-space: break-spaces;
  top: auto;
  bottom: calc(100% + 0.25rem);
  left: 0;
  right: 0;

  &:before {
    margin: auto;
    transform: rotate(180deg);
    top: 100%;
    left: 0;
    right: 0;
  }
`;

const Button = styled.p<{ disable?: boolean }>`
  width: 15rem;
  padding: 0.75rem 0;
  background: linear-gradient(95.07deg, #fd8258 11.86%, #fd3c2f 82.5%);
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0 #e2001b;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 20rem;
    margin: 0 auto;
    padding: .5rem 0;
    font-size: 1rem;
  `}

  ${(props) =>
    !props.disable &&
    `
    cursor: pointer;
  `}

  ${(props) =>
    props.disable &&
    `
    background: #e5e5e5;
    box-shadow: none;
    color: rgba(0,0,0,.87);
    font-weight: 400;
    position: relative;

    &:hover {
      ${StyledToolTip} {
        display: block;
      }
    }
  `}
`;
