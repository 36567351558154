import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import UserType from '../../const/UserType';

import { CircularIcon } from '../atoms/CircularIcon';

interface Props {
  id?: number;
  name?: string;
  nickName?: string;
  image?: string | null;
  isInstructor?: number | null;
  isStudent?: number | null;
  num?: number | null;
  type?: string;
}

export const RankingUserCard: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (props.id) {
      navigate(`/users/${props.id}/profile`);
    }
  };

  const pickUserType = (user: Pick<Props, 'isInstructor' | 'isStudent'> | undefined): string => {
    if (!user) return UserType.SnsUser;

    switch (1) {
      case user.isInstructor:
        return UserType.Instructor;
      case user.isStudent:
        return UserType.Student;
      default:
        return UserType.SnsUser;
    }
  };

  return (
    <User>
      {props.type === 'language' ? (
        <LanguageIcon image={props?.image} />
      ) : (
        <CircularIcon
          src={props?.image}
          size={40}
          userType={pickUserType(props)}
          onClick={handleClick}
        />
      )}
      <Name>{props?.nickName ? props.nickName : props?.name}</Name>
      <Number>
        <span>{props.type === 'time' || props.type === 'like' ? props?.num : ''}</span>
        {props.type === 'time' ? 'h' : ''}
      </Number>
    </User>
  );
};

const User = styled.li`
  display: flex;
  align-items: center;
  padding: 1rem 0;

  &:nth-child(n + 2) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:before {
    counter-increment: rankingNum;
    content: counter(rankingNum);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 0.75rem;
    background: #fff;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
  }
  &:nth-child(1):before {
    background: #f3bf38;
    color: #fff;
  }
  &:nth-child(2):before {
    background: rgba(0, 0, 0, 0.36);
    color: #fff;
  }
  &:nth-child(3):before {
    background: #9d4f2e;
    color: #fff;
  }
`;
const Name = styled.p`
  flex: 1;
  display: -webkit-box;
  max-height: 2.25rem;
  margin-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.125rem;
  letter-spacing: -0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Number = styled.p`
  margin-left: 0.75rem;
  font-size: 1rem;
  letter-spacing: -0.2px;

  span {
    font-size: 1.25rem;
  }
`;
const LanguageIcon = styled.div<{ image?: string | null }>`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: ${(props) => (props.image ? `url(${props.image})` : '#ccc')} center / cover no-repeat;
`;
