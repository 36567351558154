import React from 'react';
import styled from 'styled-components';

import { H3 } from '../../atoms/Typography';
import { ProgramListContainer } from '../../atoms/ProgramListContainer';
import { ProgramArticle } from '../../organisms/ProgramArticle';
import { EmptyBlock } from '../../molecules/EmptyBlock';

import { useUser } from '../../../redux/user/useUser';
import { useGetProgramsQuery, ProgramSearchType } from '../../../gen/graphql';
import { useGetExamMedal } from '../../../common/customHooks/GetExamMedal';

interface ProgramCompletedStudysProps {
  limit: number;
  offset: number;
}

export const ProgramCompletedStudys: React.FC<ProgramCompletedStudysProps> = () => {
  const { user } = useUser();
  const medalCounts = useGetExamMedal();

  const { data, loading } = useGetProgramsQuery({
    variables: {
      input: {
        programSearchType: ProgramSearchType.CompletedStudy,
      },
      userID: user.lmsUser?.id,
    },
  });

  return (
    <ProgramSection data-e2e="ProgramConpletedStudyList">
      <StyledH3 color="DARK">完了した教材</StyledH3>
      {!loading &&
        (data?.programs?.items && data?.programs?.items.length > 0 ? (
          <ProgramListContainer>
            {data.programs?.items.map((p) => {
              return (
                <ProgramArticle
                  id={p?.id ?? 0}
                  key={p.id}
                  image={p?.icon ?? ''}
                  thumb={p.thumbnail}
                  title={p?.title ?? ''}
                  description={p.description}
                  questionCount={p.questionCount}
                  answerCount={p.answerCount}
                  chapterCount={p?.chapterCount ?? 0}
                  requireTime={p?.requireTime ?? 0}
                  completedTime={p?.completedTime ?? 0}
                  tags={p.tags ?? []}
                  studyLogCount={p.studyLogCount}
                  programSample={p.programSample ?? undefined}
                  medalCount={p.id ? medalCounts.get(p.id) : undefined} // Medalカウントをこの層で取得する必要がなければ削除
                />
              );
            })}
          </ProgramListContainer>
        ) : (
          <EmptyBlock title="完了した教材はありません">
            教材を完了した後こちらで
            <br />
            どの教材を行ったか確認しましょう。
          </EmptyBlock>
        ))}
    </ProgramSection>
  );
};

const ProgramSection = styled.section`
  margin-bottom: 2.625rem;
`;

const StyledH3 = styled(H3)`
  font-size: 1.25rem;
`;
