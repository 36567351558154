import React, { useCallback } from 'react';

import { InvoiceList } from '../../organisms/InvoiceList';

import { useGetInvoicesQuery } from '../../../gen/graphql';
import { Loader } from '../../molecules/Loader';
import { AccountTabLayout } from '../../templates/AccountTabLayout';
import { useUser } from '../../../redux/user/useUser';
import { MIN_LIMIT } from '../../../const/Page';
import { LOWER_META_TITLE } from '../../../const/Service';

export const AccountInvoice: React.FC = () => {
  const metaTitle = `請求情報 | ${LOWER_META_TITLE}`;

  const { permissionCheck, isSocialLoginUser } = useUser();

  const { data, loading, refetch } = useGetInvoicesQuery({
    variables: {
      input: {
        limit: MIN_LIMIT,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const invoices = data?.invoices.items ?? [];
  const nextCursor = data?.invoices.nextCursor ?? '';
  const previousCursor = data?.invoices.previousCursor ?? '';
  const hasPrevious = data?.invoices.hasPrevious ?? false;
  const hasNext = data?.invoices.hasNext ?? false;
  const fetchNextInvoices = useCallback(async () => {
    refetch({
      input: {
        nextCursor: nextCursor,
        limit: MIN_LIMIT,
      },
    });
  }, [refetch, nextCursor]);
  const fetchPreviousInvoices = useCallback(async () => {
    refetch({
      input: {
        previousCursor: previousCursor,
        limit: MIN_LIMIT,
      },
    });
  }, [refetch, previousCursor]);

  return (
    <React.Fragment>
      <Loader display={loading}></Loader>
      <AccountTabLayout
        activeTab="invoice"
        permissionCheck={permissionCheck}
        isSocialLoginUser={isSocialLoginUser()}
        metaTitle={metaTitle}
      >
        {!loading && (
          <InvoiceList
            invoices={invoices}
            hasPrevious={hasPrevious}
            hasNext={hasNext}
            hideTeamSettingBreadcrumb={true}
            fetchNextInvoices={fetchNextInvoices}
            fetchPreviousInvoices={fetchPreviousInvoices}
          />
        )}
      </AccountTabLayout>
    </React.Fragment>
  );
};
