import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../atoms/Button';

interface PromotionCodeInputProps {
  promotionCode: string;
  setPromotionCode: (content: string) => void;
  promotionCodeInfo: string;
  promotionCodeError: string;
  handleClickApply: () => void;
  hiddenLabel?: boolean;
}

export const PromotionCodeInput: React.FC<PromotionCodeInputProps> = (props) => {
  const checkedPromotionCode = !!props.promotionCodeError;

  const inputPromotionCode = (value: string) => {
    if (props.setPromotionCode) props.setPromotionCode(value);
  };

  const disabledPromotionCode =
    props.promotionCode === undefined || props.promotionCode.trim().length === 0;

  const handleClickApply = () => {
    if (props.handleClickApply) props.handleClickApply();
  };

  return (
    <PromotionCode>
      {!props.hiddenLabel && (
        <Label>
          <label>クーポンコード</label>
        </Label>
      )}

      <PromotionCodeColumn>
        <StyledInput
          placeholder="クーポンコードを入力"
          name="promotionCode"
          value={props.promotionCode}
          type="text"
          error={checkedPromotionCode}
          onChange={(e) => inputPromotionCode(e.target.value)}
        />
        <ApplyButton onClick={handleClickApply} disabled={disabledPromotionCode}>
          適用
        </ApplyButton>
      </PromotionCodeColumn>

      <InfoText>{props.promotionCodeInfo}</InfoText>
      <ErrorText>{props.promotionCodeError}</ErrorText>
    </PromotionCode>
  );
};

const PromotionCode = styled.div``;

const Label = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 1rem;

  .required {
    display: block;
    margin-right: 0.5rem;
    padding: 0.125rem 0.5rem;
    background-color: #fd6258;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.2;
  }

  label {
    font-size: 1rem;
    font-weight: 700;
  }

  small {
    display: block;
    margin-left: 0.5rem;
    color: rgba(0, 0, 0, 0.36);
    font-size: 0.75rem;
  }
`;
const PromotionCodeColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  > img {
    display: block;
    height: 1.5rem;
  }
`;
const StyledInput = styled.input<{ error?: boolean }>`
  padding: 0.5rem;
  flex: 1 1 100%;
  width: 100%;
  appearance: none;
  border: none;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: ${(props): string => (props.error ? '#fce5e8' : '#ffffff')};
  border: 1px solid rgba(0, 0, 0, 0.36);
`;
const ApplyButton = styled(Button)`
  width: 5.625rem;
  padding-left: 0;
  padding-right: 0;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 80px;
  `}
`;
const InfoText = styled.p`
  margin-top: 0.5rem;
  color: #000000;
  align-self: center;
  font-weight: 500;
`;
const ErrorText = styled.p`
  margin-top: 0.5rem;
  color: #fd6258;
  align-self: center;
  font-weight: 500;
`;
