import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useCookies } from 'react-cookie';
import { useUser } from '../../redux/user/useUser';
import { useToastsContext } from '../../context/ToastsProvider';

import { Button } from '../atoms/Button';
import { RegisterForm } from './RegisterForm';
import { TranslateCognitoError } from '../../utils/CognitoErrorDictionary';
import { UserRegisterInput } from '../pages/public/TeamRegister';

interface Props {
  onSubmit: (user: UserRegisterInput) => void;
  onClickLogin: () => void;
}

export const TeamRegisterCreation: FC<Props> = ({ onSubmit, onClickLogin }): JSX.Element => {
  const {
    user: { loginError },
  } = useUser();
  const [cookies] = useCookies();

  const [clickableRegisterUserButton, setClickableRegisterUserButton] = useState(false);
  const [name, setName] = useState('');
  const [nickName, setNickName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { showToast } = useToastsContext();

  const handleClickSubmit = useCallback(() => {
    onSubmit({
      name: name,
      nickName: nickName,
      email: email,
      password: password,
      smrc: cookies['smrc_'],
    });
  }, [cookies, email, name, nickName, onSubmit, password]);

  // ソーシャルログインエラー表示用
  useEffect(() => {
    if (loginError) {
      showToast(1, TranslateCognitoError(loginError));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Description>
        管理者の個人アカウントを作成します。既に侍テラコヤの個人アカウントを作成してある場合は、既存のアカウントを管理者アカウントに設定できます。
      </Description>
      <Agreement>
        会員登録により、
        <a href="https://www.sejuku.net/corp/personal-information-signup" target="_blank">
          個人情報の取り扱い
        </a>
        および
        <a href="https://www.sejuku.net/corp/agreement-terakoya-enterprise" target="_blank">
          利用規約
        </a>
        に同意するものとします。
      </Agreement>
      <RegisterForm
        name={name}
        setName={setName}
        nickName={nickName}
        setNickName={setNickName}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        clickableRegisterButton={setClickableRegisterUserButton}
        socialLoginCallbackURL="/team/register/company"
      />
      <SubmitButton onClick={handleClickSubmit} disabled={!clickableRegisterUserButton}>
        会社情報の入力へ進む
      </SubmitButton>
      <LoginMessage>
        既にアカウントをお持ちの方は
        <a href="javascript:void(0)" onClick={onClickLogin}>
          ログイン
        </a>
        へ
      </LoginMessage>
    </>
  );
};

const Description = styled.p`
  font-size: 0.875rem;
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.87);

  a {
    color: #fd6258;
    font-size: inherit;
  }

  ${media.lessThan('medium')`
    font-size: 0.75rem;
    line-height: 1.3;
  `}
`;
const Agreement = styled.p`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.87);

  a {
    color: #fd6258;
    font-size: inherit;
  }

  ${media.lessThan('medium')`
    margin-top: 0.625rem;
  `}
`;

const SubmitButton = styled(Button)`
  width: 13.75rem;
  margin-top: 2rem;
  padding-left: 0;
  padding-right: 0;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 22.5rem;
  `}
`;

const LoginMessage = styled.p`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.87);

  a {
    color: #fd6258;
    font-size: inherit;
  }

  ${media.lessThan('medium')`
  font-size: 0.75rem;
  line-height: 1.3;
  `}
`;
