import { PaymentMethod } from '../../swagger/gen/ts_front';
import { Pagination } from '../../store';

export const CommonPaymentMethodActionTypes = {
  fetchIndexSuccess: 'COMMON_PAYMENT_METHODS_INDEX_FETCH_SUCCESS' as const,
  reset: 'COMMON_PAYMENT_METHODS_RESET' as const,
};

interface FetchIndexSuccessPayload {
  paymentMethods: PaymentMethod[];
  pagination: Pagination;
}

const fetchIndexSuccess = (payload: FetchIndexSuccessPayload) => ({
  type: CommonPaymentMethodActionTypes.fetchIndexSuccess,
  payload,
});

const reset = () => ({
  type: CommonPaymentMethodActionTypes.reset,
});

export type CommonPaymentMethodActions = ReturnType<typeof fetchIndexSuccess | typeof reset>;
