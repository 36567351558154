import React from 'react';
import { useUser } from './redux/user/useUser';
import { Route, Routes, Navigate } from 'react-router-dom';

import { LpAd } from './components/pages/common/LpAd';
import { LoginOauth } from './common/components/login/LoginOauth';
import { ChangePassword } from './common/components/login/ChangePassword';
import { ChangeTemporaryPassword } from './common/components/login/ChangeTemporaryPassword';
import { ChangePasswordVerification } from './common/components/login/ChangePasswordVerification';
import { NotificationIndex } from './components/pages/common/Notification';
import { NoticePage } from './components/pages/common/Notice';
import { NoticeDetailContainer as NoticeDetail } from './components/pages/common/NoticeDetail';
import { RegisterFreemium } from './components/pages/public/RegisterFreemium';
import { TeamRegister } from './components/pages/public/TeamRegister';
import { TeamMemberRegister } from './components/pages/public/TeamMemberRegister';
import { TeamMemberJoin } from './components/pages/common/TeamMemberJoin';
import { Registered } from './components/pages/common/Registered';
import { StudyReportIndex } from './components/pages/common/StudyReportIndex';
import { Search } from './components/pages/common/Search';

import { QuestionPage } from './components/pages/common/Question';
import { QuestionDetailContainer as QuestionDetail } from './components/pages/common/QuestionDetail';
import { Program } from './components/pages/common/Program';
import { ProgramWhileStudying } from './components/pages/common/ProgramWhileStudying';
import { ProgramCompletedStudy } from './components/pages/common/ProgramCompletedStudy';
import { ProgramDetailContainer as ProgramDetail } from './components/pages/common/ProgramDetail';
import { ProgramFeedbackContainer as ProgramFeedback } from './components/pages/common/ProgramFeedback';
import { ChapterDetailContainer as ChapterDetail } from './components/pages/common/ChapterDetail';
import { CourseProgramDetailContainer as CourseProgramDetail } from './components/pages/common/CourseProgramDetail';
import { ProgramSample } from './components/pages/common/ProgramSample';

import { Practices } from './components/pages/common/Practices';
import { PracticeSubmits } from './components/pages/common/PracticeSubmits';
import { PracticeDetailContainer as PracticeDetail } from './components/pages/common/PracticeDetail';
import { PracticeExplanationContainer as PracticeExplanation } from './components/pages/common/PracticeExplanation';
import { PracticeSubmitDetailContainer as PracticeSubmitDetail } from './components/pages/common/PracticeSubmitDetail';
import { PracticeFeedbackContainer as PracticeFeedback } from './components/pages/common/PracticeFeedback';
import { CreatePracticeSubmitContainer as CreatePracticeSubmit } from './components/pages/common/CreatePracticeSubmit';
import { ReCreatePracticeSubmitContainer as ReCreatePracticeSubmit } from './components/pages/common/ReCreatePracticeSubmit';
import { EditPracticeSubmitContainer as EditPracticeSubmit } from './components/pages/common/EditPracticeSubmit';

import { Top } from './components/pages/student/Top';
import { Chat } from './components/pages/common/Chat';
import { Curriculum } from './components/pages/student/Curriculum';
import { Lesson } from './components/pages/student/Lesson';
import { StudentSession } from './components/pages/student/Session';

import AccountMail from './components/pages/common/AccountMail';
import AccountProfile from './components/pages/common/AccountProfile';
import AccountAlignment from './components/pages/common/AccountAlignment';
import AccountPassword from './components/pages/common/AccountPassword';
import AccountCourse from './components/pages/student/AccountCourse';
import { AccountSubscription } from './components/pages/common/AccountSubscription';
import { AccountSubscriptionSelect } from './components/pages/common/AccountSubscriptionSelect';
import { AccountSubscriptionSelectSubmit } from './components/pages/common/AccountSubscriptionSelectSubmit';
import { AccountInvoice } from './components/pages/common/AccountInvoice';
import { AccountPaymentIntent } from './components/pages/common/AccountPaymentIntent';
import { AccountPayment } from './components/pages/common/AccountPayment';
import { CreateQuestion } from './components/pages/common/CreateQuestion';
import { EditQuestionContainer as EditQuestion } from './components/pages/common/EditQuestion';
import { StudyReportDetailContainer as StudyReportDetail } from './components/pages/common/StudyReportDetail';
import { CreateStudyReport } from './components/pages/common/CreateStudyReport';
import { EditStudyReportContainer as EditStudyReport } from './components/pages/common/EditStudyReport';
import { SpotLesson } from './components/pages/common/SpotLesson';

import { InstructorHome } from './components/pages/instructor/Home';
import { InstructorClaim } from './components/pages/instructor/Claim';
import { InstructorLessonContainer as InstructorLesson } from './components/pages/instructor/Lesson';
import { InstructorSessionContainer as InstructorSession } from './components/pages/instructor/Session';
import { InstructorCourseIndex } from './components/pages/instructor/Course';
import { InstructorCourseDetailContainer as InstructorCourseDetail } from './components/pages/instructor/CourseDetail';
import { InstructorSpotLessons } from './components/pages/instructor/SpotLessons';
import { InstructorSpotLessonSchedule } from './components/pages/instructor/SpotLessonSchedule';

import { PublicTop } from './components/pages/public/Top';

import { GeneralTop } from './components/pages/general/Top';
import { Cancel } from './components/pages/general/Cancel';

import { TopEvent } from './components/pages/student/TopEvent';

import { TeamTop } from './components/pages/team/Top';
import { TeamSubscription } from './components/pages/team/Subscription';
import { TeamSubscriptionPayment } from './components/pages/team/SubscriptionPayment';
import { TeamAuth } from './components/pages/team/Auth';
import { TeamProfile } from './components/pages/team/Profile';
import { TeamPayment } from './components/pages/team/Payment';

import { NotFoundPage } from './components/pages/public/NotFound';
import { SpotLessonReserve } from './components/pages/public/SpotLessonReserve';

import { Loader } from './components/molecules/Loader';
import { Timeline } from './components/pages/common/Timeline';
import { AccountSubscriptionLp } from './components/pages/common/AccountSubscriptionLp';
import { AccountSubscriptionPayment } from './components/pages/common/AccountSubscriptionPayment';
import { TweetDetailContainer as TweetDetail } from './components/pages/common/TweetDetail';
import { ProgramExamContainer as ProgramExam } from './components/pages/common/ProgramExam';
import { UserProfileContainer as UserProfile } from './components/pages/common/UserProfile';
import { UserProgramsContainer as UserPrograms } from './components/pages/common/UserPrograms';
import { UserTimelinesContainer as UserTimelines } from './components/pages/common/UserTimelines';
import { UserQuestionsContainer as UserQuestions } from './components/pages/common/UserQuestions';
import { UserPracticeSubmitsContainer as UserPracticeSubmits } from './components/pages/common/UserPracticeSubmits';
import { TeamInvoice } from './components/pages/team/Invoice';
import { FunctionType, PermissionType } from './const/UserPermission';
import ForbiddenPage from './components/pages/public/Forbidden';
import useLocationChange from './common/customHooks/LocationChange';
import { ServiceFeedback } from './components/pages/common/ServiceFeedback';

interface PermissionRouteProps {
  permission: boolean;
  element: JSX.Element;
}
const PermissionRoute = (props: PermissionRouteProps): JSX.Element => {
  return props.permission ? props.element : ForbiddenPage;
};

interface PermissionOriginalRouteProps {
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  hasCoaching?: boolean;
}
const PermissionHomeRoute = (props: PermissionOriginalRouteProps): JSX.Element => {
  switch (true) {
    case props.permissionCheck(FunctionType.HomeForInstructorAndCoach, PermissionType.Read):
      return <InstructorHome />;
    case props.permissionCheck(FunctionType.HomeForStudent, PermissionType.Read):
      return <Top />;
    case props.permissionCheck(FunctionType.HomeForNotLogin, PermissionType.Read):
      return <PublicTop />;
    case props.permissionCheck(FunctionType.HomeForGeneral, PermissionType.Read):
      return <GeneralTop />;
    default:
      return ForbiddenPage;
  }
};

const PermissionLessonRoute = (props: PermissionOriginalRouteProps): JSX.Element => {
  switch (true) {
    case props.permissionCheck(FunctionType.LessonForInstructor, PermissionType.Read):
      return <InstructorLesson />;
    case props.permissionCheck(FunctionType.LessonForStudent, PermissionType.Read):
      return <Lesson />;
    default:
      return ForbiddenPage;
  }
};

const PermissionSessionRoute = (props: PermissionOriginalRouteProps): JSX.Element => {
  switch (true) {
    case props.permissionCheck(FunctionType.SessionForCoach, PermissionType.Read):
      return <InstructorSession />;
    case props.permissionCheck(FunctionType.SessionForStudent, PermissionType.Read) &&
      props.hasCoaching:
      return <StudentSession />;
    default:
      return ForbiddenPage;
  }
};

const PermissionSpotLessonRoute = (props: PermissionOriginalRouteProps): JSX.Element => {
  switch (true) {
    case props.permissionCheck(FunctionType.SpotLessonForInstructor, PermissionType.Read):
      return <InstructorSpotLessons />;
    case props.permissionCheck(FunctionType.SpotLesson, PermissionType.Read):
      return <SpotLesson />;
    default:
      return ForbiddenPage;
  }
};

const PermissionFeedbackRoute = (props: PermissionOriginalRouteProps): JSX.Element => {
  if (props.permissionCheck(FunctionType.ServiceFeedback, PermissionType.Create)) {
    return <ServiceFeedback />;
  }
  return ForbiddenPage;
};

export const Router: React.FC = () => {
  const { refresh, isRoleConfirmed, isSocialLoginUser, hasCoaching, permissionCheck } = useUser();

  // URLが変わるたびに取得し直す
  useLocationChange(() => {
    window.scrollTo(0, 0);
    refresh();
  });

  React.useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoleConfirmed]);

  if (!isRoleConfirmed) return <Loader display />;

  return (
    <Routes>
      <Route path="/" element={<LpAd />} />
      <Route path="/lp" element={<Navigate replace to="/" />} />
      <Route path="/adlp" element={<Navigate replace to="/" />} />
      <Route path="/adlp2" element={<Navigate replace to="/" />} />
      <Route path="/techlp" element={<Navigate replace to="/" />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="/changePassword" element={<ChangePassword />} />
      <Route path="/changeTemporaryPassword" element={<ChangeTemporaryPassword />} />
      <Route path="/changePasswordVerification" element={<ChangePasswordVerification />} />

      <Route path="/register" element={<RegisterFreemium />} />

      {/* // 分析のためtoCとtoB(管理者,メンバー)でパスだけ変更している */}
      <Route path="/registered" element={<Registered />} />
      <Route path="/account/subscription/registered" element={<Registered />} />
      <Route path="/team/registered" element={<Registered />} />
      <Route path="/team_member/registered" element={<Registered />} />

      <Route path="/team/register/creation" element={<TeamRegister />} />
      <Route path="/team/register/company" element={<TeamRegister />} />

      <Route path="/team_member/register/creation" element={<TeamMemberRegister />} />
      <Route path="/team_member/register/join" element={<TeamMemberJoin />} />

      <Route path="/login/oauth/callback" element={<LoginOauth />} />

      <Route path="/home" element={<PermissionHomeRoute permissionCheck={permissionCheck} />} />

      <Route path="/search" element={<Search />} />

      <Route path="/programs" element={<Program />} />
      <Route path="/programs/:id/chapters" element={<ProgramDetail />} />
      <Route path="/programs/:id/questions" element={<ProgramDetail />} />
      <Route path="/programs/:id/overview" element={<ProgramDetail />} />
      <Route path="/programs/:id/practice_submits" element={<ProgramDetail />} />
      <Route path="/programs/:program_id/chapters/:chapter_id/exam" element={<ProgramExam />} />
      <Route path="/programs/:program_id/chapters/:chapter_id" element={<ChapterDetail />} />
      <Route
        path="/programs/:program_id/chapters/:chapter_id/questions"
        element={<ChapterDetail />}
      />
      <Route path="/programs/:program_id/chapters/:chapter_id/list" element={<ChapterDetail />} />
      <Route path="/programs/category/:category_id" element={<Program />} />
      <Route path="/programs_while_studying" element={<ProgramWhileStudying />} />
      <Route path="/programs_completed_study" element={<ProgramCompletedStudy />} />
      <Route path="/course_programs/:course_program_id" element={<CourseProgramDetail />} />
      <Route path="/programs/sample" element={<ProgramSample />} />
      <Route
        path="/programs/:program_id/chapters/:chapter_id/feedback"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.ProgramFeedback, PermissionType.Create)}
            element={<ProgramFeedback />}
          />
        }
      />

      <Route path="/practices" element={<Practices />} />
      <Route path="/practices/category/:category_id" element={<Practices />} />
      <Route
        path="/practices/submits/all"
        element={
          <PermissionRoute
            permission={permissionCheck(
              FunctionType.PracticeForInstructorAndCoach,
              PermissionType.Read,
            )}
            element={<PracticeSubmits />}
          />
        }
      />
      <Route
        path="/practices/submits/my_students"
        element={
          <PermissionRoute
            permission={permissionCheck(
              FunctionType.PracticeForInstructorAndCoach,
              PermissionType.Read,
            )}
            element={<PracticeSubmits />}
          />
        }
      />
      <Route
        path="/practices/submits/reviewed"
        element={
          <PermissionRoute
            permission={permissionCheck(
              FunctionType.PracticeForInstructorAndCoach,
              PermissionType.Read,
            )}
            element={<PracticeSubmits />}
          />
        }
      />
      <Route
        path="/practices/submits/mine"
        element={
          <PermissionRoute
            permission={
              !permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Read)
            }
            element={<PracticeSubmits />}
          />
        }
      />
      <Route
        path="/practices/submits/everyone"
        element={
          <PermissionRoute
            permission={
              !permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Read)
            }
            element={<PracticeSubmits />}
          />
        }
      />
      <Route
        path="/practices/submits/my_draft"
        element={
          <PermissionRoute
            permission={
              !permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Read)
            }
            element={<PracticeSubmits />}
          />
        }
      />
      <Route path="/practices/submits/:practice_submit_id" element={<PracticeSubmitDetail />} />
      <Route
        path="/practices/submits/:practice_submit_id/details/new"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.PracticeSubmit, PermissionType.Create)}
            element={<ReCreatePracticeSubmit />}
          />
        }
      />
      <Route
        path="/practices/submits/:practice_submit_id/details/:practice_submit_detail_id/edit"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.PracticeSubmit, PermissionType.Update)}
            element={<EditPracticeSubmit />}
          />
        }
      />
      <Route path="/practices/:practice_id/body" element={<PracticeDetail />} />
      <Route path="/practices/:practice_id/list" element={<PracticeDetail />} />
      <Route path="/practices/:practice_id/questions" element={<PracticeDetail />} />
      <Route path="/practices/:practice_id/submits" element={<PracticeDetail />} />
      <Route
        path="/practices/:practice_id/submits/new"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.PracticeSubmit, PermissionType.Create)}
            element={<CreatePracticeSubmit />}
          />
        }
      />
      <Route path="/practices/:practice_id/explanation" element={<PracticeExplanation />} />
      <Route
        path="/practices/:practice_id/feedback"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.PracticeFeedback, PermissionType.Create)}
            element={<PracticeFeedback />}
          />
        }
      />

      <Route path="/questions/all" element={<QuestionPage />} />
      <Route
        path="/questions/my"
        element={
          <PermissionRoute
            permission={
              permissionCheck(FunctionType.QuestionForInstructorAndCoach, PermissionType.Read) ||
              permissionCheck(FunctionType.Question, PermissionType.Read)
            }
            element={<QuestionPage />}
          />
        }
      />
      <Route
        path="/questions/answered"
        element={
          <PermissionRoute
            permission={
              permissionCheck(FunctionType.QuestionForInstructorAndCoach, PermissionType.Read) ||
              permissionCheck(FunctionType.Question, PermissionType.Read)
            }
            element={<QuestionPage />}
          />
        }
      />
      <Route
        path="/questions/draft"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.Question, PermissionType.Update)}
            element={<QuestionPage />}
          />
        }
      />
      <Route path="/question/detail/:id" element={<QuestionDetail />} />
      <Route
        path="/question/new"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.Question, PermissionType.Create)}
            element={<CreateQuestion />}
          />
        }
      />
      <Route
        path="/question/edit/:id"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.Question, PermissionType.Update)}
            element={<EditQuestion />}
          />
        }
      />

      <Route
        path="/study_report/my"
        element={
          <PermissionRoute
            permission={!permissionCheck(FunctionType.StudyReportForNotLogin, PermissionType.Read)}
            element={<StudyReportIndex />}
          />
        }
      />
      <Route path="/study_report/all" element={<StudyReportIndex />} />
      <Route path="/study_report/detail/:id" element={<StudyReportDetail />} />
      <Route
        path="/study_report/new"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.StudyReport, PermissionType.Create)}
            element={<CreateStudyReport />}
          />
        }
      />
      <Route
        path="/study_report/edit/:id"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.StudyReport, PermissionType.Update)}
            element={<EditStudyReport />}
          />
        }
      />

      <Route path="/timeline" element={<Timeline />} />

      <Route
        path="/curriculum"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.Curriculum, PermissionType.Read)}
            element={<Curriculum />}
          />
        }
      />

      <Route
        path="/courses"
        element={
          <PermissionRoute
            permission={permissionCheck(
              FunctionType.CourseForInstructorAndCoach,
              PermissionType.Read,
            )}
            element={<InstructorCourseIndex />}
          />
        }
      />
      <Route
        path="/courses/:id"
        element={
          <PermissionRoute
            permission={permissionCheck(
              FunctionType.CourseForInstructorAndCoach,
              PermissionType.Read,
            )}
            element={<InstructorCourseDetail />}
          />
        }
      />

      <Route
        path="/lessons"
        element={<PermissionLessonRoute permissionCheck={permissionCheck} />}
      />
      <Route
        path="/lessons/:course_id"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.LessonForInstructor, PermissionType.Read)}
            element={<InstructorLesson />}
          />
        }
      />

      <Route
        path="/sessions"
        element={
          <PermissionSessionRoute permissionCheck={permissionCheck} hasCoaching={hasCoaching()} />
        }
      />
      <Route
        path="/sessions/:course_id"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.SessionForCoach, PermissionType.Read)}
            element={<InstructorSession />}
          />
        }
      />

      <Route
        path="/spot_lessons"
        element={<PermissionSpotLessonRoute permissionCheck={permissionCheck} />}
      />
      <Route
        path="/spot_lessons/reserve"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.SpotLessonReserve, PermissionType.Create)}
            element={<SpotLessonReserve />}
          />
        }
      />
      <Route
        path="/spot_lessons/schedule"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.SpotLessonSchedule, PermissionType.Read)}
            element={<InstructorSpotLessonSchedule />}
          />
        }
      />

      <Route
        path="/chat"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.Chat, PermissionType.Read)}
            element={<Chat />}
          />
        }
      />

      <Route path="/users/:id/profile" element={<UserProfile />} />
      <Route path="/users/:id/programs" element={<UserPrograms />} />
      <Route path="/users/:id/timelines" element={<UserTimelines />} />
      <Route path="/users/:id/questions" element={<UserQuestions />} />
      <Route path="/users/:id/practice_submits" element={<UserPracticeSubmits />} />

      <Route
        path="/account/profile"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountProfile, PermissionType.Read)}
            element={<AccountProfile />}
          />
        }
      />
      <Route
        path="/account/mail"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountMail, PermissionType.Update)}
            element={<AccountMail />}
          />
        }
      />
      <Route
        path="/account/password"
        element={
          <PermissionRoute
            permission={
              permissionCheck(FunctionType.AccountPassword, PermissionType.Update) &&
              !isSocialLoginUser()
            }
            element={<AccountPassword />}
          />
        }
      />
      <Route
        path="/account/subscription"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountSubscription, PermissionType.Read)}
            element={<AccountSubscription />}
          />
        }
      />
      <Route
        path="/account/invoice"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountInvoice, PermissionType.Read)}
            element={<AccountInvoice />}
          />
        }
      />
      <Route
        path="/account/payment_intent"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountPaymentIntent, PermissionType.Read)}
            element={<AccountPaymentIntent />}
          />
        }
      />
      <Route
        path="/account/payment"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountPayment, PermissionType.Read)}
            element={<AccountPayment />}
          />
        }
      />
      <Route
        path="/account/alignment"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountAlignment, PermissionType.Read)}
            element={<AccountAlignment />}
          />
        }
      />
      <Route
        path="/account/course"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountCourse, PermissionType.Read)}
            element={<AccountCourse />}
          />
        }
      />

      <Route
        path="/account/subscription/lp"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountSubscriptionLp, PermissionType.Read)}
            element={<AccountSubscriptionLp />}
          />
        }
      />
      <Route
        path="/account/subscription/payment"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountSubscription, PermissionType.Create)}
            element={<AccountSubscriptionPayment />}
          />
        }
      />
      <Route
        path="/account/cancel"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.AccountWithDraw, PermissionType.Delete)}
            element={<Cancel />}
          />
        }
      />
      <Route
        path="/account/subscription/select"
        element={
          <PermissionRoute
            permission={permissionCheck(
              FunctionType.AccountSubscriptionSelect,
              PermissionType.Read,
            )}
            element={<AccountSubscriptionSelect />}
          />
        }
      />
      <Route
        path="/account/subscription/select/submit/:price_id"
        element={
          <PermissionRoute
            permission={permissionCheck(
              FunctionType.AccountSubscriptionSelect,
              PermissionType.Read,
            )}
            element={<AccountSubscriptionSelectSubmit />}
          />
        }
      />
      <Route
        path="/claim"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.Claim, PermissionType.Read)}
            element={<InstructorClaim />}
          />
        }
      />

      <Route
        path="/notification/notification"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.Notification, PermissionType.Read)}
            element={<NotificationIndex />}
          />
        }
      />
      <Route
        path="/notification/admin"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.Notification, PermissionType.Read)}
            element={<NoticePage />}
          />
        }
      />
      <Route
        path="/notification/admin/:id"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.Notification, PermissionType.Read)}
            element={<NoticeDetail />}
          />
        }
      />
      <Route path="/event" element={<TopEvent />} />

      <Route path="/tweets/:id" element={<TweetDetail />} />

      <Route
        path="/team"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.TeamTop, PermissionType.Read)}
            element={<TeamTop />}
          />
        }
      />
      <Route
        path="/team/auth"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.TeamAuth, PermissionType.Read)}
            element={<TeamAuth />}
          />
        }
      />
      <Route
        path="/team/invoice"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.TeamInvoice, PermissionType.Read)}
            element={<TeamInvoice />}
          />
        }
      />
      <Route
        path="/team/profile"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.TeamProfile, PermissionType.Read)}
            element={<TeamProfile />}
          />
        }
      />
      <Route
        path="/team/payment"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.TeamPayment, PermissionType.Read)}
            element={<TeamPayment />}
          />
        }
      />

      <Route
        path="/team/subscription"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.TeamSubscription, PermissionType.Read)}
            element={<TeamSubscription />}
          />
        }
      />
      <Route
        path="/team/subscription/payment"
        element={
          <PermissionRoute
            permission={permissionCheck(FunctionType.TeamSubscription, PermissionType.Create)}
            element={<TeamSubscriptionPayment />}
          />
        }
      />

      <Route
        path="/feedback"
        element={<PermissionFeedbackRoute permissionCheck={permissionCheck} />}
      />

      <Route path="*" element={NotFoundPage} />
    </Routes>
  );
};
