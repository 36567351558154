import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  id: number;
  icon?: string;
  name: string;
  time: number;
}

export const LanguageCard: React.FC<Props> = (props) => {
  return (
    <Card>
      <Info>
        <Tag>
          <Icon image={props.icon ? props.icon : ''} />
          {props.name}
        </Tag>
      </Info>
      <Time>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4 8C14.4 11.5346 11.5346 14.4 8 14.4C4.46538 14.4 1.6 11.5346 1.6 8C1.6 4.46538 4.46538 1.6 8 1.6C11.5346 1.6 14.4 4.46538 14.4 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.8 4.46071C8.8 4.01888 8.44183 3.66071 8 3.66071C7.55817 3.66071 7.2 4.01888 7.2 4.46071V8.53344C7.2 8.77365 7.30794 9.00114 7.49399 9.15308L10.4031 11.5287C10.7453 11.8081 11.2493 11.7573 11.5287 11.415C11.8082 11.0728 11.7573 10.5689 11.4151 10.2894L8.8 8.15387V4.46071Z"
            fill="black"
            fillOpacity="0.87"
          />
        </svg>
        <time>
          <span>{(props.time / 60) | 0}</span>時間<span>{props.time % 60}</span>分
        </time>
      </Time>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  padding: 1rem;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  ${media.lessThan('medium')`
    width: 100%;
    border-right: none;
    border-bottom: 1px solid rgba(0,0,0,0.1);
  `}
`;
const Tag = styled.p`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8125rem;
  letter-spacing: -0.2px;
`;
const Icon = styled.div<{ image: string }>`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  background: ${(props) => (props.image ? `url(${props.image})` : '#c4c4c4')} center / cover
    no-repeat;
  border-radius: 50%;
`;

const Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding: 0 0.5rem;

  time {
    margin-left: 0.25rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.75rem;

    span {
      font-size: 1rem;
      font-weight: 700;
    }
  }

  ${media.lessThan('medium')`
    justify-content: flex-end;
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
  `}
`;
