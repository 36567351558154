import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import inquiryIcon from '../../static/image/inquiry_icon.svg';

interface Props {
  programID?: number;
  chapterID?: number;
  practiceID?: string;
  isLogin: boolean;
}

export const ProgramElementBackArchive: React.FC<Props> = ({
  programID,
  chapterID,
  practiceID,
  isLogin,
}) => {
  let feedbackPageURL = '';

  if (programID) {
    if (chapterID) {
      feedbackPageURL = `/programs/${programID}/chapters/${chapterID}/feedback`;
    } else if (practiceID) {
      feedbackPageURL = `/practices/${practiceID}/feedback`;
    }
  }

  return (
    <BackArchive>
      <Link to={programID ? `/programs/${programID}/chapters` : ''}>一覧に戻る</Link>
      {isLogin && (
        <InquiryArea>
          <InquiryIcon src={inquiryIcon} />
          <Link to={feedbackPageURL}>
            改善点のご指摘、誤字脱字、その他ご要望はこちらからご連絡ください。
          </Link>
        </InquiryArea>
      )}
    </BackArchive>
  );
};

const BackArchive = styled.div`
  a:first-child {
    display: inline-block;
    padding-left: 0.875rem;
    color: #555;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.2s;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 0.375rem;
      height: 0.375rem;
      margin: auto;
      border-top: 1px solid #555;
      border-left: 1px solid #555;
      transform: rotate(-45deg);
      transition: all 0.2s;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }

    &:hover {
      color: #eb0000;

      &:before {
        border-top-color: #eb0000;
        border-left-color: #eb0000;
      }
    }
  }

  a:nth-child(2) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #fd3c2f;
  }

  ${media.greaterThan('medium')`
    margin-bottom: 32px;
  `}

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;
const InquiryArea = styled.div`
  float: right;
  ${media.lessThan('medium')`
    float: none;
    position: relative;
    padding: 1rem 0;
    a {
      position: absolute;
      left: 20px;
    }
  `}
`;
const InquiryIcon = styled.img`
  height: 1rem;
  vertical-align: bottom;
  margin-right: 0.25rem;
`;
