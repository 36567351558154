import React from 'react';
import moment from 'moment/moment';
import styled from 'styled-components';
import media from 'styled-media-query';
import Connpass from '../../static/image/connpass_logo.png';

import { H3 } from '../atoms/Typography';

interface EventArticleProps {
  id: string;
  link: string;
  started_at: Date;
  ended_at: Date;
  title: string;
  address: string;
  limit: string;
  accepted: string;
}

export const EventArticle: React.FC<EventArticleProps> = (props) => {
  const week = ['日', '月', '火', '水', '木', '金', '土'];

  return (
    <Container>
      <a href={props.link} target="_blank">
        <StyledSection>
          <Date>
            <div>
              <Year>{moment(props.started_at).format('YYYY')}</Year>
              <Day>
                {moment(props.started_at).format('M')}/{moment(props.started_at).format('D')}
                <span>({week[parseInt(moment(props.started_at).format('d'))]})</span>
              </Day>
              <Time>
                <span>{moment(props.started_at).format('HH:mm')}</span>
                <EndTime>{moment(props.ended_at).format('HH:mm')}</EndTime>
              </Time>
            </div>
          </Date>
          {props.link.match(/connpass/) ? <img src={Connpass} alt="connpass_logo" /> : <Thumb />}
          <Content>
            <Info>
              <StyledH3 color="DARK">{props.title}</StyledH3>
              <Place>
                <svg
                  width="10"
                  height="14"
                  viewBox="0 0 10 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.99998 1C2.666 1 0.767212 2.89879 0.767212 5.23277C0.767212 8.07394 4.64795 12.7168 4.81319 12.9131C4.85932 12.9679 4.92728 12.9997 4.99894 13C4.9993 13 4.99962 13 4.99998 13C5.07126 13 5.13904 12.9688 5.18544 12.9147C5.35068 12.7217 9.23275 8.15556 9.23275 5.23277C9.23272 2.89879 7.33396 1 4.99998 1ZM5.00145 12.3715C4.22206 11.4022 1.25561 7.55926 1.25561 5.23277C1.25561 3.16812 2.93533 1.4884 4.99998 1.4884C7.06463 1.4884 8.74435 3.16812 8.74435 5.23277C8.74432 7.62559 5.78304 11.4136 5.00145 12.3715Z"
                    fill="#666666"
                    stroke="#666666"
                    strokeWidth="0.5"
                  />
                  <path
                    d="M4.99999 3.35388C4.00769 3.35388 3.20044 4.16114 3.20044 5.15343C3.20044 6.14572 4.00769 6.95297 4.99999 6.95297C5.99228 6.95297 6.79953 6.14572 6.79953 5.15343C6.79953 4.16114 5.99228 3.35388 4.99999 3.35388ZM4.99999 6.46454C4.27703 6.46454 3.68884 5.87638 3.68884 5.15339C3.68884 4.43041 4.277 3.84225 4.99999 3.84225C5.72297 3.84225 6.31113 4.43041 6.31113 5.15339C6.31113 5.87638 5.72291 6.46454 4.99999 6.46454Z"
                    fill="#666666"
                    stroke="#666666"
                    strokeWidth="0.5"
                  />
                </svg>
                {props.address}
              </Place>
            </Info>
          </Content>
          <Limit>
            <p>
              {props.accepted}人<span>{props.limit}人</span>
            </p>
          </Limit>
        </StyledSection>
      </a>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin: 0 auto 2rem;
  background-color: #fff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('medium')`
    width: 49%;
    margin: 0 0 2rem;
  `}

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
const StyledSection = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 1.5rem 2rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    flex-wrap: wrap;
    padding: 0 0 3rem;
  `}
`;
const Date = styled.div`
  display: flex;
  align-items: center;
  width: 96px;
  overflow: hidden;
  text-align: left;

  ${media.lessThan('large')`
    width: 66px;
  `}

  ${media.lessThan('medium')`
    order: 2;
    justify-content: center;
    width: 56px;
    margin: 0 1rem;
    padding-right: 1rem;
    border-right: 1px solid rgba(0,0,0,0.1);
    text-align: center;
  `}
`;
const Year = styled.p`
  margin-bottom: 2px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25;
`;
const Day = styled.p`
  display: block;
  margin-bottom: 2px;
  font-family: Helvetica Neue;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.2;

  span {
    margin-left: 2px;
    font-size: 10px;
  }

  ${media.lessThan('large')`
    font-size: 1.25rem;
  `}

  ${media.lessThan('medium')`
    span {
      display: none;
    }
  `}
`;
const Time = styled.p`
  span {
    font-family: Helvetica Neue;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.75rem;
    line-height: 1.25;
  }

  ${media.lessThan('large')`
    span {
      font-size: 0.625rem;
    }
  `}

  ${media.lessThan('medium')`
    span {
      display: block;
    }
  `}
`;
const EndTime = styled.span`
  &:before {
    content: '-';
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 500;
    transform: rotate(90deg);
  }

  ${media.lessThan('medium')`
    &:before {
      display: block;
      line-height: 1;
    }
  `}
`;
const Thumb = styled.div`
  width: 120px;
  height: 120px;
  margin: auto 1rem;
  background: #ccc center / cover no-repeat;

  ${media.lessThan('medium')`
    order: 1;
    width: 100%;
    margin: 0 auto 1.5rem;
  `}
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 0;

  ${media.lessThan('medium')`
    order: 3;
    margin-right: 1rem;
  `}
`;
const Info = styled.div`
  flex: 1;
  text-align: left;
  box-sizing: border-box;
  padding-bottom: 1rem;
  position: relative;
`;
const StyledH3 = styled(H3)`
  font-size: 1.125rem;
  line-height: 1.5;
  text-align: left;

  ${media.lessThan('large')`
    font-size: 1rem;
  `}

  ${media.lessThan('medium')`
    font-size: 0.875rem;
  `}
`;
const Place = styled.p`
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  left: 0;

  svg {
    display: inline-block;
    margin-right: 3px;
    vertical-align: text-bottom;
  }
`;
const Limit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  margin-left: 1.5rem;
  padding-left: 1.5rem;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;

  span {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 500;

    &:before {
      content: '定員';
    }
  }

  ${media.lessThan('medium')`
    order: 4;
    width: auto;
    margin: 0;
    padding: 0;
    border: none;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    line-height: 1;

    span {
      display: inline;

      &:before {
        content: '/';
        margin: 0 0.1rem 0 0.3rem;
      }
    }
  `}
`;
