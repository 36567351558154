import React from 'react';
import styled from 'styled-components';
import { SelectOption } from './Select';

interface FilterInputSelectProps {
  name: string;
  options: readonly SelectOption[];
  currentValue: string;
  handleSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  active?: boolean;
  disabled?: boolean;
  className?: string;
}

export const FilterInputSelect: React.FC<FilterInputSelectProps> = (props) => {
  return (
    <Select
      name={props.name}
      onChange={props.handleSelect}
      value={props.currentValue}
      active={props.active}
      disabled={props.disabled}
      className={props.className}
    >
      {props.options.map((option): JSX.Element => {
        return (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        );
      })}
    </Select>
  );
};

const Select = styled.select<{ active?: boolean; disabled?: boolean }>`
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0.625rem 1.5rem 0.625rem 1rem;
  background: ${(props) => (props.disabled ? '#eeeeee' : '#ffffff')} center right / auto no-repeat
    url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2217%22%20height%3D%2216%22%20viewBox%3D%220%200%2017%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.49707%2013L3.30092%204H13.6932L8.49707%2013Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E');
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  outline: none;
  font-size: 0.8125rem;
  color: rgba(0, 0, 0, ${(props) => (props.active ? '.87' : '.36')});
  appearance: none;
`;
