import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import styled from 'styled-components';
import media from 'styled-media-query';
import { pipe } from 'fp-ts/function';
import { fromNullable, getOrElse } from 'fp-ts/Option';
import { useGetCategoriesQuery, CategoryFragment as Category } from '../../gen/graphql';

export interface SpotLessonStep2CheckBoxProps {
  onChange: (inputCheckBox: Category[]) => void;
  setLoading: (isLoading: boolean) => void;
}

export const SpotLessonReserveStep2CheckBox: React.FC<SpotLessonStep2CheckBoxProps> = (props) => {
  interface CheckBoxType {
    active: boolean;
    category: Category;
  }
  const [checkboxState, setCheckboxState] = React.useState<Map<number, CheckBoxType>>(new Map());

  useGetCategoriesQuery({
    onCompleted: (data) => {
      setCheckboxState(
        new Map<number, CheckBoxType>(
          data.categories?.map((e) => [
            e.id ? e.id : 0,
            {
              active: false,
              category: e,
            },
          ]),
        ),
      );
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    const category: Category = pipe(
      fromNullable(checkboxState.get(parseInt(event.target.value))?.category),
      getOrElse<Category>(() => {
        return {
          id: 0,
          name: '',
          status: 0,
          createdAt: Date(),
          updatedAt: Date(),
        };
      }),
    );
    //ここでnew MapしないとReact側のdiffアルゴリズム的に更新がトリガーされず、checkboxのcheckなどが反映されない。
    //https://ichi.pro/reactstate-fukku-de-es-6-mappu-o-shiyosuru-140787181346143
    setCheckboxState(
      new Map(
        checkboxState.set(parseInt(event.target.value), {
          active: event.target.checked,
          category: category,
        }),
      ),
    );

    props.onChange(getActiveChecks().map((e) => e.category));
  };

  const getActiveChecks = () => Array.from(checkboxState.values()).filter((x) => x.active);

  const disableCheckbox = (selected: number) =>
    getActiveChecks().length >= 2 &&
    !(checkboxState.get(selected)?.active ? checkboxState.get(selected)?.active : false);

  const getStyledCheckbox = () =>
    Array.from(checkboxState).map((e: [number, CheckBoxType]) => (
      <StyledCheckbox key={e[0]} active={e[1].active}>
        <FormControlLabel
          //https://material-ui.com/components/checkboxes/
          key={e[0]}
          control={<Checkbox />}
          disabled={disableCheckbox(e[0])}
          label={e[1].category?.name ? e[1].category?.name : '選択できません'}
          value={e[0]}
          checked={e[1].active}
          onChange={handleChange}
        />
      </StyledCheckbox>
    ));

  return (
    <React.Fragment>
      <InputLanguage>
        <StyledFormGroup row>{getStyledCheckbox()}</StyledFormGroup>
      </InputLanguage>
    </React.Fragment>
  );
};

const StyledCheckbox = styled.div<{ active: boolean }>`
  width: calc(100% / 4 - 2rem);
  max-width: 400px;
  min-width: 300px;
  border: 1px solid ${(props) => (props.active ? '#e2001b' : 'rgba(0,0,0,0.1)')};
  border-radius: 150px;
  margin: 0 0 1rem 1rem;

  label {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.375rem 1rem 0.375rem 0.625rem;

    span {
      font-size: 0.875rem;
    }
  }

  input {
    display: none;
  }

  ${media.lessThan('medium')`
    margin: 0 0 .75rem;
    width: 90%;
    max-width: 90%;

    label {
      padding: 0 1rem 0 .625rem;
    }
  `}
`;

const InputLanguage = styled.div`
  margin-top: 1rem;
  flex-direction: column;

  ${media.lessThan('medium')`
    margin-top: 1.5rem;
  `}
`;

const StyledFormGroup = styled(FormGroup)`
  margin-left: -1rem;

  ${media.lessThan('medium')`
    margin-left: 0;
  `}
`;
