export const CommonModalActionTypes = {
  openRegisterFreeUserModal: 'COMMON_MODAL_OPEN_REGISTER_FREE_USER_MODAL' as const,
  closeRegisterFreeUserModal: 'COMMON_MODAL_CLOSE_REGISTER_FREE_USER_MODAL' as const,
  openRegisterLightUserModal: 'COMMON_MODAL_OPEN_REGISTER_LIGHT_USER_MODAL' as const,
  closeRegisterLightUserModal: 'COMMON_MODAL_CLOSE_REGISTER_LIGHT_USER_MODAL' as const,
  openRegisterBasicUserModal: 'COMMON_MODAL_OPEN_REGISTER_BASIC_USER_MODAL' as const,
  closeRegisterBasicUserModal: 'COMMON_MODAL_CLOSE_REGISTER_BASIC_USER_MODAL' as const,
  openRegisterPremiumUserModal: 'COMMON_MODAL_OPEN_REGISTER_PREMIUM_USER_MODAL' as const,
  closeRegisterPremiumUserModal: 'COMMON_MODAL_CLOSE_REGISTER_PREMIUM_USER_MODAL' as const,
  openRegisterTeamSubscriptionUserModal:
    'COMMON_MODAL_OPEN_REGISTER_TEAM_SUBSCRIPTION_USER_MODAL' as const,
  closeRegisterTeamSubscriptionUserModal:
    'COMMON_MODAL_CLOSE_REGISTER_TEAM_SUBSCRIPTION_USER_MODAL' as const,
  openRegisterTeamSubscriptionToTeamAdminModal:
    'COMMON_MODAL_OPEN_REGISTER_TEAM_SUBSCRIPTION_TO_TEAM_ADMIN_MODAL' as const,
  closeRegisterTeamSubscriptionToTeamAdminModal:
    'COMMON_MODAL_CLOSE_REGISTER_TEAM_SUBSCRIPTION_TO_TEAM_ADMIN_MODAL' as const,
  closeAllModals: 'COMMON_MODAL_CLOSE_ALL_MODALS' as const,
};

const openRegisterFreeUserModal = () => ({
  type: CommonModalActionTypes.openRegisterFreeUserModal,
});

const closeRegisterFreeUserModal = () => ({
  type: CommonModalActionTypes.closeRegisterFreeUserModal,
});

const openRegisterLightUserModal = () => ({
  type: CommonModalActionTypes.openRegisterLightUserModal,
});

const closeRegisterLightUserModal = () => ({
  type: CommonModalActionTypes.closeRegisterLightUserModal,
});

const openRegisterBasicUserModal = () => ({
  type: CommonModalActionTypes.openRegisterBasicUserModal,
});

const closeRegisterBasicUserModal = () => ({
  type: CommonModalActionTypes.closeRegisterBasicUserModal,
});

const openRegisterPremiumUserModal = () => ({
  type: CommonModalActionTypes.openRegisterPremiumUserModal,
});

const closeRegisterPremiumUserModal = () => ({
  type: CommonModalActionTypes.closeRegisterPremiumUserModal,
});

const openRegisterTeamSubscriptionUserModal = () => ({
  type: CommonModalActionTypes.openRegisterTeamSubscriptionUserModal,
});

const closeRegisterTeamSubscriptionUserModal = () => ({
  type: CommonModalActionTypes.closeRegisterTeamSubscriptionUserModal,
});

const openRegisterTeamSubscriptionToTeamAdminModal = () => ({
  type: CommonModalActionTypes.openRegisterTeamSubscriptionToTeamAdminModal,
});

const closeRegisterTeamSubscriptionToTeamAdminModal = () => ({
  type: CommonModalActionTypes.closeRegisterTeamSubscriptionToTeamAdminModal,
});

const closeAllModals = () => ({
  type: CommonModalActionTypes.closeAllModals,
});

export type CommonModalActions = ReturnType<
  | typeof openRegisterFreeUserModal
  | typeof closeRegisterFreeUserModal
  | typeof openRegisterLightUserModal
  | typeof closeRegisterLightUserModal
  | typeof openRegisterBasicUserModal
  | typeof closeRegisterBasicUserModal
  | typeof openRegisterPremiumUserModal
  | typeof closeRegisterPremiumUserModal
  | typeof openRegisterTeamSubscriptionUserModal
  | typeof closeRegisterTeamSubscriptionUserModal
  | typeof openRegisterTeamSubscriptionToTeamAdminModal
  | typeof closeRegisterTeamSubscriptionToTeamAdminModal
  | typeof closeAllModals
>;
