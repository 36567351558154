import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { useOutsideClick } from '../../common/customHooks/OutsideClick';
import { SpotLessonPhase } from '../../gen/graphql';
import { strToEnum } from '../../utils/common';

interface SpotLessonFilterProps {
  startDate: Date;
  endDate: null | Date;
  onChange: (dates: [Date, Date]) => void;
  statuses: SpotLessonPhase[];
  onChangeStatus: (newStatuses: SpotLessonPhase[]) => void;
}

export const SpotLessonFilter: React.FC<SpotLessonFilterProps> = (props) => {
  const [shouldCloseDate, setShouldClose] = React.useState(false);

  const onChangeDate = (newDates: [Date, Date]) => {
    const [startDate, endDate] = newDates;

    if ((startDate && !endDate) || (!startDate && endDate)) {
      // xor
      setShouldClose(true);
    } else {
      setShouldClose(false);
    }

    props.onChange(newDates);
  };

  const [isStatusTipOpen, setIsStatusTipOpen] = React.useState(false);
  const statusTipRef = React.useRef<HTMLDivElement>(null);
  const openTipRef = React.useRef<HTMLDivElement>(null);
  const [filterContent, setFilterCOntent] = useState('すべて');

  const createFilterContent = (statuses: SpotLessonPhase[]) => {
    if (statuses.length === Object.keys(SpotLessonPhase).length) {
      return 'すべて';
    }
    if (statuses.length === 0) {
      return '選択なし';
    }
    let content = '';
    statuses.sort();
    statuses.forEach((value: SpotLessonPhase) => {
      if (SpotLessonPhase.Incomplete === value) {
        content += '実施前 ';
      } else if (SpotLessonPhase.Complete === value) {
        content += '実施済み ';
      } else if (SpotLessonPhase.Cancel === value) {
        content += 'キャンセル';
      } else if (SpotLessonPhase.NonAttendance === value) {
        content += '不参加';
      }
    });

    return content;
  };

  const onChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const valPhase = strToEnum(e.target.value, SpotLessonPhase);
      if (valPhase) {
        const newStatuses = Array.from(new Set([...props.statuses].concat(valPhase)));
        setFilterCOntent(createFilterContent(newStatuses));
        props.onChangeStatus(newStatuses);
      }
    } else {
      const newStatuses = props.statuses.filter((i) => i !== e.target.value);
      setFilterCOntent(createFilterContent(newStatuses));
      props.onChangeStatus(newStatuses);
    }
  };

  useOutsideClick(openTipRef, statusTipRef, (): void => {
    setIsStatusTipOpen(false);
  });

  return (
    <Container>
      <P>日付</P>
      <DatePicker
        placeholderText={`${props.startDate ? format(props.startDate, 'yyyy/MM/dd') : ''} - ${
          props.endDate ? format(props.endDate, 'yyyy/MM/dd') : ''
        }`}
        onChange={onChangeDate}
        startDate={props.startDate}
        endDate={props.endDate}
        shouldCloseOnSelect={shouldCloseDate}
        selectsRange
      />
      <P>ステータス</P>
      <StatusArea ref={statusTipRef}>
        <StatusBox ref={openTipRef} onClick={() => setIsStatusTipOpen((prevState) => !prevState)}>
          {filterContent}
        </StatusBox>
        <StatusTooltip isOpen={isStatusTipOpen}>
          <p>
            <input
              type="checkbox"
              name="statuses"
              value={SpotLessonPhase.Incomplete}
              id="statuses_0"
              onChange={onChangeStatus}
              defaultChecked
            />
            <label htmlFor="statuses_0">実施前</label>
          </p>
          <p>
            <input
              type="checkbox"
              name="statuses"
              value={SpotLessonPhase.Complete}
              id="statuses_1"
              onChange={onChangeStatus}
              defaultChecked
            />
            <label htmlFor="statuses_1">実施済</label>
          </p>
          <p>
            <input
              type="checkbox"
              name="statuses"
              value={SpotLessonPhase.Cancel}
              id="statuses_2"
              onChange={onChangeStatus}
              defaultChecked
            />
            <label htmlFor="statuses_2">キャンセル</label>
          </p>
          <p>
            <input
              type="checkbox"
              name="statuses"
              value={SpotLessonPhase.NonAttendance}
              id="statuses_3"
              onChange={onChangeStatus}
              defaultChecked
            />
            <label htmlFor="statuses_3">不参加</label>
          </p>
        </StatusTooltip>
      </StatusArea>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .react-datepicker-wrapper {
    width: auto;
  }

  ${media.lessThan('medium')`
    justify-content: space-between;
  `}
`;

const P = styled.p`
  ${media.lessThan('medium')`
    display: none;
  `}

  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 32px;
  margin-right: 14px;
`;

const StatusArea = styled.div`
  position: relative;
`;

const StatusBox = styled.p`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  width: 139px;
  padding: 0.5rem;
  background-color: #ffffff;
`;

const StatusTooltip = styled.div<{ isOpen?: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  right: 0;
  top: 40px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;

  p {
    & + * {
      margin-top: 0.5rem;
    }

    label {
      margin-left: 0.25rem;
    }
  }
`;
