import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { SubscriptionContractForListFragment as SubscriptionContract } from '../../gen/graphql';
import { format, parseISO } from 'date-fns';
import Pagination from './Pagination';

interface SubscriptionContractListProps {
  contracts: SubscriptionContract[];
  total: number;
  perPage: number;
  page: number;
  setPage: (page: number) => void;
}

export const SubscriptionContractList: React.FC<SubscriptionContractListProps> = ({
  contracts,
  total,
  perPage,
  page,
  setPage,
}) => {
  const formatDate = (date: string, formatType: string) => {
    return format(parseISO(date), formatType);
  };

  return (
    <React.Fragment>
      {contracts.length > 0 && (
        <Container>
          <ContentTitle>契約履歴</ContentTitle>
          <HistoryTitleRow>
            <dt>契約期間</dt>
            <dd>プラン名</dd>
          </HistoryTitleRow>
          {contracts.map((item) => (
            <HistoryRow key={item.id}>
              <dt>
                {formatDate(item.periodStart, 'yyyy/MM/dd')}
                {' ~ '}
                {formatDate(item.endDate ? item.endDate : item.periodEnd, 'yyyy/MM/dd')}
              </dt>
              <dd>{item.price?.product?.name}</dd>
            </HistoryRow>
          ))}
          <Pagination total={total} perPage={perPage} page={page} setPage={setPage} />
        </Container>
      )}
    </React.Fragment>
  );
};

const Container = styled.section`
  width: 100%;
  max-width: 800px;
  margin: 1.25rem auto 0;
  padding: 2rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    width: calc(100% + 2rem);
    margin: 1rem -1rem 0;
  `}
`;
const ContentTitle = styled.h2`
  margin-bottom: 3rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
  `}
`;
const HistoryRow = styled.dl`
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.87);
  line-height: 1rem;

  dt {
    width: 197px;
    font-size: 0.875rem;
  }

  dd {
    flex: 1;
    font-size: 0.875rem;
  }

  ${media.lessThan('medium')`
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;

    dt {
      order: 2;
      width: 100%;

      &:before {
        content: '(契約期間： ';
      }
      &:after {
        content: ')';
      }
    }

    dd {
      order: 1;
      width: 100%;
      font-size: 1rem;
      font-weight: 700;
    }
  `}
`;
const HistoryTitleRow = styled(HistoryRow)`
  color: rgba(0, 0, 0, 0.6);

  ${media.lessThan('medium')`
    display: none;
  `}
`;
