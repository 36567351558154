import React, { useMemo } from 'react';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { useUser } from '../../redux/user/useUser';
import TabNavigationLayout from './TabNavigationLayout';
import { useURL } from '../../common/customHooks/URL';

interface Props {
  activeTab: string;
  metaTitle: string;
  metaDescription?: string;
}

export const PracticeTabLayout: React.FC<Props> = ({
  activeTab,
  metaTitle,
  metaDescription,
  children,
}): JSX.Element => {
  const { permissionCheck } = useUser();
  const { excludePageQuery } = useURL();

  const tabs = useMemo(() => {
    const tabs = [{ label: '課題一覧', to: '/practices', active: activeTab === 'top' }];

    permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Read)
      ? tabs.push(
          {
            label: 'すべての提出物',
            to: excludePageQuery('/practices/submits/all'),
            active: activeTab === 'all',
          },
          {
            label: '担当受講生の提出物',
            to: excludePageQuery('/practices/submits/my_students'),
            active: activeTab === 'my_students',
          },
          {
            label: 'レビューした提出物',
            to: excludePageQuery('/practices/submits/reviewed'),
            active: activeTab === 'reviewed',
          },
        )
      : tabs.push(
          {
            label: 'あなたの提出物',
            to: excludePageQuery('/practices/submits/mine'),
            active: activeTab === 'mine',
          },
          {
            label: 'みんなの提出物',
            to: excludePageQuery('/practices/submits/everyone'),
            active: activeTab === 'everyone',
          },
          {
            label: '下書き',
            to: excludePageQuery('/practices/submits/my_draft'),
            active: activeTab === 'my_draft',
          },
        );

    return tabs;
  }, [permissionCheck, excludePageQuery, activeTab]);

  return (
    <TabNavigationLayout
      pageTitle="課題"
      tabs={tabs}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {children}
    </TabNavigationLayout>
  );
};
