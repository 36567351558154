import React, { useContext } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { HeadTagContext } from '../../context/HeadTagProvider';
import { SubscriptionHeader } from '../organisms/SubscriptionHeader';
import { useMetaData } from '../../common/customHooks/MetaReplace';

interface Props {
  metaTitle: string;
  metaDescription?: string;
}

export const SubscriptionLayout: React.FC<Props> = ({
  metaTitle,
  metaDescription,
  children,
}): JSX.Element => {
  const headTagContext = useContext(HeadTagContext);

  useMetaData(metaTitle, metaDescription);

  return (
    <>
      <Helmet title={headTagContext.title} meta={headTagContext.meta} />
      <Wrapper>
        <SubscriptionHeader />
        <main>{children}</main>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
