import React from 'react';
import styled from 'styled-components';

interface NotificationBadgeProps {
  count?: number;
  shift?: boolean;
}

export const NotificationBadge: React.FC<NotificationBadgeProps> = (props) => {
  return props.count ? (
    <Container shift={props.shift}>
      <Count>{props.count}</Count>
    </Container>
  ) : (
    <p style={{ display: 'none' }}></p>
  );
};

const Container = styled.p<{ shift?: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e2001b;

  ${(props) =>
    props.shift
      ? `
      top: -12px;
      right: -12px;
    `
      : `
      top: 0;
      right: 0;
    `}
`;

const Count = styled.span`
  position: absolute;
  font-size: 0.75rem;
  font-weight: 600;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
`;
