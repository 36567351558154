import React from 'react';
import styled from 'styled-components';
import { NotificationCourseFragment } from '../../gen/graphql';

interface NotificationCourseProps {
  course: NotificationCourseFragment;
}

export const NotificationCourse: React.FC<NotificationCourseProps> = (props) => {
  return (
    <Container>
      <Title>{props.course.plan.name}</Title>
      <SubTitle>
        {/* FIXME nameが取得できるようになったらnameを表示する */}
        {props.course.student.user.nickName} / {props.course.instructor?.user.nickName}
      </SubTitle>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  box-sizing: border-box;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 1rem;
  box-sizing: border-box;
  font-size: 1rem;
  justify-content: center;
  font-weight: 600;
  word-break: break-all;
`;

const SubTitle = styled.div`
  padding: 0rem 1rem 1rem 1rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  justify-content: center;
  word-break: break-all;
  color: rgba(0, 0, 0, 0.36);
`;
