import React from 'react';
import styled from 'styled-components';
import SearchIcon from '../../static/image/icon_search_gray.svg';

interface Props {
  name: string;
  onClick?: () => void;
  withSearchIcon?: boolean; // 虫眼鏡のアイコンを表示するか
  className?: string;
}

export const Tag: React.FC<Props> = ({
  name,
  onClick,
  withSearchIcon = false,
  className,
}): JSX.Element => (
  <StyledTag
    onClick={onClick}
    withSearchIcon={withSearchIcon}
    clickable={!!onClick}
    className={className}
  >
    {name}
  </StyledTag>
);

const StyledTag = styled.span<{ clickable: boolean; withSearchIcon: boolean }>`
  padding: 0.25rem 0.75rem;
  background: rgba(241, 196, 78, 0.6);
  border: 1px solid #f1c44e;
  box-sizing: border-box;
  border-radius: 1.25rem;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.33;
  color: rgba(51, 51, 51, 0.87);
  ${({ clickable }) => clickable && `cursor: pointer;`}

  &:before {
    display: ${({ withSearchIcon }) => (withSearchIcon ? 'inline-block' : 'none')};
    margin-right: 0.25rem;
    content: '';
    width: 0.75rem;
    height: 0.75rem;
    background: url(${SearchIcon}) center / contain no-repeat;
    vertical-align: -0.125rem;
  }
`;
