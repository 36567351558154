import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link, useNavigate } from 'react-router-dom';
import { Intercom } from './Intercom';

import { useUser } from '../../redux/user/useUser';
import { Hamburger } from '../atoms/Hamburger';
import { PageTitle } from '../atoms/PageTitle';
import { CircularIcon } from '../atoms/CircularIcon';
import { DummySearchForm } from '../atoms/DummySearchForm';
import { PopupMenu } from '../molecules/PopupMenu';
import { LoginModal } from './LoginModal';
import DefaultIcon from '../../static/image/icon_default.svg';
import NotificationIcon from '../../static/image/icon_notification.svg';
import _ from 'lodash';
import { useNotificationUnreadCount } from '../../common/customHooks/NotificationUnreadCount';
import { TeamPopupLabel } from '../atoms/TeamPopupLabel';
import { TeamPopupMenu } from '../molecules/TeamPopupMenu';
import { UserRole } from '../../gen/graphql';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { Z_INDEX_APPBAR } from '../../const/ZIndex';
import { Button } from '../atoms/Button';
import { LeadButtonToPro } from '../atoms/LeadButtonToPro';

interface AppBarProps {
  pageTitle: string;
  handleLogout: () => Promise<void>;
  logoutTransition: string;

  /**
   * Intercom表示対象画面か。
   * (表示対象画面の場合は、表示対象ロールの場合にIntercomを表示。)
   */
  hasIntercom?: boolean;

  appBarRef: React.RefObject<HTMLDivElement>;
  unfixed?: boolean;
}

export const AppBar: React.FC<AppBarProps> = (props) => {
  const navigate = useNavigate();

  const {
    user,
    changeRole,
    changeRoleStudent,
    changeTeamID,
    permissionCheck,
    permissionCheckToInstructor,
    permissionCheckToCoach,
    permissionCheckToStudent,
  } = useUser();
  const { totalCount: unreadCount } = useNotificationUnreadCount();
  const userIconRef = useRef<HTMLDivElement>(null);
  const teamPopupRef = useRef<HTMLLabelElement>(null);
  const [isTeamPopupRefOpen, setIsTeamPopupRefOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const click = async () => {
    await props.handleLogout();
    if (!_.isEmpty(props.logoutTransition)) {
      navigate(props.logoutTransition);
    }
  };

  const openModal = () => {
    setLoginModalOpen(true);
  };

  const closeModal = () => {
    setLoginModalOpen(false);
  };

  return (
    <React.Fragment>
      <Header unfixed={props.unfixed}>
        <Hamburger hamburgerRef={props.appBarRef} />
        <Contents>
          <PageTitle>{props.pageTitle}</PageTitle>
          <SearchForms>
            <DummySearchForm pc />
            {(permissionCheck(FunctionType.AdsForSubscription, PermissionType.Read) ||
              permissionCheck(FunctionType.AdsForFree, PermissionType.Read)) && (
              <StyledLeadButtonToPro />
            )}
            {permissionCheck(FunctionType.AdsForFree, PermissionType.Read) && (
              <StyledLeadButton onClick={() => navigate('/account/subscription/lp')}>
                有料プラン
              </StyledLeadButton>
            )}
          </SearchForms>
          <Icons>
            {permissionCheck(FunctionType.TeamTop, PermissionType.Read) && (
              <>
                <TeamPopupLabel ref={teamPopupRef} isOpen={isTeamPopupRefOpen} />
                <TeamPopupMenu
                  openRef={teamPopupRef}
                  isOpen={isTeamPopupRefOpen}
                  setIsOpen={setIsTeamPopupRefOpen}
                  myTeamMembers={user.teams}
                  changeTeamID={changeTeamID}
                />
              </>
            )}
            {user.isLogged ? (
              <>
                {permissionCheck(FunctionType.Notification, PermissionType.Read) && (
                  <Link to="/notification/notification">
                    <Notification>
                      <img src={NotificationIcon} alt="" />
                      {unreadCount ? <Count>{unreadCount}</Count> : ''}
                    </Notification>
                  </Link>
                )}
                <CircularIcon
                  src={user.lmsUser?.image ? user.lmsUser?.image : DefaultIcon}
                  ref={userIconRef}
                  size={30}
                  e2e="header-user-icon"
                />
              </>
            ) : (
              <>
                <RegisterButton as={Link} to="/"></RegisterButton>
                <LoginButton onClick={openModal}></LoginButton>
              </>
            )}
          </Icons>
        </Contents>

        {user.isLogged && (
          <PopupMenu openRef={userIconRef}>
            <Ul>
              <Link to="/account/profile">
                <Li>アカウント設定</Li>
              </Link>
              <Link to={`/users/${user.lmsUser?.id}/profile`}>
                <Li>マイページ</Li>
              </Link>
              {/* コーチ権限を持っている場合はコーチ画面だけ、コーチ権限を持っていない場合はインストラクター画面だけを表示できる(コーチとインスト間は行き来できない) */}
              {permissionCheckToInstructor() && (
                <Link to="/" onClick={() => changeRole(UserRole.Instructor)}>
                  <Li>インストラクター用画面へ</Li>
                </Link>
              )}
              {permissionCheckToCoach() && (
                <Link to="/" onClick={() => changeRole(UserRole.Coach)}>
                  <Li>コーチ用画面へ</Li>
                </Link>
              )}
              {permissionCheckToStudent() && (
                <Link to="/" onClick={() => changeRoleStudent()}>
                  <Li>受講生用画面へ</Li>
                </Link>
              )}
              <Li onClick={click} style={{ cursor: 'pointer' }}>
                <span>ログアウト</span>
              </Li>
            </Ul>
          </PopupMenu>
        )}
      </Header>

      {props.hasIntercom &&
        permissionCheck(FunctionType.DocumentForSubscription, PermissionType.Read) && <Intercom />}
      <LoginModal isOpen={loginModalOpen} onClose={closeModal} />
    </React.Fragment>
  );
};

const Header = styled.header<{ unfixed?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 66px;
  background: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  padding: 0 1.5rem;
  transition: transform 0.1s ease-out 0.1s;
  position: ${(props) => (props.unfixed ? 'relative' : 'sticky')};
  top: 0;
  z-index: ${Z_INDEX_APPBAR + 1};

  ${media.lessThan('small')`
    padding: 0 1rem;
  `}
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 1rem;
  }

  ${media.lessThan('small')`
    flex: 1;
    justify-content: flex-end;
    margin-left: 2rem;

    > * + * {
      margin-right: 0;
      margin-left: 0.75rem;
    }
  `}
`;

const Contents = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const SearchForms = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 0;
`;

const StyledLeadButtonToPro = styled(LeadButtonToPro)`
  ${media.lessThan('medium')`
    display: none;
  `}
`;

const StyledLeadButton = styled(Button)`
  border: none;
  border-radius: 0.125rem;
  background: var(--samurai_gradient1, linear-gradient(95deg, #fd8258 11.86%, #fd3c2f 82.5%));
  line-height: 1rem;

  ${media.lessThan('medium')`
    display: none;
    font-size: 1rem;
  `}
`;

const Ul = styled.ul`
  display: flex;
  position: fixed;
  right: 2rem;
  top: 2rem;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ddd;
  z-index: 2;
`;

const Li = styled.li`
  padding: 0.75rem 1.5rem;
  font-weight: 600;
`;
const LoginButton = styled.div`
  display: block;
  background-color: #e2001b;
  margin-left: 0.5rem;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  color: #e2001b;
  font-size: 0.8rem;
  font-weight: 600;
  background: #fff;
  border: 1px solid #e2001b;
  border-radius: 3px;

  &:before {
    content: 'ログインする';
  }

  ${media.lessThan('medium')`
    padding: .75rem 1rem;

    &:before {
      content: 'ログイン';
    }
  `}
`;

const RegisterButton = styled(LoginButton)`
  background: #e2001b;
  color: #fff;

  &:before {
    content: 'いますぐ登録する';
  }

  ${media.lessThan('medium')`
    &:before {
      content: '登録する';
    }
  `}
  ${media.lessThan('small')`
    display: none;
  `}
`;

const Notification = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;

const Count = styled.span`
  display: block;
  width: auto;
  min-width: 1rem;
  height: 1rem;
  padding: 0 0.125rem;
  background: #eb0000;
  border-radius: 1rem;
  box-sizing: border-box;
  color: #fff;
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1rem;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: calc(100% - 0.5rem);
`;
