import React from 'react';
import styled from 'styled-components';

import { Tag } from '../atoms/Tag';

import { FIRST_PAGE, MAX_LIMIT } from '../../const/Page';
import { useGetTagsQuery } from '../../gen/graphql';
import { useNavigate } from 'react-router-dom';

export const SearchTags: React.FC = () => {
  const navigate = useNavigate();

  const { data } = useGetTagsQuery({
    variables: {
      input: {
        page: FIRST_PAGE,
        limit: MAX_LIMIT,
      },
    },
  });

  const handleClick = (value: string) => {
    navigate({
      pathname: '/search',
      search: `?word=${encodeURIComponent(value)}`,
    });
  };

  return (
    <section>
      <Title>タグ</Title>
      <Container>
        {data?.getTags.items.map((item) => {
          return (
            <Tag
              key={item.id}
              name={item.name}
              onClick={() => handleClick(item.name)}
              withSearchIcon
            />
          );
        })}
      </Container>
    </section>
  );
};

const Title = styled.h2`
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.1px;
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
`;
