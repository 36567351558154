import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface LabeledLayoutProps {
  labelWidth?: string;
  label?: React.ReactNode;
  required?: boolean;
}

export const LabeledLayout: React.FC<LabeledLayoutProps> = (props): JSX.Element => (
  <Container>
    <Label width={props.labelWidth}>
      {props.required ? <span className="required">必須</span> : ''}
      {props.label}
    </Label>
    <Content>{props.children}</Content>
  </Container>
);

const Container = styled.div`
  padding: 0.6rem 0;
  display: flex;
  box-sizing: border-box;
  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

const Label = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  margin: 0.4rem 0;
  min-width: ${(props): string => (props.width ? props.width : 'none')};
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1px;

  ${media.lessThan('medium')`
    margin: 0.6rem 0;
  `}

  .required {
    display: inline-block;
    margin-right: 0.5rem;
    padding: 0 0.5rem;
    background: #fd6258;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1rem;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`;
