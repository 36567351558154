import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { TabNavigationLayout } from '../../templates/TabNavigationLayout';
import { Loaders } from '../../../common/Loader';
import { EventArticle } from '../../organisms/EventArticleSingle';
import * as Sentry from '@sentry/react';
import { LOWER_META_TITLE } from '../../../const/Service';

interface EventPropsItem {
  id: string;
  event_id: string;
  link: string;
  started_at: Date;
  ended_at: Date;
  event_url: string;
  place: string;
  title: string;
  address: string;
  limit: string;
  accepted: string;
}

export const TopEvent: React.FC = () => {
  const metaTitle = `イベント | ${LOWER_META_TITLE}`;

  const location = useLocation();
  const indexType = location.pathname.split('/').slice(-1)[0];

  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState<EventPropsItem[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    await axios
      .get('https://asia-northeast1-news-276315.cloudfunctions.net/events')
      .then((res) => {
        setEvent(res.data);
      })
      .catch((err) => Sentry.captureException(err))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <TabNavigationLayout
      pageTitle="教材"
      tabs={[
        { label: 'TOP', to: '/home', active: indexType === '' },
        { label: 'みんなのつぶやき', to: '/tweet', active: indexType === 'tweet' },
        { label: 'イベント', to: '/event', active: indexType === 'event' },
      ]}
      metaTitle={metaTitle}
    >
      <Container>
        <News>
          {loading ? (
            <Loaders width="306px" height="240px" margin="0 0.75rem 1rem 0" number={3} />
          ) : (
            event &&
            event.map((p) => {
              return (
                <EventArticle
                  id={p.event_id}
                  key={p.event_id}
                  link={p.event_url}
                  started_at={p.started_at}
                  ended_at={p.ended_at}
                  title={p.title}
                  address={p.place}
                  limit={p.limit}
                  accepted={p.accepted}
                />
              );
            })
          )}
        </News>
      </Container>
    </TabNavigationLayout>
  );
};

const Container = styled.div`
  max-width: 1020px;
  padding: 2rem;
  box-sizing: border-box;

  p {
    margin-bottom: 2rem;
    font-size: 1.25rem;
    line-height: 1.3;
    font-weight: 600;
  }

  h2 {
    margin-bottom: 1.5rem;
  }

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const News = styled.section`
  position: relative;
  width: 100%;
  margin: 1rem auto 2rem;

  ${media.lessThan('medium')`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`;
