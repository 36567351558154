import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import defaultIcon from '../../../static/image/icon_default.svg';

import { BasicLayout } from '../../templates/BasicLayout';
import { Chip } from '../../atoms/Chip';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { Loader } from '../../molecules/Loader';

import { SERVICE_NAME, LOWER_META_TITLE } from '../../../const/Service';
import { convertToJSTFormat } from '../../../utils/DateJST';

import {
  useGetLessonsForStudentQuery,
  useGetNextLessonForStudentQuery,
} from '../../../gen/graphql';

const zeroPadding = (num: number) => `0${num}`.substr(-2);

export const Lesson: React.FC = () => {
  const metaTitle = `専属レッスン | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}の専属レッスンページです。レッスン日の確認やこれまでのレッスン履歴を確認できます。`;

  const { data: nextLessonData, loading: nextLessonLoading } = useGetNextLessonForStudentQuery();
  const nextLesson = nextLessonData?.nextLessonForStudent;

  const { data: lessonData, loading: lessonLoading } = useGetLessonsForStudentQuery();
  const lessons = lessonData?.lessonsForStudent;
  const previousLessons = lessons
    ? lessons
        .filter(
          (lesson) =>
            new Date(lesson.startAt ? lesson.startAt : '') < new Date() || lesson.completed,
        )
        .sort((a, b) =>
          new Date(a.startAt ? a.startAt : '') > new Date(b.startAt ? b.startAt : '') ? -1 : 1,
        )
    : [];
  const forwardLessons = lessons
    ? lessons
        .filter(
          (lesson) =>
            new Date(lesson.startAt ? lesson.startAt : '') >= new Date() && !lesson.completed,
        )
        .sort((a, b) =>
          new Date(a.startAt ? a.startAt : '') < new Date(b.startAt ? b.startAt : '') ? -1 : 1,
        )
    : [];

  return (
    <BasicLayout
      pageTitle="専属レッスン"
      hasIntercom
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      <PageWrapper>
        <div className="container">
          <Info>
            <Loader display={lessonLoading || nextLessonLoading}></Loader>
            <NextLesson>
              <h4>{nextLesson ? '次回レッスン' : '次回以降のレッスンが未登録の状態です。'}</h4>
              {nextLesson ? (
                <section>
                  <LessonDate>
                    <time>
                      {`${convertToJSTFormat(nextLesson?.startAt, 'MM')}/${convertToJSTFormat(
                        nextLesson?.startAt,
                        'DD',
                      )}`}
                      <span>{`(${convertToJSTFormat(nextLesson?.startAt, 'E')})`}</span>
                    </time>
                    <time>
                      {`${convertToJSTFormat(nextLesson?.startAt, 'HH:mm')}〜${convertToJSTFormat(
                        nextLesson?.endAt,
                        'HH:mm',
                      )}`}
                    </time>
                  </LessonDate>

                  <div>
                    <h5>{nextLesson?.title}</h5>

                    <Instructor>
                      <span>
                        <IconImage src={nextLesson?.course?.instructor?.user?.image} alt="icon" />
                      </span>
                      <span>
                        {nextLesson?.course?.instructor?.user?.maskedPersonalInfo?.name &&
                        nextLesson?.course?.instructor?.user?.maskedPersonalInfo?.name?.length > 20
                          ? `${nextLesson?.course?.instructor?.user?.maskedPersonalInfo?.name?.substr(
                              0,
                              20,
                            )}...`
                          : nextLesson?.course?.instructor?.user?.maskedPersonalInfo?.name}
                      </span>
                    </Instructor>
                  </div>
                </section>
              ) : (
                <div>
                  <p>担当インストラクターがレッスン日程を登録すると表示されます。</p>
                  <p>
                    まだレッスン日程を決めていない場合は、メッセージ機能を使って相談しましょう。
                  </p>
                </div>
              )}
            </NextLesson>
            <Inquiry>
              <H3>お困りですか？</H3>
              <ul>
                <li>・インストラクターと連絡が取れない</li>
                <li>・レッスン開始時間になってもインストラクターがこない・連絡が取れない</li>
                <li>・レッスン直前にインストラクターに授業をキャンセルされた</li>
              </ul>
              <p>
                などの場合は
                <a href="https://tayori.com/faq/2afb58b87ebf5105ff984f92aad98eb81be77847/detail/2508beebff951fb788306a8ee320372a2aab6c0d">
                  こちら
                </a>
                から運営にご連絡ください。
              </p>
            </Inquiry>
          </Info>
          <Lessons>
            <H3>今後のレッスン予定</H3>
            {forwardLessons?.length > 0 ? (
              forwardLessons
                .filter((lesson) => lesson.id !== nextLesson?.id)
                .map((lesson) => {
                  const fullName = lesson.course?.instructor?.user?.maskedPersonalInfo?.name;
                  const startAt = new Date(lesson.startAt ? lesson.startAt : '');
                  const endAt = new Date(lesson.endAt ? lesson.endAt : '');

                  return (
                    <LessonContent key={lesson.id}>
                      <section>
                        <LessonDate>
                          <time>
                            {`${convertToJSTFormat(startAt, 'MM')}/${zeroPadding(
                              startAt.getDate(),
                            )}`}
                            <span>{`(${convertToJSTFormat(startAt, 'E')})`}</span>
                          </time>
                          <time>
                            {`${convertToJSTFormat(startAt, 'HH:mm')}〜${convertToJSTFormat(
                              endAt,
                              'HH:mm',
                            )}`}
                          </time>
                        </LessonDate>
                        <div>
                          <h5>{lesson.title}</h5>

                          <p>
                            <span>
                              <IconImage
                                src={
                                  lesson.course?.instructor?.user?.image
                                    ? lesson.course?.instructor?.user?.image
                                    : defaultIcon
                                }
                                alt="icon"
                              />
                            </span>
                            {fullName?.length && fullName.length > 20
                              ? `${fullName?.substr(0, 20)}...`
                              : fullName}
                          </p>
                        </div>
                      </section>
                    </LessonContent>
                  );
                })
            ) : (
              <EmptyBlock title="次回以降のレッスンが未登録の状態です">
                担当インストラクターがレッスン日程を登録すると表示されます。
                <br />
                まだレッスン日程を決めていない場合は、メッセージ機能を使って相談しましょう。
              </EmptyBlock>
            )}
          </Lessons>

          <Lessons>
            <H3>これまでの受講履歴（計 {previousLessons.length} 回）</H3>
            {previousLessons.length > 0 ? (
              previousLessons.map((lesson) => {
                const fullName = lesson.course?.instructor?.user?.maskedPersonalInfo?.name;
                const startAt = new Date(lesson.startAt ? lesson.startAt : '');
                const endAt = new Date(lesson.endAt ? lesson.endAt : '');

                return (
                  <LessonContent>
                    <section>
                      <LessonDate>
                        <time>
                          {`${convertToJSTFormat(startAt, 'MM')}/${zeroPadding(startAt.getDate())}`}
                          <span>{`(${convertToJSTFormat(startAt, 'E')})`}</span>
                        </time>
                        <time>
                          {`${convertToJSTFormat(startAt, 'HH:mm')}〜${convertToJSTFormat(
                            endAt,
                            'HH:mm',
                          )}`}
                        </time>
                        {lesson.canceled ? (
                          <CancelLabel>
                            キャンセル
                            <br />
                            （レッスン消化）
                          </CancelLabel>
                        ) : (
                          ''
                        )}
                      </LessonDate>
                      <div>
                        <Tag>
                          <Chip>{lesson.course?.plan?.name}</Chip>
                        </Tag>
                        <h5>{lesson.title}</h5>
                        <Instructor>
                          <span>
                            <IconImage
                              src={
                                lesson.course?.instructor?.user?.image
                                  ? lesson.course?.instructor?.user?.image
                                  : defaultIcon
                              }
                              alt="icon"
                            />
                          </span>
                          <span>
                            {fullName?.length && fullName.length > 20
                              ? `${fullName?.substr(0, 20)}...`
                              : fullName}
                          </span>
                        </Instructor>
                      </div>
                    </section>
                  </LessonContent>
                );
              })
            ) : (
              <EmptyBlock title="まだレッスン履歴がありません">
                過去のレッスンの一覧がここに表示されます。
              </EmptyBlock>
            )}
          </Lessons>
        </div>
      </PageWrapper>
    </BasicLayout>
  );
};

const H3 = styled.h3`
  margin-bottom: 1rem;
  font-weight: 600;
`;

const Info = styled.div`
  display: flex;
  margin-bottom: 2rem;

  ${media.lessThan('large')`
    flex-direction: column;
  `}
`;
const NextLesson = styled.div`
  flex: 1;
  padding: 1.25rem;
  background-color: #ffe86d;
  box-sizing: border-box;
  line-height: 1.3;

  h4 {
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  section {
    display: flex;
  }

  h5 {
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.75rem;
  }

  ${media.lessThan('small')`
    section {
      flex-direction: column;
    }
  `}
`;

const Inquiry = styled.div`
  flex: 1;
  margin-left: 1rem;
  padding: 1.25rem;
  border: 1px solid #ddd;
  box-sizing: border-box;
  line-height: 1.3;

  p,
  li,
  a {
    font-size: 0.75rem;
  }

  p {
    margin-top: 0.75rem;
  }

  a {
    color: #e2001b;
  }

  ${media.lessThan('large')`
    margin: 1rem 0 0;
  `}
`;

const Lessons = styled.section`
  margin-bottom: 2rem;
`;

const LessonContent = styled.div`
  width: 100%;
  max-width: 839px;
  box-sizing: border-box;
  line-height: 1.3;

  section {
    display: flex;
    padding: 1rem;
  }

  h5 {
    margin: 0.625rem 0.375rem;
    font-weight: 600;
  }

  ${media.lessThan('small')`
    width: auto;
    margin: 1rem -1rem 0;
    padding: 1rem 1rem 0;
    border-top: 1px dotted #ddd;

    section {
      flex-direction: column;
      padding: 0;
    }
  `}
`;

const LessonDate = styled.div`
  margin-right: 2rem;
  text-align: left;

  time {
    display: block;
    font-size: 0.75rem;

    &:nth-of-type(1) {
      font-size: 1.5rem;
      font-weight: 600;

      span {
        font-size: 0.875rem;
        font-weight: 300;
      }
    }
  }

  ${media.lessThan('small')`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 1rem;
    position: relative;

    time {
      &:nth-of-type(1) {
        margin-right: .25rem;
        font-size: 1.25rem;

        span {
          font-size: .75rem;
        }
      }
    }
  `}
`;

const IconImage = styled.img`
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
`;

const Instructor = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  span {
    font-size: 0.75rem;

    + span {
      margin-left: 0.5rem;
    }
  }
`;

const CancelLabel = styled.p`
  margin: 0.2rem;
  padding: 0.25rem 0;
  border: 1px solid #e2001b;
  border-radius: 4px;
  color: #e2001b;
  font-size: 0.625rem;
  font-weight: 700;
  text-align: center;

  ${media.lessThan('small')`
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
  `}
`;

const Tag = styled.div`
  font-size: 0;
`;
