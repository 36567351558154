import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import TagManager from 'react-gtm-module';

import { Button } from '../atoms/Button';
import { ErrorText } from '../atoms/ErrorText';
import { UserIcon } from '../organisms/UserIcon';
import { Control } from './Control';
import { Markdown } from '../../common/Markdown';
import { RichInput, RichInputHandler } from '../molecules/RichInput';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { useUser } from '../../redux/user/useUser';
import {
  QuestionAnswerFragment,
  useUpdateQuestionAnswerMutation,
  AnswerType,
} from '../../gen/graphql';

import ImgFavoriteCheckWhite from '../../static/image/favorite_check_white.svg';
import ImgFavoriteCheckRed from '../../static/image/favorite_check_red.svg';
import ImgResponseAlert from '../../static/image/response_alert.svg';

import {
  QuestionAnswerContentError,
  validateQuestionAnswerContent,
} from '../../utils/FormValidation';
import { useToastsContext } from '../../context/ToastsProvider';
import { defaultErrorMessage } from '../../const/ErrorMessage';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface QuestionResponseAnswerProps {
  questionId: number;
  response: QuestionAnswerFragment;
  refetchQuestion: () => void;
  onChangeFavoriteStatus: (questionId: number) => void;
}

export const QuestionResponseAnswer: React.FC<QuestionResponseAnswerProps> = (props) => {
  const { user, permissionCheck } = useUser();
  const {
    response: { id, questionAnswerFavorites },
    onChangeFavoriteStatus,
  } = props;

  const { showToast } = useToastsContext();

  const [openEdit, setOpenEdit] = useState(false);
  const [editAnswerId, setEditAnswerId] = useState(0);
  const [contentError, setContentError] = useState<QuestionAnswerContentError | null>(null);

  const [updateQuestionAnswer] = useUpdateQuestionAnswerMutation({
    onCompleted: props.refetchQuestion,
  });

  const editAnswerContentRef = useRef<RichInputHandler>(null);

  const handleEditAnswer = (id: number, content: string) => {
    TagManager.dataLayer({
      dataLayer: {
        userID: user.lmsUser?.id,
        user: user.lmsUser,
        event: 'gtm-qa-ans-edit',
        eventData: {
          questionID: props.questionId,
          answerID: id,
        },
      },
      dataLayerName: 'LMSDataLayer',
    });
    setEditAnswerId(id);
    editAnswerContentRef.current?.setValue(content);
    setOpenEdit(true);
  };

  const closeEdit = () => {
    setOpenEdit(false);
  };

  const updateAnswer = useSafeAsyncCallback(
    useCallback(async () => {
      const editAnswerContent = editAnswerContentRef.current?.getValue() ?? '';
      const error = validateQuestionAnswerContent(editAnswerContent);
      setContentError(error);
      if (error) return;

      try {
        await updateQuestionAnswer({
          variables: {
            id: editAnswerId,
            input: {
              content: editAnswerContent,
            },
          },
        });
      } catch (err) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, defaultErrorMessage);
      } finally {
        setOpenEdit(false);
      }
    }, [editAnswerId, updateQuestionAnswer, showToast]),
  );

  const controlMenu = [
    {
      text: '編集',
      onClick: () => {
        if (props.response.id && props.response.content)
          handleEditAnswer(props.response.id, props.response.content);
      },
    },
  ];

  const handleClickFavorite = useCallback(
    (): void => onChangeFavoriteStatus(id),
    [onChangeFavoriteStatus, id],
  );

  const isActiveFavorite = useMemo(
    (): boolean => questionAnswerFavorites.some(({ userID }) => userID === user.lmsUser?.id),
    [questionAnswerFavorites, user.lmsUser?.id],
  );

  useEffect(() => {
    editAnswerContentRef.current?.setValue(props.response.content);
  }, [props.response.content, openEdit]);

  return (
    <Container key={props.response.id}>
      <UserIcon
        user={props.response.user}
        date={props.response.updatedAt ?? props.response.createdAt}
        note={props.response.createdAt === props.response.updatedAt ? '' : '(編集済み)'}
        huge
        spSize="small"
      />
      <Content>
        {openEdit ? (
          <React.Fragment>
            <RichInput
              fileUpload={false}
              imageUpload
              initialHeight={10}
              ref={editAnswerContentRef}
              name="edit_question_answer"
            />
            {contentError && <StyledErrorText>{contentError.message}</StyledErrorText>}
            <ButtonArea>
              <StyledButton onClick={closeEdit} gray>
                キャンセル
              </StyledButton>
              <StyledButton onClick={updateAnswer}>更新する</StyledButton>
            </ButtonArea>
          </React.Fragment>
        ) : (
          <section data-e2e={props.response.user.isAI ? 'aiAnswer' : ''}>
            <Markdown content={props.response.content} />
          </section>
        )}
      </Content>
      {props.response.user?.id === user.lmsUser?.id &&
        !openEdit &&
        permissionCheck(FunctionType.QuestionAnswerAndComment, PermissionType.Update) && (
          <StyledControl menu={controlMenu} />
        )}
      <FavoriteButton $active={isActiveFavorite} onClick={handleClickFavorite}>
        参考になった {questionAnswerFavorites.length}
      </FavoriteButton>
      {props.response.answerType === AnswerType.AutoEscalation && (
        <ResponseNotice>
          <ResponseNoticeText>講師への回答依頼を自動的に行いました。</ResponseNoticeText>
        </ResponseNotice>
      )}
    </Container>
  );
};

const Container = styled.article`
  padding: 1rem;
  position: relative;

  ${media.lessThan('medium')`
    padding: 1rem 0;
  `}
`;

const Content = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  padding-left: 3.75rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    margin-top: .875rem;
    padding-left: 0;
  `}
`;

const StyledControl = styled(Control)`
  position: absolute;
  top: 1rem;
  right: 1rem;

  ${media.lessThan('medium')`
    right: 0;
  `}
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
    align-items: center;
    gap: .5rem;
    margin-top: 1rem;
  `}
`;
const StyledButton = styled(Button)`
  width: 100%;
  max-width: 240px;
`;

const FavoriteButton = styled(Button)<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 1.625rem 0 0 3.75rem;
  width: 9.125rem;
  height: 2.25rem;
  box-sizing: border-box;
  background: ${({ $active }) => ($active ? '#eb0000' : '#ffffff')};
  border: 1px solid #eb0000;
  border-radius: 0.1875rem;
  color: ${({ $active }) => ($active ? '#ffffff' : '#eb0000')};
  font-weight: 700;
  font-size: 0.75rem;

  &::before {
    content: '';
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.4375rem;
    background: center / contain no-repeat
      url(${({ $active }) => ($active ? ImgFavoriteCheckWhite : ImgFavoriteCheckRed)});
  }

  ${media.lessThan('medium')`
    margin: 1rem 0 0;
  `}
`;

const StyledErrorText = styled(ErrorText)`
  margin-top: 0.25rem;
`;

const ResponseNotice = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const ResponseNoticeText = styled.div`
  display: inline-block;
  width: auto;
  color: rgba(229, 39, 48, 1);
  background: rgba(254, 236, 228, 1);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 12px 20px 12px 20px;

  &::before {
    content: '';
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.4375rem;
    background: center / contain no-repeat url(${ImgResponseAlert});
  }
`;
