import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import { UserIcon } from './UserIcon';
import { UserFragment } from '../../gen/graphql';
import { User as TsUser } from '../../swagger/gen/ts_front';
import { convertToJSTFormat } from '../../utils/DateJST';

interface Props {
  startAt: Date;
  endAt: Date;
  title?: string;
  isSpot?: boolean;
  user?: UserFragment | TsUser;
}

export const TopNextLessonCard: React.FC<Props> = ({
  startAt,
  endAt,
  title,
  isSpot = false,
  user,
}): JSX.Element => {
  return (
    <StyledSection>
      <Header>
        <StyledH3>次回レッスン</StyledH3>
        <StyledLink to={isSpot ? '/spot_lessons' : '/lessons'} data-e2e="to-lessons">
          予約中のレッスン一覧 ＞
        </StyledLink>
      </Header>
      <Container>
        <DateTimeInfo>
          <DateText>
            {`${convertToJSTFormat(startAt, 'MM')}/${convertToJSTFormat(startAt, 'DD')}`}
            <span>({convertToJSTFormat(startAt, 'E')})</span>
          </DateText>
          <TimeText>
            {convertToJSTFormat(startAt, 'HH:mm')}~{convertToJSTFormat(endAt, 'HH:mm')}
          </TimeText>
        </DateTimeInfo>
        <DetailInfo>
          <Title>{title}</Title>
          <StyledUserIcon user={user} small name />
        </DetailInfo>
      </Container>
      <Footer>
        <StyledLink to={isSpot ? '/spot_lessons' : '/lessons'}>予約中のレッスン一覧 ＞</StyledLink>
      </Footer>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 1.25rem;
  ${media.lessThan('medium')`
    padding: 1.5rem 1rem 1.25rem;;
  `}
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: -0.5rem;

  & > * {
    margin-top: 0.5rem;
  }

  a {
    margin-left: auto;
    ${media.lessThan('medium')`
      display: none;
    `}
  }
`;
const StyledH3 = styled.h3`
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.33;
  color: #000000;

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.375;
  `}
`;
const StyledLink = styled(Link)`
  color: #e2001b;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.33;
`;
const Container = styled.div`
  margin-top: 1.8125rem;
  display: flex;

  ${media.lessThan('medium')`
    margin-top: 1rem;
    flex-direction: column;
  `}
`;
const DateTimeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.375rem 1.125rem;
  background: #fae9e2;
  color: #ee4646;
  border-radius: 3px;

  ${media.lessThan('medium')`
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.3125rem 1rem;
  `}
`;
const DateText = styled.p`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.2;

  span {
    font-size: 0.75rem;
    line-height: 1.33;
    margin-left: 0.125rem;
  }

  ${media.lessThan('medium')`
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.22;
    
    span {
      font-weight: 700;
    }
  `}
`;
const TimeText = styled.p`
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.23;
  margin-top: 0.4375rem;

  ${media.lessThan('medium')`
    font-size: 01rem;
    line-height: 1.25;
  `}
`;
const DetailInfo = styled.div`
  margin-left: 0.75rem;
  ${media.lessThan('medium')`
    margin: 1rem 0 0;
  `}
`;
const Title = styled.p`
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.33;

  ${media.lessThan('medium')`
    line-height: 1.4;
  `}
`;
const StyledUserIcon = styled(UserIcon)`
  margin-top: 0.5rem;
`;

const Footer = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
    justify-content: flex-end;
    margin-top: 1.125rem;
  `}
`;
