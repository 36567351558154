/* tslint:disable */
/* eslint-disable */
/**
 * LMS API
 * This is LMS API Documentation.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * create admin lesson request
 * @export
 * @interface CreateAdminLessonRequest
 */
export interface CreateAdminLessonRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateAdminLessonRequest
     */
    courseID: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAdminLessonRequest
     */
    instructorID: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminLessonRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminLessonRequest
     */
    startAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminLessonRequest
     */
    endAt: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAdminLessonRequest
     */
    type?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAdminLessonRequest
     */
    phase?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminLessonRequest
     */
    taughtSkill?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminLessonRequest
     */
    homework?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminLessonRequest
     */
    prepare?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminLessonRequest
     */
    delayReason?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAdminLessonRequest
     */
    studentAssistance?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAdminLessonRequest
     */
    instructorAssistance?: Array<number>;
    /**
     * 0: 遅れている 1: やや遅れている 2: 進捗通り 3: やや進んでいる 4: 進んでいる 
     * @type {number}
     * @memberof CreateAdminLessonRequest
     */
    progress?: CreateAdminLessonRequestProgressEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAdminLessonRequest
     */
    assistance?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminLessonRequest
     */
    assistanceDetail?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateAdminLessonRequestProgressEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}



