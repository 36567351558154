import { ApolloError } from '@apollo/client';
import { GraphQLFormattedError } from 'graphql';
import { ErrorCode } from '../gen/graphql';
import { defaultErrorMessage, errorMessages } from '../const/ErrorMessage';

// APIエラー時に表示するメッセージ共通処理
export const getApiErrorMessage = (
  e: unknown,
  fallbackErrorMessage?: string,
  customErrorMessages?: { [key in keyof typeof ErrorCode]?: string },
): string => {
  if (!(e instanceof ApolloError)) {
    return fallbackErrorMessage ?? defaultErrorMessage;
  }

  const errorCodes = getGraphQLErrorCodes(e);

  if (customErrorMessages) {
    for (const key in customErrorMessages) {
      if (errorCodes.includes(key as ErrorCode)) {
        return customErrorMessages[key as keyof typeof customErrorMessages] as string;
      }
    }
  }

  for (const code of errorCodes) {
    const message = errorMessages.get(code);
    if (message) {
      return message;
    }
  }

  return fallbackErrorMessage ?? defaultErrorMessage;
};

/**
 * ApolloErrorからエラーコードを取り出す
 * @param ae ApolloError
 * @return エラーコードの配列
 */
export const getGraphQLErrorCodes = (ae: ApolloError): ErrorCode[] => {
  return ae.graphQLErrors
    .map((value: GraphQLFormattedError) => {
      return value.extensions?.code;
    })
    .filter((errorCode): errorCode is ErrorCode => errorCode !== undefined);
};

export const isErrorCode = (code: unknown): code is ErrorCode => {
  return Object.values(ErrorCode).some((v) => v === code);
};
