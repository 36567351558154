import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useUser } from '../../redux/user/useUser';

import { Blur } from '../molecules/Blur';
import { Box } from '../atoms/Box';
import { Tag } from '../atoms/Tag';
import { UserIcon } from './UserIcon';

import { TITLE_LIMIT_LENGTH } from '../../const/Search';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { getPracticeFunctionType } from '../../utils/Program';
import { getRecentPracticeSubmitStatus } from '../../utils/PracticeSubmit';
import {
  ChapterFragment,
  DocumentListFragment,
  DocumentType,
  PracticeFragment,
  QuestionFragment,
  QuestionStatus,
  StudyReportFragment,
  TweetDetailType,
  TweetFragment,
  PracticeSubmitDetailReviewStatus,
} from '../../gen/graphql';

import IconSearchLog from '../../static/image/icon_search_log.svg';
import IconSearchProgram from '../../static/image/icon_search_program.svg';
import IconSearchPractice from '../../static/image/icon_search_practice.svg';
import IconSearchQa from '../../static/image/icon_search_qa.svg';
import IconSearchTweet from '../../static/image/icon_search_tweet.svg';
import { QuestionLabelType, getQuestionLabelType } from '../../utils/Question';
import { QuestionLabels } from '../../const/Question';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';

interface ProgramProps {
  item: ChapterFragment;
}

interface PracticeProps {
  item: PracticeFragment;
}

interface QaProps {
  item: QuestionFragment;
}

interface StudyReportProps {
  item: StudyReportFragment;
}

interface TweetProps {
  item: TweetFragment;
  permissionCheck: (functionType: string, permissionType: string) => boolean;
}

interface SearchArticleProps {
  item: DocumentListFragment;
  className?: string;
}

const sliceContent = (detail: string) => {
  if (detail.length > TITLE_LIMIT_LENGTH) {
    return (
      <>
        {detail.substring(0, TITLE_LIMIT_LENGTH)}...<span className="readmore">もっと見る</span>
      </>
    );
  } else {
    return detail;
  }
};

const ProgramArticle: React.FC<ProgramProps> = ({ item }) => {
  return (
    <StyledLink to={`/programs/${item.programElement.programID}/chapters/${item.id}`}>
      <Label>
        <img src={IconSearchProgram} alt="" />
        教材
      </Label>
      <Title>{item.title}</Title>
      <Program>教材：{item.programElement.program.title}</Program>
      {item.programElement.program.tags && (
        <Tags>
          {item.programElement.program.tags.map((tag) => (
            <Tag name={tag.name} />
          ))}
        </Tags>
      )}
    </StyledLink>
  );
};
const PracticeArticle: React.FC<PracticeProps> = ({ item }) => {
  const { openModal } = useCommonModal();
  const showDetail = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const functionType = getPracticeFunctionType(item.type);
      if (!functionType) {
        e.preventDefault();
        return;
      }
      if (
        !(
          getRecentPracticeSubmitStatus(item.mySubmit?.practiceSubmitDetails) ===
          PracticeSubmitDetailReviewStatus.Passed
        ) &&
        openModal(functionType, PermissionType.Read)
      )
        e.preventDefault();
    },
    [openModal, item.type, item.mySubmit?.practiceSubmitDetails],
  );
  return (
    <StyledLink to={`/practices/${item.id}/body`} onClick={showDetail}>
      <Label>
        <img src={IconSearchPractice} alt="" />
        課題
      </Label>
      <Title>{item.title}</Title>
      <Program>教材：{item.program.title}</Program>
      {item.program.tags && (
        <Tags>
          {item.program.tags.map((tag) => (
            <Tag name={tag.name} />
          ))}
        </Tags>
      )}
    </StyledLink>
  );
};
const QaArticle: React.FC<QaProps> = ({ item }) => {
  const questionLabelType = getQuestionLabelType(
    item.solved,
    item.status === QuestionStatus.Draft,
    item.answerRequestType,
  );

  return (
    <StyledLink to={`/question/detail/${item.id}`}>
      <Label>
        <img src={IconSearchQa} alt="" />
        Q&A
        <span className={questionLabelType === QuestionLabelType.Solved ? 'solved' : ''}>
          {QuestionLabels[questionLabelType]}
        </span>
      </Label>
      <Title>{item.title}</Title>
      {item.program?.title && <Program>教材：{item.program.title}</Program>}
      {item.tags && (
        <Tags>
          {item.tags.map((tag) => (
            <Tag name={tag.name} />
          ))}
        </Tags>
      )}
      <StyledUserIcon
        user={item.user}
        date={item.editedAt ? item.editedAt : item.createdAt}
        note={item.editedAt ? '(編集済み)' : ''}
        medium
      />
    </StyledLink>
  );
};
const StudyReportArticle: React.FC<StudyReportProps> = ({ item }) => {
  return (
    <StyledLink to={`/study_report/detail/${item.id}`}>
      <Label>
        <img src={IconSearchLog} alt="" />
        学習ログ
      </Label>
      <Title>{item.content && sliceContent(item.content)}</Title>
      {item.studyReportDetails && (
        <Tags>
          {item.studyReportDetails.map(
            (detail) => detail.tag?.name && <Tag name={detail.tag.name} />,
          )}
        </Tags>
      )}
      <StyledUserIcon
        user={item.user}
        date={item.updatedAt ? item.updatedAt : item.createdAt}
        note={item.updatedAt ? '(編集済み)' : ''}
        medium
      />
    </StyledLink>
  );
};
const TweetArticle: React.FC<TweetProps> = ({ item, permissionCheck }) => {
  const detailType = item.tweetDetail.detailType ?? TweetDetailType.Common;

  return (
    <StyledLink to={`/tweets/${item.tweetID}`}>
      <Label>
        <img src={IconSearchTweet} alt="" />
        投稿
      </Label>
      {/* 未課金ユーザーには運営・講師の投稿は見せない*/}
      {[TweetDetailType.Admin, TweetDetailType.Instructor].includes(detailType) &&
      !permissionCheck(FunctionType.TimeLineOfInstructorAndAdmin, PermissionType.Read) ? (
        <Blur message="有料プランを契約すると内容を確認できます" button="いますぐ登録する" />
      ) : (
        <Title>{item.tweetDetail && sliceContent(item.tweetDetail.content)}</Title>
      )}
      <StyledUserIcon user={item.tweetDetail.user} date={item.createdAt} medium />
    </StyledLink>
  );
};

export const SearchArticle: React.FC<SearchArticleProps> = ({ item, ...props }) => {
  const { permissionCheck } = useUser();

  return (
    <Container article key={item.documentID} className={props.className}>
      {item.type === DocumentType.Program && item.chapter ? (
        <ProgramArticle item={item.chapter} />
      ) : item.type === DocumentType.Practice && item.practice ? (
        <PracticeArticle item={item.practice} />
      ) : item.type === DocumentType.Qa && item.qa ? (
        <QaArticle item={item.qa} />
      ) : item.type === DocumentType.StudyReport && item.studyReport ? (
        <StudyReportArticle item={item.studyReport} />
      ) : item.type === DocumentType.Tweet && item.tweet ? (
        <TweetArticle item={item.tweet} permissionCheck={permissionCheck} />
      ) : (
        ''
      )}
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 1rem 1.5rem;
`;
const Label = styled.p`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.25rem;

  img {
    display: block;
    width: 1.25rem;
    height: auto;
  }

  span {
    display: block;
    width: 3.5rem;
    padding: 0.25rem;
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.625rem;
    line-height: 1em;
    text-align: center;

    &.solved {
      background: #fce5e8;
      color: #fd3c2f;
    }
  }
`;
const Title = styled.h3`
  margin-top: 0.25rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.1px;

  .readmore {
    color: rgba(255, 22, 22, 0.87);
    font-size: 0.625rem;
  }
`;
const Program = styled.p`
  margin-top: 0.25rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.1px;
`;
const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  margin-top: 0.75rem;
`;
const StyledUserIcon = styled(UserIcon)`
  margin-top: 0.75rem;
`;
