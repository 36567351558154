import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useLocationChange = (callback: () => void): void => {
  const refCallback = useRef<undefined | (() => void)>();
  const refFirstCall = useRef(true);
  const location = useLocation();

  useEffect(() => {
    refCallback.current = callback;
  }, [callback]);

  // ロケーションに変更があったときに処理実行
  useEffect(() => {
    if (refFirstCall.current) {
      refFirstCall.current = false;
      return;
    }
    if (refCallback.current) {
      refCallback.current();
    }
  }, [location.pathname]);
};

export default useLocationChange;
