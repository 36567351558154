import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useUser } from '../../redux/user/useUser';

import { UserIcon } from '../organisms/UserIcon';

import { StudyReportCommentFragment } from '../../gen/graphql';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface StudyReportCommentCardProps {
  studyReportComment: StudyReportCommentFragment;
  handleEdit: (
    event: React.MouseEvent<HTMLParagraphElement>,
    studyReportComment: StudyReportCommentFragment,
  ) => void;
  handleDelete: (
    event: React.MouseEvent<HTMLParagraphElement>,
    studyReportComment: StudyReportCommentFragment,
  ) => void;
  isCommentSideBorderEnable: boolean;
}

export const StudyReportCommentCard: React.FC<StudyReportCommentCardProps> = (props) => {
  const { user, permissionCheck } = useUser();
  const [isHover, setIsHover] = useState(false);
  const [isControlShow, setIsControlShow] = useState(false);

  const onMouseEnter = () => {
    setIsHover(true);
  };

  const onMouseLeave = () => {
    setIsHover(false);
  };

  const toggleControlShow: React.MouseEventHandler<unknown> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setIsControlShow(!isControlShow);
  };

  return (
    <Container
      key={props.studyReportComment.id}
      isCommentSideBorderEnable={props.isCommentSideBorderEnable}
    >
      <TapArea onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <UserIcon
          user={props.studyReportComment.user}
          date={props.studyReportComment.updatedAt ?? props.studyReportComment.createdAt}
          note={
            props.studyReportComment.createdAt === props.studyReportComment.updatedAt
              ? ''
              : '(編集済み)'
          }
          large
        />
        <CommentContent>{props.studyReportComment.content}</CommentContent>
        {props.studyReportComment.user?.id === user.lmsUser?.id &&
          permissionCheck(FunctionType.StudyReportComment, PermissionType.Update) && (
            <React.Fragment>
              <ControlIcon isHover={isHover} onClick={toggleControlShow}>
                <span></span>
              </ControlIcon>
              <Control isShow={isControlShow} onMouseLeave={toggleControlShow}>
                <li>
                  <ControlButton
                    onClick={(event: React.MouseEvent<HTMLParagraphElement>) =>
                      props.handleEdit(event, props.studyReportComment)
                    }
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.8477 4.71294C16.3042 4.25645 16.9233 4 17.5689 4C18.2144 4 18.8336 4.25645 19.2901 4.71294C19.7465 5.16943 20.003 5.78856 20.003 6.43413C20.003 7.07971 19.7465 7.69884 19.2901 8.15533L11.9408 15.5046C11.8283 15.6171 11.6756 15.6803 11.5165 15.6803H8.92266C8.59129 15.6803 8.32266 15.4117 8.32266 15.0803V12.4865C8.32266 12.3273 8.38587 12.1747 8.4984 12.0622L14.538 6.02257C14.5422 6.0181 14.5465 6.01368 14.5509 6.00931C14.5553 6.00494 14.5597 6.00065 14.5642 5.99644L15.8477 4.71294ZM14.9754 7.2823L9.52266 12.735V14.4803H11.268L16.7207 9.02763L14.9754 7.2823ZM17.5692 8.1791L15.8239 6.43377L16.6962 5.56147C16.9276 5.33002 17.2415 5.2 17.5689 5.2C17.8962 5.2 18.2101 5.33002 18.4415 5.56147C18.673 5.79291 18.803 6.10682 18.803 6.43413C18.803 6.76145 18.673 7.07535 18.4415 7.3068L17.5692 8.1791ZM4.68222 8.24527C5.11904 7.80845 5.71149 7.56305 6.32924 7.56305H8.9231C9.25447 7.56305 9.5231 7.83168 9.5231 8.16305C9.5231 8.49442 9.25447 8.76305 8.9231 8.76305H6.32924C6.02975 8.76305 5.74252 8.88203 5.53075 9.0938C5.31897 9.30557 5.2 9.5928 5.2 9.89229V17.6739C5.2 17.9734 5.31897 18.2606 5.53075 18.4724C5.74252 18.6841 6.02975 18.8031 6.32924 18.8031H14.1108C14.4103 18.8031 14.6975 18.6841 14.9093 18.4724C15.1211 18.2606 15.2401 17.9734 15.2401 17.6739V15.08C15.2401 14.7486 15.5087 14.48 15.8401 14.48C16.1714 14.48 16.4401 14.7486 16.4401 15.08V17.6739C16.4401 18.2916 16.1947 18.8841 15.7578 19.3209C15.321 19.7577 14.7286 20.0031 14.1108 20.0031H6.32924C5.71149 20.0031 5.11904 19.7577 4.68222 19.3209C4.2454 18.8841 4 18.2916 4 17.6739V9.89229C4 9.27454 4.2454 8.68209 4.68222 8.24527Z"
                        fill="black"
                        fillOpacity="0.87"
                      />
                    </svg>
                    編集する
                  </ControlButton>
                </li>
                <li>
                  <Delete
                    onClick={(event: React.MouseEvent<HTMLParagraphElement>) =>
                      props.handleDelete(event, props.studyReportComment)
                    }
                  >
                    <ControlButton>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.3861 5.26566C10.4281 5.22362 10.4851 5.2 10.5446 5.2H13.8413C13.9008 5.2 13.9578 5.22362 13.9998 5.26566C14.0419 5.30771 14.0655 5.36473 14.0655 5.42419V7.29629H10.3204V5.42419C10.3204 5.36473 10.344 5.30771 10.3861 5.26566ZM9.69685 8.49629C9.70466 8.49659 9.71251 8.49674 9.72039 8.49674C9.72827 8.49674 9.73612 8.49659 9.74393 8.49629H14.642C14.6498 8.49659 14.6576 8.49674 14.6655 8.49674C14.6734 8.49674 14.6812 8.49659 14.689 8.49629H17.31L16.54 17.7367C16.5386 17.7533 16.5379 17.7699 16.5379 17.7865C16.5379 18.0646 16.4275 18.3312 16.2309 18.5278C16.0342 18.7244 15.7676 18.8349 15.4895 18.8349H8.89606C8.61802 18.8349 8.35136 18.7244 8.15476 18.5278C7.95815 18.3312 7.84769 18.0646 7.84769 17.7865C7.84769 17.7699 7.847 17.7533 7.84562 17.7367L7.07559 8.49629H9.69685ZM9.12039 7.29629V5.42419C9.12039 5.04647 9.27044 4.68422 9.53752 4.41713C9.80461 4.15005 10.1669 4 10.5446 4H13.8413C14.219 4 14.5813 4.15005 14.8484 4.41713C15.1155 4.68422 15.2655 5.04647 15.2655 5.42419V7.29629H17.9551H17.9678H18.787C19.1183 7.29629 19.387 7.56492 19.387 7.89629C19.387 8.22766 19.1183 8.49629 18.787 8.49629H18.5142L17.7378 17.8134C17.7308 18.4 17.4947 18.961 17.0794 19.3763C16.6577 19.798 16.0859 20.0349 15.4895 20.0349H8.89606C8.29976 20.0349 7.72788 19.798 7.30623 19.3763C6.89092 18.961 6.65486 18.4 6.64785 17.8134L5.87143 8.49629H5.6C5.26863 8.49629 5 8.22766 5 7.89629C5 7.56492 5.26863 7.29629 5.6 7.29629H6.41786H6.4305H9.12039ZM10.5448 10.5933C10.8762 10.5933 11.1448 10.8619 11.1448 11.1933V16.1384C11.1448 16.4698 10.8762 16.7384 10.5448 16.7384C10.2135 16.7384 9.94485 16.4698 9.94485 16.1384V11.1933C9.94485 10.8619 10.2135 10.5933 10.5448 10.5933ZM14.4417 11.1933C14.4417 10.8619 14.1731 10.5933 13.8417 10.5933C13.5104 10.5933 13.2417 10.8619 13.2417 11.1933V16.1384C13.2417 16.4698 13.5104 16.7384 13.8417 16.7384C14.1731 16.7384 14.4417 16.4698 14.4417 16.1384V11.1933Z"
                          fill="#e2001b"
                        />
                      </svg>
                      削除する
                    </ControlButton>
                  </Delete>
                </li>
              </Control>
            </React.Fragment>
          )}
      </TapArea>
    </Container>
  );
};

const Container = styled.div<{ isCommentSideBorderEnable: boolean }>`
  display: flex;
  padding: 1rem;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 2px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  border-left: ${(props) =>
    props.isCommentSideBorderEnable ? 'solid 1px rgba(0, 0, 0, 0.1)' : '0'};
  border-right: ${(props) =>
    props.isCommentSideBorderEnable ? 'solid 1px rgba(0, 0, 0, 0.1)' : '0'};
  &:nth-of-type(n + 2) {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const CommentContent = styled.p`
  margin-top: 0.5rem;
  white-space: pre-line;
`;

const ControlIcon = styled.div<{ isHover: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 0.5625rem;
  right: 0;
  opacity: ${(props) => (props.isHover ? '1' : '0')};
  pointer-events: ${(props) => (props.isHover ? 'all' : 'none')};
  transition: all 0.2s;

  &:hover {
    background: #f5f5f5;
  }

  span {
    display: block;
    width: 4px;
    height: 4px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      position: absolute;
      top: 0;
    }

    &:before {
      left: -7px;
    }

    &:after {
      right: -7px;
    }
  }

  ${media.lessThan('medium')`
    opacity: 1;
    pointer-events: all;
  `}
`;

const Delete = styled.div`
  color: #e2001b;
`;

const ControlButton = styled.p`
  display: flex;
  align-items: center;
  width: 10rem;
  padding: 0.625rem 1rem;
  cursor: pointer;
  font-size: 1rem;

  svg {
    margin-right: 1rem;
  }
`;

const Control = styled.ul<{ isShow: boolean }>`
  padding: 0.5rem 0;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  opacity: ${(props) => (props.isShow ? '1' : '0')};
  pointer-events: ${(props) => (props.isShow ? 'all' : 'none')};
  transition: all 0.2s;
`;

const TapArea = styled.article`
  width: 100%;
  position: relative;
`;
