import React from 'react';
import styled from 'styled-components';

import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  handleCancel: () => void;
}

export const QuestionEditingModal: React.FC<Props> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      hideHeaderClose
      header={<DialogTitle>注意</DialogTitle>}
      width="576px"
      footer={
        <DialogActions>
          <CancelButton onClick={props.handleCancel}>キャンセル</CancelButton>
          <Button onClick={props.handleConfirm}>編集する</Button>
        </DialogActions>
      }
    >
      <DialogContent>
        <DialogContentText>
          質問や返信内容を編集しても、AI先生の回答・返信は更新されません。
          <br />
          <br />
          編集した内容に対して回答が必要な場合は、編集ではなく新たに質問してください。
        </DialogContentText>
      </DialogContent>
    </Modal>
  );
};

const CancelButton = styled.p`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const DialogTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

const DialogContent = styled.div``;

const DialogContentText = styled.div`
  margin: 20px 15px;
`;

const DialogActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;
