import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { isNonEmpty } from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import stripeJs, { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { Payment as PaymentConst } from '../../const/Payment';
import { StripePaymentMethodFragment } from '../../gen/graphql';

import { CardBrand } from '../atoms/CardBrand';
import { RegisterPayment } from '../organisms/RegisterPayment';

type PaymentMethodItem = StripePaymentMethodFragment;

interface AddPaymentMethodProps<T extends PaymentMethodItem> {
  cards: Array<T>;
  currentDefaultPaymentId: string;
  fetchPayments: () => Promise<void>;
  setLoading: (isLoading: boolean) => void;
  setStripe: (item: stripeJs.Stripe | null) => void;
  setElements: (item: stripeJs.StripeElements | null) => void;
  setPaymentID: (item: PaymentMethodItem['id'] | null) => void;
  selectedPayment: (item: PaymentMethodItem) => boolean;
  displayNewPayment: () => boolean;
  setIsCheckedFrontValidation?: (isChecekd: boolean) => void;
  cardNumberError: string;
  expirationError: string;
  cvcError: string;
}

export const AddPaymentMethod = <T extends PaymentMethodItem = StripePaymentMethodFragment>(
  props: AddPaymentMethodProps<T>,
): JSX.Element => {
  const [stripePromise] = React.useState(() =>
    loadStripe(
      pipe(
        O.fromNullable(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
        O.fold(
          () => '',
          (env) => env,
        ),
      ),
    ),
  );

  const now = new Date();
  const [addOpen] = useState(true);

  const checkExpire = (item: PaymentMethodItem, now: Date) =>
    now.getFullYear() < item.expireYear ||
    (now.getFullYear() === item.expireYear && now.getMonth() + 1 <= item.expireMonth);

  return (
    <List>
      {isNonEmpty(props.cards) &&
        props.cards.map((item) => {
          return (
            <Item key={item.id}>
              <Label active={props.selectedPayment(item)}>
                <input
                  type="radio"
                  value={item.id}
                  checked={props.selectedPayment(item)}
                  disabled={!checkExpire(item, now)}
                  onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    props.setPaymentID(value);
                  }}
                />
                <Dl>
                  <Brand>
                    <CardBrand brand={item.cardBrand} />
                  </Brand>
                  <NumberTitle>カード番号</NumberTitle>
                  <Number>**** **** **** {item.cardNumber}</Number>
                  <Limit active={checkExpire(item, now)}>
                    {checkExpire(item, now) ? (
                      <span>有効期限</span>
                    ) : (
                      <span className="disabled">有効期限切れ</span>
                    )}
                    {item.expireMonth}/{item.expireYear}
                  </Limit>
                  {/* isAvailableはPaymentMethodFragment(toC用)にしかない */}
                  {'isAvailable' in item && !item?.isAvailable && <Notice>決済エラー</Notice>}
                </Dl>
              </Label>
            </Item>
          );
        })}
      {props.cards.length < PaymentConst.MAX_ITEM_NUMBER && (
        <Item>
          <Label active={props.displayNewPayment()}>
            <input
              type="radio"
              checked={props.displayNewPayment()}
              onChange={() => {
                props.setPaymentID(null);
              }}
            />
            <AddText>支払い方法の追加</AddText>
            {props.displayNewPayment() && (
              <AddContainer active={addOpen}>
                <Elements stripe={stripePromise}>
                  <StyledRegisterPayment
                    numberError={props.cardNumberError}
                    expirationError={props.expirationError}
                    cvcError={props.cvcError}
                    setStripe={props.setStripe}
                    setElements={props.setElements}
                    setIsCheckedFrontValidation={props.setIsCheckedFrontValidation}
                  />
                </Elements>
              </AddContainer>
            )}
          </Label>
        </Item>
      )}
    </List>
  );
};

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;

  ${media.lessThan('medium')`
    gap: 1rem;
  `}
`;
const Item = styled.li`
  width: 100%;

  input {
    display: none;
  }

  ${media.lessThan('medium')`
    &:nth-last-of-type(n + 2) {
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
  `}
`;

const Label = styled.label<{ active: boolean }>`
  display: block;
  padding-left: 4rem;
  background: #fff;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    margin: auto;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.36);
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: 0.5rem;
  }

  &:after {
    content: '';
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    margin: auto;
    background: #${(props) => (props.active ? 'e73248' : 'fff')};
    border-radius: 50%;
    position: absolute;
    top: 11px;
    left: 0.75rem;
  }

  ${media.lessThan('large')`
    padding-left: 2.625rem;
  `}

  ${media.lessThan('medium')`
    padding-left: 2rem;
    background: #fff;

    &:before {
      left: 0.25rem;
    }

    &:after {
      left: 0.5rem;
    }
  `}
`;
const Dl = styled.dl`
  display: flex;
  align-items: center;

  ${media.lessThan('medium')`
    flex-wrap: wrap;
  `}
`;
const Brand = styled.dd`
  width: 3.25rem;
  height: 2rem;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const NumberTitle = styled.dd`
  margin: 0 1.5rem 0 2rem;

  ${media.lessThan('large')`
    margin: 0 0.5rem 0 1rem;
    font-size: 0.875rem;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const Number = styled.dd`
  margin-right: 2rem;

  ${media.lessThan('large')`
    margin-right: 1rem;
    font-size: 0.875rem;
  `}

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0 0 0.25rem;
    line-height: 1rem;
  `}
`;
const Limit = styled.dd<{ active: boolean }>`
  display: flex;
  align-items: center;

  span {
    display: block;
    margin-right: 0.5rem;

    &.disabled {
      color: #e73248;
    }
  }

  ${media.lessThan('large')`
    font-size: 0.875rem;

    span {
      font-size: 0.875rem;
    }
  `}

  ${media.lessThan('medium')`
    color: rgba(0,0,0,0.36);
    font-size: 0.6875rem;

    span {
      font-size: 0.6875rem;
    }
  `}

  ${(props) =>
    !props.active &&
    media.lessThan('medium')`
      color: #e73248;
    `}
`;
const Notice = styled.dd`
  color: #e73248;
  margin-left: 0.4rem;

  ${media.lessThan('large')`
    font-size: 0.875rem;
  `}

  ${media.lessThan('medium')`
    font-size: 0.6875rem;
  `}
`;

const AddText = styled.p`
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 1rem;

  ${media.lessThan('medium')`
    font-size: 0.875rem;
  `}
`;
const AddContainer = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  margin-top: 0.875rem;
  padding: 1.5rem 2rem;
  background: #ecf6fc;

  ${media.lessThan('medium')`
    margin-left: -2rem;
    padding: 1rem;
  `}
`;
const StyledRegisterPayment = styled(RegisterPayment)`
  margin: 0;
  padding: 0;
`;
