import React from 'react';
import styled from 'styled-components';

interface SpacerProps {
  height?: string;
  width?: string;
}

export const Spacer: React.FC<SpacerProps> = (props) => {
  return <Container height={props.height} width={props.width} />;
};

const Container = styled.div<{ height?: string; width?: string }>`
  height: ${(props) => (props.height ? props.height : 'none')};
  width: ${(props) => (props.width ? props.width : 'none')};
`;
