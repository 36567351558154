import React from 'react';
import { useUser } from '../../../redux/user/useUser';

import { Loaders } from '../../../common/Loader';
import { ProgramListContainer } from '../../atoms/ProgramListContainer';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { ProgramArticle } from '../../organisms/ProgramArticle';
import {
  useGetProgramsQuery,
  ProgramSearchType,
  ProgramSearchInput,
  ProgramType,
} from '../../../gen/graphql';
import { useGetExamMedal } from '../../../common/customHooks/GetExamMedal';

import { FunctionType, PermissionType } from '../../../const/UserPermission';

interface ProgramsProps {
  type: string;
  category?: number;
  resetCategory?: () => void;
}

const getType = (type: string): ProgramType | undefined => {
  switch (type) {
    case '1':
      return ProgramType.Normal;
    case '2':
      return ProgramType.Instructor;
    default:
      return undefined;
  }
};

export const Programs: React.FC<ProgramsProps> = (props) => {
  const { user, permissionCheck } = useUser();
  const medalCounts = useGetExamMedal();

  const tagID = props.category || undefined;

  const query: ProgramSearchInput = permissionCheck(
    FunctionType.ProgramForInstructorAndCoach,
    PermissionType.Read,
  )
    ? {
        programSearchType: ProgramSearchType.All,
        type: props.type ? getType(props.type) : undefined,
        tagID: tagID,
      }
    : {
        programSearchType: ProgramSearchType.All,
        type: ProgramType.Normal,
        tagID: tagID,
      };

  const { data, loading } = useGetProgramsQuery({
    variables: {
      input: query,
      userID: user.lmsUser?.id,
    },
  });

  return (
    <ProgramListContainer>
      {!loading ? (
        data?.programs?.items.length !== 0 ? (
          data?.programs?.items.map((p) => {
            return (
              <ProgramArticle
                id={p.id}
                key={p.id}
                image={p.icon}
                thumb={p.thumbnail}
                title={p.title}
                description={p.description}
                questionCount={p.questionCount}
                answerCount={p.answerCount}
                chapterCount={p.chapterCount ?? 0}
                requireTime={p.requireTime ?? 0}
                completedTime={p.completedTime ?? 0}
                studyLogCount={p.studyLogCount}
                programSample={p.programSample ?? undefined}
                medalCount={p.id ? medalCounts.get(p.id) : undefined} // Medalカウントをこの層で取得する必要がなければ削除
              />
            );
          })
        ) : (
          <EmptyBlock title="表示できる教材がありません" />
        )
      ) : (
        <Loaders width="312px" height="252px" margin="0 0.75rem 1rem 0" number={10} />
      )}
    </ProgramListContainer>
  );
};
