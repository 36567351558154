import React, { useCallback, useRef, useState } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { ContentRect } from 'react-measure';
import styled from 'styled-components';
import media from 'styled-media-query';

import { ChatMemberListFragment, ChatMessageListFragment } from '../../gen/graphql';

import { useUser } from '../../redux/user/useUser';
import { useInvertScrollDirection } from '../../common/customHooks/InvertScrollDirection';
import { mergeRefs } from '../../utils/common';
import { useFetchChatMessage } from '../../common/customHooks/FetchChatMessage';
import { ChatMessageListRow, createChatMessageListRowData } from './ChatMessageListRow';
import { ChatMessageInput } from './ChatMessageInput';
import { EmptyBlock } from '../molecules/EmptyBlock';
import { getChatRoomName } from '../../utils/ChatRoom';
import MenuCloseIcon from '../../static/image/arrow_menu_close.svg';

// 縦長のリストの場合は、行の高さ。横長のリストの場合は、列の幅になります。
const DEFAULT_ITEM_SIZE = 50;

interface Props {
  chatRoomID: number;
  chatMembers: ChatMemberListFragment[];
  onResetChatRoom: () => void;
  onEditing: (isEdit: boolean) => void;
  onConfirmEditing: () => Promise<unknown>;
  readMessages: (chatRoomID: number) => void;
}

export const ChatMessageList: React.FC<Props> = ({
  chatRoomID,
  chatMembers,
  onResetChatRoom,
  onEditing,
  onConfirmEditing,
  readMessages,
}) => {
  const { user: loginUser } = useUser();
  const [editingMessage, setEditingMessage] = useState(new Map<number, string>());

  const {
    chatMessages,
    error,
    incoming,
    setIncoming,
    readCalled,
    setReadCalled,
    loadMore,
    hasNextPage,
  } = useFetchChatMessage(chatRoomID, loginUser.lmsUser);

  // HACK: メッセージリストを反転していることにより、マウススクロールも反転してしまうので正常に戻す為
  // See: https://github.com/bvaughn/react-window/issues/398#issuecomment-753528128
  const scrollRef = useInvertScrollDirection(true);
  const loaderRef = useRef(null);
  const listRef = useRef<List>(null);

  const chatMessagesCount = hasNextPage ? chatMessages.length + 1 : chatMessages.length;
  const isChatMessageLoaded = (index: number) => !hasNextPage || index < chatMessages.length;
  const itemSizes = useRef<{ [key in string]: number }>({});
  const getItemSize = (index: number) => itemSizes.current[index.toString()] || DEFAULT_ITEM_SIZE;

  const handleItemResize = (index: number, { bounds, margin }: ContentRect) => {
    const boundsHeight = bounds?.height ?? 0;
    const marginTop = margin?.top ?? 0;
    const marginBottom = margin?.bottom ?? 0;

    itemSizes.current[index.toString()] = boundsHeight + marginTop + marginBottom;
    if (listRef.current) {
      listRef?.current?.resetAfterIndex(index, true);
    }
  };

  const onEditingMessage = useCallback((message: ChatMessageListFragment | null) => {
    const map = new Map<number, string>();
    if (!message) {
      // initialization
      setEditingMessage(map);
      return;
    }
    // overwriting
    setEditingMessage(map.set(message.id, message.content));
  }, []);

  const handleScrollToBottom = useCallback(async () => {
    if (!loaderRef.current) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loaderRef.current._listRef.scrollToItem(0);
    setIncoming(false);
  }, [setIncoming]);

  const itemData = createChatMessageListRowData(
    chatRoomID,
    chatMessages,
    editingMessage,
    handleItemResize,
    onConfirmEditing,
    onEditing,
    onEditingMessage,
    isChatMessageLoaded,
    readCalled,
    setReadCalled,
    readMessages,
    setIncoming,
  );

  if (chatRoomID === 0) return <></>;

  return (
    <Wrapper>
      <Contents>
        <Inner>
          <BackArea onClick={onResetChatRoom}>
            <MenuImg src={MenuCloseIcon} alt="close" onClick={onResetChatRoom} />
            <ChatRoomNameText>{getChatRoomName(chatMembers, loginUser.lmsUser)}</ChatRoomNameText>
          </BackArea>
          <Body>
            {error ? (
              <ErrorBlock title="メッセージの読み込みが行われませんでした">
                回線状況を確認して再読み込みを行ってください
              </ErrorBlock>
            ) : !chatMessages.length ? (
              <ErrorBlock title="まだチャットのやりとりはありません" />
            ) : (
              <StyledAutoSizer>
                {({ height, width }) => (
                  <InfiniteLoader
                    isItemLoaded={isChatMessageLoaded}
                    itemCount={chatMessagesCount}
                    loadMoreItems={loadMore}
                    ref={loaderRef}
                  >
                    {({ onItemsRendered, ref }) => (
                      <ListWrapper>
                        <List
                          ref={mergeRefs(ref, listRef)}
                          onItemsRendered={onItemsRendered}
                          itemData={itemData}
                          itemCount={chatMessagesCount}
                          itemSize={getItemSize}
                          width={width}
                          height={height}
                          outerRef={scrollRef}
                        >
                          {ChatMessageListRow}
                        </List>
                      </ListWrapper>
                    )}
                  </InfiniteLoader>
                )}
              </StyledAutoSizer>
            )}
          </Body>
          <Footer>
            <ChatMessageInput chatRoomID={chatRoomID} chatMembers={chatMembers} />
          </Footer>
        </Inner>
        {incoming && (
          <NewMessageNoticeToast onClick={handleScrollToBottom}>
            <NoticeBox>
              <DownArrow />
              <NewMessageNoticeText>新しいメッセージがあります</NewMessageNoticeText>
            </NoticeBox>
          </NewMessageNoticeToast>
        )}
      </Contents>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-area: chat_message_view;
  grid-template-columns: auto;
  max-height: calc(100vh - 70px);

  grid-template-areas: 'chat_message_view_contents';
  grid-template-rows: auto;

  ${media.lessThan('medium')`
    height: calc(100vh - 70px);
  `}
`;

const Contents = styled.div`
  grid-area: chat_message_view_contents;

  display: flex;
  flex-direction: column;

  min-height: 0;
  min-width: 0;
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  min-height: 0;
  position: relative;
`;

const Body = styled.div`
  flex: 1;

  min-height: 0;
  position: relative;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;
  z-index: 200;
`;

const StyledAutoSizer = styled(AutoSizer)`
  height: 100%;
`;

// HACK: transformについて
// 下から上へのメッセージリストを作り出すために追加しています。
const ListWrapper = styled.div`
  transform: scaleY(-1);
`;

const BackArea = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`}
`;

const MenuImg = styled.img`
  margin: 0 8px;
  vertical-align: text-bottom;
`;

const ChatRoomNameText = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

const ErrorBlock = styled(EmptyBlock)`
  margin: 1rem;
`;

const NewMessageNoticeToast = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 1rem 2rem;
  margin-top: 1rem;
  border-radius: 24px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
  background-color: #e2001b;
  font-size: 0.8rem;
  cursor: pointer;
`;

const NoticeBox = styled.div`
  display: flex;
`;

const DownArrow = styled.div`
  width: 3px;
  height: 3px;
  border: 2px solid;
  border-color: transparent transparent #ffffff #ffffff;
  transform: rotate(-45deg);
  margin-right: 10px;
`;

const NewMessageNoticeText = styled.p`
  font-size: 0.85rem;
`;
