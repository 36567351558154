import React from 'react';
import styled, { keyframes } from 'styled-components';

interface LoaderProps {
  width: string;
  height: string;
  margin: string;
  number: number;
}

export const Loaders: React.FC<LoaderProps> = (props) => {
  const loaders = Array.from(Array(props.number)).map((_, i) => (
    <Loader key={i} {...{ ...props, number: i }} />
  ));

  const Container = styled.div`
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  `;

  return <Container>{loaders}</Container>;
};

const blink = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Loader = styled.div<LoaderProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  background-color: #ddd;
  opacity: 0;
  animation: ${blink} 0.8s ease-in-out ${(props) => props.number * 0.125}s infinite alternate;
`;
