import React, { useMemo } from 'react';
import TabNavigationLayout from './TabNavigationLayout';

type TabType = 'team' | 'auth';

interface Props {
  activeTab: TabType;
  isAdmin: boolean;
  metaTitle: string;
  metaDescription?: string;
}

export const TeamTabLayout: React.FC<Props> = ({
  activeTab,
  isAdmin,
  metaTitle,
  metaDescription,
  children,
}): JSX.Element => {
  const tabs = useMemo(() => {
    const teamTab = {
      label: 'トップ',
      to: `/team`,
      active: activeTab === 'team',
    };
    const tabs = [teamTab];

    if (isAdmin) {
      const authTab = {
        label: '招待・権限',
        to: `/team/auth`,
        active: activeTab === 'auth',
      };
      tabs.push(authTab);
    }

    return tabs;
  }, [activeTab, isAdmin]);

  return (
    <TabNavigationLayout
      pageTitle="チーム"
      tabs={tabs}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {children}
    </TabNavigationLayout>
  );
};
