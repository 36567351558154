import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useDebouncedCallback } from 'use-debounce';
import { useUser } from '../../redux/user/useUser';

import {
  ChatMemberListFragment,
  useCreateChatMessageMutation,
  useDeleteChatMessageDraftMutation,
  useGetChatMessageDraftQuery,
  useUpsertChatMessageDraftMutation,
} from '../../gen/graphql';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { FormButton } from '../atoms/FormButton';
import {
  RICH_INPUT_AREA_DEFAULT_HEIGHT,
  RichInput,
  RichInputHandler,
} from '../molecules/RichInput';
import { StudentStatus } from '../../store';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import QuestionMarkIcon from '../../static/image/icon_question_markdown.svg';
import SenderIcon from '../../static/image/icon_sender.svg';
import AlertIcon from '../../static/image/icon_alert.svg';

interface ChatMessageInputProps {
  chatRoomID: number;
  chatMembers: ChatMemberListFragment[];
}

export const ChatMessageInput: React.FC<ChatMessageInputProps> = ({ chatRoomID, chatMembers }) => {
  const { user, permissionCheck } = useUser();

  const contentRef = React.useRef<RichInputHandler>(null);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [setDraftData] = useAutosave<ChatMessageDraftInput | null>(null);
  const [errMessage, setErrMessage] = React.useState<string>('');

  useGetChatMessageDraftQuery({
    variables: { chatRoomID: chatRoomID },
    onCompleted: (data) => {
      contentRef.current?.setValue(data.chatMessageDraft?.content ?? '');
    },
  });

  const [createChatMessage] = useCreateChatMessageMutation();

  const handleSendMessage = useSafeAsyncCallback(async (): Promise<void> => {
    const content = contentRef.current?.getValue();
    if (!content || !chatRoomID) return;
    setErrMessage('');

    try {
      await createChatMessage({
        variables: {
          input: { chatRoomID: chatRoomID, content: content },
        },
      });

      contentRef.current?.setValue('');
      contentRef.current?.setHeight(`${RICH_INPUT_AREA_DEFAULT_HEIGHT}rem`);
      setDraftData({ chatRoomID: chatRoomID, content: '' });
      setCanSubmit(false);
    } catch {
      setErrMessage('送信できませんでした。回線状況を確認し、もう一度試してみてください。');
      return;
    }
  });

  const onKeyUp = React.useCallback(() => {
    setDraftData({
      chatRoomID: chatRoomID,
      content: contentRef.current?.getValue(),
    });

    if (contentRef.current?.getValue()) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current]);

  const isDisplayInput = useMemo((): boolean => {
    const s = chatMembers
      .filter(
        (m: ChatMemberListFragment) =>
          m.user &&
          m.user.id !== user.lmsUser?.id &&
          !userIsGraduate(m.user.student?.status, m.user.isInstructor),
      )
      .reduce(
        (acc: number, cur: ChatMemberListFragment) =>
          acc +
          (cur?.user?.isInstructor ? cur?.user?.isInstructor : 0) +
          (cur?.user?.isStudent ? cur?.user?.isStudent : 0),
        0,
      );

    return !!(
      user.lmsUser &&
      s > 0 &&
      !userIsGraduate(user.lmsUser.student?.status, user.lmsUser.isInstructor) &&
      permissionCheck(FunctionType.Chat, PermissionType.Create)
    );
  }, [chatMembers, permissionCheck, user.lmsUser]);

  return (
    <Container>
      {isDisplayInput ? (
        <>
          {errMessage && <Err>{errMessage}</Err>}
          <Input>
            <Link href="https://www.sejuku.net/blog/78156" target="_blank">
              <QuestionIcon src={QuestionMarkIcon} />
              マークダウンとは
            </Link>
            <Editor>
              <RichInput
                name="chat_content"
                placeholder={'メッセージを入力してください(Markdown形式が利用できます)'}
                fileUpload={true}
                imageUpload
                ref={contentRef}
                onKeyUp={onKeyUp}
                e2e="chat-message-input"
              />
            </Editor>
            <SubmitButton onClick={handleSendMessage} isActive={canSubmit} disabled={!canSubmit}>
              <>
                <SubmitIcon src={SenderIcon} />
                <span>送信</span>
              </>
            </SubmitButton>
          </Input>
        </>
      ) : (
        <DisabledInputArea>
          {user.lmsUser && userIsGraduate(user.lmsUser.student?.status, user.lmsUser.isInstructor)
            ? '卒業済みのためメッセージを送信することができません。'
            : '該当のユーザーは退会済みのためメッセージを送信することができません。'}
        </DisabledInputArea>
      )}
    </Container>
  );
};

export const userIsGraduate = (
  studentStatus: number | undefined | null,
  isInstructor: number | undefined | null,
): boolean => {
  // ここではインストラクターではない純粋な卒業生のみを対象とする
  return studentStatus === StudentStatus.Graduate && !isInstructor;
};

const CHAT_MESSAGE_DRAFTS_DEBOUNCE_SAVE_DELAY_MS = 300;

export type ChatMessageDraftInput = {
  chatRoomID: number;
  content: string | undefined;
};

export function useAutosave<T>(dataToSave: T): [React.Dispatch<React.SetStateAction<T>>] {
  const [data, setData] = React.useState<T>(dataToSave);

  const [upsertChatMessageDraft] = useUpsertChatMessageDraftMutation();
  const [deleteChatMessageDraft] = useDeleteChatMessageDraftMutation();

  const saveData = useSafeAsyncCallback(
    React.useCallback(
      async (newData: ChatMessageDraftInput): Promise<void> => {
        try {
          if (newData.content) {
            await upsertChatMessageDraft({
              variables: {
                chatRoomID: newData.chatRoomID,
                content: newData.content,
              },
            });
          } else {
            await deleteChatMessageDraft({
              variables: {
                chatRoomID: newData.chatRoomID,
              },
            });
          }

          setData(newData as T);
        } catch {
          return;
        }
      },
      [deleteChatMessageDraft, upsertChatMessageDraft],
    ),
  );

  const debounced = useDebouncedCallback((value) => {
    saveData(value);
  }, CHAT_MESSAGE_DRAFTS_DEBOUNCE_SAVE_DELAY_MS);

  useEffect(() => {
    if (data) {
      // debounceをしている理由: 負荷対策。
      // 入力の度にリクエストが発行されてしまうのを防いでいます。
      // [debounce]とは、繰り返し大量に発生する処理が終わった時に最後の1回だけ実行するもの。
      debounced(data);
    }
  }, [data, debounced]);

  return [setData];
}

const Container = styled.div``;

const Input = styled.section`
  width: 100%;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  display: flex;
  flex-flow: column;
  align-items: flex-end;
`;

const Link = styled.a`
  padding: 4px 16px;
  font-size: 12px;
  color: #eb0000;
`;

const QuestionIcon = styled.img`
  vertical-align: sub;
`;

const Editor = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;
`;

const SubmitIcon = styled.img`
  vertical-align: sub;
`;

const Err = styled.div`
  background-color: #fcfcfc;
  padding: 5px;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.375rem;
    background: url(${AlertIcon}) center / contain no-repeat;
    vertical-align: bottom;
  }
`;

const SubmitButton = styled(FormButton)`
  ${media.lessThan('small')`
    width: calc(100% - 32px);
    margin: 0.5rem auto;
  `}
  border-radius: 3px;
  padding: 0.25rem 1rem;
  margin: 0.5rem 1rem;
`;

const DisabledInputArea = styled.section`
  width: 100%;
  text-align: center;
  padding: 2rem 2rem 2rem 2rem;
  background-color: rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;
