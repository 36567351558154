import { useEffect, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

interface QueryVal {
  [key: string]: string;
}

interface Params {
  name: string;
  value?: string;
}

export const useURL = (): {
  queries?: QueryVal;
  setParams: (values: Params[]) => void;
  excludePageQuery: (url: string) => string;
} => {
  const location = useLocation();
  const navigate = useNavigate();

  const [queries, setQueries] = useState<QueryVal>();

  const setParams = (values: Params[]) => {
    const params = queryString.parse(location.search);
    values.forEach((item) => {
      params[item.name] = item.value && item.value !== '0' ? item.value : '';
    });

    navigate({
      pathname: location.pathname,
      search: queryString.stringify(params, { skipEmptyString: true }),
    });
  };

  const getParams = useCallback(() => {
    const queries = <QueryVal>{};
    const params = queryString.parse(location.search);

    Object.keys(params).forEach((key) => {
      const value = params[key];

      if (Array.isArray(value)) return;
      if (value === null || value === undefined) return;

      queries[key] = value;
    });

    return queries;
  }, [location.search]);

  const excludePageQuery = useCallback(
    (pathname: string): string => queryString.exclude(`${pathname}${location.search}`, ['page']),
    [location.search],
  );

  useEffect(() => {
    const params = getParams();
    setQueries(params);
  }, [location.search, navigate, setQueries, getParams]);

  return {
    queries,
    setParams,
    excludePageQuery,
  };
};

export const setParamsValue = (name: string, value?: string | number): Params => {
  if (value) {
    return { name: name, value: typeof value === 'number' ? value.toString() : value };
  } else {
    return { name: name };
  }
};
