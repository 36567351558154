import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { ToggleButton } from '../atoms/ToggleButton';

import IconQa from '../../static/image/icon_search_tag_question.svg';
import IconProgram from '../../static/image/icon_search_tag_program.svg';
import IconPost from '../../static/image/icon_search_tag_post.svg';
import IconStudyReport from '../../static/image/icon_search_tag_log.svg';
import IconQaBlack from '../../static/image/icon_search_tag_question_black.svg';
import IconProgramBlack from '../../static/image/icon_search_tag_program_black.svg';
import IconPostBlack from '../../static/image/icon_search_tag_post_black.svg';
import IconStudyReportBlack from '../../static/image/icon_search_tag_log_black.svg';

import { DocumentType, useGetCountByDocumentTypeQuery, CountSearchInput } from '../../gen/graphql';

interface Props {
  searchTypes?: string[];
  setValue: (value?: string) => void;
  className?: string;
  query?: CountSearchInput;
}

export const SearchType: React.FC<Props> = (props) => {
  const [currentTypes, setCurrentTypes] = React.useState(props.searchTypes ?? []);

  const { data: documentTypeCounts } = useGetCountByDocumentTypeQuery({
    variables: {
      input: props.query,
    },
  });
  const counts = documentTypeCounts?.countByDocumentType;

  const toggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newTypes = Array.from(new Set(currentTypes.concat(e.target.value.split(','))));
      setCurrentTypes(newTypes);
      props.setValue(newTypes?.join(','));
    } else {
      const newTypes = currentTypes.filter((i) => e.target.value.split(',').indexOf(i) == -1);
      setCurrentTypes(newTypes);
      props.setValue(newTypes?.join(','));
    }
  };

  return (
    <div className={props.className}>
      <Title>検索対象の絞り込み</Title>
      <TagList>
        <ToggleButton
          name="search_tag"
          value={DocumentType.Qa}
          icon={IconQaBlack}
          activeIcon={IconQa}
          onChange={toggle}
          checked={currentTypes?.some((val) => val === DocumentType.Qa)}
        >
          <ToggleInner>
            <TagName>Q&A</TagName>
            <TagNum>
              {counts?.map((val) => {
                if (val.documentType === DocumentType.Qa) {
                  return val.count;
                }
              })}
              件
            </TagNum>
          </ToggleInner>
        </ToggleButton>
        <ToggleButton
          name="search_tag"
          value={`${DocumentType.Program},${DocumentType.Practice}`}
          icon={IconProgramBlack}
          activeIcon={IconProgram}
          onChange={toggle}
          checked={currentTypes?.some((val) => val === DocumentType.Program)}
        >
          <ToggleInner>
            <TagName>教材・課題</TagName>
            <TagNum>
              {counts
                ?.filter(
                  (val) =>
                    val.documentType === DocumentType.Program ||
                    val.documentType === DocumentType.Practice,
                )
                .map((val) => {
                  return val.count;
                })
                .reduce((sum, currentValue) => sum + currentValue)}
              件
            </TagNum>
          </ToggleInner>
        </ToggleButton>
        <ToggleButton
          name="search_tag"
          value={DocumentType.Tweet}
          icon={IconPostBlack}
          activeIcon={IconPost}
          onChange={toggle}
          checked={currentTypes?.some((val) => val === DocumentType.Tweet)}
        >
          <ToggleInner>
            <TagName>投稿</TagName>
            <TagNum>
              {counts?.map((val) => {
                if (val.documentType === DocumentType.Tweet) {
                  return val.count;
                }
              })}
              件
            </TagNum>
          </ToggleInner>
        </ToggleButton>
        <ToggleButton
          name="search_tag"
          value={DocumentType.StudyReport}
          icon={IconStudyReportBlack}
          activeIcon={IconStudyReport}
          onChange={toggle}
          checked={currentTypes?.some((val) => val === DocumentType.StudyReport)}
        >
          <ToggleInner>
            <TagName>学習ログ</TagName>
            <TagNum>
              {counts?.map((val) => {
                if (val.documentType === DocumentType.StudyReport) {
                  return val.count;
                }
              })}
              件
            </TagNum>
          </ToggleInner>
        </ToggleButton>
      </TagList>
    </div>
  );
};

const Title = styled.h2`
  color: #000;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.1px;
`;
const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    flex-wrap: nowrap;
    gap: .5rem;
    margin: .625rem -1rem 0;
    padding: 0 1rem;
    overflow-x: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;
const ToggleInner = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
const TagName = styled.span`
  flex: 1;
  font-size: 0.75rem;
`;
const TagNum = styled.span`
  font-size: 0.625rem;
`;
