import React from 'react';
import styled from 'styled-components';

export const Overlay: React.FC = (props) => {
  return <StyledOverlay>{props.children}</StyledOverlay>;
};

export const StyledOverlay = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
`;
