import * as yup from 'yup';
import { PaymentMethodType } from '../../gen/graphql';
import {
  MAXIMUM_NUMBER_OF_USERS,
  MINIMUM_NUMBER_OF_USERS,
  // LMS-4184 テラコヤ法人プラン_請求書払時の人数下限解除（ロジックをコメントアウトすることにより対応します）
  // MINIMUM_NUMBER_OF_USERS_FOR_BANK_TRANSFER,
} from '../../const/team';

// 利用人数
const quantity = yup
  .number()
  .required('利用人数を入力してください')
  .integer('利用人数は整数で入力してください')
  // LMS-4184 テラコヤ法人プラン_請求書払時の人数下限解除（ロジックをコメントアウトすることにより対応します）
  // .when('paymentMethodType', (value, schema) => {
  //   if (value === PaymentMethodType.Bank)
  //     return schema.min(
  //       MINIMUM_NUMBER_OF_USERS_FOR_BANK_TRANSFER,
  //       `銀行振込の場合、利用人数は${MINIMUM_NUMBER_OF_USERS_FOR_BANK_TRANSFER}以上で入力してください`
  //     );
  //   return schema.min(1, '利用人数は1以上で入力してください');
  // })
  .min(1, '利用人数は1以上で入力してください')
  .max(MAXIMUM_NUMBER_OF_USERS, `利用人数は${MAXIMUM_NUMBER_OF_USERS}以下で入力してください`)
  .transform((value) => (isNaN(value) ? undefined : value));

// 支払い方法
const paymentMethodType = yup
  .string()
  .required('お支払い方法を選択してください')
  .oneOf(Object.values(PaymentMethodType));

// 利用期間
const priceID = yup.number().required('利用期間を選択してください');

// クレジットカード
const providerPaymentMethodID = yup
  .string()
  .nullable()
  .when('paymentMethodType', (paymentMethodType, schema) => {
    return typeof paymentMethodType === 'string' &&
      paymentMethodType === PaymentMethodType.CreditCard
      ? schema
          .required('クレジットカードを選択してください')
          .transform((value: string) => value ?? '')
      : schema.nullable();
  });

// 利用開始日
const billingCycleAnchor = yup
  .string()
  .nullable()
  .when('paymentMethodType', (paymentMethodType, schema) => {
    return typeof paymentMethodType === 'string' && paymentMethodType === PaymentMethodType.Bank
      ? schema.required('利用開始日を入力してください').transform((value: string) => value ?? '')
      : schema.nullable();
  });

// 会社名
const companyName = yup
  .string()
  .required('会社名を入力してください')
  .max(100, '会社名は100文字以内で入力してください')
  .trim();

// 請求先メールアドレス
const adminEmail = yup
  .string()
  .required('請求先メールアドレスを入力してください')
  .email('請求先メールアドレスは正しい形式で入力してください')
  .max(100, '請求先メールアドレスは100文字以内で入力してください')
  .trim();

// タイムゾーンオフセット
const timezoneOffset = yup.number().required();

const teamID = yup.string().nullable();

// 法人請求情報入力フォームのschema
const teamSubscriptionSchema = yup.object({
  quantity,
  paymentMethodType,
  priceID,
  billingCycleAnchor,
  teamID,
  timezoneOffset,
  providerPaymentMethodID,
});

export const teamAndTeamSubscriptionSchema = yup.object({
  quantity,
  paymentMethodType,
  priceID,
  billingCycleAnchor,
  timezoneOffset,
  companyName,
  teamID,
  adminEmail,
});

interface TeamReSubscriptionSchemaProps {
  currentQuantity?: number;
}

// 法人再契約フォームのschema
export const teamReSubscriptionSchema = ({
  currentQuantity = MINIMUM_NUMBER_OF_USERS,
}: TeamReSubscriptionSchemaProps): yup.AnyObjectSchema =>
  teamSubscriptionSchema.shape({
    providerPaymentMethodID,
    // override
    quantity: quantity.min(
      currentQuantity,
      `利用人数は${currentQuantity}以上の人数を指定してください`,
    ),
  });
