import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import {
  ChapterFragment,
  ChapterSearchFragment,
  PracticeFragment,
  PracticeSearchFragment,
  PracticeSubmitDetailReviewStatus,
} from '../../gen/graphql';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { useCallback } from 'react';
import { getChapterFunctionType, getPracticeFunctionType } from '../../utils/Program';
import { getRecentPracticeSubmitStatus } from '../../utils/PracticeSubmit';
import { PermissionType } from '../../const/UserPermission';

type Chapter = ChapterFragment | ChapterSearchFragment;
type Practice = PracticeFragment | PracticeSearchFragment;

interface Props {
  programElements: (Chapter | Practice)[];
  currentID: number | string;
  programID?: number;
}

export const ProgramElementsList: React.FC<Props> = ({ programElements, programID, currentID }) => {
  const { openModal } = useCommonModal();

  const checkShowDetail = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, pe: Chapter | Practice) => {
      const functionType =
        pe.__typename === 'Chapter'
          ? getChapterFunctionType(pe.type)
          : pe.__typename === 'Practice'
            ? getPracticeFunctionType(pe.type)
            : undefined;
      if (!functionType) {
        e.preventDefault();
        return;
      }

      if (pe.__typename === 'Practice') {
        const practice = pe as PracticeFragment;
        if (
          // 合格した課題に遷移する場合はモーダルは表示しない
          getRecentPracticeSubmitStatus(practice.mySubmit?.practiceSubmitDetails) !==
            PracticeSubmitDetailReviewStatus.Passed &&
          openModal(functionType, PermissionType.Read)
        )
          e.preventDefault();
      } else {
        if (openModal(functionType, PermissionType.Read)) e.preventDefault();
      }
    },
    [openModal],
  );

  return (
    <List>
      <div className="title">目次</div>
      <div>
        {programElements?.map((programElement: Chapter | Practice) => {
          if (programElement.__typename === 'Chapter') {
            const ch = programElement;
            return (
              <StyledLink
                to={programID ? `/programs/${programID}/chapters/${ch.id}` : ''}
                className={`${ch.id === currentID ? 'active' : ''}`}
                key={ch.id}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                  checkShowDetail(e, ch)
                }
              >
                <p>{ch.number}</p>
                <h4>{ch.title}</h4>
              </StyledLink>
            );
          }
          if (programElement.__typename === 'Practice') {
            const pl = programElement;
            return (
              <StyledLink
                to={`/practices/${pl.id}/body`}
                className={`${pl.id === currentID ? 'active' : ''}`}
                key={pl.id}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                  checkShowDetail(e, pl)
                }
              >
                <p>課題</p>
                <h4>{pl.title}</h4>
              </StyledLink>
            );
          }
        })}
      </div>
    </List>
  );
};

const List = styled.div`
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;

  .title {
    margin: 0 auto 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 4px;
      margin: 1.5rem auto 0;
      background: #e2001b;
    }
  }

  ${media.lessThan('medium')`
    padding: 0;
    border: none;
  `}
`;
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  min-height: 3rem;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border-radius: 0.125rem;
  font-weight: 700;

  &:nth-of-type(n + 2) {
    margin-top: 0.25rem;
  }

  &:hover {
    background: #efefef;
  }

  &.active {
    background: #fce5e8;
  }

  p {
    width: 2rem;
    margin: 0 0.625rem 0 0;
    text-align: center;
  }

  h4 {
    flex: 1;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
