import React from 'react';

import styled from 'styled-components';
import { H3 } from '../../atoms/Typography';
import { Link } from 'react-router-dom';

import { ProgramListContainer } from '../../atoms/ProgramListContainer';
import { ProgramArticle } from '../../organisms/ProgramArticle';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { useUser } from '../../../redux/user/useUser';

import { useGetProgramsQuery, ProgramSearchType } from '../../../gen/graphql';
import { useGetExamMedal } from '../../../common/customHooks/GetExamMedal';

interface ProgramWhileStudyingsProps {
  limit: number;
  offset: number;
  showWhileStudyingLink: boolean;
  showNoContentsMessage: boolean;
}

export const ProgramWhileStudyings: React.FC<ProgramWhileStudyingsProps> = (props) => {
  const { user } = useUser();
  const programWhileStudyinsLink = '/programs_while_studying';
  const medalCounts = useGetExamMedal();

  const { data, loading } = useGetProgramsQuery({
    variables: {
      input: {
        programSearchType: ProgramSearchType.WhileStudying,
      },
      userID: user.lmsUser?.id,
    },
  });

  return (
    <ProgramSection>
      <StyledH3Block data-e2e="ProgramWhileStudyingList">
        <StyledH3 color="DARK">学習中の教材</StyledH3>
        {props.showWhileStudyingLink ? (
          <StyledH3Link to={programWhileStudyinsLink}>学習中の教材一覧 ＞</StyledH3Link>
        ) : (
          ''
        )}
      </StyledH3Block>
      {!loading &&
        (data?.programs?.items && data?.programs.items.length > 0 ? (
          <ProgramListContainer>
            {data?.programs.items.map((p) => {
              return (
                <ProgramArticle
                  id={p?.id ? p.id : 0}
                  key={p.id}
                  image={p?.icon ? p.icon : ''}
                  thumb={p.thumbnail}
                  title={p?.title ? p.title : ''}
                  description={p.description}
                  questionCount={p.questionCount}
                  answerCount={p.answerCount}
                  chapterCount={p?.chapterCount ? p.chapterCount : 0}
                  requireTime={p?.requireTime ? p.requireTime : 0}
                  completedTime={p?.completedTime ? p.completedTime : 0}
                  tags={p.tags ?? []}
                  studyLogCount={p.studyLogCount}
                  programSample={p.programSample ?? undefined}
                  medalCount={p.id ? medalCounts.get(p.id) : undefined} // Medalカウントをこの層で取得する必要がなければ削除
                />
              );
            })}
          </ProgramListContainer>
        ) : (
          props.showNoContentsMessage && (
            <EmptyBlock title="学習中の教材はありません">教材を利用し学習を始めましょう</EmptyBlock>
          )
        ))}
    </ProgramSection>
  );
};

const ProgramSection = styled.section`
  margin-bottom: 2.625rem;
`;

const StyledH3Block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const StyledH3 = styled(H3)`
  font-size: 1.25rem;
  display: inline-block;
  margin-bottom: 0;
`;

const StyledH3Link = styled(Link)<{ active?: boolean }>`
  display: inline-block;
  color: #e2001b;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
`;
