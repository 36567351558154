import React, { useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import media from 'styled-media-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Rating, Star } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css';
import {
  ProgramSatisfactionLevel,
  useCreateProgramSatisfactionMutation,
  ProgramSatisfactionInput,
} from '../../gen/graphql';
import { useSafeAsyncCallback } from './SafeAsyncCallback';
import { Modal as BaseModal } from '../../components/molecules/Modal';
import { Button } from '../../components/atoms/Button';
import { getApiErrorMessage } from '../../utils/graphqlError';
import { useToastsContext } from '../../context/ToastsProvider';
import { programSatisfactionSchema } from '../../common/formSchema/programSatisfaction';

const BAD = 1;
const POOR = 2;
const AVERAGE = 3;
const GOOD = 4;
const EXCELLENT = 5;

const toNumber = (value: ProgramSatisfactionLevel): number => {
  switch (value) {
    case ProgramSatisfactionLevel.Bad:
      return BAD;
    case ProgramSatisfactionLevel.Poor:
      return POOR;
    case ProgramSatisfactionLevel.Average:
      return AVERAGE;
    case ProgramSatisfactionLevel.Good:
      return GOOD;
    case ProgramSatisfactionLevel.Excellent:
      return EXCELLENT;
    default:
      return BAD;
  }
};

// 以下の記載があるためcomponentの外に記載
// Declare it outside your component so it doesn't get re-created
// see: https://github.com/smastrom/react-rating#behavior
const ratingStyle = {
  itemShapes: Star,
  activeFillColor: '#f59e0b',
  inactiveFillColor: '#ffedd5',
};

export const useProgramSatisfactionModal = (
  programID: number,
  isOpen: boolean,
  onClose: () => void,
  programTitle: string,
): {
  Modal: React.FC;
} => {
  const { showToast } = useToastsContext();
  const [createProgramSatisfaction] = useCreateProgramSatisfactionMutation();

  // useMemo内部にあるとshowToastの状態変更のタイミングでFormがリセットされるため外側に設定
  const { control, handleSubmit, formState, watch } = useForm<ProgramSatisfactionInput>({
    resolver: yupResolver(programSatisfactionSchema),
    defaultValues: {
      programID: 0,
      level: ProgramSatisfactionLevel.Bad,
      comment: '',
    },
  });

  const onSubmit: SubmitHandler<ProgramSatisfactionInput> = useSafeAsyncCallback(async (data) => {
    try {
      await createProgramSatisfaction({
        variables: {
          input: {
            programID,
            level: data.level,
            comment: data.comment,
          },
        },
      });
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
      showToast(1, getApiErrorMessage(e));
      onClose();
      return;
    }

    onClose();
  });

  // NOTE: smastrom/react-ratingのStarのviewBoxが0 0 0 0で設定され星が非表示になるため、ModalをuseMemoで設定してから返却する
  //       引数もModalPropsにすると何故かStarが表示されないため、useProgramSatisfactionModalで設定する
  const Modal = useMemo(() => {
    const ProgramSatisfactionModalComponent: React.FC = () => {
      const CUSTOM_ITEM_LABELS = ['不満', '', '', '', '満足'];
      const CUSTOM_ITEM_LABELS_IDS = ['BAD', 'POOR', 'AVERAGE', 'GOOD', 'EXCELLENT'];

      return (
        <BaseModal isOpen={isOpen} onClose={onClose} hideHeaderClose>
          <Header>
            <Title>教材の評価</Title>
          </Header>
          <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ContentTitle>
                『{programTitle}』修了、
                <br />
                お疲れ様でした。この教材の満足度を教えてください。
              </ContentTitle>
              <RatingBox>
                <Controller
                  control={control}
                  name="level"
                  render={({ field: { onChange, value } }) => (
                    <div role="group" style={{ maxWidth: 450, width: '100%' }}>
                      <Rating
                        value={toNumber(value)}
                        itemStyles={ratingStyle}
                        // react-ratingはクリックするとintをvalueに保持しようとする。
                        // しかしvalueの本来の型はProgramSatisfactionLevelなので
                        // 一度ProgramSatisfactionLevelに変換してからintに戻す。
                        // これをしないと型の不一致でエラーとなる。
                        onChange={(index: number) => {
                          // 本来はgraphql.tsのProgramSatisfactionLevelを使いたいがenumの順番がABC順に変わってしまうので利用しない
                          const values = Object.values(CUSTOM_ITEM_LABELS_IDS);
                          onChange(values[index - 1]);
                        }}
                        visibleItemLabelIds={CUSTOM_ITEM_LABELS_IDS}
                        spaceBetween="medium"
                        spaceInside="medium"
                      />
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(5, 1fr)',
                          justifyItems: 'center',
                        }}
                      >
                        {CUSTOM_ITEM_LABELS.map((label, index) => (
                          <span
                            key={index}
                            id={CUSTOM_ITEM_LABELS_IDS[index]}
                            style={{
                              padding: '0 5%',
                            }}
                          >
                            {label}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                />
              </RatingBox>
              <CommentLabel>評価のコメントを記入しましょう</CommentLabel>
              <Controller
                name="comment"
                control={control}
                render={({ field: { ref, value: _value, ...rest } }) => (
                  <StyledTextArea
                    placeholder="例）図解が多く、理解しやすかったです。強いて言うなら、Flexboxについてもう少し触れられていると嬉しかったです。"
                    ref={ref}
                    {...rest}
                  />
                )}
              />
              <StyledButton type={'submit'} disabled={!watch('level')}>
                評価を投稿する
              </StyledButton>
              {!watch('level') && <ErrorText>評価の星を選択しましょう</ErrorText>}
              <ErrorText>{formState.errors.level?.message}</ErrorText>
              <ErrorText>{formState.errors.comment?.message}</ErrorText>
            </form>
          </Container>
        </BaseModal>
      );
    };

    return ProgramSatisfactionModalComponent;
  }, [
    control,
    formState.errors.comment?.message,
    formState.errors.level?.message,
    handleSubmit,
    isOpen,
    onClose,
    onSubmit,
    programTitle,
    watch,
  ]);

  return { Modal };
};

const Header = styled.div`
  padding: 1rem 3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const Title = styled.h2`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
`;

const Container = styled.div`
  padding: 2rem;

  ${media.lessThan('medium')`
    padding: 1rem 1rem 2rem;
  `}
`;

const ContentTitle = styled.h3`
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: center;

  br {
    display: block;
  }

  ${media.lessThan('medium')`
    font-size: 1.125rem;
    line-height: 1.625rem;

    br {
      display: none;
    }
  `}
`;

const RatingBox = styled.div`
  margin: 32px 0;
  padding: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`;

const CommentLabel = styled.p`
  margin-top: 2rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;

  ${media.lessThan('medium')`
    margin-top: 1.5rem;
    font-size: 1rem;
  `}
`;

const StyledTextArea = styled.textarea<{ error?: boolean }>`
  width: 100%;
  height: 160px;

  margin-top: 0.5rem;

  appearance: none;
  padding: 0.5rem;
  border: 1px solid ${(props): string => (props.error ? '#fd2f92' : '#dddddd')};
  border-radius: 2px;
  background-color: ${(props): string => (props.error ? '#fce5e8' : '#ffffff')};
  box-sizing: border-box;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 240px;
  margin: 2rem auto 0;
  padding-left: 0;
  padding-right: 0;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 320px;
  `}
`;

const ErrorText = styled.div`
  text-align: center;
  margin: 0;
  margin-top: 3px;

  color: #eb0000;

  font-family: 'Noto Sans CJK JP';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
`;
