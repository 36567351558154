import React, { useReducer } from 'react';

interface ModalContext {
  isOpen: boolean;
  header: React.ReactNode;
  content: React.ReactNode;
  setHeader: (header: React.ReactNode) => void;
  setContent: (content: React.ReactNode) => void;
  open: () => void;
  close: () => void;
}

const initialContext: ModalContext = {
  isOpen: false,
  header: null,
  content: null,
  setHeader: () => undefined,
  setContent: () => undefined,
  open: () => undefined,
  close: () => undefined,
};

export const ModalContext = React.createContext(initialContext);

const headerActionTypes = {
  set: 'SET_HEADER',
} as const;

const headerSet = (payload: React.ReactNode) => ({
  type: headerActionTypes.set,
  payload,
});

type HeaderActions = ReturnType<typeof headerSet>;

const contentActionTypes = {
  set: 'SET_CONTENT',
} as const;

const contentSet = (payload: React.ReactNode) => ({
  type: contentActionTypes.set,
  payload,
});

type ContentActions = ReturnType<typeof contentSet>;

const isOpenActionTypes = {
  open: 'OPEN',
  close: 'CLOSE',
} as const;

const openModal = () => ({
  type: isOpenActionTypes.open,
});

const closeModal = () => ({
  type: isOpenActionTypes.close,
});

type IsOpenActions = ReturnType<typeof openModal | typeof closeModal>;

const useModal = (): ModalContext => {
  const [header, dispatchHeader] = useReducer((state: React.ReactNode, action: HeaderActions) => {
    switch (action.type) {
      case headerActionTypes.set:
        return action.payload;
      default:
        return state;
    }
  }, null);

  const setHeader = (header: React.ReactNode) => {
    dispatchHeader({ type: headerActionTypes.set, payload: header });
  };

  const [content, dispatchContent] = useReducer(
    (state: React.ReactNode, action: ContentActions) => {
      switch (action.type) {
        case contentActionTypes.set:
          return action.payload;
        default:
          return state;
      }
    },
    null,
  );

  const setContent = (content: React.ReactNode) => {
    dispatchContent({ type: contentActionTypes.set, payload: content });
  };

  const [isOpen, dispatchIsOpen] = useReducer((state: boolean, action: IsOpenActions) => {
    switch (action.type) {
      case isOpenActionTypes.close:
        return false;
      case isOpenActionTypes.open:
        return true;
      default:
        return state;
    }
  }, false);

  const open = () => {
    dispatchIsOpen({ type: isOpenActionTypes.open });
  };

  const close = () => {
    dispatchIsOpen({ type: isOpenActionTypes.close });
  };

  return {
    isOpen,
    header,
    content,
    setHeader,
    setContent,
    open,
    close,
  };
};

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = (props) => {
  const value = useModal();

  return <ModalContext.Provider value={value}>{props.children}</ModalContext.Provider>;
};
