import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';

import FeatureImage001 from '../../../static/image/lp/feature_01.svg';
import FeatureImage002 from '../../../static/image/lp/feature_02.svg';
import FeatureImage003 from '../../../static/image/lp/feature_03.svg';
import FeatureImage004 from '../../../static/image/lp/feature_04.svg';

import { PROGRAM_COUNT } from '../../../const/Program';

export const LpFeature: React.FC = () => {
  return (
    <Feature id="feature" data-e2e="feature">
      <Inner>
        <Ttl>
          <h2>サービスの特徴</h2>
          <p>Features</p>
        </Ttl>
        <Description>
          プログラミングのスキルの習得には、<span>「挫折しない環境」</span>が必要です
        </Description>
        <Section>
          <Flex>
            <Column>
              <img src={FeatureImage001} alt="月1回、現役エンジニアとのオンラインレッスン" />
              <p>
                現役エンジニアとのマンツーマンレッスンであなたの疑問やつまづきを解消できます。言語化できない悩みもご相談ください。
              </p>
            </Column>
            <Column>
              <img src={FeatureImage002} alt="回答率100%のQ&A掲示板に質問し放題" />
              <p>
                技術的な悩みやプログラミング学習の悩み、キャリアや転職に関する悩みも相談可能。現役エンジニアがあなたをサポートします。
              </p>
            </Column>
            <Column>
              <img src={FeatureImage003} alt={`${PROGRAM_COUNT}種類以上の教材で学習し放題`} />
              <p>
                プログラミング学習の前提知識からWebサイト制作・Webアプリ開発まで実践的な教材で着実にスキルアップできます。
              </p>
            </Column>
            <Column>
              <img src={FeatureImage004} alt="実践的なスキルを身につける課題機能" />
              <p>
                教材で身につけたスキルを「わかる」から「できる」に変えるための発展教材です。レビューをもらうことでスキルをブラッシュアップできます。
              </p>
            </Column>
          </Flex>
        </Section>
      </Inner>
    </Feature>
  );
};

const Feature = styled.section`
  padding: 4rem 0 6.25rem;
  scroll-margin-top: 49px;

  ${media.lessThan('medium')`
    padding: 4rem 0;
    scroll-margin-top: 57px;
  `}
`;
const Description = styled.p`
  margin: 3rem auto 3rem;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  span {
    color: #e2001b;
    font-size: 1.5rem;
    font-weight: 700;
  }

  ${media.lessThan('medium')`
    margin: 2rem auto 3rem;
    font-size: 1rem;

    span {
      font-size: 1rem;
    }
  `}
`;
const Section = styled.div`
  margin: 4rem auto 0;

  ${media.lessThan('medium')`
    &:nth-of-type(1) {
      margin-top: 3rem;
    }
  `}
`;
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem 2rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 2rem;
  `}
`;
const Column = styled.div`
  width: calc(50% - 1rem);

  img {
    width: 100%;
    filter: drop-shadow(0 0.25rem 1rem rgba(0, 0, 0, 0.1));
  }

  p {
    max-width: 386px;
    margin: 2rem auto 0;
    font-size: 1rem;
    line-height: 1.5;
    box-sizing: border-box;
  }

  ${media.lessThan('medium')`
    width: 100%;

    p {
      margin-top: 1.5rem;
      padding: 0 .875rem;
      font-size: .875rem;
      line-height: 1.6;
    }
  `}
`;
