import React from 'react';
import styled from 'styled-components';

export interface CheckBoxOption {
  label: string;
  value: string;
}

interface CheckBoxProps {
  name: string;
  options: CheckBoxOption[];
  checkedValues: string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const CheckBox: React.FC<CheckBoxProps> = (props) => {
  return (
    <div>
      {props.options.map((option) => {
        return (
          <Label key={option.value}>
            <input
              type="checkbox"
              name={props.name}
              value={option.value}
              onChange={props.onChange}
              checked={props.checkedValues.includes(option.value)}
              disabled={props.disabled}
            />
            {option.label}
          </Label>
        );
      })}
    </div>
  );
};

const Label = styled.label`
  display: block !important;
  font-size: 1rem;
  font-weight: normal !important;
`;
