import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';
import { Plan } from './Plan';

import { scrollToID } from '../../../utils/ui/Anchor';

export const PlanSimple: React.FC = () => {
  const [isClosed, setIsClosed] = React.useState(true);

  const openPlanDetail = () => {
    setIsClosed(false);
    scrollToID('planDetail');
  };

  const closePlanDetail = () => {
    setIsClosed(true);
    scrollToID('plan');
  };

  return (
    <>
      <Section id="plan" data-gtm="plan">
        <Inner>
          <Ttl>
            <h2>プランについて</h2>
            <p>Plans</p>
          </Ttl>
          <List>
            <Item>
              <Bubble>プログラミングに触れる</Bubble>
              <ItemTitle>フリープラン</ItemTitle>
              <UsableFunction>
                <p>
                  教材
                  <br />
                  学習ログ
                  <br />
                  Q&A閲覧
                </p>
              </UsableFunction>
              <Price>
                <Num>0</Num>円/月
              </Price>
            </Item>
            <Item>
              <Bubble>挫折しない学習をサポート</Bubble>
              <ItemTitle>有料プラン</ItemTitle>
              <UsableFunction>
                <p>
                  すべての教材
                  <br />
                  学習ログ
                  <br />
                  Q&A閲覧
                </p>
                <p>
                  Q&A質問
                  <br />
                  課題機能
                  <br />
                  レッスン
                </p>
              </UsableFunction>
              <Price>
                <Num>2,980</Num>円〜/月<Tax>(税込)</Tax>
              </Price>
            </Item>
          </List>
          <Button isClosed={isClosed} onClick={openPlanDetail}>
            + 有料プランの詳細はこちら
          </Button>
        </Inner>
      </Section>
      <Plan type="lp" isClosed={isClosed} closePlan={closePlanDetail} />
    </>
  );
};

const Section = styled.section`
  margin: 7.5rem auto;

  ${media.lessThan('medium')`
    margin: 4rem auto;
  `}
`;

const List = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  max-width: 824px;
  margin: 4rem auto 0;

  ${media.lessThan('medium')`
    flex-direction: column;
    margin-top: 2rem;
  `}
`;
const Item = styled.div`
  width: calc((100% - 1.5rem) / 2);
  padding: 3rem 1.5rem;
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    width: 100%;
    padding: 2rem 1.5rem;
  `}
`;
const Bubble = styled.p`
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  padding: 1rem 0;
  background: #ef1919;
  border-radius: 100px;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.02em;
  text-align: center;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    margin: auto;
    border-style: solid;
    border-color: #ef1919 transparent transparent transparent;
    border-width: 0.5rem 0.5rem 0 0.5rem;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  ${media.lessThan('medium')`
    padding: .625rem 0;
  `}
`;
const ItemTitle = styled.h3`
  margin-top: 0.625rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.45;
  text-align: center;

  ${media.lessThan('medium')`
    margin-top: .75rem;
    font-size: 1.75rem;
    line-height: 1.5;
  `}
`;
const UsableFunction = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.125rem;
    line-height: 2rem;
    text-align: center;
  }

  ${media.lessThan('medium')`
    p {
      font-size: .875rem;
      line-height: 1.5rem;
    }
  `}
`;
const Price = styled.p`
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1;

  ${media.lessThan('medium')`
    font-size: 1.25rem;
  `}
`;
const Num = styled.span`
  color: #ef1919;
  font-size: 3rem;
  font-weight: 700;

  ${media.lessThan('medium')`
    font-size: 2.125rem;
  `}
`;
const Tax = styled.span`
  font-size: 1.125rem;

  ${media.lessThan('medium')`
    font-size: .875rem;
  `}
`;

const Button = styled.p<{ isClosed: boolean }>`
  display: ${(props) => (props.isClosed ? 'block' : 'none')};
  width: 100%;
  max-width: 320px;
  margin: 4rem auto 0;
  padding: 0.5rem 0;
  border: 1px solid #aeaeae;
  border-radius: 0.1875rem;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;

  ${media.lessThan('medium')`
    margin-top: 3rem;
    font-size: 1rem;
  `}
`;
