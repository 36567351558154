import { ApolloError, Reference } from '@apollo/client';

import {
  ChatRoomListFragment,
  useChatRoomContentChangedSubscription,
  useGetChatMessageUnreadCountLazyQuery,
  useGetChatRoomsQuery,
  useReadAllChatMessageMutation,
} from '../../gen/graphql';

import { FunctionType, PermissionType } from '../../const/UserPermission';
import { useUser } from '../../redux/user/useUser';
import { isReference } from '@apollo/client/utilities';

export const useFetchChatRoom = (): {
  chatRooms: ChatRoomListFragment[];
  readMessages: (chatRoomID: number) => void;
  loading: boolean;
  loadMore: (startIndex: number, stopIndex: number) => Promise<void> | void;
  hasNextPage: boolean | undefined;
  error: ApolloError | undefined;
} => {
  const { permissionCheck } = useUser();
  const [getChatMessageUnreadCount] = useGetChatMessageUnreadCountLazyQuery();
  const [readAllChatMessage] = useReadAllChatMessageMutation({
    update(cache, { data }) {
      if (!data?.readAllChatMessage.result) {
        return;
      }

      cache.modify({
        fields: {
          chatRooms(existing: Reference | { items: ChatRoomListFragment[] }) {
            if (!existing || isReference(existing)) {
              return existing;
            }

            return {
              ...existing,
              items: existing.items?.map((chatRoom: ChatRoomListFragment) =>
                chatRoom.id === data?.readAllChatMessage.chatRoomID
                  ? { ...chatRoom, unreadCount: 0 }
                  : chatRoom,
              ),
            };
          },
        },
      });
    },
  });

  const { data, loading, error, fetchMore } = useGetChatRoomsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 20,
    },
  });

  useChatRoomContentChangedSubscription({
    onData: async ({ data: result, client }) => {
      if (!result.data || !result.data.chatRoomContentChanged) return;

      const changed = result.data.chatRoomContentChanged;

      client.cache.modify({
        fields: {
          chatRooms(
            existing:
              | { items: ReadonlyArray<Reference | ChatRoomListFragment> }
              | Reference
              | undefined,
          ): { items: ReadonlyArray<Reference | ChatRoomListFragment> } {
            if (!existing || typeof existing === 'string') {
              return { items: [] };
            }

            if (isReference(existing)) {
              return { items: [existing] };
            }

            const newChatRoom: ChatRoomListFragment = {
              __typename: 'ChatRoom',
              ...changed,
            } as ChatRoomListFragment;

            const updatedChatRooms = [
              newChatRoom,
              ...existing.items.filter((v) => {
                if (isReference(v)) {
                  return v.__ref !== `ChatRoom:${result.data?.chatRoomContentChanged.id}`;
                }
                return (v as ChatRoomListFragment).id !== result.data?.chatRoomContentChanged.id;
              }),
            ];

            return { items: updatedChatRooms as ReadonlyArray<Reference | ChatRoomListFragment> };
          },
        },
      });

      if (!permissionCheck(FunctionType.Chat, PermissionType.Read)) return;

      try {
        // navigation bar unread count updated
        await getChatMessageUnreadCount();
      } catch {
        return;
      }
    },
  });

  if (loading && !data?.chatRooms)
    return {
      chatRooms: [],
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      readMessages: () => {},
      loading,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      loadMore: () => {},
      hasNextPage: false,
      error,
    };

  const readMessages = async (chatRoomID: number) => {
    if (!permissionCheck(FunctionType.Chat, PermissionType.Read)) {
      return;
    }

    await readAllChatMessage({ variables: { chatRoomID: chatRoomID } }).then(async () => {
      // navigation bar unread count updated
      await getChatMessageUnreadCount();
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadMore = async (startIndex: number, stopIndex: number) => {
    const lastRoom = data?.chatRooms?.items.slice(-1)[0];
    if (!lastRoom) {
      return;
    }

    try {
      await fetchMore({
        variables: {
          after: lastRoom.lastMessage?.createdAt ?? lastRoom.createdAt,
        },
      });
    } catch {
      return;
    }
  };

  return {
    chatRooms: data?.chatRooms.items ?? [],
    readMessages,
    loading,
    loadMore,
    hasNextPage: data?.chatRooms?.pageInfo.hasNextPage,
    error,
  };
};
