import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import Logo from '../../static/image/logo.svg';
import { LIGHT_TAX_INCLUDED_LOCALED_PRICE } from '../../const';
import { SERVICE_NAME } from '../../const/Service';
import { PROGRAM_COUNT } from '../../const/Program';

export const DealTable: React.FC = () => {
  return (
    <Table>
      <RowTitle001></RowTitle001>
      <RowTitle002>
        <Title>月額</Title>
      </RowTitle002>
      <RowTitle003>
        <Title>登録料</Title>
      </RowTitle003>
      <RowTitle004>
        <Title>マンツーマンレッスン</Title>
      </RowTitle004>
      <RowTitle005>
        <Title>質問可否</Title>
      </RowTitle005>
      <RowTitle006>
        <Title>質問可能数</Title>
      </RowTitle006>
      <RowTitle007>
        <Title>教材数</Title>
      </RowTitle007>
      <RowTitle008>
        <Title>課題数</Title>
      </RowTitle008>
      <RowTitle009>
        <Title>対応可能言語</Title>
      </RowTitle009>
      <RowTitle010>
        <Title>学習の記録</Title>
      </RowTitle010>
      <PickupColumn>
        <PickUpCell001>
          <Img src={Logo} alt={SERVICE_NAME} />
        </PickUpCell001>
        <PickUpCell002>
          <PickupPrice>
            <SmallMonth>月額</SmallMonth>
            <PickupNum>{LIGHT_TAX_INCLUDED_LOCALED_PRICE}</PickupNum>
            <SmallUnit>円〜</SmallUnit>
          </PickupPrice>
        </PickUpCell002>
        <PickUpCell003>無料</PickUpCell003>
        <PickUpCell004>
          <div>
            <Circle />
            <PickupText>60分のレッスン月1回~</PickupText>
          </div>
        </PickUpCell004>
        <PickUpCell005>
          <div>
            <Circle />
            <PickupText>どんな教材でも質問可</PickupText>
          </div>
        </PickUpCell005>
        <PickUpCell006>
          <div>
            <Circle />
            <PickupText>質問し放題</PickupText>
          </div>
        </PickUpCell006>
        <PickUpCell007>
          <div>
            <Circle />
            <PickupText>{PROGRAM_COUNT}教材以上</PickupText>
          </div>
        </PickUpCell007>
        <PickUpCell008>
          <div>
            <Circle />
            <PickupText>12個</PickupText>
          </div>
        </PickUpCell008>
        <PickUpCell009>
          <div>
            <Circle />
            <PickupText>15種類以上</PickupText>
          </div>
        </PickUpCell009>
        <PickUpCell010>
          <Circle />
        </PickUpCell010>
      </PickupColumn>
      <ColumnA001>
        <Title>A社</Title>
      </ColumnA001>
      <ColumnA002>
        <Text>
          <SmallMonth>月額</SmallMonth>
          <Num>19,800</Num>
          <SmallUnit>円</SmallUnit>
        </Text>
      </ColumnA002>
      <ColumnA003>
        <Text>
          <Num>198,000</Num>
          <SmallUnit>円</SmallUnit>
        </Text>
      </ColumnA003>
      <ColumnA004>×</ColumnA004>
      <ColumnA005>
        <Text>
          <Mark>△</Mark>自社教材のみ
        </Text>
      </ColumnA005>
      <ColumnA006>
        <Text>
          <Mark>△</Mark>対応可能な
          <br />
          講師がいれば
        </Text>
      </ColumnA006>
      <ColumnA007>
        <Text>
          <Mark>△</Mark>10教材
        </Text>
      </ColumnA007>
      <ColumnA008>
        <Text>
          <Mark>△</Mark>3個
        </Text>
      </ColumnA008>
      <ColumnA009>
        <Text>
          <Mark>△</Mark>7種類
        </Text>
      </ColumnA009>
      <ColumnA010>×</ColumnA010>
      <ColumnB001>
        <Title>B社</Title>
      </ColumnB001>
      <ColumnB002>
        <Text>
          <SmallMonth>月額</SmallMonth>
          <Num>265,680</Num>
          <SmallUnit>円</SmallUnit>
        </Text>
      </ColumnB002>
      <ColumnB003>
        <Text>
          <Num>30,000</Num>
          <SmallUnit>円</SmallUnit>
        </Text>
      </ColumnB003>
      <ColumnB004>×</ColumnB004>
      <ColumnB005>
        <Text>
          <Mark>△</Mark>自社教材のみ
        </Text>
      </ColumnB005>
      <ColumnB006>
        <Text>
          <Mark>◎</Mark>質問し放題
        </Text>
      </ColumnB006>
      <ColumnB007>
        <Text>
          <Mark>○</Mark>15教材
        </Text>
      </ColumnB007>
      <ColumnB008>
        <Text>
          <Mark>△</Mark>1個
        </Text>
      </ColumnB008>
      <ColumnB009>
        <Text>
          <Mark>△</Mark>7種類
        </Text>
      </ColumnB009>
      <ColumnB010>×</ColumnB010>
      <ColumnC001>
        <Title>C社</Title>
      </ColumnC001>
      <ColumnC002>
        <Text>
          <SmallMonth>月額</SmallMonth>
          <Num>120,211</Num>
          <SmallUnit>円</SmallUnit>
        </Text>
      </ColumnC002>
      <ColumnC003>
        <Text>
          <Num>30,000</Num>
          <SmallUnit>円</SmallUnit>
        </Text>
      </ColumnC003>
      <ColumnC004>◎</ColumnC004>
      <ColumnC005>
        <Text>
          <Mark>△</Mark>自社教材のみ
        </Text>
      </ColumnC005>
      <ColumnC006>
        <Text>
          <Mark>△</Mark>追加料金必要
        </Text>
      </ColumnC006>
      <ColumnC007>
        <Text>
          <Mark>△</Mark>5教材
        </Text>
      </ColumnC007>
      <ColumnC008>
        <Text>
          <Mark>△</Mark>3個
        </Text>
      </ColumnC008>
      <ColumnC009>
        <Text>
          <Mark>△</Mark>6種類
        </Text>
      </ColumnC009>
      <ColumnC010>×</ColumnC010>
    </Table>
  );
};

const Table = styled.div`
  display: grid;
  grid-template-rows: 80px 80px 80px 100px 100px 100px 100px 80px 80px 80px;
  grid-template-columns: 214px 274px 1fr 1fr 1fr;
  margin: 5rem auto 0;
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);

  ${media.lessThan('large')`
    width: 1056px;
    margin: 0;
  `}
  ${media.lessThan('medium')`
    width: 768px;
    grid-template-columns: 150px 200px 1fr 1fr 1fr;
  `}
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  text-align: center;

  ${media.lessThan('medium')`
    padding: 0;
    font-size: 0.875rem;
  `}
`;

const RowTitle = styled(Item)`
  grid-column: 1 / 2;
  justify-content: flex-start;
  border-top: 1px solid #e5e5e5;

  &:nth-of-type(2n - 1) {
    background: #f5f5f5;
  }

  ${media.lessThan('medium')`
    padding: 0 0.75rem;
  `}
`;
const RowTitle001 = styled(RowTitle)`
  grid-row: 1 / 2;
  border-radius: 1rem 0 0 0;
  border-top: none;
`;
const RowTitle002 = styled(RowTitle)`
  grid-row: 2 / 3;
`;
const RowTitle003 = styled(RowTitle)`
  grid-row: 3 / 4;
`;
const RowTitle004 = styled(RowTitle)`
  grid-row: 4 / 5;
`;
const RowTitle005 = styled(RowTitle)`
  grid-row: 5 / 6;
`;
const RowTitle006 = styled(RowTitle)`
  grid-row: 6 / 7;
`;
const RowTitle007 = styled(RowTitle)`
  grid-row: 7 / 8;
`;
const RowTitle008 = styled(RowTitle)`
  grid-row: 8 / 9;
`;
const RowTitle009 = styled(RowTitle)`
  grid-row: 9 / 10;
`;
const RowTitle010 = styled(RowTitle)`
  grid-row: 10 / 11;
`;

const PickupColumn = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 11;
  display: grid;
  grid-template-rows: 80px 80px 80px 100px 100px 100px 100px 80px 80px 80px;
  grid-template-columns: 1fr;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
`;
const PickUpCell = styled(Item)`
  grid-column: 1 / 2;
  background: #fff3f3;
  border: 1px solid #e5e5e5;
  border-bottom: none;

  &:nth-of-type(2n - 1) {
    background: #ffe8e7;
  }
`;
const PickUpCell001 = styled(PickUpCell)`
  grid-row: 1 / 2;
  margin-top: -0.75rem;
`;
const PickUpCell002 = styled(PickUpCell)`
  grid-row: 2 / 3;
`;
const PickUpCell003 = styled(PickUpCell)`
  grid-row: 3 / 4;
  color: #e2001b;
  font-size: 1.25rem;
  font-weight: 700;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;
const PickUpCell004 = styled(PickUpCell)`
  grid-row: 4 / 5;
  color: #e2001b;
  font-size: 1.25rem;
  font-weight: 700;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;
const PickUpCell005 = styled(PickUpCell)`
  grid-row: 5 / 6;
`;
const PickUpCell006 = styled(PickUpCell)`
  grid-row: 6 / 7;
`;
const PickUpCell007 = styled(PickUpCell)`
  grid-row: 7 / 8;
`;
const PickUpCell008 = styled(PickUpCell)`
  grid-row: 8 / 9;
`;
const PickUpCell009 = styled(PickUpCell)`
  grid-row: 9 / 10;
`;
const PickUpCell010 = styled(PickUpCell)`
  grid-row: 10 / 11;
`;
const PickupPrice = styled.p`
  color: #e2001b;
  text-align: center;
`;
const PickupText = styled.p`
  margin-top: 0.5rem;
  color: #e2001b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;

  ${media.lessThan('medium')`
    font-size: 0.875rem;
  `}
`;

const NormalCell = styled(Item)``;

const ColumnA = styled(NormalCell)`
  grid-column: 3 / 4;
  border-top: 1px solid #e5e5e5;
`;
const ColumnA001 = styled(ColumnA)`
  grid-row: 1 / 2;
  border-top: none;
  background: #f5f5f5;
`;
const ColumnA002 = styled(ColumnA)`
  grid-row: 2 / 3;
`;
const ColumnA003 = styled(ColumnA)`
  grid-row: 3 / 4;
  background: #f5f5f5;
`;
const ColumnA004 = styled(ColumnA)`
  grid-row: 4 / 5;
`;
const ColumnA005 = styled(ColumnA)`
  grid-row: 5 / 6;
  background: #f5f5f5;
`;
const ColumnA006 = styled(ColumnA)`
  grid-row: 6 / 7;
`;
const ColumnA007 = styled(ColumnA)`
  grid-row: 7 / 8;
  background: #f5f5f5;
`;
const ColumnA008 = styled(ColumnA)`
  grid-row: 8 / 9;
`;
const ColumnA009 = styled(ColumnA)`
  grid-row: 9 / 10;
  background: #f5f5f5;
`;
const ColumnA010 = styled(ColumnA)`
  grid-row: 10 / 11;
`;

const ColumnB = styled(NormalCell)`
  grid-column: 4 / 5;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
`;
const ColumnB001 = styled(ColumnB)`
  grid-row: 1 / 2;
  border-top: none;
  background: #f5f5f5;
`;
const ColumnB002 = styled(ColumnB)`
  grid-row: 2 / 3;
`;
const ColumnB003 = styled(ColumnB)`
  grid-row: 3 / 4;
  background: #f5f5f5;
`;
const ColumnB004 = styled(ColumnB)`
  grid-row: 4 / 5;
`;
const ColumnB005 = styled(ColumnB)`
  grid-row: 5 / 6;
  background: #f5f5f5;
`;
const ColumnB006 = styled(ColumnB)`
  grid-row: 6 / 7;
`;
const ColumnB007 = styled(ColumnB)`
  grid-row: 7 / 8;
  background: #f5f5f5;
`;
const ColumnB008 = styled(ColumnB)`
  grid-row: 8 / 9;
`;
const ColumnB009 = styled(ColumnB)`
  grid-row: 9 / 10;
  background: #f5f5f5;
`;
const ColumnB010 = styled(ColumnB)`
  grid-row: 10 / 11;
`;

const ColumnC = styled(NormalCell)`
  grid-column: 5 / 6;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
`;
const ColumnC001 = styled(ColumnC)`
  grid-row: 1 / 2;
  border-top: none;
  border-radius: 0 1rem 0 0;
  background: #f5f5f5;
`;
const ColumnC002 = styled(ColumnC)`
  grid-row: 2 / 3;
`;
const ColumnC003 = styled(ColumnC)`
  grid-row: 3 / 4;
  background: #f5f5f5;
`;
const ColumnC004 = styled(ColumnC)`
  grid-row: 4 / 5;
`;
const ColumnC005 = styled(ColumnC)`
  grid-row: 5 / 6;
  background: #f5f5f5;
`;
const ColumnC006 = styled(ColumnC)`
  grid-row: 6 / 7;
`;
const ColumnC007 = styled(ColumnC)`
  grid-row: 7 / 8;
  background: #f5f5f5;
`;
const ColumnC008 = styled(ColumnC)`
  grid-row: 8 / 9;
`;
const ColumnC009 = styled(ColumnC)`
  grid-row: 9 / 10;
  background: #f5f5f5;
`;
const ColumnC010 = styled(ColumnC)`
  grid-row: 10 / 11;
`;

const Title = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;

  ${media.lessThan('medium')`
    font-size: 0.75rem;
  `}
`;
const Img = styled.img`
  display: block;
  max-width: 164px;

  ${media.lessThan('medium')`
    max-width: 130px;
  `}
`;
const Circle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  border: 2px solid #e2001b;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 0.875rem;
    height: 0.875rem;
    margin: auto;
    border: 2px solid #e2001b;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  ${media.lessThan('small')`
    width: 1.25rem;
    height: 1.25rem;
  `}
`;
const Small = styled.span`
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1;

  ${media.lessThan('medium')`
    font-size: 0.625rem;
  `}
`;
const SmallMonth = styled(Small)`
  margin-right: 0.25rem;
`;
const SmallUnit = styled(Small)`
  margin-left: 0.25rem;
`;
const Num = styled.span`
  font-size: 1.25rem;
  line-height: 1;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;
const PickupNum = styled(Num)`
  font-weight: 700;
`;
const Text = styled.p`
  font-size: 1rem;

  ${media.lessThan('medium')`
    font-size: 0.875rem;
  `}
`;
const Mark = styled.span`
  display: block;
  margin-bottom: 0.75rem;
`;
