import React from 'react';
import styled from 'styled-components';

interface FormButtonProps {
  children: string | React.ReactChild;
  isActive: boolean;
  // NOTE: isActiveに寄せても良いように思うが、 既存の利用箇所に影響が出ないように分けておく
  // (現状ではisActive=falseでも押せるようになっているので)
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}

export const FormButton: React.FC<FormButtonProps> = (props) => {
  return (
    <StyledButton
      className={props.className}
      isActive={props.isActive}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ isActive: boolean }>`
  appearance: none;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 2px;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'default')};
  background-color: ${(props) => (props.isActive ? '#e2001b' : 'rgba(0, 0, 0, 0.36)')};
  color: ${(props) => (props.isActive ? '#ffffff' : 'rgba(0, 0, 0, 0.36)')};
`;
