import React, { useEffect, useRef } from 'react';

interface CvGmoProps {
  userID: number;
}

export const CvGmo: React.FC<CvGmoProps> = (props) => {
  // こちらのタスクで依頼 Issue 14753: CvGmo.tsxタグの差し替え
  // タグ一覧(https://docs.google.com/spreadsheets/d/1zE7MSsf4DhM8NpvbQBElisoON9-Sm5Ldw5CVkprGGUY/edit#gid=2139083905)

  const scriptContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = scriptContainerRef.current;
    const script = document.createElement('script');
    script.src = '/CvGmo.js';

    if (container) {
      window.localStorage.setItem('cv_user_id', props.userID.toString());
      container.appendChild(script);
    }

    return () => {
      if (container) {
        container.removeChild(script);
      }
    };
  }, [props.userID]);

  return <div ref={scriptContainerRef}></div>;
};
