import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  size?: string;
  className?: string;
}

export const PageWrapper: React.FC<Props> = (props) => {
  return (
    <Wrapper className={props.className} size={props.size}>
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ size?: string }>`
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
  ${(props) =>
    props.size &&
    props.size === 'narrow' &&
    `max-width: 892px; padding: 0;
`}

  ${media.lessThan('medium')`
    padding: 1.5rem 1rem;
  `}
`;
