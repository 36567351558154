import React, { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useUser } from '../../redux/user/useUser';

import LockIcon from '../../static/image/icon_lock_gray.svg';
import greyCheckIcon from '../../static/image/icon_grey_check.svg';
import bookIcon from '../../static/image/icon_book.svg';
import circleIcon from '../../static/image/icon_circle_white.svg';
import { getMedalImage } from '../../utils/ProgramExam';
import { ChapterFragment as Chapter, StudyLogUnderstandingLevel } from '../../gen/graphql';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { checkChapterAuthType, getChapterFunctionType } from '../../utils/Program';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';

interface ChapterCardProps {
  chapter: Chapter;
  programID: number;
}

export const ChapterCard: React.FC<ChapterCardProps> = ({ chapter, programID }) => {
  const { permissionCheck } = useUser();
  const permissionExamRead = permissionCheck(FunctionType.Exam, PermissionType.Read);

  const { openModal } = useCommonModal();

  const isChapterViewable = useMemo(
    () => checkChapterAuthType(chapter, permissionCheck),
    [chapter, permissionCheck],
  );

  const maxScore = useMemo(
    () => chapter.examScores?.reduce((acc, cur) => (acc.score > cur.score ? acc : cur)),
    [chapter.examScores],
  );

  const checkShowDetail = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const functionType = getChapterFunctionType(chapter.type);
      if (!functionType) {
        e.preventDefault();
        return;
      }
      if (openModal(functionType, PermissionType.Read)) e.preventDefault();
    },
    [openModal, chapter.type],
  );

  const navigate = useNavigate();

  return (
    <Card>
      <StyledLink
        to={`/programs/${programID}/chapters/${chapter.id}`}
        key={chapter.id}
        onClick={checkShowDetail}
      >
        <Info>
          <div className="titles">
            {isChapterViewable ? (
              <ChapterArea>
                <ChapterNo>{chapter.number}</ChapterNo>
              </ChapterArea>
            ) : (
              <LimitChapterArea>
                <LimitIcon src={LockIcon} alt="icon" />
                <LimitChapterNo>{chapter.number}</LimitChapterNo>
              </LimitChapterArea>
            )}
            <div className="info">
              <Title>
                {chapter.title}
                <span>({chapter.requireTime}分)</span>
              </Title>
              <Description>{chapter.description}</Description>
            </div>
          </div>
          {permissionExamRead && (
            <Scores>
              {chapter.examQuestions?.length && (
                <ExamScore
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(`/programs/${programID}/chapters/${chapter.id}/exam`);
                  }}
                >
                  {maxScore ? (
                    <>
                      <img src={getMedalImage(maxScore.medalType)} alt="メダル" />
                      <p className="score">{maxScore.score}点</p>
                    </>
                  ) : (
                    <p className="action">実施する</p>
                  )}
                </ExamScore>
              )}
              <StudyLog>
                {chapter.studyLog ? (
                  chapter.studyLog.understandingLevel === StudyLogUnderstandingLevel.High ? (
                    <Button className="understood">
                      <img src={circleIcon} />
                      理解
                    </Button>
                  ) : (
                    <Button className="read">
                      <img src={greyCheckIcon} />
                      読了
                    </Button>
                  )
                ) : (
                  <Button className="unread">
                    <img src={bookIcon} />
                    未読
                  </Button>
                )}
              </StudyLog>
            </Scores>
          )}
        </Info>
      </StyledLink>
    </Card>
  );
};

const Card = styled.li`
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
const StyledLink = styled(Link)`
  display: block;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  background: #fff;
  transition: all 0.2s;

  &:hover {
    background: #f5f5f5;
  }
`;
const Info = styled.div`
  display: flex;
  align-items: center;

  .titles {
    display: flex;
    align-items: center;
    flex-grow: 1;

    ${media.lessThan('medium')`
      align-items: start;
    `}
  }

  .info {
    flex: 1;
    box-sizing: border-box;
    padding-right: 1rem;
  }

  ${media.lessThan('medium')`
    display: block;
    padding-right: 0;
  `}
`;
const ChapterArea = styled.div`
  display: flex;
  margin: 0 22px 0 0;
`;
const LimitChapterArea = styled(ChapterArea)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;
const LimitIcon = styled.img`
  display: flex;
  width: 1rem;
  height: 1rem;
`;
const LimitChapterNo = styled.p`
  display: flex;
  color: #575e71;

  font-size: 1.125rem;
  font-weight: 500;
`;
const ChapterNo = styled(LimitChapterNo)`
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #f5f5f5;
`;
const Title = styled.h4`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;

  span {
    margin-left: 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
  }
`;
const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8125rem;
  line-height: 1rem;
  word-break: break-all;

  ${media.lessThan('medium')`
    font-size: 0.75rem;
  `}
`;
const Scores = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    justify-content: flex-end;
    margin-top: 12px;
  `}
`;
const ExamScore = styled.div`
  display: flex;
  align-items: center;
  width: 78px;
  min-width: 78px;
  padding: 0.25rem 0.5rem;
  align-self: flex-start;
  background: #f8f8f8;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  img {
    height: 22px;
  }
  p {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    flex-grow: 1;
    &.action {
      font-size: 0.75rem;
      line-height: 1.375rem;
    }
  }
  &:hover {
    background: #e8e8e8;
  }
`;
const StudyLog = styled.div`
  width: 76px;
  min-width: 76px;
  text-align: center;
  align-self: flex-start;
  margin-left: 8px;

  ${media.lessThan('medium')`
    width: 90px;
  `}
`;
const Button = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.875rem;
  border-radius: 0.1875rem;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.6875rem;
  line-height: 0.875rem;

  .exam {
    width: 78px;
  }

  img {
    margin-right: 0.625rem;
  }

  &.read {
    background: #e95252;
  }

  &.understood {
    background: #4bb89e;
  }

  &.unread {
    background: #575e71;
  }

  ${media.lessThan('medium')`
    padding: 0.5rem 1.375rem;
  `}
`;
