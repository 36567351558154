import React, { SyntheticEvent, useCallback, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import * as Sentry from '@sentry/react';

import { UserIcon } from './UserIcon';
import { useUser } from '../../redux/user/useUser';
import { ProgramCardLink } from '../molecules/ProgramCardLink';
import { LanguageCard } from '../molecules/LanguageCard';
import { TimelineCommentInput } from './TimelineCommentInput';
import { useToastsContext } from '../../context/ToastsProvider';
import { TimelineControl } from '../molecules/TimelineControl';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { LinkifyText } from '../atoms/LinkifyText';
import {
  useDeleteStudyReportMutation,
  useCreateStudyReportFavoriteMutation,
  useDeleteStudyReportFavoriteMutation,
  useCreateStudyReportCommentMutation,
  useDeleteStudyReportCommentMutation,
  TimelineStudyReportFragment,
} from '../../gen/graphql';
import { format, parseISO } from 'date-fns';
import { defaultErrorMessage } from '../../const/ErrorMessage';
import { formatReportTime } from '../../utils/StudyReport';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { LikeButtonContent } from '../molecules/LikeButtonContent';

interface StudyReportCardProps {
  studyReport: TimelineStudyReportFragment;
  refetch: () => Promise<void>;
}

const MAX_TEXT_LENGTH = 200;
const MAX_COMMENT_COUNT = 2;

export const StudyReportCard: React.FC<StudyReportCardProps> = ({
  studyReport,
  refetch,
}): JSX.Element => {
  const { user, permissionCheck } = useUser();
  const { openModal } = useCommonModal();
  const { showToast } = useToastsContext();

  const [deleteStudyReport] = useDeleteStudyReportMutation();
  const [createStudyReportFavorite] = useCreateStudyReportFavoriteMutation();
  const [deleteStudyReportFavorite] = useDeleteStudyReportFavoriteMutation();
  const [createStudyReportComment] = useCreateStudyReportCommentMutation();
  const [deleteStudyReportComment] = useDeleteStudyReportCommentMutation();

  // コメント・いいねを追加・削除した時にタイムライン全体の再読み込みはしたくないので、stateで管理する
  const [comments, setComments] = useState(studyReport.studyReportComments ?? []);
  const [favorites, setFavorites] = useState(studyReport.studyReportFavorites ?? []);

  const [isDetailOpen, setIsDetailOpen] = useState(studyReport.content.length < MAX_TEXT_LENGTH);
  const [isCommentInputShow, setIsCommentInputShow] = useState(false);

  const [isHover, setIsHover] = useState(false);
  const [hoverCommentId, setHoverCommentId] = useState<number | null>(null);

  const deleting = useRef(false);
  const sendingComment = useRef(false);
  const sendingFavorite = useRef(false);

  const handleClickCommentToggle = useCallback(
    (e: SyntheticEvent): void => {
      // 詳細画面へのリンクを無効化
      e.preventDefault();

      if (openModal(FunctionType.StudyReportComment, PermissionType.Create)) return;
      setIsCommentInputShow((prev) => !prev);
    },
    [openModal],
  );

  const handleClickReadMore = (e: SyntheticEvent): void => {
    // 詳細画面へのリンクを無効化
    e.preventDefault();
    setIsDetailOpen(true);
  };

  const handleDelete = useCallback(async (): Promise<void> => {
    // 2度押し制御
    if (deleting.current) {
      return;
    }

    if (!window.confirm('本当に削除しますか？')) {
      return;
    }

    deleting.current = true;

    try {
      await deleteStudyReport({
        variables: {
          id: studyReport.studyReportID,
        },
      });
    } catch (e) {
      showToast(1, defaultErrorMessage);
      return;
    } finally {
      deleting.current = false;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyReport.studyReportID]);

  const handleClickAddComment = useCallback(
    async (content: string): Promise<void> => {
      // 2度押し制御
      if (sendingComment.current) {
        return;
      }
      sendingComment.current = true;

      try {
        const data = await createStudyReportComment({
          variables: {
            studyReportID: studyReport.studyReportID,
            input: {
              content: content,
            },
          },
        });
        // 投稿したコメントを先頭に追加
        data.data?.createStudyReportComment &&
          setComments([data.data.createStudyReportComment, ...comments]);
      } catch (e) {
        showToast(1, defaultErrorMessage);
      } finally {
        sendingComment.current = false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comments, studyReport.studyReportID],
  );

  const handleDeleteComment = useCallback(
    async (studyReportCommentId: number): Promise<void> => {
      // 2度押し制御
      if (sendingComment.current) {
        return;
      }

      if (!window.confirm('本当に削除しますか？')) {
        return;
      }

      sendingComment.current = true;

      try {
        await deleteStudyReportComment({
          variables: {
            id: studyReportCommentId,
          },
        });
      } catch (e) {
        showToast(1, defaultErrorMessage);
        return;
      } finally {
        sendingComment.current = false;
      }
      setComments(comments.filter((c) => c.id !== studyReportCommentId));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comments, studyReport.studyReportID],
  );

  const addFavorite = useCallback(async (): Promise<void> => {
    const studyReportFavoriteData = await createStudyReportFavorite({
      variables: {
        studyReportID: studyReport.studyReportID,
      },
    });

    studyReportFavoriteData.data?.createStudyReportFavorite &&
      setFavorites([...favorites, studyReportFavoriteData.data.createStudyReportFavorite]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites, studyReport.studyReportID]);

  const deleteFavorite = useCallback(async (): Promise<void> => {
    await deleteStudyReportFavorite({
      variables: {
        studyReportID: studyReport.studyReportID,
      },
    });
    setFavorites(favorites.filter((f) => f.userID !== user.lmsUser?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites, studyReport.studyReportID, user.lmsUser?.id]);

  const handleClickFavorite = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      // 詳細画面へのリンクを無効化
      e.preventDefault();

      if (openModal(FunctionType.StudyReportFavorite, PermissionType.Update)) return;

      // 2度押し制御
      if (sendingFavorite.current) {
        return;
      }
      sendingFavorite.current = true;

      try {
        // いいねのリストに自分のものが含まれてたら削除、そうでなければ追加
        if (favorites.find((f) => f.userID === user.lmsUser?.id)) {
          await deleteFavorite();
        } else {
          await addFavorite();
        }
      } catch (e) {
        Sentry.captureException(e);
        showToast(1, defaultErrorMessage);
      } finally {
        sendingFavorite.current = false;
      }
    },
    [addFavorite, deleteFavorite, openModal, favorites, user.lmsUser?.id, showToast],
  );

  useEffect(() => {
    setFavorites(studyReport.studyReportFavorites ?? []);
  }, [studyReport.studyReportFavorites]);

  return (
    <Container>
      <Post onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
        {studyReport.user.id === user.lmsUser?.id &&
          permissionCheck(FunctionType.StudyReport, PermissionType.Delete) && (
            <TimelineControl isVisible={isHover} onDelete={handleDelete} />
          )}
        <UserIcon user={studyReport.user} date={studyReport.createdAt} huge />
        <PostContents>
          <PostText>
            <LinkifyText options={{ target: '_blank' }}>
              {isDetailOpen
                ? studyReport.content
                : `${studyReport.content.substr(0, MAX_TEXT_LENGTH)}...`}
            </LinkifyText>
          </PostText>
          {!isDetailOpen && <ReadMore onClick={handleClickReadMore}>続きをみる</ReadMore>}
          <Card>
            {studyReport.studyReportDetails.map((studyReportDetail) => {
              return studyReportDetail.program ? (
                <ProgramCardLink
                  key={studyReportDetail.program.id}
                  id={studyReportDetail.program.id}
                  title={studyReportDetail.program.title}
                  thumbnail={studyReportDetail.program.thumbnail}
                  icon={studyReportDetail.program.icon}
                  repository={studyReportDetail.program.repository}
                  time={studyReportDetail.time ?? 0}
                />
              ) : (
                studyReportDetail.tag && (
                  <LanguageCard
                    key={studyReportDetail.tag.id}
                    id={studyReportDetail.tag.id}
                    icon={studyReportDetail.tag.iconImage ?? ''}
                    name={studyReportDetail.tag.name}
                    time={studyReportDetail.time ?? 0}
                  />
                )
              );
            })}
          </Card>
        </PostContents>
      </Post>

      <Bottom>
        <Command>
          <CommentButton data-e2e="studylogComment" onClick={handleClickCommentToggle}>
            {isCommentInputShow ? (
              <>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
                    fill="#F1B007"
                    fillOpacity="0.08"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.857 19.7143C15.1726 19.7143 15.4285 19.9701 15.4285 20.2857V22.5233C18.5699 20.6884 20.4599 19.3904 21.5998 18.2571C22.2613 17.5994 22.6251 17.0385 22.8365 16.5037C23.0489 15.966 23.1428 15.3651 23.1428 14.5714C23.1428 11.7311 20.8402 9.42857 17.9999 9.42857H13.9999C11.1596 9.42857 8.85704 11.7311 8.85704 14.5714C8.85704 17.4117 11.1596 19.7143 13.9999 19.7143H14.857ZM15.4285 24.1747C23.0446 19.7917 24.5713 17.9965 24.5713 14.5714C24.5713 10.9421 21.6292 8 17.9999 8H13.9999C10.3706 8 7.42847 10.9421 7.42847 14.5714C7.42847 18.2007 10.3706 21.1429 13.9999 21.1429V24.0098C13.9999 24.447 14.4707 24.7221 14.8505 24.5056C15.047 24.3937 15.2396 24.2834 15.4285 24.1747Z"
                    fill="#F1B007"
                  />
                </svg>
                <ClickCommentStyle>コメント {comments.length}件</ClickCommentStyle>
              </>
            ) : (
              <>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8572 19.7143C15.1728 19.7143 15.4286 19.9701 15.4286 20.2857V22.5233C18.57 20.6884 20.4601 19.3904 21.5999 18.2571C22.2614 17.5994 22.6253 17.0385 22.8366 16.5037C23.0491 15.966 23.1429 15.3651 23.1429 14.5714C23.1429 11.7311 20.8403 9.42857 18 9.42857H14C11.1597 9.42857 8.85716 11.7311 8.85716 14.5714C8.85716 17.4117 11.1597 19.7143 14 19.7143H14.8572ZM15.4286 24.1747C23.0447 19.7917 24.5714 17.9965 24.5714 14.5714C24.5714 10.9421 21.6293 8 18 8H14C10.3707 8 7.42859 10.9421 7.42859 14.5714C7.42859 18.2007 10.3707 21.1429 14 21.1429V24.0098C14 24.447 14.4708 24.7221 14.8506 24.5056C15.0471 24.3937 15.2397 24.2834 15.4286 24.1747Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                </svg>
                <NormalStyle>コメント {comments.length}件</NormalStyle>
              </>
            )}
          </CommentButton>
          <LikeButton data-e2e="studylogLike" onClick={handleClickFavorite}>
            <LikeButtonContent
              isTappedHearts={!!favorites.find((f) => f.userID === user.lmsUser?.id)}
              tappedHeartCount={favorites.length}
            />
          </LikeButton>
        </Command>
        <Summary>
          <p className="item">
            {`学習日 : ${format(parseISO(studyReport.date), 'yyyy年MM月dd日')}`}
          </p>
          <p className="item">
            {`総学習時間 : ${formatReportTime(
              studyReport.user.studySummary?.studyReportTime ?? 0,
            )}`}
          </p>
        </Summary>
      </Bottom>
      {user.lmsUser && permissionCheck(FunctionType.StudyReportComment, PermissionType.Create) && (
        <TimelineCommentInput
          user={user.lmsUser}
          isShow={isCommentInputShow}
          onSubmit={handleClickAddComment}
        />
      )}
      <Comments>
        {comments.slice(0, MAX_COMMENT_COUNT).map((c) => (
          <Comment
            key={c.id}
            onMouseEnter={() => setHoverCommentId(c.id)}
            onMouseLeave={() => setHoverCommentId(null)}
          >
            {c.user.id === user.lmsUser?.id &&
              permissionCheck(FunctionType.StudyReportComment, PermissionType.Delete) && (
                <TimelineControl
                  isVisible={c.id === hoverCommentId}
                  onDelete={() => handleDeleteComment(c.id)}
                />
              )}
            <UserIcon user={c.user} date={c.createdAt} large />
            <CommentContents>{c.content}</CommentContents>
          </Comment>
        ))}
      </Comments>
      {comments.length > MAX_COMMENT_COUNT && (
        <ReadMoreComments>
          <p>他{comments.length - MAX_COMMENT_COUNT}件のコメントを表示</p>
        </ReadMoreComments>
      )}
    </Container>
  );
};

const Container = styled.article`
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Post = styled.section`
  position: relative;
  padding: 16px;

  ${media.lessThan('medium')`
    padding: 12px;
  `}
`;

const PostContents = styled.section`
  padding-left: 56px;
  margin-top: 12px;

  ${media.lessThan('medium')`
    padding-left: 0;
  `}
`;

const PostText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const ReadMore = styled.p`
  color: #eb0000;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 0.5rem;
  cursor: pointer;
`;

const Card = styled.div`
  margin-top: 1rem;

  & > * + * {
    margin-top: 0.5rem;
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
  padding: 16px;
  padding-top: 0;

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: 0;
  `}
`;

const Command = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 60px;

  ${media.lessThan('medium')`
    padding-left: 0;
  `}

  p {
    display: flex;
    align-items: center;
    min-width: 8rem;

    & + p {
      margin-left: 1rem;

      ${media.lessThan('medium')`
        margin-left: 0.5rem;
      `}
    }

    span {
      margin-left: 0.25rem;
      font-size: 0.875rem;
      letter-spacing: -0.2px;

      ${media.lessThan('medium')`
        margin-left: 0;
      `}
    }
  }
`;
const CommentButton = styled.p`
  cursor: pointer;
`;
const LikeButton = styled.p`
  cursor: pointer;
`;

const NormalStyle = styled.span`
  color: rgba(0, 0, 0, 0.6);
`;

const ClickCommentStyle = styled.span`
  color: rgba(241, 176, 7, 1);
`;

const Comments = styled.section``;

const Comment = styled.div`
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px;
  padding-left: 24px;
`;

const CommentContents = styled.p`
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.5;
  padding-left: 47px;
  word-wrap: break-word;
  ${media.lessThan('medium')`
    padding-left: 0;
  `}
`;

const ReadMoreComments = styled.div`
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  p {
    display: inline-block;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    cursor: pointer;
  }
`;

const Summary = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0.625rem;
  `}

  .item {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
    letter-spacing: -0.2px;
    &:nth-child(n + 2) {
      margin-left: 0.5rem;
    }
    ${media.lessThan('medium')`
      font-size: 0.75rem;
    `}
  }
`;
