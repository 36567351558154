import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';

import IconClose from '../../static/image/close.svg';
import IconProgram from '../../static/image/register_modal/icon_light_program.svg';
import IconLesson from '../../static/image/register_modal/icon_light_lesson.svg';
import IconQa from '../../static/image/register_modal/icon_light_qa.svg';
import Wave from '../../static/image/wave_yellow.svg';

import { TEAM_TAX_INCLUDED_LOCALED_PRICE } from '../../const';
import { Z_INDEX_MODAL } from '../../const/ZIndex';

const useModal = (contentRef: React.RefObject<HTMLElement>, callback: () => void) => {
  const handleClick = (e: Event) => {
    if (contentRef.current && contentRef.current.contains(e.target as Node)) return;

    callback();
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RegisterTeamSubscriptionUserModal: React.FC<ModalProps> = (props) => {
  const contentRef = React.useRef<HTMLDivElement>(null);

  useModal(contentRef, () => {
    props.onClose();
  });

  return props.isOpen ? (
    <Modal onClick={props.onClose}>
      <Container ref={contentRef}>
        <Close onClick={props.onClose}>
          <img src={IconClose} alt="close" />
        </Close>
        <TtlArea>
          <h1>現在ご利用いただけません。</h1>
          <h2>有料機能を使用するにあたり、決済が必要です。</h2>
          <p>決済が完了すると、こちらの機能がご利用いただけます。</p>
        </TtlArea>
        <Tiles>
          <li>
            <img src={IconProgram} />
            <div>
              <h3>
                全教材
                <br className="pc" />
                利用可能
              </h3>
            </div>
          </li>
          <li>
            <img src={IconLesson} />
            <div>
              <h3>
                現役エンジニアとの
                <br />
                オンラインレッスン
              </h3>
            </div>
          </li>
          <li>
            <img src={IconQa} />
            <div>
              <h3>
                質問掲示板は
                <br />
                プロが即座に回答
              </h3>
            </div>
          </li>
        </Tiles>
        <LinkTtl>
          <span className="price">
            月額<span className="num">{TEAM_TAX_INCLUDED_LOCALED_PRICE}</span>円〜
            <span className="tax">(税込)</span>
          </span>
          <br />
          誰もが挫折しないプログラミング学習を
        </LinkTtl>
        <ButtonLink to="/team/subscription?cid=lms_modal">プラン状況を確認する</ButtonLink>
      </Container>
    </Modal>
  ) : (
    <React.Fragment />
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: ${Z_INDEX_MODAL};
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
`;
const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 680px;
  padding: 3.5rem 1.5rem 2rem;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  text-align: center;

  ${media.lessThan('medium')`
    width: 100%;
    padding: 3rem 1rem 2rem;
  `}
`;
const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1.5rem;

  ${media.lessThan('medium')`
    right: 1rem;
  `}
`;
const TtlArea = styled.div`
  color: rgba(0, 0, 0, 0.87);

  h1 {
    color: #c08c05;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.04em;
  }

  h2 {
    margin-top: 0.25rem;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5;
  }

  p {
    margin-top: 0.5rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  ${media.lessThan('medium')`
    h1 {
      font-size: 1.125rem;
    }

    h2 {
      margin-top: .5rem;
      font-size: .875rem;
    }

    p {
      margin-top: 1rem;
      font-size: 0.875rem;
    }
  `}
`;
const Tiles = styled.ul`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  li {
    flex: 1;
    padding: 0.875rem 0.5rem 2rem;
    background: #fff url(${Wave}) center top / contain no-repeat;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

    h3 {
      margin-top: 1.75rem;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.5rem;
    }
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: .5rem;
    margin: 1.5rem auto 0;
    max-width: 320px;

    li {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: .75rem 1rem;
      background: #fff;
      box-shadow: none;

      img {
        width: 3rem;
      }

      div {
        flex: 1;
      }

      h3 {
        margin-top: 0;

        .pc {
          display: none;
        }
      }
    }
  `}
`;
const LinkTtl = styled.p`
  margin-top: 2rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.75rem;
  text-align: center;

  .price {
    font-size: 1.125rem;

    .num {
      color: #eb0000;
      font-size: 2rem;
    }

    .tax {
      font-size: 0.75rem;
    }
  }

  ${media.lessThan('medium')`
    line-height: 1.5rem;
  `}

  ${media.lessThan('small')`
  `}
`;
const ButtonLink = styled(Link)`
  display: block;
  max-width: 300px;
  margin: 2rem auto 0;
  padding: 0.75rem 0;
  background: #e2001b;
  border-radius: 0.1875rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
`;
