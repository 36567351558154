import React, { useEffect } from 'react';

interface CvLinkAProps {
  userID: number;
}

export const CvLinkA: React.FC<CvLinkAProps> = (props) => {
  // こちらのタスクで依頼 https://samurai-7s.backlog.com/view/LMS-1420
  // タグ一覧(https://docs.google.com/spreadsheets/d/1zE7MSsf4DhM8NpvbQBElisoON9-Sm5Ldw5CVkprGGUY/edit#gid=2139083905)

  useEffect(() => {
    const h = document.getElementsByTagName('head')[0] as HTMLElement;

    const s1 = document.createElement('script');
    s1.id = 'linka';
    s1.src = 'https://link-ag.net/dist/p/c/index.js';
    s1.async = true;
    h.appendChild(s1);

    window.lag =
      window.lag ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (window.lag.q = window.lag.q || []).push(arguments);
      };
    window.lag.l = +new Date();
    window.lag({
      acd: '85c7a75f14767665',
      cs: '1',
      ucd: props.userID,
    });

    window.lag =
      window.lag ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (window.lag.q = window.lag.q || []).push(arguments);
      };
    window.lag.l = +new Date();
    window.lag({
      acd: '85c7a75f14767665',
      cs: '2',
      ucd: props.userID,
    });

    return () => {
      document.getElementById('linka')?.remove();
    };
  }, [props.userID]);

  return null;
};
