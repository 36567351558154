/* tslint:disable */
/* eslint-disable */
/**
 * LMS API
 * This is LMS API Documentation.
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ExamScore
 */
export interface ExamScore {
    /**
     * 
     * @type {string}
     * @memberof ExamScore
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ExamScore
     */
    userID: number;
    /**
     * 
     * @type {number}
     * @memberof ExamScore
     */
    chapterID: number;
    /**
     * 
     * @type {number}
     * @memberof ExamScore
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof ExamScore
     */
    medalType?: ExamScoreMedalTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExamScore
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ExamScore
     */
    updatedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ExamScoreMedalTypeEnum {
    Gold = 'GOLD',
    Shilver = 'SHILVER',
    Bronze = 'BRONZE'
}



