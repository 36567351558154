import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'styled-media-query';

import Logo from '../../../static/image/logo.svg';

//import LessonCircleSvg from '../../../static/image/icon_lesson_circle.svg';

const Header = styled.header`
  display: flex;
  width: 100%;
  height: 67px;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 2rem;
  box-sizing: border-box;

  img {
    height: 1.5rem;
  }

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;

const Main = styled.main`
  box-sizing: border-box;
  padding: 2rem 1.5rem;

  ${media.lessThan('medium')`
    padding: 2rem 1rem;
  `}

  h2 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
  }
`;

const Content = styled.section`
  max-width: 840px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;

  p {
    margin-top: 1rem;
    line-height: 1.5;
  }

  ${media.lessThan('medium')`
    padding: 2rem 1rem;

    p {
      font-size: .875rem;
    }
  `}
`;

const Text = styled.h3`
  display: block;
  font-size: 8rem;
  font-weight: bold;
  color: #e2001b;
  text-align: center;

  ${media.lessThan('small')`
    font-size: 6rem;
  `}
`;

const SubText = styled.h4`
  display: block;
  font-size: 2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.36);
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1.5rem;
  `}
`;

const TopLink = styled(Link)`
  color: #e2001b;
`;

export const NotFoundPage = (
  <React.Fragment>
    <Header>
      <Link to="/">
        <img src={Logo} alt="Samurai" />
      </Link>
    </Header>

    <Main>
      <Content>
        <Text>404</Text>
        {/* <img src={LessonCircleSvg} alt="仮登録完了"/> */}
        <SubText>Not Found</SubText>
        <p>
          申し訳ございませんが、お探しのページは見つかりませんでした。
          <br />
          お探しのページは一時的にアクセスできない状態にあるか、URLが変更された可能性がございます。
        </p>
        <p>
          <TopLink to="/">TOPへ移動する</TopLink>
        </p>
      </Content>
    </Main>
  </React.Fragment>
);

export const NotFound: React.FC = (): JSX.Element => NotFoundPage;

export default NotFound;
