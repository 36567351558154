import React, { Fragment } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { Chip } from '../atoms/Chip';
import ArrowIcon from '../../static/image/arrow_down.svg';
import { LinkifyText } from '../atoms/LinkifyText';
import { utcToJst } from '../../utils/DateFnsSupport';
import { Button } from '../atoms/Button';

import { UserIcon } from './UserIcon';
import { SpotLessonFragment as SpotLesson, SpotLessonPhase } from '../../gen/graphql';

interface SpotLessonArticleProps {
  spotLesson: SpotLesson;
  onClickDone: (id: number) => void;
  onClickNonAttendance: (id: number) => void;
}

export const SpotLessonArticle: React.FC<SpotLessonArticleProps> = (props) => {
  const [isDetailOpen, setIsDetailOpen] = React.useState(false);
  const startAt = utcToJst(props.spotLesson.startAt);
  const endAt = utcToJst(props.spotLesson.endAt);
  const nowDateAt = utcToJst(new Date());

  return (
    <Container>
      <DateBlock>
        <p className="md">
          {format(startAt, 'MM/dd')}
          <span>({format(startAt, 'E', { locale: ja })})</span>
        </p>
        <p className="time">
          {format(startAt, 'HH:mm')}~{format(endAt, 'HH:mm')}
        </p>
        <PhaseLabel
          phase={props.spotLesson.phase ? props.spotLesson.phase : SpotLessonPhase.Incomplete}
        >
          <p>
            {props.spotLesson.phase === SpotLessonPhase.Complete
              ? '実施済'
              : props.spotLesson.phase === SpotLessonPhase.Cancel
                ? 'キャンセル'
                : props.spotLesson.phase === SpotLessonPhase.NonAttendance
                  ? '不参加'
                  : ''}
          </p>
        </PhaseLabel>
      </DateBlock>
      <DetailBlock>
        <div className="title_area">
          <div className="tags">{props.spotLesson.tags?.map((tag) => <Chip>{tag.name}</Chip>)}</div>
          <h4>{props.spotLesson.categories?.map((category) => <span>{category.name}</span>)}</h4>
        </div>
        <DetailArea isOpen={isDetailOpen}>
          <div className="item">
            <p className="title">困っていること:</p>
            <p className="text">
              <LinkifyText options={{ target: '_blank' }}>
                {props.spotLesson.troubleContent}
              </LinkifyText>
            </p>
          </div>
          <div className="item">
            <p className="title">調べたこと:</p>
            <p className="text">
              <LinkifyText options={{ target: '_blank' }}>
                {props.spotLesson.researchContent}
              </LinkifyText>
            </p>
          </div>
        </DetailArea>
        <DetailToggle
          onClick={() => setIsDetailOpen((prevState) => !prevState)}
          isOpen={isDetailOpen}
        >
          {isDetailOpen ? '閉じる' : '詳細を見る'}
        </DetailToggle>
        <StyledUserIcon user={props.spotLesson.user ?? undefined} small name />
        <ZoomArea isVisible={props.spotLesson.phase === SpotLessonPhase.Incomplete}>
          <span>URL:</span>
          {props.spotLesson.zoomURL ? (
            <a href={props.spotLesson.zoomURL}>{props.spotLesson.zoomURL}</a>
          ) : (
            '処理中'
          )}
        </ZoomArea>
      </DetailBlock>
      <ButtonBlock>
        {props.spotLesson.phase === SpotLessonPhase.Incomplete && (
          <Fragment>
            <StyledButton
              disabled={startAt > nowDateAt}
              onClick={() =>
                startAt > nowDateAt
                  ? alert('未来の日付のレッスンは実施済みにできません')
                  : props.onClickDone(props.spotLesson.id)
              }
            >
              実施済みにする
            </StyledButton>
            <StyledButton
              white={true}
              disabled={startAt > nowDateAt}
              onClick={() =>
                startAt > nowDateAt
                  ? alert('未来の日付のレッスンは実施済みにできません')
                  : props.onClickNonAttendance(props.spotLesson.id)
              }
            >
              レッスン不参加
            </StyledButton>
          </Fragment>
        )}
      </ButtonBlock>
    </Container>
  );
};

const Container = styled.article`
  position: relative;
  display: flex;
  padding: 32px 0;

  ${media.lessThan('medium')`
    display: block;
    padding: 16px;
    padding-bottom: 48px;
  `}
`;

const DateBlock = styled.div`
  .md {
    font-size: 1.5rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);

    span {
      font-size: 0.65rem;
    }
  }

  .time {
    margin-top: 4px;
    font-size: 0.75rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
  }

  ${media.lessThan('medium')`
    display: flex;

    .date {
      font-size: 1.25rem;
      font-weight: bold;

      span {
        font-size: 1rem;
      }
    }

    .time {
      font-size: 1.25rem;
      font-weight: 500;
      margin-left: 0.5rem;
    }
  `}
}`;

const DetailBlock = styled.div`
  margin-left: 23px;
  min-width: 0;

  ${media.lessThan('medium')`
    margin-left: 0;
  `}

  .title_area {
    box-sizing: border-box;
    padding-right: 200px;

    ${media.lessThan('medium')`
      padding: 0;
      margin-top: 12px;
    `}

    .tags {
      display: flex;
      flex-wrap: wrap;

      ${media.lessThan('medium')`
        margin-top: 12px;
      `}
    }

    h4 {
      margin-top: 0.5rem;
      color: rgba(0, 0, 0, 0.87);

      span + span {
        &:before {
          content: '/';
          margin: 0 0.5rem;
        }
      }

      ${media.lessThan('medium')`
        margin-top: 12px;
        line-height: 1.5;
      `}
    }
  }

  .zoom {
    padding: 10px 16px;
    background-color: #f5f5f5;
    margin-top: 1rem;

    span {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.87);

      ${media.lessThan('medium')`
        display: block;
      `}
    }

    a {
      color: #1da1f2;
      margin-left: 0.5rem;

      ${media.lessThan('medium')`
        display: block;
        margin: 0;
        margin-top: 0.5rem;
      `}
    }
  }
`;

const DetailToggle = styled.p<{ isOpen?: boolean }>`
  display: inline-flex;
  margin-top: 1rem;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  position: relative;
  align-items: center;

  &:after {
    content: '';
    width: 10px;
    height: 6px;
    background-image: url(${ArrowIcon});
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 0.25rem;

    ${(props) => (props.isOpen ? 'transform: rotate(180deg)' : '')}
  }
`;

const DetailArea = styled.div<{ isOpen?: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  min-width: 0;

  .item {
    display: flex;
    align-items: flex-start;

    ${media.lessThan('medium')`
      display: block;
    `}

    & + * {
      margin-top: 1rem;
    }

    .title {
      font-weight: bold;
      font-size: 0.9rem;
      line-height: 1.5;
      white-space: nowrap;
    }

    .text {
      line-height: 1.5;
      font-size: 0.9rem;
      margin-left: 0.5rem;
      white-space: pre-line;
      min-width: 0;
      overflow-wrap: break-word;

      ${media.lessThan('medium')`
        margin: 0;
      `}
    }
  }
`;

const StyledUserIcon = styled(UserIcon)`
  margin-top: 1.25rem;
`;

const PhaseLabel = styled.div<{ phase: SpotLessonPhase }>`
  display: ${(props) => (props.phase === SpotLessonPhase.Incomplete ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.phase === SpotLessonPhase.Complete ? 'rgba(253, 60, 47, 0.13)' : 'rgba(0, 0, 0, 0.1)'};
  width: 83px;
  height: 32px;
  margin-top: 1rem;

  p {
    font-size: 1rem;
    font-weight: bold;
    color: ${(props) =>
      props.phase === SpotLessonPhase.Complete ? '#e2001b' : 'rgba(0, 0, 0, 0.36)'};
  }

  ${media.lessThan('medium')`
    width: 80px;
    height: 24px;
    margin: 0;
    margin-left: 0.5rem;

    p {
      font-size: 0.8rem;
    }
  `}
`;

const ButtonBlock = styled.div`
  margin-left: auto;
`;

const StyledButton = styled(Button)<{ white?: boolean }>`
  display: block;
  width: 173px;
  top: 2rem;
  right: 0;
  margin-bottom: 0.5rem;

  ${(props) =>
    props.white &&
    `
    background: #ffffff;
    border: none;
    color: rgba(0, 0, 0, 0.87);
  `}

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 240px;
    margin: 2rem auto 0;
    position: relative;
    top: auto;
  `};
`;

const ZoomArea = styled.p<{ isVisible?: boolean }>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  padding: 10px 16px;
  background-color: #f5f5f5;
  margin-top: 1rem;

  span {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);

    ${media.lessThan('medium')`
      display: block;
    `}
  }

  a {
    color: #1da1f2;
    margin-left: 0.5rem;
    word-break: break-all;

    ${media.lessThan('medium')`
      display: block;
      margin: 0;
      margin-top: 0.5rem;
    `}
  }
`;
