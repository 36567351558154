import React, { ChangeEvent, useState, useEffect } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { isNone, isSome, none, Option } from 'fp-ts/Option';

import { useUser } from '../../redux/user/useUser';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { Button } from '../atoms/Button';
import { Input } from '../atoms/Input';

import IconGoogle from '../../static/image/icon_google.png';
import IconFacebook from '../../static/image/icon_facebook.png';

import {
  UserNameValidation,
  UserNickNameValidation,
  UserEmailValidation,
  UserPasswordValidation,
  NameError,
  NickNameError,
  EmailError,
  PasswordError,
} from '../../utils/FormValidation';

interface Props {
  name?: string;
  setName?: (content: string) => void;
  nickName: string;
  setNickName: (content: string) => void;
  email: string;
  setEmail: (content: string) => void;
  password: string;
  setPassword: (content: string) => void;
  clickableRegisterButton: (content: boolean) => void;
  socialLoginCallbackURL: string;
  trial?: boolean;
}

export const RegisterForm: React.FC<Props> = (props) => {
  const { socialLogin } = useUser();
  const customDomains = [
    'au.com',
    'docomo.ne.jp',
    'excite.co.jp',
    'ezweb.ne.jp',
    'gmail.com',
    'googlemail.com',
    'hotmail.com',
    'hotmail.co.jp',
    'icloud.com',
    'i.softback.jp',
    'i.soft.bank.jp',
    'live.jp',
    'me.com',
    'mineo.jp',
    'nifty.com',
    'outlook.com',
    'outlook.jp',
    'softbank.ne.jp',
    'yahoo.co.jp',
    'yahoo.ne.jp',
    'ybb.ne.jp',
    'ymobile.ne.jp',
  ];

  const [isPassword, setIsPassword] = useState(true);

  // バリデーション
  const [checkedName, setCheckedName] = useState(props.name === undefined);
  const [checkedNickName, setCheckedNickName] = useState(false);
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [checkedPassword, setCheckedPassword] = useState(false);

  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorNickName, setIsErrorNickName] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorPassword, setIsErrorPassword] = useState(false);
  const [errorName, setErrorName] = useState<Option<NameError>>(none);
  const [errorNickName, setErrorNickName] = useState<Option<NickNameError>>(none);
  const [errorEmail, setErrorEmail] = useState<Option<EmailError>>(none);
  const [errorPassword, setErrorPassword] = useState<Option<PasswordError>>(none);

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isActiveSuggest, setIsActiveSuggest] = useState(false);

  const suggestDomain = (value: string) => {
    let matches: Array<string> = [];

    if (value && value.includes('@')) {
      const currentDomain = value.substring(value.indexOf('@') + 1);

      if (currentDomain) {
        matches = customDomains.filter((domain) => domain.startsWith(currentDomain));
      } else {
        matches = customDomains;
      }
    }

    return matches;
  };

  const createEmailText = (value: string, suggest: string) => {
    return value.substring(0, value.indexOf('@') + 1) + suggest;
  };

  const setEmailValue = (value: string, suggest: string) => {
    const email = createEmailText(value, suggest);
    setSuggestions([]);
    props.setEmail(email);
    setCheckedEmail(isNone(UserEmailValidation(email)));
    setIsErrorEmail(!isNone(UserEmailValidation(email)));
    if (isNone(UserEmailValidation(email))) {
      setErrorEmail(none);
    }
  };

  const inputName = (value: string) => {
    if (!props.setName) return;
    props.setName(value);
    setCheckedName(isNone(UserNameValidation(value)));
    if (isNone(UserNameValidation(value))) {
      setErrorName(none);
    }
  };
  const inputNickName = (value: string) => {
    props.setNickName(value);
    setCheckedNickName(isNone(UserNickNameValidation(value)));
    if (isNone(UserNickNameValidation(value))) {
      setErrorName(none);
    }
  };
  const inputEmail = (value: string) => {
    setSuggestions(suggestDomain(value));

    props.setEmail(value);
    setCheckedEmail(isNone(UserEmailValidation(value)));
    if (isNone(UserEmailValidation(value))) {
      setErrorEmail(none);
    }
  };
  const inputPassword = (value: string) => {
    props.setPassword(value);
    setCheckedPassword(isNone(UserPasswordValidation(value)));
    if (isNone(UserPasswordValidation(value))) {
      setErrorPassword(none);
    }
  };

  const BlurName = (value: string) => {
    setIsErrorName(!isNone(UserNameValidation(value)));
    setErrorName(UserNameValidation(value));
  };
  const BlurNickName = (value: string) => {
    setIsErrorNickName(!isNone(UserNickNameValidation(value)));
    setErrorNickName(UserNickNameValidation(value));
  };
  const BlurEmail = (value: string) => {
    setIsErrorEmail(!isNone(UserEmailValidation(value)));
    setErrorEmail(UserEmailValidation(value));
  };
  const BlurPassword = (value: string) => {
    setIsErrorPassword(!isNone(UserPasswordValidation(value)));
    setErrorPassword(UserPasswordValidation(value));
  };

  useEffect(() => {
    props.clickableRegisterButton(
      checkedName && checkedNickName && checkedEmail && checkedPassword,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedName, checkedNickName, checkedEmail, checkedPassword]);

  const emailContainer = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    const suggestHandler = (event: Event) => {
      if (emailContainer.current && !emailContainer.current.contains(event.target as Node)) {
        setIsActiveSuggest(false);
      }
    };
    document.addEventListener('click', suggestHandler);
    return () => {
      document.removeEventListener('click', suggestHandler);
    };
  });

  return (
    <React.Fragment>
      <SnsArea>
        <a
          onClick={() =>
            socialLogin(CognitoHostedUIIdentityProvider.Google, props.socialLoginCallbackURL)
          }
        >
          <GoogleSignupButton>Googleで登録</GoogleSignupButton>
        </a>
        <a
          onClick={() =>
            socialLogin(CognitoHostedUIIdentityProvider.Facebook, props.socialLoginCallbackURL)
          }
        >
          <FacebookSignupButton>Facebookで登録</FacebookSignupButton>
        </a>
      </SnsArea>

      <Separation />

      <InputArea>
        {props.name !== undefined && (
          <FormRow>
            <Label>
              <span className="required">必須</span>
              <label>名前</label>
            </Label>

            <StyledInput
              name="name"
              value={props.name}
              onChange={(e) => inputName(e.target.value)}
              type="text"
              placeholder="侍 太郎"
              error={isErrorName}
              validation={checkedName}
              onBlur={(e) => BlurName(e.target.value)}
            />

            <ErrorText>{isSome(errorName) ? errorName.value.message : ''}</ErrorText>
          </FormRow>
        )}
        <FormRow>
          <Label>
            <span className="required">必須</span>
            <label>ニックネーム (公開用)</label>
          </Label>

          <StyledInput
            name="nickName"
            value={props.nickName}
            onChange={(e) => inputNickName(e.target.value)}
            type="text"
            placeholder="サムライくん"
            error={isErrorNickName}
            validation={checkedNickName}
            onBlur={(e) => BlurNickName(e.target.value)}
          />

          <ErrorText>{isSome(errorNickName) ? errorNickName.value.message : ''}</ErrorText>
        </FormRow>
        <FormRow>
          <Label>
            <span className="required">必須</span>
            <label>メールアドレス</label>
          </Label>

          <div ref={emailContainer}>
            <StyledInput
              name="email"
              value={props.email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                inputEmail(e.target.value);
              }}
              type="email"
              placeholder="samurai@sejuku.net"
              error={isErrorEmail}
              validation={checkedEmail}
              onBlur={(e) => BlurEmail(e.target.value)}
              onFocus={() => setIsActiveSuggest(true)}
            />

            {isActiveSuggest && suggestions.length > 0 && (
              <Suggest>
                {suggestions.map((suggestion) => {
                  return (
                    <li
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setEmailValue(props.email, suggestion);
                        setIsActiveSuggest(false);
                      }}
                    >
                      {createEmailText(props.email, suggestion)}
                    </li>
                  );
                })}
              </Suggest>
            )}
          </div>

          <ErrorText>{isSome(errorEmail) ? errorEmail.value.message : ''}</ErrorText>
        </FormRow>
        <FormRow>
          <Label>
            <span className="required">必須</span>
            <label>パスワード</label>
            <small>(大文字,小文字,数字を組み合わせた8文字以上)</small>
          </Label>

          <StyledInput
            name="password"
            value={props.password}
            onChange={(e) => inputPassword(e.target.value)}
            type={isPassword ? 'password' : 'text'}
            placeholder="Samurai0319#"
            error={isErrorPassword}
            validation={checkedPassword}
            onBlur={(e) => BlurPassword(e.target.value)}
          />

          <ErrorText>{isSome(errorPassword) ? errorPassword.value.message : ''}</ErrorText>

          <ViewPassword checked={!isPassword}>
            <input
              type="checkbox"
              onChange={() => setIsPassword(!isPassword)}
              checked={!isPassword}
            />
            パスワードを表示する
          </ViewPassword>
        </FormRow>
      </InputArea>
    </React.Fragment>
  );
};

const Separation = styled.div`
  margin: 1rem auto 1.5rem;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    margin: auto;
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &:after {
    content: 'or';
    display: block;
    width: 2rem;
    height: 1.25rem;
    margin: 0 auto;
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    position: relative;
  }

  ${media.lessThan('medium')`
    margin: 1.375rem auto;
  `}
`;
const FormRow = styled.div`
  ${media.lessThan('medium')`
    position: relative;
    flex-direction: column;

    & > * + * {
      margin-top: 0.5rem;
    }

    input {
      display: block;
      width: 100% !important;
    }
  `}
`;
const Label = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 1rem;

  .required,
  .option {
    display: block;
    margin-right: 0.5rem;
    padding: 0.125rem 0.5rem;
    background-color: #fd6258;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .option {
    background-color: #999;
  }

  label {
    font-size: 1rem;
    font-weight: 700;
  }

  small {
    display: block;
    margin-left: 0.5rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.75rem;
  }

  ${media.lessThan('medium')`
    flex-wrap: wrap;

    small {
      width: 100%;
      margin: 0.375rem auto 0;
    }
  `}
`;

const StyledInput = styled(Input)`
  height: 2.75rem;
  border-color: rgba(0, 0, 0, 0.36);
  border-radius: 0;

  input {
    padding: 0.75rem;
    outline: none;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
    }
  }

  ${media.lessThan('medium')`
    height: 2.5rem;

    input {
      padding: .625rem .75rem;
    }
  `}
`;
const ErrorText = styled.p`
  margin: 0.25rem auto 0;
  color: #eb0000;
  align-self: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

const InputArea = styled.div`
  ${FormRow} + ${FormRow} {
    margin-top: 2rem;
  }
`;
const SnsArea = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;

  a {
    flex: 1;
    display: block;

    + a {
      margin-left: 1.5rem;
    }
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;

    a {
      flex: none;
      width: 100%;
      max-width: 360px;
      margin: 0 auto;

      + a {
        margin: 1rem auto 0;
      }
    }
  `}
`;

const SnsButton = styled(Button)`
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
    background: transparent center / contain no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1rem;
  }

  ${media.lessThan('large')`
    padding: .5rem 1rem .5rem 3rem;
  `}

  ${media.lessThan('medium')`
    padding: .5rem 0;
  `}
`;
const FacebookSignupButton = styled(SnsButton)`
  background: #1778f2;
  border-color: #1778f2;

  &:before {
    background-image: url(${IconFacebook});
  }
`;
const GoogleSignupButton = styled(SnsButton)`
  background: #efefef;
  border-color: #efefef;
  color: rgba(0, 0, 0, 0.87);

  &:before {
    background-image: url(${IconGoogle});
  }
`;

const ViewPassword = styled.label<{ checked: boolean }>`
  display: block;
  margin-top: 0.5rem;
  padding-left: 1.625rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.375rem;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    margin: auto;
    border: 1px solid ${(props) => (props.checked ? '#eb0000' : 'rgba(0,0,0,.36)')};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    ${(props) =>
      props.checked
        ? `
      background: #eb0000;
      border-radius: .125rem;
    `
        : ``}
  }

  &:after {
    content: '';
    display: block;
    width: 0.75rem;
    height: 5.5px;
    margin: auto;
    border-left: 0.125rem solid ${(props) => (props.checked ? '#fff' : 'rgba(0,0,0,.36)')};
    border-bottom: 0.125rem solid ${(props) => (props.checked ? '#fff' : 'rgba(0,0,0,.36)')};
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    bottom: 0.25rem;
    left: 0.1875rem;
  }

  input {
    display: none;
  }
`;

const Suggest = styled.ul`
  max-width: 100%;
  max-height: 13rem;
  padding: 0.5rem 0;
  background: #fff;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  position: absolute;
  overflow-y: auto;
  z-index: 2;

  li {
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25rem;
    line-break: anywhere;
    transition: all 0.2s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;
