import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import media, { generateMedia } from 'styled-media-query';

import { useUser } from '../../redux/user/useUser';
import { MenuContext } from '../../context/MenuProvider';
import { NavigationItem } from '../molecules/NavigationItem';
import { Divider } from '../atoms/Divider';
import { DummySearchForm } from '../atoms/DummySearchForm';
import { HelpMenu } from './HelpMenu';

import Logo from '../../static/image/logo.svg';
import IconLogo from '../../static/image/icon_logo_mark.png';
import MenuCloseIcon from '../../static/image/arrow_menu_close.svg';
import MenuOpenIcon from '../../static/image/arrow_menu_open.svg';
import { useOutsideClick } from '../../common/customHooks/OutsideClick';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { useGetChatMessageUnreadCountQuery } from '../../gen/graphql';
import { Z_INDEX_DRAWER } from '../../const/ZIndex';
import { Button } from '../atoms/Button';
import { LeadButtonToPro } from '../atoms/LeadButtonToPro';
import { useNavigate } from 'react-router-dom';

interface NavigationProps {
  pageTitle: string;
  naviRef: React.RefObject<HTMLDivElement>;
}

const NavigationList: React.FC<NavigationProps> = (props) => {
  const { permissionCheck, hasCoaching } = useUser();

  const menuContext = useContext(MenuContext);

  const permissionChat = permissionCheck(FunctionType.Chat, PermissionType.Read);
  const { data: messageUnreadCountData } = useGetChatMessageUnreadCountQuery({
    skip: !permissionChat,
  });
  const messageUnreadCount = messageUnreadCountData?.chatMessageUnreadCount ?? 0;

  const [lessonChildOpen, setLessonChildOpen] = useState(true);
  const toggleLesson = () => {
    setLessonChildOpen(!lessonChildOpen);
  };

  return (
    <Ul isOpen={menuContext.isOpen} isHoverOpen={menuContext.isHoverOpen}>
      <NavigationItem
        icon={
          <path
            d="M2 11.6234L9.73913 3L18 11.6234H13.793V16.7143H9.73913H5.6853V11.6234H2Z"
            strokeWidth="1.4"
            strokeLinejoin="round"
          />
        }
        active={props.pageTitle === 'ホーム'}
        to="/home"
        name="navi-home"
      >
        {menuContext.isOpen || menuContext.isHoverOpen ? 'ホーム' : ''}
      </NavigationItem>

      <NavigationItem
        icon={
          <>
            <path
              d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 5.83325V9.99992L12.5 12.4999"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        }
        active={props.pageTitle === 'タイムライン'}
        to="/timeline"
        name="navi-timeline"
      >
        {menuContext.isOpen || menuContext.isHoverOpen ? 'タイムライン' : ''}
      </NavigationItem>

      {permissionCheck(FunctionType.TeamTop, PermissionType.Read) && (
        <NavigationItem
          icon={
            <>
              <mask
                id="mask0_1731_64635"
                maskUnits="userSpaceOnUse"
                x="0"
                y="4"
                width="10"
                height="11"
              >
                <path fill="#FFFFFF" d="M0 4h10v11H0Z" />
              </mask>
              <g mask="url(#mask0_1731_64635)">
                <path strokeWidth="1.4" d="M5 12h2s3 0 3 3v2s0 3 -3 3h-2s-3 0 -3 -3v-2s0 -3 3 -3" />
                <path
                  strokeWidth="1.4"
                  d="M4.2002 7.9355a1.9355 1.9355 0 1 0 3.871 0a1.9355 1.9355 0 1 0 -3.871 0"
                />
              </g>
              <mask
                id="mask1_1731_64635"
                maskUnits="userSpaceOnUse"
                x="10"
                y="4"
                width="10"
                height="11"
              >
                <path transform="matrix(-1 0 0 1 20 4)" fill="#FFFFFF" d="M0 0h10v11H0Z" />
              </mask>
              <g mask="url(#mask1_1731_64635)">
                <path
                  transform="matrix(-1 0 0 1 18 12)"
                  strokeWidth="1.4"
                  d="M3 0h2s3 0 3 3v2s0 3 -3 3h-2s-3 0 -3 -3v-2s0 -3 3 -3"
                />
                <path
                  transform="matrix(-1 0 0 1 14.0645 7.93548)"
                  strokeWidth="1.4"
                  d="M-1.9355 0a1.9355 1.9355 0 1 0 3.871 0a1.9355 1.9355 0 1 0 -3.871 0"
                />
              </g>
            </>
          }
          active={props.pageTitle === 'チーム'}
          to="/team"
          name="navi-team"
        >
          {menuContext.isOpen || menuContext.isHoverOpen ? 'チーム' : ''}
        </NavigationItem>
      )}

      <Divider />

      {permissionCheck(FunctionType.CourseForInstructorAndCoach, PermissionType.Read) && (
        <>
          <NavigationItem
            icon={
              <path d="M7.577 9.807v-0.761c-1.017-0.573-1.846-2.002-1.846-3.43 0-2.294 0-4.154 2.769-4.154s2.769 1.86 2.769 4.154c0 1.429-0.829 2.857-1.846 3.43v0.761c3.131 0.256 5.538 1.795 5.538 3.655h-12.923c0-1.86 2.407-3.399 5.538-3.655z" />
            }
            active={props.pageTitle === '受講生一覧'}
            to="/courses"
            name="navi-course"
          >
            {menuContext.isOpen || menuContext.isHoverOpen ? '受講生一覧' : ''}
          </NavigationItem>

          <Divider />
        </>
      )}

      <NavigationItem
        icon={
          <path
            d="M3 15.049C4.06413 14.4346 5.27124 14.1112 6.5 14.1112C7.72876 14.1112 8.93587 14.4346 10 15.049C11.0641 14.4346 12.2712 14.1112 13.5 14.1112C14.7288 14.1112 15.9359 14.4346 17 15.049 M3 4.93782C4.06413 4.32344 5.27124 4 6.5 4C7.72876 4 8.93587 4.32344 10 4.93782C11.0641 4.32344 12.2712 4 13.5 4C14.7288 4 15.9359 4.32344 17 4.93782 M3 4.93787V15.049 M10 4.93787V15.049 M17 4.93787V15.049"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        }
        active={props.pageTitle === '教材'}
        to={
          permissionCheck(FunctionType.ProgramForInstructorAndCoach, PermissionType.Read)
            ? '/programs?type=1'
            : '/programs'
        }
        name="navi-program"
      >
        {menuContext.isOpen || menuContext.isHoverOpen ? '教材' : ''}
      </NavigationItem>

      <NavigationItem
        icon={
          <>
            <path
              d="M11.6251 2.69C11.6251 2.30892 11.3161 2 10.9351 2H4.19C3.80892 2 3.5 2.30892 3.5 2.69V16.31C3.5 16.6911 3.80892 17 4.19 17H14.81C15.1911 17 15.5 16.6911 15.5 16.31V6.79583C15.5 6.41476 15.1911 6.10583 14.81 6.10583H12.3151C11.934 6.10583 11.6251 5.79691 11.6251 5.41583V2.69Z"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 2.30005L15.4519 6.25197"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.5 9.50012L8.7647 11.7648L11.7843 8.74522"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.3748 9.02689L12.6017 13.8H12.1238V13.3221L16.8969 8.54898C16.9603 8.4856 17.0463 8.45 17.1359 8.45C17.2255 8.45 17.3115 8.4856 17.3748 8.54898C17.4382 8.61235 17.4738 8.69831 17.4738 8.78793C17.4738 8.87756 17.4382 8.96352 17.3748 9.02689Z"
              fill="black"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </>
        }
        active={props.pageTitle === '課題' || props.pageTitle === '課題提出フォーム'}
        to="/practices"
        name="navi-practice"
      >
        {menuContext.isOpen || menuContext.isHoverOpen ? '課題' : ''}
      </NavigationItem>

      <NavigationItem
        icon={
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            strokeWidth="0.1"
            d="M4.54999 10C4.54999 7.10051 6.90049 4.75 9.79999 4.75C12.6995 4.75 15.05 7.10051 15.05 10C15.05 11.122 14.698 12.1618 14.0985 13.015L12.294 11.4356C11.9823 11.1628 11.5085 11.1943 11.2357 11.506C10.9629 11.8177 10.9944 12.2915 11.3061 12.5643L13.0696 14.1079C12.1726 14.8228 11.0362 15.25 9.79999 15.25C6.90049 15.25 4.54999 12.8995 4.54999 10ZM14.2125 15.1082C13.0292 16.1312 11.4869 16.75 9.79999 16.75C6.07207 16.75 3.04999 13.7279 3.04999 10C3.04999 6.27208 6.07207 3.25 9.79999 3.25C13.5279 3.25 16.55 6.27208 16.55 10C16.55 11.5005 16.0604 12.8866 15.2322 14.0074L16.0262 14.7023C16.3379 14.9751 16.3694 15.4489 16.0966 15.7606C15.8238 16.0723 15.35 16.1038 15.0383 15.831L14.2125 15.1082Z"
          />
        }
        withFill
        active={props.pageTitle === 'Q&A'}
        to={
          permissionCheck(FunctionType.QuestionForInstructorAndCoach, PermissionType.Read)
            ? '/questions/my'
            : '/questions/all'
        }
        name="navi-question"
      >
        {menuContext.isOpen || menuContext.isHoverOpen ? 'Q&A' : ''}
      </NavigationItem>

      {permissionCheck(FunctionType.SpotLessonForInstructor, PermissionType.Read) &&
        permissionCheck(FunctionType.LessonForInstructor, PermissionType.Read) && (
          <NavigationItem
            icon={
              <path
                d="M7.11111 11.5556C7.11111 10.6964 6.41467 10 5.55556 10C4.69645 10 4 10.6964 4 11.5556V13.1111C4 13.9702 4.69645 14.6667 5.55556 14.6667C6.41467 14.6667 7.11111 13.9702 7.11111 13.1111V11.5556Z M16.4444 11.5556C16.4444 10.6964 15.748 10 14.8889 10C14.0298 10 13.3333 10.6964 13.3333 11.5556V13.1111C13.3333 13.9702 14.0298 14.6667 14.8889 14.6667C15.748 14.6667 16.4444 13.9702 16.4444 13.1111V11.5556Z M4 11.5556V9.22222C4 7.57199 4.65555 5.98934 5.82245 4.82245C6.98934 3.65555 8.57199 3 10.2222 3C11.8725 3 13.4551 3.65555 14.622 4.82245C15.7889 5.98934 16.4444 7.57199 16.4444 9.22222V11.5556 M14.8888 14.6666C14.8888 15.2855 14.3972 15.879 13.522 16.3165C12.6468 16.7541 11.4598 17 10.2222 17"
                strokeWidth="1.4"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            }
            active={props.pageTitle === '専属レッスン' || props.pageTitle === 'レッスン'}
            withFill
            onClick={toggleLesson}
            isShowChild={lessonChildOpen}
            childItem={[
              { name: 'レッスン', active: props.pageTitle === 'レッスン', to: '/spot_lessons' },
              { name: '専属レッスン', active: props.pageTitle === '専属レッスン', to: '/lessons' },
            ]}
            name="navi-lesson"
          >
            {menuContext.isOpen || menuContext.isHoverOpen ? 'レッスン' : ''}
          </NavigationItem>
        )}

      <NavigationItem
        icon={
          <path
            d="M3.33337 16.6666H6.66671L15.4167 7.91663C15.8587 7.47461 16.1071 6.87509 16.1071 6.24997C16.1071 5.62485 15.8587 5.02533 15.4167 4.5833C14.9747 4.14127 14.3752 3.89294 13.75 3.89294C13.1249 3.89294 12.5254 4.14127 12.0834 4.5833L3.33337 13.3333V16.6666Z M11.25 5.41663L14.5833 8.74996"
            strokeWidth="1.4"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        }
        active={props.pageTitle === '学習ログ' || props.pageTitle === '学習ログ一覧'}
        to={
          permissionCheck(FunctionType.StudyReportForNotLogin, PermissionType.Read)
            ? '/study_report/all'
            : '/study_report/my'
        }
        name="navi-study_report"
        withFill
      >
        {menuContext.isOpen || menuContext.isHoverOpen ? '学習ログ' : ''}
      </NavigationItem>

      {permissionCheck(FunctionType.SessionForCoach, PermissionType.Read) && (
        <NavigationItem
          icon={
            <path
              d="M7.11111 11.5556C7.11111 10.6964 6.41467 10 5.55556 10C4.69645 10 4 10.6964 4 11.5556V13.1111C4 13.9702 4.69645 14.6667 5.55556 14.6667C6.41467 14.6667 7.11111 13.9702 7.11111 13.1111V11.5556Z M16.4444 11.5556C16.4444 10.6964 15.748 10 14.8889 10C14.0298 10 13.3333 10.6964 13.3333 11.5556V13.1111C13.3333 13.9702 14.0298 14.6667 14.8889 14.6667C15.748 14.6667 16.4444 13.9702 16.4444 13.1111V11.5556Z M4 11.5556V9.22222C4 7.57199 4.65555 5.98934 5.82245 4.82245C6.98934 3.65555 8.57199 3 10.2222 3C11.8725 3 13.4551 3.65555 14.622 4.82245C15.7889 5.98934 16.4444 7.57199 16.4444 9.22222V11.5556 M14.8888 14.6666C14.8888 15.2855 14.3972 15.879 13.522 16.3165C12.6468 16.7541 11.4598 17 10.2222 17"
              strokeWidth="1.4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          }
          active={props.pageTitle === 'セッション' || props.pageTitle === 'セッション一覧'}
          to="/sessions"
          name="navi-session"
          withFill
        >
          {menuContext.isOpen || menuContext.isHoverOpen ? 'セッション' : ''}
        </NavigationItem>
      )}

      {permissionCheck(FunctionType.SpotLesson, PermissionType.Read) && (
        <NavigationItem
          icon={
            <path
              d="M7.11111 11.5556C7.11111 10.6964 6.41467 10 5.55556 10C4.69645 10 4 10.6964 4 11.5556V13.1111C4 13.9702 4.69645 14.6667 5.55556 14.6667C6.41467 14.6667 7.11111 13.9702 7.11111 13.1111V11.5556Z M16.4444 11.5556C16.4444 10.6964 15.748 10 14.8889 10C14.0298 10 13.3333 10.6964 13.3333 11.5556V13.1111C13.3333 13.9702 14.0298 14.6667 14.8889 14.6667C15.748 14.6667 16.4444 13.9702 16.4444 13.1111V11.5556Z M4 11.5556V9.22222C4 7.57199 4.65555 5.98934 5.82245 4.82245C6.98934 3.65555 8.57199 3 10.2222 3C11.8725 3 13.4551 3.65555 14.622 4.82245C15.7889 5.98934 16.4444 7.57199 16.4444 9.22222V11.5556 M14.8888 14.6666C14.8888 15.2855 14.3972 15.879 13.522 16.3165C12.6468 16.7541 11.4598 17 10.2222 17"
              strokeWidth="1.4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          }
          active={props.pageTitle === 'レッスン'}
          to="/spot_lessons"
          name="navi-lesson"
          withFill
        >
          {menuContext.isOpen || menuContext.isHoverOpen ? 'レッスン' : ''}
        </NavigationItem>
      )}

      {permissionCheck(FunctionType.Curriculum, PermissionType.Read) && (
        <>
          <Divider />

          <NavigationItem
            icon={
              <path
                d="M16.3942 16.4444H3V4 M11.1646 13.1562H16.1646 M8.3363 9.76233H13.3363 M6.07367 6.36841H11.0737"
                strokeWidth="1.4"
                strokeMiterlimit="7.3333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            }
            active={props.pageTitle === 'カリキュラム'}
            to="/curriculum"
            name="navi-curriculum"
          >
            {menuContext.isOpen || menuContext.isHoverOpen ? 'カリキュラム' : ''}
          </NavigationItem>
        </>
      )}

      {permissionCheck(FunctionType.LessonForStudent, PermissionType.Read) && (
        <NavigationItem
          icon={
            <path
              d="M7.11111 11.5556C7.11111 10.6964 6.41467 10 5.55556 10C4.69645 10 4 10.6964 4 11.5556V13.1111C4 13.9702 4.69645 14.6667 5.55556 14.6667C6.41467 14.6667 7.11111 13.9702 7.11111 13.1111V11.5556Z M16.4444 11.5556C16.4444 10.6964 15.748 10 14.8889 10C14.0298 10 13.3333 10.6964 13.3333 11.5556V13.1111C13.3333 13.9702 14.0298 14.6667 14.8889 14.6667C15.748 14.6667 16.4444 13.9702 16.4444 13.1111V11.5556Z M4 11.5556V9.22222C4 7.57199 4.65555 5.98934 5.82245 4.82245C6.98934 3.65555 8.57199 3 10.2222 3C11.8725 3 13.4551 3.65555 14.622 4.82245C15.7889 5.98934 16.4444 7.57199 16.4444 9.22222V11.5556 M14.8888 14.6666C14.8888 15.2855 14.3972 15.879 13.522 16.3165C12.6468 16.7541 11.4598 17 10.2222 17"
              strokeWidth="1.4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          }
          active={props.pageTitle === '専属レッスン'}
          to="/lessons"
          name="navi-lesson"
          withFill
        >
          {menuContext.isOpen || menuContext.isHoverOpen ? '専属レッスン' : ''}
        </NavigationItem>
      )}

      {
        // コーチングがある受講生のみ表示
        permissionCheck(FunctionType.SessionForStudent, PermissionType.Read) && hasCoaching() && (
          <NavigationItem
            icon={
              <path
                d="M7.11111 11.5556C7.11111 10.6964 6.41467 10 5.55556 10C4.69645 10 4 10.6964 4 11.5556V13.1111C4 13.9702 4.69645 14.6667 5.55556 14.6667C6.41467 14.6667 7.11111 13.9702 7.11111 13.1111V11.5556Z M16.4444 11.5556C16.4444 10.6964 15.748 10 14.8889 10C14.0298 10 13.3333 10.6964 13.3333 11.5556V13.1111C13.3333 13.9702 14.0298 14.6667 14.8889 14.6667C15.748 14.6667 16.4444 13.9702 16.4444 13.1111V11.5556Z M4 11.5556V9.22222C4 7.57199 4.65555 5.98934 5.82245 4.82245C6.98934 3.65555 8.57199 3 10.2222 3C11.8725 3 13.4551 3.65555 14.622 4.82245C15.7889 5.98934 16.4444 7.57199 16.4444 9.22222V11.5556 M14.8888 14.6666C14.8888 15.2855 14.3972 15.879 13.522 16.3165C12.6468 16.7541 11.4598 17 10.2222 17"
                strokeWidth="1.4"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            }
            active={props.pageTitle === 'セッション' || props.pageTitle === 'セッション一覧'}
            to="/sessions"
            name="navi-session"
            withFill
          >
            {menuContext.isOpen || menuContext.isHoverOpen ? 'セッション' : ''}
          </NavigationItem>
        )
      }

      {permissionCheck(FunctionType.Chat, PermissionType.Read) && (
        <>
          <Divider />

          <NavigationItem
            icon={
              <path
                d="M3.33337 17.5V6.66663C3.33337 6.00358 3.59677 5.3677 4.06561 4.89886C4.53445 4.43002 5.17033 4.16663 5.83337 4.16663H14.1667C14.8297 4.16663 15.4656 4.43002 15.9345 4.89886C16.4033 5.3677 16.6667 6.00358 16.6667 6.66663V11.6666C16.6667 12.3297 16.4033 12.9656 15.9345 13.4344C15.4656 13.9032 14.8297 14.1666 14.1667 14.1666H6.66671L3.33337 17.5Z M10 9.16663V9.17496 M6.66663 9.16663V9.17496 M13.3334 9.16663V9.17496"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            }
            active={props.pageTitle === 'メッセージ'}
            to="/chat"
            name="navi-chat"
            count={messageUnreadCount}
          >
            {menuContext.isOpen || menuContext.isHoverOpen ? 'メッセージ' : ''}
          </NavigationItem>
        </>
      )}

      {permissionCheck(FunctionType.Claim, PermissionType.Read) && (
        <>
          <Divider />

          <NavigationItem
            icon={
              <>
                <path
                  d="M11.667 1.79999C12.054 1.79999 12.367 2.11299 12.367 2.49999V5.83299C12.367 5.90699 12.427 5.96599 12.5 5.96599H15.833C16.22 5.96599 16.533 6.27899 16.533 6.66599C16.533 7.05299 16.22 7.36599 15.833 7.36599H12.5C11.654 7.36599 10.967 6.67999 10.967 5.83299V2.49999C10.967 2.11299 11.28 1.79999 11.667 1.79999Z"
                  strokeWidth="0"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.833 3.19999C5.3 3.19999 4.867 3.63299 4.867 4.16699V15.833C4.867 16.367 5.3 16.8 5.834 16.8H14.168C14.702 16.8 15.134 16.367 15.134 15.834V6.95699L11.377 3.19999H5.833ZM3.467 4.16699C3.467 2.85999 4.527 1.79999 5.834 1.79999H11.667C11.853 1.79999 12.031 1.87399 12.162 2.00499L16.329 6.17199C16.46 6.30299 16.534 6.48099 16.534 6.66699V15.834C16.534 17.141 15.475 18.2 14.168 18.2H5.833C4.526 18.2 3.466 17.14 3.466 15.833V4.16699H3.467Z"
                  strokeWidth="0"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.40399 12.21H9.30299V11.635H8.40399C8.02599 11.635 7.83699 11.51 7.83699 11.26C7.83699 11.015 8.02599 10.892 8.40399 10.892H8.91099L7.71699 9.01798C7.62099 8.86898 7.55799 8.76298 7.52899 8.69798C7.49999 8.63398 7.48499 8.56398 7.48499 8.48598C7.48499 8.34698 7.52699 8.23598 7.60999 8.15198C7.69499 8.06798 7.80299 8.02698 7.93599 8.02698C8.07199 8.02698 8.18199 8.06798 8.26599 8.15098C8.35098 8.23398 8.44799 8.36698 8.55999 8.55098L9.84999 10.572L11.005 8.66698C11.048 8.59198 11.093 8.51498 11.141 8.43298C11.189 8.35198 11.236 8.28298 11.281 8.22498C11.326 8.16798 11.383 8.12098 11.451 8.08298C11.518 8.04598 11.599 8.02698 11.692 8.02698C11.825 8.02698 11.933 8.06798 12.018 8.14898C12.102 8.22998 12.143 8.32898 12.143 8.44598C12.143 8.53898 12.126 8.62498 12.093 8.70098C12.06 8.77798 12.008 8.87598 11.935 8.99298L10.785 10.891H11.292C11.667 10.891 11.855 11.014 11.855 11.259C11.855 11.509 11.667 11.634 11.292 11.634H10.39V12.209H11.293C11.668 12.209 11.856 12.334 11.856 12.584C11.856 12.829 11.668 12.952 11.293 12.952H10.39V13.271C10.39 13.811 10.21 14.082 9.84999 14.082C9.48499 14.082 9.30299 13.811 9.30299 13.271V12.952H8.40399C8.02599 12.952 7.83699 12.829 7.83699 12.584C7.83699 12.335 8.02599 12.21 8.40399 12.21Z"
                  strokeWidth="0"
                  strokeMiterlimit="7.3333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </>
            }
            active={props.pageTitle === '請求申請'}
            to="/claim"
            name="navi-claim"
            withFill
          >
            {menuContext.isOpen || menuContext.isHoverOpen ? '請求申請' : ''}
          </NavigationItem>
        </>
      )}

      {menuContext.isOpen || menuContext.isHoverOpen ? (
        <React.Fragment>
          <Divider />
          <HelpMenu />
        </React.Fragment>
      ) : (
        ''
      )}
    </Ul>
  );
};

export const Navigation: React.FC<NavigationProps> = (props) => {
  const { isHoverOpen, isOpen, toggle, toggleClose, toggleHoverClose, toggleHoverOpen } =
    useContext(MenuContext);
  const { permissionCheck } = useUser();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const navRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();

  useOutsideClick(props.naviRef, navRef, () => {
    if (isOpen && innerWidth > 767) {
      return;
    }
    toggleClose();
    toggleHoverClose();
  });

  /**
   * メニュー開閉ボタンクリック
   */
  const clickOpen = () => {
    if (isOpen) {
      toggleHoverClose();
    }
    toggle();
  };

  /**
   * ホバーメニューオープン
   */
  const hoverMenuOpen = () => {
    if (isOpen) {
      return;
    }
    toggleHoverOpen();
  };

  /**
   * ホバーメニュークローズ
   */
  const hoverMenuClose = () => {
    if (isOpen) {
      return;
    }
    toggleHoverClose();
  };

  /**
   * 画面サイズ変更
   */
  const resizeListener = () => {
    setInnerWidth(() => {
      return window.innerWidth;
    });
    if (innerWidth > 767) {
      return;
    }
    toggleClose();
    toggleHoverClose();
  };

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  });

  return (
    <Nav
      isOpen={isOpen}
      isHoverOpen={isHoverOpen}
      onMouseEnter={hoverMenuOpen}
      onMouseLeave={hoverMenuClose}
      ref={navRef}
      hasLeadButton={permissionCheck(FunctionType.AdsForFree, PermissionType.Read)}
    >
      {isOpen || isHoverOpen ? (
        <Img src={Logo} alt="Samurai" isOpen={isOpen} isHoverOpen={isHoverOpen} />
      ) : (
        <Img src={IconLogo} alt="Samurai" isOpen={isOpen} isHoverOpen={isHoverOpen} />
      )}
      <DummySearchFormWrapper>
        <DummySearchForm />
      </DummySearchFormWrapper>
      {(permissionCheck(FunctionType.AdsForSubscription, PermissionType.Read) ||
        permissionCheck(FunctionType.AdsForFree, PermissionType.Read)) && (
        <LeadButtonWrapper>
          <LeadButtonToPro />
          {permissionCheck(FunctionType.AdsForFree, PermissionType.Read) && (
            <StyledLeadButton onClick={() => navigate('/account/subscription/lp')}>
              有料プラン
            </StyledLeadButton>
          )}
        </LeadButtonWrapper>
      )}

      <NavigationList {...props} />
      <MenuDiv onClick={clickOpen}>
        {isOpen ? (
          <MenuImg src={MenuCloseIcon} alt="close" />
        ) : (
          <MenuImg src={MenuOpenIcon} alt="open" />
        )}
      </MenuDiv>
    </Nav>
  );
};

const customMedia = generateMedia({
  minMedium: '769px',
});

const Nav = styled.nav<{ isOpen: boolean; isHoverOpen: boolean; hasLeadButton: boolean }>`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  width: 168px;
  height: 100vh;
  padding: 1rem 0 3rem;
  background: #ffffff;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
  font-weight: 600;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX_DRAWER};
  overflow: hidden;
  transition: transform 0.1s;

  ${(props) => {
    return !props.isOpen && !props.isHoverOpen
      ? customMedia.greaterThan('minMedium')`
        transform: translateX(-100px);
      `
      : '';
  }};

  ${(props) => {
    return !props.isOpen
      ? media.lessThan('medium')`
        transform: translateX(-280px);
      `
      : '';
  }}

  ${(props) =>
    props.hasLeadButton &&
    media.lessThan('medium')`
    grid-template-rows: auto auto auto 1fr auto;
  `}

  ${media.lessThan('medium')`
    width: 280px;
  `}
`;

const Ul = styled.ul<{ isOpen: boolean; isHoverOpen: boolean }>`
  position: relative;
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
  box-sizing: border-box;
`;

const Img = styled.img<{ isOpen: boolean; isHoverOpen: boolean }>`
  display: block;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem 1rem;
  box-sizing: border-box;
  ${(props) => {
    if (props.isOpen) {
      return '';
    }
    if (props.isHoverOpen) {
      return '';
    }
    return `
      width: 4.125rem;
      margin: 0 1px 0 auto;
    `;
  }};

  ${media.lessThan('medium')`
    width: 132px;
    padding: 0;
    margin: .5rem auto 1rem 1rem;
  `}
`;

const MenuDiv = styled.div`
  width: 100%;
  padding: 1rem;
  background: #fff;
  border-top: 1px solid #c0c0c0;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
`;

const MenuImg = styled.img`
  display: block;
  margin-left: 7rem;

  ${media.lessThan('medium')`
    margin-left: auto;
  `}
`;

const DummySearchFormWrapper = styled.div`
  display: none;
  margin: 0.75rem 1rem;
  padding: 1rem 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  ${media.lessThan('medium')`
    display: flex;
  `}
`;

const LeadButtonWrapper = styled.div`
  display: none;
  margin: 0 1rem 0.75rem;
  padding: 0.5rem 0 1.25rem;
  border-bottom: 1px solid #ddd;

  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column;
    gap: .5rem;
  `}
`;

const StyledLeadButton = styled(Button)`
  border: none;
  border-radius: 0.125rem;
  background: var(--samurai_gradient1, linear-gradient(95deg, #fd8258 11.86%, #fd3c2f 82.5%));
  line-height: 1rem;
  width: 100%;
`;
