import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format, parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';

import { H4, H5, Normal } from '../atoms/Typography';
import { Frame } from '../atoms/Frame';
import { Button } from '../atoms/Button';
import { UserIcon } from '../organisms/UserIcon';
import { useGetNextSessionForInstructorQuery } from '../../gen/graphql';

export const NextSession: React.FC = () => {
  // 次回セッションを取得する
  const { data } = useGetNextSessionForInstructorQuery();

  return (
    <Frame bgColor="warning" width="middle">
      <H4 color="DARK">次回専属セッション</H4>
      {data?.nextSessionForInstructor ? (
        <Container>
          <LessonDate>
            <time>
              {`${format(parseISO(data.nextSessionForInstructor.startAt), 'MM')}/${format(
                parseISO(data.nextSessionForInstructor.startAt),
                'dd',
              )}`}
              <span>{`(${format(parseISO(data.nextSessionForInstructor.startAt), 'E', {
                locale: ja,
              })})`}</span>
            </time>
            <time>
              {`${format(parseISO(data.nextSessionForInstructor.startAt), 'HH:mm')}〜${format(
                parseISO(data.nextSessionForInstructor.endAt),
                'HH:mm',
              )}`}
            </time>
          </LessonDate>

          <Info>
            <H5 color="DARK">{data.nextSessionForInstructor.title}</H5>

            <UserIcon
              user={data?.nextSessionForInstructor?.coaching?.course?.student.user}
              small
              name
            />
          </Info>
        </Container>
      ) : (
        <div>
          <Normal color="DARK">次回専属セッションが登録されていません。</Normal>
          <StyledLink to="/sessions">
            <StyledButton>専属セッションを登録する</StyledButton>
          </StyledLink>
        </div>
      )}
    </Frame>
  );
};

const Container = styled.section`
  display: flex;

  & > div:nth-of-type(1) {
    margin-right: 80px;
  }

  & > div:nth-of-type(1) time {
    display: block;
    font-size: 0.75rem;
  }

  & > div:nth-of-type(1) time:nth-child(1) {
    font-size: 1.5rem;
    font-weight: 600;
  }

  & > div:nth-of-type(1) time:nth-child(1) span {
    font-size: 1rem;
    font-weight: 300;
  }

  ${media.lessThan('small')`
    flex-direction: column;
  `}
`;
const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 15px;
`;
const StyledButton = styled(Button)`
  font-size: 1rem;

  ${media.lessThan('small')`
    padding: .5rem 1rem;
    font-size: .875rem;
  `}
`;

const LessonDate = styled.div`
  margin-right: 5rem;
  text-align: center;

  time {
    display: block;
    font-size: 0.75rem;

    &:nth-of-type(1) {
      font-size: 1.5rem;
      font-weight: 600;

      span {
        font-size: 0.875rem;
        font-weight: 300;
      }
    }
  }

  ${media.lessThan('medium')`
    margin-right: 3rem;
  `}

  ${media.lessThan('small')`
    display: flex;
    align-items: center;
    margin: 0 0 1rem;

    time {
      &:nth-of-type(1) {
        margin-right: .25rem;
        font-size: 1.25rem;

        span {
          font-size: .75rem;
        }
      }
    }
  `}
`;
const Info = styled.div`
  flex: 1;
`;
