import {
  FILTER_PREFIX_NAME_CHAPTER,
  FILTER_PREFIX_NAME_PRACTICE,
  FILTER_PREFIX_VALUE_CHAPTER,
  FILTER_PREFIX_VALUE_PRACTICE,
  INITIAL,
  INITIAL_ULID,
} from '../const/Filter';

interface ChapterOrPracticeOption {
  type: string;
  id: string;
}

export const getChapterFilterOptionValue = (value: string): string => {
  return `${FILTER_PREFIX_VALUE_CHAPTER}_${value}`;
};

export const getChapterFilterOptionLabel = (value: string): string => {
  return `${FILTER_PREFIX_NAME_CHAPTER}${value}`;
};

export const getPracticeFilterOptionValue = (value: string): string => {
  return `${FILTER_PREFIX_VALUE_PRACTICE}_${value}`;
};

export const getPracticeFilterOptionLabel = (value: string): string => {
  return `${FILTER_PREFIX_NAME_PRACTICE}${value}`;
};

export const selectChapterOrPracticeOptionValue = (
  chapterId: string,
  practiceId: string,
): string => {
  if (parseInt(chapterId) > 0) {
    return getChapterFilterOptionValue(chapterId.toString());
  } else if (practiceId) {
    return getPracticeFilterOptionValue(practiceId);
  }

  return '';
};

export const chapterOrPracticeOptionValue = (value: string): [string, string] => {
  const option = convertChapterOrPracticeOption(value);
  if (option !== undefined) {
    const optionId = option.id;
    const optionType = option.type;

    if (isSelectedChapter(optionType)) {
      return [optionId, INITIAL_ULID];
    } else if (isSelectedPractice(optionType)) {
      return [INITIAL.toString(), optionId];
    }
  }

  return [INITIAL.toString(), INITIAL_ULID];
};

const convertChapterOrPracticeOption = (value: string): ChapterOrPracticeOption | undefined => {
  if (value === INITIAL.toString() || value === INITIAL_ULID) return undefined;

  const vals = value.split('_');
  if (vals.length < 2) {
    return undefined;
  }

  return { type: vals[0], id: vals[1] };
};

const isSelectedChapter = (type: string): boolean => {
  return type === FILTER_PREFIX_VALUE_CHAPTER;
};

const isSelectedPractice = (type: string): boolean => {
  return type === FILTER_PREFIX_VALUE_PRACTICE;
};
