import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';

import QaUser001 from '../../../static/image/lp/question001_qa_user.png';
import QaContent001 from '../../../static/image/lp/question001_qa_content.jpg';
import Answer001Content from '../../../static/image/lp/question001_answer_content.jpg';
import Answer001User001 from '../../../static/image/lp/question001_answer_user001.png';
import Answer001User002 from '../../../static/image/lp/question001_answer_user002.png';
import QaUser002 from '../../../static/image/lp/question002_qa_user.png';
import Answer002User001 from '../../../static/image/lp/question002_answer_user001.png';
import Answer002User002 from '../../../static/image/lp/question002_answer_user002.png';

export const LpQuestion = (): JSX.Element => {
  return (
    <Wrapper data-gtm="question">
      <Inner>
        <Ttl>
          <h2>現役エンジニアが回答するQ&A掲示板</h2>
          <p>Q&A</p>
        </Ttl>
        <Description>
          質問し放題なので<span>未経験からでも安心して</span>学習いただけます。
        </Description>
        <Column>
          <Num>01</Num>
          <ColumnTitle>「質問テンプレート」があるのでだれでも簡単に質問できます</ColumnTitle>
          <Sup>
            現役エンジニアが初学者目線で回答してくれるのでわからないことをそのままにせず学習を進めることができます。
          </Sup>
          <Flex>
            <FlexColumn>
              <Title>Q&A</Title>
              <FlexColumnInner>
                <QuestionTitle>画像のエラーについての質問</QuestionTitle>
                <QuestionUser>
                  <QuestionUserIcon image={QaUser001} />
                  <QuestionUserName>佐野 亮</QuestionUserName>
                </QuestionUser>
                <QuestionContent>
                  <QuestionDt>Q1.やりたい・やりたかったことは？</QuestionDt>
                  <QuestionDd>画像をうまく表示したい</QuestionDd>
                  <QuestionDt>Q2.起きてる問題やエラーメッセージ</QuestionDt>
                  <QuestionDd>画像が変に折り返されてしまった。</QuestionDd>
                  <QuestionDt>Q3. 関連するソースコード</QuestionDt>
                  <QuestionDd>
                    <img src={QaContent001} />
                  </QuestionDd>
                </QuestionContent>
              </FlexColumnInner>
            </FlexColumn>
            <FlexColumn>
              <Title>回答 3件</Title>
              <FlexColumnInner>
                <AnswerContent>
                  <AnswerUser>
                    <AnswerUserIcon image={Answer001User001} />
                    <AnswerUserBatch>講師</AnswerUserBatch>
                    <AnswerUserName>戸塚 健児</AnswerUserName>
                  </AnswerUser>
                  <AnswerComment>
                    <p>以下のようなCSSを割り当ててみてください！</p>
                    <img src={Answer001Content} />
                    <p>
                      .title-img&#123;&#125;は、imgタグの親要素ですので、imgタグそのものの幅には指定した幅は、反映されません。あくまで親要素の幅を決めてあげただけになります。
                    </p>
                    <p>
                      ですが、.title-img img
                      &#123;&#125;のように親要素であるtitle-img内のimgタグに対して、cssを割り当ててあげることで反映させることが可能になります。
                    </p>
                  </AnswerComment>
                </AnswerContent>
                <AnswerContent>
                  <AnswerUser>
                    <AnswerUserIcon image={Answer001User002} />
                    <AnswerUserName>佐野 亮</AnswerUserName>
                  </AnswerUser>
                  <AnswerComment>
                    <p>
                      できなかった原因としては単に.title-imgの中のimgタグに対して、プロパティを指定していなかっただけだからですね！
                    </p>
                    <p>理解できました。ご回答ありがとうございました！</p>
                  </AnswerComment>
                </AnswerContent>
              </FlexColumnInner>
            </FlexColumn>
          </Flex>
        </Column>
        <Column>
          <Num>02</Num>
          <ColumnTitle>「学習における”悩み”」も気軽に質問できます</ColumnTitle>
          <Sup>Google検索で解決できなかった抽象度の高い質問も、解決することができます。</Sup>
          <Flex>
            <FlexColumn>
              <Title>Q&A</Title>
              <FlexColumnInner>
                <QuestionTitle>
                  ホームページにとあるサイトのデータを自動で表示させたい
                </QuestionTitle>
                <QuestionUser>
                  <QuestionUserIcon image={QaUser002} />
                  <QuestionUserName>早瀬 静</QuestionUserName>
                </QuestionUser>
                <QuestionContent>
                  <QuestionDt>Q1.あなたの悩みはなんですか？</QuestionDt>
                  <QuestionDd>
                    タイトルの通りですが、HPにとあるサイトのデータを自動で取得し、それらを、一覧にしてリスト化したいです。どういった言語やスキルを学べば、実現可能でしょうか？抽象的な質問ですいません。
                  </QuestionDd>
                  <QuestionDt>Q2.それを解決するためにしたことはなんですか？</QuestionDt>
                  <QuestionDd>
                    Google検索してみましたが、いまいち欲しい情報に辿り着きませんでした。
                  </QuestionDd>
                </QuestionContent>
              </FlexColumnInner>
            </FlexColumn>
            <FlexColumn>
              <Title>回答 1件</Title>
              <FlexColumnInner>
                <AnswerContent>
                  <AnswerUser>
                    <AnswerUserIcon image={Answer002User001} />
                    <AnswerUserBatch>講師</AnswerUserBatch>
                    <AnswerUserName>吉永 弘大</AnswerUserName>
                  </AnswerUser>
                  <AnswerComment>
                    <p>
                      データを自動取得する方法はいくつかありますが、一番有名なのが「Webスクレイピング」という方法です。
                      <br />
                      詳細は省きますが、この技術を用いれば早瀬さんのやりたいことが実現できるのではないかと思います。
                      <br />
                      大体のプログラミング言語でWebスクレイピングはできるのですが、強いてオススメを挙げるならライブラリが充実しているPythonがオススメです。
                      <br />
                      まずは、Pythonを学習し、その後、スクレイピング関連のPythonのライブラリの使い方を学習するという順番が好ましいかと思います。
                    </p>
                  </AnswerComment>
                </AnswerContent>
                <AnswerContent>
                  <AnswerUser>
                    <AnswerUserIcon image={Answer002User002} />
                    <AnswerUserName>早瀬 静</AnswerUserName>
                  </AnswerUser>
                  <AnswerComment>
                    <p>
                      なるほど。こういった技術のことを「スクレイピング」というのですね。このキーワードをフックに色々調べてみます。ありがとうございました。
                    </p>
                  </AnswerComment>
                </AnswerContent>
              </FlexColumnInner>
            </FlexColumn>
          </Flex>
        </Column>
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 7.5rem 0;
  background: #f5f0ed;

  ${media.lessThan('medium')`
    padding: 4rem 0;
  `}
`;
const Description = styled.p`
  margin: 3rem auto;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;

  span {
    color: #eb0000;
    font-size: 1.5rem;
  }

  ${media.lessThan('medium')`
    margin: 2rem auto 3rem;
    font-size: 1.25rem;
    line-height: 1.6;

    span {
      font-size: 1em;
    }
  `}
`;
const Column = styled.div`
  padding: 3rem;
  background: #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  position: relative;
  overflow: hidden;

  &:nth-of-type(n + 2) {
    margin-top: 3rem;
  }

  ${media.lessThan('large')`
    padding-top: 4rem;
  `}

  ${media.lessThan('medium')`
    padding: 3.25rem 1rem 1rem;

    &:nth-of-type(n + 2) {
      margin-top: 1rem;
    }
  `}
`;
const Num = styled.p`
  padding-left: 3rem;
  color: #e2001b;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  top: 2rem;
  left: 0;

  &:before {
    content: '';
    display: block;
    width: 2rem;
    height: 0.125rem;
    margin: auto;
    background: #e2001b;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  ${media.lessThan('large')`
    top: 1.5rem;
  `}

  ${media.lessThan('medium')`
    padding-left: 1.5rem;
    font-size: 1.5rem;
    top: 1rem;

    &:before {
      width: 1rem;
    }
  `}
`;
const ColumnTitle = styled.h3`
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1.125rem;
    line-height: 1.6;
  `}
`;
const Sup = styled.p`
  margin: 1.5rem auto 3rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;

  ${media.lessThan('medium')`
    margin: 1rem auto 1.5rem;
    font-size: 0.875rem;
    text-align: left;
  `}
`;
const Flex = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
const FlexColumn = styled.div`
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  overflow: hidden;

  &:nth-of-type(n + 2) {
    margin-left: 1rem;
  }

  ${media.lessThan('medium')`
    border-radius: 1rem;

    &:nth-of-type(n + 2) {
      margin: 1rem auto 0;
    }
  `}
`;
const Title = styled.h4`
  padding: 1rem;
  background: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.25rem;

  ${media.lessThan('medium')`
    padding: 0.625rem;
    font-size: 1rem;
  `}
`;
const FlexColumnInner = styled.div`
  padding: 1rem;

  ${media.lessThan('medium')`
    padding: 1rem 0.625rem 0.625rem;
  `}
`;
const QuestionTitle = styled.h5`
  margin: 0 auto 0.75rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375;

  ${media.lessThan('medium')`
    margin-bottom: 0.5rem;
  `}
`;
const QuestionUser = styled.div`
  display: flex;
  align-items: center;
`;
const QuestionUserIcon = styled.div<{ image: string }>`
  width: 1.5rem;
  height: 1.5rem;
  background: #ccc url(${(props) => props.image}) center / cover no-repeat;
  border-radius: 50%;

  ${media.lessThan('medium')`
    width: 1.375rem;
    height: 1.375rem;
  `}
`;
const QuestionUserName = styled.p`
  margin-left: 0.375rem;
  font-size: 0.75rem;
  line-height: 1.4;
`;
const QuestionDt = styled.dt`
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;

  ${media.lessThan('medium')`
    font-size: 0.8125rem;
    line-height: 1.125rem;
  `}
`;
const QuestionDd = styled.dd`
  font-size: 0.75rem;
  line-height: 1rem;

  img {
    display: block;
    width: 100%;
  }

  ${media.lessThan('medium')`
    font-size: 0.8125rem;
    line-height: 1.125rem;
  `}
`;
const QuestionContent = styled.dl`
  margin: 0.75rem auto 0;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${QuestionDd} + ${QuestionDt} {
    margin-top: 1rem;
  }

  ${media.lessThan('medium')`
    margin-top: 0.625rem;
    padding-top: 0.625rem;

    ${QuestionDd} + ${QuestionDt} {
      margin-top: 1.5rem;
    }
  `}
`;
const AnswerContent = styled.dl`
  display: flex;

  &:nth-of-type(n + 2) {
    margin-top: 0.75rem;
  }

  ${media.lessThan('medium')`
    &:nth-of-type(n + 2) {
      margin-top: 1rem;
    }
  `}
`;
const AnswerUser = styled.dt`
  text-align: center;
`;
const AnswerUserIcon = styled.div<{ image: string }>`
  width: 3rem;
  height: 3rem;
  margin-bottom: 0.5rem;
  background: #ccc url(${(props) => props.image}) center / cover no-repeat;
  border-radius: 50%;

  ${media.lessThan('medium')`
    width: 2.625rem;
    height: 2.625rem;
    margin-bottom: 0.25rem;
  `}
`;
const AnswerUserBatch = styled.p`
  width: 2.25rem;
  margin: 0 auto 0.25rem;
  background: linear-gradient(95.07deg, #fd8258 11.86%, #e2001b 82.5%);
  border-radius: 1px;
  color: #fff;
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.4;
`;
const AnswerUserName = styled.p`
  font-size: 0.75rem;
  line-height: 1;
  letter-spacing: -0.06em;

  ${media.lessThan('medium')`
    font-size: 0.625rem;
  `}
`;
const AnswerComment = styled.dd`
  flex: 1;
  margin-left: 1.75rem;
  padding: 0.75rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3125rem;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.5rem 0.5625rem 0.5rem 0;
    position: absolute;
  }

  &:before {
    border-color: transparent rgba(0, 0, 0, 0.1) transparent transparent;
    top: 18px;
    left: -11px;
  }

  &:after {
    border-color: transparent #fff transparent transparent;
    top: 18px;
    left: -8px;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.35;
  }

  p + p {
    margin-top: 0.75rem;
  }

  img {
    display: block;
    width: 100%;
    max-width: 302px;
    margin: 0.75rem auto 0.75rem 0;
  }

  ${media.lessThan('medium')`
    margin-left: 0.625rem;
    padding: 0.5rem;

    &:before,
    &:after {
      border-width: 0.3125rem 0.4375rem 0.3125rem 0;
    }

    &:before {
      left: -9px;
    }

    &:after {
      left: -7px;
    }

    p {
      font-size: 0.8125rem;
    }

    p + p {
      margin-top: 0.5rem;
    }

    img {
      margin: 0.5rem auto 0.5rem 0;
    }
  `}
`;
