/* eslint @typescript-eslint/no-use-before-define: "off" */
import React, { useCallback, useState } from 'react';
import TagManager from 'react-gtm-module';
import { BasicLayout } from '../../templates/BasicLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../../redux/user/useUser';

import {
  QuestionForm,
  QuestionFormDefaultInputValues,
  QuestionFormHandler,
} from '../../organisms/QuestionForm';
import {
  AnswerRequestType,
  GetQuestionDocument,
  GetQuestionsDocument,
  QuestionInput,
  QuestionStatus,
  useGetQuestionQuery,
  useUpdateQuestionMutation,
} from '../../../gen/graphql';

import { Loader } from '../../molecules/Loader';
import { useToastsContext } from '../../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { NotFoundPage } from '../public/NotFound';
import { LOWER_META_TITLE } from '../../../const/Service';
import { getApiErrorMessage } from '../../../utils/graphqlError';

export const EditQuestionContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathQuestionID = Number(paramID);
  if (Number.isNaN(pathQuestionID)) return NotFoundPage;

  return <EditQuestion questionID={pathQuestionID} />;
};

interface EditQuestionProps {
  questionID: number;
}

const EditQuestion = (props: EditQuestionProps): JSX.Element => {
  const metaTitle = `質問編集フォーム | ${LOWER_META_TITLE}`;

  const navigate = useNavigate();
  const { user } = useUser();
  const { showToast } = useToastsContext();

  const [sending, setSending] = useState(false);
  const [sendingDraft, setSendingDraft] = useState(false);
  const [previousValues, setPreviousValues] = React.useState<QuestionInput>(
    QuestionFormDefaultInputValues,
  );
  const questionFormRef = React.useRef<QuestionFormHandler>(null);

  const { data, loading } = useGetQuestionQuery({
    variables: {
      questionID: props.questionID,
    },
    onCompleted: (data) => {
      const question = data.getQuestion;

      if (question?.user.id !== user.lmsUser?.id) {
        navigate(`/questions/my`);
      }

      if (question?.tags) {
        questionFormRef.current?.setTags(
          question.tags.map((tag) => {
            return { ...tag, value: tag.id };
          }),
        );
      }

      questionFormRef.current?.setTitle(question?.title ?? '');

      questionFormRef.current?.setContent(question?.content ?? '');

      if (question?.program && question?.chapter) {
        questionFormRef.current?.setProgramID(question.program.id);
        questionFormRef.current?.setChapterID(question.chapter.id);
      }

      if (question?.program && question?.practice) {
        questionFormRef.current?.setProgramID(question.program.id);
        questionFormRef.current?.setPracticeID(question.practice.id);
      }

      const previousValues = {
        title: question?.title ?? '',
        programId: question?.program?.id,
        chapterId: question?.chapter?.id,
        practiceId: question?.practice?.id,
        tagIds: question?.tags?.map((tag) => tag.id) ?? [],
        content: question?.content ?? '',
        status: question?.status ?? QuestionStatus.Valid,
      };

      setPreviousValues(previousValues);
      questionFormRef.current?.setDraftValues(previousValues);
    },
  });
  const question = data?.getQuestion;
  const isAnswerRequestTypeAI = question?.answerRequestType === AnswerRequestType.Ai;

  const [updateQuestion] = useUpdateQuestionMutation({
    refetchQueries: [GetQuestionDocument, GetQuestionsDocument],
  });

  const submit = useSafeAsyncCallback(
    useCallback(
      async (input: QuestionInput): Promise<void> => {
        setSending(true);

        try {
          await updateQuestion({
            variables: {
              id: props.questionID,
              input: input,
            },
          });

          TagManager.dataLayer({
            dataLayer: {
              userId: user.lmsUser?.id,
              user: user.lmsUser,
              event: 'gtm-qa-que-edit-sub',
              eventData: { questionID: props.questionID },
            },
            dataLayerName: 'LMSDataLayer',
          });

          navigate(`/question/detail/${props.questionID}`);
        } catch (e) {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          showToast(1, getApiErrorMessage(e));
          return;
        } finally {
          setSending(false);
        }
      },
      [navigate, props.questionID, showToast, updateQuestion, user.lmsUser],
    ),
  );

  const draft = useSafeAsyncCallback(
    useCallback(
      async (input: QuestionInput): Promise<void> => {
        setSendingDraft(true);

        try {
          await updateQuestion({
            variables: {
              id: props.questionID,
              input: input,
            },
          });
        } catch (e) {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          showToast(1, defaultErrorMessage);
          return;
        } finally {
          setSendingDraft(false);
        }

        setPreviousValues({ ...input });
        showToast(0, '下書きを保存しました。');
      },
      [props.questionID, showToast, updateQuestion],
    ),
  );

  return (
    <BasicLayout pageTitle="Q&A" metaTitle={metaTitle}>
      <Loader display={loading} />
      <QuestionForm
        sending={sending}
        sendingDraft={sendingDraft}
        onSubmit={submit}
        onDraft={draft}
        previousValues={previousValues}
        ref={questionFormRef}
        shouldShowImageAttentionModal={isAnswerRequestTypeAI}
      />
    </BasicLayout>
  );
};
