import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
}

export const ErrorText: React.FC<Props> = ({ className, children }): JSX.Element => (
  <StyledErrorText className={className}>{children}</StyledErrorText>
);

const StyledErrorText = styled.p`
  color: #eb0000;
  font-size: 0.75rem;
  font-weight: 700;
`;
