import { Either, left, right } from 'fp-ts/Either';

export class ParseError extends Error {}

export const safeParse: (strToParse: string) => Either<ParseError, number> = (
  strToParse: string,
) => {
  const n: number = parseInt(strToParse);
  return isNaN(n) ? left(new ParseError(`失敗対象の文字列は ${strToParse}`)) : right(n);
};
