import { StoreState } from '../../store';
import { CommonPaymentMethodActions, CommonPaymentMethodActionTypes } from './actions';

const initialState: StoreState['commonPaymentMethod'] = {
  paymentMethods: [],
  pagination: {
    total: 0,
    limit: 10,
    page: 1,
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const commonPaymentMethodReducer = (
  state: StoreState['commonPaymentMethod'] = initialState,
  action: CommonPaymentMethodActions,
) => {
  switch (action.type) {
    case CommonPaymentMethodActionTypes.fetchIndexSuccess:
      return {
        ...state,
        paymentMethods: action.payload.paymentMethods,
        pagination: {
          ...state.pagination,
          total: action.payload.pagination.total,
        },
      };
    case CommonPaymentMethodActionTypes.reset:
      return initialState;
    default:
      return state;
  }
};
