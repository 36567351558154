import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useGetPracticeQuery, useNewPracticeSubmitMutation } from '../../../gen/graphql';
import { NotFoundPage } from '../public/NotFound';
import {
  PracticeSubmitInputArea,
  PracticeSubmitResponseProps,
} from '../../organisms/PracticeSubmitInputArea';
import { useUser } from '../../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { Loader } from '../../molecules/Loader';
import { BasicLayout } from '../../templates/BasicLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { PracticeSubmitBreadcrumb } from '../../organisms/PracticeSubmitBreadcrumb';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';

export const CreatePracticeSubmitContainer = (): JSX.Element => {
  const pathID = useParams<{ practice_id: string }>().practice_id;
  if (!pathID) return NotFoundPage;

  return <CreatePracticeSubmit practiceID={pathID} />;
};

interface CreatePracticeSubmitProps {
  practiceID: string;
}

const CreatePracticeSubmit: React.FC<CreatePracticeSubmitProps> = (props): JSX.Element => {
  const metaTitle = `課題提出フォーム | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}の課題ページです。課題にチャレンジすることでアウトプット力が鍛えられスキルを定着させることができます。課題を単純に解くだけではなく、提出&レビューを受けることでより知識が磨かれます。`;

  const { permissionCheck } = useUser();

  const { data: practiceData, loading: practiceLoading } = useGetPracticeQuery({
    variables: {
      practiceID: props.practiceID,
    },
  });
  const practice = practiceData?.practice ?? undefined;

  const [newPracticeSubmit] = useNewPracticeSubmitMutation();
  const draft = useCallback(
    async (url: string, comment?: string): Promise<PracticeSubmitResponseProps> => {
      const { data } = await newPracticeSubmit({
        variables: {
          input: {
            practiceID: props.practiceID,
            url: url,
            content: comment,
            isDraft: true,
          },
        },
      });

      // 成功しているのにデータがないことはありえないが、型的に null | undefined ありなのでチェック
      if (
        !data ||
        !data.newPracticeSubmit ||
        !data.newPracticeSubmit.practiceSubmitDetails ||
        data.newPracticeSubmit.practiceSubmitDetails.length === 0
      ) {
        return { isError: true, practiceSubmitID: '', practiceSubmitDetailID: '' };
      }

      return {
        isError: false,
        practiceSubmitID: data.newPracticeSubmit.id,
        practiceSubmitDetailID: data.newPracticeSubmit.practiceSubmitDetails[0].id,
      };
    },
    [newPracticeSubmit, props.practiceID],
  );
  const submit = useCallback(
    async (url: string, comment?: string): Promise<PracticeSubmitResponseProps> => {
      const { data } = await newPracticeSubmit({
        variables: {
          input: {
            practiceID: props.practiceID,
            url: url,
            content: comment,
            isDraft: false,
          },
        },
      });

      // 成功しているのにデータがないことはありえないが、型的に null | undefined ありなのでチェック
      if (!data || !data.newPracticeSubmit) {
        return { isError: true, practiceSubmitID: '', practiceSubmitDetailID: '' };
      }

      return {
        isError: false,
        practiceSubmitID: data.newPracticeSubmit.id,
        practiceSubmitDetailID: '',
      };
    },
    [newPracticeSubmit, props.practiceID],
  );

  return (
    <>
      <Loader display={practiceLoading} />
      <BasicLayout
        pageTitle="課題提出フォーム"
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <PageWrapper>
          <PracticeSubmitBreadcrumb
            permission={permissionCheck(
              FunctionType.ProgramForInstructorAndCoach,
              PermissionType.Read,
            )}
            programTitle={practice?.program.title}
            programID={practice?.programID}
            practiceTitle={practice?.title}
            practiceID={practice?.id}
          />
          {practice ? (
            <PracticeSubmitInputArea practice={practice} draft={draft} submit={submit} />
          ) : (
            <EmptyBlock title="提出できる課題がありません" />
          )}
        </PageWrapper>
      </BasicLayout>
    </>
  );
};
