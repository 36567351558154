import React, { useMemo } from 'react';
import styled from 'styled-components';
import UserType, { UserTypeLabels } from '../../const/UserType';
import { IconUserFragment, ChatMemberUserFragment } from '../../gen/graphql';
import { User as TsUser } from '../../swagger/gen/ts_front';
import { getUserType } from '../../utils/UserType';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface RoleLabelProps {
  user?: IconUserFragment | ChatMemberUserFragment | TsUser;
  className?: string;
}

export const RoleLabel: React.FC<RoleLabelProps> = (props) => {
  const { permissionCheck } = useUser();
  const type = useMemo(() => {
    return getUserType(
      props.user,
      permissionCheck(FunctionType.UserTypeLabel, PermissionType.Read),
    );
  }, [props.user, permissionCheck]);

  return type ? (
    <Label className={props.className} userType={type}>
      {UserTypeLabels[type]}
    </Label>
  ) : (
    <></>
  );
};

const Label = styled.span<{ userType?: string }>`
  display: inline-block;
  padding: 0 0.5rem;
  border-radius: 1px;
  cursor: default;
  color: #fff;
  font-size: 0.625rem;
  line-height: 0.875rem;

  ${(props) =>
    props.userType && props.userType === UserType.Admin
      ? 'background: #fd3c2f;'
      : props.userType === UserType.Coach
        ? 'background: #fd3c2f;'
        : props.userType === UserType.Instructor
          ? 'background: #fd3c2f;'
          : props.userType === UserType.SnsUser
            ? 'background: #ff5e5e;'
            : props.userType === UserType.Student
              ? 'background: #eb0000;'
              : props.userType === UserType.Team
                ? 'background: #2a9d8f;'
                : props.userType === UserType.AI
                  ? 'background: #0ea882;'
                  : ''}
`;
