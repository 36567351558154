import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format, parseISO } from 'date-fns';

import { TeamSettingTabLayout } from '../../templates/TeamSettingTabLayout';
import { TeamSettingBreadcrumb } from '../../templates/TeamSettingBreadcrumb';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Button } from '../../atoms/Button';
import { Loader } from '../../molecules/Loader';
import { TeamChangePeopleNumModal } from '../../organisms/TeamChangePeopleNumModal';
import { TeamCancelModal } from '../../organisms/TeamCancelModal';
import { SubscriptionContractList } from '../../organisms/SubscriptionContractList';

import { useUser } from '../../../redux/user/useUser';
import { FIRST_PAGE, MIN_LIMIT } from '../../../const/Page';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../../context/ToastsProvider';
import { StripeStatusLabels } from '../../../const/Stripe';
import {
  PaymentMethodType,
  useGetTeamQuery,
  useGetTeamSubscriptionQuery,
  useTeamSubscriptionContractsQuery,
  useTeamSwitchCancelAtPeriodEndSubscriptionMutation,
  useTeamUpdateSubscriptionMutation,
  useGetTeamMembersByTeamIdQuery,
} from '../../../gen/graphql';
import { useNavigate } from 'react-router-dom';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { useURL } from '../../../common/customHooks/URL';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { LOWER_META_TITLE } from '../../../const/Service';

export const TeamSubscription: React.FC = () => {
  const metaTitle = `加入プラン | ${LOWER_META_TITLE}`;

  const formatDate = (date: string, formatType: string) => {
    return format(parseISO(date), formatType);
  };

  const navigate = useNavigate();

  const { user, permissionCheck } = useUser();
  const teamID = user?.teamID ?? '';
  const { showToast } = useToastsContext();

  const { setParams, queries } = useURL();

  const page = useMemo(() => (queries?.page ? Number(queries.page) : FIRST_PAGE), [queries?.page]);

  const setPage = useCallback(
    (value: number) => {
      setParams([{ name: 'page', value: value.toString() }]);
    },
    [setParams],
  );

  const [isOpenPeopleNumModal, setIsOpenPeopleNumModal] = React.useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = React.useState(false);
  const [
    switchCancelAtPeriodEndSubscriptionLoading,
    setSwitchCancelAtPeriodEndSubscriptionLoading,
  ] = React.useState(false);
  const [teamUpdateSubscriptionLoading, setTeamUpdateSubscriptionLoading] = React.useState(false);

  const {
    data: teamData,
    loading: teamLoading,
    refetch: refetchTeam,
  } = useGetTeamQuery({
    // 申し込み後再読み込みのためにキャッシュしない
    fetchPolicy: 'no-cache',
    variables: {
      id: teamID ?? '',
    },
    skip: !teamID,
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
  });
  const paymentMethodType = teamData?.team.paymentMethodType ?? undefined;

  const {
    data: contractData,
    loading: contractLoading,
    refetch: refetchContracts,
  } = useTeamSubscriptionContractsQuery({
    // 申し込み後再読み込みのためにキャッシュしない
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        teamID: user.teamID ?? '',
        limit: MIN_LIMIT,
        page: page,
      },
    },
    skip: !user.teamID,
    notifyOnNetworkStatusChange: true,
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
  });
  const contracts = contractData?.teamSubscriptionContracts.items ?? [];
  const total = contractData?.teamSubscriptionContracts.total ?? 0;
  const currentSubscriptionContract = (contracts.length ?? 0) > 0 ? contracts[0] : null;
  const cancelReserved = currentSubscriptionContract?.canceledAt ? true : false;

  const {
    data: subscriptionData,
    refetch: refetchSubscription,
    loading: subscriptionLoading,
  } = useGetTeamSubscriptionQuery({
    // 申し込み後再読み込みのためにキャッシュしない
    fetchPolicy: 'no-cache',
    variables: {
      teamID: teamID,
    },
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
  });
  const subscription = subscriptionData?.teamSubscription;
  const scheduledSubscriptionContract =
    subscriptionData?.teamScheduledSubscriptionContract ?? undefined;

  const {
    data: teamMemberData,
    loading: teamMemberLoading,
    refetch: refetchTeamMembers,
  } = useGetTeamMembersByTeamIdQuery({
    variables: {
      input: {
        teamID: teamID ?? '',
        // Total件数だけひつようなため1で指定
        page: 1,
        limit: 1,
      },
    },
  });
  const joinedTeamMemberNum = teamMemberData?.getTeamMembersByTeamID.total ?? 0;

  const fetchSubscriptionData = useCallback(async (): Promise<void> => {
    await refetchSubscription();
    await refetchContracts();
    await refetchTeam();
    await refetchTeamMembers();
  }, [refetchSubscription, refetchContracts, refetchTeam, refetchTeamMembers]);

  const [teamSwitchCancelAtPeriodEndSubscriptionMutation] =
    useTeamSwitchCancelAtPeriodEndSubscriptionMutation({
      onCompleted: () => {
        fetchSubscriptionData();
      },
    });
  const submitCancelAtPeriod = useSafeAsyncCallback(async () => {
    setSwitchCancelAtPeriodEndSubscriptionLoading(true);

    try {
      await teamSwitchCancelAtPeriodEndSubscriptionMutation({
        variables: {
          id: teamID,
        },
      });
    } catch {
      showToast(1, defaultErrorMessage);
      return;
    } finally {
      setSwitchCancelAtPeriodEndSubscriptionLoading(false);
      setIsOpenCancelModal(false);
    }
    cancelReserved
      ? showToast(0, '解約申請のキャンセルに成功しました。')
      : showToast(0, '解約の申請に成功しました。');
  });

  const [teamUpdateSubscriptionMutation] = useTeamUpdateSubscriptionMutation({
    onCompleted: () => {
      fetchSubscriptionData();
    },
  });
  const updateSubscription = useSafeAsyncCallback(async (num: number) => {
    setTeamUpdateSubscriptionLoading(true);

    try {
      await teamUpdateSubscriptionMutation({
        variables: {
          teamID: teamID,
          input: {
            quantity: num,
          },
        },
        onCompleted: () => {
          fetchSubscriptionData();
        },
      });
    } catch (e) {
      showToast(1, getApiErrorMessage(e));
      return;
    } finally {
      setTeamUpdateSubscriptionLoading(false);
      setIsOpenPeopleNumModal(false);
    }

    // NOTE: WebhookUpdateのため即時更新ができないため、更新に時間がかかる旨表示
    showToast(0, '契約人数の変更に成功しました。反映までいましばらくお待ちください。');
  });

  const showLoader =
    teamLoading ||
    contractLoading ||
    subscriptionLoading ||
    switchCancelAtPeriodEndSubscriptionLoading ||
    teamUpdateSubscriptionLoading ||
    teamMemberLoading;

  return (
    <>
      <Loader display={showLoader} />
      {!!subscription && (
        <>
          <TeamChangePeopleNumModal
            isOpen={isOpenPeopleNumModal}
            onClose={() => setIsOpenPeopleNumModal(false)}
            teamID={teamID}
            updateSubscription={updateSubscription}
            peopleNum={subscription.providerQuantity}
            joinedTeamMemberNum={joinedTeamMemberNum}
            paymentMethodType={paymentMethodType}
          />
          <TeamCancelModal
            isOpen={isOpenCancelModal}
            onClose={() => setIsOpenCancelModal(false)}
            cancelSubscription={submitCancelAtPeriod}
            isPeriod={cancelReserved}
          />
        </>
      )}
      <TeamSettingTabLayout activeTab="subscription" metaTitle={metaTitle}>
        <PageWrapper>
          <TeamSettingBreadcrumb pageName="加入プラン" />
          <Container>
            <ContentTitle>現在の契約</ContentTitle>
            {subscription && currentSubscriptionContract ? (
              <>
                <Row>
                  <dt>ご利用中のプラン</dt>
                  <dd>{subscription.price?.product.name}</dd>
                </Row>
                <Row>
                  <dt>契約期間</dt>
                  <dd>
                    {formatDate(currentSubscriptionContract.periodStart, 'yyyy/MM/dd HH:mm')} ~{' '}
                    {formatDate(currentSubscriptionContract.periodEnd, 'yyyy/MM/dd HH:mm')}
                  </dd>
                </Row>
                <Row>
                  <dt>お支払い方法</dt>
                  <dd>
                    {paymentMethodType === PaymentMethodType.CreditCard
                      ? 'クレジットカード払い'
                      : paymentMethodType === PaymentMethodType.Bank
                        ? '銀行振込 (請求書払い)'
                        : ''}
                  </dd>
                </Row>
                <Row>
                  <dt>支払いステータス</dt>
                  {/* TODO api側から返ってくるstatusが大文字になったら.toUpperCase()を削除 */}
                  <dd>{StripeStatusLabels[subscription.providerStatus.toUpperCase()]}</dd>
                </Row>
                <Text>
                  ※こちらにマンツーマンレッスンの契約内容は記載されておりません。
                  <br />
                  ※「特定商取引法に基づく表記」は
                  <a href="https://www.sejuku.net/corp/legal" target="_blank">
                    こちら
                  </a>
                  をご覧ください。
                </Text>
                <Row>
                  <dt>利用人数</dt>
                  <dd>
                    <ButtonRow>
                      <strong>{subscription.providerQuantity}人</strong>
                      {permissionCheck(FunctionType.TeamSubscription, PermissionType.Update) && (
                        <StyledButton onClick={() => setIsOpenPeopleNumModal(true)}>
                          人数を変更する
                        </StyledButton>
                      )}
                    </ButtonRow>
                  </dd>
                </Row>
                <Hr />
                <ButtonRow>
                  <Text>
                    ※現在のプランは記載の契約期間まで有効です。期間満了時に自動的に契約が継続されます。
                    <br />
                    ※解約申請後も、契約期間終了までサービスのご利用は可能です。
                  </Text>
                  {permissionCheck(FunctionType.TeamSubscription, PermissionType.Delete) && (
                    <StyledButton onClick={() => setIsOpenCancelModal(true)}>
                      {cancelReserved ? '解約申請をキャンセルする' : '解約申請する'}
                    </StyledButton>
                  )}
                </ButtonRow>
              </>
            ) : scheduledSubscriptionContract ? (
              <>
                <Row>
                  <dt>ご契約予定のプラン</dt>
                  <dd>{scheduledSubscriptionContract.product?.name}</dd>
                </Row>
                <Row>
                  <dt>契約期間</dt>
                  <dd>
                    {formatDate(scheduledSubscriptionContract.startDate, 'yyyy/MM/dd HH:mm')} ~{' '}
                    {formatDate(scheduledSubscriptionContract.endDate, 'yyyy/MM/dd HH:mm')}
                  </dd>
                </Row>
                <Row>
                  <dt>お支払い方法</dt>
                  <dd>
                    {paymentMethodType === PaymentMethodType.CreditCard
                      ? 'クレジットカード払い'
                      : paymentMethodType === PaymentMethodType.Bank
                        ? '銀行振込 (請求書払い)'
                        : ''}
                  </dd>
                </Row>
                <Row>
                  <dt>利用人数</dt>
                  <dd>
                    <ButtonRow>
                      <strong>{scheduledSubscriptionContract.quantity}人</strong>
                    </ButtonRow>
                  </dd>
                </Row>
              </>
            ) : (
              <EmptyContent>
                <p>お客様は現在サブスクリプションに登録されていません</p>
                {permissionCheck(FunctionType.TeamSubscription, PermissionType.Create) && (
                  <>
                    <ResumeButton onClick={() => navigate('/team/subscription/payment')}>
                      利用再開を行う
                    </ResumeButton>
                  </>
                )}
                <p className="note">
                  「特定商取引法に基づく表記」は
                  <a href="https://www.sejuku.net/corp/legal" target="_blank">
                    こちら
                  </a>
                  をご覧ください。
                </p>
              </EmptyContent>
            )}
          </Container>
          <SubscriptionContractList
            contracts={contracts}
            total={total}
            page={page}
            perPage={MIN_LIMIT}
            setPage={setPage}
          />
        </PageWrapper>
      </TeamSettingTabLayout>
    </>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 1rem auto 0;
  padding: 2rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;

  &:nth-of-type(n + 2) {
    margin-top: 2rem;
  }

  ${media.lessThan('medium')`
    width: calc(100% + 2rem);
    margin: 1rem -1rem 0;
  `}
`;
const ContentTitle = styled.h2`
  margin-bottom: 3rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
  `}
`;
const Row = styled.dl`
  display: flex;
  align-items: center;
  margin: 2rem auto;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.375rem;

  dt {
    width: 186px;
    font-weight: 700;
  }

  dd {
    flex: 1;

    strong {
      font-weight: 700;
    }
  }

  ${media.lessThan('medium')`
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;

    dt {
      width: 100%;
    }

    dd {
      width: 100%;
    }
  `}
`;
const Hr = styled.hr`
  margin: 2rem auto;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
const Text = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem;
  line-height: 1.25rem;

  a {
    color: #1a0dab;
    font-size: 1em;
    text-decoration: underline;
  }

  ${media.lessThan('medium')`
    line-height: 1rem;
  `}
`;
const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  ${Text} {
    flex: 1;
  }

  ${media.lessThan('medium')`
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  `}
`;
const StyledButton = styled(Button)`
  min-width: 128px;
  padding: 0.625rem 1rem;
  background: #fff;
  border: 1px solid #eb0000;
  color: #eb0000;
  line-height: 1.125rem;
  transition: all 0.2s;

  &:hover {
    background: #eb0000;
    color: #fff;
  }

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 320px;
  `}
`;

const EmptyContent = styled.div`
  p {
    line-height: 1.375;
    text-align: center;

    &.note {
      margin-top: 2rem;
      padding-left: 0.5rem;
      font-size: 0.75rem;
      line-height: 1.25rem;
      text-align: left;
      position: relative;

      &:before {
        content: '※';
        position: absolute;
        top: 0;
        left: 0;
      }

      a {
        color: #eb0000;
        font-size: 1em;
      }
    }
  }
`;
const ResumeButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 3rem auto 0;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
`;
