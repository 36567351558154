import React, { useState } from 'react';
import media from 'styled-media-query';
import styled from 'styled-components';

import { format, parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';

import { EmptyBlock } from '../molecules/EmptyBlock';

import ScrollHint from '../../static/image/scroll_hint.png';

import {
  useGetClaimAndDetailsForInstructorQuery,
  ClaimDetailFragment as ClaimDetail,
  ClaimFragment as Claim,
} from '../../gen/graphql';
import { insertCommaDelimiter } from '../../utils/common';
import { crop } from '../../utils/String';

// 日付表示系処理
const formatDateWithDay = (d: string): string => format(parseISO(d), 'yyyy/MM/dd');
const formatDays = (d: string): string => format(parseISO(d), 'E', { locale: ja });
const formatHourMinuteSecond = (d: string): string => format(parseISO(d), 'hh:mm:ss');

interface ClaimTableProps {
  claimData: ReturnType<typeof useGetClaimAndDetailsForInstructorQuery>['data'];
  isEditable: (claim: Claim | undefined) => boolean;
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentClaimDetail: React.Dispatch<React.SetStateAction<ClaimDetail | undefined>>;
}

/**
 * 請求一覧
 */
export const ClaimTable: React.FC<ClaimTableProps> = ({
  claimData,
  isEditable,
  setEditModalOpen,
  setDeleteModalOpen,
  setCurrentClaimDetail,
}) => {
  const [displayScrollHint, setDisplayScrollHint] = useState(true);

  const claim = claimData?.claimForInstructor ?? undefined;
  const claimDetails = claimData?.claimDetailsForInstructor ?? [];

  if (claimDetails.length === 0) {
    return <EmptyContent title="現在表示できる請求情報はありません" />;
  }

  return (
    <TableContainer
      onScroll={() => setDisplayScrollHint(false)}
      displayScrollHint={displayScrollHint}
    >
      <TableWrapper className="list">
        <Dl>
          <Dt>日付</Dt>
          <Dt>カテゴリー</Dt>
          <Dt>受講生</Dt>
          <Dt>専属レッスン</Dt>
          <Dt>単発レッスン開始時間</Dt>
          <Dt>
            時間(分)・ <br />
            件数・ <br />
            数量・文字数
          </Dt>
          <Dt>
            単価(円/時)・ <br />
            単価(円/件)・ <br />
            単価(円/文字)
          </Dt>
          <Dt>追加費用</Dt>
          <Dt>小計</Dt>
          <Dt>備考</Dt>
          <Dt>編集</Dt>
          <Dt>削除</Dt>
        </Dl>
        {claimDetails.map((claimDetail) => {
          return (
            <Dl key={claimDetail.id}>
              <Dd>{`${formatDateWithDay(claimDetail.date)}(${formatDays(claimDetail.date)})`}</Dd>
              <Dd>{claimDetail?.claimCategory?.name}</Dd>
              <Dd>{claimDetail?.course?.student?.user?.maskedPersonalInfo?.name ?? '-'}</Dd>
              <Dd>{claimDetail?.lesson?.title ?? '-'}</Dd>
              <Dd>
                {claimDetail?.spotLesson?.startAt
                  ? formatHourMinuteSecond(claimDetail.spotLesson.startAt)
                  : '-'}
              </Dd>
              <Dd>{claimDetail?.amount ?? '-'}</Dd>
              <Dd>{insertCommaDelimiter(String(claimDetail.unitPrice))} 円</Dd>
              <Dd>
                {claimDetail.additionalPayment
                  ? insertCommaDelimiter(String(claimDetail.additionalPayment)) + ' 円'
                  : '-'}
              </Dd>
              <Dd>{insertCommaDelimiter(String(claimDetail.subTotal))} 円</Dd>
              <Dd>{crop(claimDetail.remark ?? '', 10)}</Dd>
              <Dd>
                {isEditable(claim) && (
                  <Edit
                    onClick={() => {
                      setCurrentClaimDetail(claimDetail);
                      setEditModalOpen(true);
                    }}
                  >
                    <EditIcon />
                  </Edit>
                )}
              </Dd>
              <Dd
                className="delete"
                onClick={() => {
                  if (!isEditable(claim)) return false;
                  setCurrentClaimDetail(claimDetail);
                  setDeleteModalOpen(true);
                }}
              >
                {isEditable(claim) && (
                  <Edit>
                    <DeleteIcon />
                  </Edit>
                )}
              </Dd>
            </Dl>
          );
        })}
      </TableWrapper>
    </TableContainer>
  );
};

const EditIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_379_67152)">
        <path
          d="M7 17V9.93L13.93 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V10.07L14.07 17H7Z"
          fill="#EB0000"
        />
        <path d="M9 15H13.24L20.44 7.80001L16.2 3.56001L9 10.76V15Z" fill="#EB0000" />
        <path
          d="M22.91 2.49L21.5 1.08C20.72 0.300003 19.45 0.300003 18.67 1.08L17.61 2.14L21.85 6.38L22.91 5.32C23.7 4.54 23.7 3.27 22.91 2.49Z"
          fill="#EB0000"
        />
      </g>
      <defs>
        <clipPath id="clip0_379_67152">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const DeleteIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_379_67149)">
        <path
          d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
          fill="#EB0000"
        />
      </g>
      <defs>
        <clipPath id="clip0_379_67149">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const TableContainer = styled.div<{ displayScrollHint: boolean }>`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  position: relative;

  ${(props) =>
    props.displayScrollHint &&
    `
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 126px;
      max-height: 70%;
      margin: auto;
      background: url(${ScrollHint}) center / contain no-repeat;
      position: absolute;
      top: 15%;
      left: 0;
    }
  `}

  ${media.lessThan('medium')`
    margin: 1rem -1rem 0;
    padding: 0 1rem;
    border-top: none;
  `}
`;
const TableWrapper = styled.div`
  width: 76rem;

  ${media.lessThan('medium')`
    padding: .5rem;
    border: 1px solid rgba(0,0,0,.36);
    border-radius: .125rem;
  `}
`;
const Dl = styled.dl`
  display: grid;
  grid-template-columns: 6.75rem 6.25rem 6rem 7rem 10.5rem 7rem 7rem 5.25rem 5rem 8.25rem 3.5rem 3.5rem;

  &:nth-of-type(2n) {
    background: rgba(0, 0, 0, 0.05);
  }
`;
const Dt = styled.dt`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.75rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  line-height: 1.25rem;

  &:nth-last-of-type(n + 2) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
const Dd = styled.dd`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;

  &:nth-last-of-type(n + 2) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
const Edit = styled.p`
  width: 100%;
  cursor: pointer;

  svg {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 auto;
  }
`;
const EmptyContent = styled(EmptyBlock)`
  margin-top: 1rem;
`;
