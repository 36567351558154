import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { useUser } from '../../../redux/user/useUser';
import { LOWER_META_TITLE } from '../../../const/Service';
import { useGetCoursesForInstructorQuery, CourseSearchType } from '../../../gen/graphql';
import differenceInDaysGreaterThanZero from '../../../utils/DateCalc';

import { BasicLayout } from '../../templates/BasicLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';

import defaultIcon from '../../../static/image/icon_default.svg';
import ScrollHint from '../../../static/image/scroll_hint.png';
import { FunctionType, PermissionType } from '../../../const/UserPermission';

const zeroPadding = (num: number) => `0${num}`.substr(-2);
const contractType = [
  '-',
  '~2020年5月契約書(期間)',
  '2020年5月~契約書(レッスン回数)',
  '2021年1月~契約書(休学廃止)',
];
const courseStatus = [
  '削除',
  '受講中',
  '初回レッスン日待ち',
  'アサイン待ち',
  'インスト交代中',
  '休学中',
  '卒業済み',
  '解約',
  '休学/インスト交代中',
  '解約中',
];

export const InstructorCourseIndex: React.FC = () => {
  const metaTitle = `受講生一覧 | ${LOWER_META_TITLE}`;

  const [sortKey, setSortKey] = useState<number>();
  const [displayScrollHint, setDisplayScrollHint] = useState(true);
  const { permissionCheck } = useUser();

  const { data } = useGetCoursesForInstructorQuery({
    variables: {
      input: {
        type: permissionCheck(FunctionType.CourseSearchTypeAll, PermissionType.Read)
          ? CourseSearchType.All
          : CourseSearchType.Coaching,
        status: sortKey ? [sortKey] : undefined,
      },
    },
  });

  const statuses = [
    'すべて',
    '受講中',
    '初回レッスン待ち',
    'アサイン待ち',
    'インスト交代中',
    '休学中',
    '卒業済み',
    '解約',
    '休学/インスト交代中',
    '解約中',
  ];

  return (
    <BasicLayout pageTitle="受講生一覧" metaTitle={metaTitle}>
      <PageWrapper>
        <Notice>
          最終レッスン終了後、
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeSILei7P43OJ1kDPXpb-cOPSssc1KAMVTsH7-1ovPDQ_llrw/viewform"
          >
            こちら
          </a>
          から卒業レポートをご提出ください。
        </Notice>

        <SelectWrapper>
          <Select
            name="status"
            id="status"
            onChange={({ target: { value } }) => setSortKey(Number(value))}
          >
            {statuses.map((status, index) => {
              return (
                <option key={index} value={index}>
                  {status}
                </option>
              );
            })}
          </Select>
        </SelectWrapper>

        {data?.coursesForInstructor?.length ? (
          <>
            <Total>全{data?.coursesForInstructor?.length ?? 0}名</Total>
            <TableWrapper
              onScroll={() => setDisplayScrollHint(false)}
              displayScrollHint={displayScrollHint}
            >
              <DataTitle>
                <Dt></Dt>
                <Dt>氏名</Dt>
                <Dt>契約コース</Dt>
                <Dt>レッスン時間上限</Dt>
                <Dt>契約書タイプ</Dt>
                <Dt>カリキュラム</Dt>
                <Dt>受講開始日</Dt>
                <Dt>受講終了日</Dt>
                <Dt>
                  卒業までの
                  <br />
                  日数
                </Dt>
                <Dt>ステータス</Dt>
                <Dt>
                  総レッスン
                  <br />
                  回数
                </Dt>
                <Dt>
                  実施済み
                  <br />
                  レッスン回数
                </Dt>
                <Dt>
                  総セッション
                  <br />
                  回数
                </Dt>
                <Dt>
                  実施済み
                  <br />
                  セッション回数
                </Dt>
              </DataTitle>
              {data?.coursesForInstructor?.map((course) => {
                const startDate = course.startDate ? new Date(course.startDate) : null;
                const sdYear = startDate ? startDate.getFullYear() : '';
                const sdMonth = startDate ? zeroPadding(startDate.getMonth() + 1) : '';
                const sdDate = startDate ? zeroPadding(startDate.getDate()) : '';

                const endDate = course.endDate ? new Date(course.endDate) : null;
                const year = endDate ? endDate.getFullYear() : '';
                const month = endDate ? zeroPadding(endDate.getMonth() + 1) : '';
                const date = endDate ? zeroPadding(endDate.getDate()) : '';
                return (
                  <Data key={course.id}>
                    <Dd>
                      <Link to={`/courses/${course.id}`}>
                        <Icon>
                          <img
                            src={
                              course.student?.user?.image ? course.student.user.image : defaultIcon
                            }
                          />
                        </Icon>
                      </Link>
                    </Dd>
                    <Dd>
                      <Link to={`/courses/${course.id}`}>
                        {`${course.student?.user?.maskedPersonalInfo?.name}`}
                      </Link>
                    </Dd>
                    <Dd>{course.plan?.name}</Dd>
                    <Dd>{course.lessonMinutesUpperLimit}</Dd>
                    <Dd>{course.contractType ? contractType[course.contractType] : '-'}</Dd>
                    <Dd>
                      {course.curriculum ? (
                        <a target="_blank" href={course.curriculum}>
                          カリキュラム
                        </a>
                      ) : (
                        '-'
                      )}
                    </Dd>
                    <Dd>{`${sdYear}/${sdMonth}/${sdDate}`}</Dd>
                    <Dd>{`${year}/${month}/${date}`}</Dd>
                    <Dd>{differenceInDaysGreaterThanZero(endDate)}</Dd>
                    <Dd>{course.status ? courseStatus[course.status] : ''}</Dd>
                    <Dd>{course.plannedLessonCount}</Dd>
                    <Dd>{course.finishedLessonCount}</Dd>
                    <Dd>{course.coaching?.plannedSessionCount ?? '-'}</Dd>
                    <Dd>{course.coaching?.finishedSessionCount ?? '-'}</Dd>
                    {/* 初期リリースではQ&Aは無し */}
                    {/* <Dd>
                      <Link to="/instructor/question/my">
                        <Question>質問一覧</Question>
                      </Link>
                    </Dd> */}
                    {/* 初期リリースではchat無し */}
                    {/* <Dd>
                      <Link to="/instructor/chat">
                        <Chat>メッセージ</Chat>
                      </Link>
                    </Dd> */}
                  </Data>
                );
              })}
            </TableWrapper>
          </>
        ) : (
          <EmptyContent title="該当する受講生が存在しません。" />
        )}
      </PageWrapper>
    </BasicLayout>
  );
};

const Notice = styled.div`
  max-width: 820px;
  padding: 1.25rem 2rem;
  background-color: rgba(253, 60, 47, 0.05);

  a {
    color: #e2001b;
  }

  ${media.lessThan('small')`
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1.4;

    a {
      font-size: 0.875rem;
    }
  `}
`;

const SelectWrapper = styled.div`
  margin-top: 2rem;
`;
const Select = styled.select`
  appearance: none;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  line-height: 1em;
  border-radius: 0px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-color: #fff;
`;

const Total = styled.p`
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 800;
`;

const TableWrapper = styled.div<{ displayScrollHint: boolean }>`
  margin-top: 2rem;
  overflow-x: auto;
  position: relative;

  ${(props) =>
    props.displayScrollHint &&
    `
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 126px;
      max-height: 70%;
      margin: auto;
      background: url(${ScrollHint}) center / contain no-repeat;
      position: absolute;
      top: 15%;
      left: 0;
      pointer-events: none;
    }
  `}
`;

const Data = styled.dl`
  display: grid;
  align-items: center;
  grid-template-columns: 2rem 7rem 7rem 5.5rem 5.5rem 5rem 5rem 5rem 5.5rem 5.5rem 5.5rem 5.5rem 6.25rem;
  gap: 1rem;
  width: 82.25rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
const Dd = styled.dd`
  grid-row: 1 / 2;
  font-size: 0.75rem;
  line-height: 1.125rem;
  word-break: break-all;

  a {
    color: #e2001b;
    font-size: 1em;
    font-weight: 500;
  }
`;

const DataTitle = styled(Data)`
  padding: 0;
  border-bottom: none;
`;
const Dt = styled.dt`
  grid-row: 1 / 2;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const EmptyContent = styled(EmptyBlock)`
  margin-top: 2rem;
`;
