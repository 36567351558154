import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { CheckBox } from '../atoms/CheckBox';
import { Button } from '../atoms/Button';
import { Radio } from '../atoms/Radio';
import { TextArea, TextAreaHandler } from '../atoms/TextArea';
import { Modal } from '../molecules/Modal';

import {
  useUpsertLessonReportForInstructorMutation,
  LessonReportInput,
  useGetLessonAssistancesQuery,
  useGetLessonForInstructorLazyQuery,
} from '../../gen/graphql';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../context/ToastsProvider';
import { defaultErrorMessage } from '../../const/ErrorMessage';

interface HistoryModalProps {
  isOpen: boolean;
  lessonID: number;
  refetch: () => Promise<void>;
  onClose: () => void;
  loading?: boolean;
}

export const HistoryModal: React.FC<HistoryModalProps> = ({
  isOpen,
  lessonID,
  refetch,
  onClose,
  ...props
}) => {
  const [canceled, setCanceled] = React.useState('');
  const [canceledError, setCanceledError] = React.useState('');
  const taughtSkillRef = React.useRef<TextAreaHandler>(null);
  const [taughtSkillError, setTaughtSkillError] = React.useState('');
  const homeworkRef = React.useRef<TextAreaHandler>(null);
  const [homeworkError, setHomeworkError] = React.useState('');
  const prepareRef = React.useRef<TextAreaHandler>(null);
  const [prepareError, setPrepareError] = React.useState('');
  const [progress, setProgress] = React.useState('');
  const [progressError, setProgressError] = React.useState('');
  const delayReasonRef = React.useRef<TextAreaHandler>(null);
  const [delayReasonError, setDelayReasonError] = React.useState('');
  const [needAssistance, setNeedAssistance] = React.useState('');
  const [needAssistanceError, setNeedAssistanceError] = React.useState('');
  const [assistances, setAssistances] = React.useState<number[]>([]);
  const [assistanceError, setAssistanceError] = React.useState('');
  const assistanceDetailRef = React.useRef<TextAreaHandler>(null);
  const [assistanceDetailError, setAssistanceDetailError] = React.useState('');

  const isCanceled = canceled === '1';
  const progressDelayed = progress === '0' || progress === '1';

  // トースト
  const { showToast } = useToastsContext();

  const [upsertLessonReport] = useUpsertLessonReportForInstructorMutation();

  // レッスン取得
  const [getLesson, lessonData] = useGetLessonForInstructorLazyQuery();

  const { data: lessonsAssistances } = useGetLessonAssistancesQuery();
  const assistanceOptions = lessonsAssistances?.lessonAssistances ?? [];

  const clearErrors = () => {
    setCanceledError('');
    setTaughtSkillError('');
    setHomeworkError('');
    setPrepareError('');
    setProgressError('');
    setDelayReasonError('');
    setNeedAssistanceError('');
    setAssistanceError('');
    setAssistanceDetailError('');
  };

  const clearValues = () => {
    setCanceled('');
    taughtSkillRef.current?.setValue('');
    homeworkRef.current?.setValue('');
    prepareRef.current?.setValue('');
    setProgress('');
    delayReasonRef.current?.setValue('');
    setNeedAssistance('');
    setAssistances([]);
    assistanceDetailRef.current?.setValue('');
  };

  const close = React.useCallback(() => {
    clearErrors();
    clearValues();
    onClose();
  }, [onClose]);

  const validate = React.useCallback((): boolean => {
    clearErrors();

    if (canceled === '1') return true; // レッスンがキャンセルの場合無条件に通す

    let errorCount = 0;

    if (canceled === '') {
      errorCount += 1;
      setCanceledError('必須項目です');
    }

    if (!taughtSkillRef.current?.getValue()) {
      errorCount += 1;
      setTaughtSkillError('必須項目です');
    }

    if (!homeworkRef.current?.getValue()) {
      errorCount += 1;
      setHomeworkError('必須項目です');
    }

    if (!prepareRef.current?.getValue()) {
      errorCount += 1;
      setPrepareError('必須項目です');
    }

    if (progress === '') {
      errorCount += 1;
      setProgressError('必須項目です');
    }

    if (progressDelayed && !delayReasonRef.current?.getValue()) {
      errorCount += 1;
      setDelayReasonError('必須項目です');
    }

    if (needAssistance === '') {
      errorCount += 1;
      setNeedAssistanceError('必須項目です');
    }

    if (needAssistance === '1' && assistances.length === 0) {
      errorCount += 1;
      setAssistanceError('必須項目です');
    }

    if (needAssistance === '1' && !assistanceDetailRef.current?.getValue()) {
      errorCount += 1;
      setAssistanceDetailError('必須項目です');
    }

    return errorCount === 0;
  }, [assistances.length, canceled, needAssistance, progress, progressDelayed]);

  const onAssistanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    assistanceDetailRef.current?.setValue('');
    setAssistances([]);
    setNeedAssistance(e.target.value);
  };

  const createRequest = React.useCallback(() => {
    const request: LessonReportInput = {
      canceled: parseInt(canceled),
      taughtSkill: taughtSkillRef.current?.getValue() ?? '',
      homework: homeworkRef.current?.getValue() ?? '',
      prepare: prepareRef.current?.getValue() ?? '',
      progress: parseInt(progress),
      delayReason: delayReasonRef.current?.getValue() ?? '',
      assistances: assistances,
      assistanceDetail: assistanceDetailRef.current?.getValue() ?? '',
    };

    return request;
  }, [assistances, canceled, progress]);

  // レッスン履歴更新処理
  const submit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!validate()) return;

      const input = createRequest();
      try {
        await upsertLessonReport({
          variables: {
            lessonID: lessonID,
            input: input,
          },
        });
        await refetch();
      } catch {
        showToast(1, defaultErrorMessage);
        return;
      }
      showToast(0, 'レッスンが更新されました。');
      onClose();
    }, [upsertLessonReport, createRequest, showToast, onClose, lessonID, refetch, validate]),
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    getLesson({
      variables: {
        lessonID: lessonID,
      },
      onCompleted: (currentLessonData) => {
        // 基本的にはありえないが、optionalになっているのでチェック
        if (!currentLessonData || !currentLessonData?.lessonForInstructor) {
          return;
        }
        const tempLesson = currentLessonData?.lessonForInstructor;

        // レッスン受講履歴がある場合はデフォルト値にセット
        setCanceled(String(tempLesson.canceled));
        taughtSkillRef.current?.setValue(tempLesson.taughtSkill ?? '');
        homeworkRef.current?.setValue(tempLesson.homework ?? '');
        prepareRef.current?.setValue(tempLesson.prepare ?? '');
        setProgress(String(tempLesson.progress));
        delayReasonRef.current?.setValue(tempLesson.delayReason ?? '');
        setNeedAssistance(tempLesson.assistanceDetail ? '1' : '0'); // assistanceDetailの有無で判断
        tempLesson.lessonsAssistances &&
          setAssistances(tempLesson.lessonsAssistances.map((a) => a.id));
        assistanceDetailRef.current?.setValue(tempLesson.assistanceDetail ?? '');
      },
    });
  }, [getLesson, lessonID, isOpen]);

  const lesson = lessonData?.data?.lessonForInstructor;

  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={close}
      loading={props.loading}
      width={'80vw'}
      header={<span>受講履歴登録・編集</span>}
      footer={
        <Buttons>
          <Button onClick={submit}>登録する</Button>
        </Buttons>
      }
    >
      <Form>
        <div className="form">
          <div className="form-content">
            <div className="checklist">
              <ol>
                <li>【専属レッスン終了後確認項目】</li>
                <li>1. 60分以上レッスンを実施しましたか？</li>
                <li>2. Webカメラに顔出ししましたか？</li>
                <li>3. 次のレッスンの予定をレッスン中に決めましたか？</li>
                <li>4. 次のレッスンの内容は明確になっていますか？</li>
                <li>5. 受講生からの質問に24時間以内に回答できていますか？</li>
              </ol>
            </div>
            <div className="lesson-info">
              <p className="label">受講生名:</p>
              <p>{lesson?.course?.student?.user?.maskedPersonalInfo?.name}</p>
              <p className="label">専属レッスンタイトル:</p>
              <p>{lesson?.title}</p>
              <p className="label">専属レッスン時間:</p>
              <p>
                {lesson?.startAt && lesson?.endAt
                  ? `${format(new Date(lesson.startAt), 'yyyy/MM/dd HH:mm')} ~ ${format(
                      new Date(lesson.endAt),
                      'yyyy/MM/dd HH:mm',
                    )}`
                  : ''}
              </p>
            </div>
            <p className="section">
              <label className="headline">1.レッスンを行いましたか？</label>
              <span>※必須</span>
              <Radio
                name="canceled"
                options={[
                  { label: '実施済み', value: '0' },
                  { label: '受講生都合によるキャンセル', value: '1' },
                ]}
                currentValue={canceled}
                onChange={(e) => setCanceled(e.target.value)}
              />
              <p className="err">{canceledError}</p>
            </p>

            <p className="section">
              <label className={isCanceled ? 'disabled headline' : 'headline'}>
                2.今回のレッスンで教えた技術は？
              </label>
              <span className={isCanceled ? 'disabled' : ''}>※必須</span>
              <TextArea
                name="taughtSkill"
                className={isCanceled ? 'disabled' : ''}
                ref={taughtSkillRef}
                disabled={isCanceled}
                placeholder="例【HTML】margin・paddingの使い方、プロパティの設定の仕方、div,classの使い方"
              />
              <p className="err">{taughtSkillError}</p>
            </p>

            <p className="section">
              <label className={isCanceled ? 'disabled headline' : 'headline'}>
                3.どのような宿題を出しましたか？
              </label>
              <span className={isCanceled ? 'disabled' : ''}>※必須</span>
              <TextArea
                name="homework"
                className={isCanceled ? 'disabled' : ''}
                ref={homeworkRef}
                disabled={isCanceled}
                placeholder={
                  '例1）HTML/CSS教材の17章確認テストを来週の火曜までに全問正解して連絡する。 https://docs.google.com/forms/d/e/1FAIpQLSef0m-j1LMq0_jAIAWtIi-nZQLsimyJK-bgELwvKULUynyIcw/viewform\n例2） オリジナルアプリの企画をフレームワーク「リーンキャンバス」を元に作成しGoogle Slideで来週の水曜までに提出する。\n例3）いま作成中のサンプルアプリにランディングページをBootstrapをつかって実装し、来週の土曜日までに提出する。'
                }
              />
              <p className="err">{homeworkError}</p>
            </p>

            <p className="section">
              <label className={isCanceled ? 'disabled headline' : 'headline'}>
                4.レッスン前後の準備は何をしましたか？
              </label>
              <span className={isCanceled ? 'disabled' : ''}>※必須</span>
              <TextArea
                name="prepare"
                className={isCanceled ? 'disabled' : ''}
                ref={prepareRef}
                disabled={isCanceled}
                placeholder="例 marginやpaddingの使い分けについて説明するための参考URLを調査。また転職の成功イメージをつけるために具体的な転職先をピックアップ。"
              />
              <p className="err">{prepareError}</p>
            </p>

            <p className="section">
              <label className={isCanceled ? 'disabled headline' : 'headline'}>
                5.学習スケジュールは進捗通りですか？
              </label>
              <span className={isCanceled ? 'disabled' : ''}>※必須</span>
              <Radio
                name="progress"
                options={[
                  { label: '遅れている', value: '0' },
                  { label: 'やや遅れている', value: '1' },
                  { label: '進捗通り', value: '2' },
                  { label: 'やや進んでいる', value: '3' },
                  { label: '進んでいる', value: '4' },
                ]}
                currentValue={progress}
                onChange={(e) => setProgress(e.target.value)}
                disabled={isCanceled}
              />
              <p className="err">{progressError}</p>
            </p>

            <p className={`section ${progressDelayed ? '' : 'disable'}`}>
              <label className={isCanceled ? 'disabled headline' : 'headline'}>
                スケジュールの進捗が遅れている理由と対策は？
              </label>
              <span className={isCanceled ? 'disabled' : ''}>※必須</span>
              <TextArea
                name="delayReson"
                className={isCanceled ? 'disabled' : ''}
                ref={delayReasonRef}
                disabled={isCanceled}
                placeholder={
                  '例\n理由：サンプルアプリ開発に入ってからモチベーションが下がっている。学習時間の確保ができていない。\n対策：次のレッスンでは一旦プログラミング学習を離れてみる。wantedlyを見ながら転職先をピックアップする。またキャリアサポートに協力を仰ぎ、キャリアの面談を挟む。'
                }
              />
              <p className="err">{delayReasonError}</p>
            </p>

            <p className="section">
              <label className={'headline'}>6.運営からフォローは必要ですか？</label>
              <span>※必須</span>
              <Radio
                name="needAssistance"
                options={[
                  { label: 'はい', value: '1' },
                  { label: 'いいえ', value: '0' },
                ]}
                currentValue={needAssistance}
                onChange={onAssistanceChange}
              />
              <p className="err">{needAssistanceError}</p>
            </p>

            <p className={`section ${needAssistance === '1' ? '' : 'disable'}`}>
              <label className={'headline'}>
                どのようなフォローを必要としますか？ ※複数選択可能
              </label>
              <span>※必須</span>
              <CheckBox
                name="assistance"
                checkedValues={assistances.map((a) => a.toString())}
                options={assistanceOptions.map((a) => {
                  return {
                    label: a.name,
                    value: a.id.toString(),
                  };
                })}
                onChange={(e) => {
                  const parsedVal = parseInt(e.target.value);

                  setAssistances((prev) => {
                    if (prev.includes(parsedVal)) {
                      // チェックが外された場合
                      return prev.filter((a) => a !== parsedVal);
                    }

                    return [...prev, parsedVal];
                  });
                }}
              />
              <p className="err">{assistanceError}</p>
            </p>

            <p className={`section ${needAssistance === '1' ? '' : 'disable'}`}>
              <label className={'headline'}>
                フォローを必要としている背景を具体的に教えてください
              </label>
              <span className={'disabled'}>※必須</span>
              <TextArea
                name="assistanceDetail"
                ref={assistanceDetailRef}
                placeholder={
                  '例）\n\n①どのようなことがいつ頃から起こっていますか？その理由は？（※具体的に記入をお願いします）\n2月からお仕事が繁忙期になり学習時間を取れていないとのことで宿題をせずにレッスンにきている\nPHPから学習に躓き始めたのでモチベ―ションがダウンしているかもしれない\n\n②この事象から今後想定される懸念はなにかありますか？\n卒業時にXXの目標を達成できない可能性がある\n卒業までに規定回数のレッスンを受講できない\n\n③いつまでに、どのようなフォローを希望しますか？\n次回レッスンまでに、学習に対するモチベーションや困っていることがないかを\nヒアリングしてほしい'
                }
              />
              <p className="err">{assistanceDetailError}</p>
            </p>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Form = styled.div`
  .form .form-header p {
    margin-bottom: 1.4rem;
  }
  .form-header h3 {
    background-color: #fafafa;
    border-bottom: 1px solid lightgray;
    padding: 1.5rem 1rem;
    font-size: 23px;
  }

  .form-header h3 span {
    position: absolute;
    right: 15px;
    top: 11px;
    font-size: 40px;
    cursor: pointer;
  }

  .form .form-content p.section {
    margin-bottom: 1.4rem;
  }

  p.section p label {
    font-weight: normal;
  }

  .form-content {
    padding: 1.25rem;
    box-sizing: border-box;
    transform: translateZ(0);
    overflow-y: scroll;
  }

  .form-content h4 {
    font-weight: 600;
  }

  .checklist {
    padding: 1rem;
    background-color: rgba(253, 60, 47, 0.25);
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 100%;
  }

  .checklist li {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  .lesson-info {
    background-color: #ffe86d;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .lesson-info > p {
    margin: 5px 0;
  }

  .lesson-info .label {
    display: inline-block;
    margin-bottom: 0.3rem;
    font-weight: 650;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .headline {
    display: inline-block;
    margin-bottom: 0.3rem;
    font-weight: 600;
  }

  .form-content span {
    margin-left: 1rem;
    color: #e2001b;
  }

  input:not([type='radio']):not([type='checkbox']),
  textarea {
    display: block;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input:not([type='radio']):not([type='checkbox']),
  textarea {
    width: 97%;
    padding: 0.6rem;
    margin-top: 0.8rem;
    border-radius: 0px;
    font-size: 0.8rem;
    border: solid 1px rgba(0, 0, 0, 0.36);
  }

  p {
    margin-bottom: 1rem;
  }

  textarea {
    height: 100px;
  }

  p.disable {
    display: none;
  }

  .err {
    margin-top: 0.4rem;
    font-size: 0.8rem;
    color: #e2001b;
  }

  .disabled {
    color: gray;
  }
`;
