import React, { useCallback, useContext, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
import { UAParser } from 'ua-parser-js';

import BoldIcon from '../../static/image/icon_editor_bold.svg';
import ItalicIcon from '../../static/image/icon_editor_italic.svg';
import LinethroughIcon from '../../static/image/icon_editor_linethrough.svg';
import LinkIcon from '../../static/image/icon_editor_link.svg';
import ImageIcon from '../../static/image/icon_editor_image.svg';
import FileIcon from '../../static/image/icon_editor_file.svg';
import QuoteIcon from '../../static/image/icon_editor_quote.svg';
import CodeIcon from '../../static/image/icon_editor_code.svg';
import ULIcon from '../../static/image/icon_editor_ul.svg';
import OLIcon from '../../static/image/icon_editor_ol.svg';

import { ModalContext } from '../../context/ModalProvider';
import { Modal } from '../molecules/Modal';
import { Popover } from '../organisms/Popover';
import { EmojiPicker } from '../molecules/EmojiPicker';
import { ToolTip } from '../atoms/ToolTip';
import { RichInputPreviewModal } from '../organisms/RichInputPreviewModal';
import { TextArea, TextAreaHandler } from '../atoms/TextArea';
import { Loader } from './Loader';
import { useUploadFilesMutation, useUploadImagesMutation } from '../../gen/graphql';
import { Emoji } from 'emoji-mart';

const getIsMobile = (userAgent: string): boolean => {
  const type = new UAParser().setUA(userAgent).getDevice().type;
  return type === 'mobile';
};

export const RICH_INPUT_AREA_DEFAULT_HEIGHT = 6;

export interface RichInputHandler {
  openPreview: () => void;
  getValue: () => string | undefined;
  setValue: (s: string) => void;
  setHeight: (s: string) => void;
}

export interface UploadImageRefHandler {
  click: () => void;
}

interface RichInputProps {
  placeholder?: string;
  fileUpload?: boolean;
  imageUpload?: boolean;
  onFocus?: () => void;
  isValidOnkeyup?: boolean;
  maxRowCount?: number;
  initialHeight?: number;
  isAutoHeight?: boolean;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  name: string;
  hiddenPreview?: boolean;
  className?: string;
  showUploadImageAttentionModal?: () => void;
  uploadImageRef?: React.Ref<UploadImageRefHandler>;
  e2e?: string;
  maxLength?: number;
}

export const RichInput = React.forwardRef<RichInputHandler, RichInputProps>((props, ref) => {
  const modalContext = useContext(ModalContext);
  const contentRef = useRef<TextAreaHandler>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const isAutoHeight = props.isAutoHeight ?? true;

  const [uploadImages] = useUploadImagesMutation();
  const [uploadFiles] = useUploadFilesMutation();

  let suppressUploadImageDialog = props.uploadImageRef !== undefined;
  const uploadImageRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(props.uploadImageRef, () => ({
    click: () => {
      if (uploadImageRef.current === null) {
        return;
      }
      suppressUploadImageDialog = false;
      uploadImageRef.current.click();
      suppressUploadImageDialog = true;
    },
  }));

  const uploadImage = useCallback(
    async (file) => {
      if (!file) {
        return;
      }

      setUploading(true);

      try {
        const uploadedImages = await uploadImages({
          variables: {
            files: [file],
          },
        });
        const images = uploadedImages.data?.uploadFiles ?? [];

        const content = contentRef.current?.getValue();
        contentRef.current?.setValue(
          `${content ? content : ''}\n![${images[0].fileName}](${images[0].s3FilePath} "${
            images[0].fileName
          }")\n`,
        );
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
      } finally {
        setUploading(false);
      }
    },
    [uploadImages],
  );

  const handleImageChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    uploadImage(file);
  };

  const uploadFile = useCallback(
    async (file) => {
      setUploading(true);

      try {
        const uploadedFiles = await uploadFiles({
          variables: {
            files: [file],
          },
        });
        const files = uploadedFiles.data?.uploadFiles ?? [];

        const content = contentRef.current?.getValue();
        contentRef.current?.setValue(
          `${content ? content : ''}\n[${files[0].fileName}](${files[0].s3FilePath} "${
            files[0].fileName
          }")\n`,
        );
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
      } finally {
        setUploading(false);
      }
    },
    [uploadFiles],
  );

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    uploadFile(file);
  };

  const openPreview = () => {
    modalContext.setHeader(<span>プレビュー</span>);
    const content = contentRef.current?.getValue();
    modalContext.setContent(<RichInputPreviewModal content={content ? content : ''} />);
    modalContext.open();
  };

  const changeStyle = (type: string) => {
    const start = contentRef.current?.getSelectionStart() ?? 0;
    const end = contentRef.current?.getSelectionEnd() ?? 0;

    const content = contentRef.current?.getValue() ?? '';

    const prev = content.substring(0, start);
    const inner = content.substring(start, end);
    const after = content.substring(end, content.length);

    contentRef.current?.setValue(
      type === 'bold'
        ? `${prev} **${inner}** ${after}`
        : type === 'italic'
          ? `${prev} *${inner}* ${after}`
          : type === 'linethrough'
            ? `${prev} ~~${inner}~~ ${after}`
            : type === 'quote'
              ? `${prev.endsWith('\n') || prev === '' ? prev : prev + '\n'}>${
                  inner.endsWith('\n')
                    ? inner.replace(/\r?\n/g, '\n>')
                    : inner.replace(/\r?\n/g, '\n>') + '\n'
                }${after}`
              : type === 'code'
                ? `${prev.endsWith('\n') || prev === '' ? prev : prev + '\n'}\`\`\`\n${
                    inner.endsWith('\n') ? inner : inner + '\n'
                  }\n\`\`\`\n${after}`
                : type === 'link'
                  ? `${prev}${inner}\n[タイトル](リンクurl)\n${after}`
                  : type === 'ul'
                    ? `${prev}${inner}\n- 項目1\n- 項目2\n- 項目3\n${after}`
                    : type === 'ol'
                      ? `${prev}${inner}\n1. 項目1\n2. 項目2\n3. 項目3\n${after}`
                      : `${prev}${inner}${after}`,
    );

    contentRef.current?.focus();
    contentRef.current?.setSelectionRange(
      start,
      end + content.length - (prev.length + inner.length + after.length),
    );
  };

  const onkeyup = (e: React.KeyboardEvent) => {
    if (props.isValidOnkeyup !== undefined && !props.isValidOnkeyup) {
      return;
    }

    if (isAutoHeight) {
      setInputAreaHeight();
    }

    if (props.onKeyUp) props.onKeyUp(e);
  };

  const setInputAreaHeight = () => {
    const lineBreak = contentRef.current?.getValue().match(/\n/g);
    if (lineBreak) {
      if (lineBreak.length <= (props.maxRowCount ? props.maxRowCount : 10)) {
        contentRef.current?.setHeight(
          `${
            lineBreak.length * 1.1 +
            (props.initialHeight ? props.initialHeight : RICH_INPUT_AREA_DEFAULT_HEIGHT)
          }rem`,
        );
      } else {
        contentRef.current?.setHeight(
          `${
            (props.maxRowCount ? props.maxRowCount : 10) * 1.1 +
            (props.initialHeight ? props.initialHeight : RICH_INPUT_AREA_DEFAULT_HEIGHT)
          }rem`,
        );
      }
    } else {
      contentRef.current?.setHeight(
        `${props.initialHeight ? props.initialHeight : RICH_INPUT_AREA_DEFAULT_HEIGHT}rem`,
      );
    }
  };

  const addEmoji = React.useCallback((emoji: typeof Emoji.Props) => {
    const content = contentRef.current?.getValue() ?? '';

    const pos = contentRef.current?.getSelectionStart() ?? 0;

    const before = content.substring(0, pos);
    const word = emoji.native;
    const after = content.substring(pos, content.length);

    contentRef.current?.setValue(before + word + after);
    contentRef.current?.focus();
  }, []);

  React.useImperativeHandle(ref, () => {
    return {
      openPreview,
      getValue: () => contentRef.current?.getValue(),
      setValue: (s: string) => contentRef.current?.setValue(s),
      setHeight: (s: string) => contentRef.current?.setHeight(s),
    };
  });

  return (
    <Container className={props.className}>
      <Loader display={uploading} />
      <Modal
        header={modalContext.header}
        isOpen={modalContext.isOpen}
        onClose={() => modalContext.close()}
      >
        {modalContext.content}
      </Modal>
      <Tools className="tools">
        {!props.hiddenPreview && <Preview onClick={() => openPreview()}>プレビュー</Preview>}
        <Tool onClick={() => changeStyle('bold')}>
          <img src={BoldIcon} />
          <StyledToolTip name="太字" />
        </Tool>
        <Tool onClick={() => changeStyle('italic')}>
          <img src={ItalicIcon} />
          <StyledToolTip name="イタリック" />
        </Tool>
        <Tool onClick={() => changeStyle('linethrough')}>
          <img src={LinethroughIcon} />
          <StyledToolTip name="打ち消し線" />
        </Tool>
        <Spacer />
        <Tool onClick={() => changeStyle('link')}>
          <img src={LinkIcon} />
          <StyledToolTip name="リンクを挿入" />
        </Tool>
        {props.imageUpload && (
          <Tool>
            <>
              <input
                id={`upload_image_${props.name}`}
                name={`upload_image_${props.name}`}
                type="file"
                accept="image/*"
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                  if (props.showUploadImageAttentionModal && suppressUploadImageDialog) {
                    props.showUploadImageAttentionModal();
                    e.preventDefault();
                  }
                }}
                onChange={handleImageChange}
                ref={uploadImageRef}
                style={{ display: 'none' }}
              />
              <label htmlFor={`upload_image_${props.name}`} className="uploadImage">
                <UploadTool src={ImageIcon} />
              </label>
            </>
            <StyledToolTip name="画像の挿入(10MB以下のjpg,gif,png)" />
          </Tool>
        )}
        {props.fileUpload ? (
          <Tool>
            <input
              type="file"
              id={`upload_file_${props.name}`}
              name={`upload_file_${props.name}`}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <label htmlFor={`upload_file_${props.name}`} className="uploadFile">
              <UploadTool src={FileIcon} />
            </label>
            <StyledToolTip name="ファイルの挿入" />
          </Tool>
        ) : (
          ''
        )}
        <Tool onClick={() => changeStyle('quote')}>
          <img src={QuoteIcon} />
          <StyledToolTip name="引用の挿入" />
        </Tool>
        <VanishingTool onClick={() => changeStyle('code')}>
          <img src={CodeIcon} />
          <StyledToolTip name="コードの挿入" />
        </VanishingTool>
        <Spacer />
        <VanishingTool onClick={() => changeStyle('ul')}>
          <img src={ULIcon} />
          <StyledToolTip name="リストの挿入" />
        </VanishingTool>
        <VanishingTool onClick={() => changeStyle('ol')}>
          <img src={OLIcon} />
          <StyledToolTip name="数字リストの挿入" />
        </VanishingTool>
        <Spacer />
        <Tool>
          <Popover
            render={() => (
              <EmojiPicker
                selectEmoji={addEmoji}
                style={getIsMobile(window.navigator.userAgent) ? { width: '100%' } : undefined}
              />
            )}
          >
            <FontAwesomeIcon icon={faFaceSmile} />
          </Popover>
          <StyledToolTip name="絵文字" />
        </Tool>
      </Tools>
      <StyledTextArea
        name="content"
        className="textarea"
        placeholder={props.placeholder}
        ref={contentRef}
        onFocus={props.onFocus}
        initialHeight={props.initialHeight}
        onKeyUp={onkeyup}
        e2e={props.e2e}
        maxLength={props.maxLength}
      />
    </Container>
  );
});

const Container = styled.div`
  flex-grow: 1;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
`;

const Tools = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eee;
  border-bottom: none;
  width: 100%;
  background-color: #fff;
  padding: 0 0.5rem;
  box-sizing: border-box;
  overflow: visible;
`;

const Preview = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 0.4rem 0;
  padding: 0.2rem 0.6rem;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f2f2f2;
  cursor: pointer;
  font-size: 0.8rem;
  line-height: 1rem;
`;

const UploadTool = styled.img`
  cursor: pointer;
`;

const StyledToolTip = styled(ToolTip)`
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
`;
const Tool = styled.div`
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  &:hover {
    ${StyledToolTip} {
      opacity: 1;
    }
  }
`;

const VanishingTool = styled.div`
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  ${media.lessThan('small')`
    display: none;
  `}
  &:hover {
    ${StyledToolTip} {
      opacity: 1;
    }
  }
`;

const Spacer = styled.div`
  width: 1rem;
`;

const StyledTextArea = styled(TextArea)<{ initialHeight?: number }>`
  width: 100%;
  height: ${(props) => (props.initialHeight ? `${props.initialHeight}rem` : '6rem')};
  resize: none;
  appearance: none;
  border: 1px solid #eee;
  background-color: #fff;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 1rem;
`;
