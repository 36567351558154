import React, { useCallback } from 'react';
import * as E from 'fp-ts/lib/Either';
import { Either } from 'fp-ts/Either';
import {
  LmsStripeValidationError,
  Payment,
  StripeSystemError,
} from '../../infrastructure/externalService/StripePaymentService';
import { useToastsContext } from '../../context/ToastsProvider';
import { pipe } from 'fp-ts/function';
import { AddPaymentMethodModal } from '../molecules/AddPaymentMethodModal';
import { StripePaymentMethodFragment, useSelectPaymentMethodMutation } from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { getApiErrorMessage } from '../../utils/graphqlError';

interface Props {
  isOpen: boolean;
  toggle: (nextState: boolean) => void;
  loading?: boolean;
  fetchPayments: () => Promise<void>;
  cards: StripePaymentMethodFragment[];
  currentDefaultPaymentId: string;
  setLoading: (isLoading: boolean) => void;
}

export const DefaultPaymentAddModal: React.FC<Props> = ({
  isOpen,
  toggle,
  fetchPayments,
  cards,
  currentDefaultPaymentId,
  setLoading,
  ...props
}) => {
  const [selectPaymentMethod] = useSelectPaymentMethodMutation();

  const { showToast } = useToastsContext();

  const changePaymentMethod = useSafeAsyncCallback(
    useCallback(
      async (
        providerID: string,
        clearElements: () => void,
        setSubmitButtonClicked: (clicked: boolean) => void,
      ): Promise<void> => {
        try {
          await selectPaymentMethod({
            variables: {
              providerID: providerID,
            },
          });
          fetchPayments();
          showToast(0, '支払い方法の追加及びデフォルトの支払い方法が変更完了しました。');
          toggle(false);
          clearElements();
        } catch (e) {
          showToast(1, getApiErrorMessage(e));
        } finally {
          setSubmitButtonClicked(false);
          setLoading(false);
        }
      },
      [selectPaymentMethod, fetchPayments, showToast, toggle, setLoading],
    ),
  );

  const addNewPayment = (
    inputNewPaymentResult: Either<LmsStripeValidationError, Payment>,
    clearElements: () => void,
    initValidateMsg: () => void,
    validateError: (error: LmsStripeValidationError) => void,
    setSubmitButtonClicked: (clicked: boolean) => void,
  ) => {
    setLoading(true);

    setSubmitButtonClicked(true);
    initValidateMsg();

    pipe(
      inputNewPaymentResult,
      E.fold(
        (e: LmsStripeValidationError) => {
          if (!(e instanceof StripeSystemError)) {
            validateError(e);
          }
          showToast(1, getApiErrorMessage(e));
          setSubmitButtonClicked(false);
          setLoading(false);
        },
        async (payment: Payment) => {
          try {
            await changePaymentMethod(payment.paymentId, clearElements, setSubmitButtonClicked);
          } catch (e) {
            setLoading(false);
            showToast(1, getApiErrorMessage(e));
            setSubmitButtonClicked(false);
          }
        },
      ),
    );
  };

  const changeSelectPayment = (
    providerID: string,
    clearElements: () => void,
    initValidateMsg: () => void,
    setSubmitButtonClicked: (clicked: boolean) => void,
  ) => {
    setLoading(true);
    setSubmitButtonClicked(true);
    initValidateMsg();
    changePaymentMethod(providerID, clearElements, setSubmitButtonClicked);
  };

  return (
    <React.Fragment>
      <AddPaymentMethodModal
        isOpen={isOpen}
        toggle={toggle}
        loading={props.loading}
        cards={cards}
        currentDefaultPaymentId={currentDefaultPaymentId}
        addNewPayment={addNewPayment}
        changeSelectPayment={changeSelectPayment}
      />
    </React.Fragment>
  );
};
