import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../atoms/Button';

interface Props {
  title: string;
  description: React.ReactNode;
  bg: string;
  minHeight?: string;
  image?: string;
  buttonText?: string;
  onClick?: () => void;
}
interface MvProps {
  mv: Props;
  className?: string;
}

export const Mv: React.FC<MvProps> = (props) => {
  return (
    <Wrapper className={props.className} background={props.mv.bg} minHeight={props.mv.minHeight}>
      <Inner>
        <Text>
          <Title>{props.mv.title}</Title>
          <Description>{props.mv.description}</Description>
          {props.mv.image ? <SpImg src={props.mv.image} /> : ''}
          {props.mv.onClick && props.mv.buttonText ? (
            <StyledButton onClick={props.mv.onClick} border>
              {props.mv.buttonText}
            </StyledButton>
          ) : (
            ''
          )}
        </Text>
        {props.mv.image ? <PcImg src={props.mv.image} /> : ''}
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.section<{ background: string; minHeight?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '0')};
  padding: 2rem 0;
  background: ${(props) => props.background};
  box-sizing: border-box;
  color: rgba(0, 0, 0, 1);

  ${media.lessThan('medium')`
    display: block;
    min-height: 0;
  `}
`;
const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    display: block;
    padding: 0 1rem;
  `}
`;
const Text = styled.div`
  flex: 1;
  margin-right: 1.5rem;

  ${media.lessThan('medium')`
    max-width: none;
    margin: 0 auto;
  `}
`;
const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;

  ${media.lessThan('small')`
    font-size: 1.25rem;
  `}
`;
const Description = styled.p`
  margin-top: 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  white-space: pre-line;

  ${media.lessThan('small')`
    margin-top: 1rem;
    font-size: 0.875rem;
  `}
`;
const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 240px;
  margin-top: 1.5rem;
  padding: 0.75rem;

  ${media.lessThan('medium')`
    margin: 1rem auto 0;
  `}
`;
const PcImg = styled.img`
  display: block;
  max-width: 18.75rem;
  max-height: 12.5rem;

  ${media.lessThan('large')`
    max-width: 15rem;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const SpImg = styled.img`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    width: 100%;
    max-width: 18.75rem;
    max-height: 12.5rem;
    margin: 1rem auto 0;
  `}

  ${media.lessThan('small')`
    margin-top: .5rem;
  `}
`;
