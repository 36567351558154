import React, { useCallback, useState } from 'react';
import { TimelinePostInputModal, TimelinePostInput } from './TimelinePostInputModal';
import { TimelinePostConfirmModal } from './TimelinePostConfirmModal';
import { useUser } from '../../redux/user/useUser';
import { Loader } from '../molecules/Loader';
import { useToastsContext } from '../../context/ToastsProvider';
import { useAddTweetMutation, useUploadImagesMutation } from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { defaultErrorMessage } from '../../const/ErrorMessage';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCompleted: () => void;
}

export const TimelinePostModalsArea: React.FC<Props> = ({
  isOpen,
  onClose,
  onCompleted,
}): JSX.Element => {
  const { user, permissionCheck } = useUser();
  const [uploadImages] = useUploadImagesMutation();
  const [addTweet] = useAddTweetMutation();
  const { showToast } = useToastsContext();
  const [currentInput, setCurrentInput] = useState<TimelinePostInput | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const isAdminOrInstructor =
    user.lmsUser?.isAdmin === 1 ||
    permissionCheck(FunctionType.TimelineForInstructorAndCoach, PermissionType.Create);

  // モーダル閉じる
  const close = useCallback((): void => {
    setCurrentInput(null);
    setShowConfirmation(false);
    onClose();
  }, [onClose]);

  // 画像アップロード
  const handleUploadImages = useCallback(
    async (images: File[]): Promise<string[]> => {
      if (images.length === 0) {
        return [];
      }
      const uploadedImages = await uploadImages({
        variables: {
          files: images,
        },
      });
      return uploadedImages.data?.uploadFiles?.map(({ s3FilePath }) => s3FilePath) ?? [];
    },
    [uploadImages],
  );

  // 投稿送信
  const sendPost = useSafeAsyncCallback(
    useCallback(
      async (input: TimelinePostInput): Promise<void> => {
        setShowLoader(true);

        try {
          const imageURLs = await handleUploadImages(input.images);

          await addTweet({
            variables: {
              input: {
                content: input.content,
                tweetImageInputs: imageURLs.map((imageURL) => ({ content: imageURL })),
                tweetPostDestination: input.destination,
              },
            },
          });
        } catch {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          showToast(1, defaultErrorMessage);
          return;
        } finally {
          setShowLoader(false);
        }

        showToast(0, 'タイムラインに投稿しました');
        onCompleted();
        close();
        window.scroll({ top: 0 });
      },
      [addTweet, close, onCompleted, showToast, handleUploadImages],
    ),
  );

  // 入力モーダル - 投稿処理
  const post = useCallback(
    (input: TimelinePostInput): void => {
      // 管理者・インストの場合は注意表示モーダルを出す
      if (isAdminOrInstructor) {
        setCurrentInput(input);
        setShowConfirmation(true);
        return;
      }
      sendPost(input);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAdminOrInstructor],
  );

  // 注意モーダル - 投稿処理
  const confirm = useCallback((): void => {
    if (!currentInput) {
      // 入力画面に戻す(基本ありえない)
      setShowConfirmation(false);
      return;
    }
    sendPost(currentInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput]);

  // 注意モーダル - 戻る
  const back = (): void => setShowConfirmation(false);

  return (
    <>
      <TimelinePostInputModal
        isOpen={isOpen && !showConfirmation}
        isBack={currentInput !== null}
        requiredDestination={isAdminOrInstructor}
        onPost={post}
        onClose={close}
      />
      <TimelinePostConfirmModal
        isOpen={isOpen && showConfirmation}
        onPost={confirm}
        onCancel={back}
        onClose={close}
      />
      <Loader display={showLoader} />
    </>
  );
};
