import { useCallback, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import {
  useGetPracticeSubmitForInstructorQuery,
  useGetPracticeSubmitQuery,
  useGetProgramSatisfactionQuery,
} from '../../../gen/graphql';
import { useUser } from '../../../redux/user/useUser';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { Loader } from '../../molecules/Loader';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { QuestionCreateModal } from '../../organisms/QuestionCreateModal';
import { ProgramCompleteModal } from '../../organisms/ProgramCompleteModal';
import { BasicLayout } from '../../templates/BasicLayout';
import { NotFoundPage } from '../public/NotFound';
import inquiryIcon from '../../../static/image/inquiry_icon.svg';
import { PracticeSubmitDetailList } from '../../organisms/PracticeSubmitDetailList';
import ForbiddenPage from '../public/Forbidden';
import { useCommonModal } from '../../../redux/common_modal/useCommonModal';
import { strToBoolean } from '../../../utils/common';
import { isCompletedProgram } from '../../../utils/Program';
import { useProgramSatisfactionModal } from '../../../common/customHooks/ProgramSatisfactionModal';
import { LOWER_META_TITLE } from '../../../const/Service';

export const PracticeSubmitDetailContainer = (): JSX.Element => {
  const pathID = useParams<{ practice_submit_id: string }>().practice_submit_id;
  if (!pathID) return NotFoundPage;

  // 投稿画面から遷移してきたか確認用パラメーター
  const submitted: boolean = strToBoolean(
    new URLSearchParams(location.search).get('submitted') ?? '',
  );

  return <PracticeSubmitDetail practiceSubmitID={pathID} submitted={submitted} />;
};

interface PracticeSubmitDetailProps {
  practiceSubmitID: string;
  submitted: boolean;
}

const PracticeSubmitDetail = ({
  practiceSubmitID,
  submitted,
}: PracticeSubmitDetailProps): JSX.Element => {
  const { permissionCheck } = useUser();
  const permissionPracticeForInstructorAndCoachRead = permissionCheck(
    FunctionType.PracticeForInstructorAndCoach,
    PermissionType.Read,
  );
  const permissionQuestionCreate = useMemo(
    () => permissionCheck(FunctionType.Question, PermissionType.Create),
    [permissionCheck],
  );
  const permissionRecommendLightRead = useMemo(
    () => permissionCheck(FunctionType.RecommendLight, PermissionType.Read),
    [permissionCheck],
  );
  const permissionRecommendTeamSubscriptionRead = useMemo(
    () => permissionCheck(FunctionType.RecommendTeamSubscription, PermissionType.Read),
    [permissionCheck],
  );
  const permissionRecommendTeamSubscriptionToAdminRead = useMemo(
    () => permissionCheck(FunctionType.RecommendTeamSubscriptionToAdmin, PermissionType.Read),
    [permissionCheck],
  );

  const { openModal } = useCommonModal();
  const [isQuestionCreateModalOpen, setIsQuestionCreateModalOpen] = useState(false);
  const [isProgramCompleteModalOpen, setIsProgramCompleteModalOpen] = useState(false);
  const [isProgramSatisfactionModalOpen, setIsProgramSatisfactionModalOpen] = useState(false);

  const [metaTitle, setMetaTitle] = useState('');

  const { data: practiceSubmitData, loading: practiceSubmitLoading } = useGetPracticeSubmitQuery({
    variables: {
      id: practiceSubmitID,
    },
    skip: permissionPracticeForInstructorAndCoachRead,
    onCompleted: (practice) => {
      setMetaTitle(`${practice?.getPracticeSubmit?.practice?.title} | ${LOWER_META_TITLE}`);
    },
  });
  const {
    data: practiceSubmitForInstructorData,
    refetch: refetchPracticeSubmitForInstructor,
    loading: practiceSubmitForInstructorLoading,
  } = useGetPracticeSubmitForInstructorQuery({
    variables: {
      id: practiceSubmitID,
    },
    skip: !permissionPracticeForInstructorAndCoachRead,
    notifyOnNetworkStatusChange: true,
    onCompleted: (practice) => {
      setMetaTitle(
        `${practice?.getPracticeSubmitForInstructor?.practice?.title} | ${LOWER_META_TITLE}`,
      );
    },
  });
  const fetchPracticeSubmitForInstructor = useCallback(async (): Promise<void> => {
    if (!permissionPracticeForInstructorAndCoachRead) return;
    refetchPracticeSubmitForInstructor();
  }, [permissionPracticeForInstructorAndCoachRead, refetchPracticeSubmitForInstructor]);
  const practiceSubmit = useMemo(
    () =>
      practiceSubmitData?.getPracticeSubmit
        ? {
            practiceSubmitID: practiceSubmitData.getPracticeSubmit.id,
            submitter: practiceSubmitData.getPracticeSubmit.user,
            practice: practiceSubmitData.getPracticeSubmit.practice,
            practiceSubmitDetails: practiceSubmitData.getPracticeSubmit.practiceSubmitDetails ?? [],
          }
        : practiceSubmitForInstructorData?.getPracticeSubmitForInstructor
          ? {
              practiceSubmitID: practiceSubmitForInstructorData.getPracticeSubmitForInstructor.id,
              submitter: practiceSubmitForInstructorData.getPracticeSubmitForInstructor.user,
              practice: practiceSubmitForInstructorData.getPracticeSubmitForInstructor.practice,
              practiceSubmitDetails:
                practiceSubmitForInstructorData.getPracticeSubmitForInstructor
                  .withoutDraftPracticeSubmitDetails ?? [],
            }
          : undefined,
    [
      practiceSubmitData?.getPracticeSubmit,
      practiceSubmitForInstructorData?.getPracticeSubmitForInstructor,
    ],
  );
  const programID = practiceSubmitData?.getPracticeSubmit?.practice.programID ?? 0;

  // 教材満足度モーダル
  const { data: programSatisfactionData } = useGetProgramSatisfactionQuery({
    variables: {
      programID: programID,
    },
  });
  const programTitle = programSatisfactionData?.program?.title ?? '';

  const { Modal: ProgramSatisfactionModal } = useProgramSatisfactionModal(
    programID,
    isProgramSatisfactionModalOpen,
    () => {
      setIsProgramSatisfactionModalOpen(false);
      setIsProgramCompleteModalOpen(true);
    },
    programTitle,
  );

  const handleClickQuestionMessage = useCallback(() => {
    if (openModal(FunctionType.Question, PermissionType.Create)) return;
    setIsQuestionCreateModalOpen(true);
  }, [openModal]);

  const handleModalLink = useCallback(() => {
    window.open(
      `/question/new?category_id=${practiceSubmit?.practice.program.category}&program_id=${practiceSubmit?.practice.program.id}&practice_id=${practiceSubmit?.practice.id}`,
    );
    setIsQuestionCreateModalOpen(false);
    return;
  }, [
    practiceSubmit?.practice.id,
    practiceSubmit?.practice.program.category,
    practiceSubmit?.practice.program.id,
  ]);

  const breadcrumbs = useMemo(
    () => [
      {
        label: 'ホーム',
        to: '/home',
      },
      {
        label: '課題',
        to: '/practices',
      },
      {
        label: practiceSubmit?.practice.program.title ?? '教材詳細',
        to: `/programs/${practiceSubmit?.practice.program.id}/chapters`,
      },
      {
        label: practiceSubmit?.practice.title ?? '課題詳細',
        to: `/practices/${practiceSubmit?.practice.id}/body`,
      },
      {
        label: '課題',
      },
    ],
    [
      practiceSubmit?.practice.id,
      practiceSubmit?.practice.program.id,
      practiceSubmit?.practice.program.title,
      practiceSubmit?.practice.title,
    ],
  );

  const showLoader = useMemo(
    () => practiceSubmitLoading || practiceSubmitForInstructorLoading,
    [practiceSubmitLoading, practiceSubmitForInstructorLoading],
  );

  useEffect(() => {
    if (!submitted) {
      return;
    }
    if (!isCompletedProgram(programSatisfactionData?.program?.programElements)) {
      return;
    }

    if (programSatisfactionData?.programSatisfaction) {
      // 教材アンケートが回答済みの場合は完了モーダル表示
      setIsProgramCompleteModalOpen(true);
      return;
    }

    // 教材アンケートが未回答の場合はアンケートモーダル表示
    setIsProgramSatisfactionModalOpen(true);
  }, [programSatisfactionData?.program, programSatisfactionData?.programSatisfaction, submitted]);

  // 提出物データ取得できない場合は403へ
  if (!practiceSubmit && !showLoader) return ForbiddenPage;

  return (
    <>
      <Loader display={showLoader} />
      <QuestionCreateModal
        isOpen={isQuestionCreateModalOpen}
        onClose={() => setIsQuestionCreateModalOpen(false)}
        handleLink={handleModalLink}
      />
      <ProgramCompleteModal
        isOpen={isProgramCompleteModalOpen}
        onClose={() => setIsProgramCompleteModalOpen(false)}
        programID={programID}
        programName={programTitle}
      />
      <ProgramSatisfactionModal />
      <BasicLayout pageTitle="課題" metaTitle={metaTitle}>
        <PageWrapper>
          <Breadcrumb links={breadcrumbs} />

          {practiceSubmit ? (
            <ListContainer>
              <PracticeSubmitDetailList
                {...practiceSubmit}
                fetchPracticeSubmitForInstructor={fetchPracticeSubmitForInstructor}
              />
            </ListContainer>
          ) : (
            <EmptyBlock title="提出された課題はありません" />
          )}

          {(permissionQuestionCreate ||
            permissionRecommendLightRead ||
            permissionRecommendTeamSubscriptionRead ||
            permissionRecommendTeamSubscriptionToAdminRead) && (
            <MessageArea onClick={() => handleClickQuestionMessage()}>
              <InquiryIcon src={inquiryIcon} />
              <QuestionMessage>課題についての質問はQ&A掲示板よりお願いします</QuestionMessage>
            </MessageArea>
          )}
        </PageWrapper>
      </BasicLayout>
    </>
  );
};

const MessageArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.75rem;

  ${media.lessThan('medium')`
    justify-content: flex-start;
    margin-top: 1rem;
  `}
`;

const InquiryIcon = styled.img`
  height: 1rem;
  vertical-align: bottom;
  margin-right: 0.25rem;
`;

const QuestionMessage = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #fd3c2f;
  cursor: pointer;
`;

const ListContainer = styled.div`
  margin-top: 1rem;
`;
