import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUser } from '../../../redux/user/useUser';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { checkChapterAuthType, getChapters, getChaptersAndPractices } from '../../../utils/Program';

import { TabNavigationLayout } from '../../templates/TabNavigationLayout';
import { Fab } from '../../atoms/Fab';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Button } from '../../atoms/Button';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { ProgramList } from '../../molecules/ProgramList';
import { QuestionTabContent } from '../../organisms/QuestionTabContent';
import { QuestionCreateModal } from '../../organisms/QuestionCreateModal';

import { Overview } from '../../organisms/ProgramOverview';

import {
  BannerCidType,
  ChapterFragment,
  ChapterSearchFragment,
  PracticeFragment,
  PracticeSearchFragment,
  InstructorPracticeSubmitSearchType,
  PracticeSubmitSearchType,
  PracticeSubmitSortKey,
  QuestionSearchInput,
  QuestionSearchType,
  QuestionSortKey,
  useGetPracticeSubmitsForInstructorQuery,
  useGetPracticeSubmitsQuery,
  useGetProgramQuery,
  useGetQuestionsQuery,
} from '../../../gen/graphql';
import { SERVICE_NAME, LOWER_META_TITLE } from '../../../const/Service';
import { useBannerCids } from '../../../common/customHooks/BannerCids';
import { FIRST_PAGE, MIDDLE_LIMIT, MIN_LIMIT } from '../../../const/Page';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { NotFoundPage } from '../public/NotFound';
import { Loader } from '../../molecules/Loader';
import {
  PracticeSubmitSearchInputParams,
  PracticeSubmitTabContent,
} from '../../organisms/PracticeSubmitTabContent';
import { getPractices } from '../../../utils/Program';
import { useCommonModal } from '../../../redux/common_modal/useCommonModal';
import ForbiddenPage from '../../../components/pages/public/Forbidden';

export const ProgramDetailContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathProgramID = Number(paramID);
  if (Number.isNaN(pathProgramID)) return NotFoundPage;

  return <ProgramDetail programID={pathProgramID} />;
};

interface ProgramDetailProps {
  programID: number;
}

const ProgramDetail = (props: ProgramDetailProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');

  const { user, permissionCheck } = useUser();
  const permissionPracticeForInstructorAndCoachRead = permissionCheck(
    FunctionType.PracticeForInstructorAndCoach,
    PermissionType.Read,
  );
  const permissionProgramForInstructorAndCoachRead = permissionCheck(
    FunctionType.ProgramForInstructorAndCoach,
    PermissionType.Read,
  );
  const permissionQuestionCreate = permissionCheck(FunctionType.Question, PermissionType.Create);
  const permissionRecommendLightRead = permissionCheck(
    FunctionType.RecommendLight,
    PermissionType.Read,
  );
  const permissionRecommendTeamSubscriptionRead = permissionCheck(
    FunctionType.RecommendTeamSubscription,
    PermissionType.Read,
  );
  const permissionRecommendTeamSubscriptionToAdminRead = permissionCheck(
    FunctionType.RecommendTeamSubscriptionToAdmin,
    PermissionType.Read,
  );

  const { openModal } = useCommonModal();
  const [isQuestionCreateModalOpen, setIsQuestionCreateModalOpen] = useState(false);
  const [activePage, setActivePage] = useState('');

  const { pcBanners, spBanners } = useBannerCids([BannerCidType.Program], MIDDLE_LIMIT);

  const {
    data: questions,
    loading: questionsLoading,
    refetch: refetchQuestions,
  } = useGetQuestionsQuery({
    variables: {
      input: {
        type: QuestionSearchType.All,
        page: FIRST_PAGE,
        limit: MIDDLE_LIMIT,
        programID: props.programID,
        sortKey: QuestionSortKey.CreatedAt,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const questionsTotal = questions?.getQuestions.total ?? 0;
  const questionsItems = questions?.getQuestions.items ?? [];
  const fetchQuestions = useCallback(
    (params: QuestionSearchInput) => {
      refetchQuestions({ input: params });
    },
    [refetchQuestions],
  );

  const { data: programData, loading: programLoading } = useGetProgramQuery({
    variables: {
      programID: props.programID,
      userID: user.lmsUser?.id,
    },
    onCompleted: (data) => {
      setMetaTitle(`${data?.program?.title} | ${LOWER_META_TITLE}`);
      setMetaDescription(
        `${SERVICE_NAME}の教材ページです。この教材では、${data?.program?.description}`,
      );
    },
  });

  const program = programData?.program;
  const nextChapter = useMemo((): ChapterFragment | ChapterSearchFragment => {
    const chapters = program ? (getChapters(program) ?? []) : [];
    return chapters?.[0];
  }, [program]);
  const programElements = useMemo((): (
    | ChapterFragment
    | ChapterSearchFragment
    | PracticeFragment
    | PracticeSearchFragment
  )[] => {
    return program ? (getChaptersAndPractices(program) ?? []) : [];
  }, [program]);

  const {
    data: practiceSubmitsData,
    loading: practiceSubmitsLoading,
    refetch: refetchPracticeSubmits,
  } = useGetPracticeSubmitsQuery({
    variables: {
      input: {
        type: PracticeSubmitSearchType.All,
        programID: props.programID,
        sortKey: PracticeSubmitSortKey.CreatedAt,
        page: FIRST_PAGE,
        limit: MIN_LIMIT,
      },
    },
    skip: permissionPracticeForInstructorAndCoachRead,
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: practiceSubmitsForInstructorData,
    loading: practiceSubmitsForInstructorLoading,
    refetch: refetchPracticeSubmitsForInstructor,
  } = useGetPracticeSubmitsForInstructorQuery({
    variables: {
      input: {
        type: InstructorPracticeSubmitSearchType.All,
        programID: props.programID,
        sortKey: PracticeSubmitSortKey.CreatedAt,
        page: FIRST_PAGE,
        limit: MIN_LIMIT,
      },
    },
    skip: !permissionPracticeForInstructorAndCoachRead,
    notifyOnNetworkStatusChange: true,
  });
  const fetchPracticeSubmits = useCallback(
    (params: PracticeSubmitSearchInputParams) => {
      if (permissionPracticeForInstructorAndCoachRead) {
        refetchPracticeSubmitsForInstructor({
          input: {
            type: InstructorPracticeSubmitSearchType.All,
            programID: props.programID,
            ...params,
          },
        });
      } else {
        refetchPracticeSubmits({
          input: {
            type: PracticeSubmitSearchType.All,
            programID: props.programID,
            ...params,
          },
        });
      }
    },
    [
      permissionPracticeForInstructorAndCoachRead,
      refetchPracticeSubmitsForInstructor,
      refetchPracticeSubmits,
      props.programID,
    ],
  );
  const practiceSubmits =
    practiceSubmitsData?.getPracticeSubmits.items ??
    practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.items ??
    [];
  const practiceSubmitsTotal =
    practiceSubmitsData?.getPracticeSubmits.total ??
    practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.total ??
    0;

  useEffect(() => {
    switch (location.pathname) {
      case `/programs/${props.programID}/chapters`:
        setActivePage('chapters');
        break;
      case `/programs/${props.programID}/overview`:
        setActivePage('overview');
        break;
      case `/programs/${props.programID}/questions`:
        setActivePage('questions');
        break;
      case `/programs/${props.programID}/practice_submits`:
        setActivePage('practice_submits');
        break;
    }
  }, [props.programID, location]);

  const handleNewQuestionClick = useCallback(() => {
    if (openModal(FunctionType.Question, PermissionType.Create)) return;
    setIsQuestionCreateModalOpen(true);
  }, [openModal]);

  const handleModalLink = () => {
    window.open(`/question/new?category_id=${program?.category}&program_id=${props.programID}`);
    setIsQuestionCreateModalOpen(false);
  };

  const tabs = useMemo(() => {
    const tabs = [
      {
        label: '章一覧',
        to: `/programs/${props.programID}/chapters`,
        active: location.pathname === `/programs/${props.programID}/chapters`,
      },
    ];

    if (program?.summary) {
      tabs.push({
        label: '概要',
        to: `/programs/${props.programID}/overview`,
        active: location.pathname === `/programs/${props.programID}/overview`,
      });
    }

    tabs.push({
      label: `Q&A(${questionsTotal}件)`,
      to: `/programs/${props.programID}/questions`,
      active: location.pathname === `/programs/${props.programID}/questions`,
    });

    tabs.push({
      label: `提出物(${practiceSubmitsTotal}件)`,
      to: `/programs/${props.programID}/practice_submits`,
      active: location.pathname === `/programs/${props.programID}/practice_submits`,
    });

    return tabs;
  }, [location.pathname, props.programID, program?.summary, questionsTotal, practiceSubmitsTotal]);

  const breadcrumbs = useMemo(
    () => [
      {
        label: 'ホーム',
        to: '/home',
      },
      {
        label: '教材',
        to: permissionProgramForInstructorAndCoachRead ? '/programs?type=1' : '/programs',
      },
      {
        label: program?.title.length
          ? program?.title.length > 30
            ? program?.title.substr(0, 30) + '...'
            : program?.title
          : '',
      },
    ],
    [permissionProgramForInstructorAndCoachRead, program?.title],
  );

  const showLoader =
    questionsLoading ||
    programLoading ||
    practiceSubmitsLoading ||
    practiceSubmitsForInstructorLoading;

  if (!programLoading && !programData?.program) {
    return ForbiddenPage;
  }

  return (
    <>
      <Loader display={showLoader} />
      <QuestionCreateModal
        isOpen={isQuestionCreateModalOpen}
        onClose={() => setIsQuestionCreateModalOpen(false)}
        handleLink={handleModalLink}
      />
      <TabNavigationLayout
        pageTitle="教材"
        tabs={tabs}
        programs={program ?? undefined}
        activePage={activePage}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <Container>
          <StyledBreadcrumb links={breadcrumbs} />
          {location.pathname === `/programs/${props.programID}/questions` ? (
            <>
              {program && (
                <QuestionTabContent
                  permissionCheck={permissionCheck}
                  questionsTotal={questionsTotal}
                  questionsItems={questionsItems}
                  fetchQuestions={fetchQuestions}
                  program={program}
                  programID={props.programID}
                />
              )}
              {(permissionQuestionCreate ||
                permissionRecommendLightRead ||
                permissionRecommendTeamSubscriptionRead ||
                permissionRecommendTeamSubscriptionToAdminRead) && (
                <Fab onClick={handleNewQuestionClick}>+ 質問する</Fab>
              )}
            </>
          ) : location.pathname === `/programs/${props.programID}/overview` ? (
            <>
              <Overview
                summary={program?.summary ?? ''}
                summaryImage1={program?.summaryImage1 ?? ''}
                summaryImage2={program?.summaryImage2 ?? ''}
                summaryImage3={program?.summaryImage3 ?? ''}
                summaryImage4={program?.summaryImage4 ?? ''}
                summaryImage5={program?.summaryImage5 ?? ''}
              />
              <OverviewLinks>
                <ArchiveBack to={`/programs/${props.programID}/chapters`}>一覧に戻る</ArchiveBack>
                {nextChapter && (
                  <Button
                    onClick={() =>
                      navigate(`/programs/${props.programID}/chapters/${nextChapter.id}`)
                    }
                    disabled={!checkChapterAuthType(nextChapter, permissionCheck)}
                    border
                  >
                    1章に進む
                  </Button>
                )}
              </OverviewLinks>
            </>
          ) : location.pathname === `/programs/${props.programID}/practice_submits` ? (
            <>
              {program && (
                <PracticeSubmitTabContent
                  practiceSubmits={practiceSubmits}
                  total={practiceSubmitsTotal}
                  practices={getPractices(program) ?? []}
                  fetch={fetchPracticeSubmits}
                />
              )}
              {(permissionQuestionCreate ||
                permissionRecommendLightRead ||
                permissionRecommendTeamSubscriptionRead ||
                permissionRecommendTeamSubscriptionToAdminRead) && (
                <Fab onClick={handleNewQuestionClick}>+ 質問する</Fab>
              )}
            </>
          ) : (
            <>
              <ProgramList
                programID={props.programID}
                programElements={programElements as (ChapterFragment | PracticeFragment)[]}
              />
              {(permissionQuestionCreate ||
                permissionRecommendLightRead ||
                permissionRecommendTeamSubscriptionRead ||
                permissionRecommendTeamSubscriptionToAdminRead) && (
                <Fab onClick={handleNewQuestionClick}>+ 質問する</Fab>
              )}
            </>
          )}
          {location.pathname === `/programs/${props.programID}/chapters` && (
            <>
              <PcBannerArea>
                {pcBanners.map((pcBanner) => (
                  <a href={pcBanner.banner.link + pcBanner.cid} target="_blank" key={pcBanner.id}>
                    <img src={pcBanner.banner.imageURL} alt={pcBanner.banner.title} />
                  </a>
                ))}
              </PcBannerArea>
              <SpBannerArea>
                {spBanners.map((spBanner) => (
                  <a href={spBanner.banner.link + spBanner.cid} target="_blank" key={spBanner.id}>
                    <img src={spBanner.banner.imageURL} alt={spBanner.banner.title} />
                  </a>
                ))}
              </SpBannerArea>
            </>
          )}
        </Container>
      </TabNavigationLayout>
    </>
  );
};

const Container = styled(PageWrapper)`
  display: flex;
  flex-wrap: wrap;

  ${media.lessThan('medium')`
    flex-direction: column;
    padding: 1.5rem 0;
  `}
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 2rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`;

const PcBannerArea = styled.div`
  width: 284px;
  margin-left: 1.25rem;

  a {
    display: block;

    + a {
      margin-top: 1rem;
    }

    img {
      display: block;
      width: 100%;
    }

    &:last-of-type {
      position: sticky;
      top: 130px;
    }
  }

  ${media.lessThan('large')`
    width: 230px;
    margin-left: 1rem;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;

const SpBannerArea = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    width: 100%;
    margin: 2rem auto 0;
    padding: 0 1rem;
    box-sizing: border-box;

    a {
      display: block;
      max-width: 480px;
      margin: 0 auto;

      + a {
        margin-top: 1rem;
      }
  
      img {
        display: block;
        width: 100%;
      }
  
      &:last-of-type {
        position: sticky;
        top: 130px;
      }
    }
  `}
`;

const OverviewLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 2rem auto 0;

  ${media.lessThan('medium')`
    padding: 0 1rem;
    box-sizing: border-box;
  `}
`;
const ArchiveBack = styled(Link)`
  padding-left: 0.875rem;
  color: #555;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    margin: auto;
    border-top: 1px solid #555;
    border-left: 1px solid #555;
    transform: rotate(-45deg);
    transition: all 0.2s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &:hover {
    color: #eb0000;

    &:before {
      border-top-color: #eb0000;
      border-left-color: #eb0000;
    }
  }
`;
