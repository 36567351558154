import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'styled-media-query';
import { UserIcon } from './UserIcon';
import { NotificationLesson } from '../molecules/NotificationLesson';
import { NotificationSession } from '../molecules/NotificationSession';
import { NotificationSpotLesson } from '../molecules/NotificationSpotLesson';
import { NotificationQuestion } from '../molecules/NotificationQuestion';
import { NotificationCourse } from '../molecules/NotificationCourse';
import QuestionIcon from '../../static/image/notification/ic_qa.svg';
import LessonIcon from '../../static/image/notification/ic_lesson.svg';
import StudyReportIcon from '../../static/image/notification/ic_log.svg';
import PracticeIcon from '../../static/image/notification/ic_practice.svg';
import { NotificationReadStatus, NotificationFragment, NotificationType } from '../../gen/graphql';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { convertToJSTFormat } from '../../utils/DateJST';

interface NotificationCardProps {
  notification: NotificationFragment;
}

export const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
}): JSX.Element => {
  const { permissionCheck } = useUser();

  const link = useMemo((): string => {
    switch (notification.type) {
      case NotificationType.LessonScheduleNotifications:
      case NotificationType.LessonScheduleUncreatedNotifications:
      case NotificationType.LessonCreatedNotifications:
      case NotificationType.LessonScheduleUpdatedNotifications:
        return '/lessons';
      case NotificationType.SpotLessonRegisteredNotifications:
      case NotificationType.SpotLessonRemindNotifications:
      case NotificationType.SpotLessonCancelNotifications:
      case NotificationType.SpotLessonLogUnregisteredNotifications:
      case NotificationType.GivenFreeSpotLessonTicketNotifications:
        return '/spot_lessons';
      case NotificationType.QuestionCreatedNotifications:
      case NotificationType.QuestionAnswerCreatedNotifications:
      case NotificationType.QuestionCommentCreatedNotifications:
        return `/question/detail/${notification.notificationDetail?.question?.id}`;
      case NotificationType.StudyReportCommentCreatedNotifications:
        return `/study_report/detail/${notification.notificationDetail?.studyReport?.id}`;
      case NotificationType.ClaimAppliedNotifications:
        return '';
      case NotificationType.AttendanceStatusChangedNotifications:
      case NotificationType.BackSchoolNotifications:
        return permissionCheck(
          FunctionType.NotificationTypeBackSchoolNotificationsInstructor,
          PermissionType.Read,
        )
          ? '/courses'
          : '';
      case NotificationType.SessionRemindNotifications:
        return '/sessions';
      case NotificationType.PracticeSubmitCreatedNotifications:
      case NotificationType.PracticeSubmitReCreatedNotifications:
      case NotificationType.PracticeSubmitDetailReviewCommentCreatedNotifications:
        return `/practices/submits/${notification.notificationDetail?.practiceSubmit?.id}`;
      default:
        return '';
    }
  }, [
    notification.type,
    notification.notificationDetail?.question?.id,
    notification.notificationDetail?.studyReport?.id,
    notification.notificationDetail?.practiceSubmit?.id,
    permissionCheck,
  ]);

  const label = useMemo((): string => {
    switch (notification.type) {
      case NotificationType.LessonScheduleNotifications:
      case NotificationType.LessonScheduleUncreatedNotifications:
      case NotificationType.LessonCreatedNotifications:
      case NotificationType.LessonScheduleUpdatedNotifications:
      case NotificationType.AttendanceStatusChangedNotifications:
      case NotificationType.BackSchoolNotifications:
        return '専属レッスン';
      case NotificationType.SpotLessonRegisteredNotifications:
      case NotificationType.SpotLessonRemindNotifications:
      case NotificationType.SpotLessonCancelNotifications:
      case NotificationType.SpotLessonLogUnregisteredNotifications:
      case NotificationType.GivenFreeSpotLessonTicketNotifications:
        return 'レッスン';
      case NotificationType.QuestionCreatedNotifications:
      case NotificationType.QuestionAnswerCreatedNotifications:
      case NotificationType.QuestionCommentCreatedNotifications:
        return 'Q&A';
      case NotificationType.StudyReportCommentCreatedNotifications:
        return '学習ログ';
      case NotificationType.ClaimAppliedNotifications:
        return '請求';
      case NotificationType.SessionRemindNotifications:
        return 'セッション';
      case NotificationType.PracticeSubmitCreatedNotifications:
      case NotificationType.PracticeSubmitReCreatedNotifications:
      case NotificationType.PracticeSubmitDetailReviewCommentCreatedNotifications:
        return '課題';
      default:
        return '';
    }
  }, [notification.type]);

  const icon = useMemo((): string => {
    switch (notification.type) {
      case NotificationType.LessonScheduleNotifications:
      case NotificationType.LessonScheduleUncreatedNotifications:
      case NotificationType.LessonCreatedNotifications:
      case NotificationType.LessonScheduleUpdatedNotifications:
      case NotificationType.SpotLessonRegisteredNotifications:
      case NotificationType.SpotLessonRemindNotifications:
      case NotificationType.SpotLessonCancelNotifications:
      case NotificationType.SpotLessonLogUnregisteredNotifications:
      case NotificationType.GivenFreeSpotLessonTicketNotifications:
        return LessonIcon;
      case NotificationType.QuestionCreatedNotifications:
      case NotificationType.QuestionAnswerCreatedNotifications:
      case NotificationType.QuestionCommentCreatedNotifications:
        return QuestionIcon;
      case NotificationType.StudyReportCommentCreatedNotifications:
        return StudyReportIcon;
      case NotificationType.PracticeSubmitCreatedNotifications:
      case NotificationType.PracticeSubmitReCreatedNotifications:
      case NotificationType.PracticeSubmitDetailReviewCommentCreatedNotifications:
        return PracticeIcon;
      case NotificationType.ClaimAppliedNotifications:
      case NotificationType.AttendanceStatusChangedNotifications:
      case NotificationType.BackSchoolNotifications:
      default:
        return LessonIcon;
    }
  }, [notification.type]);

  return (
    <div>
      <CardLink to={link} state={{ referer: location.pathname }}>
        <Card>
          <Header>
            <Label type={notification.type}>
              <Icon>
                <img src={icon} alt="" />
              </Icon>
              {label}
            </Label>
            <Time>
              {convertToJSTFormat(notification.createdAt, 'yyyy/MM/DD HH:mm')} {}
            </Time>
          </Header>
          <Title read={String(notification.notificationReadStatus)}>
            {notification.notificationDetail?.title}
          </Title>
          {notification.notificationDetail?.lesson && (
            <NotificationLesson lesson={notification.notificationDetail.lesson} />
          )}
          {notification.notificationDetail?.spotLesson && (
            <NotificationSpotLesson spotLesson={notification.notificationDetail.spotLesson} />
          )}
          {notification.notificationDetail?.question && (
            <NotificationQuestion question={notification.notificationDetail.question} />
          )}
          {notification.notificationDetail?.course && (
            <NotificationCourse course={notification.notificationDetail.course} />
          )}
          {notification.notificationDetail?.actor ? (
            <StyledUserIcon
              user={notification.notificationDetail?.actor}
              medium={
                !!notification.notificationDetail?.actor?.isStudent &&
                !notification.notificationDetail?.actor?.isInstructor
              }
              small={
                !(
                  notification.notificationDetail?.actor?.isStudent &&
                  !notification.notificationDetail?.actor?.isInstructor
                )
              }
            />
          ) : (
            ''
          )}
          {notification.notificationDetail?.session && (
            <NotificationSession session={notification.notificationDetail.session} />
          )}
        </Card>
      </CardLink>
    </div>
  );
};

const CardLink = styled(Link)`
  display: block;
`;

const Card = styled.div`
  border: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 1.2rem;
  box-sizing: border-box;
  gap: 1rem;
  flex-direction: column;
  border-radius: 2px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const labelColor = (type: NotificationType): string => {
  switch (type) {
    case NotificationType.LessonScheduleNotifications:
    case NotificationType.LessonScheduleUncreatedNotifications:
    case NotificationType.LessonCreatedNotifications:
    case NotificationType.LessonScheduleUpdatedNotifications:
    case NotificationType.AttendanceStatusChangedNotifications:
    case NotificationType.BackSchoolNotifications:
      return '#43E29F';
    case NotificationType.SpotLessonRegisteredNotifications:
    case NotificationType.SpotLessonRemindNotifications:
    case NotificationType.SpotLessonCancelNotifications:
    case NotificationType.SpotLessonLogUnregisteredNotifications:
    case NotificationType.GivenFreeSpotLessonTicketNotifications:
      return '#F1B007';
    case NotificationType.PracticeSubmitCreatedNotifications:
    case NotificationType.PracticeSubmitReCreatedNotifications:
    case NotificationType.PracticeSubmitDetailReviewCommentCreatedNotifications:
      return '#C08C34';
    case NotificationType.QuestionCreatedNotifications:
    case NotificationType.QuestionAnswerCreatedNotifications:
    case NotificationType.QuestionCommentCreatedNotifications:
      return '#EB0000';
    case NotificationType.StudyReportCommentCreatedNotifications:
      return '#EB0000';
    case NotificationType.ClaimAppliedNotifications:
      return '#EB0000';
    default:
      return '#EB0000';
  }
};

const Label = styled.div<{ type: NotificationType }>`
  display: flex;
  align-items: center;
  gap: 0.1rem;
  background-color: ${(props) => labelColor(props.type)};
  color: white;
  padding: 0.2rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  border-radius: 3px;
  line-height: 1.2rem;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 1.2rem;
    width: 1.2rem;
    stroke: white;
    fill: white;
  }
`;

const Time = styled.div`
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.36);
`;

const Title = styled.div<{ read: string }>`
  font-weight: ${(props) => (props.read === NotificationReadStatus.Unread ? '600' : '400')};
  font-size: 1rem;
`;

const StyledUserIcon = styled(UserIcon)`
  right: 0;
  bottom: 0;

  ${media.lessThan('medium')`
    margin-top: 1rem;
    position: relative;
  `}
`;
