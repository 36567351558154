import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { MenuContext } from '../../context/MenuProvider';
import { NavigationText } from '../atoms/NavigationText';
import { NavigationIcon } from '../atoms/NavigationIcon';
import { NotificationBadge } from '../atoms/NotificationBadge';

interface NavigationItemChildProps {
  name: string;
  active: boolean;
  to: string;
  onClick?: () => void;
}
interface NavigationItemProps {
  children?: string | React.ReactNode;
  icon?: React.ReactNode;
  active: boolean;
  withFill?: boolean;
  count?: number;
  to?: string;
  onClick?: () => void;
  isShowChild?: boolean;
  childItem?: NavigationItemChildProps[];
  name?: string;
}

export const NavigationItem: React.FC<NavigationItemProps> = (props) => {
  const menuContext = useContext(MenuContext);

  return (
    <Li data-e2e={props.name} $active={props.active}>
      {props.to ? (
        <StyledLink to={props.to} onClick={props.onClick}>
          {props.icon ? (
            <NavigationIcon active={props.active} withFill={props.withFill}>
              {props.icon}
            </NavigationIcon>
          ) : (
            ''
          )}
          {props.children ? (
            <NavigationText active={props.active}>{props.children}</NavigationText>
          ) : (
            ''
          )}
        </StyledLink>
      ) : (
        <StyledBlock onClick={props.onClick}>
          {props.icon ? (
            <NavigationIcon active={props.active} withFill={props.withFill}>
              {props.icon}
            </NavigationIcon>
          ) : (
            ''
          )}
          {props.children ? (
            <NavigationText active={props.active}>{props.children}</NavigationText>
          ) : (
            ''
          )}
          <Svg
            $active={props.isShowChild ? props.isShowChild : false}
            isShow={menuContext.isOpen || menuContext.isHoverOpen}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.825 13.3333L10 9.51665L6.175 13.3333L5 12.1583L10 7.15831L15 12.1583L13.825 13.3333Z"
              fill="#575E71"
            />
          </Svg>
        </StyledBlock>
      )}
      <NotificationBadge
        count={props.count}
        shift={!(menuContext.isOpen || menuContext.isHoverOpen)}
      />
      {(menuContext.isOpen || menuContext.isHoverOpen) && props.isShowChild && props.childItem
        ? props.childItem.map((p, i) => {
            return (
              <ChildLink to={p.to} $active={p.active} key={i}>
                {p.name}
              </ChildLink>
            );
          })
        : ''}
    </Li>
  );
};

const Li = styled.li<{ $active: boolean }>`
  position: relative;

  & + * {
    margin-top: 24px;
  }

  ${(props) => {
    return props.$active
      ? `
        &:before {
          content: '';
          position: absolute;
          width: 2px;
          height: 44px;
          left: -1rem;
          top: -0.75rem;
          background-color: #e2001b;
        };
      `
      : '';
  }}
`;

const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
`;
const StyledBlock = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;
`;
const Svg = styled.svg<{ $active: boolean; isShow: boolean }>`
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  transform: rotate(${(props) => (props.$active ? '0deg' : '180deg')});

  display: ${(props) => (props.isShow ? 'block' : 'none')};
`;
const ChildLink = styled(Link)<{ $active: boolean }>`
  display: block;
  width: 100%;
  margin-top: 0.875rem;
  padding-left: 2.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  box-sizing: border-box;
  transition: all 0.2s;

  &:nth-of-type(1) {
    margin-top: 1.25rem;
  }

  &:hover {
    color: #e2001b;
  }

  ${(props) => (props.$active ? 'color: #e2001b;' : '')}
`;
