import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format } from 'date-fns';

import {
  ChatMessageListFragment,
  useDeleteChatMessageMutation,
  useUpdateChatMessageMutation,
} from '../../gen/graphql';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import AlertIcon from '../../static/image/icon_alert.svg';
import MoreIcon from '../../static/image/icon_more.svg';
import { Markdown } from '../../common/Markdown';
import { RichInput, RichInputHandler } from './RichInput';
import { Button } from '../atoms/Button';
import { getApiErrorMessage } from '../../utils/graphqlError';

interface BalloonProps {
  openEdit: boolean;
  message: ChatMessageListFragment;
  onEditing: (isEdit: boolean) => void;
  onEditingMessage: (message: ChatMessageListFragment | null) => void;
  confirmEditing: (message: ChatMessageListFragment) => Promise<unknown>;
  updatePermission: boolean;
  deletePermission: boolean;
  e2e?: string;
}

export const Balloon: React.FC<BalloonProps> = ({
  openEdit,
  message,
  onEditing,
  onEditingMessage,
  confirmEditing,
  updatePermission,
  deletePermission,
  e2e,
}) => {
  const contentRef = React.useRef<RichInputHandler>(null);
  const [errMessage, setErrMessage] = React.useState<string>('');
  const [isControlShow, setIsControlShow] = React.useState<boolean>(false);
  const [isEditShow, setIsEditShow] = React.useState<boolean>(false);

  const [updateChatMessage] = useUpdateChatMessageMutation();
  const [deleteChatMessage] = useDeleteChatMessageMutation();

  const editingOpen = useCallback(
    (message: ChatMessageListFragment) => {
      onEditingMessage(message);
      onEditing(true);
      setIsEditShow(true);
    },
    [onEditing, onEditingMessage],
  );

  const editingClose = useCallback(() => {
    onEditingMessage(null);
    onEditing(false);
    setIsEditShow(false);
  }, [onEditing, onEditingMessage]);

  const saveEditMessage = useSafeAsyncCallback(
    async (id: number, content: string): Promise<void> => {
      if (!content) {
        await deleteMessage(id);
      } else {
        try {
          await updateChatMessage({
            variables: {
              id: id,
              input: { content: content },
            },
          });

          editingClose();
        } catch (e) {
          setErrMessage(
            getApiErrorMessage(
              e,
              '編集は成功している可能性があります。リロードしていただき、編集されていない場合はもう一度試してみてください。',
            ),
          );
        }
      }
    },
  );

  const deleteMessage = useSafeAsyncCallback(async (id: number): Promise<void> => {
    if (!window.confirm('本当に削除しますか？')) {
      return;
    }

    try {
      await deleteChatMessage({ variables: { id } });
      editingClose();
    } catch (e) {
      setErrMessage(
        getApiErrorMessage(
          e,
          '削除は成功している可能性があります。リロードしていただき、削除されていない場合はもう一度試してみてください。',
        ),
      );
    }
    setIsControlShow(false);
  });

  const toggleControlShow: React.MouseEventHandler<unknown> = (
    e: React.MouseEvent<HTMLParagraphElement>,
  ) => {
    e.stopPropagation();
    e.preventDefault();

    setIsControlShow(!isControlShow);
  };

  const getUserName = (message: ChatMessageListFragment) => {
    if ('maskedPersonalInfo' in message.user && message.user.maskedPersonalInfo) {
      return message.user.maskedPersonalInfo.name;
    } else if ('nickName' in message.user && message.user.nickName) {
      return message.user.nickName;
    }

    return '退会済みユーザー';
  };

  const handleEditOpen = useCallback(
    async (message: ChatMessageListFragment) => {
      const ok = await confirmEditing(message);
      if (!ok) {
        return;
      }

      editingOpen(message);
    },
    [confirmEditing, editingOpen],
  );

  const handleEditClose = useCallback(() => {
    editingClose();
  }, [editingClose]);

  const onKeyUp = useCallback(() => {
    onEditingMessage(
      Object.assign(
        {},
        {
          ...message,
          content: contentRef.current?.getValue() as string,
        },
      ),
    );
  }, [message, onEditingMessage]);

  useEffect(() => {
    contentRef.current?.setValue(message.content);
  }, [message.content]);

  useEffect(() => {
    setIsEditShow(openEdit);
  }, [openEdit]);

  return (
    <Container>
      <Header>
        <Label>
          <NameLabel>{getUserName(message)}</NameLabel>
          <TimeLabel>{format(new Date(message.createdAt), 'HH:mm')}</TimeLabel>
        </Label>
        {(updatePermission || deletePermission) && (
          <>
            <DeleteButton onClick={toggleControlShow} data-e2e={e2e}>
              <DetailIcon src={MoreIcon} alt="選択中" />
            </DeleteButton>
            <Control isShow={isControlShow} onMouseLeave={toggleControlShow}>
              {updatePermission && (
                <ControlItem>
                  <ControlButton onClick={() => handleEditOpen(message)}>
                    メッセージの編集
                  </ControlButton>
                </ControlItem>
              )}
              {deletePermission && (
                <ControlItem>
                  <ControlButton onClick={() => deleteMessage(message.id)}>
                    メッセージの削除
                  </ControlButton>
                </ControlItem>
              )}
            </Control>
          </>
        )}
      </Header>
      <MessageContainer show={!isEditShow}>
        <Markdown content={message.content} offGlobalStyle />
      </MessageContainer>
      {updatePermission && (
        <MessageContainer show={isEditShow}>
          <RichInput
            name={`balloon_input_${message.id}`}
            fileUpload={true}
            imageUpload
            ref={contentRef}
            onKeyUp={onKeyUp}
            e2e="edit-message-input"
          />
          <Buttons>
            <CancelButton onClick={handleEditClose}>キャンセル</CancelButton>
            <Button
              onClick={() => saveEditMessage(message.id, contentRef.current?.getValue() as string)}
              disabled={false}
            >
              保存する
            </Button>
          </Buttons>
        </MessageContainer>
      )}
      {errMessage && <Err>{errMessage}</Err>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  flex-grow: 0.6;
  margin: 0 0.4rem;
  padding: 0 0.5rem 0.5rem;
  line-height: 1.3;
  ${media.lessThan('medium')`
    padding: 0;
    flex-grow: 0.8;
  `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Label = styled.span``;

const NameLabel = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
`;

const TimeLabel = styled.time`
  margin-left: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  vertical-align: text-bottom;
`;

const DetailIcon = styled.img``;

const DeleteButton = styled.p`
  color: #aaa;
  font-size: 0.8rem;
  cursor: pointer;
`;

const Err = styled.div`
  font-size: 0.8rem;

  background-color: #fcfcfc;
  padding: 5px;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    width: 1.05rem;
    height: 1.05rem;
    margin-right: 0.375rem;
    background: url(${AlertIcon}) center / contain no-repeat;
    vertical-align: bottom;
  }
`;

const Control = styled.ul<{ isShow: boolean }>`
  padding: 0.5rem 0;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  opacity: ${(props) => (props.isShow ? '1' : '0')};
  pointer-events: ${(props) => (props.isShow ? 'all' : 'none')};
  transition: all 0.2s;
  z-index: 1;
`;

const ControlButton = styled.p`
  display: flex;
  align-items: center;
  width: 140px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
`;

const ControlItem = styled.li`
  padding: 5px 0;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

const CancelButton = styled.p`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const MessageContainer = styled.div<{ show: boolean }>`
  ${(props) => (props.show ? '' : 'display: none;')}
`;
