import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';

import { H3 } from '../atoms/Typography';

import IconTime from '../../static/image/icon_time_gray.svg';
import { minutesToHours } from 'date-fns';
import { progress } from '../../utils/Progress';
import { minutesToHoursSecondDecimalPlace } from '../../utils/common';

interface CourseProgramArticleProps {
  image: string;
  title: string;
  description?: string;
  id: string;
  totalChapterCount: number;
  totalRequireTime: number;
  totalCompletedTime: number;
  className?: string;
  showProgress?: boolean;
}

export const CourseProgramArticle: React.FC<CourseProgramArticleProps> = (props) => {
  return (
    <Article data-e2e="courseList" className={props.className}>
      <Link to={`/course_programs/${props.id}`}>
        <Container>
          <StyledH3 color="DARK">{props.title}</StyledH3>
          <Thumb bg={props.image}></Thumb>
          <Text>
            {props.description && <Description>{props.description}</Description>}
            {props.showProgress && (
              <Progress>
                <Bar start={props.totalCompletedTime} end={props.totalRequireTime} />
                <span>
                  {progress(props.totalCompletedTime, props.totalRequireTime)}% (
                  {minutesToHoursSecondDecimalPlace(props.totalCompletedTime)}/
                  {minutesToHoursSecondDecimalPlace(props.totalRequireTime)}時間)
                </span>
              </Progress>
            )}
            <Info>
              <p>
                <img src={IconTime} alt="" />
                {minutesToHours(props.totalRequireTime)}時間
              </p>
            </Info>
          </Text>
        </Container>
      </Link>
    </Article>
  );
};

const Article = styled.article`
  width: calc(50% - 0.625rem);
  background: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  overflow: hidden;

  ${media.lessThan('medium')`
    width: calc(50% - .5rem);
  `}

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 0;
    padding: 0;
  `}
`;
const StyledH3 = styled(H3)`
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0 auto;

  ${media.lessThan('medium')`
    order: 2;
    margin-top: 1rem;
    padding: 0 1rem;
    box-sizing: border-box;
  `}
`;
const Thumb = styled.div<{ bg?: string }>`
  width: 52%;
  max-width: 240px;
  padding-top: 26%;
  background: ${(props) => (props.bg ? `url(${props.bg})` : '#ccc')} center / cover no-repeat;
  border-radius: 0.375rem;

  ${media.lessThan('medium')`
    order: 1;
    width: 100%;
    max-width: none;
    padding-top: 55.5555%;
    border-radius: 0;
  `}
`;
const Text = styled.div`
  flex: 1;

  ${media.lessThan('medium')`
    order: 3;
    width: 100%;
    margin-top: .375rem;
    padding: 0 1rem 1rem;
    box-sizing: border-box;
  `}
`;
const Description = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
`;
const Progress = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.375rem;

  span {
    margin-left: 0.25rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.625rem;
    line-height: 0.75rem;
  }

  ${media.lessThan('medium')`
    margin-top: .25rem;
  `}
`;
const barAnimation = (start: number, end: number) => {
  return keyframes`
    0% {
      width: 0;
    }

    100% {
      width: ${progress(start, end)}%;
    }
  `;
};
const Bar = styled.div<{ start: number; end: number }>`
  flex: 1;
  max-width: 130px;
  height: 0.25rem;
  background: #e5e5e5;
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 0;
    background: linear-gradient(95.07deg, #fd8258 11.86%, #fd3c2f 82.5%);
    animation: ${(props) => barAnimation(props.start, props.end)} 0.5s ease-out 1 forwards;
  }
`;
const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  p {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    line-height: 1rem;

    + p {
      margin-left: 0.5rem;
    }

    img {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.25rem;
    }
  }
`;
