import React from 'react';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { RegisterLightUserModal } from './RegisterLightUserModal';
import { useUser } from '../../redux/user/useUser';
import { RegisterFreeUserModal } from './RegisterFreeUserModal';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import useLocationChange from '../../common/customHooks/LocationChange';
import { RegisterBasicUserModal } from './RegisterBasicUserModal';
import { RegisterTeamSubscriptionUserModal } from './RegisterTeamSubscriptionUserModal';
import { RegisterTeamSubscriptionToTeamAdminModal } from './RegisterTeamSubscriptionToTeamAdminModal';

export const CommonModals: React.FC = (): JSX.Element => {
  const {
    registerFreeUserModal,
    registerLightUserModal,
    registerBasicUserModal,
    registerPremiumUserModal,
    registerTeamSubscriptionUserModal,
    registerTeamSubscriptionToTeamAdminModal,
    closeAllModals,
  } = useCommonModal();
  const { permissionCheck } = useUser();

  // 画面遷移したら全部閉じる
  useLocationChange(closeAllModals);

  return (
    <>
      {/* 会員登録を促すモーダル */}
      {permissionCheck(FunctionType.RecommendFree, PermissionType.Read) &&
        registerFreeUserModal.isOpen && (
          <RegisterFreeUserModal
            isOpen={registerFreeUserModal.isOpen}
            onClose={registerFreeUserModal.close}
          />
        )}
      {/* ライトプラン以上の契約を促すモーダル */}
      {permissionCheck(FunctionType.RecommendLight, PermissionType.Read) &&
        registerLightUserModal.isOpen && (
          <RegisterLightUserModal
            isOpen={registerLightUserModal.isOpen}
            onClose={registerLightUserModal.close}
          />
        )}
      {/* ベーシックプラン以上の契約を促すモーダル */}
      {permissionCheck(FunctionType.RecommendBasic, PermissionType.Read) &&
        registerBasicUserModal.isOpen && (
          <RegisterBasicUserModal
            isOpen={registerBasicUserModal.isOpen}
            onClose={registerBasicUserModal.close}
          />
        )}
      {/* プレミアムプラン以上の契約を促すモーダル ※現状はないのでベーシックプラン以上のモーダルを設定 */}
      {permissionCheck(FunctionType.RecommendPremium, PermissionType.Read) &&
        registerPremiumUserModal.isOpen && (
          <RegisterBasicUserModal
            isOpen={registerPremiumUserModal.isOpen}
            onClose={registerPremiumUserModal.close}
          />
        )}
      {/* 法人管理者未課金ユーザーに課金を促すモーダル */}
      {permissionCheck(FunctionType.RecommendTeamSubscription, PermissionType.Read) &&
        registerTeamSubscriptionUserModal.isOpen && (
          <RegisterTeamSubscriptionUserModal
            isOpen={registerTeamSubscriptionUserModal.isOpen}
            onClose={registerTeamSubscriptionUserModal.close}
          />
        )}
      {/* 法人メンバー未課金ユーザーに管理者へ課金を促すモーダル */}
      {permissionCheck(FunctionType.RecommendTeamSubscriptionToAdmin, PermissionType.Read) &&
        registerTeamSubscriptionToTeamAdminModal.isOpen && (
          <RegisterTeamSubscriptionToTeamAdminModal
            isOpen={registerTeamSubscriptionToTeamAdminModal.isOpen}
            onClose={registerTeamSubscriptionToTeamAdminModal.close}
          />
        )}
    </>
  );
};
