import * as yup from 'yup';

// 会社名
const companyName = yup
  .string()
  .required('会社名を入力してください')
  .max(100, '会社名は100文字以内で入力してください')
  .trim();

// 請求先メールアドレス
const adminEmail = yup
  .string()
  .required('請求先メールアドレスを入力してください')
  .email('請求先メールアドレスは正しい形式で入力してください')
  .max(100, '請求先メールアドレスは100文字以内で入力してください')
  .trim();

// 会社情報入力フォームのschema
export const teamCreationSchema = yup.object({
  companyName,
  adminEmail,
});

// 会社情報更新フォームのschema
export const teamUpdateSchema = teamCreationSchema;
