import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { REVIEW_COMMENT_PLACE_HOLDER } from '../../const/PracticeSubmit';

import { Button } from '../atoms/Button';
import { RichInput, RichInputHandler } from '../molecules/RichInput';
import { PracticeSubmitReviewCreateModal } from './PracticeSubmitReviewCreateModal';

interface Props {
  practiceSubmitID: string;
  practiceSubmitDetailID: string;
  fetchPracticeSubmitForInstructor: () => Promise<void>;
}

export const PracticeSubmitReviewCreate: React.FC<Props> = (props) => {
  const [createReviewIsOpen, setCreateReviewIsOpen] = useState(false);
  const [disabledReview, setDisabledReview] = useState(true);
  const [passed, setPassed] = useState(false);
  const commentRef = useRef<RichInputHandler>(null);

  const handleContentChange = () =>
    setDisabledReview(commentRef.current?.getValue()?.trim().length === 0);

  const clickCreateReviewFailed = () => {
    setPassed(false);
    setCreateReviewIsOpen(true);
  };

  const clickCreateReviewPassed = () => {
    setPassed(true);
    setCreateReviewIsOpen(true);
  };

  return (
    <>
      <Container>
        <RichInput
          name="create_review_comment"
          fileUpload={false}
          imageUpload
          placeholder={REVIEW_COMMENT_PLACE_HOLDER}
          onKeyUp={handleContentChange}
          ref={commentRef}
          initialHeight={15}
        />
        <ButtonArea>
          <Button onClick={clickCreateReviewFailed} disabled={disabledReview} border>
            差し戻しにする
          </Button>
          <Button onClick={clickCreateReviewPassed} disabled={disabledReview} border>
            合格にする
          </Button>
        </ButtonArea>
      </Container>
      <PracticeSubmitReviewCreateModal
        isOpen={createReviewIsOpen}
        toggle={setCreateReviewIsOpen}
        practiceSubmitID={props.practiceSubmitID}
        practiceSubmitDetailID={props.practiceSubmitDetailID}
        content={commentRef.current?.getValue() ?? ''}
        passed={passed}
        fetchPracticeSubmitForInstructor={props.fetchPracticeSubmitForInstructor}
      />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem 2rem 1rem 2rem;

  ${media.lessThan('medium')`
    padding: 1rem;
  `}
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
