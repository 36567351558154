export const TranslateCognitoError = (enError: string): string => {
  switch (enError) {
    case 'User does not exist.':
      return 'ユーザーが存在しません';
    case 'Incorrect username or password.':
      return 'ユーザー名またはパスワードが違います';
    case 'User is not confirmed.':
      return 'ユーザーは検証されていません';
    case 'User already exists':
      return 'ユーザーは既に存在します';
    case 'Invalid verification code provided, please try again.':
      return '指定された確認コードが無効です。もう一度お試しください。';
    case 'Invalid password format':
      return 'パスワードのフォーマットが不正です';
    case 'An account with the given email already exists.':
      return 'そのメールアドレスは既に存在します';
    case 'Username cannot be empty':
      return 'ユーザー名は必須です';
    case 'Password attempts exceeded':
      return 'パスワード試行回数が超過しました';
    case 'Attempt limit exceeded, please try after some time.':
      return '試行制限を超過しました。しばらくしてからもう一度お試しください';
    case 'Username/client id combination not found.':
      return 'ユーザーが存在しません';
    case 'Invalid code provided, please request a code again.':
      return '無効なコードです。もう一度コードを確認してください。';
    case 'If you have already registered an account with an email address, you will not be able to register/login with a new social account with the same email address. Please try logging in with your email address and password.':
      return '既にメールアドレスでアカウント登録されている方は、同じメールアドレスを持ったソーシャルアカウントで新たに登録/ログインすることはできません。お手数ですがメールアドレスとパスワードからのログインをお試しください。';
    case 'Temporary password has expired and must be reset by an administrator.':
      return '仮パスワードの有効期限が切れています。カスタマーサポートへお問合せください。';
    case 'User password cannot be reset in the current state.':
      return 'パスワードが設定されておりません。仮パスワードがメールアドレスに送付されておりますので、ログイン後にパスワードの設定をお願いします。';
    default:
      return enError;
  }
};
