import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import {
  addMonths,
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  format,
} from 'date-fns';
import { StudentCourseFragment as Course } from '../../gen/graphql';
import { BOUNDARY_VALUE_OF_COURSE_DAYS_LEFT, CourseStatus } from '../../const/Course';
import differenceInDaysGreaterThanZero from '../../utils/DateCalc';

const daysAfter = (from: Date) => differenceInDays(new Date(), from);
const monthsAfter = (from: Date) => differenceInMonths(new Date(), from);
const weeksAfter = (from: Date) => {
  const now = new Date();
  const newMonth = addMonths(from, monthsAfter(from));
  return differenceInWeeks(now, newMonth) + 1;
};
const progressPercentage = (from: Date, to: Date) => {
  return (daysAfter(from) / differenceInDays(to, from)) * 100;
};

interface TopCourseInfoProps {
  course: Course;
}

export const TopCourseInfo: React.FC<TopCourseInfoProps> = ({ course }) => {
  return (
    <Container>
      <Labels>
        <h3>
          {course.status === CourseStatus.ABSENCE && <Absence>休学中</Absence>}
          <BrOnlySp />
          {course.plan?.name}
        </h3>
        {!course.curriculum ? <Curriculum>カリキュラム未登録</Curriculum> : ''}
      </Labels>
      <Progress>
        <Days>
          <p>入学から</p>
          <p>
            <span>{course.startDate && daysAfter(new Date(course.startDate))}</span>日
          </p>
        </Days>
        <ProgressBarArea>
          <ProgressBarLabel>
            <div className="labels">
              <div className="label">
                <p>入学</p>
                <p>
                  <span>
                    {course.startDate && format(new Date(course.startDate), 'yy年MM月dd日')}
                  </span>
                </p>
              </div>
              <div className="label">
                <p>卒業</p>
                <p>
                  <span>
                    {course.status === CourseStatus.ABSENCE
                      ? '休学中'
                      : course.endDate && format(new Date(course.endDate), 'yy年MM月dd日')}
                  </span>
                </p>
              </div>
            </div>
            {course.startDate && course.endDate && (
              <Balloon
                percentage={progressPercentage(
                  new Date(course.startDate),
                  new Date(course.endDate),
                )}
              >
                <div>
                  {course.startDate &&
                    (course.endDate &&
                    differenceInDaysGreaterThanZero(new Date(course.endDate)) <=
                      BOUNDARY_VALUE_OF_COURSE_DAYS_LEFT ? (
                      <p>
                        あと
                        <span>{differenceInDaysGreaterThanZero(new Date(course.endDate))}</span>日
                      </p>
                    ) : (
                      <p>
                        {monthsAfter(new Date(course.startDate))}ヶ月
                        {weeksAfter(new Date(course.startDate))}週目
                      </p>
                    ))}
                </div>
              </Balloon>
            )}
          </ProgressBarLabel>
          {course.startDate && course.endDate && (
            <ProgressBar
              percentage={progressPercentage(new Date(course.startDate), new Date(course.endDate))}
            >
              <div className="bar"></div>
            </ProgressBar>
          )}
        </ProgressBarArea>
      </Progress>
    </Container>
  );
};

const Container = styled.section`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 13px 26px;
  background-color: #ffffff;
  max-width: 824px;
  margin-top: 15px;

  ${media.lessThan('large')`
    padding: 24px 16px 30px 16px;
    margin-top: 32px;
  `}
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 0.8rem;
    font-weight: bold;

    ${media.lessThan('large')`
      font-size: 1rem;
    `}
  }

  ${media.lessThan('large')`
    display: block;
  `}
`;

const Absence = styled.span`
  display: inline-block;
  border: 1px solid #eb0000;
  color: #eb0000;
  padding: 1px 6px;
  font-size: 0.8125rem;
  margin-right: 6px;

  ${media.lessThan('large')`
    display: inline-block;
    margin-bottom: 6px;
  `}
`;

const BrOnlySp = styled.br`
  display: none;

  ${media.lessThan('large')`
    display: block;
  `}
`;

const Curriculum = styled.span`
  padding: 6px 12px;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.23);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  ${media.lessThan('large')`
    display: inline-block;
    margin-top: 8px;
  `}
`;

const Progress = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  ${media.lessThan('large')`
    display: block;
  `}
`;

const Days = styled.div`
  margin-left: 10px;

  p:nth-of-type(1) {
    font-size: 0.6rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
  }

  p:nth-of-type(2) {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.45);

    span {
      font-size: 2rem;
      color: #ee4646;
      font-weight: bold;
    }
  }

  ${media.lessThan('large')`
    margin-left: 0;
  `}
`;

const ProgressBarArea = styled.div`
  width: 669px;

  ${media.lessThan('large')`
    width: 100%;
    margin-top: 8px;
  `}
`;

const ProgressBarLabel = styled.div`
  position: relative;
  width: 100%;

  .labels {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .label {
      &:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      p {
        font-size: 0.625rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);

        span {
          font-size: 0.5625rem;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
`;

const Balloon = styled.div<{ percentage: number }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: clamp(100px, ${(props) => props.percentage}%, calc(100% - 100px));
  top: 0;
  transform: translate(-50%, -6px);
  width: 74px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;

  p {
    font-size: 0.5625rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    margin-left: -7px;
    border: 6px solid transparent;
    border-top: 6px solid #ffffff;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 50%;
    margin-left: -8px;
    border: 7px solid transparent;
    border-top: 7px solid rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  p:nth-of-type(1) {
    span {
      font-size: 1rem;
      color: #ee4646;
      font-weight: bold;
    }
  }
`;

const ProgressBar = styled.div<{ percentage: number }>`
  width: 100%;
  height: 14px;
  border-radius: 2px;
  overflow: hidden;
  background-color: #f0f0f0;
  margin-top: 8px;

  .bar {
    width: ${(props) => props.percentage}%;
    height: 100%;
    background: linear-gradient(90deg, rgb(253, 130, 88), rgb(253, 60, 47));
  }
`;
