import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import UserType from '../../const/UserType';

import { Button } from '../atoms/Button';
import { CircularIcon } from '../atoms/CircularIcon';
import { CurrentUserFragment as User } from '../../gen/graphql';

import { FunctionType, PermissionType } from '../../const/UserPermission';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';

interface Props {
  studyReportId: number;
  user: User;
  isShow: boolean;
  submitComment: (studyReportId: number, comment: string) => Promise<void>;
}

export const StudyLogInput: React.FC<Props> = (props) => {
  const { openModal } = useCommonModal();
  const contentRef = useRef(document.createElement('textarea'));
  const [message, setMessage] = useState('');

  contentRef.current.onkeyup = () => {
    const lineBreak = contentRef.current.value.match(/\n/g);
    if (lineBreak) {
      if (lineBreak.length <= 10) {
        contentRef.current.style.height = `${lineBreak.length * 1.1 + 3}rem`;
      } else {
        contentRef.current.style.height = `${10 * 1.1 + 3}rem`;
      }
    } else {
      contentRef.current.style.height = '40px';
    }
  };

  const sendComment = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (openModal(FunctionType.StudyReportComment, PermissionType.Create)) return;

    //親コンポーネントでコメントの件数や内容を反映をしたかったので、親からpropsで渡されたメソッドを使ってコメントを送信
    props.submitComment(props.studyReportId, message).then(() => {
      setMessage('');
    });
  };

  return (
    <Container isShow={props.isShow}>
      <Icon>
        <StyledIcon
          src={props.user?.image}
          size={40}
          userType={
            props.user?.isInstructor
              ? UserType.Instructor
              : props.user?.isStudent
                ? UserType.Student
                : UserType.SnsUser
          }
        />
      </Icon>
      <InputArea>
        <TextArea
          name="content"
          value={message}
          placeholder="コメントを追加..."
          ref={contentRef}
          onChange={() => setMessage(contentRef.current.value)}
          onClick={(e: React.MouseEvent<HTMLTextAreaElement>) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          data-e2e="commentTextArea"
        />
        <SubmitButton onClick={sendComment} disabled={!message}>
          投稿する
        </SubmitButton>
      </InputArea>
    </Container>
  );
};

const Container = styled.div<{ isShow: boolean }>`
  display: flex;
  margin-top: 0;
  transition: all 0.2s;
  height: 0;
  overflow: hidden;

  ${(props) =>
    props.isShow
      ? `
    height: auto;
    margin-top: 0.75rem;
  `
      : ''}
`;
const Icon = styled.div`
  margin-right: 0.75rem;
  padding-top: 1rem;
`;
const StyledIcon = styled(CircularIcon)`
  margin-right: 0;
`;
const InputArea = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('small')`
    flex-direction: column;
    gap: .5rem;
  `}
`;
const TextArea = styled.textarea<{ initialHeight?: number }>`
  flex: 1;
  height: ${(props) => (props.initialHeight ? `${props.initialHeight}rem` : '40px')};
  padding: 0.5rem;
  resize: none;
  appearance: none;
  border: none;
  border-radius: 2px;
  background: #f5f5f5;
  box-sizing: border-box;
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
const SubmitButton = styled(Button)`
  padding-left: 1rem;
  padding-right: 1rem;

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
