import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import { useUser } from '../../../redux/user/useUser';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { isSome, none, Option } from 'fp-ts/Option';

import { Layout } from './Layout';
import { Input } from '../../../components/atoms/Input';
import { LoginModal } from '../../../components/organisms/LoginModal';
import { UserEmailValidation, EmailError } from '../../../utils/FormValidation';
import { useSafeAsyncCallback } from '../../customHooks/SafeAsyncCallback';
import { TranslateCognitoError } from '../../../utils/CognitoErrorDictionary';
// import * as Sentry from '@sentry/react';

export const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const { forgotPassword } = useUser();
  const [errorEmail, setErrorEmail] = useState<Option<EmailError>>(none);
  const [error, setError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const buttonRef = useRef(document.createElement('button'));

  const openModal = () => {
    setLoginModalOpen(true);
  };
  const closeModal = () => {
    setLoginModalOpen(false);
  };

  const inputEmail = (value: string) => {
    setEmail(value);
    setErrorEmail(UserEmailValidation(value));
  };

  const buttonDisabled = !email || isSome(errorEmail);

  const send = useSafeAsyncCallback(async (): Promise<void> => {
    if (buttonDisabled) return;
    await forgotPassword(email.trim())
      .then(() => {
        navigate('/changePasswordVerification');
      })
      .catch((err) => {
        setError(TranslateCognitoError(err.message));
        // Sentry.captureException(error);
      });
  });

  useEffect(() => {
    if (buttonDisabled) {
      buttonRef.current.classList.remove('active');
    } else {
      buttonRef.current.classList.add('active');
    }
  }, [buttonDisabled, email, errorEmail]);

  return (
    <>
      <Layout title="パスワード変更">
        <Container>
          <h4>パスワード変更</h4>
          <StyledInput
            name="email"
            type="email"
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => inputEmail(e.target.value)}
            placeholder="メールアドレス"
            error={isSome(errorEmail)}
          />
          <p className="err">{isSome(errorEmail) && errorEmail.value.message}</p>
          <p className="err">{error}</p>
          <button ref={buttonRef} onClick={send}>
            確認コード送信
          </button>
          <Suggestion>
            ログイン画面は
            <span onClick={openModal}>こちら</span>
          </Suggestion>
        </Container>
        <style jsx>{`
          h4 {
            text-align: center;
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 2rem;
          }

          .err {
            color: #f00;
            margin: 0.25rem 0;
            max-width: 300px;
            font-size: 0.85rem;
          }

          button {
            margin: 0.5rem 0;
            cursor: pointer;
            padding: 0.75rem 0;
            width: 310px;
            background: rgba(0, 0, 0, 0.36);
            color: #fff;
            font-size: 1rem;
            border: none;
            border-radius: 0;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }

          button.active {
            background-color: #e2001b;
            cursor: pointer;
          }
        `}</style>
      </Layout>
      <LoginModal isOpen={loginModalOpen} onClose={closeModal} />
    </>
  );
};

const Container = styled.div`
  ${media.lessThan('medium')`
    display: inline-block;
    margin-top: 50px;
    background: #fff;
    padding: 30px 20px;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.16);
  `}
  ${media.lessThan('small')`
    display: block;
  `}
  ${media.greaterThan('medium')`
    text-align: center;
  `}
`;

const StyledInput = styled(Input)`
  width: 290px;
  margin: 0 auto;
  font-size: 1rem;
  border: solid 1px rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  background: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  input {
    margin: 0;
  }

  ${media.lessThan('small')`
    width: 100%;
  `}
`;

const Suggestion = styled.p`
  margin-top: 0.4rem;
  font-size: 1rem;

  span {
    cursor: pointer;
    color: #fd6258;
    font-size: 1rem;
    text-decoration: underline;
    font-weight: bold;
  }
`;
