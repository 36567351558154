import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Either, isRight, tryCatch } from 'fp-ts/Either';

import Logo from '../../../static/image/logo.svg';

import { Button } from '../../atoms/Button';
import { RegisterForm } from '../../organisms/RegisterForm';
import { LoginModal } from '../../organisms/LoginModal';

import { useUser } from '../../../redux/user/useUser';
import {
  useToastsContext,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_INFO,
} from '../../../context/ToastsProvider';
import { RegisterUser } from '../../../domain/model/user/RegisterUser';
import { useRegisterMutation } from '../../../gen/graphql';
import { useTagManager } from '../../../common/customHooks/TagManager';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { LOWER_META_TITLE, SERVICE_NAME, SERVICE_NAME_FULL } from '../../../const/Service';
import { TranslateCognitoError } from '../../../utils/CognitoErrorDictionary';
import { strToBoolean } from '../../../utils/common';
import { NoHeaderLayout } from '../../templates/NoHeaderLayout';
import { sessionStorageSupport } from '../../../utils/sessionStorageSupport';

export const RegisterFreemium: React.FC = () => {
  const metaTitle = `アカウント作成 | ${LOWER_META_TITLE}`;

  const {
    user: { loginError },
    login,
    logout,
    fetchCurrentUserWithPermissions,
    permissionCheck,
  } = useUser();
  const navigate = useNavigate();
  const { showToast } = useToastsContext();
  const [cookies] = useCookies();

  const [clickableRegisterUserButton, setClickableRegisterUserButton] = useState(false);
  const [registerUserButtonClicked, setRegisterUserButtonClicked] = useState(false);

  const [registerMutation] = useRegisterMutation();

  // 「login=true」のパラメーターが付与されている場合はログインモーダルを開く
  const loginParm: boolean = strToBoolean(new URLSearchParams(location.search).get('login') ?? '');

  // form項目
  const [nickName, setNickName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [loginModalOpen, setLoginModalOpen] = useState(loginParm);

  const { pushEvent } = useTagManager();

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
  };

  // ソーシャルログインエラー表示用
  useEffect(() => {
    if (loginError) {
      showToast(TOAST_TYPE_ERROR, TranslateCognitoError(loginError));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 受講生またはインストラクターの場合に、/homeにリダイレクトをするようにする。
    if (permissionCheck(FunctionType.RegisterRedirectHome, PermissionType.Read)) {
      navigate('/home');
      return;
    }
  }, [permissionCheck, navigate]);

  const loginAfterRegister = async () => {
    await login(email, password)
      .then(() => {
        showToast(TOAST_TYPE_INFO, 'ユーザの登録に成功しました。');
        fetchCurrentUserWithPermissions().then((user) => {
          if (!user) {
            return;
          }
          pushEvent('gtm-register-trial');
        });
      })
      .catch(() => {
        showToast(TOAST_TYPE_ERROR, 'エラーが発生しました');
        openLoginModal();
      });
  };

  const submitUser = async () => {
    setRegisterUserButtonClicked(true);
    const registerUser: Either<Error, RegisterUser> = tryCatch(
      () =>
        new RegisterUser({
          nickName: nickName,
          email: email,
          password: password,
          smrc: cookies['smrc_'],
        }),
      (e) => {
        if (e instanceof Error) {
          return e;
        } else {
          setRegisterUserButtonClicked(false);
          throw new Error('網羅外のエラー');
        }
      },
    );

    if (isRight(registerUser)) {
      try {
        await registerMutation({
          variables: {
            input: {
              nickName: registerUser.right.nickName,
              email: registerUser.right.email,
              password: registerUser.right.password,
            },
          },
        });

        setEmail(registerUser.right.email);
        setPassword(registerUser.right.password);
        setRegisterUserButtonClicked(false);
        await loginAfterRegister();
        sessionStorageSupport.setItem('REGISTERED_TYPE', 'new');
        sessionStorageSupport.setItem('QUESTIONNAIRE', 'true');
        navigate('/registered');
      } catch (e) {
        showToast(TOAST_TYPE_ERROR, getApiErrorMessage(e));
        setRegisterUserButtonClicked(false);
      }
    } else {
      setRegisterUserButtonClicked(false);
    }
  };

  const transitionLogin = useCallback(async (): Promise<void> => {
    await logout();
    openLoginModal();
  }, [logout]);

  return (
    <NoHeaderLayout metaTitle={metaTitle}>
      <Header>
        <Link to="/lp">
          <img src={Logo} alt={SERVICE_NAME_FULL} />
        </Link>
        <LoginLink onClick={transitionLogin}>ログイン</LoginLink>
      </Header>

      <Main>
        <Wrapper>
          <Layout>
            <Left>
              <h3>お申し込みプラン</h3>
              <InformationContainer>
                <Name>{SERVICE_NAME}フリープラン</Name>
                <PriceField>
                  <Price>¥0</Price>
                  (税込)/円
                </PriceField>
                <Note>登録料・解約金は一切かかりません</Note>
              </InformationContainer>
            </Left>
            <Right>
              <Step>
                <StepTitle>アカウント作成</StepTitle>
                <Agreement>
                  会員登録により、
                  <a href="https://www.sejuku.net/corp/personal-information-signup" target="_blank">
                    個人情報の取り扱い
                  </a>
                  および
                  <a href="https://www.sejuku.net/corp/terakoya-agreement" target="_blank">
                    利用規約
                  </a>
                  に同意するものとします。
                </Agreement>
                <RegisterForm
                  nickName={nickName}
                  setNickName={setNickName}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  clickableRegisterButton={setClickableRegisterUserButton}
                  socialLoginCallbackURL={`/registered`}
                />
                <StepButton
                  onClick={submitUser}
                  disabled={!clickableRegisterUserButton || registerUserButtonClicked}
                >
                  {SERVICE_NAME}を無料で始める
                </StepButton>
                <StepButtonNote>※アカウント作成では料金の発生はありません。</StepButtonNote>
              </Step>
            </Right>
          </Layout>
        </Wrapper>
      </Main>

      <LoginModal isOpen={loginModalOpen} onClose={closeLoginModal} />
    </NoHeaderLayout>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 67px;
  padding: 0 2rem;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  img {
    height: 1.5rem;
  }

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;
const LoginLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.3125rem;
  height: 2.375rem;
  border: 1px solid #e2001b;
  border-radius: 0.125rem;
  box-sizing: border-box;
  cursor: pointer;
  color: #e2001b;
  font-size: 0.875rem;
  font-weight: 700;
  transition: all 0.2s;

  &:after {
    content: 'する';
  }

  &:hover {
    background: #e2001b;
    color: #fff;
  }

  ${media.lessThan('medium')`
    width: 6.8125rem;
    height: 2rem;

    &:after {
      content: none;
    }
  `}
`;

const Main = styled.main`
  background-color: #fcfcfc;
  box-sizing: border-box;
  padding: 2rem 2rem 8.125rem;

  ${media.lessThan('medium')`
    padding: 0 0 9.375rem;
  `}
`;
const Wrapper = styled.div`
  max-width: 62.5rem;
  margin: 0 auto;
`;

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    margin-top: 1.25rem;
  `}
`;
const Left = styled.div`
  width: 19.125rem;

  h3 {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.25rem;
  }

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: border-box;

    h3 {
      text-align: center;
    }
  `}
`;
const InformationContainer = styled.div`
  margin-top: 1rem;
  background: #fff;
  border: 0.125rem solid #eb0000;
  border-radius: 0.25rem;
`;
const Name = styled.p`
  font-weight: 700;
  text-align: center;
  padding: 0.375rem 1rem 0.5rem;
  background: #eb0000;
  color: #fff;
  font-size: 1rem;
  line-height: 1.125rem;
`;
const PriceField = styled.p`
  font-weight: 700;
  text-align: center;
  margin: 1rem auto 0;
  padding: 0 1rem;
  font-size: 1rem;
  line-height: 1.25rem;
`;
const Price = styled.span`
  font-size: 1.75rem;
  line-height: 1;
`;
const Note = styled.p`
  font-weight: 700;
  text-align: center;
  margin: 0.375rem auto 1rem;
  padding: 0 1rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
`;

const Right = styled.div`
  width: calc(100% - 13rem);
  max-width: 39.5rem;
  margin-left: 2rem;
  counter-reset: step;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: none;
    margin: 2rem auto 0;
  `}
`;
const Step = styled.section`
  padding: 2rem 3rem;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;

  &:nth-child(n + 2) {
    margin-top: 2rem;
  }

  ${media.lessThan('medium')`
    padding: 2rem;
  `}
`;
const StepTitle = styled.h2`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 700;
`;

const Agreement = styled.p`
  font-size: 0.75rem;
  line-height: 1.3;
  margin: 1rem auto 3rem;

  a {
    color: #fd6258;
    text-decoration: underline;
    font-size: 0.8rem;
    font-weight: bold;
  }

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
  `}
`;

const StepButton = styled(Button)`
  display: block;
  margin-top: 2rem;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 360px;
    margin: 2.625rem auto 0;
  `}
`;
const StepButtonNote = styled.p`
  margin-top: 0.375rem;
  font-size: 0.75rem;
  line-height: 1.6;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
