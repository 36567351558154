import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Ttl } from '../../atoms/LpTtl';
import { LpLanguageSlider } from '../../molecules/LpLanguageSlider';
import { LpLanguageScroll } from '../../molecules/LpLanguageScroll';
import { LpIconLanguage } from '../../molecules/LpIconLanguage';
import { Loader } from '../../../common/Loader';
import { AwsContent } from './AwsContent';

import IconHtml from '../../../static/image/lp/language_icon/html.png';
import IconJavascript from '../../../static/image/lp/language_icon/javascript.png';
import IconJquery from '../../../static/image/lp/language_icon/jquery.png';
import IconPhp from '../../../static/image/lp/language_icon/php.png';
import IconLaravel from '../../../static/image/lp/language_icon/laravel.png';
import IconWordpress from '../../../static/image/lp/language_icon/wordpress.png';
import IconGit from '../../../static/image/lp/language_icon/git.png';
import IconSql from '../../../static/image/lp/language_icon/sql.png';
import IconPython from '../../../static/image/lp/language_icon/python.png';
import IconPhotoshop from '../../../static/image/lp/language_icon/photoshop.png';
import IconXd from '../../../static/image/lp/language_icon/xd.png';
import IconDesign from '../../../static/image/lp/language_icon/design.png';
import IconCommon from '../../../static/image/lp/language_icon/common.png';
import IconOriginalApp from '../../../static/image/lp/language_icon/original_app.png';
import IconCareer from '../../../static/image/lp/language_icon/career.png';
import IconFreelance from '../../../static/image/lp/language_icon/freelance.png';
import IconCloud9 from '../../../static/image/lp/language_icon/cloud9.png';
import IconCommandLine from '../../../static/image/lp/language_icon/commandline.png';

import { useGetProgramQuery } from '../../../gen/graphql';
import { getChapters } from '../../../utils/Program';
import { PROGRAM_COUNT } from '../../../const/Program';

export const LpProgramList = (): JSX.Element => {
  const [isActiveIcon, setIsActiveIcon] = useState(52);

  const languageList = [
    {
      id: 52,
      name: 'HTML/CSS',
      image: IconHtml,
    },
    {
      id: 61,
      name: 'JavaScript',
      image: IconJavascript,
    },
    {
      id: 63,
      name: 'jQuery',
      image: IconJquery,
    },
    {
      id: 76,
      name: 'PHP',
      image: IconPhp,
    },
    {
      id: 88,
      name: 'Laravel',
      image: IconLaravel,
    },
    {
      id: 80,
      name: 'WordPress',
      image: IconWordpress,
    },
    {
      id: 99,
      name: 'Git',
      image: IconGit,
    },
    {
      id: 26,
      name: 'SQL',
      image: IconSql,
    },
    {
      id: 98,
      name: 'Python',
      image: IconPython,
    },
    {
      id: 22,
      name: 'Photoshop',
      image: IconPhotoshop,
    },
    {
      id: 30,
      name: 'Adobe XD',
      image: IconXd,
    },
    {
      id: 21,
      name: 'Webデザイン',
      image: IconDesign,
    },
    {
      id: 37,
      name: 'ITリテラシー',
      image: IconCommon,
    },
    {
      id: 38,
      name: 'プログラミング\n前提知識',
      image: IconCommon,
    },
    {
      id: 33,
      name: 'オリジナル\nアプリ企画',
      image: IconOriginalApp,
    },
    {
      id: 14,
      name: 'エンジニアへ転職',
      image: IconCareer,
    },
    {
      id: 73,
      name: 'フリーランス\nエンジニア',
      image: IconFreelance,
    },
    {
      id: 1,
      name: 'AWS Cloud9',
      image: IconCloud9,
    },
    {
      id: 2,
      name: 'コマンドライン',
      image: IconCommandLine,
    },
  ];

  const switchLanguage = (id: number) => {
    setIsActiveIcon(id);
  };

  const { data: programData, loading: programLoading } = useGetProgramQuery({
    variables: {
      programID: isActiveIcon,
    },
  });
  const program = programData?.program;
  const programTitle = program?.title ?? '';
  const chapters = program ? getChapters(program) : [];

  return (
    <Container data-gtm="textbook">
      <StyledTtl>
        <h2>充実の教材</h2>
        <p>Textbook</p>
      </StyledTtl>
      <Description>
        未経験から即戦力エンジニアになるための実践型教材が{PROGRAM_COUNT}種類以上。
        <br />
        制作物は転職の面接時や案件獲得の際のポートフォリオとしてもお使いいただけます。
      </Description>
      <ProgramInner>
        {window.innerWidth < 768 ? (
          <LpLanguageScroll>
            {languageList.map((item) => {
              return (
                <LpIconLanguage
                  id={item.id}
                  name={item.name}
                  image={item.image}
                  active={isActiveIcon === item.id}
                  switch={switchLanguage}
                />
              );
            })}
          </LpLanguageScroll>
        ) : (
          <LpLanguageSlider>
            {languageList.map((item) => {
              return (
                <LpIconLanguage
                  id={item.id}
                  name={item.name}
                  image={item.image}
                  active={isActiveIcon === item.id}
                  switch={switchLanguage}
                />
              );
            })}
          </LpLanguageSlider>
        )}
        <ProgramList>
          {!programLoading ? (
            <React.Fragment>
              <ProgramTitle>{programTitle}</ProgramTitle>
              <ChapterList>
                {chapters?.slice(0, 9).map((chapter) => {
                  return (
                    <ChapterItem>
                      <ChapterInner>
                        <ChapterTitle>{chapter?.title}</ChapterTitle>
                        <ChapterDescription>{chapter?.description}</ChapterDescription>
                      </ChapterInner>
                    </ChapterItem>
                  );
                })}
              </ChapterList>
            </React.Fragment>
          ) : (
            <Loader width={'100%'} height={'630px'} margin={'0'} number={10} />
          )}
        </ProgramList>
      </ProgramInner>

      <AwsContent />
    </Container>
  );
};

const Container = styled.section`
  margin: 5.625rem auto 7.5rem;
  padding: 5.5rem 0 0;

  ${media.lessThan('medium')`
    margin: 4rem auto;
    padding: 4rem 1rem 0;
  `}
`;
const StyledTtl = styled(Ttl)`
  margin-bottom: 3rem;

  ${media.lessThan('medium')`
    padding: 0 1rem;
    margin-bottom: 2rem;
  `}
`;
const Description = styled.p`
  margin-bottom: 3rem;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: center;

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
    padding: 0 1rem;
    font-size: 1rem;
    line-height: 1.375rem;
  `}
`;
const ProgramInner = styled.div`
  max-width: 1056px;
  margin: 0 auto;
  padding: 3.75rem 2.25rem 4rem;
  background: #f8f8f8;
  border-radius: 1.25rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    margin: 0 -1rem;
    padding: 2rem 1rem 4rem;
    border-radius: 0;
  `}
`;

const ProgramList = styled.div`
  max-width: 768px;
  margin: 3rem auto 0;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`;
const ProgramTitle = styled.h3`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;

  ${media.lessThan('medium')`
    margin-bottom: 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  `}
`;
const ChapterList = styled.ul`
  counter-reset: chapter;
  padding: 0.75rem 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    padding: 0;
  `}
`;
const ChapterItem = styled.li`
  padding: 0.5rem 0.75rem;

  &:nth-of-type(n + 2) {
    counter-increment: chapter;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('medium')`
    padding: 0.625rem 1rem;
  `}
`;
const ChapterInner = styled.div`
  padding-left: 3.375rem;
  position: relative;

  &:before {
    content: '0' counter(chapter);
    display: block;
    width: 1.625rem;
    height: 1.25rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25;
    position: absolute;
    top: 0;
    left: 1.75rem;
  }

  ${media.lessThan('medium')`
    padding-left: 1.6875rem;

    &:before {
      width: 1.25rem;
      left: 0;
    }
  `}
`;
const ChapterTitle = styled.h4`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
`;
const ChapterDescription = styled.p`
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8125rem;
  line-height: 1rem;

  ${media.lessThan('medium')`
    font-size: 0.75rem;
  `}
`;
