import React, { useCallback } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { CardBrand } from '../atoms/CardBrand';
import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';

import { useToastsContext } from '../../context/ToastsProvider';
import { StripePaymentMethodFragment } from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { defaultErrorMessage } from '../../const/ErrorMessage';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  toggle: (nextState: boolean) => void;
  cardData: StripePaymentMethodFragment;
  onSubmit: (id: string) => Promise<void>;
  fetchPayments: () => Promise<void>;
}

export const PaymentDeleteModal = ({
  isOpen,
  toggle,
  cardData,
  onSubmit,
  fetchPayments,
  ...props
}: Props): JSX.Element => {
  const [submitButtonClicked, setSubmitButtonClicked] = React.useState(false);
  const { showToast } = useToastsContext();

  const closeModal = () => {
    toggle(false);
  };

  const removeCard = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setSubmitButtonClicked(true);
      try {
        await onSubmit(cardData.id);
        await fetchPayments();
      } catch (e) {
        showToast(1, defaultErrorMessage);
        return;
      } finally {
        setSubmitButtonClicked(false);
      }

      showToast(0, '支払い方法の削除が完了しました。');
      toggle(false);
    }, [onSubmit, cardData.id, fetchPayments, showToast, toggle]),
  );

  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={closeModal}
      loading={props.loading}
      width={'572px'}
      header={<Title>登録カードの削除</Title>}
      footer={
        <ButtonWrapper>
          <Button onClick={closeModal} disabled={submitButtonClicked} gray>
            キャンセル
          </Button>
          <Button onClick={removeCard}>削除する</Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <Description>
          以下のカード情報を削除します。
          <br />
          よろしいですか？
        </Description>
        <Card>
          <Icon>
            <CardBrand brand={cardData.cardBrand} />
          </Icon>
          <TextWrapper>
            <Number>
              カード番号<span>**** **** **** {cardData.cardNumber}</span>
            </Number>
            <Limit>
              有効期限
              <span>
                {cardData.expireMonth}/{cardData.expireYear}
              </span>
            </Limit>
          </TextWrapper>
        </Card>
      </Container>
    </Modal>
  );
};

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
`;
const Container = styled.div`
  padding: 2rem 4rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 2rem 1.4rem;
  `}
`;
const Description = styled.p`
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
`;
const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem auto 0;
  padding: 1.5rem 3rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 1.5rem 1rem;
  `}

  ${media.lessThan('small')`
    align-items: flex-start;
  `}
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.875rem;
  height: 2.625rem;
  padding: 0.3125rem;
  box-sizing: border-box;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  ${media.lessThan('small')`
    width: 2.5rem;
    height: auto;
    padding: 0;
  `}
`;
const TextWrapper = styled.div`
  margin-left: 1.5rem;
`;
const Number = styled.p`
  font-size: 1rem;
  line-height: 1.125rem;

  span {
    display: inline-block;
    margin-left: 1rem;
  }

  ${media.lessThan('small')`
    span {
      display: block;
      margin: 0.5rem auto 0;
    }
  `}
`;
const Limit = styled.p`
  margin-top: 0.375rem;
  color: rgba(0, 0, 0, 0.36);
  font-size: 0.875rem;

  span {
    display: inline-block;
    margin-left: 0.5rem;
  }

  ${media.lessThan('small')`
    margin-top: 1rem;
  `}
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
