import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Slider from 'react-slick';

interface Props {
  className?: string;
}

export const LpSlider: React.FC<Props> = (props) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 451,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return <StyledSlider {...settings}>{props.children}</StyledSlider>;
};

const StyledSlider = styled(Slider)`
  max-width: 1186px;
  margin: 0 auto;
  padding: 0 3rem 2.625rem;
  overflow: hidden;

  .slick-list {
    overflow: visible;

    .slick-slide {
      padding: 0 1rem;
      box-sizing: border-box;
      opacity: 0;
      transition: opacity 0.3s;

      &.slick-active {
        opacity: 1;
      }
    }
  }

  .slick-prev,
  .slick-next {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background: #e2001b;
    border-radius: 50%;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    z-index: 2;

    &:before {
      content: none;
    }

    &:after {
      content: '';
      display: block;
      width: 0.75rem;
      height: 0.75rem;
      border-style: solid;
      border-width: 2px;
      border-color: #fff #fff transparent transparent;
    }
  }

  .slick-prev {
    left: 0.5rem;

    &:after {
      margin-left: 0.5rem;
      transform: rotate(-135deg);
    }
  }

  .slick-next {
    right: 0.5rem;

    &:after {
      margin-right: 0.5rem;
      transform: rotate(45deg);
    }
  }

  .slick-dots {
    bottom: 0;
    left: 0;
    font-size: 0;

    li {
      width: auto;
      height: auto;
      margin: 0 0.375rem;

      button {
        width: 0.625rem;
        height: 0.625rem;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 50%;

        &:before {
          content: none;
        }
      }

      &.slick-active button {
        background: #e2001b;
      }
    }
  }

  ${media.lessThan('medium')`
    padding: 0 2.5rem 2.625rem;

    .slick-list {
      .slick-slide {
        padding: 0 0.5rem;
      }
    }

    .slick-prev,
    .slick-next {
      width: 2rem;
      height: 2rem;

      &:after {
        width: 0.5rem;
        height: 0.5rem;
      }
    }

    .slick-prev:after {
      margin-left: 0.375rem;
    }

    .slick-next:after {
      margin-right: 0.375rem;
    }
  `}

  ${media.lessThan('small')`
    padding: 0 1.375rem 2.625rem;

    .slick-prev,
    .slick-next {
      width: 1rem;
      height: 1rem;
      background: none;
      border-radius: 0;
      box-shadow: none;
      overflow: visible;

      &:after {
        margin: 0;
        border-color: rgba(0,0,0,0.6) rgba(0,0,0,0.6) transparent transparent;
      }
    }
  `}
`;
