import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import 'react-datepicker/dist/react-datepicker.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import ForbiddenPage from '../public/Forbidden';
import { TeamSettingTabLayout } from '../../templates/TeamSettingTabLayout';
import { TeamSettingBreadcrumb } from '../../templates/TeamSettingBreadcrumb';
import { Button } from '../../atoms/Button';
import { CircularIcon } from '../../atoms/CircularIcon';
import { Input } from '../../atoms/Input';
import { PageWrapper } from '../../atoms/PageWrapper';
import { H3 } from '../../atoms/Typography';
import { LabeledLayout } from '../../molecules/LabeledLayout';
import { Loader } from '../../molecules/Loader';
import { TeamWithdrawModal } from '../../organisms/TeamWithdrawModal';

import DefaultImage from '../../../static/image/icon_default.svg';

import {
  useGetTeamQuery,
  useUpdateTeamMutation,
  TeamFragment,
  TeamInput,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useUser } from '../../../redux/user/useUser';
import { teamUpdateSchema } from '../../../common/formSchema/team';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { useToastsContext } from '../../../context/ToastsProvider';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { LOWER_META_TITLE } from '../../../const/Service';

const hasValueOrUnregistered = (value: string | number | null | undefined) => {
  return value ? value : <Unregistered>未登録</Unregistered>;
};

const Information: React.FC<{ team: TeamFragment }> = ({ team }) => {
  return (
    <>
      <LabeledLayout labelWidth="200px" label={<Label>プロフィール画像</Label>}>
        <FlexRow>
          <CircularIcon src={team.image ?? DefaultImage} size={60} />
        </FlexRow>
      </LabeledLayout>
      <LabeledLayout labelWidth={'200px'} label={<Label>請求先メールアドレス</Label>}>
        {hasValueOrUnregistered(team.adminEmail)}
      </LabeledLayout>
      <LabeledLayout labelWidth={'200px'} label={<Label>会社名</Label>}>
        {hasValueOrUnregistered(team.companyName)}
      </LabeledLayout>
    </>
  );
};

interface EditInformationProps {
  id: string;
  team: TeamFragment;
  whenSubmit: () => void;
}

const EditInformation: React.FC<EditInformationProps> = ({
  id,
  team: { adminEmail, companyName, image },
  whenSubmit,
}) => {
  const { showToast } = useToastsContext();

  const [newImage, setNewImage] = useState<File | null>(null);

  // メールアドレスの入力欄にフォーカスがあたっているか
  const [focusedEmail, setFocusedEmail] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<TeamInput>({
    resolver: yupResolver(teamUpdateSchema),
    mode: 'all',
    defaultValues: {
      adminEmail: adminEmail ?? '',
      companyName: companyName ?? '',
    },
  });

  const currentImageURL = useMemo((): string => {
    if (newImage) {
      return URL.createObjectURL(newImage);
    }
    return image || DefaultImage;
  }, [newImage, image]);

  const [updateTeam] = useUpdateTeamMutation({
    onCompleted: () => whenSubmit(),
  });

  const submit = useSafeAsyncCallback(
    useCallback(
      async (input: TeamInput) => {
        try {
          const updateTeamRequest = {
            image: newImage,
            adminEmail: input.adminEmail,
            companyName: input.companyName,
          };

          await updateTeam({
            variables: {
              id: id,
              input: updateTeamRequest,
            },
          });
        } catch (e) {
          showToast(1, getApiErrorMessage(e));
          return;
        }
        showToast(0, 'チーム情報の更新に成功しました。');
      },
      [id, newImage, showToast, updateTeam],
    ),
  );

  return (
    <>
      <LabeledLayout labelWidth="250px" label={<Label>プロフィール画像</Label>}>
        <FlexRow>
          <CircularIcon src={currentImageURL} size={60} />
          <input
            type="file"
            id="upload-img"
            accept="image/*"
            onChange={(e) => setNewImage(e.target.files?.[0] ?? null)}
            style={{ display: 'none' }}
          />
          <StyledButton htmlFor="upload-img">変更する</StyledButton>
        </FlexRow>
      </LabeledLayout>
      <LabeledLayout
        labelWidth={'250px'}
        label={
          <React.Fragment>
            <Label>請求先メールアドレス</Label>
            <Required>必須</Required>
          </React.Fragment>
        }
      >
        <Controller
          name="adminEmail"
          control={control}
          render={({ field, fieldState: { error, isDirty } }) => (
            <>
              <StyledInput
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                type="text"
                placeholder="yourmail@domain.com"
                error={!!error}
                validation={isDirty && !error}
                onBlur={() => {
                  setFocusedEmail(false);
                  field.onBlur();
                }}
                onFocus={() => setFocusedEmail(true)}
              />
              {/* 入力中の場合にメールアドレスの形式エラーを出したくないので、フォーカスがあたっていて入力中の値がある場合はエラーを出さない */}
              <ErrorText>{(!focusedEmail || !field.value) && error && error.message}</ErrorText>
            </>
          )}
        />
      </LabeledLayout>
      <LabeledLayout
        labelWidth={'250px'}
        label={
          <React.Fragment>
            <Label>会社名</Label>
            <Required>必須</Required>
          </React.Fragment>
        }
      >
        <Controller
          name="companyName"
          control={control}
          render={({ field, fieldState: { error, isDirty } }) => (
            <>
              <StyledInput
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                type="text"
                placeholder="株式会社テラコヤ"
                error={!!error}
                validation={isDirty && !error}
                onBlur={field.onBlur}
              />
              <ErrorText>{error && error.message}</ErrorText>
            </>
          )}
        />
      </LabeledLayout>
      <Submit onClick={handleSubmit(submit)} disabled={!isValid}>
        更新する
      </Submit>
    </>
  );
};

export const TeamProfile: React.FC = () => {
  const metaTitle = `チーム情報 | ${LOWER_META_TITLE}`;

  const { user, permissionCheck } = useUser();
  const [editing, setEditing] = useState(false);
  const [withdrawIsOpen, setWithdrawIsOpen] = useState(false);
  const { data, loading, refetch } = useGetTeamQuery({
    variables: {
      id: user.teamID ?? '',
    },
    skip: !user.teamID,
    notifyOnNetworkStatusChange: true,
  });
  const team = data?.team;
  const fetchTeam = useCallback(async () => {
    refetch();
  }, [refetch]);

  const whenEdit = useCallback(() => {
    fetchTeam();
    setEditing(false);
  }, [fetchTeam]);

  if (loading) {
    return <Loader display />;
  }

  if (!user.teamID || !team) {
    return ForbiddenPage;
  }

  return (
    <TeamSettingTabLayout activeTab="profile" metaTitle={metaTitle}>
      <PageWrapper>
        <TeamSettingBreadcrumb pageName="チーム情報" />
        <Container>
          <Content>
            {permissionCheck(FunctionType.TeamProfile, PermissionType.Update) && (
              <Edit onClick={(): void => setEditing(!editing)}>
                {editing ? '保存せず戻る' : '編集'}
              </Edit>
            )}
            <Header>
              <H3 color="Dark">チーム情報</H3>
            </Header>
            {editing ? (
              <EditInformation id={user.teamID} team={team} whenSubmit={whenEdit} />
            ) : (
              <Information team={team} />
            )}
          </Content>
          {!editing && (
            <Withdraw onClick={() => setWithdrawIsOpen(true)}>
              チームアカウントの削除はこちら
            </Withdraw>
          )}
          <TeamWithdrawModal isOpen={withdrawIsOpen} onClose={() => setWithdrawIsOpen(false)} />
        </Container>
      </PageWrapper>
    </TeamSettingTabLayout>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 820px;
  padding: 2rem;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0.1);

  & > * > *:nth-child(2) {
    min-height: 42px;
    width: 100%;
  }

  ${media.lessThan('small')`
    padding: 3rem 1rem 1rem;
  `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  font-weight: bold;
`;

const Edit = styled.p`
  position: absolute;
  top: 2rem;
  right: 2rem;
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0.4rem 0.8rem;
  color: #e2001b;
  cursor: pointer;

  ${media.lessThan('small')`
    top: 1rem;
    right: 1rem;
    padding: .375rem .75rem;
    font-size: .875rem;
  `}
`;

const Unregistered = styled.span`
  color: rgba(0, 0, 0, 0.36);
`;

const Required = styled.span`
  font-size: 10px;
  color: #ffffff;
  padding: 2px 8px;
  margin-top: -2px;
  margin-left: 0.5rem;
  background-color: #fd6258;
`;

const StyledButton = styled.label<{ editing?: boolean }>`
  padding: 0.5rem 1rem;
  color: #ffffff;
  background-color: #e2001b;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 0.5rem;
  cursor: pointer;
  ${(params): string => (params.editing !== false ? '' : 'display: none;')}
`;

const StyledInput = styled(Input)`
  height: 2.75rem;
  border-color: rgba(0, 0, 0, 0.36);
  border-radius: 0;

  input {
    padding: 0.75rem;
    outline: none;

    &:autofill {
      box-shadow: 0 0 0 1000px #fff inset;
    }
  }

  ${media.lessThan('medium')`
    height: 2.5rem;

    input {
      padding: .625rem .75rem;
    }
  `}
`;

const ErrorText = styled.p`
  color: #fd6258;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-left: 1rem;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 0.5rem;
  }
`;

const Submit = styled(Button)`
  display: block;
  width: 13.75rem;
  margin: 2rem auto 0;
  padding-left: 0;
  padding-right: 0;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 22.5rem;
  `}
`;

const Withdraw = styled.p`
  margin-top: 2rem;
  cursor: pointer;
`;
