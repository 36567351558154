import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

export const checkEnableFirebaseService: () => boolean = () =>
  pipe(
    O.fromNullable(process.env.REACT_APP_FIREBASE_ENABLE),
    O.fold(
      () => false,
      (env) => env === '1',
    ),
  );
