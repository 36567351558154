import React from 'react';
import styled from 'styled-components';

interface NavigationTextProps {
  children: string | React.ReactNode;
  active: boolean;
}

export const NavigationText: React.FC<NavigationTextProps> = (props) => {
  return <P active={props.active}>{props.children}</P>;
};

const P = styled.p<{ active: boolean }>`
  font-size: 14px;
  font-weight: 600;
  margin-top: 0.2rem;
  margin-left: 1rem;
  color: ${(props) => (props.active ? '#e2001b' : 'rgba(0, 0, 0, 0.87)')};

  transition: 0.1s;
  &:hover {
    color: #e2001b;
  }
`;
