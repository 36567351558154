import React, { useCallback, useState } from 'react';

import { Loader } from '../molecules/Loader';
import { useToastsContext } from '../../context/ToastsProvider';
import { SubscriptionCancelModal } from './SubscriptionCancelModal';
import { SubscriptionCancelReservedModal } from './SubscriptionCancelReservedModal';
import { WithdrawalQuestionnaireModal } from './WithdrawalQuestionnaireModal';
import {
  useCancelSubscriptionMutation,
  useRestoreSubscriptionMutation,
  StripeSubscriptionFragment,
  SubscriptionCancellationPeriodFragment,
  WithdrawalQuestionnaireInput,
  SubscriptionContractFragment,
} from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { useTagManager } from '../../common/customHooks/TagManager';
import { defaultErrorMessage } from '../../const/ErrorMessage';

interface Props {
  isOpen: boolean;
  subscription: StripeSubscriptionFragment;
  currentSubscriptionContract?: SubscriptionContractFragment;
  subscriptionCancellationPeriod?: SubscriptionCancellationPeriodFragment;
  cancelReserved: boolean;
  toggle: (nextState: boolean) => void;
  reFetch: () => Promise<void>;
}

export const SubscriptionCancelModalsArea: React.FC<Props> = ({
  isOpen,
  subscription,
  currentSubscriptionContract,
  subscriptionCancellationPeriod,
  cancelReserved,
  toggle,
  reFetch,
}): JSX.Element => {
  const { pushEvent } = useTagManager();
  const { showToast } = useToastsContext();
  const [cancelSubscriptionMutation] = useCancelSubscriptionMutation();
  const [restoreSubscriptionMutation] = useRestoreSubscriptionMutation();

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isOpenQuestionnaireModal, setIsOpenQuestionnaireModal] = useState(false);

  const closeModal = useCallback((): void => {
    toggle(false);
  }, [toggle]);

  const closeQuestionnaireModal = useCallback((): void => {
    setIsOpenQuestionnaireModal(false);
  }, []);

  const openQuestionnaireModal = useCallback((): void => {
    // 元のモーダルは閉じる
    closeModal();
    setIsOpenQuestionnaireModal(true);
  }, [closeModal]);

  // 解約
  const cancelSubscription = useSafeAsyncCallback(
    useCallback(
      async (input: WithdrawalQuestionnaireInput): Promise<void> => {
        // GTMに解約イベント送信
        pushEvent('gtm-churn-cancel-confirm');

        setShowLoader(true);
        try {
          await cancelSubscriptionMutation({
            variables: {
              input: input,
            },
          });
        } catch (e) {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          showToast(1, defaultErrorMessage);
          return;
        } finally {
          setShowLoader(false);
        }

        reFetch();
        showToast(0, '処理に成功しました');
        closeQuestionnaireModal();
      },
      [cancelSubscriptionMutation, pushEvent, showToast, reFetch, closeQuestionnaireModal],
    ),
  );

  // 解約のキャンセル
  const restoreSubscription = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        await restoreSubscriptionMutation();
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, defaultErrorMessage);
        return;
      } finally {
        setShowLoader(false);
      }

      reFetch();
      showToast(0, '処理に成功しました');
      closeModal();
    }, [restoreSubscriptionMutation, showToast, reFetch, closeModal]),
  );

  return (
    <React.Fragment>
      <Loader display={showLoader} />
      <SubscriptionCancelModal
        isOpen={isOpen && !cancelReserved}
        subscription={subscription}
        currentSubscriptionContract={currentSubscriptionContract}
        subscriptionCancellationPeriod={subscriptionCancellationPeriod}
        openQuestionnaireForm={openQuestionnaireModal}
        onClose={closeModal}
      />
      <SubscriptionCancelReservedModal
        isOpen={isOpen && cancelReserved}
        subscription={subscription}
        onSubmit={restoreSubscription}
        onClose={closeModal}
      />
      <WithdrawalQuestionnaireModal
        isOpen={isOpenQuestionnaireModal}
        isWithdrawal={false}
        onSubmit={cancelSubscription}
        onClose={closeQuestionnaireModal}
      />
    </React.Fragment>
  );
};
