import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import Thumbnail from '../../../static/image/lp/aws_thumbnail.jpg';

export const AwsContent: React.FC = () => {
  return (
    <Container data-gtm="awsContent">
      <Img src={Thumbnail} alt="aws Solutions Architect" />
      <TextArea>
        <Title>
          <span>NEW</span>AWSに特化した教材を追加！
        </Title>
        <Description>
          AWS認定資格の中級レベル SAA
          (ソリューションアーキテクトアソシエイト)の取得を目標に学習することで、「実践力」と「客観的な知識証明」の両方をもってエンジニア転職を目指せます。
        </Description>
      </TextArea>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  max-width: 1121px;
  margin: 7.5rem auto 0;
  padding: 0 2rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    display: block;
    margin-top: 4rem;
    padding: 0;
  `}
`;
const Img = styled.img`
  display: block;
  width: 390px;

  ${media.lessThan('large')`
    width: 36%;
  `}

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const TextArea = styled.div`
  flex: 1;
  margin-left: 1.5rem;

  ${media.lessThan('medium')`
    margin: 1rem auto 0;
  `}
`;
const Title = styled.h3`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;

  span {
    display: block;
    margin-right: 0.75rem;
    padding: 0 1.625rem 0 1.25rem;
    background: #f90;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent #fff transparent transparent;
      border-width: 1rem 0.625rem 1rem 0;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.5rem;

    span {
      margin-right: .375rem;
      padding: 0 .875rem 0 .75rem;
      font-size: .75rem;
      line-height: 1.25rem;

      &:after {
        border-width: .625rem .375rem .625rem 0;
      }
    }
  `}
`;
const Description = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;

  ${media.lessThan('medium')`
    font-weight: 400;
  `}
`;
