import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useGetTimelinesQuery } from '../../gen/graphql';
import { Button } from '../atoms/Button';
import { EmptyBlock } from '../molecules/EmptyBlock';
import { Loader } from '../molecules/Loader';
import { TimelineList } from './TimelineList';
import { Fab } from '../atoms/Fab';
import { useUser } from '../../redux/user/useUser';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { TimelinePostModalsArea } from './TimelinePostModalsArea';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface Props {
  withPostForm?: boolean; // 投稿フォームあり
}

export const Timeline: React.FC<Props> = ({ withPostForm }): JSX.Element => {
  const location = useLocation();
  const { permissionCheck } = useUser();
  const { openModal } = useCommonModal();

  const { data, error, loading, refetch, fetchMore } = useGetTimelinesQuery({
    variables: { first: 20 },
  });
  const pageInfo = data?.getTimelinesV1.pageInfo;
  const adminPageInfo = data?.getTimelinesV1.adminPageInfo;

  const [refetching, setRefetching] = useState(false);
  const [postModalIsOpen, setPostModalIsOpen] = useState(false);

  // NOTE: つぶやき・学習ログ削除時の挙動として、一旦は学習ログ画面に合わせて一覧を再読み込みさせるが、
  // infinite scrollさせるUIなのでユーザビリティは悪いかも(読み込んだ位置が戻ってしまうかも)
  const refetchTimelines = useCallback(async (): Promise<void> => {
    setRefetching(true);
    try {
      await refetch();
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
    } finally {
      setRefetching(false);
    }
  }, [refetch]);

  const fetchMoreTimelines = useCallback(async (): Promise<void> => {
    if (!pageInfo?.hasNextPage && !adminPageInfo?.hasNextPage) {
      return;
    }

    try {
      await fetchMore({
        variables: {
          after: pageInfo?.endCursor,
          adminAfter: adminPageInfo?.endCursor,
        },
      });
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
    }
  }, [pageInfo, adminPageInfo, fetchMore]);

  // 投稿ボタン押下
  const handleClickPostButton = useCallback((): void => {
    if (openModal(FunctionType.Timeline, PermissionType.Create)) return;
    setPostModalIsOpen(true);
  }, [openModal]);

  // 投稿モーダル閉じる
  const closePostModal = useCallback(() => setPostModalIsOpen(false), []);

  return (
    <>
      <Loader display={loading || refetching} />
      {error ? (
        <ErrorContent title="タイムラインの読み込みが行われませんでした">
          回線状況を確認して再読み込みを行ってください
          <ReloadButton onClick={refetchTimelines}>再読み込み</ReloadButton>
        </ErrorContent>
      ) : data?.getTimelinesV1.items.length === 0 ? (
        <ErrorContent title="現在タイムラインに表示させる情報がありません">
          興味のある各種タグをフォローして情報を収集しましょう
        </ErrorContent>
      ) : (
        <TimelineList
          items={data?.getTimelinesV1.items ?? []}
          fetchMore={fetchMoreTimelines}
          refetch={refetchTimelines}
          hasNext={!!(pageInfo?.hasNextPage || adminPageInfo?.hasNextPage)}
          referer={location.pathname}
        />
      )}
      {withPostForm &&
        (permissionCheck(FunctionType.Timeline, PermissionType.Create) ||
          permissionCheck(FunctionType.TimelineForInstructorAndCoach, PermissionType.Create)) && (
          <>
            <Fab onClick={handleClickPostButton}>+ 投稿する</Fab>
            <TimelinePostModalsArea
              isOpen={postModalIsOpen}
              onClose={closePostModal}
              onCompleted={refetchTimelines}
            />
          </>
        )}
    </>
  );
};

const ErrorContent = styled(EmptyBlock)`
  margin-bottom: 2rem;

  .reload {
    display: flex;
    align-items: center;
    jsutify-content: center;
    width: 10rem;
    height: 2.625rem;
    background-color: #eb0000;
    border-radius:;
  }
`;
const ReloadButton = styled(Button)`
  display: block;
  margin: 1.25rem auto 0;
`;
