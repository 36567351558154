import React, { useMemo } from 'react';
import styled from 'styled-components';

interface Props {
  size?: 'small' | 'normal' | 'large';
  className?: string;
}

export const Spinner: React.FC<Props> = ({ size = 'normal', className }): JSX.Element => {
  const styleProps = useMemo((): { size: string; fontSize: string } => {
    switch (size) {
      case 'small':
        return { size: '1.5rem', fontSize: '0.375rem' };
      case 'large':
        return { size: '6rem', fontSize: '1rem' };
      default:
        return { size: '3rem', fontSize: '0.625rem' };
    }
  }, [size]);

  return <StyledSpinner className={className} {...styleProps} />;
};

// @see https://projects.lukehaas.me/css-loaders/
const StyledSpinner = styled.div<{ size: string; fontSize: string }>`
  border-radius: 50%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin: 0 auto;
  font-size: ${({ fontSize }) => fontSize};
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(224, 224, 224, 0.2);
  border-right: 1.1em solid rgba(224, 224, 224, 0.2);
  border-bottom: 1.1em solid rgba(224, 224, 224, 0.2);
  border-left: 1.1em solid #e0e0e0;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
