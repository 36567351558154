import React from 'react';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ApolloError } from '@apollo/client';
import styled from 'styled-components';
import media from 'styled-media-query';

import { ChatMemberListFragment, ChatRoomListFragment } from '../../gen/graphql';

import { mergeRefs } from '../../utils/common';
import { ChatRoomListRow, createChatRoomListRowData } from './ChatRoomListRow';
import { Supplement as SupplementMessage } from '../molecules/Supplement';
import { CHAT_ROOM_HEIGHT } from '../molecules/ChatRoom';

interface Props {
  chatRoomId: number;
  chatRooms: ChatRoomListFragment[];
  onChangeRoom: (chatRoomId: number, chatMembers: ChatMemberListFragment[]) => void;
  hasNextPage: boolean | undefined;
  loadMore: (startIndex: number, stopIndex: number) => Promise<void> | void;
  error: ApolloError | undefined;
}

export const ChatRoomList: React.FC<Props> = ({
  chatRoomId,
  chatRooms,
  onChangeRoom,
  hasNextPage,
  loadMore,
  error,
}) => {
  const listRef = React.useRef<List>(null);

  const chatRoomsCount = hasNextPage ? chatRooms.length + 1 : chatRooms.length;
  const isChatRoomLoaded = (index: number) => !hasNextPage || index < chatRooms.length;
  const itemSizes = React.useRef<{ [key in string]: number }>({});
  const getItemSize = (index: number) => itemSizes.current[index.toString()] || CHAT_ROOM_HEIGHT;

  const itemData = createChatRoomListRowData(chatRoomId, chatRooms, isChatRoomLoaded, onChangeRoom);

  return (
    <Wrapper>
      <Contents chatRoomId={chatRoomId}>
        {error ? (
          <Err>
            <h4>ルームの読み込みが行われませんでした</h4>
            <p>回線状況を確認して再読み込みを行ってください</p>
          </Err>
        ) : chatRoomsCount === 0 ? (
          <Supplement>
            <SupplementMessage message="担当インストラクター（講師）が決まるとルームが作成されます。ご連絡が来るまで今しばらくお待ちください。" />
          </Supplement>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isChatRoomLoaded}
                itemCount={chatRoomsCount}
                loadMoreItems={loadMore}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    ref={mergeRefs(ref, listRef)}
                    onItemsRendered={onItemsRendered}
                    itemData={itemData}
                    itemCount={chatRoomsCount}
                    itemSize={getItemSize}
                    width={width}
                    height={height}
                  >
                    {ChatRoomListRow}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        )}
      </Contents>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Contents = styled.div<{ chatRoomId?: number }>`
  width: 360px;
  height: calc(100vh - 70px);
  min-height: 0;

  grid-template-areas: 'chat_room_view_contents';
  grid-template-rows: auto;
  overflow: hidden;

  display: grid;
  grid-area: chat_room_view;
  grid-template-columns: auto;
  position: relative;

  border-right: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  ${media.lessThan('medium')`
    width: 100%;
  `}

  ${(props) => {
    return props.chatRoomId
      ? media.lessThan('medium')`
        display: none;
      `
      : '';
  }};
`;

const Supplement = styled.div`
  margin: 1rem;
`;

const Err = styled.div`
  padding: 38px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  h4 {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }
  p {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-size: 0.9rem;
    text-align: center;
  }
`;
