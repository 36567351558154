import styled from 'styled-components';
import media from 'styled-media-query';
import { useParams } from 'react-router-dom';

import { useUser } from '../../../redux/user/useUser';

import { BasicLayout } from '../../templates/BasicLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { ShareButton } from '../../molecules/ShareButton';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { StudyLogArticle } from '../../organisms/StudyLogArticle';
import { StudyLogRank } from '../../organisms/StudyLogRank';

import { useGetStudyReportQuery, useCreateStudyReportCommentMutation } from '../../../gen/graphql';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { NotFoundPage } from '../public/NotFound';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';

export const StudyReportDetailContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathStudyReportID = Number(paramID);
  if (Number.isNaN(pathStudyReportID)) return NotFoundPage;

  return <StudyReportDetail studyReportID={pathStudyReportID} />;
};

interface StudyReportDetailProps {
  studyReportID: number;
}

const StudyReportDetail = (props: StudyReportDetailProps): JSX.Element => {
  const metaTitle = `学習ログ詳細 | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}の学習ログページです。あなたの学習状況を記録して勉強の習慣化を促進します。受講生一人ひとりの学習時間や学習されているプログラミング言語をランキング形式で見れる他、気になるログには「コメント」や「いいね」でリアクションできます。`;

  const { user, permissionCheck } = useUser();
  const [createStudyReportComment] = useCreateStudyReportCommentMutation();

  const studyReportData = useGetStudyReportQuery({
    variables: {
      id: props.studyReportID,
      userID: user.lmsUser?.id,
    },
  });
  const studyReport = studyReportData?.data?.getStudyReport;

  const storeSubmitComment = async (studyReportID: number, comment: string) => {
    try {
      await createStudyReportComment({
        variables: {
          studyReportID: studyReportID,
          input: {
            content: comment,
          },
        },
      });
      studyReportData.refetch();
    } catch (e) {
      return;
    }
  };

  const submitCommentForChild = async (studyReportID: number, comment: string) => {
    return storeSubmitComment(studyReportID, comment);
  };

  const breadcrumbs = [
    {
      label: 'ホーム',
      to: '/home',
    },
    {
      label:
        user.lmsUser?.id === studyReportData.data?.getStudyReport?.user?.id
          ? '自分の学習ログ'
          : 'みんなの学習ログ',
      to:
        user.lmsUser?.id === studyReportData.data?.getStudyReport?.user?.id
          ? '/study_report/my'
          : '/study_report/all',
    },
    {
      label: '学習ログ詳細',
    },
  ];

  return (
    <BasicLayout pageTitle="学習ログ" metaTitle={metaTitle} metaDescription={metaDescription}>
      <PageWrapper>
        <Breadcrumb links={breadcrumbs} />
        <Wrapper>
          <Left>
            <ContentWrapper>
              {user.lmsUser?.id === studyReportData.data?.getStudyReport?.user?.id ? (
                <Tweet>
                  <TweetTtl>Twitterで学習内容を仲間とシェアしましょう！</TweetTtl>
                  <ShareButton
                    content={
                      studyReportData.data?.getStudyReport?.content
                        ? studyReportData.data.getStudyReport.content
                        : ''
                    }
                    hashTags={['侍エンジニア塾']}
                  />
                </Tweet>
              ) : (
                ''
              )}
              {studyReport && (
                <StudyLogArticle
                  key={studyReport.id}
                  id={studyReport.id}
                  content={studyReport.content}
                  date={studyReport.date}
                  createdAt={studyReport.createdAt}
                  refetch={studyReportData.refetch}
                  user={studyReport.user}
                  editable={
                    permissionCheck(FunctionType.StudyReport, PermissionType.Update) &&
                    studyReport.user?.id === user.lmsUser?.id
                  }
                  disableLink
                  isShowCommentCount={true}
                  isShowCommentField={true}
                  isInnerCommentField={false}
                  isLimitComment={false}
                  isCommentBorder={true}
                  studyReportDetails={studyReport.studyReportDetails}
                  studyReportFavorites={studyReport.studyReportFavorites}
                  studyReportComments={studyReport.studyReportComments}
                  submitComment={submitCommentForChild}
                />
              )}
            </ContentWrapper>
          </Left>
          <Right>
            <StudyLogRank />
          </Right>
        </Wrapper>
      </PageWrapper>
    </BasicLayout>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    margin-top: .75rem;
  `}
`;

const Left = styled.div`
  flex: 1;

  ${media.lessThan('medium')`
    flex: none;
    width: 100%;
  `}
`;
const Right = styled.div`
  width: 40%;
  max-width: 284px;
  margin-left: 1.25rem;

  ${media.lessThan('medium')`
    width: calc(100% + 2rem);
    max-width: none;
    margin: 4rem -1rem 0;
  `}
`;

const Tweet = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0px;

  ${media.lessThan('medium')`
    display: block;
  `}
`;
const TweetTtl = styled.p`
  font-weight: 600;
  line-height: 1.5;

  ${media.lessThan('medium')`
    margin-bottom: 1rem;

    br {
      display: none;
    }
  `}
`;

const ContentWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;
