import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import LogoImage from '../../static/image/lp/logo.svg';
import LogoImageSmall from '../../static/image/logo.svg';

import { Z_INDEX_APPBAR } from '../../const/ZIndex';

export const SubscriptionHeader: React.FC = (): JSX.Element => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <>
      <Header>
        <Content>
          <Inner>
            <Switch active={navOpen} onClick={toggleNav}>
              <span></span>
            </Switch>
            <Logo src={LogoImage} alt="株式会社SAMURAI" />
            <SpLogo src={LogoImageSmall} alt="株式会社SAMURAI" />
            <Links active={navOpen}>
              <ContactLink href="https://sejuku.net/contact/?cid=sns_lp_tocontact">
                お問い合わせ
              </ContactLink>
            </Links>
          </Inner>
        </Content>
        <SpNav active={navOpen}>
          <ContactButton href="https://sejuku.net/contact/?cid=sns_lp_tocontact">
            お問い合わせ
          </ContactButton>
        </SpNav>
      </Header>
    </>
  );
};

const Header = styled.header`
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX_APPBAR};
`;
const Content = styled.div`
  padding: 1rem 0 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    padding: 0.75rem 0;
    border-bottom: none;
  `}
`;
const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;

  ${media.lessThan('large')`
    padding: 0 2rem;
  `}
  ${media.lessThan('medium')`
    flex-direction: row-reverse;
    padding: 0 1rem;
    position: relative;
  `}
`;
const Switch = styled.div<{ active: boolean }>`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    position: relative;

    span {
      display: block;
      width: 1.125rem;
      height: 0.125rem;
      margin: auto;
      background: #000;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all .2s;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: #000;
        position: absolute;
        left: 0;
        transition: all .2s;
      }

      &:before {
        top: -0.3125rem;
      }

      &:after {
        bottom: -0.3125rem;
      }
    }
  `}

  ${(props) =>
    props.active
      ? media.lessThan('medium')`
    span {
      background: rgba(0,0,0,0);

      &:before {
        top: 0;
        transform: rotate(45deg);
      }

      &:after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  `
      : ''}

  ${media.lessThan('small')`
    margin-left: 0.5rem;
  `}
`;
const Logo = styled.img`
  max-height: 24px;

  ${media.lessThan('large')`
    width: 30%;
  `}
  ${media.lessThan('medium')`
    display: none;
    width: 165px;
    max-height: 2rem;
    max-width: 15rem;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1rem;
  `}
  ${media.lessThan('small')`
    width: 7.5rem;
  `}
`;
const SpLogo = styled(Logo)`
  display: none;

  ${media.lessThan('medium')`
    display: block;
  `}
`;
const Links = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex: 1;

  ${media.lessThan('large')`
    margin-left: 2rem;
  `}

  opacity: ${(props) => (props.active ? '0' : '1')};
  pointer-events: ${(props) => (props.active ? 'none' : 'all')};
`;
const StyledLink = styled.a`
  display: block;
  width: 100%;
  max-width: 160px;
  padding: 0.875rem;
  border: 1px solid #e2001b;
  border-radius: 4px;
  box-sizing: border-box;
  color: #e2001b;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  text-align: center;

  ${media.lessThan('large')`
    flex: 1;
  `}
`;
const ContactLink = styled(StyledLink)`
  ${media.lessThan('medium')`
    display: none;
  `}
`;
const SpNav = styled.nav<{ active: boolean }>`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    width: 100%;
    height: 0;
    padding: 2.75rem 2rem 2.5rem;
    background: rgba(255,255,255,0.96);
    box-sizing: border-box;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    transition: all .2s;
    opacity: 0;
    pointer-events: none;
    overflow-y: scroll;
  `}

  ${(props) =>
    props.active
      ? media.lessThan('medium')`
    height: calc(100vh - 57px);
    opacity: 1;
    pointer-events: all;
  `
      : ''}
`;
const ContactButton = styled.a`
  display: block;
  margin-top: 1.5rem;
  padding: 0.75rem 0;
  border: 1px solid #e2001b;
  border-radius: 0.25rem;
  color: #e2001b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;
`;
