import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import stripeJs, { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import * as O from 'fp-ts/lib/Option';
import * as E from 'fp-ts/lib/Either';
import { Either } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';

import { Button } from '../atoms/Button';
import { Loader } from '../molecules/Loader';
import { Modal } from '../molecules/Modal';
import { RegisterPayment } from './RegisterPayment';

import {
  CardCvcError,
  CardExpirationError,
  CardNumberError,
  LmsStripeValidationError,
  Payment,
  StripePaymentService,
  StripePaymentServiceImpl,
  StripeSystemError,
} from '../../infrastructure/externalService/StripePaymentService';
import { useToastsContext } from '../../context/ToastsProvider';
import { defaultErrorMessage } from '../../const/ErrorMessage';
import { getApiErrorMessage } from '../../utils/graphqlError';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  toggle: (nextState: boolean) => void;
  onSubmit: (providerPaymentMethodID: string) => Promise<void>;
  fetchPayments: () => Promise<void>;
}

export const PaymentAddModal: React.FC<Props> = (props) => {
  //Stripeでクレジットカード入力時に活用する項目
  const [stripe, setStripe] = React.useState<stripeJs.Stripe | null>(null);
  const [elements, setElements] = React.useState<stripeJs.StripeElements | null>(null);
  const stripeService: StripePaymentService = new StripePaymentServiceImpl(stripe);
  const [stripePromise] = React.useState(() =>
    loadStripe(
      pipe(
        O.fromNullable(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
        O.fold(
          () => '',
          (env) => env,
        ),
      ),
    ),
  );

  const [cardNumberError, setCardNumberError] = React.useState('');
  const [expirationError, setExpirationError] = React.useState('');
  const [cvcError, setCvcError] = React.useState('');
  const [submitButtonClicked, setSubmitButtonClicked] = React.useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const { showToast } = useToastsContext();

  const closeModal = () => {
    stripeService.clearElements(O.fromNullable(elements));
    props.toggle(false);
  };

  const setCardValidationStripeError = (e: LmsStripeValidationError) => {
    if (e instanceof CardNumberError) {
      setCardNumberError(e.message);
    } else if (e instanceof CardExpirationError) {
      setExpirationError(e.message);
    } else if (e instanceof CardCvcError) {
      setCvcError(e.message);
    } else {
      setCvcError(defaultErrorMessage);
    }
  };

  const initCreditInfoValidationMsg = () => {
    setCardNumberError('');
    setExpirationError('');
    setCvcError('');
  };

  const submit = async () => {
    setSubmitButtonClicked(true);
    setShowLoader(true);

    initCreditInfoValidationMsg();

    await stripeService
      .checkCreditCard(O.fromNullable(elements))
      .then((result: Either<LmsStripeValidationError, Payment>) => {
        pipe(
          result,
          E.fold(
            (e: LmsStripeValidationError) => {
              if (!(e instanceof StripeSystemError)) {
                setCardValidationStripeError(e);
              }
              showToast(1, getApiErrorMessage(e));
              setSubmitButtonClicked(false);
              setShowLoader(false);
            },
            async (payment: Payment) => {
              try {
                await props.onSubmit(payment.paymentId);
                await props.fetchPayments();
              } catch (e) {
                showToast(1, getApiErrorMessage(e));
                return;
              } finally {
                setSubmitButtonClicked(false);
                setShowLoader(false);
              }

              showToast(0, '支払い方法の追加が完了しました。');
              props.toggle(false);
              stripeService.clearElements(O.fromNullable(elements));
            },
          ),
        );
      });
  };

  return (
    <React.Fragment>
      <Loader display={showLoader}></Loader>
      <Modal
        underlayer
        isOpen={props.isOpen}
        onClose={closeModal}
        loading={props.loading}
        width={'572px'}
        header={<Title>支払い方法を追加</Title>}
        footer={
          <ButtonWrapper>
            <Button onClick={submit} disabled={submitButtonClicked}>
              追加する
            </Button>
          </ButtonWrapper>
        }
      >
        <Container>
          <Elements stripe={stripePromise}>
            <RegisterPayment
              numberError={cardNumberError}
              expirationError={expirationError}
              cvcError={cvcError}
              setStripe={setStripe}
              setElements={setElements}
            />
          </Elements>
        </Container>
      </Modal>
    </React.Fragment>
  );
};

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
`;
const ButtonWrapper = styled.div`
  text-align: right;
`;
const Container = styled.div`
  padding: 2rem 4rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 2rem 1.4rem;
  `}
`;
