import React from 'react';
import styled from 'styled-components';
import { Z_INDEX_LOADER } from '../../const/ZIndex';

interface LoaderProps {
  display: boolean;
}

export const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <React.Fragment>
      {props.display ? (
        <Container>
          <Loading />
        </Container>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: ${Z_INDEX_LOADER};
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justyfy-content: center;
  align-items: center;
`;

// https://projects.lukehaas.me/css-loaders/
const Loading = styled.div`
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;

  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &:before,
  &:after {
    content: '';
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
    position: absolute;
    top: 0;
  }

  &:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
`;
