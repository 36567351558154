import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';

interface TabProps {
  label: string;
  to: string;
  onClick?: () => void;
  active?: boolean;
  count?: number;
}

export const Tab: React.FC<TabProps> = (props) => {
  return props.to ? (
    <StyledLink to={props.to} onClick={props.onClick} $active={props.active}>
      <StyledP $active={props.active}>
        {props.label}
        {props.count ? <Count>{props.count}</Count> : ''}
      </StyledP>
    </StyledLink>
  ) : (
    <StyledLinkDiv onClick={props.onClick} $active={props.active}>
      <StyledP $active={props.active}>
        {props.label}
        {props.count ? <Count>{props.count}</Count> : ''}
      </StyledP>
    </StyledLinkDiv>
  );
};

const StyledLink = styled(Link)<{ $active?: boolean }>`
  display: inline-flex;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  border-bottom: ${(props) => (props.$active ? '2px solid #e2001b' : 'none')};
`;

const StyledLinkDiv = styled.div<{ $active?: boolean }>`
  display: inline-flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: ${(props) => (props.$active ? '2px solid #e2001b' : 'none')};
`;

const StyledP = styled.p<{ $active?: boolean }>`
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  color: ${(props) => (props.$active ? '#e2001b' : 'rgba(0, 0, 0, 0.6);')};
  position: relative;

  ${media.lessThan('small')`
    font-size: 0.9rem;
  `}
`;

const Count = styled.span`
  display: block;
  width: 1rem;
  height: 1rem;
  background: #eb0000;
  border-radius: 50%;
  color: #fff;
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1rem;
  text-align: center;
  position: absolute;
  left: 100%;
  bottom: calc(100% - 0.5rem);
`;
