import { RadioOption } from '../components/atoms/Radio';
import { SelectOption } from '../components/atoms/Select';
import { PracticeSubmitDetailReviewStatus, PracticeSubmitSortKey } from '../gen/graphql';
import { ALL } from './Filter';

export const REVIEWED = 1;
export const NOT_REVIEWED = 2;

export const REVIEW_COMMENT_PLACE_HOLDER = `例）課題提出ご苦労様です。課題自体は、合格です！1点気になったところをフィードバックします。hogehoge.htmlのファイルですが、インデントの使い方が若干綺麗ではなかったので、次回は以下の記事を参考にしながらコーディングしてみると良さそうです。

参考記事：www.code.com/1133445
`;

export const REVIEW_COMMENT_PASSED = `
## 次のステップ
合格後は、課題ページ最下部の「課題の解説を見る」から、本課題の解説をご覧いただけます。ぜひ復習して、理解をさらに深めましょう。
`;

export const REVIEW_COMMENT_FAILED = `
## 次のステップ
修正を行い、再提出をお願いします。不明点がある場合は、QA掲示板をご利用ください。
`;

export const PracticeSortByOptions: readonly SelectOption[] = [
  { value: PracticeSubmitSortKey.CreatedAt, name: '提出日が新しい順' },
  { value: PracticeSubmitSortKey.UpdatedAt, name: '更新日が新しい順' },
];

export const ReviewedOptions: readonly RadioOption[] = [
  { value: ALL.toString(), label: '全て' },
  { value: REVIEWED.toString(), label: 'レビューあり' },
  { value: NOT_REVIEWED.toString(), label: 'レビューなし' },
];

export const ReviewStatusOptions: readonly RadioOption[] = [
  { value: ALL.toString(), label: '全て' },
  { value: PracticeSubmitDetailReviewStatus.Waiting, label: 'レビュー待ち' },
  { value: PracticeSubmitDetailReviewStatus.Failed, label: '再提出' },
  { value: PracticeSubmitDetailReviewStatus.Passed, label: '合格' },
];

export const ReviewStatusLabels: { [key: string]: string } = {
  [PracticeSubmitDetailReviewStatus.Draft]: '下書き',
  [PracticeSubmitDetailReviewStatus.Passed]: '合格',
  [PracticeSubmitDetailReviewStatus.Failed]: '再提出',
  [PracticeSubmitDetailReviewStatus.Waiting]: 'レビュー待ち',
} as const;
