import moment from 'moment-timezone';

interface FullDateDetails {
  date: string;
  day: string;
  fromToTime: string;
}

moment.tz.setDefault('Asia/Tokyo');
moment.locale('ja');

const daysInJapanese = ['日', '月', '火', '水', '木', '金', '土'];

const zeroPadding = (num: number): string => `0${num}`.substr(-2);

const convertToJSTFormat = (date: string | Date, format: string): string => {
  // 曜日のみを取得する場合
  if (format === 'E') {
    const dayOfWeek = moment(date).format('d');
    return daysInJapanese[parseInt(dayOfWeek)];
  }

  return moment(date).format(format);
};

const getFullDateJST = (startAt: string | Date, endAt: string | Date): FullDateDetails => {
  const startJST = moment(startAt);
  const endJST = moment(endAt);

  const dayOfWeekStr = daysInJapanese[startJST.day()];
  const month = zeroPadding(startJST.month() + 1); // Month is 0-indexed
  const date = zeroPadding(startJST.date());
  const startHour = zeroPadding(startJST.hour());
  const startMinute = zeroPadding(startJST.minute());
  const endHour = zeroPadding(endJST.hour());
  const endMinute = zeroPadding(endJST.minute());

  return {
    date: `${month}/${date}`,
    day: dayOfWeekStr,
    fromToTime: `${startHour}:${startMinute}~${endHour}:${endMinute}`,
  };
};

export { convertToJSTFormat, getFullDateJST };
