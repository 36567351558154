import React from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { H3 } from '../../atoms/Typography';
import { CourseProgramArticle } from '../../organisms/CourseProgramArticle';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { ProgramListContainer } from '../../atoms/ProgramListContainer';

import { useGetCourseProgramsQuery } from '../../../gen/graphql';

import { useUser } from '../../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../../const/UserPermission';

interface ProgramWhileStudyingsProps {
  showWhileStudyingLink: boolean;
  showNoContentsMessage: boolean;
}

export const CourseProgramWhileStudyings: React.FC<ProgramWhileStudyingsProps> = (props) => {
  const programWhileStudyinsLink = '/programs_while_studying';

  const { data, loading } = useGetCourseProgramsQuery({
    variables: {
      input: {
        registeredCoursePrograms: false,
        isWhileStudying: true,
      },
    },
  });
  const { permissionCheck } = useUser();

  return (
    <ProgramSection>
      <StyledH3Block data-e2e="courseProgramWhileStudyingList">
        <StyledH3 color="DARK">学習中のコース教材</StyledH3>
        {props.showWhileStudyingLink && (
          <StyledH3Link to={programWhileStudyinsLink}>学習中のコース教材一覧 ＞</StyledH3Link>
        )}
      </StyledH3Block>
      {!loading &&
        (data?.coursePrograms && data?.coursePrograms.length > 0 ? (
          <ProgramListContainer>
            {data?.coursePrograms.map((p) => {
              return (
                <CourseProgramArticle
                  id={p?.id ?? ''}
                  key={p.id}
                  image={p?.image ?? ''}
                  title={p?.title ?? ''}
                  description={p.description}
                  totalChapterCount={p.totalChapterCount}
                  totalRequireTime={p.totalRequireTime}
                  totalCompletedTime={p.totalCompletedTime}
                  showProgress={permissionCheck(FunctionType.StudyLog, PermissionType.Read)}
                />
              );
            })}
          </ProgramListContainer>
        ) : (
          props.showNoContentsMessage && (
            <EmptyBlock title="学習中のコース教材はありません">
              コース教材を利用し学習を始めましょう。
            </EmptyBlock>
          )
        ))}
    </ProgramSection>
  );
};

const ProgramSection = styled.section`
  margin-bottom: 2.625rem;
`;

const StyledH3Block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const StyledH3 = styled(H3)`
  font-size: 1.25rem;
  display: inline-block;
  margin-bottom: 0;
`;

const StyledH3Link = styled(Link)<{ active?: boolean }>`
  display: inline-block;
  color: #e2001b;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
`;
