import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { CommonModals } from '../../organisms/CommonModals';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Loader } from '../../molecules/Loader';
import { QuestionFilter } from '../../organisms/QuestionFilter';
import { SearchHeader } from '../../organisms/SearchHeader';
import { SearchList } from '../../organisms/SearchList';
import { SearchTags } from '../../organisms/SearchTags';
import { SearchType } from '../../organisms/SearchType';
import { SearchFilter } from '../../organisms/SearchFilter';

import {
  DocumentSearchInput,
  DocumentSortKey,
  DocumentType,
  ProgramSearchType,
  ProgramType,
  SearchQuestionStatus,
  useGetTagsLazyQuery,
  useSearchDocumentsLazyQuery,
  useSearchProgramsQuery,
} from '../../../gen/graphql';
import { useUser } from '../../../redux/user/useUser';
import { FIRST_PAGE, MAX_LIMIT, MIDDLE_LIMIT, MIN_LIMIT } from '../../../const/Page';
import { ALL, INITIAL, INITIAL_ULID } from '../../../const/Filter';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { getAnswered, getSolved } from '../../../utils/QuestionFilter';
import { strToEnum } from '../../../utils/common';
import { useURL } from '../../../common/customHooks/URL';
import { chapterOrPracticeOptionValue } from '../../../utils/Filter';
import { getChapters, getPractices } from '../../../utils/Program';
import { LOWER_META_TITLE } from '../../../const/Service';
import { NoHeaderLayout } from '../../templates/NoHeaderLayout';

interface SearchState {
  pathname: string;
}

export const Search = (): JSX.Element => {
  const metaTitle = `検索 | ${LOWER_META_TITLE}`;

  const { permissionCheck, user } = useUser();
  const { queries, setParams } = useURL();

  const location = useLocation();
  const locationState = location.state as SearchState;
  const [originPath] = useState(locationState?.pathname);

  const navigate = useNavigate();

  const word = queries?.word ?? '';
  const answered = queries?.answered ? Number(queries.answered) : ALL;
  const solved = queries?.solved ? Number(queries.solved) : ALL;
  const searchQuestionStatus =
    strToEnum(queries?.search_question_status ?? '', SearchQuestionStatus) ??
    SearchQuestionStatus.All;
  const programID = queries?.program_id ? Number(queries.program_id) : INITIAL;
  const chapterID = queries?.chapter_id ? Number(queries.chapter_id) : INITIAL;
  const practiceID = queries?.practice_id ?? INITIAL_ULID;
  const tagID = queries?.tag_id ? Number(queries.tag_id) : INITIAL;
  const page = queries?.page ? Number(queries.page) : FIRST_PAGE;
  const searchTypes = useMemo(() => {
    const strTypes = queries?.search_type ? queries.search_type.split(',') : [];
    const types = strTypes
      .map((type) => {
        return strToEnum(type, DocumentType);
      })
      .filter((item): item is Exclude<typeof item, undefined> => item !== undefined);

    return types;
  }, [queries?.search_type]);

  const [searchTag, setSearchTag] = useState<string>('');

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const containerRef = useRef<HTMLDivElement>(null);

  const countSearchQuery = React.useMemo(() => {
    return word
      ? {
          text: word,
          types: searchTypes,
          program: programID !== ALL ? programID : undefined,
          chapter: chapterID !== ALL ? chapterID : undefined,
          practice: practiceID !== '' ? practiceID : undefined,
          tags: tagID ? [tagID] : [],
          isMine: false,
          questionHasAnswers: answered !== ALL ? getAnswered(answered) : undefined,
          questionIsResolve: solved !== ALL ? getSolved(solved) : undefined,
          searchQuestionStatus: searchQuestionStatus ?? SearchQuestionStatus.All,
        }
      : {
          types: searchTypes,
          tags: [],
          isMine: false,
        };
  }, [
    word,
    searchTypes,
    programID,
    chapterID,
    practiceID,
    tagID,
    answered,
    solved,
    searchQuestionStatus,
  ]);

  const query: DocumentSearchInput = React.useMemo(() => {
    return word
      ? {
          text: word,
          types: searchTypes,
          sortKey: DocumentSortKey.Latest,
          program: programID !== ALL ? programID : undefined,
          chapter: chapterID !== ALL ? chapterID : undefined,
          practice: practiceID !== '' ? practiceID : undefined,
          tags: tagID ? [tagID] : [],
          isMine: false,
          questionHasAnswers: answered !== ALL ? getAnswered(answered) : undefined,
          questionIsResolve: solved !== ALL ? getSolved(solved) : undefined,
          searchQuestionStatus: searchQuestionStatus ?? SearchQuestionStatus.All,
          page: page,
          limit: MIN_LIMIT,
        }
      : {
          types: searchTypes,
          sortKey: DocumentSortKey.Latest,
          isMine: false,
          page: page,
          limit: MIN_LIMIT,
        };
  }, [
    word,
    searchTypes,
    programID,
    chapterID,
    practiceID,
    tagID,
    answered,
    solved,
    searchQuestionStatus,
    page,
  ]);

  const { data: programsData, loading: programLoading } = useSearchProgramsQuery({
    variables: {
      input: {
        programSearchType: ProgramSearchType.All,
        type: permissionCheck(FunctionType.HomeForInstructorAndCoach, PermissionType.Read) // インスト・コーチの場合は全教材検索
          ? undefined
          : ProgramType.Normal,
        page: FIRST_PAGE,
        limit: MAX_LIMIT,
      },
    },
  });
  const programs = programsData?.programs;

  const [getTags, { data: tagData }] = useGetTagsLazyQuery();
  const tagsData = tagData?.getTags;
  const fetchTags = useCallback(
    (name: string, limit: number) => {
      getTags({
        variables: {
          input: {
            name,
            limit,
            page: FIRST_PAGE,
          },
        },
      });
    },
    [getTags],
  );

  const [searchDocuments, { data: documentData, loading: documentLoading }] =
    useSearchDocumentsLazyQuery();
  const documents = documentData?.searchDocuments;
  const fetchDocuments = useCallback(() => {
    searchDocuments({
      variables: {
        input: query,
        userID: user.lmsUser?.id,
      },
    });
  }, [searchDocuments, user.lmsUser?.id, query]);

  const setPage = (value: number) => {
    setParams([{ name: 'page', value: value.toLocaleString() }]);
  };

  const setSearchTypes = (value?: string) => {
    if (value) {
      // 検索条件変更時はpageをリセット
      setParams([{ name: 'search_type', value: value }, { name: 'page' }]);
    } else {
      // 検索条件変更時はpageをリセット
      setParams([{ name: 'search_type' }, { name: 'page' }]);
    }
  };

  const handleAnsweredSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'answered', value: value }, { name: 'page' }]);
  };

  const handleSolvedSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'solved', value: value }, { name: 'page' }]);
  };

  const handleSearchQuestionStatusSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'search_question_status', value: value }, { name: 'page' }]);
  };

  const handleProgramIDSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([
      { name: 'program_id', value: value },
      { name: 'chapter_id' },
      { name: 'practice_id' },
      { name: 'page' },
    ]);
  };

  const handleChapterIDOrPracticeIDSelect = (value: string) => {
    const [chapterId, practiceId] = chapterOrPracticeOptionValue(value);
    // 検索条件変更時はpageをリセット
    setParams([
      { name: 'chapter_id', value: chapterId },
      { name: 'practice_id', value: practiceId },
      { name: 'page' },
    ]);
  };

  const handleSearchTagSet = (value: string) => {
    setSearchTag(value);
  };

  const handleTagReset = () => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'tag_id' }, { name: 'page' }]);
  };

  const handleTagIDSelect = (value: number) => {
    // 検索条件変更時はpageをリセット
    setParams([
      { name: 'tag_id', value: value !== tagID ? value.toLocaleString() : INITIAL.toString() },
      { name: 'page' },
    ]);
  };

  const QuestionFilterProps = {
    permissionCheck,
    title: windowWidth > 768 ? 'フィルター' : '',
    displayAnswered: {
      answered,
      handleAnsweredSelect,
    },
    displaySolved: {
      solved,
      handleSolvedSelect,
    },
    displaySearchQuestionStatus: {
      searchQuestionStatus,
      handleSearchQuestionStatusSelect,
    },
    displayProgram: {
      programs: programs?.items ?? [],
      programID,
      handleProgramIDSelect,
    },
    displayChapterOrPractice: {
      chapters: programID
        ? (getChapters(programs?.items.find((i) => i.id === programID)) ?? [])
        : [],
      practices: programID
        ? (getPractices(programs?.items.find((i) => i.id === programID)) ?? [])
        : [],
      chapterID,
      practiceID,
      handleChapterIDOrPracticeIDSelect,
    },
    displayTag: {
      searchTag,
      handleSearchTagSet,
      tags: tagsData?.items ?? [],
      tagID,
      handleTagReset,
      handleTagIDSelect,
    },
  };

  useEffect((): void => {
    fetchDocuments();
  }, [fetchDocuments, searchTypes]);

  useEffect((): void => {
    fetchTags(searchTag, windowWidth > 1440 ? MIDDLE_LIMIT : MIN_LIMIT);
  }, [fetchTags, searchTag, windowWidth]);

  // NOTE: タグ取得件数変更のためのウインドウ幅監視処理
  useEffect((): (() => void) => {
    const resizeObserver = new ResizeObserver((entries: readonly ResizeObserverEntry[]) => {
      //https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/58701523#58701523
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        setWindowWidth(window.innerWidth);
      });
    });

    containerRef.current && resizeObserver.observe(containerRef.current);
    return (): void => resizeObserver.disconnect();
  }, []);

  return (
    <NoHeaderLayout metaTitle={metaTitle}>
      <div ref={containerRef}>
        <Loader display={programLoading || documentLoading} />
        <SearchHeader value={word} onClose={() => navigate(originPath ?? '/')} />
        {isOpenFilter ? (
          <SearchFilter
            onClose={() => setIsOpenFilter(false)}
            searchTypes={searchTypes}
            answered={answered}
            solved={solved}
            searchQuestionStatus={searchQuestionStatus}
            programID={programID}
            chapterID={chapterID}
            searchTag={searchTag}
            tagID={tagID}
            query={countSearchQuery}
          />
        ) : (
          <StyledPageWrapper>
            {word ? (
              <>
                <Left>
                  <PcSearchType
                    searchTypes={searchTypes}
                    setValue={setSearchTypes}
                    query={countSearchQuery}
                  />
                  <SearchList
                    total={documents?.total}
                    items={documents?.items}
                    openModal={() => setIsOpenFilter(true)}
                    page={page}
                    perPage={MIN_LIMIT}
                    setPage={setPage}
                  />
                </Left>
                <Right>
                  <QuestionFilter
                    {...QuestionFilterProps}
                    search
                    hideQuestion={!searchTypes.includes(DocumentType.Qa) && searchTypes.length > 0}
                    searchQuery={countSearchQuery}
                  />
                </Right>
              </>
            ) : (
              <SearchTags />
            )}
          </StyledPageWrapper>
        )}
        <CommonModals />
      </div>
    </NoHeaderLayout>
  );
};

const StyledPageWrapper = styled(PageWrapper)`
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;

  ${media.lessThan('medium')`
    padding-top: 0;
  `}
`;
const Left = styled.div`
  flex: 1;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const PcSearchType = styled(SearchType)`
  ${media.lessThan('medium')`
    display: none;
  `}
`;

const Right = styled.div`
  width: 284px;

  ${media.lessThan('medium')`
    display: none;
  `}
`;
