import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { StripeInvoiceFragment } from '../../gen/graphql';

interface Props {
  oldPriceInvoice?: StripeInvoiceFragment;
  nextPriceAmount: number;
}

export const AccountSubscriptionUpdatedInvoice: React.FC<Props> = ({
  oldPriceInvoice,
  nextPriceAmount,
}) => {
  return (
    <React.Fragment>
      {!!oldPriceInvoice && (
        <Container>
          <Title>請求額</Title>
          <Content>
            <Description>
              プラン変更時の請求額は下記の通りです。
              <br />
              <Bold>現プランの残存期間に関しては秒割り計算し返金いたします。</Bold>
            </Description>
            <InvoiceDetail>
              <dt>新プラン請求金額</dt>
              <dd>{nextPriceAmount.toLocaleString()}円(税込)</dd>
              <dt>現プランの残日数分差引額</dt>
              <dd>{Math.abs(oldPriceInvoice.total).toLocaleString()}円(税込)</dd>
              <dt>合計請求金額</dt>
              <dd>{(nextPriceAmount + oldPriceInvoice.total).toLocaleString()}円(税込)</dd>
            </InvoiceDetail>
            <Note>
              ※利用料金の按分は秒単位で計算されるため、プラン変更ボタンを押すタイミングによっては表示の請求金額と実際の請求金額が数円異なる可能性がございます。
              <br />
              ※差額の発生により返金が生じる場合があります。その際は次回更新時に精算となります。
            </Note>
          </Content>
        </Container>
      )}
    </React.Fragment>
  );
};

const Container = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 2rem auto 0;
`;

const Title = styled.h3`
  font-size: 1rem;
  padding: 0.75rem;
  background-color: #f5f5f5;
  font-weight: bold;
  line-height: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  font-size: 1rem;
  line-height: 1;
  margin-top: 1rem;
`;

const Note = styled.p`
  font-size: 0.75rem;
  line-height: 1;
  margin-top: 1rem;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const InvoiceDetail = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  font-weight: bold;

  dt {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    width: calc(35% - 1.5rem);

    ${media.lessThan('medium')`
      width: 100%;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.875rem;
      line-height: 1;
    `}
  }

  dd {
    margin-top: 0.5rem;
    width: calc(65%);

    ${media.lessThan('medium')`
      margin-top: 0.25rem;
      width: 100%;
    `}
  }
`;
