import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../../redux/user/useUser';

import { TabNavigationLayout } from '../../templates/TabNavigationLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { ProgramCompletedStudys } from './ProgramCompletedStudyList';
import { CourseProgramCompletedStudys } from './CourseProgramCompletedStudyList';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import { PROGRAM_COUNT } from '../../../const/Program';

export const ProgramCompletedStudy = (): JSX.Element => {
  const metaTitle = `完了した教材 | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のプログラミング学習教材ページです。ITリテラシーからフロントエンド、バックエンド、インフラまで全${PROGRAM_COUNT}種類以上の教材をご用意。入門者や初心者にも優しい基礎教材はもちろん実務レベルのエンジニアになるための中級者向け教材も。`;

  const { permissionCheck } = useUser();
  const location = useLocation();

  const limit = 100;
  const offset = 1;

  const tabs = useMemo(() => {
    const tabs = [];

    permissionCheck(FunctionType.ProgramForInstructorAndCoach, PermissionType.Read)
      ? tabs.push(
          { label: '学習用教材', to: '/programs?type=1', active: location.search === '?type=1' },
          {
            label: 'インストラクター向けマニュアル',
            to: '/programs?type=2',
            active: location.search === '?type=2',
          },
        )
      : tabs.push(
          { label: '教材TOP', to: '/programs', active: location.pathname === '/programs' },
          {
            label: '学習中の教材',
            to: '/programs_while_studying',
            active: location.pathname === '/programs_while_studying',
          },
          {
            label: '完了した教材',
            to: '/programs_completed_study',
            active: location.pathname === '/programs_completed_study',
          },
        );

    return tabs;
  }, [permissionCheck, location.search, location.pathname]);

  return (
    <TabNavigationLayout
      pageTitle="教材"
      tabs={tabs}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      <PageWrapper>
        {permissionCheck(FunctionType.StudyLog, PermissionType.Read) && (
          <>
            <CourseProgramCompletedStudys />
            <ProgramCompletedStudys limit={limit} offset={offset} />
          </>
        )}
      </PageWrapper>
    </TabNavigationLayout>
  );
};
