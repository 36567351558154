import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';

export const Footer: React.FC = () => {
  const { permissionCheck } = useUser();
  return (
    <FooterContainer>
      <Copyright>&copy; SAMURAI Inc.</Copyright>
      <Menu>
        <a href="https://www.sejuku.net/corp/terakoya-agreement" target="_blank">
          利用規約
        </a>
        {permissionCheck(FunctionType.DocumentForTeam, PermissionType.Read) && (
          <a href="https://www.sejuku.net/corp/agreement-terakoya-enterprise" target="_blank">
            法人会員利用規約
          </a>
        )}
        <a href="https://www.sejuku.net/corp/privacy-policy" target="_blank">
          プライバシーポリシー
        </a>
        <a href="https://www.sejuku.net/corp/" target="_blank">
          運営会社
        </a>
      </Menu>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1rem 0;
  line-height: 1rem;

  ${media.lessThan('small')`
    flex-direction: column-reverse;
    padding: 2rem 0;
  `}
`;
const Copyright = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.625rem;
`;
const Menu = styled.p`
  display: flex;
  align-items: center;
  gap: 0.625rem;

  a {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.625rem;
    font-weight: 700;
    transition: all 0.2s;

    &:hover {
      color: #eb0000;
    }
  }

  ${media.lessThan('small')`
    flex-direction: column;
    gap: .5rem;
  `}
`;
