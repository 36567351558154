import { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { H4 } from '../../atoms/Typography';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Loaders } from '../../../common/Loader';
import { WalkThrough } from '../../organisms/WalkThroughModal';
import { useUser } from '../../../redux/user/useUser';
import { GetSpotLessonTicketModal } from '../../organisms/GetSpotLessonTicketModal';
import { TopProgramCard } from '../../organisms/TopProgramCard';
import { TopNextLessonCard } from '../../organisms/TopNextLessonCard';
import { Onboarding } from '../../organisms/Onboarding';
import { GeneralHomeSummary } from '../../organisms/GeneralHomeSummary';
import { TopNextLessonEmptyCard } from '../../organisms/TopNextLessonEmptyCard';
import { TopProgramEmptyCard } from '../../organisms/TopProgramEmptyCard';
import { BasicLayout } from '../../templates/BasicLayout';
import {
  BannerCidType,
  useGetProgramsQuery,
  ProgramSearchType,
  useGetCourseProgramsQuery,
  useGetStudySummaryQuery,
  useGetLatestModalQuery,
  useReadModalMutation,
  ModalType,
} from '../../../gen/graphql';
import { useBannerCids } from '../../../common/customHooks/BannerCids';
import { CourseProgramArticle } from '../../organisms/CourseProgramArticle';
import {
  useGetTicketsQuery,
  useGetSpotLessonCountQuery,
  useGetNextSpotLessonQuery,
} from '../../../gen/graphql';
import { MIDDLE_LIMIT } from '../../../const/Page';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { LOWER_META_TITLE } from '../../../const/Service';
import { NickNameWarningModal } from '../../organisms/NickNameWarningModal';

interface GeneralTopState {
  registered: boolean;
}

export const GeneralTop = (): JSX.Element => {
  const metaTitle = `ホーム | ${LOWER_META_TITLE}`;

  const location = useLocation();
  const locationState = location.state as GeneralTopState;

  const { user } = useUser();
  const navigate = useNavigate();

  const [isNickNameWarningModalOpen, setIsNickNameWarningModalOpen] = useState(
    !user.lmsUser?.nickName,
  );
  const [walkThrough, setWalkThrough] = useState(true);

  const handleModalLink = (): void => {
    navigate('/account/profile?mode=editing');
  };

  const { data: completedStudyProgramsData, loading: completedStudyProgramsLoading } =
    useGetProgramsQuery({
      variables: {
        input: {
          programSearchType: ProgramSearchType.CompletedStudy,
        },
        userID: user.lmsUser?.id,
      },
    });
  const completedStudyProgramsCount = completedStudyProgramsData?.programs.items.length ?? 0;

  const { data: studySummaryData, loading: studySummaryLoading } = useGetStudySummaryQuery();
  const studyReportCount = studySummaryData?.getStudySummary.studyReportCount ?? 0;
  const studyReportCurrentRunningLayDays =
    studySummaryData?.getStudySummary?.studyReportCurrentRunningLayDays ?? 0;
  const studyReportRunningLayDays =
    studySummaryData?.getStudySummary?.studyReportRunningLayDays ?? 0;
  const { pcBanners } = useBannerCids([BannerCidType.TopCenter], MIDDLE_LIMIT);
  const centerPcBanners = pcBanners.filter((pcBanner) => pcBanner.type === BannerCidType.TopCenter);

  const { data: ticketData, loading: ticketLoading } = useGetTicketsQuery();
  const tickets = ticketData?.tickets ?? [];

  const { data: spotLessonCountData, loading: spotLessonCountLoading } =
    useGetSpotLessonCountQuery();
  const totalLessonCount = spotLessonCountData?.spotLessonCount;

  const { data: nextLessonData, loading: nextLessonLoading } = useGetNextSpotLessonQuery();
  const nextLesson = nextLessonData?.nextSpotLesson;

  const { data: modalData, refetch: modalRefetch } = useGetLatestModalQuery({
    variables: {
      modalType: ModalType.SpotLessonTicketModal,
    },
  });
  const modalID = modalData?.latest?.id;
  const isTicketModalOpen = modalData?.latest?.isRead === 0 && !locationState;

  const [readModalMutation] = useReadModalMutation();

  const updateModal = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!modalID) return;
      try {
        try {
          await readModalMutation({ variables: { id: modalID } });
        } catch (e) {
          return;
        }
        // 成功時のコールバック(refetch)
        await modalRefetch();
      } catch (e) {
        return;
      }
    }, [modalID, modalRefetch, readModalMutation]),
  );

  const clearWalkThrough = () => {
    setWalkThrough(false);
    modalRefetch();
    navigate('/home', { replace: true, state: undefined });
  };

  const { data: courseProgramsData, loading: courseProgramsLoading } = useGetCourseProgramsQuery({
    variables: {
      input: {
        registeredCoursePrograms: true,
      },
    },
  });

  const { data: programWhileStudying, loading: programWhileStudyingLoading } = useGetProgramsQuery({
    variables: {
      input: {
        programSearchType: ProgramSearchType.WhileStudying,
      },
      userID: user.lmsUser?.id,
    },
  });

  return (
    <>
      <NickNameWarningModal
        isOpen={isNickNameWarningModalOpen}
        onClose={(): void => setIsNickNameWarningModalOpen(false)}
        handleLink={handleModalLink}
      />
      <BasicLayout pageTitle="ホーム" hasIntercom metaTitle={metaTitle}>
        {locationState?.registered && (
          <WalkThrough isOpen={walkThrough} toggle={clearWalkThrough} />
        )}
        <PageWrapper>
          <Container>
            <Left>
              <GetSpotLessonTicketModal
                isOpen={isTicketModalOpen}
                onClose={() => {
                  if (isTicketModalOpen) {
                    updateModal();
                  }
                }}
              />
              {user.lmsUser && (
                <>
                  <WelcomeBackMessage>
                    おかえりなさい、{user.lmsUser.nickName}さん
                  </WelcomeBackMessage>
                  {completedStudyProgramsLoading ||
                  studySummaryLoading ||
                  spotLessonCountLoading ||
                  ticketLoading ? (
                    <Loaders width="20%" height="5.625rem" margin="0 0 0 0" number={5} />
                  ) : (
                    <GeneralHomeSummary
                      user={user.lmsUser}
                      studyReportCurrentRunningLayDays={studyReportCurrentRunningLayDays}
                      studyReportRunningLayDays={studyReportRunningLayDays}
                      studyReportCount={studyReportCount}
                      completedProgramCount={completedStudyProgramsCount}
                      totalLessonCount={totalLessonCount}
                      tickets={tickets}
                    />
                  )}
                </>
              )}

              <Onboarding />

              <CardContainer>
                {nextLessonLoading ? (
                  <Loaders width="100%" height="9.375rem" margin="0" number={1} />
                ) : nextLesson ? (
                  <TopNextLessonCard
                    startAt={new Date(nextLesson.startAt)}
                    endAt={new Date(nextLesson.endAt)}
                    title={nextLesson.categories?.map(({ name }) => name).join('、')}
                    user={nextLesson.instructorUser ?? undefined}
                    isSpot
                  />
                ) : (
                  <TopNextLessonEmptyCard isSpot />
                )}
                {programWhileStudyingLoading ? (
                  <Loaders width="100%" height="9.375rem" margin="0" number={1} />
                ) : programWhileStudying && programWhileStudying.programs.items.length > 0 ? (
                  <TopProgramCard
                    programId={programWhileStudying.programs.items[0].id}
                    title={programWhileStudying.programs.items[0].title}
                    thumbnail={programWhileStudying.programs.items[0].thumbnail}
                    studyLogCount={programWhileStudying.programs.items[0].studyLogCount}
                    chapterCount={programWhileStudying.programs.items[0].chapterCount ?? 0}
                  />
                ) : (
                  <TopProgramEmptyCard />
                )}
              </CardContainer>

              <CenterPcBanner>
                {centerPcBanners.map((centerPcBanner) => (
                  <a
                    href={centerPcBanner.banner.link + centerPcBanner.cid}
                    target="_blank"
                    key={centerPcBanner.id}
                  >
                    <img src={centerPcBanner.banner.imageURL} alt={centerPcBanner.banner.title} />
                  </a>
                ))}
              </CenterPcBanner>

              <Header>
                <H4 color="DARK">おすすめコース教材</H4>
              </Header>
              <CurriculumContainer>
                {!courseProgramsLoading ? (
                  courseProgramsData?.coursePrograms?.map((p) => {
                    return (
                      <StyledCourseProgramArticle
                        id={p.id}
                        key={p.id}
                        image={p.image ?? ''}
                        title={p.title ?? ''}
                        description={p.description ?? ''}
                        totalChapterCount={p.totalChapterCount}
                        totalRequireTime={p.totalRequireTime}
                        totalCompletedTime={p.totalCompletedTime}
                      />
                    );
                  })
                ) : (
                  <Loaders
                    width="calc(33.3% - 0.75rem)"
                    height="14.0625rem"
                    margin="0 0.75rem 1rem 0"
                    number={9}
                  />
                )}
              </CurriculumContainer>
            </Left>
          </Container>
        </PageWrapper>

        <style jsx>{`
          h2 {
            margin-bottom: 1.5rem;
          }
        `}</style>
      </BasicLayout>
    </>
  );
};

const CenterPcBanner = styled.section`
  position: relative;
  width: 100%;
  min-width: 284px;

  > a {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:first-of-type {
      margin-top: 2rem;
    }

    &:last-of-type {
      margin-bottom: 2rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  ${media.lessThan('small')`
    display: none;
  `}
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const Left = styled.section`
  flex: 1;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardContainer = styled.section`
  position: relative;
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
  width: 100%;
  margin: 1.5rem auto 2rem;

  & > * {
    flex: 1;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 1rem;

    & > * {
      width: 100%;
    } 
  `}
`;

const WelcomeBackMessage = styled.p`
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 1rem;
`;

const CurriculumContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 2rem 1.25rem;

  ${media.lessThan('medium')`
    gap: 1.5rem 1rem;
  `}
`;

const StyledCourseProgramArticle = styled(CourseProgramArticle)`
  width: calc(50% - 0.75rem);

  ${media.lessThan('medium')`
    width: calc(50% - .5rem);
  `}

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
