import React, { useCallback } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';
import { useApplyClaimForInstructorMutation } from '../../gen/graphql';
import { useToastsContext } from '../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { defaultErrorMessage } from '../../const/ErrorMessage';

interface ApplyModalProps {
  isOpen: boolean;
  toggle: (nextState: boolean) => void;
  loading?: boolean;
  refetch: (date: Date, claimCategoryId?: number, studentId?: number) => void;
  month: Date;
  claimCategoryId?: number;
  studentId?: number;
}

export const ApplyModal: React.FC<ApplyModalProps> = ({
  isOpen,
  toggle,
  refetch,
  month,
  claimCategoryId,
  studentId,
  ...props
}) => {
  const { showToast } = useToastsContext();

  /**
   * 申請処理
   */
  const [applyClaim] = useApplyClaimForInstructorMutation();
  const submitApplyClaim = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      try {
        await applyClaim({
          variables: {
            yearMonth: month.toISOString(),
            input: {},
          },
        });
        await refetch(month, claimCategoryId, studentId);
      } catch {
        showToast(1, defaultErrorMessage);
        return;
      }
      toggle(false);
      showToast(0, '請求申請を申請しました。');
    }, [toggle, showToast, applyClaim, refetch, month, claimCategoryId, studentId]),
  );

  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={() => toggle(false)}
      loading={props.loading}
      header={'月次請求申請'}
      footer={
        <Buttons>
          <Button onClick={() => toggle(false)} gray>
            キャンセル
          </Button>
          <Button onClick={submitApplyClaim}>請求申請の確定</Button>
        </Buttons>
      }
      width={'550px'}
    >
      <Container>
        <Text>
          こちらの申請は月末に行う申請です。
          <br />
          問題ない場合は請求申請の確定を選択ください。
        </Text>
      </Container>
    </Modal>
  );
};

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const Container = styled.div`
  padding: 2.75rem 2rem 6.5rem;
`;
const Text = styled.p`
  max-width: 432px;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 1.5;

  ${media.lessThan('medium')`
    font-size: .875rem;
  `}
`;
