import React from 'react';
import styled from 'styled-components';

import { Modal } from '../molecules/Modal';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  closeModal: () => void;
}

export const ProgramCategoryModal: React.FC<Props> = (props) => {
  return (
    <Modal
      underlayer
      isOpen={props.isOpen}
      onClose={props.closeModal}
      loading={props.loading}
      width={'572px'}
      header={<h2>カテゴリーで探す</h2>}
    >
      <Container>{props.children}</Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 1rem 0;
`;
