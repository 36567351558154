import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../atoms/Button';

import {
  ProgramDetailFragment as Program,
  ChapterFragment,
  ChapterSearchFragment,
  PracticeFragment,
  PracticeSearchFragment,
  PracticeSubmitDetailReviewStatus,
} from '../../gen/graphql';
import {
  getChapterFunctionType,
  getChaptersAndPractices,
  getPracticeFunctionType,
} from '../../utils/Program';
import { getRecentPracticeSubmitStatus } from '../../utils/PracticeSubmit';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { PermissionType } from '../../const/UserPermission';
import { useCallback } from 'react';

type Chapter = ChapterFragment | ChapterSearchFragment;
type Practice = PracticeFragment | PracticeSearchFragment;

interface ProgramElementPaginationProps {
  program: Program;
  currentChapterIndex: number;
  disabledNext: boolean;
}

export const ProgramElementPagination: React.FC<ProgramElementPaginationProps> = ({
  program,
  currentChapterIndex,
  disabledNext,
}): JSX.Element => {
  const { openModal } = useCommonModal();

  const navigate = useNavigate();

  const programElements = getChaptersAndPractices(program) ?? [];
  const prevElement = programElements[currentChapterIndex - 1] ?? null;
  const nextElement = programElements[currentChapterIndex + 1] ?? null;

  const goToElementPage = useCallback(
    (element: Chapter | Practice | null) => {
      if (!element) return;

      const functionType =
        element.__typename === 'Chapter'
          ? getChapterFunctionType(element.type)
          : element.__typename === 'Practice'
            ? getPracticeFunctionType(element.type)
            : undefined;
      if (!functionType) return;

      if (element.__typename === 'Practice') {
        const practice = element as PracticeFragment;
        if (
          // 合格した課題に遷移する場合はモーダルは表示しない
          getRecentPracticeSubmitStatus(practice.mySubmit?.practiceSubmitDetails) !==
            PracticeSubmitDetailReviewStatus.Passed &&
          openModal(functionType, PermissionType.Read)
        )
          return;
      } else {
        if (openModal(functionType, PermissionType.Read)) return;
      }

      const elementURL =
        element.__typename === 'Chapter'
          ? `/programs/${program.id}/chapters/${element.id}`
          : element.__typename === 'Practice'
            ? `/practices/${element.id}/body`
            : '';
      if (!elementURL) return;
      navigate(elementURL);
    },
    [navigate, openModal, program.id],
  );

  return (
    <Wrapper>
      <Paginate>
        <div className="prev">
          <StyledButton onClick={() => goToElementPage(prevElement)} disabled={!prevElement} border>
            前に戻る
          </StyledButton>
        </div>
        <div className="page">
          {currentChapterIndex + 1} / {programElements.length} ページ
        </div>
        <div className="next">
          <StyledButton
            onClick={() => goToElementPage(nextElement)}
            disabled={disabledNext || !nextElement}
            border
          >
            次に進む
          </StyledButton>
        </div>
      </Paginate>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 1.5rem 0;

  ${media.lessThan('small')`
    margin: 2rem 0;
  `}
`;
const Paginate = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 6.75rem 1fr;

  .prev {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .next {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  ${media.lessThan('small')`
    padding-left: 1rem;
    padding-right: 1rem;
  `}
`;
