import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Slider from 'react-slick';
// import ReactMarkdown from 'react-markdown';
import { Markdown } from '../../common/Markdown';

interface OverviewProps {
  summary: string;
  summaryImage1: string;
  summaryImage2: string;
  summaryImage3: string;
  summaryImage4: string;
  summaryImage5: string;
}

export const Overview: React.FC<OverviewProps> = (props) => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  props.summary;

  const imageArr = [];
  if (props.summaryImage1) imageArr.push(props.summaryImage1);
  if (props.summaryImage2) imageArr.push(props.summaryImage2);
  if (props.summaryImage3) imageArr.push(props.summaryImage3);
  if (props.summaryImage4) imageArr.push(props.summaryImage4);
  if (props.summaryImage5) imageArr.push(props.summaryImage5);

  return (
    <React.Fragment>
      {imageArr.length ? (
        <ImageWrapper>
          <h2>作成するサービスイメージ</h2>
          {imageArr.length === 1 ? (
            <img src={imageArr[0]} alt="" />
          ) : (
            <Slider {...settings}>
              {imageArr.map((image) => {
                return <img src={image} alt="" />;
              })}
            </Slider>
          )}
        </ImageWrapper>
      ) : (
        ''
      )}
      <MarkdownWrapper>
        <Markdown content={props.summary} />
      </MarkdownWrapper>
    </React.Fragment>
  );
};

const ImageWrapper = styled.section`
  width: 100%;
  margin-bottom: 1.75rem;

  h2 {
    margin: 0 auto 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.36;
  }

  img {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }

  .slick-slider {
    padding: 0 1.5rem 28px;

    .slick-dots {
      font-size: 0;
      bottom: 0;
      left: 0;

      li {
        width: 12px;
        height: 12px;
        margin: 0 6px;

        button {
          width: 12px;
          height: 12px;
          padding: 0;
          background: rgba(0, 0, 0, 0.1);
          border-radius: 50%;

          &:before {
            content: none;
          }
        }
        &.slick-active button {
          background: #e2001b;
        }
      }
    }

    .slick-next,
    .slick-prev {
      width: 48px;
      height: 48px;
      background: rgba(0, 0, 0, 0.6);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      z-index: 2;

      &:before {
        content: none;
      }

      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        margin: auto auto auto 16px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        transform: rotate(45deg);
      }

      &.slick-disabled {
        background: #e5e5e5;
        box-shadow: none;
        cursor: auto;

        &:after {
          border-color: rgba(0, 0, 0, 0.36);
        }
      }
    }

    .slick-prev {
      left: 0;

      &:after {
        margin-left: 20px;
        transform: rotate(-135deg);
      }
    }

    .slick-next {
      right: 0;
    }
  }

  ${media.lessThan('medium')`
    padding: 0 1rem;
    box-sizing: border-box;

    h2 {
      margin-bottom: 1rem;
      font-size: 1.25rem;
      line-height: 1.2;
    }

    .slick-slider {
      padding: 0 1rem 22px;

      .slick-dots {
        li {
          width: 10px;
          height: 10px;
          margin: 0 5px;

          button {
            width: 10px;
            height: 10px;
          }
        }
      }

      .slick-next,
      .slick-prev {
        width: 32px;
        height: 32px;

        &:after {
          width: 8px;
          height: 8px;
          margin-left: 10px;
        }
      }

      .slick-prev {
        &:after {
          margin-left: 13px;
        }
      }
    }
  `}
`;
const MarkdownWrapper = styled.div`
  width: 100%;

  ${media.lessThan('medium')`
    padding: 0 1rem;
    box-sizing: border-box;
  `}
`;
