import React from 'react';
import media from 'styled-media-query';
import styled, { keyframes } from 'styled-components';
import { minutesToHours } from 'date-fns';

import TimeIcon from '../../static/image/icon_time.svg';

import { useUser } from '../../redux/user/useUser';
import { progress } from '../../utils/Progress';

import { CourseProgramDetailFragment } from '../../gen/graphql';
import { CourseProgramOverviewModal } from '../organisms/CourseProgramOverviewModal';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { minutesToHoursSecondDecimalPlace } from '../../utils/common';

interface Props {
  courseProgram: CourseProgramDetailFragment;
}

export const CourseProgramHeader: React.FC<Props> = ({ courseProgram }) => {
  const { permissionCheck } = useUser();

  const [isOverviewModalOpen, setIsOverviewModalOpen] = React.useState(false);

  return (
    <Container>
      <CourseProgramOverviewModal
        isOpen={isOverviewModalOpen}
        toggle={(n: boolean) => setIsOverviewModalOpen(n)}
        courseProgram={courseProgram}
      />
      <Content>
        <Info>
          <h2>{courseProgram.title}</h2>
          <Description>{courseProgram.content}</Description>
          <SubInfo>
            <Nums>
              <Num>
                <Icon src={TimeIcon} alt="所要時間" />
                <p>
                  <span>{minutesToHours(courseProgram.totalRequireTime)}</span>時間
                </p>
              </Num>
            </Nums>
            <DetailLink onClick={() => setIsOverviewModalOpen(true)}>
              <span>コースの概要を確認する</span>
            </DetailLink>
          </SubInfo>
        </Info>
        <Image>
          <img src={courseProgram.image} alt={courseProgram.title} />
        </Image>
      </Content>
      {permissionCheck(FunctionType.StudyLog, PermissionType.Read) && (
        <Progress>
          <ProgressInfo>
            <p className="label">進捗率</p>

            <p className="percentage">
              {progress(courseProgram.totalCompletedTime, courseProgram.totalRequireTime)}
              <span>%</span>
            </p>
          </ProgressInfo>
          <ProgressBar
            start={courseProgram.totalCompletedChapterCount ?? 0}
            end={courseProgram.totalChapterCount ?? 0}
          >
            <div className="bar"></div>
            {
              <div className="balloon">
                {
                  <p>
                    {minutesToHoursSecondDecimalPlace(courseProgram.totalCompletedTime)}/
                    {minutesToHoursSecondDecimalPlace(courseProgram.totalRequireTime)}時間
                  </p>
                }
              </div>
            }
          </ProgressBar>
        </Progress>
      )}
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  width: 100%;
  margin-top: 16px;
  padding: 32px;
  background-color: #ffffff;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  ${media.lessThan('medium')`
    padding: 0;
    border-radius: 6px;
  `}
`;

const Content = styled.div`
  display: flex;

  ${media.lessThan('large')`
    margin-left: 0;
  `}

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
  `}
`;

const Info = styled.div`
  flex: 1;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.375;
    margin-bottom: 1rem;
  }

  ${media.lessThan('medium')`
    padding: 16px;

    h2 {
      font-size: 1.125rem;
      line-height: 1.5;
      margin-bottom: 1rem;
    }
  `}
`;

const Description = styled.p`
  font-size: 1rem;
  line-height: 1.8;

  ${media.lessThan('medium')`
    line-height: 1.5;
  `}
`;

const SubInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.25rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const DetailLink = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 256px;
  height: 44px;
  border: 1px solid #eb0000;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.2s;

  span {
    font-size: 0.875rem;
    font-weight: bold;
    color: #eb0000;
  }

  &:hover {
    background: #eb0000;

    span {
      color: #fff;
    }
  }

  ${media.lessThan('medium')`
    width: 100%;
    margin-top: 16px;
  `}
`;

const Nums = styled.div`
  display: flex;
  align-items: center;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
const Num = styled.div`
  display: flex;
  align-items: center;

  &:nth-of-type(n + 2) {
    margin-left: 0.625rem;
  }

  p {
    font-size: 0.875rem;

    span {
      font-size: 1.125rem;
      font-weight: 700;
    }
  }

  ${media.lessThan('medium')`
    &:nth-of-type(n + 2) {
      margin-left: 1rem;
    }

    p {
      font-size: 0.75rem;

      span {
        font-size: 1rem;
      }
    }
  `}
`;
const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 0.375rem;

  ${media.lessThan('medium')`
    width: 18px;
    height: 18px;
  `}
`;

const Image = styled.div`
  width: 400px;
  min-width: 400px;
  height: 180px;
  margin-left: 4rem;
  border-radius: 6px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.lessThan('medium')`
    width: 100%;
    min-width: 100%;
    height: 130px;
    margin-left: 0;
    border-radius: 4px;
  `}
`;

const barAnimation = (start: number, end: number) => {
  return keyframes`
    0% {
      width: 0;
    }

    100% {
      width: ${progress(start, end)}%;
    }
  `;
};

const Progress = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 16px 32px;

  ${media.lessThan('medium')`
    margin-top: .75rem;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  `}
`;

const ProgressBar = styled.div<{ start: number; end: number }>`
  position: relative;
  flex: 1;
  display: inline-block;
  height: 8px;
  border-radius: 1rem;
  background-color: #f0f0f0;
  margin-left: 21px;

  .bar {
    height: 100%;
    width: 0;
    background: linear-gradient(to right, #fe5e27, #e2001b);
    animation: ${(props) => barAnimation(props.start, props.end)} 0.5s ease-out 1 forwards;
  }

  .balloon {
    position: absolute;
    display: inline-block;
    top: -42px;
    left: ${(props) => progress(props.start, props.end)}%;
    transform: translateX(-50%);
    padding: 8px 12px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);

    p {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 500;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: -14px;
      left: 50%;
      margin-left: -7px;
      border: 7px solid transparent;
      border-top: 7px solid #fff;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 50%;
      margin-left: -8px;
      border: 8px solid transparent;
      border-top: 8px solid rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  }

  ${media.lessThan('medium')`
    min-height: 8px;
    width: 100%;
    margin-left: 0;
    margin-top: 12px;

    .bar {
      min-height: 8px;
    }
  `}
`;

const ProgressInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -8px;

  .label {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
  }

  .percentage {
    font-size: 2.25rem;
    font-weight: bold;
    color: #ee4646;
    margin-top: 4px;

    span {
      font-size: 1rem;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  ${media.lessThan('medium')`
    margin-bottom: 0;
  `}
`;
