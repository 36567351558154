const KEYS = {
  UTM_SOURCE: 'utm_source',
  REGISTERED_TYPE: 'registeredType', // 登録の種類（new: 新規登録、recharge: 再課金など）
  QUESTIONNAIRE: 'questionnaire', // 登録時アンケートを表示するかを示すフラグ（true: 表示、false: 非表示）
  REGISTER_POPUP: 'registerPopup',
  SOCIAL_LOGIN_CALLBACK_URL: 'socialLoginCallbackURL',
  IS_RE_CONTRACT: 'isReContract', // 再課金かどうかを示すフラグ（true: 再課金、false: 再課金以外）
} as const;

export const sessionStorageSupport = {
  getItem: (key: keyof typeof KEYS): string | null => {
    try {
      return sessionStorage.getItem(KEYS[key]);
    } catch {
      // sessionStorageをサポートしてない環境で発生するerrorは握りつぶす(今はほとんどいないと思われる)
      return null;
    }
  },
  setItem: (key: keyof typeof KEYS, value: string): void => {
    try {
      sessionStorage.setItem(KEYS[key], value);
    } catch {
      // sessionStorageをサポートしてない環境で発生するerrorは握りつぶす(今はほとんどいないと思われる)
    }
  },
  removeItem: (key: keyof typeof KEYS): void => {
    try {
      sessionStorage.removeItem(KEYS[key]);
    } catch {
      // sessionStorageをサポートしてない環境で発生するerrorは握りつぶす(今はほとんどいないと思われる)
    }
  },
};
