const LIGHT_TAX_EXCLUDED_PRICE = 2709;
const LIGHT_TAX = 271;
const LIGHT_TAX_INCLUDED_PRICE = LIGHT_TAX_EXCLUDED_PRICE + LIGHT_TAX;
const LIGHT_PRICE_ID = 1;

const LIGHT_TAX_EXCLUDED_LOCALED_PRICE = LIGHT_TAX_EXCLUDED_PRICE.toLocaleString();
const LIGHT_LOCALED_TAX = LIGHT_TAX.toLocaleString();
const LIGHT_TAX_INCLUDED_LOCALED_PRICE = LIGHT_TAX_INCLUDED_PRICE.toLocaleString();

const BASIC_TAX_EXCLUDED_PRICE = 3800;
const BASIC_TAX = 380;
const BASIC_TAX_INCLUDED_PRICE = BASIC_TAX_EXCLUDED_PRICE + BASIC_TAX;
const BASIC_PRICE_ID = 13;

const BASIC_TAX_INCLUDED_LOCALED_PRICE = BASIC_TAX_INCLUDED_PRICE.toLocaleString();

const PREMIUM_PRICE_ID = 14;

const SPOT_LESSON_TAX_EXCLUDED_PRICE = 4980;
const SPOT_LESSON_TAX = 498;
const SPOT_LESSON_TAX_INCLUDED_PRICE = SPOT_LESSON_TAX_EXCLUDED_PRICE + SPOT_LESSON_TAX;

const SPOT_LESSON_TAX_EXCLUDED_LOCALED_PRICE = SPOT_LESSON_TAX_EXCLUDED_PRICE.toLocaleString();
const SPOT_LESSON_LOCALED_TAX = SPOT_LESSON_TAX.toLocaleString();
const SPOT_LESSON_TAX_INCLUDED_LOCALED_PRICE = SPOT_LESSON_TAX_INCLUDED_PRICE.toLocaleString();

const PREMIUM_12_TAX_EXCLUDED_PRICE = 19800;
const PREMIUM_12_TAX = 1980;
const PREMIUM_12_TAX_INCLUDED_PRICE = PREMIUM_12_TAX_EXCLUDED_PRICE + PREMIUM_12_TAX;

const PREMIUM_12_TAX_EXCLUDED_LOCALED_PRICE = PREMIUM_12_TAX_EXCLUDED_PRICE.toLocaleString();
const PREMIUM_12_LOCALED_TAX = PREMIUM_12_TAX.toLocaleString();
const PREMIUM_12_TAX_INCLUDED_LOCALED_PRICE = PREMIUM_12_TAX_INCLUDED_PRICE.toLocaleString();

const PREMIUM_6_TAX_EXCLUDED_PRICE = 24800;
const PREMIUM_6_TAX = 2480;
const PREMIUM_6_TAX_INCLUDED_PRICE = PREMIUM_6_TAX_EXCLUDED_PRICE + PREMIUM_6_TAX;

const PREMIUM_6_TAX_EXCLUDED_LOCALED_PRICE = PREMIUM_6_TAX_EXCLUDED_PRICE.toLocaleString();
const PREMIUM_6_LOCALED_TAX = PREMIUM_6_TAX.toLocaleString();
const PREMIUM_6_TAX_INCLUDED_LOCALED_PRICE = PREMIUM_6_TAX_INCLUDED_PRICE.toLocaleString();

const PREMIUM_3_TAX_EXCLUDED_PRICE = 29800;
const PREMIUM_3_TAX = 2980;
const PREMIUM_3_TAX_INCLUDED_PRICE = PREMIUM_3_TAX_EXCLUDED_PRICE + PREMIUM_3_TAX;

const PREMIUM_3_TAX_EXCLUDED_LOCALED_PRICE = PREMIUM_3_TAX_EXCLUDED_PRICE.toLocaleString();
const PREMIUM_3_LOCALED_TAX = PREMIUM_3_TAX.toLocaleString();
const PREMIUM_3_TAX_INCLUDED_LOCALED_PRICE = PREMIUM_3_TAX_INCLUDED_PRICE.toLocaleString();

const PREMIUM_EXIT_FEE = 11000;
const PREMIUM_LOCALED_EXIT_FEE = PREMIUM_EXIT_FEE.toLocaleString();

const TEAM_TAX_EXCLUDED_PRICE = 3619;
const TEAM_TAX = 361;
const TEAM_TAX_INCLUDED_PRICE = TEAM_TAX_EXCLUDED_PRICE + TEAM_TAX;

const TEAM_TAX_EXCLUDED_LOCALED_PRICE = TEAM_TAX_EXCLUDED_PRICE.toLocaleString();
const TEAM_LOCALED_TAX = TEAM_TAX.toLocaleString();
const TEAM_TAX_INCLUDED_LOCALED_PRICE = TEAM_TAX_INCLUDED_PRICE.toLocaleString();

export type PlanType = 'PREMIUM' | 'BASIC' | 'LIGHT';
type SubscriptionPlansClass = {
  [key in PlanType]: {
    id: number;
    period: number;
    taxIncludedPrice: number;
    recommend: string;
  }[];
};

const SUBSCRIPTION_PLANS: SubscriptionPlansClass = {
  PREMIUM: [
    {
      id: 69,
      period: 12,
      taxIncludedPrice: 6280,
      recommend: '年間44,400円お得！',
    },
    {
      id: 68,
      period: 6,
      taxIncludedPrice: 7680,
      recommend: '初学者におすすめ！',
    },
    {
      id: 67,
      period: 1,
      taxIncludedPrice: 9980,
      recommend: '',
    },
  ],
  BASIC: [
    {
      id: 66,
      period: 12,
      taxIncludedPrice: 4180,
      recommend: '年間27,600円お得！',
    },
    {
      id: 65,
      period: 6,
      taxIncludedPrice: 4980,
      recommend: '初学者におすすめ！',
    },
    {
      id: 64,
      period: 1,
      taxIncludedPrice: 6480,
      recommend: '',
    },
  ],
  LIGHT: [
    {
      id: 63,
      period: 12,
      taxIncludedPrice: 2980,
      recommend: '年間18,000円お得！',
    },
    {
      id: 62,
      period: 6,
      taxIncludedPrice: 3480,
      recommend: '初学者におすすめ！',
    },
    {
      id: 61,
      period: 1,
      taxIncludedPrice: 4480,
      recommend: '',
    },
  ],
};

const SUBSCRIPTION_PLANS_TEST: SubscriptionPlansClass = {
  PREMIUM: [
    {
      id: 69,
      period: 12,
      taxIncludedPrice: 6280,
      recommend: '',
    },
    {
      id: 68,
      period: 6,
      taxIncludedPrice: 7680,
      recommend: '初学者におすすめ！',
    },
  ],
  BASIC: [
    {
      id: 66,
      period: 12,
      taxIncludedPrice: 4180,
      recommend: '',
    },
    {
      id: 65,
      period: 6,
      taxIncludedPrice: 4980,
      recommend: '初学者におすすめ！',
    },
  ],
  LIGHT: [
    {
      id: 63,
      period: 12,
      taxIncludedPrice: 2980,
      recommend: '',
    },
    {
      id: 62,
      period: 6,
      taxIncludedPrice: 3480,
      recommend: '初学者におすすめ！',
    },
  ],
};

export {
  LIGHT_TAX_EXCLUDED_PRICE,
  LIGHT_TAX_EXCLUDED_LOCALED_PRICE,
  LIGHT_LOCALED_TAX,
  LIGHT_TAX_INCLUDED_PRICE,
  LIGHT_TAX_INCLUDED_LOCALED_PRICE,
  LIGHT_PRICE_ID,
  BASIC_PRICE_ID,
  BASIC_TAX_INCLUDED_LOCALED_PRICE,
  PREMIUM_PRICE_ID,
  SPOT_LESSON_TAX_EXCLUDED_PRICE,
  SPOT_LESSON_TAX_EXCLUDED_LOCALED_PRICE,
  SPOT_LESSON_LOCALED_TAX,
  SPOT_LESSON_TAX_INCLUDED_LOCALED_PRICE,
  PREMIUM_12_TAX_EXCLUDED_PRICE,
  PREMIUM_12_TAX_EXCLUDED_LOCALED_PRICE,
  PREMIUM_12_LOCALED_TAX,
  PREMIUM_12_TAX_INCLUDED_LOCALED_PRICE,
  PREMIUM_6_TAX_EXCLUDED_PRICE,
  PREMIUM_6_TAX_EXCLUDED_LOCALED_PRICE,
  PREMIUM_6_LOCALED_TAX,
  PREMIUM_6_TAX_INCLUDED_LOCALED_PRICE,
  PREMIUM_3_TAX_EXCLUDED_PRICE,
  PREMIUM_3_TAX_EXCLUDED_LOCALED_PRICE,
  PREMIUM_3_LOCALED_TAX,
  PREMIUM_3_TAX_INCLUDED_LOCALED_PRICE,
  PREMIUM_LOCALED_EXIT_FEE,
  TEAM_TAX_EXCLUDED_PRICE,
  TEAM_TAX_EXCLUDED_LOCALED_PRICE,
  TEAM_LOCALED_TAX,
  TEAM_TAX_INCLUDED_LOCALED_PRICE,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_PLANS_TEST,
};
