import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { CurrentUserFragment as User } from '../../gen/graphql';
import { useNavigate } from 'react-router-dom';
import UserType from '../../const/UserType';
import { CircularIcon } from '../atoms/CircularIcon';
import CalendarIcon from '../../static/image/icon_calendar.svg';
import { InformationPopupIcon } from './InformationPopupIcon';

interface VerticalUserInfoProps {
  user?: User;
  currentRunningLayDays: number;
  runningLayDays: number;
}

export const VerticalUserInfo: React.FC<VerticalUserInfoProps> = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (props.user?.id) {
      navigate(`/users/${props.user.id}/profile`);
    }
  };
  return (
    <Container>
      <ParentWrapper>
        <UserInfo>
          <StyledCircularIcon
            size={40}
            src={props.user?.image}
            onClick={handleClick}
            userType={
              props.user?.isInstructor
                ? UserType.Instructor
                : props.user?.isStudent
                  ? UserType.Student
                  : UserType.SnsUser
            }
          />
          <Name>{props.user?.personalInfo?.name || 'ゲスト'}</Name>
        </UserInfo>
        <CurrentRunningLayDays>
          {isUpdating(props.currentRunningLayDays, props.runningLayDays) && (
            <Label>記録更新中</Label>
          )}
          <Value>
            <img src={CalendarIcon} alt={'連続学習日数'} />
            <span className="value">
              {props.currentRunningLayDays}
              <span className="total">日</span>
            </span>
          </Value>
          <Row>
            <Name>連続学習日数</Name>
            <InformationPopupIcon description="学習ログを投稿するとカウントされます。1日お休みすると連続記録がリセットされます。" />
          </Row>
        </CurrentRunningLayDays>
      </ParentWrapper>
    </Container>
  );
};

export const isUpdating = (currentRunningLayDays: number, runningLayDays: number): boolean => {
  // 最高連続学習日数と連続学習日数が等しい時も「記録更新中」とする。
  // 実装がより複雑になってしまうのと、上記条件だとしても仕様として大差がないのでそのようにした。
  // https://dev.azure.com/sejuku/SYS-TERAKOYA/_backlogs/backlog/SAMURAI%20ALL/Issues?workitem=13966

  if (currentRunningLayDays === 0) {
    return false;
  }

  return currentRunningLayDays >= runningLayDays;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
`;

const ParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${media.greaterThan('medium')`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Name = styled.p`
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  margin-top: 4px;
`;

const StyledCircularIcon = styled(CircularIcon)`
  width: 40px;
  height: 40px;
  img {
    cursor: default;
  }

  .crown_icon {
    width: 16px !important;
    height: 16px !important;
    right: 0;
    bottom: 0;
  }

  ${media.lessThan('medium')`
    width: 32px;
    height: 32px;
  `}
`;

const CurrentRunningLayDays = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Value = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 20px;
    width: auto;

    ${media.lessThan('medium')`
      height: 14px;
    `}
  }

  .value {
    display: inline-block;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 2px;
    font-weight: 500;

    ${media.lessThan('medium')`
      font-size: 1.1rem;
    `}

    .total {
      font-size: 0.75rem;
      ${media.lessThan('medium')`
        font-size: 0.55rem;
      `}
    }
  }
`;

const Label = styled.span`
  display: inline-block;
  padding: 0 0.5rem;
  border-radius: 1px;
  cursor: default;
  color: #fff;
  font-size: 0.625rem;
  line-height: 0.875rem;
  background: #fd3c2f;
  ${media.lessThan('medium')`
    margin-top: 0.25rem;
  `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;
