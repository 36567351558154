import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  handleLink?: () => void;
}

export const NickNameWarningModal: React.FC<Props> = (props) => {
  return (
    <Modal
      underlayer
      isOpen={props.isOpen}
      onClose={props.onClose}
      loading={props.loading}
      header={<Title>ニックネームが未入力です</Title>}
      width="460px"
    >
      <Inner>
        ニックネームの設定は必須です。ニックネームが未入力の場合、お客様のユーザーネームは表示されません。
        <br />
        <br />
        ニックネームを設定し、サービス内の表示名を決定しましょう。
      </Inner>
      <StyledButton onClick={props.handleLink}>設定に移動</StyledButton>
    </Modal>
  );
};

const Title = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
`;
const Inner = styled.div`
  max-width: 976px;
  padding: 2rem 2rem;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    padding: 2rem 2rem;
  `}
`;
const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 160px;
  margin: 1rem 1rem 1rem auto;
  background: #fff;
  border: 1px solid #eb0000;
  color: #eb0000;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    background: #eb0000;
    color: #fff;
  }
`;
