import React, { useEffect } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { CircularIcon } from '../atoms/CircularIcon';
import { RoleLabel } from '../atoms/RoleLabel';

import defaultIcon from '../../static/image/icon_default.svg';
import { UserFragment } from '../../gen/graphql';
import { User as TsUser } from '../../swagger/gen/ts_front';
import { getUserType } from '../../utils/UserType';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import UserType from '../../const/UserType';
import { InformationPopupIcon } from '../molecules/InformationPopupIcon';

interface Props {
  user?: UserFragment | TsUser;
  date?: string;
  note?: string;
  huge?: boolean;
  large?: boolean;
  medium?: boolean;
  small?: boolean;
  draft?: boolean;
  name?: boolean; // trueの場合user直下のnameを表示
  className?: string;
  spSize?: 'small';
}

export const UserIcon: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [iconSize, setSize] = React.useState(0);
  const [spIconSize, setSpIconSize] = React.useState(0);
  const { permissionCheck } = useUser();
  const userType = getUserType(
    props.user,
    permissionCheck(FunctionType.UserTypeLabel, PermissionType.Read),
  );

  const userName = React.useMemo(() => {
    if (!props.user) {
      return '退会済みユーザー';
    }

    if (props.name) {
      // TODO AB#11729 swagger除去してgraphqlのUserに型を統一したらinでの分岐を削除
      if ('maskedPersonalInfo' in props.user && props.user.maskedPersonalInfo) {
        return props.user.maskedPersonalInfo.name;
      } else if ('name' in props.user && props.user.name) {
        return props.user.name;
      }
    }

    return props.user?.nickName;
  }, [props.user, props.name]);

  const handleClick = () => {
    if (userType === UserType.AI) return;

    if (props.user?.id) {
      navigate(`/users/${props.user.id}/profile`);
    }
  };

  useEffect(() => {
    switch (true) {
      case props.large:
        setSize(40);
        break;
      case props.medium:
        setSize(32);
        break;
      case props.small:
        setSize(24);
        break;
      case props.huge:
      default:
        setSize(48);
    }
  }, [props.large, props.medium, props.small, props.huge]);

  useEffect(() => {
    switch (props.spSize) {
      case 'small':
        setSpIconSize(32);
        break;
      default:
        setSpIconSize(iconSize);
    }
  }, [props.spSize, iconSize]);

  return (
    <Container className={props.className}>
      {!props.draft && (
        <CircularIcon
          src={props.user?.image ?? defaultIcon}
          size={iconSize}
          mediumSize={spIconSize}
          userType={userType}
          onClick={handleClick}
        />
      )}
      <Information>
        {!props.draft && (
          <NameArea>
            <Name
              onClick={handleClick}
              small={props.small || props.medium}
              spSize={props.spSize}
              isAI={userType === UserType.AI}
            >
              {userName}
            </Name>
            <RoleLabel user={props.user} />
            {userType === UserType.AI && (
              <InformationPopupIcon description="「AI先生」はOpenAIのGPT技術を使用しています。内容の正確性や適切性を100%保証するものではありません。" />
            )}
          </NameArea>
        )}
        {(props.date || props.note) && (
          <Others>
            {props.date && (
              <PostDate small={props.small || props.medium}>
                {format(new Date(props.date), 'yyyy年MM月dd日 HH:mm')}
              </PostDate>
            )}
            {props.note && <Note small={props.small || props.medium}>{props.note}</Note>}
          </Others>
        )}
      </Information>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const Information = styled.div`
  margin-left: 0.5rem;

  ${media.lessThan('medium')`
    margin-left: 0;
  `}
`;
const NameArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
const Name = styled.p<{ small?: boolean; isAI?: boolean; spSize?: string }>`
  font-weight: 500;

  ${(props) =>
    !props.isAI &&
    `
    cursor: pointer;
  `}

  ${(props) =>
    props.small
      ? `
      color: rgba(0,0,0,.6);
      font-size: .875rem;
    `
      : `
      color: rgba(0,0,0,.87);
      font-size: 1rem;
    `}

  ${(props) =>
    props.small
      ? media.lessThan('medium')`
      font-weight: 400;
      font-size: .75rem;
      line-height: .875rem;
    `
      : media.lessThan('medium')`
      font-weight: 600;
    `}

  ${(props) =>
    props.spSize === 'small' &&
    media.lessThan('medium')`
    font-size: .75rem;
  `}
`;
const Others = styled.div`
  display: flex;
  margin-top: 0.25rem;
`;
const PostDate = styled.p<{ small?: boolean }>`
  font-weight: 400;

  ${(props) =>
    props.small
      ? `
      margin-top: .0625rem;
      color: rgba(0,0,0,.36);
      font-size: .625rem;
      letter-spacing: .1px;
    `
      : `
      color: rgba(0,0,0,.6);
      font-size: .875rem;
    `}
`;
const Note = styled.p<{ small?: boolean }>`
  margin-left: 0.5rem;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;

  ${(props) =>
    props.small
      ? `
      font-size: .625rem;
    `
      : `
      font-size: .875rem;
    `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
