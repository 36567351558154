import styled from 'styled-components';
import media from 'styled-media-query';
import { useUser } from '../../redux/user/useUser';

import { ChapterCard } from './ChapterCard';
import { PracticeCard } from './PracticeCard';

import { ChapterFragment as Chapter, PracticeFragment as Practice } from '../../gen/graphql';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { Box } from '../atoms/Box';

interface ProgramListProps {
  programID: number;
  programElements: (Chapter | Practice)[];
}

export const ProgramList: React.FC<ProgramListProps> = (props) => {
  const { permissionCheck } = useUser();

  return (
    <>
      <Container>
        <ContentTitle>章一覧</ContentTitle>
        <ChapterWrapper>
          <ChaptersHeader>
            <p className="title">タイトル</p>
            {permissionCheck(FunctionType.Exam, PermissionType.Read) && (
              <>
                <p className="exam">確認テスト</p>
                <p className="status">ステータス</p>
              </>
            )}
          </ChaptersHeader>
          <ul>
            {props.programElements.map((programElement) => {
              if (programElement.__typename === 'Chapter') {
                return (
                  <ChapterCard
                    key={programElement.id}
                    chapter={programElement}
                    programID={props.programID}
                  />
                );
              }
              if (programElement.__typename === 'Practice') {
                return <PracticeCard key={programElement.id} practice={programElement} />;
              }
            })}
          </ul>
        </ChapterWrapper>
      </Container>
    </>
  );
};

const Container = styled.div`
  flex: 1;
`;
const ContentTitle = styled.h3`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 1.5rem;

  ${media.lessThan('medium')`
    margin-bottom: 1rem;
    padding: 0 1rem;
  `}
`;
const ChapterWrapper = styled(Box)`
  ${media.lessThan('medium')`
    border-top: none;
  `}
`;
const ChaptersHeader = styled.div`
  display: flex;
  padding: 1rem 0.75rem 1rem 3rem;
  border-bottom: none;

  p {
    font-size: 0.75rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
  }

  .exam,
  .status {
    width: 76px;
  }

  .status {
    margin-left: 8px;
  }

  .title {
    flex-grow: 1;
    text-align: left;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;
