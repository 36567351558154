import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Markdown } from '../../common/Markdown';
import {
  PracticeSubmitDetailReviewCommentFragment,
  PracticeSubmitDetailReviewStatus,
} from '../../gen/graphql';
import { generateReviewComment } from '../../utils/PracticeSubmit';

import { Control } from '../molecules/Control';
import { PracticeSubmitReviewEdit } from './PracticeSubmitReviewEdit';
import { UserIcon } from './UserIcon';

interface Props {
  practiceSubmitID: string;
  practiceSubmitDetailID: string;
  practiceSubmitDetailReviewStatus: PracticeSubmitDetailReviewStatus;
  practiceSubmitDetailReviewComment: PracticeSubmitDetailReviewCommentFragment;
  isDisplayReviewerEditButton: (reviewerUserID: number) => boolean;
  fetchPracticeSubmitForInstructor: () => Promise<void>;
}

export const PracticeSubmitReview: React.FC<Props> = (props) => {
  const [editReviewIsOpen, setEditReviewIsOpen] = useState(false);

  const reviewComment = generateReviewComment(
    props.practiceSubmitDetailReviewComment.content,
    props.practiceSubmitDetailReviewStatus,
  );

  const setReviewerControlMenu = () => [
    {
      text: '編集',
      onClick: () => {
        setEditReviewIsOpen(true);
      },
    },
  ];

  return (
    <Container>
      <ContentArea>
        <StyledUserIcon
          user={props.practiceSubmitDetailReviewComment.user}
          date={
            props.practiceSubmitDetailReviewComment.updatedAt ??
            props.practiceSubmitDetailReviewComment.createdAt
          }
          note={
            props.practiceSubmitDetailReviewComment.createdAt ===
            props.practiceSubmitDetailReviewComment.updatedAt
              ? ''
              : '(編集済み)'
          }
          medium
        />
        <CommentArea>
          {editReviewIsOpen ? (
            <PracticeSubmitReviewEdit
              isOpen={editReviewIsOpen}
              toggle={setEditReviewIsOpen}
              practiceSubmitID={props.practiceSubmitID}
              practiceSubmitDetailID={props.practiceSubmitDetailID}
              practiceSubmitDetailReviewComment={props.practiceSubmitDetailReviewComment}
              isDisplayReviewerEditButton={props.isDisplayReviewerEditButton}
              fetchPracticeSubmitForInstructor={props.fetchPracticeSubmitForInstructor}
            />
          ) : (
            <Markdown content={reviewComment} />
          )}
        </CommentArea>
      </ContentArea>
      {!editReviewIsOpen &&
        props.isDisplayReviewerEditButton(props.practiceSubmitDetailReviewComment.user.id) && (
          <ActionArea>
            <StyledControl menu={setReviewerControlMenu()} />
          </ActionArea>
        )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 0.75rem;
  padding: 1rem 2rem 1rem 2rem;

  ${media.lessThan('medium')`
    padding: 1rem;
  `}
`;

const ContentArea = styled.div`
  flex: 1;
  max-width: 100%;
`;

const StyledUserIcon = styled(UserIcon)`
  margin-top: 0.5rem;
`;

const CommentArea = styled.div`
  margin-top: 0.75rem;
  margin-left: 2.5rem;

  ${media.lessThan('medium')`
    margin-left: 0;
  `}
`;

const ActionArea = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;

  .pointer {
    cursor: pointer;
  }
`;

const StyledControl = styled(Control)`
  position: absolute;
  top: 0;
  right: 0;
`;
