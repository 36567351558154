import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface ButtonProps {
  border?: boolean;
  className?: string;
  disabled?: boolean;
  gray?: boolean;
  red?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  e2e?: string;
}

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <>
      {props.onClick ? (
        <StyledButton
          className={props.className}
          onClick={props.onClick}
          border={props.border}
          gray={props.gray}
          red={props.red}
          disabled={props.disabled}
          type={props.type}
          loading={props.loading}
          data-e2e={props.e2e}
        >
          {props.children}
        </StyledButton>
      ) : (
        <StyledButton
          className={props.className}
          border={props.border}
          gray={props.gray}
          red={props.red}
          disabled={props.disabled}
          loading={props.loading}
          data-e2e={props.e2e}
        >
          {props.children}
        </StyledButton>
      )}
    </>
  );
};

const StyledButton = styled.button<{
  border?: boolean;
  gray?: boolean;
  red?: boolean;
  disabled?: boolean;
  loading?: boolean;
}>`
  appearance: none;
  padding: 0.5rem 2rem;
  background: #eb0000;
  border: 1px solid #eb0000;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;

  ${(props) =>
    props.border &&
    `
    background: #fff;
    color: #eb0000;
    transition: all .2s;

    &:hover {
      background: #eb0000;
      color: #fff;
    }
  `}

  ${(props) =>
    props.gray &&
    `
    background: #fafafa;
    border-color: #fafafa;
    color: rgba(0,0,0,.87);
    font-weight: 400;
    transition: all .2s;

    &:hover {
      background: #efefef;
    }
  `}

  ${(props) =>
    props.red &&
    `
    background: #ffffff;
    border-color: #E2001B;
    color: #E2001B;
    font-weight: 400;
    transition: all .2s;

    &:hover {
      background: #E2001B;
      color: #ffffff;
    }
  `}

  ${(props) =>
    props.disabled &&
    `
    background: #e5e5e5;
    border-color: #e5e5e5;
    cursor: default;
    pointer-events: none;
    color: rgba(0,0,0,.87);
    font-weight: 400;
  `}

  ${(props) =>
    props.loading &&
    `
    position: relative;

    &:before {
      content: '';
      display: block;
      width: .875rem;
      height: .875rem;
      margin: auto;
      border: 2px solid #fff;
      border-right-color: transparent;
      border-radius: 1rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 1rem;
      animation: rotate .5s linear infinite;
    }
  `}

  ${media.lessThan('small')`
    padding: .5rem 1rem;
  `}

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
