import { ANSWERED, NO_ANSWER, SOLVED, UNSOLVED } from '../const/Question';
import { FunctionType, PermissionType } from '../const/UserPermission';
import { QuestionSearchType } from '../gen/graphql';

export const getType = (
  type: string,
  permissionCheck: (functionType: string, permissionType: string) => boolean,
): QuestionSearchType => {
  switch (type) {
    case 'my':
      return permissionCheck(FunctionType.QuestionForInstructorAndCoach, PermissionType.Read)
        ? QuestionSearchType.MyStudents
        : QuestionSearchType.Mine;
    case 'answered':
      return QuestionSearchType.MyAnswered;
    case 'draft':
      return QuestionSearchType.MyDraft;
    default:
      return QuestionSearchType.All;
  }
};

export const getAnswered = (value: number): boolean | undefined => {
  if (value === ANSWERED) {
    return true;
  }
  if (value === NO_ANSWER) {
    return false;
  }

  return undefined;
};

export const getSolved = (value: number): boolean | undefined => {
  if (value === SOLVED) {
    return true;
  }
  if (value === UNSOLVED) {
    return false;
  }

  return undefined;
};
