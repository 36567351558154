import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { TeamMemberListFragment, TeamMemberRole } from '../../gen/graphql';
import { CurrentUserFragment as User } from '../../gen/graphql';
import DefaultIcon from '../../static/image/icon_default.svg';

import { Button } from '../atoms/Button';
import { CircularIcon } from '../atoms/CircularIcon';

interface Props {
  user: User;
  teamMember: TeamMemberListFragment;
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  changeRole: (id: string, role: TeamMemberRole) => void;
  openExcludeModal: (id: string) => void;
}

export const TeamMemberAuthColumn: React.FC<Props> = ({
  user,
  teamMember,
  permissionCheck,
  changeRole,
  openExcludeModal,
}) => {
  const isOwn = user?.id === teamMember.user.id;
  const isAdmin = teamMember.role === TeamMemberRole.Admin;

  const changeAdmin = () => {
    if (isOwn || isAdmin) return;
    changeRole(teamMember.id, TeamMemberRole.Admin);
  };

  const changeGeneral = () => {
    if (isOwn || !isAdmin) return;
    changeRole(teamMember.id, TeamMemberRole.General);
  };

  const openRemoveModal = () => {
    openExcludeModal(teamMember.id);
  };

  return (
    <Container>
      <ThumbColumn>
        <CircularIcon src={teamMember.user.image ?? DefaultIcon} size={40} mediumSize={32} />
      </ThumbColumn>
      <NameColumn>{teamMember.user.nickName}</NameColumn>
      <InputColumn>
        <Radio
          $disabled={isOwn && permissionCheck(FunctionType.TeamInvitation, PermissionType.Update)}
          $isAdmin={isAdmin}
          onClick={changeAdmin}
        />
      </InputColumn>
      <InputColumn>
        <GeneralRadio
          $disabled={isOwn && permissionCheck(FunctionType.TeamInvitation, PermissionType.Update)}
          $isAdmin={isAdmin}
          onClick={changeGeneral}
        />
      </InputColumn>
      <Column />
      <AuthColumn>
        <RemoveButton
          disabled={isOwn && permissionCheck(FunctionType.TeamRemove, PermissionType.Delete)}
          onClick={openRemoveModal}
        >
          チームから外す
        </RemoveButton>
      </AuthColumn>
    </Container>
  );
};

export const TeamMemberAuthHeader: React.FC = () => {
  return (
    <HeaderContainer>
      <ThumbColumn />
      <NameColumn>氏名</NameColumn>
      <InputColumn>管理者</InputColumn>
      <InputColumn>一般</InputColumn>
      <Column />
      <AuthColumn />
    </HeaderContainer>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0.5rem 0;
  background: #fff;

  &:nth-of-type(n + 3) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('medium')`
    gap: 1rem;
    width: 670px;
    margin: 0 auto;
  `}
`;
const Column = styled.div`
  flex: 1;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
`;
const ThumbColumn = styled(Column)`
  flex: none;
  width: 4.5rem;
  padding-left: 1rem;

  ${media.lessThan('medium')`
    width: 2rem;
    padding-left: 0;
  `}
`;
const NameColumn = styled(Column)`
  flex: none;
  width: 7.5rem;
  text-align: left;
  word-break: break-word;

  ${media.lessThan('medium')`
    width: 6.875rem;
  `}
`;
const InputColumn = styled(Column)`
  flex: none;
  width: 5rem;
`;
const AuthColumn = styled(Column)`
  flex: none;
  width: 8rem;
  color: #eb0000;
`;

const Radio = styled.div<{ $disabled?: boolean; $isAdmin: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.36);
  border-radius: 50%;
  box-sizing: border-box;

  &:before {
    content: '';
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background: ${(props) =>
      props.$disabled
        ? props.$isAdmin
          ? 'rgba(0,0,0,.36)'
          : '#fff'
        : props.$isAdmin
          ? '#eb0000'
          : '#fff'};
  }
`;
const GeneralRadio = styled(Radio)`
  &:before {
    background: ${(props) =>
      props.$disabled
        ? props.$isAdmin
          ? '#fff'
          : 'rgba(0,0,0,.36)'
        : props.$isAdmin
          ? '#fff'
          : '#eb0000'};
  }
`;

const RemoveButton = styled(Button)`
  width: 100%;
  padding: 0.5rem;
  font-weight: 400;
`;

const HeaderContainer = styled(Container)`
  margin-bottom: 1rem;
  padding: 0;

  ${Column} {
    color: rgba(0, 0, 0, 0.6);
  }

  ${NameColumn} {
    text-align: center;
  }
`;
