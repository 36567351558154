import React from 'react';
import styled from 'styled-components';

import { TwitterShareButton } from 'react-share';
import TwitterIcon from '../../static/image/icon_twitter.svg';

interface ShareButtonProps {
  content: string;
  hashTags: string[];
}

export const ShareButton: React.FC<ShareButtonProps> = (props) => {
  return (
    <StyledShareButton
      title={
        props.content + `\n${props.hashTags.reduce((acc, cur) => acc + `#${cur} `, '').trim()}`
      }
      url=" "
      translate={'no'}
    >
      <Icon src={TwitterIcon} alt="twitter" />
      <P>Twitterでシェアする</P>
    </StyledShareButton>
  );
};

const StyledShareButton = styled(TwitterShareButton)`
  display: flex;
  padding: 0.75rem 1rem !important;
  background-color: #4b9fd8 !important;
  color: #ffffff !important;
  align-items: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
`;

const Icon = styled.img`
  width: 20px;
  margin-right: 0.75rem;
`;

const P = styled.p`
  font-size: 0.875rem;
`;
