import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  cancelSubscription: () => void;
  isPeriod?: boolean;
}

export const TeamCancelModal: React.FC<Props> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      loading={props.loading}
      width={'576px'}
      header={<Title>{props.isPeriod ? '解約申請のキャンセル' : '解約申請'}</Title>}
      footer={
        <ButtonWrapper>
          <CancelButton onClick={props.onClose}>取り消す</CancelButton>
          <ExcludeButton onClick={props.cancelSubscription}>
            {props.isPeriod ? '解約申請をキャンセルする' : '解約申請する'}
          </ExcludeButton>
        </ButtonWrapper>
      }
    >
      <Container>
        <Description>
          {props.isPeriod ? (
            <>解約申請をキャンセルします。</>
          ) : (
            <>
              解約申請します。
              <br />
              解約申請後も契約期間が終了するまでご利用いただけます。
            </>
          )}
        </Description>
        <Note>
          解約についてはこちらの
          <a href="" target="_blank">
            ヘルプページ
          </a>
          をご覧ください。
        </Note>
      </Container>
    </Modal>
  );
};

const Title = styled.h3`
  width: 100%;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  border-radius: 0.125rem;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition: all 0.2s;

  &:hover {
    background: #efefef;
  }
`;
const ExcludeButton = styled(Button)`
  display: block;
  min-width: 10rem;
  padding: 0.5rem;
  margin-left: 1rem;
  background: #eb0000;
  border: 1px solid #eb0000;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    color: #eb0000;
  }
`;

const Container = styled.div`
  max-width: 480px;
  margin: 0 auto;
  padding: 4.5rem 1.5rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 2rem 1rem 2.25rem;
  `}
`;
const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: .875rem;

    br {
      display: none;
    }
  `}
`;
const Note = styled.p`
  margin-top: 1.25rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: center;

  a {
    color: #1a0dab;
    font-size: 1em;
    text-decoration: underline;
  }

  ${media.lessThan('medium')`
    margin-top: 2.375rem;
  `}
`;
