import React from 'react';
import styled from 'styled-components';

import { BreadcrumbItem } from '../atoms/BreadcrumbItem';

export interface BreadcrumbItemProps {
  label: string;
  to?: string;
  onClick?: () => void;
}

interface BreadcrumbProps {
  links: BreadcrumbItemProps[];
  className?: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = (props): JSX.Element => {
  return (
    <Container className={props.className}>
      {props.links.map((link, i): JSX.Element => {
        return <BreadcrumbItem key={i} label={link.label} to={link.to} onClick={link.onClick} />;
      })}
    </Container>
  );
};

const Container = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  li:nth-of-type(n + 2) {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: 0.875rem;
      height: 0.875rem;
      margin: 0 0.125rem;
      background: transparent center / contain no-repeat;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2214%22%20height%3D%2215%22%20viewBox%3D%220%200%2014%2015%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clipPath%3D%22url(%23clip0_882_40601)%22%3E%3Cpath%20d%3D%22M3.59614%209.976L8.27214%208.058L3.59614%205.888V4.25L10.3861%207.638V8.618L3.59614%2011.614V9.976Z%22%20fill%3D%22black%22%20fillOpacity%3D%220.87%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_882_40601%22%3E%3Crect%20width%3D%2214%22%20height%3D%2214%22%20fill%3D%22white%22%20transform%3D%22translate(14%2014.5)%20rotate(-180)%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E');
    }
  }
`;
