import React from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { TicketFragment as Ticket } from '../../gen/graphql';

interface TicketsProps {
  tickets: Ticket[];
  className?: string;
}

interface TicketElm {
  expireAt: string;
  quantity: number;
}

export const Tickets: React.FC<TicketsProps> = (props) => {
  return (
    <Container className={props.className}>
      <TitleArea>
        <Title>チケットの有効期限</Title>
      </TitleArea>
      <TicketArea>
        {props.tickets.length > 0 ? (
          props.tickets
            .reduce((acc: TicketElm[], cur: Ticket) => {
              // 日時毎にチケットをまとめる
              const elm = acc.find((r) => r.expireAt === cur.expireAt);
              if (elm) {
                elm.quantity++;
              } else {
                acc.push({
                  expireAt: cur.expireAt,
                  quantity: 1,
                });
              }
              return acc;
            }, [])
            .map((ticketElm: TicketElm) => {
              return (
                <TicketUnit>
                  {format(parseISO(ticketElm.expireAt), 'yyyy/MM/dd HH:mm')}まで：{' '}
                  {ticketElm.quantity}枚
                </TicketUnit>
              );
            })
        ) : (
          <TicketUnit>有効なチケットはありません</TicketUnit>
        )}
      </TicketArea>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  display: flex;
  justyfy-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  box-sizing: border-box;
  width: 206px;
  max-width: 206px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
`;

const TitleArea = styled.div`
  width: 100%;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 0.4rem;
  box-sizing: border-box;
`;

const Title = styled.span`
  font-size: 0.8rem;
  color: white;
`;

const TicketArea = styled.div`
  display: flex;
  justyfy-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 12px;
`;
const TicketUnit = styled.span`
  margin-top: 0.6rem;
  font-size: 0.8rem;
  color: white;
`;
