import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface InnerProps {
  className?: string;
}

export const Inner: React.FC<InnerProps> = (props) => {
  return <StyledInner className={props.className}>{props.children}</StyledInner>;
};

const StyledInner = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;
