import React from 'react';
import styled from 'styled-components';

interface InputProps {
  placeholder?: string;
  unit?: string;
  width?: string;
  name: string;
  value?: string | number;
  type: string;
  error?: boolean;
  min?: number;
  max?: number;
  leftImg?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onFocus?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  validation?: boolean;
  inputProps?: Record<string, unknown>;
  className?: string;
}

export const Input: React.FC<InputProps> = (props): JSX.Element => (
  <Container
    width={props.width}
    error={props.error}
    validation={props.validation}
    className={props.className}
  >
    {props.leftImg ? <LeftImg src={props.leftImg} /> : ''}
    <StyledInput
      placeholder={props.placeholder}
      name={props.name}
      value={props.value}
      type={props.type}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      onBlur={props.onBlur}
      error={props.error}
      min={props.min}
      max={props.max}
      onFocus={props.onFocus}
      {...props.inputProps}
    />
    {props.unit ? <Unit error={props.error}>{props.unit}</Unit> : ''}
  </Container>
);

const Container = styled.div<{ error?: boolean; width?: string; validation?: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 32px;
  width: ${(props): string => (props.width ? props.width : '100%')};
  border: 1px solid ${(props): string => (props.error ? 'rgba(0,0,0,0.36)' : '#dddddd')};
  border-radius: 2px;
  background-color: ${(props): string => (props.error ? '#feece4' : '#ffffff')};

  ${(props): string =>
    props.validation
      ? `
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin: auto;
      background: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%2210%22%20fill%3D%22%234FC59A%22%2F%3E%3Cpath%20d%3D%22M8.1264%2012.7922L5.79418%2010.46L5%2011.2486L8.1264%2014.375L14.8378%207.66359L14.0492%206.875L8.1264%2012.7922Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E') center / contain no-repeat;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
    }
  `
      : ''}
`;

const StyledInput = styled.input<{ error?: boolean; paddingLeft?: string }>`
  padding: 0.5rem;
  flex: 1 1 100%;
  width: 100%;
  appearance: none;
  border: none;
  color: ${(props): string => (props.error ? '#e52730' : 'rgba(0,0,0,.87)')};
  font-size: 1rem;
  box-sizing: border-box;
  background-color: ${(props): string => (props.error ? '#feece4' : '#ffffff')};

  &::placeholder {
    color: rgba(0, 0, 0, 0.36);
  }

  &:focus {
    color: #000;
    background-color: #fff9e2;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #fff9e2 inset;
    }
  }
`;

const Unit = styled.span<{ error?: boolean }>`
  flex: 0 0 auto;
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
`;

const LeftImg = styled.img`
  flex: 0 0 auto;
  padding: 0.5rem;
`;
