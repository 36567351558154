import { useEffect, useState } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { addDate } from '../../utils/Date';

export const useCampaignRemainingTime = (
  userCreatedAt: Date,
): {
  remainingTimeInMilliseconds: number;
} => {
  const now = new Date();

  const [remainingTimeInMilliseconds, setRemainingTimeInMilliseconds] = useState(() => {
    return getRemainingTimeInMilliseconds(userCreatedAt, now);
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTimeInMilliseconds(getRemainingTimeInMilliseconds(userCreatedAt, now));
    }, 1000);

    return () => clearInterval(timer);
  });

  return {
    remainingTimeInMilliseconds,
  };
};

/**
 * 現在日時において、基準日時から24時間後までに何ミリ秒残っているかを返す。
 * @param base 基準日時(JST)
 * @param now 現在日時(JST)
 * @returns 残りミリ秒数。
 */
export const getRemainingTimeInMilliseconds = (base: Date, now: Date): number => {
  return differenceInMilliseconds(addDate(base, 0, 0, 1), now);
};
