import { StoreState } from '../../store';
import { CommonModalActions, CommonModalActionTypes } from './actions';

const initialState: StoreState['commonModal'] = {
  isFreeUserModalOpen: false,
  isLightUserModalOpen: false,
  isBasicUserModalOpen: false,
  isPremiumUserModalOpen: false,
  isTeamSubscriptionUserModalOpen: false,
  isTeamSubscriptionToTeamAdminModalOpen: false,
};

export const commonModalReducer = (
  state: StoreState['commonModal'] = initialState,
  action: CommonModalActions,
): StoreState['commonModal'] => {
  switch (action.type) {
    case CommonModalActionTypes.openRegisterFreeUserModal:
      return {
        ...state,
        isFreeUserModalOpen: true,
      };
    case CommonModalActionTypes.closeRegisterFreeUserModal:
      return {
        ...state,
        isFreeUserModalOpen: false,
      };
    case CommonModalActionTypes.openRegisterLightUserModal:
      return {
        ...state,
        isLightUserModalOpen: true,
      };
    case CommonModalActionTypes.closeRegisterLightUserModal:
      return {
        ...state,
        isLightUserModalOpen: false,
      };
    case CommonModalActionTypes.openRegisterBasicUserModal:
      return {
        ...state,
        isBasicUserModalOpen: true,
      };
    case CommonModalActionTypes.closeRegisterBasicUserModal:
      return {
        ...state,
        isBasicUserModalOpen: false,
      };
    case CommonModalActionTypes.openRegisterPremiumUserModal:
      return {
        ...state,
        isPremiumUserModalOpen: true,
      };
    case CommonModalActionTypes.closeRegisterPremiumUserModal:
      return {
        ...state,
        isPremiumUserModalOpen: false,
      };
    case CommonModalActionTypes.openRegisterTeamSubscriptionUserModal:
      return {
        ...state,
        isTeamSubscriptionUserModalOpen: true,
      };
    case CommonModalActionTypes.closeRegisterTeamSubscriptionUserModal:
      return {
        ...state,
        isTeamSubscriptionUserModalOpen: false,
      };
    case CommonModalActionTypes.openRegisterTeamSubscriptionToTeamAdminModal:
      return {
        ...state,
        isTeamSubscriptionToTeamAdminModalOpen: true,
      };
    case CommonModalActionTypes.closeRegisterTeamSubscriptionToTeamAdminModal:
      return {
        ...state,
        isTeamSubscriptionToTeamAdminModalOpen: false,
      };
    case CommonModalActionTypes.closeAllModals:
      return initialState;
    default:
      return state;
  }
};
