import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { BasicLayout } from '../../templates/BasicLayout';
import { Button } from '../../atoms/Button';

import { FeedbackCategoryType, useCreateServiceFeedbackMutation } from '../../../gen/graphql';
import { useToastsContext } from '../../../context/ToastsProvider';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { LOWER_META_TITLE } from '../../../const/Service';
import { FeedbackForm, FeedbackFormValue } from '../../organisms/FeedbackForm';
import { FormProvider, useForm } from 'react-hook-form';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { yupResolver } from '@hookform/resolvers/yup';
import { feedbackSchema } from '../../../common/formSchema/feedback';

const breadcrumbs = [
  {
    label: 'ホーム',
    to: '/home',
  },
  {
    label: 'ご意見・ご要望',
  },
];

export const ServiceFeedback = (): JSX.Element => {
  const metaTitle = `ご意見・ご要望 | ${LOWER_META_TITLE}`;
  const metaDescription =
    '侍テラコヤへのご意見・ご要望をお送りください。お客様お一人おひとりの想いに寄り添ったサービスの提供を目指します。';

  const { showToast } = useToastsContext();
  const [createServiceFeedback] = useCreateServiceFeedbackMutation();
  const navigate = useNavigate();

  const methods = useForm<FeedbackFormValue>({
    defaultValues: {
      category: '0',
      content: '',
    },
    resolver: yupResolver(feedbackSchema),
  });

  const submit = async (data: FeedbackFormValue) => {
    try {
      await createServiceFeedback({
        variables: {
          input: {
            feedbackCategoryID: parseInt(data.category),
            content: data.content,
          },
        },
      });
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
      showToast(1, defaultErrorMessage);
    }
  };

  return (
    <>
      <BasicLayout
        pageTitle="ご意見・ご要望"
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <Container>
          <Breadcrumb links={breadcrumbs}></Breadcrumb>
          <FormProvider {...methods}>
            <FeedbackForm
              title="サービスへのご意見・ご要望をお聞かせください"
              onSubmit={submit}
              type={FeedbackCategoryType.Service}
              backButton={
                <Submit onClick={() => navigate('/home')} border>
                  <p>ホームに戻る</p>
                </Submit>
              }
            />
          </FormProvider>
        </Container>
      </BasicLayout>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 892px;
  margin: 2rem auto;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    flex-direction: column;
    padding: 2rem 0;
  `}
`;

const Submit = styled(Button)`
  display: block;
  width: 100%;
  max-width: 320px;
  margin: 2rem auto 0;
`;
