import { useEffect, useMemo } from 'react';
import { useGetAbTestDeliveriesLazyQuery, AbTestDelivery, DeviceType } from './gen/graphql';
import { generateRandomNumber } from './utils/common';
import { UserDeviceType } from './const/UserDeviceType';
import { getUserDeviceType } from './utils/common';
import { useLocation, useNavigate } from 'react-router-dom';

export function ABTestRedirector(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [getAbTestDeliveries, { data }] = useGetAbTestDeliveriesLazyQuery({
    fetchPolicy: 'cache-first',
  });
  const abTestDeliveries = useMemo(() => data?.abTestDeliveries ?? [], [data?.abTestDeliveries]);

  useEffect(() => {
    getAbTestDeliveries();
    const now = new Date();
    const matchedDelivery = abTestDeliveries.find((abTestDelivery) => {
      return (
        matches(new URL(window.location.href), new URL(abTestDelivery.originURL)) &&
        isActiveDate(now, abTestDelivery) &&
        isDeviceMatched(abTestDelivery)
      );
    });

    if (matchedDelivery) {
      const tmpRedirectUrl = chooseRedirectUrl(matchedDelivery);

      if (tmpRedirectUrl) {
        const redirectUrl = mergeSearchParams(new URL(window.location.href), tmpRedirectUrl);
        if (window.location.host === redirectUrl.host) {
          navigate(redirectUrl.pathname + redirectUrl.search);
        } else {
          window.location.href = redirectUrl.toString();
        }
      }
    }
  }, [location, navigate, getAbTestDeliveries, abTestDeliveries]);

  return <></>;
}

export function isActiveDate(now: Date, abTestDelivery: AbTestDelivery): boolean {
  const startDate = new Date(abTestDelivery.startDate);
  const endDate = new Date(abTestDelivery.endDate);

  if (endDate.getTime() < startDate.getTime()) {
    throw new Error(`endDate(${endDate})がstartDate(${startDate})より前になっています。`);
  }

  return startDate.getTime() <= now.getTime() && now.getTime() < endDate.getTime();
}

export function isDeviceMatched(abTestDelivery: AbTestDelivery): boolean {
  const userDeviceType = getUserDeviceType(navigator.userAgent);
  return (
    abTestDelivery.deviceType === DeviceType.All ||
    (abTestDelivery.deviceType === DeviceType.Pc && userDeviceType === UserDeviceType.PC) ||
    (abTestDelivery.deviceType === DeviceType.Sp && userDeviceType === UserDeviceType.SP)
  );
}

export function chooseRedirectUrl(abTestDelivery: AbTestDelivery): URL | null {
  // どの配信比率も0になる時totalRateが0になるが、後続処理でエラーにならないようにnullを返す。
  if (abTestDelivery.totalRate === 0) {
    return null;
  }
  const targetNum = generateRandomNumber(0, abTestDelivery.totalRate);
  return getRedirectUrl(abTestDelivery, targetNum);
}

/**
 * abTestDelivery.abTestDeliveryDetailsの配信比率の範囲にtargetNumが含まれればリダイレクトURLを返す。
 *
 * @param abTestDelivery - AbTestDelivery
 * @param targetNum - 0以上abTestDelivery.totalRate未満の数値を渡す。
 *
 * @returns リダイレクトURLもしくはnullを返す。
 */
export function getRedirectUrl(abTestDelivery: AbTestDelivery, targetNum: number): URL | null {
  // どの配信比率も0になる時totalRateが0になるが、後続処理でエラーにならないようにnullを返す。
  if (abTestDelivery.totalRate === 0) {
    return null;
  }

  if (targetNum < 0 || abTestDelivery.totalRate <= targetNum) {
    throw new Error(
      `targetNum(${targetNum})が0以上totalRate(${abTestDelivery.totalRate})未満の範囲外になっています。`,
    );
  }

  let accumulatedRate = 0;

  const validDelivery = abTestDelivery.abTestDeliveryDetails.find((detail) => {
    accumulatedRate += detail.deliveryRate;
    return targetNum < accumulatedRate;
  });

  const redirectUrl = validDelivery?.deliveryURL;

  try {
    return redirectUrl ? new URL(redirectUrl) : null;
  } catch {
    return null;
  }
}

/**
 * リダイレクト元のクエリパラメータをリダイレクト先のクエリパラメータにマージする。
 *
 * @param redirectFromUrl - リダイレクト元のURL。
 * @param redirectToUrl - リダイレクト先のURL。
 *
 * @returns リダイレクト先URL。
 */
export function mergeSearchParams(redirectFromUrl: URL, redirectToUrl: URL): URL {
  const newUrl = new URL(redirectToUrl);

  const redirectFromSearchParams = redirectFromUrl.searchParams;
  const newUrlSearchParams = newUrl.searchParams;

  redirectFromSearchParams.forEach((_, key) => {
    newUrlSearchParams.delete(key);
  });
  redirectFromSearchParams.forEach((value, key) => {
    newUrlSearchParams.append(key, value);
  });

  return newUrl;
}

/**
 * 検査するURLがABテストで設定しているリダイレクト元URLであるかどうか判定する。
 *
 * @param actualUrl - 検査するURL。
 * @param settingUrl - ABテストで設定しているリダイレクト元のURL。
 *
 * @returns true：検査するURLがABテストで設定しているリダイレクト元URLと一致している。
 */
export function matches(actualUrl: URL, settingUrl: URL): boolean {
  return settingUrl.toString() === actualUrl.origin + actualUrl.pathname;
}
