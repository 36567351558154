import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ChatMemberListFragment } from '../../gen/graphql';
import { format, isToday, differenceInCalendarDays } from 'date-fns';

import { H4, Tiny } from '../atoms/Typography';
import { CircularIcon } from '../atoms/CircularIcon';
import { CountBadge } from '../atoms/CountBadge';
import { RoleLabel } from '../atoms/RoleLabel';
import { useUser } from '../../redux/user/useUser';

export const CHAT_ROOM_HEIGHT = 68;

interface ChatRoomProps {
  userName: string;
  icon: string;
  recentMessage: string;
  date: Date;
  chatMembers: ChatMemberListFragment[];
  changeRoom: (id: number, chatMembers: ChatMemberListFragment[]) => void;
  id: number;
  unreadCount: number;
  currentRoomID: number;
  e2e?: string;
}

export const ChatRoom: React.FC<ChatRoomProps> = (props) => {
  const navigate = useNavigate();
  const { user } = useUser();
  // 自分以外のチャット相手
  const [chatPartners, setChatPartners] = useState<ChatMemberListFragment[]>([]);
  const diffDays = differenceInCalendarDays(new Date(), props.date);

  useEffect(() => {
    setChatPartners(
      props.chatMembers.filter((chatMember) => user.lmsUser?.id !== chatMember.user?.id),
    );
  }, [props.chatMembers, user.lmsUser?.id]);

  return (
    <Container
      selected={props.id == props.currentRoomID}
      onClick={() => props.changeRoom(props.id, props.chatMembers)}
      data-e2e={props?.e2e}
    >
      <CircularIcon
        src={props.icon}
        size={48}
        onClick={() => {
          const userID = chatPartners[0]?.user?.id;
          if (userID) {
            navigate(`/users/${userID}/profile`);
          }
        }}
        e2e="chat-room-icon"
      />
      <Info>
        <H4 color={'Dark'}>
          <RoomTitle>{props.userName}</RoomTitle>
          <RoleLabel user={chatPartners[0]?.user} />
        </H4>
        <Tiny color={'LIGHT'}>
          {props.recentMessage.length > 30
            ? `${props.recentMessage.substr(0, 30)}...`
            : props.recentMessage}
        </Tiny>
        <Time>
          {isToday(props.date)
            ? format(props.date, 'HH:mm')
            : diffDays <= 7
              ? diffDays + '日前'
              : format(props.date, 'yyyy/MM/dd')}
        </Time>
        <CountBadge count={props.unreadCount} />
      </Info>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  height: ${CHAT_ROOM_HEIGHT}px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  justify-content: space-between;
  cursor: pointer;

  ${(props) => {
    return props.selected ? 'background-color: rgba(253, 60, 47, 0.05);' : '';
  }};
`;
const Info = styled.div`
  flex-grow: 1;
  margin-left: 1rem;
`;

const Time = styled.time`
  position: absolute;
  right: 10px;
  top: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
`;

const RoomTitle = styled.span`
  vertical-align: bottom;
  margin-right: 4px;
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
