import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { BasicLayout } from '../../templates/BasicLayout';

import { MenuContext } from '../../../context/MenuProvider';

import { Button } from '../../atoms/Button';
import { PageWrapper } from '../../atoms/PageWrapper';
import { CourseList } from '../../organisms/CourseList';
import { LessonList } from '../../organisms/LessonList';
import { AddLessonModalWindow } from '../../organisms/NewLessonModal';

import { Loader } from '../../../components/molecules/Loader';

import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

import { SERVICE_NAME, LOWER_META_TITLE } from '../../../const/Service';
import { CourseStatusLabels } from '../../../const/Course';
import {
  CourseSearchType,
  useGetCoursesForInstructorCourseListQuery,
  useGetLessonsForInstructorQuery,
} from '../../../gen/graphql';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { useUser } from '../../../redux/user/useUser';
import { NotFoundPage } from '../public/NotFound';

export interface AddParam {
  startAt?: Date;
  endAt?: Date;
  courseID: number;
}

export const InstructorLessonContainer = (): JSX.Element => {
  const paramCourseID = useParams<{ course_id: string }>().course_id;
  // undefined許容
  const pathCourseID = paramCourseID ? Number(paramCourseID) : undefined;
  if (Number.isNaN(pathCourseID)) return NotFoundPage;

  return <InstructorLesson courseID={pathCourseID} />;
};

interface InstructorLessonProps {
  courseID: number | undefined;
}

const InstructorLesson = (props: InstructorLessonProps): JSX.Element => {
  const metaTitle = `専属レッスン | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}の専属レッスンページです。レッスン日の確認やこれまでのレッスン履歴を確認できます。`;

  const { permissionCheck } = useUser();
  const navigate = useNavigate();

  const [courseID, setCourseID] = useState<number | undefined>(props.courseID);
  const [filteredStatus, setFilteredStatus] = useState<number | undefined>(undefined);
  const [isListOpen, setIsOpen] = useState(Boolean(courseID));
  const { isOpen } = useContext(MenuContext);
  const { isHoverOpen } = useContext(MenuContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  // コース取得処理。ステータス変更時に実行
  const {
    data: coursesData,
    refetch: courseRefetch,
    loading: courseLoading,
  } = useGetCoursesForInstructorCourseListQuery({
    variables: {
      input: {
        type: CourseSearchType.All,
        status: filteredStatus ? [filteredStatus] : undefined, // 0の場合は未選択なのでundefined
      },
    },
  });

  // レッスン取得処理。コース&ステータス変更時に実行
  const {
    data: lessonData,
    refetch: lessonRefetch,
    loading: lessonLoading,
  } = useGetLessonsForInstructorQuery({
    variables: {
      input: {
        courseID: courseID || undefined,
        courseStatus: filteredStatus || undefined,
      },
    },
  });
  const lessons = lessonData?.lessonsForInstructor ?? [];

  // 以前のレッスン、今後のレッスンに割り振る
  const previousLessons = lessons
    .filter((lesson) => new Date(lesson.startAt) < new Date() || lesson.completed)
    .sort((a, b) => (a.startAt > b.startAt ? -1 : 1));
  const followingLessons = lessons
    .filter((lesson) => new Date(lesson.startAt) >= new Date() && !lesson.completed)
    .sort((a, b) => (a.startAt < b.startAt ? -1 : 1)); // 今後のレッスンは逆順にソートする
  const plannedLessonCount = lessons.length > 0 ? (lessons[0].course?.plannedLessonCount ?? 0) : 0;
  // 次回レッスンの有無
  const nextLessonRegisterable = courseID ? lessons.length < plannedLessonCount : false;

  // 各更新処理後のrefetch処理
  const onRefetchLesson = useSafeAsyncCallback(
    useCallback(async () => {
      await courseRefetch({
        input: {
          type: CourseSearchType.All,
          status: filteredStatus ? [filteredStatus] : undefined, // 0の場合は未選択なのでundefined
        },
      });
      await lessonRefetch({
        input: {
          courseID: courseID,
          courseStatus: filteredStatus || undefined,
        },
      });
    }, [lessonRefetch, courseRefetch, courseID, filteredStatus]),
  );

  const changeCourse = (id: number | undefined) => {
    setCourseID(id);
    setIsOpen(true);
    if (id) {
      navigate(`/lessons/${id}`);
    } else {
      navigate(`/lessons/`);
    }
  };

  const handleSelectStatus: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    async (e) => {
      const status = parseInt(e.target.value);
      setFilteredStatus(status);
      setCourseID(undefined);
      navigate(`/lessons/`);
    },
    [navigate],
  );

  useEffect(() => {
    setIsOpen(Boolean(courseID));
  }, [courseID]);

  return (
    <React.Fragment>
      <AddLessonModalWindow refetch={onRefetchLesson} isOpen={modalIsOpen} onClose={closeModal} />
      <BasicLayout pageTitle="専属レッスン" metaTitle={metaTitle} metaDescription={metaDescription}>
        <Container>
          <Left isOpen={isListOpen} menuHoverOpen={isHoverOpen} menuOpen={isOpen}>
            {permissionCheck(FunctionType.LessonForInstructor, PermissionType.Create) && (
              <ButtonContainer>
                <AddLesson onClick={openModal}>＋専属レッスンを登録 </AddLesson>
              </ButtonContainer>
            )}
            <div
              className={`all-lessons ${!courseID ? 'selected' : ''}`}
              onClick={() => changeCourse(undefined)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.6 1.6V4.2H18.4V1.6H1.6ZM1.6 18.4V5.8H18.4V18.4H1.6ZM1 0C0.447715 0 0 0.447715 0 1V19C0 19.5523 0.447715 20 1 20H19C19.5523 20 20 19.5523 20 19V1C20 0.447715 19.5523 0 19 0H1ZM12.38 13.53C12.38 14.89 11.19 15.68 9.78005 15.68C8.61005 15.68 7.82005 15.24 7.30005 14.65L7.99005 13.72C8.43005 14.15 8.97005 14.48 9.64005 14.48C10.39 14.48 10.9 14.11 10.9 13.45C10.9 12.72 10.47 12.27 8.86005 12.27V11.21C10.22 11.21 10.66 10.74 10.66 10.08C10.66 9.5 10.3 9.16 9.67005 9.15C9.14005 9.16 8.71005 9.41 8.27005 9.81L7.52005 8.91C8.16005 8.35 8.87005 8 9.74005 8C11.17 8 12.14 8.7 12.14 9.97C12.14 10.77 11.69 11.35 10.9 11.66V11.71C11.74 11.94 12.38 12.56 12.38 13.53Z"
                  fill="black"
                />
              </svg>
              <p>すべての予定</p>
            </div>
            <div>
              <StatusList name="status" id="status" onChange={handleSelectStatus}>
                <option value="0">すべて</option>
                {Object.entries(CourseStatusLabels).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </StatusList>
            </div>
            <CourseList
              changeCourse={changeCourse}
              coursesData={coursesData?.coursesForInstructor ?? []}
              currentCourseID={courseID ?? 0}
            />
          </Left>
          <Right isOpen={isListOpen}>
            <PageWrapper>
              <Loader display={lessonLoading || courseLoading}></Loader>
              <LessonList
                permissionCheck={permissionCheck}
                nextLessonRegisterable={nextLessonRegisterable}
                refetch={onRefetchLesson}
                previousLessons={previousLessons}
                followingLessons={followingLessons}
                allLessons={lessons}
              />
            </PageWrapper>
          </Right>
        </Container>
      </BasicLayout>
    </React.Fragment>
  );
};

const Container = styled.div<{ isOpen?: boolean }>`
  height: 100%;
  display: flex;

  .all-lessons {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    padding: 1rem 1.4rem;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .selected {
    background-color: rgba(253, 60, 47, 0.05);
  }

  .all-lessons p {
    margin-left: 0.6rem;
    font-weight: 600;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
const AddLesson = styled(Button)`
  display: block;
  width: 100%;
  padding: 1rem;
`;

const Left = styled.section<{ isOpen?: boolean; menuHoverOpen: boolean; menuOpen: boolean }>`
  position: sticky;
  top: 66px;
  width: 25%;
  min-width: 230px;
  max-width: 360px;
  height: calc(100vh - 66px);
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  ${(props) => {
    if (props.menuOpen || !props.menuHoverOpen) return 'z-index: 50;';
    return 'z-index: 2;';
  }}

  ${media.lessThan('medium')`
    width: 100%;
    max-width: none;
    background: #fff;
    z-index: 2;
  `}

  ${(props) =>
    props.isOpen
      ? media.lessThan('medium')`
      display: none;
    `
      : ''}

  svg {
    stroke: #000;
    stroke-opacity: 0.87;
    fill-opacity: 0.87;
    stroke-width: 1;
    stroke-linejoin: round;
    cursor: pointer;
  }

  svg.withfill {
    fill: #000;
  }
`;

const StatusList = styled.select`
  height: 2rem;
  width: 100%;
`;

const Right = styled.section<{ isOpen?: boolean }>`
  flex: 1;
  flex-grow: 1;

  ${media.lessThan('medium')`
    width: 100%;
    flex: none;
    transition: all 0.2s;
    background-color: #ffffff;
  `}

  ${(props) =>
    !props.isOpen
      ? media.lessThan('medium')`
      display: none;
    `
      : ''}
`;
