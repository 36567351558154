import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { StoreState } from '../../store';
import { loginByTemporaryPassword, setSocialLoginCallbackURL, UserActionTypes } from './actions';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client/errors';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import TagManager from 'react-gtm-module';
import {
  clearLocalStorage,
  getUserRoleFromLocalStorage,
  getUserTeamIDFromLocalStorage,
  removeUserTeamIDToLocalStorage,
  setUserRoleToLocalStorage,
  setUserTeamIDToLocalStorage,
} from '../../utils/localStorageSupport';
import {
  useUpdateLastAccessMutation,
  useGetCurrentUserWithPermissionsQuery,
  CurrentUserFragment as User,
  CurrentPermissionListFragment as UserPermissionList,
  CurrentUserWithPermissionListFragment as UserWithPermissionList,
  useOAuthRegisterMutation,
  MyTeamMemberFragment,
  useGetMyTeamMembersQuery,
  UserRole,
  TeamMemberRole,
} from '../../gen/graphql';
import { defaultErrorMessage } from '../../const/ErrorMessage';
import { UserRoleStudents, UserRoleTeams } from '../../const/UserRole';
import { UserPermissions } from '../../const/UserPermission';
import { sessionStorageSupport } from '../../utils/sessionStorageSupport';
import { addDate } from '../../utils/Date';

export interface UseUser {
  user: StoreState['user'];
  team: MyTeamMemberFragment | null;
  login: (email: string, password: string) => Promise<UserWithPermissionList | null>;
  socialLogin: (provider: CognitoHostedUIIdentityProvider, callbackURL?: string) => Promise<void>;
  callbackOauthLogin: () => Promise<UserWithPermissionList | undefined>;
  logout: () => Promise<void>;
  changeRole: (newRole: UserRole) => void;
  changeRoleStudent: () => void;
  changeTeamID: (newTeamID: string) => void;
  removeTeamID: () => void;
  refresh: () => Promise<void>;
  isLoadingUser: boolean;
  isRoleConfirmed: boolean;
  isSocialLoginUser: () => boolean;
  hasCoaching: () => boolean;
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  permissionCheckToInstructor: () => boolean;
  permissionCheckToCoach: () => boolean;
  permissionCheckToStudent: () => boolean;
  fetchCurrentUserWithPermissions: () => Promise<{
    list: UserWithPermissionList | undefined;
    error?: ApolloError;
  }>;
  forgotPassword: (email: string) => Promise<void>;
}

export const useUser = (): UseUser => {
  const user = useSelector((state: StoreState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoadingUser, setIsLoadingUser] = React.useState(false);
  const [isRoleConfirmed, setIsRoleConfirmed] = React.useState(false);

  const [cookies, setCookie, removeCookie] = useCookies();

  const [oAuthRegister] = useOAuthRegisterMutation();

  // GTMで読み込まれてるため削除禁止！
  const setCookies = React.useCallback(() => {
    removeCookie('lmsuid', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
    setCookie('is_ins', 0, {
      expires: addDate(new Date(), 10, 0, 0),
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: true,
    });
    setCookie('is_std', 0, {
      expires: addDate(new Date(), 10, 0, 0),
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: true,
    });
    setCookie('is_adm', 0, {
      expires: addDate(new Date(), 10, 0, 0),
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: true,
    });
    setCookie('is_sns', 0, {
      expires: addDate(new Date(), 10, 0, 0),
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: true,
    });
    setCookie('sns_type', 0, {
      expires: addDate(new Date(), 10, 0, 0),
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: true,
    });

    if (user.lmsUser) {
      setCookie('lmsuid', user.lmsUser.id, {
        expires: addDate(new Date(), 10, 0, 0),
        path: '/',
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: true,
      });
      setCookie('sns_type', user.lmsUser.snsType, {
        expires: addDate(new Date(), 10, 0, 0),
        path: '/',
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: true,
      });
      setCookie('is_ins', hasInstructor(), {
        expires: addDate(new Date(), 10, 0, 0),
        path: '/',
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: true,
      });
      setCookie('is_std', hasStudent(), {
        expires: addDate(new Date(), 10, 0, 0),
        path: '/',
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: true,
      });
      setCookie('is_adm', hasAdmin(), {
        expires: addDate(new Date(), 10, 0, 0),
        path: '/',
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: true,
      });
      if (user.lmsUser.snsType !== 0)
        setCookie('is_sns', 1, {
          expires: addDate(new Date(), 10, 0, 0),
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          secure: true,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const checkRoleAndTeamID = useCallback(
    (
      permissionList: UserPermissionList | null | undefined,
      myTeams: MyTeamMemberFragment[] | undefined,
      userID: number | undefined,
    ) => {
      if (!permissionList || !userID) return UserRole.NotLogin;

      // instructor,coach,student
      // ローカルストレージにログインロールが保存されていればそちらを優先
      // (手動でローカルストレージを変更するなどの不正ログインを防ぐため、userの状態もチェックする)
      const storedRole = getUserRoleFromLocalStorage(userID);
      if (storedRole === UserRole.Coach && hasCoachCheck(permissionList)) {
        return storedRole;
      }
      if (storedRole === UserRole.Instructor && hasInstructorCheck(permissionList)) {
        return storedRole;
      }
      if (
        !!storedRole &&
        permissionList.roles.includes(storedRole) &&
        hasStudentCheck(permissionList)
      ) {
        return storedRole;
      }

      // team
      if (myTeams && myTeams.length !== 0 && hasTeamCheck(permissionList)) {
        const storedTeamID = getUserTeamIDFromLocalStorage(userID);

        if (storedTeamID) {
          const existTeam = myTeams.find((team) => team.teamID === storedTeamID);

          if (existTeam) {
            // ローカルストレージに保存してるteamIDが、最新の所属チーム情報に存在する場合
            dispatch({
              type: UserActionTypes.setTeamID,
              payload: { teamID: storedTeamID },
            });
            return setTeamRole(permissionList, existTeam);
          } else {
            // ローカルストレージに保存してるteamIDが、最新の所属チーム情報に存在しない場合
            // ローカルストレージに保存してるteamIDを削除
            removeUserTeamIDToLocalStorage(userID);
            // 最新の所属チームの一番新しいチームIDをstoreに保存
            const currentTeam = myTeams[myTeams.length - 1];
            dispatch({
              type: UserActionTypes.setTeamID,
              payload: { teamID: currentTeam.id },
            });
            return setTeamRole(permissionList, currentTeam);
          }
        } else {
          const currentTeam = myTeams[myTeams.length - 1];
          return setTeamRole(permissionList, currentTeam);
        }
      }

      // admin
      if (hasAdminCheck(permissionList)) {
        const role = permissionList.roles.find((role) => role !== UserRole.Admin);
        // admin以外含まれる場合ははじめに取得できるRoleを設定
        if (role) return role;
        return UserRole.Admin;
      }

      // はじめに取得できるRoleを設定
      return permissionList.roles[0];
    },
    [dispatch],
  );

  const setTeamRole = (permissionList: UserPermissionList, myTeam: MyTeamMemberFragment) => {
    if (
      myTeam.role === TeamMemberRole.Admin &&
      permissionList.roles.includes(UserRole.TeamAdmin) &&
      permissionList.teamIDsForTeamAdmin.includes(myTeam.teamID)
    )
      return UserRole.TeamAdmin;
    if (
      myTeam.role === TeamMemberRole.Admin &&
      permissionList.roles.includes(UserRole.TeamAdminFree) &&
      !permissionList.teamIDsForTeamAdmin.includes(myTeam.teamID)
    )
      return UserRole.TeamAdminFree;
    if (
      myTeam.role === TeamMemberRole.General &&
      permissionList.roles.includes(UserRole.TeamMember) &&
      permissionList.teamIDs.includes(myTeam.teamID)
    )
      return UserRole.TeamMember;
    if (
      myTeam.role === TeamMemberRole.General &&
      permissionList.roles.includes(UserRole.TeamMemberFree) &&
      !permissionList.teamIDs.includes(myTeam.teamID)
    )
      return UserRole.TeamMemberFree;
    // 異常値
    return UserRole.NotLogin;
  };

  const [updateLastAccessMutation] = useUpdateLastAccessMutation();

  const updateLastAccessedAt = useCallback(async (): Promise<void> => {
    // 保存
    try {
      const smrc = cookies['smrc_'] ? String(cookies['smrc_']) : undefined;
      updateLastAccessMutation({ variables: { smrc: smrc } });
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
      return;
    }
  }, [updateLastAccessMutation, cookies]);

  const { refetch: refetchCurrentUserWithPermissions } = useGetCurrentUserWithPermissionsQuery({
    // await で戻り値を利用するためにSkip：trueを設定してRefetchで運用（LazyQueryだと戻り値が利用できず）
    // see:https://stackoverflow.com/questions/68216866/async-validation-with-uselazyquery-hook
    skip: true,
  });
  const fetchCurrentUserWithPermissions = useCallback(async (): Promise<{
    list: UserWithPermissionList | undefined;
    error?: ApolloError;
  }> => {
    return await refetchCurrentUserWithPermissions()
      .then((result) => {
        // onCompletedだと毎回呼ばれないためここでCurrentUserを再設定（notifyOnNetworkStatusChange: true && fetchPolicy: 'network'でも動作せず）
        const currentUserWithPermissions = result.data.currentUserWithPermissions;
        if (!currentUserWithPermissions) return { list: undefined };

        const currentUser = currentUserWithPermissions.user;
        const currentPermissionList = currentUserWithPermissions.permissionList;
        const contractsOfStripeSubscriptionForAnalytics =
          currentUserWithPermissions.contractsOfStripeSubscriptionForAnalytics;
        if (!currentUser || !currentPermissionList) return { list: undefined };

        dispatch({
          type: UserActionTypes.setLMSUser,
          payload: {
            lmsUser: currentUser,
            permissionList: currentPermissionList,
            contractsOfStripeSubscriptionForAnalytics: contractsOfStripeSubscriptionForAnalytics,
            // 課金状況によりroleが変わる(Subscription <=> General)ことがあるため、ユーザー情報と同時にroleも最新化する
            // lmsUser、roleを更新している箇所が散らばっているため、まとめた方がいい
            role: checkRoleAndTeamID(currentPermissionList, user.teams, user.lmsUser?.id),
          },
        });
        return { list: currentUserWithPermissions ?? undefined };
      })
      .catch((err) => {
        return { list: undefined, error: err };
      });
  }, [
    checkRoleAndTeamID,
    dispatch,
    refetchCurrentUserWithPermissions,
    user.lmsUser?.id,
    user.teams,
  ]);

  const { refetch: refetchMyTeamMembers } = useGetMyTeamMembersQuery({
    // LazyQueryだと前回ログインユーザーの情報が取得されてしまうため、Skip：trueを設定してRefetchで運用
    skip: true,
  });
  const fetchMyTeamMembers = useCallback(async (): Promise<MyTeamMemberFragment[] | undefined> => {
    return await refetchMyTeamMembers().then((result) => {
      // onCompletedだと毎回呼ばれないためここでteamを再設定（notifyOnNetworkStatusChange: true && fetchPolicy: 'network'でも動作せず）
      // Queryのdataが取得されないため取得情報をstoreに格納
      const myTeams = result.data.myTeamMembers;
      if (!myTeams || myTeams.length === 0) {
        dispatch({
          type: UserActionTypes.setTeams,
          payload: {
            teamID: null,
            teams: [],
          },
        });
        return;
      }

      const myTeamID = myTeams[myTeams.length - 1].teamID;
      dispatch({
        type: UserActionTypes.setTeams,
        payload: {
          teamID: myTeamID,
          teams: myTeams,
        },
      });
      return myTeams;
    });
  }, [dispatch, refetchMyTeamMembers]);
  const team = user.teams.find((team) => team.teamID === user.teamID) ?? null;

  const login = async (email: string, password: string): Promise<UserWithPermissionList | null> => {
    setIsLoadingUser(true);

    Auth.configure({
      Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
      },
      oauth: {
        domain: process.env.REACT_APP_COGNITO_CLIENT_DOMAIN,
        redirectSignIn: process.env.REACT_APP_DOMAIN + '/login/oauth/callback',
        redirectSignOut: process.env.REACT_APP_DOMAIN + '/',
        responseType: 'code',
      },
    });

    const cognitoUserRes = await Auth.signIn(email, password);
    if (cognitoUserRes == undefined) {
      setIsLoadingUser(false);
      throw 'ユーザーとして登録されていません。';
    }

    if (cognitoUserRes.challengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch(
        loginByTemporaryPassword({
          cognitoUser: cognitoUserRes,
        }),
      );

      setIsLoadingUser(false);
      return null;
    }

    // set Authorization header
    axios.defaults.headers.common['Authorization'] =
      `Bearer ${cognitoUserRes.signInUserSession.idToken.jwtToken}`;

    const { list: currentUserWithPermissionsList } = await fetchCurrentUserWithPermissions();
    const currentUser = currentUserWithPermissionsList?.user;
    const currentPermissionList = currentUserWithPermissionsList?.permissionList;
    const contractsOfStripeSubscriptionForAnalytics =
      currentUserWithPermissionsList?.contractsOfStripeSubscriptionForAnalytics;
    if (!currentUser || !currentPermissionList) throw 'ユーザーとして登録されていません。';

    const myTeams = await fetchMyTeamMembers();

    const payload = {
      lmsUser: currentUser,
      cognitoUser: cognitoUserRes,
      permissionCheck: currentPermissionList,
      contractsOfStripeSubscriptionForAnalytics: contractsOfStripeSubscriptionForAnalytics,
      role: checkRoleAndTeamID(currentPermissionList, myTeams, currentUser.id),
      isChangePassword: false,
      isLogged: true,
      loginError: '',
    };

    dispatch({
      type: UserActionTypes.login,
      payload,
    });

    setCookies();

    setIsLoadingUser(false);

    // update access date
    updateLastAccessedAt();

    return currentUserWithPermissionsList;
  };

  /**
   * ソーシャルログイン
   * @param provider
   * @param callbackURL ソーシャルログイン後に遷移する画面を指定
   */
  const socialLogin = useCallback(
    async (provider: CognitoHostedUIIdentityProvider, callbackURL?: string): Promise<void> => {
      // ソーシャルログイン時のパラメーター引き回し対応
      // TODO: Hubのイベントがステージングで発火しない問題の原因が分かったらcustomStateを使う方を戻す
      // // @see https://docs.amplify.aws/lib/auth/social/q/platform/js/#setup-frontend
      // await Auth.federatedSignIn({ provider, customState: callbackURL });
      if (callbackURL) {
        sessionStorageSupport.setItem('SOCIAL_LOGIN_CALLBACK_URL', callbackURL);
      }

      await Auth.federatedSignIn({ provider });
    },
    [],
  );

  const registerSocialLoginUser = async (userName: string): Promise<User | undefined> => {
    try {
      const res = await oAuthRegister({
        variables: {
          input: {
            userName: userName,
            smrc: cookies['smrc_'],
          },
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          userId: res.data?.oAuthRegister.id,
          user: res.data?.oAuthRegister,
          event: 'gtm-register-step_01',
        },
        dataLayerName: 'LMSDataLayer',
      });
      return res.data?.oAuthRegister;
    } catch {
      return;
    }
  };

  const callbackOauthLogin = async () => {
    const cognitoUserRes = await Auth.currentAuthenticatedUser({ bypassCache: false })
      .then((session) => session)
      .catch(() => {
        const payload = {
          error: '無効なユーザーです',
        };

        dispatch({
          type: UserActionTypes.loginError,
          payload,
        });

        navigate('/');
        return;
      });
    if (cognitoUserRes == undefined) {
      const payload = {
        error: defaultErrorMessage,
      };

      dispatch({
        type: UserActionTypes.loginError,
        payload,
      });
      navigate('/');
      return;
    }

    // set Authorization header
    axios.defaults.headers.common['Authorization'] =
      `Bearer ${cognitoUserRes.signInUserSession.idToken.jwtToken}`;

    // ユーザー登録直後の場合はInvalidTokenエラーが発生するためSocialLogin専用のCurrentUser取得処理を実行
    const { list: currentUserWithPermissionsResOp } = await fetchCurrentUserWithPermissions();

    const currentUserWithPermissionsRes = !currentUserWithPermissionsResOp
      ? await registerSocialLoginUser(cognitoUserRes.username)
          .then(async () => {
            const { list, error } = await fetchCurrentUserWithPermissions();
            if (error) {
              // @see: https://samurai-7s.backlog.com/view/LMS-4645
              if (error.graphQLErrors[0]?.extensions?.code === 'COMMON_INVALID_TOKEN') {
                dispatch({
                  type: UserActionTypes.loginError,
                  // @see: utils/CognitoErrorDictionary.ts (TranslateCognitoError)
                  payload: {
                    error:
                      'If you have already registered an account with an email address, you will not be able to register/login with a new social account with the same email address. Please try logging in with your email address and password.',
                  },
                });
              }

              return;
            } else {
              sessionStorageSupport.setItem('REGISTERED_TYPE', 'new');
              sessionStorageSupport.setItem('QUESTIONNAIRE', 'true');
            }

            return list;
          })
          .catch((err) => {
            const payload = {
              error: err,
            };

            dispatch({
              type: UserActionTypes.loginError,
              payload,
            });

            return;
          })
      : currentUserWithPermissionsResOp;
    const currentUser = currentUserWithPermissionsRes?.user;
    const currentPermissionList = currentUserWithPermissionsRes?.permissionList;
    const contractsOfStripeSubscriptionForAnalytics =
      currentUserWithPermissionsRes?.contractsOfStripeSubscriptionForAnalytics;

    if (!currentUser || !currentPermissionList) {
      navigate('/');
      return;
    }

    if (Object.keys(currentUser).length !== 0) {
      const myTeams = await fetchMyTeamMembers();

      const payload = {
        lmsUser: currentUser,
        cognitoUser: cognitoUserRes,
        permissionList: currentPermissionList,
        contractsOfStripeSubscriptionForAnalytics: contractsOfStripeSubscriptionForAnalytics,
        role: checkRoleAndTeamID(currentPermissionList, myTeams, currentUser.id),
        isChangePassword: false,
        isLogged: true,
        loginError: '',
      };

      dispatch({
        type: UserActionTypes.login,
        payload,
      });

      setIsLoadingUser(false);

      updateLastAccessedAt();
    } else {
      const payload = {
        error: defaultErrorMessage,
      };

      dispatch({
        type: UserActionTypes.loginError,
        payload,
      });
      navigate('/');
      return;
    }

    setCookies();

    return currentUserWithPermissionsRes;
  };

  const logout = useCallback(async () => {
    dispatch({
      type: UserActionTypes.logout,
    });

    axios.defaults.headers.common['Authorization'] = '';
    if (user.lmsUser?.id) {
      clearLocalStorage(user.lmsUser.id);
    }
    await Auth.signOut();

    setCookies();

    return;
  }, [dispatch, setCookies, user.lmsUser?.id]);

  const changeRole = useCallback(
    (newRole: UserRole) => {
      if (user.lmsUser?.id) {
        // 再ログイン時、リロード時に権限が戻ってしまわないように永続化する
        setUserRoleToLocalStorage(newRole, user.lmsUser.id);
      }

      dispatch({
        type: UserActionTypes.changeRole,
        payload: { role: newRole },
      });
    },
    [dispatch, user.lmsUser?.id],
  );

  const changeRoleStudent = useCallback(() => {
    const roles = user.permissionList?.roles;
    if (!roles) return;

    // ユーザー情報のロールのstudent系を設定
    const newRole = UserRoleStudents.find((userRoleStudent) =>
      roles.find((role) => role === userRoleStudent),
    );
    if (!newRole) return;

    changeRole(newRole);
  }, [user.permissionList?.roles, changeRole]);

  const changeTeamID = useCallback(
    (teamID: string) => {
      if (user.lmsUser?.id) {
        // 再ログイン時、リロード時に権限が戻ってしまわないように永続化する
        setUserTeamIDToLocalStorage(teamID, user.lmsUser.id);
      }

      dispatch({
        type: UserActionTypes.setTeamID,
        payload: { teamID: teamID },
      });
    },
    [dispatch, user.lmsUser?.id],
  );

  const removeTeamID = useCallback(() => {
    if (user.lmsUser?.id) {
      removeUserTeamIDToLocalStorage(user.lmsUser.id);
    }

    dispatch({
      type: UserActionTypes.setTeamID,
      payload: { teamID: null },
    });
  }, [dispatch, user.lmsUser?.id]);

  const refresh = React.useCallback(async () => {
    setIsLoadingUser(true);

    if (user.isLogged) {
      setCookies();
      const { list: currentUserWithPermissions } = await fetchCurrentUserWithPermissions();
      const cuurentPermissionList = currentUserWithPermissions?.permissionList;
      const myTeams = await fetchMyTeamMembers();
      dispatch({
        type: UserActionTypes.changeRole,
        payload: { role: checkRoleAndTeamID(cuurentPermissionList, myTeams, user.lmsUser?.id) },
      });
      setIsRoleConfirmed(true);
      setIsLoadingUser(false);
      return;
    }

    const currentCognitoUser = await Auth.currentAuthenticatedUser()
      .then((res) => res)
      .catch(() => {
        dispatch({ type: UserActionTypes.changeRole, payload: { role: UserRole.NotLogin } });
        setCookies();
        setIsRoleConfirmed(true);
        setIsLoadingUser(false);
        return;
      });

    if (!currentCognitoUser) {
      dispatch({ type: UserActionTypes.changeRole, payload: { role: UserRole.NotLogin } });
      setCookies();
      setIsLoadingUser(false);
      return;
    }

    axios.defaults.headers.common['Authorization'] =
      `Bearer ${currentCognitoUser.signInUserSession.idToken.jwtToken}`;
    // ユーザー登録直後の場合はInvalidTokenエラーが発生するためSocialLogin専用のCurrentUser取得処理を実行
    const { list: currentUserWithPermissionsRes } = await fetchCurrentUserWithPermissions();
    const currentUser = currentUserWithPermissionsRes?.user;
    const currentPermissionList = currentUserWithPermissionsRes?.permissionList;
    const contractsOfStripeSubscriptionForAnalytics =
      currentUserWithPermissionsRes?.contractsOfStripeSubscriptionForAnalytics;

    if (!currentUser || !currentPermissionList) {
      dispatch({ type: UserActionTypes.changeRole, payload: { role: UserRole.NotLogin } });
      setCookies();
      setIsRoleConfirmed(true);
      setIsLoadingUser(false);
      return;
    }

    dispatch({
      type: UserActionTypes.login,
      payload: {
        cognitoUser: currentCognitoUser,
        lmsUser: currentUser,
        permissionList: currentPermissionList,
        contractsOfStripeSubscriptionForAnalytics: contractsOfStripeSubscriptionForAnalytics,
      },
    });

    setCookies();

    const myTeams = await fetchMyTeamMembers();

    dispatch({
      type: UserActionTypes.login,
      payload: {
        cognitoUser: currentCognitoUser,
        lmsUser: currentUser,
        permissionList: currentPermissionList,
        contractsOfStripeSubscriptionForAnalytics: contractsOfStripeSubscriptionForAnalytics,
        role: checkRoleAndTeamID(currentPermissionList, myTeams, currentUser.id),
      },
    });

    setIsRoleConfirmed(true);
    setIsLoadingUser(false);

    // update access date
    updateLastAccessedAt();

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const setIsChangePassword = (isChangePassword: boolean) => {
    dispatch({
      type: UserActionTypes.setIsChangePassword,
      payload: { isChangePassword: isChangePassword },
    });
  };

  const forgotPassword = async (email: string) => {
    await Auth.forgotPassword(email);
    setIsChangePassword(true);
  };

  const isSocialLoginUser = useCallback((): boolean => {
    if (!user.cognitoUser) {
      return false;
    }
    return /Google|Facebook/.test(user.cognitoUser.getUsername());
  }, [user.cognitoUser]);

  // adminロールがあるかチェック
  const hasAdminCheck = (u: UserPermissionList) => u.roles.includes(UserRole.Admin);
  const hasAdmin = useCallback(() => {
    const permissionList = user.permissionList;
    if (!permissionList) return false;
    return hasAdminCheck(permissionList);
  }, [user.permissionList]);

  // instructorロールがあるかチェック
  const hasInstructorCheck = (u: UserPermissionList) => u.roles.includes(UserRole.Instructor);
  const hasInstructor = useCallback(() => {
    const permissionList = user.permissionList;
    if (!permissionList) return false;
    return hasInstructorCheck(permissionList);
  }, [user.permissionList]);

  // coachロールがあるかチェック
  const hasCoachCheck = (u: UserPermissionList) => u.roles.includes(UserRole.Coach);
  const hasCoach = useCallback(() => {
    const permissionList = user.permissionList;
    if (!permissionList) return false;
    return hasCoachCheck(permissionList);
  }, [user.permissionList]);

  // studentロールがあるかチェック
  const hasStudentCheck = (u: UserPermissionList) => {
    for (const userRoleStudent of UserRoleStudents) {
      if (u.roles.includes(userRoleStudent)) return true;
    }
    return false;
  };
  const hasStudent = useCallback(() => {
    const permissionList = user.permissionList;
    if (!permissionList) return false;
    return hasStudentCheck(permissionList);
  }, [user.permissionList]);

  // teamロールがあるかチェック
  const hasTeamCheck = (u: UserPermissionList) => {
    for (const userRoleTeam of UserRoleTeams) {
      if (u.roles.includes(userRoleTeam)) return true;
    }
    return false;
  };

  // コーチングがあるかをチェック
  const hasCoaching = (): boolean => {
    const courses = user.lmsUser?.student?.courses;
    if (courses !== undefined && courses.length > 0) {
      return courses.filter((c) => c?.coaching?.id).length > 0;
    }
    return false;
  };

  // 認可チェック
  const permissionCheck = useCallback(
    (functionType: string, permissionType: string): boolean => {
      // 現在のロールのpermissionを取得
      const userPermissionEntries = Object.entries(UserPermissions);
      const rolePermissions = userPermissionEntries.find(
        (userPermissionEntity) => userPermissionEntity[0] === user.role,
      );
      if (!rolePermissions) return false;

      // functionTypeからpermissionTypeを取得
      const roleEntries = Object.entries(rolePermissions[1]);
      const functionPermissions = roleEntries.find((roleEntity) => roleEntity[0] === functionType);
      if (!functionPermissions) return false;

      return functionPermissions[1].includes(permissionType);
    },
    [user.role],
  );

  // instructorロールがあるかをチェック
  const permissionCheckToInstructor = useCallback((): boolean => {
    // 現在のロールがinstructorかチェック
    if (user.role === UserRole.Instructor) return false;

    // ユーザー情報のロールにinstructorがあるかチェック
    return hasInstructor();
  }, [user.role, hasInstructor]);

  // coachロールがあるかをチェック
  const permissionCheckToCoach = useCallback((): boolean => {
    // 現在のロールがcoachかチェック
    if (user.role === UserRole.Coach) return false;

    // ユーザー情報のロールにcoachがあるかチェック
    return hasCoach();
  }, [user.role, hasCoach]);

  // student系ロールがあるかをチェック
  const permissionCheckToStudent = useCallback((): boolean => {
    // 現在のロールがstudent系かチェック
    if (UserRoleStudents.includes(user.role)) return false;

    // ユーザー情報のロールにstudent系があるかチェック
    return hasStudent();
  }, [user.role, hasStudent]);

  React.useEffect(() => {
    if (!user.lmsUser) return;

    TagManager.dataLayer({
      dataLayer: {
        userId: user.lmsUser.id,
        isInstructor: user.lmsUser.isInstructor,
        isStudent: user.lmsUser.isStudent,
        pageView: {
          snsType: user.lmsUser.snsType,
          status: user.lmsUser.status,
          currentContract: user.contractsOfStripeSubscriptionForAnalytics,
          name: user.lmsUser.personalInfo?.name,
          email: user.lmsUser.personalInfo?.email,
          sex: user.lmsUser.personalInfo?.sex,
          createdDate: user.lmsUser.createdAt,
        },
      },
      dataLayerName: 'LMSDataLayer',
    });
  }, [user.lmsUser, user.contractsOfStripeSubscriptionForAnalytics]);

  useEffect(() => {
    // ソーシャルログイン時のパラメーター引き回し対応
    // TODO: Hubのイベントがステージングで発火しない問題の原因が分かったらHubを使う方を戻す
    // @see https://docs.amplify.aws/lib/auth/social/q/platform/js/#setup-frontend
    // Hub.listen('auth', ({ payload: { event, data } }) => {
    //   if (event === 'customOAuthState') {
    //     dispatch(setSocialLoginCallbackURL({ callbackURL: data }));
    //   }
    // });
    try {
      const socialLoginCallbackURL = sessionStorageSupport.getItem('SOCIAL_LOGIN_CALLBACK_URL');
      if (socialLoginCallbackURL) {
        sessionStorageSupport.removeItem('SOCIAL_LOGIN_CALLBACK_URL');
        dispatch(setSocialLoginCallbackURL({ callbackURL: socialLoginCallbackURL }));
      }
    } catch {
      // sessionStorageが使えない場合は何もしない
    }
  }, [dispatch]);

  return {
    user,
    team,
    login,
    socialLogin,
    callbackOauthLogin,
    logout,
    changeRole,
    changeRoleStudent,
    changeTeamID,
    removeTeamID,
    refresh,
    isLoadingUser,
    isSocialLoginUser,
    hasCoaching,
    permissionCheck,
    permissionCheckToInstructor,
    permissionCheckToCoach,
    permissionCheckToStudent,
    forgotPassword,
    fetchCurrentUserWithPermissions,
    isRoleConfirmed,
  };
};
