import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';
import { DealTable } from '../../molecules/DealTable';

export const LpDeal = (): JSX.Element => {
  return (
    <Deal id="deal">
      <DealInner>
        <Ttl>
          <h2>他スクールとの比較</h2>
          <p>Best Deal</p>
        </Ttl>
        <TableWrapper>
          <DealTable />
        </TableWrapper>
        <Note>
          ※対応可能言語の詳細は
          <a
            href="https://intercom.help/plus---samurai/ja/articles/5882187-q-%E5%AF%BE%E5%BF%9C%E3%81%97%E3%81%A6%E3%81%84%E3%82%8B%E8%A8%80%E8%AA%9E%E3%82%84%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%AF%E3%83%BC%E3%82%AF%E7%AD%89%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84?fil=%7B%7D&taf=4SzH0t9DTJSKcQ2MJZFW936M.aHR0cHM6Ly93d3cuZW5naW5lZXItY2FyZWVyLm5ldC8.TW96aWxsYS81LjAgKE1hY2ludG9zaDsgSW50ZWwgTWFjIE9TIFggMTBfMTVfNykgQXBwbGVXZWJLaXQvNTM3LjM2IChLSFRNTCwgbGlrZSBHZWNrbykgQ2hyb21lLzk1LjAuNDYzOC42OSBTYWZhcmkvNTM3LjM2.tp20212104b3f9e9fa6bb4_NjIz"
            target="_blank"
          >
            こちら
          </a>
          。
        </Note>
      </DealInner>
    </Deal>
  );
};

const Deal = styled.section`
  padding: 6.25rem 0;
  scroll-margin-top: 49px;

  ${media.lessThan('large')`
    padding-bottom: 4.25rem;
  `}
  ${media.lessThan('medium')`
    padding: 4rem 0 2rem;
    scroll-margin-top: 57px;
  `}
`;
const DealInner = styled(Inner)`
  ${media.lessThan('large')`
    padding: 0;
  `}
`;
const TableWrapper = styled.div`
  ${media.lessThan('large')`
    margin-top: 3rem;
    padding: 2rem;
    overflow-x: auto;
  `}
  ${media.lessThan('medium')`
    margin-top: 0.5rem;
    padding: 2rem 1rem;
  `}
`;
const Note = styled.p`
  margin-top: 2rem;
  font-size: 0.875rem;
  font-weight: 700;

  a {
    color: #eb0000;
    font-size: 1em;
    text-decoration: underline;
  }

  ${media.lessThan('large')`
    padding: 0 2rem;
  `}

  ${media.lessThan('medium')`
    margin-top: 0;
    padding: 0 1rem;
  `}
`;
