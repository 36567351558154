import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface BoxProps {
  article?: boolean;
  section?: boolean;
  padding?: number[];
  spPadding?: number[];
  radius?: string;
  onClick?: () => void;
  className?: string;
}

export const Box: React.FC<BoxProps> = ({
  article,
  section,
  padding,
  spPadding,
  radius,
  onClick,
  className,
  children,
}) => {
  const paddingString = React.useMemo(() => {
    if (padding && padding.length > 0) {
      const paddingArray = padding.map((p) => {
        return `${p * 4}px`;
      });

      return paddingArray.join(' ');
    }

    return '0';
  }, [padding]);

  const spPaddingString = React.useMemo(() => {
    if (spPadding && spPadding.length > 0) {
      const paddingArray = spPadding.map((p) => {
        return `${p * 4}px`;
      });

      return paddingArray.join(' ');
    }

    return '';
  }, [spPadding]);

  return article ? (
    <Container
      as="article"
      className={className}
      padding={paddingString}
      spPadding={spPaddingString}
      radius={radius}
      onClick={onClick}
    >
      {children}
    </Container>
  ) : section ? (
    <Container
      as="section"
      className={className}
      padding={paddingString}
      spPadding={spPaddingString}
      radius={radius}
      onClick={onClick}
    >
      {children}
    </Container>
  ) : (
    <Container
      className={className}
      padding={paddingString}
      spPadding={spPaddingString}
      radius={radius}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<{
  padding?: string;
  spPadding?: string;
  radius?: string;
  onClick?: () => void;
}>`
  padding: ${(props) => props.padding};
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: ${(props) => (props.radius === 'large' ? '0.375' : '0.125')}rem;
  box-sizing: border-box;

  ${(props) =>
    !!props.onClick &&
    `
    cursor: pointer;
  `}

  ${(props) =>
    props.spPadding
      ? media.lessThan('medium')`
    padding: ${props.spPadding};
  `
      : ''}
`;
