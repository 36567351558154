import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';

import MediaImage001 from '../../../static/image/lp/media001.png';
import MediaImage002 from '../../../static/image/lp/media002.png';
import MediaImage003 from '../../../static/image/lp/media003.png';
import MediaImage004 from '../../../static/image/lp/media004.png';
import MediaImage005 from '../../../static/image/lp/media005.png';
import MediaImage006 from '../../../static/image/lp/media006.png';
import MediaImage007 from '../../../static/image/lp/media007.png';
import MediaImage008 from '../../../static/image/lp/media008.png';
import MediaImage009 from '../../../static/image/lp/media009.png';
import MediaImage010 from '../../../static/image/lp/media010.png';

interface Props {
  adlp2?: boolean;
}

export const LpMedia: React.FC<Props> = (props) => {
  return (
    <Media data-gtm="media">
      <Inner>
        {!props.adlp2 ? (
          <>
            <Ttl>
              <h2>メディア掲載実績</h2>
              <p>Media</p>
            </Ttl>
            <Description>大手含む様々なメディアに掲載されています</Description>
          </>
        ) : (
          <Adlp2Title>大手含む様々なメディアに掲載されています</Adlp2Title>
        )}
        <List>
          <Item>
            <img src={MediaImage001} alt="WBS WORLD BUSINESS SATELLITE" />
          </Item>
          <Item>
            <img src={MediaImage002} alt="週刊ダイヤモンド" />
          </Item>
          <Item>
            <img src={MediaImage003} alt="BUSINESS INSIDER JAPAN" />
          </Item>
          <Item>
            <img src={MediaImage004} alt="Yahoo! JAPAN ニュース" />
          </Item>
          <Item>
            <img src={MediaImage005} alt="NEWS PICKS" />
          </Item>
          <Item>
            <img src={MediaImage006} alt="JIJI.COM" />
          </Item>
          <Item>
            <img src={MediaImage007} alt="@niftyビジネス" />
          </Item>
          <Item>
            <img src={MediaImage008} alt="ORICON NEWS" />
          </Item>
          <Item>
            <img src={MediaImage009} alt="U-NOTE" />
          </Item>
          <Item>
            <img src={MediaImage010} alt="@DIME" />
          </Item>
        </List>
      </Inner>
    </Media>
  );
};

const Media = styled.section`
  margin: 7.5rem 0;

  ${media.lessThan('medium')`
    margin: 4rem 0;
  `}
`;
const Description = styled.p`
  margin: 3rem auto 2.5rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.18;
  text-align: center;

  ${media.lessThan('medium')`
    margin: 2rem auto 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
  `}
`;
const Adlp2Title = styled.h2`
  margin-bottom: 2.5rem;
  color: #333;
  font-size: 2rem;
  font-weight: 900;
  line-height: 3rem;
  letter-spacing: 0.06em;
  text-align: center;

  br {
    display: none;
  }

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;

    br {
      display: block;
    }
  `}
`;
const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 1040px;
  margin: 0 auto;
`;
const Item = styled.li`
  width: 20%;
  margin-top: 0.75rem;
  padding: 0 0.75rem;
  box-sizing: border-box;

  img {
    width: 100%;
    max-width: 210px;
  }

  ${media.lessThan('large')`
    width: 25%;
  `}

  ${media.lessThan('medium')`
    padding: 0 0.25rem;
  `}

  ${media.lessThan('small')`
    width: 50%;
    padding: 0 0.5rem;
  `}
`;
