import React from 'react';
import { useNavigate } from 'react-router-dom';
import media from 'styled-media-query';
import styled, { keyframes } from 'styled-components';
import { useUser } from '../../redux/user/useUser';

import { Button } from '../atoms/Button';

import TimeIcon from '../../static/image/icon_time_white.svg';
import BronzeMedal from '../../static/image/medal_bronze.svg';
import SilverMedal from '../../static/image/medal_silver.svg';
import GoldMedal from '../../static/image/medal_gold.svg';

import { ProgramDetailFragment as Program, ExamMedalType } from '../../gen/graphql';
import { progress } from '../../utils/Progress';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { getChapters } from '../../utils/Program';
import { minutesToHoursSecondDecimalPlace } from '../../utils/common';

interface EyecatchProps {
  program: Program;
  pageStatus?: string;
}

export const ProgramEyecatch: React.FC<EyecatchProps> = (props) => {
  const { permissionCheck } = useUser();
  const navigate = useNavigate();

  const chapters = getChapters(props.program);

  const medals = chapters?.reduce(
    (acc, cur) => {
      const maxScore = cur?.examScores?.reduce((a, c) => (a.score > c.score ? a : c));
      switch (maxScore?.medalType) {
        case ExamMedalType.Gold:
          return { ...acc, gold: acc.gold + 1 };
        case ExamMedalType.Shilver:
          return { ...acc, silver: acc.silver + 1 };
        case ExamMedalType.Bronze:
          return { ...acc, bronze: acc.bronze + 1 };
        default:
          return acc;
      }
    },
    { gold: 0, silver: 0, bronze: 0 },
  );

  return (
    <Eyecatch>
      <EyecatchContent>
        <EyecatchInfo>
          <h2>{props.program.title}</h2>
          <Description>{props.program.description}</Description>
          <Medals>
            <div className="medal">
              <img src={GoldMedal} alt="金メダル" />
              <p>{medals ? medals.gold : 0}</p>
            </div>
            <div className="medal">
              <img src={SilverMedal} alt="銀メダル" />
              <p>{medals ? medals.silver : 0}</p>
            </div>
            <div className="medal">
              <img src={BronzeMedal} alt="銅メダル" />
              <p>{medals ? medals.bronze : 0}</p>
            </div>
          </Medals>
          <LinkArea>
            {props.pageStatus === 'overview' ? (
              <LinkButton onClick={() => navigate(`/programs/${props.program.id}/chapters`)} border>
                章一覧を確認する
              </LinkButton>
            ) : (
              <LinkButton onClick={() => navigate(`/programs/${props.program.id}/overview`)} border>
                概要を確認する
              </LinkButton>
            )}
            {!!props.program.programSample?.url && (
              <LinkButton onClick={() => window.open(props.program.programSample?.url)} border>
                サンプルを見る
              </LinkButton>
            )}
          </LinkArea>
        </EyecatchInfo>
        <ProgramIcon>
          <img src={props.program.thumbnail} alt={props.program.title} />
          <Nums>
            <Num>
              <Icon src={TimeIcon} alt="所要時間" />
              <p>
                <span>{minutesToHoursSecondDecimalPlace(props.program.requireTime)}</span>時間
              </p>
            </Num>
          </Nums>
          {permissionCheck(FunctionType.StudyLog, PermissionType.Read) && (
            <Progress>
              <ProgressBar start={props.program.completedTime} end={props.program.requireTime}>
                <div></div>
              </ProgressBar>
              <ProgressInfo>
                {progress(props.program.completedTime, props.program.requireTime)}% (
                {minutesToHoursSecondDecimalPlace(props.program.completedTime)}/
                {minutesToHoursSecondDecimalPlace(props.program.requireTime)})
              </ProgressInfo>
            </Progress>
          )}
        </ProgramIcon>
      </EyecatchContent>
    </Eyecatch>
  );
};

const Eyecatch = styled.section`
  position: relative;
  width: 100%;
  min-height: 320px;
  padding: 4.75rem 2rem 2.5rem;
  background-color: #2e364e;
  box-sizing: border-box;
  color: #fff;

  ${media.lessThan('medium')`
    min-height: 0;
    padding: 1rem 1rem 1.5rem;
  `}
`;
const EyecatchContent = styled.div`
  display: flex;
  max-width: 1192px;
  margin: 0 auto;

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
  `}
`;
const EyecatchInfo = styled.div`
  flex: 1;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.375;
    margin-bottom: 1rem;
  }

  ${media.lessThan('medium')`
    h2 {
      font-size: 1.25rem;
      line-height: 1.5;
      margin-bottom: 1.5rem;
    }
  `}
`;
const Description = styled.p`
  font-size: 1.0625rem;
  line-height: 1.8;

  ${media.lessThan('medium')`
    font-size: 0.75rem;
  `}
`;
const LinkArea = styled.div`
  display: flex;
  gap: 1rem 1.5rem;
  margin-top: 2.5rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;
    margin: 1.25rem auto 0;
  `}
`;
const LinkButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 240px;
  padding-left: 1rem;
  padding-right: 1rem;

  ${media.lessThan('medium')`
    max-width: 360px;
  `}
`;
const Nums = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.25rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
const Num = styled.div`
  display: flex;
  align-items: center;

  &:nth-of-type(n + 2) {
    margin-left: 0.625rem;
  }

  p {
    font-size: 0.875rem;

    span {
      font-size: 1.125rem;
      font-weight: 700;
    }
  }

  ${media.lessThan('medium')`
    &:nth-of-type(n + 2) {
      margin-left: 1rem;
    }

    p {
      font-size: 0.75rem;

      span {
        font-size: 1rem;
      }
    }
  `}
`;
const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 0.375rem;

  ${media.lessThan('medium')`
    width: 18px;
    height: 18px;
  `}
`;

const ProgramIcon = styled.div`
  width: 32%;
  max-width: 340px;
  margin-left: 4rem;
  img {
    max-width: 100%;
  }

  ${media.lessThan('medium')`
    width: 100%;
    max-width: none;
    margin: 0 auto 1rem;
  `}
`;

const barAnimation = (start: number, end: number) => {
  return keyframes`
    0% {
      width: 0;
    }

    100% {
      width: ${progress(start, end)}%;
    }
  `;
};

const Progress = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  ${media.lessThan('medium')`
    margin-top: .75rem;
  `}
`;

const ProgressBar = styled.div<{ start: number; end: number }>`
  flex: 1;
  display: inline-block;
  height: 0.3rem;
  border-radius: 1rem;
  background-color: #f0f0f0;
  div {
    height: 100%;
    width: 0;
    background: linear-gradient(to right, #fe5e27, #e2001b);
    animation: ${(props) => barAnimation(props.start, props.end)} 0.5s ease-out 1 forwards;
  }

  ${media.lessThan('medium')`
    height: 0.3rem;
  `}
`;

const ProgressInfo = styled.div`
  margin-left: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const Medals = styled.div`
  display: flex;
  margin-top: 35px;

  .medal {
    display: flex;
    align-items: center;

    & + .medal {
      margin-left: 8px;
    }

    img {
      height: 22px;
      width: auto;
    }

    p {
      font-size: 1.125rem;
      font-weight: bold;
      color: #ffffff;
      margin-left: 4px;
    }
  }

  ${media.lessThan('medium')`
    margin-top: 24px;
    
    .medal {
      & + .medal {
        margin-left: 12px;
      }

      p {
        font-size: 0.875rem;
      }
    }
  `}
`;
