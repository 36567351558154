import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import greyCheckIcon from '../../static/image/icon_grey_check.svg';
import bookIcon from '../../static/image/icon_book.svg';
import circleIcon from '../../static/image/icon_circle_white.svg';

import { StudyLogUnderstandingLevel } from '../../gen/graphql';

interface Props {
  studyLevel?: StudyLogUnderstandingLevel;
}

export const StudyLevelLabel: React.FC<Props> = (props) => {
  return !props.studyLevel ? (
    <Button className="unread">
      <img src={bookIcon} />
      未読
    </Button>
  ) : props.studyLevel === StudyLogUnderstandingLevel.High ? (
    <Button className="understood">
      <img src={circleIcon} />
      理解
    </Button>
  ) : (
    <Button className="read">
      <img src={greyCheckIcon} />
      読了
    </Button>
  );
};

const Button = styled.p`
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6875rem;
  padding: 0.5rem 0.875rem;
  border-radius: 0.1875rem;

  img {
    display: block;
    margin-right: 0.625rem;
  }

  &.read {
    background: #e95252;
  }

  &.understood {
    background: #4bb89e;
  }

  &.unread {
    background: #575e71;
  }

  ${media.lessThan('medium')`
    padding: 0.5rem 1.375rem;
  `}
`;
