import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../../redux/user/useUser';
import queryString from 'query-string';
import styled from 'styled-components';
import media from 'styled-media-query';

import { TabNavigationLayout } from '../../templates/TabNavigationLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { CoursePrograms } from './CourseProgramList';
import { Programs } from './ProgramList';
import { ProgramCategoryList } from '../../organisms/ProgramCategoryList';
import { ProgramCategoryModal } from '../../organisms/ProgramCategoryModal';
import { Mv } from '../../organisms/Mv';
import { H3, H4 } from '../../atoms/Typography';

import MvImage from '../../../static/image/mv/program_sample.svg';

import { useGetTagQuery, useGetTagsQuery, TagType } from '../../../gen/graphql';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { PROGRAM_COUNT } from '../../../const/Program';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import ForbiddenPage from '../public/Forbidden';

interface ProgramState {
  lp: boolean;
}

export const Program = (): JSX.Element => {
  const paramCategoryID = useParams<{ category_id: string }>().category_id;
  const pathCategoryID = paramCategoryID ? Number(paramCategoryID) : 0;

  const location = useLocation();
  const locationState = location.state as ProgramState;

  const { permissionCheck } = useUser();
  const navigate = useNavigate();

  const [categoryModalOpen, setCategoryModalOpen] = React.useState(false);

  const { data, loading: tagLoading } = useGetTagQuery({
    variables: {
      id: pathCategoryID,
    },
    skip: !pathCategoryID,
  });
  const tag = data?.getTag;

  const query = queryString.parse(location.search);
  if (!query.page) query.page = '1';

  const metaTitle = useMemo(() => {
    return tag ? `${tag.name}の教材一覧 | ${LOWER_META_TITLE}` : `教材 | ${LOWER_META_TITLE}`;
  }, [tag]);
  const metaDescription = useMemo(() => {
    return tag
      ? `${SERVICE_NAME}の${tag.name}の教材一覧ページです。${tag.overview}`
      : `${SERVICE_NAME}のプログラミング学習教材ページです。ITリテラシーからフロントエンド、バックエンド、インフラまで全${PROGRAM_COUNT}種類以上の教材をご用意。入門者や初心者にも優しい基礎教材はもちろん実務レベルのエンジニアになるための中級者向け教材も。`;
  }, [tag]);

  useEffect(() => {
    if (locationState?.lp) {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCategoryModal = () => {
    setCategoryModalOpen(true);
  };

  const closeCategoryModal = () => {
    setCategoryModalOpen(false);
  };

  const linkMv = () => {
    navigate('/programs/sample');
  };

  const { data: tagData } = useGetTagsQuery({
    variables: {
      input: {
        type: TagType.Program,
      },
    },
  });
  const programCategories =
    tagData?.getTags.items?.map((item) => ({
      id: item.id,
      name: item.name,
      description: item.overview ?? undefined,
    })) ?? [];

  const mvData = {
    title: '教材で「つくれる人」になろう',
    description: `実践型の教材が${PROGRAM_COUNT}種類以上。教材を通して制作するサンプルサイトやアプリケーションは転職の面接時や案件獲得の際のポートフォリオとしてもお使いいただけます。`,
    bg: '#BED9EE',
    image: MvImage,
    buttonText: '教材で作れるサンプル一覧を見る',
    onClick: linkMv,
  };

  const tabs = useMemo(() => {
    const tabs = [];

    permissionCheck(FunctionType.ProgramForInstructorAndCoach, PermissionType.Read)
      ? tabs.push(
          { label: '学習用教材', to: '/programs?type=1', active: query.type === '1' },
          {
            label: 'インストラクター向けマニュアル',
            to: '/programs?type=2',
            active: query.type === '2',
          },
        )
      : tabs.push(
          { label: '教材TOP', to: '/programs', active: location.pathname === '/programs' },
          {
            label: '学習中の教材',
            to: '/programs_while_studying',
            active: location.pathname === '/programs_while_studying',
          },
          {
            label: '完了した教材',
            to: '/programs_completed_study',
            active: location.pathname === '/programs_completed_study',
          },
        );

    return tabs;
  }, [permissionCheck, location.pathname, query.type]);

  // 権限（UserRole）によりタグが取得できない場合は403へ
  if (pathCategoryID && !tag && !tagLoading) return ForbiddenPage;

  return (
    <>
      <TabNavigationLayout
        pageTitle="教材"
        tabs={tabs}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <PageWrapper>
          <StyledMv mv={mvData} />
          {query.type !== '2' && (
            <ProgramSection>
              {!pathCategoryID ? (
                <>
                  <StyledH3 data-e2e="course" color="DARK">
                    コース教材
                  </StyledH3>
                  <Text data-e2e="courseExp">
                    コース教材はウェブスキルを身につけモノづくりができる人になるために設計された学習工程の道すじです。
                  </Text>
                  <CoursePrograms />
                </>
              ) : (
                <>
                  <StyledH3 color="DARK">「{tag?.name}」の教材一覧</StyledH3>
                  <Text>{tag?.overview ?? ''}</Text>
                </>
              )}
            </ProgramSection>
          )}
          <Flex>
            <ProgramSection>
              {!pathCategoryID ? (
                query.type === '2' ? (
                  <>
                    <StyledH3 color="DARK">インストラクター向けマニュアル</StyledH3>
                    <Text>インストラクションに関するマニュアルです。</Text>
                  </>
                ) : (
                  <>
                    <StyledH3 color="DARK">各種教材</StyledH3>
                    <Text data-e2e="programExp">
                      {PROGRAM_COUNT}種類以上の学習コンテンツでプロダクト開発を学ぶことができます。
                    </Text>
                  </>
                )
              ) : (
                <StyledH3 color="DARK">「{tag?.name}」を学べる教材</StyledH3>
              )}

              {query.type !== '2' && (
                <SpCategory>
                  <StyledH4 color="DARK">カテゴリーで探す</StyledH4>
                  <SpProgramCategoryList
                    categories={programCategories}
                    searchCategory={pathCategoryID}
                    to="/programs/category"
                  />
                  <CategoryLink>
                    <span onClick={openCategoryModal}>
                      カテゴリーの一覧
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.00006 4.76843C6.00254 4.83071 6.03732 4.8902 6.09818 4.93388L12.2324 9.52484L6.09818 14.1158C6.03608 14.1604 6.00254 14.2218 6.0013 14.2859C6.00254 14.3835 6.08079 14.47 6.20002 14.5071C6.3205 14.5434 6.45712 14.522 6.54778 14.4523L12.9068 9.69309C13.0311 9.60013 13.0311 9.44955 12.9068 9.3566L6.54778 4.59739C6.45712 4.52675 6.31677 4.50537 6.19629 4.54255C6.07582 4.58066 5.99757 4.6699 6.00006 4.76843Z"
                          fill="black"
                          fillOpacity="0.36"
                          stroke="#EB0000"
                        />
                      </svg>
                    </span>
                  </CategoryLink>
                </SpCategory>
              )}
              <Programs type={query.type ? query.type.toString() : ''} category={pathCategoryID} />
            </ProgramSection>
            {query.type !== '2' && (
              <PcCategorySection data-e2e="category">
                <StyledH3 color="DARK">カテゴリーで探す</StyledH3>
                <ProgramCategoryList
                  categories={programCategories}
                  searchCategory={pathCategoryID}
                  to="/programs/category"
                />
              </PcCategorySection>
            )}
          </Flex>
        </PageWrapper>
      </TabNavigationLayout>

      <ProgramCategoryModal isOpen={categoryModalOpen} closeModal={closeCategoryModal}>
        <ProgramCategoryList
          categories={programCategories}
          searchCategory={pathCategoryID}
          closeModal={closeCategoryModal}
          to="/programs/category"
        />
      </ProgramCategoryModal>
    </>
  );
};

const StyledMv = styled(Mv)`
  margin-bottom: 3rem;

  ${media.lessThan('medium')`
    width: calc(100% + 2rem);
    margin: -1.5rem -1rem 1.5rem;
  `}
`;

const ProgramSection = styled.section`
  flex: 1;
  margin-bottom: 3rem;
`;

const Flex = styled.div`
  display: flex;
`;

const PcCategorySection = styled.section`
  width: 284px;
  margin-left: 1.25rem;

  ${media.lessThan('large')`
    width: auto;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const SpCategory = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    margin: .875rem auto 1rem;
  `}
`;
const SpProgramCategoryList = styled(ProgramCategoryList)`
  display: flex;
  flex-wrap: wrap;

  li {
    &:nth-of-type(n + 2) {
      margin-top: 0;
    }

    &:nth-of-type(n + 7) {
      display: none;
    }
  }
`;
const CategoryLink = styled.p`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;

  span {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #eb0000;
    font-size: 0.875rem;
    line-height: 1.5rem;

    svg {
      display: block;
    }
  }
`;

const StyledH3 = styled(H3)`
  font-size: 1.25rem;
`;
const StyledH4 = styled(H4)`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.25rem;
`;
const Text = styled.p`
  margin: 0.5rem auto 2rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.25rem;

  ${media.lessThan('medium')`
    margin-bottom: 1rem;
  `}
`;
