import React, { useContext, useRef } from 'react';
import styled from 'styled-components';

import media, { generateMedia } from 'styled-media-query';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { useUser } from '../../redux/user/useUser';
import { MenuContext } from '../../context/MenuProvider';
import { HeadTagContext } from '../../context/HeadTagProvider';

import { ProgramEyecatch } from '../molecules/ProgramEyecatch';
import { Navigation } from '../organisms/Navigation';
import { AppBar } from '../organisms/AppBar';
import { TabNavigation, TabProps } from '../organisms/TabNavigation';
import { CommonModals } from '../organisms/CommonModals';
import { Footer } from '../organisms/Footer';
import { CampaignSnackbar } from '../organisms/CampaignSnackbar';

import { ProgramDetailFragment as Program } from '../../gen/graphql';
import { Z_INDEX_APPBAR, Z_INDEX_DRAWER } from '../../const/ZIndex';
import { useMetaData } from '../../common/customHooks/MetaReplace';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface TabNavigationLayoutProps {
  pageTitle: string;
  tabs:
    | TabProps[]
    | ((
        isSocialLoginUser: boolean,
        permissionCheck: (functionType: string, permissionType: string) => boolean,
        pathname: string,
      ) => TabProps[]);

  /**
   * Intercom表示対象画面か。
   * (表示対象画面の場合は、表示対象ロールの場合にIntercomを表示。)
   */
  hasIntercom?: boolean;

  hideFooter?: boolean;
  unfixed?: boolean;
  programs?: Program;
  activePage?: string;
  metaTitle: string;
  metaDescription?: string;
}

export const TabNavigationLayout: React.FC<TabNavigationLayoutProps> = (props): JSX.Element => {
  const location = useLocation();
  const { isOpen } = useContext(MenuContext);
  const { user, logout, isSocialLoginUser, permissionCheck } = useUser();
  const headTagContext = useContext(HeadTagContext);
  const hamburgerRef = useRef<HTMLDivElement>(null);

  useMetaData(props.metaTitle, props.metaDescription);

  return (
    <Wrapper>
      <Helmet title={headTagContext.title} meta={headTagContext.meta} />
      <Container $isOpen={isOpen}>
        <Navigation pageTitle={props.pageTitle} naviRef={hamburgerRef} />
        <Div $isMenuOpen={isOpen}>
          <AppBar
            pageTitle={props.pageTitle}
            handleLogout={logout}
            logoutTransition={'/'}
            hasIntercom={props.hasIntercom}
            appBarRef={hamburgerRef}
            unfixed={props.unfixed}
          />
          {!!props.programs && (
            <ProgramEyecatch program={props.programs} pageStatus={props.activePage} />
          )}

          <NavigationBar data-e2e="tabNavigation" unfixed={props.unfixed}>
            <TabNavigation
              tabs={
                typeof props.tabs === 'function'
                  ? props.tabs(isSocialLoginUser(), permissionCheck, location.pathname)
                  : props.tabs
              }
            />
          </NavigationBar>

          {permissionCheck(FunctionType.AdsForFree, PermissionType.Read) &&
            user.lmsUser?.createdAt && (
              <CampaignSnackbar
                userCreatedAt={new Date(user.lmsUser.createdAt)}
                isTabNavigation
                isNavigationUnfixed={props.unfixed}
              />
            )}

          <Main>{props.children}</Main>
          {props.hideFooter ?? <Footer />}
        </Div>
      </Container>
      <CommonModals />
    </Wrapper>
  );
};

export default TabNavigationLayout;

const customMedia = generateMedia({
  minMedium: '769px',
});

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Container = styled.div<{ $isOpen: boolean }>`
  position: relative;
  display: flex;
  height: 100%;

  ${customMedia.greaterThan('minMedium')`
    width: 100%;
  `}

  ${media.lessThan('medium')`
    width: 100%;
    transition: transform 0.2s ease-out;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
      position: fixed;
      top: 0;
      left: 0;
      pointer-events: none;
      opacity: 0;
      transition: all .1s;
      z-index: ${Z_INDEX_DRAWER - 1};
    }
  `}

  ${(props): string => {
    return props.$isOpen
      ? media.lessThan('medium')`
        &:before {
          pointer-events: all;
          opacity: 1;
        }
      `
      : '';
  }}
`;

const Div = styled.div<{ $isMenuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  padding-left: ${(props) => (props.$isMenuOpen ? '168' : '68')}px;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding-left: 0;
  `}
`;

const NavigationBar = styled.div<{ unfixed?: boolean }>`
  display: flex;
  background: #fff;
  border-bottom: 1px solid #dddddd;
  transition: transform 0.1s ease-out 0.1s;
  z-index: ${Z_INDEX_APPBAR};

  ${(props) =>
    props.unfixed
      ? `
      position: relative;
    `
      : `
      position: sticky;
      top: 66px;
    `}
`;

const Main = styled.main`
  transition: transform 0.1s ease-out 0.1s;
`;
