import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  CurrentUserFragment as User,
  CurrentPermissionListFragment as PermissionList,
  MyTeamMemberFragment,
  UserRole,
} from '../../gen/graphql';

export const UserActionTypes = {
  login: 'USER_LOGIN' as const,
  loginByTemporaryPassword: 'USER_LOGIN_BY_TEMPORARY_PASSWORD' as const,
  logout: 'USER_LOGOUT' as const,
  changeRole: 'USER_CHANGE_ROLE' as const,
  setTeams: 'USER_SET_TEAMS' as const,
  setTeamID: 'USER_SET_TEAM_ID' as const,
  loginError: 'USER_LOGIN_ERROR' as const,
  setLMSUser: 'USER_SET_LMS_USER' as const,
  setIsChangePassword: 'USER_SET_IS_CHANGE_PASSWORD' as const,
  setSocialLoginCallbackURL: 'USER_SET_SOCIAL_LOGIN_CALLBACK_URL' as const,
};

interface LoginPayload {
  lmsUser: User | null;
  cognitoUser: CognitoUser;
  permissionList: PermissionList | null;
  role: UserRole;
  isChangePassword: boolean;
}

const login = (payload: LoginPayload) => ({
  type: UserActionTypes.login,
  payload,
});

interface LoginByTemporaryPasswordPayload {
  cognitoUser: CognitoUser;
}

// NOTE: ReduxのActionでReturn Typeを指定するのは冗長すぎる気もするので、
// このファイル内ではeslintのexplicit-module-boundary-typesをdisableにしてしまってもいいかも
export const loginByTemporaryPassword = (
  payload: LoginByTemporaryPasswordPayload,
): {
  type: typeof UserActionTypes.loginByTemporaryPassword;
  payload: LoginByTemporaryPasswordPayload;
} => ({
  type: UserActionTypes.loginByTemporaryPassword,
  payload,
});

const logout = () => ({
  type: UserActionTypes.logout,
});

interface ChangeRolePayload {
  role: UserRole;
}

const changeRole = (payload: ChangeRolePayload) => ({
  type: UserActionTypes.changeRole,
  payload,
});

interface SetTeamsPayload {
  teamID: string;
  teams: MyTeamMemberFragment[];
}

const setTeams = (payload: SetTeamsPayload) => ({
  type: UserActionTypes.setTeams,
  payload,
});

interface ChangeTeamIDPayload {
  teamID: string | null;
}

const setTeamID = (payload: ChangeTeamIDPayload) => ({
  type: UserActionTypes.setTeamID,
  payload,
});

interface LoginErrorPayload {
  error: string;
}

const loginError = (payload: LoginErrorPayload) => ({
  type: UserActionTypes.loginError,
  payload,
});

interface SetLMSUserPayload {
  lmsUser: User;
  permissionList: PermissionList;
  role: UserRole;
}

const setLMSUser = (payload: SetLMSUserPayload) => ({
  type: UserActionTypes.setLMSUser,
  payload,
});

interface SetIsChangePasswordPayload {
  isChangePassword: boolean;
}

const setIsChangePassword = (payload: SetIsChangePasswordPayload) => ({
  type: UserActionTypes.setIsChangePassword,
  payload,
});

interface SetSocialLoginCallbackURLPayload {
  callbackURL: string;
}

// NOTE: ReduxのActionでReturn Typeを指定するのは冗長すぎる気もするので、
// このファイル内ではeslintのexplicit-module-boundary-typesをdisableにしてしまってもいいかも
export const setSocialLoginCallbackURL = (
  payload: SetSocialLoginCallbackURLPayload,
): {
  type: typeof UserActionTypes.setSocialLoginCallbackURL;
  payload: SetSocialLoginCallbackURLPayload;
} => ({
  type: UserActionTypes.setSocialLoginCallbackURL,
  payload,
});

export type UserActions = ReturnType<
  | typeof login
  | typeof loginByTemporaryPassword
  | typeof logout
  | typeof changeRole
  | typeof setTeams
  | typeof setTeamID
  | typeof loginError
  | typeof setLMSUser
  | typeof setIsChangePassword
  | typeof setSocialLoginCallbackURL
>;
