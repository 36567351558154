import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';

import FooterLogo from '../../../static/image/lp/footer_logo.png';
import IconFacebook from '../../../static/image/lp/footer_facebook.png';
import IconTwitter from '../../../static/image/lp/footer_twitter.png';
import IconYoutube from '../../../static/image/lp/footer_youtube.png';

export const LpFooter = (): JSX.Element => {
  const teamLink = React.useMemo(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      return 'https://lp.sejuku.net/terakoya/biz/?cid=ss_header_to_terakoyabiz';
    } else {
      return `${process.env.REACT_APP_DOMAIN}/team/register/creation`;
    }
  }, []);

  return (
    <Footer data-gtm="footer">
      <FooterInner>
        <LogoArea>
          <img src={FooterLogo} alt="株式会社SAMURAI" />
          <div className="sns">
            <a
              href="https://www.facebook.com/sejuku2013/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconFacebook} alt="Facebook" />
            </a>
            <a href="https://twitter.com/samuraijuku" target="_blank" rel="noopener noreferrer">
              <img src={IconTwitter} alt="Twitter" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCCFOQO5aDK0xXam4cUQXT8g"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconYoutube} alt="Youtube" />
            </a>
          </div>
        </LogoArea>
        <FooterNav>
          <div className="menu">
            <h3>サービス</h3>
            <ul>
              <li>
                <a href="https://sejuku.net/?cid=sns_lp_tolp" target="_blank" className="en">
                  SAMURAI ENGINEER
                </a>
              </li>
              <li>
                <a href="https://sejuku.net/courses/?cid=sns_lp_topro" className="en">
                  SAMURAI ENGINEER Pro
                </a>
              </li>
              <li>
                <a
                  href="https://www.sejuku.net/blog/?cid=sns_lp_toblog"
                  target="_blank"
                  className="en"
                >
                  SAMURAI ENGINEER Blog
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCCFOQO5aDK0xXam4cUQXT8g"
                  target="_blank"
                  className="en"
                >
                  SAMURAI ENGINEER YouTube
                </a>
              </li>
            </ul>
          </div>
          <div className="menu">
            <h3>法人サービス</h3>
            <ul>
              <li>
                <a href="https://sejuku.net/biz/?cid=terakoya_lp_tobiz" target="_blank">
                  法人研修サービス
                </a>
              </li>
              <li>
                <a href="https://recruitment.sejuku.net/?cid=terakoya_lp_tocareer" target="_blank">
                  IT人材紹介サービス
                </a>
              </li>
              <li>
                <a href={teamLink} target="_blank">
                  侍テラコヤ法人プラン
                </a>
              </li>
            </ul>
          </div>
          <div className="menu">
            <h3>コーポレート</h3>
            <ul>
              <li>
                <a href="https://www.sejuku.net/corp/?cid=sns_lp_tocorp" target="_blank">
                  運営会社
                </a>
              </li>
              <li>
                <a href="https://www.sejuku.net/corp/recruit?cid=sns_lp_torecruit" target="_blank">
                  採用情報
                </a>
              </li>
              <li>
                <a href="https://www.sejuku.net/corp/contact?cid=sns_lp_tocontact" target="_blank">
                  お問い合わせ
                </a>
              </li>
              <li>
                <a
                  href="https://www.sejuku.net/corp/agreement/?cid=sns_lp_toagreement"
                  target="_blank"
                >
                  利用規約
                </a>
              </li>
              <li>
                <a
                  href="https://www.sejuku.net/corp/privacy-policy?cid=sns_lp_toprivacy"
                  target="_blank"
                >
                  プライバシーポリシー
                </a>
              </li>
              <li>
                <a
                  href="https://www.sejuku.net/corp/information-security?cid=sns_lp_tosecurity"
                  target="_blank"
                >
                  情報セキュリティ方針
                </a>
              </li>
              <li>
                <a href="https://www.sejuku.net/corp/legal?cid=sns_lp_tplegal" target="_blank">
                  特定商取引法に基づく表示
                </a>
              </li>
            </ul>
          </div>
        </FooterNav>
        <p className="copyright">
          © <span>Samurai</span>, Inc. All Rights Reserved.
        </p>
      </FooterInner>
    </Footer>
  );
};

const Footer = styled.footer`
  padding: 5rem 0 3rem;
  background: #f8f8f8;

  ${media.lessThan('medium')`
    padding: 3rem 0 0.75rem;
  `}
`;
const FooterInner = styled(Inner)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .copyright {
    width: 100%;
    margin-top: 1rem;
    color: #afb5c0;
    font-size: 0.75rem;

    span {
      font-size: 1em;
      font-weight: 700;
    }
  }

  ${media.lessThan('medium')`
    display: block;

    .copyright {
      margin-top: 3rem;
      fnot-size: 0.625rem;
    }
  `}
`;
const LogoArea = styled.div`
  > img {
    width: 100%;
    max-width: 239px;
  }

  .sns {
    display: flex;
    margin-top: 2.25rem;

    a {
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      box-shadow: 0 4px 16px rgba(12, 12, 12, 0.1);

      + a {
        margin-left: 1.5rem;
      }

      img {
        width: 100%;
      }
    }
  }

  ${media.lessThan('medium')`
    > img {
      max-width: 172px;
    }

    .sns {
      margin-top: 1.5rem;

      a {
        + a {
          margin-left: 1rem;
        }
      }
    }
  `}
`;
const FooterNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  flex: 1;

  .menu {
    + .menu {
      margin-left: 3rem;
    }

    h3 {
      margin-bottom: 1.5rem;
      color: rgba(0, 0, 0, 0.87);
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.11;
    }

    li {
      color: #4f5665;
      line-height: 1.5;

      + li {
        margin-top: 1rem;
      }

      .en {
        font-family: Lato, sans-serif;
      }
    }
  }

  ${media.lessThan('large')`
    margin-left: 2rem;

    .menu {
      max-width: 160px;

      + .menu {
        margin-left: 1rem;
      }

      h3 {
        font-size: 1rem;
      }

      li {
        a {
          font-size: 0.875rem;
        }
      }
    }
  `}

  ${media.lessThan('medium')`
    display: block;
    margin: 2.5rem auto 0;

    .menu {
      max-width: none;

      + .menu {
        margin: 1.5rem auto 0;
      }
    }
  `}
`;
