import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { InputWithSearch } from '../molecules/InputWithSearch';
import { HorizontalInput } from '../molecules/HorizontalInput';
import { Tag } from '../../types/Tag';
import { UserTag } from '../../types/UserTag';
import { useGetTagsLazyQuery } from '../../gen/graphql';

interface Handler {
  getTerm: () => string;
  getTag: () => Tag | null;
  getUserTag: () => UserTag;
  validate: () => boolean;
}

interface InputWithSearchHandler {
  getValue: () => Tag | null;
  setValue: (tag: Tag) => void;
  clear: () => void;
}

interface UserTagProps {
  minTerm?: number;
  maxTerm?: number;
  defaultValues?: UserTag;
}

export interface UserTagState {
  id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any;
  defaultValue?: UserTag;
}

export enum UserTagActionType {
  PUSH = 'PUSH',
  SPLICE = 'SPLICE',
  CLEAR = 'CLEAR',
}

export interface UserTagAction {
  type: UserTagActionType;
  id?: number;
  defaultValue?: UserTag;
}

export const UserTagComponent = forwardRef<Handler, UserTagProps>((props, ref): JSX.Element => {
  const [term, setTerm] = useState(
    props.defaultValues ? props.defaultValues?.term?.toString() : '0',
  );
  const tagRef = useRef<InputWithSearchHandler>(null);

  const [tagError, setTagError] = useState(false);
  const [termError, setTermError] = useState(false);

  const handleInputTerm = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTerm(e.target.value);
  };

  const [getTags, tagData] = useGetTagsLazyQuery();

  const tagOptions =
    tagData.data?.getTags.items ??
    (props.defaultValues && props.defaultValues.tag ? [props.defaultValues.tag] : []);

  const searchTags = (query: string) => {
    getTags({
      variables: {
        input: {
          name: query,
          page: 1,
          limit: 10,
        },
      },
    });
  };

  useImperativeHandle(ref, (): Handler => {
    return {
      getTerm: (): string => term,
      getTag: (): Tag | null => (tagRef.current ? tagRef.current.getValue() : null),
      getUserTag: (): UserTag => {
        const tag = tagRef.current?.getValue();
        return {
          term: term ? parseInt(term) : 0,
          tagId: tag ? tag.id : undefined,
        };
      },
      validate: (): boolean => {
        const tagError = tagRef.current?.getValue() === null;
        let termError = term === '';
        let t = -1;
        try {
          t = parseInt(term);
        } catch (e) {
          termError = true;
        }
        if (props.minTerm && props.minTerm > t) termError = true;
        if (props.maxTerm && t > props.maxTerm) termError = true;
        setTagError(tagError);
        setTermError(termError);
        return !tagError && !termError;
      },
    };
  });

  return (
    <Container>
      <InputWithSearch
        label="言語・技術"
        options={tagOptions}
        handleInput={searchTags}
        width="50%"
        inputWidth="100%"
        flexGrow="2"
        ref={tagRef}
        error={tagError}
        defaultValue={props.defaultValues?.tag}
      />
      <HorizontalInput
        label="期間"
        unit="年"
        name="term"
        width="30%"
        inputWidth="100%"
        flexGrow="1"
        value={term}
        onChange={handleInputTerm}
        type="number"
        min={props.minTerm}
        max={props.maxTerm}
        error={termError}
      />
    </Container>
  );
});

export default UserTagComponent;

const Container = styled.div`
  display: flex;
  padding: 0.5rem;
  background: #fff;
  border: 1px solid #ddd;
  flex-grow: 1;

  ${media.lessThan('medium')`
    padding: 0.5rem 1rem;
    flex-direction: column;

    & > * {
      width: 100% !important;
      margin: 0.5rem 0;
    }
  `}

  ${media.greaterThan('medium')`
    & > * + * {
      margin-left: 0.5rem;
    }

    & > * {
      align-items: flex-start;
      flex-direction: column;
    }

    label {
      margin-bottom: 0.3rem;
    }
  `}
`;
