import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { ChatMemberListFragment } from '../../../gen/graphql';

import { BasicLayout } from '../../templates/BasicLayout';
import { MarkdownGlobalStyle } from '../../../common/Markdown';
import { ChatMessageList } from '../../organisms/ChatMessageList';
import { ChatRoomList } from '../../organisms/ChatRoomList';
import { CustomPrompt } from '../../../common/CustomPrompt';
import { useEditingConfirmDialog } from '../../../common/customHooks/EditingConfirmDialog';
import { useFetchChatRoom } from '../../../common/customHooks/FetchChatRoom';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';

export const Chat: React.FC = () => {
  const metaTitle = `メッセージ | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のメッセージページです。インストラクターやコーチ、受講生がメッセージのやりとりを確認できます。`;

  const [chatRoomId, setChatRoomId] = useState<number>(0);
  const [chatMembers, setChatMembers] = useState<ChatMemberListFragment[]>([]);
  const [editing, setEditing] = useState<boolean>(false);

  const { chatRooms, readMessages, hasNextPage, loadMore, error } = useFetchChatRoom();

  const { Dialog: EditingConfirmDialog, confirm: confirmEditing } = useEditingConfirmDialog();

  const handleChangeRoom = useCallback(
    async (chatRoomId: number, chatMembers: ChatMemberListFragment[]) => {
      if (editing) {
        const ok = await confirmEditing();
        if (!ok) {
          return;
        }
      }

      await readMessages(chatRoomId);

      setChatRoomId(chatRoomId);
      setChatMembers(chatMembers);
      setEditing(false);
    },
    [confirmEditing, editing, readMessages],
  );

  const handleResetChatRoom = useCallback(async () => {
    if (editing) {
      const ok = await confirmEditing();
      if (!ok) {
        return;
      }
    }

    setChatRoomId(0);
    setEditing(false);
  }, [editing, confirmEditing]);

  const handleOnEditing = useCallback((isEdit: boolean) => {
    setEditing(isEdit);
  }, []);

  return (
    <BasicLayout
      pageTitle="メッセージ"
      hideFooter
      hasIntercom={false}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      <MarkdownGlobalStyle />
      <Container>
        <ChatRoomList
          chatRoomId={chatRoomId}
          chatRooms={chatRooms}
          onChangeRoom={handleChangeRoom}
          hasNextPage={hasNextPage}
          loadMore={loadMore}
          error={error}
        />
        <ChatMessageList
          key={chatRoomId}
          chatRoomID={chatRoomId}
          chatMembers={chatMembers}
          onResetChatRoom={handleResetChatRoom}
          onEditing={handleOnEditing}
          onConfirmEditing={confirmEditing}
          readMessages={readMessages}
        />
        <EditingConfirmDialog />
        <CustomPrompt
          when={editing}
          message={'他の場所に移動しますか？\nメッセージの保存されていない変更はすべて失われます。'}
        />
      </Container>
    </BasicLayout>
  );
};

const Container = styled.div`
  display: grid;
  overflow: visible;
  position: relative;

  grid-template-columns: 360px auto;
  grid-template-rows: auto auto;
  grid-template-areas: 'chat_room_view chat_message_view';

  ${media.lessThan('medium')`
    display: block;
  `}
`;
