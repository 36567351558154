import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useUser } from '../../redux/user/useUser';

import { getRecentPracticeSubmitStatusWithoutDraft } from '../../utils/PracticeSubmit';

import PracticeIcon from '../../static/image/icon_practice.svg';
import LockIcon from '../../static/image/icon_lock_red.svg';
import TimeIcon from '../../static/image/icon_time.svg';
import DifficultyIcon from '../../static/image/icon_difficulty.svg';

import { PracticeFragment as Practice, PracticeSubmitDetailReviewStatus } from '../../gen/graphql';
import { PracticeSubmitReviewStatus } from '../atoms/PracticeSubmitReviewStatus';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { useCallback, useMemo } from 'react';
import { checkPracticeAuthType, getPracticeFunctionType } from '../../utils/Program';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { PracticeDifficultyLables } from '../../const/Practice';
import { getRecentPracticeSubmitStatus } from '../../utils/PracticeSubmit';

interface PracticeCardProps {
  practice: Practice;
}

export const PracticeCard: React.FC<PracticeCardProps> = ({ practice }) => {
  const { permissionCheck } = useUser();
  const permissionPracticeSubmitCreate = permissionCheck(
    FunctionType.PracticeSubmit,
    PermissionType.Create,
  );
  const permissionPracticeSubmitUpdate = permissionCheck(
    FunctionType.PracticeSubmit,
    PermissionType.Update,
  );

  const { openModal } = useCommonModal();

  const isPracticeViewable = useMemo(
    () => checkPracticeAuthType(practice, permissionCheck),
    [practice, permissionCheck],
  );

  const recentPracticeSubmitStatus = useMemo(
    () => getRecentPracticeSubmitStatusWithoutDraft(practice.mySubmit?.practiceSubmitDetails),
    [practice.mySubmit?.practiceSubmitDetails],
  );

  const checkShowDetail = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const functionType = getPracticeFunctionType(practice.type);
      if (!functionType) {
        e.preventDefault();
        return;
      }
      if (
        !(
          getRecentPracticeSubmitStatus(practice.mySubmit?.practiceSubmitDetails) ===
          PracticeSubmitDetailReviewStatus.Passed
        ) &&
        openModal(functionType, PermissionType.Read)
      )
        e.preventDefault();
    },
    [openModal, practice.type, practice.mySubmit?.practiceSubmitDetails],
  );

  return (
    <Card>
      <StyledLink to={`/practices/${practice.id}/body`} key={practice.id} onClick={checkShowDetail}>
        <Icon>
          {isPracticeViewable ? (
            <img src={PracticeIcon} alt="practice icon" />
          ) : (
            <img src={LockIcon} alt="lock icon" />
          )}
        </Icon>
        <Info>
          <Label>課題</Label>
          <Title>{practice.title}</Title>
          <Meta>
            <Text>
              <RequireTime>
                <img src={TimeIcon} alt="icon" width="14" height="14" />
                {practice.requireTime}分
              </RequireTime>
              <Difficulty>
                <img src={DifficultyIcon} alt="icon" width="14" height="14" />
                {PracticeDifficultyLables[practice.difficulty]}
              </Difficulty>
              <SubmitPassedCount>{practice.submitPassedCount}人が合格</SubmitPassedCount>
            </Text>
            {(permissionPracticeSubmitCreate || permissionPracticeSubmitUpdate) && (
              <SpStatus>
                <PracticeSubmitReviewStatus reviewStatus={recentPracticeSubmitStatus} />
              </SpStatus>
            )}
          </Meta>
        </Info>
        {(permissionPracticeSubmitCreate || permissionPracticeSubmitUpdate) && (
          <PcStatus>
            <PracticeSubmitReviewStatus reviewStatus={recentPracticeSubmitStatus} />
          </PcStatus>
        )}
      </StyledLink>
    </Card>
  );
};

const Card = styled.li`
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  background: #fff;
  transition: all 0.2s;

  &:hover {
    background: #f5f5f5;
  }

  ${media.lessThan('medium')`
    align-items: start;
  `}
`;
const Icon = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin: 0 22px 0 0;
  border-radius: 100%;
  background: #ffe8e7;

  img {
    margin: 0 0 2px 3px;
  }
`;
const Info = styled.div`
  flex-grow: 1;
`;
const Label = styled.p`
  margin: 0 0 2px;
  color: #eb0000;
  font-size: 0.8125rem;
  font-weight: 700;
`;
const Title = styled.h4`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
`;
const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.625rem 0 0;
  font-weight: 700;

  ${media.lessThan('medium')`
    font-weight: 400;
    flex-wrap: wrap;
    gap: 0.8rem 0;
`}
`;
const RequireTime = styled.span`
  margin: 0 0.5rem 0 0.25rem;
  font-size: 0.875rem;

  img {
    margin-right: 5px;
  }
`;
const Difficulty = styled.span`
  margin: 0 0.5rem 0 0.25rem;
  font-size: 0.875rem;
  img {
    margin-right: 5px;
  }
`;
const SubmitPassedCount = styled.span`
  font-size: 0.875rem;
`;
const PcStatus = styled.div`
  display: flex;
  align-items: center;

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const SpStatus = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
  `}
`;
