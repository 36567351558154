import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Z_INDEX_APPBAR } from '../../const/ZIndex';
import { formatDateToHoursAndMinutes } from '../../utils/Date';
import { useCampaignRemainingTime } from '../../common/customHooks/CampaignRemainingTime';

interface Props {
  isTabNavigation?: boolean;
  isNavigationUnfixed?: boolean;
  userCreatedAt: Date;
}

export const CampaignSnackbar: React.FC<Props> = (props) => {
  const { remainingTimeInMilliseconds } = useCampaignRemainingTime(props.userCreatedAt);

  return remainingTimeInMilliseconds > 0 ? (
    <Container
      href="https://www.sejuku.net/terakoya/subscription/lp24/?cid=snackbar"
      isTabNavigation={props.isTabNavigation}
      isNavigationUnfixed={props.isNavigationUnfixed}
    >
      プログラミング学習効率を最大化させる業界最安級のサブスクが今なら<span>初月50%OFF!</span>{' '}
      (終了まで残り<span>{formatDateToHoursAndMinutes(remainingTimeInMilliseconds)}</span>)
    </Container>
  ) : (
    <></>
  );
};

const Container = styled.a<{ isTabNavigation?: boolean; isNavigationUnfixed?: boolean }>`
  display: block;
  padding: 0.5rem 1rem;
  background: #ddfcc5;
  border: 1px solid #aaf959;
  color: #007940;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375;
  text-align: center;
  position: sticky;
  top: ${(props) => (props.isNavigationUnfixed ? '0' : props.isTabNavigation ? '113' : '66')}px;
  z-index: ${Z_INDEX_APPBAR};

  span {
    color: #eb0000;
    font-size: 1em;
    font-weight: 700;
  }

  ${media.lessThan('medium')`
    padding: 1rem;
    line-height: 1.25;
    text-align: left;
  `}
`;
