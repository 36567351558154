import { GetCourseProgramQuery } from '../gen/graphql';

export type CourseProgram = NonNullable<GetCourseProgramQuery['courseProgram']>;
export type CourseProgramSteps = NonNullable<CourseProgram['courseProgramSteps']>;

export function excludeStepsWithoutItems(
  courseProgramSteps: CourseProgramSteps,
): CourseProgramSteps {
  return courseProgramSteps.filter(
    (courseProgramStep) =>
      Array.isArray(courseProgramStep.courseProgramStepItems) &&
      courseProgramStep.courseProgramStepItems.length > 0,
  );
}
