import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { H4 } from '../../atoms/Typography';
import { PageWrapper } from '../../atoms/PageWrapper';
import { BasicLayout } from '../../templates/BasicLayout';
import { CourseProgramArticle } from '../../organisms/CourseProgramArticle';
import { LoginModal } from '../../organisms/LoginModal';

import { useBannerCids } from '../../../common/customHooks/BannerCids';
import { Loaders } from '../../../common/Loader';
import { useUser } from '../../../redux/user/useUser';
import { BannerCidType, useGetCourseProgramsQuery } from '../../../gen/graphql';

import { MIDDLE_LIMIT } from '../../../const/Page';
import { LOWER_META_TITLE } from '../../../const/Service';

export const PublicTop: React.FC = () => {
  const metaTitle = `ホーム | ${LOWER_META_TITLE}`;

  const { user } = useUser();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [loginError, setLoginError] = useState<string>(user.loginError);

  const { data: courseProgramsData, loading: courseProgramsLoading } = useGetCourseProgramsQuery({
    variables: {
      input: {
        registeredCoursePrograms: true,
      },
    },
  });

  const { pcBanners } = useBannerCids([BannerCidType.TopCenter], MIDDLE_LIMIT);
  const centerPcBanners = pcBanners.filter((pcBanner) => pcBanner.type === BannerCidType.TopCenter);

  const openModal = () => {
    setLoginModalOpen(true);
  };

  const closeModal = () => {
    setLoginError(''); // リセット
    setLoginModalOpen(false);
  };

  // ソーシャルログインエラー表示用
  useEffect(() => {
    if (loginError) {
      openModal();
    }
  }, [loginError]);

  return (
    <BasicLayout pageTitle="ホーム" metaTitle={metaTitle}>
      <PageWrapper>
        <CenterPcBanner>
          {centerPcBanners.map((centerPcBanner) => (
            <a
              href={centerPcBanner.banner.link + centerPcBanner.cid}
              target="_blank"
              key={centerPcBanner.id}
            >
              <img src={centerPcBanner.banner.imageURL} alt={centerPcBanner.banner.title} />
            </a>
          ))}
        </CenterPcBanner>

        <Container>
          <Left>
            <Header>
              <H4 color="DARK">おすすめコース教材</H4>
            </Header>
            <CurriculumContainer>
              {!courseProgramsLoading ? (
                courseProgramsData?.coursePrograms?.map((p) => {
                  return (
                    <StyledCourseProgramArticle
                      id={p.id}
                      key={p.id}
                      image={p.image ?? ''}
                      title={p.title ?? ''}
                      description={p.description ?? ''}
                      totalChapterCount={p.totalChapterCount}
                      totalRequireTime={p.totalRequireTime}
                      totalCompletedTime={p.totalCompletedTime}
                    />
                  );
                })
              ) : (
                <Loaders
                  width="calc(33.3% - 0.75rem)"
                  height="14.0625rem"
                  margin="0 0.75rem 1rem 0"
                  number={9}
                />
              )}
            </CurriculumContainer>
          </Left>
        </Container>
      </PageWrapper>
      <LoginModal isOpen={loginModalOpen} onClose={closeModal} loginError={loginError} />
    </BasicLayout>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Left = styled.section`
  flex: 1;
`;

const CenterPcBanner = styled.section`
  position: relative;
  width: 100%;
  min-width: 284px;

  > a {
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:first-of-type {
      margin-top: 2rem;
    }

    &:last-of-type {
      margin-bottom: 2rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  ${media.lessThan('small')`
    display: none;
  `}
`;

const CurriculumContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 2rem 1.25rem;

  ${media.lessThan('medium')`
    gap: 1.5rem 1rem;
  `}
`;

const StyledCourseProgramArticle = styled(CourseProgramArticle)`
  width: calc(50% - 0.75rem);

  ${media.lessThan('medium')`
    width: calc(50% - .5rem);
  `}

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
