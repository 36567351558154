import React from 'react';
import styled from 'styled-components';
import { FilterInputLabel } from '../atoms/FilterInputLabel';
import { FilterInputSelect } from '../atoms/FilterInputSelect';
import { SelectOption } from '../atoms/Select';

interface FilterSelectProps {
  label?: string;
  name: string;
  options: readonly SelectOption[];
  currentValue: string;
  handleSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  active?: boolean;
  disabled?: boolean;
}

export const FilterSelect: React.FC<FilterSelectProps> = (props) => {
  return (
    <SelectContainer>
      {props.label && <FilterInputLabel label={props.label} />}
      <FilterInputSelect
        name={props.name}
        options={props.options}
        currentValue={props.currentValue}
        handleSelect={props.handleSelect}
        active={props.active}
        disabled={props.disabled}
      />
    </SelectContainer>
  );
};

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
