import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { StudyLogChart } from './StudyLogChart';
import { StudyLogGraph } from './StudyLogGraph';
import { StudySummaryFragment, StudyCalendarFragment } from '../../gen/graphql';
import { pipe } from 'fp-ts/pipeable';
import { fromNullable, getOrElse } from 'fp-ts/Option';

interface SummaryProps {
  label: string;
  summary?: StudySummaryFragment;
  type?: string;
  data?: StudyCalendarFragment[];
  range?: number;
}

export const StudySummary: React.FC<SummaryProps> = (props) => {
  const getOrElse0 = (value: number | undefined | null) => {
    return pipe(
      fromNullable(value),
      getOrElse<number>(() => {
        return 0;
      }),
    );
  };

  const studyReportTimeWeek: number = Math.floor(
    getOrElse0(props.summary?.studyReportTimeWeek) / 60,
  );

  const studyReportTimeMonth: number = Math.floor(
    getOrElse0(props.summary?.studyReportTimeMonth) / 60,
  );

  const studyReportTimeHour: number = Math.floor(getOrElse0(props.summary?.studyReportTime) / 60);

  const studyReportTimeMinutes: number = getOrElse0(props.summary?.studyReportTime) % 60;

  const studyReportCount: number = getOrElse0(props.summary?.studyReportCount);

  const studyReportRunningLayDays: number = getOrElse0(props.summary?.studyReportRunningLayDays);

  return (
    <Container>
      <h3>{props.label}</h3>
      {props.type == 'calendar' && props.range ? (
        <StyledStudyLogChart data={props.data} range={props.range} />
      ) : (
        ''
      )}
      {props.type === 'graph' && props.data ? <StudyLogGraph data={props.data} /> : ''}
      <RightColumn>
        <Row>
          <dt>直近7日間</dt>
          <dd>
            {studyReportTimeWeek}
            <span>時間</span>
          </dd>
        </Row>
        <Row>
          <dt>直近30日間</dt>
          <dd>
            {studyReportTimeMonth}
            <span>時間</span>
          </dd>
        </Row>
        <Row>
          <dt>総学習時間</dt>
          <dd>
            {studyReportTimeHour}
            <span>時間</span> {studyReportTimeMinutes}
            <span>分</span>
          </dd>
        </Row>
        <Row>
          <dt>総学習回数</dt>
          <dd>
            {studyReportCount}
            <span>回</span>
          </dd>
        </Row>
        <Row>
          <dt>最高連続学習日数</dt>
          <dd>
            {studyReportRunningLayDays}
            <span>日</span>
          </dd>
        </Row>
      </RightColumn>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;

  h3 {
    width: 100%;
    margin-bottom: 34px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
`;
const StyledStudyLogChart = styled(StudyLogChart)`
  width: 173px;
`;
const RightColumn = styled.div`
  flex: 1;
  margin-left: 32px;

  ${media.lessThan('large')`
    flex: auto;
    width: 100%;
    margin: 32px 0 0;
  `}

  ${media.lessThan('medium')`
    flex: 1;
    margin: 0 0 0 24px;
  `}

  ${media.lessThan('small')`
    flex: auto;
    margin: 24px 0 0;
  `}
`;
const Row = styled.dl`
  display: flex;
  justify-content: space-between;
  position: relative;
  line-height: 20px;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    margin: auto;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &:nth-of-type(n + 2) {
    margin-top: 12px;
  }

  dt {
    padding-right: 8px;
    background: #fff;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
  }

  dd {
    padding-left: 8px;
    background: #fff;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: bold;

    span {
      margin-left: 4px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 10px;
      font-weight: 500;
    }
  }
`;
