import React from 'react';
import styled from 'styled-components';

interface InputLabelProps {
  children: string;
  for: string;
  className?: string;
}
export const InputLabel: React.FC<InputLabelProps> = (props): JSX.Element => (
  <Label htmlFor={props.for} className={props.className}>
    {props.children}
  </Label>
);

const Label = styled.label`
  min-width: 30%;
  font-size: 1rem;
  margin-right: 0.5rem;
`;
