import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link, useNavigate } from 'react-router-dom';

import { useUser } from '../../../redux/user/useUser';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';

import { BasicLayout } from '../../templates/BasicLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { FormHeadline } from '../../atoms/FormHeadline';
import { LearnedContent } from '../../organisms/LearnedContent';
import { TextArea, TextAreaHandler } from '../../atoms/TextArea';
import { Button } from '../../atoms/Button';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { getStudyReportContentError } from '../../../utils/StudyReport';

import { useUpdateStudyReportMutation, useGetStudyReportQuery } from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

import TrashIcon from '../../../static/image/icon_trash.svg';
import { StudyReport } from '../../../common/Const';
import { NotFoundPage } from '../public/NotFound';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { LOWER_META_TITLE } from '../../../const/Service';

enum LearnedContentsActionType {
  PUSH = 'PUSH',
  SPLICE = 'SPLICE',
}

interface LearnedContentsAction {
  type: LearnedContentsActionType;
  id?: number;
  detailID?: number; // Stg上でロードデータの重複が発生するため重複排除用の設定
  defaultValues?: {
    time: number;
    tag: {
      id: number;
      name: string;
    };
    program: {
      id: number;
      name: string;
    };
  };
}

export const EditStudyReportContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathStudyReportID = Number(paramID);
  if (Number.isNaN(pathStudyReportID)) return NotFoundPage;

  return <EditStudyReport studyReportID={pathStudyReportID} />;
};

interface EditStudyReportProps {
  studyReportID: number;
}

const EditStudyReport = (props: EditStudyReportProps): JSX.Element => {
  const metaTitle = `学習ログ編集フォーム | ${LOWER_META_TITLE}`;

  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentID, setCurrentID] = useState(0);
  const contentRef = React.useRef<TextAreaHandler>(null);
  const [contentError, setContentError] = useState<string>('');
  const [learnedContentError, setLearnedContentError] = useState<string>('');
  const { user } = useUser();

  const [updateStudyReport] = useUpdateStudyReportMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [learnedContents, dispatch] = useReducer((state: any[], action: LearnedContentsAction) => {
    switch (action.type) {
      case LearnedContentsActionType.PUSH:
        if (state.length >= 5) return state;
        // STG環境だと重複でDetailが登録されるため重複チェック
        if (
          state.some(
            (learnedContent) =>
              learnedContent.detailID &&
              action.detailID &&
              learnedContent.detailID === action.detailID,
          )
        )
          return state;
        setCurrentID(currentID + 1);
        return [
          ...state,
          {
            id: currentID,
            ref: React.createRef(),
            defaultValues: action.defaultValues,
            detailID: action.detailID,
          },
        ];
      case LearnedContentsActionType.SPLICE:
        return state.filter((learnedContent) => learnedContent.id !== action.id);
      default:
        return state;
    }
  }, []);

  const validate = () => {
    const isLearnedContentsFilled = learnedContents.every((learnedContent) =>
      learnedContent.ref.current.validate(),
    );

    const totalLearningTime = learnedContents.reduce(
      (sum, lc) => sum + Number(lc.ref.current.getStudyTime()),
      0,
    );
    const validTotalLearningTime = totalLearningTime <= StudyReport.MAX_TIME;
    const totalLearningTimeErrorMessage = validTotalLearningTime
      ? ''
      : '合計時間が24時間を超えています';

    setLearnedContentError(totalLearningTimeErrorMessage);
    const contentError = getStudyReportContentError(contentRef.current?.getValue());
    setContentError(contentError);

    return isLearnedContentsFilled && !contentError && validTotalLearningTime;
  };

  const submit = useSafeAsyncCallback(async () => {
    if (!validate()) return;

    const content = contentRef.current?.getValue() ?? '';
    const studyReportDetails = learnedContents.map((learnedContent) => {
      const content = {
        time: learnedContent.ref.current?.getStudyTime(),
        tag: learnedContent.ref.current?.getTag(),
        program: learnedContent.ref.current?.getProgram(),
      };

      if (!content.time || !content.tag) return null;

      return {
        time: parseInt(content.time),
        tagID: content.tag.id,
        programID: content.program ? content.program.id : null,
      };
    });

    try {
      await updateStudyReport({
        variables: {
          id: props.studyReportID,
          input: {
            date: selectedDate.toISOString(),
            studyReportDetails: studyReportDetails,
            content: content,
          },
        },
        onCompleted: () => {
          navigate(`/study_report/detail/${props.studyReportID}`);
        },
      });
    } catch (e) {
      setContentError(getApiErrorMessage(e));
    }
  });

  useGetStudyReportQuery({
    variables: {
      id: props.studyReportID,
      userID: user.lmsUser?.id,
    },
    onCompleted: (data) => {
      const studyReport = data?.getStudyReport;
      contentRef.current?.setValue(studyReport?.content ?? '');
      if (studyReport?.date) {
        setSelectedDate(new Date(studyReport?.date));
      }

      studyReport?.studyReportDetails.forEach((studyReportDetail) => {
        if (!studyReportDetail.tag) {
          // Tagが空（Invalid）な場合は処理をしない
          return;
        }

        const defaultValue = {
          time: studyReportDetail.time ?? 0,
          tag: {
            id: studyReportDetail.tag.id,
            name: studyReportDetail.tag.name,
          },
          program: {
            id: studyReportDetail.program?.id ?? 0,
            name: studyReportDetail.program?.title ?? '',
          },
        };

        dispatch({
          detailID: studyReportDetail.id,
          type: LearnedContentsActionType.PUSH,
          defaultValues: defaultValue,
        });
      });
    },
  });

  const breadcrumbs = [
    {
      label: 'ホーム',
      to: '/home',
    },
    {
      label: '自分の学習ログ',
      to: '/study_report/my',
    },
    {
      label: '投稿',
    },
  ];

  return (
    <BasicLayout pageTitle="学習ログ" metaTitle={metaTitle}>
      <PageWrapper>
        <StyledBreadcrumb links={breadcrumbs} />
        <Container>
          <Section>
            <StyledHeadline>学習日</StyledHeadline>
            <FormContent>
              <DatePicker
                selected={selectedDate}
                onChange={(d: Date) => setSelectedDate(d)}
                dateFormat="yyyy年MM月dd日"
                minDate={new Date(2000, 0, 1)}
                disabledKeyboardNavigation
                placeholderText="選択なし"
                className="input datepicker-date"
                maxDate={new Date()}
              />
            </FormContent>
          </Section>
          <Section>
            <StyledHeadline>学習内容</StyledHeadline>
            <FormContent>
              {learnedContents.map((learnedContent) => {
                return (
                  <Row key={learnedContent.id}>
                    <LearnedContent
                      ref={learnedContent.ref}
                      defaultValues={learnedContent.defaultValues}
                    />
                    {learnedContents.length >= 2 ? (
                      <Icon
                        onClick={() =>
                          dispatch({
                            type: LearnedContentsActionType.SPLICE,
                            id: learnedContent.id,
                          })
                        }
                        src={TrashIcon}
                        alt="ゴミ箱"
                      />
                    ) : (
                      ''
                    )}
                  </Row>
                );
              })}
              {learnedContents.length < StudyReport.LEARNED_CONTENT_MAX_NUMBER_OF_ITEM ? (
                <Add onClick={() => dispatch({ type: LearnedContentsActionType.PUSH })}>
                  + 内容を追加する
                </Add>
              ) : (
                ''
              )}
              <ErrP>{learnedContentError}</ErrP>
            </FormContent>
          </Section>
          <Section>
            <StyledHeadline>ひとこと</StyledHeadline>
            <FormContent>
              <TextArea
                name="content"
                ref={contentRef}
                width="650px"
                height="139px"
                placeholder={
                  '学習した内容（つまずいたことやどのように解決したかなど）を記入しましょう'
                }
              />
              <ErrP>{contentError}</ErrP>
            </FormContent>
          </Section>
          <StyledButton onClick={submit}>更新する</StyledButton>
          <Cancel to="/study_report/my">キャンセル</Cancel>
        </Container>
      </PageWrapper>
    </BasicLayout>
  );
};

const StyledBreadcrumb = styled(Breadcrumb)`
  max-width: 800px;
  margin: 0 auto 1.25rem;

  ${media.lessThan('medium')`
    margin-bottom: 1rem;
  `}
`;
const Container = styled.div`
  max-width: 50rem;
  min-height: 100%;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    margin: 0 -1rem;
    padding: 0 1rem;
    border: none;
  `}
`;

const Section = styled.section`
  display: flex;
  margin-bottom: 2rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
const StyledHeadline = styled(FormHeadline)`
  width: 7.5rem;
  margin: 0;
  padding-top: 1rem;

  ${media.lessThan('medium')`
    width: 100%;
    margin-bottom: 1rem;
    padding: 0;
  `}
`;
const FormContent = styled.div`
  flex: 1;

  .datepicker-date {
    border: 1px solid #ddd;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding-right: 2.5rem;
  position: relative;

  &:nth-of-type(n + 2) {
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const Add = styled.p`
  color: #e2001b;
  font-weight: 600;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  ${media.lessThan('large')`
    margin-left: 1rem;
  `}
`;

const ErrP = styled.p`
  color: #f00;
  margin: 0.25rem 0 0.5rem;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
`;
const Cancel = styled(Link)`
  display: block;
  width: 5rem;
  margin: 1.5rem auto 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
`;
