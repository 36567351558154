import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Link } from 'react-router-dom';
import { QuestionListFragment, QuestionStatus } from '../../gen/graphql';
import { Tag } from '../atoms/Tag';
import { UserIcon } from './UserIcon';
import { getQuestionLabelType } from '../../utils/Question';
import { QuestionLabels } from '../../const/Question';

interface QuestionCardProps {
  question: QuestionListFragment;
}

export const QuestionCard: React.FC<QuestionCardProps> = ({ question }): JSX.Element => {
  const isDraft = question.status === QuestionStatus.Draft;
  const solved = isDraft ? false : question.solved;
  const nextPage = isDraft ? `/question/edit/${question.id}` : `/question/detail/${question.id}`;

  return (
    <Link to={nextPage}>
      <Article data-e2e="qaList">
        <StatusContainer>
          <Status solved={solved}>
            {QuestionLabels[getQuestionLabelType(solved, isDraft, question.answerRequestType)]}
          </Status>
          <AnswerCount solved={question.solved}>
            <p className="count">{question.answerCount}</p>
            <p className="label">回答</p>
          </AnswerCount>
        </StatusContainer>

        <Body>
          <Title>{question.title}</Title>
          <Program>{question.program ? `教材：${question.program?.title}` : ''}</Program>
          <TagContainer>
            {question.tags?.map((tag) => <Tag key={tag.id} name={tag.name} />)}
          </TagContainer>
          <StyledUserIcon
            user={question.user}
            date={question.editedAt ?? question.createdAt}
            draft={isDraft}
            medium
          />
        </Body>
      </Article>
    </Link>
  );
};

export default QuestionCard;

const Article = styled.article`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 118px;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.6);
  background-color: #fff;
  border: solid 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  cursor: pointer;

  ${media.lessThan('medium')`
    padding: 1rem;
  `}
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.0625rem;
`;

const Status = styled.p<{ solved: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1.4375rem 0.3125rem;
  min-width: 6rem;
  box-sizing: border-box;
  background: ${({ solved }) => (solved ? '#fce5e8' : '#F5F5F5')};
  color: ${({ solved }) => (solved ? '#fd3c2f' : 'rgba(0, 0, 0, 0.6)')};
  font-weight: 500;
  font-size: 0.625rem;

  ${media.lessThan('medium')`
    padding: 0.375rem;
    min-width: 2.875rem;
    font-size: 0.6875rem;
    font-weight: 400;
  `}
`;

const AnswerCount = styled.div<{ solved: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.3125rem;
  color: ${({ solved }) => (solved ? '#fd3c2f' : 'rgba(0, 0, 0, 0.36)')};

  .count {
    font-size: 1.5rem;
    line-height: 1.2;
  }
  .label {
    margin-top: 0.1875rem;
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 1.2;
  }

  ${media.lessThan('medium')`
    margin-top: 0.375rem;
    
    .label {
      margin-top: 0.375rem;
      font-size: 0.6875rem;
      font-weight: 400;
    }
  `}
`;

const Body = styled.div`
  margin-left: 0.75rem;
  width: 100%;

  ${media.lessThan('medium')`
    margin-left: 0.625rem;
  `}
`;

const Title = styled.h3`
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.1px;

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.38;
  `}
`;

const Program = styled.p`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.6);

  ${media.lessThan('medium')`
    margin-top: 1rem;
    font-weight: 400;
  `}
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.25rem 0 0 -0.5rem;

  & > * {
    margin: 0.5rem 0 0 0.5rem;
  }
`;

const StyledUserIcon = styled(UserIcon)`
  margin-top: 0.8125rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
