import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { BasicLayout } from '../../templates/BasicLayout';
import { H2 } from '../../atoms/Typography';
import { NextLesson } from '../../molecules/InstructorNextLesson';
import { NextSession } from '../../molecules/InstructorNextSession';
import { ProgramArticle } from '../../organisms/ProgramArticle';
import { PageWrapper } from '../../atoms/PageWrapper';

import { useUser } from '../../../redux/user/useUser';
import { useBannerCids } from '../../../common/customHooks/BannerCids';
import {
  BannerCidType,
  ProgramSearchType,
  ProgramType,
  useGetProgramsQuery,
} from '../../../gen/graphql';
import { FIRST_PAGE, MAX_LIMIT, MIDDLE_LIMIT } from '../../../const/Page';
import { LOWER_META_TITLE } from '../../../const/Service';
import { NickNameWarningModal } from '../../organisms/NickNameWarningModal';
import { useNavigate } from 'react-router-dom';

export const InstructorHome: React.FC = () => {
  const metaTitle = `ホーム | ${LOWER_META_TITLE}`;

  const { user } = useUser();
  const navigate = useNavigate();

  const [isNickNameWarningModalOpen, setIsNickNameWarningModalOpen] = useState(
    !user.lmsUser?.nickName,
  );

  const { pcBanners } = useBannerCids([BannerCidType.TopCenter], MIDDLE_LIMIT);
  const centerPcBanners = pcBanners.filter((pcBanner) => pcBanner.type === BannerCidType.TopCenter);

  const handleModalLink = (): void => {
    navigate('/account/profile?mode=editing');
  };

  const { data, loading } = useGetProgramsQuery({
    variables: {
      input: {
        programSearchType: ProgramSearchType.All,
        type: ProgramType.Instructor,
        page: FIRST_PAGE,
        limit: MAX_LIMIT,
      },
      userID: user.lmsUser?.id,
    },
  });
  const programs = data?.programs.items ?? [];

  return (
    <>
      <NickNameWarningModal
        isOpen={isNickNameWarningModalOpen}
        onClose={(): void => setIsNickNameWarningModalOpen(false)}
        handleLink={handleModalLink}
      />
      <BasicLayout pageTitle="ホーム" metaTitle={metaTitle}>
        <PageWrapper>
          <Container>
            <Left>
              {!user.lmsUser?.instructor?.isCoach ? (
                <section>
                  <NextLesson />
                </section>
              ) : (
                <section>
                  <NextSession />
                </section>
              )}

              <CenterPcBanner>
                {centerPcBanners.map((centerPcBanner) => (
                  <a
                    href={centerPcBanner.banner.link + centerPcBanner.cid}
                    target="_blank"
                    key={centerPcBanner.id}
                  >
                    <img src={centerPcBanner.banner.imageURL} alt={centerPcBanner.banner.title} />
                  </a>
                ))}
              </CenterPcBanner>

              <H2 color="DARK">マニュアル</H2>
              <Manual>
                {!loading &&
                  programs.map((p) => {
                    return (
                      <StyledProgramArticle
                        id={p.id}
                        key={p.id}
                        image={p.icon}
                        thumb={p.thumbnail}
                        title={p.title}
                        description={p.description}
                        questionCount={p.questionCount}
                        answerCount={p.answerCount}
                        chapterCount={p.chapterCount ? p.chapterCount : 0}
                        requireTime={p.requireTime ? p.requireTime : 0}
                        completedTime={p.completedTime ? p.completedTime : 0}
                        programSample={p.programSample ?? undefined}
                        tags={p.tags ?? []}
                      />
                    );
                  })}
              </Manual>
            </Left>
          </Container>
        </PageWrapper>
      </BasicLayout>
    </>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const Left = styled.section`
  flex: 1;

  & > section:nth-of-type(1) {
    position: relative;
    height: 160px;
    margin-bottom: 2rem;
  }

  section:nth-of-type(2) {
    display: flex;
    flex-wrap: wrap;
  }

  .all-watch {
    float: right;
  }
`;

const CenterPcBanner = styled.section`
  position: relative;
  width: 100%;
  min-width: 284px;

  > a {
    display: block;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 2rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  ${media.lessThan('small')`
    display: none;
  `}
`;

const Manual = styled.div`
  position: relative;
  display: flex;
  gap: 2rem 1.25rem;
  width: 100%;
  margin-top: 1rem;
  flex-wrap: wrap;

  ${media.lessThan('medium')`
    gap: 1.5rem 1rem;
  `}
`;

const StyledProgramArticle = styled(ProgramArticle)`
  width: calc((100% - 2.5rem) / 3);

  ${media.lessThan('large')`
    width: calc(50% - .75rem);
  `}

  ${media.lessThan('medium')`
    width: calc(50% - .5rem);
  `}

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
