import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'styled-media-query';

import { ExamScoreFragment } from '../../gen/graphql';
import { getMedalImage } from '../../utils/ProgramExam';

interface ChapterExamLinkProps {
  display?: boolean;
  programId: number;
  chapterId: number;
  examScores?: ExamScoreFragment[] | null;
}

export const ChapterExamLink: React.FC<ChapterExamLinkProps> = (props) => {
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const maxScore = props.examScores?.reduce((acc, cur) => (acc.score > cur.score ? acc : cur));

  return (
    <Container $display={props.display}>
      <h3>最後に確認テストを行いましょう</h3>
      {props.examScores && maxScore ? (
        <>
          <p className="comment">
            {props.examScores.length}回めのテストです。以前の成績を上回る得点を出しましょう
          </p>
          <MaxScore>
            <div className="item">
              <p className="label">自己ベスト</p>
              <p className="score">
                <span>{maxScore.score}</span>点
              </p>
            </div>

            <div className="item">
              <p className="label">獲得メダル</p>
              <p className="score">
                <img src={getMedalImage(maxScore.medalType)} alt="メダル" />
              </p>
            </div>
          </MaxScore>
        </>
      ) : (
        <p className="comment">下のボタンを押すとテストが始まります。</p>
      )}
      <Buttons>
        <GoBackButton onClick={scrollToTop}>
          <span>教材をみなおす</span>
        </GoBackButton>
        <ExamLink to={`/programs/${props.programId}/chapters/${props.chapterId}/exam`}>
          <span data-e2e="startExam">テストをはじめる</span>
        </ExamLink>
      </Buttons>
    </Container>
  );
};

const Container = styled.section<{ $display?: boolean }>`
  display: ${(props) => (props.$display ? 'block' : 'none')};
  position: relative;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 26px 0;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-top: 16px;
  text-align: center;

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
  }

  .comment {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 16px;
    text-align: center;
  }

  ${media.lessThan('medium')`
    h3 {
      font-size: 1.375rem;
    }

    .comment {
      padding: 0 32px;
      line-height: 1.5;
    }
  `}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  ${media.lessThan('medium')`
    margin-top: 20px;
    padding: 0 32px;
    box-sizing: border-box;
    flex-direction: column;
  `}
`;

const GoBackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 46px;
  box-sizing: border-box;
  border: 1px solid #eb0000;
  border-radius: 3px;
  cursor: pointer;

  span {
    font-size: 1rem;
    color: #e2001b;
  }

  ${media.lessThan('medium')`
    width: 100%;
    height: 44px;
  `}
`;

const ExamLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 46px;
  box-sizing: border-box;
  margin-left: 48px;
  border-radius: 3px;
  background-color: #eb0000;

  span {
    font-size: 1rem;
    color: #ffffff;
  }

  ${media.lessThan('medium')`
    width: 100%;
    height: 44px;
    margin-left: 0;
    margin-top: 16px;
  `}
`;

const MaxScore = styled.div`
  display: inline-flex;
  margin-top: 20px;
  padding: 14px 64px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .item {
    display: flex;
    align-items: center;

    & + .item {
      margin-left: 32px;
    }

    .label {
      font-size: 1.15rem;
      color: rgba(0, 0, 0, 0.87);
    }

    .score {
      margin-left: 16px;
      color: rgba(0, 0, 0, 0.87);
      font-size: 1.15rem;
      font-weight: bold;

      span {
        font-size: 1.875rem;
        color: #eda91d;
        font-weight: bold;
      }
    }
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;
    padding: 14px 53px;

    .item + .item {
      margin-left: 0;
      margin-top: 4px;
    }

    .item {
      & + .item {
        margin-left: 0;
      }
    }

    .label {
      font-size: 1rem;
    }
  `}
`;
