import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { HomeSummaryElement } from '../molecules/HomeSummaryElement';
import CalendarIcon from '../../static/image/icon_calendar.svg';
import BookIcon from '../../static/image/icon_book_orange.svg';
import HeadsetIcon from '../../static/image/icon_headset.svg';
import QuestionIcon from '../../static/image/icon_question_orange.svg';

interface Props {
  studyReportCount: number;
  whileStudyingProgramCount: number;
  completedProgramCount: number;
  totalLessonCount: number;
  questionCount: number;
  className?: string;
}

export const UserProfileSummary: React.FC<Props> = ({
  studyReportCount,
  whileStudyingProgramCount,
  completedProgramCount,
  totalLessonCount,
  questionCount,
  className,
}): JSX.Element => {
  return (
    <Container className={className}>
      <HomeSummaryElement icon={CalendarIcon} name="総学習回数" value={studyReportCount} />
      <HomeSummaryElement icon={BookIcon} name="進行中教材数" value={whileStudyingProgramCount} />
      <HomeSummaryElement icon={BookIcon} name="完了教材数" value={completedProgramCount} />
      <HomeSummaryElement icon={HeadsetIcon} name="総レッスン数" value={totalLessonCount} />
      <HomeSummaryElement icon={QuestionIcon} name="Q&A数" value={questionCount} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > * {
    box-sizing: border-box;
    width: calc((100% - 4px) / 5);
  }

  & > * + * {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('medium')`
    flex-wrap: wrap;
    align-items: stretch;
    
    & > * {
      padding: 0.75rem;
    }

    & > *:nth-child(-n + 3) {
      width: calc((100% - 2px) / 3);
    }
    
    & > *:nth-child(n + 4) {
      margin-top: 0.625rem;
      width: calc((100% - 1px) / 2);
    }

    & > *:nth-of-type(4) {
      border-left: none;
    }
  `}
`;
