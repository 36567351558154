import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Modal } from '../../components/molecules/Modal';
import { Button } from '../../components/atoms/Button';

export const useEditingConfirmDialog = (): {
  Dialog: React.FC;
  confirm: () => Promise<unknown>;
} => {
  const [editConfirm, setEditConfirm] = useState<{ resolve: (value: boolean) => void } | null>(
    null,
  );

  const confirm = () =>
    new Promise((resolve) => {
      setEditConfirm({ resolve });
    });

  const handleClose = useCallback(() => {
    setEditConfirm(null);
  }, []);

  const handleConfirm = useCallback(() => {
    editConfirm?.resolve(true);
    handleClose();
  }, [handleClose, editConfirm]);

  const handleCancel = useCallback(() => {
    editConfirm?.resolve(false);
    handleClose();
  }, [handleClose, editConfirm]);

  const Dialog = useMemo(() => {
    const ConfirmDialogComponent: React.FC = () => (
      <Modal
        isOpen={editConfirm !== null}
        onClose={() => void 0}
        hideHeaderClose
        header={<DialogTitle>他の場所に移動しますか？</DialogTitle>}
        footer={
          <DialogActions>
            <CancelButton onClick={handleCancel}>編集を続ける</CancelButton>
            <Button onClick={handleConfirm}>続行する</Button>
          </DialogActions>
        }
      >
        <DialogContent>
          <DialogContentText>
            メッセージの保存されていない変更はすべて失われます。
          </DialogContentText>
        </DialogContent>
      </Modal>
    );
    return ConfirmDialogComponent;
  }, [handleCancel, handleConfirm, editConfirm]);

  return { Dialog, confirm };
};

const CancelButton = styled.p`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const DialogTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
`;

const DialogContent = styled.div``;

const DialogContentText = styled.div`
  margin: 20px 15px;
`;

const DialogActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;
