import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Ttl } from '../../atoms/LpTtl';
import { PlanTab } from './PlanTab';
import { PlanLimitedFunction } from './PlanLimitedFunction';
import { PlanCard } from './PlanCard';

// NOTE 単月プランを表示する場合はSUBSCRIPTION_PLANSを呼び出す
import { SUBSCRIPTION_PLANS_TEST, PlanType } from '../../../const/Prices';

interface Props {
  type: string;
  forwardToSubscription?: boolean;
}

export const PlanList: React.FC<Props> = (props) => {
  const [currentPlan, setCurrentPlan] = useState<PlanType>('PREMIUM');

  return (
    <Container>
      <Ttl>
        <h2>{props.type === 'lp' ? 'プラン料金' : 'プランを選択する'}</h2>
      </Ttl>
      <Description>
        侍テラコヤでは契約期間に応じてお得になる価格設定となっています。
        <br />
        予定している期間に合ったプランを選択しましょう。
      </Description>

      <PlanTab currentPlan={currentPlan} setCurrentPlan={setCurrentPlan} />

      <PlanListArea type={props.type}>
        {SUBSCRIPTION_PLANS_TEST[currentPlan].map((plan) => {
          return (
            <PlanCard
              id={plan.id}
              period={plan.period}
              price={plan.taxIncludedPrice}
              recommend={plan.recommend}
              forwardToSubscription={props.forwardToSubscription}
              type={props.type}
            />
          );
        })}
      </PlanListArea>

      <Note>
        ※12ヶ月、もしくは6ヶ月プランで途中解約をした場合、解約手数料が発生いたします。詳しくは
        <a
          href="https://intercom.help/plus---samurai/ja/articles/6251120-q-%E8%A7%A3%E7%B4%84%E6%89%8B%E6%95%B0%E6%96%99%E3%81%AF%E7%99%BA%E7%94%9F%E3%81%97%E3%81%BE%E3%81%99%E3%81%8B"
          target="_blank"
        >
          こちら
        </a>
        。
      </Note>

      <StyledPlanLimitedContent plan={currentPlan} />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 4rem;

  ${media.lessThan('medium')`
    margin-top: 2.5rem;
  `}
`;
const Description = styled.p`
  margin: 1rem auto 4rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  line-height: 1.4;
  text-align: center;

  ${media.lessThan('medium')`
    margin: 2rem auto;
    font-size: 1rem;
    line-height: 1.8;
  `}
`;
const PlanListArea = styled.div<{ type: string }>`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 3rem auto 0;
  ${(props) =>
    props.type === 'lp' &&
    `
    max-width: 640px;
  `}

  ${media.lessThan('medium')`
    gap: 1rem;
    max-width: 480px;
    margin: 2rem auto 0;
  `}
`;
const Note = styled.p`
  margin-top: 2rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;

  a {
    color: #eb0000;
    font-size: 1em;
    font-weight: 700;
    text-decoration: underline;
  }

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
const StyledPlanLimitedContent = styled(PlanLimitedFunction)`
  margin-top: 4rem;

  ${media.lessThan('medium')`
    margin-top: 2.5rem;
  `}
`;
