import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';
import { PlanTable } from '../../molecules/lp/PlanTable';
import { PlanList } from '../../molecules/lp/PlanList';

import { SERVICE_NAME } from '../../../const/Service';

import ScrollHint from '../../../static/image/scroll_hint.png';

interface Props {
  type: string;
  forwardToSubscription?: boolean;
  isClosed?: boolean;
  closePlan?: () => void;
}

export const Plan: React.FC<Props> = (props) => {
  const [displayScrollHint, setDisplayScrollHint] = useState(true);

  return !props.isClosed ? (
    <Section id={props.type !== 'lp' ? 'plan' : 'planDetail'}>
      <Inner>
        <Ttl>
          <h2>プラン紹介</h2>
          <p>Plans</p>
        </Ttl>
        <Description>
          {SERVICE_NAME}ではあなたの学習方法に合わせて3つのプランからお選びいただけます。
        </Description>
        <TableContainer
          onScroll={() => setDisplayScrollHint(false)}
          displayScrollHint={displayScrollHint}
          isSubscription={props.forwardToSubscription}
        >
          <PlanTable forwardToSubscription={props.forwardToSubscription} />
        </TableContainer>
        <PlanList type={props.type} forwardToSubscription={props.forwardToSubscription} />
        {props.type === 'lp' && (
          <ToggleButton onClick={props.closePlan}>- 表示を減らす</ToggleButton>
        )}
      </Inner>
    </Section>
  ) : (
    <></>
  );
};

const Section = styled.section`
  margin: 7.5rem auto;
`;
const Description = styled.p`
  margin-top: 2rem;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.875rem;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
  `}

  ${media.lessThan('small')`
    width: 255px;
    margin: 2rem auto 0;
  `}
`;
const TableContainer = styled.div<{ displayScrollHint: boolean; isSubscription?: boolean }>`
  margin-top: 4rem;

  ${(props) =>
    !props.isSubscription &&
    media.lessThan('medium')`
    margin: 4rem -2rem 0;
    padding: 0 2rem;
    overflow-x: auto;
  `}

  ${media.lessThan('medium')`
    margin: 2rem -1rem 0;
    padding: 0 1rem;
    overflow-x: auto;
  `}

  ${(props) =>
    props.displayScrollHint &&
    media.lessThan('small')`
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 126px;
      margin: auto;
      background: url(${ScrollHint}) center / contain no-repeat;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  `}
`;

const ToggleButton = styled.p`
  width: 100%;
  max-width: 320px;
  margin: 4rem auto 0;
  padding: 0.5rem 0;
  border: 1px solid #eb0000;
  border-radius: 0.1875rem;
  cursor: pointer;
  color: #eb0000;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.625rem;
  text-align: center;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`;
