import React from 'react';
import styled from 'styled-components';
import media, { generateMedia } from 'styled-media-query';

interface PageTitleProps {
  children: string;
}

export const PageTitle: React.FC<PageTitleProps> = (props) => {
  return <H1>{props.children}</H1>;
};

const customMedia = generateMedia({
  xs: '374px',
});

const H1 = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;

  ${media.lessThan('small')`
    font-size: 1.25rem;
  `}
  ${customMedia.lessThan('xs')`
    font-size: 1.125rem;
  `}
`;
