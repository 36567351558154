import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { PlanType } from '../../../const/Prices';

import { Ttl } from '../../atoms/LpTtl';

import Image001 from '../../../static/image/lp/plan_function_001.jpg';
import Image002 from '../../../static/image/lp/plan_function_002.jpg';

interface Props {
  plan: PlanType;
  className?: string;
}

const PremiumContent: React.FC = () => {
  return (
    <>
      <Ttl>
        <h2>プレミアムプランの限定機能</h2>
        <p>Premium Contents</p>
      </Ttl>
      <Description>
        プレミアムプランでは、
        <br className="sp" />
        ライトプランの機能に加えて、
        <br />
        以下の限定機能が使えます。
      </Description>
      <ColumnList>
        <Column>
          <Image src={Image001} />
          <div>
            <ColumnTitle>課題機能</ColumnTitle>
            <ColumnText>
              教材によるインプットに加えて応用にチャレンジすることでアウトプット回数が増え、通常よりも早く、確かなスキルが身につきます！
            </ColumnText>
          </div>
        </Column>
        <Column>
          <Image src={Image002} />
          <div>
            <ColumnTitle>月4回のレッスン</ColumnTitle>
            <ColumnText>
              本格的に学習をする場合、週に1回ペースでレッスンを受けるのがおすすめです。学習ペースを維持して副業、転職を目指す方は現役エンジニアにたくさん質問しましょう。
            </ColumnText>
          </div>
        </Column>
      </ColumnList>
    </>
  );
};

const BasicContent: React.FC = () => {
  return (
    <>
      <Ttl>
        <h2>ベーシックプランの限定機能</h2>
        <p>Basic Contents</p>
      </Ttl>
      <Description>
        ベーシックプランでは、
        <br className="sp" />
        ライトプランの機能に加えて、
        <br />
        以下の限定機能が使えます。
      </Description>
      <ColumnList>
        <Column>
          <Image src={Image001} />
          <div>
            <ColumnTitle>課題機能</ColumnTitle>
            <ColumnText>
              教材によるインプットに加えて応用にチャレンジすることでアウトプット回数が増え、通常よりも早く、確かなスキルが身につきます！
            </ColumnText>
          </div>
        </Column>
        <Column>
          <Image src={Image002} />
          <div>
            <ColumnTitle>月2回のレッスン</ColumnTitle>
            <ColumnText>
              初学者の方は月1回のレッスンでは、学習ペースが掴めない可能性があります。いつでも現役エンジニアに相談できるようにレッスン回数が多い方がおすすめです。
            </ColumnText>
          </div>
        </Column>
      </ColumnList>
    </>
  );
};

export const PlanLimitedFunction: React.FC<Props> = (props) => {
  return props.plan === 'PREMIUM' ? (
    <section className={props.className}>
      <PremiumContent />
    </section>
  ) : props.plan === 'BASIC' ? (
    <section className={props.className}>
      <BasicContent />
    </section>
  ) : (
    <></>
  );
};

const Description = styled.p`
  margin-top: 4rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  line-height: 1.4;
  text-align: center;

  .sp {
    display: none;
  }

  ${media.lessThan('medium')`
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.8;
  `}

  ${media.lessThan('small')`
    .sp {
      display: block;
    }
  `}
`;
const ColumnList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  margin-top: 4rem;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`;
const Column = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem 3rem;
  width: 100%;
  max-width: 1000px;
  color: rgba(0, 0, 0, 0.87);

  ${media.lessThan('medium')`
    flex-direction: column;
    max-width: 480px;
  `}
`;
const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 15rem;

  ${media.lessThan('medium')`
    max-width: 20rem;
  `}
`;
const ColumnTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;

  ${media.lessThan('medium')`
    font-size: 1.125rem;
  `}
`;
const ColumnText = styled.p`
  margin-top: 0.75rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
`;
