import { useContext, useEffect } from 'react';
import { HeadTagContext } from '../../context/HeadTagProvider';
import { DEFAULT_META_DESCRIPTION } from '../../const/Service';

export const setMetaData = (
  title: string,
  description: string,
  changeTitle: (title: string) => void,
  changeMeta: (meta: Record<string, unknown>[]) => void,
): void => {
  changeTitle(title);
  changeMeta([
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
  ]);
};

export const useMetaData = (title: string, description?: string): void => {
  const { changeTitle, changeMeta } = useContext(HeadTagContext);

  const metaDescription = description ?? DEFAULT_META_DESCRIPTION;

  useEffect(() => {
    setMetaData(title, metaDescription, changeTitle, changeMeta);
  }, [title, metaDescription, changeTitle, changeMeta]);
};
