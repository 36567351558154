import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { QuestionResponseAnswerPending } from '../molecules/QuestionResponseAnswerPending';
import { QuestionUserFragment } from '../../gen/graphql';

interface AnswerWaitingProps {
  answerPendingUser?: QuestionUserFragment;
  answerRequestedDate: string;
  className?: string;
}

export const QuestionAnswerWaiting: React.FC<AnswerWaitingProps> = (props) => {
  return (
    <Container className={props.className}>
      <QuestionResponseAnswerPending
        answeredUser={props.answerPendingUser}
        answerRequestedDate={props.answerRequestedDate}
        huge
      />
      <ResponseButton disable={true}>返信する</ResponseButton>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:nth-of-type(n + 2) {
    margin-top: 2rem;
  }

  ${media.lessThan('medium')`
    margin: 0 -1rem;
    padding: 0 1rem;
    border: none;
  `}
`;
const ResponseButton = styled.div<{ disable?: boolean }>`
  padding: 0.875rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: ${({ disable }) => (disable ? 'rgba(0, 0, 0, 0.3)' : 'rgba(253, 60, 47, 0.87)')};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
`;
