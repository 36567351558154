import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useCreatePracticeSubmitDetailReviewCommentMutation } from '../../gen/graphql';
import { useToastsContext } from '../../context/ToastsProvider';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';

import { getApiErrorMessage } from '../../utils/graphqlError';

interface PracticeSubmitReviewCreateModalProps {
  isOpen: boolean;
  loading?: boolean;
  toggle: (nextState: boolean) => void;
  practiceSubmitID: string;
  practiceSubmitDetailID: string;
  content: string;
  passed: boolean;
  fetchPracticeSubmitForInstructor: () => Promise<void>;
}

export const PracticeSubmitReviewCreateModal: React.FC<PracticeSubmitReviewCreateModalProps> = ({
  isOpen,
  loading,
  toggle,
  practiceSubmitID,
  practiceSubmitDetailID,
  content,
  passed,
  fetchPracticeSubmitForInstructor,
}) => {
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const { showToast } = useToastsContext();

  const [createPracticeSubmitDetailReviewComment] =
    useCreatePracticeSubmitDetailReviewCommentMutation();

  const closeModal = () => toggle(false);

  const createReview = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setSubmitButtonClicked(true);
      if (content.trim().length === 0) {
        showToast(1, 'コメントを入力してください。');
        setSubmitButtonClicked(false);
        return;
      }
      try {
        await createPracticeSubmitDetailReviewComment({
          variables: {
            input: {
              practiceSubmitID: practiceSubmitID,
              practiceSubmitDetailID: practiceSubmitDetailID,
              content: content,
              passed: passed,
            },
          },
        });
        await fetchPracticeSubmitForInstructor();
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, getApiErrorMessage(e));
        return;
      } finally {
        setSubmitButtonClicked(false);
      }

      showToast(0, 'レビューの投稿が完了しました。');
      toggle(false);
    }, [
      content,
      createPracticeSubmitDetailReviewComment,
      practiceSubmitID,
      practiceSubmitDetailID,
      passed,
      fetchPracticeSubmitForInstructor,
      showToast,
      toggle,
    ]),
  );

  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={closeModal}
      loading={loading}
      header={<Header>投稿の確認</Header>}
      footer={
        <Buttons>
          <Button onClick={closeModal} disabled={submitButtonClicked} gray>
            キャンセル
          </Button>
          <Button onClick={createReview}>{passed ? '合格にする' : '差し戻す'}</Button>
        </Buttons>
      }
      width={'576px'}
    >
      <Container>
        <Content>
          <Text>
            一度合格・差し戻しにしたステータスは、変更できません。
            <br />
            {passed ? '合格' : '差し戻し'}でよろしいですか？
            <br />
            （※ただし、レビューの投稿内容の編集は可能です。）
          </Text>
        </Content>
      </Container>
    </Modal>
  );
};

const Header = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;

  margin: 0 auto;
`;

const Container = styled.div`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const Content = styled.div`
  padding: 50px 72px 75px 72px;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 24px 16px 40px 16px;
  `}
`;

const Text = styled.p`
  text-align: left;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;

  ${media.lessThan('medium')`
    text-align: center;
  `}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  ${media.lessThan('medium')`
    justify-content: center;
  `}
`;
