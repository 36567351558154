import React from 'react';
import OriginalIntercom from 'react-intercom';
import { useUser } from '../../redux/user/useUser';

export const Intercom: React.FC = () => {
  const { user } = useUser();

  return (
    <OriginalIntercom
      appID="smcnwtu0"
      user_id={user.lmsUser?.id}
      email={user.lmsUser?.personalInfo?.email}
      name={user.lmsUser?.personalInfo?.name}
    />
  );
};
