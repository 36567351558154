import React, { useState, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import { isSome } from 'fp-ts/lib/Option';

import { Button } from '../atoms/Button';
import { Spacer } from '../atoms/Spacer';
import { Modal } from '../molecules/Modal';

import {
  useUpdateLessonForInstructorMutation,
  useDeleteLessonForInstructorMutation,
  useGetLessonForInstructorQuery,
  LessonInput,
} from '../../gen/graphql';
import { getApiErrorMessage } from '../../utils/graphqlError';
import { LessonDateTimeValidation, LessonMinutesValidation } from '../../utils/FormValidation';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../context/ToastsProvider';
import { defaultErrorMessage } from '../../const/ErrorMessage';

interface UpdateModalProps {
  isOpen: boolean;
  lessonID: number;
  loading?: boolean;
  refetch: () => Promise<void>;
  onClose: () => void;
}

export interface AddParam {
  title?: string;
  startAt?: Date;
  endAt?: Date;
}

export const UpdateModal: React.FC<UpdateModalProps> = ({
  isOpen,
  lessonID,
  refetch,
  onClose,
  ...props
}): JSX.Element => {
  const [addData, setAddData] = useState<AddParam>({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [dateError, setDateError] = useState('');

  // トースト
  const { showToast } = useToastsContext();

  const [updateLesson] = useUpdateLessonForInstructorMutation();
  const [deleteLesson] = useDeleteLessonForInstructorMutation();

  // レッスン取得
  const { data: currentLesson } = useGetLessonForInstructorQuery({
    variables: {
      lessonID: lessonID,
    },
    onCompleted: (currentLessonData) => {
      // 基本的にはありえないが、optionalになっているのでチェック
      if (!currentLessonData || !currentLessonData?.lessonForInstructor) {
        return;
      }
      const tempLesson = currentLessonData?.lessonForInstructor;

      // レッスン受講履歴がある場合はデフォルト値にセット
      setAddData({
        title: tempLesson.title,
        startAt: new Date(tempLesson.startAt),
        endAt: new Date(tempLesson.endAt),
      });
      setStartDate(tempLesson.startAt ? new Date(tempLesson.startAt) : new Date());
      setEndDate(tempLesson.endAt ? new Date(tempLesson.endAt) : new Date());
      setStartTime(tempLesson.startAt ? new Date(tempLesson.startAt) : new Date());
      setEndTime(tempLesson.endAt ? new Date(tempLesson.endAt) : new Date());
    },
  });

  const lesson = currentLesson?.lessonForInstructor;

  // レッスン更新用のデータをセット
  const setLessonInput = useCallback(() => {
    const input: LessonInput = {
      title: addData.title ?? '',
      startAt: addData.startAt ? addData.startAt.toISOString() : '',
      endAt: addData.endAt ? addData.endAt.toISOString() : '',
    };

    return input;
  }, [addData]);

  const handleChangeLessonTitle: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setAddData({
      ...addData,
      title: event.target.value,
    });
  };

  function handleChangeLessonStartDate(date: Date | null) {
    if (!date) {
      return;
    }
    const startAt = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      startTime.getHours(),
      startTime.getMinutes(),
      startTime.getSeconds(),
    );
    const endAt = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      startTime.getHours() + 1,
      startTime.getMinutes(),
      startTime.getSeconds(),
    );

    setStartDate(date);
    setEndDate(endAt);
    setEndTime(endAt);

    setAddData({
      ...addData,
      startAt: startAt,
      endAt: endAt,
    });
  }

  function handleChangeLessonEndDate(date: Date | null) {
    if (!date) {
      return;
    }
    setEndDate(date);
    const endAt = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      endTime.getHours(),
      endTime.getMinutes(),
      endTime.getSeconds(),
    );
    setAddData({
      ...addData,
      endAt: endAt,
    });
  }

  function handleChangeStartTime(date: Date | null) {
    if (date) {
      const startAt = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      );
      const endAt = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        date.getHours() + 1,
        date.getMinutes(),
        date.getSeconds(),
      );

      setStartTime(date);
      setEndDate(endAt);
      setEndTime(endAt);

      setAddData({
        ...addData,
        startAt: startAt,
        endAt: endAt,
      });
    }
  }

  function handleChangeEndTime(date: Date | null) {
    if (date) {
      const endAt = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      );
      setEndTime(date);
      setAddData({
        ...addData,
        endAt: endAt,
      });
    }
  }

  const validate = useCallback((): boolean => {
    if (!addData.startAt || !addData.endAt) {
      setDateError('正しいレッスン日時を選択してください');
      return false;
    }
    const startAt = new Date(addData.startAt);
    const endAt = new Date(addData.endAt);
    if (isSome(LessonDateTimeValidation(startAt, endAt))) {
      setDateError('レッスン終了日時は、開始日時より後を指定してください');
      return false;
    }

    if (
      lesson?.course?.plan?.lessonMinutes &&
      isSome(LessonMinutesValidation(startAt, endAt, lesson?.course?.plan?.lessonMinutes))
    ) {
      setDateError(`該当コースのレッスンは${lesson?.course?.plan?.lessonMinutes / 60}時間以内です`);
      return false;
    }

    setDateError('');
    return true;
  }, [addData, lesson]);

  // レッスン更新処理
  const submit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!validate()) return;

      const input = setLessonInput();
      try {
        await updateLesson({
          variables: {
            lessonID: lessonID,
            input: input,
          },
        });
        await refetch();
      } catch (e) {
        showToast(1, getApiErrorMessage(e));
        return;
      }
      showToast(0, 'レッスンが更新されました。');
      onClose();
    }, [updateLesson, setLessonInput, showToast, onClose, lessonID, refetch, validate]),
  );

  // レッスン削除処理
  const deleteData = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      try {
        await deleteLesson({
          variables: {
            lessonID: lessonID,
          },
        });
        await refetch();
      } catch {
        showToast(1, defaultErrorMessage);
        return;
      }
      showToast(0, '専属レッスンが削除されました。');
      onClose();
    }, [deleteLesson, showToast, onClose, lessonID, refetch]),
  );

  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={onClose}
      loading={props.loading}
      width={'60vw'}
      header={<span>専属レッスン日程を登録</span>}
      footer={
        <Buttons>
          {!lesson?.completed && (
            <Button onClick={deleteData} gray>
              削除する
            </Button>
          )}
          <ButtonsInner>
            <Button onClick={onClose} gray>
              キャンセル
            </Button>
            <Button onClick={submit}>保存</Button>
          </ButtonsInner>
        </Buttons>
      }
    >
      <ModalContainer>
        <LessonDateForm>
          <label>開始日時</label>
          <div className="lesson-date-input">
            <DatePicker
              selected={startDate}
              onChange={handleChangeLessonStartDate}
              // minDate={new Date()} // 登録忘れのパターンを考慮
              dateFormat="yyyy年MM月dd日"
              disabledKeyboardNavigation
              placeholderText="日程を選択"
              className="input datepicker-date"
            />
            <DatePicker
              selected={startTime}
              onChange={handleChangeStartTime}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="開始時間"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              disabledKeyboardNavigation
              placeholderText="00:00"
              className="input datepicker-start-time"
            />
          </div>
        </LessonDateForm>
        <LessonDateForm>
          <label>終了日時</label>
          <div className="lesson-date-input">
            <DatePicker
              selected={endDate}
              onChange={handleChangeLessonEndDate}
              // minDate={new Date()} // 登録忘れのパターンを考慮
              dateFormat="yyyy年MM月dd日"
              disabledKeyboardNavigation
              placeholderText="日程を選択"
              className="input datepicker-date"
            />
            <DatePicker
              selected={endTime}
              onChange={handleChangeEndTime}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="終了時間"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              disabledKeyboardNavigation
              placeholderText="00:00"
              className="input datepocker-end-time"
            />
          </div>
        </LessonDateForm>
        <p className="err">{dateError}</p>
        <LessonTitleForm>
          <label>タイトル</label>
          <input
            className="lesson-title"
            onChange={handleChangeLessonTitle}
            placeholder="レッスンの概要が具体的にわかるタイトルを記入してください"
            value={addData.title}
          />
        </LessonTitleForm>
        {/* DatePickerの高さを確保 */}
        <Spacer height={'100px'} />
      </ModalContainer>
    </Modal>
  );
};

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;
const ButtonsInner = styled(Buttons)`
  flex: 1;
  justify-content: flex-end;
`;

const ModalContainer = styled.div`
  padding: 2rem;

  .react-datepicker-wrapper {
    width: auto;
  }

  input {
    border: solid 1px lightgray;
  }

  label {
    display: block;
    font-size: 15px;
    font-weight: bold;
    margin: 7px 0;
  }

  .lesson-title {
    display: block;
    font-size: 15px;
    width: 99%;
    height: 40px;
    border: solid 1px lightgray;
    background-color: white;
    margin: 0 auto;
  }

  .err {
    color: #e2001b;
    font-size: 0.8rem;
  }
`;
const LessonDateForm = styled.div`
  .lesson-date-input {
    display: flex;
  }
`;
const LessonTitleForm = styled.div`
  margin-top: 30px;
`;
