import { useCallback } from 'react';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { useGetNotificationUnreadCountQuery } from '../../gen/graphql';
import { useUser } from '../../redux/user/useUser';
import useLocationChange from './LocationChange';

export const useNotificationUnreadCount = (): {
  notificationUnreadCount: number;
  noticeUnreadCount: number;
  totalCount: number;
  refetch: () => Promise<void>;
} => {
  const { permissionCheck } = useUser();
  const skipQuery = !permissionCheck(FunctionType.Notification, PermissionType.Read);
  const { data, refetch } = useGetNotificationUnreadCountQuery({
    skip: skipQuery,
  });

  const notificationUnreadCount = data?.getNotificationUnreadCount.count ?? 0;
  const noticeUnreadCount = data?.noticeUnreadCount ?? 0;
  const totalCount = notificationUnreadCount + noticeUnreadCount;

  // 戻り値のPromise<ApolloQueryResult>をPromise<void>にして隠蔽したい
  const refetchUnreadCount = useCallback(async (): Promise<void> => {
    await refetch();
  }, [refetch]);

  // URLが変わるたびに取得し直す
  useLocationChange(() => {
    if (skipQuery) {
      return;
    }
    refetchUnreadCount();
  });

  return {
    notificationUnreadCount,
    noticeUnreadCount,
    totalCount,
    refetch: refetchUnreadCount,
  };
};
