import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { ReviewStatusLabels } from '../../const/PracticeSubmit';
import { PracticeSubmitDetailReviewStatus } from '../../gen/graphql';
import { isDraft, isPassed, isFailed, isWaiting } from '../../utils/PracticeSubmit';
import crossIcon from '../../static/image/cross.svg';

import circleIcon from '../../static/image/icon_circle_white.svg';

interface PracticeSubmitReviewStatusProps {
  reviewStatus: PracticeSubmitDetailReviewStatus | undefined;
}

export const PracticeSubmitReviewStatus: React.FC<PracticeSubmitReviewStatusProps> = ({
  reviewStatus,
}) => {
  return (
    <>
      {!reviewStatus ? (
        <SubmitStatus className="not-submitted">
          <span>未提出</span>
        </SubmitStatus>
      ) : isPassed(reviewStatus) ? (
        <SubmitStatus className="passed">
          <img src={circleIcon} alt="icon" width="10" height="10" />
          <span>{ReviewStatusLabels[PracticeSubmitDetailReviewStatus.Passed]}</span>
        </SubmitStatus>
      ) : isFailed(reviewStatus) ? (
        <SubmitStatus className="failed">
          <img src={crossIcon} alt="icon" width="10" height="8" />
          <span>{ReviewStatusLabels[PracticeSubmitDetailReviewStatus.Failed]}</span>
        </SubmitStatus>
      ) : isWaiting(reviewStatus) ? (
        <SubmitStatus className="waiting">
          <span>{ReviewStatusLabels[PracticeSubmitDetailReviewStatus.Waiting]}</span>
        </SubmitStatus>
      ) : isDraft(reviewStatus) ? (
        <SubmitStatus className="draft">
          <span>{ReviewStatusLabels[PracticeSubmitDetailReviewStatus.Draft]}</span>
        </SubmitStatus>
      ) : (
        <></>
      )}
    </>
  );
};

const SubmitStatus = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.1rem;
  border-radius: 0.1875rem;
  min-width: 74px;

  ${media.lessThan('medium')`
    min-width: 84px;
  `}

  span {
    font-size: 0.6875rem;
  }

  &.not-submitted {
    background: #575e71;
    color: #fff;
  }

  &.passed {
    background: #4bb89e;
    color: #fff;

    span {
      margin: 0 0 1px;
    }

    img {
      margin: 0 6px 0 0;
    }
  }

  &.failed {
    background: #e95252;
    color: #fff;

    span {
      margin: 0 0 1px;
    }

    img {
      margin: 0 6px 0 0;
    }
  }

  &.waiting {
    border: 1px solid #f09122;
    background: #fff4e7;
    color: #da8017;
  }

  &.draft {
    background: #575e71;
    color: #fff;
  }
`;
