import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../../atoms/LpInner';

import LeadImage from '../../../../static/image/lp/lead001.png';
import LeadImageSp from '../../../../static/image/lp/lead001_sp.png';

interface Props {
  onClickCV: () => void;
}

export const Lead: React.FC<Props> = (props) => {
  return (
    <LeadSection data-gtm="lead">
      <StyledInner>
        <h2>
          仲間と一緒にエンジニア
          <br />
          を目指そう
        </h2>
        <LeadLink onClick={props.onClickCV}>今すぐ無料で試す</LeadLink>
      </StyledInner>
    </LeadSection>
  );
};

const LeadSection = styled.section`
  padding: 4rem 0 2.875rem;
  background: url(${LeadImage}) center / cover no-repeat;

  h2 {
    margin-bottom: 2.5rem;
    color: #e2001b;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 1.36;
    text-align: center;

    &:before,
    &:after {
      content: '';
      display: inline-block;
      width: 40px;
      height: 2px;
      background: #e2001b;
      vertial-align: super;
    }

    &:before {
      transform: rotate(60deg);
      margin-right: 1rem;
    }
    &:after {
      transform: rotate(-60deg);
      margin-left: 1rem;
    }
  }

  ${media.lessThan('medium')`
    padding-bottom: 5.5rem;
    background: url(${LeadImageSp}) center bottom / cover no-repeat;
    text-align: center;

    h2 {
      display: inline-block;
      margin-bottom: 2.75rem;
      padding: 0 2rem;
      box-sizing: border-box;
      font-size: 1.25rem;
      line-height: 1.36;
      position: relative;

      &:before,
      &:after {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
      }

      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }
  `}

  ${media.greaterThan('medium')`
    h2 br {
      display: none;
    }
  `}
`;
const StyledInner = styled(Inner)`
  ${media.lessThan('small')`
    max-width: 332px;
    margin: 0 auto;
  `}
`;
const LeadLink = styled.div`
  display: block;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
  padding: 1.5rem 0;
  background: linear-gradient(95.07deg, #fd8258 11.86%, #fd3c2f 82.5%);
  border-radius: 4px;
  box-shadow: 0 0.25rem 0 #e2001b;
  cursor: pointer;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.11;
  text-align: center;

  ${media.lessThan('medium')`
    padding: 0.875rem 0;
    font-size: 1rem;
  `}
`;
