/* eslint @typescript-eslint/no-use-before-define: "off" */
import React, { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { BasicLayout } from '../../templates/BasicLayout';
import { useUser } from '../../../redux/user/useUser';
import { Loader } from '../../molecules/Loader';
import { useToastsContext } from '../../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { QuestionForm } from '../../organisms/QuestionForm';
import {
  useAddQuestionMutation,
  GetQuestionDocument,
  GetQuestionsDocument,
  QuestionInput,
} from '../../../gen/graphql';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { LOWER_META_TITLE } from '../../../const/Service';
import { getApiErrorMessage } from '../../../utils/graphqlError';

export const CreateQuestion = (): JSX.Element => {
  const metaTitle = `質問投稿フォーム | ${LOWER_META_TITLE}`;

  const navigate = useNavigate();

  const { showToast } = useToastsContext();
  const { user } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sendingDraft, setSendingDraft] = useState(false);

  const [addQuestion] = useAddQuestionMutation({
    refetchQueries: [GetQuestionDocument, GetQuestionsDocument],
  });

  const submit = useSafeAsyncCallback(
    useCallback(
      async (input: QuestionInput): Promise<void> => {
        setIsLoading(true);
        setSending(true);

        try {
          const { data } = await addQuestion({
            variables: {
              input: input,
            },
          });

          TagManager.dataLayer({
            dataLayer: {
              userId: user.lmsUser?.id,
              user: user.lmsUser,
              event: 'gtm-qa-que-new-sub',
              eventData: {},
            },
            dataLayerName: 'LMSDataLayer',
          });

          if (data === null) {
            throw new Error('data === null');
          }
          if (data === undefined) {
            // GraphQLではオプションで指定しない限りundefinedで返ってはこないためthrowする
            // https://www.apollographql.com/docs/react/api/react/hooks/#mutationtupletdata-tvariables-result-tuple
            throw new Error('想定外のエラー');
          }
          navigate(`/question/detail/${data.addQuestion.id}`);
        } catch (e) {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          showToast(1, getApiErrorMessage(e));
          return;
        } finally {
          setSending(false);
          setIsLoading(false);
        }
      },
      [addQuestion, navigate, showToast, user.lmsUser],
    ),
  );

  const draft = useSafeAsyncCallback(
    useCallback(
      async (input: QuestionInput): Promise<void> => {
        setIsLoading(true);
        setSendingDraft(true);

        try {
          const { data } = await addQuestion({
            variables: {
              input: input,
            },
          });

          showToast(0, '下書きを保存しました。');
          navigate(`/question/edit/${data?.addQuestion.id}`);
        } catch (e) {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          showToast(1, defaultErrorMessage);
          return;
        } finally {
          setSendingDraft(false);
          setIsLoading(false);
        }
      },
      [addQuestion, navigate, showToast],
    ),
  );

  useEffect(() => {
    return () => {
      setSending(false);
      setSendingDraft(false);
    };
  }, []);

  return (
    <BasicLayout pageTitle="Q&A" hideFooter metaTitle={metaTitle}>
      <Loader display={isLoading} />
      <QuestionForm
        sending={sending}
        sendingDraft={sendingDraft}
        onSubmit={submit}
        onDraft={draft}
        shouldShowImageAttentionModal={true}
      />
    </BasicLayout>
  );
};
