import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';

import { Button } from '../atoms/Button';
import { RichInputHandler } from '../molecules/RichInput';

import { PracticeFragment } from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { defaultErrorMessage } from '../../const/ErrorMessage';
import { useToastsContext } from '../../context/ToastsProvider';
import { PracticeSubmitInputContent } from './PracticeSubmitInputContent';
import { PracticeSubmitResponseProps } from './PracticeSubmitInputArea';
import { getApiErrorMessage } from '../../utils/graphqlError';

interface PracticeSubmitEditInputAreaProps {
  practiceSubmitID: string;
  practice: PracticeFragment;
  submit: (url: string, comment: string) => Promise<PracticeSubmitResponseProps>;
  initialUrl?: string;
  initialComment?: string;
}

export const PracticeSubmitEditInputArea: React.FC<PracticeSubmitEditInputAreaProps> = ({
  practiceSubmitID,
  practice,
  submit,
  initialUrl,
  initialComment,
}): JSX.Element => {
  const navigate = useNavigate();
  const { showToast } = useToastsContext();

  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const [url, setUrl] = useState(initialUrl ?? '');
  const commentRef = useRef<RichInputHandler>(null);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const handleClickCancel = () => navigate(`/practices/submits/${practiceSubmitID}`);

  const handleClickSubmit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setSubmitButtonClicked(true);

      if (disabledSubmit) {
        showToast(1, 'コメントを入力してください。');
        setSubmitButtonClicked(false);
        return;
      }

      try {
        const { isError, practiceSubmitID } = await submit(
          url,
          commentRef.current?.getValue() ?? '',
        );

        if (isError) {
          showToast(1, defaultErrorMessage);
          return;
        }
        showToast(0, '課題を編集しました。');
        navigate(`/practices/submits/${practiceSubmitID}`);
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, getApiErrorMessage(e));
        return;
      } finally {
        setSubmitButtonClicked(false);
      }
    }, [disabledSubmit, showToast, submit, url, navigate]),
  );

  return (
    <FormContainer>
      <PracticeSubmitInputContent
        practice={practice}
        initialUrl={initialUrl}
        initialComment={initialComment}
        url={url}
        setUrl={setUrl}
        commentRef={commentRef}
        setDisabledSubmit={setDisabledSubmit}
      />
      <ButtonArea>
        <Button onClick={handleClickCancel} disabled={submitButtonClicked} border>
          キャンセル
        </Button>
        <Button onClick={handleClickSubmit} disabled={submitButtonClicked || disabledSubmit}>
          保存する
        </Button>
      </ButtonArea>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  max-width: 1044px;
  margin: 1.5rem auto 0;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
