import React, { SyntheticEvent, useCallback } from 'react';
import styled from 'styled-components';

import BlurImg from '../../static/image/blur.png';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface BlurProps {
  message: string;
  button: string;
  backgroundColor?: string;
}

export const Blur: React.FC<BlurProps> = (props): JSX.Element => {
  const { openModal } = useCommonModal();

  const handleClickButton = useCallback(
    (e: SyntheticEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      openModal(FunctionType.TimeLineOfInstructorAndAdmin, PermissionType.Read);
    },
    [openModal],
  );

  return (
    <Container backgroundColor={props.backgroundColor}>
      <Message>{props.message}</Message>
      <Button onClick={handleClickButton}>{props.button}</Button>
    </Container>
  );
};

const Container = styled.div<{ backgroundColor?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 48px 0px;
  background-image: url(${BlurImg});
  background-repeat: no-repeat;

  ${({ backgroundColor }) =>
    backgroundColor &&
    `
      ::after {
      content: '';
      background-color: ${backgroundColor};
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      max-width: 637px;
      max-height: 188px;
    }
  `}
`;

const Message = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.87);
`;

const Button = styled.div`
  margin-top: 24px;
  padding: 15px 45px;
  background: #ffffff;
  border: 1px solid #e2001b;
  box-sizing: border-box;
  border-radius: 1px;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #e2001b;
  cursor: pointer;
`;
