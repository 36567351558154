export const FunctionType = {
  HomeForInstructorAndCoach: 'HomeForInstructorAndCoach',
  HomeForStudent: 'HomeForStudent',
  HomeForNotLogin: 'HomeForNotLogin',
  HomeForGeneral: 'HomeForGeneral',
  ShowNextTypeSpotLesson: 'ShowNextTypeSpotLesson',
  CourseProgramUserLog: 'CourseProgramUserLog',
  Exam: 'Exam',
  ExamForInstructorAndCoach: 'ExamForInstructorAndCoach',
  ChapterAuthTypeRegistered: 'ChapterAuthTypeRegistered',
  ChapterAuthTypeLight: 'ChapterAuthTypeLight',
  ChapterAuthTypeBasic: 'ChapterAuthTypeBasic',
  ChapterAuthTypePremium: 'ChapterAuthTypePremium',
  ProgramForInstructorAndCoach: 'ProgramForInstructorAndCoach',
  StudyLog: 'StudyLog',
  CourseSearchTypeAll: 'CourseSearchTypeAll',
  ProgramFeedback: 'ProgramFeedback',
  ProgramSearchForInstructorAndCoach: 'ProgramSearchForInstructorAndCoach',
  ProgramSearchForStudent: 'ProgramSearchForStudent',
  ProgramSearchForNotLogin: 'ProgramSearchForNotLogin',
  ProgramSearchForGeneral: 'ProgramSearchForGeneral',
  PracticeForInstructorAndCoach: 'PracticeForInstructorAndCoach',
  PracticeSubmit: 'PracticeSubmit',
  PracticeFeedback: 'PracticeFeedback',
  PracticeAuthTypeRegistered: 'PracticeAuthTypeRegistered',
  PracticeAuthTypeLight: 'PracticeAuthTypeLight',
  PracticeAuthTypeBasic: 'PracticeAuthTypeBasic',
  PracticeAuthTypePremium: 'PracticeAuthTypePremium',
  QuestionForInstructorAndCoach: 'QuestionForInstructorAndCoach',
  Question: 'Question',
  QuestionResolve: 'QuestionResolve',
  QuestionAnswerAndComment: 'QuestionAnswerAndComment',
  QuestionFavoriteAndAnswerFavorite: 'QuestionFavoriteAndAnswerFavorite',
  StudyReportSummary: 'StudyReportSummary',
  StudyReportForInstructorAndCoach: 'StudyReportForInstructorAndCoach',
  StudyReportForNotLogin: 'StudyReportForNotLogin',
  StudyReport: 'StudyReport',
  StudyReportComment: 'StudyReportComment',
  StudyReportFavorite: 'StudyReportFavorite',
  TimeLineForPro: 'TimeLineForPro',
  TimeLineForPlus: 'TimeLineForPlus',
  TimeLineOfInstructorAndAdmin: 'TimeLineOfInstructorAndAdmin',
  Timeline: 'Timeline',
  TimelineForInstructorAndCoach: 'TimelineForInstructorAndCoach',
  TimelineComment: 'TimelineComment',
  TimelineFavorite: 'TimelineFavorite',
  Curriculum: 'Curriculum',
  CourseForInstructorAndCoach: 'CourseForInstructorAndCoach',
  LessonForInstructor: 'LessonForInstructor',
  LessonForStudent: 'LessonForStudent',
  SessionForCoach: 'SessionForCoach',
  SessionForStudent: 'SessionForStudent',
  SpotLessonForInstructor: 'SpotLessonForInstructor',
  SpotLesson: 'SpotLesson',
  SpotLessonReserve: 'SpotLessonReserve',
  SpotLessonBuyTicket: 'SpotLessonBuyTicket',
  SpotLessonCancel: 'SpotLessonCancel',
  SpotLessonSchedule: 'SpotLessonSchedule',
  Chat: 'Chat',
  UserTypeLabel: 'UserTypeLabel',
  AccountProfile: 'AccountProfile',
  AccountProfileLabelForStudent: 'AccountProfileLabelForStudent',
  AccountProfileLabelForInstructor: 'AccountProfileLabelForInstructor',
  AccountProfileLabelForCoach: 'AccountProfileLabelForCoach',
  AccountWithDraw: 'AccountWithDraw',
  AccountCourse: 'AccountCourse',
  AccountSubscription: 'AccountSubscription',
  AccountSubscriptionSelect: 'AccountSubscriptionSelect',
  AccountSubscriptionRegister: 'AccountSubscriptionRegister',
  AccountSubscriptionLp: 'AccountSubscriptionLp',
  AccountSubscriptionPathForNotLogin: 'AccountSubscriptionPathForNotLogin',
  AccountInvoice: 'AccountInvoice',
  AccountPaymentIntent: 'AccountPaymentIntent',
  AccountPayment: 'AccountPayment',
  AccountMail: 'AccountMail',
  MailSettingsForStudent: 'MailSettingsForStudent',
  MailSettingsForInstructor: 'MailSettingsForInstructor',
  MailSettingsForCoach: 'MailSettingsForCoach',
  MailSettingsForSubscription: 'MailSettingsForSubscription',
  MailSettingsForFree: 'MailSettingsForFree',
  AccountPassword: 'AccountPassword',
  AccountAlignment: 'AccountAlignment',
  Claim: 'Claim',
  Notification: 'Notification',
  NotificationTypeBackSchoolNotificationsInstructor:
    'NotificationTypeBackSchoolNotificationsInstructor',
  TeamRegisterCreation: 'TeamRegisterCreation',
  TeamInvitationErrorForInstructor: 'TeamInvitationErrorForInstructor',
  TeamInvitationErrorForStudent: 'TeamInvitationErrorForStudent',
  TeamTop: 'TeamTop',
  TeamAuth: 'TeamAuth',
  TeamInvitation: 'TeamInvitation',
  TeamRole: 'TeamRole',
  TeamRemove: 'TeamRemove',
  TeamProfile: 'TeamProfile',
  TeamSubscription: 'TeamSubscription',
  TeamInvoice: 'TeamInvoice',
  TeamPayment: 'TeamPayment',
  BannerForInstructor: 'BannerForInstructor',
  BannerForStudent: 'BannerForStudent',
  BannerForGraduate: 'BannerForGraduate',
  BannerForSubscription: 'BannerForSubscription',
  BannerForTeam: 'BannerForTeam',
  BannerForFree: 'BannerForFree',
  BannerForNotLogin: 'BannerForNotLogin',
  AdsForFree: 'AdsForFree',
  AdsForSubscription: 'AdsForSubscription',
  DocumentForInstructor: 'DocumentForInstructor',
  DocumentForCoach: 'DocumentForCoach',
  DocumentForStudent: 'DocumentForStudent',
  DocumentForSubscription: 'DocumentForSubscription',
  DocumentForNotLogin: 'DocumentForNotLogin',
  DocumentForTeam: 'DocumentForTeam',
  RecommendFree: 'RecommendFree',
  RecommendLight: 'RecommendLight',
  RecommendBasic: 'RecommendBasic',
  RecommendPremium: 'RecommendPremium',
  RecommendTeamSubscriptionToAdmin: 'RecommendTeamSubscriptionToAdmin',
  RecommendTeamSubscription: 'RecommendTeamSubscription',
  All: 'All',
  ServiceFeedback: 'ServiceFeedback',
  RegisterRedirectHome: 'RegisterRedirectHome',
  TeamRegisterRedirectHome: 'TeamRegisterRedirectHome',
  LP: 'LP',
};
export const PermissionType = {
  Read: 'Read',
  Create: 'Create',
  Update: 'Update',
  Delete: 'Delete',
};
export const UserPermissions = {
  NOT_LOGIN: {
    HomeForNotLogin: ['Read'],
    ProgramSearchForNotLogin: ['Read'],
    StudyReportForNotLogin: ['Read'],
    TimeLineForPlus: ['Read'],
    SpotLesson: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountSubscriptionPathForNotLogin: ['Read'],
    TeamRegisterCreation: ['Create'],
    BannerForNotLogin: ['Read'],
    DocumentForNotLogin: ['Read'],
    RecommendFree: ['Read'],
    RecommendLight: ['Read'],
    RecommendBasic: ['Read'],
    RecommendPremium: ['Read'],
    All: ['Read'],
    LP: ['Read'],
  },
  SUBSCRIPTION: {
    HomeForGeneral: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForGeneral: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    PracticeAuthTypePremium: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    SpotLesson: ['Read'],
    SpotLessonReserve: ['Create'],
    SpotLessonBuyTicket: ['Create'],
    SpotLessonCancel: ['Delete'],
    AccountProfile: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountSubscription: ['Read', 'Delete'],
    AccountSubscriptionSelect: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForSubscription: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    BannerForSubscription: ['Read'],
    AdsForSubscription: ['Read'],
    DocumentForSubscription: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
    TeamRegisterRedirectHome: ['Read'],
  },
  SUBSCRIPTION_LIGHT: {
    HomeForGeneral: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForGeneral: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    SpotLesson: ['Read'],
    SpotLessonReserve: ['Create'],
    SpotLessonBuyTicket: ['Create'],
    SpotLessonCancel: ['Delete'],
    AccountProfile: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountSubscription: ['Read', 'Delete'],
    AccountSubscriptionSelect: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForSubscription: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    BannerForSubscription: ['Read'],
    AdsForSubscription: ['Read'],
    DocumentForSubscription: ['Read'],
    RecommendBasic: ['Read'],
    RecommendPremium: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
    TeamRegisterRedirectHome: ['Read'],
  },
  SUBSCRIPTION_BASIC: {
    HomeForGeneral: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForGeneral: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    SpotLesson: ['Read'],
    SpotLessonReserve: ['Create'],
    SpotLessonBuyTicket: ['Create'],
    SpotLessonCancel: ['Delete'],
    AccountProfile: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountSubscription: ['Read', 'Delete'],
    AccountSubscriptionSelect: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForSubscription: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    BannerForSubscription: ['Read'],
    AdsForSubscription: ['Read'],
    DocumentForSubscription: ['Read'],
    RecommendPremium: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
    TeamRegisterRedirectHome: ['Read'],
  },
  SUBSCRIPTION_PREMIUM: {
    HomeForGeneral: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForGeneral: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    PracticeAuthTypePremium: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    SpotLesson: ['Read'],
    SpotLessonReserve: ['Create'],
    SpotLessonBuyTicket: ['Create'],
    SpotLessonCancel: ['Delete'],
    AccountProfile: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountSubscription: ['Read', 'Delete'],
    AccountSubscriptionSelect: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForSubscription: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    BannerForSubscription: ['Read'],
    AdsForSubscription: ['Read'],
    DocumentForSubscription: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
    TeamRegisterRedirectHome: ['Read'],
  },
  SUBSCRIPTION_FREE: {
    HomeForGeneral: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForGeneral: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    SpotLesson: ['Read'],
    AccountProfile: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountSubscription: ['Read', 'Create'],
    AccountSubscriptionRegister: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForFree: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    BannerForFree: ['Read'],
    AdsForFree: ['Read'],
    DocumentForSubscription: ['Read'],
    RecommendLight: ['Read'],
    RecommendBasic: ['Read'],
    RecommendPremium: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  TEAM_MEMBER: {
    HomeForGeneral: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForGeneral: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    PracticeAuthTypePremium: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    SpotLesson: ['Read'],
    SpotLessonReserve: ['Create'],
    SpotLessonCancel: ['Delete'],
    AccountProfile: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountSubscription: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForSubscription: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamTop: ['Read'],
    BannerForTeam: ['Read'],
    DocumentForSubscription: ['Read'],
    DocumentForTeam: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  TEAM_MEMBER_FREE: {
    HomeForGeneral: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForGeneral: ['Read'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    StudyReportSummary: ['Read'],
    TimeLineForPlus: ['Read'],
    SpotLesson: ['Read'],
    AccountProfile: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountSubscription: ['Read', 'Create'],
    AccountSubscriptionRegister: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForFree: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamTop: ['Read'],
    BannerForTeam: ['Read'],
    DocumentForSubscription: ['Read'],
    DocumentForTeam: ['Read'],
    RecommendTeamSubscriptionToAdmin: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  TEAM_ADMIN: {
    HomeForGeneral: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForGeneral: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    PracticeAuthTypePremium: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    SpotLesson: ['Read'],
    SpotLessonReserve: ['Create'],
    SpotLessonCancel: ['Delete'],
    AccountProfile: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountSubscription: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForSubscription: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamTop: ['Read'],
    TeamAuth: ['Read'],
    TeamInvitation: ['Create'],
    TeamRole: ['Update'],
    TeamRemove: ['Delete'],
    TeamProfile: ['Read', 'Update'],
    TeamSubscription: ['Read', 'Update', 'Delete'],
    TeamInvoice: ['Read'],
    TeamPayment: ['Read', 'Create', 'Update', 'Delete'],
    BannerForTeam: ['Read'],
    DocumentForSubscription: ['Read'],
    DocumentForTeam: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  TEAM_ADMIN_FREE: {
    HomeForGeneral: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForGeneral: ['Read'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    StudyReportSummary: ['Read'],
    TimeLineForPlus: ['Read'],
    SpotLesson: ['Read'],
    AccountProfile: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountSubscription: ['Read', 'Create'],
    AccountSubscriptionRegister: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForFree: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamTop: ['Read'],
    TeamAuth: ['Read'],
    TeamInvitation: ['Create'],
    TeamRole: ['Update'],
    TeamRemove: ['Delete'],
    TeamProfile: ['Read', 'Update'],
    TeamSubscription: ['Read', 'Create', 'Update', 'Delete'],
    TeamInvoice: ['Read'],
    TeamPayment: ['Read', 'Create', 'Update', 'Delete'],
    BannerForTeam: ['Read'],
    DocumentForSubscription: ['Read'],
    DocumentForTeam: ['Read'],
    RecommendTeamSubscription: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  INSTRUCTOR: {
    HomeForInstructorAndCoach: ['Read'],
    ExamForInstructorAndCoach: ['Read'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    ProgramForInstructorAndCoach: ['Read'],
    CourseSearchTypeAll: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForInstructorAndCoach: ['Read'],
    PracticeForInstructorAndCoach: ['Read', 'Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    PracticeAuthTypePremium: ['Read'],
    QuestionForInstructorAndCoach: ['Read'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportForInstructorAndCoach: ['Read'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPro: ['Read'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    TimelineForInstructorAndCoach: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    CourseForInstructorAndCoach: ['Read'],
    LessonForInstructor: ['Read', 'Create', 'Update'],
    SpotLessonForInstructor: ['Read'],
    SpotLessonSchedule: ['Read'],
    Chat: ['Read', 'Create', 'Update', 'Delete'],
    UserTypeLabel: ['Read'],
    AccountProfile: ['Read'],
    AccountProfileLabelForInstructor: ['Read'],
    AccountMail: ['Update'],
    MailSettingsForInstructor: ['Read'],
    AccountPassword: ['Update'],
    AccountAlignment: ['Read'],
    Claim: ['Read'],
    Notification: ['Read'],
    NotificationTypeBackSchoolNotificationsInstructor: ['Read'],
    TeamInvitationErrorForInstructor: ['Read'],
    BannerForInstructor: ['Read'],
    DocumentForInstructor: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
    RegisterRedirectHome: ['Read'],
    TeamRegisterRedirectHome: ['Read'],
  },
  COACH: {
    HomeForInstructorAndCoach: ['Read'],
    ExamForInstructorAndCoach: ['Read'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    ProgramForInstructorAndCoach: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForInstructorAndCoach: ['Read'],
    PracticeForInstructorAndCoach: ['Read', 'Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    PracticeAuthTypePremium: ['Read'],
    QuestionForInstructorAndCoach: ['Read'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportForInstructorAndCoach: ['Read'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPro: ['Read'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    TimelineForInstructorAndCoach: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    CourseForInstructorAndCoach: ['Read'],
    SessionForCoach: ['Read', 'Create', 'Update'],
    Chat: ['Read', 'Create', 'Update', 'Delete'],
    UserTypeLabel: ['Read'],
    AccountProfile: ['Read'],
    AccountProfileLabelForCoach: ['Read'],
    AccountMail: ['Update'],
    MailSettingsForCoach: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    BannerForInstructor: ['Read'],
    DocumentForCoach: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
    RegisterRedirectHome: ['Read'],
    TeamRegisterRedirectHome: ['Read'],
  },
  STUDENT: {
    HomeForStudent: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForStudent: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    PracticeAuthTypePremium: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPro: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    Curriculum: ['Read'],
    LessonForStudent: ['Read'],
    SessionForStudent: ['Read'],
    Chat: ['Read', 'Create', 'Update', 'Delete'],
    AccountProfile: ['Read'],
    AccountProfileLabelForStudent: ['Read'],
    AccountCourse: ['Read'],
    AccountSubscription: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForStudent: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamInvitationErrorForStudent: ['Read'],
    BannerForStudent: ['Read'],
    DocumentForStudent: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
    RegisterRedirectHome: ['Read'],
    TeamRegisterRedirectHome: ['Read'],
  },
  STUDENT_GRADUATED: {
    HomeForStudent: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForStudent: ['Read'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    Question: ['Read'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPro: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    Curriculum: ['Read'],
    LessonForStudent: ['Read'],
    SessionForStudent: ['Read'],
    SpotLesson: ['Read'],
    Chat: ['Read'],
    AccountProfile: ['Read'],
    AccountProfileLabelForStudent: ['Read'],
    AccountCourse: ['Read'],
    AccountSubscription: ['Read', 'Create'],
    AccountSubscriptionRegister: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForStudent: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamInvitationErrorForStudent: ['Read'],
    BannerForGraduate: ['Read'],
    DocumentForStudent: ['Read'],
    RecommendLight: ['Read'],
    RecommendBasic: ['Read'],
    RecommendPremium: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  STUDENT_SUBSCRIPTION: {
    HomeForStudent: ['Read'],
    ShowNextTypeSpotLesson: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForStudent: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    PracticeAuthTypePremium: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPro: ['Read'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    Curriculum: ['Read'],
    LessonForStudent: ['Read'],
    SessionForStudent: ['Read'],
    SpotLesson: ['Read'],
    SpotLessonReserve: ['Create'],
    SpotLessonBuyTicket: ['Create'],
    SpotLessonCancel: ['Delete'],
    Chat: ['Read'],
    AccountProfile: ['Read'],
    AccountProfileLabelForStudent: ['Read'],
    AccountCourse: ['Read'],
    AccountSubscription: ['Read', 'Create', 'Delete'],
    AccountSubscriptionSelect: ['Read'],
    AccountSubscriptionRegister: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForStudent: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamInvitationErrorForStudent: ['Read'],
    BannerForSubscription: ['Read'],
    DocumentForStudent: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  STUDENT_SUBSCRIPTION_LIGHT: {
    HomeForStudent: ['Read'],
    ShowNextTypeSpotLesson: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForStudent: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPro: ['Read'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    Curriculum: ['Read'],
    LessonForStudent: ['Read'],
    SessionForStudent: ['Read'],
    SpotLesson: ['Read'],
    SpotLessonReserve: ['Create'],
    SpotLessonBuyTicket: ['Create'],
    SpotLessonCancel: ['Delete'],
    Chat: ['Read'],
    AccountProfile: ['Read'],
    AccountProfileLabelForStudent: ['Read'],
    AccountCourse: ['Read'],
    AccountSubscription: ['Read', 'Create', 'Delete'],
    AccountSubscriptionSelect: ['Read'],
    AccountSubscriptionRegister: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForStudent: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamInvitationErrorForStudent: ['Read'],
    BannerForSubscription: ['Read'],
    DocumentForStudent: ['Read'],
    RecommendBasic: ['Read'],
    RecommendPremium: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  STUDENT_SUBSCRIPTION_BASIC: {
    HomeForStudent: ['Read'],
    ShowNextTypeSpotLesson: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForStudent: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPro: ['Read'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    Curriculum: ['Read'],
    LessonForStudent: ['Read'],
    SessionForStudent: ['Read'],
    SpotLesson: ['Read'],
    SpotLessonReserve: ['Create'],
    SpotLessonBuyTicket: ['Create'],
    SpotLessonCancel: ['Delete'],
    Chat: ['Read'],
    AccountProfile: ['Read'],
    AccountProfileLabelForStudent: ['Read'],
    AccountCourse: ['Read'],
    AccountSubscription: ['Read', 'Create', 'Delete'],
    AccountSubscriptionSelect: ['Read'],
    AccountSubscriptionRegister: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForStudent: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamInvitationErrorForStudent: ['Read'],
    BannerForSubscription: ['Read'],
    DocumentForStudent: ['Read'],
    RecommendPremium: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  STUDENT_SUBSCRIPTION_PREMIUM: {
    HomeForStudent: ['Read'],
    ShowNextTypeSpotLesson: ['Read'],
    CourseProgramUserLog: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    ChapterAuthTypeLight: ['Read'],
    ChapterAuthTypeBasic: ['Read'],
    ChapterAuthTypePremium: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForStudent: ['Read'],
    PracticeSubmit: ['Create', 'Update'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    PracticeAuthTypeLight: ['Read'],
    PracticeAuthTypeBasic: ['Read'],
    PracticeAuthTypePremium: ['Read'],
    Question: ['Read', 'Create', 'Update'],
    QuestionResolve: ['Update'],
    QuestionAnswerAndComment: ['Create', 'Update'],
    QuestionFavoriteAndAnswerFavorite: ['Update'],
    StudyReportSummary: ['Read'],
    StudyReport: ['Create', 'Update', 'Delete'],
    StudyReportComment: ['Create', 'Update', 'Delete'],
    StudyReportFavorite: ['Update'],
    TimeLineForPro: ['Read'],
    TimeLineForPlus: ['Read'],
    TimeLineOfInstructorAndAdmin: ['Read'],
    Timeline: ['Create', 'Delete'],
    TimelineComment: ['Create', 'Delete'],
    TimelineFavorite: ['Update'],
    Curriculum: ['Read'],
    LessonForStudent: ['Read'],
    SessionForStudent: ['Read'],
    SpotLesson: ['Read'],
    SpotLessonReserve: ['Create'],
    SpotLessonBuyTicket: ['Create'],
    SpotLessonCancel: ['Delete'],
    Chat: ['Read'],
    AccountProfile: ['Read'],
    AccountProfileLabelForStudent: ['Read'],
    AccountCourse: ['Read'],
    AccountSubscription: ['Read', 'Create', 'Delete'],
    AccountSubscriptionSelect: ['Read'],
    AccountSubscriptionRegister: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForStudent: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamInvitationErrorForStudent: ['Read'],
    BannerForSubscription: ['Read'],
    DocumentForStudent: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  STUDENT_FREE: {
    HomeForStudent: ['Read'],
    ShowNextTypeSpotLesson: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForStudent: ['Read'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    StudyReportSummary: ['Read'],
    Curriculum: ['Read'],
    SpotLesson: ['Read'],
    Chat: ['Read'],
    AccountProfile: ['Read'],
    AccountProfileLabelForStudent: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountCourse: ['Read'],
    AccountSubscription: ['Read', 'Create'],
    AccountSubscriptionRegister: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForStudent: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    TeamInvitationErrorForStudent: ['Read'],
    BannerForFree: ['Read'],
    AdsForFree: ['Read'],
    DocumentForStudent: ['Read'],
    RecommendLight: ['Read'],
    RecommendBasic: ['Read'],
    RecommendPremium: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
  ADMIN: {
    HomeForGeneral: ['Read'],
    Exam: ['Read', 'Create'],
    ChapterAuthTypeRegistered: ['Read'],
    StudyLog: ['Read'],
    ProgramFeedback: ['Create'],
    ProgramSearchForGeneral: ['Read'],
    PracticeFeedback: ['Create'],
    PracticeAuthTypeRegistered: ['Read'],
    StudyReportSummary: ['Read'],
    SpotLesson: ['Read'],
    AccountProfile: ['Read'],
    AccountWithDraw: ['Delete'],
    AccountSubscription: ['Read', 'Create'],
    AccountSubscriptionRegister: ['Read'],
    AccountSubscriptionLp: ['Read'],
    AccountInvoice: ['Read'],
    AccountPaymentIntent: ['Read'],
    AccountPayment: ['Read', 'Create', 'Update', 'Delete'],
    AccountMail: ['Update'],
    MailSettingsForFree: ['Read'],
    AccountPassword: ['Update'],
    Notification: ['Read'],
    BannerForFree: ['Read'],
    DocumentForSubscription: ['Read'],
    RecommendLight: ['Read'],
    RecommendBasic: ['Read'],
    RecommendPremium: ['Read'],
    All: ['Read'],
    ServiceFeedback: ['Create'],
  },
};
