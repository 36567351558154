import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { EmptyBlock } from '../molecules/EmptyBlock';
import { SearchArticle } from './SearchArticle';
import Pagination from './Pagination';

import { DocumentListFragment } from '../../gen/graphql';

import IconFilter from '../../static/image/icon_filter.svg';

interface Props {
  items?: Array<DocumentListFragment>;
  total: number;
  page: number;
  perPage: number;
  setPage: (page: number) => void;
  openModal: () => void;
  className?: string;
}

export const SearchList: React.FC<Props> = (props) => {
  return (
    <Container className={props.className}>
      {props.total > 0 ? (
        <>
          <FilterSection>
            <Title>検索結果：{props.total}件</Title>
            <Filter src={IconFilter} onClick={props.openModal} />
          </FilterSection>
          <List>
            {props.items?.map((item) => {
              return <StyledSearchArticle item={item} />;
            })}
          </List>
          <Pagination
            total={props.total}
            perPage={props.perPage}
            page={props.page}
            setPage={props.setPage}
          />
        </>
      ) : (
        <>
          <FilterSection>
            <Filter src={IconFilter} onClick={props.openModal} />
          </FilterSection>
          <EmptyBlock title="指定のワードの検索結果はありません">
            類似の検索ワードやタグで見つかる場合もあります。
            <br />
            方法を変更して再度検索してみましょう。
          </EmptyBlock>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 2.25rem;

  ${media.lessThan('medium')`
    margin: 0;
  `}
`;
const Title = styled.h2`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.1px;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    gap: 0;
    margin: 0 -1rem;
    border-bottom: 1px solid rgba(0,0,0,.1);
  `}
`;

const StyledSearchArticle = styled(SearchArticle)`
  ${media.lessThan('medium')`
    border: none;
    border-top: 1px solid rgba(0,0,0,.1);
  `}
`;

const FilterSection = styled.div`
  ${media.lessThan('medium')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: .75rem 0;
  `}
`;
const Filter = styled.img`
  display: none;

  ${media.lessThan('medium')`
    display: block;
    width: 1.25rem;
    height: auto;
    margin-left: auto;
    cursor: pointer;
  `}
`;
