import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { SubscriptionLayout } from '../../templates/SubscriptionLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { AccountSubscriptionSchedule } from '../../organisms/AccountSubscriptionSchedule';
import { AccountSubscriptionUpdateCard } from '../../organisms/AccountSubscriptionUpdateCard';
import { AccountSubscriptionUpdatedInvoice } from '../../organisms/AccountSubscriptionUpdatedInvoice';

import {
  useGetTicketsQuery,
  useSubscriptionQuery,
  useMySubscriptionContractsQuery,
  useCheckUpgradeQuery,
  SubscriptionUpgradeType,
  useGetUpcomingInvoiceQuery,
  useUpdateSubscriptionMutation,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../../context/ToastsProvider';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { Loader } from '../../molecules/Loader';
import directionRight from '../../../static/image/direction_right.svg';
import { Button } from '../../atoms/Button';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { LOWER_META_TITLE } from '../../../const/Service';
import { CANCELLATION_FAQ_LINK } from '../../../const/Link';

export const AccountSubscriptionSelectSubmit: React.FC = () => {
  const metaTitle = `加入プラン | ${LOWER_META_TITLE}`;

  const { showToast } = useToastsContext();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const params = useParams<{ price_id: string }>();
  const priceID = Number(params.price_id) ?? 0;

  const formatDate = (date?: string) => {
    if (!date) return '';
    return format(parseISO(date), 'yyyy/MM/dd HH:mm:ss');
  };

  const { data: ticketData, loading: ticketLoading } = useGetTicketsQuery();
  const tickets = ticketData?.tickets ?? [];
  const numberOfTickets = tickets.length ?? 0;

  const { data: subscriptionData, loading: subscriptionLoading } = useSubscriptionQuery({
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
  });
  const subscription = subscriptionData?.subscription ?? undefined;

  // 契約後のPlan情報取得
  const { data: checkUpgradeData, loading: checkUpgradeLoading } = useCheckUpgradeQuery({
    fetchPolicy: 'no-cache', // 更新後キャッシュされないようにする
    variables: {
      priceID: priceID,
    },
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
  });
  const upgradePlan = checkUpgradeData?.checkUpgrade;

  // 最新の契約情報のみを取得
  const { loading: currentContractLoading, ...currentContractData } =
    useMySubscriptionContractsQuery({
      variables: {
        input: {
          page: 1,
          limit: 1,
        },
      },
      onError: () => {
        showToast(1, defaultErrorMessage);
      },
    });
  const currentSubscriptionContract =
    (currentContractData.data?.mySubscriptionContracts?.items.length ?? 0) > 0
      ? currentContractData?.data?.mySubscriptionContracts?.items[0]
      : undefined;

  const { loading: invoiceLoading, ...invoiceData } = useGetUpcomingInvoiceQuery({
    variables: {
      input: {
        priceID: priceID,
      },
    },
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
  });
  const stripeInvoice = invoiceData.data?.upcomingInvoice ?? undefined;

  const [updateSubscriptionMutation] = useUpdateSubscriptionMutation();
  const updateSubscription = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      // 保存
      try {
        await updateSubscriptionMutation({
          variables: {
            input: {
              priceID: priceID,
            },
          },
        });

        navigate('/account/subscription');
        showToast(
          0,
          'サブスクリプション更新しました。画面に反映されるまでにしばらく時間がかかります。',
        );
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, '更新に失敗しました。');
        return;
      } finally {
        setShowLoader(false);
      }
    }, [updateSubscriptionMutation, priceID, navigate, showToast]),
  );

  const loading =
    ticketLoading ||
    subscriptionLoading ||
    currentContractLoading ||
    checkUpgradeLoading ||
    invoiceLoading;

  return (
    <React.Fragment>
      <Loader display={loading || showLoader}></Loader>
      <SubscriptionLayout metaTitle={metaTitle}>
        <PageWrapper>
          <Container>
            <Title>プラン変更申請確認</Title>
            <Description>
              {upgradePlan?.subscriptionUpgradeType == SubscriptionUpgradeType.Immediate ? (
                <div>
                  プラン変更は即時で切り替わります。
                  <br />
                  <Notice>現プランの残存期間に関しては秒割り計算し返金いたします。</Notice>
                </div>
              ) : (
                <div>
                  プラン変更の申請手続きを確定します。よろしいですか?
                  <br />
                  プラン変更は次回更新時（
                  {formatDate(currentSubscriptionContract?.periodEnd)}
                  ）に自動的に行われます。
                </div>
              )}
            </Description>
            <PlanContent>
              <PlanDetail>
                <AccountSubscriptionUpdateCard
                  isNextPlan={false}
                  productName={subscription?.price?.product.name ?? ''}
                  unitAmount={subscription?.price?.unitAmount ?? 0}
                  numberOfTickets={subscription?.price?.numberOfTickets ?? 0}
                  startDate={currentSubscriptionContract?.periodStart}
                  endDate={currentSubscriptionContract?.periodEnd}
                  pricePermission={subscription?.price?.pricePermission ?? undefined}
                />
                <div>
                  <img src={directionRight} />
                </div>
                <AccountSubscriptionUpdateCard
                  isNextPlan={true}
                  productName={upgradePlan?.price?.product.name ?? ''}
                  unitAmount={upgradePlan?.price?.unitAmount ?? 0}
                  numberOfTickets={upgradePlan?.price?.numberOfTickets ?? 0}
                  startDate={upgradePlan?.startDate}
                  endDate={upgradePlan?.endDate}
                  pricePermission={upgradePlan?.price?.pricePermission ?? undefined}
                />
              </PlanDetail>
              <PlanDescription>
                ※ 現在お持ちのレッスンチケット[{numberOfTickets}
                枚]は、引き続き有効期限までご使用いただけます。
                <br />
                「特定商取引法に基づく表記」は
                <a href="https://www.sejuku.net/corp/legal" target="_blank">
                  こちら
                </a>
                をご覧ください。
                <br />※ 解約方法については
                <a href={CANCELLATION_FAQ_LINK} target="_blank">
                  こちら
                </a>
                をご覧ください。
              </PlanDescription>
            </PlanContent>
            <AccountSubscriptionSchedule
              confirm={true}
              subscriptionUpgradeType={upgradePlan?.subscriptionUpgradeType}
              currentPeriodStart={currentSubscriptionContract?.periodStart}
              nextPeriodStart={upgradePlan?.startDate}
              nextPeriodEnd={upgradePlan?.endDate}
              nextPlanName={upgradePlan?.price?.product.name ?? ''}
            />
            {upgradePlan?.subscriptionUpgradeType == SubscriptionUpgradeType.Immediate && (
              <AccountSubscriptionUpdatedInvoice
                oldPriceInvoice={stripeInvoice?.oldPriceInvoice ?? undefined}
                nextPriceAmount={upgradePlan.price?.unitAmount ?? 0}
              />
            )}
            <ButtonWrapper>
              <CancelButton to="/account/subscription">取り消す</CancelButton>
              <SubmitButton onClick={updateSubscription}>変更予約を行う</SubmitButton>
            </ButtonWrapper>
          </Container>
        </PageWrapper>
      </SubscriptionLayout>
    </React.Fragment>
  );
};

const Title = styled.h2`
  color: rgba(0, 0, 0, 0.87);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1.375rem;
  `}
`;

const Description = styled.div`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.8;
  `}
`;

const Container = styled.div`
  margin: 1rem auto 1rem;
  display: flex;
  flex-direction: column;
`;

const PlanContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanDescription = styled.p`
  margin: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.8;
  `}

  a {
    color: #1a0dab;
  }
`;

const PlanDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmitButton = styled(Button)`
  margin-left: 2rem;
`;

const CancelButton = styled(Link)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
`;

const Notice = styled.span`
  color: #eb0000;
  font-weight: bold;
`;
