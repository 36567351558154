import { useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { BasicLayout } from '../../templates/BasicLayout';
import { Button } from '../../atoms/Button';

import {
  useCreateProgramFeedbackMutation,
  useGetPracticeQuery,
  FeedbackCategoryType,
} from '../../../gen/graphql';
import { useToastsContext } from '../../../context/ToastsProvider';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { NotFoundPage } from '../public/NotFound';
import ForbiddenPage from '../public/Forbidden';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import { FormProvider, useForm } from 'react-hook-form';
import { FeedbackForm, FeedbackFormValue } from '../../organisms/FeedbackForm';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { yupResolver } from '@hookform/resolvers/yup';
import { feedbackSchema } from '../../../common/formSchema/feedback';

export const PracticeFeedbackContainer = (): JSX.Element => {
  const params = useParams<{ practice_id: string }>();
  const pathPracticeID = params.practice_id ?? '';
  if (pathPracticeID === '') return NotFoundPage;

  return <PracticeFeedback practiceID={pathPracticeID} />;
};

interface PracticeFeedbackProps {
  practiceID: string;
}

const PracticeFeedback = (props: PracticeFeedbackProps): JSX.Element => {
  const metaTitle = `ご意見・ご要望 | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}の課題ページです。課題にチャレンジすることでアウトプット力が鍛えられスキルを定着させることができます。課題を単純に解くだけではなく、提出&レビューを受けることでより知識が磨かれます。`;

  const { showToast } = useToastsContext();

  const [createProgramFeedback] = useCreateProgramFeedbackMutation();
  const navigate = useNavigate();

  const { data: practiceData, loading: practiceLoading } = useGetPracticeQuery({
    variables: {
      practiceID: props.practiceID,
    },
    onError: () => {
      // 不正なidの場合教材トップにリダイレクト
      return <Navigate to="/practices" replace />;
    },
  });
  const practice = useMemo(() => practiceData?.practice, [practiceData?.practice]);

  const methods = useForm<FeedbackFormValue>({
    defaultValues: {
      category: '0',
      content: '',
    },
    resolver: yupResolver(feedbackSchema),
  });

  const submit = async (data: FeedbackFormValue) => {
    try {
      await createProgramFeedback({
        variables: {
          input: {
            practiceID: props.practiceID,
            feedbackCategoryID: parseInt(data.category),
            content: data.content,
          },
        },
      });
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
      showToast(1, defaultErrorMessage);
    }
  };

  // 課題データ取得できない場合は403へ
  if (!practice && !practiceLoading) return ForbiddenPage;

  const breadcrumbs = [
    {
      label: '課題TOP',
      to: '/practices',
    },
    {
      label: practice?.title ?? '',
      to: practice?.id ? `/practices/${practice.id}/body` : '',
    },
    {
      label: 'ご意見・ご要望',
    },
  ];
  return (
    <>
      <BasicLayout pageTitle="課題" metaTitle={metaTitle} metaDescription={metaDescription}>
        <Container>
          <StyledBreadcrumb links={breadcrumbs} />
          <FormProvider {...methods}>
            <FeedbackForm
              title="この章へのご意見・ご要望をお聞かせください"
              onSubmit={submit}
              type={FeedbackCategoryType.Program}
              backButton={
                <Submit onClick={() => navigate(`/practices/${props.practiceID}/body`)} border>
                  <p>課題に戻る</p>
                </Submit>
              }
            />
          </FormProvider>
        </Container>
      </BasicLayout>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 892px;
  margin: 2rem auto;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    flex-direction: column;
    padding: 2rem 0;
  `}
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 1rem;
`;

const Submit = styled(Button)`
  display: block;
  width: 100%;
  max-width: 320px;
  margin: 2rem auto 0;
`;
