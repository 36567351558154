import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../common/customHooks/OutsideClick';

interface PopupMenuProps {
  openRef: React.MutableRefObject<HTMLElement | null>;
}

export const PopupMenu: React.FC<PopupMenuProps> = (props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick(props.openRef, menuRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (props.openRef.current) props.openRef.current.onclick = () => setIsOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Div ref={menuRef} isOpen={isOpen}>
      {props.children}
    </Div>
  );
};

const Div = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;
