import React, { memo } from 'react';
import { areEqual } from 'react-window';
import memoize from 'memoize-one';
import styled from 'styled-components';

import { useUser } from '../../redux/user/useUser';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ChatRoom } from '../molecules/ChatRoom';
import { getChatRoomIcon, getChatRoomName } from '../../utils/ChatRoom';
import { ChatMemberListFragment, ChatRoomListFragment } from '../../gen/graphql';

interface ChatRoomListRowData {
  chatRoomId: number;
  chatRooms: ChatRoomListFragment[];
  isChatRoomLoaded: (index: number) => boolean;
  onChangeRoom: (chatRoomId: number, chatMembers: ChatMemberListFragment[]) => void;
}

export const createChatRoomListRowData = memoize(
  (chatRoomId, chatRooms, isChatRoomLoaded, onChangeRoom) => ({
    chatRoomId,
    chatRooms,
    isChatRoomLoaded,
    onChangeRoom,
  }),
);

export const ChatRoomListRow = memo(
  ({
    data,
    index,
    style,
  }: {
    data: ChatRoomListRowData;
    index: number;
    style: React.CSSProperties;
  }) => {
    const { user } = useUser();

    const { chatRoomId, chatRooms, isChatRoomLoaded, onChangeRoom } = data;

    // notice
    // 素早くスクロールした際に、間に合わない場合があり
    // エラーになってしまうのを防いでいます。
    if (!chatRooms[index]) {
      return <></>;
    }

    const { id, unreadCount, createdAt, chatMembers, lastMessage } = chatRooms[index];

    return (
      <div style={style}>
        {!isChatRoomLoaded(index) ? (
          <Loader>
            <CircularProgress />
          </Loader>
        ) : (
          <ChatRoom
            key={index}
            id={id}
            icon={getChatRoomIcon(chatRooms[index], user.lmsUser)}
            userName={getChatRoomName(chatRooms[index].chatMembers, user.lmsUser)}
            recentMessage={lastMessage ? lastMessage.content : ''}
            date={lastMessage ? new Date(lastMessage.createdAt) : new Date(createdAt)}
            unreadCount={unreadCount}
            currentRoomID={chatRoomId}
            changeRoom={onChangeRoom}
            chatMembers={chatMembers}
            e2e={`chat-room-${index}`}
          />
        )}
      </div>
    );
  },
  areEqual,
);

const Loader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
