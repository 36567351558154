import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';

export type AlertSeverity = 'success' | 'info' | 'warning' | 'error';
interface Props {
  severity: AlertSeverity;
  title: string;
  link?: string;
  linkLabel?: string;
  className?: string;
}

const backgroundColors: { [key: string]: string } = {
  error: '#fff9f9',
  warning: '#fef4e4',
  info: '#e5f6fd',
  success: '#edf7ed',
};

const colors: { [key: string]: string } = {
  error: '#eb0000',
  warning: '#ff9800',
  info: '#03a9f4',
  success: '#4caf50',
};

export const Alert: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (!props.link) return;

    if (props.link.match(/^http/)) {
      window.open(props.link, '_blank');
    } else {
      navigate(props.link);
    }
  };

  // childrenがテキストのみ、またはbrタグのみの場合、childrenを囲むタグをpタグにする
  const isText = React.Children.toArray(props.children).every(
    (child) => typeof child === 'string' || (React.isValidElement(child) && child.type === 'br'),
  );

  return (
    <Container severity={props.severity} className={props.className}>
      <Text severity={props.severity}>
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <circle cx="10" cy="10" r="10" />
          <path
            d="M11.0279 12.2339H8.8859L8.4359 3.64795H11.4779L11.0279 12.2339ZM8.3999 15.24C8.3999 14.688 8.5499 14.3039 8.8499 14.0879C9.1499 13.8599 9.5159 13.7459 9.9479 13.7459C10.3679 13.7459 10.7279 13.8599 11.0279 14.0879C11.3279 14.3039 11.4779 14.688 11.4779 15.24C11.4779 15.768 11.3279 16.152 11.0279 16.392C10.7279 16.62 10.3679 16.7339 9.9479 16.7339C9.5159 16.7339 9.1499 16.62 8.8499 16.392C8.5499 16.152 8.3999 15.768 8.3999 15.24Z"
            fill="white"
          />
        </svg>
        <StyledH3 severity={props.severity}>{props.title}</StyledH3>
        {isText ? <Content as="p">{props.children}</Content> : <Content>{props.children}</Content>}
      </Text>
      {props.link && props.linkLabel && (
        <Button onClick={handleButtonClick} severity={props.severity}>
          {props.linkLabel}
        </Button>
      )}
    </Container>
  );
};

const Container = styled.div<{ severity: string }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background: ${(props) => backgroundColors[props.severity]};
  border-radius: 4px;

  ${media.lessThan('medium')`
    display: block;
    text-align: right;
  `}
`;
const Text = styled.div<{ severity: string }>`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem 0.75rem;

  svg {
    height: 1rem;
    width: 1rem;
    fill: ${(props) => colors[props.severity]};
  }

  ${media.lessThan('medium')`
    text-align: left;
  `}
`;
const StyledH3 = styled.h3<{ severity: string }>`
  flex: 1;
  color: ${(props) => colors[props.severity]};
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
`;
const Content = styled.div`
  width: 100%;
  padding-left: 28px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.8125rem;
  line-height: 1.45;
  letter-spacing: 0.9px;
`;

const Button = styled.button<{ severity: string }>`
  display: block;
  padding: 8px 21px;
  background: ${(props) => colors[props.severity]};
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 0.8125rem;
  line-height: 1.5;
  letter-spacing: 0.9px;

  ${media.lessThan('medium')`
    display: inline-block;
    margin-top: 1.5rem;
  `}
`;
