import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format, parseISO } from 'date-fns';

import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';
import {
  StripeSubscriptionFragment,
  SubscriptionCancellationPeriodFragment,
  SubscriptionContractFragment,
} from '../../gen/graphql';
import { UPGRADE_PAGE_URL } from '../../const/helpPageUrl';

// このコンポーネントで表示する日付のフォーマット(画面によって違うので共通化はしない)
const formatDate = (dateString: string) => format(parseISO(dateString), 'yyyy/MM/dd');
const formatDateTime = (dateString: string) => format(parseISO(dateString), 'yyyy/MM/dd HH:mm:ss');
interface Props {
  isOpen: boolean;
  subscription: StripeSubscriptionFragment;
  currentSubscriptionContract?: SubscriptionContractFragment;
  subscriptionCancellationPeriod?: SubscriptionCancellationPeriodFragment;
  loading?: boolean;
  openQuestionnaireForm: () => void;
  onClose: () => void;
}

export const SubscriptionCancelModal: React.FC<Props> = ({
  isOpen,
  subscription,
  currentSubscriptionContract,
  subscriptionCancellationPeriod,
  loading,
  openQuestionnaireForm,
  onClose,
}): JSX.Element => {
  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={onClose}
      loading={loading}
      width={'572px'}
      header={<Title>解約申請</Title>}
      footer={
        <ButtonWrapper>
          <Button onClick={onClose} gray>
            取り消す
          </Button>
          <Button onClick={openQuestionnaireForm}>解約する</Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <Description>現在の契約内容は下記の通りです。</Description>
        {currentSubscriptionContract ? (
          <SubscriptionDetail>
            <div>
              <dt>契約終了予定日</dt>
              <dd>{formatDate(currentSubscriptionContract.periodEnd)}</dd>
            </div>
            {subscription.price?.isMultiMonthContract && subscriptionCancellationPeriod && (
              <div>
                <dt>解約受付期間</dt>
                <dd>
                  {formatDateTime(subscriptionCancellationPeriod.startDate)}~
                  {formatDateTime(subscriptionCancellationPeriod.endDate)}
                </dd>
              </div>
            )}
            <div>
              <dt>契約プラン</dt>
              <dd>{subscription.price?.product.name}</dd>
            </div>
          </SubscriptionDetail>
        ) : (
          ''
        )}
        {subscription.price?.isMultiMonthContract && (
          <MultiContractDescription>
            解約手続きを行う際は解約受付期間内に行ってください。解約受付期間外での解約は
            <span>解約手数料</span>が発生します。
          </MultiContractDescription>
        )}
        <NoticeArea>
          {subscription.price?.isMultiMonthContract && (
            <Notice>
              ※解約手数料については
              <a href="https://www.sejuku.net/corp/terakoya-agreement" target="_blank">
                こちら
              </a>
              を御覧ください。
            </Notice>
          )}
          {subscription.stripeSubscriptionResponse && (
            <Notice>
              ※解約後も{formatDate(subscription.stripeSubscriptionResponse.currentPeriodEnd)}
              までは引き続きサービスをご利用いただけます。
            </Notice>
          )}
          {subscription.price?.isMultiMonthContract && (
            <Notice>
              ※プランのアップグレードを希望される場合、解約手数料はご返金いたします。
              <br />
              詳しくは
              <a href={UPGRADE_PAGE_URL} target="_blank">
                こちら
              </a>
              をご覧ください。
            </Notice>
          )}
        </NoticeArea>
      </Container>
    </Modal>
  );
};

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 auto;
`;
const Container = styled.div`
  display: flex;
  flex-flow: column;
  padding: 1.25rem 3.75rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 1rem;
    align-items: center;
  `}
`;
const Description = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.35;

  ${media.lessThan('medium')`
    font-size: 0.8125rem;
    line-height: 1.7;
    text-align: center;
  `}
`;

const SubscriptionDetail = styled.dl`
  margin-top: 1.5rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}

  div {
    display: flex;
    flex-wrap: wrap;

    dt {
      display: flex;
      justify-content: space-between;
      width: 7.8125rem;
      line-height: 1.5;

      &:after {
        content: ':';
      }

      ${media.lessThan('medium')`
        justify-content: center;
        width: 100%;
      `}
    }

    dd {
      margin-left: 0.1875rem;
      width: 20.25rem;
      line-height: 1.38;

      ${media.lessThan('medium')`
        text-align: center;
        width: 100%;
      `}
    }

    dt,
    dd {
      font-weight: 700;
      font-size: 1rem;

      ${media.lessThan('medium')`
        font-size: 0.8125rem;
        line-height: 1.38;
      `}
    }
  }

  div + div {
    margin-top: 0.25rem;

    ${media.lessThan('medium')`
      margin-top: 1rem;
    `}
  }
`;

const MultiContractDescription = styled(Description)`
  margin-top: 1.5rem;

  span {
    font-size: inherit;
    font-weight: 700;
  }

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

const NoticeArea = styled.div`
  margin-top: 1.5rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

const Notice = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;

  ${media.lessThan('medium')`
    font-size: 0.6875rem;
    line-height: 2;
    
    br {
      display: none;
    }
  `}

  a {
    font-size: inherit;
    color: #e2001b;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
