import BlankMedal from '../static/image/medal_blank.svg';
import BronzeMedal from '../static/image/medal_bronze.svg';
import SilverMedal from '../static/image/medal_silver.svg';
import GoldMedal from '../static/image/medal_gold.svg';

import { ExamMedalType } from '../gen/graphql';
import { ExamScoreMedalTypeEnum } from '../swagger/gen/ts_front';

export const getMedalImage = (medalType?: ExamMedalType | ExamScoreMedalTypeEnum): string => {
  switch (medalType) {
    case ExamMedalType.Gold || ExamScoreMedalTypeEnum.Gold:
      return GoldMedal;
    case ExamMedalType.Shilver || ExamScoreMedalTypeEnum.Shilver:
      return SilverMedal;
    case ExamMedalType.Bronze || ExamScoreMedalTypeEnum.Bronze:
      return BronzeMedal;
    default:
      return BlankMedal;
  }
};
