import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { PracticeListItemFragment, PracticeSubmitDetailReviewStatus } from '../../gen/graphql';
import { useNavigate } from 'react-router-dom';
import { checkIncludeReviewed } from '../../utils/PracticeSubmit';
import { getRecentPracticeSubmitStatus } from '../../utils/PracticeSubmit';
import { PracticeSubmitReviewStatus } from '../atoms/PracticeSubmitReviewStatus';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { checkPracticeAuthType, getPracticeFunctionType } from '../../utils/Program';

import LockIcon from '../../static/image/icon_lock_red.svg';
import BlackSubmissionIcon from '../../static/image/icon_submission_black.svg';

import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { PracticeDifficultyLables } from '../../const/Practice';

interface PracticeArticleProps {
  practice: PracticeListItemFragment;
}

export const PracticeArticle: React.FC<PracticeArticleProps> = ({ practice }) => {
  const navigate = useNavigate();
  const { openModal } = useCommonModal();

  const { permissionCheck } = useUser();
  const permissionPracticeForInstructorAndCoachRead = permissionCheck(
    FunctionType.PracticeForInstructorAndCoach,
    PermissionType.Read,
  );

  const isPracticeViewable = useMemo(
    () => checkPracticeAuthType(practice, permissionCheck),
    [practice, permissionCheck],
  );

  const recentPracticeSubmitStatus = useMemo(
    () => getRecentPracticeSubmitStatus(practice.mySubmit?.practiceSubmitDetails),
    [practice.mySubmit?.practiceSubmitDetails],
  );

  const includeReviewed = useMemo(
    () => checkIncludeReviewed(practice.mySubmit?.practiceSubmitDetails),
    [practice.mySubmit?.practiceSubmitDetails],
  );

  const showDetail = useCallback(() => {
    const functionType = getPracticeFunctionType(practice.type);
    if (!functionType) return;
    if (
      !(
        getRecentPracticeSubmitStatus(practice.mySubmit?.practiceSubmitDetails) ===
        PracticeSubmitDetailReviewStatus.Passed
      ) &&
      openModal(functionType, PermissionType.Read)
    )
      return;
    navigate(`/practices/${practice.id}/body`);
  }, [openModal, navigate, practice.id, practice.type, practice.mySubmit?.practiceSubmitDetails]);

  const showReview = useCallback(
    async (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();

      if (practice.mySubmit?.id == undefined) return;
      navigate(`/practices/submits/${practice.mySubmit.id}`);
    },
    [navigate, practice.mySubmit?.id],
  );

  return (
    <Article onClick={showDetail}>
      <Content>
        <h3>{practice.title}</h3>
        <Program>
          {isPracticeViewable ? (
            <img src={practice.program.icon} alt="program icon" />
          ) : (
            <img src={LockIcon} alt="lock icon" />
          )}
          <p>{practice.program.title}</p>
        </Program>
      </Content>
      <Difficulty>{PracticeDifficultyLables[practice.difficulty]}</Difficulty>
      <Success>{practice.submitPassedCount}人</Success>
      {!permissionPracticeForInstructorAndCoachRead && (
        <SubmissionStatusReviewSection>
          <Submission>
            {includeReviewed || recentPracticeSubmitStatus ? (
              <ShowReview onClick={showReview}>
                <img src={BlackSubmissionIcon} alt="submission icon" className="submissionIcon" />
              </ShowReview>
            ) : (
              <GreyedOutReview>
                <img src={BlackSubmissionIcon} alt="submission icon" className="submissionIcon" />
              </GreyedOutReview>
            )}
          </Submission>
          <Status>
            <PracticeSubmitReviewStatus reviewStatus={recentPracticeSubmitStatus} />
          </Status>
        </SubmissionStatusReviewSection>
      )}
    </Article>
  );
};

const Article = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  background: #fff;

  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }

  &:nth-of-type(n + 2) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('medium')`
    flex-wrap: wrap;
    gap: .5rem .5rem;
    padding: .625rem 1rem;
  `}
`;

const Content = styled.div`
  flex: 1;

  h3 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
  }

  ${media.lessThan('medium')`
    flex: none;
    width: 100%;
  `}
`;
const Program = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  margin-top: 0.5rem;

  img {
    display: block;
    max-width: 1.5rem;
    max-height: 1.5rem;
  }

  p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
    line-height: 1rem;
  }

  ${media.lessThan('medium')`
    gap: .25rem;
    margin-top: .25rem;

    img {
      max-width: 1.25rem;
      max-height: 1.25rem;
    }

    p {
      font-size: .75rem;
    }
  `}
`;
const Difficulty = styled.p`
  width: 105px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;

  ${media.lessThan('medium')`
    width: auto;
    font-weight: 500;
    text-align: left;
  `}
`;
const Success = styled.p`
  width: 78px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;

  ${media.lessThan('medium')`
    width: auto;
    font-weight: 500;
    text-align: left;

    &:after {
      content: 'が合格';
    }
  `}
`;
const SubmissionStatusReviewSection = styled.div`
  display: flex;
  align-items: center;

  ${media.lessThan('medium')`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`}
`;
const Submission = styled.p`
  width: 98px;

  ${media.lessThan('medium')`
    width: 55px;
  `}
`;
const ShowReview = styled.div`
  color: #eb0000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  margin-left: -0.9rem;

  cursor: pointer;

  .submissionIcon {
    transition: filter 0.1s ease;
  }

  &:hover .submissionIcon {
    filter: brightness(0) saturate(100%) invert(14%) sepia(91%) saturate(7381%) hue-rotate(0deg)
      contrast(113%);
  }
`;
const GreyedOutReview = styled.div`
  color: #eb0000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  margin-left: -0.9rem;

  .submissionIcon {
    filter: grayscale(100%);
    opacity: 0.4;
  }
`;
const Status = styled.div`
  width: 78px;
`;
