import React, { useMemo } from 'react';
import { User } from '../../gen/graphql';
import TabNavigationLayout from './TabNavigationLayout';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';

type TabType = 'profile' | 'programs' | 'timelines' | 'questions' | 'practice_submits';

interface Props {
  activeTab: TabType;
  user: Pick<User, 'id' | 'nickName' | 'isInstructor'>;
  hideFooter?: boolean;
  metaTitle: string;
  metaDescription?: string;
}

export const UserPageLayout: React.FC<Props> = ({
  activeTab,
  user,
  hideFooter,
  metaTitle,
  metaDescription,
  children,
}): JSX.Element => {
  const {
    user: { lmsUser },
    permissionCheck,
  } = useUser();

  // 自分の場合は「マイページ」、他人の場合はニックネームをページタイトルに表示する
  const pageTitle = user.id === lmsUser?.id ? 'マイページ' : user.nickName;

  const tabs = useMemo(() => {
    const profileTab = {
      label: 'プロフィール',
      to: `/users/${user.id}/profile`,
      active: activeTab === 'profile',
    };
    const tabs = [profileTab];

    if (!user.isInstructor) {
      const programsTab = {
        label: '教材',
        to: `/users/${user.id}/programs`,
        active: activeTab === 'programs',
      };
      tabs.push(programsTab);
    }

    const timelinesTab = {
      label: '投稿',
      to: `/users/${user.id}/timelines`,
      active: activeTab === 'timelines',
    };
    tabs.push(timelinesTab);

    if (!user.isInstructor) {
      const questionsTab = {
        label: 'Q&A',
        to: `/users/${user.id}/questions`,
        active: activeTab === 'questions',
      };
      tabs.push(questionsTab);
    }

    if (
      user.id !== lmsUser?.id ||
      !permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Read)
    ) {
      const practiceTab = {
        label: '提出物',
        to: `/users/${user.id}/practice_submits`,
        active: activeTab === 'practice_submits',
      };
      tabs.push(practiceTab);
    }

    return tabs;
  }, [activeTab, user.id, lmsUser?.id, user.isInstructor, permissionCheck]);

  return (
    <TabNavigationLayout
      pageTitle={pageTitle}
      tabs={tabs}
      hideFooter={hideFooter}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {children}
    </TabNavigationLayout>
  );
};
