import { none, Option, some } from 'fp-ts/Option';
import _ from 'lodash';
import { Either, isLeft } from 'fp-ts/Either';
import { ParseError, safeParse } from '../../../utils/FPSupport';

export interface Tag {
  value: number | string;
  name: string;
}

export interface Program {
  id: number;
  title: string;
}

export interface Chapter {
  id: number;
  title: string;
}

export interface Practice {
  id: string;
  title: string;
}

export class TitleValidationError extends Error {}
export class ChapterOrPracticeValidationError extends Error {}
export class ContentValidationError extends Error {}

const QUESTION_TITLE_MAX_LENGTH = 100;
const QUESTION_CONTENT_MAX_LENGTH = 15000;

//FIXME Swaggerの型から自動生成ができたら、それをimplementsするようにする
export class QuestionRequest {
  public readonly title: string;
  public readonly programID: number | null;
  public readonly chapterID: number | null;
  public readonly practiceID: string | null;
  public readonly tags: number[];
  public readonly content: string | undefined;

  public constructor(props: {
    title: string;
    programID: string;
    chapterID: string;
    practiceID: string;
    tags: number[];
    content: string | undefined;
  }) {
    const programIdIO: Either<ParseError, number> = safeParse(props.programID);
    if (isLeft(programIdIO)) {
      throw programIdIO.left;
    }

    const chapterIDIO: Either<ParseError, number> = safeParse(props.chapterID);
    if (isLeft(chapterIDIO)) {
      throw chapterIDIO.left;
    }

    //例外を一つしか投げれれないという制約があるので、オブジェクト生成時には例外を投げない
    //TypeScriptでFactory経由でしかクラスを生成できないなどの機能があれば、例外を投げずに返り値をEither<Error[], QuestionRequest>で表現できる
    this.title = props.title;
    this.programID = programIdIO.right === 0 ? null : programIdIO.right;
    this.chapterID = chapterIDIO.right === 0 ? null : chapterIDIO.right;
    this.practiceID = props.practiceID === '' ? null : props.practiceID;
    this.tags = props.tags;
    this.content = props.content;
  }

  public validate(): Option<Error>[] {
    return [
      this.validateTitle(this.title),
      this.validateChapterOrPractice(this.programID, this.chapterID, this.practiceID),
      this.validateContent(this.content),
    ];
  }

  public validateTitle(titleVal: string): Option<TitleValidationError> {
    if (titleVal === '') {
      return some(new TitleValidationError('タイトルを入力してください'));
    }
    if (titleVal.length > QUESTION_TITLE_MAX_LENGTH) {
      return some(
        new TitleValidationError(
          `タイトルは${QUESTION_TITLE_MAX_LENGTH}文字以内で入力してください`,
        ),
      );
    }
    return none;
  }

  public validateChapterOrPractice(
    programVal: number | null,
    chapterVal: number | null,
    practiceVal: string | null,
  ): Option<ChapterOrPracticeValidationError> {
    if (programVal !== null && chapterVal === null && practiceVal === null) {
      return some(
        new ChapterOrPracticeValidationError('教材を選択した場合は、章・課題を選択してください'),
      );
    }
    return none;
  }

  public validateContent(content: string | undefined): Option<ContentValidationError> {
    if (_.isEmpty(content) || content === undefined) {
      return some(new ContentValidationError('本文を入力してください'));
    }
    if (content.length > QUESTION_CONTENT_MAX_LENGTH) {
      return some(
        new ContentValidationError(
          `本文は${QUESTION_CONTENT_MAX_LENGTH}文字以内で入力してください`,
        ),
      );
    }
    return none;
  }
}
