export const scrollToID = (id: string): void => {
  const target = document.getElementById(id);
  if (target !== null) {
    const clientRect = target.getBoundingClientRect();
    window.scrollTo({
      top: window.pageYOffset + clientRect.top,
      left: window.pageXOffset + clientRect.left,
      behavior: 'smooth',
    });
  }
};
