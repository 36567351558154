import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { TabNavigationLayout } from '../../templates/TabNavigationLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';

import { SERVICE_NAME, LOWER_META_TITLE } from '../../../const/Service';
import { useGetCurrentStudentQuery } from '../../../gen/graphql';

export const Curriculum: React.FC = () => {
  const metaTitle = `カリキュラム | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のカリキュラムページです。専属マンツーマンのカリキュラムを閲覧できます。`;

  const [URL, setURL] = useState<string>();

  const { data } = useGetCurrentStudentQuery({
    onCompleted: (data) => {
      const courses = data.currentStudent.courses;
      if (courses && courses.length > 0) {
        setURL(courses[0].curriculum ?? undefined);
      }
    },
  });
  const courses = data?.currentStudent.courses ?? [];

  return (
    <TabNavigationLayout
      pageTitle="カリキュラム"
      tabs={
        courses.length > 0
          ? courses.map((course) => {
              return {
                label: course.plan.name,
                to: '/curriculum',
                onClick: () => setURL(course.curriculum ?? undefined),
                active: URL === (course.curriculum ?? undefined),
              };
            })
          : []
      }
      hasIntercom
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {courses.length > 0 ? (
        <Spreadsheet frameBorder="0" src={URL}></Spreadsheet>
      ) : (
        <PageWrapper>
          <EmptyBlock title="カリキュラムが登録されていません">
            ご契約済みの場合は運営までお問い合わせください。
          </EmptyBlock>
        </PageWrapper>
      )}
    </TabNavigationLayout>
  );
};

const Spreadsheet = styled.iframe`
  display: flex;
  height: calc(100vh - 115px);
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 1rem 0 0;
  `}
`;
