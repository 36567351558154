import * as yup from 'yup';
import { ProgramSatisfactionLevel } from '../../gen/graphql';

export const programSatisfactionSchema = yup.object({
  programID: yup.number().required(),
  level: yup
    .string()
    .required()
    .oneOf(Object.values(ProgramSatisfactionLevel), '評価の星を正しく選択ください'),
  comment: yup.string().nullable().max(2000, 'コメントは2000文字以内で入力してください').trim(),
});

export type ProgramSatisfactionFormInput = {
  level: number;
  comment?: string;
};
