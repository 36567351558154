import React from 'react';
import styled from 'styled-components';

import { Modal } from '../molecules/Modal';

interface ModalProps {
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  isWorry: boolean;
}

export const QuestionHintModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal
      header="質問本文について"
      isOpen={props.isOpen}
      onClose={props.onClose}
      loading={props.loading}
      width="600px"
      spFullScreen
    >
      <Container>
        {
          <>
            <p className="bold">前提情報を記載しましょう</p>
            <p>
              1. やりたいこと
              <br />
              2. 起きている問題
              <br />
              3. エラーメッセージ
              <br />
              4. 関連するソースコード
              <br />
              5. 試したこと
              <br />
              6. うまく行かない原因の仮説
              <br />
              7. 使用ライブラリ、ライブラリのバージョンなどの環境情報
              <br />
              を記載しましょう。
              <br />
              <br />
              どのような環境で何に困っているかを回答者は把握できるので、
              <span className="bold">素早い問題解決</span>に繋がります。
            </p>
            <p className="good">
              <span className="section_title">
                <span>○</span>よい記載例 1
              </span>
              <span className="bold">1. やりたいこと</span>
              <br />
              &emsp;ブロック要素（親要素の幅一杯に広がる要素、改行される要素）、インライン（改行されない要素）を理解したい
              <br />
              <span className="bold">2. 起きている問題</span>
              <br />
              &emsp;ブロック要素（親要素の幅一杯に広がる要素、改行される要素）、インライン（改行されない要素）の違いが分からない
              <br />
              <span className="bold">3. エラーメッセージ</span>
              <br />
              &emsp;なし
              <br />
              <span className="bold">4. 関連するソースコード</span>
              <br />
              &emsp;なし
              <br />
              <span className="bold">5. 試したこと</span>
              <br />
              &emsp;ソースコードをいくつか書いてみた
              <br />
              <span className="bold">6. うまく行かない原因の仮説</span>
              <br />
              &emsp;実際に使っているイメージが沸かない
              <br />
              <span className="bold">7. 使用ライブラリ、ライブラリのバージョンなどの環境情報</span>
              <br />
              &emsp;特になし
            </p>
            <p className="bad">
              <span className="section_title">
                <span>×</span>悪い質問例 1
              </span>
              ブロック要素とインライン要素について分かりませんでした。
            </p>
            <p className="good">
              <span className="section_title">
                <span>○</span>よい記載例 2
              </span>
              <span className="bold">1. やりたいこと</span>
              <br />
              &emsp;教材の通りに書いてみたがエラーメッセージが出て進まないのでどこが間違っているのか知りたい
              <br />
              <span className="bold">2. 起きている問題</span>
              <br />
              &emsp;Ruby on Rails の ○○章をのソースコードを書いて実行したらエラーになった
              <br />
              <span className="bold">3. エラーメッセージ</span>
              <br />
              &emsp;Syntax Error in ProjectContoller#index
              <br />
              unexpected end-of-input, expecting end (SyntaxError)
              <br />
              <span className="bold">4. 関連するソースコード</span>
              <br />
              &emsp;<span>https://github.com/****</span>
              <br />
              <span className="bold">5. 試したこと</span>
              <br />
              &emsp;書いたソースコードを見直した、Syntax Errorで検索した
              <br />
              <span className="bold">6. うまく行かない原因の仮説</span>
              <br />
              &emsp;何かを書き間違えてるのだと思う
              <br />
              <span className="bold">7. 使用ライブラリ、ライブラリのバージョンなどの環境情報</span>
              <br />
              &emsp;Ruby 2.3.1 Ruby on Rails 5.0.6
            </p>
            <p className="bad">
              <span className="section_title">
                <span>×</span>悪い質問例 2
              </span>
              Ruby on Rails の ○○章をやったらエラーになって進みません。どうしたら良いですか？
            </p>
          </>
        }
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 1rem 1.5rem;

  p {
    font-size: 0.875rem;
    line-height: 1.25rem;

    + p {
      margin-top: 1rem;
    }

    * {
      font-size: 1em;
    }
  }

  .bold {
    font-weight: 700;
  }

  .good,
  .bad {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .section_title {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
`;
