import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { REVIEW_COMMENT_PLACE_HOLDER } from '../../const/PracticeSubmit';
import { useToastsContext } from '../../context/ToastsProvider';
import {
  PracticeSubmitDetailReviewCommentFragment,
  useUpdatePracticeSubmitDetailReviewCommentMutation,
} from '../../gen/graphql';
import { Button } from '../atoms/Button';

import { RichInput, RichInputHandler } from '../molecules/RichInput';
import { getApiErrorMessage } from '../../utils/graphqlError';

interface Props {
  isOpen: boolean;
  toggle: (nextState: boolean) => void;
  practiceSubmitID: string;
  practiceSubmitDetailID: string;
  practiceSubmitDetailReviewComment: PracticeSubmitDetailReviewCommentFragment;
  isDisplayReviewerEditButton: (reviewerUserID: number) => boolean;
  fetchPracticeSubmitForInstructor: () => Promise<void>;
}

export const PracticeSubmitReviewEdit: React.FC<Props> = ({
  isOpen,
  toggle,
  practiceSubmitID,
  practiceSubmitDetailID,
  practiceSubmitDetailReviewComment,
  fetchPracticeSubmitForInstructor,
}) => {
  const [disabledReview, setDisabledReview] = useState(true);
  const commentRef = useRef<RichInputHandler>(null);

  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const { showToast } = useToastsContext();

  const [updatePracticeSubmitDetailReviewComment] =
    useUpdatePracticeSubmitDetailReviewCommentMutation();

  const handleContentChange = () =>
    setDisabledReview(commentRef.current?.getValue()?.trim().length === 0);

  const clickCancel = () => toggle(false);

  const clickEdit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setSubmitButtonClicked(true);
      const content = commentRef.current?.getValue();
      if (!content || disabledReview) {
        showToast(1, 'コメントを入力してください。');
        setSubmitButtonClicked(false);
        return;
      }
      try {
        await updatePracticeSubmitDetailReviewComment({
          variables: {
            input: {
              practiceSubmitID: practiceSubmitID,
              practiceSubmitDetailID: practiceSubmitDetailID,
              practiceSubmitDetailReviewCommentID: practiceSubmitDetailReviewComment.id,
              content: content,
            },
          },
        });
        await fetchPracticeSubmitForInstructor();
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, getApiErrorMessage(e));
        return;
      } finally {
        setSubmitButtonClicked(false);
      }

      showToast(0, 'レビューを保存しました。');
      toggle(false);
    }, [
      disabledReview,
      showToast,
      practiceSubmitID,
      practiceSubmitDetailID,
      practiceSubmitDetailReviewComment.id,
      updatePracticeSubmitDetailReviewComment,
      fetchPracticeSubmitForInstructor,
      toggle,
    ]),
  );

  useEffect(() => {
    commentRef.current?.setValue(practiceSubmitDetailReviewComment.content ?? '');
  }, [practiceSubmitDetailReviewComment.content, isOpen]);

  return (
    <Container>
      <RichInput
        name="edit_review_comment"
        fileUpload={false}
        imageUpload
        placeholder={REVIEW_COMMENT_PLACE_HOLDER}
        onKeyUp={handleContentChange}
        ref={commentRef}
        initialHeight={15}
      />
      <ButtonArea>
        <Button onClick={clickCancel} disabled={submitButtonClicked} border>
          キャンセル
        </Button>
        <Button onClick={clickEdit} disabled={disabledReview} border>
          保存
        </Button>
      </ButtonArea>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem 2rem 1rem 2rem;

  ${media.lessThan('medium')`
    padding: 1rem;
  `}
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
