import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { useUser } from '../../redux/user/useUser';

import { Button } from '../atoms/Button';
import { LoginModal } from './LoginModal';

import mvImage from '../../static/image/img_mv_program_illustration.png';
import mvImageX2 from '../../static/image/img_mv_program_illustration_x2.png';
import mvImageX3 from '../../static/image/img_mv_program_illustration_x3.png';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { useNavigate } from 'react-router-dom';

export const MvFull: React.FC = (props) => {
  const navigate = useNavigate();
  const { permissionCheck } = useUser();
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  const move = (url: string) => {
    navigate(url);
  };

  const openModal = () => {
    setLoginModalOpen(true);
  };

  const closeModal = () => {
    setLoginModalOpen(false);
  };

  return (
    <>
      <ImgWrapper>
        <Img />
        <TextWrapper>
          <Text>{props.children}</Text>
          <ButtonWrapper>
            <StyledButton
              onClick={() =>
                move(
                  permissionCheck(
                    FunctionType.AccountSubscriptionPathForNotLogin,
                    PermissionType.Read,
                  )
                    ? '/'
                    : '/account/subscription',
                )
              }
              border
            >
              いますぐ登録する
            </StyledButton>
            <StyledButton onClick={openModal} border>
              ログインする
            </StyledButton>
          </ButtonWrapper>
        </TextWrapper>
      </ImgWrapper>
      <LoginModal isOpen={loginModalOpen} onClose={closeModal} />
    </>
  );
};

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 360px;
  background-color: #643ec9;
  ${media.lessThan('large')`
    text-align: left;
    overflow: hidden;
  `}
  ${media.lessThan('small')`
    align-items: normal;
    justify-content: center;
    padding-top: 32px;
    text-align: left;
    box-sizing: border-box;
  `}
`;

const Img = styled.img`
  width: 100%;
  height: 360px;
  background-image: url(${mvImage});
  background-size: cover;
  background-position: center bottom;
  position: absolute;
  top: 0;
  left: 0;

  ${media.lessThan('large')`
    background-image: url(${mvImageX2});
    background-repeat: no-repeat;
    background-position: right bottom;
  `}
  ${media.lessThan('small')`
    text-align: left;
    background-image: url(${mvImageX3});
    background-position: center bottom;
    background-repeat: no-repeat;
  `}
`;

const TextWrapper = styled.div`
  position: relative;
  margin-left: 50px;

  ${media.lessThan('small')`
    margin: 0 auto;
  `}
`;

const Text = styled.p`
  margin-bottom: 16px;
  color: white;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.4;
  ${media.lessThan('large')`
    text-shadow: 0 2px 3px rgba(0,0,0,0.6);
  `}
  ${media.lessThan('small')`
    font-size: 22px;
    line-height: 1.5;
    text-align: center;
    text-shadow: none;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2.5rem;
  ${media.lessThan('large')`
    margin-top: 1.25rem;
  `}
  ${media.lessThan('small')`
    margin-top: 2rem;
  `}
`;
const StyledButton = styled(Button)`
  ${media.lessThan('small')`
    width: 8.5rem;
  `}
`;
