import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../../../atoms/lp/trial/Button';

import { SERVICE_NAME } from '../../../../const/Service';
import { PROGRAM_COUNT } from '../../../../const/Program';

import ImageBgPc from '../../../../static/image/lp/fv_bg_pc.png';
import ImageBgSp from '../../../../static/image/lp/fv_bg_sp.png';

interface Props {
  onClickCV: () => void;
}

export const FvContent: React.FC<Props> = (props) => {
  return (
    <Section data-gtm="fv">
      <Wrapper>
        <Inner>
          <SubTitle>
            <span>ゼロから始める定額・学び放題</span>の<br />
            プログラミング学習サービス
          </SubTitle>
          <TitleWrapper>
            <Title>
              仕事につながる
              <br />
              プログラミングスキル習得の
              <br className="sp" />
              第一歩を
              <span>{SERVICE_NAME}</span>
            </Title>
          </TitleWrapper>
          <Reason>
            <ReasonColumn>
              <h2>業界最安級</h2>
              <p>
                <span>0</span>円から
              </p>
            </ReasonColumn>
            <ReasonColumn>
              <h2>教材数</h2>
              <p>
                <span>{PROGRAM_COUNT}</span>種類以上
              </p>
            </ReasonColumn>
            <ReasonColumn>
              <h2>登録者数</h2>
              <p className="small">
                累計<span>18,000</span>人<br />
                を突破
              </p>
            </ReasonColumn>
          </Reason>
          <ButtonArea>
            <Button onClick={props.onClickCV} />
          </ButtonArea>
        </Inner>
      </Wrapper>
    </Section>
  );
};

const Section = styled.section`
  background-color: #fff;
  padding: 6.5rem 0 4.5rem;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  background: url(${ImageBgPc}) center top / contain no-repeat;

  ${media.lessThan('medium')`
    margin-top: 57px;
    padding-top: 26.25rem;
    background: url(${ImageBgSp}) right top / auto 420px no-repeat;
  `}

  ${media.lessThan('small')`
    padding-top: 18.5rem;
    background: url(${ImageBgSp}) right top / auto 280px no-repeat;
  `}
`;
const Wrapper = styled.div`
  max-width: 85.25rem;
  padding: 0 2rem;
  margin: 0 auto;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;

const Inner = styled.div`
  max-width: 678px;
`;
const SubTitle = styled.p`
  max-width: 585px;
  color: #122b46;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 2rem;

  br {
    display: none;
  }

  span {
    color: #eb0000;
    font-size: 1em;
    font-weight: 900;
  }

  ${media.lessThan('medium')`
    max-width: none;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;

    br {
      display: block;
    }
  `}
`;
const TitleWrapper = styled.div`
  margin: 1.375rem 0 1.75rem;
  padding: 1.875rem 0 1.5rem;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: calc(100% + 50vw);
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0 1.5rem 1.5rem 0;
    box-shadow: 0.25rem 0.75rem 1.625rem 0 rgba(111, 35, 35, 0.15);
    position: absolute;
    top: 0;
    right: 0;
  }

  ${media.lessThan('medium')`
    margin: .625rem 0 2rem;
    padding: 1rem 0 1.5rem;
  `}
`;
const Title = styled.h1`
  color: #122b46;
  font-size: 2rem;
  font-weight: 900;
  line-height: 2.5rem;
  letter-spacing: 0.64px;
  position: relative;

  span {
    display: block;
    margin-top: 0.25rem;
    color: #eb283f;
    font-size: 2.875rem;
    font-weight: 900;
    line-height: 4rem;
    letter-spacing: 0;
  }

  .sp {
    display: none;
  }

  ${media.lessThan('medium')`
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: .36px;
    text-align: center;

    span {
      margin-top: .75rem;
      font-size: 2.75rem;
      line-height: 1;
    }
  `}

  ${media.lessThan('small')`
    .sp {
      display: block;
    }
  `}
`;
const Reason = styled.div`
  display: flex;
  gap: 0.75rem;
  margin: 1.75rem 0 0;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: .5rem;
    margin-top: 2rem;
  `}
`;
const ReasonColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 1rem 0 0.75rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1rem;
  filter: drop-shadow(0.125rem 0.5rem 1.25rem rgba(111, 35, 35, 0.15));
  text-align: center;

  h2 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.125rem;
  }

  p {
    color: #eb0000;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.625rem;

    &.small {
      font-size: 1rem;
      line-height: 1;
    }

    span {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1;
    }
  }

  ${media.lessThan('medium')`
    align-items: baseline;
    flex-direction: row;
    gap: .375rem;

    h2 {
      font-size: 1.125rem;
    }

    p {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5;

      &.small {
        font-size: .75rem;
        text-align: right;
      }
    }
  `}
`;
const ButtonArea = styled.div`
  max-width: 392px;
  margin: 2.25rem auto 0;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`;
