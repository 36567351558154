import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Markdown } from '../../common/Markdown';

import Check from '../../static/image/exam_check.svg';

import { ExamQuestionFragment, AnswerInput } from '../../gen/graphql';

interface ExamQuestionProps {
  question: ExamQuestionFragment;
  questionNumber: number;
  onSelectAnswer: (answer: AnswerInput) => void;
  answered?: boolean;
}

export const ExamQuestion: React.FC<ExamQuestionProps> = ({ answered, ...props }) => {
  const [selectedId, setSelectedId] = React.useState<string>();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const answer = {
      questionID: props.question.id,
      answerID: e.target.value,
    };

    setSelectedId(e.target.value);
    props.onSelectAnswer(answer);
  };

  React.useEffect(() => {
    // answeredがfalseになったとき（もう一度テストをするが押下されたとき）回答をクリア
    if (!answered) setSelectedId(undefined);
  }, [answered]);

  return (
    <Container>
      <div>
        <QuestionNumber>
          <p>Q{props.questionNumber}</p>
        </QuestionNumber>
        <Markdown content={props.question.content} linkDisabled={true} />
      </div>
      <Answers>
        {props.question.examAnswers?.map((a) => {
          return (
            <Radio
              key={a.id}
              $selected={a.id === selectedId}
              $isCorrect={a.isCorrect}
              $answered={answered}
            >
              <input
                type="radio"
                name={props.question.id}
                onChange={onChange}
                value={a.id}
                disabled={answered}
                checked={a.id === selectedId}
              />
              <div>
                <Markdown content={a.content} linkDisabled={true} />
                <p className="result">{a.isCorrect ? '正解' : '不正解'}</p>
              </div>
            </Radio>
          );
        })}
      </Answers>
      {answered && (
        <Commentary>
          <h4>解説</h4>
          <Markdown content={props.question.commentary} />
        </Commentary>
      )}
    </Container>
  );
};

const Container = styled.div`
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    line-height: 36px;
  }
`;

const QuestionNumber = styled.div`
  padding: 0 0.75rem;

  p {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: bold;
    color: #eb0000;
    padding: 0.4rem 1.1rem;
    border-radius: 40px;
    border: 1px solid #eb0000;
  }

  ${media.lessThan('medium')`
    padding: 0 0.5rem;
    margin-bottom: 0.25rem;

    p {
      font-size: 1rem;
    }
  `}
`;

const Answers = styled.div`
  margin-left: 24px;
  margin-top: 24px;

  input {
    position: absolute;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -1px;
  }

  input:checked + div {
    background-color: #fcf6f6;
    border: 1px solid rgba(219, 39, 39, 0.1);
    border-radius: 8px;
    box-sizing: border-box;

    &:after {
      opacity: 1;
    }
  }

  ${media.lessThan('medium')`
    margin-left: 0;
    box-sizing: border-box;

    label > div {
      padding: 8px 12px;
      min-width: 100%;
      width: 100%;

      &:before {
        width: 20px;
        height: 20px;
        min-width: 20px;
      }

      &:after {
        left: 12px;
        width: 20px;
        height: 20px;
      }
    }
  `}
`;

const Commentary = styled.div`
  margin-top: 32px;
  border: 1px solid rgba(0, 0, 0, 0.05);

  h4 {
    padding: 10px 20px;
    background-color: #f4f9f9;
    color: #7a7a7a;
    font-size: 1rem;
  }

  p {
    padding: 12px 20px;
    line-height: 1.5;
    font-size: 1.1rem;
  }

  ${media.lessThan('medium')`
    margin-top: 24px;

    h4 {
      padding: 10px 16px;
      font-size: 1rem;
    }

    p {
      padding: 10px 16px;
      font-size: 1rem;
    }
  `}
`;

const Radio = styled.label<{ $selected?: boolean; $isCorrect?: boolean; $answered?: boolean }>`
  display: block;
  box-sizing: border-box;

  & > div {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 8px 18px;
    min-width: 440px;
    box-sizing: border-box;
    border: 1px solid rgba(219, 39, 39, 0);
    cursor: pointer;
    transition: all 0.1s;

    &:before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      min-width: 24px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.24);
      margin-right: 8px;
      box-sizing: border-box;
      transition: all 0.1s;
    }

    &:after {
      content: url(${Check});
      opacity: ${(props) => (props.$answered && props.$isCorrect ? 1 : 0)};
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${(props) => (props.$answered && props.$isCorrect ? '#ABEE1C' : '#fd3c2f')};
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.1s;
    }

    .result {
      display: ${(props) => (props.$selected && props.$answered ? 'block' : 'none')};
      color: ${(props) => (props.$isCorrect ? '#93CB1E' : '#EB0000')};
      font-weight: bold;
      font-size: 1rem;
      margin-left: 10px;
    }
  }

  ${media.lessThan('medium')`
    & > div {
      .result {
        display: none !important;
      }
    }
  `}
`;
