import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface Props {
  className?: string;
  subscription?: boolean;
  onClick: () => void;
}

export const LpButton: React.FC<Props> = (props) => {
  return (
    <StyledLink onClick={props.onClick} className={props.className}>
      <span className="small">最短30秒で登録完了</span>
      <span className="large">
        {props.subscription ? '有料プランに加入する' : 'いますぐ登録する'}
      </span>
    </StyledLink>
  );
};

const StyledLink = styled.div`
  display: block;
  width: 100%;
  max-width: 392px;
  margin: 0 auto;
  padding: 0.8125rem 0 0.875rem;
  border-radius: 0.25rem;
  text-align: center;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: calc(100% - 1.25rem);
    height: calc(100% - 0.375rem);
    margin: auto;
    box-shadow: 0 0.25rem 2.25rem rgb(226 0 27 / 45%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(95.07deg, #fd8258 11.86%, #fd3c2f 82.5%);
    border-radius: 0.25rem;
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    color: #fff;
    position: relative;
    z-index: 2;

    &.small {
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1rem;

      &:before,
      &:after {
        content: '';
        display: inline-block;
        width: 0.125rem;
        height: 1.25rem;
        background: #fff;
        vertical-align: text-top;
      }

      &:before {
        margin-right: 1rem;
        transform: rotate(-45deg);
      }

      &:after {
        margin-left: 1rem;
        transform: rotate(45deg);
      }
    }

    &.large {
      display: block;
      margin-top: 0.25rem;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1;
    }
  }

  ${media.lessThan('medium')`
    padding: .625rem 0;

    &:before {
      height: calc(100% - .625rem);
      box-shadow: 0 .25rem 1.5rem rgba(226, 0, 27, .45);
    }

    span {
      &.small {
        font-size: .75rem;
        font-weight: 700;

        &:before,
        &:after {
          vertical-align: bottom;
        }
      }

      &.large {
        margin-top: .375rem;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  `}
`;
