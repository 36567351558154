import { Link } from 'react-router-dom';
import ReactMarkdownHeading from 'react-markdown-heading';
import styled from 'styled-components';
import media, { generateMedia } from 'styled-media-query';

import {
  ChapterFragment,
  ChapterSearchFragment,
  PracticeFragment,
  PracticeSearchFragment,
  PracticeSubmitDetailReviewStatus,
} from '../../gen/graphql';
import { useCallback } from 'react';
import { getChapterFunctionType, getPracticeFunctionType } from '../../utils/Program';
import { getRecentPracticeSubmitStatus } from '../../utils/PracticeSubmit';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import { PermissionType } from '../../const/UserPermission';

type Chapter = ChapterFragment | ChapterSearchFragment;
type Practice = PracticeFragment | PracticeSearchFragment;

interface Props {
  programElements: (Chapter | Practice)[];
  currentID: number | string;
  currentChapterContent?: string;
  programID?: number;
}

export const ProgramElementsNav: React.FC<Props> = ({
  programElements,
  currentID,
  currentChapterContent,
  programID,
}) => {
  const { openModal } = useCommonModal();

  const checkShowDetail = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, pe: Chapter | Practice) => {
      const functionType =
        pe.__typename === 'Chapter'
          ? getChapterFunctionType(pe.type)
          : pe.__typename === 'Practice'
            ? getPracticeFunctionType(pe.type)
            : undefined;
      if (!functionType) {
        e.preventDefault();
        return;
      }

      if (pe.__typename === 'Practice') {
        const practice = pe as PracticeFragment;
        if (
          // 合格した課題に遷移する場合はモーダルは表示しない
          getRecentPracticeSubmitStatus(practice.mySubmit?.practiceSubmitDetails) !==
            PracticeSubmitDetailReviewStatus.Passed &&
          openModal(functionType, PermissionType.Read)
        )
          e.preventDefault();
      } else {
        if (openModal(functionType, PermissionType.Read)) e.preventDefault();
      }
    },
    [openModal],
  );

  return (
    <Root>
      {programElements?.map((programElement: Chapter | Practice) => {
        if (programElement.__typename === 'Chapter') {
          const ch = programElement;
          return (
            <Item key={ch.id}>
              <Link
                to={programID ? `/programs/${programID}/chapters/${ch.id}` : ''}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                  checkShowDetail(e, ch)
                }
              >
                <div className={`content ${ch.id === currentID ? 'active' : ''}`}>
                  <p>{ch.number ? ch.number : '0'}</p>
                  <h4>{ch.title}</h4>
                </div>
              </Link>
              {ch.id === currentID && (
                <ReactMarkdownHeading
                  markdown={currentChapterContent || ''}
                  hyperlink={true}
                  liClassName="heading"
                  headingDepth={2}
                />
              )}
            </Item>
          );
        }
        if (programElement.__typename === 'Practice') {
          const pl = programElement;
          return (
            <Item key={pl.id}>
              <Link
                to={`/practices/${pl.id}/body`}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                  checkShowDetail(e, pl)
                }
              >
                <div className={`content ${pl.id === currentID ? 'active' : ''}`}>
                  <p>課題</p>
                  <h4>{pl.title}</h4>
                </div>
              </Link>
            </Item>
          );
        }
      })}
    </Root>
  );
};

const customMedia = generateMedia({
  tablet: '1024px',
});
const Root = styled.div`
  position: sticky;
  top: 2rem;
  width: 284px;
  max-height: calc(100vh - 4rem);
  margin-left: 1.25rem;
  color: rgba(0, 0, 0, 0.6);
  overflow-y: auto;

  ${media.lessThan('large')`
    width: 14rem;
    margin-left: 1rem;
  `}

  ${customMedia.lessThan('tablet')`
    display: none;
  `}
`;
const Item = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 0.25rem;
  }

  a,
  p,
  h4 {
    line-height: 1.25rem;
    font-size: 0.875rem;
  }

  a:hover .content,
  .heading a:hover {
    background: #efefef;
  }

  .content {
    display: flex;
    padding: 0.25rem 0.5rem;

    &.active {
      background: #fce5e8;
    }

    p {
      width: 2rem;
      margin-right: 0.5rem;
      text-align: center;
    }

    h4 {
      flex: 1;
    }
  }

  .heading {
    margin-top: 0.25rem;
    padding-left: 1.5rem;

    a {
      display: block;
      padding: 0.25rem 0.5rem;
    }
  }
`;
