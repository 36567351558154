import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../../../redux/user/useUser';

import { useWithdrawMutation, WithdrawalQuestionnaireInput } from '../../../gen/graphql';
import { WithdrawalQuestionnaireModal } from '../../organisms/WithdrawalQuestionnaireModal';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useTagManager } from '../../../common/customHooks/TagManager';

import { SERVICE_NAME, LOWER_META_TITLE } from '../../../const/Service';
import { AccountTabLayout } from '../../templates/AccountTabLayout';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { TOAST_TYPE_ERROR, useToastsContext } from '../../../context/ToastsProvider';

export const Cancel: React.FC = (): JSX.Element => {
  const metaTitle = `アカウント削除 | ${LOWER_META_TITLE}`;

  const { logout, permissionCheck, isSocialLoginUser } = useUser();
  const { pushEvent } = useTagManager();
  const navigate = useNavigate();
  const { showToast } = useToastsContext();
  const [withdrawMutation] = useWithdrawMutation();

  const [isOpenQuestionnaireModal, setIsOpenQuestionnaireModal] = useState(false);

  const withdraw = useSafeAsyncCallback(
    useCallback(
      async (questionnaireInput: WithdrawalQuestionnaireInput): Promise<void> => {
        pushEvent('gtm-churn-withdraw-confirm');

        try {
          await withdrawMutation({ variables: { questionnaireInput } });
        } catch (e) {
          showToast(TOAST_TYPE_ERROR, getApiErrorMessage(e));
          return;
        }

        await logout();
        navigate('/');
      },
      [pushEvent, logout, navigate, withdrawMutation, showToast],
    ),
  );

  return (
    <AccountTabLayout
      activeTab="cancel"
      permissionCheck={permissionCheck}
      isSocialLoginUser={isSocialLoginUser()}
      metaTitle={metaTitle}
    >
      <Main>
        <Content>
          <Title>{SERVICE_NAME}の退会について</Title>
          <p>
            ・一度退会すると同じメールアドレスを使って再度登録することはできません。
            <br />
            ・退会するとご予約いただいたレッスン、お持ちのレッスンチケットは無効になります。
            <br />
            ・退会するとこれまで登録した学習ログなどはご覧いただけなくなります。
            <br />
            ・退会しても質問などの情報は公開され続けます。
            <br />
            ・退会後もデータ保護の観点から、投稿いただいた内容や情報は保持されます。
            <br />
            ・サブスクリプションが契約期間中の場合でも、退会後はただちにサービスをご利用いただけなくなります。
            <br />
            ・サブスクリプションは期間途中で解約した場合でも返金されません。
            <br />
            ・一度退会するとアカウントを復旧することはできません。
            <br />
          </p>
          <CancelButton onClick={() => setIsOpenQuestionnaireModal(true)}>
            上記を理解した上で退会フォームに進む
          </CancelButton>
        </Content>
        <WithdrawalQuestionnaireModal
          isOpen={isOpenQuestionnaireModal}
          isWithdrawal
          onSubmit={withdraw}
          onClose={() => setIsOpenQuestionnaireModal(false)}
        />
      </Main>
    </AccountTabLayout>
  );
};

const Main = styled.main`
  margin: 0 auto;
  background-color: #fcfcfc;
  box-sizing: border-box;
  padding: 32px 240px;
  ${media.lessThan('large')`
    padding: 32px 0px;
  `}
  min-height: 100%;
`;

const Title = styled.h2`
  margin: 0 auto 3rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1em;

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
  `}
`;

const Content = styled.section`
  margin: 0 auto;
  padding: 1.5rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  p {
    line-height: 1.8;
    font-size: 0.8rem;
  }
`;

const CancelButton = styled.p`
  color: #ffffff;
  background-color: #e2001b;
  padding: 1rem 0;
  width: 350px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 2rem;
  cursor: pointer;
`;
