import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';

import { LinkifyText } from '../atoms/LinkifyText';
import { Modal } from '../molecules/Modal';
import { UserIcon } from './UserIcon';

import { utcToJst } from '../../utils/DateFnsSupport';
import { SpotLessonFragment as SpotLesson, SpotLessonPhase } from '../../gen/graphql';

interface InstructorSpotLessonDetailModalProps {
  isOpen: boolean;
  loading?: boolean;
  toggle: (nextState: boolean) => void;
  spotLesson?: SpotLesson;
}

export const InstructorSpotLessonDetailModal: React.FC<InstructorSpotLessonDetailModalProps> = ({
  isOpen,
  loading,
  toggle,
  spotLesson,
}) => {
  const startAt = spotLesson ? utcToJst(spotLesson.startAt) : null;
  const endAt = spotLesson ? utcToJst(spotLesson.endAt) : null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        toggle(false);
      }}
      loading={loading}
      header={'レッスン内容の確認'}
      width={'550px'}
    >
      <Contents>
        <ZoomArea>
          <div className="date_area">
            <p className="date">
              {startAt ? format(startAt, 'MM/dd') : ''}
              <span>({startAt ? format(startAt, 'E', { locale: ja }) : ''})</span>
            </p>
            <p className="time">
              {startAt ? format(startAt, 'HH:mm') : ''}~{endAt ? format(endAt, 'HH:mm') : ''}
            </p>
          </div>
          <div className="info_area">
            <ZoomLink
              href={spotLesson?.zoomURL ? spotLesson?.zoomURL : ''}
              isVisible={Boolean(
                spotLesson?.zoomURL && spotLesson?.phase === SpotLessonPhase.Incomplete,
              )}
            >
              {spotLesson?.zoomURL}
            </ZoomLink>
            <StyledUserIcon user={spotLesson?.user ?? undefined} small name />
          </div>
        </ZoomArea>

        <H4>質問したいプログラミング言語</H4>
        <Tags>{spotLesson?.tags?.map((tag) => <span>{tag.name}</span>)}</Tags>

        <H4>レッスンで話したいカテゴリー</H4>
        <Text>{spotLesson?.categories?.map((category) => <span>{category.name}</span>)}</Text>

        <H4>具体的な相談内容</H4>
        <Consultation>
          <H4>困っていること</H4>
          <Text>
            <LinkifyText options={{ target: '_blank' }}>{spotLesson?.troubleContent}</LinkifyText>
          </Text>
          <H4>調べたこと</H4>
          <Text>
            <LinkifyText options={{ target: '_blank' }}>{spotLesson?.researchContent}</LinkifyText>
          </Text>
        </Consultation>
      </Contents>
    </Modal>
  );
};

const Contents = styled.div`
  padding: 24px;
`;

const ZoomArea = styled.div`
  background-color: #f5f5f5;
  display: flex;
  padding: 16px;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}

  .date_area {
    padding-right: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    .date {
      font-size: 1.5rem;
      font-weight: bold;

      span {
        font-size: 0.7rem;
      }
    }

    .time {
      margin-top: 4px;
      font-size: 0.75rem;
      font-weight: bold;
    }

    ${media.lessThan('medium')`
      padding-right: 0;
      padding-bottom: 16px;
      display: flex;
      border-right: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .date {
        font-size: 1.25rem;
        
        span {
          font-size: 0.8rem;
        }
      }

      .time {
        margin-top: 0;
        font-size: 1.25rem;
        margin-left: 0.5rem;
      }
    `}
  }

  .info_area {
    padding-left: 16px;

    ${media.lessThan('medium')`
      padding-left: 0;
      padding-top: 16px;
    `}

    a {
      display: block;
      color: #1da1f2;
    }

    .user {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;

      img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }

      p {
        margin-left: 8px;
        font-size: 0.9rem;
        font-weight: bold;
      }
    }
  }
`;

const H4 = styled.h4`
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: 24px;
  margin-bottom: 10px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    margin-right: 8px;
    background-color: #f1b007;
    color: #ffffff;
    font-size: 0.8rem;
    padding: 4px 12px;
    border-radius: 20px;
  }
`;

const Text = styled.p`
  font-size: 0.9rem;
  line-height: 1.5;
  white-space: pre-line;

  span {
    & + span {
      &:before {
        content: '/';
        margin: 0 0.25rem;
      }
    }
  }
`;

const Consultation = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  padding-top: 0;
`;

const ZoomLink = styled.a<{ isVisible?: boolean }>`
  display: ${(props) => (props.isVisible ? 'inline-block' : 'none')};
`;

const StyledUserIcon = styled(UserIcon)`
  margin-top: 1.25rem;
`;
