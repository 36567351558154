import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import TagManager from 'react-gtm-module';

import { BasicLayout } from '../../templates/BasicLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Loader } from '../../molecules/Loader';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { SpotLessonReserveStep1Group } from '../../organisms/SpotLessonReserveStep1Group';
import { SpotLessonReserveStep2Group } from '../../organisms/SpotLessonReserveStep2Group';
import { Step2InputSata } from '../../organisms/SpotLessonReserveStep2TextBox';
import { SpotLessonReserveStep3ConfirmGrid } from '../../organisms/SpotLessonReserveStep3ConfirmGrid';
import { TagFragment, CategoryFragment as Category } from '../../../gen/graphql';

import { useUser } from '../../../redux/user/useUser';
import { SPOT_LESSON_TAX_EXCLUDED_PRICE } from '../../../const';
import { Box } from '../../atoms/Box';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';

export const SpotLessonReserve: React.FC = () => {
  const metaTitle = `レッスン予約 | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のレッスンページです。現役エンジニアとの1on1レッスンを実施できます。学習中のエラー解決や何気ない疑問はもちろん、転職や副業などの相談もできます。`;

  const { user } = useUser();
  const [displayStep, setDisplayStep] = React.useState<1 | 2 | 3>(1);

  const [targetDateStep1, setTargetDateStep1] = useState<Date | null>(null);
  const [targetInputDataStep2, setTargetInputDataStep2] = useState<Step2InputSata | null>(null);

  const [step1CheckboxState, setStep1CheckboxState] = React.useState<TagFragment[]>([]);
  const [step2CheckboxState, setStep2CheckboxState] = React.useState<Category[]>([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        userId: user.lmsUser?.id,
        user: user.lmsUser,
        event: 'gtm-spot_lesson-step_00',
        eventData: {
          price: SPOT_LESSON_TAX_EXCLUDED_PRICE,
        },
      },
      dataLayerName: 'LMSDataLayer',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbs = [
    {
      label: 'ホーム',
      to: '/home',
    },
    {
      label: 'レッスン',
      to: '/spot_lessons',
    },
    {
      label: 'レッスン予約フォーム',
    },
  ];

  return (
    <React.Fragment>
      <Loader display={showLoader}></Loader>
      <BasicLayout
        pageTitle="レッスン予約"
        hasIntercom
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <PageWrapper>
          <StyledBreadcrumb links={breadcrumbs} />
          <Layout>
            <Center>
              <StyledBox>
                <StepTitle>空き情報検索</StepTitle>
                {
                  <React.Fragment>
                    <div style={displayStep === 1 ? {} : { display: 'none' }}>
                      <StepContent>
                        <SpotLessonReserveStep1Group
                          onNext={(tags, targetDate) => {
                            setStep1CheckboxState(tags);
                            setTargetDateStep1(targetDate);
                            setDisplayStep(2);

                            TagManager.dataLayer({
                              dataLayer: {
                                userId: user.lmsUser?.id,
                                user: user.lmsUser,
                                event: 'gtm-spot_lesson-step_01',
                                eventData: {
                                  price: SPOT_LESSON_TAX_EXCLUDED_PRICE,
                                },
                              },
                              dataLayerName: 'LMSDataLayer',
                            });
                          }}
                          setLoading={setShowLoader}
                        />
                      </StepContent>
                    </div>
                  </React.Fragment>
                }
                {displayStep > 1 && (
                  <ChangeValue onClick={() => setDisplayStep(1)}>変更する</ChangeValue>
                )}
              </StyledBox>
              <StyledBox>
                <StepTitle>具体的な相談内容の記入</StepTitle>
                {
                  <React.Fragment>
                    <div style={displayStep === 2 ? {} : { display: 'none' }}>
                      <StepContent>
                        <SpotLessonReserveStep2Group
                          onNext={(categories, inputSata) => {
                            setStep2CheckboxState(categories);
                            setTargetInputDataStep2(inputSata);
                            setDisplayStep(3);

                            TagManager.dataLayer({
                              dataLayer: {
                                userId: user.lmsUser?.id,
                                user: user.lmsUser,
                                event: 'gtm-spot_lesson-step_02',
                                eventData: {
                                  price: SPOT_LESSON_TAX_EXCLUDED_PRICE,
                                },
                              },
                              dataLayerName: 'LMSDataLayer',
                            });
                          }}
                          setLoading={setShowLoader}
                        />
                      </StepContent>
                    </div>
                  </React.Fragment>
                }
                {displayStep > 2 && (
                  <ChangeValue onClick={() => setDisplayStep(2)}>変更する</ChangeValue>
                )}
              </StyledBox>
              <StyledBox>
                <StepTitle>予約情報確認</StepTitle>
                {
                  <React.Fragment>
                    <div style={displayStep === 3 ? {} : { display: 'none' }}>
                      <StepContent $needsHeaderCautionSpace>
                        <SpotLessonReserveStep3ConfirmGrid
                          selectedTags={step1CheckboxState}
                          selectedCategories={step2CheckboxState}
                          targetDate={targetDateStep1}
                          inputText={targetInputDataStep2}
                          setLoading={(loading) => setShowLoader(loading)}
                        />
                      </StepContent>
                    </div>
                  </React.Fragment>
                }
              </StyledBox>
            </Center>
          </Layout>
        </PageWrapper>
      </BasicLayout>
    </React.Fragment>
  );
};

const Layout = styled.div`
  counter-reset: step;
  display: flex;
  justify-content: space-between;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

const Center = styled.div`
  counter-reset: step;
  width: 100%;

  ${media.lessThan('medium')`
    max-width: none;
    margin: 0 auto;
  `}
`;

const StyledBox = styled(Box)`
  background: #fff;
  position: relative;
  &:nth-child(n + 2) {
    margin-top: 2rem;
  }

  ${media.lessThan('medium')`
    padding: 0;
  `}
`;

const StepTitle = styled.h3`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;

  &:before {
    counter-increment: step;
    content: 'STEP' counter(step);
    display: block;
    width: 132px;
    margin-right: 0.75rem;
    padding: 1.25rem 1.5rem;
    background: #eb0000;
    box-sizing: border-box;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
  }

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent #fff #fff transparent;
    border-width: 35px 10px;
    position: absolute;
    top: 0;
    left: 112px;
  }

  ${media.lessThan('medium')`
    font-size: 1rem;

    &:before {
      width: 93px;
      padding: .875rem 1rem;
      font-size: .875rem;
      line-height: 1rem;
    }

    &:after {
      border-width: 22px 8px;
      left: 77px;
    }
  `}
`;

const StepContent = styled.section<{ $needsHeaderCautionSpace?: boolean }>`
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  padding: ${({ $needsHeaderCautionSpace }) => ($needsHeaderCautionSpace ? '1' : '2')}rem 2rem 2rem;
  ${media.lessThan('medium')`
    padding: 1.5rem 1rem 2rem;
  `}
`;

const ChangeValue = styled.button`
  display: block;
  height: 1.25rem;
  margin: auto;
  background: none;
  color: #e2001b;
  font-size: 1rem;
  line-height: 1.25rem;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2rem;

  ${media.lessThan('small')`
    width: 100%;
    height: auto;
    padding: 0.625rem 0;
    border: 1px solid #e2001b;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    position: relative;
    right: auto;
  `}
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 1.5rem;
`;
