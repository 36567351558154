import React, { ReactNode } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { fromNullable, isNone } from 'fp-ts/Option';

interface HomeSummaryElementProps {
  icon: string;
  name: string;
  value: number;
  total?: number;
  noteNode?: ReactNode;
}

export const HomeSummaryElement: React.FC<HomeSummaryElementProps> = (props) => {
  return (
    <Container>
      <Value>
        <img src={props.icon} alt={props.name} />
        <span className="value">
          {props.value}
          {!isNone(fromNullable(props.total)) && <span className="total">/{props.total}</span>}
        </span>
      </Value>
      <Name>{props.name}</Name>
      {props.noteNode && <NoteArea>{props.noteNode}</NoteArea>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Value = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 20px;
    width: auto;

    ${media.lessThan('medium')`
      height: 14px;
    `}
  }

  .value {
    display: inline-block;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 2px;
    font-weight: 500;

    ${media.lessThan('medium')`
      font-size: 1.1rem;
    `}

    .total {
      font-size: 0.75rem;
      ${media.lessThan('medium')`
        font-size: 0.55rem;
      `}
    }
  }
`;

const Name = styled.p`
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  margin-top: 12px;
`;

const NoteArea = styled.div`
  position: absolute;
  top: 3rem;

  ${media.lessThan('medium')`
    top: 3.625rem;
  `}
`;
