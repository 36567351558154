import React from 'react';
import styled from 'styled-components';

interface CountBadgeProps {
  count?: number;
}

export const CountBadge: React.FC<CountBadgeProps> = (props) => {
  return props.count ? <Container>{props.count}</Container> : <p style={{ display: 'none' }}></p>;
};

const Container = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;
  font-weight: bold;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 1;
  background-color: #e2001b;
  color: #fff;
`;
