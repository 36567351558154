import { useCallback, useRef } from 'react';

// 非同期処理で2度押しなどを防ぐためのcallbackを生成
// 更新系APIの２重送信防止などを想定
export const useSafeAsyncCallback = <A extends unknown[]>(
  callback: (...args: A) => Promise<void>,
): ((...args: A) => Promise<void>) => {
  const processing = useRef(false);
  return useCallback(
    async (...args: A): Promise<void> => {
      if (processing.current) {
        return;
      }
      processing.current = true;
      try {
        await callback(...args);
      } finally {
        processing.current = false;
      }
    },
    [callback],
  );
};
