import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { addDays, subDays } from 'date-fns';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../../redux/user/useUser';
import { ModalContext } from '../../../context/ModalProvider';

import { Button } from '../../atoms/Button';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { Mv } from '../../organisms/Mv';
import { Modal } from '../../molecules/Modal';
import { InputWithSearch, Option } from '../../molecules/InputWithSearch';
import { TabNavigationLayout } from '../../templates/TabNavigationLayout';
import { Alert } from '../../molecules/Alert';
import { StudyLogArticle } from '../../organisms/StudyLogArticle';
import { StudyLogRank } from '../../organisms/StudyLogRank';
import { Fab } from '../../atoms/Fab';

import { StudyLogChart } from '../../organisms/StudyLogChart';
import { StudySummary as StudySummaryComponent } from '../../organisms/StudySummary';
import { StudyTagSummary as StudyTagSummaryComponent } from '../../organisms/StudyTagSummary';
import { fromNullable, isSome, Option as Op } from 'fp-ts/Option';
import {
  useGetTagsLazyQuery,
  useGetTagLazyQuery,
  useGetCoursesForInstructorQuery,
  CourseSearchType,
  InstructorCourseSearchInput,
  CourseStudentFragment,
  useGetStudyReportsLazyQuery,
  useGetRelatedStudyReportsLazyQuery,
  useCreateStudyReportCommentMutation,
  useGetStudySummaryQuery,
  useGetStudyCalendarQuery,
  useGetStudyTagSummariesQuery,
  TagSearchInput,
} from '../../../gen/graphql';

import MvImage from '../../../static/image/mv/study_log.svg';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { FIRST_PAGE, MIN_LIMIT } from '../../../const/Page';
import { useCommonModal } from '../../../redux/common_modal/useCommonModal';
import Pagination from '../../organisms/Pagination';

interface InputWithSearchHandler {
  getValue: () => Option;
  setValue: (option: Option) => void;
  clear: () => void;
}

export const StudyReportIndex: React.FC = (): JSX.Element => {
  //TODO 本来はDIしてテストなどでも差し替えられるようにするべきだが、reflectMetadataをimportするとエラーになるので、一旦暫定
  /* eslint-disable react-hooks/exhaustive-deps */
  const studySummaryData = useGetStudySummaryQuery();

  const modalContext = useContext(ModalContext);
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [page, setPage] = useState<number>(
    query.page && typeof query.page === 'string' ? parseInt(query.page) : FIRST_PAGE,
  );
  const [tagID, setTagID] = useState<number>(
    query.tag_id && typeof query.tag_id === 'string' ? parseInt(query.tag_id) : 0,
  );
  const [currentTag, setCurrentTag] = useState<Option>({ id: 0, name: '' });
  const [userID, setUserID] = useState<number>(
    query.user_id && typeof query.user_id === 'string' ? parseInt(query.user_id) : 0,
  );
  const tagRef = useRef<InputWithSearchHandler>(null);
  const userRef = useRef<InputWithSearchHandler>(null);
  const indexType = location.pathname.split('/').slice(-1)[0];
  const navigate = useNavigate();
  const limit = MIN_LIMIT;

  const { user, permissionCheck } = useUser();
  const { openModal } = useCommonModal();

  const shouldShowForInstructor = useMemo(() => {
    return permissionCheck(FunctionType.StudyReportForInstructorAndCoach, PermissionType.Read);
  }, [permissionCheck]);

  const [getTags, tagData] = useGetTagsLazyQuery();

  const [getTag] = useGetTagLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.getTag) {
        const value = {
          id: data.getTag.id,
          name: data.getTag.name,
        };
        tagRef.current?.setValue(value);
        setCurrentTag(value);
      } else {
        const value = { id: 0, name: '' };
        tagRef.current?.setValue(value);
        setCurrentTag(value);
      }
    },
  });

  const today = React.useMemo(() => new Date(), []);

  const studyCalendarData = useGetStudyCalendarQuery({
    skip: !permissionCheck(FunctionType.StudyReportSummary, PermissionType.Read),
    variables: {
      input: {
        from: subDays(today, 365).toISOString(),
        to: addDays(today, 1).toISOString(),
      },
    },
  });
  const studyCalendars = studyCalendarData.data?.getStudyCalendar;

  const studyTagSummariesData = useGetStudyTagSummariesQuery({
    skip: !permissionCheck(FunctionType.StudyReportSummary, PermissionType.Read),
  });
  const studyTagSummaries = studyTagSummariesData.data?.getStudyTagSummaries;

  const fetchTags = useCallback(
    (query: string) => {
      const input: TagSearchInput = {
        name: query,
        page: 1,
      };

      // デフォルトは10個で検索する時は制限なし
      if (query.length === 0) {
        input.limit = 10;
      }

      getTags({
        variables: {
          input,
        },
      });
    },
    [getTags],
  );

  const { data: coursesData, refetch } = useGetCoursesForInstructorQuery({
    variables: {
      input: {
        type: CourseSearchType.All,
      },
    },
    skip: !user.lmsUser?.isInstructor,
  });

  // ユーザー検索Option
  const userFilterOptions = useMemo((): Option[] => {
    const courses = coursesData?.coursesForInstructor ?? [];
    // コースで取得すると受講生名が重複するため、受講生名の重複を排除する(student.idでuniqueにする)
    const uniqueStudents = courses.reduce<{ [key: number]: CourseStudentFragment }>(
      (acc, cur) => ({ [cur.student.id]: cur.student, ...acc }),
      {},
    );

    return Object.values(uniqueStudents)
      .filter(({ user }) => user.maskedPersonalInfo)
      .map(({ user }) => ({ id: user.id, name: user.maskedPersonalInfo?.name ?? '' }));
  }, [coursesData?.coursesForInstructor]);

  // 選択中のユーザー
  const selectedUser = useMemo(
    (): Option | null => userFilterOptions.find((o) => o.id === userID) ?? null,
    [userFilterOptions, userID],
  );

  const [getStudyReports, getStudyReportsData] = useGetStudyReportsLazyQuery({
    variables: {
      input: {
        limit: limit,
        page: page,
        tagID: tagID !== 0 ? tagID : undefined,
        targetUserID: indexType === 'my' ? user.lmsUser?.id : undefined,
      },
    },
  });

  const [getRelatedStudyReports, getRelatedStudyReportsData] = useGetRelatedStudyReportsLazyQuery({
    variables: {
      input: {
        limit: limit,
        page: page,
        tagID: tagID !== 0 ? tagID : undefined,
        targetUserID: selectedUser?.id,
      },
    },
  });

  // 権限、選択しているタブによって適切な学習ログ一覧の値を返す
  const fetchStudyReports =
    indexType == 'my' && shouldShowForInstructor ? getRelatedStudyReports : getStudyReports;

  const studyReportsData =
    indexType == 'my' && shouldShowForInstructor
      ? getRelatedStudyReportsData.data?.getRelatedStudyReports
      : getStudyReportsData.data?.getStudyReports;

  const [createStudyReportComment] = useCreateStudyReportCommentMutation();

  // 再検索
  const refetchCourses = useCallback(
    async (params: InstructorCourseSearchInput): Promise<void> => {
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      }
    },
    [refetch],
  );

  // instructor
  const fetchUsers = useCallback(
    async (query: string): Promise<void> => {
      const params = {
        type: CourseSearchType.All,
        studentName: query,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      await refetchCourses(params);
    },
    [refetchCourses],
  );

  const clearQuery = (): void => {
    setPage(1);
    tagRef.current?.clear();
    userRef.current?.clear();
    setTagID(0);
    setUserID(0);
  };

  const init = useCallback(async (): Promise<void> => {
    if (tagID !== 0) {
      getTag({
        variables: {
          id: tagID,
        },
      });
    } else {
      tagRef.current?.setValue({ id: 0, name: '' });
    }
  }, [tagID]);

  useEffect((): void => {
    if (shouldShowForInstructor) {
      fetchUsers('');
    }
    init();
  }, [init, tagID]);

  useEffect((): void => {
    query.page = page !== 1 ? page.toString() : '';
    query.tag_id = tagID !== 0 ? tagID.toString() : '';
    query.user_id = userID !== 0 ? userID.toString() : '';

    const PATH = shouldShowForInstructor
      ? location.pathname +
        (query.page || query.tag_id || query.user_id ? `?${queryString.stringify(query)}` : '')
      : location.pathname + (query.page || query.tag_id ? `?${queryString.stringify(query)}` : '');

    fetchStudyReports();
    if (location.pathname + location.search === PATH) return;
    navigate(PATH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, tagID, userID, indexType, fetchStudyReports]);

  useEffect(() => {
    // 戻るボタンを押されたときの対応
    const queryTagID = (typeof query.tag_id === 'string' && parseInt(query.tag_id)) || 0;
    const queryUserID = (typeof query.user_id === 'string' && parseInt(query.user_id)) || 0;
    const queryPage = (typeof query.page === 'string' && parseInt(query.page)) || 1;
    const selectedTag = tagData.data?.getTags.items.find((t): boolean => t.id === queryTagID);

    setTagID(queryTagID);
    setUserID(queryUserID);
    setPage(queryPage);
    tagRef.current?.setValue(selectedTag ?? { id: 0, name: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.tag_id, query.user_id, query.page]);

  const metaTitle = useMemo(() => {
    return indexType ? `みんなの学習ログ | ${LOWER_META_TITLE}` : `学習ログ | ${LOWER_META_TITLE}`;
  }, [indexType]);
  const metaDescription = `${SERVICE_NAME}の学習ログページです。あなたの学習状況を記録して勉強の習慣化を促進します。受講生一人ひとりの学習時間や学習されているプログラミング言語をランキング形式で見れる他、気になるログには「コメント」や「いいね」でリアクションできます。`;

  const tabs = useMemo(() => {
    const tabs = [];

    if (!permissionCheck(FunctionType.StudyReportForNotLogin, PermissionType.Read)) {
      const myTabs = {
        label: shouldShowForInstructor ? '担当受講生の学習ログ' : '自分の学習ログ',
        to: '/study_report/my',
        active: indexType === 'my',
        onClick: clearQuery,
      };
      tabs.push(myTabs);
    }

    const allTabs = {
      label: 'みんなの学習ログ',
      to: '/study_report/all',
      active: indexType === 'all',
      onClick: clearQuery,
    };
    tabs.push(allTabs);

    return tabs;
  }, [permissionCheck, indexType, clearQuery]);

  const createOption = (id: Op<number>, name: Op<string>) => {
    if (isSome(id) && isSome(name)) {
      return { id: id.value, name: name.value };
    } else {
      return { id: 0, name: '' };
    }
  };

  const tagOptions: Option[] = tagData.data
    ? tagData.data.getTags.items
        .map((value) => {
          return createOption(fromNullable(value.id), fromNullable(value.name));
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    : [];

  const storeSubmitComment = async (studyReportID: number, comment: string) => {
    try {
      await createStudyReportComment({
        variables: {
          studyReportID: studyReportID,
          input: {
            content: comment,
          },
        },
      });
      fetchStudyReports();
    } catch (e) {
      return;
    }
  };

  const submitCommentForChild = async (studyReportID: number, comment: string) => {
    return storeSubmitComment(studyReportID, comment);
  };

  const mvPostLog = useCallback(() => {
    if (openModal(FunctionType.StudyReport, PermissionType.Create)) return;
    navigate('/study_report/new');
  }, [openModal, navigate]);

  // 学習ログ作成ボタン表示が可能な権限の判定値
  const shouldShowCreateButton = useMemo(() => {
    return (
      permissionCheck(FunctionType.StudyReport, PermissionType.Create) ||
      permissionCheck(FunctionType.RecommendLight, PermissionType.Read) ||
      permissionCheck(FunctionType.RecommendTeamSubscription, PermissionType.Read) ||
      permissionCheck(FunctionType.RecommendTeamSubscriptionToAdmin, PermissionType.Read)
    );
  }, [permissionCheck]);

  const shouldShowMv = useMemo(() => {
    return (
      permissionCheck(FunctionType.StudyReportSummary, PermissionType.Read) && indexType === 'my'
    );
  }, [permissionCheck, indexType]);

  const mvData = {
    title: '学習をログして日々の学びを習慣化しよう',
    description: (
      <React.Fragment>
        学習ログは、日々の学習を記録できる機能です。記録することで学習の習慣化につながります。ハードルを上げすぎずメモをとるくらいの気持ちで気軽に更新していきましょう。
        <br />
        <MvLink
          href="https://intercom.help/plus---samurai/ja/articles/5323078-%E5%AD%A6%E7%BF%92%E3%83%AD%E3%82%B0%E3%82%92%E7%B6%99%E7%B6%9A%E3%81%95%E3%81%9B%E3%82%8B6%E3%81%A4%E3%81%AE%E3%82%B3%E3%83%84"
          target="_blank"
        >
          学習ログをつけるときの注意点 | 継続するコツ
        </MvLink>
      </React.Fragment>
    ),
    bg: '#FAE3D2',
    image: MvImage,
    buttonText: '学習ログを記録する',
    onClick: mvPostLog,
  };

  return (
    <>
      <Modal isOpen={modalContext.isOpen} onClose={(): void => modalContext.close()}>
        {modalContext.content}
      </Modal>
      <TabNavigationLayout
        pageTitle="学習ログ"
        tabs={tabs}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <Container>
          <PageWrapper>
            <Wrapper>
              {shouldShowForInstructor && (
                <Alert
                  severity="warning"
                  title="学習のヒント"
                  link="https://tayori.com/faq/8921b734331f31134b24218f9de5fc4d5ad32b21/detail/243329eb60000fe5a02a43f1a78ba91e7e1d185a"
                  linkLabel="詳細を見る"
                >
                  担当受講生の学習ログをコーチングに役立てましょう！
                  <br />
                  受講生向けに【学習ログをつけるときの注意点】と【継続するコツ】を参考にしてみてください！
                </Alert>
              )}
              {shouldShowMv && (
                <>
                  <StyledMv mv={mvData} />
                  <Logs>
                    <ColumnWide>
                      <h3 data-e2e="studyCalendar">学習カレンダー</h3>
                      <StudyLogChart data={studyCalendars} range={365} />
                    </ColumnWide>
                    <Column data-e2e="summary">
                      <StudySummaryComponent
                        label="サマリー"
                        summary={studySummaryData.data?.getStudySummary}
                        type="graph"
                        data={studyCalendars}
                      />
                    </Column>
                    <Column>
                      <StudyTagSummaryComponent data={studyTagSummaries ? studyTagSummaries : []} />
                    </Column>
                  </Logs>
                </>
              )}
              <Left needTopMargin={shouldShowMv || shouldShowForInstructor}>
                {studyReportsData?.items.length === 0 ? (
                  <EmptyContainer>
                    <Title display={!shouldShowForInstructor}>学習ログ</Title>
                    <Filter>
                      <StyledInputWithSearch
                        label="言語・技術"
                        options={tagOptions}
                        handleInput={fetchTags}
                        deletable={true}
                        onSelect={(id): void => {
                          id ? setTagID(id) : setTagID(0);
                          setPage(1);
                        }}
                        defaultValue={currentTag ?? tagRef.current?.getValue()}
                        ref={tagRef}
                        width="100%"
                      />

                      {shouldShowForInstructor && indexType === 'my' && (
                        <NarrowInputWithSearch
                          label="受講生名"
                          options={userFilterOptions}
                          handleInput={fetchUsers}
                          deletable={true}
                          value={selectedUser}
                          onSelect={(id): void => {
                            id ? setUserID(id) : setUserID(0);
                            setPage(1);
                          }}
                          ref={userRef}
                        />
                      )}
                    </Filter>

                    <StudyReports data-e2e="studyLogArticle">
                      <EmptyBlock title="学習ログが登録されていません">
                        教材で学習を終えた際に学習ログを記入して傾向を知りましょう。
                        {shouldShowCreateButton && (
                          <EmptyButton onClick={mvPostLog}>学習ログを記録</EmptyButton>
                        )}
                      </EmptyBlock>
                    </StudyReports>
                  </EmptyContainer>
                ) : (
                  <React.Fragment>
                    <LabelArea>
                      <TotalLabel>
                        全{studyReportsData?.total}件
                        <span>
                          ({(page - 1) * limit + 1}~
                          {studyReportsData && (page - 1) * limit + studyReportsData.items.length}
                          件を表示中)
                        </span>
                      </TotalLabel>
                    </LabelArea>
                    <Filter>
                      <StyledInputWithSearch
                        label="言語・技術"
                        options={tagOptions}
                        handleInput={fetchTags}
                        deletable={true}
                        onSelect={(id): void => {
                          id ? setTagID(id) : setTagID(0);
                          setPage(1);
                        }}
                        defaultValue={currentTag ?? tagRef.current?.getValue()}
                        ref={tagRef}
                        width="100%"
                      />
                      {shouldShowForInstructor && indexType === 'my' && (
                        <NarrowInputWithSearch
                          label="受講生名"
                          options={userFilterOptions}
                          handleInput={fetchUsers}
                          deletable={true}
                          onSelect={(id): void => {
                            id ? setUserID(id) : setUserID(0);
                            setPage(1);
                          }}
                          ref={userRef}
                          width="100%"
                        />
                      )}
                    </Filter>
                    <StudyReports>
                      {studyReportsData?.items.map((studyReport) => (
                        <StudyLogArticle
                          key={studyReport.id}
                          id={studyReport.id}
                          content={studyReport.content}
                          date={studyReport.date}
                          createdAt={studyReport.createdAt}
                          refetch={fetchStudyReports}
                          user={studyReport.user}
                          editable={
                            permissionCheck(FunctionType.StudyReport, PermissionType.Update) &&
                            studyReport.user?.id === user.lmsUser?.id
                          }
                          isShowCommentField={false}
                          isShowCommentCount={false}
                          isInnerCommentField={true}
                          isLimitComment={true}
                          isCommentBorder={false}
                          studyReportFavorites={studyReport.studyReportFavorites}
                          studyReportDetails={studyReport.studyReportDetails}
                          studyReportComments={studyReport.studyReportComments}
                          submitComment={submitCommentForChild}
                        />
                      ))}
                    </StudyReports>

                    {studyReportsData && (
                      <Pagination
                        total={studyReportsData.total}
                        perPage={limit}
                        page={page}
                        setPage={setPage}
                      />
                    )}
                  </React.Fragment>
                )}
              </Left>
              <Right needTopMargin={shouldShowMv || shouldShowForInstructor}>
                <StudyLogRank />
              </Right>
            </Wrapper>
          </PageWrapper>
        </Container>
      </TabNavigationLayout>
      {
        /*親コンポーネントでtransformを使用しており位置をfixedにするためLayout外に配置*/
        shouldShowCreateButton && <Fab onClick={mvPostLog}>+ 学習ログを記録</Fab>
      }
    </>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledMv = styled(Mv)`
  ${media.lessThan('medium')`
    width: calc(100% + 2rem);
    margin: -2rem -1rem 0;
  `}
`;

const MvLink = styled.a`
  color: #eb0000;
  font-size: 0.75rem;
  line-height: 1rem;
`;

const Left = styled.div<{ needTopMargin: boolean }>`
  flex: 1;
  ${(props) => props.needTopMargin && 'margin-top: 2rem;'}

  ${media.lessThan('medium')`
    flex: none;
    width: 100%;
  `}
`;
const Right = styled.div<{ needTopMargin: boolean }>`
  width: 40%;
  max-width: 284px;
  margin-left: 1.25rem;
  ${(props) => props.needTopMargin && 'margin-top: 2rem;'}

  ${media.lessThan('medium')`
    width: calc(100% + 2rem);
    max-width: none;
    margin: 4rem -1rem 0;
  `}
`;

const Logs = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
  padding: 1.5rem 2rem 2rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  h3 {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
  }
`;

const ColumnWide = styled.div`
  width: 100%;
`;

const Column = styled.div`
  flex: 1;
  margin-top: 1.5rem;

  &:last-of-type {
    margin-left: 1.5rem;
    padding-left: 1.5rem;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('medium')`
    flex: auto;
    width: 100%;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(0,0,0,0.1);

    &:last-of-type {
      margin-left: 0;
      padding-left: 0;
      border-left: none;
    }
  `}
`;

const LabelArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;

  ${media.lessThan('medium')`
    align-items: flex-start;
    flex-direction: column;
  `}
`;
const Filter = styled.div`
  display: flex;
  position: sticky;
  z-index: 2;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const StyledInputWithSearch = styled(InputWithSearch)`
  width: 246px;

  &:nth-of-type(n + 2) {
    margin-left: 1rem;
  }

  label {
    min-width: 0;
    margin-right: 1rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }

  ${media.lessThan('medium')`
    display: block;
    width: 100%;

    label {
      display: block;
      margin: 0;
      font-size: .75rem;
    }
  `}
`;
const NarrowInputWithSearch = styled(StyledInputWithSearch)`
  max-width: 200px;

  ${media.lessThan('medium')`
    max-width: none;
 `}
`;

const TotalLabel = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;

  span {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const StudyReports = styled.section`
  margin-top: 1.5rem;
  margin-bottom: 2rem;

  & > * + * {
    margin-top: 1rem;
  }

  ${media.lessThan('medium')`
    margin: 1rem -1rem 0;

    & > * + * {
      margin-top: -1px;
    }
  `}
`;

const EmptyContainer = styled.section`
  h2 {
    margin-bottom: 1.125rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.125rem;
    font-weight: 700;
  }
`;

const EmptyButton = styled(Button)`
  display: block;
  margin: 2rem auto 0;
`;

const Title = styled.h2<{ display: boolean }>`
  ${(props): string => (props.display ? '' : 'display: none;')}
`;
