import { useCallback } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BasicLayout } from '../../templates/BasicLayout';
import { useGetTweetQuery } from '../../../gen/graphql';
import { NotFoundPage } from '../public/NotFound';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Loader } from '../../molecules/Loader';
import { TweetCard } from '../../organisms/TweetCard';
import arrowIcon from '../../../static/image/left_arrow.svg';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';

interface TweetDetailState {
  referer: string;
}

export const TweetDetailContainer = (): JSX.Element => {
  const pathID = useParams<{ id: string }>().id;
  if (!pathID) return NotFoundPage;

  return <TweetDetail tweetID={pathID} />;
};

interface TweetDetailProps {
  tweetID: string;
}

const TweetDetail = (props: TweetDetailProps): JSX.Element => {
  const metaTitle = `タイムライン | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のタイムラインページです。他のユーザーの学習進捗や動向を確認できます。日頃の学習の成果や悩みを投稿してユーザー同士で交流できます。`;

  const { data, loading } = useGetTweetQuery({
    variables: { id: props.tweetID },
  });

  const location = useLocation();
  const locationState = location.state as TweetDetailState;
  // 戻るボタンのURL(現時点では /home or /timeline)
  // history.goBackだと遅いのでrefererを引き回してLinkかnavigationで遷移
  const backUrl = locationState?.referer ?? '/timeline';

  const navigation = useNavigate();
  const handleDeleteComplete = useCallback((): void => {
    // 削除完了後は元の画面に戻る
    navigation(backUrl);
  }, [navigation, backUrl]);

  if (!loading && !data) {
    return NotFoundPage;
  }

  return (
    <BasicLayout pageTitle="タイムライン" metaTitle={metaTitle} metaDescription={metaDescription}>
      <Loader display={loading} />
      <PageWrapper>
        <Container>
          <Top>
            <StyledLink to={backUrl}>
              <ArrowIcon src={arrowIcon} alt="戻る" />
              前の画面に戻る
            </StyledLink>
          </Top>
          {data && (
            <CardWrapper>
              <TweetCard tweet={data.getTweetV1} showAll onDeleteComplete={handleDeleteComplete} />
            </CardWrapper>
          )}
        </Container>
      </PageWrapper>
    </BasicLayout>
  );
};

const Container = styled.div`
  max-width: 892px;
  margin: 0 auto;
`;

const Top = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledLink = styled(Link)`
  display: flex;
  font-size: 1rem;
`;

const ArrowIcon = styled.img`
  margin-right: 0.75rem;
`;

const CardWrapper = styled.div`
  margin-top: 1rem;
`;
