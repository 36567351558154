import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../atoms/Button';

import { ProgramSampleFragment } from '../../gen/graphql';
import { useNavigate } from 'react-router-dom';

interface Props {
  sample: ProgramSampleFragment;
}

export const ProgramSampleCard: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const linkProgram = () => {
    navigate(`/programs/${props.sample.program.id}/overview`);
  };
  const linkSample = () => {
    window.open(props.sample.url);
  };

  return (
    <Article id={props.sample.id}>
      <Thumbnail bg={props.sample.thumbnail} />
      <Title>{props.sample.title}</Title>
      <Description>{props.sample.description}</Description>
      <ButtonArea>
        <StyledButton onClick={linkProgram}>概要</StyledButton>
        <StyledButton onClick={linkSample}>サンプル</StyledButton>
      </ButtonArea>
    </Article>
  );
};

const Article = styled.article`
  width: calc((100% - 3.75rem) / 4);

  ${media.lessThan('large')`
    width: calc((100% - 2.5rem) / 3);
  `}

  ${media.lessThan('medium')`
    width: calc(50% - .5rem);
  `}

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
const Thumbnail = styled.div<{ bg?: string }>`
  display: block;
  width: 100%;
  padding-top: 66.9%;
  background: #efefef center / cover no-repeat;
  ${(props) =>
    !!props.bg &&
    `
    background-image: url(${props.bg});
  `}
  box-shadow: 0 .25rem 1rem rgba(0,0,0,.1);
  border-radius: 1.25rem;
`;
const Title = styled.h3`
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;

  ${media.lessThan('medium')`
    margin-top: 1.25rem;
  `}
`;
const Description = styled.p`
  margin-top: 1.5rem;
  font-size: 1rem;
  line-height: 1.375rem;

  ${media.lessThan('medium')`
    margin-top: .625rem;
  `}
`;
const ButtonArea = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    margin-top: 1.25rem;
  `}
`;
const StyledButton = styled(Button)`
  flex: 1;
  display: block;
  padding: 0.25rem;
  background: #fff;
  border: 1px solid #eb0000;
  color: #eb0000;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  transition: all 0.2s;

  &:hover {
    background: #eb0000;
    color: #fff;
  }
`;
