import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';

import { SPOT_LESSON_TIME } from '../../const/SpotLesson';
import { Z_INDEX_MODAL } from '../../const/ZIndex';

import { Button } from '../atoms/Button';

import TicketImage from '../../static/image/spot_lesson_ticket.png';

const useModal = (contentRef: React.RefObject<HTMLElement>, callback: () => void) => {
  const handleClick = (e: Event) => {
    if (contentRef.current && contentRef.current.contains(e.target as Node)) return;

    callback();
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GetSpotLessonTicketModal: React.FC<ModalProps> = (props) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const transitionReserve = () => {
    props.onClose();
    navigate('/spot_lessons/reserve');
  };

  useModal(contentRef, () => {
    props.onClose();
  });

  return props.isOpen ? (
    <Modal>
      <Container ref={contentRef}>
        <TicketHeading>
          <span>
            レッスンチケット
            <br />
            獲得！！
          </span>
        </TicketHeading>
        <Catch src={TicketImage} />
        <H1>
          おめでとうございます！
          <br />
          レッスンチケットが付与されました。
        </H1>
        <LinkTtl>チケット一枚あたり、{SPOT_LESSON_TIME}分間のレッスンが受けられます。</LinkTtl>
        <StyledButton onClick={transitionReserve}>さっそくレッスンをはじめる</StyledButton>
      </Container>
    </Modal>
  ) : (
    <React.Fragment />
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: ${Z_INDEX_MODAL};
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
`;
const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 680px;
  padding: 32px 24px;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  text-align: center;

  ${media.lessThan('small')`
    padding: 24px 12px;
  `}
`;
const H1 = styled.h1`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5;

  span {
    color: #c08c05;
    font-size: 1em;
  }

  ${media.lessThan('medium')`
    font-size: 1.25rem;
  `}

  ${media.lessThan('small')`
    margin-bottom: 1.5rem;
    font-size: 1rem;
  `}
`;
const LinkTtl = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;

  span {
    color: #e2001b;
    font-size: 1.5em;
  }

  ${media.lessThan('small')`
    margin-bottom: 1rem;
    font-size: 0.875rem;
  `}
`;
const StyledButton = styled(Button)`
  display: block;
  max-width: 300px;
  margin: 0 auto;
`;

const TicketHeading = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;

  br {
    display: none;
  }

  span {
    color: #e2001b;
    background: -webkit-linear-gradient(95.07deg, #fd8258 11.86%, #fd3c2f 82.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.1em;
  }

  &:before,
  &:after {
    content: '';
    display: inline-block;
    width: 22px;
    height: 2px;
    vertical-align: middle;
    background: linear-gradient(95.07deg, #fd8258 11.86%, #e2001b 82.5%);
  }

  &:before {
    margin-right: 0.625rem;
    transform: rotate(45deg);
  }

  &:after {
    margin-left: 0.625rem;
    transform: rotate(135deg);
  }

  ${media.lessThan('medium')`
    span {
      font-size: 1.25rem;
    }

    &:before {
      margin-right: 0.25rem;
    }

    &:after {
      margin-left: 0.25rem;
    }
  `}

  ${media.lessThan('small')`
    br {
      display: block;
    }
  `}
`;

const Catch = styled.img`
  display: block;
  width: 80%;
  max-width: 312px;
  margin: auto;
`;
