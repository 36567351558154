import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { fromNullable, isNone } from 'fp-ts/Option';

import { isAfter, format } from 'date-fns';

import { useToastsContext } from '../../../context/ToastsProvider';
import { useCommonModal } from '../../../redux/common_modal/useCommonModal';
import { useUser } from '../../../redux/user/useUser';
import { calcTicketReturnDeadline } from '../../../utils/spotLesson';
import { utcToJst } from '../../../utils/DateFnsSupport';
import { SPOT_LESSON_EVALUATION_FORM_URL } from '../../../const/SpotLesson';
import {
  useGetSpotLessonLazyQuery,
  useGetSpotLessonsQuery,
  useUpdateSpotLessonMutation,
  useCancelSpotLessonMutation,
  useGetTicketsQuery,
  SpotLessonPhase,
} from '../../../gen/graphql';
import { FIRST_PAGE, MIN_LIMIT } from '../../../const/Page';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

import TicketIcon from '../../../static/image/icon_ticket.svg';

import { BasicLayout } from '../../templates/BasicLayout';
import { Button } from '../../atoms/Button';
import { PageWrapper } from '../../atoms/PageWrapper';
import { TextArea, TextAreaHandler } from '../../atoms/TextArea';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { Modal } from '../../molecules/Modal';
import { Tickets } from '../../molecules/Tickets';
import { Loader } from '../../molecules/Loader';
import { BuyTicketModal } from '../../organisms/BuyTicketModal';
import { CommonSpotLessonArticle } from '../../organisms/CommonSpotLessonArticle';
import Pagination from '../../organisms/Pagination';
import { SpotLessonBanner } from '../../organisms/SpotLessonBanner';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';

export const SpotLesson: React.FC = () => {
  const metaTitle = `レッスン | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}のレッスンページです。現役エンジニアとの1on1レッスンを実施できます。学習中のエラー解決や何気ない疑問はもちろん、転職や副業などの相談もできます。`;

  const today = new Date();

  const { permissionCheck } = useUser();
  const { openModal } = useCommonModal();

  const [currentSpotLessonID, setCurrentSpotLessonID] = useState<number | null>(null);

  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isBuyTicketModalOpen, setIsBuyTicketModalOpen] = React.useState(false);
  const troubleContentRef = React.useRef<TextAreaHandler>(null);
  const researchContentRef = React.useRef<TextAreaHandler>(null);
  const { showToast } = useToastsContext();
  const navigate = useNavigate();
  const query = queryString.parse(useLocation().search);
  const firstPage =
    query.page && typeof query.page === 'string' && !isNaN(parseInt(query.page))
      ? parseInt(query.page)
      : FIRST_PAGE;
  const [page, setPage] = useState<number>(firstPage);
  const perPage = MIN_LIMIT;

  const [getSpotLesson] = useGetSpotLessonLazyQuery();
  const [updateSpotLesson] = useUpdateSpotLessonMutation();
  const [cancelSpotLesson] = useCancelSpotLessonMutation();

  const {
    data,
    refetch,
    loading: isLoadingCommonSpotLesson,
  } = useGetSpotLessonsQuery({
    variables: {
      input: {
        limit: perPage,
        page: page,
      },
    },
  });
  const spotLessons = data?.spotLessons.items ?? [];
  const total = data?.spotLessons.total ?? 0;

  const refetchSpotLessons = useCallback(
    async (page: number): Promise<void> => {
      try {
        await refetch({
          input: {
            limit: perPage,
            page: page,
          },
        });
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      }
    },
    [refetch, perPage],
  );

  const { data: ticketData, refetch: refetchTicketsQuery } = useGetTicketsQuery({});
  const tickets = ticketData?.tickets ?? [];

  const refetchTickets = useCallback(async (): Promise<void> => {
    try {
      await refetchTicketsQuery({});
    } catch {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
      return;
    }
  }, [refetchTicketsQuery]);

  const changePage = (nextPage: number) => {
    navigate(`?page=${nextPage}`);
    refetchSpotLessons(nextPage);
    setPage(nextPage);
  };

  const onCloseCancelModal = React.useCallback(() => {
    setCurrentSpotLessonID(null);
    setIsCancelModalOpen(false);
  }, []);

  const onClickCancel = React.useCallback((id: number) => {
    setCurrentSpotLessonID(id);
    setIsCancelModalOpen(true);
  }, []);

  const onCancel = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!currentSpotLessonID) return;

      try {
        try {
          await cancelSpotLesson({
            variables: {
              id: currentSpotLessonID,
            },
          });
        } catch (e) {
          showToast(1, getApiErrorMessage(e));
          return;
        }
        // 成功時のコールバック(refetch)
        await refetchSpotLessons(page);
        await refetchTickets();
      } finally {
        onCloseCancelModal();
      }
    }, [
      cancelSpotLesson,
      currentSpotLessonID,
      onCloseCancelModal,
      page,
      refetchSpotLessons,
      refetchTickets,
      showToast,
    ]),
  );

  const onCloseEditModal = React.useCallback(() => {
    setCurrentSpotLessonID(null);
    troubleContentRef.current?.setValue('');
    researchContentRef.current?.setValue('');
    setIsEditModalOpen(false);
  }, []);

  const onClickEdit = useCallback(
    async (id: number) => {
      getSpotLesson({
        variables: {
          id: id,
        },
        onCompleted: (data) => {
          if (!data.spotLesson) return;

          const spotLessonData = data.spotLesson;
          setCurrentSpotLessonID(id);

          troubleContentRef.current?.setValue(
            spotLessonData.troubleContent ? spotLessonData.troubleContent : '',
          );
          researchContentRef.current?.setValue(
            spotLessonData.researchContent ? spotLessonData.researchContent : '',
          );
        },
      });
      setIsEditModalOpen(true);
    },
    [getSpotLesson],
  );

  const onEdit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!currentSpotLessonID) return;

      const troubleContent = fromNullable(troubleContentRef.current?.getValue());
      const researchContent = fromNullable(researchContentRef.current?.getValue());

      if (isNone(troubleContent) || troubleContent.value === '') {
        showToast(1, '困ったことは必須項目です');
        return;
      }
      if (isNone(researchContent) || researchContent.value === '') {
        showToast(1, '調べたことは必須項目です');
        return;
      }

      try {
        try {
          await updateSpotLesson({
            variables: {
              id: currentSpotLessonID,
              input: {
                troubleContent: troubleContent.value,
                researchContent: researchContent.value,
              },
            },
          });
        } catch (e) {
          showToast(1, getApiErrorMessage(e));
          return;
        }
        // 成功時のコールバック(refetch)
        await refetchSpotLessons(page);
      } finally {
        onCloseEditModal();
      }
    }, [
      currentSpotLessonID,
      onCloseEditModal,
      page,
      refetchSpotLessons,
      showToast,
      updateSpotLesson,
    ]),
  );

  const handleClickBuyTicket = useCallback((): void => {
    if (openModal(FunctionType.SpotLessonBuyTicket, PermissionType.Create)) return;
    setIsBuyTicketModalOpen(true);
  }, [openModal]);

  const onClickEvaluation = useCallback(() => {
    window.open(SPOT_LESSON_EVALUATION_FORM_URL);
  }, []);

  // キャンセルモーダルに表示するチケット返却可能期限
  const currentSpotLesson = spotLessons.find((sl) => sl.id === currentSpotLessonID);
  const ticketReturnDeadline = currentSpotLesson
    ? format(
        utcToJst(calcTicketReturnDeadline(new Date(currentSpotLesson.startAt))),
        'yyyy年MM月dd日 HH:mm',
      )
    : null;

  return (
    <BasicLayout pageTitle="レッスン" metaTitle={metaTitle} metaDescription={metaDescription}>
      <PageWrapper>
        <SpotLessonBanner onClickBuyTicket={handleClickBuyTicket} />
        <TicketArea>
          <p className="label">あなたのチケット所持枚数</p>
          <div className="info">
            <p className="tickets">
              <img src={TicketIcon} alt="チケット" />
              <span>{tickets.length}枚</span>
            </p>
            <div className="due">
              <StyledTickets tickets={tickets} />
              <DueLabel>(有効期限)</DueLabel>
            </div>
            {(permissionCheck(FunctionType.SpotLessonBuyTicket, PermissionType.Create) ||
              permissionCheck(FunctionType.RecommendFree, PermissionType.Read) ||
              permissionCheck(FunctionType.RecommendLight, PermissionType.Read)) && (
              <TicketBuyButton onClick={handleClickBuyTicket}>チケット購入</TicketBuyButton>
            )}
          </div>
        </TicketArea>
        <LessonsTitle>予約中のレッスン/これまでの受講履歴</LessonsTitle>
        {spotLessons.length > 0 ? (
          <>
            <LessonsContainer>
              {spotLessons.map((sl) => {
                return (
                  <CommonSpotLessonArticle
                    key={sl.id}
                    permissionCheck={permissionCheck}
                    spotLesson={sl}
                    onClickCancel={onClickCancel}
                    onClickEdit={onClickEdit}
                    onClickEvaluation={onClickEvaluation}
                    editable={
                      sl.phase === SpotLessonPhase.Incomplete &&
                      isAfter(new Date(sl.startAt), today)
                    }
                  />
                );
              })}
            </LessonsContainer>
            <Pagination total={total} perPage={perPage} page={page} setPage={changePage} />
          </>
        ) : (
          <EmptyBlock title="予約中のレッスン、レッスン履歴がありません">
            現在予約中のレッスンと過去のレッスンの一覧がここに表示されます。
          </EmptyBlock>
        )}

        <Modal
          isOpen={isCancelModalOpen}
          onClose={onCloseCancelModal}
          header={<CancelModalHeader>予約のキャンセル</CancelModalHeader>}
          footer={
            <Buttons>
              <Button onClick={onCloseCancelModal} gray>
                取り消す
              </Button>
              <Button onClick={onCancel} disabled={isLoadingCommonSpotLesson}>
                予約のキャンセル
              </Button>
            </Buttons>
          }
          width={'36rem'}
        >
          <CancelModalInner>
            <p className="description">
              <span className="deadline">{ticketReturnDeadline}</span>
              以降のキャンセルは<span className="caution">チケットが消化</span>されます。
              <br />
              ご予約をキャンセルしますか？
            </p>
            <p className="help">
              ご不明な点はこちらの
              <a
                href="https://intercom.help/plus---samurai/ja/articles/5206205-q-%E3%83%AC%E3%83%83%E3%82%B9%E3%83%B3%E3%81%AE%E3%82%AD%E3%83%A3%E3%83%B3%E3%82%BB%E3%83%AB%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
                target="_blank"
              >
                ヘルプページ
              </a>
              をご覧ください。
            </p>
          </CancelModalInner>
          <Loader display={isLoadingCommonSpotLesson} />
        </Modal>

        <Modal
          isOpen={isEditModalOpen}
          onClose={onCloseEditModal}
          header={'相談内容の編集'}
          footer={
            <Buttons>
              <Button onClick={onCloseEditModal} gray>
                キャンセル
              </Button>
              <Button onClick={onEdit} disabled={isLoadingCommonSpotLesson}>
                保存する
              </Button>
            </Buttons>
          }
          width={'550px'}
        >
          <EditModalInner>
            <h4>困っていること</h4>
            <EditTextArea ref={troubleContentRef} name="troubleContent" />
            <h4>調べたこと</h4>
            <EditTextArea ref={researchContentRef} name="researchContent" />
          </EditModalInner>
        </Modal>
        <BuyTicketModal
          isOpen={isBuyTicketModalOpen}
          onComplete={refetchTickets}
          onClose={() => setIsBuyTicketModalOpen(false)}
        />
      </PageWrapper>
    </BasicLayout>
  );
};

const StyledTickets = styled(Tickets)`
  display: none;
  bottom: 1.5rem;
  right: 0;
`;

const TicketArea = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
  box-sizing: border-box;

  .label {
    font-size: 0.9rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);

    &:after {
      content: ':';
    }
  }

  .info {
    display: flex;
    align-items: center;

    .tickets {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;

      span {
        font-size: 1rem;
        font-weight: bold;
        color: #e2001b;
      }
    }

    .due {
      position: relative;
      font-size: 0.75rem;
      cursor: pointer;

      &:hover ${StyledTickets} {
        display: block;
      }
    }
  }

  ${media.lessThan('small')`
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1.5rem;
    
     .label {
        &:after {
          display: none;
        }
      }
      
      .info {
        margin-top: 0.25rem;
      }
  `}
`;

const DueLabel = styled.span`
  color: #e2001b;
  font-size: 0.75rem;
  text-decoration: underline;
`;

const LessonsTitle = styled.h4`
  margin: 2rem auto 1rem;
  font-size: 1.25rem;
  font-weight: 700;

  ${media.lessThan('medium')`
      margin: 1.5rem auto 1rem;
      font-size: 1rem;
  `}
`;
const LessonsContainer = styled.div`
  margin-top: 0.75rem;
  background-color: #ffffff;
  padding: 0 32px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .empty {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.36);
    margin: 2rem 0;
    line-height: 1.5;
  }

  ${media.lessThan('medium')`
    margin-top: 0.5rem;
    padding: 0;

    .empty {
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.36);
      margin: 1rem 1rem 1.5rem 1rem;
    }
  `}
  & > * + *:not(:nth-of-type(1)) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const CancelModalHeader = styled.h4`
  margin: 0 auto;
  font-size: 1.125rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
`;

const CancelModalInner = styled.div`
  padding: 1.5rem 4.375rem 0.5rem;
  box-sizing: border-box;

  .description {
    font-size: 1rem;
    line-height: 1.5;

    .deadline {
      font-size: inherit;
      font-weight: 700;
    }

    .caution {
      font-size: inherit;
      font-weight: 700;
      color: rgb(226, 0, 27);
    }
  }

  .help {
    margin-top: 1.25rem;
    font-size: 0.75rem;
    line-height: 1.8;

    a {
      font-size: inherit;
      color: #fd3c2f;
    }
  }

  ${media.lessThan('medium')`
    padding: 1rem;
    
    .description {
      font-size: 0.8125rem;
      line-height: 1.7;
    }
    
    .help {
      margin-top: 2.125rem;
      font-size: 0.6875rem;
      line-height: 2;
    }
  `}
`;

const EditModalInner = styled.div`
  h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  textarea {
    margin-bottom: 2rem;
  }

  padding: 2rem;
`;
const EditTextArea = styled(TextArea)`
  min-height: 8rem;
  line-height: 1.8;
`;

const TicketBuyButton = styled(Button)`
  margin-left: 0.5rem;
  padding: 0.25rem 0.875rem;
  font-size: 0.75rem;
`;
