import React from 'react';
import styled from 'styled-components';

interface SupplementProps {
  message: string;
}

export const Supplement: React.FC<SupplementProps> = (props) => {
  return (
    <Container>
      <Icon>?</Icon>
      <Message>{props.message}</Message>
    </Container>
  );
};

const Container = styled.div`
  padding: 0.6rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fffccc;
`;

const Message = styled.span`
  line-height: 1.4;
  font-size: 0.8rem;
`;

const Icon = styled.span`
  margin-right: 3px;
  display: inline-block;
  text-align: center;
  height: 1rem;
  width: 1rem;
  background-color: #444444;
  color: white;
  border-radius: 50%;
`;
