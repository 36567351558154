import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { CircularIcon } from '../atoms/CircularIcon';
import { TeamMemberListFragment } from '../../gen/graphql';
import { TeamMemberRoleLabels } from '../../const/TeamMemberRole';
import DefaultIcon from '../../static/image/icon_default.svg';
import { formatReportTime } from '../../utils/StudyReport';

interface Props {
  teamMember: TeamMemberListFragment;
}

export const TeamMemberColumn: React.FC<Props> = ({ teamMember }) => {
  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate(`/users/${teamMember.user.id}/profile`)}>
      <ThumbColumn>
        <CircularIcon src={teamMember.user.image ?? DefaultIcon} size={40} mediumSize={32} />
      </ThumbColumn>
      <NameColumn>
        {`${teamMember.user.maskedPersonalInfo?.name}（${teamMember.user.nickName}）`}
      </NameColumn>
      <Column>{formatReportTime(teamMember.user.studySummary?.studyReportTime)}</Column>
      <Column>{teamMember.user.studySummary?.studyReportCount ?? 0}</Column>
      <Column>{teamMember.user.completedPrograms?.length ?? 0}</Column>
      <Column>{teamMember.user.totalLessonCount?.length ?? 0}</Column>
      <AuthColumn>{TeamMemberRoleLabels[teamMember.role]}</AuthColumn>
    </Container>
  );
};

export const TeamMemberHeader: React.FC = () => {
  return (
    <HeaderContainer>
      <ThumbColumn></ThumbColumn>
      <NameColumn>氏名</NameColumn>
      <Column>総学習時間</Column>
      <Column>総学習回数</Column>
      <Column>完了教材数</Column>
      <Column>総レッスン数</Column>
      <AuthColumn>権限</AuthColumn>
    </HeaderContainer>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0.5rem 0;
  background: #fff;
  cursor: pointer;
  transition: all 0.2s;

  &:nth-of-type(n + 3) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background: #ededed;
  }

  ${media.lessThan('large')`
    width: 924px;
  `}

  ${media.lessThan('medium')`
    gap: 1rem;
    width: 670px;
    margin: 0 auto;
  `}
`;
const Column = styled.div`
  flex: 1;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
`;
const ThumbColumn = styled(Column)`
  flex: none;
  width: 4.5rem;
  padding-left: 1rem;

  ${media.lessThan('medium')`
    width: 2rem;
    padding-left: 0;
  `}
`;
const NameColumn = styled(Column)`
  flex: none;
  width: 7.5rem;
  text-align: left;
  word-break: break-word;

  ${media.lessThan('medium')`
    width: 6.875rem;
  `}
`;
const AuthColumn = styled(Column)`
  flex: none;
  width: 5rem;
  color: #eb0000;
`;

const HeaderContainer = styled(Container)`
  margin-bottom: 1rem;
  padding: 0;
  cursor: default;

  &:hover {
    background: #fff;
  }

  ${Column} {
    color: rgba(0, 0, 0, 0.6);
  }

  ${NameColumn} {
    text-align: center;
  }
`;
