import React from 'react';
import styled from 'styled-components';

interface FrameProps {
  bgColor: string;
  borderColor?: string;
  borderWidth?: string;
  width?: string;
}
interface BorderWidth {
  [key: string]: string;
  normal: string;
  bold: string;
}
interface Color {
  [key: string]: string;
  warning: string;
  danger: string;
  white: string;
}
interface Width {
  [key: string]: string;
  small: string;
  middle: string;
  large: string;
}

const BORDER_WIDTH: BorderWidth = {
  normal: '1px',
  bold: '3px',
};
const COLOR: Color = {
  warning: '#ffe86d',
  danger: '#e2001b',
  gray: 'rgba(0,0,0,.1)',
  white: '#fff',
};
const WIDTH: Width = {
  small: '300px',
  middle: '450px',
  large: '100%',
};

export const Frame: React.FC<FrameProps> = (props) => {
  return (
    <StyledFrame bgColor={props.bgColor} width={props.width}>
      {props.children}
    </StyledFrame>
  );
};

const StyledFrame = styled.div<{ bgColor: string; width?: string }>`
  width: 100%;
  max-width: ${(props) => WIDTH[props.width || 'large']};
  padding: 1.25rem;
  background-color: ${(props) => COLOR[props.bgColor || 'white']};
  box-sizing: border-box;
  line-height: 1.3;
`;

export const BorderFrame: React.FC<FrameProps> = (props) => {
  return (
    <StyledBorderFrame
      bgColor={props.bgColor}
      borderColor={props.borderColor}
      borderWidth={props.borderWidth}
      width={props.width}
    >
      {props.children}
    </StyledBorderFrame>
  );
};

const StyledBorderFrame = styled.div<{
  bgColor: string;
  borderColor?: string;
  borderWidth?: string;
  width?: string;
}>`
  width: 100%;
  max-width: ${(props) => WIDTH[props.width || 'large']};
  padding: 1.35rem;
  background-color: ${(props) => COLOR[props.bgColor || 'white']};
  border: ${(props) => BORDER_WIDTH[props.borderWidth || 'normal']} solid
    ${(props) => COLOR[props.borderColor || 'gray']};
  box-sizing: border-box;
  line-height: 1.3;
`;
