import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import {
  SessionPhase as Phase,
  SessionListForInstructorFragment as Session,
} from '../../gen/graphql';
import { Button } from '../atoms/Button';
import defaultIcon from '../../static/image/icon_default.svg';
import SessionCircleSvg from '../../static/image/icon_lesson_circle.svg';
import { SessionInputModal, SessionInput } from './SessionInputModal';
import { SessionHistoryModal } from './SessionHistoryModal';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { useCategorizedSessionList } from '../../common/customHooks/CategorizedSessionList';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface Props {
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  sessions: Session[];
  plannedSessionCount: number;
  finishedSessionCount: number;
  onUpdateSession: (id: string, input: SessionInput) => Promise<void>;
  onRefetchSession: () => Promise<void>;
}

export const SessionList: React.FC<Props> = ({
  permissionCheck,
  sessions,
  plannedSessionCount,
  finishedSessionCount,
  onUpdateSession,
  onRefetchSession,
}): JSX.Element => {
  const [isOpenInputModal, setIsOpenInputModal] = useState(false);
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);
  const [currentSession, setCurrentSession] = useState<Session>();

  // 入力モーダル開閉
  const handleClickEditButton = useCallback((session: Session): void => {
    setCurrentSession(session);
    setIsOpenInputModal(true);
  }, []);
  const closeInputModal = useCallback((): void => setIsOpenInputModal(false), []);

  // 受講履歴モーダル開閉
  const handleClickHistoryEditButton = useCallback((session: Session): void => {
    setCurrentSession(session);
    setIsOpenHistoryModal(true);
  }, []);
  const closeHistoryModal = useCallback((): void => {
    setIsOpenHistoryModal(false);
  }, []);

  // セッション更新
  const updateSession = useSafeAsyncCallback(
    useCallback(
      async (input: SessionInput): Promise<void> => {
        try {
          if (!currentSession?.id) {
            return;
          }
          await onUpdateSession(currentSession.id, input);
          // 例外処理は上位のコンポーネントにやらせるのでcatchしない
        } finally {
          closeInputModal();
        }
      },
      [currentSession?.id, onUpdateSession, closeInputModal],
    ),
  );

  const { pastSessions, featureSessions, nextSession } = useCategorizedSessionList(sessions);

  return (
    <Container>
      {sessions.length > 0 ? (
        <>
          <NextSessionContainer>
            <CheckList>
              <h4>セッション前確認項目</h4>
              <ol>
                <li>1. 身だしなみを整える</li>
                <li>2. Webカメラの写る範囲の背景を清潔にする</li>
                <li>3. 音声・画面接続確認を5分前に行う</li>
              </ol>
            </CheckList>
            {/* 次回予定あり、または予定回数に到達していない場合のみ次回予定領域を表示する */}
            {(nextSession || plannedSessionCount > finishedSessionCount) && (
              <NextSession>
                {nextSession ? (
                  <>
                    <h4>次回セッション</h4>
                    <section>
                      <NextSessionDate
                        startAt={new Date(nextSession.startAt)}
                        endAt={new Date(nextSession.endAt)}
                      />

                      <div>
                        <h5>{nextSession.title}</h5>
                        <NextSessionUser>
                          <span>
                            <img
                              src={nextSession.coaching.course.student.user.image || defaultIcon}
                              alt="icon"
                              className="icon-img"
                            />
                          </span>
                          {nextSession.coaching.course.student.user.maskedPersonalInfo?.name}
                        </NextSessionUser>
                      </div>
                    </section>
                    {permissionCheck(FunctionType.SessionForCoach, PermissionType.Update) && (
                      <div className="edit">
                        <EditButton onClick={() => handleClickEditButton(nextSession)}>
                          編集
                        </EditButton>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <h4>次回以降のセッションが未登録の状態です</h4>
                    <p className="no-next-session">まだ次回のセッションが登録されていません</p>
                  </>
                )}
              </NextSession>
            )}
          </NextSessionContainer>
          <ListTitle>今後のセッション予定</ListTitle>
          <SessionContents>
            {featureSessions.map((session) => (
              <SessionContent
                permissionCheck={permissionCheck}
                key={session.id}
                session={session}
                isSessionReportSubmittable={false}
                onClickEditButton={handleClickEditButton}
              />
            ))}
          </SessionContents>

          <ListTitle>これまでのセッション（計 {finishedSessionCount} 回）</ListTitle>
          <SessionContents>
            {pastSessions.map((session) => (
              <SessionContent
                permissionCheck={permissionCheck}
                key={session.id}
                session={session}
                isSessionReportSubmittable={true}
                onClickEditButton={handleClickEditButton}
                onOpenHistoryModal={handleClickHistoryEditButton}
              />
            ))}
          </SessionContents>
        </>
      ) : (
        <EmptyMessageArea>
          <img src={SessionCircleSvg} alt="セッション未登録" />
          <h4>まだセッションが登録されていません。</h4>
          <p>セッション日程を登録しましょう。</p>
        </EmptyMessageArea>
      )}
      <SessionInputModal
        isOpen={isOpenInputModal}
        type="edit"
        defaultInput={{
          coachingID: currentSession?.coaching.id,
          ...currentSession,
        }}
        onSubmit={updateSession}
        onClose={closeInputModal}
      />
      <SessionHistoryModal
        isOpen={isOpenHistoryModal}
        sessionID={currentSession?.id ?? ''}
        onRefetchSession={onRefetchSession}
        onClose={closeHistoryModal}
      />
    </Container>
  );
};

const NextSessionDate = ({ startAt, endAt }: { startAt: Date; endAt: Date }) => (
  <StyledNextSessionDate>
    <time>
      {format(startAt, 'MM/dd')}
      <span>({format(startAt, 'E', { locale: ja })})</span>
    </time>
    <time>
      {format(startAt, 'HH:mm')}~{format(endAt, 'HH:mm')}
    </time>
  </StyledNextSessionDate>
);

const SessionContent = ({
  permissionCheck,
  session,
  isSessionReportSubmittable,
  onClickEditButton,
  onOpenHistoryModal,
}: {
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  session: Session;
  isSessionReportSubmittable: boolean;
  onClickEditButton: (session: Session) => void;
  onOpenHistoryModal?: (session: Session) => void;
}) => {
  const startAt = new Date(session.startAt);
  const endAt = new Date(session.endAt);

  return (
    <StyledSessionContent>
      <SessionDate>
        <h3>
          {format(startAt, 'MM/dd')}
          <span>({format(startAt, 'E', { locale: ja })})</span>
        </h3>
        <p>
          {format(startAt, 'HH:mm')}~{format(endAt, 'HH:mm')}
        </p>
        {session.phase === Phase.CanceledByStudent && (
          <CancelLabel>
            キャンセル
            <br />
            (セッション消化)
          </CancelLabel>
        )}
      </SessionDate>
      <SessionInfo>
        <SessionTitle>{session.title}</SessionTitle>
        <InfoArea>
          <div className="user">
            <img src={session.coaching.course.student.user.image || defaultIcon} alt="icon" />
            <h3>{session.coaching.course.student.user.maskedPersonalInfo?.name}</h3>
          </div>
          <ButtonArea>
            {permissionCheck(FunctionType.SessionForCoach, PermissionType.Update) && (
              <EditButton onClick={() => onClickEditButton(session)}>編集</EditButton>
            )}
            {isSessionReportSubmittable &&
              permissionCheck(FunctionType.SessionForCoach, PermissionType.Update) && (
                <EditButton
                  $hasHistory={session.phase != Phase.NotCompleted}
                  onClick={() => onOpenHistoryModal?.(session)}
                >
                  受講履歴{session.phase == Phase.NotCompleted ? '登録' : '編集'}
                </EditButton>
              )}
          </ButtonArea>
        </InfoArea>
      </SessionInfo>
    </StyledSessionContent>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const NextSessionContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 2rem;
  flex-wrap: wrap;
`;

const CheckList = styled.div`
  padding: 1.25rem;
  background-color: #e2001b62;
  box-sizing: border-box;

  h4 {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  ${media.lessThan('large')`
    width: 100%;
  `}
`;

const NextSession = styled.div`
  width: calc(100% - 27rem);
  margin-left: 1.25rem;
  padding: 1.25rem;
  background-color: #ffe86d;
  box-sizing: border-box;
  line-height: 1.3;
  position: relative;

  h4 {
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  section {
    display: flex;
  }

  h5 {
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .edit {
    position: absolute;
    right: 1.25rem;
    bottom: 1.25rem;
  }

  .no-next-session {
    margin-left: 1rem;
    font-size: 1rem;
    font-weight: 700;
  }

  ${media.lessThan('large')`
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
  `}

  ${media.lessThan('small')`
    section {
      flex-direction: column;
    }
  `}
`;

const StyledNextSessionDate = styled.div`
  margin-right: 2rem;
  text-align: center;

  time {
    display: block;
    font-size: 0.75rem;

    &:nth-of-type(1) {
      font-size: 1.5rem;
      font-weight: 600;

      span {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }

  ${media.lessThan('medium')`
    time:nth-of-type(1) {
      font-size: 1.25rem;

      span {
        font-size: 0.75rem;
      }
    }
  `}

  ${media.lessThan('small')`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto 1rem;

    time {
      &:nth-of-type(1) {
        margin-right: 0.5rem;
      }
    }
  `}
`;

const NextSessionUser = styled.p`
  display: flex;
  align-items: center;
  font-size: 0.75rem;

  span {
    margin-right: 0.25rem;

    img {
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
    }
  }
`;

const ListTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 700;
  padding: 1.875rem;
`;

const SessionContents = styled.div`
  width: 100%;
`;

const SessionTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 700;
`;

const InfoArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - calc(4.25rem));
  padding-top: 0.625rem;

  ${media.lessThan('large')`
    width: 100%;
  `}

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: flex-start;
  `}

  h3 {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    margin-left: 0.5rem;
  }

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const StyledSessionContent = styled.div`
  display: flex;
  width: 95%;
  margin: 0 auto;

  &:nth-of-type(n + 2) {
    margin-top: 3rem;
  }

  ${media.lessThan('medium')`
    height: auto;

    &:nth-of-type(n + 2) {
      margin-top: 2rem;
    }
  `}

  ${media.lessThan('small')`
    flex-direction: column;
    width: 100%;
    padding: 1rem 2rem 0;
    border-top: 1px dotted #ddd;
    box-sizing: border-box;

    &:nth-of-type(n + 2) {
      margin-top: 1rem;
    }
  `}
`;

const SessionDate = styled.div`
  width: 6.25rem;
  margin-right: 2rem;
  vertical-align: top;
  text-align: center;

  h3 {
    margin-bottom: 0.25rem;
    font-size: 1.5625rem;
    font-weight: 700;
  }

  span {
    font-size: 0.9375rem;
    font-weight: 400;
  }

  ${media.lessThan('small')`
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: auto;
    text-align: left;
    position: relative;

    h3 {
      margin-right: 0.5rem;
      font-size: 1.25rem;
    }

    span,
    p {
      font-size: 0.75rem;
    }
  `}
`;
const SessionInfo = styled.div`
  flex: 1;

  h3 {
    word-wrap: break-word;
  }

  ${media.lessThan('small')`
    margin-top: 1rem;
  `}
`;
const CancelLabel = styled.div`
  margin: 0.25rem auto 0;
  padding: 0.25rem 0;
  border: 1px solid #e2001b;
  border-radius: 0.25rem;
  color: #e2001b;
  font-size: 0.625rem;
  font-weight: 700;

  ${media.lessThan('small')`
    margin: 0;
    padding: 0.25rem 0.5rem;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
  `}
`;

const ButtonArea = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    justify-content: flex-end;
    width: 100%;
    margin-top: 0.5rem;
  `}
`;

const EditButton = styled(Button)<{ $hasHistory?: boolean }>`
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  &&& {
    background-color: ${(props) => (props.$hasHistory ? '#fff' : '#e2001b')};
    color: ${(props) => (props.$hasHistory ? '#e2001b' : '#fff')};
    border: ${(props) => (props.$hasHistory ? '1px solid #e2001b;' : 'none')};
  }
`;

const EmptyMessageArea = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 8rem;

  h4 {
    margin-top: 2rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  p {
    margin-top: 1rem;
    line-height: 1.8rem;
    color: rgba(0, 0, 0, 0.6);
  }
`;
