import React from 'react';
import styled from 'styled-components';

import { LabeledLayout } from '../../molecules/LabeledLayout';
import { Spacer } from '../../atoms/Spacer';
import { H3 } from '../../atoms/Typography';

import { LOWER_META_TITLE } from '../../../const/Service';
import { useGetCurrentStudentQuery } from '../../../gen/graphql';
import { format, parseISO } from 'date-fns';
import { AccountTabLayout } from '../../templates/AccountTabLayout';
import { useUser } from '../../../redux/user/useUser';

// 日付のフォーマット(画面ごとに異なるのでutil化はしない)
const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString) {
    return '';
  }
  return format(parseISO(dateString), 'yyyy/MM/dd');
};

export const AccountCourse: React.FC = (): JSX.Element => {
  const metaTitle = `契約コース | ${LOWER_META_TITLE}`;

  const { permissionCheck, isSocialLoginUser } = useUser();

  const { data } = useGetCurrentStudentQuery();
  const courses = data?.currentStudent.courses ?? [];

  return (
    <AccountTabLayout
      activeTab="course"
      permissionCheck={permissionCheck}
      isSocialLoginUser={isSocialLoginUser()}
      metaTitle={metaTitle}
    >
      <Container>
        <Content>
          {courses.length > 0 && (
            <React.Fragment>
              <Spacer height={'1rem'} />
              <CourseInfo>
                <H3 color={'Dark'}>契約情報</H3>
                {courses.map((course) => (
                  <Course key={course.id}>
                    <LabeledLayout labelWidth={'120px'} label={<Label>契約ID</Label>}>
                      <ProfileSpan>{course.id}</ProfileSpan>
                    </LabeledLayout>
                    <LabeledLayout labelWidth={'120px'} label={<Label>契約コース</Label>}>
                      <ProfileSpan>{course.plan.name}</ProfileSpan>
                    </LabeledLayout>
                    {(course.startDate || course.endDate) && (
                      <LabeledLayout labelWidth={'120px'} label={<Label>契約期間</Label>}>
                        <ProfileSpan>
                          {formatDate(course.startDate)} ~ {formatDate(course.endDate)}
                        </ProfileSpan>
                      </LabeledLayout>
                    )}
                    {(course.absentStartDate || course.absentEndDate) && (
                      <LabeledLayout labelWidth={'120px'} label={<Label>休学期間</Label>}>
                        <ProfileSpan>
                          {formatDate(course.absentStartDate)} ~ {formatDate(course.absentEndDate)}
                        </ProfileSpan>
                      </LabeledLayout>
                    )}
                  </Course>
                ))}
              </CourseInfo>
            </React.Fragment>
          )}
        </Content>
      </Container>
    </AccountTabLayout>
  );
};

export default AccountCourse;

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const CourseInfo = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0.1);
`;

const Course = styled.div`
  margin-top: 1rem;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
`;

const Label = styled.span`
  font-weight: bold;
`;

const ProfileSpan = styled.span`
  margin: 9px 0;

  & > * + * {
    margin-top: 0.5rem;
  }
`;
