import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { PageWrapper } from '../atoms/PageWrapper';

import IconBack from '../../static/image/icon_search_back.svg';
import IconSearch from '../../static/image/icon_search.svg';
import IconClose from '../../static/image/icon_search_close.svg';
import { SP_WIDTH } from '../../const/Layout';
import { PLACEHOLDER_FOR_PC, PLACEHOLDER_FOR_SP } from '../../const/Search';

interface Props {
  value: string;
  onClose: () => void;
}

export const SearchHeader: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { windowWidth } = useMediaQuery();

  const [currentText, setCurrentText] = React.useState(props.value ?? '');

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentText(e.target.value);
  };

  const search = () => {
    navigate({
      pathname: '/search',
      search: `?word=${encodeURIComponent(currentText)}`,
    });
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.isComposing || e.key !== 'Enter') return;

    search();
  };

  useEffect(() => {
    setCurrentText(props.value);
  }, [props.value]);

  return (
    <SearchForm>
      <SearchFormContainer>
        <CommandIcon alt="戻る" onClick={() => navigate(-1)} src={IconBack} />
        <SearchInput>
          <img onClick={search} src={IconSearch} />
          <input
            onChange={handleChangeText}
            onKeyDown={handleSearchKeyDown}
            placeholder={windowWidth > SP_WIDTH ? PLACEHOLDER_FOR_PC : PLACEHOLDER_FOR_SP}
            type="text"
            value={currentText}
          />
        </SearchInput>
        <CommandIcon alt="閉じる" src={IconClose} onClick={props.onClose} />
      </SearchFormContainer>
    </SearchForm>
  );
};

export const useMediaQuery = () => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { windowWidth };
};

const SearchForm = styled.div`
  padding: 1.125rem 0;
  border-bottom: 1px solid #e5e5e5;
`;
const SearchFormContainer = styled(PageWrapper)`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 0;
  padding-bottom: 0;

  ${media.lessThan('medium')`
    gap: .5rem;
  `}
`;
const SearchInput = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 0 0 0.875rem;
  background: #fff;
  border: 1px solid #524e4d;
  border-radius: 2rem;
  overflow: hidden;

  img {
    display: block;
    width: 1rem;
    height: auto;
    cursor: pointer;
  }

  input {
    flex: 1;
    height: 2rem;
    padding: 0 0.5rem 0 0.625rem;
    background: #fff;
    border: none;
    border-radius: 0;
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: 400;

    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.875rem;
    }
  }

  ${media.lessThan('medium')`
    padding: 0 .5rem;
    background-position: .5rem center;
  `}
`;
const CommandIcon = styled.img`
  display: block;
  width: 1.25rem;
  height: auto;
  cursor: pointer;
`;
