import React, { useEffect } from 'react';

export const useOutsideClick = (
  openRef: React.MutableRefObject<HTMLElement | null>,
  closeRef: React.MutableRefObject<HTMLElement | null>,
  callback: () => void,
): void => {
  const handleClick = (e: Event) => {
    if (openRef.current && openRef.current.contains(e.target as Node)) return;

    if (closeRef.current && !closeRef.current.contains(e.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
