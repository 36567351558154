import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from 'styled-media-query';

import Logo from '../../../static/image/logo.svg';

const Header = styled.header`
  display: flex;
  width: 100%;
  height: 67px;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 2rem;
  box-sizing: border-box;

  img {
    height: 1.5rem;
  }

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;

const Main = styled.main`
  box-sizing: border-box;
  padding: 32px 180px;

  ${media.lessThan('medium')`
    padding: 32px;
  `}

  h2 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
  }
`;

const Content = styled.section`
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;

  p {
    margin-top: 1rem;
    line-height: 1.3;
  }
`;

const Text = styled.h3`
  display: block;
  font-size: 8rem;
  font-weight: bold;
  color: #e2001b;
  text-align: center;
`;

const SubText = styled.h4`
  display: block;
  font-size: 2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.36);
  text-align: center;
`;

const TopLink = styled(Link)`
  color: #e2001b;
`;

export const ForbiddenPage = (
  <React.Fragment>
    <Header>
      <Link to="/">
        <img src={Logo} alt="Samurai" />
      </Link>
    </Header>

    <Main>
      <Content>
        <Text>403</Text>
        <SubText>Forbidden</SubText>
        <p>
          申し訳ございませんが、現在の権限ではお探しのページにアクセスできません。
          <br />
          ログインされているかどうか、違うアカウントでアクセスしていないかどうかをお確かめください。
        </p>
        <p>
          <TopLink to="/">TOPへ移動する</TopLink>
        </p>
      </Content>
    </Main>
  </React.Fragment>
);

export const Forbidden: React.FC = (): JSX.Element => ForbiddenPage;

export default ForbiddenPage;
