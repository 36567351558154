import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';
import { StripeSubscriptionFragment } from '../../gen/graphql';
interface Props {
  isOpen: boolean;
  subscription: StripeSubscriptionFragment;
  loading?: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const SubscriptionCancelReservedModal: React.FC<Props> = ({
  isOpen,
  subscription,
  loading,
  onSubmit,
  onClose,
}): JSX.Element => {
  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={onClose}
      loading={loading}
      width={'572px'}
      header={<Title>プランの解約を取り消す</Title>}
      footer={
        <ButtonWrapper>
          <Button onClick={onClose} gray>
            閉じる
          </Button>
          <Button onClick={onSubmit}>解約を取り消す</Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <Description>
          プランの解約を取り消します。
          <br />
          よろしいですか？
        </Description>
        <Card>
          <CardTitle>解約予定のプラン</CardTitle>
          <CardPlan>{subscription.price?.product.name}</CardPlan>
          <CardPrice>
            ¥{subscription.price?.unitAmount.toLocaleString()}
            <span>(税込)/月</span>
          </CardPrice>
        </Card>
      </Container>
    </Modal>
  );
};

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
`;
const Container = styled.div`
  padding: 2rem 4rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 2rem 1.4rem;
  `}
`;
const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
`;
const Card = styled.div`
  margin: 1.5rem auto 0;
  padding: 1.5rem 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  ${media.lessThan('medium')`
    max-width: 400px;
    padding: 1.5rem;
  `}

  ${media.lessThan('small')`
    padding: 1.5rem 1rem;
  `}
`;
const CardTitle = styled.h3`
  font-size: 1rem;
  font-weight: 700;
`;
const CardPlan = styled.p`
  margin-top: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  span {
    display: inline-block;
    margin-left: 1rem;
  }

  ${media.lessThan('medium')`
    font-size: 0.875rem;

    span {
      font-size: 0.875rem;
    }
  `}

  ${media.lessThan('small')`
    span {
      display: block;
      margin: 0.5rem auto 0;
    }
  `}
`;
const CardPrice = styled.p`
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
  text-align: right;

  span {
    font-size: 0.875rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
