import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { ExamScoreFragment, ExamMedalType } from '../../gen/graphql';
import { getMedalImage } from '../../utils/ProgramExam';

interface ExamHeaderProps {
  chapterTitle?: string;
  bestScore?: number;
  bestMedal?: ExamMedalType;
  answered?: boolean;
  examScore?: ExamScoreFragment;
  disableScore?: boolean;
}

const getResultMessage = (medalType?: ExamMedalType) => {
  switch (medalType) {
    case ExamMedalType.Gold:
      return 'おめでとうございます！金メダルを獲得しました！\n次の章に進みましょう。';
    case ExamMedalType.Shilver:
      return '銀メダルを獲得しました！あとちょっとで金メダルです！\n繰り返し教材を再度見直して100点を目指しましょう。';
    case ExamMedalType.Bronze:
      return '銅メダルを獲得しました！\nまだまだ理解できていない部分があるようです。繰り返し教材を見直してみましょう。';
    default:
      return 'メダルを獲得できませんでした。\n理解が不十分なので改めて教材を見直しましょう';
  }
};

export const ExamHeader: React.FC<ExamHeaderProps> = (props) => {
  return (
    <Container>
      <h2>{props.chapterTitle}</h2>
      {!props.disableScore && (
        <ScoreArea>
          <div className="scores">
            {props.answered ? (
              <div className="answered_container">
                <div className="answered">
                  <img src={getMedalImage(props.examScore?.medalType)} alt="メダル" />
                  <p className="score">
                    <span className="large">{props.examScore?.score}</span>/100点
                  </p>
                </div>
                <p className="comment">{getResultMessage(props.examScore?.medalType)}</p>
              </div>
            ) : (
              <>
                <div className="best">
                  <p className="label">自己ベスト</p>
                  <p className="score">
                    <span className="large">{props.bestScore ? props.bestScore : '-'}</span>点
                  </p>
                </div>
                <div className="medal">
                  <p className="label">獲得メダル</p>
                  <p className="medal_img">
                    <img src={getMedalImage(props.bestMedal)} alt="メダル" />
                  </p>
                  <MedalTip className="tip">
                    <p>確認テストの点数によってメダルが付与されます。</p>
                    <p>100点 ： 金メダル</p>
                    <p>99~80点： 銀メダル</p>
                    <p>79~60点： 銅メダル</p>
                  </MedalTip>
                </div>
              </>
            )}
          </div>
        </ScoreArea>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin-bottom: 32px;
  padding-bottom: 40px;

  h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #363636;
    text-align: center;

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 4px;
      background-color: #e2001b;
      margin: 25px auto;
    }
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.05);
  }

  ${media.lessThan('medium')`
    margin-bottom: 1rem;
    padding: 0 0 1rem;

    h2 {
      font-size: 1.5rem;
      line-height: 1.5;
    }
  `}
`;

const ScoreArea = styled.div`
  width: 480px;
  background-color: #fcf6f6;
  border: 1px solid rgba(219, 39, 39, 0.1);
  padding: 15px 22px;
  margin: 0 auto;

  .scores {
    display: flex;
    justify-content: center;
    align-items: center;

    .label {
      font-size: 1.1rem;
      color: rgba(0, 0, 0, 0.87);
      margin-right: 10px;
    }

    .best {
      display: flex;
      align-items: center;

      .score {
        font-size: 1.1rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;

        .large {
          font-size: 2.5rem;
          font-weight: bold;
          color: #eda91d;
          margin-bottom: -6px;
        }
      }
    }

    .medal {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 40px;

      &:hover {
        .tip {
          display: block;
        }
      }
    }

    .answered_container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .answered {
      display: flex;
      align-items: center;

      .score {
        font-size: 1.1rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        align-items: flex-end;
        margin-left: 4px;

        .large {
          font-size: 2.5rem;
          font-weight: bold;
          color: #eda91d;
          margin-bottom: -6px;
        }
      }
    }

    .comment {
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.87);
      line-height: 1.5;
      text-align: center;
      margin-top: 8px;
      white-space: pre-wrap;
    }
  }

  ${media.lessThan('medium')`
    width: calc(100% - 2rem);
    box-sizing: border-box;
    padding: 16px;

    .scores {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 24px;

      .label {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.87);
        margin-right: 10px;
      }

      .best, .medal {
        margin-left: 0;
      }

      .best {
        display: flex;
        align-items: center;

        .score {
          font-size: 1.1rem;
          font-weight: bold;
          color: rgba(36, 29, 29, 0.87);
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;

          .large {
            font-size: 1.875rem;
            font-weight: bold;
            color: #eda91d;
            margin-bottom: -4px;
          }
        }
      }

      .medal {
        img {
          margin-left: 20px;
        }
      }

      .comment {
        font-size: 0.875rem;
      }
    }
  `}
`;

const MedalTip = styled.div`
  display: none;
  position: absolute;
  bottom: -88px;
  right: 0;
  width: 300px;
  border: 1px solid #f66969;
  background-color: #fff;
  padding: 7px;

  &:before {
    position: absolute;
    right: 50px;
    top: -9px;
    content: '';
    border-right: 6px solid transparent;
    border-bottom: 9px solid #f66969;
    border-left: 6px solid transparent;
  }

  &:after {
    position: absolute;
    right: 51px;
    top: -7px;
    content: '';
    border-right: 5px solid transparent;
    border-bottom: 7px solid #ffffff;
    border-left: 5px solid transparent;
  }

  p {
    text-align: center;
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.4;
  }
`;
