import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { LpFv } from '../../organisms/lp/LpFv';
import { LpDeal } from '../../organisms/lp/LpDeal';
import { Plan } from '../../organisms/lp/Plan';
import { Campaign } from '../../organisms/lp/Campaign';
import { SubscriptionLayout } from '../../templates/SubscriptionLayout';
import { LpFooter } from '../../organisms/lp/LpFooter';
import { scrollToID } from '../../../utils/ui/Anchor';
import { SubscriptionFeature } from '../../organisms/lp/SubscriptionFeature';
import { LOWER_META_TITLE } from '../../../const/Service';

export const AccountSubscriptionLp: React.FC = (): JSX.Element => {
  const link = () => scrollToID('plan');
  const metaTitle = `加入プラン | ${LOWER_META_TITLE}`;

  return (
    <SubscriptionLayout metaTitle={metaTitle}>
      <LpFv isResigning={true} onClickCV={link} subscription />
      <SubscriptionFeature />
      <LpDeal />
      <Campaign onClickCV={link} subscription />
      <Plan type="recontract" forwardToSubscription />
      <FooterWrapper>
        <LpFooter />
      </FooterWrapper>
    </SubscriptionLayout>
  );
};

const FooterWrapper = styled.div`
  margin-top: 3rem;
  ${media.lessThan('medium')`
    margin-top: 0;
  `}
`;
