import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import { CircularIcon } from '../atoms/CircularIcon';

import { MyTeamMemberFragment } from '../../gen/graphql';
import { useOutsideClick } from '../../common/customHooks/OutsideClick';

interface PopupMenuProps {
  openRef: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  myTeamMembers: MyTeamMemberFragment[];
  changeTeamID: (value: string) => void;
}

export const TeamPopupMenu: React.FC<PopupMenuProps> = ({
  openRef,
  isOpen,
  setIsOpen,
  myTeamMembers,
  changeTeamID,
}) => {
  const menuRef = useRef<HTMLUListElement>(null);

  useOutsideClick(openRef, menuRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (openRef.current) openRef.current.onclick = () => setIsOpen(true);
  }, [openRef, setIsOpen]);

  return (
    <TeamList ref={menuRef} active={isOpen}>
      {myTeamMembers.map((myTeamMember) => {
        return (
          <Item>
            <Link to="/team" onClick={() => changeTeamID(myTeamMember.teamID)}>
              <CircularIcon src={myTeamMember.team.image} size={20} />
              {myTeamMember.team.companyName}
            </Link>
          </Item>
        );
      })}
    </TeamList>
  );
};

const TeamList = styled.ul<{ active: boolean }>`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.75rem 1rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  inline-size: max-content;
  position: fixed;
  right: 7.5rem;
  top: 2rem;
  z-index: 3;

  ${media.lessThan('medium')`
    max-width: 288px;
    box-sizing: border-box;
    right: 3rem;
  `}
`;
const Item = styled.li`
  user-select: none;

  a {
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
    padding: 0.375rem 0;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
  }
`;
