import isWithinInterval from 'date-fns/isWithinInterval';

const isWithinRangeDate = (targetDate: Date | null, startDate: Date, endDate: Date): boolean => {
  if (
    targetDate === null ||
    targetDate === undefined ||
    startDate === null ||
    startDate === undefined ||
    endDate === null ||
    endDate === undefined
  ) {
    return false;
  }
  return isWithinInterval(targetDate, { start: startDate, end: endDate });
};

export default isWithinRangeDate;
