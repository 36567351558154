import { UserRole } from '../gen/graphql';

const KEY_USER_ROLE = 'user.role' as const;
const KEY_USER_TEAM_ID = 'user.team.id' as const;
const KEY_ONBOARDING_CLOSED = 'close_onboarding' as const;

// LocalStorageはUserIDと紐づける
const localStorageKey = (key: string, userID: number): string => {
  return `${key}#${userID}`;
};

const getItem = (key: string, userID: number): string | null => {
  try {
    return localStorage.getItem(localStorageKey(key, userID));
  } catch {
    // localStorageをサポートしてない環境で発生するerrorは握りつぶす(今はほとんどいないと思われる)
    return null;
  }
};

const setItem = (key: string, userID: number, value: string): void => {
  try {
    localStorage.setItem(localStorageKey(key, userID), value);
  } catch {
    // localStorageをサポートしてない環境で発生するerrorは握りつぶす(今はほとんどいないと思われる)
  }
};

const removeItem = (key: string, userID: number): void => {
  try {
    localStorage.removeItem(localStorageKey(key, userID));
  } catch {
    // localStorageをサポートしてない環境で発生するerrorは握りつぶす(今はほとんどいないと思われる)
  }
};

/**
 * ユーザーのロールを取得(ロール切り替え時の永続化対応)
 * @return {UserRole|null} ユーザーのロール
 */
export const getUserRoleFromLocalStorage = (userID: number): UserRole | null => {
  const role = getItem(KEY_USER_ROLE, userID);
  return Object.values(UserRole).find((value) => value === role) ?? null;
};

/**
 * ユーザーのロールをセット(ロール切り替え時の永続化対応)
 * @param role {UserRole} ユーザーのロール
 * @return {void}
 */
export const setUserRoleToLocalStorage = (role: UserRole, userID: number): void =>
  setItem(KEY_USER_ROLE, userID, role);

/**
 * ユーザーのチームIDを取得
 * @return {string | null}
 */
export const getUserTeamIDFromLocalStorage = (userID: number): string | null =>
  getItem(KEY_USER_TEAM_ID, userID);

/**
 * ユーザーのチームIDをセット(チーム切り替え時の永続化対応)
 * @param teamID {string} ユーザーのチームID
 * @return {void}
 */
export const setUserTeamIDToLocalStorage = (teamID: string, userID: number): void =>
  setItem(KEY_USER_TEAM_ID, userID, teamID);

/**
 * ユーザーのチームIDを削除
 * @return {void}
 */
export const removeUserTeamIDToLocalStorage = (userID: number): void =>
  removeItem(KEY_USER_TEAM_ID, userID);

/**
 * オンボーディングカードの開閉状態取得
 * @return {boolean} true:閉じている/false:閉じていない
 */
export const getOnboardingClosedFromLocalStorage = (userID: number): boolean =>
  getItem(KEY_ONBOARDING_CLOSED, userID) === 'true';

/**
 * オンボーディングカードを閉じた状態にする
 * @return {void}
 */
export const setOnboardingClosedToLocalStorage = (userID: number): void => {
  setItem(KEY_ONBOARDING_CLOSED, userID, 'true');
};

export const clearLocalStorage = (userID: number): void => {
  // logout後も永続化させておきたいものは一旦退避して入れ直す(logout後にすべてclearする既存仕様をなるべく踏襲)
  // HACK: もう少しスマートなやり方で対応したい(そもそもログアウト時のlocalStorage.clearは必要？)
  const persistentItems = [KEY_USER_ROLE, KEY_ONBOARDING_CLOSED].map((key) => ({
    key,
    item: getItem(key, userID),
  }));

  try {
    localStorage.clear();
  } catch {
    // localStorageをサポートしてない環境で発生するerrorは握りつぶす(今はほとんどいないと思われる)
    return;
  }

  persistentItems.forEach(({ key, item }) => {
    if (item) {
      setItem(key, userID, item);
    }
  });
};
