import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Modal } from '../molecules/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const PracticeHintModal: React.FC<Props> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      width="800px"
      header={<Title>課題提出でつまづいたら</Title>}
    >
      <Container>
        <Text>課題提出のつまづきやすいポイント別に対処法を記載します。</Text>
        <Text>
          <span>①GitHubの使い方がよくわからずつまづいている</span>
          <br />
          <br />
          まずは、「
          <a href={`${process.env.REACT_APP_DOMAIN}/programs/97/chapters`} target="_blank">
            GitHubの基本的な使い方を学ぼう
          </a>
          」の教材を再度確認しましょう。
          <br />
          それでも不明な場合はQ&A掲示板もしくはレッスンにて講師にご相談ください。
          <br />
          <br />
          <span>②課題の内容が難しくてつまづいている</span>
          <br />
          <br />
          課題が挿入されている前の章を再度復習しましょう。
          <br />
          <br />
          課題の内容は原則として前の章の内容を理解できていれば回答できる難易度に設定しています。
          <br />
          それでも難しい場合は、Q&A掲示板、もしくはレッスンにてご相談ください。
          <br />
          <br />
          相談する際は、何に困っているかを具体的に伝えていだけますと、素早い問題解決に繋がります。
        </Text>
      </Container>
    </Modal>
  );
};

const Title = styled.h2`
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375;
  text-align: center;
`;
const Container = styled.div`
  max-width: 40rem;
  margin: 0 auto;
  padding: 2rem 1rem 3rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 1.5rem 1rem;
  `}
`;
const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.4;

  a {
    color: #eb0000;
    font-size: 1em;
    text-decoration: underline;
  }

  span {
    font-size: 1em;
    font-weight: 700;
  }

  &:nth-of-type(n + 2) {
    margin-top: 0.5rem;
  }

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.25rem;

    &:nth-of-type(n + 2) {
      margin-top: 1rem;
    }
  `}
`;
