import React from 'react';
import styled from 'styled-components';

interface TextAreaProps {
  placeholder?: string;
  width?: string;
  height?: string;
  error?: boolean;
  name: string;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onFocus?: () => void;
  className?: string;
  disabled?: boolean;
  e2e?: string;
  maxLength?: number;
}

export interface TextAreaHandler {
  getValue: () => string;
  setValue: (v: string) => void;
  getSelectionStart: () => number | undefined;
  getSelectionEnd: () => number | undefined;
  focus: () => void;
  setSelectionRange: (start: number, end: number) => void;
  setHeight: (height: string) => void;
}

export const TextArea = React.forwardRef<TextAreaHandler, TextAreaProps>((props, ref) => {
  const [value, setValue] = React.useState('');
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  React.useImperativeHandle(ref, (): TextAreaHandler => {
    return {
      getValue: () => value,
      setValue: (v: string) => setValue(v),
      getSelectionStart: () => textAreaRef.current?.selectionStart,
      getSelectionEnd: () => textAreaRef.current?.selectionEnd,
      focus: () => textAreaRef.current?.focus(),
      setSelectionRange: (start: number, end: number) =>
        textAreaRef.current?.setSelectionRange(start, end),
      setHeight: (height: string) => {
        const element = textAreaRef.current;
        if (!element) return;
        element.style.height = height;
      },
    };
  });

  return (
    <StyledTextArea
      className={props.className}
      width={props.width}
      height={props.height}
      placeholder={props.placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      error={props.error}
      ref={textAreaRef}
      onKeyUp={props.onKeyUp}
      onFocus={props.onFocus}
      disabled={props.disabled}
      data-e2e={props.e2e}
      maxLength={props.maxLength}
    />
  );
});

const StyledTextArea = styled.textarea<{ width?: string; height?: string; error?: boolean }>`
  appearance: none;
  padding: 0.5rem;
  border: 1px solid ${(props): string => (props.error ? '#fd2f92' : '#dddddd')};
  border-radius: 2px;
  background-color: ${(props): string => (props.error ? '#fce5e8' : '#ffffff')};
  box-sizing: border-box;
  width: 100%;
  ${(props): string => (props.width ? `max-width: ${props.width};` : '')}
  ${(props): string => (props.height ? `height: ${props.height};` : '')}
`;
