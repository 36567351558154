import { differenceInDays } from 'date-fns';

const differenceInDaysGreaterThanZero = (to: Date | null): number => {
  const from = new Date(new Date().toDateString());

  if (!to) {
    return 0;
  }

  const diff = differenceInDays(to, from);
  if (diff < 0) {
    return 0;
  }
  return diff;
};

export default differenceInDaysGreaterThanZero;
