import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { InputLabel } from '../atoms/InputLabel';
import styled from 'styled-components';
import media from 'styled-media-query';
import lodash from 'lodash';
import { useOutsideClick } from '../../common/customHooks/OutsideClick';

export interface Option {
  id: number | string;
  name: string;
}

interface InputWithSearchProps {
  label: string;
  hasLabel: boolean;
  options: Option[];
  width?: string;
  inputWidth?: string;
  flexGrow?: string;
  error?: boolean;
  defaultValue?: Option;
  // defaultValueはpropsの変更を反映してくれないので、apiでフェッチしたものを初期値にセットしたい場合などはこちら
  value?: Option | null;
  deletable?: boolean;
  shownId?: boolean;
  handleInput: (value: string) => void;
  onSelect?: (id: number | string | null) => void;
  className?: string;
}

interface Handler {
  getValue: () => Option | null;
  setValue: (option: Option) => void;
  clear: () => void;
}

// InputWithSearchForULIDはULIDと通常のIDどちらでも使用できるコンポーネントです
// TODO: 最終的にはInputWithSearchではなくInputWithSearchForULIDを使う方向で修正し、InputWithSearchForULIDをInputWithSearchにリネームすること
export const InputWithSearchForULID = forwardRef<Handler, InputWithSearchProps>(
  (props, ref): JSX.Element => {
    const [currentChoice, setCurrentChoice] = useState<Option | null>(
      props.defaultValue ? props.defaultValue : null,
    );
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const openRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);

    useOutsideClick(openRef, optionsRef, (): void => {
      setIsOpen(false);
      if (inputRef.current && inputRef.current.value !== '') {
        props.handleInput('');
        inputRef.current.value = '';
      }
    });

    const handleOpen = (): void => {
      props.handleInput(''); // fetch default value
      setIsOpen(true);
    };

    const emitInput = (): void => {
      props.handleInput(inputRef.current?.value ? inputRef.current?.value : '');
    };

    const handleChoice = (option: Option | null): void => {
      setCurrentChoice(option);
      setIsOpen(false);
      props.handleInput('');
      if (inputRef.current) inputRef.current.value = '';

      if (typeof props.onSelect !== 'undefined') props.onSelect(option ? option.id : null);
    };

    const clear = () => {
      setCurrentChoice(null);
    };

    useImperativeHandle(ref, () => {
      return {
        getValue: (): Option | null => currentChoice,
        setValue: (option: Option) => setCurrentChoice(option),
        clear: clear,
      };
    });

    useEffect(() => {
      if (props.value !== undefined) {
        setCurrentChoice(props.value);
      }
    }, [props.value]);

    return (
      <Container
        ref={optionsRef}
        width={props.width}
        flexGrow={props.flexGrow ? props.flexGrow : '0'}
        className={props.className}
      >
        {props.hasLabel && <InputLabel for="">{props.label}</InputLabel>}
        <SearchContainer>
          <Input onClick={handleOpen} error={props.error}>
            <CurrentChoice selected={Boolean(currentChoice && currentChoice.name)}>
              {currentChoice && currentChoice.name ? currentChoice.name : '選択なし'}
            </CurrentChoice>
            <span>▼</span>
          </Input>
          <Options isOpen={isOpen}>
            <div>
              <SearchInput
                placeholder="検索"
                onKeyUp={lodash.debounce(emitInput, 500)}
                ref={inputRef}
              ></SearchInput>
            </div>
            <Ul>
              {props.deletable ? <Li onClick={(): void => handleChoice(null)}>選択なし</Li> : ''}
              {props.options?.map((option): JSX.Element => {
                return (
                  <Li key={option?.id} onClick={() => handleChoice(option)}>
                    {props.shownId && option.id ? `${option.id}: ` : ''}
                    {option?.name}
                  </Li>
                );
              })}
            </Ul>
          </Options>
        </SearchContainer>
      </Container>
    );
  },
);

const Container = styled.div<{ width?: string; flexGrow: string }>`
  display: flex;
  position: relative;
  align-items: center;
  ${(props): string => (props.width ? `width: ${props.width}` : '')};
  flex-grow: ${(props): string => props.flexGrow};
`;

const SearchContainer = styled.div`
  flex: 1;
  position: relative;
`;

const Options = styled.div<{ isOpen: boolean }>`
  z-index: 2;
  background-color: #ffffff;
  display: ${(props): string => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 2rem;
  left: 0;
  width: 320px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);

  ${media.lessThan('small')`
    width: 280px;
  `}
`;

const SearchInput = styled.input`
  width: calc(100% - 2rem);
  margin: 0.5rem 1rem;
  padding: 0.5rem;
  appearance: none;
  background: #f2f2f2;
  border: none;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 0.875rem;

  &:focus {
    outline: none;
  }
`;

const Ul = styled.ul`
  max-height: 12rem;
  overflow-y: scroll;
`;

const Li = styled.li`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const Input = styled.div<{ width?: string; error?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${(props): string => (props.error ? '#fd2f92' : '#dddddd')};
  border-radius: 2px;
  background-color: ${(props): string => (props.error ? '#fce5e8' : '#ffffff')};
  ${(props): string => (props.width ? `width: ${props.width};` : '')}
  box-sizing: border-box;
  padding: 0.5rem;
`;

const CurrentChoice = styled.span<{ selected: boolean }>`
  color: ${(props): string => (props.selected ? 'rgba(0, 0, 0, 0.9)' : 'rgba(0, 0, 0, 0.36)')};
`;
