import React, { useRef, useCallback, SyntheticEvent } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Button } from '../atoms/Button';
import { CircularIcon } from '../atoms/CircularIcon';
import UserType from '../../const/UserType';
import { CurrentUserFragment as User } from '../../gen/graphql';

interface TimelineCommentInputProps {
  user: User;
  isShow: boolean;
  onSubmit: (content: string) => Promise<void>;
}

export const TimelineCommentInput: React.FC<TimelineCommentInputProps> = (props) => {
  const contentRef = useRef(document.createElement('textarea'));

  contentRef.current.onkeyup = () => {
    const lineBreak = contentRef.current.value.match(/\n/g);
    if (lineBreak) {
      if (lineBreak.length <= 10) {
        contentRef.current.style.height = `${lineBreak.length * 1.1 + 3}rem`;
      } else {
        contentRef.current.style.height = `${10 * 1.1 + 3}rem`;
      }
    } else {
      contentRef.current.style.height = '40px';
    }
  };

  const handleClickSubmit = useCallback(
    async (e: SyntheticEvent): Promise<void> => {
      // 利用側のタイムラインカード全体がリンクになっているので遷移をキャンセルさせる
      e.preventDefault();

      const content = contentRef.current.value.trim();
      if (content.length === 0) {
        // 既存の学習ログと挙動をあわせるため、エラーメッセージなどは出さない
        return;
      }
      await props.onSubmit(contentRef.current.value);
      // 同じ内容で何度も投稿されないように、投稿後は入力欄を空にする
      contentRef.current.value = '';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onSubmit],
  );

  return (
    <CommentInputContainer isShow={props.isShow}>
      <Icon>
        <CircularIcon
          src={props.user.image}
          size={40}
          userType={
            props.user.isInstructor
              ? UserType.Instructor
              : props.user?.isStudent
                ? UserType.Student
                : UserType.SnsUser
          }
        />
      </Icon>
      <InputArea data-e2e="tweetCommentInput">
        <TextArea
          name="content"
          placeholder="コメントを追加..."
          ref={contentRef}
          onClick={(e: React.MouseEvent<HTMLTextAreaElement>) => e.preventDefault()}
        />
        <StyledButton onClick={handleClickSubmit}>投稿する</StyledButton>
      </InputArea>
    </CommentInputContainer>
  );
};

const CommentInputContainer = styled.div<{ isShow: boolean }>`
  display: flex;
  transition: all 0.2s;
  height: 0;
  overflow: hidden;
  padding: 0 1rem;

  ${(props) =>
    props.isShow
      ? `
    height: auto;
    padding: 1rem;
    padding-top: 0;
  `
      : ''}
`;
const Icon = styled.div`
  margin-right: 0.75rem;
  padding: 1rem 0 0 0.5rem;
`;
const InputArea = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('small')`
    flex-direction: column;
    gap: .5rem;
  `}
`;
const TextArea = styled.textarea<{ initialHeight?: number }>`
  flex: 1;
  height: ${(props) => (props.initialHeight ? `${props.initialHeight}rem` : '40px')};
  padding: 0.5rem;
  resize: none;
  appearance: none;
  border: none;
  border-radius: 2px;
  background: #f5f5f5;
  box-sizing: border-box;
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
const StyledButton = styled(Button)`
  padding-left: 1rem;
  padding-right: 1rem;

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
