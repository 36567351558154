import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { isNonEmpty } from 'fp-ts/lib/Array';

import { useOutsideClick } from '../../common/customHooks/OutsideClick';

interface ControlProps {
  className?: string;
  menu: MenuProps[];
}

export interface MenuProps {
  text: string;
  to?: string;
  onClick?: () => void;
}

export const Control: React.FC<ControlProps> = (props) => {
  const [open, setOpen] = useState(false);
  const statusTipRef = useRef<HTMLDivElement>(null);
  const openTipRef = useRef<HTMLDivElement>(null);

  useOutsideClick(openTipRef, statusTipRef, (): void => {
    setOpen(false);
  });

  return (
    <Container className={props.className} ref={statusTipRef}>
      <ControlIcon onClick={() => setOpen(!open)} ref={openTipRef}>
        <span></span>
      </ControlIcon>
      {isNonEmpty(props.menu) && (
        <ControlMenu open={open}>
          {props.menu.map((menuItem, i) => (
            <li key={i}>
              {menuItem.to ? (
                <Link to={menuItem.to}>
                  <Button
                    onClick={() => {
                      menuItem.onClick?.();
                      setOpen(false);
                    }}
                  >
                    {menuItem.text}
                  </Button>
                </Link>
              ) : (
                <Button
                  onClick={() => {
                    menuItem.onClick?.();
                    setOpen(false);
                  }}
                >
                  {menuItem.text}
                </Button>
              )}
            </li>
          ))}
        </ControlMenu>
      )}
    </Container>
  );
};

const Container = styled.div``;
const ControlIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;

  span {
    display: block;
    width: 0.25rem;
    height: 0.25rem;
    margin: auto;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      position: absolute;
      top: 0;
    }

    &:before {
      left: -7px;
    }

    &:after {
      right: -7px;
    }
  }

  &:hover {
    span {
      background: #eb0000;

      &:before,
      &:after {
        background: #eb0000;
      }
    }
  }
`;
const ControlMenu = styled.ul<{ open: boolean }>`
  padding: 0.5rem 0;
  background: #fff;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  right: 0;
  opacity: ${(props) => (props.open ? '1' : '0')};
  pointer-events: ${(props) => (props.open ? 'all' : 'none')};
  transition: all 0.2s;
`;
const Button = styled.p`
  width: 10rem;
  padding: 0.625rem 1rem;
  cursor: pointer;
  font-size: 1rem;
`;
