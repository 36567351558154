import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Button } from '../../atoms/Button';
import { CardBrand } from '../../atoms/CardBrand';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Loader } from '../../molecules/Loader';

import { Payment } from '../../../const/Payment';
import { useToastsContext } from '../../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useUser } from '../../../redux/user/useUser';
import {
  ErrorCode,
  StripePaymentMethodFragment,
  PaymentMethodType,
  useGetTeamPaymentMethodsQuery,
  useGetTeamQuery,
  useTeamDeletePaymentMethodMutation,
  useTeamSelectPaymentMethodMutation,
  useTeamUpdatePaymentMethodTypeMutation,
  useGetTeamExistSubscriptionQuery,
} from '../../../gen/graphql';
import { PaymentDeleteModal } from '../../organisms/PaymentDeleteModal';
import { PaymentAddModal } from '../../organisms/PaymentAddModal';
import { ApolloError } from '@apollo/client';
import { getGraphQLErrorCodes } from '../../../utils/graphqlError';
import { MINIMUM_NUMBER_OF_USERS_FOR_BANK_TRANSFER } from '../../../const/team';
import { TeamSettingTabLayout } from '../../templates/TeamSettingTabLayout';
import { TeamSettingBreadcrumb } from '../../templates/TeamSettingBreadcrumb';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { LOWER_META_TITLE } from '../../../const/Service';

export const TeamPayment: React.FC = () => {
  const metaTitle = `お支払い方法 | ${LOWER_META_TITLE}`;

  const now = new Date();

  const [addIsOpen, setAddIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [deleteCard, setDeleteCard] = useState<StripePaymentMethodFragment>();
  const [selectPaymentMethodsLoading, setSelectPaymentMethodsLoading] = useState<boolean>(false);
  const [radioButtonClicked, setRadioButtonClicked] = useState(false);

  const { showToast } = useToastsContext();
  const { user, permissionCheck } = useUser();

  // v1.0でサブスクの契約状態確認の為取得。v1.5では不要になるので削除
  const { data: teamExistSubscriptionData, loading: teamExistSubscriptionLoading } =
    useGetTeamExistSubscriptionQuery({
      variables: {
        teamID: user.teamID ?? '',
      },
      skip: !user.teamID,
    });
  const validTeamSubscription = teamExistSubscriptionData?.teamExistSubscription ?? false;

  const {
    data: getTeamData,
    loading: getTeamLoading,
    refetch: getTeamRefetch,
  } = useGetTeamQuery({
    variables: {
      id: user.teamID ?? '',
    },
    skip: !user.teamID,
    notifyOnNetworkStatusChange: true,
  });
  const paymentMethodType = getTeamData?.team.paymentMethodType ?? undefined;
  const fetchTeam = useCallback(async () => {
    await getTeamRefetch();
  }, [getTeamRefetch]);

  /* 各コンポーネントはAccountPayment.tsxと共通化（処理が異なるので別コンポーネントにする可能性有り） */
  const {
    data: getPaymentMethodsData,
    loading: getPaymentMethodsLoading,
    refetch: getPaymentMethodsRefetch,
  } = useGetTeamPaymentMethodsQuery({
    variables: {
      teamID: user.teamID ?? '',
      limit: Payment.MAX_ITEM_NUMBER,
    },
    onError: () => {
      showToast(1, defaultErrorMessage);
    },
    skip: !user.teamID,
  });
  const paymentMethods = useMemo(
    () => getPaymentMethodsData?.teamPaymentMethods.items ?? [],
    [getPaymentMethodsData],
  );

  const [selectPaymentMethodMutation] = useTeamSelectPaymentMethodMutation();
  const [deletePaymentMethodMutation] = useTeamDeletePaymentMethodMutation();
  const [updatePaymentMethodTypeMutation] = useTeamUpdatePaymentMethodTypeMutation();

  const fetchPayments = useCallback(async () => {
    await getPaymentMethodsRefetch();
  }, [getPaymentMethodsRefetch]);

  const changeDefaultPayment = useSafeAsyncCallback(
    useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const providerID = event.target.value;
        setSelectPaymentMethodsLoading(true);
        setRadioButtonClicked(true);

        try {
          await selectPaymentMethodMutation({
            variables: {
              teamID: user.teamID ?? '', // ここに来る時点でteamIDがないことはありえない
              providerID,
            },
          });
          await fetchPayments();
        } catch {
          showToast(1, defaultErrorMessage);
          return;
        } finally {
          setSelectPaymentMethodsLoading(false);
          setRadioButtonClicked(false);
        }

        showToast(0, 'デフォルトのお支払い方法を変更しました。');
      },
      [fetchPayments, selectPaymentMethodMutation, showToast, user.teamID],
    ),
  );

  const changePaymentMethodType = useSafeAsyncCallback(
    useCallback(
      async (type: PaymentMethodType): Promise<void> => {
        setSelectPaymentMethodsLoading(true);

        try {
          await updatePaymentMethodTypeMutation({
            variables: {
              teamID: user.teamID ?? '', // ここに来る時点でteamIDがないことはありえない
              paymentMethodType: type,
            },
          });
          await fetchTeam();
        } catch (e) {
          const message =
            e instanceof ApolloError &&
            getGraphQLErrorCodes(e).includes(ErrorCode.LessThanMinimumNumberOfTeamMembers)
              ? `ご利用人数が${MINIMUM_NUMBER_OF_USERS_FOR_BANK_TRANSFER}名未満の場合は銀行振込を利用できません。`
              : defaultErrorMessage;

          showToast(1, message);
          return;
        } finally {
          setSelectPaymentMethodsLoading(false);
        }
        showToast(0, 'お支払い方法を変更しました。');
      },
      [showToast, updatePaymentMethodTypeMutation, user.teamID, fetchTeam],
    ),
  );

  const createPaymentMethod = useCallback(
    async (providerID: string): Promise<void> => {
      // ローディング表示や、例外処理はモーダル(PaymentAddModal)コンポーネント内で実施されている
      await selectPaymentMethodMutation({
        variables: {
          teamID: user.teamID ?? '', // ここに来る時点でteamIDがないことはありえない
          providerID,
        },
      });
    },
    [selectPaymentMethodMutation, user.teamID],
  );

  const deletePaymentMethod = useCallback(
    async (providerID: string): Promise<void> => {
      // ローディング表示や、例外処理はモーダル(PaymentDeleteModal)コンポーネント内で実施されている
      await deletePaymentMethodMutation({
        variables: {
          teamID: user.teamID ?? '', // ここに来る時点でteamIDがないことはありえない
          providerID,
        },
      });
    },
    [deletePaymentMethodMutation, user.teamID],
  );

  const checkExpire = (item: StripePaymentMethodFragment, now: Date) =>
    now.getFullYear() < item.expireYear ||
    (now.getFullYear() === item.expireYear && now.getMonth() + 1 <= item.expireMonth);

  const showLoader =
    selectPaymentMethodsLoading ||
    getPaymentMethodsLoading ||
    getTeamLoading ||
    teamExistSubscriptionLoading;

  return (
    <>
      <Loader display={showLoader} />
      <PaymentAddModal
        isOpen={addIsOpen}
        toggle={setAddIsOpen}
        onSubmit={createPaymentMethod}
        fetchPayments={fetchPayments}
      />
      {deleteCard && (
        <PaymentDeleteModal
          isOpen={deleteIsOpen}
          toggle={setDeleteIsOpen}
          cardData={deleteCard}
          onSubmit={deletePaymentMethod}
          fetchPayments={fetchPayments}
        />
      )}
      <TeamSettingTabLayout activeTab="payment" metaTitle={metaTitle}>
        <PageWrapper>
          <TeamSettingBreadcrumb pageName="支払方法" />
          <Container>
            <ContentTitle>お支払い方法</ContentTitle>
            <PaymentGroup>
              <PaymentRadio>
                <Label
                  active={paymentMethodType === PaymentMethodType.CreditCard}
                  disabled={
                    validTeamSubscription ||
                    !permissionCheck(FunctionType.TeamPayment, PermissionType.Update)
                  }
                >
                  <input
                    type="radio"
                    value={PaymentMethodType.CreditCard}
                    checked={paymentMethodType === PaymentMethodType.CreditCard}
                    onChange={() => changePaymentMethodType(PaymentMethodType.CreditCard)}
                    disabled={
                      validTeamSubscription ||
                      !permissionCheck(FunctionType.TeamPayment, PermissionType.Update)
                    }
                  />
                  クレジットカード
                </Label>
              </PaymentRadio>
              {paymentMethodType === PaymentMethodType.CreditCard && (
                <>
                  {paymentMethods.length > 0 ? (
                    <Ul>
                      {paymentMethods.map((item) => {
                        return (
                          <Li key={item.id}>
                            <Label active={item.isDefault}>
                              <input
                                type="radio"
                                value={item.id}
                                checked={item.isDefault}
                                onChange={changeDefaultPayment}
                                disabled={
                                  radioButtonClicked ||
                                  item.isDefault ||
                                  !permissionCheck(FunctionType.TeamPayment, PermissionType.Update)
                                }
                              />
                              <dl>
                                <Brand>
                                  <CardBrand brand={item.cardBrand} />
                                </Brand>
                                <NumberTitle>カード番号</NumberTitle>
                                <Number>**** **** **** {item.cardNumber}</Number>
                                <Limit active={checkExpire(item, now)}>
                                  {checkExpire(item, now) ? (
                                    <span>有効期限</span>
                                  ) : (
                                    <span className="disabled">有効期限切れ</span>
                                  )}
                                  {item.expireMonth}/{item.expireYear}
                                </Limit>
                                {!item.isDefault &&
                                  permissionCheck(
                                    FunctionType.TeamPayment,
                                    PermissionType.Delete,
                                  ) && (
                                    <Command
                                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        setDeleteIsOpen(true);
                                        setDeleteCard(item);
                                      }}
                                    >
                                      削除
                                    </Command>
                                  )}
                              </dl>
                            </Label>
                          </Li>
                        );
                      })}
                    </Ul>
                  ) : (
                    <EmptyMessage>ご登録されているクレジットカード情報がありません</EmptyMessage>
                  )}
                  {permissionCheck(FunctionType.TeamPayment, PermissionType.Create) && (
                    <StyledButton onClick={() => setAddIsOpen(true)}>
                      クレジットカードを追加する
                    </StyledButton>
                  )}
                  <Info>
                    ※未払いの請求情報が存在する場合、有効なクレジットカードを指定すると自動で再決済が行われます。
                  </Info>
                </>
              )}
            </PaymentGroup>
            <PaymentGroup>
              <PaymentRadio>
                <Label
                  active={paymentMethodType === PaymentMethodType.Bank}
                  disabled={
                    validTeamSubscription ||
                    !permissionCheck(FunctionType.TeamPayment, PermissionType.Update)
                  }
                >
                  <input
                    type="radio"
                    value={PaymentMethodType.Bank}
                    checked={paymentMethodType === PaymentMethodType.Bank}
                    disabled={
                      validTeamSubscription ||
                      !permissionCheck(FunctionType.TeamPayment, PermissionType.Update)
                    }
                    onChange={() => changePaymentMethodType(PaymentMethodType.Bank)}
                  />
                  銀行振込 (請求書払い)
                </Label>
              </PaymentRadio>
            </PaymentGroup>
          </Container>
        </PageWrapper>
      </TeamSettingTabLayout>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 1.25rem auto 0;
  padding: 2rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    width: calc(100% + 2rem);
    margin: 1rem -1rem 0;
  `}
`;
const ContentTitle = styled.h3`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.35rem;
`;
const PaymentGroup = styled.div`
  margin-top: 1.5rem;

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`;

const Li = styled.li`
  input {
    display: none;
  }

  ${media.lessThan('medium')`
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0,0,0,0.1);
  `}
`;
const Ul = styled.ul`
  margin-top: 1rem;

  ${Li} + ${Li} {
    margin-top: 0.875rem;
  }

  ${media.lessThan('medium')`
    ${Li} + ${Li} {
      margin-top: 1rem;
    }
  `}
`;
const Label = styled.label<{ active: boolean; disabled?: boolean }>`
  display: block;
  padding: 0.5rem 4.25rem 0.5rem 4rem;
  background: ${(props) => (props.active ? 'rgba(226,0,0,0.1)' : '#fff')};
  border-radius: 0.25rem;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    margin: auto;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.36);
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.5rem;
  }

  &:after {
    content: '';
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    margin: auto;
    background: ${(props) =>
      props.active ? (props.disabled ? 'rgba(0, 0, 0, 0.6)' : '#e73248') : '#fff'};
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.75rem;
  }

  dl {
    display: flex;
    align-items: center;
  }

  ${media.lessThan('large')`
    padding: 0.5rem 3.75rem 0.5rem 2.625rem;
  `}

  ${media.lessThan('medium')`
    padding: 0 4.25rem 0 2rem;
    background: #fff;

    &:before {
      left: 0.25rem;
    }

    &:after {
      left: 0.5rem;
    }

    dl {
      flex-wrap: wrap;
    }
  `}
`;
const Brand = styled.dd`
  width: 3.25rem;
  height: 2.125rem;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const NumberTitle = styled.dd`
  margin: 0 1.5rem 0 2rem;

  ${media.lessThan('large')`
    margin: 0 0.5rem 0 1rem;
    font-size: 0.875rem;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const Number = styled.dd`
  margin-right: 2rem;

  ${media.lessThan('large')`
    margin-right: 1rem;
    font-size: 0.875rem;
  `}

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0 0 0.25rem;
    line-height: 1rem;
  `}
`;
const Limit = styled.dd<{ active: boolean }>`
  display: flex;
  align-items: center;

  span {
    display: block;
    margin-right: 0.5rem;

    &.disabled {
      color: #e73248;
    }
  }

  ${media.lessThan('large')`
    font-size: 0.875rem;

    span {
      font-size: 0.875rem;
    }
  `}

  ${media.lessThan('medium')`
    color: rgba(0,0,0,0.36);
    font-size: 0.6875rem;

    span {
      font-size: 0.6875rem;
    }
  `}

  ${(props) =>
    !props.active
      ? media.lessThan('medium')`
      color: #e73248;
    `
      : ''}
`;

const Command = styled.button`
  height: 0.875rem;
  margin: auto;
  cursor: pointer;
  color: #e73248;
  font-size: 0.875rem;
  line-height: 0.875rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  background-color: transparent;
  border: none;

  ${media.lessThan('medium')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.75rem;
    height: 2.25rem;
    background: #fff;
    border: 1px solid #e73248;
    border-radius: 0.1875rem;
    box-sizing: border-box;
  `}
`;

const EmptyMessage = styled.p`
  font-size: 1rem;
  text-align: center;
`;
const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 240px;
  margin: 1rem auto 0;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;

  ${media.lessThan('medium')`
    max-width: 300px;
  `}
`;
const Info = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.75rem;
  line-height: 1.25rem;
`;

const PaymentRadio = styled.div`
  input {
    display: none;
  }

  ${Label} {
    padding: 0 0 0 1.5rem;
    background: #fff;
    line-height: 1.5rem;

    &:before {
      left: 0;
    }

    &:after {
      left: 0.25rem;
    }
  }
`;
