import { UserRole } from '../../gen/graphql';
import { StoreState } from '../../store';
import { UserActions, UserActionTypes } from './actions';

const initialState: StoreState['user'] = {
  lmsUser: null,
  cognitoUser: null,
  permissionList: null,
  contractsOfStripeSubscriptionForAnalytics: null, //useEffectで発火させるため、初期値はnullにしている(本来は[])
  role: UserRole.NotLogin,
  teamID: null,
  teams: [],
  isLogged: false,
  loginError: '',
  isChangePassword: false,
  socialLoginCallbackURL: null,
};

export const userReducer = (
  state: StoreState['user'] = initialState,
  action: UserActions,
): StoreState['user'] => {
  switch (action.type) {
    case UserActionTypes.login:
      return {
        ...state,
        ...action.payload,
        isLogged: true,
      };
    case UserActionTypes.loginByTemporaryPassword:
      // 仮パスワードログインはログイン扱いにしない
      // isLoggedのフラグを立てると画面遷移毎にcurrent_userのリクエストが飛んでしまうが、
      // 本登録前はAPI Gatewayのcognito認証で401エラーになってしまうため「no current user」のエラーが頻発してしまう
      return {
        ...state,
        lmsUser: null,
        cognitoUser: action.payload.cognitoUser,
        permissionList: null,
        role: UserRole.NotLogin,
        isLogged: false,
      };
    case UserActionTypes.logout:
      return initialState;
    case UserActionTypes.changeRole:
      return {
        ...state,
        role: action.payload.role,
      };
    case UserActionTypes.setTeams:
      return {
        ...state,
        teamID: action.payload.teamID,
        teams: action.payload.teams,
      };
    case UserActionTypes.setTeamID:
      return {
        ...state,
        teamID: action.payload.teamID,
      };
    case UserActionTypes.setIsChangePassword:
      return {
        ...state,
        isChangePassword: action.payload.isChangePassword,
      };
    case UserActionTypes.setLMSUser:
      return {
        ...state,
        lmsUser: action.payload.lmsUser,
        permissionList: action.payload.permissionList,
        role: action.payload.role,
      };
    case UserActionTypes.setSocialLoginCallbackURL:
      return {
        ...state,
        socialLoginCallbackURL: action.payload.callbackURL,
      };
    case UserActionTypes.loginError:
      return {
        ...state,
        loginError: action.payload.error,
      };
    default:
      return state;
  }
};
