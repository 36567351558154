import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';

import Feature001 from '../../../static/image/lp/lesson_feature001.png';
import Feature002 from '../../../static/image/lp/lesson_feature002.png';
import Feature003 from '../../../static/image/lp/lesson_feature003.png';
import Flow001 from '../../../static/image/lp/lesson_flow001.png';
import Flow002 from '../../../static/image/lp/lesson_flow002.png';
import Flow003 from '../../../static/image/lp/lesson_flow003.png';
import { SPOT_LESSON_TIME } from '../../../const/SpotLesson';
import { SERVICE_NAME } from '../../../const/Service';

export const LpLesson: React.FC = () => {
  return (
    <Container data-gtm="lesson">
      <Inner>
        <Ttl>
          <h2>いつでも好きな時に現役エンジニアとオンラインレッスン</h2>
          <p>Lesson</p>
        </Ttl>
        <Description>
          インターネットがつながる環境があれば、
          <br />
          いつでも好きな時にオンラインで現役エンジニアに相談できます。
        </Description>
        <Section>
          <SectionTitle>レッスンの特徴</SectionTitle>
          <Flex>
            <Column>
              <img src={Feature001} alt="" />
              <p>
                レッスンは、チケット制です。チケット1枚あたり{SPOT_LESSON_TIME}
                分間のレッスンを受けられます。{SERVICE_NAME}会員には、レッスンチケットを
                <span>
                  ”毎月1枚無料配布”<small>(※)</small>
                </span>
                いたします。
              </p>
              <p className="sup">※予告なく終了する可能性がございます。</p>
            </Column>
            <Column>
              <img src={Feature002} alt="" />
              <p>
                チケット制なので、<span>好きな時にやりたい回数だけ受講できます</span>
                。忙しい社会人でも自分のペースでレッスンの頻度を変更できます。
              </p>
            </Column>
            <Column>
              <img src={Feature003} alt="" />
              <p>
                レッスンを担当する講師は現役のエンジニアなので、学習における悩みや転職、作りたいWebサービスについてなど
                <span>様々なトピックの相談</span>ができます。
              </p>
            </Column>
          </Flex>
        </Section>
        <Section>
          <SectionTitle>レッスンまでの流れ</SectionTitle>
          <Flex>
            <Column>
              <img src={Flow001} alt="" />
              <h4>レッスン予約フォームの記入</h4>
              <p>質問したいプログラミング言語と、相談内容のカテゴリーを選択します。</p>
            </Column>
            <Column>
              <img src={Flow002} alt="" />
              <h4>空いているレッスン枠で予約</h4>
              <p>選択した内容に対応できる講師のカレンダーが表示されるので、予約します。</p>
            </Column>
            <Column>
              <img src={Flow003} alt="" />
              <h4>Zoomで担当の講師とレッスン</h4>
              <p>Zoomにて担当の講師と{SPOT_LESSON_TIME}分間のレッスンを行います。</p>
            </Column>
          </Flex>
        </Section>
      </Inner>
    </Container>
  );
};

const Container = styled.section`
  padding: 7.5rem 0;
  background: #f8f8f8;

  ${media.lessThan('medium')`
    padding: 4rem 0;
  `}
`;
const Description = styled.p`
  margin-top: 3rem;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: center;

  ${media.lessThan('medium')`
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.5;

    br {
      display: none;
    }
  `}
`;
const Section = styled.div`
  margin: 4rem auto 0;

  ${media.lessThan('medium')`
    &:nth-of-type(1) {
      margin-top: 3rem;
    }
  `}
`;
const SectionTitle = styled.h3`
  margin: 0 auto 2rem;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: center;

  ${media.lessThan('medium')`
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
  `}
`;
const Flex = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
const Column = styled.div`
  flex: 1;

  &:nth-of-type(n + 2) {
    margin-left: 2rem;
  }

  img {
    display: block;
    width: 100%;
    margin: 0 auto 1.5rem;
  }

  h4 {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;

    span {
      color: #eb0000;
      font-weight: 700;
    }

    small {
      font-size: 0.625rem;
      font-weight: 400;
    }

    &.sup {
      margin-top: 0.5rem;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.625rem;
      line-height: 1.4;
    }
  }

  ${media.lessThan('medium')`
    &:nth-of-type(n + 2) {
      margin: 3rem auto 0;
    }

    img {
      margin-bottom: 1rem;
    }

    h4 {
      margin-bottom: .75rem;
      font-size: 1rem;
      line-height: 1.125rem;
    }
  `}
`;
