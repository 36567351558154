import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { BreadcrumbItemProps } from '../organisms/Breadcrumb';

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = (props) => {
  return (
    <Item>
      {props.to ? (
        <StyledLink to={props.to} onClick={props.onClick}>
          {props.label}
        </StyledLink>
      ) : (
        <UnLinkText onClick={props.onClick}>{props.label}</UnLinkText>
      )}
    </Item>
  );
};

const Item = styled.li``;
const StyledLink = styled(Link)`
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    color: #eb0000;
    text-decoration: underline;
  }
`;
const UnLinkText = styled.span`
  display: block;
  color: #b6b6b6;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
`;
