export const addDate = (originalDate: Date, y = 0, m = 0, d = 0): Date => {
  const newDate = new Date(originalDate);
  newDate.setFullYear(newDate.getFullYear() + y);
  newDate.setMonth(newDate.getMonth() + m);
  newDate.setDate(newDate.getDate() + d);
  return newDate;
};

export const formatDateToHoursAndMinutes = (milliseconds: number): string => {
  const seconds = milliseconds / 1000;
  const hours = Math.floor(seconds / 3600);
  const remainder = (seconds % 3600) % 60;
  const minutes =
    remainder > 0 ? Math.floor((seconds % 3600) / 60) + 1 : Math.floor((seconds % 3600) / 60);

  if (seconds <= 0) return '0分';

  if (minutes === 60) return `${hours + 1}時間`;

  if (hours <= 0) return `${minutes}分`;

  if (minutes === 0) return `${hours}時間`;

  return `${hours}時間${minutes}分`;
};
