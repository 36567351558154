import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';
import { useStartCourseProgramMutation } from '../../gen/graphql';
import { useToastsContext } from '../../context/ToastsProvider';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';

import { defaultErrorMessage } from '../../const/ErrorMessage';

interface CourseProgramModalProps {
  isOpen: boolean;
  toggle: (nextState: boolean) => void;
  loading?: boolean;
  linkTo: string;
  courseProgramId: string;
}

export const CourseProgramModal: React.FC<CourseProgramModalProps> = ({
  toggle,
  linkTo,
  ...props
}) => {
  const navigate = useNavigate();
  const [startCourseProgram] = useStartCourseProgramMutation({
    variables: {
      id: props.courseProgramId,
    },
  });
  const { showToast } = useToastsContext();

  const start = useSafeAsyncCallback(
    React.useCallback(async () => {
      try {
        await startCourseProgram();
        toggle(false);
        navigate(linkTo);
      } catch (e) {
        showToast(1, defaultErrorMessage);
      }
    }, [navigate, toggle, linkTo, startCourseProgram, showToast]),
  );

  return (
    <Modal
      underlayer
      isOpen={props.isOpen}
      onClose={() => toggle(false)}
      loading={props.loading}
      header={<Header>コース教材の開始</Header>}
      footer={
        <Buttons>
          <Button onClick={() => toggle(false)} gray>
            取り消す
          </Button>
          <Button onClick={start}>コース教材で学習をはじめる</Button>
        </Buttons>
      }
      width={'576px'}
    >
      <Container>
        <Content>
          <Text>進捗率100%を目指してがんばりましょう！</Text>
        </Content>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const Content = styled.div`
  padding: 80px 72px 67px 72px;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 56px 16px;
  `}
`;

const Header = styled.h3`
  font-size: 1.125rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
`;

const Text = styled.p`
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
