import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import ClockIcon from '../../static/image/icon_time_gray.svg';
import QuestionIcon from '../../static/image/icon_course_program_q.svg';
import AnswerIcon from '../../static/image/icon_course_program_a.svg';
import CheckIcon from '../../static/image/check_white.svg';
import {
  CourseProgramStepItemFragment,
  ChapterForCourseProgramStepItemFragment,
  ProgramElementForCourseProgramStepItemFragment,
  PracticeSubmitDetailReviewStatus,
} from '../../gen/graphql';
import { getRecentPracticeSubmitStatus } from '../../utils/PracticeSubmit';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import {
  checkProgramElementAuthType,
  getChapterElementFunctionType,
  isCompletedProgram,
  isLearnedProgram,
} from '../../utils/Program';
import { minutesToHoursSecondDecimalPlace } from '../../utils/common';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import {
  ProgramElementInCourseProgramStepItem,
  ProgramElementsInCourseProgramStepItem,
} from '../../types/Program';

export interface CourseProgramDetailArticleProps {
  id: string;
  courseProgramStepItems?: CourseProgramStepItemFragment[];
  number: number;
  title: string;
  description: string;
  isStarted: boolean;
  showProgress?: boolean;
  openModal: (to: string) => void;
}

const chapterDetailLink = (programID: number, chapterID?: number): string => {
  return `/programs/${programID}/chapters/${chapterID}`;
};

const practiceDetailLink = (practiceID: string): string => {
  return `/practices/${practiceID}/body`;
};

const programElementDetailLink = (
  programElement?: ProgramElementForCourseProgramStepItemFragment,
): string => {
  if (programElement && programElement.chapter) {
    return chapterDetailLink(programElement.programID, programElement.chapter.id);
  }
  if (programElement && programElement.practice) {
    return practiceDetailLink(programElement.practice.id);
  }

  return '';
};

export const CourseProgramDetailArticle: React.FC<CourseProgramDetailArticleProps> = (props) => {
  return (
    <Container>
      <Steps>
        <Step>
          <p>Step</p>
          <p>{`00${props.number}`.slice(-2)}</p>
        </Step>
        <ArticleDescription>
          <h3>{props.title}</h3>
          <p>{props.description}</p>
        </ArticleDescription>
      </Steps>
      <Programs>
        {props.courseProgramStepItems?.map((courseProgramStepItem, i) => {
          const program = courseProgramStepItem.program;
          if (!program) return;
          const firstProgramElement = program.programElements
            ? program.programElements[0]
            : undefined;
          const nextProgramElement = getNextProgramElement(program.programElements ?? []);
          const progressPercentage = program.completedTime
            ? Math.round((program.completedTime / program.requireTime) * 100)
            : 0;
          const chapters = program ? program.programElements?.map((e) => e.chapter) : [];

          const currentChapter = chapters?.find((c) => c?.studyLog === null) || chapters?.[0];
          const [completed, learned, firstPage, continuePage] = [
            isCompletedProgram(program.programElements),
            isLearnedProgram(program.programElements),
            programElementDetailLink(firstProgramElement),
            programElementDetailLink(nextProgramElement),
          ];

          return (
            <React.Fragment key={courseProgramStepItem.id}>
              <Program completed={completed}>
                <Status
                  completed={completed}
                  isFirst={i === 0}
                  isLast={
                    props.courseProgramStepItems && props.courseProgramStepItems.length - 1 === i
                  }
                />
                <Thumbnail src={program.thumbnail} alt={program.title} />
                <Detail>
                  <Title>{program.title}</Title>
                  <Info>
                    <p>
                      <img src={ClockIcon} alt="time" />
                      <span>{minutesToHoursSecondDecimalPlace(program.requireTime)}時間</span>
                    </p>
                    <p>
                      <img src={QuestionIcon} alt="question" />
                      <span className="label">質問:</span>
                      <span className="bold">{program.questionCount}件</span>
                    </p>
                    <p>
                      <img src={AnswerIcon} alt="answer" />
                      <span className="label">回答:</span>
                      <span className="bold">{program.answerCount}件</span>
                    </p>
                  </Info>
                  {props.showProgress && (
                    <Progress percentage={progressPercentage}>
                      <div className="bar"></div>
                      <p className="number">
                        {progressPercentage}%(
                        {minutesToHoursSecondDecimalPlace(program.completedTime)}/
                        {minutesToHoursSecondDecimalPlace(program.requireTime)}時間)
                      </p>
                    </Progress>
                  )}
                </Detail>
                <Buttons>
                  <Link to={`/programs/${program.id}/chapters`} className="index">
                    <span>章一覧へ</span>
                  </Link>
                  <ProgramButton
                    chapter={currentChapter ?? undefined}
                    nextProgramElement={nextProgramElement}
                    firstProgramElement={firstProgramElement}
                    started={props.isStarted}
                    learned={learned}
                    completed={completed}
                    firstPage={firstPage}
                    continuePage={continuePage}
                    openFirstModal={props.openModal}
                  />
                </Buttons>
              </Program>
            </React.Fragment>
          );
        })}
      </Programs>
    </Container>
  );
};

// 未実施で順番が一番早い章or課題を続きからの要素にする
export const getNextProgramElement = (
  programElements: ProgramElementsInCourseProgramStepItem,
): ProgramElementInCourseProgramStepItem | undefined => {
  return programElements.find((element) => {
    const { chapter, practice } = element;

    if (chapter && !chapter.studyLog) return true;

    if (practice && !practice.mySubmit) return true;

    return false;
  });
};

interface ProgramButtonProps {
  chapter: ChapterForCourseProgramStepItemFragment | undefined;
  nextProgramElement: ProgramElementForCourseProgramStepItemFragment | undefined;
  firstProgramElement: ProgramElementForCourseProgramStepItemFragment | undefined;
  started: boolean;
  learned: boolean;
  completed: boolean;
  firstPage: string;
  continuePage: string;
  openFirstModal: (linkTo: string) => void;
}

const ProgramButton: React.FC<ProgramButtonProps> = ({
  nextProgramElement,
  firstProgramElement,
  started,
  learned,
  completed,
  openFirstModal,
}) => {
  const { permissionCheck } = useUser();
  const { openModal } = useCommonModal();

  const [label, programElement] = (() => {
    if (completed) {
      return ['復習する', firstProgramElement];
    }

    if (!learned) {
      return ['はじめる', firstProgramElement];
    }

    return ['続きから', nextProgramElement];
  })();

  // 未ログイン等、StudyLog作成不可の(=コース教材の進捗をとれない・開始できない)権限
  if (!permissionCheck(FunctionType.CourseProgramUserLog, PermissionType.Read)) {
    return <></>;
  }

  const [to, hasPermission, functionType] = [
    programElementDetailLink(programElement),
    checkProgramElementAuthType(programElement, permissionCheck),
    getChapterElementFunctionType(programElement),
  ];

  // 遷移先の参照権限があるかチェック
  if (
    getRecentPracticeSubmitStatus(programElement?.practice?.mySubmit?.practiceSubmitDetails) !==
      PracticeSubmitDetailReviewStatus.Passed &&
    !hasPermission
  ) {
    return (
      <p
        className="start"
        onClick={() => {
          if (!functionType) return;
          openModal(functionType, PermissionType.Read);
        }}
      >
        <span>{label}</span>
      </p>
    );
  }

  if (started) {
    return (
      <Link className="start" to={to}>
        <span>{label}</span>
      </Link>
    );
  }

  return (
    <p className="start" onClick={() => openFirstModal(to)}>
      <span>{label}</span>
    </p>
  );
};

const Step = styled.div`
  position: absolute;
  left: 24px;
  top: 0;
  width: 60px;
  height: 60px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p:nth-child(1) {
    font-size: 0.8rem;
    color: #575e71;
  }

  p:nth-child(2) {
    font-size: 1.5rem;
    color: #575e71;
    font-weight: 800;
    margin-top: 0.25rem;
  }

  ${media.lessThan('medium')`
    position: relative;
    width: 40px;
    height: 40px;
    left: 0;
    top: 0;

    p:nth-child(1) {
      font-size: 0.6rem;
      color: #575e71;
    }
  
    p:nth-child(2) {
      font-size: 1rem;
      color: #575e71;
      font-weight: 800;
      margin-top: 0.25rem;
    }
  `}
`;

const Steps = styled.div`
  ${media.lessThan('medium')`
    display: flex;
    align-items: flex-start;
  `}
`;

const Container = styled.article`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0 32px 32px 92px;

  ${media.lessThan('medium')`
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  `}

  &:before {
    position: absolute;
    content: '';
    width: 4px;
    height: 100%;
    left: 52px;
    top: 0;
    background-color: #f5f5f5;

    ${media.lessThan('medium')`
      content: none;
    `}
  }
`;

const ArticleDescription = styled.div`
  h3 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
  }

  p {
    margin-top: 0.125rem;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;

const Programs = styled.section`
  margin-top: 2rem;
  padding: 0 2rem 0 3rem;
  box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.08);

  & > * + * {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('medium')`
    padding: 0;
    box-shadow: none;
    margin-top: .75rem;

    & > * + * {
      border: none;
      margin-top: .75rem;
    }
  `}
`;

const Status = styled.div<{ completed?: boolean; isFirst?: boolean; isLast?: boolean }>`
  &:before,
  &:after {
    content: '';
    display: block;
    width: 0.125rem;
    height: 50%;
    background-color: #fdefe5;
    position: absolute;
    left: -23px;
    z-index: 0;
  }
  &:before {
    bottom: 50%;
    ${(props) =>
      props.isFirst &&
      `
      content: none;
    `}
  }
  &:after {
    top: calc(50% + 1px);
    ${(props) =>
      props.isLast &&
      `
      content: none;
    `}
  }
`;

const Program = styled.article<{ completed?: boolean }>`
  position: relative;
  padding: 16px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    z-index: 1;

    ${(props) =>
      props.completed
        ? `
        left: -32px;
        width: 20px;
        height: 20px;
        background: url(${CheckIcon}) #eb0000 center / 12px no-repeat;
      `
        : `
        left: -28px;
        width: 12px;
        height: 12px;
        background-color: #fdefe5;
      `}
  }

  ${(props) =>
    props.completed &&
    `
    ${Status}:after {
      background-color: #eb0000;
    }

    + article ${Status}:before {
      background-color: #eb0000;
    }
  `}

  ${media.lessThan('medium')`
    flex-direction: column;
    padding: 0;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    border-radius: 6px;

    &:before {
      content: none;
    }
  `}
`;

const Thumbnail = styled.img`
  width: 25%;
  max-width: 180px;
  height: 90px;
  border-radius: 4px;
  object-fit: cover;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: none;
    height: auto;
    border-radius: 6px 6px 0 0;
  `}
`;

const Detail = styled.div`
  flex: 1;
  margin-left: 2rem;

  ${media.lessThan('medium')`
    width: 100%;
    margin: 1.25rem auto 0;
    padding: 0 1rem;
    box-sizing: border-box;
  `}
`;

const Title = styled.p`
  padding-right: 220px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  display: block;

  ${media.lessThan('medium')`
    padding: 0;
  `}
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  & > * + * {
    margin-left: 1rem;
  }

  img {
    margin-right: 5px;
  }

  p {
    display: flex;
    align-items: center;

    span {
      font-size: 0.8rem;
      color: rgba(0, 0, 0, 0.6);

      &.bold {
        font-weight: bold;
      }

      ${media.lessThan('medium')`
        &.label {
          display: none;
        }
      `}
    }
  }
`;

const Progress = styled.div<{ percentage: number }>`
  display: flex;
  align-items: center;
  margin-top: 18px;

  .bar {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 5px;
    background-color: #e5e5e5;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      height: 100%;
      width: ${(props) => `${props.percentage}%`};
      background: linear-gradient(to right, rgb(253, 130, 88), rgb(253, 60, 47));
    }

    ${media.lessThan('medium')`
      width: 172px;
    `}
  }

  .number {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 12px;
    white-space: nowrap;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
  position: absolute;
  top: 1rem;
  right: 0;

  .index {
    cursor: pointer;
    text-align: center;

    span {
      color: rgba(0, 0, 0, 0.87);
      font-size: 0.75rem;
    }
  }

  .start {
    display: flex;
    width: 120px;
    height: 37px;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.87);
    transition: all 0.2s;
    cursor: pointer;

    span {
      font-size: 0.875rem;
    }

    &:hover {
      background-color: #e2001b;
      color: #ffffff;
    }
  }

  ${media.lessThan('medium')`
    width: calc(100% - 2rem);
    margin: .75rem auto 0;
    padding: .75rem 0 2.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: static;

    a {
      flex: 1;
    }
  `}
`;
