import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { format } from 'date-fns';
import { Markdown } from '../../../common/Markdown';
import { H1 } from '../../atoms/Typography';
import { useNotificationUnreadCount } from '../../../common/customHooks/NotificationUnreadCount';
import { NotificationLayout } from '../../templates/NotificationLayout';
import { useGetNoticeQuery, useReadNoticeMutation } from '../../../gen/graphql';
import { NotFoundPage } from '../public/NotFound';
import { Loader } from '../../molecules/Loader';
import { LOWER_META_TITLE } from '../../../const/Service';

export const NoticeDetailContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathNoticeID = Number(paramID);
  if (Number.isNaN(pathNoticeID)) return NotFoundPage;

  return <NoticeDetail noticeID={pathNoticeID} />;
};

interface NoticeDetailProps {
  noticeID: number;
}

const NoticeDetail = (props: NoticeDetailProps): JSX.Element => {
  const {
    notificationUnreadCount,
    noticeUnreadCount,
    refetch: refetchUnreadCount,
  } = useNotificationUnreadCount();

  const [metaTitle, setMetaTitle] = useState('');

  const [readNotice] = useReadNoticeMutation();
  const { data, loading } = useGetNoticeQuery({
    variables: { id: props.noticeID },
    onCompleted: async ({ notice }) => {
      if (!notice?.noticeRead) {
        try {
          await readNotice({ variables: { id: props.noticeID } });
          // 既読化後にヘッダ件数更新のため再取得
          await refetchUnreadCount();
        } catch {
          // エラー表示してもしょうがないので握りつぶし(Sentryへの通知は共通処理)
        }
      }

      setMetaTitle(`${notice?.title} | ${LOWER_META_TITLE}`);
    },
  });
  const notice = data?.notice;

  if (!notice && !loading) {
    return NotFoundPage;
  }

  return (
    <NotificationLayout
      activeTab="admin"
      notificationUnreadCount={notificationUnreadCount}
      noticeUnreadCount={noticeUnreadCount}
      metaTitle={metaTitle}
    >
      <Loader display={loading} />
      <Container>
        <BackLink to="/notification/admin">&lt; 一覧に戻る</BackLink>
        {notice && (
          <Content>
            <Title color={'DARK'}>{notice.title}</Title>
            <Time>{format(new Date(notice.createdAt), 'yyyy/MM/dd HH:mm')}</Time>
            <Markdown content={notice.content} />
          </Content>
        )}
      </Container>
    </NotificationLayout>
  );
};

const Container = styled.div`
  max-width: 70rem;
  padding: 2rem 2.9375rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 1.5rem 1rem;
  `}
`;

const BackLink = styled(Link)`
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.3;
  color: #e2001b;
`;

const Content = styled.div`
  margin-top: 0.625rem;
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  width: 100%;
  word-wrap: break-word;
  background-color: #fff;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;

  ${media.lessThan('medium')`
    padding: 1rem;
  `}
`;

const Title = styled(H1)`
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: 0.1px;
`;

const Time = styled.p`
  margin: 1rem auto;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.1px;
`;
