import React, { useContext } from 'react';
import styled from 'styled-components';
import { MenuContext } from '../../context/MenuProvider';

export const Divider: React.FC = (props) => {
  const menuContext = useContext(MenuContext);

  return <Div {...props} isOpen={menuContext.isOpen} isHoverOpen={menuContext.isHoverOpen}></Div>;
};

const Div = styled.div<{ isOpen: boolean; isHoverOpen: boolean }>`
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 24px auto;
  ${(props) => {
    return props.isOpen || props.isHoverOpen ? '' : 'margin-left: 95px; width: calc(100% - 95px);';
  }};
`;
