import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Chip } from '../atoms/Chip';

interface Props {
  image: string;
  title: string;
  comment: string;
  tags: string[];
}

export const ProgramSlide: React.FC<Props> = (props) => {
  return (
    <Container>
      <Thumb src={props.image} />
      <Title>{props.title}</Title>
      <Comment>{props.comment}</Comment>
      <Tags>
        {props.tags.map((item) => {
          return <Chip>{item}</Chip>;
        })}
      </Tags>
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
`;
const Thumb = styled.img`
  width: 100%;
  height: auto;
`;
const Title = styled.h3`
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.125;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
const Comment = styled.p`
  margin-top: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.6;
`;
const Tags = styled.p`
  margin-top: 1rem;
  font-size: 0;
`;
