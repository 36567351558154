import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { useUser } from '../../redux/user/useUser';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';
import { Loader } from '../molecules/Loader';
import { useDeleteTeamMutation } from '../../gen/graphql';
import { useToastsContext } from '../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
}

export const TeamWithdrawModal: React.FC<Props> = ({ isOpen, onClose, ...props }): JSX.Element => {
  const { user, removeTeamID } = useUser();
  const [deleteTeamMutation] = useDeleteTeamMutation({
    onCompleted: () => removeTeamID(),
  });
  const { showToast } = useToastsContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleClose = useCallback((): void => {
    //ローディング状態でモーダル外をクリックした時に閉じないようにする
    if (loading) {
      return;
    }
    onClose();
  }, [loading, onClose]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!user.teamID) return;

      setLoading(true);
      try {
        await deleteTeamMutation({
          variables: {
            id: user.teamID,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, 'チームアカウントの削除処理に失敗しました。お問い合わせをお願いいたします。');
        return;
      } finally {
        setLoading(false);
      }

      showToast(0, 'チームアカウントの削除処理が完了しました');
      onClose();
      // Router.tsxのuseEffectにてrefresh()実行されるのでここではrefresh()不要
      navigate('/home');
    }, [user.teamID, deleteTeamMutation, onClose, showToast, navigate]),
  );

  return (
    <>
      <Loader display={loading} />
      <Modal
        underlayer
        isOpen={isOpen}
        onClose={handleClose}
        loading={props.loading}
        width={'37.5rem'}
        header={<Title>チームアカウントの削除</Title>}
        footer={
          <ButtonWrapper>
            <Button onClick={onClose} gray>
              キャンセル
            </Button>
            <Button onClick={handleSubmit} disabled={!checked}>
              チームアカウントを削除する
            </Button>
          </ButtonWrapper>
        }
      >
        <Container>
          <List>
            <ListItem>
              チームアカウントを削除するとチーム機能は直ちにご利用いただけなくなります
            </ListItem>
            <ListItem>既に支払われた金額の返金はおこなわれません</ListItem>
            <ListItem>
              チームアカウントに紐づくユーザーは未契約の一般ユーザーとして引き続きサービスをご利用いただけます
            </ListItem>
            <ListItem>この操作は取り消せません</ListItem>
          </List>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="この操作は取り消せないことを理解した"
          />
          <Notion>
            ※アカウント削除についての詳しい内容は、
            <a
              href="https://intercom.help/plus---samurai/ja/articles/6622179-q-%E6%B3%95%E4%BA%BA%E3%83%97%E3%83%A9%E3%83%B3%E3%81%AE%E3%83%81%E3%83%BC%E3%83%A0%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E3%81%AE%E5%89%8A%E9%99%A4%E3%81%A8%E9%99%A4%E5%A4%96%E3%81%AE%E9%81%95%E3%81%84%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
              target="_blank"
            >
              こちら
            </a>
            のヘルプページをご覧ください。
          </Notion>
        </Container>
      </Modal>
    </>
  );
};

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 auto;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem calc(20% / 2) 2rem;

  ${media.lessThan('small')`
    padding: 2rem 1rem 2.5rem;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  ${media.lessThan('small')`
    flex-direction: column-reverse;
  `}
`;

const List = styled.ul`
  list-style-type: disc;
  margin: 1.75rem 0;
  line-height: 1.33;
`;

const ListItem = styled.li`
  font-size: 1rem;
`;

const Notion = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 1rem;

  ${media.lessThan('medium')`
    font-size: 0.625rem;
    margin-top: 0.75rem;
  `}

  a {
    font-size: inherit;
    color: #e2001b;
  }
`;
