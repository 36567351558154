import React from 'react';
import styled from 'styled-components';

export interface RadioOption {
  label: string;
  value: string;
}

interface RadioProps {
  name: string;
  options: RadioOption[];
  currentValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const Radio: React.FC<RadioProps> = (props) => {
  return (
    <div>
      {props.options.map((option) => {
        return (
          <Label key={option.value}>
            <input
              type="radio"
              name={props.name}
              value={option.value}
              checked={props.currentValue === option.value}
              onChange={props.onChange}
              disabled={props.disabled}
            />
            {option.label}
          </Label>
        );
      })}
    </div>
  );
};

const Label = styled.label`
  font-size: 1rem;
  font-weight: normal !important;
`;
