import React from 'react';
import styled from 'styled-components';

interface InputWithRefProps {
  placeholder?: string;
  width?: string;
  error?: boolean;
  name: string;
  type: string;
  className?: string;
}

export interface InputWithRefHandler {
  getValue: () => string;
  setValue: (v: string) => void;
}

export const InputWithRef = React.forwardRef<InputWithRefHandler, InputWithRefProps>(
  (props, ref) => {
    const [value, setValue] = React.useState('');

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => value,
        setValue: (v: string) => setValue(v),
      };
    });

    return (
      <Container width={props.width} error={props.error} className={props.className}>
        <StyledInput
          placeholder={props.placeholder}
          name={props.name}
          value={value}
          type={props.type}
          error={props.error}
          onChange={(e) => setValue(e.target.value)}
        />
      </Container>
    );
  },
);

const StyledInput = styled.input<{ error?: boolean; paddingLeft?: string }>`
  padding: 0.5rem;
  flex: 1 1 100%;
  width: 100%;
  appearance: none;
  border: none;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: ${(props): string => (props.error ? '#fce5e8' : '#ffffff')};
`;

const Container = styled.div<{ error?: boolean; width?: string }>`
  display: flex;
  justify-content: space-between;
  height: 32px;
  width: ${(props): string => (props.width ? props.width : '100%')};
  border: 1px solid ${(props): string => (props.error ? '#fd2f92' : '#dddddd')};
  border-radius: 2px;
  background-color: ${(props): string => (props.error ? '#fce5e8' : '#ffffff')};
`;
