import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Ttl } from '../../atoms/LpTtl';
import { LpSlider } from '../../molecules/LpSlider';
import { ProgramSlide } from '../../molecules/LpProgramSlide';

import SliderImg001 from '../../../static/image/lp/program_slide001.png';
import SliderImg002 from '../../../static/image/lp/program_slide002.png';
import SliderImg003 from '../../../static/image/lp/program_slide003.png';
import SliderImg004 from '../../../static/image/lp/program_slide004.png';
import SliderImg005 from '../../../static/image/lp/program_slide005.png';

import { PROGRAM_COUNT } from '../../../const/Program';

export const LpProgram = (): JSX.Element => {
  return (
    <Container data-gtm="program">
      <StyledTtl>
        <h2>作りながら学べる教材がなんと{PROGRAM_COUNT}種類以上</h2>
      </StyledTtl>
      <Description>
        プログラミングやWebサイト制作は習うより慣れることが重要。
        <br />
        実際に手を動かしながら学ぶことで最短最速でエンジニアを目指せます。
      </Description>
      <LpSlider>
        <ProgramSlide
          image={SliderImg001}
          title={'Amazon風のECサイト制作'}
          comment={
            'Amazonを模倣したECサイトをフルスクラッチで作成します。ECサイトには、ウェブアプリ開発の基礎から応用まで学べる機能が詰まっているのでスキルを伸ばすには非常に優れた題材です。'
          }
          tags={['PHP', 'Laravel']}
        />
        <ProgramSlide
          image={SliderImg002}
          title={'美容室のホームページ作成'}
          comment={
            'WordPressは世界で最も利用されているブログやHPを作成できるCMSです。副業の獲得にも繋がりやすいスキルのため、ライトにプログラミングを学びたい方におすすめです。'
          }
          tags={['WordPress']}
        />
        <ProgramSlide
          image={SliderImg003}
          title={'SAMURAI ENGINEER風のWebデザイン制作'}
          comment={
            '弊社のWebサイトを模倣したWebデザイン作成をおこないます。ウェブサイト制作の上流部分に該当するため、コーディングする上でも非常に役立つスキルになります。'
          }
          tags={['Adobe XD']}
        />
        <ProgramSlide
          image={SliderImg004}
          title={'Web広告バナー作成'}
          comment={
            'Adobe社が提供するPhotoshopを使い、簡単なバナー広告を作成します。シンプルなバナーでありながら、ツールの基礎から応用まで網羅的に習得できる題材のため汎用的なスキルを身につけられます。'
          }
          tags={['Photoshop']}
        />
        <ProgramSlide
          image={SliderImg005}
          title={'簡単なタイピングゲーム制作'}
          comment={
            'ウェブサイトを動的に表現できるJavaScriptを学びながら、タイピングゲームを作ります。いま最も人気なプログラミング言語の一つであるJavaScriptを学びスキルの幅を広げましょう。'
          }
          tags={['JavaScript']}
        />
      </LpSlider>
    </Container>
  );
};

const Container = styled.section`
  margin: 8.125rem auto 4.875rem;

  ${media.lessThan('medium')`
    margin: 4rem auto;
  `}
`;
const StyledTtl = styled(Ttl)`
  margin-bottom: 3rem;

  ${media.lessThan('medium')`
    padding: 0 1rem;
    margin-bottom: 1.5rem;

    h2 {
      line-height: 1.5;
    }
  `}
`;
const Description = styled.p`
  margin-bottom: 3rem;
  font-size: 1.25rem;
  line-height: 1.8;
  text-align: center;

  ${media.lessThan('medium')`
    padding: 0 1rem;
    font-size: 1rem;
  `}
`;
