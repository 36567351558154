import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';
import { ChatMessageUserFragment } from '../../gen/graphql';
import defaultIcon from '../../static/image/icon_default.svg';
import { CircularIcon } from './CircularIcon';

import { getUserType } from '../../utils/UserType';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface ContributorIconProps {
  user?: ChatMessageUserFragment;
}

export const ContributorIcon: React.FC<ContributorIconProps> = ({ user }): JSX.Element => {
  const navigate = useNavigate();
  const { permissionCheck } = useUser();

  return (
    <Container>
      <CircularIcon
        src={user?.image ? user.image : defaultIcon}
        size={50}
        userType={getUserType(
          user,
          permissionCheck(FunctionType.UserTypeLabel, PermissionType.Read),
        )}
        onClick={() => user?.id && navigate(`/users/${user.id}/profile`)}
      />
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4px;
  ${media.lessThan('medium')`
    width: 50px;
  `}
`;
