import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import { TweetCard } from './TweetCard';
import { StudyReportCard } from './StudyReportCard';
import { TimelineStudyReportFragment, TweetFragment } from '../../gen/graphql';
import { Spinner } from '../atoms/Spinner';

interface Props {
  items: Array<TweetFragment | TimelineStudyReportFragment>;
  fetchMore: () => Promise<void>;
  refetch: () => Promise<void>;
  hasNext: boolean;
  referer: string;
}

export const TimelineList: React.FC<Props> = ({
  items,
  fetchMore,
  refetch,
  hasNext,
  referer,
}): JSX.Element => {
  const intersectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        fetchMore();
      }
    });
    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }
    return () => {
      if (intersectionRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(intersectionRef.current);
      }
    };
  }, [fetchMore]);

  return (
    <Container data-e2e="timelineList">
      {items.map((timeline) =>
        timeline.__typename === 'Tweet' ? (
          // LinkをCardコンポーネントの中に入れると詳細画面で使いまわせなくなるためここに置く
          <CardLink
            key={timeline.tweetID}
            to={`/tweets/${timeline.tweetID}`}
            state={{ referer }}
            data-e2e="tweets"
          >
            <TweetCard tweet={timeline} onDeleteComplete={refetch} />
          </CardLink>
        ) : timeline.__typename === 'StudyReport' ? (
          <CardLink
            key={timeline.studyReportID}
            to={`/study_report/detail/${timeline.studyReportID}`}
            state={{ referer }}
            data-e2e="study_reports"
          >
            <StudyReportCard studyReport={timeline} refetch={refetch} />
          </CardLink>
        ) : (
          ''
        ),
      )}
      {hasNext && (
        <Intersection ref={intersectionRef}>
          <Spinner size="small" />
        </Intersection>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #ffffff;

  & > * + * {
    margin-top: 1rem;
  }

  ${media.lessThan('medium')`
    & > * + * {
      margin-top: 0;
    }
  `}
`;

const CardLink = styled(Link)`
  display: block;
`;

const Intersection = styled.div`
  width: 100%;
  height: 2.5rem;
`;
