import { combineReducers } from 'redux';
import { userReducer as user } from './user/reducer';
import { commonPaymentMethodReducer as commonPaymentMethod } from './common_payment_method/reducer';
import { commonModalReducer as commonModal } from './common_modal/reducer';

const reducer = combineReducers({
  user,
  commonPaymentMethod,
  commonModal,
});

export default reducer;
