import React from 'react';
import styled from 'styled-components';
import { InputLabel } from '../atoms/InputLabel';
import { Input } from '../atoms/Input';

interface HorizontalInputProps {
  name: string;
  label: string;
  unit?: string;
  placeholder?: string;
  width?: string;
  inputWidth?: string;
  flexGrow?: string;
  error?: boolean;
  min?: number;
  max?: number;
  value: string;
  type: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const HorizontalInput: React.FC<HorizontalInputProps> = (props): JSX.Element => (
  <Container width={props.width} flexGrow={props.flexGrow}>
    <InputLabel for={props.name}>{props.label}</InputLabel>
    <Input
      name={props.name}
      unit={props.unit}
      placeholder={props.placeholder}
      value={props.value}
      type={props.type}
      onChange={props.onChange}
      error={props.error}
      min={props.min}
      max={props.max}
      width={props.inputWidth}
    />
  </Container>
);

const Container = styled.div<{ width?: string; flexGrow?: string }>`
  display: flex;
  align-items: center;
  ${(props): string => (props.width ? `width: ${props.width}` : '')};
  flex-grow: ${(props): string => (props.flexGrow ? props.flexGrow : '0')};
`;
