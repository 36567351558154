const SERVICE_NAME = '侍テラコヤ';
const SERVICE_NAME_FULL = '侍テラコヤ（SAMURAI TERAKOYA）';
const META_TITLE = 'ゼロから始める定額・学び放題のプログラミング学習サイト';
const LOWER_META_TITLE = 'プログラミング学習サイト【侍テラコヤ】';
const DEFAULT_META_TITLE = `${SERVICE_NAME} | ${META_TITLE}`;
const DEFAULT_META_DESCRIPTION = `${SERVICE_NAME}は、ゼロから始められる定額・学び放題のプログラミング学習サイトです。入門者や初心者でも学べる教材、実践力が鍛えられる課題、現役エンジニアに相談できるレッスン・Q&A掲示板、学習を記録する学習ログなど、エンジニアになるための機能が盛り沢山！`;

export {
  SERVICE_NAME,
  SERVICE_NAME_FULL,
  META_TITLE,
  LOWER_META_TITLE,
  DEFAULT_META_TITLE,
  DEFAULT_META_DESCRIPTION,
};
