import React, { useCallback } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { TabNavigationLayout } from '../../templates/TabNavigationLayout';
import moment from 'moment';
import {
  endOfMonth,
  endOfWeek,
  getDate,
  setDate,
  setHours,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventClickArg, EventSourceInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ViewConfigIcon from '@material-ui/icons/ViewComfyOutlined';
import ViewWeekIcon from '@material-ui/icons/ViewWeekOutlined';
import ViewAgendaIcon from '@material-ui/icons/ViewAgendaOutlined';

import { Button } from '../../atoms/Button';
import { PageWrapper } from '../../atoms/PageWrapper';
import {
  InstructorScheduleAddModal,
  InstructorScheduleAddModalHandler,
} from '../../organisms/InstructorScheduleAddModal';
import {
  InstructorScheduleEditModal,
  InstructorScheduleEditModalHandler,
} from '../../organisms/InstructorScheduleEditModal';
import { InstructorSpotLessonDetailModal } from '../../organisms/InstructorSpotLessonDetailModal';

import { utcToJst } from '../../../utils/DateFnsSupport';
import {
  useGetInstructorSchedulesQuery,
  useGetSpotLessonsForInstructorQuery,
  useGetSpotLessonForInstructorLazyQuery,
  useCreateInstructorScheduleMutation,
  useDeleteInstructorScheduleMutation,
  useUpdateInstructorScheduleMutation,
  SpotLessonFragment as SpotLesson,
  SpotLessonPhase,
} from '../../../gen/graphql';
import { SLOT_MAX_TIME, SLOT_MIN_TIME } from '../../../const/SpotLesson';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useToastsContext } from '../../../context/ToastsProvider';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { LOWER_META_TITLE } from '../../../const/Service';

export const InstructorSpotLessonSchedule: React.FC = () => {
  const metaTitle = `スケジュール管理 | ${LOWER_META_TITLE}`;

  const { showToast } = useToastsContext();
  const calendarRef = React.useRef<FullCalendar>(null);
  const addModalRef = React.useRef<InstructorScheduleAddModalHandler>(null);
  const editModalRef = React.useRef<InstructorScheduleEditModalHandler>(null);
  const [currentDate, setCurrentDate] = React.useState(moment().toDate());
  const [currentView, setCurrentView] = React.useState('');
  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isSpotLessonDetailModalOpen, setIsSpotLessonDetailModalOpen] = React.useState(false);

  const [createInstructorSchedule] = useCreateInstructorScheduleMutation();
  const [deleteInstructorSchedule] = useDeleteInstructorScheduleMutation();
  const [updateInstructorSchedule] = useUpdateInstructorScheduleMutation();

  const startDate =
    currentView === 'dayGridMonth' ? startOfMonth(currentDate) : startOfWeek(currentDate);
  const endDate = currentView === 'dayGridMonth' ? endOfMonth(currentDate) : endOfWeek(currentDate);

  const { data: spotLessonData } = useGetSpotLessonsForInstructorQuery({
    variables: {
      input: {
        from: startDate.toISOString(),
        to: endDate.toISOString(),
        phases: [SpotLessonPhase.Incomplete, SpotLessonPhase.Complete],
      },
    },
  });
  const spotLessons = spotLessonData?.spotLessonsForInstructor.items ?? [];

  const {
    data: instructorScheduleData,
    refetch: refetchInstructorScheduleQuery,
    loading: isLoadingInstructorSchedule,
  } = useGetInstructorSchedulesQuery({
    variables: {
      from: startDate.toISOString(),
      to: endDate.toISOString(),
    },
  });
  const instructorSchedules = instructorScheduleData?.instructorSchedules ?? [];

  const refetchInstructorSchedules = useCallback(async (): Promise<void> => {
    try {
      await refetchInstructorScheduleQuery({
        from: startDate.toISOString(),
        to: endDate.toISOString(),
      });
    } catch {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
      return;
    }
  }, [endDate, refetchInstructorScheduleQuery, startDate]);

  const [getSpotLesson, spotLessonResponse] = useGetSpotLessonForInstructorLazyQuery();
  const fetchSpotLesson = async (id: number) => {
    getSpotLesson({
      variables: {
        id: id,
      },
    });
  };
  const currentSpotLesson: SpotLesson | undefined =
    spotLessonResponse.data?.spotLessonForInstructor ?? undefined;

  // InstructorScheduleとSpotLessonのCalendarEventデータを作成
  const instructorScheduleEvents: EventSourceInput = instructorSchedules.map((is) => {
    return {
      id: is.id.toString(),
      title: '待機',
      start: utcToJst(is.startAt),
      end: utcToJst(is.endAt),
      color: '#f1b007',
    };
  });
  const events: EventSourceInput = instructorScheduleEvents.concat(
    spotLessons.map((isl) => {
      const title = (() => {
        if (isl.user) {
          if ('maskedPersonalInfo' in isl.user && isl.user.maskedPersonalInfo) {
            return isl.user.maskedPersonalInfo.name;
          } else if ('name' in isl.user && isl.user.name) {
            return isl.user.name;
          }
        }

        return isl.user?.nickName;
      })();

      return {
        id: isl.id.toString(),
        title: title,
        start: utcToJst(isl.startAt),
        end: utcToJst(isl.endAt),
        color: '#e73248',
      };
    }),
  );

  const toggleAddModal = (nextState: boolean) => {
    setIsAddModalOpen(nextState);
  };

  const toggleEditModal = (nextState: boolean) => {
    setIsEditModalOpen(nextState);
  };

  const toggleSpotLessonDetailModal = (nextState: boolean) => {
    setIsSpotLessonDetailModalOpen(nextState);
  };

  const handleChangeView = (view: string) => {
    const calendar = calendarRef.current;

    if (!calendar) return;
    calendar.getApi().changeView(view);
    setCurrentView(view);
  };

  const onResize = useCallback(() => {
    if (window.innerWidth < 768) {
      handleChangeView('listWeek');
    } else {
      handleChangeView('timeGridWeek');
    }
  }, []);

  const handleDatePrev = () => {
    const calendar = calendarRef.current;

    if (!calendar) return;

    calendar.getApi().prev();
    setCurrentDate(calendar.getApi().getDate());
  };

  const handleDateNext = () => {
    const calendar = calendarRef.current;

    if (!calendar) return;

    calendar.getApi().next();
    setCurrentDate(calendar.getApi().getDate());
  };

  const handleDateToday = () => {
    const calendar = calendarRef.current;

    if (!calendar) return;

    calendar.getApi().today();
    setCurrentDate(calendar.getApi().getDate());
  };

  const onSelectDate = (d: DateSelectArg) => {
    d.view.calendar.unselect();

    const selectedDate =
      currentView === 'dayGridMonth'
        ? {
            ...d,
            start: setHours(d.start, 10),
            end: setHours(setDate(d.end, getDate(d.start)), 11),
          }
        : d;

    addModalRef.current?.setDefaultValue(selectedDate.start, selectedDate.end);
    toggleAddModal(true);
  };

  const onEventClick = (e: EventClickArg) => {
    if (!e.event.start || !e.event.end || !e.event.id) return;
    if (e.event.title === '待機') {
      // もうちょいマシなやり方がある気が（ないかもしれないが）
      editModalRef.current?.setDefaultValue(e.event.start, e.event.end, parseInt(e.event.id));
      toggleEditModal(true);
      return;
    }

    fetchSpotLesson(parseInt(e.event.id));
    toggleSpotLessonDetailModal(true);
  };

  const onInstructorScheduleCreate = useSafeAsyncCallback(
    useCallback(
      async (startAt: string, endAt: string) => {
        if (isLoadingInstructorSchedule) return;

        try {
          // 保存
          try {
            await createInstructorSchedule({
              variables: {
                input: {
                  startAt: startAt,
                  endAt: endAt,
                },
              },
            });
          } catch (e) {
            // HACK: APIのエラーについてはInputのモーダルまで引き回すと手間がかかるので一旦Toastで表示するが、
            // どうしてもモーダル上に表示したい要望や、簡単に対応する方法があれば改修する
            showToast(1, getApiErrorMessage(e));
            return;
          }
          // 成功時のコールバック(refetch)
          await refetchInstructorSchedules();
        } finally {
          toggleAddModal(false);
        }
      },
      [
        isLoadingInstructorSchedule,
        refetchInstructorSchedules,
        createInstructorSchedule,
        showToast,
      ],
    ),
  );

  const onInstructorScheduleUpdate = useSafeAsyncCallback(
    useCallback(
      async (instructorScheduleID: number, startAt: string, endAt: string) => {
        if (isLoadingInstructorSchedule) return;

        try {
          // 保存
          try {
            await updateInstructorSchedule({
              variables: {
                id: instructorScheduleID,
                input: {
                  startAt: startAt,
                  endAt: endAt,
                },
              },
            });
          } catch (e) {
            // HACK: APIのエラーについてはInputのモーダルまで引き回すと手間がかかるので一旦Toastで表示するが、
            // どうしてもモーダル上に表示したい要望や、簡単に対応する方法があれば改修する
            showToast(1, getApiErrorMessage(e));
            return;
          }
          // 成功時のコールバック(refetch)
          await refetchInstructorSchedules();
        } finally {
          toggleEditModal(false);
        }
      },
      [
        isLoadingInstructorSchedule,
        updateInstructorSchedule,
        refetchInstructorSchedules,
        showToast,
      ],
    ),
  );

  const onInstructorScheduleDelete = useSafeAsyncCallback(
    useCallback(
      async (instructorScheduleID: number) => {
        if (isLoadingInstructorSchedule) return;

        try {
          // 保存
          try {
            await deleteInstructorSchedule({
              variables: {
                id: instructorScheduleID,
              },
            });
          } catch (e) {
            // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
            showToast(1, getApiErrorMessage(e));
            return;
          }
          // 成功時のコールバック(refetch)
          await refetchInstructorSchedules();
        } finally {
          toggleEditModal(false);
        }
      },
      [
        isLoadingInstructorSchedule,
        refetchInstructorSchedules,
        deleteInstructorSchedule,
        showToast,
      ],
    ),
  );

  React.useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return (
    <TabNavigationLayout
      pageTitle="レッスン"
      tabs={[
        { label: '予約一覧', to: '/spot_lessons', active: false },
        { label: 'スケジュール管理', to: '/spot_lessons/schedule', active: true },
      ]}
      metaTitle={metaTitle}
    >
      <PageWrapper>
        <ButtonArea>
          <Button onClick={() => toggleAddModal(true)}>シフトを登録</Button>
        </ButtonArea>
        <StyledCalendarWrapper>
          <HeaderToolbar>
            <HeaderToolbarButtons>
              <TodayButton onClick={handleDateToday} border>
                今日
              </TodayButton>
              <Arrows>
                <button onClick={handleDatePrev}>
                  <span>
                    <NavigateBeforeIcon />
                  </span>
                </button>
                <button onClick={handleDateNext}>
                  <span>
                    <NavigateNextIcon />
                  </span>
                </button>
              </Arrows>
            </HeaderToolbarButtons>
            <HeaderHeadline>{moment(currentDate).format('YYYY年M月')}</HeaderHeadline>
            <HeaderIcons>
              <HeaderIcon
                isActive={currentView === 'dayGridMonth'}
                onClick={() => handleChangeView('dayGridMonth')}
              >
                <ViewConfigIcon />
              </HeaderIcon>
              <HeaderIcon
                isActive={currentView === 'timeGridWeek'}
                onClick={() => handleChangeView('timeGridWeek')}
              >
                <ViewWeekIcon />
              </HeaderIcon>
              <HeaderIcon
                isActive={currentView === 'listWeek'}
                onClick={() => handleChangeView('listWeek')}
              >
                <ViewAgendaIcon />
              </HeaderIcon>
            </HeaderIcons>
          </HeaderToolbar>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, momentPlugin]}
            headerToolbar={false}
            height={530}
            locale="ja"
            initialView="timeGridWeek"
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            buttonText={{
              today: '今日',
            }}
            allDaySlot={false}
            slotMinTime={SLOT_MIN_TIME}
            slotMaxTime={SLOT_MAX_TIME}
            slotDuration="01:00"
            slotLabelInterval="01:00"
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short',
            }}
            events={events}
            ref={calendarRef}
            views={{
              listWeek: {
                listDayFormat: 'M/D(dddd)',
                listDaySideFormat: false,
              },
            }}
            select={onSelectDate}
            eventClick={onEventClick}
          />
          <Info>
            ※こちらのカレンダーのシフト・営業時間は日本標準時(JST)での時刻表記となっております。
          </Info>
        </StyledCalendarWrapper>
      </PageWrapper>
      <InstructorScheduleAddModal
        isOpen={isAddModalOpen}
        toggle={toggleAddModal}
        ref={addModalRef}
        onInstructorScheduleCreate={onInstructorScheduleCreate}
        isLoadingInstructorSchedule={isLoadingInstructorSchedule}
      />
      <InstructorScheduleEditModal
        isOpen={isEditModalOpen}
        toggle={toggleEditModal}
        ref={editModalRef}
        onInstructorScheduleUpdate={onInstructorScheduleUpdate}
        onInstructorScheduleDelete={onInstructorScheduleDelete}
        isLoadingInstructorSchedule={isLoadingInstructorSchedule}
      />
      <InstructorSpotLessonDetailModal
        isOpen={isSpotLessonDetailModalOpen}
        toggle={toggleSpotLessonDetailModal}
        spotLesson={currentSpotLesson}
      />
    </TabNavigationLayout>
  );
};

const ButtonArea = styled.section`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${media.lessThan('medium')`
    justify-content: flex-start;
  `}
`;

const HeaderToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  ${media.lessThan('medium')`
    display: block;
    margin-bottom: 1rem;
  `}
`;

const HeaderToolbarButtons = styled.div`
  display: flex;
  align-items: center;
`;

const TodayButton = styled(Button)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const Arrows = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;

  button {
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;

    span {
      color: #e73248;
    }
  }
`;

const HeaderIcons = styled.div`
  display: flex;
  ${media.lessThan('medium')`
    display: none;
  `}
`;

const HeaderIcon = styled.p<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? '#eb0000' : '#000000')};
  margin-left: 27px;
  cursor: pointer;
`;

const HeaderHeadline = styled.h3`
  font-size: 1rem;
  font-weight: 700;
  ${media.lessThan('medium')`
    display: none;
  `}
`;

const StyledCalendarWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 1rem;

  .fc-timeGridWeek-view {
    background-color: #ffffff;
    height: 530px;

    .fc-scrollgrid-section-header {
      th {
        background-color: #f4f6f8;
        color: rgba(0, 0, 0, 0.87);
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 0.875rem;

        .fc-day-past {
          color: rgba(0, 0, 0, 0.36);
        }

        .fc-day-today {
          font-weight: 700;
        }
      }
    }

    .fc-day-today {
      background-color: transparent;
    }

    .fc-timegrid-slot-label-cushion {
      padding: 0.75rem;
    }
  }
`;

const Info = styled.p`
  margin: 0.5rem;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
`;
