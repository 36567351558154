import { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useParams } from 'react-router-dom';

import { PracticeTabLayout } from '../../templates/PracticeTabLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Mv } from '../../organisms/Mv';
import { PracticeList } from '../../organisms/PracticeList';
import { ProgramCategoryModal } from '../../organisms/ProgramCategoryModal';
import Pagination from '../../organisms/Pagination';
import { ProgramCategoryList } from '../../organisms/ProgramCategoryList';

import MvImage from '../../../static/image/mv/practice.svg';

import { FIRST_PAGE, MIDDLE_LIMIT } from '../../../const/Page';
import { useURL } from '../../../common/customHooks/URL';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import {
  useGetPracticesQuery,
  useGetTagsQuery,
  TagType,
  useGetTagQuery,
} from '../../../gen/graphql';
import { Loader } from '../../molecules/Loader';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import ForbiddenPage from '../public/Forbidden';

export const Practices = (): JSX.Element => {
  const metaTitle = `課題 | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}の課題ページです。課題にチャレンジすることでアウトプット力が鍛えられスキルを定着させることができます。課題を単純に解くだけではなく、提出&レビューを受けることでより知識が磨かれます。`;

  const { setParams, queries } = useURL();

  const paramCategoryID = useParams<{ category_id: string }>().category_id;
  const pathCategoryID = paramCategoryID ? Number(paramCategoryID) : 0;
  const page = queries?.page ? Number(queries.page) : FIRST_PAGE;

  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);

  const { data: practicesData, loading: practicesLoading } = useGetPracticesQuery({
    variables: {
      input: {
        page,
        limit: MIDDLE_LIMIT,
        tagID: pathCategoryID !== 0 ? pathCategoryID : undefined,
      },
    },
  });
  const practices = practicesData?.practices.items ?? [];
  const total = practicesData?.practices.total ?? 0;

  const { data, loading: tagLoading } = useGetTagQuery({
    variables: {
      id: pathCategoryID,
    },
    skip: !pathCategoryID,
  });
  const tag = data?.getTag;

  const { data: tagData } = useGetTagsQuery({
    variables: {
      input: {
        type: TagType.Program,
      },
    },
  });
  const programCategories =
    tagData?.getTags.items?.map((item) => ({
      id: item.id,
      name: item.name,
      description: item.overview ?? undefined,
    })) ?? [];

  const setPage = (value: number) => {
    setParams([{ name: 'page', value: value.toString() }]);
  };

  const mvProps = {
    title: '課題にチャレンジしてアウトプット力を鍛えよう',
    description:
      '各種教材の中に「課題」を用意しています。課題にチャレンジすることでアウトプット力が鍛えられスキルを定着させることができます。課題を単純に解くだけではなく、提出&レビューを受けることでより知識が磨かれます。皆さんのチャレンジお待ちしております！',
    bg: '#f3ede1',
    image: MvImage,
  };

  // 権限（UserRole）によりタグが取得できない場合は403へ
  if (pathCategoryID && !tag && !tagLoading) return ForbiddenPage;

  return (
    <>
      <Loader display={practicesLoading} />
      <PracticeTabLayout activeTab="top" metaTitle={metaTitle} metaDescription={metaDescription}>
        <PageWrapper>
          <StyledMv mv={mvProps} />
          <Container>
            <PracticeListWrapper>
              {total > 0 ? (
                <>
                  <PracticeList practices={practices} />
                  <Pagination total={total} perPage={MIDDLE_LIMIT} page={page} setPage={setPage} />
                </>
              ) : (
                <EmptyBlock title="現在表示できる課題はありません" />
              )}
            </PracticeListWrapper>
            <CategoryList>
              <h3>カテゴリーで探す</h3>
              <ProgramCategoryList
                categories={programCategories}
                searchCategory={pathCategoryID}
                to="/practices/category"
              />
              <SpCategoryLink>
                <span onClick={() => setIsOpenCategoryModal(true)}>
                  カテゴリーの一覧
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.00006 4.76843C6.00254 4.83071 6.03732 4.8902 6.09818 4.93388L12.2324 9.52484L6.09818 14.1158C6.03608 14.1604 6.00254 14.2218 6.0013 14.2859C6.00254 14.3835 6.08079 14.47 6.20002 14.5071C6.3205 14.5434 6.45712 14.522 6.54778 14.4523L12.9068 9.69309C13.0311 9.60013 13.0311 9.44955 12.9068 9.3566L6.54778 4.59739C6.45712 4.52675 6.31677 4.50537 6.19629 4.54255C6.07582 4.58066 5.99757 4.6699 6.00006 4.76843Z"
                      fill="black"
                      fillOpacity="0.36"
                      stroke="#EB0000"
                    />
                  </svg>
                </span>
              </SpCategoryLink>
            </CategoryList>
          </Container>
        </PageWrapper>
      </PracticeTabLayout>
      <ProgramCategoryModal
        isOpen={isOpenCategoryModal}
        closeModal={() => setIsOpenCategoryModal(false)}
      >
        <ProgramCategoryList
          categories={programCategories}
          searchCategory={pathCategoryID}
          to="/practices/category"
        />
      </ProgramCategoryModal>
    </>
  );
};

const StyledMv = styled(Mv)`
  ${media.lessThan('medium')`
    width: calc(100% + 2rem);
    margin: -2rem -1rem 0;
  `}
`;
const Container = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-top: 2rem;

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
  `}
`;
const PracticeListWrapper = styled.div`
  flex: 1;
`;
const CategoryList = styled.div`
  width: 284px;

  h3 {
    margin-bottom: 0.75rem;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  ${media.lessThan('medium')`
    width: 100%;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: .5rem 1rem;

      li {
        padding: .25rem;

        &:hover {
          background: none;
        }

        &:nth-of-type(n + 2) {
          margin-top: 0;
        }

        &:nth-of-type(n + 7) {
          display: none;
        }
      }
    }
  `}
`;
const SpCategoryLink = styled.p`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
    justify-content: flex-end;
    margin-top: .5rem;

    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #eb0000;
      font-size: .875rem;
      line-height: 1.25rem;
    }
  `}
`;
