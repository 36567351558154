import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { FunctionType, PermissionType } from '../../const/UserPermission';

import { PracticeListItemFragment } from '../../gen/graphql';
import { useUser } from '../../redux/user/useUser';
import { PracticeArticle } from './PracticeArticle';

interface Props {
  className?: string;
  practices: PracticeListItemFragment[];
}

export const PracticeList: React.FC<Props> = (props) => {
  const { permissionCheck } = useUser();
  const permissionPracticeForInstructorAndCoachRead = permissionCheck(
    FunctionType.PracticeForInstructorAndCoach,
    PermissionType.Read,
  );

  return (
    <div className={props.className}>
      <List>
        <ListHeader>
          <HeaderContent as="p">課題名 / 教材</HeaderContent>
          <Difficulty>難易度</Difficulty>
          <Success>合格者数</Success>
          {!permissionPracticeForInstructorAndCoachRead && (
            <>
              <Submission>提出物</Submission>
              <Status>ステータス</Status>
            </>
          )}
        </ListHeader>
        {props.practices.map((practice) => (
          <PracticeArticle key={practice.id} practice={practice} />
        ))}
      </List>
    </div>
  );
};

const List = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;

  ${media.lessThan('medium')`
    margin: 0 -1rem;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.1);
    border-radius: 0;
  `}
`;
const ListHeader = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem 0.75rem 1.5rem;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`;

const Content = styled.div`
  flex: 1;

  h3 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
  }

  ${media.lessThan('medium')`
    flex: none;
    width: 100%;
  `}
`;
const Difficulty = styled.p`
  width: 98px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;

  ${media.lessThan('medium')`
    flex: 1;
    width: auto;
    font-weight: 700;
    text-align: left;
  `}
`;
const Success = styled.p`
  width: 78px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;

  ${media.lessThan('medium')`
    flex: 1;
    width: auto;
    font-weight: 700;
    text-align: left;

    &:after {
      content: 'が合格';
    }
  `}
`;
const Submission = styled.p`
  width: 98px;
`;
const Status = styled.p`
  width: 78px;
`;

const HeaderContent = styled(Content)`
  text-align: left;
`;
