import React from 'react';
import styled from 'styled-components';
import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';

import iconCaution from '../../static/image/icon_caution.svg';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  onCancel: () => void;
  onPost: () => void;
  onClose: () => void;
}

export const TimelinePostConfirmModal: React.FC<Props> = ({
  isOpen,
  onCancel,
  onPost,
  onClose,
  ...props
}): JSX.Element => {
  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={onClose}
      loading={props.loading}
      width={'572px'}
      footer={
        <Footer>
          <CancelButton onClick={onCancel}>キャンセル</CancelButton>
          <Button onClick={onPost}>投稿する</Button>
        </Footer>
      }
    >
      <Container>
        <Caution>投稿内容にご注意ください。</Caution>
        <Message>
          この投稿は、運営または講師として投稿されます。
          <br />
          投稿内容には十分に注意して投稿ください。
        </Message>
      </Container>
    </Modal>
  );
};

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Container = styled.div`
  padding: 3.5rem 2rem 1.5rem 4.75rem;
`;

const CancelButton = styled(Button)`
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
`;

const Caution = styled.p`
  position: relative;
  font-weight: bold;
  font-size: 0.9375rem;

  &:before {
    position: absolute;
    left: -2.5rem;
    top: -0.375rem;
    content: '';
    display: inline-block;
    width: 2em;
    height: 2em;
    background: url(${iconCaution}) no-repeat;
    background-size: contain;
  }
`;

const Message = styled.p`
  margin-top: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.8;
`;
