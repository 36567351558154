import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useUser } from '../../../redux/user/useUser';

import { Header } from '../../organisms/lp/trial/Header';
import { Fv } from '../../organisms/lp/trial/Fv';
import { LpResult } from '../../organisms/lp/LpResult';
import { LpProgram } from '../../organisms/lp/LpProgram';
import { LpFeature } from '../../organisms/lp/LpFeature';
import { LeadSimple } from '../../organisms/lp/trial/LeadSimple';
import { LpLesson } from '../../organisms/lp/LpLesson';
import { LpProgramList } from '../../organisms/lp/LpProgramList';
import { CareerSupport } from '../../organisms/lp/CareerSupport';
import { LpQuestion } from '../../organisms/lp/LpQuestion';
import { LpFlow } from '../../organisms/lp/LpFlow';
import { Rank } from '../../organisms/lp/trial/Rank';
import { LpVoice } from '../../organisms/lp/LpVoice';
import { Price } from '../../organisms/lp/trial/Price';
import { LpMedia } from '../../organisms/lp/LpMedia';
import { LpGift } from '../../organisms/lp/LpGift';
import { PlanSimple } from '../../organisms/lp/PlanSimple';
import { Lead } from '../../organisms/lp/trial/Lead';
import { LpFaq } from '../../organisms/lp/LpFaq';
import { LpFooter } from '../../organisms/lp/LpFooter';
import { FloatBanner } from '../../organisms/lp/trial/FloatBanner';
import { LpPopup } from '../../organisms/lp/LpPopup';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { Challenge } from '../../organisms/lp/Challenge';
import { sessionStorageSupport } from '../../../utils/sessionStorageSupport';

export const LpAd: React.FC = () => {
  const { permissionCheck } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const query = queryString.parse(location.search);
  if (query.utm_source) {
    sessionStorageSupport.setItem('UTM_SOURCE', String(query.utm_source));
  }

  const link = () => {
    navigate('/register');
  };

  return (
    <>
      {permissionCheck(FunctionType.LP, PermissionType.Read) ? (
        <>
          <Helmet>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap"
              rel="stylesheet"
            />
          </Helmet>

          <Header onClickCV={link} />
          <Fv onClickCV={link} />
          <LpResult />
          <LpProgram />
          <LeadSimple onClickCV={link} />
          <LpFeature />
          <Price />
          <LpLesson />
          <LpProgramList />
          <Challenge />
          <CareerSupport />
          <LpQuestion />
          <LpFlow />
          <Rank onClickCV={link} />
          <LpVoice />
          <LpGift />
          <PlanSimple />
          <LpMedia />
          <Lead onClickCV={link} />
          <LpFaq />
          <LpFooter />
          <FloatBanner onClickCV={link} />

          <LpPopup />
        </>
      ) : (
        <Navigate to="/home" replace />
      )}
    </>
  );
};
