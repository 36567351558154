import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
}

export const LpLanguageScroll: React.FC<Props> = (props) => {
  return (
    <IconList>
      <ScrollContainer>{props.children}</ScrollContainer>
    </IconList>
  );
};

const IconList = styled.div`
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: 1.25rem;
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.36);
    border-radius: 0.25rem;
  }
`;
const ScrollContainer = styled.div`
  > * {
    display: inline-block;
    vertical-align: top;

    + * {
      margin-left: 1.5rem;
    }
  }
`;
