import React from 'react';
import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';

import { ProgramListContainer } from '../atoms/ProgramListContainer';
import { Modal } from '../molecules/Modal';
import { ShareButton } from '../molecules/ShareButton';
import { CourseProgramArticle } from './CourseProgramArticle';

import CloseIcon from '../../static/image/close.svg';
import Image001 from '../../static/image/program_complete_001.png';
import Image002 from '../../static/image/program_complete_002.png';
import Image003 from '../../static/image/program_complete_003.png';
import Image004 from '../../static/image/program_complete_004.png';

import { useGetCourseProgramsQuery } from '../../gen/graphql';
import { useUser } from '../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../const/UserPermission';

interface Props {
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  programID: number;
  programName?: string;
}

export const ProgramCompleteModal: React.FC<Props> = (props) => {
  const { permissionCheck } = useUser();

  const { data } = useGetCourseProgramsQuery({
    variables: {
      input: {
        programID: props.programID,
      },
    },
  });
  const coursePrograms = data?.coursePrograms;

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} loading={props.loading} width="960px">
      <BgImage1 src={Image003} />
      <BgImage2 src={Image004} />
      <Container>
        <Title>Congratulations</Title>
        <Text>
          おめでとうございます!
          <br />
          {!!props.programName && `「${props.programName}」`}教材を修了しました！
        </Text>
        <ImageArea>
          <ContentBgImage src={Image002} />
          <ContentImage src={Image001} />
        </ImageArea>
        <ButtonArea>
          <ShareButton
            content={`「${props.programName}」教材を修了しました！`}
            hashTags={['侍エンジニア']}
          />
        </ButtonArea>
        <ArchiveLinks>
          <ArchiveLink to="/programs">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.6523 1.7832C10.3857 1.7832 10.1738 1.87891 9.98926 2.05664L4.74609 7.17676C4.51367 7.40918 4.4043 7.64844 4.39746 7.94238C4.39746 8.23633 4.50684 8.48242 4.74609 8.70801L9.98926 13.835C10.1738 14.0127 10.3926 14.1084 10.6523 14.1084C11.1855 14.1084 11.6094 13.6846 11.6094 13.1582C11.6094 12.8984 11.5 12.6523 11.3086 12.4609L6.66016 7.94238L11.3086 3.43066C11.5 3.23926 11.6094 2.99316 11.6094 2.7334C11.6094 2.20703 11.1855 1.7832 10.6523 1.7832Z"
                fill="#555555"
              />
            </svg>
            教材一覧へ戻る
          </ArchiveLink>
          <ArchiveLink to={`/programs/${props.programID}/chapters`}>
            章一覧へ戻る
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.34766 1.7832C5.61426 1.7832 5.82617 1.87891 6.01074 2.05664L11.2539 7.17676C11.4863 7.40918 11.5957 7.64844 11.6025 7.94238C11.6025 8.23633 11.4932 8.48242 11.2539 8.70801L6.01074 13.835C5.82617 14.0127 5.60742 14.1084 5.34766 14.1084C4.81445 14.1084 4.39062 13.6846 4.39062 13.1582C4.39062 12.8984 4.5 12.6523 4.69141 12.4609L9.33984 7.94238L4.69141 3.43066C4.5 3.23926 4.39062 2.99316 4.39062 2.7334C4.39062 2.20703 4.81445 1.7832 5.34766 1.7832Z"
                fill="#555555"
              />
            </svg>
          </ArchiveLink>
        </ArchiveLinks>
        {!!coursePrograms && (
          <>
            <ProgramTitle>関連コース教材</ProgramTitle>
            <StyledProgramListContainer>
              {coursePrograms.map((p) => {
                return (
                  <CourseProgramArticle
                    id={p.id}
                    key={p.id}
                    image={p.image}
                    title={p.title}
                    totalChapterCount={p.totalChapterCount}
                    totalRequireTime={p.totalRequireTime}
                    totalCompletedTime={p.totalCompletedTime}
                    description={p.description}
                    showProgress={permissionCheck(FunctionType.StudyLog, PermissionType.Read)}
                  />
                );
              })}
            </StyledProgramListContainer>
          </>
        )}
      </Container>

      <CloseModal onClick={props.onClose}>
        <img src={CloseIcon} />
      </CloseModal>
    </Modal>
  );
};

const fade1 = keyframes`
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;
const fade2 = keyframes`
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;
const zoom = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  5% {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const confetti = keyframes`
  0% {
    top: -14rem;
    opacity: 1;
  }
  5% {
    top: -14rem;
    opacity: 1;
  }
  10% { left: 50.3%; }
  18% { left: 49.6%; }
  25% { left: 50.3%; }
  32% { left: 49.8%; }
  40% { left: 50.3%; }
  48% { left: 49.7%; }
  56% { left: 50.3%; }
  64% { left: 49.6%; }
  71% { left: 50.3%; }
  79% { left: 49.8%; }
  87% { left: 50.3%; }
  95% { left: 49.7%; }
  98% {
    top: -2rem;
    opacity: 1;
    left: 50%;
  }
  100% {
    top: -2rem;
    opacity: 0;
  }
`;
const confettiSp = keyframes`
  0% {
    top: -15%;
    opacity: 1;
  }
  5% {
    top: -15%;
    opacity: 1;
  }
  10% { left: 50.3%; }
  18% { left: 49.6%; }
  25% { left: 50.3%; }
  32% { left: 49.8%; }
  40% { left: 50.3%; }
  48% { left: 49.7%; }
  56% { left: 50.3%; }
  64% { left: 49.6%; }
  71% { left: 50.3%; }
  79% { left: 49.8%; }
  87% { left: 50.3%; }
  95% { left: 49.7%; }
  98% {
    top: 0;
    opacity: 1;
    left: 50%;
  }
  100% {
    top: 0;
    opacity: 0;
  }
`;

const BgImage1 = styled.img`
  display: block;
  width: 922px;
  position: absolute;
  top: -14rem;
  left: 50%;
  transform: translateX(-50%);
  animation: ${confetti} 10s linear infinite;

  ${media.lessThan('medium')`
    width: 150%;
    animation: ${confettiSp} 10s linear infinite;
  `}
`;
const BgImage2 = styled.img`
  display: block;
  width: 827px;
  position: absolute;
  top: -1.375rem;
  left: 50%;
  transform: translateX(-50%);

  ${media.lessThan('medium')`
    width: 100%;
    top: .1875rem;
  `}
`;
const Container = styled.div`
  padding: 3.5rem 2rem 2.5rem;
  position: relative;

  ${media.lessThan('medium')`
    padding: 3.5rem 1rem 2.5rem;
  `}
`;
const Title = styled.h3`
  color: #edae0d;
  font-size: 4rem;
  font-weight: 700;
  line-height: 5rem;
  text-align: center;
  animation: ${fade1} 10s linear infinite;

  ${media.lessThan('medium')`
    font-size: 2rem;
    line-height: 2.625rem;
  `}
`;
const Text = styled.p`
  margin-top: 0.25rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;
  animation: ${fade2} 10s linear infinite;

  ${media.lessThan('medium')`
    margin-top: .5rem;
    font-size: .875rem;
    line-height: 1.25rem;
  `}
`;
const ImageArea = styled.div`
  position: relative;
`;
const ContentBgImage = styled.img`
  display: block;
  width: 392.5px;
  height: auto;
  margin: auto;
  position: absolute;
  top: -1.875rem;
  left: 0;
  right: 1.5rem;
  animation: ${zoom} 10s linear infinite;

  ${media.lessThan('medium')`
    width: 252px;
    top: -1.25rem;
    right: .25rem;
  `}
`;
const ContentImage = styled.img`
  display: block;
  width: 100%;
  max-width: 374px;
  margin: 2rem auto 0;
  position: relative;

  ${media.lessThan('medium')`
    max-width: 240px;
    margin-top: 2.25rem;
    left: 1rem;
  `}
`;
const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  ${media.lessThan('medium')`
    margin-top: 1.25rem;
  `}
`;

const ArchiveLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;
const ArchiveLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #555;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: all 0.2s;

  svg path {
    transition: all 0.2s;
  }

  &:hover {
    color: #eb0000;

    svg path {
      fill: #eb0000;
    }
  }

  ${media.lessThan('medium')`
    gap: .25rem;
    font-size: .875rem;
    line-height: 1.25rem;
  `}
`;

const ProgramTitle = styled.h4`
  margin-top: 2.5rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 700;
  line-heght: 1.35;
`;
const StyledProgramListContainer = styled(ProgramListContainer)`
  margin-top: 1rem;
`;

const CloseModal = styled.div`
  cursor: pointer;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;

  img {
    width: 1.125rem;
  }

  ${media.lessThan('medium')`
    top: 1.125rem;
    right: 1.125rem;

    img {
      width: .75rem;
    }
  `}
`;
