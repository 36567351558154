import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { SERVICE_NAME } from '../../const/Service';

import { Button } from '../atoms/Button';

import Catch007 from '../../static/image/walkThrough007.png';

interface WalkThroughModalStartContentProps {
  toggle: (nextState: boolean) => void;
}

export const WalkThroughModalStartContent: React.FC<WalkThroughModalStartContentProps> = ({
  toggle,
}) => {
  const linkBlank = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <StartContent>
      <Catch src={Catch007} />
      <Title>
        {SERVICE_NAME}で<br />
        プログラミングスキル習得
        <br className="sp" />
        の第一歩を
      </Title>
      <Text>効率的に学習を進めたい方は有料プランや無料サポートの利用をご検討ください。</Text>
      <ButtonBlock>
        <StyledButton
          onClick={() => linkBlank('/account/subscription/lp?cid=post-registration_modal1')}
          border
        >
          有料プランの詳細を見る
        </StyledButton>
        <StyledButton
          onClick={() =>
            linkBlank(
              'https://docs.google.com/forms/d/e/1FAIpQLSeELqJrRenIEUAzsQfc6q1Sk3eIspM4n0Ax9raJGgyT00wgog/viewform?cid=terakoya_counseling',
            )
          }
          border
        >
          無料サポートを予約する
        </StyledButton>
      </ButtonBlock>
      <CloseModal>
        <p onClick={() => toggle(false)}>フリープランで学習を始める</p>
      </CloseModal>
    </StartContent>
  );
};

const StartContent = styled.div`
  padding-bottom: 5.5rem;
  position: relative;

  ${media.lessThan('small')`
    padding: 0 0 2rem;
    box-sizing: border-box;
  `}
`;
const ButtonBlock = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 1.375rem;
  padding: 0 2rem;

  ${media.lessThan('small')`
    flex-direction: column;
    gap: .75rem;
  `}
`;
const StyledButton = styled(Button)`
  flex: 1;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
const CloseModal = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 2rem;

  p {
    cursor: pointer;
    color: #eb0000;
    font-size: 1rem;
    line-height: 1.8;
    letter-spacing: 0.048px;
    text-align: center;
  }

  ${media.lessThan('small')`
    margin-top: .75rem;
    padding: 0 2rem;
    box-sizing: border-box;
    position: relative;
    bottom: auto;

    p {
      padding: .5rem 0;
      font-size: .875rem;
      line-height: 1.375rem;
    }
  `}
`;
const Catch = styled.img`
  display: block;
  width: 100%;
`;
const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  padding: 0 2rem;
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  .sp {
    display: none;
  }

  ${media.lessThan('small')`
    margin: 1.75rem auto 0;

    .sp {
      display: block;
    }
  `}
`;
const Text = styled.p`
  margin: 1.125rem auto 0;
  padding: 0 2rem;
  color: #000;
  font-size: 1rem;
  letter-spacing: 0.003em;
  line-height: 1.8em;
  text-align: center;

  ${media.lessThan('small')`
    margin-top: 1.5rem;
    font-size: .875rem;
    letter-spacing: .042px;
  `}
`;
