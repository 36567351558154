import { ClaimCategory } from '../common/Const';
import { HistoricalCourseFragment as HistoricalCourse } from '../gen/graphql';
import { Option } from '../components/molecules/InputWithSearch';

enum CourseStatus {
  DELETED = 0,
  ACTIVE = 1,
  BEFORE_FIRST_LESSON = 2,
  BEFORE_ASSIGN = 3,
  INSTRUCTOR_CHANGING = 4,
  ABSENCE = 5,
  GRADUATED = 6,
  CANCELED = 7,
  ABSENCE_INSTRUCTOR_CHANGING = 8,
  CANCELING = 9,
}

export const CourseStatusLabels = {
  [CourseStatus.ACTIVE]: '受講中',
  [CourseStatus.BEFORE_FIRST_LESSON]: '初回レッスン待ち',
  [CourseStatus.BEFORE_ASSIGN]: 'アサイン待ち',
  [CourseStatus.INSTRUCTOR_CHANGING]: 'インスト交代中',
  [CourseStatus.ABSENCE]: '休学中',
  [CourseStatus.GRADUATED]: '卒業済み',
  [CourseStatus.CANCELED]: '解約',
  [CourseStatus.ABSENCE_INSTRUCTOR_CHANGING]: '休学/インスト交代中',
  [CourseStatus.CANCELING]: '解約中',
} as const;

const filterCourseByClaimCategory = (
  courses: HistoricalCourse[],
  claimCategoryID: string,
): HistoricalCourse[] => {
  switch (parseInt(claimCategoryID)) {
    case ClaimCategory.LESSON_PREPARATION:
      return courses.filter(
        (course: HistoricalCourse) => course.plan.isLessonPreparationAllowed == 'TRUE',
      ); // レッスン前後対応が許可されているコースだけ請求申請が出せる
    case ClaimCategory.QUESTION:
      return courses.filter((course: HistoricalCourse) => course.plan.isQuestionAllowed == 'TRUE'); // 質問対応が許可されているコースのみ請求申請が出せる
    case ClaimCategory.CURRICULUM:
      return courses.filter(
        (course: HistoricalCourse) => course.plan.isFlexibleCurriculum == 'TRUE',
      ); // オーダーメイドのカリキュラムが許可されているコースのみ請求申請がだせる
    default:
      return courses;
  }
};

const BOUNDARY_VALUE_OF_COURSE_DAYS_LEFT = 30;

const formatCourses = (courses: HistoricalCourse[]): Option[] => {
  const formattedCourses = courses.map((course: HistoricalCourse) => {
    return {
      id: course.id ? course.id : 0,
      name: `${course.student?.user?.maskedPersonalInfo?.name}: ${course.plan?.name}`,
    };
  });
  return formattedCourses;
};

export {
  filterCourseByClaimCategory,
  formatCourses,
  CourseStatus,
  BOUNDARY_VALUE_OF_COURSE_DAYS_LEFT,
};
