import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import bgImage from '../../../static/image/login_bg.jpg';

interface LayoutProps {
  children?: React.ReactNode;
  title: string;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Container>
      <section>
        <h2>
          SAMURAI
          <br />
          TERAKOYA
        </h2>
        <h3>Learning Management System</h3>
      </section>
      <section>
        {children}
        <Copyright className="copyright">© Samurai, Inc. All Rights Reserved.</Copyright>
      </section>
      <style jsx>{`
        h2 {
          font-size: 2.5rem;
          font-weight: 600;
          line-height: 1.3;
          margin-bottom: 0.5rem;
          color: #fff;
        }

        h3 {
          color: #fff;
        }

        h4 {
          text-align: center;
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 2rem;
        }

        input {
          display: block;
          width: 290px;
          margin: 0;
          margin-bottom: 1rem;
          font-size: 1rem;
          border: solid 1px rgba(0, 0, 0, 0.87);
          padding: 0.5rem 0.5rem 0.5rem 2rem;
          box-sizing: border-box;
          background: none;
          border-radius: 0;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }

        button {
          margin: 1rem 0;
          cursor: pointer;
          padding: 0.75rem 0;
          width: 290px;
          background: rgba(0, 0, 0, 0.36);
          color: #fff;
          font-size: 1rem;
          border: none;
          border-radius: 0;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }
      `}</style>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 10%;
  background: url(${bgImage}) center center / cover no-repeat fixed;

  ${media.lessThan('medium')`
    display: block;
    padding: 100px 50px;
    background-attachment: scroll;
  `}

  ${media.lessThan('small')`
    padding: 80px 16px 20px;
  `}
`;

const Copyright = styled.p`
  text-align: center;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.36);

  ${media.lessThan('medium')`
    margin: 20px;
  `}
  ${media.greaterThan('medium')`
    position: absolute;
    bottom: 1rem;
    transform: translateX(20%);
  `}
`;
