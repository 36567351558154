import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import TagManager from 'react-gtm-module';

import { useUser } from '../../redux/user/useUser';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { COMMENT_PLACE_HOLDER } from '../../const/Question';
import { useToastsContext } from '../../context/ToastsProvider';

import { Button } from '../atoms/Button';
import { ErrorText } from '../atoms/ErrorText';
import { QuestionResponseAnswer } from '../molecules/QuestionResponseAnswer';
import { QuestionResponseAnswerPending } from '../molecules/QuestionResponseAnswerPending';
import { QuestionResponseComment } from '../molecules/QuestionResponseComment';
import { RichInput, RichInputHandler } from '../molecules/RichInput';

import {
  QuestionAnswerFragment,
  QuestionUserFragment,
  useAddQuestionCommentMutation,
  AnswerRequestType,
} from '../../gen/graphql';

import { getCommentInputType, CommentInputType } from '../../utils/Question';

import {
  QuestionCommentContentError,
  validateQuestionCommentContent,
} from '../../utils/FormValidation';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { useCommonModal } from '../../redux/common_modal/useCommonModal';
import ImgResponseAlert from '../../static/image/response_alert.svg';
import { getApiErrorMessage } from '../../utils/graphqlError';

interface AnswerProps {
  answer: QuestionAnswerFragment;
  questionID: number;
  isAnswerPending: boolean;
  answerRequestType: AnswerRequestType;
  isOwnQuestion: boolean;
  isEscalated: boolean;
  isQuestionAnswerTypeAI: boolean; // 回答が生成されてない場合も考慮してOptional
  answerPendingUser?: QuestionUserFragment;
  answerRequestedDate: string;
  refetchQuestion: () => void;
  onChangeFavoriteStatus: (questionID: number) => void;
  className?: string;
  openModalForEditing: boolean;
}

export const QuestionAnswer: React.FC<AnswerProps> = (props) => {
  const { user } = useUser();
  const { openModal } = useCommonModal();
  const { showToast } = useToastsContext();
  const [commentOpen, setCommentOpen] = useState(false);
  const [omitComment, setOmitComment] = useState(
    props.answer.questionComments && props.answer.questionComments.length > 1,
  );
  const [contentError, setContentError] = useState<QuestionCommentContentError | null>(null);

  const handleComment = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        userId: user.lmsUser?.id,
        user: user.lmsUser,
        event: 'gtm-qa-cmt-new',
        eventData: {
          questionID: props.questionID,
          answerID: props.answer.id,
        },
      },
      dataLayerName: 'LMSDataLayer',
    });

    if (openModal(FunctionType.QuestionAnswerAndComment, PermissionType.Create)) return;
    setCommentOpen(true);
  }, [openModal, props.answer.id, props.questionID, user.lmsUser]);

  const [addQuestionComment] = useAddQuestionCommentMutation({
    onCompleted: props.refetchQuestion,
  });

  const commentContentRef = useRef<RichInputHandler>(null);
  const submitComment = useSafeAsyncCallback(
    useCallback(async () => {
      const commentContent = commentContentRef.current?.getValue() ?? '';
      const error = validateQuestionCommentContent(commentContent);
      setContentError(error);
      if (error) return;

      try {
        await addQuestionComment({
          variables: {
            questionAnswerID: props.answer.id,
            input: {
              content: commentContent,
            },
          },
        });
      } catch (err) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, getApiErrorMessage(err));
      } finally {
        setCommentOpen(false);
      }
    }, [props.answer.id, addQuestionComment, showToast]),
  );

  const commentInputType = getCommentInputType(
    props.answerRequestType,
    props.isAnswerPending,
    props.isOwnQuestion,
    props.isEscalated,
    props.isQuestionAnswerTypeAI,
  );

  return (
    <Container key={props.answer.id} className={props.className}>
      <QuestionResponseAnswer
        response={props.answer}
        questionId={props.questionID}
        refetchQuestion={props.refetchQuestion}
        onChangeFavoriteStatus={props.onChangeFavoriteStatus}
      />
      {props.answer.questionComments &&
        (omitComment ? (
          <QuestionResponseComment
            key={props.answer.questionComments[props.answer.questionComments.length - 1].id}
            response={props.answer.questionComments[props.answer.questionComments.length - 1]}
            answerId={props.answer.id}
            questionId={props.questionID}
            omitComment={omitComment}
            omitCount={props.answer.questionComments.length - 1}
            setOmitComment={setOmitComment}
            refetchQuestion={props.refetchQuestion}
            openModalForEditing={props.openModalForEditing}
          />
        ) : (
          props.answer.questionComments.map((comment) => {
            return (
              <QuestionResponseComment
                key={comment.id}
                response={comment}
                answerId={props.answer.id}
                questionId={props.questionID}
                refetchQuestion={props.refetchQuestion}
                openModalForEditing={props.openModalForEditing}
              />
            );
          })
        ))}
      {props.isAnswerPending && props.answerPendingUser && (
        <QuestionResponseAnswerPending
          answeredUser={props.answerPendingUser}
          answerRequestedDate={props.answerRequestedDate}
          isComment
          large
        />
      )}
      {commentInputType === CommentInputType.OpenComment &&
        (commentOpen ? (
          <ResponseInputArea>
            <RichInput
              fileUpload={false}
              imageUpload={true}
              initialHeight={10}
              ref={commentContentRef}
              name="question_comment"
              placeholder={COMMENT_PLACE_HOLDER}
              e2e={props.isQuestionAnswerTypeAI ? 'textareaToAi' : 'textareaToUser'}
            />
            {contentError && <StyledErrorText>{contentError.message}</StyledErrorText>}
            <SubmitButton
              onClick={submitComment}
              border
              e2e={props.isQuestionAnswerTypeAI ? 'submitCommentToAi' : 'submitCommentToUser'}
            >
              返信する
            </SubmitButton>
          </ResponseInputArea>
        ) : (
          <ResponseButton
            onClick={handleComment}
            data-e2e={props.isQuestionAnswerTypeAI ? 'openResponseToAi' : 'openResponseToUser'}
          >
            返信する
          </ResponseButton>
        ))}
      {commentInputType === CommentInputType.DisableComment && (
        <ResponseButton $disable>返信する</ResponseButton>
      )}
      {commentInputType === CommentInputType.AICommentMessage && (
        <ResponseNotice>
          <ResponseNoticeText>質問者本人のみがAI先生に返信できます。</ResponseNoticeText>
        </ResponseNotice>
      )}
      {commentInputType === CommentInputType.WaitingToEscalationMessage && (
        <ResponseNotice>
          <ResponseNoticeText>
            AI先生にエラーが発生しました。最下部のフォームより講師からの回答を依頼してください。
          </ResponseNoticeText>
        </ResponseNotice>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:nth-of-type(n + 2) {
    margin-top: 2rem;
  }

  ${media.lessThan('medium')`
    margin: 0 -1rem;
    padding: 0 1rem;
    border: none;
  `}
`;
const ResponseButton = styled.div<{ $disable?: boolean }>`
  padding: 0.875rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: ${({ $disable }) => ($disable ? 'rgba(0, 0, 0, 0.3)' : 'rgba(253, 60, 47, 0.87)')};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
`;
const ResponseInputArea = styled.div`
  padding: 1rem 1rem 1rem 4.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    padding: 0 0 1rem;
    border-top: none;
  `}
`;
const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 240px;
  margin: 1rem auto 0;

  ${media.lessThan('small')`
    max-width: none;
  `}
`;

const StyledErrorText = styled(ErrorText)`
  margin-top: 0.25rem;
`;

const ResponseNotice = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const ResponseNoticeText = styled.div`
  display: inline-block;
  width: auto;
  color: rgba(229, 39, 48, 1);
  background: rgba(254, 236, 228, 1);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 12px 20px 12px 20px;

  &::before {
    content: '';
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.4375rem;
    background: center / contain no-repeat url(${ImgResponseAlert});
  }
`;
