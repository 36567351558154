import { setMilliseconds, setMinutes, setSeconds } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

export const setClearMinutes = (date: Date): Date => setMinutes(date, 0);

export const setClearSeconds = (date: Date): Date => setSeconds(date, 0);

export const setClearMilliseconds = (date: Date): Date => setMilliseconds(date, 0);

export const utcToJst = (date: Date | string | number): Date => toZonedTime(date, 'Asia/Tokyo');
export const jstToUtc = (date: Date | string | number): Date => fromZonedTime(date, 'Asia/Tokyo');
