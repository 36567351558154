import { useMemo } from 'react';
import { Session } from '../../gen/graphql';

// セッションの一覧を分類する
export const useCategorizedSessionList = <T extends Pick<Session, 'startAt'>>(
  sessions: T[],
): {
  featureSessions: T[];
  pastSessions: T[];
  nextSession?: T;
} => {
  return useMemo(() => {
    const now = new Date().getTime();
    const pastSessions: T[] = [];
    const featureSessions: T[] = [];

    // セッション一覧を、これまでの一覧と今後の予定一覧に分割
    sessions.forEach((session) => {
      if (new Date(session.startAt).getTime() < now) {
        pastSessions.push(session);
      } else {
        // sessionsは降順で並び替えられているが、今後の予定は昇順で表示するため逆順で追加する
        featureSessions.unshift(session);
      }
    });
    // 今後の予定で直近のものを次回セッションとして取り出す(今後の予定には表示しない)
    const nextSession = featureSessions.shift();

    return { pastSessions, featureSessions, nextSession };
  }, [sessions]);
};
