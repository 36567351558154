import React from 'react';
import styled from 'styled-components';

export const Chip: React.FC = (props) => {
  return <Span {...props}>{props.children}</Span>;
};

const Span = styled.span`
  display: inline-block;
  margin: 0.25rem 0.25rem 0 0;
  padding: 0.25rem 0.75rem;
  background: #f1b007;
  border-radius: 50px;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1.5;
`;
