import React from 'react';
import styled from 'styled-components';
import { FilterInputLabel } from '../atoms/FilterInputLabel';
import { FilterInputRadio } from '../atoms/FilterInputRadio';
import { RadioOption } from '../atoms/Radio';

interface FilterRadioProps {
  label: string;
  name: string;
  options: readonly RadioOption[];
  currentValue: string;
  handleSelect: (value: string) => void;
  column?: number;
}

export const FilterRadio: React.FC<FilterRadioProps> = (props) => {
  return (
    <RadioContainer>
      <FilterInputLabel label={props.label} />
      <FilterInputRadio
        name={props.name}
        options={props.options}
        currentValue={props.currentValue}
        handleSelect={props.handleSelect}
        column={props.column}
      />
    </RadioContainer>
  );
};

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
