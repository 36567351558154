import { ProviderSubscriptionStatus } from '../gen/graphql';

export const StripeStatusLabels: { [key: string]: string } = {
  [ProviderSubscriptionStatus.Active]: '有効',
  [ProviderSubscriptionStatus.Canceled]: 'キャンセル',
  [ProviderSubscriptionStatus.Incomplete]: '失敗',
  [ProviderSubscriptionStatus.IncompleteExpired]: '失敗(期限切れ)',
  [ProviderSubscriptionStatus.PastDue]: '延滞',
  [ProviderSubscriptionStatus.Trialing]: 'トライアル中',
  [ProviderSubscriptionStatus.Unpaid]: '未払い',
} as const;
