import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Modal } from '../molecules/Modal';
import { CourseProgramFragment } from '../../gen/graphql';
import { Markdown } from '../../common/Markdown';
interface CourseProgramOverviewModalProps {
  isOpen: boolean;
  toggle: (nextState: boolean) => void;
  loading?: boolean;
  courseProgram: CourseProgramFragment;
}

export const CourseProgramOverviewModal: React.FC<CourseProgramOverviewModalProps> = ({
  toggle,
  isOpen,
  courseProgram,
  ...props
}) => {
  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={() => toggle(false)}
      loading={props.loading}
      header={<Header>コースの概要</Header>}
      footer={
        <Close>
          <span onClick={() => toggle(false)}>コース教材に戻る</span>
        </Close>
      }
      width={'1100px'}
    >
      <Container>
        <Content>
          <ContentHeader>
            <h2>{courseProgram.title}</h2>
            <p className="description">{courseProgram.description}</p>
          </ContentHeader>
          <Inner>
            <Markdown content={courseProgram.overview} />
          </Inner>
        </Content>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 2rem;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ${media.lessThan('medium')`
    padding: 1.5rem 1rem;
  `}
`;

const Content = styled.div`
  max-width: 788px;
  margin: 0 auto;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 0;
  `}
`;

const Header = styled.h3`
  font-size: 1.125rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
`;

const ContentHeader = styled.section`
  padding: 28px 48px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  text-align: center;
  margin-bottom: 32px;

  h2 {
    font-size: 2.25rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);

    &:after {
      display: block;
      content: '';
      width: 40px;
      height: 4px;
      background-color: #e2001b;
      margin: 10px auto;
    }
  }

  .description {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.5;
  }

  ${media.lessThan('medium')`
    margin-bottom: 0;

    h2 {
      font-size: 1.5rem;
    }
  `}
`;

const Inner = styled.section`
  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`;

const Close = styled.p`
  display: flex;
  justify-content: flex-end;

  span {
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    line-height: 1.25rem;
    cursor: pointer;
  }
`;
