import React from 'react';
import styled from 'styled-components';
import { UserPageLayout } from '../../templates/UserPageLayout';
import { NotFoundPage } from '../public/NotFound';
import { useGetUserProfileQuery } from '../../../gen/graphql';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Loader } from '../../molecules/Loader';
import { UserProfileCard } from '../../organisms/UserProfileCard';
import { useParams } from 'react-router-dom';
import { LOWER_META_TITLE } from '../../../const/Service';

export const UserProfileContainer = (): JSX.Element => {
  const paramID = useParams<{ id: string }>().id;
  const pathUserID = Number(paramID);
  if (Number.isNaN(pathUserID)) return NotFoundPage;

  return <UserProfile userID={pathUserID} />;
};

interface UserProfileProps {
  userID: number;
}

const UserProfile = (props: UserProfileProps): JSX.Element => {
  const metaTitle = `プロフィール | ${LOWER_META_TITLE}`;

  const { data, loading } = useGetUserProfileQuery({
    variables: { id: props.userID },
  });

  // ユーザー情報取得前は画面レイアウトを表示できないためローディングだけを返す
  if (loading) {
    return <Loader display />;
  }

  if (!data || !data.user) {
    return NotFoundPage;
  }

  return (
    <UserPageLayout activeTab="profile" user={data.user} metaTitle={metaTitle}>
      <PageWrapper>
        <Container>
          <UserProfileCard user={data.user} />
        </Container>
      </PageWrapper>
    </UserPageLayout>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
