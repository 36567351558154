import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';

import VoiceImage001Icon from '../../../static/image/lp/voice001_icon.png';
import VoiceImage002Icon from '../../../static/image/lp/voice002_icon.png';
import VoiceImage003Icon from '../../../static/image/lp/voice003_icon.png';
import VoiceImage002Content from '../../../static/image/lp/voice002_content.png';
import VoiceImage003Content from '../../../static/image/lp/voice003_content.png';

import { SERVICE_NAME } from '../../../const/Service';

export const LpVoice = (): JSX.Element => {
  return (
    <Voice id="voice" data-gtm="voice">
      <Inner>
        <StyledTtl>
          <h2>利用者の声</h2>
          <p>User Voice</p>
        </StyledTtl>
        <Article>
          <div className="name">
            <Icon src={VoiceImage001Icon} alt="" />
            <p>岩戸 ユウスケ（32歳）</p>
          </div>
          <Text>
            教材の難易度がちょうどいい感じに難しくて、ちょうどいい感じにエラー出て挫折するんだけど、質問掲示板があるから、乗り越えられる。
            <br />
            エンジニアスキルを鍛える上で必要な「トライ＆エラー」を経験できる感覚がある。良い。
            <br />
            <br />
            <span>#侍エンジニア</span> <span>#{SERVICE_NAME}</span>
          </Text>
        </Article>
        <Article>
          <div className="name">
            <Icon src={VoiceImage002Icon} alt="" />
            <p>藤平 裕希（24歳）</p>
          </div>
          <Text>
            学習ログなるもので、学習の遍歴をログることができるんですが、受験の時にやっていたスタディプラスに近い感覚で、それ自体が楽しいってなりました。
            <br />
            経験値増やしている感じがRPGみたいな感覚で、習慣になっています。
            <br />
            <br />
            <span>#侍エンジニア</span> <span>#{SERVICE_NAME}</span>
            <img src={VoiceImage002Content} alt="" />
          </Text>
        </Article>
        <Article>
          <div className="name">
            <Icon src={VoiceImage003Icon} alt="" />
            <p>ayana（非公開）</p>
          </div>
          <Text>
            質問掲示板、教材をやりながらエラーが出たところを質問できて、しかも30分くらいで返答があるのが便利です。
            <br />
            <br />
            テラテイルとかスタックオーバーフローとかってどこか質問しづらい雰囲気あるけど、駆け出しエンジニアがたくさんいるから恥ずかしくないのも、私みたいなプログラミング初心者には地味にありがたい。
            <span>#侍エンジニア</span> <span>#{SERVICE_NAME}</span>
            <img src={VoiceImage003Content} alt="" />
          </Text>
        </Article>
      </Inner>
    </Voice>
  );
};

const Voice = styled.section`
  padding: 6.25rem 0;
  scroll-margin-top: 49px;

  ${media.lessThan('medium')`
    padding: 4rem 0;
    scroll-margin-top: 57px;
  `}
`;
const StyledTtl = styled(Ttl)`
  margin-bottom: 3rem;

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
  `}
`;
const Article = styled.div`
  max-width: 616px;
  margin: 1.5rem auto 0;
  padding: 1rem 2rem 1.5rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 4px 16px rgba(18, 18, 18, 0.1);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;

  .name {
    display: flex;
    align-items: center;

    p {
      font-weight: 700;
    }
  }

  ${media.lessThan('medium')`
    margin-top: 1rem;
    padding: 0.75rem 1rem 1rem;
    border-radius: 0.75rem;
  `}
`;
const Icon = styled.img`
  width: 3.25rem;
  margin-right: 0.75rem;

  ${media.lessThan('medium')`
    margin-right: 0.5rem;
  `}
`;
const Text = styled.p`
  padding-left: 4rem;
  line-height: 1.5;

  span {
    color: #1fa1f2;
  }

  img {
    display: block;
    width: 100%;
    margin-top: 1rem;
  }

  ${media.lessThan('medium')`
    padding-left: 3.75rem;
  `}
`;
