import React, { useCallback, useEffect, useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { addMinutes, endOfDay } from 'date-fns';
import { isNonEmpty } from 'fp-ts/es6/Array';
import { Modal } from '../molecules/Modal';
import { InputWithSearchForULID, Option } from '../molecules/InputWithSearchForULID';
import { Button } from '../atoms/Button';
import {
  useGetActiveCoursesForInstructorQuery,
  CourseSearchType,
  InstructorCourseSearchInput,
  SessionInput as SessionInputForGQL,
} from '../../gen/graphql';
import { SESSION_DEFAULT_MINUTES } from '../../const/Session';

// 日付と時刻のDateを連結して1つのDateにする(画面UI上別々で管理しているものを結合するため)
const concatDateWithTime = (date: Date, time: Date): Date =>
  new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    0,
  );

export interface SessionInput extends SessionInputForGQL {
  coachingID?: string;
}

interface Errors {
  startAt?: string;
  endAt?: string;
  coachingID?: string;
  title?: string;
}

interface Props {
  isOpen: boolean;
  type: 'add' | 'edit';
  defaultInput: Partial<SessionInput>;
  loading?: boolean;
  onSubmit: (input: SessionInput) => void;
  onClose: () => void;
}

export const SessionInputModal: React.FC<Props> = ({
  isOpen,
  type,
  defaultInput,
  onSubmit,
  onClose,
  ...props
}): JSX.Element => {
  const [coachingID, setCoachingID] = useState<string>();
  const [title, setTitle] = useState('');
  const [startAt, setStartAt] = useState(new Date());
  const [endAt, setEndAt] = useState(new Date());
  const [errors, setErrors] = useState<Errors>({});

  const { data: courses, refetch } = useGetActiveCoursesForInstructorQuery({
    variables: {
      input: {
        type: CourseSearchType.Coaching,
      },
    },
    skip: type === 'edit',
  });

  // 開始日時のバリデーション
  const validateStartAt = useCallback(
    (startAt: Date): string | undefined => {
      const coursesData = courses?.activeCoursesForInstructor ?? [];
      const course = coursesData.find((c) => c.coaching?.id === coachingID);
      if (!course?.endDate) {
        return undefined;
      }

      if (startAt.getTime() >= endOfDay(new Date(course.endDate)).getTime()) {
        return '卒業日以降にセッションは登録できません';
      }
      return undefined;
    },
    [coachingID, courses],
  );

  // 終了日時のバリデーション
  const validateEndAt = useCallback(
    (endAt: Date): string | undefined => {
      if (endAt.getTime() <= startAt.getTime()) {
        return 'セッション終了日時は、開始日時より後を指定してください';
      }
      return undefined;
    },
    [startAt],
  );

  // 受講生のバリデーション
  const validateCoachingID = useCallback(
    (coachingID: string): string | undefined => {
      const coursesData = courses?.activeCoursesForInstructor ?? [];
      const course = coursesData.find((c) => c.coaching?.id === coachingID);
      if (!course) {
        return '受講生を選択してください';
      }

      const finishedSessionCount = course.coaching?.finishedSessionCount ?? 0;
      const plannedSessionCount = course.coaching?.plannedSessionCount ?? 0;
      if (finishedSessionCount >= plannedSessionCount) {
        return '規定のセッション回数以上セッションを登録できません';
      }

      return undefined;
    },
    [courses],
  );

  // タイトルのバリデーション
  const validateTitle = useCallback((title: string): string | undefined => {
    if (title.trim().length === 0) {
      return 'タイトルを入力してください';
    }
    if (title.trim().length > 255) {
      return 'タイトルは255文字以内で入力してください';
    }
    return undefined;
  }, []);

  // 全項目バリデーション
  const validateAll = useCallback((): Errors => {
    const errors: Errors = {
      startAt: validateStartAt(startAt),
      endAt: validateEndAt(endAt),
      title: validateTitle(title),
    };
    // 新規登録時はcoachingID(受講生)をチェック
    if (type === 'add') {
      errors.coachingID = validateCoachingID(coachingID ?? '');
    }
    return errors;
  }, [
    coachingID,
    endAt,
    startAt,
    title,
    type,
    validateCoachingID,
    validateEndAt,
    validateStartAt,
    validateTitle,
  ]);

  const changeStartAt = useCallback(
    (startAt: Date): void => {
      setStartAt(startAt);
      setEndAt(addMinutes(startAt, SESSION_DEFAULT_MINUTES));
      setErrors({ ...errors, startAt: validateStartAt(startAt), endAt: undefined });
    },
    [errors, validateStartAt],
  );

  const changeEndAt = useCallback(
    (endAt: Date): void => {
      setEndAt(endAt);
      setErrors({ ...errors, endAt: validateEndAt(endAt) });
    },
    [errors, validateEndAt],
  );

  // 開始日時(日)変更時のイベントハンドラ
  const handleChangeStartAtDate = useCallback(
    (date: Date | null): void => {
      if (date) {
        changeStartAt(concatDateWithTime(date, startAt));
      }
    },
    [changeStartAt, startAt],
  );

  // 開始日時(時)変更時のイベントハンドラ
  const handleChangeStartAtTime = useCallback(
    (time: Date | null): void => {
      if (time) {
        changeStartAt(concatDateWithTime(startAt, time));
      }
    },
    [changeStartAt, startAt],
  );

  // 終了日時(日)変更時のイベントハンドラ
  const handleChangeEndAtDate = useCallback(
    (date: Date | null): void => {
      if (date) {
        changeEndAt(concatDateWithTime(date, endAt));
      }
    },
    [changeEndAt, endAt],
  );

  // 終了日時(時)変更時のイベントハンドラ
  const handleChangeEndAtTime = useCallback(
    (time: Date | null): void => {
      if (time) {
        changeEndAt(concatDateWithTime(endAt, time));
      }
    },
    [changeEndAt, endAt],
  );

  // 受講生変更時のイベントハンドラ
  const handleChangeCoachingID = useCallback(
    (id: string): void => {
      setCoachingID(id);
      setErrors({ ...errors, coachingID: validateCoachingID(id) });
    },
    [errors, validateCoachingID],
  );

  // タイトル変更時のイベントハンドラ
  const handleChangeTitle = useCallback(
    (e: { target: { value: string } }): void => {
      const title = e.target.value;
      setTitle(title);
      setErrors({ ...errors, title: validateTitle(title) });
    },
    [errors, validateTitle],
  );

  // 送信(登録/保存)ボタン押下時のイベントハンドラ
  const handleClickSubmit = useCallback((): void => {
    const errors = validateAll();
    setErrors(errors);
    if (isNonEmpty(Object.values(errors).filter((error) => !!error))) {
      return;
    }

    onSubmit({
      coachingID,
      title: title.trim(),
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
    });
  }, [onSubmit, coachingID, title, startAt, endAt, validateAll]);

  // コーチング検索Option
  const coachingFilterOptions = useMemo((): Option[] => {
    const coursesData = courses?.activeCoursesForInstructor ?? [];

    return coursesData
      .filter(({ student, coaching }) => student.user.maskedPersonalInfo && coaching?.id)
      .map(({ plan, student, coaching }) => ({
        id: coaching?.id ?? '',
        name: `${student.user.maskedPersonalInfo?.name ?? ''}: ${plan.name}`,
      }));
  }, [courses?.activeCoursesForInstructor]);

  // 選択中のコーチング
  const selectedCoaching = useMemo(
    (): Option | null => coachingFilterOptions.find((o) => o.id === coachingID) ?? null,
    [coachingFilterOptions, coachingID],
  );

  // コーチングの再検索
  const refetchCoachings = useCallback(
    async (params: InstructorCourseSearchInput): Promise<void> => {
      try {
        await refetch({ input: params });
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      }
    },
    [refetch],
  );

  // コーチングを取得
  const fetchCoachings = useCallback(
    async (query: string): Promise<void> => {
      const params = {
        type: CourseSearchType.Coaching,
        studentName: query,
      };
      await refetchCoachings(params);
    },
    [refetchCoachings],
  );

  useEffect(() => {
    // 開いた時にフォームの初期値をセットし直す
    if (isOpen) {
      const currentTime = new Date().getTime();

      setCoachingID(defaultInput.coachingID);
      setTitle(defaultInput.title ?? '');
      setStartAt(new Date(defaultInput.startAt ?? currentTime));
      setEndAt(new Date(defaultInput.endAt ?? currentTime));
      setErrors({});
    }
  }, [
    isOpen,
    defaultInput.coachingID,
    defaultInput.title,
    defaultInput.startAt,
    defaultInput.endAt,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      loading={props.loading}
      width={'60vw'}
      header={`セッション日程を${type === 'add' ? '登録' : '編集'}`}
      footer={
        <Buttons>
          <SubmitButton onClick={handleClickSubmit}>
            {type === 'add' ? '登録' : '保存'}
          </SubmitButton>
        </Buttons>
      }
    >
      <ModalContainer>
        <ModalContent>
          <SessionDateForm>
            <Label>開始日時</Label>
            <div className="session-date-input">
              <DatePicker
                selected={startAt}
                onChange={handleChangeStartAtDate}
                dateFormat="yyyy年MM月dd日"
                disabledKeyboardNavigation
                placeholderText="日程を選択"
                className="input datepicker-date"
              />
              <DatePicker
                selected={startAt}
                onChange={handleChangeStartAtTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="開始時間"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                disabledKeyboardNavigation
                placeholderText="00:00"
                className="input datepicker-start-time"
              />
            </div>
            {errors.startAt && <ErrorText>{errors.startAt}</ErrorText>}
          </SessionDateForm>
          <SessionDateForm>
            <Label>終了日時</Label>
            <div className="session-date-input">
              <DatePicker
                selected={endAt}
                onChange={handleChangeEndAtDate}
                dateFormat="yyyy年MM月dd日"
                disabledKeyboardNavigation
                placeholderText="日程を選択"
                className="input datepicker-date"
              />
              <DatePicker
                selected={endAt}
                onChange={handleChangeEndAtTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="終了時間"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                disabledKeyboardNavigation
                placeholderText="00:00"
                className="input datepocker-end-time"
              />
            </div>
            {errors.endAt && <ErrorText>{errors.endAt}</ErrorText>}
          </SessionDateForm>
          {type === 'add' && (
            <SessionForm>
              <Label>受講生</Label>
              <SelectDiv>
                <InputWithSearchForULID
                  label=""
                  hasLabel={false}
                  options={coachingFilterOptions}
                  handleInput={fetchCoachings}
                  value={selectedCoaching}
                  deletable={true}
                  onSelect={(id) => {
                    if (typeof id === 'number') {
                      return;
                    }
                    handleChangeCoachingID(id ?? '');
                  }}
                />
              </SelectDiv>
              {errors.coachingID && <ErrorText>{errors.coachingID}</ErrorText>}
            </SessionForm>
          )}
          <SessionForm>
            <Label>タイトル</Label>
            <SessionTitle
              value={title}
              onChange={handleChangeTitle}
              placeholder="セッションの概要が具体的にわかるタイトルを記入してください"
            />
            {errors.title && <ErrorText>{errors.title}</ErrorText>}
          </SessionForm>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  padding: 2rem 2rem 8rem;

  input {
    font-size: 1rem;
    padding: 0;
    border-radius: 0;
    outline: none;
    background: none;
  }

  input,
  button {
    margin: 0;
    padding: 0;
    background: none;
    border-radius: 0;
    outline: none;
    appearance: none;
  }
`;
const ModalContent = styled.div`
  margin: 0 auto;
`;
const SelectDiv = styled.div`
  width: 50%;

  input {
    display: block;
    height: 3.125rem;
    margin: 0.5rem 1rem;
    padding: 0px 1.5rem;
    font-size: 0.9375rem;
    line-height: 1.75;
    background-color: #f2f2f2;
    background-image: none;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    word-break: normal;
  }
`;
const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0.5rem 0;
`;
const SessionDateForm = styled.div`
  margin: 0.625rem 1.5rem 0 0;

  .session-date-input {
    display: flex;
  }
  .react-datepicker-wrapper {
    width: auto;

    .input {
      border: 1px solid lightgray;
    }
  }
`;
const SessionForm = styled.div`
  margin-top: 2rem;
`;
const SessionTitle = styled.input`
  display: block;
  font-size: 0.9375rem;
  width: 99%;
  height: 2.5rem;
  border: 1px solid lightgray;
  background-color: white;
  margin: 0 auto;
`;

const Buttons = styled.div`
  text-align: right;
`;

const SubmitButton = styled(Button)`
  bottom: 2rem;
  margin: 0 0.1875rem;
  padding: 0.5rem 0;
  width: 8.125rem;
  font-size: 0.9375;
  font-weight: 700;
`;

const ErrorText = styled.p`
  margin-top: 0.25rem;
  color: #e2001b;
  font-size: 0.75rem;
`;
