import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { format } from 'date-fns';
import { SubscriptionLayout } from '../../templates/SubscriptionLayout';
import { Loader } from '../../molecules/Loader';
import { useToastsContext } from '../../../context/ToastsProvider';
import { FormButton } from '../../atoms/FormButton';
import { Spacer } from '../../atoms/Spacer';
import {
  PaymentMethodType,
  TeamSubscribeFormInput,
  useGetTeamPaymentMethodsQuery,
  useGetTeamPricesQuery,
  useGetTeamWithSubscriptionQuery,
  useTeamSubscribeMutation,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useUser } from '../../../redux/user/useUser';
import { teamReSubscriptionSchema } from '../../../common/formSchema/teamSubscription';
import { Button } from '../../atoms/Button';
import { Input } from '../../atoms/Input';
import { ForbiddenPage } from '../public/Forbidden';
import { TeamDefaultPaymentAddModal } from '../../organisms/TeamDefaultPaymentAddModal';
import { Select } from '../../atoms/Select';
import { CustomDatePicker } from '../../atoms/CustomDatePicker';
import { MAXIMUM_NUMBER_OF_USERS, MINIMUM_NUMBER_OF_USERS } from '../../../const/team';
import { Payment } from '../../../const/Payment';
import { getApiErrorMessage } from '../../../utils/graphqlError';
import { LOWER_META_TITLE } from '../../../const/Service';
import { CANCELLATION_FAQ_LINK } from '../../../const/Link';

export const TeamSubscriptionPayment = (): JSX.Element => {
  const metaTitle = `お支払い内容確認・変更 | ${LOWER_META_TITLE}`;

  const navigate = useNavigate();
  const { user } = useUser();
  const { showToast } = useToastsContext();

  const [showLoader, setShowLoader] = useState(false);
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
  const [teamMemberCount, setTeamMemberCount] = useState(MINIMUM_NUMBER_OF_USERS);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, dirtyFields, isValid },
  } = useForm<TeamSubscribeFormInput>({
    resolver: yupResolver(teamReSubscriptionSchema({ currentQuantity: teamMemberCount })),
    defaultValues: {
      teamID: user.teamID ?? '',
      paymentMethodType: PaymentMethodType.CreditCard,
      timezoneOffset: new Date().getTimezoneOffset(),
    },
    mode: 'all',
  });

  const quantity = watch('quantity');
  const paymentMethodType = watch('paymentMethodType');
  const priceID = watch('priceID');

  const { data: teamData, loading: teamLoading } = useGetTeamWithSubscriptionQuery({
    variables: {
      id: user.teamID ?? '',
    },
    skip: !user.teamID,
    onCompleted: ({ team }) => {
      if (team.paymentMethodType) {
        setValue('paymentMethodType', team.paymentMethodType);
      }

      if (team.teamMembers) {
        setTeamMemberCount(team.teamMembers.length);
      }
    },
  });

  const {
    data: paymentMethodsData,
    loading: paymentMethodsLoading,
    refetch: refetchPaymentMethods,
  } = useGetTeamPaymentMethodsQuery({
    variables: {
      teamID: user.teamID ?? '',
      // NOTE
      // クレジットカードの登録はToC側はフロント、サーバー共に登録枚数5枚の制限が入っており、
      // ToB側はStripeのAPIを直接呼んでいる関係で登録枚数制限は入っていない。
      // フロントは共通のコンポーネントを使いたいので、いずれも登録枚数5枚の制限が入っているが、バックエンドのバリデーションはToBとToCで一致しない形になっている。
      limit: Payment.MAX_ITEM_NUMBER,
    },
    skip: !user.teamID,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ teamPaymentMethods }) => {
      const defaultPaymentMethod = teamPaymentMethods.items.find((p) => p.isDefault);
      if (defaultPaymentMethod) {
        setValue('providerPaymentMethodID', defaultPaymentMethod.id);
      }
    },
  });

  const paymentMethods = useMemo(
    () => paymentMethodsData?.teamPaymentMethods.items ?? [],
    [paymentMethodsData],
  );
  const defaultPaymentMethod = useMemo(
    () => paymentMethods.find((p) => p.isDefault),
    [paymentMethods],
  );

  const { data: pricesData } = useGetTeamPricesQuery({
    variables: {
      paymentMethodType: paymentMethodType,
    },
    fetchPolicy: 'cache-first',
    onCompleted: ({ teamPrices }) => {
      // paymentMethodに連動してPriceも変わる
      setValue('priceID', teamPrices[0]?.id);
      // 銀行振込用の日付を初期化
      setValue('billingCycleAnchor', null);
    },
  });

  const prices = useMemo(() => pricesData?.teamPrices ?? [], [pricesData]);
  const price = useMemo(() => prices.find((p) => p.id === priceID), [prices, priceID]);

  const [subscribe, { loading: sending }] = useTeamSubscribeMutation();

  const submitSubscription = useSafeAsyncCallback(
    async (input: TeamSubscribeFormInput): Promise<void> => {
      try {
        await subscribe({ variables: { input } });
      } catch (e) {
        showToast(1, getApiErrorMessage(e));
        return;
      }

      // NOTE: WebhookUpdateのため即時更新ができないため、更新に時間がかかる旨表示
      showToast(0, '利用再開の手続きが完了しました。反映までいましばらくお待ちください。');
      navigate('/team/subscription');
    },
  );

  const handleChangeDefaultPayment = useCallback(
    async (paymentMethodID: string): Promise<void> => {
      setValue('providerPaymentMethodID', paymentMethodID);
      await refetchPaymentMethods();
    },
    [setValue, refetchPaymentMethods],
  );

  // 既に契約済みの場合は画面表示不可
  if (teamData?.teamSubscription) {
    return ForbiddenPage;
  }

  return (
    <>
      <Loader display={showLoader || teamLoading || paymentMethodsLoading || sending} />
      <SubscriptionLayout metaTitle={metaTitle}>
        <Wrapper>
          <Container>
            <Title>お支払い内容確認・変更</Title>
            <PaymentMethodInputArea>
              <PaymentMethodTypeLabel>お支払い方法</PaymentMethodTypeLabel>
              <Spacer height="0.4375rem" />
              <Controller
                name="paymentMethodType"
                control={control}
                render={({ field }) => (
                  <>
                    <FormControl component="fieldset">
                      <RadioGroup value={field.value} onChange={field.onChange}>
                        <FormControlLabel
                          value={PaymentMethodType.CreditCard}
                          control={<Radio />}
                          label="クレジットカード"
                        />
                        <FormControlLabel
                          value={PaymentMethodType.Bank}
                          control={<Radio />}
                          label="銀行振込 (請求書払い)"
                        />
                      </RadioGroup>
                    </FormControl>
                  </>
                )}
              />
              {paymentMethodType === PaymentMethodType.CreditCard && (
                <>
                  {defaultPaymentMethod ? (
                    <PaymentMethodTable>
                      <dt>
                        カード番号下4桁
                        <PaymentMethodButton onClick={() => setPaymentModalIsOpen(true)}>
                          変更
                        </PaymentMethodButton>
                      </dt>
                      <dd>{defaultPaymentMethod.cardNumber}</dd>
                      <dt>有効期限</dt>
                      <dd>
                        {defaultPaymentMethod.expireMonth}/{defaultPaymentMethod.expireYear}
                      </dd>
                      <dt>セキュリティコード</dt>
                      <dd>***</dd>
                    </PaymentMethodTable>
                  ) : (
                    <>
                      <PaymentMethodEmptyMessage>
                        ご登録されているクレジットカード情報がありません
                      </PaymentMethodEmptyMessage>
                      <Spacer height="1rem" />
                      <PaymentMethodButton onClick={() => setPaymentModalIsOpen(true)}>
                        追加
                      </PaymentMethodButton>
                    </>
                  )}
                  <PaymentMethodNoteArea>
                    <p>
                      ※未払いの請求情報が存在する場合、有効なクレジットカードを指定すると自動で再決済が行われます。
                    </p>
                  </PaymentMethodNoteArea>
                </>
              )}
              {paymentMethodType === PaymentMethodType.Bank && (
                <>
                  <Spacer height="0.4375rem" />
                  <FormRow>
                    <Label>
                      <span className="required">必須</span>
                      <label>利用期間</label>
                    </Label>
                    <Controller
                      name="priceID"
                      control={control}
                      render={({ field }) => (
                        <>
                          <RecurringIntervalSelect
                            name="priceID"
                            value={String(field.value)}
                            onChange={(e) => setValue('priceID', parseInt(e.target.value))}
                            options={prices.map((p) => ({
                              name: String(p.recurringIntervalCount),
                              value: String(p.id),
                            }))}
                          />
                          <RecurringIntervalUnit>ヶ月</RecurringIntervalUnit>
                        </>
                      )}
                    />
                  </FormRow>
                  <FormRow>
                    <Label>
                      <span className="required">必須</span>
                      <label>利用開始日</label>
                    </Label>
                    <Controller
                      name="billingCycleAnchor"
                      control={control}
                      render={({ field }) => (
                        <>
                          <StyledDatePicker
                            selected={field.value ? new Date(field.value) : null}
                            onChange={(date) =>
                              setValue('billingCycleAnchor', date?.toISOString(), {
                                shouldValidate: true,
                              })
                            }
                            onBlur={() => trigger('billingCycleAnchor')}
                            minDate={new Date()}
                            dateFormat="yyyy年MM月dd日"
                            disabledKeyboardNavigation
                            placeholderText="開始日を選択"
                          />
                          <ErrorText>{errors.billingCycleAnchor?.message}</ErrorText>
                        </>
                      )}
                    />
                  </FormRow>
                </>
              )}
              <FormRow>
                <Label>
                  <span className="required">必須</span>
                  <label>利用人数</label>
                </Label>
                <FormLabelNote>
                  {MAXIMUM_NUMBER_OF_USERS}人を超える場合は
                  <a href="https://lp.sejuku.net/terakoya/biz/#form_contact" target="_blank">
                    こちら
                  </a>
                  からお問い合わせ下さい。
                </FormLabelNote>
                <Controller
                  name="quantity"
                  control={control}
                  render={({ field }) => (
                    <>
                      <QuantityInput
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        type="text"
                        width="6.875rem"
                        onBlur={field.onBlur}
                      />
                      <QuantityUnit>人</QuantityUnit>
                      <ErrorText>{errors.quantity?.message}</ErrorText>
                    </>
                  )}
                />
              </FormRow>
            </PaymentMethodInputArea>
            <PriceInfo>
              <PriceTitle>請求金額</PriceTitle>
              {paymentMethodType === PaymentMethodType.CreditCard && (
                <PriceLabel>月々の支払い額</PriceLabel>
              )}
              <Price>
                ¥
                {price && dirtyFields.quantity && !errors.quantity
                  ? (price.unitAmount * quantity).toLocaleString()
                  : '-'}
                <span>(税込){paymentMethodType === PaymentMethodType.CreditCard && ' / 月'}</span>
              </Price>
            </PriceInfo>
            <LegalInfo>
              <p>
                ※「特定商取引法に基づく表記」は
                <a href="https://www.sejuku.net/corp/legal" target="_blank">
                  こちら
                </a>
                をご覧ください。
              </p>
              <p>
                ※ 解約方法については
                <a href={CANCELLATION_FAQ_LINK} target="_blank">
                  こちら
                </a>
                をご覧ください。
              </p>
              {paymentMethodType === PaymentMethodType.CreditCard && (
                <p>
                  ※ サブスクリプションは{format(new Date(), 'yyyy/M/d')}
                  に開始され1ヶ月後に自動で更新されます。
                </p>
              )}
              {paymentMethodType === PaymentMethodType.Bank && (
                <p>
                  ※
                  サブスクリプションは利用開始日を起点に開始され、解約申請がない場合、契約は自動更新されます。
                </p>
              )}
            </LegalInfo>
            <ButtonWrapper>
              <CancelButton to="/team/subscription">取り消す</CancelButton>
              <SubmitButton
                onClick={handleSubmit(submitSubscription)}
                isActive={isValid}
                disabled={!isValid}
              >
                利用再開を行う
              </SubmitButton>
            </ButtonWrapper>
          </Container>
        </Wrapper>
      </SubscriptionLayout>
      <TeamDefaultPaymentAddModal
        teamID={user.teamID ?? ''}
        isOpen={paymentModalIsOpen}
        toggle={setPaymentModalIsOpen}
        onCompleted={handleChangeDefaultPayment}
        cards={paymentMethods}
        currentDefaultPaymentId={defaultPaymentMethod?.id ?? ''}
        setLoading={setShowLoader}
      />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  background-color: #fcfcfc;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 2rem 0;
  `}
`;

const Container = styled.div`
  padding: 2rem 2.5rem;
  width: 100%;
  max-width: 50rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 2px;

  ${media.lessThan('medium')`
    padding: 2rem;
  `}
`;

const Title = styled.h2`
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 700;
`;

const PaymentMethodInputArea = styled.div`
  padding: 1.5rem 1rem 2rem;

  ${media.lessThan('medium')`
    padding: 2rem 0;
  `}
`;

const PaymentMethodTypeLabel = styled.p`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;

  ${media.lessThan('medium')`
    font-size: 1.25rem;
    line-height: 1.35;
  `}
`;

const PaymentMethodTable = styled.dl`
  margin-top: -1rem;

  dt {
    margin: 2rem auto 1rem;
    font-size: 1rem;
    font-weight: 700;
  }

  dd {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

const PaymentMethodNoteArea = styled.div`
  margin: 1rem auto 2rem;
  p {
    font-size: 0.75rem;
    line-height: 1.7;
  }
`;

const PaymentMethodEmptyMessage = styled.p`
  margin-top: 1rem;
  font-size: 0.875rem;

  ${media.lessThan('medium')`
    margin-left: 0;
  `}
`;

const PaymentMethodButton = styled(Button)`
  padding: 0 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.1;
  background-color: #fff;
  color: #eb0000;
`;

const FormRow = styled.div`
  & + & {
    margin-top: 2rem;
  }

  & > * + * {
    margin-top: 1rem;
  }

  ${media.lessThan('medium')`
    & > * + * {
      margin-top: 0.75rem;
    }
  `}
`;

const Label = styled.div`
  display: flex;
  align-items: center;

  label {
    display: flex;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 700;
  }

  .required {
    display: block;
    margin-right: 0.5rem;
    padding: 0.125rem 0.5rem;
    background-color: #fd6258;
    color: #fff;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.2;
  }

  ${media.lessThan('medium')`
    flex-wrap: wrap;
  `}
`;

const FormLabelNote = styled.p`
  margin: 0.125rem auto -0.3125rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3;

  a {
    font-size: inherit;
    color: #e2001b;
  }

  ${media.lessThan('medium')`
    margin: 0.75rem auto 0.25rem;
  `}
`;

const RecurringIntervalSelect = styled(Select)`
  width: 9.375rem;
  padding: 0.75rem;
  background: #fff top 0.75rem right 0.75rem / auto no-repeat
    url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2217%22%20height%3D%2216%22%20viewBox%3D%220%200%2017%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.49707%2013L3.30092%204H13.6932L8.49707%2013Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E');
  font-size: 1rem;
  appearance: none;
  outline: none;

  ${media.lessThan('medium')`
    padding: 0.625rem 0.75rem;
    width: 5.75rem;
  `}
`;

const RecurringIntervalUnit = styled.span`
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.35;
  color: rgba(0, 0, 0, 0.6);

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;

const StyledDatePicker = styled(CustomDatePicker)`
  padding: 0.75rem;
  width: 11.625rem;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.36);
  outline: none;

  ${media.lessThan('medium')`
    padding: 0.625rem 1rem;
    width: 10rem;
  `}
`;

const QuantityInput = styled(Input)`
  display: inline-block;
  height: 2.5rem;
  border-color: rgba(0, 0, 0, 0.36);
  border-radius: 0;

  input {
    padding: 0.625rem 1rem;
    outline: none;

    &:autofill {
      box-shadow: 0 0 0 1000px #fff inset;
    }
  }

  ${media.lessThan('medium')`
    width: 6.125rem;
  `}
`;

const QuantityUnit = styled.span`
  margin-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.36;
  color: rgba(0, 0, 0, 0.6);
`;

const ErrorText = styled.p`
  margin: 0.25rem auto 0;
  color: #eb0000;
  align-self: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

const PriceInfo = styled.div`
  padding: 2rem 1rem 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    padding: 2rem 0;
  `}
`;

const PriceTitle = styled.h3`
  font-size: 1.125rem;
  line-height: 1.1;
  font-weight: 700;

  ${media.lessThan('medium')`
    font-size: 1.25rem;
    line-height: 1;
  `}
`;

const PriceLabel = styled.h4`
  margin-top: 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.6);

  &::before {
    content: '■';
    margin-right: 0.25rem;
  }

  ${media.lessThan('medium')`
    margin-top: 2rem;
  `}
`;

const Price = styled.p`
  margin-top: 0.8125rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;

  span {
    font-size: 0.875rem;
  }

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

const LegalInfo = styled.div`
  p {
    font-size: 0.75rem;
    line-height: 1.7;
    color: rgba(0, 0, 0, 0.87);

    a {
      font-size: inherit;
      color: #e2001b;
    }

    ${media.lessThan('medium')`
      line-height: 1.3;
    `}
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
  `}
`;

const CancelButton = styled(Link)`
  padding: 0 4.1875rem;
  font-size: 0.875rem;
  line-height: 1.4;
  text-align: center;
  cursor: pointer;

  ${media.lessThan('medium')`
    padding: 1.625rem 0 0.4375rem;
  `}
`;

const SubmitButton = styled(FormButton)`
  width: 14.5rem;
  height: 3rem;
  font-size: 1rem;
  line-height: 1.4;
  border-radius: 0.1875rem;
`;
