import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import CloseIcon from '../../static/image/close.svg';
import { Overlay } from '../atoms/Overlay';
import { Z_INDEX_MODAL } from '../../const/ZIndex';

const useModal = (contentRef: React.RefObject<HTMLElement | null>, callback: () => void): void => {
  const handleClick = (e: Event) => {
    if (contentRef.current && contentRef.current.contains(e.target as Node)) return;

    callback();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  width?: string;
  tbWidth?: string;
  padding?: string;
  underlayer?: boolean;
  spFullScreen?: boolean;
  hideHeaderClose?: boolean;
  loading?: boolean;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [windowHeight, setWindowHeight] = useState('');

  useModal(contentRef, () => {
    if (props.isOpen && !props.loading) props.onClose();
  });

  useEffect(() => {
    if (props.spFullScreen) {
      setWindowHeight(window.innerHeight + 'px');
      window.addEventListener('resize', () => setWindowHeight(window.innerHeight + 'px'));
    }
  }, [props.spFullScreen]);

  return (
    <Container isOpen={props.isOpen} underlayer={props.underlayer}>
      <Overlay>
        <Content
          ref={contentRef}
          width={props.width}
          tbWidth={props.tbWidth}
          spFullScreen={props.spFullScreen}
          spHeight={windowHeight}
          hasHeader={!!props.header}
          hasFooter={!!props.footer}
        >
          {props.header && (
            <Header>
              {props.header}
              {!props.hideHeaderClose && (
                <Close onClick={props.onClose}>
                  <img src={CloseIcon} />
                </Close>
              )}
            </Header>
          )}
          <ChildrenWrapper padding={props.padding} spFullScreen={props.spFullScreen}>
            {props.children}
          </ChildrenWrapper>
          {props.footer && <Footer>{props.footer}</Footer>}
        </Content>
      </Overlay>
    </Container>
  );
};

const Container = styled.div<{ isOpen: boolean; underlayer?: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  z-index: ${Z_INDEX_MODAL};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Content = styled.div<{
  width?: string;
  tbWidth?: string;
  spFullScreen?: boolean;
  spHeight?: string;
  hasHeader: boolean;
  hasFooter: boolean;
}>`
  position: relative;
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;

  ${(props) =>
    props.tbWidth
      ? media.lessThan('medium')`
      width: ${props.tbWidth};
      max-width: 100%;
    `
      : media.lessThan('medium')`
      width: 100%;
    `}

  ${media.lessThan('small')`
    display: grid;
    grid-template-rows: auto 1fr auto;
    width: 100%;
  `}
  ${(props) =>
    props.hasHeader &&
    props.hasFooter &&
    media.lessThan('small')`
      grid-template-rows: auto 1fr auto;
    `}
  ${(props) =>
    props.hasHeader &&
    !props.hasFooter &&
    media.lessThan('small')`
      grid-template-rows: auto 1fr;
    `}
  ${(props) =>
    !props.hasHeader &&
    props.hasFooter &&
    media.lessThan('small')`
      grid-template-rows: 1fr auto;
    `}
  ${(props) =>
    !props.hasHeader &&
    !props.hasFooter &&
    media.lessThan('small')`
      grid-template-rows: 1fr;
    `}
  
  ${(props) =>
    props.spFullScreen && props.spHeight
      ? media.lessThan('small')`
      height: ${props.spHeight};
      border-radius: 0;
    `
      : ''}
`;

const ChildrenWrapper = styled.div<{ padding?: string; spFullScreen?: boolean }>`
  position: relative;
  max-width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
  ${media.lessThan('medium')`
    padding: 0;
  `}
  ${(props) =>
    props.spFullScreen
      ? media.lessThan('small')`
      overflow-y: auto;
    `
      : ''}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.4rem;
  box-sizing: border-box;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Close = styled.div`
  cursor: pointer;
`;

const Footer = styled.div`
  padding: 1rem 1.4rem;
  box-sizing: border-box;
  background-color: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
`;
