import React, { useState } from 'react';
import styled from 'styled-components';

import { PracticeSubmitFilter } from './PracticeSubmitFilter';
import { Button } from '../atoms/Button';

import { PracticeSearchFragment, ProgramSearchFragment } from '../../gen/graphql';
import { setParamsValue, useURL } from '../../common/customHooks/URL';
import { getPractices } from '../../utils/Program';
import { INITIAL } from '../../const/Filter';
import { Modal } from '../molecules/Modal';

interface Props {
  sortKey: string;
  reviewed: number;
  reviewStatus: string;
  programs?: ProgramSearchFragment[]; // 提出物一覧で必須
  programID?: number; // 提出物一覧で必須
  practices?: PracticeSearchFragment[]; // 教材詳細で必須
  practiceID: string;
  isOpen: boolean;
  isCloseModal: () => void;
}

export const PracticeSubmitFilterModal: React.FC<Props> = (props) => {
  const { setParams } = useURL();

  const [sortKey, setSortKey] = useState<string>(props.sortKey);
  const [reviewed, setReviewed] = useState<number>(props.reviewed);
  const [reviewStatus, setReviewStatus] = useState<string>(props.reviewStatus);
  const [programID, setProgramID] = useState<number>(props.programID ?? INITIAL);
  const [practiceID, setPracticeID] = useState<string>(props.practiceID);

  const handleSortKeySelect = (value: string) => {
    setSortKey(value);
  };

  const handleReviewedSelect = (value: string) => {
    setReviewed(parseInt(value));
  };

  const handleReviewStatusSelect = (value: string) => {
    setReviewStatus(value);
  };

  const handleProgramIDSelect = (value: string) => {
    const handleProgramID = parseInt(value);
    setProgramID(handleProgramID);
    setPracticeID(INITIAL.toString());
  };

  const handlePracticeIDSelect = (value: string) => {
    setPracticeID(value);
  };

  const submit = () => {
    const params = [];

    params.push(setParamsValue('sort_key', sortKey));
    params.push(setParamsValue('reviewed', reviewed));
    params.push(setParamsValue('review_status', reviewStatus));
    params.push(setParamsValue('program_id', programID));
    params.push(setParamsValue('practice_id', practiceID));
    // 検索条件変更時はpageをリセット
    params.push(setParamsValue('page'));

    setParams(params);
    props.isCloseModal();
  };

  const filterProps = {
    displaySortKey: {
      sortKey,
      handleSortKeySelect,
    },
    displayReviewed: {
      reviewed,
      handleReviewedSelect,
    },
    displayReviewStatus: {
      reviewStatus,
      handleReviewStatusSelect,
    },
    displayProgram:
      props.programs && props.programID !== undefined
        ? {
            programs: props.programs,
            programID,
            handleProgramIDSelect,
          }
        : undefined,
    displayPractice:
      props.programs && props.programID !== undefined
        ? {
            practices: getPractices(props.programs.find((i) => i.id === programID)) ?? [],
            practiceID,
            handlePracticeIDSelect,
          }
        : props.practices
          ? {
              practices: props.practices,
              practiceID,
              handlePracticeIDSelect,
            }
          : undefined,
  };

  return (
    <Modal
      underlayer
      isOpen={props.isOpen}
      onClose={props.isCloseModal}
      header={<Title>フィルター</Title>}
      footer={<StyledButton onClick={submit}>絞り込み</StyledButton>}
    >
      <PracticeSubmitFilter {...filterProps} />
    </Modal>
  );
};

const Title = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 240px;
`;
