import React, { useCallback, useState, useMemo } from 'react';
import media from 'styled-media-query';
import styled from 'styled-components';

import { format, addMonths, differenceInMonths, startOfMonth } from 'date-fns';

import { BasicLayout } from '../../templates/BasicLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Box } from '../../atoms/Box';
import { Button } from '../../atoms/Button';
import { Select } from '../../atoms/Select';
import { Loader } from '../../molecules/Loader';
import { AddModal } from '../../organisms/ClaimAddModal';
import { ApplyModal } from '../../organisms/ClaimApplyModal';
import { EditModal } from '../../organisms/ClaimEditModal';
import { DeleteModal } from '../../organisms/ClaimDeleteModal';

import { LOWER_META_TITLE } from '../../../const/Service';
import {
  useGetClaimAndDetailsForInstructorQuery,
  useGetClaimCategoriesForInstructorQuery,
  ClaimDetailFragment as ClaimDetail,
  ClaimFragment as Claim,
  ClaimApplyStatus,
} from '../../../gen/graphql';
import { showTotalUnit, showUnit } from '../../../utils/Claim';
import { ClaimTable } from '../../organisms/ClaimTable';

// 月指定をTimezoneでずれないようにUTCでyyyy/mm/01を設定する
const toUtcDate = (d: Date): Date => new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

/**
 * 請求申請
 */
export const InstructorClaim: React.FC = () => {
  const metaTitle = `請求申請 | ${LOWER_META_TITLE}`;

  // 編集可能かチェック
  const isEditable = (claim: Claim | undefined): boolean =>
    !claim || claim.applyStatus === ClaimApplyStatus.Editable;

  // 各種Modal
  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [currentClaimDetail, setCurrentClaimDetail] = useState<ClaimDetail>();

  // UTCで月初日を設定
  const today = useMemo(() => {
    return startOfMonth(new Date());
  }, []);
  const [month, setMonth] = useState(today);
  const [utcMonth, setUtcMonth] = useState(toUtcDate(today));

  const [isOpen, setIsOpen] = useState(false);
  const toggleSummary = () => {
    setIsOpen(!isOpen);
  };

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {
    setInnerWidth(window.innerWidth);
  });

  // 各フィルター選択値
  const [filteredCategoryId, setFilteredCategoryId] = useState(0);
  const [filteredStudentId, setFilteredStudentId] = useState(0);

  // ClaimおよびClaimDetail取得
  const {
    data: claimData,
    loading: claimLoading,
    refetch,
  } = useGetClaimAndDetailsForInstructorQuery({
    variables: {
      yearMonth: utcMonth.toISOString(),
      input: {
        studentID: filteredStudentId || undefined,
        claimCategoryID: filteredCategoryId || undefined,
      },
    },
  });
  const claim = claimData?.claimForInstructor ?? undefined;
  const claimDetails = claimData?.claimDetailsForInstructor ?? [];
  // 受講生フィルター作成
  const studentsFilter = Array.from(
    new Map(
      claimDetails.filter((c) => c.course).map((c) => [c.course?.student.id, c.course?.student]), // 重複排除
    ).values(),
  ).map((c) => {
    return {
      value: c?.id,
      name: c?.user.maskedPersonalInfo?.name,
    };
  });
  studentsFilter.unshift({
    value: 0,
    name: 'すべて',
  });

  // Modal実行時の再検索
  const refetchClaimDetails = useCallback(
    async (date: Date, claimCategoryId?: number, studentId?: number): Promise<void> => {
      try {
        await refetch({
          yearMonth: date.toISOString(),
          input: {
            studentID: studentId || undefined,
            claimCategoryID: claimCategoryId || undefined,
          },
        });
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      }
    },
    [refetch],
  );

  // ClaimCategory取得
  const { data: claimCategoryData, loading: claimCategoryLoading } =
    useGetClaimCategoriesForInstructorQuery();
  const categories = claimCategoryData?.claimCategoriesForInstructor ?? [];
  // カテゴリーフィルター作成
  const categoriesFilter = categories.map((c) => {
    return {
      value: c.id,
      name: c.name,
    };
  });
  categoriesFilter.unshift({
    value: 0,
    name: 'すべて',
  });

  // プルダウンの月表示
  const releaseDay = new Date(2020, 0, 1);
  const passed = Math.abs(differenceInMonths(today, releaseDay));
  const selectableDates = Array.from(Array(2 + passed)) // 翌月まで表示させるためDiff+2
    .map((_, i) => {
      return {
        value: format(addMonths(new Date(2020, 0, 1), i), 'yyyy/MM'),
        name: format(addMonths(new Date(2020, 0, 1), i), 'yyyy/MM'),
      };
    })
    .reverse();

  /**
   * カテゴリーフィルター変更ハンドラ
   */
  const changeCategoryFilter: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    async (e) => {
      setFilteredCategoryId(parseInt(e.target.value));
    },
    [],
  );

  /**
   * 受講生フィルター変更ハンドラ
   */
  const changeStudentFilter: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    async (e) => {
      setFilteredStudentId(parseInt(e.target.value));
    },
    [],
  );

  /**
   * 年月変更ハンドラ
   */
  const changeMonth: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const ymd = e.target.value + '/01'; // YYYY/MM形式だとDateでパースできない
    const dateYmd = new Date(ymd);
    setMonth(dateYmd);
    setUtcMonth(toUtcDate(dateYmd));
  };

  return (
    <>
      <Loader display={claimLoading || claimCategoryLoading}></Loader>
      {/*親コンポーネントでtransformを使用しており位置を固定するためLayout外に配置*/}
      <ApplyModal
        isOpen={applyModalOpen}
        toggle={setApplyModalOpen}
        refetch={refetchClaimDetails}
        month={utcMonth}
        claimCategoryId={filteredCategoryId}
        studentId={filteredStudentId}
      />
      <AddModal
        isOpen={addModalOpen}
        toggle={setAddModalOpen}
        refetch={refetchClaimDetails}
        month={month}
        utcMonth={utcMonth}
        claimCategoryId={filteredCategoryId}
        studentId={filteredStudentId}
        width={innerWidth}
      />
      <EditModal
        isOpen={editModalOpen}
        toggle={setEditModalOpen}
        claimDetail={currentClaimDetail}
        refetch={refetchClaimDetails}
        month={month}
        utcMonth={utcMonth}
        claimCategoryId={filteredCategoryId}
        studentId={filteredStudentId}
        width={innerWidth}
      />
      <DeleteModal
        isOpen={deleteModalOpen}
        toggle={setDeleteModalOpen}
        claimDetail={currentClaimDetail}
        refetch={refetchClaimDetails}
        month={utcMonth}
        claimCategoryId={filteredCategoryId}
        studentId={filteredStudentId}
      />

      <BasicLayout pageTitle="請求申請" metaTitle={metaTitle}>
        <PageWrapper>
          <Section>
            <SectionTitle>請求申請月の選択</SectionTitle>
            <DateSelect>
              <InputLabel>申請月</InputLabel>
              <Select
                name="month"
                onChange={changeMonth}
                value={format(month, 'yyyy/MM')}
                options={selectableDates}
              />
            </DateSelect>

            {isEditable(claim) ? (
              <ClaimButton onClick={() => setApplyModalOpen(true)}>
                月次の請求を申請する
              </ClaimButton>
            ) : claim?.applyStatus === ClaimApplyStatus.Applied ? (
              <ClaimButton disabled={true}>申請済み</ClaimButton>
            ) : claim?.applyStatus === ClaimApplyStatus.Approved ? (
              <ClaimButton disabled={true}>承認済み</ClaimButton>
            ) : (
              ''
            )}
          </Section>

          <Separator />

          <Section>
            <SectionTitle>請求申請項目の詳細</SectionTitle>
            <ActionContainer>
              <AddButton
                border
                onClick={() => setAddModalOpen(true)}
                disabled={claim && claim.applyStatus !== ClaimApplyStatus.Editable}
              >
                請求項目の追加
              </AddButton>

              <FilterCondition>
                <FilterGroup>
                  <InputLabel>カテゴリー</InputLabel>
                  <Select
                    name="category"
                    onChange={changeCategoryFilter}
                    value={filteredCategoryId}
                    options={categoriesFilter}
                  />
                </FilterGroup>
                <FilterGroup>
                  <InputLabel>受講生</InputLabel>
                  <Select
                    name="student"
                    onChange={changeStudentFilter}
                    value={filteredStudentId}
                    options={studentsFilter}
                  />
                </FilterGroup>
              </FilterCondition>
            </ActionContainer>

            <ClaimTable
              claimData={claimData}
              isEditable={isEditable}
              setEditModalOpen={setEditModalOpen}
              setDeleteModalOpen={setDeleteModalOpen}
              setCurrentClaimDetail={setCurrentClaimDetail}
            />
          </Section>

          <Separator />

          <Section>
            <SectionTitle>請求申請のサマリー</SectionTitle>
            <SummaryContainer padding={[8, 8, 16, 8]} spPadding={[4]}>
              <CategoryTotals isOpen={isOpen}>
                <CategoryTotalsInner isOpen={isOpen}>
                  <CategoryTotalsInnerDiv innerWidth={innerWidth}>
                    <CategoryTotalsInnerName>【当月報酬】※源泉徴収前</CategoryTotalsInnerName>
                    <br />
                    合計金額:{' '}
                    <CategoryTotalsInnerSpan>
                      {(claimDetails ? claimDetails : []).reduce(
                        (acc: number, cur) => acc + (cur.subTotal ? cur.subTotal : 0),
                        0,
                      )}
                    </CategoryTotalsInnerSpan>
                    円(税込)
                  </CategoryTotalsInnerDiv>
                  {categories.map((category, i) => {
                    return (
                      <CategoryTotalsInnerDiv innerWidth={innerWidth} key={i}>
                        <CategoryTotalsInnerName>{category.name}</CategoryTotalsInnerName>
                        <br />
                        {showTotalUnit(category.id)}
                        <CategoryTotalsInnerSpan>
                          {(claimDetails ? claimDetails : [])
                            .filter((c) => c.claimCategory?.id === category.id)
                            .reduce((acc, cur) => acc + (cur.amount ? cur.amount : 0), 0)}
                        </CategoryTotalsInnerSpan>
                        {showUnit(category.id)}
                        <br />
                        合計金額:
                        <CategoryTotalsInnerSpan>
                          {(claimDetails ? claimDetails : [])
                            .filter((c) => c.claimCategory?.id === category.id)
                            .reduce((acc, cur) => acc + (cur.subTotal ? cur.subTotal : 0), 0)}
                        </CategoryTotalsInnerSpan>
                        円
                      </CategoryTotalsInnerDiv>
                    );
                  })}
                </CategoryTotalsInner>
              </CategoryTotals>
              <Toggle onClick={toggleSummary}>続きを見る {isOpen ? '▲' : '▼'}</Toggle>
            </SummaryContainer>
          </Section>
        </PageWrapper>
      </BasicLayout>
    </>
  );
};

const Section = styled.section`
  width: 100%;
  position: relative;
`;
const SectionTitle = styled.h2`
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;
const Separator = styled.hr`
  margin: 2rem auto;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
const InputLabel = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 500;

  ${media.lessThan('medium')`
    color: rgba(0,0,0,.87);
    font-weight: 400;
    letter-spacing: .1px;
  `}
`;

const ClaimButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;

  ${media.lessThan('medium')`
    margin-top: 1rem;
    position: relative;
  `}
`;
const DateSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2.25rem;

  select {
    width: 13rem;
  }

  ${media.lessThan('medium')`
    align-items: flex-start;
    flex-direction: column;
    gap: .5rem;
    margin-top: 1rem;

    select {
      width: 11.25rem;
    }
  `}
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2.5rem;

  ${media.lessThan('medium')`
    align-items: flex-start;
    flex-direction: column;
    margin-top: 1rem;
  `}
`;
const AddButton = styled(Button)`
  padding-left: 0.875rem;
  padding-right: 0.875rem;
`;
const FilterCondition = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${media.lessThan('medium')`
    width: 100%;
    flex-direction: column;
  `}
`;
const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  select {
    width: 11.25rem;
  }

  ${media.lessThan('medium')`
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    gap: .5rem;

    select {
      width: 100%;
    }
  `}
`;

const SummaryContainer = styled(Box)`
  margin-top: 1rem;

  ${media.lessThan('medium')`
    margin: 1rem -1rem 0;
    border-left: none;
    border-right: none;
    border-radius: 0;
  `}
`;
const CategoryTotals = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: ${(props) => (props.isOpen ? 'auto' : '10rem')};
  padding-bottom: 2.5rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: all 0.2s;
`;
const CategoryTotalsInner = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  gap: 1.5rem 1rem;
  opacity: 0.4;
  ${(props) => {
    if (props.isOpen) {
      return 'opacity: 1;';
    }
  }};
`;
const CategoryTotalsInnerDiv = styled.div<{ innerWidth: number }>`
  width: calc((100% - 3rem) / 4);
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.625rem;
  line-height: 1.5;
  ${(props) => {
    if (!props.innerWidth || props.innerWidth > 850) {
      return '';
    }
    return 'width: calc(50% - .5rem)';
  }};
`;
const CategoryTotalsInnerName = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 500;
`;
const CategoryTotalsInnerSpan = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 700;
`;
const Toggle = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.375rem;
  height: 2.5rem;
  margin: -1.25rem auto 0;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.36);
  border-radius: 1.25rem;
  box-sizing: border-box;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  position: relative;
  z-index: 2;

  ${media.lessThan('medium')`
    width: 7.5rem;
    height: 2rem;
    margin-top: -1rem;
    font-size: .875rem;
  `}
`;
