import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../../atoms/LpInner';
import { Button } from '../../../atoms/lp/trial/Button';

import RankImage001 from '../../../../static/image/lp/rank001.png';
import RankImage002 from '../../../../static/image/lp/rank002.png';
import RankImage003 from '../../../../static/image/lp/rank003.png';
import RankBgPc from '../../../../static/image/lp/rank_bg_pc.jpg';
import RankBgSp from '../../../../static/image/lp/rank_bg_sp.jpg';

interface Props {
  onClickCV: () => void;
}

export const Rank: React.FC<Props> = (props) => {
  return (
    <RankSection data-gtm="rank">
      <Inner>
        <Title>
          数多くの実績を残す
          <br />
          SAMURAIが提供する新サービスです。
        </Title>
        <List>
          <Item>
            <Img src={RankImage001} alt="挫折しないプログラミングスクール No.1" />
          </Item>
          <Item>
            <Img src={RankImage002} alt="実践的スキルが身につくプログラミングスクール No.1" />
          </Item>
          <Item>
            <Img src={RankImage003} alt="レッスン満足度が高いプログラミングスクール No.1" />
          </Item>
        </List>
        <Button onClick={props.onClickCV} />
        <Description>
          ■アンケートモニター提供元： ゼネラルリサーチ&emsp;&emsp;■調査機関： 2019年12月13日 ~
          16日&emsp;&emsp;■調査方法： インターネット調査&emsp;&emsp;■調査概要：
          プログラミングスクール10社を対象にしたサイト比較イメージ調査
          <br />
          ■調査対象： 全国の20代 ~
          50代の男女1021名（プログラミングスクールに通っている方または通っていた方)
        </Description>
      </Inner>
    </RankSection>
  );
};

const RankSection = styled.section`
  padding: 4rem 0 1rem;
  background: #01011c url(${RankBgPc}) center / cover no-repeat;

  ${media.lessThan('medium')`
    padding: 3rem 0 1.6875rem;
    background: #01011c url(${RankBgSp}) center top / cover no-repeat;
  `}
`;
const Title = styled.h2`
  color: #fff;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1.375rem;
    line-height: 1.5;
  `}

  ${media.greaterThan('medium')`
    br {
      display: none;
    }
  `}
`;
const List = styled.ul`
  display: flex;
  max-width: 696px;
  margin: 4rem auto 2rem;

  ${media.lessThan('medium')`
    display: block;
    margin: 3rem auto;
  `}
`;
const Item = styled.li`
  flex: 1;

  + li {
    margin-left: 1.5rem;
  }

  ${media.lessThan('medium')`
    max-width: 216px;
    margin: 0 auto;

    + li {
      margin-top: 1.5rem;
      margin-left: auto;
    }
  `}
`;
const Img = styled.img`
  width: 100%;
`;
const Description = styled.p`
  margin-top: 2rem;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.625rem;
  line-height: 1.4;

  ${media.lessThan('medium')`
    margin-top: 2.25rem;

    br {
      display: none;
    }
  `}
`;
