import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';

import Image001 from '../../../static/image/lp/careersupport_001.png';
import Image002 from '../../../static/image/lp/careersupport_002.png';
import Image003 from '../../../static/image/lp/careersupport_003.png';
import Image004 from '../../../static/image/lp/careersupport_004.png';
import Image005 from '../../../static/image/lp/careersupport_005.png';

export const CareerSupport: React.FC = () => {
  return (
    <Section data-gtm="careerSupport">
      <Inner>
        <Ttl>
          <h2>転職・就職サポート実施中</h2>
        </Ttl>
        <Description>
          未経験からエンジニア転職するために転職サポートを実施しております。
          <br />
          具体的には以下のようなサポートが受けられます。
        </Description>
        <Note>※ 本サポートは予告なく終了する可能性がございます。</Note>
        <List>
          <Item>
            <ItemTitle>エンジニア転職ガイダンス</ItemTitle>
            <ItemThumbnail>
              <img src={Image001} alt="" />
            </ItemThumbnail>
          </Item>
          <Item>
            <ItemTitle>IT業界理解サポート</ItemTitle>
            <ItemThumbnail>
              <img src={Image002} alt="" />
            </ItemThumbnail>
          </Item>
          <Item>
            <ItemTitle>
              職務経歴書の作成サポート<span>※</span>
            </ItemTitle>
            <ItemThumbnail>
              <img src={Image003} alt="" />
            </ItemThumbnail>
          </Item>
          <Item>
            <ItemTitle>
              面接対策サポート<span>※</span>
            </ItemTitle>
            <ItemThumbnail>
              <img src={Image004} alt="" />
            </ItemThumbnail>
          </Item>
          <Item>
            <ItemTitle>
              自己PR対策サポート<span>※</span>
            </ItemTitle>
            <ItemThumbnail>
              <img src={Image005} alt="" />
            </ItemThumbnail>
          </Item>
        </List>
        <ListNote>※ 年齢が満20歳から31歳の間であること</ListNote>
      </Inner>
    </Section>
  );
};

const Section = styled.section`
  margin: 7.5rem auto;

  ${media.lessThan('medium')`
    margin: 4rem auto;
  `}
`;
const Description = styled.p`
  margin-top: 3rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  line-height: 2.25rem;
  text-align: center;

  ${media.lessThan('medium')`
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.8;
  `}
`;
const Note = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;

  ${media.lessThan('medium')`
    margin-top: .75rem;
  `}
`;
const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem 2rem;
  justify-content: center;
  margin-top: 4rem;

  ${media.lessThan('medium')`
    gap: 2rem 1rem;
  `}
`;
const Item = styled.li`
  display: flex;
  flex-direction: column;
  width: calc((100% - 4rem) / 3);

  ${media.lessThan('medium')`
    width: calc((100% - 2rem) / 2);
  `}

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
const ItemTitle = styled.h3`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 2rem;

  span {
    color: #7c7c7c;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1;
  }

  ${media.lessThan('medium')`
    font-size: 1.125rem;
    line-height: 1.5rem;
  `}
`;
const ItemThumbnail = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  background: #c5d7f1;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 2rem;

  img {
    width: 100%;
  }

  ${media.lessThan('medium')`
    border-radius: 1.5rem;
  `}
`;
const ListNote = styled.p`
  margin-top: 2rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.125rem;

  ${media.lessThan('medium')`
    margin-top: 1.5rem;
  `}
`;
