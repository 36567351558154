import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';
import { useUser } from '../../redux/user/useUser';

import { Button } from '../atoms/Button';
import { H3 } from '../atoms/Typography';
import { Tag } from '../atoms/Tag';
import IconTime from '../../static/image/icon_time_gray.svg';

import { MedalCount } from '../../types/MedalCount';

import BronzeMedal from '../../static/image/medal_bronze.svg';
import SilverMedal from '../../static/image/medal_silver.svg';
import GoldMedal from '../../static/image/medal_gold.svg';

import { ProgramSampleSimpleFragment, ProgramTagFragment } from '../../gen/graphql';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { minutesToHoursSecondDecimalPlace } from '../../utils/common';
import { progress } from '../../utils/Progress';

interface ProgramArticleProps {
  image: string;
  title: string;
  description?: string;
  id: number;
  thumb?: string;
  chapterCount?: number;
  time?: number;
  tags?: ProgramTagFragment[];
  questionCount?: number;
  answerCount?: number;
  requireTime: number;
  completedTime: number;
  className?: string;
  studyLogCount?: number;
  medalCount?: MedalCount;
  programSample?: ProgramSampleSimpleFragment;
  isUserPage?: boolean;
}

export const ProgramArticle: React.FC<ProgramArticleProps> = (props) => {
  const { permissionCheck } = useUser();
  const navigate = useNavigate();
  const { isUserPage = false } = props;

  return (
    <Container data-e2e="programList" className={props.className}>
      <StyledLink
        to={`/programs/${props.id}/chapters`}
        data-e2e={`programLinkTitle_${props.title}`}
      >
        <Thumb bg={props.thumb}></Thumb>
        <Icon>
          <svg
            width="98"
            height="40"
            viewBox="0 0 98 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M74.3639 15.4065C67.5168 8.65806 62.4363 0 49.3868 0H48.6132C35.5637 0 30.4832 8.65806 23.6361 15.4065C18.6587 21.6258 8.52342 22.9806 0 23.2258V40H98V23.2258C89.4766 22.9806 79.3413 21.6258 74.3639 15.4065Z"
              fill="white"
            />
          </svg>
          <img src={props.image} alt="" />
        </Icon>
        <StyledSection>
          <StyledH3 color="DARK">{props.title}</StyledH3>
        </StyledSection>
        {props.medalCount && (
          <Medals>
            <div className="medal">
              <img src={GoldMedal} alt="金メダル" />
              <p className="count">{props.medalCount.gold}</p>
            </div>
            <div className="medal">
              <img src={SilverMedal} alt="銀メダル" />
              <p className="count">{props.medalCount.silver}</p>
            </div>
            <div className="medal">
              <img src={BronzeMedal} alt="銅メダル" />
              <p className="count">{props.medalCount.bronze}</p>
            </div>
          </Medals>
        )}
        {(permissionCheck(FunctionType.Exam, PermissionType.Read) || isUserPage) && (
          <Progress data-e2e="programCardProgress">
            <ProgressBar
              start={props.completedTime ? props.completedTime : 0}
              end={props.requireTime ? props.requireTime : 0}
            >
              <div></div>
            </ProgressBar>
            <ProgressInfo>
              {progress(
                props.completedTime ? props.completedTime : 0,
                props.requireTime ? props.requireTime : 0,
              )}
              %
              <span>
                ({props.completedTime ? minutesToHoursSecondDecimalPlace(props.completedTime) : 0}/
                {props.requireTime ? minutesToHoursSecondDecimalPlace(props.requireTime) : 0}時間)
              </span>
            </ProgressInfo>
          </Progress>
        )}
        {props.tags && props.tags.length > 0 && (
          <Tags>
            {props.tags.map((tag) => (
              <Tag key={tag.id} name={tag.name} />
            ))}
          </Tags>
        )}
        <Qa data-e2e="programCardIcons">
          <p>
            <QaIcon>Q</QaIcon>質問:{props.questionCount}件
          </p>
          <p>
            <QaIcon>A</QaIcon>回答:{props.answerCount}件
          </p>
          <p className="time">
            <img src={IconTime} alt="time" />
            {minutesToHoursSecondDecimalPlace(props.requireTime)}時間
          </p>
        </Qa>
      </StyledLink>
      <ButtonArea>
        <StyledButton onClick={() => navigate(`/programs/${props.id}/overview`)}>概要</StyledButton>
        {props.programSample?.url && (
          <StyledButton onClick={() => window.open(props.programSample?.url)}>
            サンプル
          </StyledButton>
        )}
      </ButtonArea>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: calc((100% - 2.5rem) / 3);
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  transition: all 0.2s ease;
  overflow: hidden;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('large')`
    width: calc(50% - .625rem);
  `}
  ${media.lessThan('medium')`
    width: calc(50% - .5rem);
  `}
  ${media.lessThan('small')`
    width: 100%;
  `}
`;
const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 3.25rem);
  padding-bottom: 0.625rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
`;
const Thumb = styled.div<{ bg?: string }>`
  padding-top: 50.2958%;
  background: ${(props) => (props.bg ? `url(${props.bg})` : '#ccc')} center / cover no-repeat;
`;
const Icon = styled.p`
  margin-top: -23px;
  padding: 0.25rem 33px;
  font-size: 2rem;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
  }

  img {
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 0 0 0 auto;
    position: relative;
    border-radius: 1rem;
  }
`;
const StyledSection = styled.section`
  flex: 1 0 auto;
  padding: 0 1rem;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
`;
const StyledH3 = styled(H3)`
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0.25rem auto 0.5rem;

  ${media.lessThan('medium')`
    font-size: 1.125rem;
  `}
`;

const Tags = styled.div`
  padding: 0 1rem;
  font-size: 0;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0.25rem 0.25rem 0 0;
  }
`;
const Qa = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: calc(100% - 2rem);
  margin: 0.5rem auto 0;

  p {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
  }
`;

const QaIcon = styled.span`
  display: block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.125rem;
  color: rgba(0, 0, 0, 0.67);
  font-family: Helvetica;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  text-align: center;
`;

const barAnimation = (start: number, end: number) => {
  return keyframes`
    0% {
      width: 0;
    }

    100% {
      width: ${progress(start, end)}%;
    }
  `;
};

const Progress = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 1rem 0;
`;

const ProgressBar = styled.div<{ start: number; end: number }>`
  flex: 1;
  height: 0.25rem;
  border-radius: 1rem;
  background-color: #e5e5e5;
  overflow: hidden;

  div {
    height: 100%;
    width: 0;
    background: linear-gradient(95.07deg, #fd8258 11.86%, #fd3c2f 82.5%);
    animation: ${(props) => barAnimation(props.start, props.end)} 0.5s ease-out 1 forwards;
  }
`;

const ProgressInfo = styled.div`
  margin-left: 0.25rem;
  font-size: 0.625rem;

  span {
    font-size: 1em;
  }
`;

const Medals = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;

  .medal {
    display: flex;
    align-items: center;

    & + .medal {
      margin-left: 0.875rem;
    }

    img {
      height: 1rem;
      width: auto;
    }

    .count {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.75rem;
      margin-left: 0.25rem;
    }
  }
`;

const ButtonArea = styled.div`
  display: flex;
  gap: 0.75rem;
  padding: 0.625rem 1rem 0.75rem;
`;
const StyledButton = styled(Button)`
  flex: 1;
  padding: 0.25rem;
  background: #fff;
  border: 1px solid #eb0000;
  color: #eb0000;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  transition: all 0.2s;

  &:hover {
    background: #eb0000;
    color: #fff;
  }
`;
