import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

interface CategoryProps {
  id: number;
  name: string;
  description?: string;
}
interface Props {
  categories: CategoryProps[];
  searchCategory: number;
  to: string;
  closeModal?: () => void;
  className?: string;
}

export const ProgramCategoryList: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    props.closeModal?.();
    navigate(`${props.to}/${id}`);
  };

  return (
    <ul className={props.className}>
      {props.categories.map((category) => (
        <Item
          key={category.id}
          active={props.searchCategory === category.id}
          onClick={() => handleClick(category.id)}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-e2e="categories"
          >
            <path
              d="M4 10.825L7.81667 7L4 3.175L5.175 2L10.175 7L5.175 12L4 10.825Z"
              fill="black"
              fillOpacity="0.87"
            />
          </svg>
          <span>{category.name}</span>
        </Item>
      ))}
    </ul>
  );
};

const Item = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s;

  &:nth-of-type(n + 2) {
    margin-top: 0.5rem;
  }

  &:hover {
    background: #ececec;
  }

  svg {
    display: block;
  }

  span {
    margin-left: 0.375rem;
    color: ${(props) => (props.active ? '#eb0000' : 'rgba(0,0,0,.87)')};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
  }
`;
