import React from 'react';

import { Loaders } from '../../../common/Loader';
import { CourseProgramArticle } from '../../organisms/CourseProgramArticle';
import { ProgramListContainer } from '../../atoms/ProgramListContainer';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { useGetCourseProgramsQuery } from '../../../gen/graphql';
import { useUser } from '../../../redux/user/useUser';

import { FunctionType, PermissionType } from '../../../const/UserPermission';

export const CoursePrograms: React.FC = (): JSX.Element => {
  const { permissionCheck } = useUser();

  const { data, loading } = useGetCourseProgramsQuery({
    variables: {
      input: {
        registeredCoursePrograms: false,
      },
    },
  });

  return (
    <ProgramListContainer>
      {!loading ? (
        data?.coursePrograms ? (
          data.coursePrograms.map((p) => {
            return (
              <CourseProgramArticle
                id={p.id}
                key={p.id}
                image={p.image}
                title={p.title}
                totalChapterCount={p.totalChapterCount}
                totalRequireTime={p.totalRequireTime}
                totalCompletedTime={p.totalCompletedTime}
                description={p.description}
                showProgress={permissionCheck(FunctionType.StudyLog, PermissionType.Read)}
              />
            );
          })
        ) : (
          <EmptyBlock title="表示できるコース教材がありません" />
        )
      ) : (
        <Loaders width="312px" height="252px" margin="0 0.75rem 1rem 0" number={10} />
      )}
    </ProgramListContainer>
  );
};
