import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import { HeadTagContext } from '../../context/HeadTagProvider';
import { useMetaData } from '../../common/customHooks/MetaReplace';

interface NoHeaderLayoutProps {
  metaTitle: string;
  metaDescription?: string;
}

export const NoHeaderLayout: React.FC<NoHeaderLayoutProps> = (props) => {
  const headTagContext = useContext(HeadTagContext);

  useMetaData(props.metaTitle, props.metaDescription);

  return (
    <>
      <Helmet title={headTagContext.title} meta={headTagContext.meta} />
      {props.children}
    </>
  );
};
