import React from 'react';
import styled from 'styled-components';

export interface SelectOption {
  name?: string;
  value?: string | number;
}

interface SelectProps {
  options: SelectOption[];
  width?: string;
  name: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: () => void;
  className?: string;
}

export const Select: React.FC<SelectProps> = (props): JSX.Element => (
  <StyledSelect
    name={props.name}
    onChange={props.onChange}
    onFocus={props.onFocus}
    value={props.value}
    className={props.className}
  >
    {props.options.map((option, i): JSX.Element => {
      return (
        <option key={i} value={option.value}>
          {option.name}
        </option>
      );
    })}
  </StyledSelect>
);

const StyledSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.36);
  border-radius: 0.125rem;
  background-color: #fff;
  font-size: 1rem;
`;
