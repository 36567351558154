import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { UserIcon } from '../organisms/UserIcon';
import { QuestionUserFragment } from '../../gen/graphql';

import ImgLoading from '../../static/image/ic_loading.svg';

interface QuestionResponseAnswerPendingProps {
  answeredUser?: QuestionUserFragment;
  answerRequestedDate: string;
  large?: boolean;
  huge?: boolean;
  isComment?: boolean;
}

export const QuestionResponseAnswerPending: React.FC<QuestionResponseAnswerPendingProps> = (
  props,
) => {
  return (
    <Container key={'waiting_answer'} visibleTopBorder={props.isComment}>
      <UserIcon
        user={props.answeredUser}
        date={props.answerRequestedDate}
        large={props.large}
        huge={props.huge}
        spSize="small"
      />
      <Content>
        <Loading>
          <LoadingImg>
            <img src={ImgLoading} alt="Loading" />
          </LoadingImg>
          <LoadingText>
            <p>※30秒程度かかることがあります。</p>
            <p>回答が表示されない場合は、お手数ですが新規で質問投稿をお願いいたします。</p>
          </LoadingText>
        </Loading>
      </Content>
    </Container>
  );
};

const Container = styled.article<{ visibleTopBorder?: boolean }>`
  ${({ visibleTopBorder }) => visibleTopBorder && `border-top: 1px solid rgba(0, 0, 0, 0.1);`}
  padding: 1rem;
  position: relative;

  ${media.lessThan('medium')`
    padding: 1rem 0;
  `}
`;

const Content = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    margin-top: .875rem;
    padding-left: 0;
  `}
`;

const Loading = styled.div`
  display: flex;
  flex-flow: column;
  text-align: center;
`;

const LoadingImg = styled.div``;

const LoadingText = styled.div`
  font-size: 14px;
  font-weight: 400;
  > p:first-child {
    margin-top: 0;
  }
  > p {
    margin-top: 0.25rem;
  }
`;
