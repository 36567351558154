import React from 'react';
import { ApolloClient, ApolloProvider, from, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

// RestApolloClientProviderはリビジョンアップで使用するためのProviderです
// 基本的にはそれ以外の用途では使用しません
export const RestApolloClientProvider: React.FC = ({ children }): JSX.Element => {
  const restLink = new RestLink({
    uri: `${process.env.REACT_APP_DOMAIN}`,
  });

  const client = new ApolloClient({
    link: from([restLink]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
