import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

interface TtlProps {
  className?: string;
}

export const Ttl: React.FC<TtlProps> = (props) => {
  return <StyledTtl className={props.className}>{props.children}</StyledTtl>;
};

const StyledTtl = styled.div`
  text-align: center;

  h2 {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: -0.007em;
  }

  p {
    margin-top: 0.375rem;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.05em;

    &:after {
      content: '';
      display: block;
      width: 3.5rem;
      height: 0.125rem;
      margin: 1rem auto 0;
      background: #eb0000;
    }
  }

  ${media.lessThan('medium')`
    h2 {
      font-size: 1.375rem;
      line-height: 1.5rem;
      letter-spacing: 0;
    }

    p {
      margin-top: .5rem;
      font-size: .8125rem;
      line-height: 1.125rem;
      letter-spacing: 0;

      &:after {
        margin-top: .5rem;
      }
    }
  `}
`;
