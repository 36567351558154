import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../../static/image/logo.svg';
import {
  ErrorCode,
  useGetTeamInvitationQuery,
  useJoinTeamMemberMutation,
} from '../../../gen/graphql';
import { Loader } from '../../molecules/Loader';
import { Button } from '../../atoms/Button';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { getGraphQLErrorCodes } from '../../../utils/graphqlError';
import { useUser } from '../../../redux/user/useUser';
import ForbiddenPage from '../public/Forbidden';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { sessionStorageSupport } from '../../../utils/sessionStorageSupport';

export const TeamMemberJoin = (): JSX.Element => {
  const { user, changeTeamID, permissionCheck } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const id = new URLSearchParams(location.search).get('id') ?? '';

  const {
    data,
    error: loadingError,
    loading,
  } = useGetTeamInvitationQuery({
    variables: { id },
  });

  const [joinTeamMember, { error: updateError, loading: updating }] = useJoinTeamMemberMutation({
    onCompleted: ({ joinTeamMember }) => {
      changeTeamID(joinTeamMember.teamID);
    },
  });

  const invitation = data?.teamInvitation;
  const error = loadingError ?? updateError;
  const errorCodes = useMemo(() => (error ? getGraphQLErrorCodes(error) : []), [error]);
  const errorReason = useMemo((): string => {
    // 表示できるエラーメッセージは一つだけ
    switch (errorCodes[0]) {
      case ErrorCode.TeamInvitationInvalidId:
        return '招待URLが無効なため';
      case ErrorCode.TeamInvitationEmailNotMatch:
        return '招待したメールアドレスと異なるため';
      case ErrorCode.TeamInvitationUserAlreadySubscribed:
        return '個人プランを契約済みのため';
      case ErrorCode.TeamInvitationReachedMaximumLimit:
        return 'チームのユーザー数が上限に達しているため';
      case ErrorCode.TeamInvitationAuthError:
        // 権限でエラーになるパターンは受講生or講師
        if (permissionCheck(FunctionType.TeamInvitationErrorForStudent, PermissionType.Read)) {
          return 'マンツーマンレッスンを契約済みのため';
        }
        return 'インストラクターとして契約済みのため';
      default:
        return 'システムエラーのため';
    }
  }, [errorCodes, permissionCheck]);

  const handleClickJoin = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      try {
        await joinTeamMember({ variables: { teamInvitationID: id } });
      } catch {
        return;
      }

      // Router.tsxのuseEffectにてrefresh()実行されるのでここではrefresh()不要
      // 法人のthanksページへ
      sessionStorageSupport.setItem('REGISTERED_TYPE', 'new');
      navigate('/team_member/registered'); // 分析のためtoCとtoBでパスだけ変更している
    }, [navigate, joinTeamMember, id]),
  );

  useEffect(() => {
    // 既に法人に参加済みの場合はhomeに遷移
    if (errorCodes.includes(ErrorCode.TeamInvitationUserAlreadyJoined)) {
      navigate('/home');
    }
  }, [errorCodes, navigate]);

  if (!user.lmsUser) {
    return ForbiddenPage;
  }

  return (
    <>
      <Loader display={loading || updating} />
      <Header>
        <Link to="/">
          <img src={Logo} alt="Samurai" />
        </Link>
      </Header>
      <Main>
        <Container>
          <Title>
            {error ? 'チームアカウントに参加できませんでした' : 'チームアカウントに参加'}
          </Title>
          {invitation && (
            <>
              <Confirmation>
                「{invitation.team.companyName}」に参加します
                <br />
                よろしいですか？
              </Confirmation>
              <JoinButton onClick={handleClickJoin}>参加する</JoinButton>
            </>
          )}
          {errorCodes.length > 0 && (
            <>
              <ErrorMessage>
                チームアカウントへの参加処理をおこないましたが、{errorReason}
                参加できませんでした。
              </ErrorMessage>
              <HelpMessage>
                ご不明点は
                <a
                  href="https://intercom.help/plus---samurai/ja/collections/3613671-%E6%B3%95%E4%BA%BA%E3%83%97%E3%83%A9%E3%83%B3"
                  target="_blank"
                >
                  ヘルプページ
                </a>
                を参考にしていただけますと幸いです。
                <br />
                または、
                <a
                  href="https://intercom.help/plus---samurai/ja/collections/3613671-%E6%B3%95%E4%BA%BA%E3%83%97%E3%83%A9%E3%83%B3"
                  target="_blank"
                >
                  ヘルプページ
                </a>
                に記載のメールアドレスよりお問い合わせください。
                <br />
              </HelpMessage>
            </>
          )}
        </Container>
      </Main>
    </>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4.1875rem;
  padding: 0 2rem;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  img {
    height: 1.5rem;
  }

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;
const Main = styled.main`
  height: calc(100vh - 4.1875rem);
  background-color: #fcfcfc;
  box-sizing: border-box;
  padding-top: 5.25rem;
`;

const Container = styled.section`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto;
  padding: 3rem 8.125rem;
  box-sizing: border-box;
  max-width: 50.25rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;

  ${media.lessThan('medium')`
    padding: 2rem 1rem;
  `}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4;
`;

const Confirmation = styled.p`
  margin-top: 0.125rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4;
  text-align: center;
`;

const JoinButton = styled(Button)`
  margin-top: 1rem;
  padding: 0.75rem;
  width: 10rem;
  border-radius: 0.1875rem;
  font-size: 0.875rem;
`;

const ErrorMessage = styled.p`
  margin-top: 0.125rem;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4;

  ${media.lessThan('medium')`
    font-size: 0.875rem;
  `}
`;

const HelpMessage = styled.p`
  margin-top: 0.75rem;
  width: 100%;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5;

  a {
    color: #fd6258;
    font-size: inherit;
  }

  ${media.lessThan('medium')`
    font-size: 0.875rem;
  `}
`;
