import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';
import { SPOT_LESSON_TAX_INCLUDED_LOCALED_PRICE } from '../../../const';
import { SPOT_LESSON_TIME } from '../../../const/SpotLesson';
import { SERVICE_NAME } from '../../../const/Service';

export const LpFaq: React.FC = () => {
  return (
    <Faq id="faq" data-gtm="faq">
      <Inner>
        <Ttl>
          <h2>よくある質問</h2>
          <p>FAQ</p>
        </Ttl>
        <FaqContent>
          <Question>掲示板は何回利用しても良いのでしょうか？</Question>
          <Answer>はい、何回でも質問していただけます。</Answer>
        </FaqContent>
        <FaqContent>
          <Question>マンツーマンのレッスンはありますか？</Question>
          <Answer>
            レッスンチケットを利用し、1回{SPOT_LESSON_TIME}
            分のマンツーマンのレッスンを受講することができます。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>マンツーマンのレッスンをしてくれる講師はどのように選ばれますか？</Question>
          <Answer>
            事前に記載いただくアンケートの情報を元に、お客様に最適な講師をアサインいたします。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>レッスンは何時間でしょうか？</Question>
          <Answer>1回当たり{SPOT_LESSON_TIME}分間です。</Answer>
        </FaqContent>
        <FaqContent>
          <Question>レッスンチケットは1枚いくらですか？</Question>
          <Answer>1枚当たり{SPOT_LESSON_TAX_INCLUDED_LOCALED_PRICE}円（税込）です。</Answer>
        </FaqContent>
        <FaqContent>
          <Question>レッスンチケットの有効期限はどのくらいですか？</Question>
          <Answer>配布日を起点にして、30日間です。</Answer>
        </FaqContent>
        <FaqContent>
          <Question>レッスンをキャンセルした場合、チケットはどうなりますか？</Question>
          <Answer>
            チケットが返還されます。ただし、レッスン開始予定時刻まで24時間以内でキャンセルされた場合、チケットは消化されます。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>質問の受付時間は何時から何時ですか？</Question>
          <Answer>
            24時間いつでもお受けしております。
            <br />
            営業時間（10時〜22時）外の質問は、翌営業日に回答させていただきます。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>専属マンツーマンでの受講に興味があります。</Question>
          <Answer>
            {SERVICE_NAME}は専属マンツーマンでのレッスンを行っておりません。
            <br />
            専属マンツーマンにご興味がある方は、
            <a href="https://sejuku.net/courses/?cid=sns_lp_tolp" target="_blank">
              こちらのサービスページ
            </a>
            の
            <a href="https://calendar.sejuku.net/?cid=sns_lp_tocalendar" target="_blank">
              無料カウンセリング
            </a>
            をご予約し、担当のコンサルタントにご相談ください。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>地方に住んでいても大丈夫ですか？</Question>
          <Answer>はい。全国どこでも、ご登録可能です。</Answer>
        </FaqContent>
        <FaqContent>
          <Question>オンラインイベントなどの開催はありますか？</Question>
          <Answer>不定期でもくもく会などの勉強会を開催しております。</Answer>
        </FaqContent>
        <FaqContent>
          <Question>Q&A掲示板では技術的な質問以外も質問できますか？</Question>
          <Answer>
            はい。質問可能です。
            <br />
            例えば、「学習を効率的に進めるにはどうしたらいいですか？」といった、抽象的な質問にも、現役エンジニアが回答いたします。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>教材を購入する必要はありますか？</Question>
          <Answer>
            弊社でご用意している教材以外のプログラミング言語やフレームワークを学習したい場合は、ご用意いただく必要がございます。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>クレジットカード以外での決済は可能ですか？</Question>
          <Answer>現時点では、ご対応できません。</Answer>
        </FaqContent>
        <FaqContent>
          <Question>対応しているクレジットカードを教えてください。</Question>
          <Answer>
            Visa、Mastercard、American Express、Discover、Diners Club、JCB の6種類になります。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>デビットカードは利用できますか？</Question>
          <Answer>利用できます。</Answer>
        </FaqContent>
        <FaqContent>
          <Question>学習時間が確保できなくなった場合、解約することはできますか？</Question>
          <Answer>
            はい、可能です。サブスクリプションの解約手続き後に月額料金の支払いは停止となります。解約中も学習履歴等はすべて保存され、再登録後は過去の情報を引き継いだ形でご利用いただけます。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>支払い方法や決済のタイミング、引き落とし日について教えてください。</Question>
          <Answer>
            お支払い方法はクレジットカードのみとなります。初回は登録時に決済され、2回目以降は毎月の基準日に決済されます。
            <br />
            例えば3月22日にご登録・初回決済いただいた場合は22日が基準日となり、4月22日に2回目の決済が実施されます。
            <br />
            引き落とし日に関しましては、ご利用のクレジットカード会社によって異なりますので詳しくはご利用の会社へお問い合わせください。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>対応している言語やフレームワーク等を教えてください。</Question>
          <Answer>
            <a
              href="https://intercom.help/plus---samurai/ja/articles/5882187-q-%E5%AF%BE%E5%BF%9C%E3%81%97%E3%81%A6%E3%81%84%E3%82%8B%E8%A8%80%E8%AA%9E%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
              target="_blank"
            >
              こちら
            </a>
            をご確認ください。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>教材以外のことも質問掲示板やレッスンで質問・相談できますか？</Question>
          <Answer>
            質問・相談できます。対応している言語に関しては
            <a
              href="https://intercom.help/plus---samurai/ja/articles/5882187-q-%E5%AF%BE%E5%BF%9C%E3%81%97%E3%81%A6%E3%81%84%E3%82%8B%E8%A8%80%E8%AA%9E%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
              target="_blank"
            >
              こちら
            </a>
            をご確認ください。
          </Answer>
        </FaqContent>
        <FaqContent>
          <Question>解約手数料は発生しますか？</Question>
          <Answer>
            年間定額、半年定額プランに関しての詳細は
            <a
              href="https://intercom.help/plus---samurai/ja/articles/6251120-q-%E8%A7%A3%E7%B4%84%E9%87%91%E3%81%AF%E7%99%BA%E7%94%9F%E3%81%97%E3%81%BE%E3%81%99%E3%81%8B"
              target="_blank"
            >
              こちら
            </a>
            をご確認ください。
          </Answer>
        </FaqContent>
      </Inner>
    </Faq>
  );
};

const Faq = styled.section`
  padding: 6.25rem 0 10rem;
  scroll-margin-top: 49px;

  ${media.lessThan('medium')`
    padding: 4rem 0;
    scroll-margin-top: 57px;
  `}
`;
const FaqContent = styled.dl`
  margin-top: 2rem;
  padding: 2rem 2rem 2rem 4.875rem;
  border-radius: 1rem;
  box-shadow: 0 4px 16px rgba(18, 18, 18, 0.1);
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  line-height: 1.77;

  &:first-of-type {
    margin-top: 3rem;
  }

  a {
    color: #1a0dab;
    text-decoration: underline;
  }

  ${media.lessThan('medium')`
    margin-top: 1.5rem;
    padding: 1.5rem 1rem 1.5rem 3.125rem;
    border-radius: 0.75rem;
    font-size: 1rem;
    line-height: 1.6;

    &:first-of-type {
      margin-top: 2rem;
    }
  `}
`;
const Question = styled.dt`
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 700;
  position: relative;

  &:before {
    content: 'Q';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #e2001b;
    color: #fff;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: -48px;
  }

  ${media.lessThan('medium')`
    &:before {
      width: 24px;
      height: 24px;
      left: -34px;
    }
  `}
`;
const Answer = styled.dd`
  font-size: 18px;
  position: relative;

  a {
    font-size: 1em;
  }

  &:before {
    content: 'A';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff3f3;
    color: #e2001b;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: -48px;
  }

  ${media.lessThan('medium')`
    font-size: 0.875rem;

    &:before {
      width: 24px;
      height: 24px;
      left: -34px;
    }
  `}
`;
