import { FunctionType, PermissionType } from '../const/UserPermission';
import {
  AuthorizationType,
  ChapterFragment,
  ChapterSearchFragment,
  ChapterForCourseProgramStepItemFragment,
  PracticeFragment,
  PracticeListItemFragment,
  PracticeSearchFragment,
  PracticeForCourseProgramStepItemFragment,
  Program,
  ProgramElementForCourseProgramStepItemFragment,
  ProgramDetailFragment,
  ProgramFragment,
  ProgramSearchFragment,
} from '../gen/graphql';
import { ChapterAndPracticeProgramElements, ProgramElementInProgramDetail } from '../types/Program';

export const isCompletedProgram = (
  programElements?: ChapterAndPracticeProgramElements,
): boolean => {
  if (!programElements || programElements.length === 0) {
    return false;
  }

  return programElements.every(({ chapter, practice }) => chapter?.studyLog || practice?.mySubmit);
};

export const isLearnedProgram = (programElements?: ChapterAndPracticeProgramElements): boolean => {
  if (!programElements || programElements.length === 0) {
    return false;
  }

  return programElements.some(({ chapter, practice }) => chapter?.studyLog || practice?.mySubmit);
};

export const getChapterFunctionType = (type: AuthorizationType | undefined): string | undefined => {
  switch (type) {
    case AuthorizationType.Public:
      return FunctionType.All;
    case AuthorizationType.Registered:
      return FunctionType.ChapterAuthTypeRegistered;
    case AuthorizationType.Light:
      return FunctionType.ChapterAuthTypeLight;
    case AuthorizationType.Basic:
      return FunctionType.ChapterAuthTypeBasic;
    case AuthorizationType.Premium:
      return FunctionType.ChapterAuthTypePremium;
    default:
      return undefined;
  }
};

export const getPracticeFunctionType = (type: AuthorizationType): string | undefined => {
  switch (type) {
    case AuthorizationType.Public:
      return FunctionType.All;
    case AuthorizationType.Registered:
      return FunctionType.PracticeAuthTypeRegistered;
    case AuthorizationType.Light:
      return FunctionType.PracticeAuthTypeLight;
    case AuthorizationType.Basic:
      return FunctionType.PracticeAuthTypeBasic;
    case AuthorizationType.Premium:
      return FunctionType.PracticeAuthTypePremium;
    default:
      return undefined;
  }
};

export const getChapterElementFunctionType = (
  programElement: ProgramElementForCourseProgramStepItemFragment | undefined,
): string | undefined => {
  if (programElement?.chapter) {
    return getChapterFunctionType(programElement.chapter.type);
  }
  if (programElement?.practice) {
    return getPracticeFunctionType(programElement.practice.type);
  }

  return undefined;
};

export const checkProgramElementAuthType = (
  programElement:
    | ProgramElementForCourseProgramStepItemFragment
    | ProgramElementInProgramDetail
    | undefined,
  permissionCheck: (functionType: string, permissionType: string) => boolean,
): boolean => {
  if (programElement?.chapter) {
    return checkChapterAuthType(programElement.chapter, permissionCheck);
  }
  if (programElement?.practice) {
    return checkPracticeAuthType(programElement.practice, permissionCheck);
  }

  return false;
};

export const checkChapterAuthType = (
  chapter:
    | ChapterFragment
    | ChapterSearchFragment
    | ChapterForCourseProgramStepItemFragment
    | undefined,
  permissionCheck: (functionType: string, permissionType: string) => boolean,
): boolean => {
  switch (chapter?.type) {
    case AuthorizationType.Public:
      return true;
    case AuthorizationType.Registered:
      return permissionCheck(FunctionType.ChapterAuthTypeRegistered, PermissionType.Read);
    case AuthorizationType.Light:
      return permissionCheck(FunctionType.ChapterAuthTypeLight, PermissionType.Read);
    case AuthorizationType.Basic:
      return permissionCheck(FunctionType.ChapterAuthTypeBasic, PermissionType.Read);
    case AuthorizationType.Premium:
      return permissionCheck(FunctionType.ChapterAuthTypePremium, PermissionType.Read);
    default:
      return false;
  }
};

export const checkPracticeAuthType = (
  practice:
    | PracticeFragment
    | PracticeListItemFragment
    | PracticeSearchFragment
    | PracticeForCourseProgramStepItemFragment,
  permissionCheck: (functionType: string, permissionType: string) => boolean,
): boolean => {
  switch (practice.type) {
    case AuthorizationType.Public:
      return true;
    case AuthorizationType.Registered:
      return permissionCheck(FunctionType.PracticeAuthTypeRegistered, PermissionType.Read);
    case AuthorizationType.Light:
      return permissionCheck(FunctionType.PracticeAuthTypeLight, PermissionType.Read);
    case AuthorizationType.Basic:
      return permissionCheck(FunctionType.PracticeAuthTypeBasic, PermissionType.Read);
    case AuthorizationType.Premium:
      return permissionCheck(FunctionType.PracticeAuthTypePremium, PermissionType.Read);
    default:
      return false;
  }
};

export const getChapters = (
  program?: Program | ProgramFragment | ProgramDetailFragment | ProgramSearchFragment,
): (ChapterFragment | ChapterSearchFragment)[] | undefined => {
  if (!program?.programElements) return undefined;

  const chapters = program.programElements
    .map((item) => item.chapter)
    .filter((item): item is Exclude<typeof item, undefined> => item !== undefined)
    .filter((item): item is Exclude<typeof item, null> => item !== null);

  return chapters;
};

export const getPractices = (
  program?: Program | ProgramFragment | ProgramDetailFragment | ProgramSearchFragment,
): PracticeSearchFragment[] | undefined => {
  if (!program?.programElements) return undefined;

  const practices = program.programElements
    .map((item) => item.practice)
    .filter((item): item is Exclude<typeof item, undefined> => item !== undefined)
    .filter((item): item is Exclude<typeof item, null> => item !== null);

  return practices;
};

export const getChaptersAndPractices = (
  program?: Program | ProgramFragment | ProgramDetailFragment | ProgramSearchFragment,
):
  | (ChapterFragment | ChapterSearchFragment | PracticeFragment | PracticeSearchFragment)[]
  | undefined => {
  if (!program?.programElements) return undefined;

  const programElements = program.programElements
    .map((item) => {
      if (item.chapter) return item.chapter;
      if (item.practice) return item.practice;
    })
    .filter((item): item is Exclude<typeof item, undefined> => item !== undefined)
    .filter((item): item is Exclude<typeof item, null> => item !== null);

  return programElements;
};
