import React, { useEffect } from 'react';

interface CvAfibProps {
  userID: number;
}

export const CvAfib: React.FC<CvAfibProps> = (props) => {
  // こちらのタスクで依頼 https://samurai-7s.backlog.com/view/LMS-1362
  // タグ一覧(https://docs.google.com/spreadsheets/d/1zE7MSsf4DhM8NpvbQBElisoON9-Sm5Ldw5CVkprGGUY/edit#gid=2139083905)

  useEffect(() => {
    const h = document.getElementsByTagName('head')[0] as HTMLElement;

    const s1 = document.createElement('script');
    s1.id = 'afib';
    s1.src = 'https://t.afi-b.com/jslib/lpcv.js?cid=9c06de33&pid=r129908';
    s1.async = true;
    h.appendChild(s1);

    if (!window.afblpcvCvConf) {
      window.afblpcvCvConf = [];
    }
    window.afblpcvCvConf.push({
      siteId: '9c06de33',
      commitData: {
        pid: 'r129908',
        u: props.userID,
      },
    });

    return () => {
      document.getElementById('afib')?.remove();
    };
  }, [props.userID]);

  return null;
};
