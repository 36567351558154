import React, { useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';

import {
  ChatMessageListFragment,
  ChatMessagePostedDocument,
  ChatMessagePostedSubscription,
  ChatMessagePostedSubscriptionVariables,
  CurrentUserFragment as User,
  GetChatMessagesQuery,
  useGetChatMessagesQuery,
  Valid,
} from '../../gen/graphql';

export const useFetchChatMessage = (
  chatRoomID: number,
  lmsUser: User | null,
): {
  chatMessages: ChatMessageListFragment[];
  hasNextPage: boolean | undefined;
  incoming: boolean;
  setIncoming: React.Dispatch<React.SetStateAction<boolean>>;
  readCalled: boolean;
  setReadCalled: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  loadMore: (startIndex: number, stopIndex: number) => Promise<void> | void;
  error: ApolloError | undefined;
} => {
  // 新着メッセージ受信有無
  const [incoming, setIncoming] = useState<boolean>(false);
  // 既読クエリ実行を制御
  const [readCalled, setReadCalled] = useState<boolean>(false);

  const { data, loading, error, fetchMore, subscribeToMore } = useGetChatMessagesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      chatRoomID,
      first: 50,
    },
  });

  useEffect(() => {
    subscribeToMore<ChatMessagePostedSubscription, ChatMessagePostedSubscriptionVariables>({
      document: ChatMessagePostedDocument,
      variables: { chatRoomID: chatRoomID },
      updateQuery: (prev: GetChatMessagesQuery, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const postedMessage = subscriptionData.data.chatMessagePosted;

        // --- for delete ---
        if (postedMessage.status === Valid.Invalid) {
          const edges =
            prev.chatMessages?.edges.filter((e) => e.node.id !== postedMessage.id) ?? [];

          return Object.assign({}, prev, {
            chatMessages: {
              edges: edges,
              pageInfo: Object.assign({}, prev.chatMessages?.pageInfo, {
                endCursor:
                  edges.length > 0
                    ? edges[edges.length - 1].cursor
                    : prev.chatMessages?.pageInfo.endCursor,
              }),
            },
          });
        }

        // --- for update ---
        if (prev.chatMessages?.edges.find((e) => e.node.id === postedMessage.id)) {
          return Object.assign({}, prev, {
            chatMessages: {
              edges: [
                postedMessage,
                ...prev.chatMessages.edges.map((e) =>
                  e.node.id === postedMessage.id ? { ...e, content: postedMessage.content } : e,
                ),
              ],
              pageInfo: prev.chatMessages?.pageInfo,
            },
          });
        }

        // --- for create ---
        if (postedMessage.user.id !== lmsUser?.id) {
          setIncoming(true);
          setReadCalled(false);
        }

        return Object.assign({}, prev, {
          chatMessages: {
            edges: [
              {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __typename: 'ChatMessageEdge',
                node: postedMessage,
                cursor: String(postedMessage.id),
              },
              ...(prev.chatMessages?.edges ?? []),
            ],
            pageInfo: Object.assign({}, prev.chatMessages?.pageInfo, {
              startCursor: String(postedMessage.id),
            }),
          },
        });
      },
    });
  });

  if (loading && !data?.chatMessages)
    return {
      chatMessages: [],
      hasNextPage: false,
      incoming,
      setIncoming,
      readCalled,
      setReadCalled,
      loading,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      loadMore: () => {},
      error,
    };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadMore = async (startIndex: number, stopIndex: number) => {
    try {
      await fetchMore({
        variables: {
          after: data?.chatMessages?.pageInfo.endCursor.toString(),
        },
      });
    } catch {
      return;
    }
  };

  return {
    chatMessages: data?.chatMessages ? data.chatMessages.edges.map(({ node }) => node) : [],
    hasNextPage: data?.chatMessages?.pageInfo.hasNextPage,
    incoming,
    setIncoming,
    readCalled,
    setReadCalled,
    loading,
    loadMore,
    error,
  };
};
