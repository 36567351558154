import React from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';
import { StudyCalendarFragment } from '../../gen/graphql';

interface StudyLogGraphProps {
  data: StudyCalendarFragment[];
  className?: string;
}

export const StudyLogGraph: React.FC<StudyLogGraphProps> = (props) => {
  const week = ['日', '月', '火', '水', '木', '金', '土'];
  const now = new Date();
  const weekNum = moment(now).day();

  const calendar = [];
  for (let i = 6; i >= 0; i--) {
    const day = moment()
      .subtract(weekNum - i, 'days')
      .format('YYYY-MM-DD');
    const data = props.data.find((v) => moment(v.date).format('YYYY-MM-DD') === day);

    calendar[i] = {
      date: day,
      time: data?.studyReportTime ? data.studyReportTime : 0,
    };
  }

  return (
    <Container className={props.className}>
      <Graph>
        {calendar.map((p, i) => {
          const hour = Math.floor(p.time / 60);
          const minute = p.time % 60;
          const ratio = p.time / 720 > 1 ? 100 : (p.time / 720) * 100;
          return (
            <div key={i}>
              <Time>
                {hour}:{('00' + minute).slice(-2)}
              </Time>
              <Rod length={ratio} />
            </div>
          );
        })}
      </Graph>
      <Labels>
        {week.map((w, index) => {
          return (
            <Label key={index} className={index === weekNum ? 'active' : ''}>
              {w}
            </Label>
          );
        })}
      </Labels>
    </Container>
  );
};

const Container = styled.div``;
const Graph = styled.div`
  display: flex;
  margin-bottom: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
const Time = styled.p`
  margin-bottom: 6px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  font-weight: 500;
  text-align: center;
`;
const Rod = styled.div<{ length: number }>`
  position: relative;
  width: 16px;
  height: 123px;
  margin: 0 7px;
  background: #f0f0f0;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: ${(props) => props.length}%;
    background: linear-gradient(95.07deg, #fd8258 11.86%, #e2001b 82.5%);
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
const Labels = styled.div`
  display: flex;
`;
const Label = styled.div`
  width: 16px;
  margin: 0 7px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;

  &.active {
    background: #e73248;
    border-radius: 50%;
    color: #fff;
  }
`;
