import React from 'react';
import styled from 'styled-components';

interface PaginationProps {
  className?: string;
  total: number;
  perPage: number;
  page: number;
  setPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = (props): JSX.Element => {
  const totalPageCount = getTotalPageCount(props.total, props.perPage);
  if (totalPageCount === 1) {
    return <></>;
  }

  return (
    <Container className={props.className}>
      {Array.from(Array(totalPageCount)).map((_, i): JSX.Element | string => {
        if (i === props.page + 2 || i === props.page - 4) return <li>...</li>;
        if (i !== 0 && i !== totalPageCount - 1 && (i > props.page + 1 || i < props.page - 3))
          return '';

        return (
          <Item current={i + 1 === props.page} key={i}>
            <ItemLink onClick={(): void => props.setPage(i + 1)}>{i + 1}</ItemLink>
          </Item>
        );
      })}
    </Container>
  );
};

export default Pagination;

const getTotalPageCount = (totalItemCount: number, perPageItemCount: number): number => {
  return Math.ceil(totalItemCount / perPageItemCount);
};

const Container = styled.ul`
  display: flex;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const Item = styled.li<{ current: boolean }>`
  font-weight: ${(props): string => (props.current ? '600' : '400')};
  color: ${(props): string => (props.current ? '#ffffff' : '#b40015')};
  background-color: ${(props): string => (props.current ? '#b40015' : 'transparent')};

  ${(props) => (props.current ? 'margin: 0 0.25rem;' : '')}
`;

const ItemLink = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  height: 1.5rem;
  padding: 0 0.25rem;
  box-sizing: border-box;
  cursor: pointer;
`;
