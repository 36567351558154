import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { TranslateCognitoError } from '../../../utils/CognitoErrorDictionary';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import media from 'styled-media-query';
import { isSome, none, Option } from 'fp-ts/Option';

import { Input } from '../../../components/atoms/Input';

import { useUser } from '../../../redux/user/useUser';

import { Layout } from './Layout';
import * as Sentry from '@sentry/react';

import { UserPasswordValidation, PasswordError } from '../../../utils/FormValidation';
import { useSafeAsyncCallback } from '../../customHooks/SafeAsyncCallback';
import { useNavigate } from 'react-router-dom';

export const ChangeTemporaryPassword = (): JSX.Element => {
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');
  const { user } = useUser();
  const buttonRef = useRef(document.createElement('button'));

  const [retypePassword, setRetypePassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorRetypePassword, setErrorRetypePassword] = useState<Option<PasswordError>>(none);
  const [errorPassword, setErrorPassword] = useState<Option<PasswordError>>(none);

  const inputRetypePassword = (value: string) => {
    setRetypePassword(value);
    setErrorRetypePassword(UserPasswordValidation(value));
    isMatchPassword(password, value);
  };
  const inputPassword = (value: string) => {
    setPassword(value);
    setErrorPassword(UserPasswordValidation(value));
    isMatchPassword(value, retypePassword);
  };

  const isMatchPassword = (password: string, retypePassword: string) => {
    if (password !== retypePassword) {
      setError('パスワードが一致しません');
    } else {
      setError('');
    }
  };

  const buttonDisabled =
    !password || !retypePassword || isSome(errorRetypePassword) || isSome(errorPassword) || error;

  const send = useSafeAsyncCallback(async (): Promise<void> => {
    if (buttonDisabled) return;
    await Auth.completeNewPassword(user.cognitoUser, password.trim(), {})
      .then(() => {
        navigate('/home');
      })
      .catch((err) => {
        setError(TranslateCognitoError(err.message));
        Sentry.captureException(err);
      });
  });

  const checkAuthState = () => {
    if (user.cognitoUser == null) {
      // 正常な画面遷移でない場合はloginに戻す
      navigate('/');
    }
  };

  useEffect(() => {
    checkAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (buttonDisabled) {
      buttonRef.current.classList.remove('active');
    } else {
      buttonRef.current.classList.add('active');
    }
  }, [buttonDisabled, error, errorPassword, errorRetypePassword, password, retypePassword]);

  return (
    <Layout title="仮パスワード変更">
      <Container>
        <h4>仮パスワード変更</h4>
        <StyledInput
          name="password"
          type="password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) => inputPassword(e.target.value)}
          placeholder="パスワード"
          error={isSome(errorPassword)}
        />
        <StyledInput
          name="retypePpassword"
          type="password"
          value={retypePassword}
          onChange={(e: ChangeEvent<HTMLInputElement>) => inputRetypePassword(e.target.value)}
          placeholder="パスワード確認"
          error={isSome(errorRetypePassword)}
        />
        <p className="err">
          {(isSome(errorPassword) && errorPassword.value.message) ||
            (isSome(errorRetypePassword) && errorRetypePassword.value.message)}
        </p>
        <p className="err">{error}</p>
        <button ref={buttonRef} onClick={send}>
          パスワード設定
        </button>
      </Container>
      <style jsx>{`
        h4 {
          text-align: center;
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 2rem;
        }

        .err {
          color: #f00;
          margin: 0.25rem 0;
          max-width: 290px;
          font-size: 0.85rem;
        }

        button {
          margin: 1rem 0;
          cursor: default;
          padding: 0.75rem 0;
          width: 310px;
          background: rgba(0, 0, 0, 0.36);
          color: #fff;
          font-size: 1rem;
          border: none;
          border-radius: 0;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }

        button.active {
          background-color: #e2001b;
          cursor: pointer;
        }
      `}</style>
    </Layout>
  );
};

const Container = styled.div`
  ${media.lessThan('medium')`
    display: inline-block;
    margin-top: 50px;
    background: #fff;
    padding: 30px 20px;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.16);
  `}
  ${media.lessThan('small')`
    display: block;
  `}
  ${media.greaterThan('medium')`
    text-align: center;
  `}
`;

const StyledInput = styled(Input)`
  width: 290px;
  margin: 0 auto;
  font-size: 1rem;
  border: solid 1px rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  background: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 16px;
  input {
    margin: 0;
  }

  ${media.lessThan('small')`
    width: 100%;
  `}
`;
