import React, { useCallback, useLayoutEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';

import { DEFAULT_META_DESCRIPTION, DEFAULT_META_TITLE } from '../const/Service';

interface HeadTagContext {
  title: string;
  meta: Record<string, unknown>[];
  changeTitle: (title: string) => void;
  changeMeta: (meta: Record<string, unknown>[]) => void;
}

const initialContext: HeadTagContext = {
  title: DEFAULT_META_TITLE,
  meta: [
    {
      name: 'description',
      content: DEFAULT_META_DESCRIPTION,
    },
    {
      property: 'og:title',
      content: DEFAULT_META_TITLE,
    },
    {
      property: 'og:description',
      content: DEFAULT_META_DESCRIPTION,
    },
  ],
  changeTitle: () => undefined,
  changeMeta: () => undefined,
};

export const HeadTagContext = React.createContext(initialContext);

const headTagActionTypes = {
  reset: 'RESET' as const,
  changeTitle: 'CHANGE_TITLE' as const,
  changeMeta: 'CHANGE_META' as const,
};

const headTagReset = () => ({
  type: headTagActionTypes.reset,
});

const headTagChangeTitle = (payload: string) => ({
  type: headTagActionTypes.changeTitle,
  payload,
});

const headTagChangeMeta = (payload: Record<string, unknown>[]) => ({
  type: headTagActionTypes.changeMeta,
  payload,
});

type HeadTagActions = ReturnType<
  typeof headTagReset | typeof headTagChangeTitle | typeof headTagChangeMeta
>;

const useHeadTag = (): HeadTagContext => {
  const location = useLocation();
  const path = location.pathname;

  const [headTag, dispatch] = useReducer((state: HeadTagContext, action: HeadTagActions) => {
    switch (action.type) {
      case headTagActionTypes.reset:
        return initialContext;
      case headTagActionTypes.changeTitle:
        return {
          ...state,
          title: action.payload,
        };
      case headTagActionTypes.changeMeta:
        return {
          ...state,
          meta: action.payload,
        };
      default:
        return state;
    }
  }, initialContext);

  const changeTitle = useCallback((title: string) => {
    dispatch({ type: headTagActionTypes.changeTitle, payload: title });
  }, []);

  const changeMeta = useCallback((meta: Record<string, unknown>[]) => {
    dispatch({ type: headTagActionTypes.changeMeta, payload: meta });
  }, []);

  useLayoutEffect(() => {
    dispatch({ type: headTagActionTypes.reset });
  }, [path]);

  return {
    ...headTag,
    changeTitle,
    changeMeta,
  };
};

interface HeadTagProviderProps {
  children: React.ReactNode;
}

export const HeadTagProvider: React.FC<HeadTagProviderProps> = (props) => {
  const value = useHeadTag();

  return <HeadTagContext.Provider value={value}>{props.children}</HeadTagContext.Provider>;
};
