import { UserRole } from '../gen/graphql';

export const UserRoleStudents = [
  UserRole.Student,
  UserRole.StudentGraduated,
  UserRole.StudentSubscription,
  UserRole.StudentFree,
];

export const UserRoleTeams = [
  UserRole.TeamMember,
  UserRole.TeamMemberFree,
  UserRole.TeamAdmin,
  UserRole.TeamAdminFree,
];
