import React from 'react';
import { StudentData } from './Student';
import styled from 'styled-components';
import { CourseListFragment } from '../../gen/graphql';

interface StudentListProps {
  changeCourse: (id: number) => void;
  coursesData: CourseListFragment[];
  currentCourseID: number;
}

export const CourseList: React.FC<StudentListProps> = (props): JSX.Element => {
  return (
    <Container>
      {props.coursesData.map(({ id, student }) => (
        <StudentData
          key={id}
          icon={student.user.image ?? ''}
          userName={`${student.user.maskedPersonalInfo?.name}`}
          changeCourse={() => props.changeCourse(id)}
          currentCourseID={props.currentCourseID}
          courseID={id}
        />
      ))}
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  height: calc(100% - 10.25rem);
  overflow: scroll;
`;
