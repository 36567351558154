import React from 'react';
import styled from 'styled-components';
import { Breadcrumb } from '../organisms/Breadcrumb';

interface Props {
  pageName: string;
}

export const TeamSettingBreadcrumb: React.FC<Props> = ({ pageName }) => {
  const breadcrumbs = [
    { label: 'ホーム', to: '/home' },
    { label: 'チーム', to: '/team' },
    { label: pageName },
  ];

  return <StyledBreadcrumb links={breadcrumbs} />;
};

const StyledBreadcrumb = styled(Breadcrumb)`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;
