import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import PopupImage from '../../../static/image/lp/popup.png';
import { Z_INDEX_MODAL } from '../../../const/ZIndex';

export const LpPopup: React.FC = () => {
  const [isExit, setIsExit] = useState(false);

  useEffect(() => {
    const state = history.state;
    if (!state) {
      history.replaceState({ isExit: true }, '');
      history.pushState({ isExit: false }, '');
    }

    const exit = (e: PopStateEvent) => {
      setIsExit(e?.state?.isExit || false);
    };

    window.addEventListener('popstate', exit);
    return () => {
      window.removeEventListener('popstate', exit);
    };
  }, []);

  return (
    <Wrapper isShow={isExit} data-gtm="popup">
      <Container>
        <Close
          onClick={() => {
            setIsExit(false);
          }}
        >
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            aria-labelledby="addIconTitle"
            stroke="#ffffff"
            strokeWidth="2.4"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            color="#ffffff"
          >
            <title id="addIconTitle">Add</title>
            <path d="M17 12L7 12M12 17L12 7" /> <circle cx="12" cy="12" r="10" />
          </svg>
        </Close>
        <a href="https://terakoya.sejuku.net/#plan?cid=popup-lp_20211111" target="_blank">
          <img src={PopupImage} alt="" />
        </a>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isShow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: ${Z_INDEX_MODAL};
  transition: all 0.3s;

  ${(props) =>
    props.isShow
      ? `
    opacity: 1;
    pointer-events: all;
  `
      : `
    opacity: 0;
    pointer-events: none;
  `}
`;
const Container = styled.div`
  width: calc(100% - 2rem);
  max-width: 500px;
  position: relative;

  a {
    display: block;

    img {
      display: block;
      max-width: 100%;
    }
  }
`;
const Close = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;

  svg {
    display: block;
    width: 2rem;
    height: 2rem;
    transform: rotate(45deg);
    filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.6));
  }
`;
