import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Inner } from '../../../atoms/LpInner';

import DotBefore from '../../../../static/image/lp/lead_simple_before.png';
import DotAfter from '../../../../static/image/lp/lead_simple_after.png';

interface Props {
  onClickCV: () => void;
}

export const LeadSimple: React.FC<Props> = (props) => {
  return (
    <Lead data-gtm="leadSimple">
      <Inner>
        <h2>仲間と一緒にエンジニアを目指そう</h2>
        <LeadLink onClick={props.onClickCV}>
          <span data-e2e="toTrialForm">今すぐ無料で試す</span>
        </LeadLink>
      </Inner>
    </Lead>
  );
};

const Lead = styled.section`
  padding: 4rem 0 5.25rem;
  background: #fff6ea;
  font-weight: 700;
  text-align: center;
  overflow: hidden;

  h2 {
    margin: 0 auto 1.5rem;
    color: #e2001b;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  ${media.lessThan('medium')`
    padding: 2rem 0 2.25rem;

    h2 {
      margin-bottom: 1rem;
      font-size: 1rem;
      line-height: 1.25;
    }
  `}
`;
const LeadLink = styled.div`
  display: block;
  width: 90%;
  max-width: 50rem;
  margin: 0 auto;
  cursor: pointer;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    background: transparent center / contain no-repeat;
    position: absolute;
  }

  &:before {
    width: 3.875rem;
    height: 3.875rem;
    background-image: url(${DotBefore});
    top: -2.375rem;
    right: -1.75rem;
  }

  &:after {
    width: 3.25rem;
    height: 3.25rem;
    background-image: url(${DotAfter});
    bottom: -2.25rem;
    left: -2.375rem;
  }

  span {
    display: block;
    width: 100%;
    padding: 1.6875rem 0;
    background: linear-gradient(95.07deg, #fd8258 11.86%, #e2001b 82.5%);
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0 #e2001b;
    color: #fff;
    font-size: 1.625rem;
    line-height: 1;
    position: relative;
    z-index: 2;
  }

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 30rem;

    &:before {
      width: 2rem;
      height: 2rem;
      top: -27px;
      right: -9px;
    }

    &:after {
      width: 2rem;
      height: 2rem;
      bottom: -42px;
      left: -24px;
    }

    span {
      padding: 1rem 0;
      font-size: 1rem;
    }
  `}
`;
