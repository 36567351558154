import React from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';
import { StudyCalendarFragment } from '../../gen/graphql';

interface StudyLogChartProps {
  data?: StudyCalendarFragment[];
  range: number;
  className?: string;
}

interface Color {
  [key: string]: string;
  EMPTY: string;
  SCALE1: string;
  SCALE2: string;
  SCALE3: string;
  SCALE4: string;
}
const CELL_COLOR: Color = {
  EMPTY: '#EBEDF0',
  SCALE1: 'rgba(241, 176, 7, 0.28)',
  SCALE2: 'rgba(241, 176, 7, 0.6)',
  SCALE3: '#F1B007',
  SCALE4: '#C08C05',
};

export const StudyLogChart: React.FC<StudyLogChartProps> = (props) => {
  // FIXME 応急処置
  // const makeCount = (value: StudyCalendar) => {
  //   const time: number = pipe(
  //     fromNullable(value?.studyReportTime),
  //     getOrElse<number>(() => {
  //       return 0
  //     })
  //   )
  //   switch (true){
  //     case time > 360: return 'SCALE4'
  //     case time > 240: return 'SCALE3'
  //     case time > 120: return 'SCALE2'
  //     case time > 0: return 'SCALE1'
  //     case time === 0: return 'EMPTY'
  //     default://time < -1
  //       throw new Error('未到達') //未到達のパターンだが、網羅のため記述
  //   }
  // };

  //TODO moment駆逐
  const calendar = [];
  const range = props.range + parseInt(moment().format('d'));

  for (let i = 0; i < range; i++) {
    let day = moment().subtract(i, 'days').format('YYYY-MM-DD');

    const data = props.data?.find((v) => moment(v.date).format('YYYY-MM-DD') === day);

    let count = 'EMPTY';
    if (data !== undefined && data.studyReportTime && data.date) {
      if (data.studyReportTime > 0) count = 'SCALE1';
      if (data.studyReportTime > 120) count = 'SCALE2';
      if (data.studyReportTime > 240) count = 'SCALE3';
      if (data.studyReportTime > 360) count = 'SCALE4';
      day = data.date;
    }

    calendar[i] = {
      date: day,
      count: count,
    };

    // FIXME 応急処置
    // let filteredList: StudyCalendar[] = pipe(
    //   fromNullable(props.data),
    //   getOrElse<StudyCalendar[]>(() => {
    //     return []
    //   }),
    //   spanLeft((v: StudyCalendar) => moment(v.date).format('YYYY-MM-DD') === day),
    // ).init//TODO テスト欲しい

    // Sentry.captureException('a');
    // Sentry.captureException(filteredList);

    // if(!isEmpty(filteredList)) {
    //   Sentry.captureException('b');
    //   const value = filteredList[0]
    //   calendar[i] = {
    //     date: day,
    //     count: makeCount(value)
    //   }
    // } else {
    //   calendar[i] = {
    //     date: day,
    //     count: 'EMPTY'
    //   }
    // }
  }

  return (
    <Container className={props.className}>
      <CellMap>
        <Labels>
          <Label>日</Label>
          <Label>月</Label>
          <Label>火</Label>
          <Label>水</Label>
          <Label>木</Label>
          <Label>金</Label>
          <Label>土</Label>
        </Labels>
        {calendar.reverse().map((p, i) => {
          const date = moment(p.date).date();
          return date === 1 ? (
            <KeyCell key={i} color={p.count} count={moment(p.date).format('M')} />
          ) : (
            <Cell key={i} color={p.count} />
          );
        })}
      </CellMap>
      <Index>
        少ない
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        多い
      </Index>
    </Container>
  );
};

const Container = styled.div`
  svg {
    display: block;
  }

  .react-calendar-heatmap-month-label {
    fill: rgba(0, 0, 0, 0.6);
    font-size: 8px;
  }

  .react-calendar-heatmap-weekday-label {
    fill: rgba(0, 0, 0, 0.6);
    font-size: 8px;
  }

  .color-empty {
    fill: #ebedf0;
  }

  .color-scale-1 {
    fill: rgba(241, 176, 7, 0.28);
  }

  .color-scale-2 {
    fill: rgba(241, 176, 7, 0.6);
  }

  .color-scale-3 {
    fill: #f1b007;
  }

  .color-scale-4 {
    fill: #c08c05;
  }
`;
const Index = styled.div`
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  text-align: right;

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 2px;

    &:nth-of-type(1) {
      background: #ebedf0;
    }
    &:nth-of-type(2) {
      background: rgba(241, 176, 7, 0.28);
    }
    &:nth-of-type(3) {
      background: rgba(241, 176, 7, 0.6);
    }
    &:nth-of-type(4) {
      background: #f1b007;
    }
    &:nth-of-type(5) {
      background: #c08c05;
    }
  }
`;
const CellMap = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-content: flex-end;
  height: 186px;
  padding-top: 18px;
  padding-left: 28px;
  position: relative;
  overflow-x: auto;
  box-sizing: border-box;

  &:before {
    content: '';
    display: block;
    width: 28px;
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const Cell = styled.div<{ color: string }>`
  display: block;
  width: 20px;
  height: 20px;
  margin: 4px 4px 0 0;
  background: ${(props) => CELL_COLOR[props.color || 'EMPTY']};
`;
const KeyCell = styled.div<{ color: string; count: string }>`
  display: block;
  width: 20px;
  height: 20px;
  margin: 4px 4px 0 0;
  background: ${(props) => CELL_COLOR[props.color || 'EMPTY']};

  &:before {
    content: '${(props) => props.count}月';
    display: block;
    width: 30px;
    margin-left: -5px;
    position: absolute;
    top: 0px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    text-align: center;
  }
`;
const Labels = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;
const Label = styled.div`
  display: block;
  width: 11px;
  height: 20px;
  margin: 4px 0 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`;
