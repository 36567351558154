import React, { useCallback, useEffect } from 'react';

// See: https://github.com/bvaughn/react-window/issues/398#issuecomment-753528128
export const useInvertScrollDirection = (
  enabled: boolean,
): ((incomingRef: HTMLDivElement | null) => void) => {
  const ref = React.useRef<HTMLDivElement>();

  const invertedWheelEvent = useCallback((e: WheelEvent) => {
    if (ref.current) {
      ref.current.scrollTop += -e.deltaY;
      e.preventDefault();
    }
  }, []);

  useEffect(
    () => () => {
      if (ref.current) {
        ref.current.removeEventListener('wheel', invertedWheelEvent);
      }
    },
    [invertedWheelEvent],
  );

  return (incomingRef: HTMLDivElement | null) => {
    if (!enabled || !incomingRef) {
      return;
    }

    ref.current = incomingRef;

    if (ref.current) {
      ref.current.addEventListener('wheel', invertedWheelEvent);
    }
  };
};
