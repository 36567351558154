import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUser } from '../../../redux/user/useUser';

import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import media, { generateMedia } from 'styled-media-query';

import { TabNavigationLayout } from '../../templates/TabNavigationLayout';
import { Fab } from '../../atoms/Fab';
import { PageWrapper } from '../../atoms/PageWrapper';
import { Breadcrumb } from '../../organisms/Breadcrumb';

import { ChapterTitle } from '../../molecules/ChapterTitle';
import { ProgramElementBackArchive } from '../../molecules/ProgramElementBackArchive';
import { ProgramElementPagination } from '../../molecules/ProgramElementPagination';
import { ChapterStudyLog } from '../../molecules/ChapterStudyLog';
import { ChapterExamLink } from '../../organisms/ChapterExamLink';
import { ProgramCompleteModal } from '../../organisms/ProgramCompleteModal';
import { ProgramElementsList } from '../../organisms/ProgramElementsList';
import { ProgramElementsNav } from '../../organisms/ProgramElementsNav';

import { Markdown } from '../../../common/Markdown';
import { useProgramSatisfactionModal } from '../../../common/customHooks/ProgramSatisfactionModal';
import { QuestionCreateModal } from '../../organisms/QuestionCreateModal';

import { FIRST_PAGE, MIDDLE_LIMIT } from '../../../const/Page';
import { SERVICE_NAME, LOWER_META_TITLE } from '../../../const/Service';
import {
  useGetQuestionsQuery,
  useGetProgramQuery,
  QuestionSearchType,
  QuestionSortKey,
  QuestionSearchInput,
  useGetStudyLogQuery,
  useGetChapterQuery,
  useGetProgramSatisfactionLazyQuery,
} from '../../../gen/graphql';
import { scrollToID } from '../../../utils/ui/Anchor';
import { QuestionTabContent } from '../../organisms/QuestionTabContent';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { NotFoundPage } from '../public/NotFound';
import { getChaptersAndPractices } from '../../../utils/Program';
import { Loader } from '../../molecules/Loader';
import ForbiddenPage from '../public/Forbidden';
import { useCommonModal } from '../../../redux/common_modal/useCommonModal';
import { isCompletedProgram } from '../../../utils/Program';

export const ChapterDetailContainer = (): JSX.Element => {
  const params = useParams<{ program_id: string; chapter_id: string }>();
  const pathProgramID = Number(params.program_id);
  const pathChapterID = Number(params.chapter_id);
  if (Number.isNaN(pathProgramID) || Number.isNaN(pathChapterID)) return NotFoundPage;

  return <ChapterDetail programID={pathProgramID} chapterID={pathChapterID} />;
};

interface ChapterDetailProps {
  programID: number;
  chapterID: number;
}

const ChapterDetail = (props: ChapterDetailProps): JSX.Element => {
  const location = useLocation();
  const { user, permissionCheck } = useUser();
  const { openModal } = useCommonModal();

  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');

  const {
    data: studyLogData,
    loading: studyLogLoading,
    refetch: refetchStudyLog,
  } = useGetStudyLogQuery({
    variables: { chapterID: props.chapterID },
    skip: !permissionCheck(FunctionType.StudyLog, PermissionType.Read),
  });

  const { data: chapterData, loading: chapterLoading } = useGetChapterQuery({
    variables: {
      chapterID: props.chapterID,
      includeExam:
        permissionCheck(FunctionType.Exam, PermissionType.Read) ||
        permissionCheck(FunctionType.ExamForInstructorAndCoach, PermissionType.Read),
    },
    onCompleted: (data) => {
      setMetaTitle(`${data?.chapter?.title} | ${LOWER_META_TITLE}`);
      setMetaDescription(
        `${SERVICE_NAME}の章ページです。この章では、${data?.chapter?.description}`,
      );
    },
  });
  const maxScore = chapterData?.chapter?.examScores?.reduce((acc, cur) =>
    acc.score > cur.score ? acc : cur,
  );

  const [isQuestionCreateModalOpen, setIsQuestionCreateModalOpen] = React.useState(false);
  const [isProgramCompleteModalOpen, setIsProgramCompleteModalOpen] = React.useState(false);
  const [isProgramSatisfactionModalOpen, setIsProgramSatisfactionModalOpen] = React.useState(false);

  const {
    data: questions,
    loading: questionsLoading,
    refetch: refetchQuestions,
  } = useGetQuestionsQuery({
    variables: {
      input: {
        type: QuestionSearchType.All,
        page: FIRST_PAGE,
        limit: MIDDLE_LIMIT,
        chapterID: props.chapterID,
        sortKey: QuestionSortKey.CreatedAt,
      },
    },
  });
  const questionsTotal = questions?.getQuestions.total ?? 0;
  const questionsItems = questions?.getQuestions.items ?? [];
  const fetchQuestions = useCallback(
    (params: QuestionSearchInput) => {
      refetchQuestions({ input: params });
    },
    [refetchQuestions],
  );

  const { data, loading: programLoading } = useGetProgramQuery({
    variables: {
      programID: props.programID,
      userID: user.lmsUser?.id,
    },
  });
  const programData = data?.program ?? undefined;
  const programElements = programData ? (getChaptersAndPractices(programData) ?? []) : [];
  const currentChapterIndexInProgramElements = programElements.findIndex(
    ({ __typename: typename, id }) => typename === 'Chapter' && id === props.chapterID,
  );

  const scrollPage = useCallback((): void => {
    const anchor = location.hash;
    if (anchor) {
      const hash = decodeURI(anchor.replace(/^#/, ''));
      if (hash) {
        scrollToID(hash);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollPage();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [scrollPage]);

  const [getProgramSatisfaction, { data: programSatisfactionData }] =
    useGetProgramSatisfactionLazyQuery({
      fetchPolicy: 'network-only',
    });

  const openCompleteModal = () => {
    getProgramSatisfaction({
      variables: {
        programID: props.programID,
      },
    }).then((result) => {
      if (!isCompletedProgram(result.data?.program?.programElements)) {
        return;
      }

      if (result.data?.programSatisfaction) {
        // 教材アンケートが回答済みの場合は完了モーダル表示
        setIsProgramCompleteModalOpen(true);
        return;
      }

      // 教材アンケートが未回答の場合はアンケートモーダル表示
      setIsProgramSatisfactionModalOpen(true);
    });
  };

  const handleModalLink = () => {
    window.open(
      `/question/new?category_id=${programData?.category}&program_id=${props.programID}&chapter_id=${props.chapterID}`,
    );
    setIsQuestionCreateModalOpen(false);
  };

  const handleNewQuestionClick = useCallback(() => {
    if (openModal(FunctionType.Question, PermissionType.Create)) return;
    setIsQuestionCreateModalOpen(true);
  }, [openModal]);

  const breadcrumbs = [
    {
      label: 'ホーム',
      to: '/home',
    },
    {
      label: '教材',
      to: permissionCheck(FunctionType.ProgramForInstructorAndCoach, PermissionType.Read)
        ? '/programs?type=1'
        : '/programs',
    },
    {
      label: chapterData?.chapter?.programElement?.program.title
        ? chapterData.chapter.programElement.program.title
        : '章一覧',
      to: `/programs/${props.programID}/chapters`,
    },
    {
      label: chapterData?.chapter?.title ? chapterData.chapter.title : '現在のページ',
    },
  ];

  const showLoader = useMemo(
    () => studyLogLoading || chapterLoading || questionsLoading || programLoading,
    [studyLogLoading, chapterLoading, questionsLoading, programLoading],
  );

  const { Modal: ProgramSatisfactionModal } = useProgramSatisfactionModal(
    props.programID,
    isProgramSatisfactionModalOpen,
    () => {
      setIsProgramSatisfactionModalOpen(false);
      setIsProgramCompleteModalOpen(true);
    },
    programSatisfactionData?.program?.title ?? '',
  );

  // 章データまたは教材データが取得できない場合、権限（UserRole）により教材が取得できない場合は403へ
  if ((!chapterData?.chapter && !chapterLoading) || (!programData && !programLoading))
    return ForbiddenPage;

  return (
    <React.Fragment>
      <Loader display={showLoader} />
      <QuestionCreateModal
        isOpen={isQuestionCreateModalOpen}
        onClose={() => setIsQuestionCreateModalOpen(false)}
        handleLink={handleModalLink}
      />
      <ProgramCompleteModal
        isOpen={isProgramCompleteModalOpen}
        onClose={() => setIsProgramCompleteModalOpen(false)}
        programID={props.programID}
        programName={chapterData?.chapter?.programElement.program.title}
      />
      <ProgramSatisfactionModal />
      <TabNavigationLayout
        pageTitle="教材"
        tabs={[
          {
            label: '本文',
            to: `/programs/${props.programID}/chapters/${props.chapterID}`,
            active:
              location.pathname === `/programs/${props.programID}/chapters/${props.chapterID}`,
          },
          {
            label: '目次',
            to: `/programs/${props.programID}/chapters/${props.chapterID}/list`,
            active:
              location.pathname === `/programs/${props.programID}/chapters/${props.chapterID}/list`,
          },
          {
            label: `質問一覧 ${questionsTotal}件`,
            to: `/programs/${props.programID}/chapters/${props.chapterID}/questions`,
            active:
              location.pathname ===
              `/programs/${props.programID}/chapters/${props.chapterID}/questions`,
          },
        ]}
        unfixed
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <PageWrapper>
          <Main>
            <StyledBreadcrumb links={breadcrumbs} />
            {location.pathname === `/programs/${props.programID}/chapters/${props.chapterID}` ? (
              <TutorialWrapper>
                <div className="tutorial">
                  <Content data-e2e="chapterContent">
                    <StyledChapterTitle
                      title={
                        chapterData?.chapter
                          ? `${chapterData.chapter.number}章 ${chapterData.chapter.title}`
                          : ''
                      }
                      description={chapterData?.chapter?.description}
                      requireTime={chapterData?.chapter?.requireTime}
                      examScore={maxScore}
                      studyLog={chapterData?.chapter?.studyLog?.understandingLevel}
                    />
                    <MarkdownWrapper>
                      <Markdown content={chapterData?.chapter?.content} type="program" />
                    </MarkdownWrapper>
                  </Content>
                  {permissionCheck(FunctionType.StudyLog, PermissionType.Read) &&
                  refetchStudyLog ? (
                    <ChapterStudyLog
                      chapterID={props.chapterID}
                      studyLog={studyLogData?.getStudyLog ?? null}
                      onCheckStudyLogStatusChanged={() => refetchStudyLog().then()}
                      modalOpen={openCompleteModal}
                    />
                  ) : (
                    ''
                  )}
                  {!!chapterData?.programExam?.length && (
                    <>
                      {permissionCheck(FunctionType.Exam, PermissionType.Read) && (
                        <ChapterExamLink
                          display={!!studyLogData?.getStudyLog}
                          programId={props.programID}
                          chapterId={props.chapterID}
                          examScores={chapterData?.chapter?.examScores}
                        />
                      )}
                      {permissionCheck(
                        FunctionType.ExamForInstructorAndCoach,
                        PermissionType.Read,
                      ) && (
                        <ExamLinkForInstructor>
                          <Link
                            to={`/programs/${props.programID}/chapters/${props.chapterID}/exam`}
                          >
                            テストを確認
                          </Link>
                        </ExamLinkForInstructor>
                      )}
                    </>
                  )}
                  {programData && (
                    <ProgramElementPagination
                      program={programData}
                      currentChapterIndex={currentChapterIndexInProgramElements}
                      disabledNext={
                        permissionCheck(FunctionType.StudyLog, PermissionType.Read) &&
                        !studyLogData?.getStudyLog
                      }
                    />
                  )}
                  <ProgramElementBackArchive
                    programID={props.programID}
                    chapterID={props.chapterID}
                    isLogin={user.isLogged}
                  />
                </div>
                <ProgramElementsNav
                  programElements={programElements}
                  currentID={props.chapterID}
                  currentChapterContent={chapterData?.chapter?.content}
                  programID={props.programID}
                />
              </TutorialWrapper>
            ) : location.pathname ===
              `/programs/${props.programID}/chapters/${props.chapterID}/list` ? (
              <ProgramElementsList
                programElements={programElements}
                currentID={props.chapterID}
                programID={props.programID}
              />
            ) : (
              <React.Fragment>
                {programData && (
                  <QuestionTabContent
                    permissionCheck={permissionCheck}
                    questionsTotal={questionsTotal}
                    questionsItems={questionsItems}
                    fetchQuestions={fetchQuestions}
                    program={programData}
                    chapterID={props.chapterID}
                  />
                )}
              </React.Fragment>
            )}
          </Main>
          {(permissionCheck(FunctionType.Question, PermissionType.Create) ||
            permissionCheck(FunctionType.RecommendLight, PermissionType.Read) ||
            permissionCheck(FunctionType.RecommendTeamSubscription, PermissionType.Read) ||
            permissionCheck(
              FunctionType.RecommendTeamSubscriptionToAdmin,
              PermissionType.Read,
            )) && <Fab onClick={handleNewQuestionClick}>+ 質問する</Fab>}
        </PageWrapper>
      </TabNavigationLayout>
    </React.Fragment>
  );
};

const customMedia = generateMedia({
  tablet: '1024px',
});

const Main = styled.div`
  transition: all 0.2s;

  .tutorial {
    flex: 1;
    min-width: 0;

    .markdown-body {
      scroll-snap-type: y mandatory;

      h2 {
        scroll-snap-align: start;
        scroll-margin-top: 1em;

        &:first-child {
          scroll-snap-align: start;
          scroll-margin-bottom: -1em;
        }
      }
    }
  }

  ${media.lessThan('large')`
    .tutorial {
      width: calc(100% - 15rem);
    }
  `}

  ${customMedia.lessThan('tablet')`
    .tutorial {
      width: 100%;
    }
  `}
`;
const TutorialWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;

  ${media.lessThan('medium')`
    padding-bottom: 3rem;
  `}
`;
const Content = styled.div`
  position: relative;

  svg {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
  }
`;
const StyledChapterTitle = styled(ChapterTitle)`
  ${media.lessThan('medium')`
    margin: -2rem -1rem 0;
  `}
`;
const MarkdownWrapper = styled.div`
  margin: 0 auto;
  padding: 2rem;
  overflow: hidden;

  ${media.lessThan('medium')`
    padding: 1.5rem 0;
  `}
`;
const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 1rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const ExamLinkForInstructor = styled.p`
  margin: 2rem auto;
  text-align: center;

  a {
    color: #eb0000;
    font-size: 1rem;
    font-weight: bold;
  }
`;
