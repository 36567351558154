import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';

import styled from 'styled-components';
import media from 'styled-media-query';
import { StudyTagSummaryFragment, Valid } from '../../gen/graphql';
import { pipe } from 'fp-ts/pipeable';
import { fromNullable, getOrElse, isSome } from 'fp-ts/Option';

interface StudyTagSummaryProps {
  data: StudyTagSummaryFragment[];
}

export const StudyTagSummary: React.FC<StudyTagSummaryProps> = (props) => {
  const COLORS = ['#f60900', '#ff423b', '#ff625c', '#ff807b', '#ffa7a4'];

  const studyReportTime = (value: StudyTagSummaryFragment) => {
    return Math.floor(
      pipe(
        fromNullable(value.studyReportTime),
        getOrElse<number>(() => {
          return 0;
        }),
      ) / 60,
    );
  };

  const data = props.data?.map((p) => {
    const tagSummaryId = fromNullable(p.id);
    const tagSummaryStudyReportTime = fromNullable(p.studyReportTime);

    if (isSome(tagSummaryId) && isSome(tagSummaryStudyReportTime)) {
      return {
        name: tagSummaryId.value,
        value: tagSummaryStudyReportTime.value,
      };
    } else {
      return {
        name: '',
        value: 0,
      };
    }
  });

  return (
    <Container>
      <h3 data-e2e="TOP5">学習時間TOP5</h3>
      {props.data ? (
        <React.Fragment>
          <PieChart width={160} height={160}>
            <Pie
              data={data}
              cx={75}
              cy={75}
              innerRadius={30}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
            >
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
          <List>
            {props.data.map((p, index) => {
              return (
                <Row key={index}>
                  <dt>
                    <Square color={COLORS[index]} />
                    {p.tag.status === Valid.Valid ? p.tag?.name : '-'}
                  </dt>
                  <dd>{studyReportTime(p)}</dd>
                </Row>
              );
            })}
          </List>
        </React.Fragment>
      ) : (
        <Nodata>データが登録されていません</Nodata>
      )}
    </Container>
  );
};

const Container = styled.div`
  counter-reset: summary;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  h3 {
    width: 100%;
  }

  ${media.lessThan('medium')`
  width: 100%;
`}
`;
const List = styled.div`
  flex: 1;
  margin-left: 32px;

  ${media.lessThan('large')`
  flex: auto;
  width: 100%;
  margin: 32px 0 0;
`}

  ${media.lessThan('medium')`
  flex: 1;
  margin: 0 0 0 24px;
`}

  ${media.lessThan('small')`
  flex: auto;
  margin: 24px 0 0;
`}
`;
const Row = styled.dl`
  counter-increment: summary;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  }

  &:nth-of-type(n + 2) {
    margin-top: 12px;
  }

  dt {
    padding-right: 8px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
    position: relative;
    background: #fff;

    &:before {
      content: counter(summary) '.';
      display: inline-block;
      width: 13px;
      margin-right: 12px;
    }
  }

  dd {
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: bold;
    position: relative;
    background: #fff;

    &:after {
      content: '時間';
      margin-left: 2px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 10px;
      font-weight: 500;
    }
  }
`;
const Square = styled.span<{ color: string }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 4px;
  background: ${(props) => props.color};
`;
const Nodata = styled.div`
  width: 100%;
  padding: 2rem;
  font-weight: bold;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
`;
