import React, { FC } from 'react';
import styled from 'styled-components';
import Linkify from 'linkify-react';

interface LinkifyOptions {
  target: string;
}

interface LinkifyTextProps {
  children?: string | null;
  className?: string;
  options?: LinkifyOptions;
}

export const LinkifyText: FC<LinkifyTextProps> = ({ children, className, options }) => {
  return (
    <StyledLinkifyText className={className} onClick={(e) => e.stopPropagation()}>
      <Linkify options={options}>{children}</Linkify>
    </StyledLinkifyText>
  );
};

const StyledLinkifyText = styled.span`
  a {
    color: #1da1f2;
  }
`;
