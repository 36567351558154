import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { Option, none, some, isSome } from 'fp-ts/Option';
import { compact } from 'fp-ts/Array';
import styled from 'styled-components';
import media from 'styled-media-query';
import { SRLWrapper } from 'simple-react-lightbox';

import { Modal } from '../molecules/Modal';
import { Button } from '../atoms/Button';
import imageFileIcon from '../../static/image/icon_image_file.svg';
import imageDeleteIcon from '../../static/image/icon_image_delete.svg';
import { Spacer } from '../atoms/Spacer';
import { TweetPostDestination } from '../../gen/graphql';
import { SERVICE_NAME } from '../../const/Service';
import { MAX_IMAGE_UPLOAD_COUNT } from '../../const/Timeline';

// 投稿先
const DESTINATIONS = [
  {
    value: TweetPostDestination.Pro,
    label: 'Proに投稿する',
  },
  {
    value: TweetPostDestination.Plus,
    label: `${SERVICE_NAME}に投稿する`,
  },
  {
    value: TweetPostDestination.All,
    label: `両方（Pro / ${SERVICE_NAME}）に投稿する`,
  },
] as const;

interface Props {
  isOpen: boolean;
  isBack: boolean;
  requiredDestination: boolean;
  loading?: boolean;
  onPost: (input: TimelinePostInput) => void;
  onClose: () => void;
}

// NOTE: ファイルのアップロードをRESTでやる必要がなくなったら
// 自動生成されたinputの型を使えるかも
export interface TimelinePostInput {
  destination: TweetPostDestination;
  content: string;
  images: File[];
}

// NOTE: バリデーションのロジックはそのうちModel的なものに寄せるかも
// 一旦はこのコンポーネント内に置いておく
class DestinationValidationError extends Error {}
class ContentValidationError extends Error {}

// 投稿先のバリデーション
const validateDestination = (
  destination: TweetPostDestination | null,
  required: boolean,
): Option<DestinationValidationError> => {
  if (destination === null) {
    if (required) {
      return some(new DestinationValidationError('投稿先を選択してください'));
    }
  }
  return none;
};

// 投稿先のバリデーション
const validateContent = (content: string): Option<ContentValidationError> => {
  const length = content.trim().length;
  if (length === 0) {
    return some(new ContentValidationError('メッセージを入力してください'));
  }
  if (length > 10000) {
    return some(new ContentValidationError('メッセージは1万文字以内で入力してください'));
  }
  return none;
};

export const TimelinePostInputModal: React.FC<Props> = ({
  isOpen,
  isBack,
  requiredDestination,
  onPost,
  onClose,
  ...props
}): JSX.Element => {
  const [destination, setDestination] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState<File[]>([]);

  const [destinationError, setDestinationError] = useState('');
  const [contentError, setContentError] = useState('');

  const [previewImage, setPreviewImage] = useState<boolean>(false);

  // メッセージと宛先(必要あれば)が入力されていれば、投稿ボタンを押せる
  const postButtonIsActive =
    content.trim().length > 0 && (destination.length > 0 || !requiredDestination);
  // 画像は3枚まで
  const imageSelectIsActive = images.length < MAX_IMAGE_UPLOAD_COUNT;

  // 閉じるボタン
  const handleClickClose = useCallback((): void => {
    // 画像プレビュー中のクリックで閉じないようにする
    if (previewImage) {
      return;
    }
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImage]);

  // エラーメッセージクリア
  const clearErrors = (): void => {
    setDestinationError('');
    setContentError('');
  };

  // 投稿ボタンクリック
  const handleClickPostButton = useCallback((): void => {
    // FormButtonのisActiveで弾いてくれないのでチェック
    if (!postButtonIsActive) {
      return;
    }

    clearErrors();

    const destinationValue = DESTINATIONS.find(({ value }) => destination === value)?.value || null;
    const validationErrors: Option<Error>[] = [
      validateDestination(destinationValue, requiredDestination),
      validateContent(content),
    ];

    if (validationErrors.some((error) => isSome(error))) {
      compact(validationErrors).forEach((e) => {
        if (e instanceof DestinationValidationError) {
          setDestinationError(e.message);
        } else if (e instanceof ContentValidationError) {
          setContentError(e.message);
        }
      });
      return;
    }

    onPost({
      // 投稿先選択不可ユーザー(選択できないため値がない)の場合はPro/SNS両方に投稿
      destination: destinationValue || TweetPostDestination.All,
      content: content.trim(),
      images,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination, content, images, postButtonIsActive, requiredDestination]);

  // 画像選択
  const handleChangeImage = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>): void => {
      if (target.files?.length) {
        setImages([...images, target.files[0]]);
      }
      // 連続で同じファイルを選択しても発火するように
      target.value = '';
    },
    [images],
  );

  // 画像削除
  const handleClickRemoveImage = useCallback(
    (removeIndex: number): void => {
      setImages(images.filter((_, i) => i !== removeIndex));
    },
    [images],
  );

  useEffect(() => {
    // 確認画面からの戻りでなければ開いたときに入力値を初期化
    if (isOpen && !isBack) {
      setDestination('');
      setContent('');
      setImages([]);
      clearErrors();
    }
  }, [isOpen, isBack]);

  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={handleClickClose}
      loading={props.loading}
      width={'572px'}
      header={<HeaderTitle>投稿する</HeaderTitle>}
      footer={
        <Footer>
          <ImageSelectLabel
            data-e2e="tweetImageUpload"
            htmlFor="imageUpload"
            isActive={imageSelectIsActive}
          >
            <input
              id="imageUpload"
              type="file"
              accept="image/*"
              onChange={handleChangeImage}
              disabled={!imageSelectIsActive}
              style={{ display: 'none' }}
            />
            <img src={imageFileIcon} alt="画像ファイル選択" />
            <span>画像</span>
          </ImageSelectLabel>
          <Buttons data-e2e="tweetsButtons">
            <Button onClick={onClose} gray>
              キャンセル
            </Button>
            <Button onClick={handleClickPostButton} disabled={!postButtonIsActive}>
              投稿する
            </Button>
          </Buttons>
        </Footer>
      }
    >
      <Container data-e2e="tweetModal">
        {requiredDestination && (
          <>
            <Select value={destination} onChange={({ target: { value } }) => setDestination(value)}>
              <option value="">投稿先を選択してください</option>
              {DESTINATIONS.map(({ value, label }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </Select>
            {destinationError !== '' && <ErrorText>{destinationError}</ErrorText>}
            <Spacer height="1rem" />
          </>
        )}
        <Textarea
          value={content}
          placeholder="メッセージを入力"
          onChange={({ target: { value } }) => setContent(value)}
        />
        {contentError !== '' && <ErrorText>{contentError}</ErrorText>}
        {images.length > 0 && (
          <PostImagesContainer>
            {images.map((image, i) => (
              <PostImageWrapper key={i}>
                <SRLWrapper
                  callbacks={{
                    onLightboxOpened: () => setPreviewImage(true),
                    onLightboxClosed: () => setPreviewImage(false),
                  }}
                  options={{
                    buttons: {
                      showDownloadButton: false,
                      showNextButton: false,
                      showPrevButton: false,
                      showThumbnailsButton: false,
                    },
                    thumbnails: {
                      showThumbnails: false,
                    },
                  }}
                >
                  <PostImage src={URL.createObjectURL(image)} />
                </SRLWrapper>
                <ImageDeleteIcon src={imageDeleteIcon} onClick={() => handleClickRemoveImage(i)} />
              </PostImageWrapper>
            ))}
          </PostImagesContainer>
        )}
        <Note>※画像は3枚まで投稿可能です</Note>
      </Container>
    </Modal>
  );
};

const HeaderTitle = styled.span`
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const Container = styled.div`
  padding: 1.5rem 1.5rem 1rem;
`;

const Select = styled.select`
  padding: 0.4375rem 0.9375rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 0.125rem;
  width: 100%;
  font-size: 0.9375rem;
`;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 11.5rem;
  padding: 0.875rem 1rem 0;
  font-size: 0.9375rem;
  line-height: 1.4;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 0.125rem;
  resize: none;

  &::placeholder {
    color: rgba(0, 0, 0, 0.36);
  }
`;

const ImageSelectLabel = styled.label<{ isActive: boolean }>`
  padding: 0.625rem 0.5625rem;
  border-radius: 0.25rem;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'default')};
  background-color: ${({ isActive }) => (isActive ? '#f2f2f2' : 'rgba(0, 0, 0, 0.36)')};

  img {
    width: 0.875em;
    height: 0.875em;
    vertical-align: middle;
  }

  span {
    margin-left: 0.375rem;
    font-size: 0.75rem;
    color: ${({ isActive }) => (isActive ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.36)')};
    vertical-align: middle;
  }
`;

const PostImagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4px;
  width: 100%;
  margin-top: 1rem;
  border-radius: 1rem;
  overflow: hidden;

  ${media.lessThan('medium')`
    gap: 2px;
    border-radius: 0.625rem;
  `}
`;

const PostImageWrapper = styled.div`
  position: relative;

  /* 画像1枚 */
  &:only-child {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  /* 画像2枚 */
  &:first-child:nth-last-child(2),
  &:first-child:nth-last-child(2) ~ & {
    grid-row: 1 / 3;
  }

  /* 画像3枚 */
  &:first-child:nth-last-child(3),
  &:first-child:nth-last-child(3) ~ & {
    height: 26vw;
    max-height: 12.25rem;
    &:nth-of-type(n + 3) {
      grid-column: 1 / 3;
    }
  }}
`;

const PostImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const ImageDeleteIcon = styled.img`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const ErrorText = styled.p`
  margin: 0.5rem auto 0;
  color: #fd6258;
  align-self: center;
  font-size: 0.875rem;
  font-weight: bold;
`;
const Note = styled.p`
  line-height: 1.8;
  font-size: 0.75rem;
`;
