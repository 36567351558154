import React, { useEffect } from 'react';

interface CvAccessTradeProps {
  userID: number;
}

export const CvAccessTrade: React.FC<CvAccessTradeProps> = (props) => {
  // こちらのタスクで依頼 https://samurai-7s.backlog.com/view/LMS-936
  useEffect(() => {
    window.__atw = [];
    window.__atw.push({
      merchant: 'samuraiep',
      param: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result_id: '100',
        verify: props.userID,
      },
    });
    ((d) => {
      const b = d.createElement('script');
      b.id = 'accessTradeScript';
      b.src = 'https://h.accesstrade.net/js/nct/cv.min.js';
      b.async = !0;
      const h = document.getElementsByTagName('head')[0] as HTMLElement;
      h.appendChild(b);
    })(document);

    return () => {
      document.getElementById('accessTradeScript')?.remove();
    };
  }, [props.userID]);

  return <p />;
};
