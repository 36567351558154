import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Inner } from '../../atoms/LpInner';
import { Ttl } from '../../atoms/LpTtl';

import IconBootstrap from '../../../static/image/lp/language/icon_bootstrap.png';
import IconDesign from '../../../static/image/lp/language/icon_design.png';
import IconHtml from '../../../static/image/lp/language/icon_html.png';
import IconLaravel from '../../../static/image/lp/language/icon_laravel.png';
import IconPhp from '../../../static/image/lp/language/icon_php.png';
import IconPython from '../../../static/image/lp/language/icon_python.png';
import IconSql from '../../../static/image/lp/language/icon_sql.png';
import IconXd from '../../../static/image/lp/language/icon_xd.png';

export const Challenge: React.FC = () => {
  return (
    <Section data-gtm="practice">
      <Inner>
        <Ttl>
          <h2>スキルが身につく課題機能</h2>
          <p>Challenge</p>
        </Ttl>
        <Description>
          教材で学んだことに加えてレベルに合わせた課題で学んだことをアウトプットして、スキルを習得することが可能です。課題は現役エンジニアにレビューしてもらうことができます。
        </Description>
        <Container>
          <ListTitle>課題の一例</ListTitle>
          <List>
            <Item>
              <ItemTitle>「MYPROFILE」というページを作成しよう</ItemTitle>
              <ItemIcon src={IconHtml} alt="HTML/CSS" />
              <ItemCategory>HTML/CSSの基礎を学ぼう</ItemCategory>
            </Item>
            <Item>
              <ItemTitle>テキストと写真のレイアウトをしよう</ItemTitle>
              <ItemIcon src={IconDesign} alt="デザイン" />
              <ItemCategory>デザインの基礎知識を学ぼう</ItemCategory>
            </Item>
            <Item>
              <ItemTitle>テストの平均点を計算してみよう</ItemTitle>
              <ItemIcon src={IconPhp} alt="PHP" />
              <ItemCategory>PHPの基礎を学ぼう</ItemCategory>
            </Item>
            <Item>
              <ItemTitle>クラスを2つ作ってそれぞれ呼び出してみよう</ItemTitle>
              <ItemIcon src={IconPhp} alt="PHP" />
              <ItemCategory>PHPの基礎を学ぼう</ItemCategory>
            </Item>
            <Item>
              <ItemTitle>書籍管理アプリを作成してみよう</ItemTitle>
              <ItemIcon src={IconPhp} alt="PHP" />
              <ItemCategory>PHPとデータベースを連携し簡単なWebアプリを作ろう</ItemCategory>
            </Item>
            <Item>
              <ItemTitle>アプリに機能を追加してみよう</ItemTitle>
              <ItemIcon src={IconLaravel} alt="Laravel" />
              <ItemCategory>Laravelで簡単なTwitter風の投稿アプリを作ろう</ItemCategory>
            </Item>
            <Item>
              <ItemTitle>グリッドシステムを実装してみよう</ItemTitle>
              <ItemIcon src={IconBootstrap} alt="Bootstrap" />
              <ItemCategory>Bootstrapで効率的にWebサイトを作ろう</ItemCategory>
            </Item>
            <Item>
              <ItemTitle>手書きワイヤーを元にレイアウト変更しよう</ItemTitle>
              <ItemIcon src={IconXd} alt="AdobeXD" />
              <ItemCategory>AdobeXDでWebサイトのデザインを作ろう</ItemCategory>
            </Item>
            <Item>
              <ItemTitle>Pythonのアレンジ</ItemTitle>
              <ItemIcon src={IconPython} alt="Python" />
              <ItemCategory>Pythonの基礎を学ぼう</ItemCategory>
            </Item>
            <Item>
              <ItemTitle>データベースとテーブルを作成してデータを取得・削除してみよう</ItemTitle>
              <ItemIcon src={IconSql} alt="SQL" />
              <ItemCategory>データベースとSQLの基礎を学ぼう</ItemCategory>
            </Item>
          </List>
        </Container>
      </Inner>
    </Section>
  );
};

const Section = styled.section`
  margin: 7.5rem auto;

  ${media.lessThan('medium')`
    margin: 4rem auto;
  `}
`;
const Description = styled.p`
  margin: 3rem auto 0;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: center;

  ${media.lessThan('medium')`
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.5;
  `}
`;
const Container = styled.div`
  margin-top: 4rem;
  padding: 4rem 2.25rem;
  background: #f8f8f8;

  ${media.lessThan('medium')`
    margin: 2rem -1rem 0;
    padding: 2rem 1rem 4rem;
  `}
`;
const ListTitle = styled.h3`
  max-width: 768px;
  margin: 0 auto;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`;
const List = styled.ul`
  max-width: 768px;
  margin: 1.5rem auto 0;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    margin-top: .75rem;
  `}
`;
const Item = styled.li`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem 0.375rem;
  padding: 0.5rem 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    gap: .5rem .25rem;
    padding: .625rem 1rem;
  `}
`;
const ItemTitle = styled.h4`
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
`;
const ItemIcon = styled.img`
  display: block;
  width: 1.5rem;
`;
const ItemCategory = styled.p`
  flex: 1;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  line-height: 1rem;

  ${media.lessThan('medium')`
    font-size: .75rem;
  `}
`;
