import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useLocation, useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useUser } from '../../../redux/user/useUser';

import { Fab } from '../../atoms/Fab';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import Pagination from '../../organisms/Pagination';
import QuestionCard from '../../organisms/QuestionCard';
import { QuestionCreateModal } from '../../organisms/QuestionCreateModal';
import { FIRST_PAGE, MAX_LIMIT, MIDDLE_LIMIT, MIN_LIMIT } from '../../../const/Page';

import OnayamiBanner from '../../../static/image/banner_onayami_qa.png';
import FilterIcon from '../../../static/image/icon_filter.svg';
import {
  ProgramSearchType,
  ProgramType,
  QuestionSearchInput,
  useGetTagsLazyQuery,
  useSearchProgramsLazyQuery,
  SearchQuestionStatus,
  QuestionSearchType,
  TagOrder,
} from '../../../gen/graphql';

import { QuestionSortKey, useGetQuestionsLazyQuery } from '../../../gen/graphql';
import { getAnswered, getSolved, getType } from '../../../utils/QuestionFilter';
import { QuestionFilterModal } from '../../organisms/QuestionFilterModal';
import { QuestionFilter } from '../../organisms/QuestionFilter';
import { ALL, INITIAL, INITIAL_ULID } from '../../../const/Filter';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { chapterOrPracticeOptionValue } from '../../../utils/Filter';
import { getChapters, getPractices } from '../../../utils/Program';
import { useCommonModal } from '../../../redux/common_modal/useCommonModal';
import { useURL } from '../../../common/customHooks/URL';
import { Loader } from '../../molecules/Loader';
import { QuestionTabLayout } from '../../templates/QuestionTabLayout';
import { strToEnum } from '../../../utils/common';
import { Mv } from '../../organisms/Mv';

export const QuestionPage: React.FC = (): JSX.Element => {
  const { openModal } = useCommonModal();
  const { user, permissionCheck } = useUser();

  const navigate = useNavigate();
  const location = useLocation();
  const pathType = useMemo(() => location.pathname.split('/').slice(-1)[0], [location.pathname]);
  const [presentType, setPresentType] = useState('');

  const { setParams, queries } = useURL();
  const page = queries?.page ? Number(queries.page) : FIRST_PAGE;
  const sortKey = queries?.sort_key ?? QuestionSortKey.CreatedAt;
  const answered = queries?.answered ? Number(queries.answered) : ALL;
  const solved = queries?.solved ? Number(queries.solved) : ALL;
  const searchQuestionStatus =
    strToEnum(queries?.search_question_status ?? '', SearchQuestionStatus) ??
    SearchQuestionStatus.All;
  const programID = queries?.program_id ? Number(queries.program_id) : INITIAL;
  const chapterID = queries?.chapter_id ? Number(queries.chapter_id) : INITIAL;
  const practiceID = queries?.practice_id ? queries.practice_id : INITIAL_ULID;
  const tagID = queries?.tag_id ? Number(queries.tag_id) : INITIAL;

  const [searchTag, setSearchTag] = useState<string>('');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isQuestionCreateModalOpen, setIsQuestionCreateModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const containerRef = useRef<HTMLDivElement>(null);

  const questionQuery = useMemo(
    (): QuestionSearchInput => ({
      sortKey:
        Object.values(QuestionSortKey).find((value) => value === sortKey) ??
        QuestionSortKey.CreatedAt,
      type: getType(pathType, permissionCheck),
      page: page,
      limit: MIDDLE_LIMIT,
      tagID: tagID ? tagID : undefined,
      programID: programID ? programID : undefined,
      chapterID: chapterID ? chapterID : undefined,
      practiceID: practiceID ? practiceID : undefined,
      solved: getSolved(solved),
      searchQuestionStatus: searchQuestionStatus,
      answered: getAnswered(answered),
    }),
    [
      answered,
      chapterID,
      page,
      pathType,
      permissionCheck,
      practiceID,
      programID,
      searchQuestionStatus,
      solved,
      sortKey,
      tagID,
    ],
  );
  const [getQuestions, { data: questionsData, loading: questionsLoading, error: questionsError }] =
    useGetQuestionsLazyQuery();
  const questions = questionsData?.getQuestions;
  const fetchQuestions = useCallback(() => {
    getQuestions({
      variables: {
        input: questionQuery,
      },
    });
  }, [getQuestions, questionQuery]);

  const [getPrograms, { data: programsData, loading: programsLoading }] =
    useSearchProgramsLazyQuery();
  const programs = programsData?.programs;
  const fetchPrograms = useCallback(() => {
    getPrograms({
      variables: {
        input: {
          programSearchType: ProgramSearchType.All,
          type: permissionCheck(FunctionType.ProgramForInstructorAndCoach, PermissionType.Read)
            ? ProgramType.Normal
            : undefined,
          page: FIRST_PAGE,
          limit: MAX_LIMIT,
        },
      },
    });
  }, [getPrograms, permissionCheck]);

  const [getTags, { data: tagData, loading: tagsLoading }] = useGetTagsLazyQuery();
  const tagsData = tagData?.getTags;
  const fetchTags = useCallback(
    (name: string, limit: number) => {
      getTags({
        variables: {
          input: {
            name,
            limit,
            page: FIRST_PAGE,
            order: TagOrder.QuestionCount,
          },
        },
      });
    },
    [getTags],
  );

  const handleIsFilterModalOpenSet = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const handleSortKeySelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'sort_key', value: value }, { name: 'page' }]);
  };

  const handleAnsweredSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'answered', value: value }, { name: 'page' }]);
  };

  const handleSolvedSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'solved', value: value }, { name: 'page' }]);
  };

  const handleSearchQuestionStatusSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'search_question_status', value: value }, { name: 'page' }]);
  };

  const handleProgramIDSelect = (value: string) => {
    // 検索条件変更時はpageをリセット
    setParams([
      { name: 'program_id', value: value },
      { name: 'chapter_id', value: INITIAL.toString() },
      { name: 'practice_id', value: INITIAL_ULID },
      { name: 'page' },
    ]);
  };

  const handleChapterIDOrPracticeIDSelect = (value: string) => {
    const [chapterId, practiceId] = chapterOrPracticeOptionValue(value);
    // 検索条件変更時はpageをリセット
    setParams([
      { name: 'chapter_id', value: chapterId },
      { name: 'practice_id', value: practiceId },
      { name: 'page' },
    ]);
  };

  const handleSearchTagSet = (value: string) => {
    setSearchTag(value);
  };

  const handleTagReset = () => {
    // 検索条件変更時はpageをリセット
    setParams([{ name: 'tag_id', value: INITIAL.toString() }, { name: 'page' }]);
  };

  const handleTagIDSelect = (value: number) => {
    // 検索条件変更時はpageをリセット
    setParams([
      { name: 'tag_id', value: value !== tagID ? value.toString() : INITIAL.toString() },
      { name: 'page' },
    ]);
  };

  const setPage = useCallback(
    (value: number) => {
      setParams([{ name: 'page', value: value.toString() }]);
    },
    [setParams],
  );

  const handleNewQuestionClick = () => {
    if (openModal(FunctionType.Question, PermissionType.Create)) return;
    setIsQuestionCreateModalOpen(true);
  };

  const handleModalLink = (): void => {
    TagManager.dataLayer({
      dataLayer: {
        userId: user.lmsUser?.id,
        user: user.lmsUser,
        event: 'gtm-qa-que-new',
        eventData: {},
      },
      dataLayerName: 'LMSDataLayer',
    });

    navigate('/question/new');
  };

  const questionFilterModalProps = {
    isOpen: isFilterModalOpen,
    toggle: handleIsFilterModalOpenSet,
    permissionCheck,
    sortKey,
    answered,
    solved,
    searchQuestionStatus,
    programID,
    chapterID,
    practiceID,
    searchTag,
    setSearchTag,
    tagID,
  };

  const questionFilterProps = {
    permissionCheck,
    title: 'フィルター',
    displaySortKey: {
      sortKey,
      handleSortKeySelect,
    },
    displayAnswered: {
      answered,
      handleAnsweredSelect,
    },
    displaySolved: {
      solved,
      handleSolvedSelect,
    },
    displaySearchQuestionStatus: {
      searchQuestionStatus,
      handleSearchQuestionStatusSelect,
    },
    displayProgram: {
      programs: programs?.items ?? [],
      programID,
      handleProgramIDSelect,
    },
    displayChapterOrPractice: {
      chapters: programID
        ? (getChapters(programs?.items.find((i) => i.id === programID)) ?? [])
        : [],
      chapterID,
      practices: programID
        ? (getPractices(programs?.items.find((i) => i.id === programID)) ?? [])
        : [],
      practiceID,
      handleChapterIDOrPracticeIDSelect,
    },
    displayTag: {
      searchTag,
      handleSearchTagSet,
      tags: tagsData?.items ?? [],
      tagID,
      handleTagReset,
      handleTagIDSelect,
    },
  };

  const showLoader = useMemo(
    () => programsLoading || tagsLoading || questionsLoading,
    [programsLoading, tagsLoading, questionsLoading],
  );

  const metaTitle = useMemo(() => {
    switch (pathType) {
      case 'my':
        return permissionCheck(FunctionType.QuestionForInstructorAndCoach, PermissionType.Read)
          ? `担当受講生の質問 | ${LOWER_META_TITLE}`
          : `あなたの質問 | ${LOWER_META_TITLE}`;
      case 'answered':
        return `回答した質問 | ${LOWER_META_TITLE}`;
      case 'draft':
        return `質問の下書き | ${LOWER_META_TITLE}`;
      default:
        return `Q&A掲示板 | ${LOWER_META_TITLE}`;
    }
  }, [pathType, permissionCheck]);
  const metaDescription = `${SERVICE_NAME}のQ＆A掲示板ページです。プログラミング学習に関する技術的な質問はもちろん、転職や案件での困りごとまで現役エンジニア講師が回答します。質問回数は無制限、いつでも悩み相談できる環境を提供します。`;

  useEffect((): (() => void) => {
    const resizeObserver = new ResizeObserver((entries: readonly ResizeObserverEntry[]) => {
      //https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/58701523#58701523
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        setWindowWidth(window.innerWidth);
      });
    });

    containerRef.current && resizeObserver.observe(containerRef.current);
    return (): void => resizeObserver.disconnect();
  }, []);

  useEffect((): void => {
    fetchTags(searchTag, windowWidth > 1440 ? MIDDLE_LIMIT : MIN_LIMIT);
  }, [searchTag, windowWidth, fetchTags]);

  useEffect((): void => {
    fetchPrograms();
  }, [fetchPrograms]);

  useEffect((): void => {
    // 初回表示、または、別タブからの遷移の場合、取得しないでtypeを設定
    if (presentType === '' || (presentType !== '' && pathType !== presentType)) {
      setPresentType(pathType);
      return;
    }
    fetchQuestions();
  }, [fetchQuestions, pathType, presentType]);

  // Q&A作成ボタン表示が可能な権限の判定値
  const shouldShowCreateButton = useMemo(() => {
    return (
      permissionCheck(FunctionType.Question, PermissionType.Create) ||
      permissionCheck(FunctionType.RecommendLight, PermissionType.Read) ||
      permissionCheck(FunctionType.RecommendTeamSubscription, PermissionType.Read) ||
      permissionCheck(FunctionType.RecommendTeamSubscriptionToAdmin, PermissionType.Read)
    );
  }, [permissionCheck]);

  const shouldShowMv = useMemo(
    () => questionQuery.type === QuestionSearchType.All && shouldShowCreateButton,
    [questionQuery, shouldShowCreateButton],
  );

  const mvData = {
    title: '気軽に質問してみよう！',
    description:
      '侍テラコヤのQ&Aでは、技術的な質問はもちろん、学習の進め方での疑問や転職、 案件での困りごとなど「プログラミング学習」に関する疑問を解消できます。',
    bg: '#F4DA92',
    image: OnayamiBanner,
    buttonText: '質問してみる',
    onClick: handleNewQuestionClick,
  };

  return (
    <>
      <Loader display={showLoader} />
      {isFilterModalOpen && <QuestionFilterModal {...questionFilterModalProps} />}
      <QuestionCreateModal
        isOpen={isQuestionCreateModalOpen}
        onClose={(): void => setIsQuestionCreateModalOpen(false)}
        handleLink={handleModalLink}
      />
      <QuestionTabLayout
        activeTab={pathType}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <PageWrapper>
          {shouldShowMv && <StyledMv mv={mvData} />}
          <Container ref={containerRef} showMv={shouldShowMv}>
            <QuestionContainer>
              {questionsError ? (
                <EmptyBlock title="質問の読み込み中にエラーが発生しました">
                  回線状況を確認して再読み込みを行ってください
                </EmptyBlock>
              ) : (
                <>
                  <QuestionHeader>
                    <QuestionCount>{questions?.total}件の結果</QuestionCount>
                    <FilterImg src={FilterIcon} alt="filter" onClick={handleIsFilterModalOpenSet} />
                  </QuestionHeader>
                  {!questions?.items.length ? (
                    <EmptyBlock title="検索結果はありません">
                      類似の検索ワードやタグで見つかる場合もあります。
                      <br />
                      方法を変更して再度検索してみましょう。
                    </EmptyBlock>
                  ) : (
                    questions?.items.map((question) => (
                      <QuestionCard key={question.id} question={question} />
                    ))
                  )}
                  <Pagination
                    total={questions?.total ? questions.total : 0}
                    perPage={MIDDLE_LIMIT}
                    page={page}
                    setPage={setPage}
                  />
                </>
              )}
            </QuestionContainer>
            <FilterContainer>
              <QuestionFilter {...questionFilterProps} />
            </FilterContainer>
          </Container>
        </PageWrapper>
      </QuestionTabLayout>
      {shouldShowCreateButton && <Fab onClick={handleNewQuestionClick}>+ 質問する</Fab>}
    </>
  );
};

const StyledMv = styled(Mv)`
  ${media.lessThan('medium')`
    width: calc(100% + 2rem);
    margin: -2rem -1rem 0;
  `}
`;

const Container = styled.div<{ showMv: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  ${(props) => props.showMv && 'margin-top: 2rem'};
`;

const QuestionContainer = styled.section`
  flex: 1;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const QuestionCount = styled.h3`
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.375rem;
`;

const FilterImg = styled.img`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
  `}
`;

const FilterContainer = styled.div`
  height: 100%;
  width: 284px;
  margin-left: 1.25rem;

  ${media.lessThan('large')`
    width: 230px;
    margin-left: 1rem;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`;
