import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import TagManager from 'react-gtm-module';

import { Markdown } from '../../common/Markdown';
import { Button } from '../atoms/Button';
import { ErrorText } from '../atoms/ErrorText';
import { RichInput, RichInputHandler } from './RichInput';
import { UserIcon } from '../organisms/UserIcon';
import { Control } from './Control';

import { useUser } from '../../redux/user/useUser';
import { QuestionCommentFragment, useUpdateQuestionCommentMutation } from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import {
  QuestionCommentContentError,
  validateQuestionCommentContent,
} from '../../utils/FormValidation';
import { defaultErrorMessage } from '../../const/ErrorMessage';
import { useToastsContext } from '../../context/ToastsProvider';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { QuestionEditingModal } from '../organisms/QuestionEditingModal';

interface QuestionResponseCommentProps {
  response: QuestionCommentFragment;
  answerId?: number;
  questionId: number;
  omitComment?: boolean;
  omitCount?: number;
  setOmitComment?: (omitComment: boolean) => void;
  refetchQuestion: () => void;
  openModalForEditing: boolean;
}

export const QuestionResponseComment: React.FC<QuestionResponseCommentProps> = (props) => {
  const { user, permissionCheck } = useUser();
  const { showToast } = useToastsContext();

  const [openEdit, setOpenEdit] = useState(false);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [editComment, setEditComment] = useState({
    answerId: props.answerId,
    commentId: 0,
    content: '',
  });
  const [contentError, setContentError] = useState<QuestionCommentContentError | null>(null);

  const [updateQuestionComment] = useUpdateQuestionCommentMutation({
    onCompleted: props.refetchQuestion,
  });

  const editContentRef = useRef<RichInputHandler>(null);

  const handleEditComment = (answerId: number, commentId: number, content: string) => {
    TagManager.dataLayer({
      dataLayer: {
        userId: user.lmsUser?.id,
        user: user.lmsUser,
        event: 'gtm-qa-cmt-edit',
        eventData: {
          questionID: props.questionId,
          answerID: answerId,
          commentID: commentId,
        },
      },
      dataLayerName: 'LMSDataLayer',
    });

    setEditComment({ answerId: answerId, commentId: commentId, content: content });
    editContentRef.current?.setValue(content);

    if (props.openModalForEditing) {
      setIsEditingModalOpen(true);
      return;
    }

    setOpenEdit(true);
  };

  const handleCancelEditModal = () => {
    setIsEditingModalOpen(false);
  };

  const handleConfirmEditModal = () => {
    setIsEditingModalOpen(false);
    setOpenEdit(true);
  };

  const closeEdit = () => {
    setOpenEdit(false);
  };

  const updateComment = useSafeAsyncCallback(
    useCallback(async () => {
      const editCommentContent = editContentRef.current?.getValue() ?? '';
      const error = validateQuestionCommentContent(editCommentContent);
      setContentError(error);
      if (error) return;

      try {
        await updateQuestionComment({
          variables: {
            id: editComment.commentId,
            input: {
              content: editCommentContent,
            },
          },
        });
      } catch (err) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        showToast(1, defaultErrorMessage);
      } finally {
        setOpenEdit(false);
      }
    }, [editComment.commentId, updateQuestionComment, showToast]),
  );

  const controlMenu = [
    {
      text: '編集',
      onClick: () => {
        if (props.answerId && props.response.id && props.response.content)
          handleEditComment(props.answerId, props.response.id, props.response.content);
      },
    },
  ];

  useEffect(() => {
    editContentRef.current?.setValue(props.response.content);
  }, [props.response.content, openEdit]);

  return (
    <Container key={props.response.id} omitComment={props.omitComment}>
      {props.omitComment && props.omitCount ? (
        <OmitContent>
          <Omit
            onClick={() => {
              if (props.setOmitComment) props.setOmitComment(false);
            }}
          >
            +過去{props.omitCount}件の返信を表示
          </Omit>
        </OmitContent>
      ) : (
        ''
      )}
      <UserIcon
        user={props.response.user}
        date={props.response.updatedAt ?? props.response.createdAt}
        note={props.response.createdAt === props.response.updatedAt ? '' : '(編集済み)'}
        large
        spSize="small"
      />
      <Content>
        {openEdit ? (
          <React.Fragment>
            <RichInput
              fileUpload={false}
              imageUpload
              initialHeight={10}
              ref={editContentRef}
              name="edit_question_comment"
            />
            {contentError && <StyledErrorText>{contentError.message}</StyledErrorText>}
            <ButtonArea>
              <StyledButton onClick={closeEdit} gray>
                キャンセル
              </StyledButton>
              <StyledButton onClick={updateComment}>更新する</StyledButton>
            </ButtonArea>
          </React.Fragment>
        ) : (
          <section data-e2e={props.response.user.isAI ? 'aiResponse' : ''}>
            <Markdown content={props.response.content} />
          </section>
        )}
      </Content>
      {props.response.user?.id === user.lmsUser?.id &&
        !openEdit &&
        permissionCheck(FunctionType.QuestionAnswerAndComment, PermissionType.Update) && (
          <StyledControl menu={controlMenu} />
        )}
      <QuestionEditingModal
        isOpen={isEditingModalOpen}
        onClose={() => setIsEditingModalOpen(false)}
        handleCancel={handleCancelEditModal}
        handleConfirm={handleConfirmEditModal}
      />
    </Container>
  );
};

const Container = styled.article<{ isComment?: boolean; omitComment?: boolean }>`
  padding: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;

  ${media.lessThan('medium')`
    padding: 1rem 0;
  `}

  ${(props) =>
    props.omitComment
      ? `
        margin-top: .5rem;
        padding-top: 1.5rem;
      `
      : ''}

  ${(props) =>
    props.omitComment
      ? media.lessThan('medium')`
        margin-top: 1rem;
        padding-top: 2rem;
      `
      : ''}
`;

const OmitContent = styled.p`
  display: flex;
  justify-content: center;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
`;
const Omit = styled.span`
  display: block;
  padding: 0.125rem 0.875rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 1.25rem;
  cursor: pointer;
  color: rgba(253, 60, 47, 0.87);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  padding-left: 3.75rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    margin-top: .875rem;
    padding-left: 0;
  `}
`;

const StyledControl = styled(Control)`
  position: absolute;
  top: 1rem;
  right: 1rem;

  ${media.lessThan('medium')`
    right: 0;
  `}
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    align-items: center;
    flex-direction: column-reverse;
    gap: .5rem;
  `}
`;
const StyledButton = styled(Button)`
  width: 100%;
  max-width: 240px;
`;

const StyledErrorText = styled(ErrorText)`
  margin-top: 0.25rem;
`;
