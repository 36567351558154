import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Slider from 'react-slick';

interface Props {
  className?: string;
}

export const LpLanguageSlider: React.FC<Props> = (props) => {
  const settings = {
    autoplay: false,
    arrows: true,
    dots: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <IconList>
      <StyledSlider {...settings}>{props.children}</StyledSlider>
    </IconList>
  );
};

const IconList = styled.div`
  max-width: 968px;
  margin: 0 auto;
  padding: 0 100px;
  box-sizing: border-box;

  ${media.lessThan('large')`
    max-width: calc(768px + 9rem);
    padding: 0 4.5rem;
  `}

  ${media.lessThan('medium')`
    max-width: none;
    padding: 0 3rem;
  `}
`;
const StyledSlider = styled(Slider)`
  padding-bottom: 1.375rem;

  .slick-dots {
    font-size: 0;
    bottom: 0;

    li {
      width: auto;
      height: auto;
      margin: 0 0.375rem;

      button {
        width: 0.625rem;
        height: 0.625rem;
        padding: 0;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 50%;

        &:before {
          content: none;
        }
      }

      &.slick-active button {
        background: #e2001b;
      }
    }
  }

  .slick-prev,
  .slick-next {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background: #e2001b;
    border-radius: 50%;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    top: 1rem;
    bottom: auto;
    z-index: 2;
    transform: none;

    &:before {
      content: none;
    }

    &:after {
      content: '';
      display: block;
      width: 0.75rem;
      height: 0.75rem;
      border-style: solid;
      border-width: 2px;
      border-color: #fff #fff transparent transparent;
    }
  }

  .slick-prev {
    left: -6.25rem;

    &:after {
      margin-left: 0.5rem;
      transform: rotate(-135deg);
    }
  }

  .slick-next {
    right: -6.25rem;

    &:after {
      margin-right: 0.5rem;
      transform: rotate(45deg);
    }
  }

  ${media.lessThan('large')`
    .slick-prev,
    .slick-next {
      top: 11px;
    }

    .slick-prev {
      left: -4.5rem;
    }

    .slick-next {
      right: -4.5rem;
    }
  `}

  ${media.lessThan('medium')`
    .slick-prev,
    .slick-next {
      width: 2rem;
      height: 2rem;
      top: 6px;
    }

    .slick-prev {
      left: -3rem;
    }

    .slick-next {
      right: -3rem;
    }
  `}
`;
